// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import ModalNew from "../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import GenericTableNew from "../../components/Layouts/GenericTableNew";
// --------------- icons 💥  ----------------//

import Back from "../../assets/img/icons/atras-icon.svg";
import SearchFile from "../../assets/img/icons/searchFileGrey.svg";
//-------------- scss styles 😄   --------------
import Select from "react-select";
import {
  customSelectNew,
  customSelectNewDark,
} from "../../components/Layouts/react-select-custom";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Styles from "./CodeReplace.module.scss"




// ------------------ Functions ----------------------


function ConfigurationCodeReplace(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);
 

  const [info, setInfo] = useState({
    search: "",
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "CostTypes"
    );

  //--------------first functions 🍕   ----------

    function handleClickBack() {
        history.push("/contabilidad/Movimientos/Comprobantes");
    }

    function handleClickHistory() {
        history.push("/contabilidad/Movimientos/HistorialDeReemplazos");
    }
    


  //-------------- select options 🍕   --------------

  let optionsAccount = [{ key: "", value: "", label: "Seleccione cuenta" }];
  if (Array.isArray(storage.pucReducer.niifAccounts)) {
    storage.pucReducer.niifAccounts.map((item, index) => {
      optionsAccount.push({
        value: item.id,
        label: item.complete_account + " - " + item.description,
        key: index + "account",
      });
    });
  }

  //--------------  Actions  🍕   --------------

  return (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
      <div className="d-flex">
        <div className="align-self-top pt-1 mx-1">
          <img
            src={Back}
            alt="volver"
            width={24}
            className="hoverPointer "
            style={{ marginTop: "56px" }}
            onClick={handleClickBack}
          />
        </div>
        <div className="d-flex flex-column" style={{width:"60%"}}>
            <h1 className={tableStyles.title} style={{paddingLeft:"0px", marginRight:"0px", marginBottom: "3px"}}>Reemplazo de códigos</h1>
            <p style={{fontWeight:"700", color: "#01A0F6", fontSize:"15px" , paddingLeft: "1px", marginTop:"0px"}}>Configuración avanzada</p>
            <Row className="mx-1">
                <Col xs={4} style={{paddingLeft:"0"}}>
                    <p className={tableStyles.crudModalLabel}>Tipo de comprobante</p>
                    <Select 
                        noOptionsMessage={() => 'No hay datos'}
                        placeholder="Seleccionar..."
                        styles={customSelectNewDark}
                    />
                </Col>
                <Col xs={4}>
                    <p className={tableStyles.crudModalLabel}>Fecha de comprobante</p>
                    <input
                        type="date"
                        name="date"
                        className={IndividualStyles.registerInputsBlue}
                    />
                </Col>
                <Col xs={4} style={{paddingRight:"0"}}>
                    <p className={tableStyles.crudModalLabel}>Fecha de inicio</p>
                    <input
                        type="date"
                        name="date"
                        className={IndividualStyles.registerInputsBlue}
                    />
                </Col>
                <Col xs={12} style={{paddingRight:"0", paddingLeft: "0px"}}>
                    <p className={tableStyles.crudModalLabel}>Seleccionar tipo de reemplazo</p>
                    <Select 
                        noOptionsMessage={() => 'No hay datos'}
                        placeholder="Seleccionar..."
                        styles={customSelectNewDark}
                    />
                </Col>
                <Col xs={12} style={{paddingRight:"0", paddingLeft: "0px"}}>
                    <p className={tableStyles.crudModalLabel}>Seleccionar cuenta o tercero a sustituir*</p>
                    <Select 
                        noOptionsMessage={() => 'No hay datos'}
                        placeholder="Seleccionar..."
                        styles={customSelectNewDark}
                    />
                    
                </Col>
                <Col xs={12} style={{paddingRight:"0", paddingLeft: "0px"}}>
                    <p className={tableStyles.crudModalLabel}>Seleccionar cuenta o tercero de reemplazo*</p>
                    <Select 
                        noOptionsMessage={() => 'No hay datos'}
                        placeholder="Seleccionar..."
                        styles={customSelectNewDark}
                    />
                </Col>
            </Row>
            

            <div className={IndividualStyles.bottomRow}>
                
                <Button 
                    className={IndividualStyles.btnPrimary} 
                    //onClick={()=>buttonReimbursement()}
                    //disabled={!!(storage.accountingReducer.detailExpense.length <= 0)}
                >
                    Reemplazar
                </Button>
                <Button 
                    className={IndividualStyles.btnPrimary} 
                    //onClick={()=>buttonReimbursement()}
                    //disabled={!!(storage.accountingReducer.detailExpense.length <= 0)}
                >
                    Cancelar
                </Button>
                <div style={{padding: "5px", width: "60%"}}>
                    <label
                        // className={Styles.bgBtn}
                    >
                        {" "}
                    </label>
                </div>
                <div style={{alignContent:"flex-start"}} onClick={handleClickHistory}>
                    <label
                        className={Styles.bgBtn}
                        style={{padding: "5px", width: "185px",fontSize: "12px", fontWeight: "700" , fontStyle: "bold", cursor: "pointer"}}
                    >
                        {" "}
                        Historial de reemplazos
                        <img
                        style={{ width: "17px", marginLeft: "7px" }}
                        src={SearchFile}
                        />
                    </label>
                </div>

            </div>
        </div>
      </div>
    </div>
  );
}
export default ConfigurationCodeReplace;