import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useGetMethod } from "../../Hooks/useFetch";
import tableStyles from "../Layouts/tableStyle.module.scss";
import Chart from "react-apexcharts";
import { donutOptions } from "../../helpers/constants";
import { loader } from "../../helpers/helpers";

const DetailSurvey = ({ idSurvey }) => {
  const store = useSelector((state) => state);
  // const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;

  //   ----------------------|------- STATES--------------------------------------
  const { results: surveyListResults, load: surveyListLoader, trigger: getSurveyListMethod } = useGetMethod();
  useEffect(() => {
    getSurveyListMethod({
      url: "/payroll/workenviroment_detail",
      objFilters: {
        id: idSurvey,
        eaccount: idAccount,
      },
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {surveyListLoader && loader}
      <Row className="">
        {surveyListResults?.results?.results?.length > 0 &&
          surveyListResults?.results?.results?.map((elem, index) => {
            let statusT = false
            let numberOf = 0
            let responseNumbers = 0
            let numberOfShort = 0
            if (elem.type === "multiple_choice") {
              elem?.data.length > 0 &&
                elem?.data.forEach((e) => {
                  if (e !== 0) {
                    numberOf++
                  }
                  responseNumbers = responseNumbers + e
                })

              if (numberOf > 0) {
                statusT = true
              }
            } else {
              elem?.answers?.forEach(e =>{
                if (e !== ""){
                  numberOfShort++
                } 
              })
            }
            
            return (
              <div className="py-4">
                <div className={`${tableStyles.nmCardShortQuestions} py-4`} style={{ height: "" }}>
                  <div className={` text-start`}>
                    <div>
                      <p className={`${tableStyles.darkGrayText}`}>
                        <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14} px-4 py-2`}>
                          {" "}
                          {`${index + 1} - ${elem.question}`}
                        </b> <br />
                        <span className={`px-4 ${tableStyles.tlnTextGray} ${tableStyles.f12}`}>{elem.type === "short_answer" ?  `${numberOfShort} respuestas`  : `${responseNumbers} respuestas`} </span>
                      </p>

                      {elem.type === "multiple_choice" ? (
                        statusT === true ?
                          <div className="d-flex justify-content-center">
                            {elem?.labels?.length > 0 && elem?.data?.length > 0 ? (
                              <Chart
                                options={donutOptions({
                                  labels: elem?.labels?.length > 0 ? elem?.labels : [],
                                })}
                                // series={[20, 80]}
                                series={elem?.data?.length > 0 ? elem.data : []}
                                type="donut"
                                width={400}
                              // height={900}
                              />
                            ) : (
                              <Chart
                                options={donutOptions({
                                  labels: ["Cargando..."],
                                  colors: ["gray"],
                                })}
                                series={[50]}
                                type="donut"
                                height={900}
                              />
                            )
                            }
                          </div> :
                          <div className={`${tableStyles.tlnTextGray} ${tableStyles.f14} px-4 py-2 text-center `}>
                            No hay respuestas disponibles
                          </div>
                      ) : (
                        <div className="my-2">
                          {/* <div className={`${tableStyles.nmCardShortQuestions} py-4`}> */}
                          <div className={`${tableStyles.nmShortAnswerContainer}`}>
                            {elem?.answers?.length > 0 ?
                              elem?.answers?.map((answer, index) => {
                                // {answers?.map((answer, index) =>
                                return (
                                  <div className={`${tableStyles.nmShortAnswer} mt-2`}>
                                    <div className="px-4">

                                    <span className={` ${tableStyles.tlnTextGray} ${tableStyles.f14}`}>{answer}</span>
                                    </div>
                                  </div>
                                );
                              })
                              :
                              <div className={`${tableStyles.tlnTextGray} ${tableStyles.f14} px-4 py-2 text-center `}>
                                No hay respuestas disponibles
                              </div>
                            }
                          </div>

                          {/* </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Row>
    </>

  );
};

export default DetailSurvey;
