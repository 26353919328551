//-------------- components 🍕   --------------------------------------------
import React, { useEffect, useState } from 'react'
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import Select from "react-select";
import { tlnCustomSelect } from '../../../src/components/Layouts/react-select-custom';

//-------------- styles 🍕   --------------------------------------------
import tableStyles from "../../../src/components/Layouts/tableStyle.module.scss";
//-------------- icons 🍕   --------------------------------------------
import OrdLupa from "../../assets/img/icons/OrdLupa.svg";
import desplegarGris from "../../assets/img/icons/desplegarGris.svg";
import Help from "../../assets/img/icons/questionBlueClear.svg";
import Lupa from "../../assets/img/icons/tlnSearch.svg";
import Download from '../../assets/img/icons/download.svg';
import ordBlueSearch from "../../assets/img/icons/ordBlueSearch.svg";
import { OrdSlideDownButton } from '../../OrderingModule/OrderingComponents/OrdSlideDownButton';
import ordComponentStyles from '../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import { useGetMethod } from '../../Hooks/useFetch';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { convertMoneyFormat, downloaFile, genericDownloaFile, getPermission } from '../../helpers/helpers';
import paginationStyles from "../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss";
import excelVeryClearBlue from "../../assets/img/icons/excelVeryClearBlue.svg";
import pdfClearBlueIcon from "../../assets/img/icons/pdfClearBlueIcon.svg";
import Pagination from 'react-js-pagination';


function CitationReport(props) {
    //-------------- const 🍕   --------------------------
    const [rotate, setRotate] = useState(false)
    const store = useSelector((state) => state);
    const history = useHistory();
    const token = store.loginReducer.Authorization;
    const [filters, setFilters] = useState({ perpage: 10, page: 1, modality: "tmed" });
    const [trigger, setTrigger] = useState(0);
    const listPermission = store.loginReducer.currentAccount?.profile?.permission
    const myPermission = getPermission({ prefix: 'infoCi', listPermission })
    const { results: ListInfoApp, load: ListInfoAppLoader, trigger: getListInfoApp } = useGetMethod();
    const { results: SelectDoctor, trigger: getSelectDoctor } = useGetMethod();
    const { results: SelectCompany, trigger: getSelectCompany } = useGetMethod();
    const { load: docPdfLoader, trigger: getDocPdf } = useGetMethod();
    const { load: docExcelLoader, trigger: getDocExcel } = useGetMethod();



    //-------------- Actions 🍕   --------------------------
    useEffect(() => {
        getListInfoApp({
            url: '/medical/appointment/appointmentReport/',
            objFilters: filters,
            token: token,
            // doAfterSuccess: (results) => formatData(results.results)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.page, filters.userMed, filters.cClient, filters.status, filters.startDate, filters.endDate, trigger])
    useEffect(() => {
        getSelectDoctor({
            url: '/medical/generals/doctors/',
            token: token,
            // doAfterSuccess: (results) => formatData(results.results)
        });
        getSelectCompany({
            url: '/medical/generals/company/',
            token: token,
            // doAfterSuccess: (results) => formatData(results.results)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let buttons = [
        {
            button: < img
                alt="btn"
                src={OrdLupa}
            >
            </img>,
        },
        {
            button: < img
                alt="btn"
                style={{
                    transform: !rotate && "rotate(180deg)"
                }}
                width={10}
                src={desplegarGris}
            >
            </img>,
        },
    ]

    const renderList = () => {
        let list = [];
        if (Array.isArray(ListInfoApp?.results?.appoinments)) {
            list = ListInfoApp?.results?.appoinments.map((x, index) => {
                return (
                    <Col xs={12} className="mb-3">
                        <div className={tableStyles.tlnCard}>
                            <Row>
                                <Col xs={6}>
                                    <b className={tableStyles.tlnTextDark}>{x?.description}</b>
                                </Col>
                                <Col xs className="text-end">
                                    <span className={`${tableStyles.tlnTextGray} fs-12`}>{x?.appointmentDate}</span>
                                </Col>
                                <div className="p-0 d-flex" style={{ width: "max-content" }}>
                                    <div style={{ backgroundColor: x?.statusBgColor, borderRadius: "10px", padding: "0px 8px", width: "max-content" }}>
                                        <span className="fw-bold" style={{ color: x?.statusFontColor, fontSize: "14px" }}>{x?.statusName}</span>
                                    </div>
                                </div>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Médico</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.medical ? x?.medical : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Paciente</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.patient ? x?.patient : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Identificación</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.documentType ? x?.documentType : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Nacimiento</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.birthDate ? x?.birthDate : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Vía de acceso</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.access ? x?.access : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Empresa</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.corporateClient ? x?.corporateClient : "-"}</span>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Plan contrato</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.contract_name ? x?.contract_name : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Fecha solicitud</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.creationDate ? x?.creationDate : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Costo transacción</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.transactionCost ? convertMoneyFormat(x?.transactionCost) : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Tarifa empresa</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.companyCost ? convertMoneyFormat(x?.companyCost) : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Código autorización</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x.authorization_code_corporate_client ? x.authorization_code_corporate_client : "-"}</span>
                                </Col>
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Forma de pago</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.paymentMethod ? x?.paymentMethod : "-"}</span>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={2} className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Diagnóstico principal</b>
                                    <span className={`${tableStyles.tlnTextGray} fs-13px`}>{!!x?.principalDiagnostic ? x?.principalDiagnostic : "-"}</span>
                                </Col>
                                <Col xs className="d-grid">
                                    <b className={tableStyles.tlnTextPrimary}>Otros diagnósticos</b>
                                    <div>
                                        {!!x?.extendedDiagnostic ?
                                            x?.extendedDiagnostic?.map((item, i) => {
                                                return (
                                                    < span className={`${tableStyles.tlnTextGray} fs-13px`}>
                                                        {item.extendedDiagnostic}{i === x?.extendedDiagnostic.length - 1 ? "" : ", "}
                                                    </span>
                                                )
                                            }) : "-"
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">

                                {
                                    myPermission?.read && (
                                        <Col xs={12} className="text-end align-self-end">
                                            <img src={Lupa} onClick={() => history.push({
                                                pathname: "/telemedicina/detalleCita/",
                                                state: {
                                                    idApp: x?.app_id,
                                                },
                                            })} alt="lupa" className="cursorPointer" />
                                        </Col>
                                    )
                                }
                            </Row>
                        </div>
                    </Col >
                );
            });
        }
        return list;
    }
    let optionsDoctor = [
        { key: "default", value: "", label: "Todos" },
    ];
    if (Array.isArray(SelectDoctor.results)) {
        // eslint-disable-next-line array-callback-return
        SelectDoctor.results.map((item, index) => {
            optionsDoctor.push({
                value: item.idDoctor,
                label: item.nameDoctor,
                key: index + "sda",
            });
        });
    }
    let optionsCompany = [
        { key: "default", value: "", label: "Todas" },
    ];
    if (Array.isArray(SelectCompany.results)) {
        // eslint-disable-next-line array-callback-return
        SelectCompany.results.map((item, index) => {
            optionsCompany.push({
                value: item.idCorporateClient,
                label: item.nameCorporateClient,
                key: index + "fddfss",
            });
        });
    }
    let optionsSevice = [
        { key: "default", value: "", label: "Todos" },
        { key: "default", value: "pending_attention", label: "Atención pendiente" },
        { key: "default", value: "attended", label: "Atención exitosa" },
        { key: "default", value: "partial_attention", label: "Atención parcial" },
        { key: "default", value: "pending_payment", label: "Pago pendiente" },
        { key: "default", value: "failed", label: "Fallida" },
        { key: "default", value: "cancelled", label: "Anulada" },
        { key: "default", value: "pending_validation", label: "Pendiente por validación", },
    ];
    const handleSearch = (e) => {
        e.preventDefault();
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
    };
    const downloadDoc = () => {
        getDocPdf({
            url: '/medical/appointment/appointmentReportExport/',
            objFilters: filters,
            token: token,
            doAfterSuccess: (results) => downloaFile(results.key)
        });
    }

    const downloadExcel = () => {
        getDocExcel({
            url: "/medical/appointment/appointmentReportExport/",
            objFilters: { modality: "tmed", perpage: filters.perpage, cClient: filters.cClient, page: filters.page, userMed: filters.userMed, startDate: filters.startDate, endDate: filters.endDate, site: filters.status, excel: 1 },
            token: token,
            doAfterSuccess: (res) => {
                if (!!res.success) {
                    genericDownloaFile(res?.results?.base64, res?.results?.filename)
                }
            }
        });
    };
    //-------------- Principal return 🍕   --------------------------
    return (
        <div className={`${tableStyles.tlnContainer} mt-4`}>
            {(ListInfoAppLoader || docPdfLoader || docExcelLoader) && (
                <Loader
                    className="loading"
                    type="Oval"
                    color="#003f80"
                    height={100}
                    width={100}
                />
            )}
            <OrdGenericTemplate
                showBottomBorder={false}
                title={"Informe de citas"}
            >
                <Col xs={12} className="p-0 m-0 text-end">
                    {
                        myPermission?.read && (
                            <div className=" d-flex justify-content-end">
                                <button onClick={() => {
                                    downloadDoc()
                                }}>
                                    <img src={pdfClearBlueIcon} alt="pdfClearBlueIcon" className={`${tableStyles.iconSvgSemi}`} style={{ width: "1.7rem" }} />
                                </button>
                                <button onClick={() => {
                                    downloadExcel()
                                }}>
                                    <img
                                        src={excelVeryClearBlue}
                                        alt="excelVeryClearBlue p-2 mr-2"
                                        className={`${tableStyles.iconSvg}`}
                                        style={{ width: "1.5rem" }}
                                    />
                                </button>
                            </div>
                        )
                    }
                    {/* <img
                        src={Download}
                        className="cursorPointer"
                        alt="descargar/Download"
                        onClick={() => downloadExcel()}
                    />
                    <img
                        src={Download}
                        className="cursorPointer"
                        alt="descargar/Download"
                        onClick={() => downloadDoc()}
                    /> */}
                </Col>
                {
                    myPermission?.read && (
                        <OrdSlideDownButton
                            buttons={buttons}
                            onToggleClick={() => setRotate(!rotate)}
                            // bodyClassName={ordComponentStyles.OrdSlideButton}
                            accordionClassName={ordComponentStyles.OrdSlideButton2}
                        >
                            <Row>
                                <Col xs={4}>
                                    <Form.Group className="mb-3 text-start" controlId="fName">
                                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                            <b className="px-1">Médico</b>
                                        </Form.Label>
                                        <Select noOptionsMessage={() => 'No hay datos'}
                                            options={optionsDoctor}
                                            className="text-secondary"
                                            placeholder={"Seleccione"}
                                            styles={tlnCustomSelect}
                                            onChange={(e) => setFilters({ ...filters, userMed: e.value })}
                                        ></Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group className="mb-3 text-start" controlId="fName">
                                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                            <b className="px-1">Empresa</b>
                                        </Form.Label>
                                        <Select noOptionsMessage={() => 'No hay datos'}
                                            options={optionsCompany}
                                            className="text-secondary"
                                            placeholder={"Seleccione"}
                                            styles={tlnCustomSelect}
                                            onChange={(e) => setFilters({ ...filters, cClient: e.value })}
                                        ></Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group className="mb-3 text-start" controlId="fName">
                                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                            <b className="px-1">Estado</b>
                                        </Form.Label>
                                        <Select noOptionsMessage={() => 'No hay datos'}
                                            options={optionsSevice}
                                            className="text-secondary"
                                            placeholder={"Seleccione"}
                                            styles={tlnCustomSelect}
                                            onChange={(e) => setFilters({ ...filters, status: e.value })}
                                        ></Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <Form.Group className="mb-3 text-start" controlId="fName">
                                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                            <b className="px-1">Fecha de inicio</b>
                                        </Form.Label>
                                        <Form.Control
                                            className={`ord-roundInput`}
                                            type="date"
                                            placeholder=""
                                            onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                                        />
                                    </Form.Group></Col>
                                <Col xs={2}>
                                    <Form.Group className="mb-3 text-start" controlId="fName">
                                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                            <b className="px-1">Fecha de fin</b>
                                        </Form.Label>
                                        <Form.Control
                                            className={`ord-roundInput`}
                                            type="date"
                                            placeholder=""
                                            onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={8}>
                                    <form onSubmit={(e) => handleSearch(e)}>
                                        <Form.Group className="mb-3 text-start" controlId="fName">
                                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                            </Form.Label>
                                            <div
                                                className={`d-flex ${tableStyles.ordBlueInputSearch}`}
                                            >
                                                <Form.Control
                                                    className={`ord-roundInput-search`}
                                                    type="text"
                                                    placeholder="Nombre del paciente, no. de Id, nombre del servicio, diagnóstico principal  y otros diagnósticos."
                                                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                                    autocomplete="off"
                                                />
                                                <img
                                                    src={ordBlueSearch}
                                                    alt={"search"}
                                                >
                                                </img>
                                            </div>
                                        </Form.Group>
                                    </form>
                                </Col>
                            </Row>
                        </OrdSlideDownButton>
                    )
                }
                <Row>
                    <Col xs={3}>
                        <div className={`${tableStyles.tlnMiniCont} p-3 d-flex`} >
                            <div className="d-grid col">
                                <span>Total citas programadas</span>
                                <b >{!!ListInfoApp?.results?.status?.scheduledAppointments ? ListInfoApp?.results?.status?.scheduledAppointments : "0"}</b>
                            </div>
                            <div className="text-end">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip placement="top-end" id="button-tooltip" {...props}>
                                            Todas las citas programadas pagadas o no pagadas que no se han atendido
                                        </Tooltip>
                                    }
                                >
                                    <img src={Help} className="cursorPointer" alt="ayuda/help" />
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className={`${tableStyles.tlnMiniCont} p-3 d-flex`} >
                            <div className="d-grid col">
                                <span>Total citas pagadas</span>
                                <b >{!!ListInfoApp?.results?.status?.payAppointments ? ListInfoApp?.results?.status?.payAppointments : "0"}</b>
                            </div>
                            <div className="text-end">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip placement="top-end" id="button-tooltip" {...props}>
                                            Todas las citas programadas que han sido pagadas
                                        </Tooltip>
                                    }
                                >
                                    <img src={Help} className="cursorPointer" alt="ayuda/help" />
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className={`${tableStyles.tlnMiniCont} p-3 d-flex`} >
                            <div className="d-grid col">
                                <span>Total citas atendidas</span>
                                <b >{!!ListInfoApp?.results?.status?.appointmentsAttended ? ListInfoApp?.results?.status?.appointmentsAttended : "0"}</b>
                            </div>
                            <div className="text-end">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip placement="top-end" id="button-tooltip" {...props}>
                                            Todas las citas que se han atendido
                                        </Tooltip>
                                    }
                                >
                                    <img src={Help} className="cursorPointer" alt="ayuda/help" />
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className={`${tableStyles.tlnMiniCont} p-3 d-flex`} >
                            <div className="d-grid col">
                                <span>Total citas fallidas</span>
                                <b >{!!ListInfoApp?.results?.status?.failedAppointments ? ListInfoApp?.results?.status?.failedAppointments : "0"}</b>
                            </div>
                            <div className="text-end">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip placement="top-end" id="button-tooltip" {...props}>
                                            Todas las citas que han fallado
                                        </Tooltip>
                                    }
                                >
                                    <img src={Help} className="cursorPointer" alt="ayuda/help" />
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    myPermission?.read && (
                        <Row className="mt-4">
                            {renderList()}
                        </Row>
                    )
                }
            </OrdGenericTemplate>
            {
                myPermission?.read && (
                    <div className="px-3">
                        <Pagination
                            innerClass={`float-none pagination mb-4`}
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={Number(ListInfoApp.rowTotal)}
                            pageRangeDisplayed={3}
                            onChange={(e) => { setFilters({ ...filters, page: e }); }}
                            itemClassPrev={paginationStyles.itemClassPrev}
                            itemClassNext={paginationStyles.itemClassNext}
                            itemClassFirst={paginationStyles.itemClassFirst}
                            itemClassLast={paginationStyles.itemClassLast}
                            itemClass={paginationStyles.itemClass}
                        />

                    </div>
                )
            }
        </div >
    )

}
export default CitationReport;