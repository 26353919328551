//Login's reducer

import {
  USER_LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  UPDATE_ALL_LOGIN_DATA,
} from "../actions/actionTypes";
import {
  ACCOUNT_SELECT_SAVE,
  ACCOUNT_SELECT_GET,
  ACCOUNT_LOADING,
  CATEGORY_SELECT_SAVE,
  GET_ALL_CATEGORIES,
} from "../actions/actionTypes";

const initialState = {
  user_data: {},
  password: "",
  accounts: [],
  currentAccount: null,
  error: null,
  loading: false,
  loadingCategories: false,
  Authorization: "",
  Authenticated: false,
  all_categories: [],
  newCategories: [],
  urlToGo: "",
};
export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        user_data: action.payload,
        Authenticated: true,
        Authorization: action.Authorization,
      };
    case LOGOUT_SUCCESS:
      return {
        user_data: {},
        displayError: false,
        error: null,
        Authorization: null,
        Authenticated: false,
        accounts: [],
        currentAccount: null,
      };

    case ACCOUNT_LOADING:
      return {
        ...state,
        loading: action.loading,
        error: action.error,
      };

    case ACCOUNT_SELECT_GET:
      return {
        ...state,
        accounts: action.payload,
        loading: action.loading,
      };

    case ACCOUNT_SELECT_SAVE:
      return {
        ...state,
        currentAccount: action.payload,
      };
    case CATEGORY_SELECT_SAVE:
      return {
        ...state,
        category: action.category,
      };
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        loadingCategories: action.loadingCategories,
        all_categories: action.all_categories,
        newCategories: action.newCategories,
      };
    case UPDATE_ALL_LOGIN_DATA:
      return {
        user_data: action?.payload?.user_data,
        accounts: action?.payload?.accounts,
        currentAccount: action?.payload?.currentAccount,
        error: action?.payload?.error,
        loading: action?.payload?.loading,
        Authorization: action?.payload?.Authorization,
        Authenticated: action?.payload?.Authenticated,
        all_categories: action?.payload?.all_categories,
        newCategories: action?.payload?.newCategories,
        urlToGo: action?.urlToGo,
      };

    default:
      return state;
  }
};
