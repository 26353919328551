import {
  BANK_NOTE_TYPES,
  GET_BILLING_RESOLUTION_LIST,
  POST_BANK_NOTE,
  TRANSFER_REGISTER,
} from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { message } from "../helpers/helpers";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);

export const banking_note_types = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: BANK_NOTE_TYPES,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  let filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/treasury/banking_note_types/?${filters}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: BANK_NOTE_TYPES,
        payload: results,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: BANK_NOTE_TYPES,
        loading: false,
      });
    });
};

export const banking_notes_insert =
  (data, doAfter = false) =>
  (dispatch, getState) => {
    dispatch({
      type: POST_BANK_NOTE,
      loading: true,
    });
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/treasury/banking_notes/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { success, message } = res;
        const icon = !!success ? "success" : "warning";
        dispatch({
          type: POST_BANK_NOTE,
          loading: false,
        });

        customSwaltAlert({
          icon: icon,
          title: !!success ? "Registrada exitosamente" : "intenta de nuevo",
          text: message,
        }).then(() => {
          //if (result.isConfirmed){}
          if (!!doAfter) {
            doAfter();
          }
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: POST_BANK_NOTE,
          loading: false,
        });
      });
  };

export const transfer_register =
  (data, doAfter = false) =>
  (dispatch, getState) => {
    dispatch({
      type: TRANSFER_REGISTER,
      loading: true,
    });
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/treasury/transfers/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { success, message } = res;
        const icon = !!success ? "success" : "warning";
        dispatch({
          type: TRANSFER_REGISTER,
          loading: false,
        });

        customSwaltAlert({
          icon: icon,
          title: `${
            success ? "Transferencia Guardada exitosamente" : "Intenta de nuevo"
          }`,
          text: message,
        }).then((result) => {
          //if (result.isConfirmed){}
          if (!!doAfter) {
            doAfter();
          }
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: TRANSFER_REGISTER,
          loading: false,
        });
      });
  };

export const getBillingResolutionList =
  (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_BILLING_RESOLUTION_LIST,
      loading: true,
      rowTotal: 0,
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(
      `${URL_GATEWAY}${API_VERSION}/admin/invoicingResolution/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: GET_BILLING_RESOLUTION_LIST,
          payload: res.results,
          loading: false,
          rowTotal: res.rowCount,
        });
        if (!res.success) {
          dispatch({
            type: GET_BILLING_RESOLUTION_LIST,
            loading: false,
            rowTotal: 0,
          });
          return message("warning", res.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_BILLING_RESOLUTION_LIST,
          loading: false,
          rowTotal: 0,
        });
        console.error(error.message);
        return message("error", "Error", "Ha ocurrido un error");
      });
  };

export const changeResolutionBillStatus = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/admin/invoicingResolution/changeStatus/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "Ha ocurrido un error");
  }
};

export const createNewResolution = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/admin/invoicingResolution/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);

    return customSwaltAlert({
      icon: "warning",
      title: "Intenta de nuevo",
      text:error.message,
      showCancelButton:false
    })
  }
};
export const updateResolution = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/admin/invoicingResolution/${data?.id}/`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text:error.message,
          showCancelButton:false
    })
    
  }
};
export const newParemAgesByWallet = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/receivable/expirationPeriods/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
      icon: "warning",
      title: "Intenta de nuevo",
      text: error.message,
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  }
};
export const updateParemAgesByWallet = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/receivable/expirationPeriods/`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
      icon: "warning",
      title: "Intenta de nuevo",
      text: error.message,
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  }
};
