import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Search from "../../../assets/img/icons/lupa.svg";
import ordBlueSearch from "../../../assets/img/icons/ordBlueSearch.svg";
import PENDING from "../../../assets/img/icons/Alert.svg";
import APPROVED from "../../../assets/img/icons/Check Mark.svg";
import REJECTED from "../../../assets/img/icons/extraBtnRedX.svg";
import PROCESSED from "../../../assets/img/icons/grayCheckNew.svg";
import GenericTableNew from "../../Layouts/GenericTableNew";
import { useSelector, } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import Styles from "../Hiring/Contract.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Box, Grid, Tooltip } from "@mui/material";
import moment from 'moment';
import tableStyles2 from "../../../components/Layouts/tableStyle.module.scss";
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import { convertMoneyFormat, loader } from '../../../helpers/helpers'
import { Header } from './header/Header';
import { useGetMethod } from "../../../Hooks/useFetch";
import { Col, Row } from "react-bootstrap";
import { Title } from "../../../shared";

export const SeveranceAuthorization = () => {
  const store = useSelector((state) => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    (x) => x.functionality?.prefix === "pplTm");
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const [detailPage, setDetailPage] = useState(null)
  const history = useHistory();
  const [data, setData] = useState({
    trigger: 0,
    generatePremiumSchedule: null,
    filters: {
      suggestedDate: "",
      search: "",
      page: 1,
      perpage: 10,
      eaccount: idAccount,
      module: "severanceAuth",
    },
    modal: {
      show: false,
      isEditing: false,
      dateStart: moment(new Date()).format('YYYY-MM-DD'),
      dateEnd: moment(new Date()).format('YYYY-MM-DD'),
      datePayment: moment(new Date()).format('YYYY-MM-DD'),
    }
  })

  const { filters, trigger, modal } = data
  const { results: severancePayrollResults, load: severanceAuthLoader, trigger: getSeverancePayroll } = useGetMethod();


  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }

    getSeverancePayroll({
      url: "/payroll/severance/",
      objFilters: filters,
      token: token,
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);



  const renderElement = (elem) => {
    return (
      <tr key={elem?.idSeverance}>
        <td className={`col-3 px-3 text-start fw-bold ${tableStyles2.darkBlueText}`}>
          {elem?.paymentYear ?? "-"}
        </td>
        <td className="col-md-2 text-center">{elem?.paymentDate ?? "-"}</td>
        <td className="col-md-2 text-center">{elem?.totalEmployees ?? "-"}</td>
        <td className="col-md-3 text-end">{convertMoneyFormat(elem?.totalAmount ?? "-")}</td>
        <td className="col-1 text-center">
          <Tooltip title={elem?.status?.value} arrow>
            <img
              alt={"status"}
              src={
                elem?.status?.name === "review"
                  ? PENDING
                  : elem?.status?.name === "approved"
                    ? APPROVED
                    : elem?.status?.name === "rejected"
                      ? REJECTED
                      : elem?.status?.name === "processed" && PROCESSED
              }
            >
            </img>
          </Tooltip>
        </td>
        <td className="col-1 text-center">
          <Box
            display={'flex'}
            justifyContent='center'
          >
            <span
              style={{ padding: "3px 5px" }}
              className="pointer hoverImg"
            >
              <img
                src={ordBlueSearch}
                alt="buscador"
                onClick={() => setDetailPage(elem)}

              />
            </span>
          </Box>
        </td>
      </tr>
    );
  };

  const renderHeaders = [
    <th key={2} className="text-start col-1 px-3">
      Año
    </th>,
    <th key={4} className="text-center col-2">
      Fecha sugerida
    </th>,
    <th key={5} className="text-center col-2">
      No. de empleados
    </th>,
    <th key={5} className="text-end col-2">
      Total
    </th>,
    <th key={6} className="text-center col-1 px-2">
      Estado
    </th>,
    <th key={6} className="text-center col-1 px-2">
      &nbsp;
    </th>,
  ];
  const closeModal = () => {
    setData(state => ({
      ...state, modal: {
        show: false,
        isEditing: false,
        dateStart: moment(new Date()).format('YYYY-MM-DD'),
        dateEnd: moment(new Date()).format('YYYY-MM-DD'),
        datePayment: moment(new Date()).format('YYYY-MM-DD')
      }
    }))
  }
  const validationRequest = () => {
    closeModal()
    setData(state => ({ ...state, generatePremiumSchedule: true }))
  }

  return (
    <>
      {severanceAuthLoader && loader}
      {
        detailPage === null
          ? (
            <>
              <OrdModal
                title={modal.isEditing ? "" : "Generar planilla de primas"}
                show={modal.show}
                btnYesName={modal.isEditing ? "Guardar" : "Guardar"}
                btnNoName={"Cancelar"}
                size={550}
                onHide={() => closeModal()}
                btnYesEvent={() => validationRequest()}
                btnNoEvent={() => closeModal()}
                sizeBody={`${tableStyles2.modalHeight}`}
                titleClassName={tableStyles2.darkBlueText}
                btnYesStyle={tableStyles2.btnYesStyle}
                btnCancelStyle={tableStyles2.btnCancelStyle}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <label className={tableStyles.crudModalLabel}>
                      Fecha sugerida
                    </label>
                    <input
                      className={`${Styles.register_inputs_new} ${Styles.register_inputs_blue2}`}
                      type="date"
                      value={modal.dateStart}
                      onChange={(e) =>
                        setData(state => ({ ...state, filters: { ...state.filters, dateStart: e.target.value } }))
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label className={tableStyles.crudModalLabel}>
                      Fecha de fin
                    </label>
                    <input
                      className={`${Styles.register_inputs_new} ${Styles.register_inputs_blue2}`}
                      type="date"
                      value={modal.dateEnd}
                      onChange={(e) =>
                        setData(state => ({ ...state, filters: { ...state.filters, dateEnd: e.target.value } }))
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container className="my-3">
                  <Grid item xs={12}>
                    <label className={tableStyles.crudModalLabel}>
                      Fecha de pago
                    </label>
                    <input
                      className={`${Styles.register_inputs_new} ${Styles.register_inputs_blue2}`}
                      type="date"
                      value={modal.datePayment}
                      onChange={(e) =>
                        setData(state => ({ ...state, filters: { ...state.filters, datePayment: e.target.value } }))
                      }
                    />
                  </Grid>
                </Grid>
              </OrdModal>
              <div className="w-80 mx-auto">
                <div className={tableStyles.title}>
                  <Title
                    title="Autorización de cesantías"
                    onClickIcon={() => {
                      history.push('/contabilidad/inicio')
                    }}
                  />
                </div>
                <Row>
                  <Col xs={2}>
                    <p className={tableStyles.crudModalLabel}>Fecha sugerida </p>
                    <input
                      onChange={(e) =>
                        setData(state => ({ ...state, filters: { ...state.filters, suggestedDate: e.target.value } }))
                      }
                      type="date"
                      placeholder="Escribir..."
                      style={{ color: "#7FADDE" }}
                      className={`register-inputs fw-bold`}
                      name="position"
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setData(state => ({ ...state, trigger: state.trigger + 1 }))
                        }
                      }}
                    />
                  </Col>
                  <Col
                    xs={4}
                    className="d-flex align-self-end gap-1 mt-2"
                  >
                    <input
                      className={tableStyles.SearchNew}
                      placeholder="Buscar por No de empleados, Estado..."
                      type="text"
                      value={filters.search}
                      onChange={(e) =>
                        setData(state => ({ ...state, filters: { ...state.filters, search: e.target.value } }))
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setData(state => ({ ...state, trigger: state.trigger + 1 }))
                        }
                      }}
                    ></input>
                    <img
                      className="hoverPointer"
                      alt="iconSearch"
                      style={{}}
                      src={Search}
                      height="24px"
                      onClick={() => {
                        setData(state => ({ ...state, trigger: state.trigger + 1 }))
                      }}
                    />
                  </Col>
                </Row>
                <Grid container>
                  <Grid item xs={12}>
                    <GenericTableNew headers={renderHeaders} dark={true}>
                      {severancePayrollResults?.results?.length > 0 &&
                        severancePayrollResults?.results?.map((el) => {
                          return renderElement(el);
                        })
                      }
                    </GenericTableNew>
                  </Grid>
                  <Grid item xs={12}>

                    <div className={paginationStyles.wrapper}>
                      <p className={`${paginationStyles.paginationText} text-secondary`}>
                        Pag. {severancePayrollResults?.results ? filters.page : ""}
                        {" de "}
                        {Math.ceil(severancePayrollResults?.rowTotal / filters.perpage)
                          ? Math.ceil(severancePayrollResults?.rowTotal / filters.perpage)
                          : ""}{" "}
                        ({severancePayrollResults?.rowTotal} encontrados)
                      </p>
                      <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.perpage}
                        totalItemsCount={severancePayrollResults?.rowTotal}
                        pageRangeDisplayed={5}
                        onChange={(e) =>
                          setData(state => ({ ...state, filters: { ...state.filters, page: e }, trigger: state.trigger + 1 }))
                        }
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext}
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          )
          : <Header dataPage={{ setDetailPage, detailPage }} setTrigger={setData} />
      }
    </>
  );
}
