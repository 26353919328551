import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Search from "../../../../assets/img/icons/lupa.svg";
import GenericTableNew from "../../../Layouts/GenericTableNew";
import {  useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../../Layouts/pagination.module.scss";
import { Col, Row,  } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { loader } from "../../../../helpers/helpers";
import { useGetMethod,  } from "../../../../Hooks/useFetch";



const SurveyAnswers = ({edit, detailD ,data}) => {
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "evalPerf"
    );
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    page: 1,
    perpage: 10,
    eaccount: idAccount,
    id: data.id ?? "",
  });
  const { results: surveyListResults, load: surveyListLoader, trigger: getSurveyListMethod } = useGetMethod();

  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }

    getSurveyListMethod({
      url: "/payroll/performance_evaluation_answers",
      objFilters: filters,
      token: token,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const goToDetail = (elem) => {

    history.push({
      pathname: "/nomina/EvaluacionDeDesempeño/Evaluacion/DetalleRespuesta",
      state: {data: elem, dataDetail: data, action: "detail"},
    })
  }

// --------------------- SECTION TABLE  -------------------------
  const renderElement = (elem) => {
    let percentage = Number(elem?.totalAverage?.slice(0, elem?.totalAverage?.length - 1))
    let color;
    let backgroundColor;

    if (percentage <= 50 ){
      color="#ec1c24"
      backgroundColor= "#f5e2e5"
    } else if ( percentage >= 51  && percentage <= 85){
      color="#e9b419"
      backgroundColor= "#fefaee"
    } else if (percentage >= 86  && percentage <= 100){
      color="#00a551"
      backgroundColor= "#e6f6ee"
    }

    return (
      <tr key={elem.id}>
        <td
        key={1}
        className="col-md-3 text-start pr-2 " style={{ paddingLeft: "2rem" }}>
          <button
          onClick={ ()=> goToDetail(elem)}
          className="text-start"
          >
            <u>
          {elem.nameCandidate ?? "-"}
            </u>
          </button>
        </td>
        <td  key={2} className="col-md-2 text-start">{elem.dependence ?? "-"}</td>
        <td key={3} className="col-md-2 text-start">{elem.jobPosition ?? "-"}</td>
        <td key={4} className="col-md-2 text-center">{elem.transversalAverage ?? "-"}</td>
        <td key={5} className="col-md-2 text-center">{elem.specificAverage ?? "-"}</td>
        <td key={6} className="col-md-2 text-center mx-2">
        <div
              className="rounded-pill p-1"
              style={{
                backgroundColor: backgroundColor,
              }}
            >
              <b style={{ color: color }}>{elem.totalAverage ?? "-"}</b>
            </div>
          </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    // surveyListResults
    if (surveyListResults?.results?.length > 0) {
      const elem2 = surveyListResults?.results;
      if (elem2?.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className=" pl-5 text-start pr-2"
    style={{ paddingLeft: "2rem" }}
    >
    Nombre
    </th>,
    <th key={2} className="text-start">
      Dependencia
    </th>,
    <th key={3} className="text-start">
      Cargo
    </th>,
    <th key={4} className="text-center">Media transversal</th>,
    <th key={5} className="text-center">Media especifica</th>,
    <th key={6} className="text-center px-2">% alcanzado</th>,
  ];
// ----------------!SECTION--------------------------------

  const handleClickSurvey = (action, elem) => {
    if (action === "detail" || action === "edit"){
      history.push({
        pathname: "/nomina/EvaluacionDeDesempeño/Evaluacion",
        state: { action: action, data: elem },
      });
    } else {
      history.push({
        pathname: "/nomina/EvaluacionDeDesempeño/CrearEvaluacion",
        state: action === "new" ? {action: action} : {},
      });
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const statusOptions = [
    {value: "", label:"Seleccionar..."},
    {value: "in_progress", label:"En curso"},
    {value: "to_start", label:"Por iniciar"},
    {value: "completed", label:"Finalizado"},
    {value: "draft", label:"Borrador"},
  ]

  return (
    <>
{(surveyListLoader) && loader}
      <div className={``}>
        <div className={``}>
          <Row>
            <Col className="d-block" xs={8}>
              <div className="pt-4 d-flex">
                  <input
                    className={`${tableStyles.SearchNew} mr-2 text-secondary ${tableStyles.outlineNone}`}
                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                    name="search"
                    type="text"
                    placeholder="Buscar por nombre, dependencia o cargo..."
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch(e);
                      }
                    }}
                  />
                  <img
                    src={Search}
                    className="cursorPointer"
                    height="24px"
                    style={{ marginBottom: "2px" }}
                    alt="Search icon"
                    onClick={(e) => handleSearch(e)}
                  />
              </div>
            </Col>
          </Row>

          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {surveyListResults?.results ? filters.page : ""}
              {" de "}
              {Math.ceil(surveyListResults?.rowTotal / filters.perpage)
                ? Math.ceil(surveyListResults?.rowTotal / filters.perpage)
                : ""}{" "}
              ({surveyListResults?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={surveyListResults?.rowTotal}
              pageRangeDisplayed={5}
              onChange={(e) => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyAnswers