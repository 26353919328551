import React, { Component, useState, useEffect } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Excel from "../../assets/img/icons/excel.svg";
import Alert from "../../assets/img/icons/alertDarkBlue.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Pagination from "react-js-pagination";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import { customSelectNew } from "../../components/Layouts/react-select-custom";
import { useSelector, useDispatch } from "react-redux";
import {
  getClient,
  getBalanceSum,
  getBalanceTotal,
  getProvision,
  getDetailDeteriorate,
  creatProvision,
} from "../../actions/receiptOfInvoicesActions";
import ModalNew from "../../components/Layouts/ModalNew";
import deepcopy from "deepcopy";
import { loginReducer } from "../../reducers/loginReducer";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";

function ImpairmentPurse(props) {
  const history = useHistory();
  const counter = useSelector((state) => state);
  const [showModaldatalle, setShowModalDetalle] = useState(false);
  const [showModalProvi, setShowModalProvi] = useState(false);
  const storage = useSelector((state) => state);
  const [see, setSee] = useState(false);
  const [trigger, setTrigger] = useState(1);
  const dispatch = useDispatch();
  const [selectedList, setselectedList] = useState([]);
  const isLoading = counter.invoiceReducer.loading_PayLegalCollection;

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "portfolioI"
    );

  const [provision, setProvision] = useState({
    user: counter.loginReducer.user_data.id,
    clients: [],
  });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    client: "",
    consolidated: true,
    eaccount: counter.loginReducer.currentAccount.id,
  });

  const [details, setDetails] = useState({
    show: false,
    client_id: "",
  });

  const [filters1, setFilters1] = useState({
    page: 1,
    eaccount: counter.loginReducer.currentAccount.id,
    perpage: 10,
    client: "",
    consolidated: false,
    search: "",
    orderType: "",
    reportType: true,
    module: "deterioration",
  });

  const [percentages, setPercentages] = useState([]);

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };

  const isBoxSelected = (id_client) => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find((x) => x.id === id_client);
      return !!target;
    }
  };

  const changeBoxSelected = (id) => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find((x) => x.id == id);
      if (!!target) {
        tempSelected = tempSelected.filter((x) => x.id != id);
      } else {
        let newChange = {
          id,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };
  const isBoxAllSelected = () => {
    let res = true;
    let counting = 0;
    if (
      Array.isArray(counter.invoiceReducer.balance_sum) &&
      Array.isArray(selectedList)
    ) {
      storage.invoiceReducer.balance_sum.map((item) => {
        if (selectedList.find((itemb) => item.id == itemb.id)) {
          counting++;
        } else {
          res = false;
        }
      });
    }
    return res;
  };

  const changeBoxAllSelected = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [...selectedList];
      if (
        Array.isArray(storage.invoiceReducer.balance_sum) &&
        Array.isArray(tempSelected)
      ) {
        storage.invoiceReducer.balance_sum.map((x) => {
          let id = x.id;
          let newChange = {
            id,
          };
          tempSelected.push(newChange);
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };
  useEffect(() => {
    if (!!!myPermission()?.read) {
      history.push("/cartera/inicio");
    }
  }, []);

  useEffect(() => {
    dispatch(getBalanceSum(filters1));
    dispatch(getBalanceTotal(filters));
    dispatch(getProvision());
  }, [trigger]);

  useEffect(() => {
    dispatch(getBalanceSum(filters1));
  }, [trigger, filters1.page]);

  const infoModalDetail = (id) => {
    dispatch(
      getDetailDeteriorate({
        eaccount: storage.loginReducer.currentAccount.id,
        client: id,
      })
    );
    setShowModalDetalle(true);
    setProvision({ ...provision, clients: [{ id: id }] });
    let tempObjet = [];
    storage.invoiceReducer.provision?.map((x) => {
      let newthing = {
        id: x.tag,
        val: x.deteriorationValue,
      };
      tempObjet.push(newthing);
    });
    setPercentages(tempObjet);
  };

  const header = [
    <th className="text-center">
      <input
        type="checkbox"
        className="border border-dark-blue form-check-input p1 check-dark-blue"
        style={{ borderColor: "1px solid #005DBF !important" }}
        name={"selectedAll"}
        value="selectedAll"
        checked={isBoxAllSelected()}
        onChange={(e) => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th className="text-center">NIT</th>,
    <th className="text-start">Empresa</th>,
    <th className="text-start">Saldo</th>,
    <th className="text-start">Pendiente por radicar</th>,
    <th className="text-start">Corriente</th>,
    <th className="text-start">Deterioro</th>,
    <th className="text-start"></th>,
  ];
  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.balance_sum)) {
      table = storage.invoiceReducer.balance_sum.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">
            <td className="text-center">
              <input
                type="checkbox"
                className="border border-dark-blue form-check-input p1 check-dark-blue"
                style={{
                  width: "15px",
                  height: "15px",
                  borderColor: "1px solid #005DBF !important",
                }}
                name=""
                id=""
                checked={isBoxSelected(x.id)}
                onChange={(e) => changeBoxSelected(x.id)}
              />
            </td>
            <td>{x.nit}</td>
            <td className="text-start">{x.client}</td>
            <td className="text-start">{"$" + numberWithCommas(x.pending)}</td>
            <td className="text-start">{"$" + numberWithCommas(x.unfiled)}</td>
            <td className="text-start">{"$" + numberWithCommas(x.not_due)}</td>
            <td className="text-start">
              {"$" + numberWithCommas(x.deteriorated)}
            </td>
            <td className="text-center">
              <img
                className="cursorPointer"
                src={Filtrar}
                onClick={() => infoModalDetail(x.id)}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const renderListHeaderModal = () => {
    let header = [];
    header = storage.invoiceReducer.detail_deteriorate?.modalHeader?.map(
      ({ path, name }) => (
        <th className="text-center" key={path}>
          {name}
        </th>
      )
    );

    return header;
  };

  const renderListModal = () => {
    let body = [];

    body = storage.invoiceReducer.detail_deteriorate?.modalBody?.map(
      (rowData, index) => (
        <tr key={rowData[index]} className="hover-table-row">
          {storage.invoiceReducer.detail_deteriorate.modalHeader?.map(
            ({ path }) => (
              <td className="text-end" key={path}>
                {"$" + numberWithCommas(rowData[path])}
              </td>
            )
          )}
        </tr>
      )
    );

    return body;
  };

  const setModalPercentage = (id, val) => {
    let tempObjet = deepcopy(percentages);
    var found = tempObjet.findIndex((x) => x.id == id);
    if (found >= 0) {
      tempObjet[found].val = val;
    } else {
      let newthing = {
        id: id,
        val: val,
      };
      tempObjet.push(newthing);
    }
    setPercentages(tempObjet);
  };

  const closeModal = () => {
    setShowModalDetalle(false);
    setShowModalProvi(true);
  };

  const sendProvision = () => {
    setShowModalProvi(false);
    dispatch(
      creatProvision({
        user: provision.user,
        clients: provision.clients,
        eaccount: storage.loginReducer.currentAccount.id,
        percentages,
      }),
      () => {
        dispatch(getBalanceSum(filters1));
        dispatch(getBalanceTotal(filters));
        dispatch(getProvision());
      }
    );
  };

  const sendVarious = () => {
    dispatch(
      creatProvision({
        user: storage.loginReducer.user_data.id,
        clients: selectedList,
        eaccount: storage.loginReducer.currentAccount.id,
      }),
      () => {
        dispatch(getBalanceSum(filters1));
        dispatch(getBalanceTotal(filters));
        dispatch(getProvision());
      }
    );
    setselectedList([]);
  };

  const changeSee = () => {
    if (see) {
      setSee(false);
    } else {
      setSee(true);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };

  return (
    <>
      {(storage.InvoiceReceiptReducer.balanceT_loading) &&
        <div className="loading">
          <Loader
            type="Oval"
            color="#003f80"
            height={100}
            width={100}
          />
        </div>
      }
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        <div className={tableStyles.title}>
          <Title
            title="Deterioro de cartera"
            onClickIcon={() => {
              history.push('/cartera/inicio')
            }}
          />
        </div>
        <div className="d-flex card-container-principal">
          <div className="d-flex card-container">
            <div className="card-principal">
              <label>Saldo</label>
              <p>
                {!!storage.invoiceReducer.balance_total?.pending
                  ? "$" +
                  numberWithCommas(
                    storage.invoiceReducer.balance_total?.pending
                  )
                  : "$"}
              </p>
            </div>
            <div className="card-divwhite"></div>
            <div className="card-principal">
              <label>Pendiente por radicar</label>
              <p>
                {!!storage.invoiceReducer.balance_total?.unfiled
                  ? "$" +
                  numberWithCommas(
                    storage.invoiceReducer.balance_total?.unfiled
                  )
                  : "$"}
              </p>
            </div>
            <div className="card-divwhite"></div>
            <div className="card-principal">
              <label>Corriente</label>
              <p>
                {!!storage.invoiceReducer.balance_total?.not_due
                  ? "$" +
                  numberWithCommas(
                    storage.invoiceReducer.balance_total?.not_due
                  )
                  : "$"}
              </p>
            </div>
          </div>
          <div className="card-mediaQ2">
            <div className="d-flex card-container-2">
              <div className="card-segundario">
                <p>30 dìas</p>
                {see ? (
                  <p style={{ color: "#FF8B00" }}>
                    {!!storage.invoiceReducer.balance_total?.deterioration_m30
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total
                          ?.deterioration_m30
                      )
                      : "$"}
                  </p>
                ) : (
                  <p>
                    {!!storage.invoiceReducer.balance_total?.deterioration_m30
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total?.period_m30
                      )
                      : "$"}
                  </p>
                )}
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>60 dìas</p>
                {see ? (
                  <p style={{ color: "#FF8B00" }}>
                    {!!storage.invoiceReducer.balance_total?.deterioration_m30
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total?.period_31_60
                      )
                      : "$"}
                  </p>
                ) : (
                  <p>
                    {!!storage.invoiceReducer.balance_total?.deterioration_31_60
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total
                          ?.deterioration_31_60
                      )
                      : "$"}
                  </p>
                )}
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>90 dìas</p>
                {see ? (
                  <p style={{ color: "#FF8B00" }}>
                    {!!storage.invoiceReducer.balance_total?.deterioration_m30
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total
                          ?.deterioration_31_60
                      )
                      : "$"}
                  </p>
                ) : (
                  <p>
                    {!!storage.invoiceReducer.balance_total?.deterioration_61_90
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total?.period_31_60
                      )
                      : "$"}
                  </p>
                )}
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>180 dìas</p>
                {see ? (
                  <p style={{ color: "#FF8B00" }}>
                    {!!storage.invoiceReducer.balance_total?.deterioration_m30
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total?.period_91_180
                      )
                      : "$"}
                  </p>
                ) : (
                  <p>
                    {!!storage.invoiceReducer.balance_total
                      ?.deterioration_91_180
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total
                          ?.deterioration_91_180
                      )
                      : "$"}
                  </p>
                )}
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>360 dìas</p>
                {see ? (
                  <p style={{ color: "#FF8B00" }}>
                    {!!storage.invoiceReducer.balance_total?.deterioration_m30
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total?.period_181_360
                      )
                      : "$"}
                  </p>
                ) : (
                  <p>
                    {!!storage.invoiceReducer.balance_total
                      ?.deterioration_181_360
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total
                          ?.deterioration_181_360
                      )
                      : "$"}
                  </p>
                )}
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>Mayor a 360 dìas</p>
                {see ? (
                  <p style={{ color: "#FF8B00" }}>
                    {!!storage.invoiceReducer.balance_total?.deterioration_m30
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total?.period_M360
                      )
                      : "$"}
                  </p>
                ) : (
                  <p>
                    {!!storage.invoiceReducer.balance_total?.deterioration_M360
                      ? "$" +
                      numberWithCommas(
                        storage.invoiceReducer.balance_total
                          ?.deterioration_M360
                      )
                      : "$"}
                  </p>
                )}
              </div>
              <div className="card-divgray"></div>
            </div>
          </div>
        </div>
        <Row className="d-flex mt-4">
          <Col className="mt-1" xs={4}>
            <form onSubmit={(e) => handleSearch(e)}>
              <label className={tableStyles.crudModalLabel}></label>
              <input
                className={IndividualStyles.Search}
                type="text"
                placeholder="Buscar.."
                value={filters1.search}
                onChange={(e) =>
                  setFilters1({ ...filters1, search: e.target.value })
                }
              ></input>
            </form>
          </Col>
          <Col xs={4}>
            <i>
              <img
                style={{ marginTop: "30px" }}
                src={Lupa}
                alt=""
                srcset=""
                onClick={() => setTrigger(trigger + 1)}
              />
            </i>
          </Col>
          <Col xs={4} className="text-end">
            {/* <button
              className={`viewProvi`}
              style={{ marginTop: "35px", padding: "5px" }}
              onClick={() => sendVarious()}
            >
              Provisionar %
            </button> */}
            <button
              className={` viewMora`}
              style={{
                marginTop: "35px",
                padding: "5px",
                width: "max-content",
              }}
              onClick={() => changeSee()}
            >
              {see ? "Ver deterioro" : "Ver Mora"}
            </button>
            <i>
              <img
                // className="rounded bg-secondary"
                style={{ marginTop: "35px" }}
                src={Excel}
                alt=""
                srcset=""
              />
            </i>
          </Col>
        </Row>

        <GenericTable headers={header} dark={true}>
          {renderList()}
        </GenericTable>
        <div className={IndividualStyles.bottom}>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {storage.invoiceReducer.balance_sum ? filters1.page : ""}
              {" de "}
              {Math.ceil(storage.invoiceReducer.balance_sum_total / filters1.perpage)
                ? Math.ceil(
                  storage.invoiceReducer.balance_sum_total / filters1.perpage
                )
                : ""}{" "}
              ({storage.invoiceReducer.balance_sum_total} encontrados)
            </p>
            <Pagination
              activePage={filters1.page}
              itemsCountPerPage={10}
              totalItemsCount={Number(storage.invoiceReducer.balance_sum_total)}
              pageRangeDisplayed={5}
              onChange={(e) => setFilters1({ ...filters1, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>

        <ModalNew
          show={showModaldatalle}
          onHide={() => setShowModalDetalle(false)}
          title={"Detalle de deterioro"}
          btnYesEvent={() => closeModal()}
          btnYesName={"Provisionar"}
          size={"940"}
        >
          <div className="div">
            {(storage.invoiceReducer.provision_loading) &&
              <div className="loading">
                <Loader
                  type="Oval"
                  color="#003f80"
                  height={100}
                  width={100}
                />
              </div>
            }
            <div className="display-grid mr-2">
              <label className={IndividualStyles.crudModalLabelGris}>
                Empresa
              </label>
              <input
                className={IndividualStyles.registerInputsGris}
                type="text"
                style={{ background: "#F5F7FA" }}
                readOnly
                value={
                  !!storage.invoiceReducer.detail_deteriorate?.client
                    ? storage.invoiceReducer.detail_deteriorate?.client
                    : ""
                }
              />
            </div>
            <div className="display-grid mr-2">
              <label className={IndividualStyles.crudModalLabelGris}>
                Saldo
              </label>
              <input
                className={IndividualStyles.registerInputsGris}
                type="text"
                readOnly
                style={{ background: "#F5F7FA" }}
                value={
                  !!storage.invoiceReducer.detail_deteriorate?.pending
                    ? "$" +
                    numberWithCommas(
                      storage.invoiceReducer.detail_deteriorate?.pending
                    )
                    : ""
                }
              />
            </div>
            <div className="display-grid mr-2">
              <label className={IndividualStyles.crudModalLabelGris}>
                Corriente
              </label>
              <input
                className={IndividualStyles.registerInputsGris}
                type="text"
                style={{ background: "#F5F7FA" }}
                readOnly
                value={
                  storage.invoiceReducer.detail_deteriorate?.not_due
                    ? "$" +
                    numberWithCommas(
                      storage.invoiceReducer.detail_deteriorate?.pending
                    )
                    : ""
                }
              />
            </div>
            <div className="display-grid mr-2">
              <label className={IndividualStyles.crudModalLabelGris}>
                Deterioro
              </label>
              <input
                className={IndividualStyles.registerInputsGris}
                style={{ background: "#F5F7FA" }}
                type="text"
                readOnly
                value={
                  storage.invoiceReducer.detail_deteriorate?.deteriorated
                    ? "$" +
                    numberWithCommas(
                      storage.invoiceReducer.detail_deteriorate?.deteriorated
                    )
                    : ""
                }
              />
            </div>
          </div>
          <GenericTable headers={renderListHeaderModal()} dark={false}>
            {renderListModal()}
          </GenericTable>
          <div className="col-3 display-grid mr-2">
            <label className={IndividualStyles.crudModalLabelGris}>
              Última provisión
            </label>
            <input
              className={IndividualStyles.registerInputsGris}
              type="text"
              style={{ background: "#F5F7FA" }}
              readOnly
              value={
                storage.invoiceReducer.detail_deteriorate?.last_provision
                  ? storage.invoiceReducer.detail_deteriorate?.last_provision
                  : ""
              }
            />
          </div>
        </ModalNew>

        <ModalNew
          show={showModalProvi}
          onHide={() => setShowModalProvi(false)}
          title={"Provisionar"}
          hideCancelButton={true}
          btnYesEvent={() => sendProvision()}
          btnYesName={"Aceptar"}
          size={"500"}
        >
          <div className="row">
            <div className="display-grid col-12">
              <label
                className={IndividualStyles.crudModalLabel}
                style={{ color: "#58595B" }}
              >
                <img
                  src={Alert}
                  style={{ width: "10px", height: "10px" }}
                  alt=""
                  srcset=""
                />{" "}
                {
                  "Especifíca el porcentaje a asignar según la edad de la cartera"
                }
              </label>
              <label
                className={IndividualStyles.crudModalLabel}
                style={{ color: "#58595B" }}
              >
                {"que deseas provisionar"}
              </label>
            </div>
          </div>
          <div className="row">
            {storage.invoiceReducer.provision?.map((x, realindex) => (
              <div className="display-grid col-4">
                <label className={IndividualStyles.crudModalLabel}>
                  {x?.name}
                </label>
                <input
                  className={IndividualStyles.registerInputsGris}
                  type="text"
                  defaultValue={x.deteriorationValue}
                  value={percentages[x.tag]}
                  onChange={(e) => setModalPercentage(x.tag, e.target.value)}
                />
              </div>
            ))}
          </div>

          <label
            className={`${IndividualStyles.crudModalLabel} mt-4 `}
            style={{ fontSize: "14px" }}
          >
            <b>Nuevo plazo</b>
          </label>
          <div className="div">
            <div className="display-grid mr-2">
              <label className={IndividualStyles.crudModalLabel}>Días</label>
              <input
                className={IndividualStyles.registerInputsGris}
                type="text"
              />
            </div>
            <div className="display-grid mr-2">
              <label className={IndividualStyles.crudModalLabel}>
                Porcentaje
              </label>
              <input
                className={IndividualStyles.registerInputsGris}
                type="text"
              />
            </div>
          </div>
        </ModalNew>
      </div>
    </>
  );
}
export default ImpairmentPurse;
