
//import SASS && Mui material && icons
import { Box } from '@mui/material';
import tableStyle from "../../components/Layouts/tableStyle.module.scss";

// components
import OrdModal from "../../OrderingModule/OrderingComponents/OrdModal"
import { GridInfoCite } from './components';


export const ServiceOrderReplace = ({
  children,
  data,
  title,
  subTitle,
  show,
  size,
  onHide,
  btnNoEvent,
  hideGridInfo,
  customClassChildren,
  dataCite,
  dataInsurance
}) => {

  return (
    <OrdModal
      hideCancelButton
      hideFooter
      title={title}
      subtitle={subTitle}
      show={show}
      size={size}
      onHide={onHide}
      btnNoEvent={btnNoEvent}
      footerModalClassName={`${hideGridInfo ? '' : tableStyle.bgTabOrderReplace} ${tableStyle.br15px} ps-0 py-0 pe-0 m-0 ${hideGridInfo && customClassChildren}`}
      styleBody={`${hideGridInfo ? '' : 'p-0'} m-0` }
    >

      <Box
        display={'grid'}
        gridTemplateColumns={hideGridInfo ? '1fr' : '1fr 1fr'}
      >

        {
          !hideGridInfo && (
            <Box
              paddingX={4}
              paddingRight={2}
              paddingY={2}
              paddingBottom={8}
              bgcolor={`${tableStyle.bgTabOrderReplace}`}
              className={`${tableStyle.borderServeOrderReplace}`}
            >
              <GridInfoCite
                title={dataInsurance?.title}
                subTitle={dataInsurance?.subTitle}
                printData={dataInsurance?.data}
              />

              <GridInfoCite
                mt={4}
                title={dataCite?.title}
                subTitle={dataCite?.subTitle}
                printData={dataCite?.data}
              />

            </Box>
          )
        }

        <Box
          paddingX={4}
          paddingY={2}
          paddingBottom={0}
          bgcolor={'white'}
          borderRadius={3}
        >
          {children}
        </Box>

      </Box>

    </OrdModal>
  )
}

