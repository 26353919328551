import deepcopy from 'deepcopy'
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
import { loader } from '../../helpers/helpers'
import { useMultiGetMethod } from '../../Hooks/useFetch'
import { ButtonAddClearBlueHover } from '../../OrderingModule/OrderingComponents/ButtonAddClearBlueHover'
export const Conditions = () => {
    const [trigger, setTrigger] = useState(0)
    const [items, setItems] = useState([
    ])
    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const idEnterprise = store.loginReducer.currentAccount.id;
    const [filters, setFilters] = useState({
        page: 1,
        search: "",
        perpage: 10,
        idAccount: idEnterprise
    })
    /* ----------------------------- FETCHS SECTION ----------------------------- */
    /* ----------------------------- GET ----------------------------- */
    const { results: initialList, load: initialListLoader, trigger: getInitialList } = useMultiGetMethod()
    //console.log("🚀 ~ file: Conditions.jsx ~ line 25 ~ Conditions ~ initialList", initialList)
    /* ---------------------------------- POST /PUT ---------------------------------- */

    /* ------------------------------------ - ----------------------------------- */
    useEffect(() => {
        getInitialList({
            multipleGet: [
                {
                    url: '/medical/operator',
                    objFilters: filters,
                    token: token,
                    requestName: "conditions",
                },
            ],
            token: token,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleDelete = (e) => {
        let newArr = items.filter((x) => x.id !== e.id);
        setTrigger(trigger + 1);
        setItems(newArr)
    }
    const handleAddItems = () => {
        let newItem = {
            id: Date.now(),
        }
        setItems([...items, newItem])
    }
    const handleChangeConditions = (e, i) => {
        const { name, value } = e.target;
        let copy = deepcopy(items);
        copy[i][name] = value;
        setItems(copy);
    }
    return (
        <>
            {initialListLoader && loader}
            <div
                className="mb-3"
            >
                {initialList?.conditions?.results?.length > 0 &&
                    initialList?.conditions?.results?.map((e, i) => {
                        return (
                            <>
                                <Row>
                                    <Col xs={5} className={`${tableStyles.ordSearchSections} my-2 ml-2`}
                                        style={{ borderRadius: '10px' }}
                                    >
                                        <Row
                                            className={``}
                                        >
                                            {/* <div className="text-end">
                                                <span onClick={() => handleDelete(e)} className={`cursorPointer ${tableStyles.ordClearGrayText} ${tableStyles.hoverClearGrayToDark} ${tableStyles.f12}`}>✖ &nbsp;</span>
                                            </div> */}
                                            <Col xs={12}>
                                                <Form.Group className="mb-3 text-start" controlId="lName">
                                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                                                        <b> &nbsp; Condición</b>
                                                    </Form.Label>
                                                    <Form.Control
                                                        key={trigger}
                                                        disabled
                                                        value={`${e.name} (${e.value})`}
                                                        name="condition"
                                                        onChange={(e) =>
                                                            handleChangeConditions(e, i)} className={`ord-roundInput`} type="text" placeholder="" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        )
                    })
                }
                {/* <ButtonAddClearBlueHover
                    title={"Nueva condición"}
                    size={2}
                    onClick={() => handleAddItems()}
                /> */}
            </div>
        </>
    )
}
