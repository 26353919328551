import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { OrdGenericTemplate } from "../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import styles from "../../components/Layouts/tableStyle.module.scss";
import { Col, Form, Row } from "react-bootstrap";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import { formatToRcSelect, loader, message, today, validateEmptyString } from "../../helpers/helpers";
import { ordCustomSelect,customSelectMultiGeneral } from "../../components/Layouts/react-select-custom";
import Select from "react-select";
import deleteIcon from "../../assets/img/icons/close.svg";
import addIcon from "../../assets/img/icons/orangeNoBGArrow.svg";
import { AntSwitch } from "../../helpers/constants";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import moment from "moment";

const NewSchedule = ({ setTab, tab, idDoctor, isEditingSchedule, setIsEditingSchedule }) => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const userId = store.loginReducer.user_data.id;
  const [trigger, setTrigger] = useState(0);
  const [triggerSelect, setTriggerSelect] = useState(0);

  const { schId, isEditing } = isEditingSchedule
  // let history = useHistory();
  // ANCHOR data
  const [data, setData] = useState({
    eaccount: idAccount,
    schName: "",
    schDescription: "",
    schExtraQuota: "",
    allowExtraQuota: false,
    siteId: "",
    typeConRoomId: "",
    conRoomId: "",
    modality: "on_site",
    beginsAt: "",
    endsAt: "",
    dow: [],
    auxName: "",
    // attGroup: [],
    doctorId: idDoctor,
    userId: userId,
    schColor: "",
    servicesId: [],
    services: [],
    servicesAttGroup: [],
  });
  // //console.log("data: ", data);


  const [careGroupRow, setCareGroupRow] = useState({
    attGroupId: "",
    attGroupName: "",
    percentage: "",
    attGroup: [],
  });
  // //console.log("careGroupRow: ", careGroupRow);
  // ANCHOR SERVICES ROW 
  const [servicesRow, setServicesRow] = useState({
    srvIdSe: "",
    srvId: "",
    srvName: "",
    maxTime: "",
    ctypeId: "",
    attGroupId: "",
    srvArr: [],

  });
  // //console.log("servicesRow: ", servicesRow);

  const closeTab = () => {
    setTab({ ...tab, page: "index" });
    setIsEditingSchedule({ schId: "", isEditing: false })
  };

  const { results: siteOptionsResults, load: siteOptionsLoader, trigger: getSiteOptions } = useGetMethod();
  const { results: typeOptionsResults, load: typeOptionsLoader, trigger: getTypeOptions } = useGetMethod();
  const { results: locationOptionsResults, load: locationOptionsLoader, trigger: getLocationOptions } = useGetMethod();
  const { results: careGroupOptionsResults, load: careGroupOptionsLoader, trigger: getCareGroupOptions } = useGetMethod();
  const { results: daysOptionsResults, load: dayOptionsLoader, trigger: getDaysOptions } = useGetMethod();
  const { results: servicesOptionsServices, load: servicesOptionsLoader, trigger: getServicesOptions } = useGetMethod();
  const { results: detailScheduleResults, load: detailScheduleLoader, trigger: getDetailSchedule } = useGetMethod();
  const { load: extraQuotaValidationLoader, trigger: ExtraQuotaValidationMethod } = usePostMethod();
  const { load: createScheduleLoader, trigger: createScheduleMethod } = usePostMethod();

  const siteOptions = formatToRcSelect(siteOptionsResults?.results, "siteId", "site", "", "", "");
  const typeOptions = formatToRcSelect(typeOptionsResults?.results, "id", "name", "", "", "");
  const locationOptions = formatToRcSelect(locationOptionsResults?.results, "id", "name", "", "", "");
  var careGroupOptions = servicesRow.srvArr.length > 0 ? formatToRcSelect(careGroupOptionsResults?.results, "id", "name", "", "", "") : [{ value: "", label: "-Seleccione-" }]
  const daysOptions = formatToRcSelect(daysOptionsResults?.results, "dowId", "dowValue", "", "", "");
  const servicesOptions = formatToRcSelect(
    servicesOptionsServices?.results,
    "srvId",
    "srvName",
    "ctypeId",
    "srvId",
    "ctypeName",
    true,
    "attGroupId",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "ctypeId"
  );

  const quotaExtraOptions = [
    { value: "", label: "-Seleccione-" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
  ];
  useEffect(() => {
    if (isEditing) {
      let arrServices = [];
      let srvAttGrp = [];
      let srvIds = [];
      let srvArrState = []
      let arrIdString = []
      let attGroupState = []
      if (schId) {
        //console.log("schId2: ", schId);

        getDetailSchedule({
          url: "/medical/schedules/detail/",
          objFilters: { eaccount: idAccount, schId: schId },
          token: token,
          doAfterSuccess: (res) => {
            if (res?.results?.siteId) {
              // gettingServices(res?.results?.siteId);
              getLocationOptions({
                url: "/medical/schedules/consultingRooms/",
                objFilters: { crtyId: res.results.crtyId, siteId: res.results.siteId },
                token: token,
              });
              getServicesOptions({
                url: "/medical/schedules/serviceSiteCtype/",
                objFilters: {
                  eaccount: idAccount,
                  siteId: res?.results?.siteId,
                  doctorId: data.doctorId,
                  modality:data.modality
                },
                token: token,
                doAfterSuccess: () => {
                  res.results.services.forEach((e) => {
                    arrServices.push({ srvId: e.srvId, ctypeId: e.ctypeId }); //AQUÍ
                    srvAttGrp.push({ srvId: e.srvId, attGroupId: e.attGroupId });
                    srvIds.push(`${e.srvId}${e.ctypeId}`);
                    arrIdString.push(parseInt(e.ctypeId));
                    srvArrState.push({
                      maxTime: e.maxDate,
                      srvId: e.srvId,
                      srvIdSe: `${e.srvId}${e.ctypeId}`,
                      srvName: `${e.srvName} - ${e.ctypeName}`,
                      ctypeId: e.ctypeId,
                      attGroupId: e.attGroupId,
                    });
                  });

                  res.results.attGroup.forEach((e) => {
                    attGroupState.push({
                      percentage: e.percentage,
                      attGroupId: e.attGroupId,
                      attGroupName: e.attGroupName,
                    });
                  });

                  setData({
                    eaccount: idAccount,
                    schName: res?.results?.schName,
                    schDescription: res?.results?.schDescription,
                    schExtraQuota: res?.results?.schExtraQuota,
                    allowExtraQuota: res?.results?.allowExtraQuota === 0 ? false : true,
                    siteId: res?.results?.siteId,
                    typeConRoomId: res?.results?.crtyId,
                    conRoomId: res?.results?.conRoomId,
                    modality: res?.results?.modality.modalityTag,
                    beginsAt: res?.results?.beginsAt,
                    endsAt: res?.results?.endsAt,
                    dow: res?.results?.dow,
                    auxName: res?.results?.auxName,
                    // attGroup: [],
                    doctorId: idDoctor,
                    userId: userId,
                    schColor: res?.results?.schColor,
                    servicesId: srvIds,
                    services: arrServices,
                    servicesAttGroup: srvAttGrp,
                  });

                  setServicesRow({
                    srvIdSe: "",
                    srvId: "",
                    srvName: "",
                    maxTime: "",
                    ctypeId: "",
                    attGroupId: "",
                    srvArr: srvArrState,
                  });

                  getCareGroupOptions({
                    url: "/medical/attentionGroup/",
                    objFilters: { eaccount: idAccount, status: "enabled", ctypeId: arrIdString },
                    token: token,
                    doAfterSuccess: () => { },
                  });

                  setCareGroupRow({ attGroupId: "", attGroupName: "", percentage: "", attGroup: attGroupState });
                },
              });
            } else {
              return message("info", "No se ha podido obtener la información de la agenda");
            }
          },
        });
      }
    }

    getSiteOptions({
      url: "/medical/generals/site/",
      objFilters: { eaccount: idAccount, modality: data.modality, status: "enabled" },
      token: token,
    });
    getTypeOptions({
      url: "/medical/schedules/consultingRoomType/",
      objFilters: { eaccount: idAccount, status: "enabled" },
      token: token,
    });

    getDaysOptions({
      url: "/medical/schedules/dow/",
      //   objFilters: data,
      token: token,
    });





    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, idDoctor]);

  const gettingSite = (e) => {
    getLocationOptions({
      url: "/medical/schedules/consultingRooms/",
      objFilters: { crtyId: e, siteId: data.siteId },
      token: token,
    });
  };

  const gettingServices = (e) => {
    getServicesOptions({
      url: "/medical/schedules/serviceSiteCtype/",
      objFilters: {
        eaccount: idAccount,
        siteId: e,
        doctorId: data.doctorId,
        modality:data.modality
      },
      token: token,
    });
  };

  const gettingCareGroup = (e) => {
    getCareGroupOptions({
      url: "/medical/attentionGroup/",
      objFilters: { eaccount: idAccount, status: "enabled", ctypeId: e },
      token: token,
    });
  };

  useEffect(() => {
    setData({ ...data, siteId: "", typeConRoomId: "", conRoomId: "", auxName: "" });
    setTrigger(trigger + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.modality]);

  // ANCHOR ADD
  const addCareGroup = (index) => {
    let validations = validateEmptyString([{ field: careGroupRow.attGroupId, message: "El grupo de atención es obligatorio" }]);
    let careGroupValidation = careGroupRow.attGroup.find((care) => care.attGroupId === careGroupRow.attGroupId)
      ? message("info", "Grupo de atención repetido", "El grupo de atención elegido ya se encuentra seleccionado")
      : false;
    if (validations.valid && careGroupValidation === false) {
      careGroupRow?.attGroup?.push({
        percentage: "",
        attGroupId: careGroupRow.attGroupId,
        attGroupName: careGroupRow.attGroupName,
      });
      setCareGroupRow({ ...careGroupRow, attGroupId: "", attGroupName: "" });
    }

  };
  const addService = (index) => {
    let validations = validateEmptyString([{ field: servicesRow.srvIdSe, message: "El servicio es obligatorio" }]);
    let servicesValidation = servicesRow.srvArr.find((srv) => srv.srvIdSe === servicesRow.srvIdSe)
      ? message("info", "Servicio repetido", "El servicio elegido ya se encuentra seleccionado")
      : false;
    if (validations.valid && servicesValidation === false) {
      servicesRow?.srvArr?.push({
        maxTime: "",
        srvId: servicesRow.srvId,
        srvIdSe: servicesRow.srvIdSe,
        srvName: servicesRow.srvName,
        ctypeId: servicesRow.ctypeId,
        attGroupId: servicesRow.attGroupId,
      });
      onChangeServices()
      setServicesRow({ ...servicesRow, srvIdSe: "", srvName: "" });
    }
  };

  // ANCHOR ONCHANGE SERVICES ID
  const onChangeServices = (e) => {
    // setCurrentAlternatives(e);
    let arrIdEnd = [];
    let arrIdString = [];
    let arrSrv = [];
    let arrIdSrv = [];
    let arrAttGroup = [];
    // let attGroup = []; 
    if (servicesRow.srvArr.length > 0) {
      servicesRow.srvArr.forEach((elem) => {
        arrIdEnd.push(elem.srvIdSe);
        arrIdString.push(parseInt(elem.ctypeId));
        arrIdSrv.push(parseInt(elem.srvId));
        arrAttGroup.push({ srvId: parseInt(elem.srvId), attGroupId: parseInt(elem.attGroupId) });
        arrSrv.push({ srvId: parseInt(elem.srvId), ctypeId: parseInt(elem.ctypeId) });
      });
    }
    setData({ ...data, servicesId: arrIdEnd, services: arrSrv, servicesAttGroup: arrAttGroup });
    gettingCareGroup(arrIdString);
  };


  const deleteItem = (item) => {
    careGroupRow.attGroup.splice(item, 1);
  };
  const deleteService = (item) => {
    // if (isEditing){
    let dataSrvArr = data?.services
    let dataAttGrArr = data?.servicesAttGroup
    dataSrvArr?.splice(item, 1);
    dataAttGrArr?.splice(item, 1);
    setData({ ...data, services: dataSrvArr, servicesAttGroup: dataAttGrArr })
    // }


    let attGroupFilter = careGroupRow.attGroup.find((e) => e.attGroupId === servicesRow.srvArr[item].attGroupId)
    let attGroupSrv = servicesRow?.srvArr?.filter((e) => e.attGroupId === attGroupFilter?.attGroupId)
    let newAttGroup;
    if (attGroupSrv.length === 1) {
      newAttGroup = careGroupRow.attGroup.findIndex((e) => e.attGroupId === servicesRow.srvArr[item].attGroupId)
      deleteItem(newAttGroup)
    }
    servicesRow.srvArr.splice(item, 1);
    let arrIdString = [];
    if (servicesRow.srvArr.length > 0) {
      servicesRow.srvArr.forEach((elem) => {
        arrIdString.push(parseInt(elem.ctypeId));
      });
    }
    gettingCareGroup(arrIdString);

    if (servicesRow.srvArr.length === 0) {
      setCareGroupRow({ ...careGroupRow, attGroup: [], attGroupId: "" });
      setTriggerSelect(triggerSelect + 1)

    }
  };

  const percentageValidation = (item) => {
    let final = 0;
    let status;
    careGroupRow.attGroup.forEach((item) => {
      final = final + parseInt(item.percentage);

      return final;
    });
    if (final === 100) {
      status = true;
    } else {
      message("info", "Verifique porcentajes de grupos de atención", "La sumatoria de los porcentajes debe ser igual a 100%");
      status = false;
    }

    return status;
  };
  const extraQuotaValidation = () => {
    let validationCareGroup =
      careGroupRow.attGroup.length > 0
        ? true
        : message("info", "Añada un grupo de atención", "Debe añadir como mínimo un (1) grupo de atención");

    if (validationCareGroup && percentageValidation() === true) {
      ExtraQuotaValidationMethod({
        url: "/medical/schedules/allowsExtraQuota/",
        method: "POST",
        body: {
          eaccount: idAccount,
          siteId: data.siteId,
          doctorId: idDoctor,
          attGroup: careGroupRow.attGroup,
        },
        token: token,
        doAfterSuccess: (info) => {
          setData({ ...data, allowExtraQuota: true });
          message("info", "Información", info.message);
        },
        doAfterException: (error) => {
          setData({ ...data, allowExtraQuota: false });
          message("info", "Información", error.message);
        },
      });
    }
  };
  const modalitySwitch = () => {
    if (!isEditing){
      if (data.modality === "tmed") {
      setData({ ...data, modality: "on_site" });
    } else {
      setData({ ...data, modality: "tmed" });
    }
    } else {
      message("info", "No es posible cambiar la modalidad")
    }
    
  };
  const onChangeDays = (e) => {
    if (isEditing) {
      setData({ ...data, dow: e.value });
    } else {
      let arrIdEnd = [];
      if (e.length > 0) {
        e.forEach((elem) => {
          arrIdEnd.push(parseInt(elem.value));
        });
      }
      setData({ ...data, dow: arrIdEnd });
    }
  };

  // const amountOnChangeInput = (e) => {
  //   setData({ ...data, amount: e });
  //   getFinalValue(e);
  // };

  // ANCHOR POST DATA
  const createSchedule = () => {
    let validations = validateEmptyString([
      { field: data.schColor, message: "El campo 'Color' es obligatorio" },
      { field: data.endsAt, message: "El campo 'Hasta' es obligatorio" },
      { field: data.beginsAt, message: "El campo 'Horario desde' es obligatorio" },
      { field: data.conRoomId, message: "El campo 'Lugar' es obligatorio" },
      { field: data.typeConRoomId, message: "El campo 'Tipo' es obligatorio" },
      { field: data.siteId, message: "El campo 'Sede' es obligatoria" },
      { field: data.schDescription, message: "La descripción es obligatoria" },
      { field: data.schName, message: "El nombre de la agenda es obligatorio" },
    ]);

    let copy = careGroupRow.attGroup;
    careGroupRow.attGroup.forEach((e, i) => {
      copy[i].services = [];
      data.servicesAttGroup.forEach((data) => {
        if (data.attGroupId === e.attGroupId) {
          copy[i].services.push(data.srvId);
        }
        setCareGroupRow({ ...careGroupRow, attGroup: copy });
      });
    });

    let arrSrv = []
    if (servicesRow.srvArr.length > 0) {
      servicesRow.srvArr.forEach((elem) => {
        arrSrv.push({ srvId: parseInt(elem.srvId), ctypeId: parseInt(elem.ctypeId), maxDate: elem.maxTime });
      });
    }
    setData({ ...data, services: arrSrv });


    // let validationMaxTime;
    // servicesRow.srvArr.forEach((e)=>{
    //   validationMaxTime = e.maxTime === "" ? message("info", "Campos vacíos", "El campo 'Fecha hasta' es obligatorio en la sección de servicios") :  true 
    //  })

    let validationDow = isEditing ? data.dow !== "" ? true :
      message("info", "Campos vacíos", "El campo 'Repetición' es obligatorio")
      : data.dow.length > 0 ? true : message("info", "Campos vacíos", "El campo 'Repetición' es obligatorio");

    let validationCareGroup =
      careGroupRow.attGroup.length > 0 ? true : message("info", "Campos vacíos", "Los grupos de atención son obligatorios");

    // if (validations.valid && validationCareGroup === true && validationDow === true && percentageValidation() === true && validationMaxTime ) {
    if (validations.valid && validationCareGroup === true && validationDow === true && percentageValidation() === true) {
      Swal.fire({
        icon: "warning",
        title: "¿Está seguro?",
        text: isEditing ? `Se editará la agenda "${data.schName}"` : `Se creará la agenda "${data.schName}"`,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Si, Continuar",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          createScheduleMethod({
            url: "/medical/schedules/",
            method: isEditing ? "PUT" : "POST",
            // body: { ...data, attGroup: careGroupRow.attGroup },
            body: isEditing ?
              {
                schId: schId,
                "eaccount": data.eaccount,
                "schName": data.schName,
                "schDescription": data.schDescription,
                "schExtraQuota": data.schExtraQuota,
                "allowExtraQuota": data.allowExtraQuota,
                "siteId": data.siteId,
                "typeConRoomId": data.typeConRoomId,
                "conRoomId": data.conRoomId,
                "modality": data.modality,
                "beginsAt": data.beginsAt,
                "endsAt": data.endsAt,
                "dow": data.dow,
                "doctorId": data.doctorId,
                "userId": data.userId,
                "schColor": data.schColor,
                "services": arrSrv,
                "auxName": data.auxName,
                "attGroup": careGroupRow.attGroup,

              }
              :
              {
                "eaccount": data.eaccount,
                "schName": data.schName,
                "schDescription": data.schDescription,
                "schExtraQuota": data.schExtraQuota,
                "allowExtraQuota": data.allowExtraQuota,
                "siteId": data.siteId,
                "typeConRoomId": data.typeConRoomId,
                "conRoomId": data.conRoomId,
                "modality": data.modality,
                "beginsAt": data.beginsAt,
                "endsAt": data.endsAt,
                "dow": data.dow,
                "doctorId": data.doctorId,
                "userId": data.userId,
                "schColor": data.schColor,
                "services": arrSrv,
                "auxName": data.auxName,
                "attGroup": careGroupRow.attGroup,
              }

            ,
            token: token,
            doAfterSuccess: (info) => {
              if (isEditing) { closeTab() } else {
                setData({
                  eaccount: idAccount,
                  schName: "",
                  schDescription: "",
                  schExtraQuota: "",
                  allowExtraQuota: false,
                  siteId: "",
                  typeConRoomId: "",
                  conRoomId: "",
                  modality: "on_site",
                  beginsAt: "",
                  endsAt: "",
                  dow: [],
                  attGroup: [],
                  doctorId: idDoctor,
                  userId: userId,
                  schColor: "",
                  servicesId: [],
                  services: [],
                  auxName: "",
                });
                setCareGroupRow({ ...careGroupRow, attGroupId: "", attGroupName: "", percentage: "", attGroup: [] });
                setServicesRow({
                  srvIdSe: "",
                  srvId: "",
                  srvName: "",
                  maxTime: "",
                  ctypeId: "",
                  attGroupId: "",
                  srvArr: [],
                });
                message("info", "Información", info.message);
              }

            },
            doAfterException: (error) => {
              message("info", "Información", error.message);
            },
          });
        }
      });
    }
  };
  return (
    <div className="d-flex flex-column h-100 w-100 justify-content-between">
      {(createScheduleLoader ||
        extraQuotaValidationLoader ||
        dayOptionsLoader ||
        locationOptionsLoader ||
        typeOptionsLoader ||
        siteOptionsLoader ||
        servicesOptionsLoader ||
        detailScheduleLoader ||
        careGroupOptionsLoader) &&
        loader}
      <OrdGenericTemplate
        showBackArrow={true}
        backArrowAction={() => {
          closeTab();
        }}
        title={isEditing ? "Editar agenda" : "Nueva agenda"}
        className="px-4 my-2 w-90"
        // titleSize={12}
        style={{ overflow: "auto", height: "min-content" }}
        titleStyle={{ marginLeft: "1rem", fontSize: "32px" }}
      >
        <div className="mx-4">
          <div className={`${styles.app_back_search} my-2`}>
            <Row>
              <Col xs={8}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Nombre de la agenda</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${styles.tlnTextGray}`}
                    type="text"
                    disabled={isEditing ? true : false}
                    placeholder="Escriba..."
                    value={data.schName}
                    onChange={(e) => {
                      setData({ ...data, schName: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={4} className="align-self-end">
                <div
                  className="d-flex align-self-end mb-3 pb-1"
                // styles={{marginBotton}}
                >
                  <AntSwitch
                    className="mr-2"
                    defaultChecked
                    // key={trigger + "Ant"}
                    checked={data.modality === "on_site" ? false : true}
                    // onChange={(e) => handleChangeStatus(e.target.checked)}
                    onClick={() => modalitySwitch()}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <b className={`${styles.f14} ${styles.tlnTextGray}   `}>Telemedicina</b>
                </div>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="fName">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <span className="text-start">&nbsp;Descripción</span>
                </Form.Label>
                <textarea
                  value={data?.schDescription}
                  placeholder={"Escribir..."}
                  rows="40"
                  cols="50"
                  style={{ height: "4rem" }}
                  className={`text-secondary ord-roundInput w-100 ${styles.outlineNone} ${styles.tlnTextGray}`}
                  onChange={(e) =>
                    setData({
                      ...data,
                      schDescription: e.target.value,
                    })
                  }
                ></textarea>
              </Form.Group>
            </Row>
            <Row>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Sede</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={siteOptions}
                    className="text-secondary "
                    // isDisabled={data.modality === "tmed" ? true : false}
                    placeholder={"Seleccione"}
                    value={siteOptions.find((x) => x.value === data.siteId)}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setData({ ...data, siteId: e.value, typeConRoomId: "", conRoomId: "", services: [], servicesId: [] });
                      setCareGroupRow({ ...careGroupRow, attGroup: [], attGroupId: "", });
                      setServicesRow({ ...servicesRow, srvArr: [], srvIdSe: "" });
                      gettingServices(e.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Tipo</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={typeOptions}
                    // isDisabled={data.modality === "tmed" ? true : false}
                    className="text-secondary "
                    placeholder={"Seleccione"}
                    value={typeOptions.find((x) => x.value === data.typeConRoomId)}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setData({ ...data, typeConRoomId: e.value });
                      gettingSite(e.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Lugar</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={locationOptions}
                    // isDisabled={data.modality === "tmed" ? true : false}
                    className="text-secondary "
                    placeholder={"Seleccione"}
                    value={locationOptions.find((x) => x.value === data.conRoomId)}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setData({ ...data, conRoomId: e.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Auxiliar</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${styles.tlnTextGray}`}
                    // disabled={isEditing ? true : false}
                    type="text"
                    placeholder="Escriba..."
                    value={data?.auxName}
                    onChange={(e) => {
                      setData({ ...data, auxName: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                {/* ANCHOR SERVICIOS */}
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Servicios</span>
                  </Form.Label>
                  {/* <Select noOptionsMessage={() => 'No hay datos'}
                    isMulti
                    onChange={(e) => {
                      onChangeServices(e);
                    }}
                    className={`${styles.heightAutoSelectApp} text-sm`}
                    styles={ordCustomSelect}
                    options={servicesOptions}
                    placeholder="Seleccione"
                    // className=" "
                    value={servicesOptions.filter((item) => data.servicesId.includes(item.value))}
                  /> */}
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={servicesOptions}
                    className="text-secondary "
                    placeholder={"Seleccione"}
                    value={servicesOptions.find((x) => x.value === servicesRow.srvIdSe)}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setServicesRow({ ...servicesRow, srvIdSe: e.value, srvName: e.label, ctypeId: parseInt(e.extra), srvId: parseInt(e.extra2), attGroupId: parseInt(e.extra3) });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={2} className="d-flex align-self-end ">
                <button className={`d-flex align-self-end mb-3 ${styles.appButtonAdd}`} onClick={() => addService()}>
                  <b className={`${styles.f16} ${styles.textOrangeHover}`}>Añadir</b>
                  <img alt="add" src={addIcon} className={`px-2 ${styles.AddIconHover}`} />
                </button>
              </Col>

            </Row>
            {servicesRow.srvArr.length > 0 &&
              servicesRow.srvArr.map((srv, index) => {
                return (
                  <Row className="my-2">
                    <div>
                      <div className={`bg-white py-2 d-flex rounded ${styles.bdRadius10} align-items-center`}>
                        <Col xs={8} className="d-flex">
                          <span className={`m-0 ${styles.ordDarkBlueText} ${styles.f16} px-4`}
                          // style={{whiteSpace: "nowrap"}}
                          > {srv.srvName}</span>
                        </Col>
                        <Col xs={3}>
                          <Form.Group className=" text-start" controlId="dateH">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                              <span> &nbsp;Fecha hasta:</span>&nbsp;&nbsp;
                            </Form.Label>
                            <input
                              className={` ${styles.appInputLine}  ${styles.tlnTextGray}`}
                              type="date"
                              placeholder=""
                              min={today()}
                              value={servicesRow?.srvArr[index].maxTime}
                              // value={servicesRow.srvArr[index].maxTime}
                              onChange={(e) => {
                                let srvRow = servicesRow?.srvArr
                                srvRow[index].maxTime = e.target.value;
                                setServicesRow({ ...servicesRow, srvArr: srvRow });
                                // }
                              }}
                            // onClick={(e)=>{
                            //   servicesRow.srvArr[index].maxTime = e.target.value;
                            // }}
                            // onBlur={(e) => {
                            //   let srvRow = servicesRow.srvArr
                            //   // servicesRow.srvArr[index].maxTime = e.target.value;
                            //   srvRow.srvArr[index].maxTime = e.target.value;
                            //   setServicesRow({ ...servicesRow, srvArr: srvRow });
                            // }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={1} className="d-flex justify-content-end">
                          <button onClick={() => deleteService(index)} className="px-2">
                            <img alt="delete" src={deleteIcon} width="25px" />
                          </button>
                        </Col>
                      </div>
                    </div>
                  </Row>
                );
              })}
          </div>
          <div className={`${styles.app_back_search} my-4`}>
            <Row>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Grupos de atención</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={careGroupOptions}
                    className="text-secondary "
                    placeholder={"Seleccione"}
                    value={careGroupOptions.find((x) => x.value === careGroupRow.attGroupId)}
                    styles={ordCustomSelect}
                    key={'attGroup' + triggerSelect}
                    onChange={(e) => {
                      setCareGroupRow({ ...careGroupRow, attGroupId: e.value, attGroupName: e.label });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={2} className="d-flex align-self-end ">
                <button className={`d-flex align-self-end mb-3 ${styles.appButtonAdd}`} onClick={() => addCareGroup()}>
                  <b className={`${styles.f16} ${styles.textOrangeHover}`}>Añadir</b>
                  <img alt="add" src={addIcon} className={`px-2 ${styles.AddIconHover}`} />
                </button>
              </Col>
              <Col xs={3}></Col>
            </Row>
            {careGroupRow.attGroup.length > 0 &&
              careGroupRow.attGroup.map((care, index) => {
                return (
                  <Row className="my-2">
                    <div>
                      <div className={`bg-white py-2 d-flex rounded ${styles.bdRadius10} align-items-center`}>
                        <Col xs={4}>
                          <span className={`m-0 ${styles.ordDarkBlueText} ${styles.f16} px-4`}> &nbsp;{care.attGroupName}</span>
                        </Col>
                        <Col xs={4}>
                          <Form.Group className=" text-start" controlId="fPercentage">
                            {/* <input
                              className={` ${styles.appInputLine}  ${styles.tlnTextGray}`}
                              type="number"
                              placeholder="Porcentaje"
                              // value={careGroupRow.percentage}
                              onChange={(e) => {
                                careGroupRow.attGroup[index].percentage = e.target.value;
                                // setCareGroupRow({ ...careGroupRow, percentage: e.target.value });
                              }}
                            /> */}
                            <div className="input-group ">
                              <NumberFormat
                                className={` ${styles.appInputLine}  ${styles.tlnTextGray}`}
                                allowNegative={false}
                                id="percentage"
                                thousandsGroupStyle="thousand"
                                thousandSeparator
                                value={careGroupRow.attGroup[index].percentage}
                                onValueChange={(e) => {
                                  // amountOnChangeInput(e.value);
                                  careGroupRow.attGroup[index].percentage = e.value;

                                }}
                                maxLength={12}
                              ></NumberFormat>
                              <div className={` ${styles.appInputLine}  ${styles.tlnTextGray}`}>
                                <span className=""> &nbsp;%</span>
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col xs={4} className="d-flex justify-content-end">
                          <button onClick={() => deleteItem(index)} className="px-2">
                            <img alt="delete" src={deleteIcon} width="25px" />
                          </button>
                        </Col>
                      </div>
                    </div>
                  </Row>
                );
              })}
          </div>
          <div className={`${styles.app_back_search} my-4`}>
            <Row>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Horario desde</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput `}
                    type="time"
                    min="08:00"
                    max="18:00"
                    placeholder="Escriba..."
                    value={data.beginsAt}
                    onChange={(e) => {
                      setData({ ...data, beginsAt: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Hasta</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput `}
                    type="time"
                    min="08:00"
                    max="18:00"
                    placeholder="Escriba..."
                    value={data.endsAt}
                    onChange={(e) => {
                      setData({ ...data, endsAt: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <span> &nbsp;Repetición</span>
                  </Form.Label>
                  {isEditing ?
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={daysOptions}
                      className="text-secondary "
                      // isDisabled={data.modality === "tmed" ? true : false}
                      placeholder={"Seleccione"}
                      value={daysOptions.find((x) => x.value === data.dow)}
                      styles={ordCustomSelect}
                      onChange={(e) => {
                        onChangeDays(e);
                      }}
                    />
                    :
                    <Select noOptionsMessage={() => 'No hay datos'}
                      isMulti
                      onChange={(e) => {
                        onChangeDays(e);
                      }}
                      styles={customSelectMultiGeneral}
                      options={daysOptions}
                      placeholder="Seleccione"
                      className="text-sm "
                      value={daysOptions.filter((item) => data.dow.includes(item.value))}
                    />
                  }

                </Form.Group>
              </Col>
            </Row>
            <Row className="my-2 ">
              <Col xs={3} className="d-flex align-self-center ">
                <div class="container d-flex flex-column text-start align-self-center w-100">
                  <label for="inputTypeColor" className={`${styles.appInputColorExa} ${styles.ordDarkBlueText} ${styles.f14} mb-1`}>
                    Color
                  </label>
                  <input
                    type="color"
                    id="inputTypeColor"
                    value={data.schColor}
                    onChange={(e) => {
                      setData({ ...data, schColor: e.target.value });
                    }}
                  />
                </div>
              </Col>
              <Col xs={3} className="d-flex align-self-center py-2"></Col>
              <Col xs={4} className="d-flex align-self-end py-2">
                <div className="d-flex align-items-center">
                  <div>
                    <button
                      onClick={() => {
                        extraQuotaValidation();
                      }}
                    >
                      <input
                        type="checkbox"
                        name="handleTelemedicine"
                        className={` ${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                        checked={data.allowExtraQuota}
                        onClick={(e) => { }}
                      />
                    </button>
                  </div>
                  <div className="">
                    <b className={`${styles.f14} ${styles.tlnTextGray}  align-self-end  px-2 `}>Permite cupos extras</b>
                  </div>
                </div>
              </Col>
              {data.allowExtraQuota && (
                <Col xs={2}>
                  <Form.Group className=" text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <span> &nbsp; No. de cupos</span>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={quotaExtraOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      value={quotaExtraOptions.find((x) => x.value === data.schExtraQuota)}
                      styles={ordCustomSelect}
                      onChange={(e) => {
                        setData({ ...data, schExtraQuota: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </OrdGenericTemplate>
      <div
        style={{
          // width: "calc(100% - 400px)",
          width: "100%",
          padding: " 1rem 2rem",
          position: "relative",
          borderTop: "1px solid #",
          background: "#fff",
          marginRight: "1rem",
          // bottom: "0",
        }}
        className="d-flex px-4  justify-content-end align-items-end align-self-end border-top bg-white"
      >
        <div style={{ width: "90%" }}>
          <div
            className="px-4 w-90 d-flex justify-content-end align-items-end align-self-end mb-2 "
            style={{ width: "90%", marginRight: "1rem" }}
          >
            <button
              onClick={() => closeTab()}
              //   closePersonalDrawer()}
              className={`mr-2 btn ${styles.ordBtnSecondary}`}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                setData({ ...data, attGroup: careGroupRow.attGroup });

                createSchedule();
              }}
              className={`${styles.ordBtnPrimary} btn`}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSchedule;
