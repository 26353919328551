import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import AddBlue from "../../assets/img/icons/AddBlue.svg";
import ADD from "../../assets/img/icons/ADD.svg";
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
export const ButtonAddClearBlueHover = ({ title, firstImage, hoverImage, hoverTextDecoration, hoverTextColor, onClick, size, className, style }) => {
    const [hoverImg, setHoverImg] = useState({
        add: firstImage || ADD,
        textColor: "text-secondary",
        textDecoration: ""
    })
    return (
        <Col xs={size || 12}
            onClick={() => onClick()}
            onMouseEnter={() => setHoverImg({
                add: hoverImage || AddBlue,
                textColor: tableStyles.ordClearBlueText || hoverTextColor,
                textDecoration: hoverTextDecoration
                // || "text-decoration-underline"
            })}
            onMouseLeave={() => setHoverImg({
                add: firstImage || ADD,
                textColor: hoverTextColor || "text-secondary",
                textDecoration: ""
            })}
            style={style}
            className={`${hoverImg.textColor} mb-3 ${tableStyles.f12} ${hoverImg.textDecoration} cursorPointer ${className}`}
        >
            <img width="18" alt="icon" className="" src={hoverImg.add} ></img>
            &nbsp;
            {title || "Añadir"}
        </Col>
    )
}
