
import GenericTableNew from "../Layouts/GenericTableNew";
import {  useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../Layouts/customTabs.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import genericTableStyles from "../Layouts/genericTable.module.scss";
import ModalNew from "../Layouts/ModalNew";
import {customSelectNew, customSelectNewDark} from "../Layouts/react-select-custom";
import Select from "react-select";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import detailStyles from "./PODetail.module.scss";
import checkboxTrue from "../../assets/img/icons/checkboxTrue.svg";
import False from "../../assets/img/icons/False.svg";

function ModalProductsAut (props){
    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const [trigger, setTrigger] = useState(1);


    
    //seccion "Productos serán autorizados" ____________________________________________________________________
    const renderHeadersAuthorization = [
        <th key={`item1`}>Descripción</th>,
        <th key={`item2`}>Proveedor</th>,
        <th key={`item3`}>Cant.</th>,
        <th key={`item4`}>Valor Total</th>,
    ]

    

    const renderAuthorization = ()=>{
        let table =[];

        // if (Array.isArray(props.filterCompra)) {
        //     table = props.filterCompra.map( x => {
        //         return <tr key={"ReqModalConsolidateList"+x.id_article} className={`hover-table-row`}>
        //             <td className={`col-md-1`}>
        //                 {x.id_article}
        //             </td>
        //             <td className={`col-md-4`}>
        //                 <p className="m-0 text-start">
        //                     {x.article?.description}
        //                 </p>
        //             </td>
        //             <td className={`col-md-1`}>
        //                 {x.qty_article}
        //             </td>
        //             <td className={`col-md-1`}>
        //                 {x.warehousearticle?.min_stock}
        //             </td>
        //             <td className={`col-md-1`}>
        //                 {x.warehousearticle?.max_stock}
        //             </td>
        //             <td className={`col-md-1`}>
        //                 {x.warehousearticle?.available}
        //             </td>
        //             <td className={`col-md-2`}>
        //                 {x.warehousearticle?.warehousename}
        //             </td>
        //         </tr>
        //     });
        //   }
        return table;
    }

   
    


    //seccion "Productos serán denegados" _________________________________________________________________________
    const renderHeadersSubasta = [
        <th key={`subasta1`}>Item</th>,
        <th key={`subasta2`}>Artículo</th>,
        <th key={`subasta3`}>Cantidad</th>,
    ]



    const noAthorization =() => {
        let table =[];

        // if (Array.isArray(props.filterSubasta)) {
        //     table = props.filterSubasta.map( x => {
        //         return <tr key={"ReqModalConsolidateList"+x.id_article} className={`hover-table-row`}>
        //             <td className={`col-md-1`}>
        //                 {x.id_article}
        //             </td>
        //             <td className={`col-md-4`}>

        //                 <p className="m-0 text-start">
        //                     {x.article?.description}
        //                 </p>
                        
        //             </td>
        //             <td className={`col-md-1`}>
        //                 {x.qty_article}
        //             </td>
        //         </tr>
        //     });
        //   }
        return table;
    }

    
    
    //render ___________________________________________________________________________________________


    return (
            <div className={`${detailStyles.container2}`}>
                
            
            {/* Productos Autorizados*/}
            <div className="d-flex">
                <img
                    src={checkboxTrue}
                    alt="check"
                    className={detailStyles.adjustIconPart2}
                />
                <p className={` ${detailStyles.textPart2}`}>
                    Los siguientes productos serán Autorizados
                </p>
            </div>

            <GenericTableNew headers={renderHeadersAuthorization}>

            </GenericTableNew>
            
            {/* Productos no autorizados */}
            <div className="d-flex">
                <img
                    src={False}
                    alt="false"
                    className={detailStyles.adjustIconPart2}
                />
                <p className={` ${detailStyles.textPart2} ${detailStyles.textPart2Orange}`}>
                    Los siguientes artículos serán denegados
                </p>
            </div>
            <GenericTableNew headers={renderHeadersSubasta}>
                
            </GenericTableNew>
            
            
        </div>
    )

}



export default ModalProductsAut;