import React from "react";
// import Filter from "../../assets/img/icons/filtro.svg";
// import Add from "../../assets/img/icons/agregar.svg";
// import Export from "../../assets/img/icons/exportar.svg";
import Gear from "../../assets/img/icons/engraneGris.svg";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";

// import GearWhite from "../../assets/img/icons/engraneBlanco.svg";
// import GearGray from "../../assets/img/icons/engraneGris.svg";
// import EditIcon from "../../assets/img/icons/editar.svg";
// import EraseICon from "../../assets/img/icons/canecaGris.svg";
// import DeactiveIcon from "../../assets/img/icons/puntoRojo.svg"
// import ActiveIcon from "../../assets/img/icons/puntoVerde.svg"
// import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg"
import Popup from "reactjs-popup";
//import "reactjs-popup/dist/index.css";
import styles from "./customPopup.module.scss";

function CustomPopupExtend(props) {
  const hidePopupExtend = () => {
    // const ref1 = document?.querySelector('.popup-content')
    const ref = document?.querySelector("#popup-root");
    ref?.remove();
  };

  return (
    <Popup
      trigger={
        <span
          style={
            props?.center
              ? { cursor: "pointer", textAlign: "center" }
              : { cursor: "pointer" }
          }
          className={
            !!props.triggerDisabled
              ? styles.disabled
              : !!props.noHover
                ? ""
                : `${tableStyles.colorNewOrderHover}`
          }
          disabled={!!props.triggerDisabled}
        >
          <img
            src={!!props.triggerSrc ? props.triggerSrc : Gear}
            alt="configuracion"
            className={`icons-gear ${props.triggerClass}`}
            height="20px"
          />
        </span>
      }
      position={!!props.position ? props.position : "left top"}
      contentStyle={{ zIndex: 5000 }}
      //position={!!props.position? props.position: "right center"}
      styles={styles.popupContent}
      // nested
    >
      <ul className={`${styles.popUpUl}  hoverPointer`}>
        {props.showDetails && (
          <li
            onClick={() => {
              props.showDetailsEvent();
              hidePopupExtend();
            }}
            className={styles.popUpShowDetails}
          >
            {props.textDetails ? props.textDetails : "Ver detalle"}
          </li>
        )}
        {props.showEnable ? (
          <li
            onClick={() => {
              props.enableClickEvent();
              hidePopupExtend();
            }}
            className={
              props.isEnabled ? styles.popUpLiActiveRed : styles.popUpLiActive
            }
          >
            {props.isEnabled
              ? props.enableText
                ? props.enableText
                : "Deshabilitar"
              : props.disabledText
                ? props.disabledText
                : "Habilitar"}
          </li>
        ) : null}

        {props.showEdit ? (
          <li
            onClick={() => {
              hidePopupExtend();
              props.editClickEvent();
            }}
            className={
              props.iconUpdate ? styles.popUpLiUpdate : styles.popUpLiEdit
            }
          >
            {props.editText ? props.editText : "Editar"}
          </li>
        ) : null}

        {props.showPrint && (
          <li
            className={styles.popUpLiUndo}
            onClick={() => {
              props.printEvent();
              hidePopupExtend();
            }}
          >
            {props.printText ? props.printText : "Imprimir"}
          </li>
        )}

        {/* is you need to delete in popup action */}
        {props.showDelete && (
          <li
            className={styles?.popUpLiDelete}
            onClick={() => {
              props?.deleteEvent();
              hidePopupExtend();
            }}
          >
            {props?.deleteText ? props?.deleteText : "Eliminar"}
          </li>
        )}

        {props.showAddDataSheet && (
          <li
            className={styles.popUpLiDataSheet}
            onClick={() => {
              props.addDataSheetEvent();
              hidePopupExtend();
            }}
          >
            {props.addDataSheetText ? props.addDataSheetText : "Ficha técnica"}
          </li>
        )}

        {!!props.checkEvent && (
          <li
            className={styles.popUpLiCheck}
            onClick={() => {
              props.checkEvent();
              hidePopupExtend();
            }}
          >
            {props.checkText ? props.checkText : "Elegir Todo"}
          </li>
        )}

        {!!props.purchaseEvent && (
          <li
            className={styles.popUpLiPurchase}
            onClick={() => {
              props.purchaseEvent();
              hidePopupExtend();
            }}
          >
            {props.purchaseText ? props.purchaseText : "Ver OC generada"}
          </li>
        )}

        {!!props.dealEvent && (
          <li
            className={styles.popUpLiDeal}
            onClick={() => {
              props.dealEvent();
              hidePopupExtend();
            }}
          >
            {props.dealText ? props.dealText : "Convenio"}
          </li>
        )}

        {props.showUndo && (
          <li
            className={styles.popUpLiUndo}
            onClick={() => {
              props.undoEvent();
              hidePopupExtend();
            }}
          >
            {props.undoText ? props.undoText : "Deshacer"}
          </li>
        )}

        {props.showSing && (
          <li
            className={styles.popUpSign}
            onClick={() => {
              props.signEvent();
              hidePopupExtend();
            }}
          >
            {props.signText ? props.signText : "Firmar"}
          </li>
        )}

        {props.showRadFis && (
          <li
            className={styles.showIRadDig}
            onClick={() => {
              props.radFisEvent();
              hidePopupExtend();
            }}
          >
            {props.undoText ? props.undoText : "Rad. Fisica"}
          </li>
        )}
        {props.showRadDig && (
          <li
            className={styles.showIRadDig}
            onClick={() => {
              props.radDigEvent();
              hidePopupExtend();
            }}
          >
            {props.undoText ? props.undoText : "Rad. Digital"}
          </li>
        )}

        {props.showClone && (
          <li
            className={styles.popUpClone}
            onClick={() => {
              props.cloneEvent();
              hidePopupExtend();
            }}
          >
            {props.cloneText ? props.cloneText : "CLonar"}
          </li>
        )}
        {props.showRegister && (
          <li
            className={styles.popUpRegister}
            onClick={() => {
              props.registerEvent();
              hidePopupExtend();
            }}
          >
            {props.registerText ? props.registerText : "Registrar"}
          </li>
        )}
        {props.showSign && (
          <li
            className={styles.popUpLiSign}
            onClick={() => {
              props.signEvent();
              hidePopupExtend();
            }}
          >
            {props.signText ? props.signText : "Firmar"}
          </li>
        )}
        {props.showOtroSi && (
          <li
            className={styles.popUpLiOtroSi}
            onClick={() => {
              props.otroSiEvent();
              hidePopupExtend();
            }}
          >
            {props.otroSi ? props.otroSi : "Agregar otrosí"}
          </li>
        )}
        {props.showEmployeeRe && (
          <li
            className={styles.popUpLiEmployeeRe}
            onClick={() => {
              props.employeeReEvent();
              hidePopupExtend();
            }}
          >
            {props.employeeRe ? props.employeeRe : 'Sustitución patronal'}
          </li>
        )}
        {props.sendToAccount && (
          <li
            className={`${styles.sendToAccountTextStyles} ${tableStyles.f13}`}
            onClick={() => {
              props.sendToAccountTextEvent();
              hidePopupExtend();
            }}
          >
            {props.sendToAccountText ?? "Agregar otrosí"}
          </li>
        )}

        {props?.extraButtons &&
          props?.extraButtons?.map((button, index) => {
            return button?.class
              ? button?.event && (
                  <li
                    key={`btn-popUp-${index}`}
                    className={button?.class}
                    onClick={() => button?.event()}
                  >
                    {button?.icon && (
                      <img src={button?.icon} alt={button?.text} />
                    )}
                    {button?.text}
                  </li>
                )
              : button?.event && (
                  <li
                    key={`btn-popUp-${index}`}
                    className={styles.popUpGeneric}
                    onClick={() => {
                      button?.event();
                      hidePopupExtend();
                    }}
                  >
                    <img src={button?.icon} alt={button?.text} />
                    {button?.text}
                  </li>
                );
          })}
      </ul>
    </Popup>
  );
}

export default CustomPopupExtend;
