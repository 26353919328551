import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { customSwaltAlert } from "../helpers/customSwaltAlert";
import { responseSwal } from "../helpers/responseSwal";

export const getCollectionsLetters = async (objFilters, token) => {
    const filters = convertFilterToString(objFilters);
    const response = await fetch(
        `${URL_GATEWAY}${API_VERSION}/receivable/collectionLetterTemplate/?${filters}`,
        {
            method: "GET",
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
        }
    );
    const data = await response.json();
    return data;
};

export const getCollectionsLettersDetail = async (objFilters, token) => {
    const filters = convertFilterToString(objFilters);
    const response = await fetch(
      `${URL_GATEWAY}${API_VERSION}/receivable/collectionLetterTemplate/?${filters}`,
      {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    );
    const data = await response.json();
    return data;
  };

export const createCollectionLetter = (data, doAfter) => async (dispatch, getState) => {
    if (!data.name || !data.signature || !data.role || !data.template) {
        return customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: "Debe llenar todos los campos",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        
    } else {
        dispatch({ type: "LOADING", payload: true })
        const token = getState().loginReducer.Authorization;
        const response = await fetch(
            `${URL_GATEWAY}${API_VERSION}/receivable/collectionLetterTemplate/`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        ).catch(() => {

             customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: "Ocurrió un error al crear la carta de cobro",
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })

        });
        dispatch({ type: "LOADING", payload: false })
        const res = await response.json();
        if (res.success) {
            if (!!doAfter) doAfter()
            return customSwaltAlert({
                icon: "success",
                title: "Creada exitosamente",
                text: `Se ha creado la carta de cobro: ${data?.name}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })
            
        } else {
            return customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: res.message,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })
        }
    }
};

export const editCollectionLetter = (data, doAfter) => async (dispatch, getState) => {
    dispatch({ type: "LOADING", payload: true })
    const token = getState().loginReducer.Authorization;
    const response = await fetch(
        `${URL_GATEWAY}${API_VERSION}/receivable/collectionLetterTemplate/`,
        {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
            },
        }
    ).catch(() => {

        customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: "Ocurrió un error al crear la carta de cobro",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
    });
    dispatch({ type: "LOADING", payload: false })
    const res = await response.json();
    if (res.success) {
        if (!!doAfter) doAfter()
        return customSwaltAlert({
            icon: "success",
            title: "Actualizada exitosamente",
            text: `Se ha actualizado la carta de cobro: ${data?.name}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        
    } else {
        return customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: res.message,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
    }
};
