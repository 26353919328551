import React from 'react'
import '../../../OrderingModule/orderingStyles.css'
import { PseudoSideBarAccordion } from '../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion';
import { useHistory } from "react-router-dom";
import { onCapitalLetter } from '../../../helpers/helpers';
import { useSelector } from 'react-redux';
export const TelemedicineHomeContent = () => {
    let history = useHistory();
    const { all_categories } = useSelector((state) => state?.loginReducer);
    const accountingModules = all_categories?.find((cat) => cat?.name === 'Telemedicina')
    const { modules } = accountingModules
    const storage = useSelector((state) => state);
    // const titles = [
    //     {
    //         title: 'Citas virtuales',
    //         content: [
    //             { subtitle: "Consultar citas", action:() => history.push('/telemedicina/ConsultarCitas/') },
    //             { subtitle: "Historial de pagos", action:() => history.push('/telemedicina/historialPagos/') }
    //             ]
    //     },
    //     // {
    //     //     title: 'Médicos y agendas',
    //     //     content: [
    //     //         { subtitle: "Médicos y agendas", action: () => history.push('/telemedicina/medicosAgendas/')}]
    //     // },
    //     {
    //         title: 'Reportes',
    //         content: [
    //             { subtitle: "Informe de citas", action: () => history.push('/telemedicina/informeCitas') },
    //         ]
    //     },
    //     {
    //         title: 'Configuración',
    //         content: [
    //             { subtitle: "Configuración", action: () => history.push('/telemedicina/configuracion/')},
    //         ]
    //     },
    // ]
    const contentDynamic = modules?.map((module) => {
        const textJoin = onCapitalLetter({text:module?.name})
        return {
            title: textJoin,
            content: module?.functionalities?.map((subModule)=>{
                let subModuleName = onCapitalLetter({text: subModule?.name})
                let returnValue = <></>
                let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === subModule?.prefix
                  )?.read
                if(permissionValidation){
                    returnValue = { subtitle: subModuleName, action: () => history.push(subModule?.url) };
                  }
                return returnValue
            })
        }
    })
    return (
        <div>
            {/* /* ----------------------------- PSEUDO-SIDEBAR--------------------------*/}
            {<PseudoSideBarAccordion accordionItems={contentDynamic} />}
        </div>
    )
}
