import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
export default function FullScreenDialog(props) {
    let { isOpen, onHide } = props
    const handleClose = () => {
        onHide(false);
    };
    return (
        <div>
            <Dialog
                fullScreen
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                {props.children || "Put your content in"}
            </Dialog>
        </div >
    );
}

{/* <FullScreenDialog
    onHide={() => setFullDialogScreen(false)}
    isOpen={fullDialogScreen.show}
>
    <RenderMedicalFormulasPdf
        pdfParams={{
            url: '/medical/pdfMedicationComboOrder/',
            id: fullDialogScreen.id,
        }}
        title={'Fórmula de medicamentos en combo'}
        miPres={{
            title: "Ola!",
            mipresAction: () => alert('mipres!')
        }}
        finalize={{
            title: "Finalizar!",
            finalizeAction: () => {
                setData({
                    combo: "",
                    observations: "",
                });
                message('success', 'Orden(es) finalizada')
                setFullDialogScreen(false)
            }
        }}
        backAction={() => {
            setData({
                combo: "",
                observations: "",
            });
            setFullDialogScreen(false)
        }}
    />
</FullScreenDialog> */}
