import React from "react";
import tableStylesScroll from "./genericTableScroll.module.scss";

function genericTableScroll(props) {
    const headercolor = () => {
        switch (props.headerColor) {
            case "clear":
                return tableStylesScroll.headerClear2;
            case "dark":
                return tableStylesScroll.headerDark;
            default:
                return "";
        }
    };
    const typeHead = () => {
        switch (props.typeHead) {
            case "1":
                return tableStylesScroll.head;
            case "2":
                return tableStylesScroll.head2;
            case "3":
                return tableStylesScroll.head3;
            case "4":
                return tableStylesScroll.head4;
            default:
                return tableStylesScroll.head;
        }
    };

    return (
        <div className={`${tableStylesScroll.table}`}>
            <div className={`${typeHead()} ${headercolor()}`}>{props.headers}</div>

            <div
                className={`${tableStylesScroll.bodycontainer}`}
                style={{ maxHeight: props.maxHeight ? props.maxHeight : 300 }}
                ref={props?.refBody}
            >
                <div className={`${tableStylesScroll.body}`}>{props.body}</div>
            </div>

            {!!props.footer && <div className={`${tableStylesScroll.foot} `}>{props.footer}</div>}

            {!!props.footer2 && <div className={`${tableStylesScroll.foot2} `}>{props.footer2}</div>}
        </div>
    );
}

export default genericTableScroll;
