import Drawer from "@mui/material/Drawer";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import calenImg from "../../assets/img/icons/adviserModul.svg";
import close from "../../assets/img/icons/appClosePatient.svg";
import approved from "../../assets/img/icons/app_aprovado.svg";
import arrowDarkGrey from "../../assets/img/icons/arrowDarkGrey.svg";
import denied from "../../assets/img/icons/closeIconBigger.svg";
import edit from "../../assets/img/icons/editPencil.svg";
import download from "../../assets/img/icons/iconDownload.svg";
import config from "../../assets/img/icons/iconGearGray.svg";
import ordBlueSearch from "../../assets/img/icons/ordBlueSearch.svg";
import people from "../../assets/img/icons/people.svg";
import personIcon from "../../assets/img/icons/PersonIcon.svg";
import questionIcon from "../../assets/img/icons/questionBlueClear.svg";
import {
  appDarkCustomSelect,
  customSelectTransparent,
  ordCustomSelect,
} from "../../components/Layouts/react-select-custom";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { AntSwitch } from "../../helpers/constants";
import { formatToRcSelect, loader } from "../../helpers/helpers";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import OrdTable from "../../OrderingModule/OrderingComponents/OrdTable";
import DrawerTable from "../Components/DrawerTable";

export const Post = (props) => {
  const { tab, attentionGroupId } = props;
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const eaccount = store.loginReducer.currentAccount.id;
  const id = attentionGroupId;
  const {
    results: reportAdvisor,
    load: loadReportAdvisor,
    trigger: getReportAdvisor,
  } = useGetMethod();

  const { results: optionsSites, trigger: getOptionsSites } = useGetMethod();
  const { results: attentionGroup, trigger: getAttentionGroup } =
    useGetMethod();
  const [detailAssistant, setDetailAssistant] = useState([]);

  const [showTable, setShowTable] = useState({
    show: false,
  });

  const { results: resultAttentionGroup, trigger: updateAttentionGroup } =
    usePostMethod();

  const [filters, setFilters] = useState({
    site: "",
    dateEnd: "",
    dateStart: "",
    eaccount: eaccount,
  });

  useEffect(() => {
    getOptionsSites({
      url: "/medical/generals/site/",
      token: token,
      objFilters: { eaccount: eaccount },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOptionsSites]);

  useEffect(() => {
    getAttentionGroup({
      url: "/medical/attentionGroup/",
      token: token,
      objFilters: { id: id, eaccount: eaccount, status: "enabled" },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setFilters({ ...filters, atgId: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setFilters({
      site: "",
      atgId: id,
      dateEnd: "",
      dateStart: "",
      eaccount: eaccount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.tabName]);

  useEffect(() => {
    setValueConfig({
      atgId: attentionGroup?.results?.id,
      dailyGoal: `${attentionGroup?.results?.dailyGoal}`,
    });
  }, [attentionGroup]);

  const generateButton = () => {
    getReportAdvisor({
      url: "/medical/reports/advisorsReport/",
      objFilters: filters,
      token: token,
      doAfterSuccess: (data) => {
        if (data?.results?.data?.length > 0) {
          setShowTable({ ...showTable, show: true });
        }
      },
    });
  };

  const formatSite = formatToRcSelect(optionsSites?.results, "siteId", "site");

  const headers = [
    {
      className: "px-2 text-start ",
    },
    {
      className: "px-2 text-start ",
    },
    {
      title: "Asesor",
      className: "px-2 text-start col-3",
    },
    {
      title: "Días",
      className: "px-2 text-center ",
    },
    {
      title: "Tickets",
      className: "px-2 text-center  col-1",
    },
    {
      title: "Meta acumulada",
      className: "px-2 text-center col-1",
    },
    {
      title: "Tiempo total",
      className: "mx-4 text-center  ",
    },
    {
      title: "Tiempo Max",
      className: "mx-2 text-center  ",
    },
    {
      title: "Tiempo Min",
      className: "mx-2 text-center  ",
    },
    {
      title: "Promedio",
      className: "px-2 text-center  col-1",
    },
    {},
  ];

  const headerDrawerPersonal = [
    {
      title: "",
      className: "px-2 text-start",
    },
    {
      title: "Asesor",
      className: "px-2 text-start col-6  align-self-start",
    },
    {
      title: "Estado",
      className: "px-2 text-start col-3 align-items-start ",
    },
    {
      title: "Rol",
      className: "px-2 text-start col-3  align-self-start",
    },
  ];

  const headerDrawer = [
    {
      title: "Fecha",
      className: "px-2 text-start col-3  align-self-start",
    },
    {
      title: "Tickets",
      className: "px-2 text-start col-2  align-self-start",
    },
    {
      title: "Tiempo total",
      className: "px-2 text-start  col-2 ",
    },
    {
      title: "Tiempo promedio",
      className: "px-2 text-start col-2 ",
    },
    {
      title: "Meta",
      className: "px-2 text-center  align-self-start",
    },
  ];

  const provisionalPersonalDrawer = {
    message: "Combo básico",
    results: [
      {
        id: 107,
        adviserIcon: personIcon,
        siteName: "FOCA Riohacha",
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
      {
        id: 107,
        adviserIcon: personIcon,
        siteName: "FOCA Riohacha",
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
      {
        id: 107,
        adviserIcon: personIcon,
        siteName: "FOCA Riohacha",
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
      {
        id: 107,
        adviserIcon: personIcon,
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
      {
        id: 107,
        adviserIcon: personIcon,
        siteName: "FOCA Riohacha",
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
      {
        id: 107,
        adviserIcon: personIcon,
        siteName: "FOCA Riohacha",
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
      {
        id: 107,
        adviserIcon: personIcon,
        siteName: "FOCA Riohacha",
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
      {
        id: 107,
        adviserIcon: personIcon,
        siteName: "FOCA Riohacha",
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
      {
        id: 107,
        adviserIcon: personIcon,
        siteName: "FOCA Riohacha",
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
      {
        id: 107,
        adviserIcon: personIcon,
        siteName: "FOCA Riohacha",
        adviserName: "Luz Marina Hernandez Paraíso",
        statusId: 8,
        rolId: 2,
      },
    ],
    rowTotal: 3,
    status: 200,
    success: true,
    title: null,
  };

  const dataTableDrawer = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let goalStatus = item.checkGoal === 1 ? approved : denied;
        let height = item.checkGoal === 1 ? "20px" : "32px";
        tempList.push(
          <tr
            key={index}
            className={`hover-table-row ${tableStyles.tlnTextGray}`}
          >
            <td className={`text-start px-2`}>{item.datetime}</td>
            <td className={`text-start px-2`}>{item.tickets}</td>
            <td className={`text-start px-2`}>{item.totalTime}</td>
            <td className={`text-start px-2`}>{item.averagetime}</td>
            <td className={`text-center px-2`}>
              <img alt="goal" src={goalStatus} style={{ height: height }} />
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };

  const dataPersonalDraweTable = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={` ${tableStyles.tlnTextGray}`}>
            <td className={`text-start px-2`}>
              <img alt="arrow" src={personIcon} />
            </td>
            <td className={`text-start px-2  ${tableStyles.TableRowHeader}`}>
              <button
                button
                onClick={() => {
                  setOpenAdviserDrawer(true);
                }}
                className={`text-start px-2  ${tableStyles.TableRowHeader} ${tableStyles.tlnTextGray}`}
              >
                {item.adviserName}
                <br />
                <span
                  className={`${tableStyles.f12} ${tableStyles.superClearGreyText} `}
                >
                  {item.siteName}
                </span>
              </button>
            </td>
            <td className={`text-start px-2`} key={index + 1}>
              <div className="w-100">
                <Select noOptionsMessage={() => 'No hay datos'}
                  //   options={servicesAppOptions}
                  className="text-secondary "
                  placeholder={"Sede"}
                  styles={customSelectTransparent}
                  //   value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                  onChange={(e) => {
                    // setFilterAppointment({ ...filterAppointment, srvId: e.value });
                  }}
                ></Select>
              </div>
            </td>
            <td className={`text-start px-2`} key={index + 2}>
              <div className="w-100">
                <Select noOptionsMessage={() => 'No hay datos'}
                  //   options={servicesAppOptions}
                  className="text-secondary "
                  placeholder={"Sede"}
                  styles={customSelectTransparent}
                  //   value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                  onChange={(e) => {
                    // setFilterAppointment({ ...filterAppointment, srvId: e.value });
                  }}
                ></Select>
              </div>
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };

  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr
            key={index}
            className={`hover-table-row ${tableStyles.tlnTextGray}`}
          >
            <td className={`text-center px-2 `}>
              <b className={` ${tableStyles.blueMediumText}`}>{index + 1}</b>
            </td>
            <td className={`text-center px-2`}>
              <img alt="arrow" src={personIcon} />
            </td>
            <td className={`text-start px-2 ${tableStyles.f14}`}>
              {item.adviserName}
              <br />
              <span
                className={`${tableStyles.f12} ${tableStyles.superClearGreyText}`}
              >
                {item.siteName}
              </span>
            </td>
            <td className={`text-center px-2`}>{item.days}</td>
            <td className={`text-center px-2`}>{item.appCount}</td>
            <td className={`text-center px-2`}>{item.accumulatedGoal}</td>
            <td className={`text-center px-2`}>{item.totalTime}</td>
            <td className={`text-center px-2`}>{item.maxTime}</td>
            <td className={`text-center px-2`}>{item.minTime}</td>
            <td className={`text-center px-2`}>{item.averageTime}</td>
            <td className={`text-center px-2`}>
              <button
                onClick={() => {
                  setOpenDrawer(true);
                  setDetailAssistant(item);
                }}
              >
                <img alt="arrow" src={arrowDarkGrey} />
              </button>
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };

  const renderTooltipPersonalIcon = () => <Tooltip>Personal</Tooltip>;
  const renderTooltipDownloadIcon = () => <Tooltip>Descargar</Tooltip>;
  const renderTooltipConfigIcon = () => <Tooltip>Configuración</Tooltip>;
  const renderTooltipAskIcon = () => (
    <Tooltip>aquí va eso traído del backend</Tooltip>
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfigDrawer, setOpenConfigDrawer] = useState(false);
  const [openAdviserDrawer, setOpenAdviserDrawer] = useState(false);
  const [dataConfig, setDataConfig] = useState({
    isEditing: false,
  });
  const [valueConfig, setValueConfig] = useState({});

  const [personalFilter, setPersonalFilter] = useState({
    //FILTERS OF PERSONAL DRAWER TO THE TABLE
  });
  const [dataPersonal, setDataPersonal] = useState({
    // PUT METHOD, DATA OF PERSONAL DRAWER
  });

  const closeConfigDrawer = () => {
    setOpenConfigDrawer(false);
    setDataConfig({ ...dataConfig, isEditing: false });
  };

  const closePersonalDrawer = () => {
    setOpenPersonalDrawer(false);
  };

  const [openPersonalDrawer, setOpenPersonalDrawer] = useState(false);

  return (
    <>
      {loadReportAdvisor && loader}
      <Drawer
        anchor={"right"}
        open={openAdviserDrawer}
        style={{ width: "50%" }}
      >
        <div
          style={{ width: "350px", padding: " 1rem 2rem", height: "100%" }}
          className="h-100"
        >
          <div>
            <Row>
              <div className="d-flex justify-content-start">
                <button onClick={() => setOpenAdviserDrawer(false)}>
                  <img alt="close" src={close} />
                </button>
              </div>
            </Row>
            <div className="px-2">
              <div className="d-flex flex-column justify-content-center ">
                <img
                  alt="icon"
                  src={personIcon}
                  height="80px"
                  className="my-2"
                />
                <span className="text-center">
                  <b
                    className={`${tableStyles.f16} ${tableStyles.tlnTextDark} `}
                  >
                    Luz Marina Hernandez Paraíso
                  </b>{" "}
                  <br />
                  <span
                    className={`${tableStyles.f13} ${tableStyles.tlnTextDark} `}
                  >
                    FOCA Rioacha
                  </span>
                </span>
                <div className="d-flex justify-content-center my-4">
                  <AntSwitch
                    className="mr-2"
                    defaultChecked
                    // key={trigger + "Ant"}
                    // checked={
                    //     isSelected?.currentItem?.status === "disabled" ? false : true}
                    // onChange={(e) => handleChangeStatus(e.target.checked)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <span
                    className={`${tableStyles.f12} ${tableStyles.tlnTextGray}  `}
                  >
                    Persona "habilitada"
                  </span>
                </div>
              </div>
              <div>
                <Row>
                  <Col xs={6}>
                    <b
                      className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                    >
                      Documento
                    </b>
                  </Col>
                  <Col xs={6}>
                    <span
                      className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                    >
                      C.C. 1.456.654.321
                    </span>
                  </Col>
                  <Col xs={6}>
                    <b
                      className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                    >
                      Fecha de ingreso
                    </b>
                  </Col>
                  <Col xs={6}>
                    <span
                      className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                    >
                      25/10/2021
                    </span>
                  </Col>
                  <Col xs={6}>
                    <b
                      className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                    >
                      Días de actividad
                    </b>
                  </Col>
                  <Col xs={6}>
                    <span
                      className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                    >
                      45 días
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="mt-4">
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <span> &nbsp;Rol asignado</span>
                  </Form.Label>
                  <Form.Group className="mb-3 text-start">
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={[
                        { value: 1, label: "Option 1" },
                        { value: 2, label: "Option 2" },
                        { value: 3, label: "Option 3" },
                      ]}
                      className="text-secondary"
                      placeholder={"Sede"}
                      styles={ordCustomSelect}
                    ></Select>
                  </Form.Group>
                </Form.Group>
              </div>
              <div
                className={`${tableStyles.blueCardNoBorder} px-4 py-2 mb-3 `}
              >
                <span
                  className={`${tableStyles.tlnTextPrimary} ${tableStyles.f14}`}
                >
                  Total Citas agendadas
                </span>
                <br />{" "}
                <span
                  className={`${tableStyles.tlnTextGray} ${tableStyles.f12}`}
                >
                  321.654 citas
                </span>
              </div>
              <div
                className={`${tableStyles.blueCardNoBorder} px-4 py-2 mb-3 `}
              >
                <span
                  className={`${tableStyles.tlnTextPrimary} ${tableStyles.f14}`}
                >
                  Tiempo promedio
                </span>
                <br />{" "}
                <span
                  className={`${tableStyles.tlnTextGray} ${tableStyles.f12}`}
                >
                  08:02:00
                </span>
              </div>
              <div
                className={`${tableStyles.blueCardNoBorder} px-4 py-2 mb-3 `}
              >
                <span
                  className={`${tableStyles.tlnTextPrimary} ${tableStyles.f14}`}
                >
                  Record de tickets por día
                </span>
                <br />
                <div className="d-flex justify-content-between">
                  <span
                    className={`${tableStyles.tlnTextGray} ${tableStyles.f12}`}
                  >
                    32.000
                  </span>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipDownloadIcon()}
                  >
                    <img
                      alt="ask"
                      src={questionIcon}
                      height="20px"
                      className={`${tableStyles.darkBlueHover}`}
                    />
                  </OverlayTrigger>
                </div>
              </div>
              <div
                className={`${tableStyles.blueCardNoBorder} px-4 py-2 mb-3 `}
              >
                <span
                  className={`${tableStyles.tlnTextPrimary} ${tableStyles.f14}`}
                >
                  Porcentaje de cumplimiento
                </span>
                <br />{" "}
                <span
                  className={`${tableStyles.tlnTextGray} ${tableStyles.f12}`}
                >
                  95%
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-end py-4">
                <img alt="icon" src={calenImg} width="250px" />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor={"right"}
        open={openPersonalDrawer}
        style={{ width: "50%" }}
      >
        <div
          style={{ width: "650px", padding: " 1rem 2rem", height: "100%" }}
          className="h-100"
        >
          <div>
            <Row>
              <div className="d-flex justify-content-start">
                <button onClick={() => setOpenPersonalDrawer(false)}>
                  <img alt="close" src={close} />
                </button>
              </div>
            </Row>
            <div className="px-4">
              <div className="">
                <b className={`${tableStyles.f32} ${tableStyles.tlnTextDark} `}>
                  Asignación de personal
                </b>{" "}
                <br />
              </div>
              <Row>
                <Col xs={3}>
                  <Form.Group className=" text-start" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}
                    >
                      <b> &nbsp;</b>
                    </Form.Label>
                    <div
                      className={`d-flex ${tableStyles.appGrayInputSearch} ${tableStyles.f12}`}
                    >
                      <Form.Control
                        style={{ backGroundColor: "red" }}
                        className={`ord-roundInput    ${tableStyles.appInputDate}`}
                        typeDiscount="text"
                        placeholder="Nombre "
                        autoComplete="off"
                        onChange={(e) => {
                          //   setFilterAppointment({ ...filterAppointment, search: e.target.value });
                        }}
                      />
                      <img src={ordBlueSearch} className="" alt="buscar"></img>
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <div className="w-100">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}
                    >
                      <b> &nbsp;</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={servicesAppOptions}
                      className="text-secondary "
                      placeholder={"Sede"}
                      styles={appDarkCustomSelect}
                      //   value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                      onChange={(e) => {
                        // setFilterAppointment({ ...filterAppointment, srvId: e.value });
                      }}
                    ></Select>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="w-100">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}
                    >
                      <b> &nbsp;</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={servicesAppOptions}
                      className="text-secondary "
                      placeholder={"Estado"}
                      styles={appDarkCustomSelect}
                      //   value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                      onChange={(e) => {
                        // setFilterAppointment({ ...filterAppointment, srvId: e.value });
                      }}
                    ></Select>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="w-100">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}
                    >
                      <b> &nbsp;</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={servicesAppOptions}
                      className="text-secondary "
                      placeholder={"Rol"}
                      styles={appDarkCustomSelect}
                      //   value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                      onChange={(e) => {
                        // setFilterAppointment({ ...filterAppointment, srvId: e.value });
                      }}
                    ></Select>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="px-4 my-4"
            style={{ height: "70%", overflowY: "auto" }}
          >
            <div className=" " style={{ marginBottom: "40px" }}>
              <DrawerTable
                headers={headerDrawerPersonal}
                hasChildren={true}
                // shadow={true}
                oneHeadDarkBlue={false}
                // lowerCase={true}
                drawerThead={true}
                drawerPadding={true}
                drawerTable

                // paginate={{
                //   activePage: filters?.page,
                //   totalPages: referencesReports?.rowTotal,
                //   perPage: filters?.perpage,
                //   pageRangeDisplayed: 3,
                //   onChangePage: async (e) => {
                //     const result = await getReferencesReports({
                //       url: "/medical/reference/",
                //       objFilters: { ...filters, page: e },
                //       token: token,
                //     });
                //     setFilters({ ...filters, page: e });
                //     formatData(result?.results);
                //   },
                //   showTextDetails: true,
                // }}
              >
                {dataPersonalDraweTable(provisionalPersonalDrawer.results)}
              </DrawerTable>
            </div>
          </div>

          <div
            style={{
              width: "650px",
              padding: " 1rem 2rem",
              position: "fixed",
              borderTop: "1px solid #",
              background: "#fff",
              bottom: "0px",
            }}
            className="d-flex justify-content-end align-items-end align-self-end border-top bg-white"
          >
            <div
              className="px-4 w-100 d-flex justify-content-end align-items-end align-self-end mb-2 "
              style={{ width: "100%" }}
            >
              <button
                onClick={() => closePersonalDrawer()}
                className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}
              >
                Cancelar
              </button>
              <button
                // onClick={() => handleSubmit()}
                className={`${tableStyles.ordBtnPrimary} btn`}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
        {/* buttons */}
      </Drawer>
      <Drawer anchor={"right"} open={openConfigDrawer} style={{ width: "50%" }}>
        <div
          style={{ width: "350px", padding: " 1rem 2rem", height: "100%" }}
          className=""
        >
          <div>
            <Row>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <button onClick={() => closeConfigDrawer()}>
                    <img alt="close" src={close} />
                  </button>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() =>
                      setDataConfig({
                        ...dataConfig,
                        isEditing: !dataConfig.isEditing,
                      })
                    }
                  >
                    <img alt="edit" src={edit} width="20p" />
                  </button>
                </div>
              </div>
            </Row>
            <div className="px-4">
              <div className="">
                <b
                  className={`${tableStyles.f32} ${tableStyles.tlnTextDark} text-center `}
                >
                  {" "}
                  &nbsp;Configuración
                </b>{" "}
                <br />
              </div>
              <div className="my-2">
                <b
                  className={`${tableStyles.f16} ${tableStyles.tlnTextPrimary} `}
                >
                  Metas diarias por persona:
                </b>

                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <span> &nbsp;{attentionGroup?.results?.name}</span>
                  </Form.Label>

                  <Form.Control
                    disabled={!dataConfig.isEditing}
                    className={`ord-roundInput `}
                    type="text"
                    name={attentionGroup?.results?.id}
                    placeholder="Escriba..."
                    value={valueConfig?.dailyGoal}
                    onChange={(e) => {
                      setValueConfig({
                        ...valueConfig,
                        dailyGoal: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ width: "350px", padding: " 1rem 2rem", height: "100%" }}
          className="d-flex justify-content-end align-items-end align-self-end mb-4 "
        >
          {dataConfig.isEditing && (
            <div className="px-4">
              <button
                onClick={() => {
                  closeConfigDrawer();
                  setValueConfig({
                    atgId: attentionGroup?.results?.id,
                    dailyGoal: `${attentionGroup?.results?.dailyGoal}`,
                  });
                }}
                className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}
              >
                Cancelar
              </button>
              <button
                onClick={() => {
                  updateAttentionGroup({
                    url: "/medical/reports/advisorsReport/",
                    token: token,
                    method: "PUT",
                    body: {
                      userId: idUser,
                      goals: [
                        {
                          atgId: valueConfig?.atgId,
                          dailyGoal: Number(valueConfig?.dailyGoal),
                        },
                      ],
                    },
                    noAlert: false,
                    doAfterSuccess: () => {
                      closeConfigDrawer();
                      getAttentionGroup({
                        url: "/medical/attentionGroup/",
                        token: token,
                        objFilters: {
                          id: id,
                          eaccount: eaccount,
                          status: "enabled",
                        },
                      });
                    },
                  });
                }}
                className={`${tableStyles.ordBtnPrimary} btn`}
              >
                Guardar
              </button>
            </div>
          )}
        </div>
      </Drawer>
      <Drawer anchor={"right"} open={openDrawer} style={{ width: "50%" }}>
        <div style={{ width: "650px", padding: " 1rem 2rem" }}>
          <Row>
            <div className="d-flex justify-content-start">
              <button onClick={() => setOpenDrawer(false)}>
                <img alt="close" src={close} />
              </button>
            </div>
          </Row>
          <div className="px-4">
            <div className="">
              <b className={`${tableStyles.f32} ${tableStyles.tlnTextDark} `}>
                Detalle
              </b>{" "}
              <br />
              <span
                className={`${tableStyles.f13} ${tableStyles.tlnTextDark} `}
              >
                {detailAssistant?.adviserName}
              </span>
            </div>
            <div className="mt-4">
              <DrawerTable
                headers={headerDrawer}
                hasChildren={true}
                // shadow={true}
                oneHeadDarkBlue={false}
                // lowerCase={true}
                drawerThead={true}
                drawerPadding={true}
                drawerTable

                // paginate={{
                //   activePage: filters?.page,
                //   totalPages: referencesReports?.rowTotal,
                //   perPage: filters?.perpage,
                //   pageRangeDisplayed: 3,
                //   onChangePage: async (e) => {
                //     const result = await getReferencesReports({
                //       url: "/medical/reference/",
                //       objFilters: { ...filters, page: e },
                //       token: token,
                //     });
                //     setFilters({ ...filters, page: e });
                //     formatData(result?.results);
                //   },
                //   showTextDetails: true,
                // }}
              >
                {dataTableDrawer(detailAssistant?.dailyDetail)}
              </DrawerTable>
            </div>
          </div>
        </div>
      </Drawer>
      <div className=" pt-4">
        {/* /* ----------------------------- INPUTS SECTION ----------------------------- */}
        <Row>
          <Col xs={6} className="mb-3">
            <Row>
              <Col xs={3} className="">
                <Form.Group className="mb-3 text-start">
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordDarkBlueText} ${tableStyles.f13}`}
                    id="sDate"
                    type="date"
                    value={filters?.dateStart}
                    onChange={(e) =>
                      setFilters({ ...filters, dateStart: e.target.value })
                    }
                    placeholder="Fecha inicial "
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className="">
                <Form.Group className="mb-3 text-start">
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordDarkBlueText} ${tableStyles.f13}`}
                    id="eDate"
                    type="date"
                    value={filters?.dateEnd}
                    onChange={(e) =>
                      setFilters({ ...filters, dateEnd: e.target.value })
                    }
                    placeholder="Fecha final"
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className="">
                <Form.Group className="mb-3 text-start">
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={formatSite}
                    className="text-secondary"
                    placeholder={"Sede"}
                    value={formatSite.find((e) => e.value === filters.site)}
                    onChange={(e) => {
                      setFilters({ ...filters, site: e.value });
                    }}
                    styles={ordCustomSelect}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className="">
                <button
                  onClick={() => {
                    generateButton();
                  }}
                  className={`${tableStyles.ordBtnPrimary} btn`}
                >
                  <b>Generar</b>
                </button>
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <div className="d-flex justify-content-end ">
              <div className={`d-flex ${tableStyles.f14}`}>
                <b className={`${tableStyles.tlnTextGray}`}>
                  <span className={`${tableStyles.tlnTextPrimary}`}>
                    Meta: {""}
                  </span>
                  {attentionGroup?.results?.dailyGoal} tickets por persona
                </b>
                {showTable.show && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipDownloadIcon()}
                  >
                    <button
                      onClick={() => alert("hago algo")}
                      className={`mx-2 ${tableStyles.filterHoverBlue}`}
                    >
                      <img alt="config" src={download} height="20px" />
                    </button>
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipConfigIcon()}
                >
                  <button
                    onClick={() => {
                      setOpenConfigDrawer(true);
                    }}
                    className={`mx-2 ${tableStyles.filterHoverBlue}`}
                  >
                    <img alt="config" src={config} height="20px" />
                  </button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipPersonalIcon()}
                >
                  <button
                    onClick={() => setOpenPersonalDrawer(true)}
                    className={`mx-2 ${tableStyles.filterHoverBlue}`}
                  >
                    <img alt="people" src={people} width="20px" />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
          </Col>
        </Row>
        {/* /* ----------------------------- CARDS SECTION ----------------------------- */}
        {/* 1 */}
        <Row className={` justify-content-between`}>
          <Col className={` pr-2 ml-1`} xs={3}>
            <div className={`${tableStyles.blueCardNoBorder} p-2 px-4 ml-1`}>
              <span
                className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}
              >
                Total de citas agendadas
              </span>
              <Row className={`pt-2 align-content-end`}>
                <Col
                  className={`${tableStyles.f16} ${tableStyles.ordDarkGrayText} d-flex align-self-end`}
                  xs={4}
                >
                  <b>
                    {reportAdvisor?.results?.appTotals?.appCount
                      ? reportAdvisor?.results?.appTotals?.appCount
                      : 0}
                  </b>
                </Col>
                <Col
                  className={`${tableStyles.f12} ${tableStyles.ordDarkGrayText}  d-flex align-self-end`}
                  xs={4}
                >
                  Max{" "}
                  {reportAdvisor?.results?.appTotals?.maxAppCount
                    ? reportAdvisor?.results?.appTotals?.maxAppCount
                    : 0}
                </Col>
                <Col
                  className={`${tableStyles.f12} ${tableStyles.ordDarkGrayText}  d-flex align-self-end`}
                  xs={4}
                >
                  Min{" "}
                  {reportAdvisor?.results?.appTotals?.minAppCount
                    ? reportAdvisor?.results?.appTotals?.minAppCount
                    : 0}
                </Col>
              </Row>
            </div>
          </Col>
          <Col className={` pr-2 `} xs={3}>
            <div className={`${tableStyles.blueCardNoBorder} p-2 px-4 `}>
              <span
                className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}
              >
                Tiempo total de agendamiento
              </span>
              <Row className={`pt-2`}>
                <Col
                  className={`${tableStyles.f16} ${tableStyles.ordDarkGrayText} `}
                >
                  <b>
                    {reportAdvisor?.results?.appTotals?.totalTime
                      ? reportAdvisor?.results?.appTotals?.totalTime
                      : 0}
                  </b>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className={` `} xs={3}>
            <div className={`${tableStyles.blueCardNoBorder} p-2 px-4 `}>
              <span
                className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}
              >
                Tiempo promedio
              </span>
              <Row className={`pt-2`}>
                <Col
                  className={`${tableStyles.f16} ${tableStyles.ordDarkGrayText} d-flex align-self-end`}
                  xs={4}
                >
                  <b>
                    {reportAdvisor?.results?.appTotals?.averageTime
                      ? reportAdvisor?.results?.appTotals?.averageTime
                      : 0}
                  </b>
                </Col>
                <Col
                  className={`${tableStyles.f12} ${tableStyles.ordDarkGrayText} d-flex align-self-end`}
                  xs={4}
                >
                  Max{" "}
                  {reportAdvisor?.results?.appTotals?.maxTime
                    ? reportAdvisor?.results?.appTotals?.maxTime
                    : 0}
                </Col>
                <Col
                  className={`${tableStyles.f12} ${tableStyles.ordDarkGrayText} d-flex align-self-end`}
                  xs={4}
                >
                  Min{" "}
                  {reportAdvisor?.results?.appTotals?.minTime
                    ? reportAdvisor?.results?.appTotals?.minTime
                    : 0}
                </Col>
              </Row>
            </div>
          </Col>
          <Col className={`pr-2`} xs={3}>
            <div className={`${tableStyles.blueCardNoBorder} p-2 px-4 `}>
              <span
                className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}
              >
                Cantidad de asesores
              </span>
              <Row className={`pt-2`}>
                <Col
                  className={`${tableStyles.f16} ${tableStyles.ordDarkGrayText}`}
                  xs={4}
                >
                  <b>{reportAdvisor?.rowTotal ? reportAdvisor?.rowTotal : 0}</b>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="mt-4">
          {showTable.show ? (
            <div className="m-2 ">
              <OrdTable
                headers={headers}
                hasChildren={true}
                shadow={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
              >
                {formatData(reportAdvisor?.results?.data)}
              </OrdTable>
            </div>
          ) : (
            <div className={`d-flex justify-content-center`}>
              <b
                className={`${tableStyles.f18} ${tableStyles.tlnTextGray}  p-2 px-4 `}
              >
                Estado Actual{" "}
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {moment().format("LL")}
                </span>
              </b>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
