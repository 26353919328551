import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import iconBack from "../../assets/img/icons/iconBack.svg";
import Imprimir from "../../assets/img/icons/imprimir.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import {
  getDetailRecord,
  receiveRecord,
} from "../../actions/receiptOfInvoicesActions";
import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import { message } from "../../helpers/helpers";
import { getPdfTest } from "../../actions/consultAction";
import Loader from "react-loader-spinner";
function DocSignature(props) {
  const storage = useSelector((state) => state);
  const dispatch = useDispatch();
  const isLoading = storage?.invoiceReducer?.loading_det_receipt_invoice;
  const [showPdf, setShowPdf] = useState(false);
  const token = storage.loginReducer.Authorization;
  const [base64, setBase64] = useState("");
  const getPdf = async () => {
    const result = await getPdfTest(
      {
        id: props?.id,
        doc: 7,
      },
      token
    );

    if (result?.success) {
      return setBase64(result?.pdf[0]?.key?.split("'")[1]);
    } else {
      // message(
      //   "error",
      //   "Ha ocurrido un error",
      //   "No ha sido posible cargar el documento"
      // );
      return setShowPdf(false);
    }
  };
  useEffect(() => {
    getPdf();
  }, []);

  useEffect(() => {
    if (!!props.selectedRecord) {
      dispatch(
        getDetailRecord({
          id: props.selectedRecord,
          eaccount: storage.loginReducer.currentAccount.id,
        })
      );
    }
  }, [props.show]);

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };

  const header = [
    <th className="text-center">No. Factura </th>,
    <th className="text-center">Fecha factura</th>,
    <th className="text-center">Valor</th>,
  ];

  const renderList = () => {
    let table = [];
    if (Array.isArray(storage.invoiceReducer.detail.invoices)) {
      table = storage.invoiceReducer.detail.invoices.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">
            <td className="text-uppercase">{x.invoice}</td>
            <td>{x.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")}</td>
            <td>{x.amount ? "$" + numberWithCommas(x.amount) : ""}</td>
          </tr>
        );
      });
    }
    return table;
  };

  var today = new Date();

  return (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem", width: "60%" }}
    >
      {isLoading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}

      <div className="d-flex" style={{ marginTop: '40px', alignItems: 'end' }}>

        <img 
          style={{ height: '43px', paddingBottom: '10px' }} 
          className={`mr-3 cursorPointer`} 
          src={iconBack} 
          onClick={() => props.setDetails({ show: false })}
        />

        <h1 className={tableStyles.title} style={{ margin: '0px' }}>Actas de radicación</h1>

        <img
          className={`${tableStyles.cursorPointer} mt-5 ml-3 `}
          style={{ paddingLeft: "5px", paddingRight: "5px" }}
          src={Imprimir}
          onClick={() => setShowPdf(true)}
        />
        
      </div>
      <div className="text-end">
        <p></p>
      </div>
      <Row className="d-flex">
        <Col>
          <p className="text-secondary">
            Por medio del presente documento se da constancia de radicación de
            las facturas anexadas a la empresa{" "}
            {storage.invoiceReducer.detail.clientName} con NIT{" "}
            {storage.invoiceReducer.detail.clientNit}, en la dirección{" "}
            {storage.invoiceReducer.detail.clientAddress} de la ciudad de
            Barranquilla, el día {String(today.getDate()).padStart(2, "0")}, del
            mes {String(today.getMonth() + 1).padStart(2, "0")} del{" "}
            {today.getFullYear()}.
          </p>
        </Col>
      </Row>

      <GenericTable className="mb-6" headers={header} dark={true}>
        {renderList()}
      </GenericTable>

      <div className="d-flex mt-5">
        <div className="col-4 mr-3">
          <div>
            <label className={tableStyles.crudModalLabel}>Nombre</label>
            <input
              className={`${IndividualStyles.registerInputs} ${tableStyles.darkGrayText}`}
              type="text"
              defaultValue={storage.invoiceReducer.detail.generatorFullName}
              readOnly
            ></input>
          </div>
          <div>
            <label className={tableStyles.crudModalLabel}>
              No. Documento identidad
            </label>
            <input
              className={`${IndividualStyles.registerInputs} ${tableStyles.darkGrayText}`}
              type="text"
              defaultValue={storage.invoiceReducer.detail.generatorDocNum}
              readOnly
            ></input>
          </div>
        </div>

        <div className="col-8">
          <div className="display-grid">
            <label
              htmlFor="imageUpload"
              className={`${IndividualStyles.divfir}`}
            >
              <img
                className={`${IndividualStyles.profilepic} ${IndividualStyles.hoverPointer} `}
                src={storage.invoiceReducer.detail.filing_file_url}
                alt="firma"
              />
            </label>
            <div
              className="w-100 mt-2 text-secondary"
              style={{ height: "1px", border: "solid 1px" }}
            ></div>
            <label
              className={`${tableStyles.crudModalLabelGris} text-center m-0`}
            >
              <b className="text-secondary">Firma</b>
            </label>
          </div>
        </div>
      </div>
      <ModalNew
        title="Acta de radicación"
        show={showPdf}
        btnNoName={"Cancelar"}
        size="700"
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer
          downloadable
          file={`data:application/pdf;base64,${base64}`}
        ></PdfViewer>
      </ModalNew>
    </div>
  );
}
export default DocSignature;
