import React from 'react'
import '../../../OrderingModule/orderingStyles.css'
import { PseudoSideBarAccordion } from '../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion';
import { useHistory } from "react-router-dom";
import { onCapitalLetter } from '../../../helpers/helpers';
import { useSelector } from 'react-redux';
export const MedicalAccountsHomeContent = () => {
    let history = useHistory();
    const { all_categories } = useSelector((state) => state?.loginReducer);
    const accountingModules = all_categories?.find((cat) => cat?.name === 'Cuentas médicas')
    const { modules } = accountingModules
    const storage = useSelector((state) => state);

    // const titles = [
    //     {
    //         title: 'Contratación',
    //         content: [
    //             { subtitle: "Contratos", action:() => history.push('/cuentasMedicas/Contratos') },
    //             ]
    //     },
    // ]
    const contentDynamic = modules?.map((module) => {
        const textJoin = onCapitalLetter({text:module?.name})
        return {
            title: textJoin,
            content: module?.functionalities?.map((subModule)=>{
                let subModuleName = onCapitalLetter({text: subModule?.name})
                let returnValue = <></>
                let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === subModule?.prefix
                  )?.read
                if(permissionValidation){
                    returnValue = { subtitle: subModuleName, action: () => history.push(subModule?.url) };
                  }
                return returnValue
            })
        }
    })
    return (
        <div>
            {/* /* ----------------------------- PSEUDO-SIDEBAR--------------------------*/}
            {<PseudoSideBarAccordion accordionItems={contentDynamic} />}
        </div>
    )
}
