import { useEffect, useState } from 'react';

export const useFormatData = ({ tableBodyStructure, listTableBody, changeRender }) => {
    const [formatData, setFormatData] = useState([])

    const render = () => {

        let tempList = [];
        if (Array.isArray(listTableBody)) {
            listTableBody?.forEach((e, index) => {
                tempList.push(tableBodyStructure(e));
            });
        }
        setFormatData(tempList)
        return tempList;
        
    }

    useEffect(() => {
        render()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, Array.isArray(changeRender) ? changeRender : [changeRender])

    
    return { formatData }
}