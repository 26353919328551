import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import tableStyles from "./tableStyle.module.scss";
import Close from "../../assets/img/icons/modalClose.svg";
import Cerrar from "../../assets/img/icons/Cerrar.svg";
import { CSSTransition } from "react-transition-group";
import "animate.css";

export const DialogModalFullScreen = (props) => {
  // animate__fadeInUp
  // animate__fadeOutDown;

  /* -------------------------------------------------------------------------- */
  /*                                    PROPS                                   */
  /* -------------------------------------------------------------------------- */
  //        animationIn === The animation you want to show when the component is mounted
  //        animationOut === The animation you want to show when the component is unmounted
  //        closeDialog === Receives a function that is going to unmount the component
  //        title === Title of the dialog

  //PD: all classes of animation have to be from Animate.css library https://animate.style/
  // if you don't specify animationIn or animationOut the default will be animationIn: animate__fadeInUp
  // animationOut: animate__fadeOutDown

  /* -------------------------------------------------------------------------- */
  /*                             HOW TO USE EXAMPLE                             */
  /* -------------------------------------------------------------------------- */
  //   <DialogModalFullScreen
  //        animationIn="animate__bounce"
  //        animationOut="animate__backOutRight"
  //        closeDialog={() => {
  //        setOpen(false);
  //        title={"Discusión entre compañeros"}
  // >
  /* ------------------------------------ - ----------------------------------- */

  const [animation, setAnimation] = useState(
    props.animationIn ? props.animationIn : "animate__fadeInUp"
  );

  const [image, setImage] = useState(Close);
  return (
    <>
      <div className={`animate__animated  container-fluid ${animation}`}>
        <Row
          className={`${tableStyles.bgColorClearBlue} d-flex justify-content-between shadow `}
        >
          <Col xs={1} className="d-flex justify-content-center">
            <img
              alt="animate"
              onMouseEnter={() => setImage(Cerrar)}
              onMouseLeave={() => setImage(Close)}
              onClick={() => {
                setAnimation(
                  props.animationOut
                    ? props.animationOut
                    : "animate__fadeOutDown"
                );

                setTimeout(() => {
                  props.closeDialog();
                }, 700);
              }}
              width="30%"
              className={`cursorPointer `}
              src={image}
            ></img>
          </Col>
          <Col xs={11} className={tableStyles.darkBlueText}>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "35px",
              }}
              className="m-2"
            >
              {props.title ? props.title : "Your dialog title here! 😁😁😄 "}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="w-100">
            {props.children}
          </Col>
        </Row>
      </div>
    </>
  );
};
