/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useGetMethod, usePostMethod } from "../../../Hooks/useFetch";
import styles from "../../../components/Layouts/tableStyle.module.scss";
import { genderList, maritalStatusList } from "../../../helpers/constants";
import {
  expRegList,
  formatToRcSelect,
  generateId,
  getAge,
  loader,
  message,
  swalConfirm,
  validateEmptyString,
  validExpReg,
} from "../../../helpers/helpers";
import closeInsurer from "../../../assets/img/icons/close_insurer_voluntary.svg";
import { appCustomSelectNew } from "../../../components/Layouts/react-select-custom";
import { useDispatch, useSelector } from "react-redux";
import { city_get, province_get } from "../../../actions/locationActions";
import { ACTIVE_ID } from './../../../actions/actionTypes';

const RegisterPatient = ({ token, options, filters, trigger, setTrigger, setFilters, setOptions, getListPacient, getDetailPatient, postTicketFunction }) => {
  const [newPatient, setNewPatient] = useState({
    documentType: "",
    documentNumber: validExpReg(filters.filterPatient, expRegList.number) === true ? filters.filterPatient : "",
    firstName: "",
    secondName: "",
    firstSurname: "",
    secondSurname: "",
    gender: "",
    birthDate: "",
    homeAddress: "",
    email: "",
    cityId: "",
    homephone: "",
    phoneNumber: "",
    whatsapp: "",
    bloodType: "",
    maritalStatus: "",
    provinceId: "",
    contracts: [],
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [validateWebScrapper, setValidateWebScrapper] = useState({
    modality: "onSite",
    doc: validExpReg(filters.filterPatient, expRegList.number) === true ? filters.filterPatient : "",
    docType: "",
    entityId: "",
    entityType: "",
  });
  // //console.log("validateWebScrapper: ", validateWebScrapper);

  const store = useSelector((state) => state);
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [contracts, setContracts] = useState([
    {
      index: generateId(),
      contractId: "",
      corporateClientId: "",
      companyTypeTag: "eps",
      populationId: "",
      crtId: "",
      policy: "",
      isMain: 1,
      wsValidated: 1,
    },
  ]);
  // //console.log("contracts: ", contracts);

  const [webScrapper, setWebScrapper] = useState([
    {
      index: generateId(),
      errorWS: "",
      success: "",
      message: "",
      results: "",
    },
  ]);
  // //console.log("webScrapper: ", webScrapper);

  const counter = useSelector((state) => state);

  const temporalContract = contracts;
  const temporalWebScrapper = webScrapper;

  const companyTypeTag = [
    { value: "-seleccione-", label: "-seleccione-" },
    { value: "prepaid", label: "Prepagada" },
    { value: "special_agreement", label: "Convenio especial" },
  ];

  const bloodType = [
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B-", label: "B-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
  ];

  const { results: typeDocument, trigger: getDocumentTypes } = useGetMethod();
  const { results: insurersObligatory, trigger: getInsurersObligatory } = useGetMethod();

  const { results: validateWeb, load: loadValidateWeb, trigger: getValidateWeb } = useGetMethod();

  const { results: insurerVoluntary, trigger: getInsurerVoluntary } = useGetMethod();

  const { results: contractsClient, load: contractsLoader, trigger: getContracts } = useGetMethod();

  const { results: contractsVoluntary, load: contractsLoaderVolunray, trigger: getContractsVoluntary } = useGetMethod();

  const { results: poblations, load: poblationsLoader, trigger: getPoblations } = useGetMethod();
  const { results: poblationsObligatory, load: poblationsObligatoryLoader, trigger: getPoblationsObligatory } = useGetMethod();

  const { results: incomeGrupo, trigger: getIncomeGrupo } = useGetMethod();

  const {
    // eslint-disable-next-line no-unused-vars
    results: validateExistence,
    trigger: getValidateExistence,
  } = useGetMethod();

  const { load: loadCreatePatient, trigger: sendPatient } = usePostMethod();

  useEffect(() => {
    getDocumentTypes({
      url: "/payroll/document_types/",
      token: token,
    });

    getInsurersObligatory({
      url: "/medical/client/",
      token: token,
      objFilters: { contractCompanyType: "eps", status: "enabled", eaccount: idEnterprise },
    });

    getIncomeGrupo({
      url: "/medical/copaymentRate/",
      token: token,
      // objFilters:{status: "enabled"}
    });
    getSelectCities(newPatient.provinceId);
    dispatch(
      province_get({
        entity_account: counter.loginReducer.currentAccount.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const provincesOptions = [{ value: "", label: "Seleccionar...", name: "id_dep" }];
  if (Array.isArray(counter.locationReducer.provinces)) {
    counter.locationReducer.provinces.map((item) => {
      provincesOptions.push({
        value: item.id,
        label: item.name,
        name: "id_province",
      });
    });
  }

  const citiesOptions = [{ value: "", label: "Seleccionar...", name: "id_province" }];

  if (Array.isArray(counter.locationReducer.cities)) {
    counter.locationReducer.cities.map((item) => {
      citiesOptions.push({
        value: item.id,
        label: item.name,
        name: "id_province",
      });
    });
  }

  const formatTypeDocument = formatToRcSelect(typeDocument.results, "id", "description", "prefix");

  const formatedEntityObligatory = formatToRcSelect(insurersObligatory.results, "id", "name");

  const formatedIncomeGrupo = formatToRcSelect(incomeGrupo.results, "id", "name");

  const formatedInsurerVoluntary = formatToRcSelect(insurerVoluntary.results, "id", "name");

  const formatContracts = formatToRcSelect(contractsClient.results, "id", "name");

  const contractsVoluntaryOptions = formatToRcSelect(contractsVoluntary.results, "id", "name");

  const formatPoblations = formatToRcSelect(poblations.results, "idPopulation", "name");
  const formatPoblationsObligatory = formatToRcSelect(poblationsObligatory.results, "idPopulation", "name");

  const getSelectCities = (id) => {
    setLoading(true);
    dispatch(city_get({ province: id }, () => setLoading(false)));
    setNewPatient({ ...newPatient, provinceId: id });
  };

  // ANCHOR COMPANY VOLUNTARY

  const getInsurersVoluntary = (id) => {
    getInsurerVoluntary({
      url: "/medical/client/",
      token: token,
      objFilters: { contractCompanyType: id, status: "enabled", eaccount: idEnterprise },
    });
  };

  // ANCHOR CONTRACTS PETITIONS
  const getContractsEps = (id) => {
    getContracts({
      url: "/medical/client/contract/",
      token: token,
      objFilters: {
        client: id,
        cType: "eps",
        status: "enabled",
        eaccount: idEnterprise,
      },
      doAfterSuccess: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
      },
    });
  };

  const getContractPrepaidVoluntary = (id) => {
    getContractsVoluntary({
      url: "/medical/client/contract/",
      token: token,
      objFilters: {
        client: id,
        cType: "prepaid",
        status: "enabled",
        eaccount: idEnterprise,
      },
    });
  };

  const getContractSpecialVoluntary = (id) => {
    getContractsVoluntary({
      url: "/medical/client/contract/",
      token: token,
      objFilters: {
        client: id,
        cType: "special_agreement",
        status: "enabled",
        eaccount: idEnterprise,
      },
      doAfterSuccess: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
      },
    });
  };

  // --------------

  const getContractPoblations = (id) => {
    getPoblations({
      url: "/medical/client/contractpopulation/",
      token: token,
      objFilters: {
        contract: id,
        status: "enabled",
        eaccount: idEnterprise,
      },
      doAfterSuccess: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
      },
    });
  };

  const getContractPoblationsObligatory = (id) => {
    getPoblationsObligatory({
      url: "/medical/client/contractpopulation/",
      token: token,
      objFilters: {
        contract: id,
        status: "enabled",
        eaccount: idEnterprise
      },
      doAfterSuccess: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
      },
    });
  };

  const addInsurer = () => {
    let newInsurer = {
      index: generateId(),
      contractId: "",
      corporateClientId: "",
      companyTypeTag: "",
      populationId: "",
      crtId: "",
      policy: "",
      isMain: 0,
      wsValidated: 1,
    };
    setContracts([...contracts, newInsurer]);
  };

  const deleteInsurer = (i) => {
    setContracts(contracts.filter((item, index) => index !== i));
    setWebScrapper(webScrapper.filter((item, index) => index !== i));
  };

  const onSubmitPatient = (e) => {
    let validations = validateEmptyString([
      { field: newPatient.documentType, message: "El tipo de documento es obligatorio" },
      { field: newPatient.documentNumber, message: "El número de documento es obligatorio" },
      { field: newPatient.firstName, message: "El primer nombre es obligatorio" },
      { field: newPatient.firstSurname, message: "El primer apellido es obligatorio" },
      { field: newPatient.birthDate, message: "La fecha de nacimiento es obligatorio" },
      { field: newPatient.whatsapp, message: "El Whatsapp es obligatorio" },
      { field: newPatient.gender, message: "El género es obligatorio" },
      { field: newPatient.email, message: "El correo es obligatorio" },
      // { field: newPatient.contracts, message: "La empresa es obligatorio" },
      { field: newPatient.phoneNumber, message: "El celular es obligatorio" },
      // { field: newPatient.qtyPerMeasUnit, message: 'El campo cantidad de la unidad de receta es obligatorio' },
    ]);
    let contractsVol = contracts.filter((e) => e.isMain === 0 && e.companyTypeTag !== "particular");
    // //console.log("contractsVol: ", contractsVol);
    let validationContracts = contracts.find((e) => e.isMain === 0 && e.companyTypeTag !== "particular");
    let validationContractsOb = contracts.find(
      (e) => e.isMain === 1 && e.crtId !== "" && e.contractId !== "" && e.corporateClientId !== "" && e.populationId !== ""
    );
    let status;
    if (validationContracts === undefined) {
      status = true;
    } else if (validationContractsOb !== undefined) {
      status = true;
      contractsVol.length > 0 &&
        contractsVol.forEach((e) => {
          if (e.companyTypeTag === "special_agreement") {
            if (e.companyTypeTag !== "" && e.contractId !== "" && e.corporateClientId !== "" && e.populationId !== "") {
              status = true;
            } else {
              status = false;
              message(
                "info",
                "Llenar campos aseguradora voluntaria",
                "Para crear una aseguradora voluntaria es necesario llenar todos los campos"
              );
              return status;
            }
          } else {
            if (e.companyTypeTag !== "" && e.contractId !== "" && e.corporateClientId !== "") {
              status = true;
            } else {
              status = false;
              message(
                "info",
                "Llenar campos aseguradora voluntaria",
                "Para crear una aseguradora voluntaria es necesario llenar todos los campos"
              );
              return status;
            }
          }
        });
    } else {
      status = false;
      message(
        "info",
        "Debe crear la aseguradora obligatoria",
        "El anterior procedimiento es prerequisito para crear una aseguradora voluntaria"
      );
    }



    if (validations.valid && status) {
      e.preventDefault();
      swalConfirm({
        title: `¿Está seguro?`,
        text: `Se creará el paciente ${newPatient.firstName} ${newPatient.secondName} ${newPatient.firstSurname} ${newPatient.secondSurname}`,
        confirmButtonText: `Si, continuar`,
        cancelButtonText: `Cancelar`,
        showCancelButton: true,
        doAfterConfirm: () => {
          sendPatient({
            url: "/medical/patient/register/",
            token: token,
            method: "POST",
            body: { ...newPatient, contracts: contracts[0].contractId !== "" ? contracts : [], eaccount: idEnterprise },
            doAfterSuccess: (data) => {
              // postTicketFunction("save_new_patient")
              postTicketFunction("save_new_patient")
              getListPacient({
                url: "/medical/patient/listPatient/",
                objFilters: filters,
                token: token,
              });
              getDetailPatient({
                url: "/medical/patient/",
                objFilters: { id: data.results.id, eaccount: idEnterprise, },
                token: token,
              });

              dispatch({
                type: ACTIVE_ID,
                payload:data.results.id
              })
              
              setOptions({
                ...options,
                showInfoPatient: false,
                showDetailPatient: true,
                showSearchPatient: false,
                showRegisterPatient: false,
              });
            },
          });
          // message("success", "Proceso realizado con éxito");
        },
      });
    }
  };

  const onChangeInsureVoluntary = (e, index) => {
    const temporalContract = contracts;
    temporalContract[index].corporateClientId = e.value;
    if (temporalContract[index].companyTypeTag === "special_agreement") {
      // getContractsEps(e.value);
      getContractSpecialVoluntary(e.value);
      getValidateWeb({
        url: "/medical/appointment/validateInfoWebscrapper/",
        token: token,
        objFilters: {
          ...validateWebScrapper,
          entityId: e.value,
          entityType: "special_agreement",
          eaccount: idEnterprise,
        },
        doAfterSuccess: (data) => {
          if (!data.success && !data.errorWS) {
            message("error", "Error", data.message);
            let aux = temporalContract[index];
            aux.corporateClientId = "";
            aux.contractId = "";
            aux.populationId = "";
            aux.crtId = "";
            aux.wsValidated = 0;
            // //console.log(data, "data");
            temporalContract[index] = aux;

            temporalContract[index].WSresults = {
              errorWS: data?.errorWS,
              success: data?.success,
              message: data?.message,
              results: data?.results,
            };

            setContracts(temporalContract);
          }
          if (data.errorWS === true) {
            message("error", "Validador de derechos no disponible", "Por favor complete los datos de la aseguradora manualmente");
            temporalContract[index].WSresults = {
              errorWS: data?.errorWS,
              success: data?.success,
              message: data?.message,
              results: data?.results,
            };
            setContracts(temporalContract);
          }
          if (data.errorWS === false && data.success === true) {
            temporalContract[index].contractId = data.results.info.contractId;
            temporalContract[index].populationId = data.results.info.populationId;
            temporalContract[index].crtId = data.results.info.crtId;
            temporalContract[index].WSresults = {
              errorWS: data?.errorWS,
              success: data?.success,
              message: data?.message,
              results: data?.results,
            };
            setContracts(temporalContract);
          }
          if (data.errorWS === false && data.success === true && data.results.affiliate === false) {
            message("error", "Validador de derechos", data.message);
            temporalContract[index].WSresults = {
              errorWS: data?.errorWS,
              success: data?.success,
              message: data?.message,
              results: data?.results,
            };
            setContracts(temporalContract);
          }
        },
        doAfterException: (error) => {
          temporalContract[index].contractId = "";
          temporalContract[index].populationId = "";
          if (error?.errorWS === true) {
            message("error", "Validador de derechos no disponible", "Por favor complete los datos de la aseguradora manualmente");
            temporalContract[index].WSresults = {
              errorWS: error?.errorWS,
              success: error?.success,
              message: error?.message,
              results: error?.results,
            };
            setContracts(temporalContract);
          }
          // //console.log("error", error);

          temporalContract[index].WSresults = {
            errorWS: error?.errorWS,
            success: error?.success,
            message: error?.message,
            results: error?.results,
          };
          setContracts(temporalContract);
          if (error?.errorWS === false && error.success === true) {
            temporalContract[index].contractId = error?.results.info.contractId;
            temporalContract[index].populationId = error?.results.info.populationId;
            temporalContract[index].crtId = error?.results.info.crtId;
            temporalContract[index].WSresults = {
              errorWS: error?.errorWS,
              success: error?.success,
              message: error?.message,
              results: error?.results,
            };
            setContracts(temporalContract);
          }
          if (error?.errorWS === false && error.success === true && error?.results.affiliate === false) {
            message("error", "Validador de derechos", error?.message);
            setWebScrapper(temporalWebScrapper);
            temporalContract[index].WSresults = {
              errorWS: error?.errorWS,
              success: error?.success,
              message: error?.message,
              results: error?.results,
            };
            setContracts(temporalContract);
          }
        },
      });
    } else {
      getContractPrepaidVoluntary(e.value);
    }
    contracts[index].contractId = "";
    setContracts(temporalContract);
  };

  const onChangeInsureObligatory = (e, index) => {
    getContractsEps(e.value);
    temporalContract[index].corporateClientId = e.value;
    setContracts(temporalContract);
    getValidateWeb({
      url: "/medical/appointment/validateInfoWebscrapper/",
      token: token,
      objFilters: {
        ...validateWebScrapper,
        entityId: e.value,
        entityType: "eps",
        eaccount: idEnterprise,
      },
      doAfterSuccess: (data) => {
        if (!data.success && !data.errorWS) {
          message("error", "Error", data.message);
          temporalContract[index].corporateClientId = "";
          temporalContract[index].contractId = "";
          temporalContract[index].populationId = "";
          temporalContract[index].crtId = "";
          temporalContract[index].wsValidated = 0;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
        }
        if (data.errorWS === true) {
          message("error", "Validador de derechos no disponible", "Por favor complete los datos de la aseguradora manualmente");
          temporalContract[index].wsValidated = 0;

          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
        if (data.errorWS === false && data.success === true) {
          temporalContract[index].contractId = data.results.info.contractId;
          temporalContract[index].populationId = data?.results?.info?.populationId;
          temporalContract[index].crtId = data?.results?.info?.crtId;
          temporalContract[index].wsValidated = 1;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
        if (data.errorWS === false && data.success === true && data.results.affiliate === false) {
          message("error", "Validador de derechos", data.message);
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
      },
      doAfterException: (data) => {
        if (!data?.success && !data?.errorWS) {
          message("error", "Error", data?.message);
          temporalContract[index].corporateClientId = "";
          temporalContract[index].contractId = "";
          temporalContract[index].populationId = "";
          temporalContract[index].crtId = "";
          temporalContract[index].wsValidated = 0;

          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
        if (data?.errorWS === true) {
          message("error", "Validador de derechos no disponible", "Por favor complete los datos de la aseguradora manualmente");
          temporalContract[index].wsValidated = 0;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
        if (data?.errorWS === false && data?.success === true) {
          temporalContract[index].contractId = data.results.info.contractId;
          temporalContract[index].populationId = data?.results?.info?.populationId;
          temporalContract[index].crtId = data?.results?.info?.crtId;
          temporalContract[index].wsValidated = 1;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
        if (data?.errorWS === false && data?.success === true && data?.results?.affiliate === false) {
          message("error", data?.message, "");
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
        dispatch(
          province_get({
            entity_account: counter.loginReducer.currentAccount.id,
          })
        );
      },
      succesAction: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
        dispatch(
          province_get({
            entity_account: counter.loginReducer.currentAccount.id,
          })
        );
      }
    });
    contracts[index].contractId = "";
    contracts[index].populationId = "";
    contracts[index].crtId = "";
    setContracts(temporalContract);
  };

  // //console.log("contracts: ", contracts);
  const onChangeCompanyType = (e, index) => {
    const temporalContract = contracts;
    temporalContract[index].companyTypeTag = e.value;
    contracts[index].corporateClientId = "";
    contracts[index].contractId = "";
    contracts[index].WSresults = {};
    getInsurersVoluntary(e.value);
    getContractPrepaidVoluntary(e.value); // CLEANING CONTRACT OPTIONS
    setContracts(temporalContract);
  };
  // //console.log("webScrapper: ", webScrapper);

  return (
    <>
      {(contractsLoader || loadValidateWeb || poblationsLoader || loadCreatePatient || loading || contractsLoaderVolunray) && loader}
      <div className={styles.app_container_register_app}>
        <div
          className={styles.app_container_close_app}
          onClick={() => {
            setOptions({
              ...options,
              showSearchPatient: true,
              showRegisterPatient: false,
            });
            setFilters({ filterPatient: "" });
            setTrigger(trigger + 1);
          }}
        >
          <div className={styles.app_close} />
        </div>
        <div className={styles.app_container_register_patient}>
          {/* ANCHOR  Incripción del paciente */}
          <div style={{ marginBottom: "10px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span className={styles.app_title_register_app} style={{ fontSize: "40px" }}>
                Registrar paciente
              </span>
            </div>
            <div className={styles.app_container_form_register}>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="docType">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Tipo de documento <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={formatTypeDocument}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    value={formatTypeDocument.find((e) => e.value === newPatient.documentType)}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        documentType: e.value,
                        contracts: [],
                      });
                      setValidateWebScrapper({
                        ...validateWebScrapper,
                        docType: e.extra,
                      });
                    }}
                    styles={appCustomSelectNew}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="fN">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Número de documento <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.documentNumber}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        documentNumber: e.target.value,
                      });
                      setValidateWebScrapper({
                        ...validateWebScrapper,
                        doc: e.target.value,
                      });
                    }}
                    onBlur={() => {
                      getValidateExistence({
                        url: "/medical/patient/validateExistence/",
                        token: token,
                        objFilters: {
                          documentType: newPatient.documentType,
                          documentNumber: newPatient.documentNumber,
                        },
                        doAfterSuccess: (data) => {
                          if (data.rowTotal > 0) message("error", `${data.title}`, data.message);
                        },
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Primer Nombre <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        firstName: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="sName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Segundo Nombre </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        secondName: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="fLs">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Primer Apellido <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        firstSurname: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="sfr">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Segundo Apellido </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        secondSurname: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="bombo">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Fecha de nacimiento <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="date"
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        birthDate: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="age">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Edad <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    placeholder="Escribe..."
                    value={`${newPatient.birthDate === "" ? "0" : getAge(newPatient.birthDate)} años`}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="titi">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      WhatsApp <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    placeholder="Escribe..."
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        whatsapp: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="me">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Género <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={genderList}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    value={genderList.find((x) => x.value === newPatient.gender)}
                    onChange={(e) => {
                      setNewPatient({ ...newPatient, gender: e.value });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="ask">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Correo electrónico <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="email"
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        email: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="otra">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Estado civil </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={maritalStatusList}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    value={maritalStatusList.find((x) => x.value === newPatient.maritalStatus)}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        maritalStatus: e.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          {/* ANCHOR Información complementaria */}
          <div style={{ marginBottom: "10px" }}>
            <div>
              <span className={styles.app_subtitle_register_app}>Información complementaria</span>
            </div>
            <div className={styles.app_container_form_register}>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="toto">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Departamento </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={provincesOptions}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    value={provincesOptions.find((x) => x.value === newPatient.provinceId)}
                    onChange={(e) => {
                      setNewPatient({ ...newPatient, provinceId: e.value, cityId: "" });
                      getSelectCities(e.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="to">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Municipio </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    value={citiesOptions.find((x) => x.value === newPatient.cityId)}
                    options={citiesOptions}
                    className="text-secondary"
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        cityId: e.value,
                      });
                    }}
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="tu">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Dirección </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        homeAddress: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="vip">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Grupo sanguíneo </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={bloodType}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    value={bloodType.find((x) => x.value === newPatient.bloodType)}
                    styles={appCustomSelectNew}
                    onChange={(e) => {
                      setNewPatient({ ...newPatient, bloodType: e.value });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="self">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Celular <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    placeholder="Escribe..."
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        phoneNumber: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="chis">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Teléfono fijo </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    placeholder="Escribe..."
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        homephone: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          {/* ANCHOR Aseguradora Obligatoria */}
          {contracts.map((item, index) => {
            if (index === 0)
              return (
                <div className={styles.app_container_register_insurance}>
                  <div>
                    <span className={styles.app_subtitle_register_app}>Aseguradora obligatoria</span>
                  </div>
                  <div className={styles.app_container_form_register}>
                    <div className={styles.app_container_content_form}>
                      <Form.Group controlId="jum">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b className="px-1">Empresa</b>
                          <span style={{ color: "#F3E882" }}>*</span>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          options={formatedEntityObligatory}
                          styles={appCustomSelectNew}
                          placeholder={"Seleccionar..."}
                          value={formatedEntityObligatory.find((x) => x.value === contracts[index].corporateClientId)}
                          onChange={(e) => {
                            onChangeInsureObligatory(e, index);
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                      {contracts[index].WSresults?.errorWS === true ? (
                        <>
                          <Form.Group controlId="pero">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Contrato</b>
                              <span style={{ color: "#F3E882" }}>*</span>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                              options={formatContracts}
                              styles={appCustomSelectNew}
                              placeholder={"Seleccionar..."}
                              value={formatContracts.find((x) => x.value === contracts[index].contractId)}
                              onChange={(e) => {
                                temporalContract[index].contractId = e.value;
                                setContracts(temporalContract);
                                getContractPoblationsObligatory(e.value);
                                contracts[index].populationId = "";
                                contracts[index].crtId = "";
                              }}
                            />
                          </Form.Group>
                        </>
                      ) : (
                        <Form.Group controlId="no">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className="px-1">Contrato</b>
                            <span style={{ color: "#F3E882" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type="text"
                            disabled
                            value={contracts[index]?.WSresults?.results?.info ? contracts[index].WSresults?.results?.info?.contractDescription : "-"}
                            onChange={() => {
                              temporalContract[index].contractId = validateWeb?.results?.info?.contractId;
                            }}
                            placeholder="Escribe..."
                          />
                        </Form.Group>
                      )}
                    </div>
                    <div className={styles.app_container_content_form}>
                      {contracts[index].WSresults?.errorWS === true ? (
                        <>
                          <Form.Group controlId="can">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Población</b>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                              options={formatPoblationsObligatory}
                              styles={appCustomSelectNew}
                              placeholder={"Seleccionar..."}
                              value={formatPoblationsObligatory.find((x) => x.value === contracts[index].populationId)}
                              onChange={(e) => {
                                temporalContract[index].populationId = e.value;
                                setContracts(temporalContract);
                                contracts[index].crtId = "";
                              }}
                            />
                          </Form.Group>
                        </>
                      ) : (
                        <Form.Group controlId="sorry">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className="px-1">Población</b>
                            <span style={{ color: "#F3E882" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type="text"
                            disabled
                            value={contracts[index]?.WSresults?.results?.info ? contracts[index]?.WSresults?.results?.info?.population : "-"}
                            onChange={() => {
                              temporalContract[index].populationId = validateWeb?.results?.info?.contractId;
                            }}
                            placeholder="Escribe..."
                          />
                        </Form.Group>
                      )}
                    </div>
                    <div className={styles.app_container_content_form}>
                      {contracts[index].WSresults?.errorWS === true ? (
                        <>
                          <Form.Group controlId="trust">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Grupo de ingreso</b>
                              <span style={{ color: "#F3E882" }}>*</span>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                              options={formatedIncomeGrupo}
                              styles={appCustomSelectNew}
                              placeholder={"Seleccionar..."}
                              value={formatedIncomeGrupo.find((x) => x.value === contracts[index].crtId)}
                              onChange={(e) => {
                                temporalContract[index].crtId = e.value;
                                setContracts(temporalContract);
                              }}
                            />
                          </Form.Group>
                        </>
                      ) : (
                        <Form.Group controlId="new">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className="px-1">Grupo de ingreso</b>
                            <span style={{ color: "#F3E882" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type="text"
                            disabled
                            value={contracts[index]?.WSresults?.results?.info ? contracts[index]?.WSresults?.results?.info?.crtName : "-"}
                            placeholder="Escribe..."
                          />
                        </Form.Group>
                      )}
                    </div>
                  </div>
                </div>
              );
          })}
          {/* ANCHOR VOLUNTARIA */}
          {contracts?.length > 0 &&
            contracts?.map((item, index) => {
              if (index > 0)
                return (
                  <div key={index} className={styles.app_container_insurance_voluntary}>
                    <div className={styles.container_title_insurer_voluntary}>
                      <span className={styles.app_insurer_voluntary}>Aseguradora voluntaria</span>
                      <div>
                        <img
                          src={closeInsurer}
                          alt="close"
                          className="cursorPointer"
                          width={13}
                          style={{ color: "#A3BAD1" }}
                          onClick={() => {
                            deleteInsurer(index);
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.app_container_form_register}>
                      <div className={styles.app_container_content_form}>
                        <Form.Group controlId="Ftype">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className="px-1">Tipo de empresa</b>
                          </Form.Label>
                          <Select noOptionsMessage={() => 'No hay datos'}
                            options={companyTypeTag}
                            styles={appCustomSelectNew}
                            value={companyTypeTag.find((x) => x.value === contracts.companyTypeTag)}
                            placeholder={"Seleccionar..."}
                            onChange={(e) => {
                              onChangeCompanyType(e, index);
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className={styles.app_container_content_form}>
                        <Form.Group controlId="Company">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className="px-1">Empresa</b>
                          </Form.Label>
                          <Select noOptionsMessage={() => 'No hay datos'}
                            options={formatedInsurerVoluntary}
                            styles={appCustomSelectNew}
                            placeholder={"Seleccionar..."}
                            value={formatedInsurerVoluntary.find((x) => x.value === contracts[index].corporateClientId)}
                            onChange={(e) => {
                              onChangeInsureVoluntary(e, index);
                            }}
                          />
                        </Form.Group>
                      </div>
                      {/* ANCHOR CONTRACT */}
                      <div className={styles.app_container_content_form}>
                        {contracts[index].companyTypeTag === "special_agreement" ? (
                          contracts[index]?.WSresults?.errorWS === true ? (
                            <Form.Group controlId="fcontr">
                              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className="px-1">Contrato </b>
                              </Form.Label>
                              <Select noOptionsMessage={() => 'No hay datos'}
                                options={contractsVoluntaryOptions}
                                styles={appCustomSelectNew}
                                placeholder={"Seleccionar..."}
                                value={contractsVoluntaryOptions.find((x) => x.value === contracts[index].contractId)}
                                onChange={(e) => {
                                  const temporalContract = contracts;
                                  temporalContract[index].contractId = e.value;
                                  setContracts(temporalContract);
                                  getContractPoblations(e.value);
                                }}
                              />
                            </Form.Group>
                          ) : (
                            <Form.Group controlId="fidk">
                              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className="px-1">Contrato </b>
                                <span style={{ color: "#F3E882" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                className={styles.app_inputs_detail_patient}
                                type="text"
                                disabled
                                value={contracts[index]?.WSresults?.results?.info ? contracts[index]?.WSresults?.results?.info?.contractName : "-"}
                                onChange={() => {
                                  temporalContract[index].contractId = validateWeb?.results?.info?.contractId;
                                }}
                                placeholder="Escribe..."
                              />
                            </Form.Group>
                          )
                        ) : (
                          <Form.Group controlId="fbb">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Contrato</b>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                              options={contractsVoluntaryOptions}
                              styles={appCustomSelectNew}
                              placeholder={"Seleccionar..."}
                              value={contractsVoluntaryOptions.find((x) => x.value === contracts[index].contractId)}
                              onChange={(e) => {
                                const temporalContract = contracts;
                                temporalContract[index].contractId = e.value;
                                setContracts(temporalContract);
                              }}
                            />
                          </Form.Group>
                        )}
                      </div>
                      <div className={styles.app_container_content_form}>
                        {contracts[index].companyTypeTag === "special_agreement" ? (
                          contracts[index]?.WSresults?.errorWS === true ? (
                            <Form.Group controlId="frm2">
                              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className="px-1">Población</b>
                                <span style={{ color: "#F3E882" }}>*</span>
                              </Form.Label>
                              <Select noOptionsMessage={() => 'No hay datos'}
                                options={formatPoblations}
                                styles={appCustomSelectNew}
                                placeholder={"Seleccionar..."}
                                value={formatPoblations.find((x) => x.value === contracts[index].populationId)}
                                onChange={(e) => {
                                  temporalContract[index].populationId = e.value;
                                  setContracts(temporalContract);
                                }}
                              />
                            </Form.Group>
                          ) : (
                            <Form.Group controlId="spc1">
                              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className="px-1">Población</b>
                                <span style={{ color: "#F3E882" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                className={styles.app_inputs_detail_patient}
                                type="text"
                                disabled
                                value={validateWeb?.results?.info ? validateWeb?.results?.info?.population : "-"}
                                placeholder="Escribe..."
                              />
                            </Form.Group>
                          )
                        ) : (
                          <Form.Group controlId="knj2">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">No. de Póliza</b>
                            </Form.Label>
                            <Form.Control
                              className={styles.app_inputs_detail_patient}
                              type="text"
                              placeholder="Escribe..."
                              onChange={(e) => {
                                const temporalContract = contracts;
                                temporalContract[index].policy = e.target.value;
                                setContracts(temporalContract);
                              }}
                            />
                          </Form.Group>
                        )}
                      </div>
                    </div>
                  </div>
                );
            })}

          <div className={styles.app_container_icon_voluntary}>
            <div
              style={{
                gap: "0.6rem",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                addInsurer();
              }}
            >
              <div className={styles.btn_add_insurer} />
              <span className={styles.app_title_insurer_voluntary}>Agregar aseguradora voluntaria</span>
            </div>
            <button
              className={styles.btn_save_patient}
              onClick={(e) => {
                onSubmitPatient(e);
              }}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", backgroundColor: "#F6FEFF" }}></div>
    </>
  );
};

export default RegisterPatient;
