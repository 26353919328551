import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


import ModalNew from "../../Layouts/ModalNew";
import payrollStyles from "../payrollStyles.module.scss";
import { convertFilterToString } from "../../../helpers/convertToFilter";

import { customSelectNew, customSelectNewDark } from "../../Layouts/react-select-custom";

import tableStyles from "../../Layouts/tableStyle.module.scss";
import Lista from "../../../assets/img/icons/Lista.svg";
import { Col, Row } from "react-bootstrap";
import { costcenterReducer } from "../../../reducers/costcenterReducer";
import { getCalendarMonthFull } from "../../../actions/PlanningMedicalExamsAction";

import {
    SELECT_CALENDAR_MEDICAL_EXAMS,
} from "../../../actions/actionTypes";
import SelectComponent from "../../SelectComponent/SelectComponent";

function PlanningMedicalExams(props) {
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const history = useHistory();
    const [trigger, setTrigger] = useState(0);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        month: undefined,
        year: undefined,
    });
    const today = new Date();
    const currYear = today.getFullYear();
    const month = today.getMonth() + 1;
    useEffect(() => {
        dispatch(getCalendarMonthFull(
            {
                year: !!filter.year ? filter.year : currYear,
                month: !!filter.month ? filter.month : month
            }
        ))
    }, [trigger])
    const optionMonth = [
        { value: "", label: "Seleccionar..." },
        { value: "1", label: "Enero" },
        { value: "2", label: "Febrero" },
        { value: "3", label: "Marzo" },
        { value: "4", label: "Abril" },
        { value: "5", label: "Mayo" },
        { value: "6", label: "Junio" },
        { value: "7", label: "Julio" },
        { value: "8", label: "Agosto" },
        { value: "9", label: "Septiembre" },
        { value: "10", label: "Octubre" },
        { value: "11", label: "Noviembre" },
        { value: "12", label: "Diciembre" },
    ]
    const optionYear = [{value: "", label: "Seleccionar..."}]
    for (let step = 2020; step < 2050; step++) {
        optionYear.push(
            { value: step, label: step }
        )
    }

    const changesMonth = (e) => {
        setFilter({
            ...filter,
            month: e.value
        })
        setTrigger(trigger + 1)
    }
    const changesYear = (e) => {
        setFilter({
            ...filter,
            year: e.value
        })
        setTrigger(trigger + 1)
    }

    return (
        <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>

            <h1 className={tableStyles.title} style={{ marginBottom: 0 }}>Planeación de Exámenes Médicos</h1>

            <div className={`d-flex`}>
                <div className="col-2 display-grid">
                    <label className={tableStyles.crudModalLabel}>Mes</label>
                    <SelectComponent
                        // key={"provider" + trigger}
                        placeholder="Seleccionar..."
                        options={optionMonth}
                        styles={customSelectNewDark}
                        onChange={(e) =>
                            changesMonth(e)
                        }
                        value={optionMonth.find((x)=> x.value === filter.month)}

                    />
                </div>
                <div className="col-2 display-grid" style={{ paddingLeft: "1rem" }}>
                    <label className={tableStyles.crudModalLabel}>Año</label>
                    <SelectComponent
                        placeholder="Seleccionar..."
                        options={optionYear}
                        styles={customSelectNewDark}
                        onChange={(e) =>
                            changesYear(e)
                        }
                        value={optionYear.find((x)=> x.value === filter.year)}

                    />
                </div>
                <div style={{ flex: 1 }}></div>


                <div className={`${tableStyles.iconWrap} hoverPointer`} style={{ alignSelf: "end", height: "max-content" }}>
                    <span className={`${tableStyles.iconLabel} `} >Histórico de Exámenes</span>
                </div>
                <div onClick={() => props.setScreenControl(1)} className={`${tableStyles.iconWrap} hoverPointer`} style={{ marginLeft: 10, alignSelf: "end", height: "max-content" }}>
                    <span className={`${tableStyles.iconLabel}`}>Ver Listado</span>
                    <img style={{ width: "20px" }} src={Lista} alt="Form" />
                </div>

            </div>
            <div className="container-month mt-5">
                <div className="container-nameday d-flex flex-nowrap">
                    <div className={payrollStyles.name_day} style={{ borderRadius: "10px 0 0 0" }}><p>Dom</p></div>
                    <div className={payrollStyles.name_day}><p>Lun</p></div>
                    <div className={payrollStyles.name_day}><p>Mar</p></div>
                    <div className={payrollStyles.name_day}><p>Mié</p></div>
                    <div className={payrollStyles.name_day}><p>Jue</p></div>
                    <div className={payrollStyles.name_day}><p>Vie</p></div>
                    <div className={payrollStyles.name_day} style={{ borderRadius: "0 10px 0 0" }}><p>Sáb</p></div>
                </div>
                <div className={payrollStyles.containerDay}>
                    {
                        counter?.PlanningMedicalExamsReducer?.results?.map((item) => (
                            < div className={`${payrollStyles.number_day}`} >
                                <p className="text-day">
                                    {item.day}
                                </p>
                                {item?.data.length >= 1 ?
                                    <div className={payrollStyles.day_icons} >
                                        {item?.data?.map((x) => (
                                            <div
                                                title={x.area_name}
                                                onClick={() => {
                                                    //alert("en mantenimiento")
                                                    dispatch({
                                                        type: SELECT_CALENDAR_MEDICAL_EXAMS,
                                                        //...x,
                                                        id: x.id,
                                                        area: x.area_name,
                                                    });
                                                    history.push("/nomina/examenesMedicosDetalles/");
                                                }}
                                            >
                                                {x.area_name}
                                            </div>
                                        ))}
                                    </div> : ""}
                            </div>
                        ))
                    }
                </div>


            </div>
        </div >
    );

}

export default PlanningMedicalExams;