import styled from "styled-components";


export const Button = styled.button`
    font-size: ${({ fontSize, theme }) => fontSize ? fontSize : theme.sizes.title.sx};
    padding: ${({ padding }) => padding ? padding : '0px'};
    order: ${({ order }) => order ? order : 'auto'};
    margin: ${({ margin }) => margin ? margin : '0px'};
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 'bolder'};
    width:  ${({ width }) => width ? width : '120px'};
    border:  ${({ border, theme }) => border ? border : `1px solid ${theme.colors.ordAquaMarine}`};
    border-radius:  ${({ borderRadius }) => borderRadius ? borderRadius : '6px'};
    color:  ${({ color, theme }) => color ? color : 'white'};
    background-color:  ${({ backgroundColor, theme }) => backgroundColor ? backgroundColor : theme.colors.ordDarkBlueText};
    border-bottom-right-radius:${({ borderBottomRightRadius }) => borderBottomRightRadius ? borderBottomRightRadius : 'auto'};
    border-top-right-radius:${({ borderTopRightRadius }) => borderTopRightRadius ? borderTopRightRadius : 'auto'};
    border-right:  ${({ borderRight, theme }) => borderRight ? borderRight : 'auto'};
    outline:  0;
    transition: all 0.3s;
    
    
    :focus{
        box-shadow:  ${({ boxShadow, theme }) => boxShadow ? boxShadow : `0px 0px 1px ${theme.colors.ordDarkGray}`};
    }
    
    :hover{
        color:  ${({ colorHover, theme }) => colorHover ? colorHover : 'white'};
        background-color:  ${({ backgroundColorHover, theme }) => backgroundColorHover ? backgroundColorHover : theme.colors.ordAquaMarine};
        opacity:  ${({ opacityHover, theme }) => opacityHover ? opacityHover : ''};
    }

    :disabled{
        background-color: #cecece !important;
        border: 1.5px solid #cecece !important;
        color: #fff !important;
        opacity: 1 !important;
    }
`
