// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lupa from "../../assets/img/icons/lupa.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import ModalNew from "../../components/Layouts/ModalNew";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";

// ------------------ Functions ----------------------
import { getNiifAccounts } from "../../actions/pucActions";
import { getInfoPayroll, getPeriodsPayroll, savePay } from "../../actions/payrollActions";
import { GET_INFO_PAYROLL } from "../../actions/actionTypes";


//-------------- scss styles 😄   --------------
import GenericTableScroll from "../../components/Layouts/GenericTableScroll";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { Title } from "../../shared";



function Payroll(props) {
    const dispatch = useDispatch();
    const storage = useSelector(state => state);
    const [trigger, setTrigger] = useState(1);
    const history = useHistory();
    let today = new Date().toISOString().slice(0, 10)
    const [info, setInfo] = useState({
        search: "",
        niifAccount: "",
        date: today,
        period: "",

    });
    //--------------myPermission 🍕   --------------

    const myPermission = () => storage.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "payroll");

    useEffect(() => {
        if (!myPermission()?.read || !myPermission()?.create) {
            history?.push("/tesoreria/inicio");
        }
    }, []);

    //--------------first functions 🍕   --------------
    useEffect(() => {
        if (info.period != "" && info.niifAccount != "") {
            dispatch({
                type: GET_INFO_PAYROLL,
                payload: [],
            });
            dispatch(getInfoPayroll({
                // period: info.idDocRef,
                period: info.period,
                eaccount: storage.loginReducer.currentAccount.id,
            }));

        }

    }, [info.period, info.niifAccount]);

    useEffect(() => {
        dispatch(getNiifAccounts({ eaccount: storage.loginReducer.currentAccount.id, check_info_bank: 1, active: 1 }));
        dispatch(getPeriodsPayroll({ eaccount: storage.loginReducer.currentAccount.id }));
    }, [trigger]);


    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }

    //-------------- Table 🍕   -------------

    const header = [
        <div className={`col-2`}>Cuenta</div>,
        <div className={`col-6 text-start pl-1`}>Detalle</div>,
        <div className={`col-2 text-end px-2`}>DEBE</div>,
        <div className={`col-2 text-end px-3`}>HABER</div>
    ]
    const renderList = () => {
        let tempList = [];
        if (Array.isArray(storage.payrollReducer.infoPayroll[0]?.detail)) {
            storage.payrollReducer.infoPayroll[0].detail.map((item, index) => {
                tempList.push(
                    <section2 className={`d-flex`}>
                        <div className="col-2" >{item.account_nbr}</div>
                        <div className="col-6 text-start pl-1">{item.full_name}</div>
                        <div className="col-2 text-end px-1" >{!!item.debit_money ? "$" + numberWithCommas(item.debit_money) : ""}</div>
                        <div className="col-2 text-end px-2">{!!item.credit_money ? "$" + numberWithCommas(item.credit_money) : ""}</div>

                    </section2>

                );
            })

            tempList.push(
                <section2 className={`d-flex`}>
                    <div className="col-2" >{info.accountNbr}</div>
                    <div className="col-6 text-start pl-1">{info.accountName}</div>
                    <div className="col-2 text-end p-1" >{"$" + 0}</div>
                    <div className="col-2 text-end p-1">{!!storage.payrollReducer.infoPayroll[0]?.total_amount ? "$" + numberWithCommas(storage.payrollReducer.infoPayroll[0].total_amount) : ""}</div>

                </section2>

            );

        }
        return tempList;
    }


    //-------------- select options 🍕   --------------

    let optionsAccount = [{ key: "", value: "", label: "Seleccionar..." }];
    if (Array.isArray(storage.pucReducer.niifAccounts)) {
        storage.pucReducer.niifAccounts.map((item, index) => {
            optionsAccount.push({
                value: item.id,
                label: item.complete_account + " - " + item.description,
                key: item.complete_account,
                name: item.description,
            });
        });
    }

    let optionsPeriods = [{ key: "", value: "", label: "Seleccionar..." }];
    if (Array.isArray(storage.payrollReducer.listPeriodsPayroll)) {
        storage.payrollReducer.listPeriodsPayroll.map((item, index) => {
            optionsPeriods.push({
                value: item.id,
                label: item.period,
                key: index + "account",
            });
        });
    }

    // --------------------------
    const btnSave = () => {
        dispatch(
            savePay(
                {
                    eaccount: storage.loginReducer.currentAccount.id,
                    user: storage.loginReducer.user_data.id,
                    payType: "2",
                    date: info.date,
                    idDocRef: info.idDocRef,
                    payMean: 1,
                    niifAccount: info.niifAccount,
                    accountNbr: info.accountNbr,
                    totalAmount: storage.payrollReducer.infoPayroll[0]?.total_amount,
                    observations: storage.payrollReducer.infoPayroll[0]?.details,
                    status: 1

                },
                () => {
                    setTrigger(trigger + 1)
                    setInfo({
                        search: "",
                        niifAccount: "",
                        date: today,
                        period: "",
                    })
                    dispatch({
                        type: GET_INFO_PAYROLL,
                        payload: [],
                    });
                }

            )
        );
    }


    return (
        <>
            <div 
                className={` ml-5 ${tableStyles.container}`} 
                style={{ marginRight: "3rem", marginTop:'30px' }}
            >
                <Title
                    title="Pago de nómina"
                    className={'mb-2'}
                    onClickIcon={() => {
                        history.push('/tesoreria/inicio')
                    }}
                />
                <div>
                    <div className="div">
                        <div className="col-4 display-grid" style={{ paddingRight: "1rem" }}>
                            <label className={tableStyles.crudModalLabel}>Cuenta contable*</label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                key={"account" + trigger}
                                placeholder={"Seleccionar..."}
                                value={optionsAccount.find(
                                    (item) => item.value === info.niifAccount
                                )}
                                options={optionsAccount}
                                styles={customSelectNewDark}
                                onChange={(e) =>
                                    setInfo({
                                        ...info,
                                        niifAccount: e.value,
                                        accountNbr: e.key,
                                        accountName: e.name,
                                    })
                                }
                            />
                        </div>
                        <div className="col-3 display-grid">
                            <label className={tableStyles.crudModalLabel}>Fecha</label>
                            <input
                                className={IndividualStyles.registerInputsBlue}
                                type="date"
                                name=""
                                id=""
                                value={info.date}
                                // onChange={(e) =>
                                //     setInfo({
                                //         ...info,
                                //         date: e.target.value,
                                //     })
                                // }
                                disabled={true}
                                max={today}

                            />
                        </div>
                        <div className="col-5 display-grid" style={{ paddingLeft: "1rem" }}>
                            <label className={tableStyles.crudModalLabel} >Periodo</label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                key={"period" + trigger}
                                options={optionsPeriods}
                                placeholder={"Seleccionar..."}
                                styles={customSelectNewDark}
                                value={optionsPeriods.find(
                                    (item) => item.value === info.idDocRef
                                )}
                                onChange={(e) =>
                                    setInfo({
                                        ...info,
                                        period: e.label,
                                        idDocRef: e.value
                                    })
                                }
                            />
                        </div>

                    </div>

                    <div className="div">
                        <div className="col-7 display-grid">
                            <label className={tableStyles.crudModalLabel}>Detalle*</label>
                            <input
                                key={"detail" + trigger}
                                className={IndividualStyles.registerInputs}
                                type="text"
                                value={!!storage.payrollReducer.infoPayroll[0]?.details ? storage.payrollReducer.infoPayroll[0]?.details : ""}
                                style={{ background: "#F5F7FA", color: "#58595B" }}
                                disabled={true}
                            />
                        </div>
                        <div className="col-5 display-grid" style={{ paddingLeft: "1rem" }}>
                            <label className={tableStyles.crudModalLabel}>Valor a pagar*</label>
                            <NumberFormat
                                key={"pay" + trigger}
                                style={{ color: "#58595B", background: "#F5F7FA", textAlign: "end" }}
                                className={IndividualStyles.registerInputs}
                                allowNegative={false}
                                prefix="$"
                                thousandsGroupStyle="thousand"
                                thousandSeparator
                                value={!!storage.payrollReducer.infoPayroll[0]?.total_amount ? storage.payrollReducer.infoPayroll[0]?.total_amount : ""}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <GenericTableScroll
                        headers={header}
                        dark={true}
                        body={renderList()}
                        typeHead={"2"}
                    // footer={footer3}
                    >
                    </GenericTableScroll>

                    <Row className="d-flex">
                        <Col xs={2}></Col>
                        <Col xs={6} style={{ textAlign: "center" }}>
                            <label style={{ color: "#58595B", fontWeight: "700", fontSize: "16px" }}>Totales</label>
                        </Col>
                        <Col xs={2} style={{ textAlign: "right" }}>
                            <label style={{ color: "#58595B", fontWeight: "700", fontSize: "16px", textAlign: "right" }}>{!!storage.payrollReducer.infoPayroll[0]?.total_amount ? "$" + numberWithCommas(storage.payrollReducer.infoPayroll[0]?.total_amount) : ""}</label>
                        </Col>
                        <Col xs={2} style={{ textAlign: "right" }}>
                            <label style={{ color: "#58595B", fontWeight: "700", fontSize: "16px", paddingRight: "0.25rem" }}>{!!storage.payrollReducer.infoPayroll[0]?.total_amount ? "$" + numberWithCommas(storage.payrollReducer.infoPayroll[0]?.total_amount) : ""}</label>
                        </Col>
                    </Row>
                </div>

                <Row className="d-flex">
                    <Col xs={8} style={{ paddingLeft: "29px" }}></Col>
                    <Col
                        xs={4}
                        className="d-flex"
                        style={{ justifyContent: "flex-end" }}
                    >
                        <button className={`${tableStyles.btnSecondary} mt-4`} onClick={() => {
                            setTrigger(trigger + 1);
                            dispatch({
                                type: GET_INFO_PAYROLL,
                                payload: [],
                            });
                            setInfo({
                                search: "",
                                niifAccount: "",
                                date: today,
                                period: "",
                            })
                        }}>
                            Cancelar
                        </button>
                        &nbsp;

                        <button
                            className={`${tableStyles.btnPrimary} mt-4`}
                            onClick={() => btnSave()}
                            disabled={
                                (info.period == "Seleccionar..." || !info.period)
                                || !info.niifAccount
                                || !info.date
                                || !info.idDocRef
                            }
                        >
                            Guardar
                        </button>
                    </Col>
                </Row>

            </div>

        </>
    )

};
export default Payroll;