import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DetailPatient from "./DetailPatient";
import { loader } from "../../helpers/helpers";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import arrayRight from "../../assets/img/icons/Arrow-gray.svg";
import styles from "../../components/Layouts/tableStyle.module.scss";
import toOrderImg from "../../assets/img/icons/img_appointment.svg";
import alertCircleRed from "../../assets/img/icons/alertCircleRed.svg";
import icon_search from "../../assets/img/icons/ordSearchClearBlue.svg";
import RegisterPatient from "./RegisterPatient";
import UpdatePatient from "./UpdatePatient";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getPatientInfo } from "../../actions/patientActions";

export const RegisterAppointment = () => {
  const [ticketValue, setTicketValue] = useState()
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const userId = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const location = useLocation()
  const [infoPatient, setInfoPatient] = useState(location?.state?.infoPatient && location?.state.infoPatient)
  const [procedure, setProcedure] = useState(location?.state?.procedure && location?.state.procedure)
  // //console.log("procedure: ", procedure);
  // //console.log("infoPatient: ", infoPatient);
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilters] = useState({
    filterPatient: infoPatient?.document ? infoPatient?.document : "",
    page: 1,
    perpage: 100,
    orderByType: "creation",
  });

  const [patient, setPatient] = useState({
    eaccount: idAccount,
  });

  const [options, setOptions] = useState({
    showCards: false,
    showImportance: false,
    showInfoPatient: false,
    showSearchPatient: true,
    showDetailPatient: false,
    showEditPatient: false,
    showProgramming: false,
    showSchedule: false,
    showRegisterPatient: false,
    header: [
      {
        title: "Fecha",
        className: "col-2 text-center",
      },
      {
        title: "Servicio",
        className: "col-3 text-start",
      },
      {
        title: "Estado",
        className: "col-2 text-center",
      },
      {
        title: <>&nbsp;</>,
        className: "col-1 text-end px-1",
      },
    ],
  });
  const { results: ticketResults, load: ticketLoader, trigger: getTicket } = useGetMethod();
  const { load: postTicketLoader, trigger: postTicket } = usePostMethod();
  const [triggerTicket, setTriggerTicket] = useState()
  useEffect(() => {

    getTicket({
      url: "/medical/appointment/concecutive/",
      objFilters: {
        idConsec: 23
      },
      token: token,
      doAfterSuccess: (res) => {
        setTicketValue(res.results)
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerTicket])



  const postTicketFunction = (tag, idApp) => {
    postTicket({
      url: "/medical/appointment/saveStepProcess/",
      method: "POST",
      body: {
        "consecutiveTicket": ticketValue,
        "userId": userId,
        "aleEvent": tag,
        appId: idApp ? idApp : [],
      },
      token: token,
      noAlert: true,
    });
  }


  useEffect(() => {

    getListPacient({
      url: "/medical/patient/listPatient/",
      objFilters: filters,
      token: token,
    });

    infoPatient && getIdPatient(infoPatient.id, infoPatient.alertId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const {
    results: listPatients,
    load: ListPacientLoader,
    trigger: getListPacient,
  } = useGetMethod();

  const {
    results: detailPatient,
    loader: detailLoaderPatient,
    trigger: getDetailPatient,
  } = useGetMethod();

  // ANCHOR LISTA DEL PACIENTE
  const renderList = () => {
    let tempList = [];
    if (Array.isArray(listPatients.results)) {
      listPatients.results.forEach((item, index) => {
        tempList.push(
          <>
            <div
              className={styles.app_container_card_list_patient}
              key={index}
              onClick={() => {
                getIdPatient(item.id, item.alertId);
                // setPatient({ ...patient, cum_id: item.alert });
              }}
            >
              <div className="d-flex flex-column m-0">
                <span className={styles.app_title_list}>
                  {item.first_name} {item?.other_names} {item.last_name}{" "}
                  {item?.second_surname}
                </span>
                <span className={styles.app_text_list}>
                  {item.docTypeDesc} {item.document}
                </span>
              </div>
              <img
                src={arrayRight}
                alt="right"
                width="10"
                className={`cursorPointer ${styles.appColorArrow}`}
              />
            </div>
          </>
        );
      });
    }
    return tempList;
  };

  const getIdPatient = (id, alert) => {
    const filters = {
      id: id,
      eaccount: idAccount,
    };
    getDetailPatient({
      url: "/medical/patient/",
      objFilters: filters,
      token: token,
    });
    setPatient({ ...patient, id: id, cum_id: alert });
    setFilters({ ...filters, idPatient: id, patient: id });
    setOptions({
      ...options,
      showCards: true,
      showDetailPatient: true,
      showSearchPatient: false,
    });
  };

  return (
    <>
      {ListPacientLoader && loader}
      {options.showDetailPatient && (
        <>
          {/* ANCHOR ruta => progrm */}
          {detailLoaderPatient && loader}
          <DetailPatient
            token={token}
            filters={filters}
            patient={patient}
            options={options}
            setOptions={setOptions}
            setPatient={setPatient}
            setFilters={setFilters}
            detailPatient={detailPatient}
            getListPacient={getListPacient}
            getDetailPatient={getDetailPatient}
            detailLoaderPatient={detailLoaderPatient}
            ListPacientLoader={ListPacientLoader}
            procedure={procedure}
            setInfoPatient={setInfoPatient}
            setProcedure={setProcedure}
            postTicketFunction={postTicketFunction}
            triggerTicket={triggerTicket}
            setTriggerTicket={setTriggerTicket}
            setTicketValue={setTicketValue}
          />
        </>
      )}
      {options.showEditPatient && (
        <>
          <UpdatePatient
            token={token}
            patient={patient}
            filters={filters}
            options={options}
            setOptions={setOptions}
            detailPatientData={detailPatient}
            getListPacient={getListPacient}
            detailLoaderPatient={detailLoaderPatient}
          />
        </>
      )}
      {options.showRegisterPatient && (
        <RegisterPatient
          token={token}
          filters={filters}
          options={options}
          trigger={trigger}
          setFilters={setFilters}
          setOptions={setOptions}
          setTrigger={setTrigger}
          getListPacient={getListPacient}
          getDetailPatient={getDetailPatient}
          postTicketFunction={postTicketFunction}
        />
      )}
      {options.showSearchPatient && (
        <>
          <div className={styles.app_container_list_app}>
            <div style={{ marginTop: "30px" }}>
              <div className={styles.app_container_search_app}>
                <div style={{ marginBottom: "20px" }}>
                  <span className={styles.app_title_register_app}>
                    Registro de Citas
                  </span>
                </div>
                <div className={styles.app_back_search}>
                  <span className={styles.app_title_search}>Buscar</span>
                  <div className={styles.app_container_icon_search}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        setTrigger(trigger + 1);
                      }}
                    >
                      <input
                        className={`${styles.app_input_search} p-2`}
                        type="text"
                        value={filters.filterPatient}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            filterPatient: e.target.value,
                            id: "",
                            idPatient: "",
                            patient: "",
                            return: true,
                          })
                        }
                      />
                    </form>
                    <img
                      className="hoverPointer"
                      width="27px"
                      alt="search"
                      src={icon_search}
                      onClick={(e) => {
                        setTrigger(trigger + 1);
                      }}
                    />
                  </div>
                </div>
                <div
                  className={styles.app_container_icon_new}
                  onClick={() => {
                    postTicketFunction("register_new_patient")
                    setOptions({
                      ...options,
                      showSearchPatient: false,
                      showRegisterPatient: true,
                    });
                  }}
                >
                  <div className={styles.tlnBtnNew} />
                  <span>Inscribir Nuevo Paciente</span>
                </div>
              </div>
              {listPatients.results?.length <= 0 ? (
                <div className={styles.app_container_alert_patient}>
                  <img src={alertCircleRed} alt="alert-cicle" />
                  <div className={styles.app_alert_patient}>
                    <span>Paciente no encontrado</span>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div className={styles.app_container_list_patient}>
                      {renderList()}
                    </div>
                    {listPatients.results?.length > 0 && (
                      <span className={`${styles.app_text_list} px-3 `}>
                        ({listPatients.rowTotal}) resultados
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="d-flex justify-content-center mb-3">
              <img alt="order" src={toOrderImg} width="270" />
            </div>
          </div>
          <div style={{ width: "100%", backgroundColor: "#F6FEFF" }} />
        </>
      )}
    </>
  );
};
