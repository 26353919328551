import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";

import { customSwaltAlert } from './../helpers/customSwaltAlert';
import { 
  GET_ACCOUNTS_PAYABLE,
  GET_PAY_CONDITIONS,
  GET_VAUCHER_HISTORY,
  GET_ONE_VAUCHER_HISTORY,
  GET_JOURNAL_TYPE,
  GET_THIRD_PARTIES,
  GET_CASH_FLOW,
  GET_TASK,
  GET_ONE_TASK,
  GET_EVENT_TYPE,
  LOADING
} from "./actionTypes";

export const getAccountsPayable = (objFilters, doAfter=null) => (dispatch, getCreate) => {
  dispatch({
    type: GET_ACCOUNTS_PAYABLE,
    loading: true,
  });
    const token = getCreate().loginReducer.Authorization;
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/pinvoices/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_ACCOUNTS_PAYABLE,
          payload: results,
          total: total,
          loading: false
        });
        if(!!results){
          if(!!doAfter){
            doAfter(results)
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ACCOUNTS_PAYABLE,
          payload: [],
          total: 0,
          loading: false
        });
        console.error(err.message);
      });
  };

  export const getPayConditions = (objFilters) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/treasury/pay_conditions/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_PAY_CONDITIONS,
          payload: results,
          total: total,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };


  export const getVaucherHistory = (objFilters) => (dispatch, getCreate) => {
    dispatch({
      type: GET_VAUCHER_HISTORY,
      loading: true,
    });
    const token = getCreate().loginReducer.Authorization;
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/journalVoucher/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count } = res;
        dispatch({
          type: GET_VAUCHER_HISTORY,
          payload: results,
          total: row_count,
          loading: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_VAUCHER_HISTORY,
          payload: [],
          total: 0,
          loading: false,
        });
        console.error(err.message);
      });
  };

  
export const getDetailOneVaucher = (objFilters, doAfter=null) => (dispatch, getCreate) => {
  const token = getCreate().loginReducer.Authorization;
  let filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/journalVoucher/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_ONE_VAUCHER_HISTORY,
        payload: results,
        total: total,
      });
      if(!!results){
        if(!!doAfter){
          doAfter(results)
        }
      }
    })
    .catch((err) => {
      console.error(err.message);
    });
};


export const getJournalType= (objFilters, doafter=null) => (dispatch, getCreate) => {
  const token = getCreate().loginReducer.Authorization;
  let filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/journalType/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if(doafter){
        doafter()
      }
      const { results, total } = res;
      dispatch({
        type: GET_JOURNAL_TYPE,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
      if(doafter){
        doafter()
      }
    });
};

export const listThirdParties = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/third_parties/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_THIRD_PARTIES,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const getCashFlow = (objFilters, doAfter=null) => (dispatch, getCreate) => {
  dispatch({
    type: GET_CASH_FLOW,
    loading: true,
  });
  const token = getCreate().loginReducer.Authorization;
  let filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/cashFlow/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_CASH_FLOW,
        payload: results,
        total: total,
        loading: false,
      });
      if(!!results){
        if(!!doAfter){
          doAfter(results)
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_CASH_FLOW,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};



export const getTask = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_TASK,
    loading: true
  });
  const token = getCreate().loginReducer.Authorization;
  let filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/taxTask/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_TASK,
        payload: results,
        total: total,
        loading: false
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_TASK,
        payload: [],
        total: 0,
        loading: false
      });
      console.error(err.message);
    });
};


  
export const getOneTask = (objFilters, doAfter=null) => (dispatch, getCreate) => {
  const token = getCreate().loginReducer.Authorization;
  let filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/taxTask/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_ONE_TASK,
        payload: results,
        total: total,
      });
      if(!!results){
        if(!!doAfter){
          doAfter(results)
        }
      }
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const getEventType = (objFilters) => (dispatch, getCreate) => {
  const token = getCreate().loginReducer.Authorization;
  let filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/taxTaskType/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_EVENT_TYPE,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};


export const createEvent = (data, doAfter) => (dispatch,getState) => {
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: LOADING,
    payload: true
})
  fetch(
      `${URL_GATEWAY}${API_VERSION}/accounting/taxTask/`,
      {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          
          token: token,
          },
      }
      )
      .then((response) => response.json())
      .then((res) => {
          if (res.success) {
            dispatch({
              type: LOADING,
              payload: true
          })
          customSwaltAlert({
              icon: "success",
              title: "Evento agregado exitosamente",
              text:`Se ha agregado el evento: ${res.results.name}`,
              confirmButtonText: "Aceptar",
              showCancelButton: false
          }).then((response)=>{
            if(response.isConfirmed){
              doAfter() 
            }else{
              doAfter()
            }
          })
          }else{
          customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              showCancelButton: false,
              confirmButtonText: "Aceptar"
          });
          }
      })
      .catch((res) => {
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: res.message,
            footer: "Si el problema persiste comuníquese con un asesor.",
            showCancelButton: false,
            confirmButtonText: "Aceptar"
          });
      });
  }

  
  export const finishEvent = (data, doAfter) => (dispatch,getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(
        `${URL_GATEWAY}${API_VERSION}/accounting/taxTask/changeStatus/`,
        {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            
            token: token,
            },
        }
        )
        .then((response) => response.json())
        .then((res) => {
            if (res.success) {
            customSwaltAlert({
                icon: "success",
                title: "Tarea finalizada exitosamente",
                text:`Se ha finalizado la tarea: ${res.results.title}`,
                showCancelButton: false,
                confirmButtonText: "Aceptar",
            }).then((response)=>{
              if(response.isConfirmed){
                doAfter() 
              }else{
                doAfter()
              }
            })
            }else{
            customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            });
            }
        })
        .catch((res) => {
            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              showCancelButton: false,
              confirmButtonText: "Aceptar"
            });
        });
    }