import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import IndividualStyles from "../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { responseSwal } from "../helpers/responseSwal";
import { 
  GET_COMPANIES, 
  GET_CONTRACTS_IN_BILLING,  
  GET_BILLING_ACOUNTABLE,
  GET_ONE_COMPANIES,  
  GET_SERVICES_OF_CONTRACTS,
  GLOSS_HISTORY_DET,
      
  GET_INVOICE_TYPE,
  GET_BILLING_HISTORY,
  GET_BILLING_HISTORY_STATUS,
  GET_BILLING_ACTS,
  GET_BILLING_ACTS_DETAILS,
  GET_RECEIPT_RECORDS,
  GET_RECEIPT_RECORDS_ONE,
  GET_SERVICES_IN_ADM,

  GET_TYPE_SERVICES_IN_ADM,
  GET_IVA,
  GET_ONE_SERVICES_IN_ADM,
  GET_LIST_STATUS,

  GET_TYPES_CONTRACTS,
  GET_REGION,
  GET_CIUU,
  GET_PAY_CONDITIONS,
  GET_PAY_METHODS,
  GET_DIVISA,
  GET_ONE_CONTRACT_IN_ADM,

  GET_CUPS,
  GET_CAUSES,
  GLOSS_HISTORY,
  STATUS_HISTORY,
  NOTE_TYPES, 
  OBJECTED_BY_CLIENT, 
  INFO_NOTES, 
  INVOICES_TYPES, 
  OBJECTION_TYPES, 
  INVOICES_TO_SENT,
  GET_ONE_CONTRACT,
  GET_SITES_IN_BILLING,
  GENERATE_INVOICE_LOADING,
  GENERATE_CAPTION_LOADING,
  SEND_INVOICES_LOADING,
  CREATE_NOTE_LOADING,
  TRANSFER_REGISTER,
  NW_CONTRACT_LOADING
} from "./actionTypes";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);

function responseError(message, action =null){
  if(!!action){
    action()
  }
  return responseSwal({icon:"error", title:"Error", text:message})
}

export const getListCompanies = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/client/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_COMPANIES,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const getOneListCompanies = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/client/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_ONE_COMPANIES,
          payload: results,
          total: total,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

export const getListContracts = (objFilters, doafter) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/contract/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if(doafter){
        doafter()
      }
      const { results, row_count } = res;
      dispatch({
        type: GET_CONTRACTS_IN_BILLING,
        payload: results,
        total:row_count
      });
    })
    .catch((err) => {
      if(doafter){
        doafter()
      }
      console.error(err.message);
    });
};

export const getListAcountable = (objFilters) => (dispatch, getCreate) => {
  //let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  const endpoint = `${URL_GATEWAY}${API_VERSION}/receivable/accountable/?type=receiptMinute`;
  fetch(endpoint, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: GET_BILLING_ACOUNTABLE,
        payload: results
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};


export const getOneListContract = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/contract/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_ONE_CONTRACT,
          payload: results,
          total: total,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };


export const getServices = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/contract/services/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results } = res;
        dispatch({
          type: GET_SERVICES_OF_CONTRACTS,
          payload: results
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };


  
export const getHistory = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_BILLING_HISTORY,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/invoice/history/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_count } = res;

      dispatch({
        type: GET_BILLING_HISTORY,
        payload: results,
        total: row_count,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err.message);
      dispatch({
        type: GET_BILLING_HISTORY,
        loading: false,
      });
    });
};


export const getReceiptRecord = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_RECEIPT_RECORDS,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/receiptRecord/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_count } = res;

      dispatch({
        type: GET_RECEIPT_RECORDS,
        payload: results,
        total: row_count,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err.message);
      dispatch({
        type: GET_RECEIPT_RECORDS,
        loading: false,
      });
    });
};


export const getReceiptRecordOne = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_RECEIPT_RECORDS_ONE,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/receiptRecord/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_count } = res;

      dispatch({
        type: GET_RECEIPT_RECORDS_ONE,
        payload: results,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err.message);
      dispatch({
        type: GET_RECEIPT_RECORDS_ONE,
        loading: false,
      });
    });
};



export const getHistoryStatus = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  //type=invoiceHis&eaccount=12?
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/status/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: GET_BILLING_HISTORY_STATUS,
        payload: results
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

  
export const getInvoiceTypes = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  //type=invoiceHis&eaccount=12?
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/invoiceType/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: GET_INVOICE_TYPE,
        payload: results
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

  export const getSites = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/site/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results } = res;
        dispatch({
          type: GET_SITES_IN_BILLING,
          payload: results
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  export const generateInvoice = (data, doAfter) => (dispatch,getState) => {
    if (!data.client) return (
      customSwaltAlert({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: 'Debe escoger una empresa valida',
        showCancelButton: false
      })
    )
    
    if (!data.contract) return (
      customSwaltAlert({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: 'Debe escoger un contrato valido',
        showCancelButton: false
      })
    )
    
    if (!data.site) return(
      customSwaltAlert({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: 'Debe escoger una sede valida',
        showCancelButton: false
      })
    )

    if (data.services.length===0) return (
      customSwaltAlert({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: 'Usted debe seleccionar al menos un servicio asociado a la factura',
        showCancelButton: false
      })
    )
    
    dispatch({
      type: GENERATE_INVOICE_LOADING,
      loading: true,
    });

    const token = getState().loginReducer.Authorization;
    
    fetch(
        `${URL_GATEWAY}${API_VERSION}/invoicing/invoice/free/`,
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            
            token: token,
            Authorization: token
            },
        }
        )
        .then((response) => response.json())
        .then((res) => {
            if (res.success) {
            dispatch({
              type: GENERATE_INVOICE_LOADING,
              loading: false,
            });

            customSwaltAlert({
              icon: 'success',
              title: `Factura generada exitosamente`,
              confirmButtonText: `Se ha generado la factura No. ${res.results.sequence}`,
              showCancelButton: false
            }).then((response)=>{
              if(response.isConfirmed){
                dispatch({
                  type: GENERATE_INVOICE_LOADING,
                  loading: false,
                });
                doAfter()

              }else{
                dispatch({
                  type: GENERATE_INVOICE_LOADING,
                  loading: false,
                });
                doAfter()
                
              }
            })
            }else{
            dispatch({
              type: GENERATE_INVOICE_LOADING,
              loading: false,
            });

            customSwaltAlert({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              showCancelButton: false
            })
            
          }
        })
        .catch((res) => {
            dispatch({
              type: GENERATE_INVOICE_LOADING,
              loading: false,
            });

            customSwaltAlert({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              showCancelButton: false
            })
            
        });
    }

    export const generateCaption= (data, doAfter=null) => (dispatch,getState) => {
      if (!data.client) return (
        customSwaltAlert({
          icon: 'warning',
          title: `Intenta de nuevo`,
          text: 'Debe escoger una empresa valida',
          showCancelButton: false
        })
      )
      
      if (!data.contract) return(
        customSwaltAlert({
          icon: 'warning',
          title: `Intenta de nuevo`,
          text: 'Debe escoger un contrato valido',
          showCancelButton: false
        })
      )

      if (!data.site) return(
        customSwaltAlert({
          icon: 'warning',
          title: `Intenta de nuevo`,
          text: 'Debe escoger una sede valida',
          showCancelButton: false
        })
      )

      if (!data.file64) return(
          customSwaltAlert({
            icon: 'warning',
            title: `Intenta de nuevo`,
            text: 'Debe escoger un archivo de soporte valido',
            showCancelButton: false
          })
      )
      if (!data.copayment) return(
        customSwaltAlert({
            icon: 'warning',
            title: `Intenta de nuevo`,
            text: 'Debe ingresar el valor del copago',
            showCancelButton: false
          })
      )

      dispatch({
        type: GENERATE_CAPTION_LOADING,
        loading: true,
      });
        const token = getState().loginReducer.Authorization;
        fetch(
            `${URL_GATEWAY}${API_VERSION}/invoicing/invoice/capitation/`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                
                token: token,
                Authorization: token
                },
            }
            )
            .then((response) => response.json())
            .then((res) => {
                if (res.success) {
                  dispatch({
                    type: GENERATE_CAPTION_LOADING,
                    loading: false,
                  });
                  customSwaltAlert({
                    icon: 'success',
                    title: `Factura generada exitosamente`,
                    text: `Se ha generado la factura No. ${res.results.sequence}`,
                    showCancelButton: false
                  }).then((response)=>{
                  if(response.isConfirmed){
                    dispatch({
                      type: GENERATE_CAPTION_LOADING,
                      loading: false,
                    });
                    doAfter()
                  }
                  else{
                    dispatch({
                      type: GENERATE_CAPTION_LOADING,
                      loading: false,
                    });
                    doAfter()
                  }
                })
                }else{
                  dispatch({
                    type: GENERATE_CAPTION_LOADING,
                    loading: false,
                  });
                  customSwaltAlert({
                    icon: 'warning',
                    title: `Intenta de nuevo`,
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                    showCancelButton: false
                  })
                }
            })
            .catch((res) => {
              dispatch({
                type: GENERATE_CAPTION_LOADING,
                loading: false,
              });
                MySwal.fire({
                icon: "error",
                title: "Error al generar factura",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
                });
            });
        }

export const getInvoicesToSend = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: INVOICES_TO_SENT,
    loading: true
  });
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/invoice/ListToSend/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: INVOICES_TO_SENT,
          payload: results,
          total: row_count,
          loading: false
        });
      })
      .catch((err) => {
        dispatch({
          type: INVOICES_TO_SENT,
          payload: [],
          total: 0,
          loading: false
        });
        console.error(err.message);
      });
  };

  export const getInvoicesType = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/invoiceType/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results} = res;
        dispatch({
          type: INVOICES_TYPES,
          payload: results,
            
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  export const sendInvoices = (data, doAfter) => (dispatch,getState) => {
    dispatch({
      type: SEND_INVOICES_LOADING,
      loading: true,
    });
    const token = getState().loginReducer.Authorization;
    fetch(
        `${URL_GATEWAY}${API_VERSION}/invoicing/receiptRecord/`,
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            
            token: token,
            },
        }
        )
        .then((response) => response.json())
        .then((res) => {
            if (res.success) {
              dispatch({
                type: SEND_INVOICES_LOADING,
                loading: false,
              });
              customSwaltAlert({
                  icon: "success",
                  title: "Servicio creado exitosamente",
                  text: "Se ha creado el acta No. "+ res.results.sequence,
                  confirmButtonText: "Aceptar",
                  showCancelButton:false
                }).then((response)=>{
              if(response.isConfirmed){
                dispatch({
                  type: SEND_INVOICES_LOADING,
                  loading: false,
                });
                doAfter(); 
              }
              else{
                dispatch({
                  type: SEND_INVOICES_LOADING,
                  loading: false,
                });
                doAfter(); 
              }
            })
            }else{
              dispatch({
                type: SEND_INVOICES_LOADING,
                loading: false,
              });

              customSwaltAlert({
                  icon: "warning",
                  title: "Intenta de nuevo",
                  text: res.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton:false
                })
            }
        })
        .catch((res) => {
          dispatch({
            type: SEND_INVOICES_LOADING,
            loading: false,
          });

          customSwaltAlert({
                  icon: "warning",
                  title: "Intenta de nuevo",
                  text: res.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton:false,
                  footer: "Si el problema persiste comuníquese con un asesor.",
                })

        });
    }

    export const getObjectionType = (objFilters) => (dispatch, getCreate) => {
        let filters = convertFilterToString(objFilters);
        const token = getCreate().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/objectionType/?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            const { results} = res;
            dispatch({
              type: OBJECTION_TYPES,
              payload: results,
                
            });
          })
          .catch((err) => {
            console.error(err.message);
          });
      };

      export const getInfoInNotes = (objFilters) => (dispatch, getCreate) => {
        let filters = convertFilterToString(objFilters);
        const token = getCreate().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/invoice/objectionInfo/?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            const { results} = res;
            dispatch({
              type: INFO_NOTES,
              payload: results,
                
            });
          })
          .catch((err) => {
            console.error(err.message);
          });
      };

      export const getTypesNotes = (objFilters) => (dispatch, getCreate) => {
        let filters = convertFilterToString(objFilters);
        const token = getCreate().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/accounting/noteTypes/?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            const { results} = res;
            dispatch({
              type: NOTE_TYPES,
              payload: results,
                
            });
          })
          .catch((err) => {
            console.error(err.message);
          });
      };

      export const creatNote = (data, doAfter) => (dispatch,getState) => {
        
        if (!data.client) return (
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: 'Debe escoger una empresa valida',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
        
        if (!data.objType) return(
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: 'Debe escoger una empresa valida',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
        
        if (!data.invoice) return(
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: 'Debe escoger un No. de factura valido',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
        
        if (!data.type) return(
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: 'Debe escoger un  tipo de nota valido',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
        
        if (!data.value) return(
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: 'Debe ingresar el valor de la nota',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )

        const token = getState().loginReducer.Authorization;
          customSwaltAlert({
            icon: "warning",
            title: "¿Está seguro?",
            text: 'Verifique la información',
            confirmButtonText: "Si, Continuar",
            showCancelButton: true
          }).then((result) => {
            if (result.isConfirmed) {
              
              dispatch({
                type: CREATE_NOTE_LOADING,
                loading: true,
              });

              fetch(
                `${URL_GATEWAY}${API_VERSION}/invoicing/note/`,
                {
                  method: "POST",
                  body: JSON.stringify(data),
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",                    
                    Authorization: token,
                  },
                }
              )
                .then((response) => response.json())
                .then((res) => {
                  if (res.success) {
                    dispatch({
                      type: CREATE_NOTE_LOADING,
                      loading: false, 
                    });
                    dispatch({
                      type: INFO_NOTES,
                      payload: [],
                        
                    });
                    dispatch({
                      type: OBJECTED_BY_CLIENT,
                      payload: [],
                        
                    });
                    doAfter();
                    customSwaltAlert({
                      icon: "success",
                      title: "Nota registrada exitosamente",
                      text: res.results.sequence,
                      confirmButtonText: "Aceptar",
                      showCancelButton: false
                    });
                    
                  }else{
                    dispatch({
                      type: CREATE_NOTE_LOADING,
                      loading: false, 
                    });
                    
                    customSwaltAlert({
                      icon: "warning",
                      title: "Intenta de nuevo",
                      text: res.message,
                      footer: "Si el problema persiste comuníquese con un asesor.",
                      confirmButtonText: "Aceptar",
                      showCancelButton: false
                    })

                  }
                })
                .catch((res) => {
                  dispatch({
                    type: CREATE_NOTE_LOADING,
                    loading: false, 
                  });

                  customSwaltAlert({
                      icon: "warning",
                      title: "Intenta de nuevo",
                      text: res.message,
                      footer: "Si el problema persiste comuníquese con un asesor.",
                      confirmButtonText: "Aceptar",
                      showCancelButton: false
                    })

                });
            }else{
              dispatch({
                type: CREATE_NOTE_LOADING,
                loading: false,
                  
              });
            }
          });
      };

      export const getObjectedByClient = (objFilters) => (dispatch, getCreate) => {
        let filters = convertFilterToString(objFilters);
        const token = getCreate().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/invoice/Objected/?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            const { results} = res;
            dispatch({
              type: OBJECTED_BY_CLIENT,
              payload: results,
                
            });
          })
          .catch((err) => {
            console.error(err.message);
          });
      };

      export const getStatusForHistory = (objFilters) => (dispatch, getCreate) => {
        let filters = convertFilterToString(objFilters);
        const token = getCreate().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/status/?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            const { results} = res;
            dispatch({
              type: STATUS_HISTORY,
              payload: results,
                
            });
          })
          .catch((err) => {
            console.error(err.message);
          });
      };

      export const getGlossHistory = (objFilters) => (dispatch, getCreate) => {
        dispatch({
          type: GLOSS_HISTORY,
          payload: [],
          total: 0,
          loading: true
            
        });
        let filters = convertFilterToString(objFilters);
        const token = getCreate().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/objection/?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            const { results, row_count} = res;
            dispatch({
              type: GLOSS_HISTORY,
              payload: results,
              total: row_count,
              loading: false,
                
            });
          })
          .catch((err) => {
            dispatch({
              type: GLOSS_HISTORY,
              payload: [],
              total: 0,
              loading: false,
                
            });
            console.error(err.message);

          });
      };

      export const getDetGlossHistory = (objFilters) => (dispatch, getCreate) => {
        dispatch({
          type: GLOSS_HISTORY_DET,
          payload: [],
          total: 0,
          loading: true,
            
        });
        let filters = convertFilterToString(objFilters);
        const token = getCreate().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/objection/?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            const { results, row_count} = res;
            dispatch({
              type: GLOSS_HISTORY_DET,
              payload: results,
              total: row_count,
              loading: false,
                
            });
          })
          .catch((err) => {
            dispatch({
              type: GLOSS_HISTORY_DET,
              payload: [],
              total: 0,
              loading: false,
                
            });
            console.error(err.message);
          });
      };

      export const getTypesServices = (objFilters) => (dispatch, getCreate) => {
        let filters = convertFilterToString(objFilters);
        const token = getCreate().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/service/type/?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            const { results, total } = res;
            dispatch({
              type: GET_TYPE_SERVICES_IN_ADM,
              payload: results,
              total: total,
            });
          })
          .catch((err) => {
            console.error(err.message);
          });
      };

      export const getListStatus = (objFilters) => (dispatch, getCreate) => {
        let filters = convertFilterToString(objFilters);
        const token = getCreate().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/status/?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            const { results, total } = res;
            dispatch({
              type: GET_LIST_STATUS,
              payload: results,
              total: total,
            });
          })
          .catch((err) => {
            console.error(err.message);
          });
      };

      export const  getServicesInADM = (objFilters, doafter) => (dispatch, getCreate) => {
        const token = getCreate().loginReducer.Authorization;
        let filters = convertFilterToString(objFilters);
        fetch(`${URL_GATEWAY}${API_VERSION}/medical/offered-services?${filters}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            if(doafter){
              doafter()
            }
            const { results, rowTotal } = res;
            dispatch({
              type: GET_SERVICES_IN_ADM,
              payload: results,
              total: rowTotal,
            });
            
          })
          .catch((err) => {
            if(doafter){
              doafter()
            }
            console.error(err.message);
          });
      };

export const creatServices = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/medical/offered-services`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {

        customSwaltAlert({
          icon: "success",
          title: "Creado exitosamente",
          text: `Se ha creado el servicio: ${data?.name}`,
          confirmButtonText: "Aceptar",
          showCancelButton: false
        }).then((response) => {
          if (response.isConfirmed) {
            doAfter()
          } else {
            doAfter()
          }
        })
      } else {
        doAfter()

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: res.message,
          footer: "Si el problema persiste comuníquese con un asesor.",
          confirmButtonText: 'Aceptar',
          showCancelButton: false
        })

      }
    })
    .catch((res) => {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: res.message,
        footer: "Si el problema persiste comuníquese con un asesor.",
        confirmButtonText: 'Aceptar',
        showCancelButton: false
      })
    });
}

        export const updateServices = (data, doAfter) => (dispatch,getState) => {
          const token = getState().loginReducer.Authorization;
          fetch(
              `${URL_GATEWAY}${API_VERSION}/medical/offered-services`,
              {
                  method: "PUT",
                  body: JSON.stringify(data),
                  headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  
                  token: token,
                  },
              }
              )
              .then((response) => response.json())
              .then((res) => {
                  if (res.success) {

                    customSwaltAlert({
                      icon: "success",
                      title: "Actualizado exitosamente",
                      text:`Se ha actualizado el servicio: ${data?.name}`,
                      confirmButtonText: "Aceptar",
                      showCancelButton:false
                    }).then((response)=>{
                    if(response.isConfirmed){
                      doAfter() 
                    }else{
                      doAfter()
                    }
                  })
                  }else{
                  doAfter()
                  

                  customSwaltAlert({
                    icon: "warning",
                    title: "Intenta de nuevo",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })
                  }
              })
              .catch((res) => {
                  doAfter()
                  
                  customSwaltAlert({
                    icon: "warning",
                    title: "Intenta de nuevo",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })
              });
          }

        export const getIva= (objFilters) => (dispatch, getCreate) => {
          const token = getCreate().loginReducer.Authorization;
          let filters = convertFilterToString(objFilters);
          fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/vat/?${filters}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          })
            .then((response) => response.json())
            .then((res) => {
              const { results, total } = res;
              dispatch({
                type: GET_IVA,
                payload: results,
                total: total,
              });
            })
            .catch((err) => {
              console.error(err.message);
            });
        };

        export const changeStatusService = (data, doAfter) => (dispatch,getState) => {
          const token = getState().loginReducer.Authorization;
          customSwaltAlert({
            icon: "warning",
            title: "¿Está seguro?",
            text: `Se va ${data?.elem?.status === 'enabled' ? 'deshabilitar' : 'habilitar'} el servicio: ${data?.elem?.name}`,
            showCancelButton:true
        }).then(({isConfirmed})=>{
          if(isConfirmed){
            fetch(
                `${URL_GATEWAY}${API_VERSION}/medical/offered-services`,
                {
                    method: "PUT",
                    body: JSON.stringify({
                      status:data?.status,
                      srvId: data?.id
                    }),
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                    },
                }
                )
                .then((response) => response.json())
                .then((res) => {
                    if (res.success) {
                    
                    customSwaltAlert({
                        icon: "success",
                        title: "Servicio actualizado",
                        text: `Se ha ${data?.elem?.status === 'enabled' ? 'deshabilitado' : 'habilitado'} el servicio: ${data?.elem?.name}`,
                        confirmButtonText: "Aceptar",
                        showCancelButton:false
                    }).then((response)=>{
                      if(response.isConfirmed){
                        doAfter() 
                      }else{
                        doAfter()
                      }
                    })
                    }else{
                    doAfter() 
    
                    customSwaltAlert({
                      icon: "warning",
                      title: "Intenta de nuevo",
                      text: res.message,
                      footer: "Si el problema persiste comuníquese con un asesor.",
                      confirmButtonText:'Aceptar',
                      showCancelButton:false
                    })}
                })
                .catch((res) => {
                    doAfter() 
    
                    customSwaltAlert({
                      icon: "warning",
                      title: "Intenta de nuevo",
                      text: res.message,
                      footer: "Si el problema persiste comuníquese con un asesor.",
                      confirmButtonText:'Aceptar',
                      showCancelButton:false
                    })
                });
          }else{
            doAfter()
          }
        })}


          export const getOneService = (objFilters, doAfter=null) => (dispatch, getCreate) => {
            const token = getCreate().loginReducer.Authorization;
            let filters = convertFilterToString(objFilters);
            fetch(`${URL_GATEWAY}${API_VERSION}/medical/offered-services?${filters}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
              },
            })
              .then((response) => response.json())
              .then((res) => {
                const { results, total } = res;
                dispatch({
                  type: GET_ONE_SERVICES_IN_ADM,
                  payload: results,
                  total: total,
                });
                if(!!results){
                  if(!!doAfter){
                    doAfter(results)
                  }
                }
              })
              .catch((err) => {
                console.error(err.message);
              });
          };

          export const getTypesContracts= (objFilters) => (dispatch, getCreate) => {
            const token = getCreate().loginReducer.Authorization;
            let filters = convertFilterToString(objFilters);
            fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/contract/type/?${filters}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
              },
            })
              .then((response) => response.json())
              .then((res) => {
                const { results, total } = res;
                dispatch({
                  type: GET_TYPES_CONTRACTS,
                  payload: results,
                  total: total,
                });
              })
              .catch((err) => {
                console.error(err.message);
              });
          };

          export const getRegion= (objFilters) => (dispatch, getCreate) => {
            const token = getCreate().loginReducer.Authorization;
            let filters = convertFilterToString(objFilters);
            fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/population/?${filters}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
              },
            })
              .then((response) => response.json())
              .then((res) => {
                const { results, total } = res;
                dispatch({
                  type: GET_REGION,
                  payload: results,
                  total: total,
                });
              })
              .catch((err) => {
                console.error(err.message);
              });
          };

          export const getCIIU= (objFilters) => (dispatch, getCreate) => {
            const token = getCreate().loginReducer.Authorization;
            let filters = convertFilterToString(objFilters);
            fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/ciiu/?${filters}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
              },
            })
              .then((response) => response.json())
              .then((res) => {
                const { results, total } = res;
                dispatch({
                  type: GET_CIUU,
                  payload: results,
                  total: total,
                });
              })
              .catch((err) => {
                console.error(err.message);
              });
          };
          export const getPayConditions= (objFilters) => (dispatch, getCreate) => {
            const token = getCreate().loginReducer.Authorization;
            let filters = convertFilterToString(objFilters);
            fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/paymentCondition/?${filters}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
              },
            })
              .then((response) => response.json())
              .then((res) => {
                const { results, total } = res;
                dispatch({
                  type: GET_PAY_CONDITIONS,
                  payload: results,
                  total: total,
                });
              })
              .catch((err) => {
                console.error(err.message);
              });
          };

          export const getPayMethods= (objFilters) => (dispatch, getCreate) => {
            const token = getCreate().loginReducer.Authorization;
            let filters = convertFilterToString(objFilters);
            fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/depositType/?${filters}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
              },
            })
              .then((response) => response.json())
              .then((res) => {
                const { results, total } = res;
                dispatch({
                  type: GET_PAY_METHODS,
                  payload: results,
                  total: total,
                });
              })
              .catch((err) => {
                console.error(err.message);
              });
          };

          export const getDivisa= (objFilters) => (dispatch, getCreate) => {
            const token = getCreate().loginReducer.Authorization;
            let filters = convertFilterToString(objFilters);
            fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/currency/?${filters}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
              },
            })
              .then((response) => response.json())
              .then((res) => {
                const { results, total } = res;
                dispatch({
                  type: GET_DIVISA,
                  payload: results,
                  total: total,
                });
              })
              .catch((err) => {
                console.error(err.message);
              });
          };

          
          export const creatContract = (data, doafter=null, trigger=null) => (dispatch,getState) => {
            if (!data.client) {
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'La razón social del contrato es obligatoria',
                showCancelButton: false
              })
              doafter()
              return
            }
            // if (data.population?.length===0) return responseError("La población del contrato es un campo obligatorio", doafter());
            if (data.companyType?.length===0) {
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'El tipo de empresa es un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return

            }

            if (data.conectivityPrice === '' || !data.conectivityPrice){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'El costo de conectividad es un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return  
            } 
            
            if (!data.contract_name){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'El nombre del contrato un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return   
            } 
            
            if (!data.contractType){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'El tipo de contrato un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return  
            } 
            
            if (!data.ciiu){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'El código CIIU un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 
            
            if (!data.start_date){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'La fecha de inicio del contrato es un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 
            
            // if (!data.end_date) return responseError("La fecha de fin del contrato es un campo obligatorio");
            if (!data.end_date){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'La fecha de vencimiento del contrato es un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 
            
            if (!data.description){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'La descripción del contrato es un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 
            
            if (!data.attention_conditions){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Las condiciones de atención al cliente del contrato son un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 
            
            if (!data.paymentCond){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Las condiciones de pago del contrato son un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 
            
            if (!data.depositType){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Los métodos de pago del contrato son un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 

            if (!data.filing_date){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'La fecha de radicación es un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 
            
            if (!data.notification_email){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'El correo de notificación es un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 
            
            // if (!data.trm) return responseError("La tasa de cambio del contrato es un campo obligatorio", doafter());
            if (!data.currency){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'La divisa es un campo obligatorio',
                showCancelButton: false
              })
              doafter()
              return
            } 
            
            if (data.services?.length===0){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Usted debe seleccionar los servicios asociados al nuevo contrato',
                showCancelButton: false
              })
              doafter()
              return
            } 

            const validateServices = data.services.filter(
              e=> (e.min === 0 || e.min === '' || e.qty === 0 || e.qty === '' || e.price === 0 || e.price === ''))


            if(validateServices.length){
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Usted debe completar los datos de los servicios asociados al nuevo contrato',
                showCancelButton: false
              })
              doafter()
              return
            }
            
            const token = getState().loginReducer.Authorization;
            
            customSwaltAlert({
              icon:"warning",
              title: "¿Está seguro?",
              text:"Verifique la información",
              showCancelButton: true,
            })
            .then((result)=>{
              if(result.isConfirmed){     
                dispatch({
                  type: NW_CONTRACT_LOADING,
                  loading: true,
                })
                fetch(
                  `${URL_GATEWAY}${API_VERSION}/invoicing/contract/`,
                  {
                      method: "POST",
                      body: JSON.stringify(data),
                      headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      token: token,
                      },
                  }
                )
                .then((response) => response.json())
                .then((res) => {
                    if (res.success) {
                      doafter()
                      dispatch({
                        type: NW_CONTRACT_LOADING,
                        loading: false,
                      })
                      doafter()
                      
                      customSwaltAlert({
                        icon: "success",
                        title: "Creado exitosamente",
                        text: "Se ha creado el contrato no. "+ res.results.sequence,
                        confirmButtonText: "Aceptar",
                        showCancelButton:false
                      })
                      .then((response)=>{
                        if(response.isConfirmed){
                          window.location.reload(); 
                        }else{
                          window.location.reload(); 
                        }
                      })
                    }else{
                      doafter()
                      dispatch({
                        type: NW_CONTRACT_LOADING,
                        loading: false,
                      })
                      

                      customSwaltAlert({
                        icon: "warning",
                        title: "Intenta de nuevo",
                        text: res.message,
                        footer: "Si el problema persiste comuníquese con un asesor.",
                        confirmButtonText:'Aceptar',
                        showCancelButton:false
                    })
                    }
                })
                .catch((res) => {
                  doafter()
                  dispatch({
                    type: NW_CONTRACT_LOADING,
                    loading: false,
                  })

                    customSwaltAlert({
                      icon: "warning",
                      title: "Intenta de nuevo",
                      text: res.message,
                      footer: "Si el problema persiste comuníquese con un asesor.",
                      confirmButtonText:'Aceptar',
                      showCancelButton:false
                    })

                });
              }else{
                doafter()
              }
          })
        }

        export const sendSign = (data, doAfter=null, trigger=null) => (dispatch,getState) => {
          const token = getState().loginReducer.Authorization;
          fetch(
              `${URL_GATEWAY}${API_VERSION}/invoicing/contract/sign/`,
              {
                  method: "POST",
                  body: JSON.stringify(data),
                  headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  
                  token: token,
                  },
              }
              )
              .then((response) => response.json())
              .then((res) => {
                  doAfter()
                  if (res.success) {
                  MySwal.fire({
                      icon: "success",
                      title: "Contrato firmado",
                      text: res.results.name,
                      confirmButtonText: "Aceptar"
                  }).then((response)=>{
                    if(response.isConfirmed){
                      trigger()
                    }else{
                      trigger()
                    }
                  })
                  }else{
                  MySwal.fire({
                      icon: "error",
                      title: "Error al firmar contrato",
                      text: res.message,
                      footer: "Si el problema persiste comuníquese con un asesor.",
                  });
                  }
              })
              .catch((res) => {
                  doAfter()
                  MySwal.fire({
                  icon: "error",
                  title: "Error al firmar contrato",
                  text: res.message,
                  footer: "Si el problema persiste comuníquese con un asesor.",
                  });
              });
          }

          export const changeStatusContract = (data, doAfter = null, trigger=null) => (dispatch,getState) => {
            const token = getState().loginReducer.Authorization;
            
            customSwaltAlert({
              icon: "warning",
              title: "¿Está seguro?",
              text: `Se ${data?.elem?.status === 'Inhabilitado' ? 'habilitará' : 'inhabilitará'} el contrato: ${data?.elem?.contract_name}`,
              showCancelButton:true
            }).then(({isConfirmed})=>{
              if(isConfirmed){
                fetch(
                  `${URL_GATEWAY}${API_VERSION}/invoicing/contract/changeStatus/`,
                  {
                      method: "PUT",
                      body: JSON.stringify(data),
                      headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      token: token,
                      },
                  }
                  )
                  .then((response) => response.json())
                  .then((res) => {
                      if (res.success) {
                      
                      customSwaltAlert({
                          icon: "success",
                          title: "Actualizado exitosamente",
                          text: `Se ha ${data?.elem?.status === 'Inhabilitado' ? 'habilitado' : 'inhabilitado'} el contrato: ${data?.elem?.contract_name}`,
                          confirmButtonText: "Aceptar",
                          showCancelButton:false
                      }).then((response)=>{
                        if(response.isConfirmed){
                          doAfter()
                          trigger()
                        }else{
                          doAfter()
                          trigger()
                        }
                      })
                      }else{
                      doAfter()
  
                      customSwaltAlert({
                        icon: "warning",
                        title: "Intenta de nuevo",
                        text: res.message,
                        confirmButtonText:'Aceptar',
                        showCancelButton:false
                      })
                      }
                  })
                  .catch((res) => {
                      doAfter()
  
                      customSwaltAlert({
                        icon: "warning",
                        title: "Intenta de nuevo",
                        text: res.message,
                        confirmButtonText:'Aceptar',
                        showCancelButton:false
                      })
                      
                  });
              }else{
                doAfter()
              }
            })
          }

            export const getOneContract = (objFilters, doAfter=null, loading=null) => (dispatch, getCreate) => {
              const token = getCreate().loginReducer.Authorization;
              let filters = convertFilterToString(objFilters);
              fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/contract/?${filters}`, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              })
                .then((response) => response.json())
                .then((res) => {
                  const { results, total } = res;
                  dispatch({
                    type: GET_ONE_CONTRACT_IN_ADM,
                    payload: results,
                  });
                  if(!!results){
                    loading()
                    if(!!doAfter){
                      
                      doAfter(results);
                     
                    }
                  }
                })
                .catch((err) => {
                  loading()
                  console.error(err.message);
                });
            };

            export const getCups = (objFilters) => (dispatch, getCreate) => {
              let filters = convertFilterToString(objFilters);
              const token = getCreate().loginReducer.Authorization;
              fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/cups/?${filters}`, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              })
                .then((response) => response.json())
                .then((res) => {
                  const { results} = res;
                  dispatch({
                    type: GET_CUPS,
                    payload: results,
                      
                  });
                })
                .catch((err) => {
                  console.error(err.message);
                });
            };

            
            export const returnedCheckRegister = (data, doAfter) => (dispatch,getState) => {
              const token = getState().loginReducer.Authorization;
                Swal.fire({
                  title: `¿Está seguro?`,
                  text:"Verifique la información",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#003f80",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Si, Guardar",
                  cancelButtonText: "Cancelar",
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch({
                      type: TRANSFER_REGISTER,
                      loading: true,
                  })
                    fetch(
                      `${URL_GATEWAY}${API_VERSION}/treasury/returned_checks/`,
                      {
                        method: "POST",
                        body: JSON.stringify(data),
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          
                          token: token,
                        },
                      }
                    )
                      .then((response) => response.json())
                      .then((res) => {
                        dispatch({
                          type: TRANSFER_REGISTER,
                          loading: false,
                      })
                        if (res.success) {
                          MySwal.fire({
                            icon: "success",
                            title: "Cheque registrado",
                            confirmButtonText: "Aceptar",
                            text: "No. " + data.check_nbr
                          });
                          doAfter();
                          
                        }else{
                          MySwal.fire({
                            icon: "error",
                            title: "Error al registrar cheque",
                            footer: "Si el problema persiste comuníquese con un asesor.",
                          });
                        }
                      })
                      .catch((res) => {
                        dispatch({
                            type: TRANSFER_REGISTER,
                            loading: false,
                        })
                        MySwal.fire({
                          icon: "error",
                          title: "Error al registrar cheque",
                          footer: "Si el problema persiste comuníquese con un asesor.",
                        });
                      });
                  }
                });
            };

            export const getCauses = (objFilters) => (dispatch, getCreate) => {
              let filters = convertFilterToString(objFilters);
              const token = getCreate().loginReducer.Authorization;
              fetch(`${URL_GATEWAY}${API_VERSION}/treasury/causes/`, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              })
                .then((response) => response.json())
                .then((res) => {
                  const { results} = res;
                  dispatch({
                    type: GET_CAUSES,
                    payload: results,
                      
                  });
                })
                .catch((err) => {
                  console.error(err.message);
                });
            };


