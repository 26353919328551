import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { OrdGenericTemplate } from "../OrderingModule/OrderingComponents/OrdGenericTemplate";
import { Col, Form, Row } from "react-bootstrap";
import tableStyles from "../components/Layouts/tableStyle.module.scss";
import orangeNoBGArrow from "../assets/img/icons/orangeNoBGArrow.svg";
import cleanCloseIcon from "../assets/img/icons/cleanCloseIcon.svg";
import Update from "../assets/img/icons/Update.svg";
import "../OrderingModule/orderingStyles.css";
import Select from "react-select";
import { ordCustomSelect } from "../components/Layouts/react-select-custom";
import OrdTable from "../OrderingModule/OrderingComponents/OrdTable";
import { TextField } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import {
  useGetMethod,
  useMultiGetMethod,
  usePostMethod,
} from "../Hooks/useFetch";
import {
  formatToRcSelect,
  calculatePresentationMedicine,
  loader,
  message,
} from "../helpers/helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const NewCombo = () => {
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const [trigger, setTrigger] = useState(1);
  const [trigger2, setTrigger2] = useState(1);
  const location = useLocation();
  const isEditing = location.state?.isEditing;
  const combo = location?.state?.combo;
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const [presentation, setPresentation] = useState({
    label: "",
    value: 0,
  });
  const [data, setData] = useState({
    mecName: "",
    mecSuggestions: "",
    medicines: [],
    info: [],
  });
  const [medicine, setMedicine] = useState({
    mdcId: null,
    mdcQtyDosis: "",
    omeIdDosis: null,
    mcdQtyFrequency: "",
    timIdFrequency: null,
    mcdQtyPeriod: "",
    timIdPeriod: null,
    draId: null,
    mcdQtyPresentation: "",
    omeIdPresentation: null,
    mcdObservations: "",
    presentationValue: 0,
    presentationLabel: 0,
  });
  const {
    results: selectsLists,
    load: selectsLoader,
    trigger: getDoctors,
  } = useMultiGetMethod();
  const {
    results: bodyOrgans,
    load: bodyLoader,
    trigger: getBodyOrgans,
  } = useGetMethod();
  const { load: createComboLoader, trigger: createCombo } = usePostMethod();
  const { load: updateComboLoader, trigger: updateCombo } = usePostMethod();
  useEffect(() => {
    getDoctors({
      multipleGet: [
        {
          url: "/medical/doctor/",
          objFilters: {
            slim: 1,
            idAccount: store.loginReducer.currentAccount.id,
          },
          requestName: "doctors",
        },
        {
          url: "/medical/medicine/",
          objFilters: {
            slim: 1,
            idAccount: store.loginReducer.currentAccount.id,
          },
          requestName: "medicines",
        },
        {
          url: "/medical/routeAdministration/",
          requestName: "routeAdministration",
        },
        {
          url: "/medical/time/",
          requestName: "time",
        },
        {
          url: "/medical/medicine/getPharmaUnit/",
          objFilters: { type: "Unidad de Receta" },
          requestName: "dosis",
        },
      ],
      token: token,
    });
    let medicineList = [];

    if (Array.isArray(combo?.medicine)) {
      combo?.medicine?.map((item, index) => {
        medicineList.push({
          timIdFrequency: item?.mcdIdFrequency,
          timIdPeriod: item?.mcdIdPeriod,
          omeIdPresentation: item?.mcdIdPresentation,
          frequencyValue: item?.frequencyValue,
          periodValue: item?.periodValue,
          dboId: item?.dboId,
          dboName: item?.dboName,
          draId: item?.draId,
          omeIdDosis: item?.mcdIdDosis,
          mdcId: item?.mdcId,
          mdcLabel: item?.name,
          mdcQtyDosis: item?.mcdQtyDosis,
          omeLabelDosis: item?.mcdDosisDescription,
          mcdQtyFrequency: item?.mcdQtyFrequency,
          timLabelFrequency: item?.mcdFrequencyDescription,
          mcdQtyPeriod: item?.mcdQtyPeriod,
          timLabelPeriod: item?.mcdPeriodDescription,
          mcdQtyPresentation: item?.mcdQtyPresentation,
          presentationLabel: item?.mcdPresentationDescription,
          qtyPerPresentation: item?.mdcQtyPerPresentation,
          qtyPerMeasUnit: item?.mdcQtyPerMeasUnit,
          unitMeasureLabel: item?.mdcOrderUnitLabel,
          draLabel: item?.draName,
          mcdObservations: item?.mcdObservations,
          presentationDescrption: item?.mcdPresentationDescription,
        });
      });
    }
    if (isEditing) {
      setData({
        id: combo.id,
        mecName: combo.name,
        mecSuggestions: combo.suggestions,
        medicines: medicineList,
        userMd: combo?.doctor ? combo.doctor.id : null,
      });
    }
  }, []);

  useEffect(() => {
    if (!!medicine?.draId) {
      getBodyOrgans({
        url: "/medical/bodyOrgans/",
        objFilters: { draId: medicine?.draId },
        requestName: "dosis",
        token: token,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger2]);

  const formattedDosis = formatToRcSelect(
    selectsLists?.dosis?.results,
    "id",
    "label",
    "",
    "",
    ""
  );
  const formattedTime = formatToRcSelect(
    selectsLists?.time?.results,
    "id",
    "name",
    "value",
    "",
    ""
  );
  const formattedRouteAdmn = formatToRcSelect(
    selectsLists?.routeAdministration?.results,
    "id",
    "name",
    "tag",
    "",
    ""
  );
  const formattedDoctors = formatToRcSelect(
    selectsLists?.doctors?.results,
    "id",
    "name",
    "",
    "",
    ""
  );
  const formattedBodyOrgans = formatToRcSelect(
    bodyOrgans?.results,
    "id",
    "name",
    "",
    "",
    ""
  );

  const formattedMedicines = [];
  if (Array.isArray(selectsLists?.medicines?.results)) {
    selectsLists?.medicines?.results.forEach((item) => {
      formattedMedicines.push({
        label:
          item?.name +
          " " +
          item?.qtyPerPresentation +
          " " +
          item?.unitMeasureLabel,
        value: item?.id,
        omeIdPresentation: item?.idPresentation,
        idOrderUnit: item?.idReceiptUnit,
        qtyPerMeasUnit: item?.qtyPerMeasUnit,
        qtyPerPresentation: item?.qtyPerPresentation,
        presentationDescrption: item?.presentationDescrption,
        unitMeasureLabel: item?.unitMeasureLabel,
        nameMedicine: item?.name,
        name: "medicine",
      });
    });
  }
  useEffect(() => {
    let isValidate = validateMedicine();
    if (isValidate.sw === false) {
      let pres = calculatePresentationMedicine(medicine);
      setPresentation({ label: pres.presentation, value: pres.result });
    } else {
      setPresentation({ label: "", value: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicine]);

  const headers = [
    {
      title: "Medicamento",
      className: "px-2 text-start",
    },
    {
      title: "Dosis",
      className: "px-2 text-start",
    },
    {
      title: "Frecuencia",
      className: "px-2 text-start",
    },
    {
      title: "Duración",
      className: "px-2 text-start",
    },
    {
      title: "Cantidad",
      className: "px-2 text-start",
    },
    {
      title: "Vía",
      className: "px-2 text-start",
    },
    {
      title: "Observaciones",
      className: "px-2 text-start",
    },
    {
      title: <>ㅤ</>,
    },
  ];
  const validateMedicine = () => {
    let sw = false;
    let mess = "";

    if (!medicine?.mdcId) {
      mess = "El campo Medicamento es obligatorio.";
      sw = true;
    } else if (!medicine.mdcQtyDosis) {
      mess = "El campo Cantidad es obligatorio.";
      sw = true;
    } else if (!medicine.omeIdDosis) {
      mess = "El campo Unidad es obligatorio.";
      sw = true;
    } else if (!medicine.mcdQtyFrequency) {
      mess = "El campo Cada es obligatorio.";
      sw = true;
    } else if (!medicine.timIdFrequency) {
      mess = "El campo Tiempo es obligatorio.";
      sw = true;
    } else if (!medicine.mcdQtyPeriod) {
      mess = "El campo Durante es obligatorio.";
      sw = true;
    } else if (!medicine.timIdPeriod) {
      mess = "El campo Período es obligatorio.";
      sw = true;
    } else if (!medicine.draId) {
      mess = "El campo Vía es obligatorio.";
      sw = true;
    }
    return { sw: sw, text: mess };
  };
  const validateFreqPeriod = (item) => {
    if (!!medicine?.mcdQtyFrequency && !!medicine?.mcdQtyPeriod) {
      let cadaT = medicine?.mcdQtyFrequency * medicine?.frequencyValue;
      let duranteT = medicine?.mcdQtyPeriod * medicine?.periodValue;
      if (cadaT <= duranteT) {
        return false;
      } else {
        return true;
      }
    }
  };
  const validateDuplicate = (item) => {
    let id = item?.mdcId;
    let founded =
      data.medicines.length > 0 && data.medicines.filter((x) => x.mdcId === id);
    if (founded.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const addMedicine = () => {
    let isValidate = validateMedicine();
    if (isValidate.sw === false) {
      let isDuplicate = validateDuplicate(medicine);
      if (isDuplicate) {
        MySwal.fire({
          icon: "error",
          title: "Medicamento duplicado",
          text: "El medicamento ya ha sido registrado en el combo",
        });
      } else {
        let freqValidate = validateFreqPeriod();
        if (freqValidate) {
          MySwal.fire({
            icon: "info",
            title: "Campos erróneos",
            text: "La frecuencia del medicamento NO debe ser mayor al período estipulado.",
          });
        } else {
          formatData(medicine);
          setMedicine();
          setTrigger(trigger + 1);
        }
      }
    } else {
      MySwal.fire({
        icon: "error",
        title: "Datos incompletos",
        text: isValidate.text,
      });
    }
  };
  const deleteItem = (item) => {
    let id = item?.mdcId;
    let founded =
      data.medicines.length > 0 && data.medicines.filter((x) => x.mdcId !== id);
    setData({ ...data, medicines: founded });
  };
  const editItem = (item) => {
    setMedicine(item);
    deleteItem(item);
    setTrigger(trigger + 1);
  };
  const formatData = (e) => {
    e.mcdQtyPresentation = presentation?.value;
    e.presentationLabel = presentation?.label;
    e.mdcQtyDosis = parseFloat(e.mdcQtyDosis);
    e.mcdQtyFrequency = parseFloat(e.mcdQtyFrequency);
    e.mcdQtyPeriod = parseFloat(e.mcdQtyPeriod);
    e.mcdQtyPresentation = parseFloat(e.mcdQtyPresentation);
    let dataMedicines = data?.medicines;
    dataMedicines.push(e);
    setData({ ...data, medicines: dataMedicines });
  };

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(data?.medicines)) {
      // eslint-disable-next-line array-callback-return
      data?.medicines?.map((item, index) => {
        tempList.push(
          <tr key={index} className="hover-table-row">
            <td className="col-3 px-2 text-start">{item?.mdcLabel}</td>
            <td className="col-1 px-2 text-start">
              {item?.mdcQtyDosis + " " + item?.omeLabelDosis }
            </td>
            <td className="col-1 px-2 text-start">
              {"Cada " +
                item?.mcdQtyFrequency +
                " " +
                item?.timLabelFrequency 
                }
            </td>
            <td className="col-1 px-2 text-start">
              {"Durante " +
                item?.mcdQtyPeriod +
                " " +
                item?.timLabelPeriod 
                }
            </td>
            <td className="col-2 px-2 text-start">
              {item?.mcdQtyPresentation +
                " " +
                item?.presentationLabel +
                " " +
                item?.qtyPerPresentation +
                item?.unitMeasureLabel}
            </td>
            <td className="col-1 px-2 text-start">{item?.draLabel}</td>
            <td className="col-2 px-2 text-start">
              {item?.mcdObservations ? item?.mcdObservations : "-"}
            </td>
            <td className="col-1">
              <button onClick={() => editItem(item)}>
                <img alt="update" src={Update}></img>
              </button>
              <button className="px-1" onClick={() => deleteItem(item)}>
                <img alt="delete" src={cleanCloseIcon}></img>
              </button>
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };
  const findUnitReceipt = (item) => {
    let find = formattedDosis.find((x) => x.value === item?.idOrderUnit);
    setMedicine({
      ...medicine,
      mdcId: item?.value,
      mdcLabel: item?.label,
      unitMeasureLabel: item?.unitMeasureLabel,
      omeIdPresentation: item?.omeIdPresentation,
      qtyPerMeasUnit: item?.qtyPerMeasUnit,
      qtyPerPresentation: item?.qtyPerPresentation,
      presentationDescrption: item?.presentationDescrption,
      omeIdDosis: find?.value,
      omeLabelDosis: find?.label,
    });
  };
  const handleSubmit = () => {
    if (!isEditing) {
      createCombo({
        url: "/medical/medicationCombo/",
        token: token,
        method: "POST",
        body: data,
        doAfterSuccess: () => {
          history.push("/asistencial/CombosDeCirugia");
          message("success", "good", "good");
        },
      });
    } else {
      updateCombo({
        url: "/medical/medicationCombo/",
        token: token,
        method: "PUT",
        body: data,
        doAfterSuccess: () => {
          history.push("/asistencial/CombosDeCirugia");
          message("success", "good", "good");
        },
        succesAction: ()=> message("success",`<span class=${tableStyles.ordClearBlueText}>Proceso completado </span>`,`Combo de cirugía ${data?.mecName} actualizado  exitosamente`)
      });
    }
  };
  return (
    <>
      {selectsLoader && loader}
      {createComboLoader && loader}
      {bodyLoader && loader}
      {updateComboLoader && loader}
      <div
        className="ml-5
            "
      >
        <OrdGenericTemplate
          showBackArrow={true}
          backArrowAction={() => history.push("/asistencial/CombosDeCirugia")}
          titleStyle={{ marginLeft: "2.5rem" }}
          titleSize="11"
          title={
            isEditing ? (
              <span className="pl-5">Editar combo de cirugía</span>
            ) : (
              <span className="pl-5">Nuevo combo de cirugía</span>
            )
          }
        >
          <div className="mt-4 pl-5">
            <Row>
              <div className="mx-4 mt-4 pl-5">
                <Row
                  className={`d-flex justify-content-center m-auto m-0 p-0 px-5`}
                >
                  <Col
                    style={{ paddingLeft: "0px", paddingRight: "15px" }}
                    xs={6}
                  >
                    <Form.Group className="mb-3" controlId="ordType">
                      <Form.Label
                        className={`m-0 ${tableStyles.ordDarkBlueText}`}
                      >
                        <b>&nbsp;Nombre del combo</b>
                      </Form.Label>
                      <Form.Control
                        className={`ord-roundInput`}
                        value={data.mecName}
                        type="text"
                        placeholder="Escribe..."
                        onChange={(e) =>
                          setData({ ...data, mecName: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col className={`px-0`} xs={6}>
                    <Form.Group className="mb-3" controlId="fName">
                      <Form.Label
                        className={`m-0 ${tableStyles.ordDarkBlueText} `}
                      >
                        <b className="text-start">&nbsp;Médico</b>
                      </Form.Label>
                      <Select noOptionsMessage={() => 'No hay datos'}
                        options={formattedDoctors}
                        className="text-secondary"
                        placeholder={"Seleccione"}
                        value={formattedDoctors.find(
                          (item) => item.value === data?.userMd
                        )}
                        styles={ordCustomSelect}
                        onChange={(e) => setData({ ...data, userMd: e.value })}
                      ></Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group
                  className={`${tableStyles.ordSearchSections} mb-4 mx-5 pt-2 text-start`}
                  controlId="cc"
                >
                  <Row className={`px-2`}>
                    <Col xs={12} className="pt-4">
                      <Autocomplete
                        key={trigger + "mdcId"}
                        onChange={(event, newValue) => {
                          findUnitReceipt(newValue);
                        }}
                        id="controllable-states-demo"
                        options={formattedMedicines}
                        value={
                          medicine?.mdcId
                            ? formattedMedicines.find(
                              (item) => item?.value === medicine?.mdcId
                            )
                            : null
                        }
                        renderInput={(params) => (
                          <TextField
                            placeholder="Seleccionar medicamento"
                            {...params}
                            label=""
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  {/* #2 */}
                  <Row className={`px-2`}>
                    <Col xs={3}>
                      <Form.Group className="mb-3" controlId="ordType">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText}`}
                        >
                          <b>&nbsp;Cantidad</b>
                        </Form.Label>
                        <Form.Control
                          value={medicine?.mdcQtyDosis}
                          className={`ord-roundInput`}
                          type="number"
                          placeholder="0"
                          key={trigger + "qtyDosis"}
                          onChange={(e) =>
                            setMedicine({
                              ...medicine,
                              mdcQtyDosis: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={3}>
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText} `}
                        >
                          <b className="text-start">&nbsp;Unidad</b>
                        </Form.Label>
                        <Form.Control
                          className={`ord-roundInput`}
                          readOnly
                          type="text"
                          key={trigger + "Dosis"}
                          placeholder=""
                          value={
                            medicine?.omeLabelDosis
                              ? medicine?.omeLabelDosis
                              : ""
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText} `}
                        >
                          <b className="text-start">&nbsp;Cada</b>
                        </Form.Label>
                        <Form.Control
                          value={medicine?.mcdQtyFrequency}
                          className={`ord-roundInput`}
                          type="number"
                          key={trigger + "qtyFrequency"}
                          placeholder="0"
                          onChange={(e) =>
                            setMedicine({
                              ...medicine,
                              mcdQtyFrequency: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText} `}
                        >
                          <b className="text-start">&nbsp;Tiempo</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          options={formattedTime}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          value={formattedTime.find(
                            (item) => item?.value === medicine?.timIdFrequency
                          )}
                          styles={ordCustomSelect}
                          key={trigger + "idFrequency"}
                          onChange={(e) => {
                            setMedicine({
                              ...medicine,
                              timIdFrequency: e.value,
                              timLabelFrequency: e.label,
                              frequencyValue: e.extra,
                            });
                          }}
                        ></Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* #3 */}
                  <Row className={`px-2`}>
                    <Col xs={3}>
                      <Form.Group className="mb-3" controlId="ordType">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText}`}
                        >
                          <b>&nbsp;Durante</b>
                        </Form.Label>
                        <Form.Control
                          value={medicine?.mcdQtyPeriod}
                          className={`ord-roundInput`}
                          type="number"
                          key={trigger + "qtyPeriodo"}
                          placeholder="0"
                          onChange={(e) =>
                            setMedicine({
                              ...medicine,
                              mcdQtyPeriod: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText} `}
                        >
                          <b className="text-start">&nbsp;Período</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          value={formattedTime.find(
                            (x) => x.value === medicine?.timIdPeriod
                          )}
                          options={formattedTime}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                          key={trigger + "idPeriod"}
                          onChange={(e) => {
                            setMedicine({
                              ...medicine,
                              timIdPeriod: e.value,
                              timLabelPeriod: e.label,
                              periodValue: e.extra,
                            });
                          }}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText} `}
                        >
                          <b className="text-start">&nbsp;Vía</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          value={
                            medicine?.draId
                              ? formattedRouteAdmn.find(
                                (item) => item?.value === medicine?.draId
                              )
                              : null
                          }
                          options={formattedRouteAdmn}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          key={trigger + "draId"}
                          styles={ordCustomSelect}
                          onChange={(e) => {
                            setTrigger2(trigger2 + 1);
                            setMedicine({
                              ...medicine,
                              draId: e.value,
                              draLabel: e.label,
                              draTag : e.extra
                            });
                          }}
                        ></Select>
                      </Form.Group>
                    </Col>
                    {medicine?.draTag === "oftalmico" && (
                      <Col xs={3}>
                        <Form.Group className="mb-3" controlId="fName">
                          <Form.Label
                            className={`m-0 ${tableStyles.ordDarkBlueText} `}
                          >
                            <b className="text-start">&nbsp;Ojo</b>
                          </Form.Label>
                          <Select noOptionsMessage={() => 'No hay datos'}
                            value={
                              medicine?.dboId
                                ? formattedBodyOrgans.find(
                                  (item) => item?.value === medicine?.dboId
                                )
                                : null
                            }
                            options={formattedBodyOrgans}
                            className="text-secondary"
                            placeholder={"Seleccione"}
                            key={trigger + "draId"}
                            styles={ordCustomSelect}
                            onChange={(e) =>
                              setMedicine({ ...medicine, dboId: e.value })
                            }
                          ></Select>
                        </Form.Group>
                      </Col>
                    )}
                    <Col xs={3}>
                      <Form.Group className="mb-3 " controlId="ordType">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText}`}
                        >
                          <b>&nbsp;&nbsp;Presentación</b>
                        </Form.Label>
                        <Row className={`d-flex align-items-center  `}>
                          <Col
                            className={` ${tableStyles.f13} d-flex align-items-center bg-light text-secondary ml-3 tLeftBLeftBorder justify-content-center`}
                            // "  bg-light  text-secondary ml-3 tLeftBLeftBorder"
                            xs={5}
                          >
                            <span>{presentation.value}</span>
                          </Col>
                          <Col
                            className={` ${tableStyles.f12} d-flex align-items-center bg-light text-secondary tRighttBrightBorder justify-content-center text-center`}
                            xs={5}
                          >
                            <span clas>{presentation.label}</span>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* #4 */}
                  <Row className={`px-2`}>
                    <Col xs={9}>
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText} `}
                        >
                          <b className="text-start">&nbsp;Observaciones</b>
                        </Form.Label>
                        <textarea
                          value={medicine?.mcdObservations}
                          placeholder={"Escribir..."}
                          rows="25"
                          cols="55"
                          style={{ height: "2.2rem" }}
                          className={`text-secondary ord-roundInput w-100`}
                          key={trigger + "observations"}
                          onChange={(e) =>
                            setMedicine({
                              ...medicine,
                              mcdObservations: e.target.value,
                            })
                          }
                        ></textarea>
                      </Form.Group>
                    </Col>
                    <Col
                      xs={3}
                      className="d-flex "
                      style={{ alignItems: "center" }}
                    >
                      <Col xs={10}>
                        <label className={`pr-2 ${tableStyles.ordOrangeText}`}>
                          <b>Agregar medicamento</b>
                        </label>
                      </Col>
                      <Col xs={2}>
                        <img
                          className="cursorPointer"
                          onClick={() => {
                            addMedicine();
                          }}
                          width={"25px"}
                          alt={"add medicine"}
                          src={orangeNoBGArrow}
                        ></img>
                      </Col>
                    </Col>
                  </Row>
                </Form.Group>

                <div className={`mb-4 mx-5 pt-2 text-start`}>
                  <OrdTable hasChildren={true} headers={headers}>
                    {renderList()}
                  </OrdTable>

                  <Col xs={12}>
                    <Form.Group className="mb-3" controlId="fName">
                      <Form.Label
                        className={`m-0 ${tableStyles.ordDarkBlueText} `}
                      >
                        <b className="text-start">&nbsp;Recomendaciones</b>
                      </Form.Label>
                      <textarea
                        placeholder={"Escribir..."}
                        rows="25"
                        cols="55"
                        style={{ height: "4rem" }}
                        value={data.mecSuggestions}
                        className={`text-secondary ord-roundInput w-100`}
                        onChange={(e) =>
                          setData({ ...data, mecSuggestions: e.target.value })
                        }
                      ></textarea>
                    </Form.Group>
                  </Col>
                </div>
                <Col className="d-flex justify-content-end mb-4 mx-5 pt-2 ">
                  <button
                    className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}
                    onClick={() => history.push("/asistencial/CombosDeCirugia")}
                  >
                    Cancelar
                  </button>
                  <button
                    className={`${tableStyles.ordBtnPrimary} btn`}
                    onClick={() => handleSubmit()}
                  >
                    Guardar
                  </button>
                </Col>
              </div>
            </Row>
          </div>
        </OrdGenericTemplate>{" "}
      </div>
    </>
  );
};
