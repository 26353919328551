//import SASS && Mui material && icons
import { Box } from '@mui/material';
// import tableStyle from "../../../components/Layouts/tableStyle.module.scss";

export const RenderRow = ({ title, subTitle, subTitleList }) => (
  <>
    <Box
      // className={`${tableStyle.elipsis} fw-bold`}
      className={`fw-bold`}
    >
      {title}
    </Box>

    {
      subTitle && (
        <Box>
          {subTitle}
        </Box>
      )
    }

    {
      subTitleList?.length && (
        <Box>
          {
            subTitleList?.map((el) => (
              <Box>{el}</Box>
            ))
          }
        </Box>
      )
    }


  </>
)