import React from 'react'
import '../../../OrderingModule/orderingStyles.css'
import { PseudoSideBarAccordion } from '../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { onCapitalLetter } from '../../../helpers/helpers';
export const AppointmentsHomeContent = () => {
    let history = useHistory();
    const { all_categories } = useSelector((state) => state?.loginReducer);
    const accountingModules = all_categories?.find((cat) => cat?.name === 'Citas')
    const { modules } = accountingModules
    const storage = useSelector((state) => state);

    // const titles = [
    //     {
    //         title: 'Registro de citas',
    //         content: [
    //             { subtitle: "Registrar", action:() => history.push('/citas/RegistroCita') },
    //             { subtitle: "Consulta de citas", action:() =>  history.push('/citas/ListadoDeCitas') },
    //             { subtitle: "Informe de rendimiento", action: () => history.push("/citas/InformeDeRendimiento") }]
    //     },
    //     {
    //         title: 'Cupos adicionales',
    //         content: [
    //             { subtitle: "Listado de cupos", action: () => history.push('/citas/ListadoDeCupos')}]
    //     },
    //     {
    //         title: 'Descuentos',
    //         content: [
    //             { subtitle: "Solicitar descuento", action: () => history.push('/citas/SolicitarDescuentos') },
    //             { subtitle: "Aprobar descuento", action: () => history.push('/citas/AutorizacionDeDescuentos') },
    //         ]
    //     },
    // ]
    const contentDynamic = modules?.map((module) => {
        const textJoin = onCapitalLetter({text:module?.name})
        return {
            title: textJoin,
            content: module?.functionalities?.map((subModule)=>{
                let subModuleName = onCapitalLetter({text: subModule?.name})
                let returnValue = <></>
                let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === subModule?.prefix
                  )?.read
                if(permissionValidation){
                    returnValue = { subtitle: subModuleName, action: () => history.push(subModule?.url) };
                  }
                return returnValue
            })
        }
    })
    return (
        <div>
            {/* /* ----------------------------- PSEUDO-SIDEBAR--------------------------*/}
            {<PseudoSideBarAccordion accordionItems={contentDynamic} />}
        </div>
    )
}
