import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import backIcon from "../../../assets/img/icons/atras-icon.svg";
import GenericTableNew from "../../Layouts/GenericTableNew";
import { connect } from "react-redux";
import ownStyles from "./stylesLegalization.module.scss";
import { applyLegalization, getReportDetails } from "../../../actions/inventoryActions";
import ModalNew from "../../Layouts/ModalNew";
import deepcopy from "deepcopy";
import { convertMoneyFormat } from "../../../helpers/helpers";
import { responseSwal } from "../../../helpers/responseSwal";
class Legalization extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  get initialState() {
    return {
      addLegalization: {},
      modalLots:false,
      articleShow:{},

    };
  }
  componentDidMount() {
      const {id} = this.props.match.params
      this.props.getReportDetails(id, ()=>this.setState({...this.state, addLegalization:this.props.movementDetail}));
  }


  handleEditRecord = (article) => {
    let {articleShow, modalLots} = this.state
    modalLots = !modalLots
    articleShow = deepcopy(article)
    this.setState({articleShow, modalLots})
  }


  renderArticles = (article, index) => {
    let subtotal = (article.qty_article * article.unit_price)
    let totalValue = subtotal;
    if (!!article.discount) {
      totalValue -= article.discount_value;
    }
    if (!!article.iva) {
      totalValue += article.iva_value;
    }
    return (
      <tr key={article.id}>
        <td className="text-center">
          {index+1}
        </td>
        <td className="text-center">
          {article.article_id ? article.article_id : "-"}
        </td>
        <td className="text-center">
          {article.description ? article.description : "-"}
        </td>
        <td className="text-center text-primary text-decoration-underline fw-bold hoverPointer" onClick={()=>this.handleEditRecord(article)}>
          {article?.lots?.length}
        </td>
        
        <td className="text-center">
          <NumberFormat
            name="qty_article"
            disabled={this.state.addLegalization?.status === "solved" ? true : false}
            allowNegative={false}
            className={ownStyles.inputTextTable}
            value={article.qty_article ? article.qty_article : ""}
            placeholder="-"
            maxLength={14}
            onValueChange={(values) => {
              let { addLegalization } = this.state;
              let value = values.value;
              if (parseInt(value) === 0) value = 1;
              addLegalization.articles[index].qty_article = value;
              this.setState({ addLegalization });
            }}
          />
        </td>
        <td className="text-center">
          <NumberFormat
            name="unit_price"
            thousandSeparator
            maxLength={14}
            allowNegative={false}
            prefix={article.unit_price ? "$" : ""}
            className={ownStyles.inputTextTable}
            value={article.unit_price ? article.unit_price : ""}
            placeholder="-"
            onValueChange={(values) => {              
              article.unit_price = values.value;              
              this.setState({ article });
            }}
          />
        </td>
        <td className="text-center">
          <div className="d-flex justify-content-around align-items-center">
            <NumberFormat
              name="discount"
              allowNegative={false}
              suffix={
                article.discount ? "%" : ""
              }
              className={ownStyles.inputTextTable}
              value={article.discount ? article.discount : ""}
              placeholder="-"
              onValueChange={(values) => {
                article.discount = values.value;
                let subtotal = (article.qty_article * article.unit_price)
                article.discount_value = subtotal - (subtotal * (values.value/100))
                this.setState({ article });
              }}
            />
            <NumberFormat
              name="discount_value"
              thousandSeparator
              allowNegative={false}
              prefix={article.discount && article.unit_price ? "$" : ""}
              className={ownStyles.inputTextTable}
              value={article.discount_value}
              placeholder="-"
              readOnly
            />
          </div>
        </td>
        <td className="text-center">
          <div className="d-flex justify-content-around">
            <NumberFormat
              name="iva"
              thousandSeparator
              allowNegative={false}
              suffix="%"
              placeholder="-"
              value={article.iva ? article.iva : ""}
              className={ownStyles.inputTextTable}
              onValueChange={(values) => {
                article.iva = values.value;
                let aux = (subtotal) - article.discount_value
                article.iva_value = aux + (aux * (values.value/100))
                this.setState({ article });
              }}
            />
            <NumberFormat
              name="iva_value"
              thousandSeparator
              allowNegative={false}
              prefix="$"
              placeholder="-"
              value={article.iva_value ? article.iva_value : "-"}
              className={ownStyles.inputTextTable}
              isAllowed={false}
              readOnly
            />
          </div>
        </td>
        <td className="text-center">
          {totalValue ? convertMoneyFormat((totalValue).toFixed(2)) : "-"}   
        </td>
      </tr>
    );
  };


  renderTableFooter = (tfoot) => {
    return tfoot;
  };

// ANCHOR RENDER METHOD
  render() {

    let completeSubtotal = 0,
      completeDiscount = 0,
      completeIvaValue = 0;

    this.state.addLegalization?.articles?.forEach(article => {
      completeSubtotal += (article.qty_article * article.unit_price)
      completeDiscount += article.discount_value
      completeIvaValue += article.iva_value      
    })

    let completeTotal = completeSubtotal
    if (!!completeDiscount) {
      completeTotal -= completeDiscount
    }
    if (!!completeIvaValue) {
      completeTotal += completeIvaValue
    }

    let renderHeaders = [
        // "",    
    "Item",
    "Código",
    "Descripción del artículo",
    "Lote",
    "Cant. ",
    "Valor unidad",
    <div className="mx-auto text-center px-3">
      Descuento
      <br />
      <div className="d-flex justify-content-around">
        <span>%</span>
        <span>$</span>
      </div>
    </div>,
    <div className="mx-auto text-center">
      IVA
      <br />
      <div className="d-flex justify-content-around">
        <span>%</span>
        <span>$</span>
      </div>
    </div>,
    <div className="px-3">Total</div>,
    // "",
    ].map((header) => {
        if (header === "Item" || header == "Código") return <th className="text-center w-10">{header}</th>;
        return <th className="text-center bg-dark-blue">{header}</th>;
      });
      

      var renderArticles = this.state.addLegalization?.articles?.map(
        (article, index) => {
          if (this.state.modal) {
            if (!!article.checked) return this.renderArticles(article, index);            
          }else{
            return this.renderArticles(article, index);
          }
        }
      );

      let renderFooter = [
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td className="p-2">
            <span className="p-1 ml-2 px-3">Subtotal</span>
          </td>
          <td className="flex-wrap text-end">
            {completeSubtotal ? convertMoneyFormat(completeSubtotal.toFixed(2)) : "$0"}
          </td>
        </tr>,
        <tr>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="px-4">Descuento</td>
          <td className="text-end">
            {completeDiscount ? convertMoneyFormat(completeDiscount.toFixed(2)) : "$0"}
          </td>
        </tr>,
        <tr>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="px-4">IVA</td>
          <td className="text-end">
            {completeIvaValue ? convertMoneyFormat(completeIvaValue.toFixed(2)) : "$0"}
          </td>
        </tr>,
        <tr>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="fs-6 px-4">TOTAL</td>
          <td className="fs-6 text-end text-truncate">
            {completeTotal ? convertMoneyFormat(completeTotal.toFixed(2)) : "$0"}
          </td>
        </tr>,
      ].map((item) => {
        return this.renderTableFooter(item);
      });   
   
    return (
      <>
        <div className="d-flex flex-column my-3 mx-5">
          <div className="d-flex">
            <Link to="/compras/informeEntradaPorConsignacion">
              <img
                src={backIcon}
                alt="atras"
                width="18"
                style={{ marginTop: "1.6rem", marginRight: "1rem" }}
              />
            </Link>
            <div className="d-flex flex-column">
              <h1>Legalización</h1>
              <h6 className="text-primary">
                Entrada por consignación {this.state.addLegalization?.wm_number}
              </h6>
            </div>
          </div>
          <div className="d-flex flex-wrap mx-4 px-2 justify-content-between">
            <div className="d-flex flex-column col">
                <label className={ownStyles.labelFont} htmlFor="wm_movement_type">Tipo de movimiento</label>
                <input type="text" disabled className={ownStyles.inputText} value="Legalización" readOnly/>
            </div>
            <div className="d-flex flex-column col mx-2">
                <label className={ownStyles.labelFont} htmlFor="wm_movement_type">Entrada por almacén</label>
                <input type="text" disabled className={ownStyles.inputText} value={this.state.addLegalization?.we_number} readOnly/>
            </div>
            <div className="d-flex flex-column col mx-2">
                <label className={ownStyles.labelFont} htmlFor="wm_movement_type">Orden de compra</label>
                <input type="text" disabled className={ownStyles.inputText} value={this.state.addLegalization?.purchaseOrder_number} readOnly/>
            </div>
            <div className="d-flex flex-column col">
                <label className={ownStyles.labelFont} htmlFor="wm_movement_type">Fecha</label>
                <input type="text" disabled className={ownStyles.inputText} value={this.state.addLegalization?.created_at} readOnly/>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between mx-4 px-2 mt-3">
            <div className="d-flex flex-column col">
                <label className={ownStyles.labelFont} htmlFor="wm_movement_type">Proveedor</label>
                <input type="text" disabled className={ownStyles.inputText} value={this.state.addLegalization?.name} readOnly/>
            </div>
            <div className="d-flex flex-column col mx-2">
                <label className={ownStyles.labelFont} htmlFor="wm_movement_type">NIT</label>
                <input type="text" disabled className={ownStyles.inputText} value={this.state.addLegalization?.nit} readOnly/>
            </div>
            <div className="d-flex flex-column col mx-2">
                <label className={ownStyles.labelFont} htmlFor="wm_movement_type">Almacén</label>
                <input type="text" disabled className={ownStyles.inputText} value={this.state.addLegalization?.description} readOnly/>
            </div>
            <div className="d-flex flex-column col">
                <label className={ownStyles.labelFont} htmlFor="wm_movement_type">No. Remisión</label>
                <input type="text" disabled className={ownStyles.inputText} value={this.state.addLegalization?.we_billNumber} readOnly/>
            </div>
          </div>
          <div className="mx-3 px-3">
            <GenericTableNew
                headers={renderHeaders}
                dark={true}
                lowerCase={true}
                footer={renderFooter}
              >
                {renderArticles}
            </GenericTableNew>
          </div>

              <div className="d-flex justify-content-between" style={{marginTop:"-100px"}}>
              <div className="d-flex w-100 mx-3 px-3">
                <div className="d-flex flex-column w-70">
                  <label htmlFor="observations" className={ownStyles.labelFont}>
                    Observaciones
                  </label>
                  <textarea
                    name="observations"
                    cols="30"
                    rows="10"
                    className={ownStyles.observationsArea}
                    onChange={this.onChangeForm}
                    value={this.state.addLegalization?.observations}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end my-3 mx-3 px-3">
                <button className="btnOutlineClearBlue mx-2" onClick={()=>this.props.history.push("/compras/informeEntradaPorConsignacion")}>Cancelar</button>
                <button className="btn btn-primary" onClick={()=>responseSwal({
                  icon:"warning",
                  title:"¿Está seguro?",
                  text:"Esta acción es irreversible",
                  confirmButtonText:"Si, enviar",
                  cancelButtonText:"Cancelar",
                  showConfirmButton: true,
                  showCancelButton:true
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.props.applyLegalization({...this.state.addLegalization, id: this.props.match.params.id})
                    }
                  })
                }
                style={{border:"none"}}
                >
                  Enviar
                </button>
            </div>

        </div>

        <ModalNew
          size="400"          
          title="Lotes"
          show={this.state.modalLots}
          onHide={() =>  this.setState({...this.state, modalLots:false})}
        >
          <div className="d-flex flex-column">
            <div style={{
              display:"flex",
              flexDirection: "column",
              backgroundColor: "rgba(0, 93, 191, 0.06)",
              borderRadius: "10px", 
              padding: "10px"
            }}>
              <h6 className="text-light-blue">Información del producto</h6>
              <label htmlFor="article_description" className={ownStyles.labelFontGray}>
                Producto
              </label>
              <input type="text" disabled className={ownStyles.inputText} readOnly value={this.state.articleShow.description}/>
            </div>
            <div className="mt-3">
              <GenericTableNew headers={
                [
                  <th className="text-center">Vencimiento</th>,
                  <th className="text-center">Lote</th>,
                  <th className="text-center">Cantidad</th>
                ]
              }
              dark={false}
              >
                {this.state.articleShow?.lots?.map(item => 
                  (
                    <tr>
                      <td>{item.lot_date_expiration}</td>
                      <td>{item.lot_name}</td>
                      <td>{item.lot_qty_received}</td>
                    </tr>
                  )
                )}
              </GenericTableNew>
            </div>            
          </div>
        </ModalNew>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
    isAuth: state.loginReducer.Authenticated,
    currentAccount: state.loginReducer.currentAccount,
    loginReducer: state.loginReducer,
    movementDetail: state.inventoryReducer.reportDetail,

  });
  
  const mapDispatchToProps = (dispatch) => ({
    getReportDetails: (id_wm, doAfter) => {
      dispatch(getReportDetails(id_wm, doAfter));
    },
    applyLegalization: (data, doAfter) => {
      dispatch(applyLegalization(data, doAfter));
    }
    })

export default connect(mapStateToProps, mapDispatchToProps)(Legalization);
