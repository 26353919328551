//_--------------------. import hooks ⛳ -----------------------------//
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
//----------------------------- ☪ Images--------------------------------//
import Lupa from "../../assets/img/icons/lupa.svg";
import auctionDetailLupa2 from "../../assets/img/icons/auctionDetailLupa2.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
// ---------------------------------------------------------------------//

// ----------- 🧮 components --------------------//
import { Tooltip } from "@material-ui/core";
import GenericTableNew from "../Layouts/GenericTableNew";
import Loader from "react-loader-spinner";
import { Link, Redirect, useHistory } from "react-router-dom";
// import Pagination from "react-js-pagination";
// ----------- --------------- --------------------//

// ----------------- 🦄 Styless -----------------------------//
// import paginationStyles from "../Layouts/pagination.module.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";

// ----------------- end styles-----------------------------//

// ---------------- functions 🛬 ---------------------------//
import { getCollectionsLetters } from "../../actions/collectionActions";
import { responseSwal } from "../../helpers/responseSwal";
import CustomPopupExtend from "../Popup/customPopUpExtends";
//-----------------------------------------------------------//

export const CollectionLetter = (props) => {
  const reducers = useSelector((state) => state);
  let history = useHistory();
  const dispatch = useDispatch();
  const [collectionLetters, setCollectionLetters] = useState([]);
  const initialState = () => {
    return {
      addLetter: {},
    };
  };
  const [state, setState] = useState({ initialState });
  const [filters, setFilters] = useState({
    status: "",
    search: "",
    rowTotal: 0,
    eaccount: reducers.loginReducer.currentAccount.id,
  });

  const myPermission = () =>
    reducers.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "collectLet"
    );

  useEffect(async () => {
    await getLetters();
  }, []);

  const getLetters = async () => {
    dispatch({ type: "LOADING", payload: true });

    let res = await getCollectionsLetters(
      filters,
      reducers.loginReducer?.Authorization
    );

    if (res.success) {
      dispatch({ type: "LOADING", payload: false });
      setCollectionLetters(res.results);
      setFilters({ ...filters, rowTotal: res.row_count });
    } else {
      dispatch({ type: "LOADING", payload: false });
      responseSwal({ icon: "error", title: "Error", text: res.message });
      setCollectionLetters(res.results);
    }
  };

  const viewDetalis = (id) => {
    history.push({
      pathname: `/administracion/TMDeCartera/DetalleCarta/${id}`,
      state: { edit: false },
    });
  };
  const editDetalis = (id) => {
    history.push({
      pathname: `/administracion/TMDeCartera/DetalleCarta/${id}`,
      state: { edit: true },
    });
  };

  const renderAccidents = () => {
    return collectionLetters?.map((item) => {
      return (
        <tr className="text-start">
          <td className="px-2">{item.name}</td>
          <td className="text-center">
            <Tooltip title={item.statusValue} placement="top">
              <div
                style={{
                  backgroundColor: item.statusBackground,
                  width: "12px",
                  height: "12px",
                  borderRadius: "100%",
                  margin: "auto",
                }}
              ></div>
            </Tooltip>
          </td>
          <td className="px-2">
            <CustomPopupExtend
              triggerSrc = {threeDots}
              showEdit={true}
              editText="Editar"
              editClickEvent={() => editDetalis(item.id)}
              showDetails={true}
              showDetailsEvent={() => viewDetalis(item.id)}
              textDetails="Ver Detalle"
              noHover
            />
            {/* <Link to={`/administracion/TMDeCartera/DetalleCarta/${item.id}`}>
              <img
                src={auctionDetailLupa2}
                alt="Ver detalle"
                width="18px"
                className="hoverPointer"
              />
            </Link> */}
          </td>
        </tr>
      );
    });
  };
  const handleSumit = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    getLetters();
  };
  return (
    <div className="w-100 px-5">
      {!myPermission().read && <Redirect to="/nomina/inicio" />}
      {reducers.payrollReducer.loading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div className="d-flex px-5 flex-column container-fluid">
        <h1>Cartas de cobro</h1>
        {/* filters section */}
        <div className="d-flex justify-content-between">
          <Col>
            <div className="d-flex">
              <form onSubmit={handleSumit}>
                <Col>
                  <label htmlFor="search">ㅤ </label>
                  <div className="d-flex">
                    <input
                      type="text"
                      placeholder="Buscar..."
                      className={tableStyles.searchUsersAlt}
                      name="search"
                      onChange={(e) =>
                        setFilters({ ...filters, name: e.target.value })
                      }
                    />
                    <button className="mx-2">
                      <img
                        src={Lupa}
                        alt="buscar"
                        width="25px"
                        onClick={() => {
                          setFilters({ ...filters, page: 1 });
                          getLetters();
                        }}
                      />
                    </button>
                  </div>
                </Col>
              </form>
            </div>
          </Col>
          <Col>
            {/* <label htmlFor="newAccident">ㅤ </label> */}
            <div className="d-flex justify-content-end">
              {!!myPermission().create && (
                <Link
                  className="groupAddButton"
                  style={{ marginTop: "15px" }}
                  to="/administracion/TMDeCartera/CrearCarta"
                >
                  <label htmlFor="newAccident" className="darkGray fw-bold">
                    Crear carta
                  </label>
                  <button
                    className="addCheckButton mx-2"
                    style={{ marginBottom: "1.5px" }}
                  />
                </Link>
              )}
            </div>
          </Col>
        </div>
        {/* end filters section */}

        {/* table */}
        <GenericTableNew
          dark={true}
          headers={[
            <th className="text-start px-2">Nombre</th>,
            <th></th>,
            <th style={{ width: "5%" }}></th>,
          ]}
        >
          {renderAccidents()}
        </GenericTableNew>
      </div>
    </div>
  );
};

export default CollectionLetter;
