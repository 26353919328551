import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GET_CAUSATION_TYPE } from "./actionTypes";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);

export const getCausationType = (objFilters, doafter = null) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/causationType/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (doafter) {
        doafter()
      }
      const { results } = res;
      dispatch({
        type: GET_CAUSATION_TYPE,
        payload: results,
      });
    })
    .catch((err) => {
      if (doafter) {
        doafter()
      }
      console.error(err.message);
    });
};

export const updateCausation = (data, doAfter=null, doafterLoading = null) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  // Swal.fire({
  //   title: `¿Está seguro?`,
  //   text: "Verifique la información",
  //   icon: "warning",
  //   showCancelButton: true,
  //   confirmButtonColor: "#003f80",
  //   cancelButtonColor: "#d33",
  //   confirmButtonText: "Si, Guardar",
  //   cancelButtonText: "Cancelar",
  // }).then((result) => {
  //   if (result.isConfirmed) {
      fetch(
        `${URL_GATEWAY}${API_VERSION}/accounting/causation/`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

            token: token,
            Authorization: token
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (doafterLoading){
            doafterLoading()
          }
          if (res.success === true) {

            customSwaltAlert({
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Aceptar",
              title: res.message,
              text:res.results.sequence,
            })

            doAfter()

          } else {

            customSwaltAlert({
              icon: "error",
              title: res.message,
              text:res.results.sequence,
              footer: "Si el problema persiste comuníquese con un asesor.",
              showCancelButton: false,
              confirmButtonText: "Aceptar",
            })

          }
        })
        .catch((res) => {
          if (doafterLoading){
            doafterLoading()
          }

          customSwaltAlert({
            icon: "error",
            title: "Error al registrar nota",
            text:res.message,
            footer: "Si el problema persiste comuníquese con un asesor.",
            showCancelButton: false,
            confirmButtonText: "Aceptar",
          })

        });
  //   }
  // });
};