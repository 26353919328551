import React, { Component, useState, useEffect } from "react";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import Atras from "../../assets/img/icons/atras-icon.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getDetailRecord,
  receiveInvoices,
  sendInvoice,
  getStatus,
  getClient,
  getReceiptInvoices,
} from "../../actions/receiptOfInvoicesActions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function DetReceiptInvoices(props) {
  const storage = useSelector((state) => state);
  const dispatch = useDispatch();
  const isLoading = storage?.InvoiceReceiptReducer?.loadingInvoicesReceipt;
  const [selectedList, setselectedList] = useState([]);
  const [trigger, setTrigger] = useState(0);

  const [info, setInfo] = useState({
    invoice: "",
  });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    filing_status: "unfiled",
    client: "",
    contract: "",
    invoice: "",
    eaccount: storage.loginReducer.currentAccount.id,
  });

  useEffect(() => {
    if (!!props.selectedRecord) {
      dispatch(
        getDetailRecord({
          id: props.selectedRecord,
          eaccount: storage.loginReducer.currentAccount.id,
        })
      );
    }
  }, [
    props.show,
    props.selectedRecord,
    dispatch,
    storage.loginReducer.currentAccount.id,
    storage.invoiceReducer.send_inv,
  ]);

  const isBoxSelected = (id) => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find((x) => x.id == id);
      return !!target;
    }
  };
  const changeBoxSelected = (id) => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find((x) => x.id == id);
      if (!!target) {
        tempSelected = tempSelected.filter((x) => x.id != id);
      } else {
        let newChange = {
          id,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };
  const isBoxAllSelected = () => {
    let res = true;
    let counting = 0;
    if (
      Array.isArray(storage.invoiceReducer.detail.invoices) &&
      Array.isArray(selectedList)
    ) {
      storage.invoiceReducer.detail.invoices.map((item) => {
        if (selectedList.find((itemb) => item.id == itemb.id)) {
          counting++;
        } else {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (
        Array.isArray(storage.invoiceReducer.detail.invoices) &&
        Array.isArray(tempSelected)
      ) {
        storage.invoiceReducer.detail.invoices.map((x) => {
          let id = x.id;
          let newChange = {
            id,
          };
          tempSelected.push(newChange);
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };
  let input = document.getElementById("inputAdd");
  let text = document.getElementById("textAdd");
  const addRow = () => {
    input.classList.remove("d-none");
    text.classList.add("d-none");
  };
  const funcionx = () => {
    input.classList.add("d-none");
    text.classList.remove("d-none");
    dispatch(
      sendInvoice({
        id: props.selectedRecord,
        invoice: info.invoice,
        eaccount: storage.loginReducer.currentAccount.id,
      })
    );
  };

  const [invoices, setInvoices] = useState({
    page: 1,
    perpage: 10,
    observations: "",
  });

  const buttonReceive = () => {
    dispatch(
      receiveInvoices(
        {
          id: props.selectedRecord,
          eaccount: storage.loginReducer.currentAccount.id,
          invoices: selectedList,
          totalInvoices: storage.invoiceReducer.detail.invoices.length,
        },
        () => {
          props.setDetails({ show: false });
          dispatch(getStatus({ type: "receivRec" }));
          dispatch(
            getClient({ eaccount: storage.loginReducer.currentAccount.id })
          );
          dispatch(
            getReceiptInvoices({
              page: 1,
              perpage: 10,
              eaccount: storage.loginReducer.currentAccount.id,
            })
          );
        }
      )
    );
  };

  const header = [
    <th className="text-center">
      <input
        type="checkbox"
        name={"selectedAll"}
        value="selectedAll"
        checked={isBoxAllSelected()}
        onChange={(e) => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th className="text-center">No. Factura </th>,
    <th className="text-center">Fecha factura</th>,
    <th className="text-center">Empresa</th>,
    <th className="text-center">Valor</th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.detail.invoices)) {
      table = storage.invoiceReducer.detail.invoices.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">
            <td className="text-center">
              <input
                type="checkbox"
                name=""
                id=""
                checked={isBoxSelected(x.id)}
                onChange={(e) => changeBoxSelected(x.id)}
              />
            </td>
            <td className="text-uppercase">{x.invoice}</td>
            <td>{x.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")}</td>
            <td>{x.company}</td>
            <td>{"$" + numberWithCommas(x.amount)}</td>
          </tr>
        );
      });
    }
    return table;
  };

  var today = new Date();

  return (
    <>
      {isLoading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem", width: "60%" }}
      >
        <div className="d-flex">
          <img
            className={`${tableStyles.title} mr-3`}
            style={{ cursor: "poniter" }}
            src={Atras}
            onClick={() => props.setDetails({ show: false })}
          />
          {storage.invoiceReducer.loading_det_receipt_invoice && (
            <div className="loading">
              <Loader type="Oval" color="#003f80" height={100} width={100} />
            </div>
          )}
          <h1 className={tableStyles.title}>Acta de facturación</h1>
        </div>
        <div className="text-end">
          <p>{storage.invoiceReducer.detail.sequence}</p>
        </div>
        <Row className="d-flex">
          <Col>
            <p>
              Por medio del presente documento se da constancia del envío de las
              facturas anexadas al departamento de cartera, el día{" "}
              {String(today.getDate()).padStart(2, "0")}, del mes{" "}
              {String(today.getMonth() + 1).padStart(2, "0")} del{" "}
              {today.getFullYear()}.
            </p>
          </Col>
        </Row>

        <GenericTable className="mb-6" headers={header} dark={true}>
          {renderList()}
        </GenericTable>

        <div>
          <p
            className={`${IndividualStyles.labelNew} ml-5`}
            id="textAdd"
            onClick={() => addRow()}
          >
            <b style={{ fontSize: "14px" }}>+ Agregar factura</b>
          </p>
          <input
            id="inputAdd"
            className="d-none"
            type="text"
            value={info.invoice}
            onChange={(e) => setInfo({ ...info, invoice: e.target.value })}
            onKeyPress={(e) => {
              if (e.key == "Enter") {
                funcionx();
              }
            }}
          />
        </div>

        <div className="d-flex mt-5">
          <div className="col-4 mt-4">
            <p className={`${IndividualStyles.labelInfo} mb-0`}>
              {storage.invoiceReducer.detail.generatorFullName}
            </p>
            <p className={`${IndividualStyles.labelInfo} mb-0`}>
              Asistente de facturación
            </p>
            <p className={`${IndividualStyles.labelInfo} mb-0`}>
              C.C:{storage.invoiceReducer.detail.generatorDocNum}
            </p>
          </div>
          <div className="col-8">
            <div className="display-grid">
              <label className={`${tableStyles.crudModalLabel} m-0`}>
                Descripción
              </label>
              <textarea
                className={IndividualStyles.textAreaBig}
                style={{ color: "#58595B" }}
                name="textarea"
                rows="3"
                cols="50"
                value={storage.invoiceReducer.detail.summary}
                readOnly
              ></textarea>
            </div>
          </div>
        </div>
        <div className={IndividualStyles.bottomRow}>
          <Button
            className={`${tableStyles.buttonExtra3} d-block`}
            onClick={() => buttonReceive()}
            disabled={
              !!(storage.invoiceReducer.detail?.invoices?.length > 0)
                ? false
                : true
            }
          >
            Recibir
          </Button>
          <Button
            className={tableStyles.btnSecondary}
            onClick={() => props.setDetails({ show: false })}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
}
export default DetReceiptInvoices;
