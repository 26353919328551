import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import GenericTableNew from "../../Layouts/GenericTableNew";
import Pagination from "react-js-pagination";
import miniShearchIcon from "../../../assets/img/icons/miniShearchIcon.svg";
import iconPrint from "../../../assets/img/icons/iconPrint.svg";
import Search from "../../../assets/img/icons/lupa.svg";
import paginationStyles from "../../Layouts/pagination.module.scss";
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";
import ModalNew from "../../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import {
  getEntryByDonationRecords,
  getReportDetails,
} from "../../../actions/inventoryActions";
import {
  convertDateToLatinFormat,
  convertMoneyFormat,
  isEmptyOrUndefined,
  message,
} from "../../../helpers/helpers";
import { PdfViewer } from "../../Layouts/PdfViewer";
import { getPdfTest } from "../../../actions/IndividualAuctionActions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Tooltip } from "@material-ui/core";

export const ReportEntryByDonation = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const arrReportList = store.inventoryReducer.reportDetail;
  const arrArticles = store.inventoryReducer.reportDetail?.articles;
  const [currentLot, setCurrentLot] = useState([]);
  const arrReportEntryByDonation = store.inventoryReducer?.entryByDonation;
  const [search, setSearch] = useState({
    search: "",
    date_from: "",
    date_until: "",
  });
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    date_from: "",
    date_until: "",
    search: "",
    wm_movement_type: "donationEntry",
    wm_account: store.loginReducer.currentAccount.id
  });

  const handlePageChange = (val) => {
    setFilters({
      ...filters,
      page: val,
    });
  };
  const [showPdf, setShowPdf] = useState(false);
  const [base64, setBase64] = useState("");
  const token = store.loginReducer.Authorization;
  

  const getPdf = async (id) => {
    const result = await getPdfTest(
      {
        id: id,
        doc: 14,
      },
      token
    );
    if (result?.success) {
      return setBase64(result?.pdf[0]?.key?.split("'")[1]);
    } else {
      message(
        "warning",
        "Intente de nuevo",
        "No ha sido posible cargar el documento", undefined, true
      );
      return setShowPdf(false);
    }
  };

  useEffect(() => {
    dispatch(getEntryByDonationRecords(filters));
  }, [trigger, filters]);

  let i = 0;
  const [modalArtControl, setmodalArtControl] = useState({
    showFirst: false,
    showSecond: false,
    showThird: false,
  });
  const listElem = () => {
    let elemMap;
    if (arrReportEntryByDonation !== undefined) {
      const elem2 = arrReportEntryByDonation;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const handleSearch = () => {
    setFilters({
      ...filters,
      page: 1,
      search: search.search,
      date_from: search.date_from,
      date_until: search.date_until,
    });
  };
  const listElemFirst = () => {
    let elemMap;
    if (arrArticles !== undefined) {
      const elem2 = arrArticles;
      elemMap = elem2?.map((elem, i) => {
        return renderElementFirst(elem, i);
      });
    }
    return elemMap;
  };
  const thirdListElem = () => {
    let elemMap;
    if (currentLot !== undefined) {
      const elem2 = currentLot;
      elemMap = elem2.map((elem) => {
        return thirdRenderElement(elem);
      });
    }
    return elemMap;
  };

  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center text-uppercase">
          {elem.wm_number}
        </td>
        <td
          className={`${
            !isEmptyOrUndefined(elem.provider_name)
              ? "text-start "
              : "text-center"
          } col-md-2 `}
        >
          {!isEmptyOrUndefined(elem.provider_name) ? elem.provider_name : "-"}
        </td>
        <td
          className={`${
            !isEmptyOrUndefined(elem.provider_nit)
              ? "text-center "
              : "text-center"
          } col-md-2 `}
        >
          {!isEmptyOrUndefined(elem.provider_nit) ? elem.provider_nit : "-"}
        </td>
        <td className="col-md-2 text-center">
          {elem.created_at?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")}
        </td>
        <td className="col-md-1 text-center cursorPointer">
          <div
            className={`d-flex`}
            onClick={() => handleClickFirstModal(elem)}
            style={{ justifyContent: "flex-center", marginRight: "5px" }}
          >
            <img src={miniShearchIcon} alt="Search icon" />
          </div>
        </td>
      </tr>
    );
  };
  const renderElementFirst = (elem, i) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-1 text-center">{i + 1}</td>
        <td className="col-md-1 text-start">{elem.article_id}</td>
        <td className="col-md-3 text-start">{elem.description}</td>
        <td className="col-md-1 text-center cursorPointer">
          <u onClick={() => handleClickSecondModal(elem)}>
            <b className={tableStyles.darkBlueText}>{elem?.lots.length}</b>
          </u>
        </td>
        <td className="col-md-2 text-center">{elem.qty_received}</td>
        {/* <td className="col-md-2 text-center cursorPointer">
          {convertMoneyFormat(elem.unit_price ? elem?.unit_price : "")}
        </td>
        <td className="col-md-2 text-center">
          {elem.discount}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {convertMoneyFormat(elem.discount_value ? elem.discount_value : "")}
        </td>
        <td className="col-md-2 text-center">
          {convertMoneyFormat(elem.total ? elem.total : "")}
        </td> */}
      </tr>
    );
  };
  const thirdRenderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">
          {convertDateToLatinFormat(elem?.lot_date_expiration)}
        </td>
        <td className="col-md-4 text-center">{elem.lot_name}</td>
        <td className="col-md-2 text-center">{elem.lot_qty_received}</td>
      </tr>
    );
  };

  const localStyles = {
    endPagination: {
      display: "flex",
      justifyContent: "flex-end",
    },
    blueInputText: {
      color: "#005dbf",
    },
    footerStyles: {
      display: "flex",
      justifyContent: "flex-end",
      width: "330%",
    },
    noBorder: { border: "none", padding: "8px" },
  };
  const renderHeadersFirst = [
    <th
      className="col-md-1 text-center pb-3"
      style={{ borderTopLeftRadius: "10px" }}
      key={i}
    >
      Item
    </th>,
    <th className="col-md-1 text-start pb-3" key={i + 1}>
      Código
    </th>,
    <th className="col-md-3 text-start pb-3" key={i + 1}>
      Descripción de Artículo
    </th>,
    <th className="col-md-1 text-center pb-3" key={i + 1}>
      Lote
    </th>,
    // <th className="col-md-1 text-center pb-3" key={i + 1}>
    //   Cant.
    // </th>,
    // <th className="col-md-2 text-center pb-3" key={i + 1}>
    //   Valor unidad
    // </th>,
    // <th className="col-md-2 text-center " key={i + 1}>
    //   Descuento <br></br> % &nbsp;
    //   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $
    // </th>,
    <th
      className="col-md-2 text-center pb-3"
      style={{ borderTopRightRadius: "10px" }}
      key={i + 1}
    >
      Cant.
    </th>,
  ];
  const renderHeaders = [
    <th
      className="col-md-2 text-center"
      style={{ borderTopLeftRadius: "10px" }}
      key={i}
    >
      No. de Entrada
    </th>,
    <th className="col-md-4 text-start" key={i + 1}>
      Proveedor
    </th>,
    <th className="col-md-2 text-center" key={i + 1}>
      NIT
    </th>,
    <th className="col-md-2" className="text-center" key={i + 1}>
      Fecha
    </th>,
    <th
      className="col-md-1 text-center"
      style={{ borderTopRightRadius: "10px" }}
      key={i + 1}
    >
      &nbsp;
    </th>,
  ];
  const renderHeadersThird = [
    <th style={{ borderTopLeftRadius: "10px" }} className="px-2 text-center" key={i}>
      Vencimiento
    </th>,
    <th key={i + 1}>Lote</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={i + 1}>
      Cantidad
    </th>,
  ];

  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  const handleClickFirstModal = (elem) => {
    getPdf(elem.id);
    dispatch(getReportDetails(elem.id));
    setmodalArtControl({
      ...modalArtControl,
      showFirst: true,
    });
  };

  const handleClickSecondModal = (elem) => {
    setCurrentLot(elem.lots);
    setmodalArtControl({
      ...modalArtControl,
      showThird: true,
    });
  };
  return (
    <>
      <ModalNew
        size="940"
        title="Detalle de entrada por donación"
        show={modalArtControl.showFirst}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            showFirst: false,
          })
        }
      >
        <Row className="d-flex">
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>No. Entrada</p>
            <input
              className="register-inputs text-secondary"
              value={arrReportList?.we_number}
            />
          </Col>

          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Entrada por almacén</p>
            <input
              className="register-inputs text-secondary"
              name="id_consumption_center"
              value={arrReportList?.wm_number}
            />
          </Col>
          {/* 
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Orden de compra</p>
            <input
              className="register-inputs text-secondary"
              name="id_consumption_center"
              value={arrReportList?.purchaseOrder_number}
            />
          </Col> */}

          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Fecha</p>
            <input
              className="register-inputs text-secondary"
              name="requisition_date"
              type="date"
              value={arrReportList?.created_at}
            />
          </Col>

          {/* <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Proveedor</p>
            <input
              className="register-inputs text-secondary"
              name="id_consumption_center"
              value={arrReportList?.name}
            />
          </Col> */}

          {/* <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>NIT</p>
            <input
              className="register-inputs text-secondary"
              name="id_consumption_center"
              value={arrReportList?.nit}
            />
          </Col> */}
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>almacén</p>
            <input
              className="register-inputs text-secondary"
              name="id_consumption_center"
              value={arrReportList?.description}
            />
          </Col>
          {/* <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>No. Factura</p>
            <input
              className="register-inputs text-secondary"
              name="id_consumption_center"
              value={arrReportList?.we_billNumber}
            />
          </Col> */}
        </Row>
        <Row className="d-flex">
          {/* /* -------------------------------- PDF Viwer ------------------------------- */}

          <ModalNew
            title="Detalle de entrada por compra"
            show={showPdf}
            btnNoName={"Cancelar"}
            size="700"
            btnYesDisabled={false}
            onHide={() => setShowPdf(false)}
            btnNoEvent={() => setShowPdf(false)}
            btnNoDisabled={false}
          >
            <PdfViewer
              downloadable
              file={`data:application/pdf;base64,${base64}`}
            ></PdfViewer>
          </ModalNew>
          <Col
            xs={12}
            className="d-flex justify-content-end mt-2 cursorPointer"
          >
            <Tooltip title="Imprimir">
              <img
                // className={`${detailStyles.titleIcons}`}
                src={iconPrint}
                alt="imprimir"
                onClick={() => setShowPdf(true)}
              />
            </Tooltip>
          </Col>
        </Row>
        <GenericTableNew headers={renderHeadersFirst}>
          {listElemFirst()}
        </GenericTableNew>

        {/* <div style={localStyles.endPagination}>
          <p className={tableStyles.crudModalLabel}>
            <b>
              Total: &nbsp;&nbsp;
              {arrReportList?.sumTotal
                ? convertMoneyFormat(arrReportList?.sumTotal)
                : ""}
            </b>
          </p>
        </div> */}
      </ModalNew>
      {/* /* ------------------------------ Third modal ------------------------------ */}
      <ModalNew
        size="200"
        title="Lotes"
        show={modalArtControl.showThird}
        hideCancelButton={true}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            showThird: false,
          })
        }
      >
        <GenericTableNew dark={true} headers={renderHeadersThird}>
          {thirdListElem()}
        </GenericTableNew>
      </ModalNew>

      <div className={tableStyles.container}>
          {(store.inventoryReducer.entryByDonation_loading) &&
            <div className="loading">
                <Loader
                type="Oval"
                color="#003f80"
                height={100}
                width={100}
                />
            </div>
          }

        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Informe entradas por donación</h1>

          <Row className="d-flex">
            <Col xs={2} className="align-self-end">
              <p className={tableStyles.crudModalLabel}>Desde</p>
              <div className="d-flex">
                <input
                  className={`register-inputs text-secondary`}
                  name="requisition_date"
                  type="date"
                  value={search.date_from}
                  onChange={(e) =>
                    setSearch({ ...search, date_from: e.target.value })
                  }
                  onKeyDown={e=>{
                    if (e.key === 'Enter') {
                      handleSearch()
                  }  }}
                  // onChange={(e) =>
                  //   setFilters({
                  //     ...filters,
                  //     date_from: e.target.value,
                  //   })
                  // }
                />
                {/* <div style={{ color: "gray" }}>━</div> */}
              </div>
            </Col>
            <Col xs={2} className="align-self-end">
              <p className={tableStyles.crudModalLabel}>Hasta</p>
              <input
                className="register-inputs text-secondary"
                name="requisition_date"
                type="date"
                value={search.date_until}
                onChange={(e) =>
                  setSearch({ ...search, date_until: e.target.value })
                }
                onKeyDown={e=>{
                    if (e.key === 'Enter') {
                      handleSearch()
                  }  }}
                // onChange={(e) =>
                //   setFilters({
                //     ...filters,
                //     date_until: e.target.value,
                //   })
                // }
              />
            </Col>

            <Col xs={4}>
              <div className="mt-4 d-flex align-self-end">
                <input
                  className={`${tableStyles.SearchNew} align-self-end`}
                  type="text"
                  placeholder="Buscar..."
                  value={search.search}
                  onChange={(e) =>
                    setSearch({ ...search, search: e.target.value })
                  }
                  onKeyDown={e=>{
                    if (e.key === 'Enter') {
                      handleSearch()
                  }  
                  }}
                />
                <button>
                  <img
                    src={Search}
                    alt="User icon"
                    height={24}
                    className={`${tableStyles.iconSvgMargin2}`}
                    onClick={() => handleSearch()}
                    // onClick={() => setTrigger(trigger + 1)}
                  />
                </button>
              </div>
            </Col>
          </Row>

          <GenericTableNew dark={true} headers={renderHeaders}>
            {listElem()}
          </GenericTableNew>

          <div
            className={`${paginationStyles.wrapper}`}
            style={localStyles.endPagination}
          >
            <p className={paginationStyles.paginationText}>
              Pag. {store.inventoryReducer.entryByDonation ? filters.page : ""}
              {" de "}
              {Math.ceil(
                store.inventoryReducer.entryByDonationTotal / filters.perpage
              )
                ? Math.ceil(
                    store.inventoryReducer.entryByDonationTotal /
                      filters.perpage
                  )
                : ""}{" "}
              ({store.inventoryReducer.entryByDonationTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={store.inventoryReducer.entryByDonationTotal}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
