import _ from "lodash";
import { useMemo, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
//
import OrdTable from "../OrderingModule/OrderingComponents/OrdTable";
import ThreeDots from "../assets/img/icons/threeDots.svg";
import UploadSvg from "../assets/img/icons/clearBlueUpload.svg";
import DownloadSvg from "../assets/img/icons/downloadArrow2.svg";
import FileSvg from "../assets/img/icons/planilla.svg";
import ExcelFile from "../assets/files/textos_pred_no_ins.xlsx";
import PopUp from "../components/Popup/customPopUpExtends";
import OrdModal from "../OrderingModule/OrderingComponents/OrdModal";
//
import { convertBase64, customSwaltAlertAsistencial, formatToRcSelect, loader } from "../helpers";
import { useGetMethod, usePostMethod } from "../Hooks";
//
import { ordCustomSelect } from "../components/Layouts/react-select-custom";
import ordComponentStyles from "../OrderingModule/OrderingComponents/ordComponentStyles.module.scss";
import tableStyles from "../components/Layouts/tableStyle.module.scss";

const CONFIG_URL = "/medical/clinicHistory/configuration/";

export default function PredefinedTextNoIns() {
    const store = useSelector((state) => state);
    const PT_INITIAL_STATE = {
        userId: store.loginReducer.user_data.id,
        eaccount: store.loginReducer.currentAccount.id,
        active: true,
        type: "non-institutional",
        description: "",
        segmentDetail: null,
        moduleId: null,
        componentId: null,
        segmentId: null,
        users: []
    };

    const [uploadedFile] = useState({
        type: "non-institutional",
        createdBy: store.loginReducer.user_data.id,
        eaccount: store.loginReducer.currentAccount.id,
    });
    const [searchValue, setSearchValue] = useState("");
    const [services, setServices] = useState({
        showNewModal: false,
        showUploadModal: false,
        isEditing: false,
        headers: [
            { title: "Código", className: "col-1 text-center" },
            { title: "Descripción", className: "col-3 text-start" },
            { title: "Módulo", className: "col-1 text-start" },
            { title: "Componente", className: "col-2 text-start" },
            { title: "Segmento", className: "col-2 text-start" },
            { title: "Detalle del segmento", className: "col-2 text-start" },
            { title: "Estado", className: "col-2 text-center" },
            { title: (<>&nbsp;</>), className: "col-1" },
        ]
    });
    const [filters, setFilters] = useState({
        perpage: 10,
        page: 1,
    });
    const [predefinedText, setPredefinedText] = useState(PT_INITIAL_STATE);

    const { load: predefinedTextsLoader, trigger: getPredefinedTexts, results: predefinedTexts } = useGetMethod();
    const { trigger: getAccountUsers, results: accountUsersData } = useGetMethod();

    const { trigger: getModules, results: modulesData, loader: modulesLoader } = useGetMethod();
    const { trigger: getComponents, results: componentsData, loader: componentsLoader } = useGetMethod();
    const { trigger: getSegments, results: segmentsData, loader: segmentsLoader } = useGetMethod();
    const { trigger: getSegmentsDetail, results: segmentsDetailData, loader: segmentsDetailLoader } = useGetMethod();

    const { load: updatePredefinedTextsLoader, trigger: updatePredefinedText, } = usePostMethod();
    const { load: createPredefinedTextsLoader, trigger: createPredefinedText, } = usePostMethod();
    const { load: uploadPredefinedTextsLoader, trigger: uploadPredefinedTexts } = usePostMethod();
    const { load: predefinedTextsFileLoader, trigger: getPredefinedTextsFile } = useGetMethod();

    useEffect(() => {
        async function fetchAsyncData() {
            await getPredefinedTexts({
                url: "/medical/clinicHistory/predefinedTexts/",
                objFilters: {
                    ...filters,
                    eaccount: store.loginReducer.currentAccount.id,
                    type: "non-institutional",
                    search: searchValue,
                },
                token: store.loginReducer.Authorization
            });
        }
        fetchAsyncData().catch(error => console.error("Error: ", error));
    }, [
        getPredefinedTexts,
        store.loginReducer.currentAccount.id,
        store.loginReducer.Authorization,
        searchValue,
        updatePredefinedTextsLoader,
        createPredefinedTextsLoader,
        uploadPredefinedTextsLoader,
        filters
    ]);

    useEffect(() => {
        if (services.showNewModal) {
            async function fetchAsyncData() {
                getAccountUsers({
                    url: "/medical/generals/medical/",
                    objFilters: { eaccount: store.loginReducer.currentAccount.id },
                    token: store.loginReducer.Authorization
                });
                getModules({
                    url: CONFIG_URL,
                    objFilters: { entity: "modules" },
                    token: store.loginReducer.Authorization
                });
            }
            fetchAsyncData().catch(error => console.error("Error: ", error));
        }
    }, [
        services.showNewModal,
        store.loginReducer.Authorization,
        store.loginReducer.currentAccount.id,
        getAccountUsers,
        getModules
    ]);

    useEffect(() => {
        if (predefinedText.moduleId) {
            async function fetchAsyncData() {
                getComponents({
                    url: CONFIG_URL,
                    objFilters: {
                        moduleId: predefinedText.moduleId,
                        entity: "components"
                    },
                    token: store.loginReducer.Authorization
                });
            }
            fetchAsyncData().catch(error => console.error("Error: ", error));
        }
    }, [
        predefinedText.moduleId,
        store.loginReducer.Authorization,
        getComponents
    ]);

    useEffect(() => {
        if (predefinedText.componentId) {
            async function fetchAsyncData() {
                getSegments({
                    url: CONFIG_URL,
                    objFilters: {
                        componentId: predefinedText.componentId,
                        entity: "segments"
                    },
                    token: store.loginReducer.Authorization
                });
            }
            fetchAsyncData().catch(error => console.error("Error: ", error));
        }
    }, [
        predefinedText.componentId,
        store.loginReducer.Authorization,
        getSegments
    ]);

    useEffect(() => {
        if (predefinedText.segmentId) {
            async function fetchAsyncData() {
                getSegmentsDetail({
                    url: CONFIG_URL,
                    objFilters: {
                        segmentId: predefinedText.segmentId,
                        entity: "dtlSegments"
                    },
                    token: store.loginReducer.Authorization
                });
            }
            fetchAsyncData().catch(error => console.error("Error: ", error));
        }
    }, [
        predefinedText.segmentId,
        store.loginReducer.Authorization,
        getSegmentsDetail
    ]);

    useEffect(() => {
        return () => { debounceSearch.cancel(); };
    });

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    const debounceSearch = useMemo(() => {
        return _.debounce(handleSearchChange, 500);
    }, []);

    const handleChangeState = (item) => {
        updatePredefinedText({
            url: "/medical/clinicHistory/predefinedTexts/",
            token: store.loginReducer.Authorization,
            method: "PUT",
            body: {
                updatedBy: store.loginReducer.user_data.id,
                id: item.id,
                active: item.active === 1 ? false : true,
                eaccount: store.loginReducer.currentAccount.id,
            }
        });
    };

    const handleOpenEditModal = (item) => {
        const formattedUsers = accountUsersData?.results?.filter(
            user => item.users?.some(el => el.userId === user.mdId)
        );

        setPredefinedText({
            ...predefinedText,
            id: item.id,
            active: item.active,
            moduleId: item.moduleId,
            componentId: item.componentId,
            segmentId: item.segId,
            segmentDetail: item.dtlSegmentId,
            description: item.description,
            users: formattedUsers?.map(user => ({
                label: user.mdName,
                value: user.mdId
            }))
        });
        setServices({ ...services, showNewModal: true, isEditing: true });
    };

    const handleOpenUploadModal = () => {
        setServices({ ...services, showUploadModal: true });
    };

    const handleCloseModal = () => {
        setPredefinedText(PT_INITIAL_STATE);
        setServices({ ...services, showNewModal: false, isEditing: false });
    };

    const handleCreatePredefinedText = () => {
        const payload = {
            type: predefinedText.type,
            createdBy: predefinedText.userId,
            updatedBy: predefinedText.userId,
            description: predefinedText.description,
            dtlSegment: predefinedText.segmentDetail,
            eaccount: predefinedText.eaccount,
            users: predefinedText.users?.map(user => user.value)
        };
        if (predefinedText?.id) {
            updatePredefinedText({
                url: "/medical/clinicHistory/predefinedTexts/",
                token: store.loginReducer.Authorization,
                method: "PUT",
                body: {
                    ...payload,
                    id: predefinedText.id
                },
                succesAction: (results) => {
                    customSwaltAlertAsistencial({
                        icon: "success",
                        titleColor: "#1ABCD2",
                        title: "Editado exitosamente",
                        text: results.message,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false,
                    });
                },
                doAfterSuccess: () => {
                    handleCloseModal();
                },
                doAfterException: (err) => {
                    customSwaltAlertAsistencial({
                        icon: "warning",
                        titleColor: "#1ABCD2",
                        title: "Intenta de nuevo",
                        text: err.message,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false,
                    });
                }
            });
        } else {
            createPredefinedText({
                url: "/medical/clinicHistory/predefinedTexts/",
                token: store.loginReducer.Authorization,
                method: "POST",
                body: payload,
                succesAction: (results) => {
                    customSwaltAlertAsistencial({
                        icon: "success",
                        titleColor: "#1ABCD2",
                        title: "Creado exitosamente",
                        text: results.message,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false,
                    });
                },
                doAfterSuccess: () => {
                    handleCloseModal();
                },
                doAfterException: (err) => {
                    customSwaltAlertAsistencial({
                        icon: "warning",
                        titleColor: "#1ABCD2",
                        title: "Intenta de nuevo",
                        text: err.message,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false,
                    });
                }
            });
        }
    };

    const handleUploadFile = async (event) => {
        const file = event?.target?.files[0];
        if (file) {
            let encode = await convertBase64(file);
            let file64 = encode?.split(",")[1];

            await uploadPredefinedTexts({
                url: "/medical/clinicHistory/uploadPredifinedTexts/",
                token: store.loginReducer.Authorization,
                method: "POST",
                body: { ...uploadedFile, base64_file: file64 },
                succesAction: (results) => {
                    customSwaltAlertAsistencial({
                        icon: "info",
                        titleColor: "#1ABCD2",
                        title: "Archivo cargado",
                        text: results.message,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false,
                    });
                },
                doAfterSuccess: () => {
                    setServices({ ...services, showUploadModal: false });
                },
                doAfterException: (err) => {
                    customSwaltAlertAsistencial({
                        icon: "warning",
                        titleColor: "#1ABCD2",
                        title: "Intenta de nuevo",
                        text: err.message,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false,
                    });
                }
            });
        }
    };

    const handleDownloadFile = async () => {
        await getPredefinedTextsFile({
            url: "/medical/clinicHistory/predefinedTexts/",
            objFilters: {
                ...filters,
                eaccount: store.loginReducer.currentAccount.id,
                type: "non-institutional",
                search: searchValue,
                download: true
            },
            token: store.loginReducer.Authorization,
            succesAction: (results) => {
                let treatPdf = results.results.replace(/^b'/gm, "").replace(/=*'$/gm, "");
                let linkSource = `data:application/vnd.ms-excel;base64,${treatPdf}`;
                let downloadLink = document.createElement("a");
                let fileName = "textos_predefinidos_no_institucionales.xlsx";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            },
        });
    };


    const handleSelectUser = (selectedOption) => {
        setPredefinedText({
            ...predefinedText,
            users: selectedOption
        });
    };

    const renderRow = (item) => {
        return (
            <tr key={item.id} className={`hover-table-row`} >
                <td>{item.id}</td>
                <td className="text-start">{item.description}</td>
                <td className="text-start">{item.moduleName}</td>
                <td className="text-start">{item.componentName}</td>
                <td className="text-start">{item.segName}</td>
                <td className="text-start">{item.dtlSegmentName}</td>
                <td className="text-center">
                    {item.active === 1 ? (
                        <div className={tableStyles.app_container_state}>
                            <span className={tableStyles.app_state_true}>
                                Habilitado
                            </span>
                        </div>
                    ) : (
                        <div className={tableStyles.app_container_state}>
                            <span className={tableStyles.app_state_false}>
                                Deshabilitado
                            </span>
                        </div>
                    )}
                </td>
                <td className="text-end">
                    <PopUp
                        showEdit={true}
                        showEnable={true}
                        position="right"
                        triggerSrc={ThreeDots}
                        editClickEvent={() => handleOpenEditModal(item)}
                        isEnabled={item.active === 1}
                        enableClickEvent={() => {
                            customSwaltAlertAsistencial({
                                icon: "warning",
                                titleColor: "#1ABCD2",
                                title: "¿Está seguro?",
                                text: `El texto predefinido será ${item.active ? "deshabilitado" : "habilitado"}`,
                                confirmButtonText: "Si, continuar",
                                cancelButtonText: "Cancelar",
                                showCancelButton: true,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    handleChangeState(item);
                                }
                            });
                        }}
                    />
                </td>
            </tr>
        );
    };

    const renderTable = () => {
        return (
            <OrdTable
                className={`${tableStyles.shade}`}
                headers={services.headers}
                hasChildren={true}
                style={{ borderRadius: "10px", overflow: "hidden" }}
                paginate={{
                    activePage: filters.page,
                    totalPages: predefinedTexts?.rowTotal,
                    perPage: filters.perpage,
                    pageRangeDisplayed: 3,
                    onChangePage: async (e) => {
                        await getPredefinedTexts({
                            url: "/medical/clinicHistory/predefinedTexts/",
                            objFilters: {
                                eaccount: store.loginReducer.currentAccount.id,
                                type: "non-institutional",
                                search: searchValue,
                                page: e
                            },
                            token: store.loginReducer.Authorization
                        });
                        setFilters({ ...filters, page: e });
                    },
                    showTextDetails: true
                }}
            >
                {predefinedTexts?.results?.map(text => renderRow(text))}
            </OrdTable>
        );
    };

    const renderModal = () => {
        const formattedUsers = accountUsersData?.results?.map(user => ({ label: user.mdName, value: user.mdId }));
        const selectedUsers = formattedUsers?.filter(user => predefinedText.users?.some(el => user.value === el.value));

        const formattedModules = formatToRcSelect(modulesData?.results, "id", "name");
        const formattedComponents = formatToRcSelect(componentsData?.results, "id", "name");
        const formattedSegments = formatToRcSelect(segmentsData?.results, "id", "name");
        const formattedSegmentsDetail = formatToRcSelect(segmentsDetailData?.results, "id", "fieldName");

        return (
            <OrdModal
                title={`${services.isEditing ? "Editar" : "Nuevo"} texto predefinido`}
                titleClassName="fs-5"
                show={services.showNewModal}
                btnYesName="Guardar"
                btnNoName="Cancelar"
                size="lg"
                onHide={() => handleCloseModal()}
                btnYesEvent={() => handleCreatePredefinedText()}
                btnNoEvent={() => handleCloseModal()}
            >
                <form>
                    <div className="row">
                        {services.isEditing && (
                            <div className="col-12">
                                <Form.Group className="mb-3">
                                    <Form.Label className={tableStyles.app_title_input}>
                                        <div className="ms-1">
                                            <span className={tableStyles.app_title_new_item}>Código</span>
                                            <span style={{ color: "#FFFF00" }}>*</span>
                                        </div>
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Escribir..."
                                        className="ord-roundInput"
                                        value={predefinedText?.id}
                                        readOnly
                                    />
                                </Form.Group>
                            </div>
                        )}
                        <div className="col-12">
                            <Form.Group className="mb-3">
                                <Form.Label className={tableStyles.app_title_input}>
                                    <div className="ms-1">
                                        <span className={tableStyles.app_title_new_item}>Usuario</span>
                                        <span style={{ color: "#FFFF00" }}>*</span>
                                    </div>
                                </Form.Label>
                                <Select
                                    noOptionsMessage={() => "No hay datos"}
                                    className="w-100 text-secondary"
                                    isClearable
                                    isMulti
                                    styles={ordCustomSelect}
                                    onChange={handleSelectUser}
                                    value={selectedUsers}
                                    options={formattedUsers}
                                    placeholder="Seleccionar usuarios..."
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group className="mb-3">
                                <Form.Label className={tableStyles.app_title_input}>
                                    <div className="ms-1">
                                        <span className={tableStyles.app_title_new_item}>Módulo</span>
                                        <span style={{ color: "#FFFF00" }}>*</span>
                                    </div>
                                </Form.Label>
                                <Select
                                    noOptionsMessage={() => "No hay datos"}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    options={formattedModules}
                                    onChange={(option) => setPredefinedText({
                                        ...predefinedText,
                                        moduleId: option.value,
                                        componentId: "",
                                        segmentId: "",
                                        segmentDetail: ""
                                    })}
                                    value={formattedModules?.find(module => module.value === predefinedText.moduleId)}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group className="mb-3">
                                <Form.Label className={tableStyles.app_title_input}>
                                    <div className="ms-1">
                                        <span className={tableStyles.app_title_new_item}>Componente</span>
                                        <span style={{ color: "#FFFF00" }}>*</span>
                                    </div>
                                </Form.Label>
                                <Select
                                    noOptionsMessage={() => "No hay datos"}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    options={formattedComponents}
                                    onChange={(option) => setPredefinedText({
                                        ...predefinedText,
                                        componentId: option.value,
                                        segmentId: "",
                                        segmentDetail: ""
                                    })}
                                    value={formattedComponents?.find(component => component.value === predefinedText.componentId)}
                                    isDisabled={predefinedText.moduleId ? false : true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group className="mb-3">
                                <Form.Label className={tableStyles.app_title_input}>
                                    <div className="ms-1">
                                        <span className={tableStyles.app_title_new_item}>Segmento</span>
                                        <span style={{ color: "#FFFF00" }}>*</span>
                                    </div>
                                </Form.Label>
                                <Select
                                    noOptionsMessage={() => "No hay datos"}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    options={formattedSegments}
                                    onChange={(option) => setPredefinedText({
                                        ...predefinedText,
                                        segmentId: option.value,
                                        segmentDetail: ""
                                    })}
                                    value={formattedSegments?.find(segment => segment.value === predefinedText.segmentId)}
                                    isDisabled={predefinedText.componentId ? false : true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group className="mb-3">
                                <Form.Label className={tableStyles.app_title_input}>
                                    <div className="ms-1">
                                        <span className={tableStyles.app_title_new_item}>Detalle del segmento</span>
                                        <span style={{ color: "#FFFF00" }}>*</span>
                                    </div>
                                </Form.Label>
                                <Select
                                    noOptionsMessage={() => "No hay datos"}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    options={formattedSegmentsDetail}
                                    onChange={(option) => setPredefinedText({ ...predefinedText, segmentDetail: option.value })}
                                    value={formattedSegmentsDetail?.find(detail => detail.value === predefinedText.segmentDetail)}
                                    isDisabled={predefinedText.segmentId ? false : true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group className="mb-3">
                                <Form.Label className={tableStyles.app_title_input}>
                                    <div className="ms-1">
                                        <span className={tableStyles.app_title_new_item}>Descripción</span>
                                        <span style={{ color: "#FFFF00" }}>*</span>
                                    </div>
                                </Form.Label>
                                <textarea
                                    placeholder="Escribir..."
                                    className="form-control ord-roundInput"
                                    onChange={({ target }) => setPredefinedText({ ...predefinedText, description: target.value })}
                                    value={predefinedText.description}
                                    style={{ height: 60, resize: "none" }}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </form>
            </OrdModal >
        );
    };

    const renderUpload = () => {
        return (
            <Modal
                show={services.showUploadModal}
                size="md"
                centered
                className={`${tableStyles.container}`}
                onHide={() => setServices({ ...services, showUploadModal: false })}
            >
                <div className="px-4 py-5 d-flex flex-column align-items-center">
                    <img src={FileSvg} width={80} alt="file icon" />
                    <p className="mt-4">Descargar plantilla base en formato .xlsx</p>
                    <div className="d-flex align-items-center mt-3">
                        <a
                            href={ExcelFile}
                            download={"plantilla_textos_predefinidos_no_institucionales"}
                            className="ordBtnSecondaryModuleAssistance me-3"
                            style={{ whiteSpace: "nowrap", padding: "0 15px" }}
                        >
                            Descargar plantilla
                        </a>
                        <input type="file" id="uploadXlsx" style={{ display: "none" }} onChange={handleUploadFile} />
                        <label htmlFor="uploadXlsx" className="ordBtnPrimaryModuleAssitance cursorPointer" style={{ padding: "0 35px" }}>
                            Subir archivo
                        </label>
                    </div>
                </div>
            </Modal>
        );
    };

    const render = () => {
        return (
            <>
                {predefinedTextsLoader && loader}
                {updatePredefinedTextsLoader && loader}
                {createPredefinedTextsLoader && loader}
                {modulesLoader && loader}
                {componentsLoader && loader}
                {segmentsLoader && loader}
                {segmentsDetailLoader && loader}
                {uploadPredefinedTextsLoader && loader}
                {predefinedTextsFileLoader && loader}

                {renderModal()}
                {renderUpload()}
                <div className={`${tableStyles.tlnContainer}`}>
                    <div className={tableStyles.ordContainer}>
                        <h1 style={{ fontSize: '40px' }} className={`fw-bold ${tableStyles.ordDarkBlueText} ${tableStyles.fontFamiTitle}`}>
                            Textos predefinidos no institucionales
                        </h1>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <input
                                    placeholder='Escribe aquí para buscar'
                                    className={`${ordComponentStyles.backgroundImage} ${tableStyles.appInputDate} pl-2 w-50`}
                                    onChange={debounceSearch}
                                />
                            </div>
                            <div
                                className="d-flex justify-content-end ordGroupAddButton align-items-center svgIconDark me-3"
                                onClick={() => handleOpenUploadModal()}
                            >
                                <label className="ordGrayText fw-bold">
                                    Cargar
                                </label>
                                <img width={20} src={UploadSvg} className="ms-2" alt="" />
                            </div>

                            <div
                                className="d-flex justify-content-end ordGroupAddButton align-items-center svgIconDark me-3"
                                onClick={handleDownloadFile}
                            >
                                <label className="ordGrayText fw-bold">
                                    Descargar
                                </label>
                                <img src={DownloadSvg} className="ms-2" alt="" />
                            </div>
                            <div
                                className={"d-flex justify-content-end ordGroupAddButton align-items-center svgIconDark"}
                                onClick={() => {
                                    setPredefinedText(PT_INITIAL_STATE);
                                    setServices({ ...services, showNewModal: true });
                                }}
                            >
                                <label className="ordGrayText fw-bold">
                                    Nuevo
                                </label>
                                <button className="addCheckButton ms-2" />
                            </div>
                        </div>
                        {renderTable()}
                    </div>
                </div>
            </>
        );
    };

    return render();
}