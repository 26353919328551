import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { message } from "../helpers/helpers";
import {
  GET_MY_VACATIONS_LIST,
  GET_PERMISSIONS_LIST_MY_PAYROLL,
  GET_TICKETS_LIST,
} from "./actionTypes";

export const getPermissionsList = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/historyPermission/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_PERMISSIONS_LIST_MY_PAYROLL,
        payload: res.results,
      });
    })
    .catch((error) => {
      console.error(error.message);
      return message("error", "Error", "Ha ocurrido un error");
    });
};

export const sendPermissionRequest = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/export/registerPermission/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    // if (result.success) {
    // } else {
    //   return message("error", "Error", result.message);
    // }
    return result;
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "Ha ocurrido un error");
  }
};

export const getMyVacationsList = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/historyHolidays/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_MY_VACATIONS_LIST,
        payload: res.results,
      });
    })
    .catch((error) => {
      console.error(error.message);
      return message("error", "Error", "Ha ocurrido un error");
    });
};

export const availableDays = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/daysAvailable/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "Ha ocurrido un error");
  }
};

export const sendVacationRequest = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/registerHolidays/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    // if (result.success) {
    // } else {
    //   return message("error", "Error", result.message);
    // }
    return result;
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "Ha ocurrido un error");
  }
};

export const getCurriculmPdf = async (data, token) => {
  let filters = convertFilterToString(data);
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/print-candidate-resume/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "No ha sido posible cargar el documento");
  }
};

export const getMyTickets = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_TICKETS_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/payroll/request_payrollSheet/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_TICKETS_LIST,
        payload: res.results,
        loading: false,
      });
      if (!res.success) {
        dispatch({
          type: GET_TICKETS_LIST,
          loading: false,
        });
        return message("warning", "Intenta de nuevo",res.message, undefined, true);
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_TICKETS_LIST,
        loading: false,
      });
      console.error(error.message);
      return message("error", "Error", "Ha ocurrido un error");
    });
};

export const sendPaymentTicket = async (data, token) => {
  // {
  //   eaccount: "",
  //   month: "",
  //   year: ""
  // }
  const result = {
    success: true,
    message: "Se envió esa vuelta",
  };
  return result;
  // try {
  //   const query = await fetch(
  //     `${URL_GATEWAY}${API_VERSION}/payroll/registerHolidays/`,
  //     {
  //       method: "POST",
  //       headers: {
  //         Authorization: token,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     }
  //   );
  //   let result = await query.json();
  //   if (result.success) {
  //     return result;
  //   } else {
  //     return message("error", "Error", result.message);
  //   }
  // } catch (error) {
  //   console.error(error.message);
  //   return message("error", "Error", "Ha ocurrido un error");
  // }
};
