import {GET_SPECIALITY} from "./actionTypes";
import {GET_SUBSPECIALITY, SET_INDEX_SUBSPECIALITY} from "./actionTypes";

import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const getSpeciality = (objFilters) => (dispatch, getState) => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
    fetch(
      `${URL_GATEWAY}${API_VERSION}/admin/especiality?${filters}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        const { results, row_total } = res;
        dispatch({
          type: GET_SPECIALITY,
          payload: results,
          total: row_total,
        });
      })
      .catch((err) => {
      });
   
  };

  export const getSubSpeciality = (objFilters, id) => (dispatch, getState) => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
    fetch(
      `${URL_GATEWAY}${API_VERSION}/admin/subespeciality?search=&id_especiality=${id}&${filters}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        const { results, row_total } = res;
        dispatch({
          type: GET_SUBSPECIALITY,
          payload: results,
        });
        dispatch({
          type: SET_INDEX_SUBSPECIALITY,
          payload: row_total,
        });
      })
      .catch((err) => {
      });
   
  };
  export const updateSubspeciality = (specialty) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/admin/subespeciality`, {
      method: "PUT",
      body: JSON.stringify(specialty),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
       
      })
      .catch((err) => {
        // MySwal.fire({
        //   icon: "error",
        //   title: "Error al conectar",
        //   text:
        //     "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
        // });
      });
  };


  export const createSpeciality = (obj, filters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const {description} = obj;
    if (description != "" ) {
      fetch(
        `${URL_GATEWAY}${API_VERSION}/admin/especiality`, 
        {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
             MySwal.fire({
              icon: "success",
              title: "¡Especialidad creada!",
              text: `Especialidad,  ${description} creada exitosamente`,
            });
            dispatch(getSpeciality(filters));
  
          
        })
        .catch((err) => {
          MySwal.fire({
            icon: "error",
            title: "Error al conectar",
            text:
              "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
          });
        });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error al ingresar",
        text: "Debe ingresar los datos obligatorios para crear la especialidad.",
      });
    }
  };
  export const createSubspeciality = (obj, objSpeciality) => (dispatch, getState) => {
    let data = convertFilterToString(obj);
    const filters = {
      page: 1,
      search: "",
      perpage: 5,
    };
    const token = getState().loginReducer.Authorization;
    const {descripcion} = obj;
    const {id} = objSpeciality;
    if (descripcion != "" ) {
      fetch(
        `${URL_GATEWAY}${API_VERSION}/admin/subespeciality?search=${descripcion}&id_especiality=${id}`, 
        {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
             MySwal.fire({
              icon: "success",
              title: "¡Subespecialidad creada!",
              text: `Subespecialidad,  ${descripcion} creada exitosamente`,
            });
            dispatch(getSubSpeciality(filters,id));
  
          
        })
        .catch((err) => {
          MySwal.fire({
            icon: "error",
            title: "Error al conectar",
            text:
              "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
          });
        });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error al ingresar",
        text: "Debe ingresar los datos obligatorios para crear la especialidad.",
      });
    }
  };

  export const updateSpeciality = (obj, filters) => (dispatch, getState) => {

    const token = getState().loginReducer.Authorization;
    const {description, id} = obj;
    if (description != "" && id != "") {
      var data = obj;
      fetch(`${URL_GATEWAY}${API_VERSION}/admin/especiality?id=${obj.id}&search=${obj.description}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
  
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
        
          
            MySwal.fire({
              icon: "success",
              title: "¡Especialidad actualizada!",
              text: `¡Especialidad ${obj.description} actualizada exitosamente!`,
            });
            dispatch(getSpeciality(filters));
         
        })
        .catch((err) => {
          MySwal.fire({
            icon: "error",
            title: "Error al conectar",
            text:
              "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
          });
        });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error al ingresar",
        text: "Debe ingresar los datos obligatorios para actualizar el registro.",
      });
    }
  };

  export const activationDeactivation = (id, status, filters, description) => (
    dispatch,
    getState
  ) => {
    const token = getState().loginReducer.Authorization;
    let active = status ? "deshabilitar" : "habilitar";
    if (status === true) {
      status = 0;
    } else if (status === false) {
      status = 1;
    }
  
   let data = {
       id: id,
       active: status
   };
    if (id != null && status != null) { 
      
      Swal.fire({
        title: `¿Está seguro de ${active} la especialidad?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#003f80",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, estoy seguro",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          fetch(
            `${URL_GATEWAY}${API_VERSION}/admin/especiality?id=${data.id}&enabled=${data.active}`,
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
              },
            }
          )
            .then((response) => response.json())
            .then((res) => {
              if (res.success || !res.message) {
                MySwal.fire({
                  icon: "success",
                  title: "¡Especialidad actualizada!",
                  text: `Se acaba de  ${active} la especialidad ${description}`,
                });
                dispatch(getSpeciality(filters));
              } else {
              }
            })
            .catch((err) => {
              MySwal.fire({
                icon: "error",
                title: "Error al guardar",
                text: "No se pudo cambiar el estado",
                footer: "Si el problema persiste comuníquese con un asesor.",
              });
            });
        }
      });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error al ingresar",
        text: "Debe ingresar correo y contraseña para acceder al sistema.",
      });
    }
  };