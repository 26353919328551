import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import False from "../../assets/img/icons/extraBtnRedX.svg";
import SearchIcon from "../../assets/img/icons/lupa.svg";
import iconDownload from "../../assets/img/icons/iconDownload.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import CheckMark from "../../assets/img/icons/CheckMark.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import { connect } from "react-redux";
import "reactjs-popup/dist/index.css";
import Pagination from "react-js-pagination";
import { PAGE, PERPAGE } from "../../helpers/constants";
import ownStyles from "./tableAgreements.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import GenericTableNew from "../Layouts/GenericTableNew";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import deepcopy from "deepcopy";
import ModalNew from "../Layouts/ModalNew";
import {
  changeStatusAgreement,
  getAgreements,
  getAgreementsProviders,
  getOneAgreement,
  updateAgreement,
} from "../../actions/agreementActions";
import { convertMoneyFormat } from "../../helpers/helpers";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import ReactSelect from "react-select";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);

class AuthAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.props.getAgreements({
      page: 1,
      perpage: 10,
      eaccount: this.props.currentAccount.id,
      multistatus:"authorized,reqAuth,denied"
    });
    this.props.getAgreementsProviders({multistatus:"authorized,reqAuth,denied"});
    this.setState(this.initialState);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {      
      if (!!this.props.agreementReducer.agreementDetails && this.state.addAgreement !== this.props.agreementReducer.agreementDetails) {
        this.setState({
          ...this.state,
          addAgreement:this.props.agreementReducer.agreementDetails[0]
        })
      }
    }
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  get initialState() {
    return {
      modal: false,
      modalOC: false,
      modalFinalize: false,
      modalQuotation: false,
      editRecord: false,
      modalDate:false,
      addAgreement: {
        articles: [],
      },
      article: {},
      checkModal: false,
      countChecked: 0,
      paginationModal: {
        page: 1,
        perpage: 10,
        totalIndex: 0,
      },
      filters: {
        search: "",
        status: "",
        due_date: "",
        // current_date:CURDATE,
        eaccount: this.props.currentAccount.id,
        perpage: PERPAGE,
        page: PAGE,
        multistatus:"authorized,reqAuth,denied"
      },
      paginationDeliveries: {
        page: 1,
        perpage: 10,
      },
      selectedList: [],
      headerState: [
        "ID",
        "Nombre del convenio",
        "Vencimiento",
        "Proveedor",
        "Cotización",
        "Estado",
        "‌",
      ],
    };
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getAgreements(filters)
  }
  // pagination articles 🗃
  handlePageChangeModalArticle(pageNumber) {
    let { paginationModal } = this.state;
    paginationModal.page = pageNumber;
    this.setState({ paginationModal });
  }
  // pagination deliveries 🚚
  handlePageChangemodalOC(pageNumber) {
    let { paginationDeliveries, addAgreement } = this.state;
    paginationDeliveries.page = pageNumber;
    let min =
      pageNumber * paginationDeliveries.perpage - paginationDeliveries.perpage;
    let max = pageNumber * paginationDeliveries.perpage;
    addAgreement.articles.slice(min, max);
    this.setState({ paginationDeliveries });
  }

  handleEditRecord = (agreement, edit) => {    
    let { addAgreement, modal, checkModal, editRecord } = this.state;    
    modal = !modal;
    editRecord = !!edit
    this.props.getOneAgreement(agreement.id)
    addAgreement = deepcopy(agreement);
    this.setState({ addAgreement, modal, checkModal, editRecord });
  };

  // toggleStatus = (account) => {
  // };

  // Search puc when user was typing
  onKeyUpSearch = (e) => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
    } else {
      e.preventDefault();
    }
    // this.props.getAgreements(filters);
    this.setState({ filters });
  };

  //Method to search when click in magnify lens
  handleSearch = (e) => {
    let { filters } = this.state;
    filters.page = 1;
    this.props.getAgreements(filters);
    this.setState({ filters });
  };

  toggleModal = (e) => {
    // let { modal } = this.state;
    // modal = !modal;
    // if (this.state.editRecord) {
    //   this.setState({ modal });
    // }
    // this.setState(this.initialState);
    // this.setState({ modal });
  };

  toggleModalDataSheet = (e) => {
    // let { modalDataSheet } = this.state;
    // modalDataSheet = !modalDataSheet;
    // this.setState({ modalDataSheet });
  };

  onKeyDownNumber = (e) => {
    let { errorInputs } = this.state;
    if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
      e.preventDefault();
      errorInputs = true;
      return customSwaltAlert({
        icon: "warning",
        text: "Intenta de nuevo",
        title: "Este campo solo acepta números",
      });
    }
    errorInputs = false;
    this.setState({ errorInputs });
  };  

  handleSubmit() {
    // this.toggleModal();
    // if (this.state.editRecord) {
    //   return this.props.updateArticle(this.state.addArticle);
    // }
    // // this.props.createArticle(this.state.addArticle);
    // this.setState(this.initialState);
  }

  //   toggle visibilty of modal to edit or create record
  handleOnHide = () => {
    let { modal, addAgreement, selectedList } = this.state;
    modal = !modal;
    addAgreement = { articles: [] };
    selectedList = [];
    this.setState({ modal, addAgreement, selectedList });
  };

  // Method to get css style for circle in status
  getStatusClass = (status) => {
    switch (status) {
      case "expired":
        return { class: tableStyles.circleRedAuto, text: "Vencido" };

      case "vigent":
        return { class: tableStyles.circleGreenAuto, text: "vigente" };

      case "reqAuth":
        return { class: tableStyles.warningIcon, text: "Requiere autorización" };

      case "denied":
        return { class: tableStyles.deniedCross, text: "Denegado" };

      case "authorized":
        return { class: tableStyles.checkMark, text: "Autorizado" };

      default:
        return { class: tableStyles.circleRedAuto, text: "Vencido" };
    }
  };

  getCoinNickname = (coin) => {
    switch (coin) {
      case "dolar":
        return "USD";
      case "peso":
        return "COP";
      case "Dolar estadunidense":
        return "USD";
      case "Peso colombiano":
        return "COP";
      default:
        return "-";
    }
  };

  getPaymentCondition = (pay) => {
    switch (pay) {
      case "prepayment":
        return "Pago anticipado";
      case "delivery":
        return "Pago contraentrega";
      case "pay20":
        return "Pago a 20 días";
      case "pay30":
        return "Pago a 30 días";
      case "pay60":
        return "Pago a 60 días";
      case "pay90":
        return "Pago a 90 días";
      case null:
        return "-";
      default:
        return pay;
    }
  };

  onChangeForm = (event) => {
    const { name, value } = event.target;
    let { addAgreement } = this.state;
    addAgreement[name] = value;
    this.setState({ addAgreement });
  };

  providerOptions = ()=>{
    let arr = [{label: "Seleccionar...", value:"", name:"provider"}];
    this.props.agreementReducer.listProviders?.map(
      (prov) => {
        arr.push({label: prov.name, value: prov.id, name:"provider"})
      }
    )
    return arr
  }
  onChangeCheckModal = (event, index) => {
    let { addAgreement, countChecked, selectedList, checkModal } = this.state;
    countChecked = 0;
    addAgreement.articles[index].checked =
      !addAgreement.articles[index].checked;
    let element = addAgreement.articles[index];
    if (addAgreement.articles[index].checked === false) {
      selectedList = selectedList.filter((x) => x !== element.id);
    } else if (!selectedList.includes(element.id))
      selectedList.push(element.id);

    for (let i = 0; i < addAgreement.articles.length; i++) {
      const element = addAgreement.articles[i];
      if (!!element.checked) {
        countChecked++;
      } else {
        checkModal = false;
      }
    }
    this.setState({ addAgreement, countChecked, selectedList, checkModal });
  };

  renderArticles = (article, index) => {
    let realIndex = index + 1;
    let count = `${
      this.state.paginationModal.page - 1 > 0
        ? this.state.paginationModal.page - 1
        : ""
    }${realIndex}`;
    if (realIndex > 1 && realIndex % 10 === 0)
      count = this.state.paginationModal.page * 10;
    return (
      <tr key={article.id}>
        <td className="text-center w-10">{count}</td>
        <td className="text-start">
          {article?.article_ref?.description
            ? article.article_ref.description
            : "-"}
        </td>
        <td className="text-center w-10">{article.brand ? article.brand : "-" }</td>
        <td className="text-end">
          {article?.unit_price ? `${convertMoneyFormat(article.unit_price)}` : "-"}
        </td>
        <td className="text-center">
          {article.qty_article ? article.qty_article : "-"}
        </td>
        <td className="text-center">
          {article.discount ? `${article.discount}%` : "-"}
        </td>
        <td className="text-end">
          {article.discount ? `${convertMoneyFormat(article.iva_value)}` : "-"}
        </td>
        <td className="text-end">
          {article.qty_article && article.unit_price
            ? convertMoneyFormat(
                article.qty_article * article.unit_price +
                  article.iva_value -
                  article.discount_value
              )
            : "-"}
        </td>
        {/* <td className="text-center">
          <img src={Clock} alt="entregas" width="18" className={article?.deliveries?.length > 0 ? "" : ownStyles.clock } onClick={()=>this.setState({...this.state, modalOC:true, modal:false, article:article})}/>
        </td> */}
      </tr>
    );
  };
  renderPurcharseOrders = (purcharse) => {
    return (
      <tr key={purcharse.id}>
        <td className="text-center">
          {purcharse.delivery_date ? purcharse.delivery_date : "-"}
        </td>
        <td className="text-center">
          {purcharse.purchaseOrder_number ? purcharse.purchaseOrder_number : "-"}
        </td>
        <td className="text-center w-10"><img src={Filtrar} width="15"/></td>
      </tr>
    );
  };
  onChangeFilter = (event) => {
    const { name, value } = event.target ?? event;
    let { filters } = this.state;
    filters[name] = value;
    // this.props.getAgreements(filters);
    this.setState({ filters });
  };

  nextDetails = () => {
    let { addAgreement, selectedList } = this.state;
    addAgreement.articles = addAgreement.articles.filter((x) =>
      selectedList.includes(x.id)
    );
    if (addAgreement.status === "solved") {
      addAgreement.pay_conditions = "";
    }
    this.setState({ addAgreement, selectedList });
  };

  handleUntilDate = (event) => {
    event.preventDefault();
    let {modalDate, addAgreement} = this.state;
    modalDate = !modalDate;
    addAgreement.valid_until_before = addAgreement.valid_until;
    this.setState({modalDate, addAgreement});
  }


  authorizedAgreement = () => {
    customSwaltAlert({
      icon:"warning",
      title:"¿Está seguro?",
      html:`Se autorizará el convenio <br/> ${this.state.addAgreement.agreement_number} ${this.state.addAgreement.name}`,
      showCancelButton:true,
      cancelButtonText:"Cancelar",
      showConfirmButton:true,
      confirmButtonText:"Si, autorizar"
    })
    .then((result) => {
      if (result.isConfirmed) {
        const bodySend = {
          id: this.state.addAgreement.id,
          status: "authorized",
          filters:this.state.filters
        }
        this.props.changeStatusAgreement(bodySend, () =>
        this.setState({
          ...this.state,          
          modal: false,
        }))
      }      
    })
  }

  deniedAgreement = () => {
    customSwaltAlert({icon:"warning",
      title:"¿Está seguro?",
      html:`Se denegará el convenio <br/> ${this.state.addAgreement.agreement_number} ${this.state.addAgreement.name}`,
      showCancelButton:true,
      cancelButtonText:"Cancelar",
      showConfirmButton:true,
      confirmButtonText:"Si, denegar"      
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          ...this.state,
          modalFinalize:true
        })        
      }      
    })
  }


  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "AuthConv"
    );

  // ANCHOR RENDER METHOD
  render() {
    if (!this.props.isAuth) {
      return <Redirect to={"/login"} />;
    }

    let renderHeaders = this.state.headerState
      ? this.state.headerState.map((header) => {
          return (
            <th key={`header-${header}`} className="text-center">
              {header}
            </th>
          );
        })
      : "";

    // let min =
    //   this.state.paginationModal.page * this.state.paginationModal.perpage -
    //   this.state.paginationModal.perpage;
    // let max =
    //   this.state.paginationModal.page * this.state.paginationModal.perpage;
    var renderArticlesModal =
        this.state.addAgreement?.quotation_ref?.quotation_article_ref.map((article, index) => {
            return this.renderArticles(article, index);
          });

    var renderOC = this.state.addAgreement?.purchaseOrder_ref?.map((purcharseOrder) => {
            return this.renderPurcharseOrders(purcharseOrder);
          })
    var renderHeadersModal = [
      "#",
      "Descripción del artículo",
      "Marca",
      "Precio Unt.",
      "Cant.",
      "Descuento",
      "IVA",
      "Valor Total",
    ].map((header) => {
      return (
        <th key={`hdModal-${header}`} className="text-center">
          {header}
        </th>
      );
    });

    var renderHeadersOC = ["Fecha", "No.", ""].map(
      (header) => {
          return <th className="text-center">{header}</th>;
      }
    );

    let renderAgreements = this.props?.agreementReducer?.agreements.map(
      (item) => {
        let circleClass = this.getStatusClass(item.status).class;
        const renderTooltip = (props) => (
          <Tooltip id="button-tooltip" {...props}>
            {this.getStatusClass(item.status).text}
          </Tooltip>
        );
        return (
          <tr key={item.id}>
            <td className="text-center">{item.agreement_number}</td>
            <td>{item.name}</td>
            <td className="text-center">
              {convertDateToLatinFormat(item.valid_until)}
            </td>
            {/* <td
              className="text-center text-primary fw-bold hoverPointer text-decoration-underline"
              onClick={()=>this.setState({...this.state, modalOC:true, addAgreement:item})}
            >
              {item.purchaseOrder_ref?.length}
            </td> */}
            <td className="text-wrap w-25">{item.provider_ref.name}</td>
            <td 
              className="text-center text-primary text-decoration-underline hoverPointer"
              onClick={()=>{
                this.props.getOneAgreement(item.id);
                this.setState({...this.state, modalQuotation:true})
              }}
            >
              {item.quotation_ref?.quotation_number}
            </td>
            <td className="text-center">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <div className={circleClass}></div>
              </OverlayTrigger>
            </td>
            <td className="text-center">
              <CustomPopupExtend
              noHover
              triggerSrc={threeDots}
                showEnable={item.status === "vigent" ? true : false}
                showDetails={this.myPermission().read ? true : false}
                textDetails="Ver Detalle"
                showDetailsEvent={
                  this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "AuthConv"
                  )?.read ?
                  () => this.handleEditRecord(item)
                  : null
                }                
                extraButtons={
                  this.myPermission()?.reject && item.status === "denied"
                    ? [
                        {
                          class: ownStyles.enterLineIcon,
                          text: "Deshacer",
                          event: () =>
                            {
                              customSwaltAlert({
                                icon:"warning",
                                title:"¿Está seguro?",
                                html:`Se modificará el convenio <br> ${item?.name}`,
                                showConfirmButton:true,
                                showCancelButton:true,
                                confirmButtonText:"Si, deshacer",
                                cancelButtonText:"Cancelar"
                              }).then((result)=>{
                                if (result.isConfirmed) {
                                  this.props.changeStatusAgreement({
                                    id:item?.id,
                                    status:"reqAuth",
                                    filters: this.state.filters
                                  }, ()=> this.setState(this.initialState))
                                }
                              })
                          }
                        },
                      ]
                    : null
                }
              />
            </td>
          </tr>
        );
      }
    );
    return (
      <div className="w-100 mt-3">
        {this.props.loading ? (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        ) : null}
        <div className={`w-100 ${this.props.loading ? "disabled" : ""}`}>
          <div className={`${ownStyles.containerAllContent}`}>
            <div className="row">
              <h1 className="top-h1 text-primary">Autorizar Convenios</h1>
            </div>

            <div className="d-flex justify-content-between align-items-center">
                    <div
                      className={`${ownStyles.selectGroup}`}
                      style={{ flex: 1 }}
                    >
                      <label
                        htmlFor="valid_from"
                        className={`${ownStyles.labelFont}`}
                      >
                        Fecha de vencimiento
                      </label>
                      <input
                        type="date"
                        name="valid_from"
                        className={`register-inputs `}
                        onChange={this.onChangeFilter}
                        onKeyDown={e=>{
                          if (e.key === 'Enter') {
                            this.handleSearch()
                          }
                        }}
                      />
                    </div>

                    <div 
                      className={`${ownStyles.selectGroup}`}
                      style={{ flex: 1 }}
                    >
                      <label
                        htmlFor="provider"
                        className={`${ownStyles.labelFont}`}
                      >
                        Proveedor
                      </label>
                      <ReactSelect noOptionsMessage={() => 'No hay datos'}
                        placeholder="Seleccionar..."
                        name="status"
                        styles={customSelectNewDark}
                        onChange={(e)=>{
                          this.onChangeFilter(e);
                          this.handleSearch()
                        }
                        }
                        options={this.providerOptions()}
                      />
                    </div>

                    <div 
                      className={`${ownStyles.selectGroup}`}
                      style={{ flex: 1 }}
                    >
                      <label
                        htmlFor="active"
                        className={`${ownStyles.labelFont}`}
                      >
                        Estado
                      </label>
                      <ReactSelect noOptionsMessage={() => 'No hay datos'}
                        placeholder="Seleccionar..."
                        name="status"
                        styles={customSelectNewDark}
                        onChange={(e)=>{
                          this.onChangeFilter(e);
                          this.handleSearch()
                        }}
                        options={[
                          { label: "Seleccionar...", value: "", name: "status" },
                          { label: "Autorizado", value: "authorized", name: "status" },
                          { label: "Denegado", value: "denied", name: "status" },
                          { label: "Requiere autorización", value: "reqAuth", name: "status" },
                        ]}
                      />
                    </div>

                    <div 
                      className={`d-flex flex-column align-self-end`}
                      style={{ flex: 2 }}
                    >
                      <label
                        className={`${ownStyles.labelFont}`}
                      >
                        &nbsp;
                      </label>
                      <input
                        className={`${tableStyles.SearchNew}`}
                        type="text"
                        name="search"
                        onKeyUp={this.onKeyUpSearch}
                        placeholder="Buscar..."
                        onKeyDown={e=>{
                          if (e.key === 'Enter') {
                            this.handleSearch()
                          }   
                        }}
                      />
                    </div>

                    <div 
                      className={`d-flex flex-column  align-self-end`}
                    >
                      <label
                        className={`${ownStyles.labelFont}`}
                      >
                        &nbsp;
                      </label>
                      <img
                        src={SearchIcon}
                        alt="buscar"
                        height={24}
                        className="icons-svg-top-table float-left hoverPointer"
                        onClick={this.handleSearch}
                      />
                    </div>
            </div>
            <GenericTableNew
              headers={renderHeaders}
              dark={true}
              lowerCase={true}
            >
              {renderAgreements}
            </GenericTableNew>
            {this.props.row_total > 10 && (
            <div
              className={`${paginationStyles.wrapper} justify-content-between`}
            >
              <p style={{ display: "inline" }}>
                Pag. {this.state.filters.page} de{" "}
                {Math.ceil(this.props.row_total / this.state.filters.perpage)
                  ? Math.ceil(
                      this.props.row_total / this.state.filters.perpage
                    )
                  : ""}{" "}
                ({this.props.row_total} encontrados)
              </p>
              
                <Pagination
                  activePage={this.state.filters.page}
                  itemsCountPerPage={10}
                  totalItemsCount={
                    this.props.row_total ? this.props.row_total : 10
                  }
                  pageRangeDisplayed={10}
                  activeClass={paginationStyles.activeClass}
                  onChange={this.handlePageChange.bind(this)}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
            </div>
              )}
            {/* <div className="d-flex juistify-content-start">
            <button className="btn" style={{backgroundColor:"#CECECE", color:"#fff", padding:"0.5rem 1.5rem"}}>Crear cotización</button>
          </div> */}
          </div>
        </div>
        <ModalNew
          onHide={this.handleOnHide}
          show={this.state.modal}
          size="xl"
          centered
          title={this.state.addAgreement?.name}
          subtitle={this.state.addAgreement?.provider_ref?.name}
          extraButtons={
            this.myPermission()?.approve && this.myPermission().reject &&
              this.state.addAgreement?.status === "reqAuth" ? [
              {
                type: 1,
                name: "Denegar",
                icon: False,
                event: () => this.deniedAgreement()
              },
              {
                type: 1,
                name: "Autorizar",
                icon: CheckMark,
                event: () =>
                  this.authorizedAgreement()
              },
            ]: null
          }
        >
          <div
            className={`row justify-content-between w-100 mt-3`}
          >
            <div className="d-flex flex-column col">
              <label htmlFor="valid_from" className={ownStyles.labelFont}>
                Vigencia desde* :
              </label>
              <input
                type="date"
                name="valid_from"
                className={`${ownStyles.selectInputClear} text-primary fw-bold`}
                disabled
                value={this.state.addAgreement?.valid_from}
              />
            </div>
            <div className={`d-flex flex-column col ${ownStyles.lineBefore}`}>
              <label htmlFor="valid_until" className={ownStyles.labelFont}>
                Hasta*:
              </label>
              <input
                type="date"
                name="valid_until"
                className={`${ownStyles.selectInputClear} ${this.state.editRecord ? ownStyles.inputIconEditWithOriginal : ""} fw-bold text-primary`}
                value={this.state.addAgreement?.valid_until}
                disabled={this.state.editRecord ? false : true}
                onClick={(e)=>this.handleUntilDate(e)}
                // onChange={this.onChangeForm}
              />
            </div>
            <div className="d-flex flex-column col"></div>
            <div className="d-flex flex-column col"></div>
            <div style={{ width: "48px", alignSelf: "flex-end" }}></div>
          </div>
          <div className="row justify-content-between w-100 mt-2">
            <div className="d-flex flex-column col">
              <label htmlFor="quotation_number" className={ownStyles.labelFont}>
                No. de la cotización
              </label>
              <input
                type="text"
                name="quotation_number"
                className={`${ownStyles.selectInputClear} text-primary fw-bold`}
                disabled
                value={this.state.addAgreement?.quotation_ref?.quotation_number}
              />
            </div>           

            <div className="d-flex flex-column col">
              <label htmlFor="quote_validity" className={ownStyles.labelFont}>
                Validez
              </label>
              <input
                type="date"
                name="quote_validity"
                className={`${ownStyles.selectInputClear} bg-disabled-input`}
                disabled
                value={this.state.addAgreement?.quotation_ref?.quote_validity}
              />
            </div>

            <div className="d-flex flex-column col">
              <label htmlFor="name" className={ownStyles.labelFont}>
                Términos y condiciones
              </label>
              <input
                type="text"
                name="name"
                className={ownStyles.selectInputClear}
                value={
                  this.state.addAgreement?.quotation_ref?.individual_auctions
                    ?.terms
                }
                disabled
              />
            </div>

            <div className="d-flex flex-column col">
              <label htmlFor="pay_conditions" className={ownStyles.labelFont}>
                Condiciones de pago
              </label>
              <input
                type="text"
                name="pay_conditions"
                className={`${ownStyles.selectInputClear} ${this.state.editRecord ? ownStyles.inputIconEdit : ""} bg-disabled-input`}
                disabled={!!this.state.editRecord ? false : true}
                value={this.getPaymentCondition(
                  this.state.addAgreement?.quotation_ref?.pay_conditions
                )}
                onChange={this.onChangeForm}
              />
            </div>

            <img
              src={iconDownload}
              style={{ width: "48px", alignSelf: "flex-end" }}
              className="hPointer"
            />
          </div>          
          {/* TODO 8 REM CHANGE */}
          <GenericTableNew headers={renderHeadersModal}>
            {renderArticlesModal}
          </GenericTableNew>
          
          {/* <div className="d-flex justify-content-end">
            <Pagination
              activePage={this.state.paginationModal.page}
              itemsCountPerPage={this.state.paginationModal.perpage}
              totalItemsCount={
                this.props?.totalArticles ? this.props.totalArticles : 10
              }
              pageRangeDisplayed={10}
              activeClass={paginationStyles.activeClass}
              onChange={this.handlePageChangeModalArticle.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div> */}
          <div className="d-flex justify-content-between">
            <h6 className="fw-bold text-primary paddingLeft1rem">TOTALES</h6>
            <h6 className="fw-bold text-primary px-3">
              ${this.state.addAgreement?.quotation_ref?.total.toLocaleString()}
            </h6>
          </div>
          <div className="d-flex w-100 justify-content-between">
            <div className="w-100">
              <label htmlFor="observations" className={ownStyles.labelFont}>
                Observaciones
              </label>
              <textarea
                type="text"
                name="observations"
                value={this.state.addAgreement?.observations}
                disabled
                className={`${ownStyles.inputText} ${ownStyles.textareaObservationsAuth} bg-input-disabled ml-2 w-100`}
              />
            </div>
          </div>
        </ModalNew>

        {/* modal quotation */}
        <ModalNew
          onHide={()=>this.setState({...this.state, modalQuotation:false})}
          show={this.state.modalQuotation}
          size="xl"
          centered
          title={"Detalle de la cotización"}
          subtitle={this.state.addAgreement?.provider_ref?.name}
          
        >          
        <div
            className={`row justify-content-between w-100 mt-3`}
          >
            <div className="d-flex flex-column col">
              <label htmlFor="valid_from" className={ownStyles.labelFont}>
                Vigencia desde* :
              </label>
              <input
                type="date"
                name="valid_from"
                className={`${ownStyles.selectInputClear} text-primary fw-bold`}
                disabled
                value={this.state.addAgreement?.valid_from}
              />
            </div>
            <div className={`d-flex flex-column col ${ownStyles.lineBefore}`}>
              <label htmlFor="valid_until" className={ownStyles.labelFont}>
                Hasta*:
              </label>
              <input
                type="date"
                name="valid_until"
                className={`${ownStyles.selectInputClear} ${this.state.editRecord ? ownStyles.inputIconEditWithOriginal : ""} fw-bold text-primary`}
                value={this.state.addAgreement?.valid_until}
                disabled={this.state.editRecord ? false : true}
                onClick={(e)=>this.handleUntilDate(e)}
                // onChange={this.onChangeForm}
              />
            </div>
            <div className="d-flex flex-column col"></div>
            <div className="d-flex flex-column col"></div>
            <div style={{ width: "48px", alignSelf: "flex-end" }}></div>
          </div>
          <div className="row justify-content-between w-100 mt-3">
            <div className="d-flex flex-column col">
              <label htmlFor="quotation_number" className={ownStyles.labelFont}>
                No. de la cotización
              </label>
              <input
                type="text"
                name="quotation_number"
                className={`${ownStyles.selectInputClear} text-primary fw-bold`}
                disabled
                value={this.state.addAgreement?.quotation_ref?.quotation_number}
              />
            </div>

            <div className="d-flex flex-column col">
              <label htmlFor="name" className={ownStyles.labelFont}>
                Nombre de la cotización
              </label>
              <input
                type="text"
                name="name"
                className={ownStyles.selectInputClear}
                value={
                  this.state.addAgreement?.quotation_ref?.individual_auctions
                    ?.description
                }
                disabled
              />
            </div>

            <div className="d-flex flex-column col">
              <label htmlFor="quote_validity" className={ownStyles.labelFont}>
                Validez
              </label>
              <input
                type="date"
                name="quote_validity"
                className={`${ownStyles.selectInputClear} bg-disabled-input`}
                disabled
                value={this.state.addAgreement?.quotation_ref?.quote_validity}
              />
            </div>

            <div className="d-flex flex-column col">
              <label htmlFor="pay_conditions" className={ownStyles.labelFont}>
                Condiciones de pago
              </label>
              <input
                type="text"
                name="pay_conditions"
                className={`${ownStyles.selectInputClear} bg-disabled-input`}
                disabled
                value={this.getPaymentCondition(this.state.addAgreement?.quotation_ref?.pay_conditions)}
              />
            </div>
            <img
              src={iconDownload}
              style={{ width: "48px", alignSelf: "flex-end" }}
              className="hoverPointer"
            />
          </div>
          {/* <div className={ownStyles.containerTableModal}> */}
          <GenericTableNew headers={renderHeadersModal} scrollInside={true}>
            {renderArticlesModal}
          </GenericTableNew>
          {/* </div> */}
          {/* <div className="d-flex justify-content-end">
            <Pagination
              activePage={this.state.paginationModal.page}
              itemsCountPerPage={this.state.paginationModal.perpage}
              totalItemsCount={
                this.props?.totalArticles ? this.props.totalArticles : 10
              }
              pageRangeDisplayed={10}
              activeClass={paginationStyles.activeClass}
              onChange={this.handlePageChangeModalArticle.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div> */}
          <div className="d-flex justify-content-between">
            <h6 className="fw-bold text-primary paddingLeft1rem">TOTALES</h6>
            <h6 className="fw-bold text-primary px-3">
              ${this.state.addAgreement?.quotation_ref?.total.toLocaleString()}
            </h6>
          </div>
          <div className="d-flex w-100 justify-content-between">
            <div className="w-100">
              <label htmlFor="observations" className={ownStyles.labelFont}>
                Observaciones
              </label>
              <textarea
                type="text"
                name="observations"
                readOnly
                value={this.state.addAgreement?.quotation_ref?.observations}
                className={`${ownStyles.inputText} ${ownStyles.textareaObservationsAuth} ml-2 w-100 bg-input-disabled`}
              />
            </div>
          </div>
        </ModalNew>
        <ModalNew
          title="Ordenes de compra"
          show={this.state.modalOC}
          onHide={() => {
            this.setState({
              ...this.state,
              modalOC: false,
            });
          }}          
        >
          <div>
            
            <GenericTableNew headers={renderHeadersOC}>
              {renderOC}
            </GenericTableNew>
            <div className="d-flex w-100 justify-content-end">
              {/* <Pagination
                activePage={this.state.paginationDeliveries.page}
                itemsCountPerPage={this.state.paginationDeliveries.perpage}
                totalItemsCount={
                  this.state?.article?.deliveries?.length
                    ? this.state.article.deliveries.length
                    : 10
                }
                pageRangeDisplayed={10}
                activeClass={paginationStyles.activeClass}
                onChange={this.handlePageChangemodalOC.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              /> */}
            </div>
          </div>
        </ModalNew>
        <ModalNew
          show={this.state.modalFinalize}
          size="md"
          title="Denegación"
          btnYesEvent={() =>
            {
              const bodySend = {
                id: this.state.addAgreement.id,
                status: "denied",
                justification:this.state.justification,
                filters:this.state.filters  
              }              
              this.props.changeStatusAgreement(
                bodySend,
                () =>
                this.setState({
                  ...this.state,
                  modalFinalize: false,
                  modal: false,
                })
                )
            }
            
          }
          btnYesName="Guardar"
          btnYesDisabled={!this.state.justification ? true : false}
          btnNoEvent={() =>
            this.setState({ ...this.state, modalFinalize: false })
          }
        >
          <div className="d-flex flex-column">
            <label htmlFor="justification" className={ownStyles.labelFont}>
              Justificación
            </label>
            <textarea
              name="justification"
              id=""
              cols="30"
              rows="10"
              className={ownStyles.textAreaJustification}
              onChange={(e) =>
                this.setState({ ...this.state, justification: e.target.value })
              }
            ></textarea>
          </div>
        </ModalNew>

        {/* modal date */}
        <ModalNew
          title="Cambio de fecha"
          show={this.state.modalDate}
          onHide={()=>this.setState({...this.state, modalDate:false})}
          btnYesEvent={()=>this.props.updateAgreement({...this.state.addAgreement}, this.props.getAgreements(this.state.filters))}
          btnYesDisabled={!!this.state.modalDate && !this.state.addAgreement?.justification_renew ? true : false}
        >
          <div className="d-flex flex-column">
              <div className="w-100">
                <label htmlFor="due_date_before" className={ownStyles.labelFontGray}>Fecha de vencimiento anterior</label>
                <input
                  type="date"
                  name="valid_until_before"
                  className={` fw-bold text-primary bg-input-disabled w-100 ${ownStyles.selectInput}`} 
                  disabled
                  value={this.state.addAgreement?.valid_until_before}
                />
              </div>
              <div className="w-100">
                <label htmlFor="valid_until" className={ownStyles.labelFont}>Nueva fecha*</label>
                <input type="date" name="valid_until" className={`fw-bold text-primary w-100 ${ownStyles.selectInput}`} onChange={this.onChangeForm}/>                
              </div>
              <div className="w-100">
                <label htmlFor="justification_renew" className={ownStyles.labelFont}>Justificación*</label>
                <textarea name="justification_renew" cols="30" rows="10" className={`w-100 ${ownStyles.justificationRenew}`} onChange={this.onChangeForm}></textarea>
              </div>
              
          </div>
        </ModalNew>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
  agreementReducer: state.agreementReducer,
  currentAccount: state.loginReducer.currentAccount,
  loading: state.agreementReducer.loading,
  row_total: state.agreementReducer.row_total,
  loginReducer: state.loginReducer,  
  //   clients: state.auctionReducer.clients,
  //   auctionArticles: state.auctionReducer.auctionArticles,
  //   totalArticles: state.auctionReducer.totalArticles,
});

const mapDispatchToProps = (dispatch) => ({
  getAgreements: (filters) => {
    dispatch(getAgreements(filters));
  },
  getAgreementsProviders: (filters) => {
    dispatch(getAgreementsProviders(filters));
  },
  changeStatusAgreement: (data, doAfter) => {
    dispatch(changeStatusAgreement(data, doAfter));
  },
  getOneAgreement: (data, doAfter) => {
    dispatch(getOneAgreement(data, doAfter));
  },
  updateAgreement: (data) => {
    dispatch(updateAgreement(data))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(AuthAgreement);
