import React, { Component } from "react";
import { connect } from "react-redux";
import popupStyles from "../Layouts/popupEdEdit.module.css";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";

class TableServices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <h1>Services works</h1>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TableServices);
