import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useGetMethod } from "../../Hooks/useFetch";
import styles from "../../components/Layouts/tableStyle.module.scss";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import arrow from "../../assets/img/icons/arrowTable.svg";
import yellowArrow from "../../assets/img/icons/yellowRightArrow.svg";
import { appCustomSelect } from "../../components/Layouts/react-select-custom";
import OrdTable from "../../OrderingModule/OrderingComponents/OrdTable";
import { OrdBiTable } from "../../OrderingModule/OrderingComponents/OrdBiTable";
import { formatToRcSelect, loader } from "../../helpers/helpers";
import FullScreenDialog from "../../OrderingModule/OrderingComponents/FullScreenDialog";
import { RenderMedicalFormulasPdf } from "../RenderMedicalFormulasPdf";

export const DatingHistoryTab = () => {
  const location = useLocation();
  const idPatient = location.state.idPatient;
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;

  const [filter, setFilter] = useState({
    idPatient: idPatient,
    page: 1,
    perpage: 10,
    statusValue: "",
    search: "",
  });

  const [trigger, setTrigger] = useState(1);

  const [services, setServices] = useState({
    currentItem: {},
    header: [
      {
        title: "Fecha",
        className: "col-2 text-center",
      },
      {
        title: "Servicio",
        className: "col-3 text-start",
      },
      {
        title: "Estado",
        className: "col-2 text-center",
      },
      {
        title: <>&nbsp;</>,
        className: "col-1 text-end px-1",
      },
    ],
  });

  //console.log("services: ", services);
  const {
    results: appointmentHistoryPatient,
    load: loadAppointmentHistoryPatient,
    trigger: getAppointmentHistoryPatient,
  } = useGetMethod();

  const {
    results: stateAppointment,
    load: loadStateAppointment,
    trigger: getStateAppointment,
  } = useGetMethod();

  useEffect(() => {
    getAppointmentHistoryPatient({
      url: `/medical/appointment/`,
      token: token,
      objFilters: filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    getStateAppointment({
      url: `/medical/status/appointment/`,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let bgYellow =
          services.currentItem?.id === item.id &&
          styles.app_bg_table_appointment;

        let arrowIcon =
          services.currentItem?.id === item.id ? yellowArrow : arrow;
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className={`${bgYellow}  text-center`}>{item.dateApp}</td>
            <td className={`${bgYellow} text-start px-2`}>
              {item.serviceName}
            </td>
            <td
              className={`${bgYellow}  text-center`}
              style={{ color: `${item.fontColorStatus}` }}
            >
              <div
                style={{
                  background: `${item.bgColorStatus}`,
                  borderRadius: "10px",
                  padding: "5px",
                  maxWidth: "200px",
                }}
              >
                {item.status}
              </div>
            </td>
            <td className={`${bgYellow} text-center px-2`}>
              <img
                className={"cursorPointer"}
                alt={"arrow"}
                src={arrowIcon}
                width={10}
                onClick={() => {
                  setServices({ ...services, currentItem: item, show: true });
                  setFullDialogScreen({ appId: item.id });
                }}
              />
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };

  const formattedMedicines = formatToRcSelect(
    stateAppointment.results,
    "id",
    "description",
    "value",
    false
  );

  const appointmentInfo = [
    {
      title: "Sede",
      subTitle: services?.currentItem?.siteName,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Médico",
      subTitle: services?.currentItem?.fullNameDoctor,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Servicio",
      subTitle: services?.currentItem?.serviceName,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Fecha",
      subTitle:
        services?.currentItem?.dateApp + " " + services?.currentItem?.timeApp,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "No. Historia clínica",
      subTitle: services?.currentItem?.clinicalHistoryID,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Diagnóstico",
      subTitle: services?.currentItem?.dxName,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
  ];

  const collectAppointmentInfo = [
    {
      title: "Efectivo",
      subTitle: "-",
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Total débito",
      subTitle: "-",
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Total",
      subTitle: "-",
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Asesor",
      subTitle: "-",
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Fecha",
      subTitle: "-",
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
  ];

  const insurenceAppointmentInfo = [
    {
      title: "Aseguradora",
      subTitle: services?.currentItem?.ccName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Contrato",
      subTitle: services?.currentItem?.contractName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Población",
      subTitle: services?.currentItem?.popName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Grupo de ingreso",
      subTitle: services?.currentItem?.crtName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: "Observaciones",
      subTitle: services?.currentItem?.appObservations,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
  ];




  // ------------------ NEW MODAL ----------------------

  const [fullDialogScreen, setFullDialogScreen] = useState({
    show: false,
    appId: "",
    ordTag: "",
  });






  return (
    <>
      {loadAppointmentHistoryPatient && loader}
      <FullScreenDialog
        onHide={() => setFullDialogScreen({ ...fullDialogScreen, show: false })}
        isOpen={fullDialogScreen.show}
      >
        <RenderMedicalFormulasPdf
          pdfParams={{
            url: "/medical/appointment/pdf/", //ESPERAR URL
            // appId: 238,
            // ordTag: fullDialogScreen.ordTag,
            objFilters: {
              appId: fullDialogScreen.appId
            }
          }}
          title={"Recordatorio de cita"}
          // miPres={{
          //   mipresAction: () => {
          //     message("success", "Mipres generado");
          //     setFullDialogScreen(false);
          //   },
          // }}
          backAction={() => {
            setFullDialogScreen({ ...fullDialogScreen, show: false });
          }}
          withPagination={true}
        />
      </FullScreenDialog>
      <Row style={{ marginLeft: "20px" }}>
        <Col xs={5}>
          <Row className={styles.app_container_options}>
            <Col xs={9}>
              <Form.Group>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setTrigger(trigger + 1);
                  }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Escribe aquí para buscar"
                    className={`${styles.app_background_image} ${styles.app_roundSearch} col-6 pl-2`}
                    onChange={(e) => {
                      setFilter({ ...filter, search: e.target.value });
                    }}
                  />
                </form>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Select noOptionsMessage={() => 'No hay datos'}
                options={formattedMedicines}
                className="text-secondary"
                placeholder={"Estado"}
                styles={appCustomSelect}
                onChange={(e) => {
                  setFilter({ ...filter, statusValue: e.extra });
                  setTrigger(trigger + 1);
                }}
              />
            </Col>
          </Row>
          <OrdTable
            hasChildren={true}
            headers={services.header}
            paginate={{
              activePage: filter.page,
              totalPages: appointmentHistoryPatient.rowTotal,
              perPage: filter.perpage,
              pageRangeDisplayed: 1,
              onChangePage: async (e) => {
                const result = await getAppointmentHistoryPatient({
                  url: `/medical/appointment/`,
                  token: token,
                  objFilters: { ...filter, page: e },
                });
                setFilter({ ...filter, page: e });
                formatData(result?.results);
              },
              showTextDetails: true,
            }}
          >
            {formatData(appointmentHistoryPatient?.results)}
          </OrdTable>
        </Col>
        {services.show === true ? (
          <Col xs={7} style={{ marginTop: "10px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className={styles.app_title_order}>
                  Identificador de la Cita No. {services.currentItem?.id}
                </span>
              </div>

              <div>
                {/* ANCHOR botón */}
                {((services.currentItem?.status === "Atención pendiente") || (services.currentItem?.status === "Pagada")) &&
                  <button className={`${styles.button_detail_patient}`}
                    onClick={() =>
                      setFullDialogScreen({
                        ...fullDialogScreen,
                        show: true,
                        // ordTag: item.ortTag
                      })}
                  >Recordatorio de cita</button>
                }

              </div>
            </div>

            <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
              <Col xs={6}>
                <div>
                  <span className={styles.app_container_biTable_title}>
                    Información de la cita
                  </span>
                  <OrdBiTable data={appointmentInfo} />
                </div>
              </Col>
              <Col xs={6}>
                <div>
                  <span className={styles.app_container_biTable_title}>
                    Aseguradora
                  </span>
                  <OrdBiTable data={insurenceAppointmentInfo} />
                </div>
              </Col>
            </Row>
            <div>
              <span className={styles.app_container_biTable_title}>
                Recaudo
              </span>
              <OrdBiTable data={collectAppointmentInfo} />
            </div>
          </Col>
        ) : null}
      </Row>
    </>
  );
};
