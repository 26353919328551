import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { API_VERSION, defaultFilters, URL_GATEWAY } from "../helpers/constants";
import { GET_NIIF_ACCOUNTS, TRANSFER_REGISTER } from "./actionTypes";
import { customSwaltAlert } from "../helpers";
const MySwal = withReactContent(Swal);
export const ConsignementToDate = (data, doAfter=null) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    
    customSwaltAlert({
        title: `¿Está seguro?`,
        text: "Verifique la información",
        icon: "warning",
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch({
                type: TRANSFER_REGISTER,
                loading: true,
            })
            fetch(
                `${URL_GATEWAY}${API_VERSION}/treasury/insert_consignment_to_date/`,
                {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",

                        token: token,
                    },
                }
            )
                .then((response) => response.json())
                .then((res) => {
                    dispatch({
                        type: TRANSFER_REGISTER,
                        loading: false,
                    })
                    if (res.success) {
                        customSwaltAlert({
                            icon: "success",
                            title: res.message,
                            text: res.results,
                            showCancelButton:false
                        });
                        if(doAfter){
                            doAfter()
                        }

                    } else {
                        customSwaltAlert({
                            icon: "warning",
                            title: "Intenta de nuevo",
                            text: res.message,
                            showCancelButton:false
                        });
                    }
                })
                .catch((res) => {
                    dispatch({
                        type: TRANSFER_REGISTER,
                        loading: false,
                    })
                    customSwaltAlert({
                            icon: "warning",
                            title: "Intenta de nuevo",
                            text: res.message,
                            showCancelButton:false
                        });
                });
        }
    });
};