import React, { useEffect, useState } from 'react';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import trash from "../assets/img/icons/outLineGrayTrash.svg";
import tableStyles from "../../src/components/Layouts/tableStyle.module.scss";
import { TlnAccordion } from '../TelemedicineModule/TelemedicineComponents/TlnAccordion';
import Pen from "../assets/img/icons/penFullGray.svg";
import Swal from 'sweetalert2'
import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSymptom, getSympton, updateSymptom } from '../actions/telemedicineAction';
import Loader from 'react-loader-spinner';


function Symptom(props) {
    const store = useSelector(state => state);
    const ListSympton = store.telemedicineReducer.listSympton
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState({ action: false, type: "" });
    const [filters, setFilters] = useState({});
    const [data, setData] = useState({});
    const [trigger, setTrigger] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        dispatch(getSympton(filters, () => setLoading(false)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);
    const renderList = () => {
        let accordion = [];
        if (Array.isArray(ListSympton)) {
            accordion = ListSympton.map((x, index) => {
                return (
                    <TlnAccordion
                        classAccordion='mb-4'
                        keyToogle={"2"}
                        keyCollapse={"1"}
                        titleVal={x.sym_name}
                        buttons={[{
                            img: Pen,
                            action: () => { setShowModal({ action: true, type: "edit" }); setData({ name: x.sym_name, id: x.sym_id }) }
                        },
                        {
                            img: trash,
                            action: () => { valiDelete({ id: x.sym_id, status: 0 }, x.sym_name) }
                        }]}
                    >
                    </TlnAccordion>
                );
            })
        }
        return accordion;
    }
    const editSymptom = () => {
        setLoading(true);
        dispatch(updateSymptom(data, () => {
            setLoading(false);
            setTrigger(trigger + 1);
            setShowModal({ action: false, type: "" });
            setData({});
        }))
    }
    const posSymptom = () => {
        setLoading(true);
        dispatch(createSymptom(data, () => {
            setLoading(false);
            setTrigger(trigger + 1);
            setShowModal({ action: false, type: "" });
            setData({});
        }))
    }
    const valiDelete = (params, name) => {
        Swal.fire({
            title: `<span style=color:#00B4CC>¿Está seguro?</span>`,
            text: `Se eliminará el síntoma: ${name}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#003f80",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Continuar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                dispatch(updateSymptom(params, () => { setLoading(false); setTrigger(trigger + 1) }))
            }
        })
    }
    let buttons = [
        {
            btnTitle: "Nuevo síntoma",
            btnVariant: "gray",
            btnSize: 4,
            btnIcon: <div className={tableStyles.tlnBtnNew}></div>,
            action: () => setShowModal({ action: true, type: "create" }),
            btnTitleStyle: {
                fontSize: "14px", fontWeight: "700", textAlign: "end", display: "flex", justifyContent: "end", alignItems:'center'
            }
        },
    ]
    let asterisk = <b style={{ color: "#FF8B00" }}>*</b>;

    return (
        <div className={`${tableStyles.tlnContainer} mt-4`}>
            {(loading) &&
                <div className="loading">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            }
            <OrdGenericTemplate
                buttons={buttons}
                className="w-100"
                classNameRow={'mb-4'}
                rowStyle={{marginBottom:'1rem'}}
                showBottomBorder={false}
                title={"Síntomas"}
                searchWithNew={{
                    onChange: (e) => {
                        setFilters({ ...filters, name: e.target.value })
                    },
                    colSize: "12",
                    onSubmit: (e) => {
                        e.preventDefault();
                        setLoading(true);
                        setTrigger(trigger + 1);
                    },
                }}
            >
                <div className="w-100 mx-1 mb-5">
                    {renderList()}

                </div>
            </OrdGenericTemplate>
            <OrdModal
                title={showModal.type === "edit" ? "Editar síntoma" : "Nuevo síntoma"}
                show={showModal.action}
                btnYesName="Guardar"
                btnNoName="Cancelar"
                btnYesDisabled={!!data.name ? false : true}
                btnYesEvent={() => showModal.type === "edit" ? editSymptom() : posSymptom()}
                onHide={() => {
                    setShowModal({ action: false, type: "" }); setData({})
                }}
                btnNoEvent={() => {
                    setShowModal({ action: false, type: "" }); setData({})
                }}
            >
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3 text-start" controlId="fName">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                <b className="px-1">Nombre{asterisk}</b>
                            </Form.Label>
                            <Form.Control
                                className={`ord-roundInput`}
                                type="text"
                                placeholder="Escribe…"
                                value={data.name}
                                onChange={(e) => {
                                    setData({ ...data, name: e.target.value })
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </OrdModal>
        </div>
    )
}
export default Symptom;
