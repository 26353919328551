/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { OrdGenericTemplate } from "../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import styles from "../../components/Layouts/tableStyle.module.scss";
import { today, loader, convertMoneyFormatNew, isEmptyOrUndefined } from "../../helpers/helpers";
import ordBlueSearch from "../../assets/img/icons/ordBlueSearch.svg";
import reemplazar from "../../assets/img/icons/reemplazar.svg";
import OrdTable from "../../OrderingModule/OrderingComponents/OrdTable";
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import CustomPopupStyles from "../../components/Popup/customPopup.module.scss";
import threeDots from "../../assets/img/icons/threeDots.svg";
import paid from "../../assets/img/icons/app_aprovado.svg";
import notNecessary from "../../assets/img/icons/minusGray.svg";
import pending from "../../assets/img/icons/alert-circle-orange.svg";
import orderIcon from "../../assets/img/icons/orderIcon.svg";
import closeBlue from "../../assets/img/icons/close-blue.svg";
import OrdModal from "../../OrderingModule/OrderingComponents/OrdModal";
import { useHistory } from "react-router";
import { ordCustomSelect } from "./../../components/Layouts/react-select-custom";
import Select from "react-select";
import moment from 'moment';

// request
import { useGetMethod, usePostMethod } from "./../../Hooks/useFetch";

// components
import { ServiceOrderReplace } from "./";
import { Box } from "@mui/material";
import {
  RenderRow,
} from "./components/";
import { useFormatData } from "../../Hooks";
import { customSwaltAlertAsistencial } from "./../../helpers/customSwaltAlertAsistencial";
import NumberFormat from "react-number-format";
import { convertMoneyFormat } from './../../helpers/helpers';

const ServicesOrders = () => {
  // states
  const history = useHistory();
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const isCoordAdmin = (store?.loginReducer?.currentAccount?.profile?.prefijo === 'COORADMISIONIST')
  const site = store.userReducer.site

  const [dataPostCancellations, setDataPostCancellations] = useState({
    userId: idUser,
    appStatus: "pending_cancelled",
    appId: undefined,
    cancelId: undefined,
    cancelObservation: undefined
  })

  const [dataPostReplace, setDataPostReplace] = useState({
    userId: idUser,
    appStatus: "replaced",
    replacementData: {
      approveReplacement: 1,
      currentAppId: undefined,
      newAppId: undefined,
    }
  })

  const [listPatientFilter, setListPatientFilter] = useState({
    eaccount: idAccount,
    date: undefined,
    admitted: 1,
    site,
    page: 1,
    perpage: 10,
    createdBy: idUser,
    cluId: undefined
  })

  const [triggerListPatientFilter, setTriggerListPatientFilter] = useState(0)

  const [data, setData] = useState({
    trigger: 0,
    filters: {
      status: '',
      idUser,
      idAccount,
      isCoordinator: (isCoordAdmin === true) ? 1 : 0,
      isAdmisionist: (isCoordAdmin === false) ? 1 : 0,
      dateMax: "",
      dateMin: "",
      search: '',
      page: 1,
      perpage: 10,
    },
    amount: '',
    company: {
      id: '',
      name: '',
      nit: ''
    },
    contract: {
      id: '',
      name: '',
      sequence: ''
    },
    date: '',
    donation: '',
    hour: '',
    id: '',
    patient: {
      docType: '',
      document: '',
      id: '',
      name: ''
    },
    pendingAmountStatus: '',
    pending_amount: '',
    service: {
      description: '',
      id: '',
      name: ''
    },
    status: {
      background: '',
      fontColor: '',
      id: '',
      name: ''
    },
    modalAmount: {
      donation: 0,
      amountToDeliver: 0,
    }
  });

  const {
    filters,
    id,
    patient,
    pending_amount,
    trigger,
    modalAmount
  } = data;

  const [citeSelected, setCiteSelected] = useState();
  const [modalOnCancellation, setModalOnCancellation] = useState(false);

  // state modal replace
  const [modalOrderReplace, setModalOrderReplace] = useState(false);
  const [replaceSection, setReplaceSection] = useState(1);

  //request

  const {
    load: loadListServiceOrder,
    results: listServiceOrder,
    trigger: getListServiceOrder,
  } = useGetMethod();

  const {
    results: listCancellationReason,
    load: loaderListCancellationReason,
    trigger: getListCancellationReason
  } = useGetMethod();

  const {
    results: listPatientAdmission,
    load: loaderListPatientAdmission,
    trigger: getListPatientAdmission
  } = useGetMethod();

  const {
    load: loadPostModalAmount,
    trigger: postModalAmount,
  } = usePostMethod();

  const {
    load: loaderPostReplace,
    trigger: postReplace,
  } = usePostMethod();

  const {
    load: loaderPostCancellations,
    trigger: postCancellations,
  } = usePostMethod();


  //change state modal replace
  const onChangeStateModalOrderReplace = (state) => setModalOrderReplace(state);
  const onChangeTrigger = () => setData(state => ({ ...state, trigger: state.trigger + 1 }));

  const clearState = () => {
    setReplaceSection(1);
    setCiteSelected();
    setModalOrderReplace(false);

    setListPatientFilter(state => ({
      ...state,
      page: 1
    }))

  };

  const onClearState = () => {

    setListPatientFilter(state => ({
      ...state,
      page: 1
    }))

    setData(state => ({
      ...state,
      trigger: state.trigger,

      filters: {
        status: '',
        idUser: idUser,
        idAccount: idAccount,
        isCoordinator: (isCoordAdmin === true) ? 1 : 0,
        isAdmisionist: (isCoordAdmin === false) ? 1 : 0,
        dateMax: "",
        dateMin: "",
        search: '',
        page: 1,
        perpage: 10,
      },
      amount: '',
      company: {
        id: '',
        name: '',
        nit: ''
      },
      contract: {
        id: '',
        name: '',
        sequence: ''
      },
      date: '',
      donation: '',
      hour: '',
      id: '',
      patient: {
        docType: '',
        document: '',
        id: '',
        name: ''
      },
      pendingAmountStatus: '',
      pending_amount: '',
      service: {
        description: '',
        id: '',
        name: ''
      },
      status: {
        background: '',
        fontColor: '',
        id: '',
        name: ''
      },
      modalAmount: {
        donation: 0,
        amountToDeliver: 0,
      }
    }))
  }

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      <div className=" ">
        <span>
          Servicios
        </span>
      </div>
    </Tooltip>
  );

  const renderTooltipVuelto = (item) => (
    <Tooltip>
      <div className="">
        <span>{item === 'paid' ? 'Pagado' : item === 'pending' ? 'Pendiente por entregar' : 'No aplica'}</span>
      </div>
    </Tooltip>
  );

  const checkChange = (e) => {
    if (modalChange.check === 0) {
      setModalChange({ ...modalChange, check: 1 });
    } else {
      setModalChange({ ...modalChange, check: 0 });
    }
  };


  const headers = [
    {
      title: "No. Orden",
      className: `px-2 text-center col-1`,
    },
    {
      title: "Fecha",
      className: `px-2 text-center col-2`,
    },
    {
      title: "Paciente",
      className: `px-2 text-start col-2`,
    },
    {
      title: "Empresa",
      className: `px-2 text-start col-1`,
    },
    {
      title: `Contrato`,
      className: `px-2 text-start`,
    },
    {
      title: `Servicio`,
      className: `px-2 text-start col-2`,
    },
    {
      title: `Admisionista`,
      className: `px-2 text-start col-1`,
    },
    {
      title: "Total",
      className: `px-2 text-end col-1`,
    },
    {
      title: "Vuelto",
      className: "px-2  text-center ",
    },
    {
      title: "Estado",
      className: `px-2 text-center col-3`,
    },
    {
      className: " text-center",
    },
  ];

  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        const status = ["pending_cancelled", "pending_replaced"]
        const showReplaceOrCanceled = status.includes(item?.status?.value)

        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>

            <td className={`text-center px-2 mx-2`}>
              {item?.id || '-'}
            </td>

            <td className={`text-center px-2 mx-2`}>
              <span>{item?.date || '-'}</span>
              <br />
              <span>{item?.hour || '-'}</span>
            </td>

            <td className={`text-start px-2 `}>
              <span>{item?.patient?.name || '-'}</span>
              <br />
              <span style={{ color: '#A2A3AB' }}>
                {`${item?.patient?.docType || '-'} ${item?.patient?.document || '-'}`}
              </span>
            </td>

            <td className={`text-start px-2`}>
              {item?.company?.name || '-'}
            </td>

            <td className={`text-start px-2`}>
              {item?.contract?.name || '-'}
            </td>

            <td className={`text-start px-2`}>
              {item?.service?.description || '-'}
            </td>

            <td className={`text-start px-2`}>
              {item?.admittedByName || '-'}
            </td>

            <td className={`text-end px-2`}>
              {convertMoneyFormatNew({ textNumber: item?.amount }) || '-'}
            </td>

            <td className={`text-center px-2`}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 250 }}
                overlay={renderTooltipVuelto(item?.pendingAmountStatus)}
              >
                <img
                  alt="pendingAmountStatus"
                  src={
                    item?.pendingAmountStatus === 'paid'
                      ? paid
                      : item?.pendingAmountStatus === 'pending'
                        ? pending : notNecessary
                  }
                  height="22px"
                  width="22px"
                />
              </OverlayTrigger>

            </td>

            <td className={`text-center px-2`}>
              <span
                style={{
                  color: `${item?.status?.fontColor}`,
                  backgroundColor: `${item?.status?.background}`,
                  borderRadius: "10px",
                  padding: "2px 10px",
                }}
              >
                {item?.status?.name || '-'}
              </span>
            </td>

            <td className={`text-start`}>
              <CustomPopupExtend
                position="right center"
                className={`align-self-end`}
                triggerClass={`${styles.hoverPopup} `}
                triggerSrc={threeDots}
                extraButtons={[
                  {
                    text: "Ver detalle",
                    class: CustomPopupStyles.popUpGeneric,
                    event: () => {
                      history.push({
                        pathname: `ordenesServicios/${item?.id}`,
                        state: { ...item }
                      });
                    },
                  },
                  {
                    text: "Dar vuelto",
                    class: CustomPopupStyles.popUpGeneric,
                    event: () => {
                      setModalChange({ ...modalChange, show: true });

                      setData(state => ({
                        ...state,
                        ...item,
                      }))
                    },
                  },
                  !showReplaceOrCanceled ?
                    {
                      text: "Anular",
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => {
                        setModalOnCancellation(true)
                        setData(state => ({
                          ...state,
                          ...item,
                        }))

                      },
                    } : undefined,
                  !showReplaceOrCanceled ?
                    {
                      text: "Reemplazar",
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => {

                        onChangeStateModalOrderReplace(true)
                        setData(state => ({
                          ...state,
                          ...item,
                        }))
                      }
                    } : undefined,
                ]}
              ></CustomPopupExtend>
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };

  //  ------------ SECTION MODAL CHANGE --------------------

  const [modalChange, setModalChange] = useState({
    show: false,
    check: 0,
  });

  const validateModalAmount = () => {

    const { amountToDeliver, donation } = modalAmount
    let result = true

    if (pending_amount <= 0) {
      result = false
      customSwaltAlertAsistencial({
        icon: "warning",
        title: "Intenta de nuevo",
        text: `Paciente: ${patient.name}, no tiene vuelto pendiente por entregar`,
        showCancelButton: false,
      })

    }

    if (Number(amountToDeliver) > pending_amount) {
      result = false
      customSwaltAlertAsistencial({
        icon: "warning",
        title: "Intenta de nuevo",
        text: `El valor del monto a entregar no puede superar al valor del vuelto pendiente`,
        showCancelButton: false,
      })
    }

    if (modalChange?.check) {
      const calculoOfDonation = Math?.abs(Number(amountToDeliver) - Number(pending_amount))

      if ((Number(amountToDeliver) - Number(pending_amount) > 0) && Number(donation) <= 0) {
        result = false
        customSwaltAlertAsistencial({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `El valor a donar debe ser mayor a: $0`,
          showCancelButton: false,
        })
      }

      if (Number(donation) > calculoOfDonation) {
        result = false
        customSwaltAlertAsistencial({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `El valor a donar no puede ser mayor a: ${convertMoneyFormat(calculoOfDonation)}`,
          showCancelButton: false,
        })
      }
    }

    return result
  }

  const submitChange = () => {

    if (validateModalAmount()) {

      handlePostModalAmount()

    }
  };

  const printDataInsuranceCancellation = () => {
    return (
      <>
        <RenderRow title={"Aseguradora"} subTitle={data?.company?.name} />
        <RenderRow title={"Contrato"} subTitle={data?.contract?.name} />
        <RenderRow title={"Grupo de ingreso"} subTitle={data?.service?.incomeGroup} />
        <RenderRow title={"Origen"} subTitle={data?.service?.population} />
      </>
    );
  };

  const printDataCiteCancellation = () => {
    return (
      <>
        <RenderRow title={"Sede"} subTitle={data?.service?.siteName} />
        <RenderRow title={"Médico"} subTitle={data?.service?.doctor} />
        <RenderRow
          title={"Servicio"}
          subTitle={data?.service?.name}
        />
        <RenderRow title={"Fecha"} subTitle={data?.date} />
        <RenderRow title={"Hora"} subTitle={data?.hour} />
        <RenderRow title={"No. de historia clínica"} subTitle={data?.service?.medHistory || 'N/A'} />
        <RenderRow
          title={"Diagnóstico"}
          subTitleList={data?.service?.diagnostics}
          subTitle={data?.service?.diagnostics?.length ? '' : '-'}
        />
        <RenderRow title={"Total"} subTitle={convertMoneyFormatNew({ textNumber: data?.amount }) || '-'} />
      </>
    );
  };

  // TABLE
  const tableHeader = [
    {
      title: "",
      className: "px-2",
    },
    {
      title: "Hora",
      className: "px-2 text-center",
    },
    {
      title: "Servicio",
      className: "text-start px-2 col-4",
    },
    {
      title: "Total",
      className: " text-end px-2",
    },
  ];

  const tableBodyStructure = (cite) => [
    {
      text: (
        <div
          className={`${styles.formCheckInputRadioAsistencial} ${styles.radioClass}`}
        >
          <input
            style={{ width: "16px", height: "16px" }}
            type="radio"
            name="citeReplace"
            className={`pointer`}
            onChange={() => {
              setCiteSelected(cite?.id);
            }}
          />
        </div>
      ),
      className: `px-2 ${cite?.id === citeSelected ? styles.bgTabOrderReplaceRowSelected : ""
        }`,
    },
    {
      text: <>{moment(cite?.hour, "h:mm").format('h:mm a') || '-'}</>,
      className: `${styles.f12} text-center px-2 ${cite?.id === citeSelected ? styles.bgTabOrderReplaceRowSelected : ""
        }`,
    },
    {
      text: <>{cite?.service?.name || '-'}</>,
      className: `${styles.f12} text-start px-2 col-6 ${cite?.id === citeSelected ? styles.bgTabOrderReplaceRowSelected : ""
        }`,
    },
    {
      text: <>{convertMoneyFormatNew({ textNumber: cite?.service?.amount }) || '-'}</>,
      className: `${styles.f12} text-end px-2 ${cite?.id === citeSelected ? styles.bgTabOrderReplaceRowSelected : ""
        }`,
    },
  ];

  const { formatData: formatDataReplace } = useFormatData({
    tableBodyStructure,
    listTableBody: listPatientAdmission?.results?.filter(el => el?.id !== data?.id) || [],
    changeRender: [citeSelected, listPatientAdmission?.results],
  });

  const handlePostModalAmount = () => {

    postModalAmount({
      url: "/medical/admissions/giveChange/",
      token: token,
      method: "POST",
      body: {
        ...modalAmount,
        appId: id,
        depoTypeTag: 'vuelto',
        amount: 0,
        eaccount: idAccount,
        patientId: data?.patient?.id,
        userId: idUser
      },
      succesAction: (data) => {

        customSwaltAlertAsistencial({
          icon: "success",
          title: "Pagado exitosamente",
          text: data?.message,
          showCancelButton: false,
        })

        onChangeTrigger()
        onClearState()
        setModalChange({
          show: false,
          check: 0,
        })

        return true
      },
      doAfterException: (error) => {
        customSwaltAlertAsistencial({
          icon: "warning",
          title: "Intenta de nuevo",
          text: 'Ha ocurrido un error al intentar registrar el vuelto en el sistema',
          showCancelButton: false,
        })

      }
    })
  }

  const handleCancellations = ({ id, patientId }) => {

    postCancellations({
      url: "/medical/admissions/changeServiceOrderStatus/",
      token: token,
      method: "POST",
      body: {
        cancelId: dataPostCancellations?.cancelId,
        cancelObservation: dataPostCancellations?.cancelObservation,
        appStatus: "cancelled",
        patientId,
        userId: idUser,
        cancelationData: {
          appId: id,
          approveCancelation: 1
        },
      },
      succesAction: (res) => {

        customSwaltAlertAsistencial({
          icon: "success",
          title: "Actualizado exitosamente",
          text: `Se ha anulado la orden ${data?.id}`,
          showCancelButton: false,
        })
        setModalOnCancellation(false)
        onChangeTrigger()
        onClearState()
        setDataPostCancellations({
          userId: idUser,
          appStatus: "pending_cancelled",
          appId: undefined,
          cancelId: undefined,
          cancelObservation: undefined
        })

      },
      doAfterException: (error) => {
        customSwaltAlertAsistencial({
          icon: "warning",
          title: "Intenta de nuevo",
          text: error?.message,
          showCancelButton: false,
        })

      }
    })
  }

  const handleCancellationsWitchAuthorization = () => {

    customSwaltAlertAsistencial({
      icon: "warning",
      title: "¿Está seguro?",
      text: `Se anulará la orden ${data?.id}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {

        if (data?.needsAuthorization === 0) {
          handleCancellations({ id: data?.id, patientId: data?.patient?.id })
        } else {
          postCancellations({
            url: "/medical/admissions/changeServiceOrderStatus/",
            token: token,
            method: "PUT",
            body: { ...dataPostCancellations, appId: data?.id, patientId: data?.patient?.id, userId: idUser },
            succesAction: (res) => {

              customSwaltAlertAsistencial({
                icon: "success",
                title: "Actualizado exitosamente",
                text: `Se ha anulado la orden ${data?.id}`,
                showCancelButton: false,
              }).finally(() => {
                setModalOnCancellation(false)
                onChangeTrigger()
                onClearState()
                setDataPostCancellations({
                  userId: idUser,
                  appStatus: "pending_cancelled",
                  appId: undefined,
                  cancelId: undefined,
                  cancelObservation: undefined
                })
              })
            },
            doAfterException: (error) => {
              customSwaltAlertAsistencial({
                icon: "warning",
                title: "Intenta de nuevo",
                text: error?.message,
                showCancelButton: false,
              })

            }
          })
        }
      }
    })
  }

  const handleReplace = () => {

    postReplace({
      url: "/medical/admissions/changeServiceOrderStatus/",
      token: token,
      method: "POST",
      body: {
        cancelId: dataPostCancellations?.cancelId,
        cancelObservation: dataPostCancellations?.cancelObservation,
        patientId: data?.patient?.id,
        userId: idUser,
        appStatus: "replaced",
        replacementData: {
          approveReplacement: 1,
          currentAppId: data?.id,
          newAppId: citeSelected,
        }
      },

      succesAction: (res) => {

        onChangeStateModalOrderReplace(false);
        clearState();
        setModalOnCancellation(false)
        onChangeTrigger()
        onClearState()
        setDataPostReplace({
          userId: idUser,
          appStatus: "replaced",
        })

        customSwaltAlertAsistencial({
          icon: "success",
          title: "Actualizado exitosamente",
          text: `Se ha anulado la orden ${data?.id} del paciente: ${data?.patient?.name}`,
          showCancelButton: false,
        })


      },
      doAfterException: (error) => {
        customSwaltAlertAsistencial({
          icon: "warning",
          title: "Intenta de nuevo",
          text: error?.message,
          showCancelButton: false,
        })
      }
    })
  }

  const handleReplaceWitchAuthorization = () => {

    customSwaltAlertAsistencial({
      icon: "warning",
      title: "¿Está seguro?",
      text: `Se remplazará la cita ${data?.id} asignada al paciente: ${data?.patient?.name}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {

        if (data?.needsAuthorization === 0) {
          handleReplace()
        } else {
          postReplace({
            url: "/medical/admissions/changeServiceOrderStatus/",
            token: token,
            method: "PUT",
            body: {
              ...dataPostCancellations,
              appId: data?.id,
              appStatus: 'pending_replaced',
              patientId: data?.patient?.id,
              userId: idUser,
            },

            succesAction: (res) => {

              onChangeStateModalOrderReplace(false);
              clearState();
              setModalOnCancellation(false)
              onChangeTrigger()
              onClearState()
              setDataPostReplace({
                userId: idUser,
                appStatus: "replaced",
              })

              customSwaltAlertAsistencial({
                icon: "success",
                title: "Actualizado exitosamente",
                text: `Se ha anulado la orden ${data?.id} del paciente: ${data?.patient?.name}`,
                showCancelButton: false,
              })


            },
            doAfterException: (error) => {
              customSwaltAlertAsistencial({
                icon: "warning",
                title: "Intenta de nuevo",
                text: error?.message,
                showCancelButton: false,
              })

            }
          })
        }
      }
    })
  }

  useEffect(() => {
    getListServiceOrder({
      objFilters: { ...filters },
      token: token,
      url: "/medical/admissions/serviceOrder/",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (modalOrderReplace && !isEmptyOrUndefined(data?.patient?.id)) {
      getListPatientAdmission({
        url: "/medical/patientAdmission/",
        objFilters: { ...listPatientFilter, cluId: data?.patient?.id, date: moment(data?.date, 'DD-MM-YYYY').format('YYYY-MM-DD') },
        token: token,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOrderReplace, triggerListPatientFilter]);

  useEffect(() => {
    if (modalAmount?.amountToDeliver <= 0) {
      setData(state => ({
        ...state,
        modalAmount: {
          ...state.modalAmount,
          donation: 0
        }
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalAmount?.amountToDeliver]);

  useEffect(() => {
    setData(state => ({
      ...state,
      modalAmount: {
        ...state.modalAmount,
        donation: 0
      }
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalChange?.check]);


  useEffect(() => {
    getListCancellationReason({
      url: "/medical/generals/cancellationReason/",
      token: token
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //  ------------- !SECTION MODAL CHANGE --------------------
  return (
    <>
      {(
        loadListServiceOrder ||
        loadPostModalAmount ||
        loaderPostCancellations ||
        loaderPostReplace ||
        loaderListPatientAdmission ||
        loaderListCancellationReason
      ) && loader}

      <OrdModal
        size={400}
        styleBody='p-2'
        title={"Entrega de vuelto"}
        show={modalChange.show}
        btnYesName={"Aceptar"}
        btnYesDisabled={
          Number(pending_amount) <= 0 |
          Number(modalAmount?.amountToDeliver) <= 0
        }
        onHide={() => {
          onClearState()
          setModalChange({
            show: false,
            check: 0,
          })
        }}
        btnYesEvent={() => submitChange()}
        btnNoEvent={() => {
          onClearState()
          setModalChange({
            show: false,
            check: 0,
          })
        }}
      >
        <Row className="d-flex">
          <div className="d-flex">
            <Col xs={6}>
              <Form.Label
                className={`m-0 ${styles.darkerBlueText}  ${styles.f16}`}
              >
                <b>&nbsp;Vuelto pendiente</b>
              </Form.Label>
            </Col>
            <Col xs={6}>
              <Form.Group className="mb-3 text-start" controlId="pending">

                <NumberFormat
                  disabled
                  allowNegative={false}
                  className={"ordInputAdmission w-100"}
                  decimalSeparator={"."}
                  placeholder="Escribir..."
                  isNumericString={true}
                  maxLength={12}
                  prefix={"$"}
                  thousandSeparator={","}
                  thousandsGroupStyle="thousand"
                  value={Number(pending_amount)}
                />

              </Form.Group>
            </Col>
          </div>

          <div className="d-flex my-2">

            <Col xs={6}>
              <Form.Label
                className={`m-0 ${styles.darkerBlueText}  ${styles.f16}`}
              >
                <b>&nbsp;Monto a entregar</b>
              </Form.Label>
            </Col>

            <Col xs={6}>

              <NumberFormat
                disabled={pending_amount <= 0}
                allowNegative={false}
                className={"ordInputAdmission w-100"}
                decimalSeparator={"."}
                placeholder="Escribir..."
                isNumericString={true}
                maxLength={String(pending_amount)?.length + 2}
                prefix={"$"}
                thousandSeparator={","}
                thousandsGroupStyle="thousand"
                value={modalAmount?.amountToDeliver || ''}
                onValueChange={({ value, floatValue, formattedValue }) => {
                  setData(state => ({
                    ...state,
                    modalAmount: {
                      ...state?.modalAmount,
                      amountToDeliver: value
                    }
                  }))
                }}
              />

            </Col>
          </div>

          {
            modalAmount?.amountToDeliver > 0 && (
              <div className="d-flex my-2">
                <Col xs={6} className="align-self-center">
                  <Form.Group className=" text-start" controlId="span">
                    <input
                      className={`border border-dark-blue form-check-input p1 check-dark-blue ${styles.cursorPointer} mx-2`}
                      type="checkbox"
                      name="edit"
                      value="selectedAll"
                      checked={modalChange.check === 1 ? true : false}
                      onClick={() => checkChange()}
                    />
                    <Form.Label
                      className={`m-0 ${styles.darkerBlueText}  ${styles.f16}`}
                    >
                      <b> &nbsp;¿Donar vuelto?</b>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={6} className="d-flex">
                  <Form.Group className="mb-3 text-start" controlId="number">

                    <NumberFormat
                      disabled={modalChange.check === 1 ? false : true}
                      allowNegative={false}
                      className={"ordInputAdmission w-100"}
                      decimalSeparator={"."}
                      placeholder="Escribir..."
                      isNumericString={true}
                      maxLength={12}
                      prefix={"$"}
                      thousandSeparator={","}
                      thousandsGroupStyle="thousand"
                      value={modalAmount?.donation || ''}
                      onValueChange={({ value, floatValue, formattedValue }) => {
                        setData(state => ({
                          ...state,
                          modalAmount: {
                            ...state?.modalAmount,
                            donation: value
                          }
                        }))
                      }}
                    />

                  </Form.Group>
                </Col>
              </div>
            )
          }



        </Row>
      </OrdModal>

      {/* action replace service order */}
      <ServiceOrderReplace
        // hideGridInfo={replaceSection === 3}
        // customClassChildren={styles.replaceChildrenScroll}
        data={[]}
        title={`${data?.needsAuthorization === 0 ? 'Reemplazo de orden de servicio' : 'Solicitud reemplazo de orden de servicio'}`}
        subTitle={`${data?.patient?.docType} ${convertMoneyFormatNew({ textNumber: data?.patient?.document, SymbolShow: true })} - ${data?.patient?.name}`}
        show={modalOrderReplace}
        size={940}
        onHide={() => {
          onChangeStateModalOrderReplace(false);
          clearState();
        }}
        btnNoEvent={() => { }}
        dataInsurance={{
          data: printDataInsuranceCancellation,
          title: "Cita original",
          subTitle: "Datos de aseguradora",
        }}
        dataCite={{
          data: printDataCiteCancellation,
          subTitle: "Datos de la cita",
        }}
      >
        {replaceSection === 1 && (
          <Box>
            <Box mb={3} className={`${styles.ordAquaMarineText} fw-bold`}>
              Cita de reemplazo
            </Box>

            <OrdTable
              hoverYellow
              lastBorderNone
              body={formatDataReplace}
              headers={tableHeader}
              className="mt-0  w-100"
              paginate={{
                activePage: listPatientFilter?.page,
                totalPages: listPatientAdmission?.results?.filter(el => el?.id === data?.id)?.length
                  ? (listPatientAdmission?.rowTotal - 1) : listPatientAdmission?.rowTotal,
                perPage: listPatientFilter?.perpage,
                pageRangeDisplayed: 3,
                onChangePage: async (e) => {
                  setListPatientFilter((state) => ({
                    ...state,
                    page: e,
                  }));
                  setTriggerListPatientFilter(state => state + 1)
                },
                showTextDetails: true,
              }}
            />

            <Box mb={3} display={"grid"} gridTemplateColumns={"1fr 3fr"} columnGap={2}>
              <button
                className={`${styles.btnReplaceCancel}`}
                onClick={() => {
                  onChangeStateModalOrderReplace(false);
                  clearState();
                }}
              >
                Cancelar
              </button>

              <button
                disabled={citeSelected === undefined}
                className={`${styles.btnReplace} ${citeSelected === undefined ? "" : styles.btnReplaceHover
                  }`}
                onClick={() => {
                  setReplaceSection(2)
                }}
              >
                Reemplazar
                <img className="px-2" src={reemplazar} alt="reemplazar" />
              </button>
            </Box>
          </Box>
        )}

        {replaceSection === 2 && (
          <Box>
            <Box>
              <Box>
                <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                  &nbsp;Motivo
                  <span className={`${styles.ordOrangeText}`}>*</span>
                </Box>

                <Select
                  noOptionsMessage={() => "No hay datos"}
                  options={listCancellationReason?.results?.map(el => ({ label: el?.canDescription, value: el?.canId }))}
                  className="text-secondary "
                  placeholder={"Seleccionar..."}
                  styles={ordCustomSelect}
                  onChange={({ value }) => {
                    setDataPostCancellations(state => ({
                      ...state,
                      cancelId: value
                    }))
                  }}
                />
              </Box>

              <Box my={2}>
                <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                  &nbsp;Observaciones
                  <span className={`${styles.ordOrangeText}`}>*</span>
                </Box>

                <textarea
                  placeholder="Escribir..."
                  style={{ minHeight: "265px", maxHeight: "265px" }}
                  className={`text-secondary ordInputAdmission w-100 p-2`}
                  onChange={({ target }) => {
                    setDataPostCancellations(state => ({
                      ...state,
                      cancelObservation: target?.value
                    }))
                  }}
                />
              </Box>
            </Box>

            <Box display={"grid"} gridTemplateColumns={"1fr 3fr"} columnGap={2}>
              <button
                className={`${styles.btnReplaceCancel}`}
                onClick={() => {
                  onChangeStateModalOrderReplace(false);
                  clearState();
                }}
              >
                Cancelar
              </button>

              <button
                disabled={citeSelected === undefined || !dataPostCancellations?.cancelObservation?.trim() || isEmptyOrUndefined(dataPostCancellations?.cancelId)}
                className={`${styles.btnReplace} ${citeSelected === undefined ? "" : styles.btnReplaceHover
                  }`}
                onClick={handleReplaceWitchAuthorization}
              >
                Reemplazar
                <img className="px-2" src={reemplazar} alt="reemplazar" />
              </button>
            </Box>
          </Box>
        )}

      </ServiceOrderReplace>
                  
      <ServiceOrderReplace
        title={!data?.needsAuthorization ? 'Anulación' : 'Solicitud de anulación'}
        subTitle={`${data?.patient?.docType} ${convertMoneyFormatNew({ textNumber: data?.patient?.document, SymbolShow: true, SymbolDecimal: '.' })} - ${data?.patient?.name}`}
        show={modalOnCancellation}
        size={940}
        onHide={() => {
          setModalOnCancellation(false)
          clearState();
          setDataPostCancellations({
            userId: idUser,
            appStatus: "pending_cancelled",
            appId: undefined,
            cancelId: undefined,
            cancelObservation: undefined
          })
        }}
        btnNoEvent={() => {
          setModalOnCancellation(false)
          clearState();
          setDataPostCancellations({
            userId: idUser,
            appStatus: "pending_cancelled",
            appId: undefined,
            cancelId: undefined,
            cancelObservation: undefined
          })
        }}
        dataInsurance={{
          data: printDataInsuranceCancellation,
          subTitle: "Datos de aseguradora",
        }}
        dataCite={{
          data: printDataCiteCancellation,
          subTitle: "Datos de la cita",
        }}
      >
        <Box>
          <Box>
            <Box>
              <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                &nbsp;Motivo
                <span className={`${styles.ordOrangeText}`}>*</span>
              </Box>

              <Select
                noOptionsMessage={() => "No hay datos"}
                options={listCancellationReason?.results?.map(el => ({ label: el?.canDescription, value: el?.canId }))}
                className="text-secondary "
                placeholder={"Seleccionar..."}
                styles={ordCustomSelect}
                onChange={({ value }) => {
                  setDataPostCancellations(state => ({
                    ...state,
                    cancelId: value
                  }))
                }}
              />
            </Box>

            <Box my={2}>
              <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                &nbsp;Observaciones
                <span className={`${styles.ordOrangeText}`}>*</span>
              </Box>

              <textarea
                placeholder="Escribir..."
                style={{ minHeight: "265px", maxHeight: "265px" }}
                className={`text-secondary ordInputAdmission w-100 p-2`}
                onChange={({ target }) => {
                  setDataPostCancellations(state => ({
                    ...state,
                    cancelObservation: target?.value
                  }))
                }}
              />
            </Box>
          </Box>

          <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} columnGap={3} mb={3}>
            <button
              className={`${styles.btnReplaceCancel}`}
              onClick={() => {
                setModalOnCancellation(false)
                clearState();
                setDataPostCancellations({
                  userId: idUser,
                  appStatus: "pending_cancelled",
                  appId: undefined,
                  cancelId: undefined,
                  cancelObservation: undefined
                })
              }}
            >
              Cancelar
            </button>

            <button
              disabled={!dataPostCancellations?.cancelObservation?.trim() || isEmptyOrUndefined(dataPostCancellations?.cancelId)}
              className={`${styles.btnReplace} ${styles.btnReplaceHover}`}
              onClick={handleCancellationsWitchAuthorization}
            >
              Anular
              <img className="px-2" height={12} src={closeBlue} alt="close-blue" />
            </button>
          </Box>
        </Box>
      </ServiceOrderReplace>

      <OrdGenericTemplate
        className="w-95 my-4"
        showBottomBorder={false}
        title={"Órdenes de servicios"}
        titleClass={"px-2"}
        titleSize={12}
        titleIcon={{
          icon: (
            <>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <img
                  alt="goal"
                  src={orderIcon}
                  height="30px"
                  width="30px"
                  className="align-self-center mx-2"
                />
              </OverlayTrigger>
            </>
          ),
        }}
      >
        <div className="mx-2 me-5 my-2 asistencialDateColorIcon">
          <Row className="d-flex px-1">

            <Col xs={2} className={`px-0 align-self-end`}>
              <Form.Group className="px-2" controlId="ordType">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                  <span>&nbsp;Fecha desde</span>
                </Form.Label>
                <input
                  className={`ord-roundInput2`}
                  style={filters.startDate ? { color: '#1a538d' } : {}}
                  type="date"
                  id="date-start"
                  value={filters.startDate}
                  onChange={(e) => {
                    setData((state) => ({
                      ...state,
                      filters: { ...state.filters, dateMin: e.target.value },
                    }));
                  }}
                  min={today()}
                />
              </Form.Group>
            </Col>

            <Col xs={2} className={`px-0 align-self-end`}>
              <Form.Group className=" px-2" controlId="ordType">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                  <span>&nbsp;Hasta</span>
                </Form.Label>
                <input
                  className={`ord-roundInput2`}
                  style={filters.endDate ? { color: '#1a538d' } : {}}
                  id="date-end"
                  type="date"
                  value={filters.endDate}
                  onChange={(e) => {
                    setData((state) => ({
                      ...state,
                      filters: { ...state.filters, dateMax: e.target.value },
                    }));
                  }}
                  min={filters.dateMin}
                />
              </Form.Group>
            </Col>

            <Col xs={4} className="align-self-end">
              <Form.Group className=" text-start" controlId="fName">
                <div
                  className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}
                >
                  <input
                    className={`ord-roundInput ${styles.appInputDate} w-100`}
                    type="text"
                    placeholder="Escribe aquí para buscar"
                    autoComplete="off"
                    onChange={(e) => {
                      setData((state) => ({
                        ...state,
                        filters: { ...state.filters, search: e.target.value },
                      }));
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onChangeTrigger()
                      }
                    }}
                  />
                  <img
                    src={ordBlueSearch}
                    className="pointer"
                    alt="buscar"
                    onClick={() => onChangeTrigger()}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          {/* SECTION TABLE */}
          <Row>
            <div>
              <OrdTable
                shadow
                headers={headers}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                paginate={{
                  activePage: filters.page,
                  totalPages: listServiceOrder?.rowTotal,
                  perPage: filters.perpage,
                  pageRangeDisplayed: 3,
                  showTextDetails: true,
                  onChangePage: async (e) => {
                    setData((state) => ({
                      ...state,
                      filters: { ...state?.filters, page: e },
                      trigger: trigger + 1
                    }));
                  },
                }}
              >
                {formatData(listServiceOrder?.results || [])}
              </OrdTable>
            </div>
          </Row>
          {/* !SECTION */}
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default ServicesOrders;