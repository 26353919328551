import GenericTableNew from "../Layouts/GenericTableNew";
import Lupa from "../../assets/img/icons/lupa.svg";
import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg";
import AlertTriangle from "../../assets/img/icons/AlertTriangle.svg";
import iconDownload from "../../assets/img/icons/iconDownload.svg";

import React, { useEffect, useState } from "react";

import {
  requisition_export_pdf,
  requisition_filter,
} from "../../actions/requisitionActions";

import { storage_get } from "../../actions/storageActions";

import { getAllConCenter } from "../../actions/consumptionCenterActions";

import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
// import popupStyles from "../Popup/customPopup.module.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import reqStyles from "./Requisitions.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import Select from "react-select";
import { Tooltip } from "@material-ui/core";
import { Button } from "react-bootstrap";
import ReqModalPending from "./ReqModalPending";
// import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import ReqModalConsolidate from "./ReqModalConsolidate";
import Loader from "react-loader-spinner";
import { message } from "../../helpers/helpers";

function ballthingyTooltipName(backendName) {
  switch (backendName) {
    case "pending":
      return "Pendiente";
    case "solved":
      return "Atendida";
    case "incomplete":
      return "Incompleto";
    case "enabled":
      return "Habilitado";
    case "disabled":
      return "Deshabilitado";
    case "rejected":
      return "Rechazado";
    case "reqAuth":
      return "Requiere Autorización";
    default:
      return "?";
  }
}

function statusBallToClass(name) {
  switch (name) {
    case "pending":
      return tableStyles.circleYellowAuto;
    case "solved":
      return tableStyles.circleGreenAuto;
    case "rejected":
      return tableStyles.circleRedAuto;
    default:
      return tableStyles.circleBlackAuto;
  }
}

function RequisitionsPending() {
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const myPermission =
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "Reqp"
    );

  const [trigger, setTrigger] = useState(1);
  const [loading, setLoading] = useState(false);

  const [freezeConsolidate, setfreezeConsolidate] = useState(false);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: counter.loginReducer.currentAccount.id,
    multistatus: "pending,solved",
    status: ""
  });

  const [reqState, setreqState] = useState({
    showModal: false,
  });

  const [modalCloneControl, setmodalCloneControl] = useState({
    show: false,
  });

  const [modalConsControl, setmodalConsControl] = useState({
    showModal: false,
  });

  useEffect(() => {
    //dispatch(requisition_articles_filter() );
    setLoading(true);
    dispatch(
      getAllConCenter({ eaccount: counter.loginReducer.currentAccount.id },()=> setLoading(false))
    );
    dispatch(
      storage_get({
        page: 1,
        perpage: 3000,
        id_account: counter.loginReducer.currentAccount.id,
      }, ()=> setLoading(false))
    );
  }, []);

    useEffect(() => {
        setfreezeConsolidate(false);
        setLoading(true);
        dispatch(requisition_filter(filters, ()=>setLoading(false)));
        setselectedList([]);
    },[trigger, filters.page, filters.cc_id, filters.status]);

  const hasNewArticle = (reqArticles) => {
    let hasNew = false;
    let i = 0;

    //using while instead of map so i can break the loop if i found a valid target
    if (Array.isArray(reqArticles)) {
      while (!hasNew && i < reqArticles.length) {
        let x = reqArticles[i];
        if (x.status === "pending") {
          hasNew = true;
        }
        i++;
      }
    }
    return hasNew;
  };

  const hasAllRejected = (reqArticles) => {
    let rejecteds = [];

    if (Array.isArray(reqArticles)) {
      rejecteds = reqArticles.filter((x) => x.status === "rejected");
      return rejecteds.length === reqArticles.length && rejecteds.length > 0;
    }
    return false;
  };

  //=============================================================================== TS-218
  const [selectedList, setselectedList] = useState([]);

  const isBoxSelected = (id_req) => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find((x) => x.id == id_req);
      return !!target;
    }
  };

  const changeBoxSelected = (item) => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find((x) => x.id == item.id);
      if (!!target) {
        tempSelected = tempSelected.filter((x) => x.id != item.id);
      } else {
        if (!hasNewArticle(item.article_list) && item.status == "pending") {
          let newChange = {
            ...item,
            // id_requisitions: item.id_requisitions,
            // id_article: item.id_article,
            // qty_article: item.qty_article,
            // nameformail: item.article?.description,
            // status: "rejected",
            // comment: "",
          };
          tempSelected.push(newChange);
        }
      }
      setselectedList(tempSelected);
    }
  };

  const isBoxAllSelected = () => {
    let res = false;
    if (
      Array.isArray(counter.requisitionReducer.requisitions) &&
      Array.isArray(selectedList)
    ) {
      // if(counter.requisitionReducer.requisitions.length == selectedList.length ){
      //     res = true;
      // }
      let counting = 0;
      counter.requisitionReducer.requisitions.map((x) => {
        if (!hasNewArticle(x?.article_list) && x.status == "pending") {
          counting++;
        }
      });

      if (selectedList.length == counting) {
        res = true;
      }
    }

    return res;
  };

  const isSameCCAllSelected = () => {
    let res = false;
    if (
      Array.isArray(counter.requisitionReducer.requisitions) &&
      Array.isArray(selectedList)
    ) {
      let selected_cc_id = 0;
      let counting = 0;
      if (selectedList.length >= 1) {
        selected_cc_id = selectedList[0].id_consumption_center;
      }
      selectedList.map((x) => {
        if (x?.id_consumption_center === selected_cc_id) {
          counting++;
        }
      });

      if (selectedList.length == counting) {
        res = true;
      }
    }

    return res;
  };

  const changeBoxAllSelected = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (
        Array.isArray(counter.requisitionReducer.requisitions) &&
        Array.isArray(tempSelected)
      ) {
        counter.requisitionReducer.requisitions.map((x) => {
          if (!hasNewArticle(x.article_list) && x.status == "pending") {
            //check if the article is in the selected array
            let newChange = {
              ...x,
              // id_requisitions: x.id_requisitions,
              // id_article: x.id_article,
              // qty_article: x.qty_article,
              // status: "rejected",
              // nameformail: x.article?.description,
              // comment: "",
            };
            tempSelected.push(newChange);
          }
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };
  //=============================================================================== TS-218

  const buttonCancel = () => {
    setselectedList([]);
  };

  const buttonOk = () => {
    if (selectedList.length > 0){
      let error = "";
      if (!isSameCCAllSelected()) {
        error = "Todas las requisiciones deben ser del mismo centro de consumo.";
      }
      if (!!error) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: error,
        });
        return null;
      }
      let selected_cc_id = null;
      if (Array.isArray(selectedList) && selectedList.length >= 1) {
        selected_cc_id = selectedList[0].id_consumption_center;
      }
      setmodalConsControl({
        ...modalConsControl,
        showModal: true,
        selected_cc_id: selected_cc_id,
      });
    }else{
      message("warning", "Intenta de nuevo", "Debe seleccionar como mínimo una requisición", undefined, true)
    }
  };

  const optionCostCenter = () => {
    let tempModules = [
      {
        value: "",
        label: "Seleccionar...",
      },
    ];
    if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
      counter.consumptionCenterReducer.consumption_center.map((elem) => {
        let label = elem.description;
        if (Array.isArray(elem.consumption_combination)) {
          if (elem.consumption_combination.length < 1) {
            label += "*";
          }
        } else {
          label += "*";
        }
        tempModules.push({
          value: elem.id,
          label: label,
        });
      });
    }
    return tempModules;
  };

  const optionStorage = () => {
    let tempModules = [];
    if (Array.isArray(counter.storageReducer.list)) {
      counter.storageReducer.list.map((elem) => {
        tempModules.push({
          value: elem.id,
          label: elem.description,
          articles: elem.articles,
        });
      });
    }
    return tempModules;
  };

  const renderHeaders = [
    <th key={`reqTH1`} className="text-center">
      {myPermission?.approve ? (
        <input
          //className={tableStyles.myCheckboxInput}
          key={"RequisitionsPending" + Math.random()}
          type="checkbox"
          name={"selectedAll"}
          value="selectedAll"
          defaultChecked={isBoxAllSelected()}
          onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
          disabled={freezeConsolidate}
        />
      ) : (
        <span>&nbsp;</span>
      )}
    </th>,
    <th key={`reqTH2`} className="text-center">N° Req</th>,
    <th key={`reqTH3`} className="text-center">Fecha Req</th>,
    <th key={`reqTH4`} className="text-start">Centro de Consumo</th>,
    <th key={`reqTH5`} className="text-center">Estado de Requisición</th>,
    <th key={`reqTH99`}></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(counter.requisitionReducer.requisitions)) {
      table = counter.requisitionReducer.requisitions.map((x) => {
        // x?.article_list.forEach(article=>{article.status==="rejected" ?? setfreezeConsolidate(true)})
        let reqdateformat = new Date(x.requisition_date);
        return (
          <tr key={"renderList" + x.num_req} className="hover-table-row">
            {myPermission?.approve ? (
              <td className={`col-md-1`}>
                <input
                  //className={tableStyles.myCheckboxInput}
                  key={"RequisitionsPending" + x.num_req + "_" + Math.random()}
                  type="checkbox"
                  name={"selected" + x.num_req}
                  value="selected"
                  defaultChecked={isBoxSelected(x.id)}
                  onChange={(e) => changeBoxSelected(x)}
                  disabled={
                    hasNewArticle(x.article_list) ||
                    hasAllRejected(x.article_list) ||
                    x.status != "pending" ||
                    freezeConsolidate
                  }
                />
              </td>
            ) : (
              <span>&nbsp;</span>
            )}
            <td className={`col-md-1`}>
              <p
                className={`m-0 text-capitalize ${
                  x.urgent ? reqStyles.urgentText : ""
                }`}
              >
                {x.num_req}
              </p>
            </td>
            <td className={`col-md-1`}>
              <p
                className={`m-0 ${tableStyles.cursorFlecha} ${
                  x.urgent ? reqStyles.urgentText : ""
                }`}
              >
                {reqdateformat.toLocaleDateString("es-CO")}
                {/* , { timeZone: 'America/Bogota' }*/}
              </p>
            </td>
            <td className={`col-md-5`}>
              <p
                className={`m-0 text-start ${tableStyles.cursorFlecha} ${
                  x.urgent ? reqStyles.urgentText : ""
                }`}
              >
                <Tooltip title={x.urgent ? "Urgente" : ""}>
                  <span className="m-0">
                    {x.cc_ref?.description}
                    {x.urgent ? "*" : ""}
                  </span>
                </Tooltip>
                {hasNewArticle(x.article_list) ? (
                  <Tooltip title="Artículo Nuevo">
                    <img
                      className={`${reqStyles.alertTriangle}` /*float-right*/}
                      src={AlertTriangle}
                      alt="Alerta"
                    />
                  </Tooltip>
                ) : (
                  <span />
                )}
              </p>
            </td>
            <td className={`col-md-3`}>
              <Tooltip title={ballthingyTooltipName(x.status)} arrow="true">
                {/* <div
                  className={`
                                ${reqStyles.popupReqActive} ${statusBallToClass(
                    x.status
                  )}`}
                ></div> */}
                <div
            className="rounded-pill p-1 mx-auto"
            style={{
              width: 200,
              backgroundColor: x?.status === "solved" ? "#FAFDF6" : x?.status=== "pending" ? "rgb(254, 250, 238)" : "#FEF7F5" ,
            }}
          >

            <b style={{ color: x?.status === "solved" ? "#83C036" : x?.status=== "pending" ? "rgb(233, 180, 25)" : "#F39682" }}>
            {ballthingyTooltipName(x.status)}
            </b>
          </div>
              </Tooltip>
            </td>
            <td className={`col-md-1`}>
              <img
                src={lupaTransparent}
                alt="detalles"
                className={`icons-popUp ${reqStyles.adjustIconDetalles}`}
                onClick={() => setreqState({ ...x, showModal: true })}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  // const paginateList = () => {
  //     const fullList = renderList();
  //     return fullList.slice(filters.perpage*(filters.page-1),  filters.perpage*(filters.page));
  // }

  const btnClonar = () => {
    setreqState({ showModal: false });
  };

  const validationArticleDisabled = () => {
    let booleanValue = false;
      selectedList?.forEach((requisition) => {
        requisition?.article_list.forEach((article) => {
          if (article.status === "rejected") booleanValue = true;
        });
      });
    return booleanValue;
  };

  return (
    <div className={tableStyles.container} style={{ padding: "0 6rem" }}>

      {loading && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        )
      }
      <div className={reqStyles.container1}>
        <div className={reqStyles.ItemSmall}>
          <h1 className={tableStyles.title}>
            Requisiciones pendientes de insumos
          </h1>
        </div>
      </div>

      <div className={reqStyles.container2}>
        {/* ---------------------------- ROW 1 ---------------------------------- */}
        <div className="d-flex">
          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>N° de Requisición</p>
            <input
              className="register-inputs col"
              name="concept"
              type="text"
              value={filters.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
              onKeyDown={e=>{
                    if (e.key === 'Enter') {
                        setFilters({...filters, page:1})
                        setTrigger(trigger+1);
                    }    
                }}
              placeholder="Escribir..."
            />
          </div>

          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Fecha de Requisición</p>

            <div className={tableStyles.deleteicon}>
              {!!filters.date ? (
                <span onClick={(e) => setFilters({ ...filters, date: "" })}>
                  x
                </span>
              ) : (
                ""
              )}
              <input
                className={`register-inputs ${tableStyles.crudModalInputDate}`}
                name="requisition_date"
                type="date"
                value={filters.date}
                onChange={(e) =>
                  setFilters({ ...filters, date: e.target.value })
                }
                placeholder="Escribir..."
                onKeyDown={e=>{
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                }
                }}
              />
            </div>
          </div>

          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
            <Select noOptionsMessage={() => 'No hay datos'}
              defaultValue={optionCostCenter().find(
                (x) => x.value == filters.cc_id
              )}
              onChange={(e) => setFilters({ ...filters, cc_id: e.value })}
              options={optionCostCenter()}
              placeholder="Seleccionar..."
              styles={customSelectNewDark}
              maxHeight={22}
            />
          </div>

          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Estado de requisición</p>
            <Select noOptionsMessage={() => 'No hay datos'}
              defaultValue={{ value: "", label: "Seleccionar..." }}
              placeholder="Seleccionar..."
              onChange={(e) => setFilters({ ...filters, status: e.value })}
              options={[
                { value: "", label: "Seleccionar..." },
                { value: "pending", label: "Pendiente" },
                { value: "solved", label: "Atendida" },
                //{value:"rejected", label: "Rechazado"},
              ]}
              styles={customSelectNewDark}
              maxHeight={22}
            />
          </div>

          <div className={`${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>&nbsp;</p>
            <img
              src={Lupa}
              alt="filtrar"
              className={`${tableStyles.iconSvg}`}
              onClick={() => {
                setFilters({ ...filters, page: 1 });
                setTrigger(trigger + 1);
              }}
            />
          </div>

          {/* <div className={`${reqStyles.Item}`}>
            <p className={tableStyles.crudModalLabel}>&nbsp;</p>
            <Tooltip title="Descargar PDF">
              <img
                src={iconDownload}
                alt="Descargar"
                className={`${reqStyles.adjustIconDescargar}`}
                onClick={() => dispatch(requisition_export_pdf())}
              />
            </Tooltip>
          </div> */}
        </div>
      </div>

      <div className={reqStyles.container3}>
        <GenericTableNew dark={true} headers={renderHeaders}>
          {renderList()}
        </GenericTableNew>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {counter.requisitionReducer.req_total ? filters.page : ""}
            {" de "}
            {Math.ceil(counter.requisitionReducer.req_total / filters.perpage)
              ? Math.ceil(
                  counter.requisitionReducer.req_total / filters.perpage
                )
              : ""}{" "}
            ({counter.requisitionReducer.req_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={counter.requisitionReducer.req_total}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>

      <div className={reqStyles.bottom}>
   

        <div className={reqStyles.bottomRow}>
          {myPermission?.approve && (
            <Button
              className={tableStyles.btnPrimary}
              onClick={() => buttonOk()}
              disabled={validationArticleDisabled() || freezeConsolidate}
            >
              Consolidar
            </Button>
          )}

          <Button
            className={tableStyles.btnSecondary}
            onClick={() => buttonCancel()}
          >
            Cancelar
          </Button>
        </div>
      </div>

      {reqState.showModal ? (
        <ReqModalPending
          title="Detalle de Requisición Pendiente"
          btnYesName="Aceptar"
          btnNoName="Devolución"
          doIFdispatch={hasNewArticle(reqState.article_list)}
          reqState={reqState}
          reqId={reqState.id}
          show={true}
          btnClonar={btnClonar}
          onHide={() => setreqState({ showModal: false })}
          reloadparent={() => setTrigger(trigger + 1)}
        />
      ) : (
        ""
      )}

      {modalConsControl.showModal && (
        <ReqModalConsolidate
          show={modalConsControl.showModal}
          onHide={() => setmodalConsControl({ showModal: false })}
          requisitions={selectedList}
          optionStorage={optionStorage()}
          selected_cc_id={modalConsControl.selected_cc_id}
          reloadparent={() => setTrigger(trigger + 1)}
          freezeConsolidate={freezeConsolidate}
          setfreezeConsolidate={setfreezeConsolidate}
        />
      )}
    </div>
  );
}

export default RequisitionsPending;
