import React from "react";
import styles from "./cardAccordionComponent.module.scss";
import rowIcon from "../../../../assets/img/icons/arrowDarkGrey.svg";
import rowIconClose from "../../../../assets/img/icons/detailsClosed.svg";
import tableStyles from "../../../Layouts/tableStyle.module.scss";

import { useState } from "react";
import { Button, Row } from "react-bootstrap";

const CardAccordionComponent = (props) => {
 const {
  iconRowOpen,
  iconRowClose,
  classIcon,
  title,
  subtitle,
  buttons,
  bodyTitle,
  bodyCard,
  styleIcon,
  cardBodyOpen,
  cardBodyClose,
  labelAtTitle,
 } = props;
 const [isOpen, setIsOpen] = useState(false);
 const openAccordion = () => {
  setIsOpen(!isOpen);
 };
 // ------------- SECTION STYLES OPTIONS--------------
 let stylesIsOpen = cardBodyOpen ?? styles.isOpenAcc;
 let stylesIsOpenBody = isOpen ? styles.cardBody : styles.cardBodyNone;

 // --------------------------------------------------
 return (
  <div className={`${styles.cardAccPerAppComponent}`}>
   <div className={` ${isOpen && stylesIsOpen} ${cardBodyClose ?? styles.cardBodyTitle}`}>
    <div className="d-flex justify-content-between">
     <div className="d-flex">
      <div
       onClick={openAccordion}
       className="cursorPointer d-flex"
      >
       <img
        alt="buttonRow"
        src={isOpen ? iconRowOpen ?? rowIconClose : iconRowClose ?? rowIcon}
        className={`${classIcon ?? styles.iconRow}`}
       />
      <div className="px-2 ">
       <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f13}`}>
        {" "}
        <b className={`${tableStyles.f15}`}>{title ?? "-"}</b>
        {subtitle && ` | ${subtitle}`}
       </span>
      </div>
      </div>

     </div>

     <div className=" ">
      <div className="d-flex">
       {labelAtTitle && labelAtTitle}
       {buttons &&
        buttons?.length > 0 &&
        buttons.map((elem, ind) => {
         return (
          <div key={`icon + ${ind}`}>
           <img
            key={`icon + ${ind}`}
            src={elem.icon}
            alt={`icon + ${ind}`}
            className={`${elem?.class ?? styles.iconRow} px-2 cursorPointer`}
            onClick={elem.function}
            styes={styleIcon}
           />
          </div>
         );
        })}
      </div>
     </div>
    </div>

    <Row className={`${styles.cardBodyTitle}`}>{bodyTitle}</Row>
   </div>
   {isOpen && <div className={`${stylesIsOpenBody} `}>{bodyCard}</div>}
  </div>
 );
};

export default CardAccordionComponent;
