import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import GenericTableNew from "../Layouts/GenericTableNew";
import { customSelectNew, customSelectNewDark } from "../Layouts/react-select-custom";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import Pagination from "react-js-pagination";
import Search from "../../assets/img/icons/lupa.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import Agregar from "../../assets/img/icons/add-check.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import ModalNew from "../Layouts/ModalNew";
import { useSelector, useDispatch } from "react-redux";
import { getNiifAccounts } from "../../actions/pucActions";
import { createNote, getNotesTypes, getNoteDetails, editNoteType, changeStatusNoteType } from "../../actions/noteActions";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import { useHistory } from "react-router";
import Styles from "../PettyCash/pettyCashStyles.module.scss"
import Loader from "react-loader-spinner";
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { Title } from "../../shared";


export const NoteTypes = () => {
  const dispatch = useDispatch();
  const counter = useSelector((state) => state);
  const [filters, setFilters] = useState({
    id: "",
    search: "",
    page: 1,
    perpage: 10
  });
  useEffect(() => {
    dispatch(
      getNiifAccounts({
        eaccount: counter.loginReducer.currentAccount.id,
      })
    );
    dispatch(
      getNotesTypes({
        eaccount: counter.loginReducer.currentAccount.id,
        ...filters,
      })
    );
  }, []);

  //-------------- myPermission  😄   --------------
  const myPermission = () => counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "notType");

  const history = useHistory();
  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push("/contabilidad/inicio");
    }
  }, []);


  let i = 0;
  const [modalAlertControl, setModalAlertControl] = useState({
    showSecond: false,
  });

  const [addNote, setNote] = useState({
    category: "",
    name: "",
    niif: "",
    nature: ""
  });


  const handlePageChange = (pageNumber) => {
    filters.page = pageNumber;
    setFilters({ ...filters })
    dispatch(getNotesTypes({
      ...filters,
      eaccount: counter.loginReducer.currentAccount.id,
    }));
  }

  const handleEditNote = () => {
    dispatch(
      editNoteType(addNote,
        () => {
          dispatch(getNotesTypes({ ...filters, eaccount: counter.loginReducer.currentAccount.id, }))
          setModalAlertControl({ ...modalAlertControl, showSecond: false })
        }))
  }

  const clearData = () => {
    setModalAlertControl({
      ...modalAlertControl,
      showFourt: false,
    });
    setNote({
      category: "",
      name: "",
      niif: "",
      nature: "",
      eaccount: counter.loginReducer.currentAccount.id,
    });
    dispatch(getNotesTypes({ ...filters, eaccount: counter.loginReducer.currentAccount.id }));
  };

  const listElem = () => {
    let elemMap;
    if (!!counter.noteReducer?.notes) {
      const elem2 = counter.noteReducer.notes;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const renderElement = (elem) => {
    return (
      <tr key={`note-${elem.id}`}>
        <td className="col-md-2 text-start px-2">{elem.id}</td>
        <td className="col-md-6 text-start">{elem.name}</td>
        <td className="col-md-2 text-start">{elem.categoryName}</td>
        <td className="col-md-1 text-center">
          <div
            className="rounded-pill p-1"
            style={{
              backgroundColor: elem.statusName !== "Deshabilitado" ? "#FAFDF6" : "#FEF7F5",
            }}
          >
            <b style={{ color: elem.statusName !== "Deshabilitado" ? "#83C036" : "#F39682" }}>{elem.statusName !== "Deshabilitado"
              ? "Habilitado"
              : "Inhabilitado"}</b>
          </div>

        </td>
        <td className="col-md-1 ">
          <CustomPopupExtend
            noHover
            showDetails={true}
            triggerSrc={threeDots}
            textDetails="Ver detalle"
            showDetailsEvent={() => handleDetails(elem, true)}
            showEdit={myPermission()?.edit}
            editClickEvent={() => handleDetails(elem, false)}
            showEnable={myPermission()?.delete}
            isEnabled={elem.status === "enabled" ? true : false}
            enableClickEvent={() =>
              customSwaltAlert({
                icon: "warning",
                title: "¿Esta seguro?",
                text: `Se ${elem.status === "enabled" ? 'deshabilitará' : 'habilitará'} el tipo de nota: ${elem.name}`,
                showCancelButton: true
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(changeStatusNoteType(elem, () => dispatch(getNotesTypes({ ...filters, eaccount: counter.loginReducer.currentAccount.id, }))))
                }
              })
            }
          />
        </td>
      </tr>
    );
  };

  const renderHeaders = [
    <th
      className="col-md-2 text-start px-2"
      style={{ borderTopLeftRadius: "10px" }}
      key={i}
    >
      Código
    </th>,
    <th className="col-md-6 text-start" key={i + 1}>
      Descripción
    </th>,
    <th className="col-md-2 text-start" key={i + 1}>
      Módulo
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Estado
    </th>,
    <th
      className="col-md-1 text-center"
      style={{ borderTopRightRadius: "10px" }}
      key={i + 1}
    >
      &nbsp;
    </th>,
  ];

  const loadInfo = (noteDetail) => {
    setNote({
      id: noteDetail.id,
      name: noteDetail.name,
      niif: noteDetail.niif_account,
      nature: noteDetail.nature,
      eaccount: noteDetail.eaccount,
      status: noteDetail.status,
      statusName: noteDetail.statusName,
      statusBackground: noteDetail.background,
      edit: noteDetail.edit,
      category: noteDetail.category
    });

  }

  const handleDetails = (elem, edit) => {
    dispatch(
      getNoteDetails(
        elem.id,
        (noteDetail) => loadInfo({ ...noteDetail, edit: edit })
      )
    );

    setModalAlertControl({
      ...modalAlertControl,
      showSecond: true,
    });
  };

  let renderNiifAccounts = [{ label: "Seleccionar...", value: "" }];
  counter?.pucReducer?.niifAccounts?.forEach((account) => {
    renderNiifAccounts.push({
      label: account.complete_account + " - " + account.description,
      value: account.id,
    });
  });

  let renderCategories = [
    { label: "Seleccionar...", value: "" },
    { value: 1, label: "Compras" },
    { value: 2, label: "Contabilidad" },
    { value: 3, label: "Tesorería" },
    { value: 4, label: "Facturación" },
    { value: 5, label: "Cartera" },
    { value: 6, label: "Nómina" },
    { value: 7, label: "Administración" },
    { value: 8, label: "Gerencia" },
    { value: 9, label: "Configuración" }
  ];






  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 })
    dispatch(
      getNotesTypes({
        ...filters,
        eaccount: counter.loginReducer.currentAccount.id,
        page: 1
      })
    )
  }


  return (
    <>
      {/* /* ------------------------------ Second modal ------------------------------ */}
      <ModalNew
        btnYesEvent={!addNote?.edit ? () => handleEditNote() : false}
        btnCancelEvent={() => setModalAlertControl({ ...modalAlertControl, showSecond: false })}
        btnYesName={!addNote?.edit ? "Aceptar" : null}
        size="600"
        title={!!addNote?.edit ? "Detalle tipo de nota" : "Editar tipo de nota"}
        show={modalAlertControl.showSecond}
        onHide={() => {
          setNote({})
          setModalAlertControl({
            ...modalAlertControl,
            showSecond: false,
          })
        }
        }
      >
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <Row className="d-flex">
          <Col xs={4} className="">
            <p className={`${tableStyles.crudModalLabel} `}>Tipo de nota</p>
            <p className={`${tableStyles.crudModalLabel} `}>
              <input
                style={{ marginTop: "5px" }}
                type="radio"
                name="goal"
                onChange={() => setNote({ ...addNote, nature: "C" })}
                checked={addNote.nature === "C" ? true : false}
                disabled={!!addNote?.edit ? true : false}
              />
              &nbsp; Crédito
            </p>
          </Col>
          <Col xs={4} className="mt-4">
            <p className={`${tableStyles.crudModalLabel} `}>
              <input
                className="pl-3"
                type="radio"
                name="goal"
                onChange={() => setNote({ ...addNote, nature: "D" })}
                checked={addNote.nature === "D" ? true : false}
                disabled={!!addNote?.edit ? true : false}
              />
              &nbsp; Débito
            </p>
          </Col>
        </Row>
        <Row className="d-flex ">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>Módulo</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar..."
              styles={customSelectNewDark}
              options={renderCategories}
              onChange={(e) => setNote({ ...addNote, category: e.value })}
              value={renderCategories.find(x => x.value === parseInt(addNote.category))}
              isDisabled={!!addNote?.edit ? true : false}
            />
          </Col>{" "}
        </Row>
        <Row className="d-flex ">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>Cuenta</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar..."
              styles={customSelectNewDark}
              options={renderNiifAccounts}
              onChange={(e) => setNote({ ...addNote, niif: e.value })}
              value={renderNiifAccounts.find(x => x.value === parseInt(addNote.niif))}
              isDisabled={!!addNote?.edit ? true : false}
            />
          </Col>{" "}
        </Row>

        <Row className="d-flex ">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>Descripción</p>
            <textarea
              type="text"
              className="register-inputs darkGray"
              name="name"
              onChange={(e) => setNote({ ...addNote, name: e.target.value })}
              value={addNote.name}
              disabled={!!addNote?.edit ? true : false}
              placeholder="Escribir..."
            />
          </Col>{" "}
        </Row>
      </ModalNew>

      {/* /* ------------------------------ Fourth modal ------------------------------ */}
      <ModalNew
        btnYesEvent={() => dispatch(createNote(addNote, () => {
          dispatch(getNotesTypes({ ...filters, eaccount: counter.loginReducer.currentAccount.id }))
          clearData()
        }))}
        btnYesName="Agregar"
        size="650"
        title="Crear tipo de nota"
        show={modalAlertControl.showFourt}
        onHide={() =>
          setModalAlertControl({
            ...modalAlertControl,
            showFourt: false,
          })
        }
      >
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <Row className="d-flex ">
          <Col xs={4} className="">
            <p className={`${tableStyles.crudModalLabel} `}>Tipo de nota</p>
            <p className={`${tableStyles.crudModalLabel} `}>
              <input
                style={{ marginTop: "5px" }}
                type="radio"
                name="goal"
                onChange={() => setNote({ ...addNote, nature: "C" })}
              />
              &nbsp; Crédito
            </p>
          </Col>
          <Col xs={4} className="mt-4">
            <p className={`${tableStyles.crudModalLabel} `}>
              <input
                className="pl-3"
                type="radio"
                name="goal"
                onChange={() => setNote({ ...addNote, nature: "D" })}
              />
              &nbsp; Débito
            </p>
          </Col>
        </Row>
        <Row className="d-flex ">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>Módulo</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar..."
              styles={customSelectNewDark}
              options={renderCategories}
              onChange={(e) => setNote({ ...addNote, category: e.value })}
            />
          </Col>{" "}
        </Row>

        <Row className="d-flex ">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>Cuenta</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar..."
              styles={customSelectNewDark}
              options={renderNiifAccounts}
              onChange={(e) => setNote({ ...addNote, niif: e.value })}
            />
          </Col>{" "}
        </Row>

        <Row className="d-flex ">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>Descripción</p>
            <textarea
              type="text"
              className="register-inputs"
              name="name"
              onChange={(e) => setNote({ ...addNote, name: e.target.value })}
              placeholder="Escribir..."
            />
          </Col>{" "}
        </Row>
      </ModalNew>

      <div className={tableStyles.container}>
        {!!counter.notesReducer.loading &&
          <div className="mx-auto loading">
            <Loader
              type="Oval"
              color="#003f80"
              height={100}
              width={100}
            />
          </div>
        }
        <div className={tableStyles.tableArea}>
          
          <div className={tableStyles.title}>
            <Title
              title="Tipos de notas"
              onClickIcon={() => {
                history.push('/contabilidad/inicio')
              }}
            />
          </div>

          <Row className="d-flex">
            {/* <Row xs={12} className={`d-flex`}> */}
            <Col xs={6} className="d-flex" style={{ marginTop: "6px" }}>
              {/* <form onSubmit={(e) => handleSearch(e)} className="d-flex"> */}
              <input
                className={`${tableStyles.SearchNew} d-flex`}
                type="text"
                placeholder="Buscar por código o descripción"
                onChange={(e) =>
                  setFilters({ ...filters, name: e.target.value })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch(e)
                  }
                }}

              />
              {/* </form> */}
              <button>
                <img
                  src={Search}
                  alt="Search icon"
                  height="24px"
                  className={`${tableStyles.iconSvgMargin2}`}
                  onClick={(e) => handleSearch(e)}
                />
              </button>
            </Col>


            {myPermission()?.create ?

              <Col xs={6}
                // onClick={() => {
                //   setModalAlertControl({
                //     ...modalAlertControl,
                //     showFourt: true,
                //   });
                // }}
                className={`${tableStyles.groupElems} hoverPointer ${tableStyles.btnNewHover}`}
                style={{ justifyContent: "flex-end" }}
              >

                <div
                  onClick={() => {
                    setModalAlertControl({
                      ...modalAlertControl,
                      showFourt: true,
                    });
                  }}
                  className={tableStyles.createNomModule}
                >
                  <b className={`mr-2`}>
                    Crear tipo de nota
                  </b>
                  <img
                    src={Agregar}
                    alt="User"
                  />
                  <div></div>
                </div>

                {/* <small className="mt-4 pr-3">
                    <span                      
                      className={`buttonNewOrCreate fw-bold ${tableStyles.btnNewHover} ${tableStyles.darkBlueText}`}
                    >
                      Crear tipo de nota
                      <img
                        width="18.6px"
                        height="25px"
                        src={blueAddNew}
                        alt="Form"
                        className={`${tableStyles.iconSvg} ${tableStyles.btnNewHover} buttonNewOrCreate_img m-0`}
                      />
                    </span>
                  </small> */}
              </Col>
              :
              ""
            }
            {/* </Row> */}
          </Row>

          <GenericTableNew dark={true} headers={renderHeaders}>
            {listElem()}
          </GenericTableNew>
        </div>
        <div className={`${paginationStyles.wrapper} mx-auto justify-content-between w-90 px-3`} >
          <p style={{ display: "inline" }} className="darkGray">
            Pag. {filters.page} de{" "}
            {Math.ceil(counter.noteReducer.row_total / filters.perpage)
              ? Math.ceil(counter.noteReducer.row_total / filters.perpage)
              : ""}{" "}
            ({counter.noteReducer.row_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={Number(counter.noteReducer.row_total)}
            pageRangeDisplayed={4}
            activeClass={paginationStyles.activeClass}
            onChange={(e) => handlePageChange(e)}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </>
  );
};
