
import { message } from "../helpers";
import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { customSwaltAlert } from "../helpers/customSwaltAlert";


export const updateUserInfo = (user) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  if (user.newPassword !== user.newPasswordConfirm) {
    return customSwaltAlert({
      icon: "error",
      title: "Error al cambiar contraseña",
      text: "Las contraseñas ingresadas para cambiar no coinciden.",
      confirmButtonText: 'Aceptar',
      showCancelButton: false

    });
  }
  if (user.newPassword !== "") {
    const data = {
      email: user.email,
      old_password: user.password,
      new_password: user.newPassword,
    };
    fetch(`${URL_GATEWAY}${API_VERSION}/users/change_pass/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success || res.code >= 300) {
          return customSwaltAlert({
            icon: "error",
            title: "Error al guardar",
            text: res.message,
            footer: "Si el problema contínua comuniquese con un asesor",
            confirmButtonText: 'Aceptar',
            showCancelButton: false

          });
        }
        return customSwaltAlert({
          icon: "success",
          title: "Contraseña restablecida",
          text: "Se ha cambiado la contraseña correctamente",
          confirmButtonText: 'Aceptar',
          showCancelButton: false

        });
      })
      .catch((err) => console.error(err));
  }
};

export const upImage = (data) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/users/change_image`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    }
  }).then(response => response.json())
    .then(res => {
      if (res.success) {
        message(
          'success',
          'Actualizado exitosamente',
          'Se ha actualizado el perfil correctamente',
          false,
          true,
        );
      } else {
        message(
          'warning',
          'Intenta de nuevo',
          'Revise información e intente de nuevo',
          false,
          true,
        );
      }
    });

};