import { 
    BILL_PAY_GET_PROVIDERS,
    BILL_PAY_GET_NIIF,
    GET_PAYORDERS,
    GET_PO_PINVOICES,
    GET_PO_MOVCONTABLES,
    PAY_ORDER_PAY,
    PAGO_FACTURAS_CAUSADAS,
    PAGO_FACTURAS_CAUSADAS_RESET,
} from "./actionTypes";
import { URL_GATEWAY, API_VERSION, defaultFilters } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { customSwaltAlert } from "../helpers";

const MySwal = withReactContent(Swal)

export const get_providers = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/provider?${filters}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            const {success, message, results, row_total} = res;
            dispatch({
                type: BILL_PAY_GET_PROVIDERS,
                providers: results
            })
        })
        .catch((err) => {
            console.error(err)

        })
}


export const get_niifs = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/admin/niif?${filters}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            const {totalIndex, results, row_total} = res;
            dispatch({
                type: BILL_PAY_GET_NIIF,
                niif: results
            })
        })
        .catch((err) => {
            console.error(err)

        })
}


export const get_pay_orders = (objFilters,loading) => (dispatch, getState) => {
    dispatch({
        type: GET_PAYORDERS,
        loading: true,
    })
    const token = getState().loginReducer.Authorization
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pay_order/?${filters}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            loading()
            const {totalIndex, results, row_total} = res;
            dispatch({
                type: GET_PAYORDERS,
                payorders: results,
                loading: false,
            })
        })
        .catch((err) => {
            loading()
            console.error(err)
            dispatch({
                type: GET_PAYORDERS,
                loading: true,
            })
        })
}


export const pinvoices_get_from_payorder = (objFilters) => (dispatch, getState) => {
    dispatch({
        type: GET_PO_PINVOICES,
        loading: true,
    })
    const token = getState().loginReducer.Authorization
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pinvoices/from_payorder/?${filters}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            const {totalIndex, results, row_total} = res;
            dispatch({
                type: GET_PO_PINVOICES,
                pinvoices: results,
                loading: false,
            })
        })
        .catch((err) => {
            console.error(err)
            dispatch({
                type: GET_PO_PINVOICES,
                loading: false,
            })
        })
}

export const mov_contables_from_payorder = (objFilters) => (dispatch, getState) => {
    dispatch({
        type: GET_PO_MOVCONTABLES,
        loading: true,
    })
    const token = getState().loginReducer.Authorization
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/treasury/mov_contables/from_payorder/?${filters}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            const {totalIndex, results, row_total} = res;
            dispatch({
                type: GET_PO_MOVCONTABLES,
                movcontables: results,
                loading: false,
            })
        })
        .catch((err) => {
            console.error(err)
            dispatch({
                type: GET_PO_MOVCONTABLES,
                loading: false,
            })
        })
}

export const pay_order_pay = (objFilters) => (dispatch, getState) => {
    dispatch({
        type: PAY_ORDER_PAY,
        loading: true,
    })
    const token = getState().loginReducer.Authorization
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pay_order/pay/?${filters}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            const {success, pinvoice_status, po_status, sum_pinvoice, po_total} = res;
            dispatch({
                type: PAY_ORDER_PAY,
                loading: false,
            })
        })
        .catch((err) => {
            console.error(err)
            dispatch({
                type: PAY_ORDER_PAY,
                loading: false,
            })
        })
}

export const pago_facturas_causadas = (data, doAfter = false) => (dispatch, getState) => {
    dispatch({
        type: PAGO_FACTURAS_CAUSADAS,
        loading: true,
    })
    const token = getState().loginReducer.Authorization
    fetch(`${URL_GATEWAY}${API_VERSION}/treasury/mov_contables/from_payorder/`,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            const {success, message} = res;
            dispatch({
                type: PAGO_FACTURAS_CAUSADAS,
                loading: false,
            });
            
            customSwaltAlert({
                icon: success? "success": "warning",
                title: success? "Pago Exitoso" : "Intenta de nuevo",
                text: message,
                showCancelButton: false
            }).then((result)=>{
                //if (result.isConfirmed){}
                if(!!doAfter){
                    doAfter();
                }
            });
            
        })
        .catch((err) => {
            console.error(err)
            dispatch({
                type: PAGO_FACTURAS_CAUSADAS,
                loading: false,
            });

            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'error del sistema',
                showCancelButton: false
            })
        })
}



