import React, { useEffect, useState} from "react";
import tableStyles from "../../../src/components/Layouts/tableStyle.module.scss";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import Img from "../../assets/img/child-login.png"
import Back from "../../assets/img/icons/ordBackArrow.svg"
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useGetMethod} from "../../Hooks/useFetch";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";

function ScheduleDetail(props) {
    const history = useHistory();
    const { results: schedule, load: scheduleLoader, trigger: getSchedule } = useGetMethod();
    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const location = useLocation();
    
    const convertDateToLatinFormatBack = (date) => {
        return moment(date).format("YYYY-MM-DD");
    };

    //-------------- useEffect 🍕   --------------------------

    useEffect(() => {
        if (!!(location?.state?.idDoctor)) {
            getSchedule({
            url: "/medical/appointment/scheduleByOneDoctor/",
            objFilters: {date: convertDateToLatinFormatBack(new Date()), user_med: location?.state?.idDoctor },
            token: token,
            });
        }
    }, []);


    //-------------- detail 🍕   -------------------------

    const eventDetail = (event) => {
        if (event.groupId)
            history.push({
                pathname: "/telemedicina/detalleCita/",
                state: {
                    idApp: event.groupId,
                    location:"calendar",
                    imageDoctor :location?.state?.imageDoctor,
                    nameDoctor: location?.state?.nameDoctor,
                    idDoctor: location?.state?.idDoctor

                }
            })
    }

    return (
        <div className={`${tableStyles.tlnContainer} mt-4`}>
            <div
                className="w-100 mb-3 "
            >
                {scheduleLoader && (
                    <Loader
                    className="loading"
                    type="Oval"
                    color="#003f80"
                    height={100}
                    width={100}
                    />
                )}
                <div className="d-flex py-2">
                    <div className="align-self-center">
                        <div>
                            <img src={Back} alt="" width="20" className="cursorPointer"
                                onClick={() => history.push('/telemedicina/medicosAgendas/')}
                            />
                        </div>
                    </div>
                    <div className="align-self-center px-2">
                        <div>
                            <img className="rounded-circle" src={!!(location?.state?.imageDoctor)? location?.state?.imageDoctor : Img} alt="" width="40" />
                        </div>
                    </div>
                    <div className="align-self-center">
                        <div>
                            <span className={`${tableStyles.tlnTextPrimary} fw-bold px-2`}>{!!(location?.state?.idDoctor)? location?.state?.nameDoctor : "-" }</span>
                        </div>
                    </div>
                </div>
            </div>
            <FullCalendar
                eventClick={(e) => eventDetail(e.event)}
                id="calendar"
                height="90%"
                aspectRatio={1}
                timeZone="America/Bogota"
                locale={esLocale}
                initialView="timeGridWeek"
                headerToolbar={{ left: 'prev,next', center: 'title', right: '' }}
                // headerToolbar={false}
                plugins={[dayGridPlugin, timeGridPlugin]}
                events={schedule.results}
                allDaySlot={false}
                slotLabelFormat={{ hour: 'numeric', minute: 'numeric', hour12: true }}
                titleFormat={{ month: 'long', year: 'numeric', day: 'numeric', }}
                dayHeaderFormat={{ weekday: 'long', day: 'numeric', omitCommas: true }}
                eventMaxStack={3}
            />

        </div>
    )
}
export default ScheduleDetail;
