import React, { useEffect, useState } from "react";
import { convertMoneyFormatNew, formatToRcSelect, loader, message, swalConfirm, validateEmptyString } from "../../helpers/helpers";
import { useSelector } from "react-redux";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Col, Form, Row } from "react-bootstrap";
import ordComponentStyles from "../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss";
import CustomPopupStyles from "../../../src/components/Popup/customPopup.module.scss";
import threeDots from "../../assets/img/icons/threeDots.svg";
import orderIcon from "../../assets/img/icons/orderIcon.svg";
import check2 from "../../assets/img/icons/check2.svg";
import engraneGrisOscuro from "../../assets/img/icons/engraneGrisOscuro.svg";
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import { OrdGenericTemplate } from './../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from './../../OrderingModule/OrderingComponents/OrdModal';
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import OrdTable from './../../OrderingModule/OrderingComponents/OrdTable';
import { useHistory } from 'react-router';
import { OrdAccordion } from "../../OrderingModule/OrderingComponents/OrdAccordion";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from "react-select";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg'
import ADD from '../../assets/img/icons/ADD.svg'
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from 'moment-timezone';


const SubscriptionsPage = () => {

    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const idAccount = store.loginReducer.currentAccount.id;
    const history = useHistory();
    const MySwal = withReactContent(Swal);

    //  --------------------------- States 🪔 ------------------------
    const [showAssigmentModal, setShowAssigmentModal] = useState({
        show: false,
        isEditing: false,
    });

    const [data, setData] = useState({
        idAssigment: '',
        personal: {
            name: '',
            locations: []
        },
        position: '',
        enable: true
    });

    const [tabActice, setTabActice] = useState('OrdersPage')

    const [dataTable, setDataTable] = useState()

    const [trigger, setTrigger] = useState(0);

    const [filters, setFilters] = useState({
        // canDescription: "",
        // eaccount: idAccount,
        assigmentEnabled: false
    });

    //  --------------------------- Petitions  🤙 ------------------------------
    const [assignmentResults, setAssignmentResults] = React.useState({
        results: [
            {
                "canApp": 1,
                "canDescription": "sd",
                "canId": 8,
                "canLocked": 1,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "Solicitud de cupo adicional anulada",
                "canId": 9,
                "canLocked": 1,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "Paciente no se present\u00f3",
                "canId": 10,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "El paciente se tuvo que ir",
                "canId": 19,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "El m\u00e9dico se tuvo que ir",
                "canId": 20,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "hola mundo 2",
                "canId": 21,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            }
        ]
    })
    // const { results: anulationResults, load: AnulationsLoader, trigger: getAnulations } = useGetMethod();
    const { load: updateMotiveLoader, trigger: updateMotive } = usePostMethod();
    const { load: createMotiveLoader, trigger: createNewMotive } = usePostMethod();

    // useEffect(() => {
    //     getAnulations({
    //         url: "/medical/generals/cancellationReason/",
    //         objFilters: filters,
    //         token: token,
    //     });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [trigger]);

    //  --------------------------- Modal -------------------------
    const openNewAssigment = () => {
        setData({
            idAssigment: '',
            personal: {
                name: '',
                locations: []
            },
            position: '',
            enable: true
        })
        setShowAssigmentModal({ ...showAssigmentModal, show: true, isEditing: false });
    };

    const openEditAssigment = (item) => {
        setShowAssigmentModal({ ...showAssigmentModal, show: true, isEditing: true });
        setData({ ...item })
    };
    const closeAssigmentModal = () => {
        setShowAssigmentModal({ ...showAssigmentModal, show: false, isEditing: false });
        // setData({ ...data, canDescription: "", canApp: 0, canSurgery: 0, canId: "" });
    };
    //  --------------------------- Methods ------------------------------

    const editAssigment = (item) => {
        // updateMotive({
        //     url: "/medical/generals/cancellationReason/",
        //     token: token,
        //     method: "PUT",
        //     body: data,
        //     doAfterSuccess: () => {
        //         setTrigger(trigger + 1);
        //         closeAssigmentModal();
        //     },
        //     succesAction: () =>
        //         message("success", `<span class=${tableStyles.ordClearBlueText}>Cambios guardados</span>`, `${data.canDescription}`),
        // });

        const filter = dataExample.map(e => {
            if (e.idAssigment === data.idAssigment) {
                return { ...data }
            } else {
                return e
            }
        })
        setDataExample(filter)
        message("success", `<span class=${tableStyles.ordClearBlueText}>Asignacion Editada</span>`)
        closeAssigmentModal();
    };

    const createAssigment = () => {
        // createNewMotive({
        //     url: "/medical/generals/cancellationReason/",
        //     token: token,
        //     method: "POST",
        //     body: data,
        //     doAfterSuccess: () => {
        //         setTrigger(trigger + 1);
        //         closeAssigmentModal();
        //     },
        //     succesAction: () => message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo creado</span>`, `${data.canDescription}`),
        // });
        setDataExample(e => ([...e, data]))
        message("success", `<span class=${tableStyles.ordClearBlueText}>Asignacion creada</span>`)
        closeAssigmentModal();

    };

    const disabledAssigment = (item) => {
        // swalConfirm({
        //     title: `¿Está seguro?`,
        //     text: `Se inhabilitara el motivo, "paciente no atendido"`,
        //     confirmButtonText: `Si, continuar`,
        //     doAfterConfirm: () => {
        //         const filterReasonDisable = assignmentResults.results.map(state => state?.canId === item.canId ? { ...state, canEnabled: false } : state)
        //         setAssignmentResults

        //             ({ results: filterReasonDisable })
        //         message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo inhabilitado</span>`)

        //         // updateMotive({
        //         //     url: "/medical/generals/cancellationReason/",
        //         //     token: token,
        //         //     method: "PUT",
        //         //     body: {
        //         //         canEnabled: 0,
        //         //         canId: item.canId,
        //         //     },
        //         //     doAfterSuccess: () => {
        //         //         setTrigger(trigger + 1);
        //         //         closeMotiveModal();
        //         //     },
        //         //     succesAction: () =>
        //         //         message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo inhabilitado</span>`),
        //         // });
        //     },
        // });

        const filter = dataExample.map(e => {
            if (e.idAssigment === item.idAssigment) {
                return { ...item, enable: false }
            } else {
                return e
            }
        })
        setDataExample(filter)
        message("success", `<span class=${tableStyles.ordClearBlueText}>Asignacion inhabilitada</span>`)
        closeAssigmentModal();
    };

    // -------------------------------- Validations -----------------------
    const validationRequest = (item) => {

        if (!data.position) {
            return message("warning", `<span class=${tableStyles.ordClearBlueText}>El Cargo es obligatorio</span>`)
        }

        if (!data.personal.name) {
            return message("warning", `<span class=${tableStyles.ordClearBlueText}>El Usuario es obligatorio</span>`)
        }

        showAssigmentModal.isEditing ? editAssigment(item) : createAssigment();

    };


    // ----------------------------- Headers table --------------------------------

    const headers = [
        {
            title: "Tipo",
            className: "text-center px-2",
        },

        {
            title: "No. Orden",
            className: "text-center px-2",
        },
        {
            title: "Fecha",
            className: "text-center px-2 ",
        },
        
        {
            title: "Paciente",
            className: "text-center px-2",
        },

        {
            title: "Empresa",
            className: "text-start px-2",
        },
        {
            title: "Contrato",
            className: "text-start px-2",
        },
        {
            title: "Tipo de lente",
            className: "text-center px-2",
        },
        {
            title: "Total",
            className: "text-end px-2",
        },
        {
            title: "Faltante",
            className: "text-end px-2",
        },
        {
            title: "Estado",
            className: "text-center"
        },
        {
            title: "",
            className: "text-center"
        },
    ];

    //-------------- useEffect 🍕   --------------------------

    //  useEffect(() => {
    //     setFilters({ ...filters, page: 1 })
    //     getListApp({
    //       url: "/medical/generals/appointments/",
    //       objFilters: filters,
    //       token: token,
    //       doAfterSuccess: (result) => { formatData(result.results) }
    //     });
    //   }, [filters.doctor, filters.company, filters.status, trigger]);

    //   useEffect(() => {
    //     getListApp({
    //       url: "/medical/generals/appointments/",
    //       objFilters: filters,
    //       token: token,
    //       doAfterSuccess: (result) => { formatData(result.results) }
    //     });
    //   }, [filters.page]);


    const [dataExample, setDataExample] = useState([
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,
            dataAccordion: [{
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            },
            {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'Total',
                total: '$40000',
                ajustes: 'icono'
            }
            ]

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,
            dataAccordion: [{
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            },
            {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'Total',
                total: '$40000',
                ajustes: 'icono'
            }
            ]

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,
            dataAccordion: [{
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            },
            {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'Total',
                total: '$40000',
                ajustes: 'icono'
            }
            ]

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,
            dataAccordion: [{
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            },
            {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'Total',
                total: '$40000',
                ajustes: 'icono'
            }
            ]

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,
            dataAccordion: [{
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            },
            {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'Total',
                total: '$40000',
                ajustes: 'icono'
            }
            ]

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,
            dataAccordion: [{
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            },
            {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'Total',
                total: '$40000',
                ajustes: 'icono'
            }
            ]

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,
            dataAccordion: [{
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            },
            {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'Total',
                total: '$40000',
                ajustes: 'icono'
            }
            ]

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,
            dataAccordion: [{
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            },
            {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'efectivo,tarjeta',
                total: '$10000',
                ajustes: 'icono'
            }, {
                transaccion: 0,
                fecha: '08/10/2022',
                vuelto: 'icono',
                tipoRecaudo: 'Total',
                total: '$40000',
                ajustes: 'icono'
            }
            ]

        },
    ])


        //-------------- format data to table 🍕   --------------------------

    const formatData = (array) => {
        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                tempList.push(
                    <tr key={index}>
                        <td className="text-center px-2">
                            <img src={orderIcon} alt="orderIcon" />
                        </td>
                        <td className="text-center px-2">{item?.nOrden || '-'}</td>
                        <td className="text-center px-2">
                            {item?.fecha || '-'}
                        </td>
                        <td className="text-center px-2">
                            <div>{item?.paciente || '-'}</div>
                            <div style={{ color: '#959393' }}>C.C. {item?.identificacion}</div>
                        </td>
                        <td className="text-start px-2">{item?.empresa || '-'}</td>
                        <td className="text-start px-2">{'Contrato personal'}</td>
                        <td className="text-center px-2">{'Monodical'}</td>
                        <td className="text-end px-2">{convertMoneyFormatNew({ textNumber: '3500' })}</td>
                        <td className="text-end px-2">{convertMoneyFormatNew({ textNumber: '1500' })}</td>
                        <td className="text-center px-2">
                            <span style={{ borderRadius: '10px', padding: '5px 10px', background: '#00b4cc36', color: '#00B4CC', fontWeight: 'bold' }}>
                                Abono: 100%
                            </span>
                        </td>
                        <td className="text-center px-2">
                            <CustomPopupExtend
                                triggerSrc={threeDots}
                                center
                                extraButtons={
                                    [
                                        {
                                            text: "Abonar",
                                            class: CustomPopupStyles.popUpGeneric,
                                            event: () => {
                                                history.push("/admision/ordenAbono")
                                            },
                                        },
                                        {
                                            text: "Anular",
                                            class: CustomPopupStyles.popUpGeneric,
                                            event: () => {
                                                cancelOrder()
                                            },
                                        },
                                    ]
                                }
                            />
                        </td>
                    </tr>
                );
            });
        }
        return tempList;
    };



    const optionsPosition = [
        { value: 'coordinador', label: 'coordinador' },
        { value: 'auxiliar', label: 'auxiliar' },
        { value: 'ingeniero', label: 'ingeniero' },
        { value: 'tecnico', label: 'tecnico' },
    ]

    const optionsPersonal = [
        { value: 'jhan', label: 'jhan' },
        { value: 'walter', label: 'walter' },
        { value: 'emanuel', label: 'emanuel' },
        { value: 'laura', label: 'laura' },
        { value: 'valentina', label: 'valentina' },
        { value: 'leidy', label: 'leidy' }
    ]

    const optionsLocations = [
        { value: 'sur', label: 'sur' },
        { value: 'norte', label: 'norte' },
        { value: 'foca', label: 'foca' },
        { value: 'optica', label: 'optica' },
    ]

    const handleFilterPatient = (e) => {
        setFilters({ ...filters, page: 1 })
        setTrigger(trigger + 1)
        e.preventDefault()
    }


    const cancelOrder = () => {
        return MySwal.fire({
            icon: "success",
            title: "Orden anulada",
            html: `No. 3659`,
            confirmButtonText: "Aceptar",
        });

    }

    return (
        <div style={{ fontSize: '13px' }}>

            {/* <OrdTable
                tableStyleAccordion='acordionHeader'
                tableStyleAccordionHeader='acordionHeader2'
                accordion
                accordionBox={(e) => (
                    <table style={{ textAlign: 'initial', fontFamily: 'PT Sans Caption' }}>
                        <tr>
                            <th>
                                <span>Abonos</span>
                                <br />
                                <span>Transacción</span>
                            </th>
                            <th>Fecha</th>
                            <th>Vuelto</th>
                            <th>Tipo de Recaudo</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                        {e.dataAccordion.map((x, i) => (
                            <tr>
                                <td>{i}</td>
                                <td>{x.fecha}</td>
                                <td><img style={{ marginLeft: '10px' }} src={check2} alt="check2" height={20} /></td>
                                <td style={i === e?.dataAccordion.length - 1 ? { fontWeight: 'bold', color: '#697387' } : {}}>{x.tipoRecaudo}</td>
                                <td style={i === e?.dataAccordion.length - 1 ? { fontWeight: 'bold', color: '#697387' } : {}}>{x.total}</td>
                                <td><img height={25} className={`${tableStyles.gray}`} src={engraneGrisOscuro} alt="engraneGrisOscur" /></td>
                            </tr>

                        ))}

                    </table>
                )
                }
                data={dataExample}
                isEnable={filters.assigmentEnabled}
                headers={headers}
                body={formatData}
                className={`${tableStyles.ordTableShadow}`}
                paginate={{
                    activePage: filters.page,
                    totalPages: 3,
                    perPage: filters.perpage,
                    pageRangeDisplayed: 3,
                    onChangePage: async (e) => {
                        setFilters({ ...filters, page: e })
                    },
                    showTextDetails: true
                }}
            ></OrdTable > */}

            <OrdTable
                shadow
                hasChildren={true}
                headers={headers}
            // paginate={{
            //     activePage: filters.page,
            //     totalPages: listPatient.rowTotal,
            //     perPage: filters.perpage,
            //     pageRangeDisplayed: 1,
            //     onChangePage: async (e) => {
            //         const result = await getListPatient({
            //             url: "/medical/patient/listPatient/",
            //             objFilters: { ...filters, page: e },
            //             token: token,
            //         });
            //         setFilters({ ...filters, page: e });
            //         formatData(result?.results);
            //     },
            //     showTextDetails: true,
            // }}
            >
                {formatData(dataExample)}
            </OrdTable>

        </div >
    )
}

export default SubscriptionsPage