import React, { Component, useState, useEffect } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import Excel from "../../assets/img/icons/excel.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import ModalNew from "../../components/Layouts/ModalNew";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import LupaClear from "../../assets/img/icons/lupaClear.svg";
import { useSelector, useDispatch } from "react-redux";
import {
    getHistory, getClient, getStatus, getOneMovement, getContracts
} from "../../actions/receiptOfInvoicesActions";
import Pagination from "react-js-pagination";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import { isEmptyOrUndefined } from "../../helpers/helpers";
import NumberFormat from "react-number-format";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";

function InvoiceHistory(props) {

    const history = useHistory()
    const storage = useSelector((state) => state);
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(1);
    const [filters, setFilters] = useState({
        page: 1,
        perpage: 10,
        client: "",
        contract: "",
        status: "",
        sequence: "",
        date_from: "",
        date_until: "",
        resolution: "",
        range_from: "",
        range_until: "",
        eaccount: storage.loginReducer.currentAccount.id,
    });

    useEffect(() => {
        dispatch(getHistory(filters))
    }, [trigger, filters.page]);

    useEffect(() => {
        dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
        dispatch(getStatus({ type: "invoiceHis" }));
    }, [dispatch]);

    useEffect(() => {
        if (filters.client !== "") {
            dispatch(getContracts(
                { eaccount: storage.loginReducer.currentAccount.id, client: filters.client }
            ))
        }
    }, [filters.client]);



    let optionsStatus = [{ key: "default", value: "", label: "Seleccionar...", id: "" }];
    if (Array.isArray(storage.invoiceReducer.status)) {
        storage.invoiceReducer.status.map((item) => {
            optionsStatus.push({
                value: item.name,
                label: item.value,
                key: item.id + "status",
            })
        });
    }

    let optionsClient = [{ key: "default1", value: "", label: "Seleccionar...", id: "" }];
    if (Array.isArray(storage.invoiceReducer.clients)) {
        storage.invoiceReducer.clients.map((item) => {
            optionsClient.push({
                value: item.id,
                label: item.copyName,
                key: item.id + "clients",
            })
        });
    }

    let optionsContracts = [{ key: "default2", value: "", label: "Seleccionar...", id: "" }];
    if (Array.isArray(storage.invoiceReducer.contracts)) {
        storage.invoiceReducer.contracts.map((item) => {
            optionsContracts.push({
                value: item.id,
                label: item.contract_name,
                key: item.id + "clients",
            })
        });
    }

    let optionsBalance = [
        { key: "default2", value: "", label: "Seleccionar...", id: "" },
        { key: "default3", value: "withBalance", label: "Con saldo", id: "1" },
        { key: "default4", value: "noBalance", label: "Sin saldo", id: "2" }

    ]


    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }

    const showMovement = (id) => {
        setShowModal(true)
        dispatch(getOneMovement({ id: id, eaccount: storage.loginReducer.currentAccount.id }))
    }


    const [showModal, setShowModal] = useState(false)
    const header = [

        <th className="text-center">No. Factura </th>,
        <th className="text-center">Fecha facturación</th>,
        <th className="text-center">NIT</th>,
        <th className="text-center">Empresa</th>,
        <th className="text-center">Contrato</th>,
        <th className="text-center">Saldo</th>,
        <th className="text-center">Valor</th>,
        <th className="text-center px-2">Estado</th>,
        <th className="text-center"></th>
    ]

    const renderList = () => {
        let table = [];

        if (Array.isArray(storage.invoiceReducer.history)) {
            table = storage.invoiceReducer.history.map((x, index) => {
                return (
                    <tr key={"renderList" + index} className="hover-table-row">
                        <td className="text-uppercase">{x.sequence}</td>
                        <td>{x.exp_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</td>
                        <td>{x.clientNit}</td>
                        <td>{x.clientName}</td>
                        <td>{x.contract_name}</td>
                        <td>{"$" + numberWithCommas(x.pending_amount)}</td>
                        <td>{"$" + numberWithCommas(x.total_amount)}</td>
                        <td className="text-center px-2 d-flex justify-content-center">
                            <div
                                className={`rounded-pill m-0 ${tableStyles.elipsis}`}
                                style={{
                                    backgroundColor: `${x.status_fontcolor}12`,
                                    color: `${x.status_fontcolor}`,
                                    fontWeight: 'bold',
                                    padding: '7px',
                                    width: '150px'
                                }}>
                                {x?.value}
                            </div>
                        </td>
                        <td className="text-center px-2"><img className="text-start" src={Filtrar} onClick={() => showMovement(x.id)} /></td>

                    </tr>
                );
            });
        }
        return table;
    };


    const header2 = [

        <th className="text-center">No. Doc. </th>,
        <th className="text-center">Tipo movimiento</th>,
        <th className="text-center">Fecha de rad.</th>,
        <th className="text-center">Valor</th>,
        <th className="text-center">Saldo</th>,
    ]
    const renderList2 = () => {
        let table = [];

        if (Array.isArray(storage.invoiceReducer.movements_for_invoice.detail)) {
            table = storage.invoiceReducer.movements_for_invoice.detail.map((x, index) => {
                return (
                    <tr key={"renderList" + index} className="hover-table-row">
                        <td className="text-uppercase">{x.doc_number}</td>
                        <td>{x.doc_type}</td>
                        <td>{isEmptyOrUndefined(x.filing_date) ? "-" : x.filing_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</td>
                        <td>{"$" + numberWithCommas(x.amount)}</td>
                        <td>{"$" + numberWithCommas(x.balance)}</td>

                    </tr>
                );
            });
        }
        return table;
    };

    const [showOpt, setShowOpt] = useState({
        show: false,
        arrow: false,
    });

    const ShowOptions = () => {
        if (showOpt?.show && showOpt?.arrow) {
            setShowOpt({
                ...showOpt,
                show: false,
                arrow: false,
            });
        } else {
            setShowOpt({
                ...showOpt,
                show: true,
                arrow: true,
            });
            setFilters({
                ...filters,
                client: "",
                contract: "",
                status: "",
                sequence: "",
                date_from: "",
                date_until: "",
                resolution: "",
                range_from: "",
                range_until: ""
            })
        }
    };
    const applyFilters = () => {
        setFilters({ ...filters, page: 1, perpage: 10 })
        setTrigger(trigger + 1)
    }
    const handleSearch = (e) => {
        e.preventDefault();
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
    };

    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                {(storage.invoiceReducer.history_loading) &&
                    <div className="loading">
                        <Loader
                            type="Oval"
                            color="#003f80"
                            height={100}
                            width={100}
                        />
                    </div>
                }
                
                <div className={tableStyles.title}>
                    <Title
                        title="Histórico de facturas"
                        onClickIcon={() => {
                            history.push('/cartera/inicio')
                        }}
                    />
                </div>
                {/* <div className={IndividualStyles.container2}>
                    <div
                        className={`${IndividualStyles.Aling} ${IndividualStyles.inputMargin} `}
                    >
                        <div
                            className={`${IndividualStyles.Boton} `} onClick={() => ShowOptions()}>
                            <img
                                src={LupaClear}
                                alt="añadir"
                                className={`mx-3  ${IndividualStyles.pointerNew} ${IndividualStyles.sizeNew2} ${tableStyles.cursorPointer}`}
                            />
                            <span class={`${IndividualStyles.proArrowWrapper}`}>
                                {!!showOpt.arrow ?
                                    <span class={`${IndividualStyles.proArrow2}`}> </span>
                                    :
                                    <span class={`${IndividualStyles.proArrow}`}> </span>
                                }
                            </span>
                        </div>
                    </div>
                </div> */}
                {/* {!!showOpt.show ?
                    <span ></span>
                } */}

                <div>
                    <div
                        className="div align-items-end"
                        style={{ gap: '0.5rem 1rem', flexWrap: 'wrap' }}
                    >

                        <div className={tableStyles.containerSelect}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.stylesLabel}>Empresa</label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={optionsClient}
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            client: e.value,
                                        })
                                    }
                                    placeholder="Seleccionar..."
                                    styles={customSelectNewDark} />
                            </form>
                        </div>

                        <div className={tableStyles.containerSelect}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.stylesLabel}>Estado</label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={optionsStatus}
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            status: e.value,
                                        })
                                    }
                                    placeholder="Seleccionar..."
                                    styles={customSelectNewDark} />
                            </form>
                        </div>

                        <div className={tableStyles.containerSelect}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.stylesLabel}>Resolución</label>
                                <input className={IndividualStyles.registerInputsGris}
                                    type="text"
                                    placeholder="Escribir..."
                                    value={filters.resolution}
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            resolution: e.target.value,
                                        })
                                    }
                                >

                                </input>
                            </form>
                        </div>

                        <div className={tableStyles.containerDate}>

                            <label className={tableStyles.stylesLabel}>Fecha desde</label>
                            <div className={tableStyles.deleteicon}>
                                {!!filters.date_from ?
                                    <span onClick={e => setFilters({ ...filters, date_from: "" })} >x</span>
                                    :
                                    ""
                                }
                                <form onSubmit={(e) => handleSearch(e)}>
                                    <input className={IndividualStyles.registerInputsBlue} type="date"
                                        value={filters.date_from}
                                        onChange={(e) =>
                                            setFilters({
                                                ...filters,
                                                date_from: e.target.value,
                                            })
                                        }
                                    />
                                </form>
                            </div>
                        </div>

                        <div className={tableStyles.containerDate}>
                            <label className={tableStyles.stylesLabel}>Hasta</label>
                            <div className={tableStyles.deleteicon}>
                                {!!filters.date_until ?
                                    <span onClick={e => setFilters({ ...filters, date_until: "" })} >x</span>
                                    :
                                    ""
                                }
                                <form onSubmit={(e) => handleSearch(e)}>
                                    <input className={IndividualStyles.registerInputsBlue} type="date"
                                        value={filters.date_until}
                                        onChange={(e) =>
                                            setFilters({
                                                ...filters,
                                                date_until: e.target.value,
                                            })
                                        }
                                    />
                                </form>
                            </div>

                        </div>

                        <div className={tableStyles.containerSelect}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.stylesLabel}>Saldo</label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={optionsBalance}
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            balance: e.value,
                                        })
                                    }
                                    placeholder="Seleccionar..."
                                    styles={customSelectNewDark}
                                />
                            </form>
                        </div>

                        <div className={tableStyles.containerSelect}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.stylesLabel}>Contrato</label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={optionsContracts}
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            contract: e.value,
                                        })
                                    }
                                    placeholder="Seleccionar..."
                                    styles={customSelectNewDark}
                                    isDisabled={filters.client == ""}
                                />
                            </form>
                        </div>

                        <div className={tableStyles.containerSelect}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.stylesLabel}>No. Factura desde</label>
                                <NumberFormat
                                    allowNegative={false}
                                    prefix={"SC"}
                                    style={{ textAlign: "start !important" }}
                                    placeholder={"Escribir..."}
                                    className={`${IndividualStyles.registerInputs} text-secondary `}
                                    name="value"
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        setFilters({
                                            ...filters,
                                            range_from: value,
                                        })
                                    }}

                                >
                                </NumberFormat>
                            </form>
                        </div>

                        <div className={tableStyles.containerSelect}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.stylesLabel}>Hasta</label>
                                <NumberFormat
                                    allowNegative={false}
                                    prefix={"SC"}
                                    placeholder={"Escribir..."}
                                    className={`${IndividualStyles.registerInputs} text-secondary `}
                                    name="value"
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        setFilters({
                                            ...filters,
                                            range_until: value,
                                        })
                                    }}

                                >
                                </NumberFormat>
                            </form>
                        </div>

                        <div className=" col display-grid  mr-2" style={{ alignContent: "end" }}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className=""> <img style={{ width: '25px' }} className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`} src={Lupa} onClick={() => applyFilters()} /></label>
                            </form>
                        </div>

                        <div className="col display-grid  mr-2" style={{ alignContent: "end" }}>
                            <label className="" style={{ marginLeft: 'auto' }}> <img src={Excel} /></label>

                        </div>

                    </div>

                </div>


                <GenericTable
                    headers={header}
                    dark={true}
                >
                    {renderList()}
                </GenericTable>
                <div className={IndividualStyles.bottom}>
                    <div className={paginationStyles.wrapper}>
                        <p className={paginationStyles.paginationText}>
                            Pag.{" "}
                            {storage.invoiceReducer.history
                                ? filters.page
                                : ""}
                            {" de "}
                            {Math.ceil(
                                storage.invoiceReducer?.history_total / filters.perpage
                            )
                                ? Math.ceil(
                                    storage.invoiceReducer.history_total /
                                    filters.perpage
                                )
                                : ""}{" "}
                            ({storage.invoiceReducer.history_total} encontrados)
                        </p>
                        <Pagination
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={storage.invoiceReducer.history_total}
                            pageRangeDisplayed={5}
                            onChange={(e) => setFilters({ ...filters, page: e })}
                            itemClassPrev={paginationStyles.itemClassPrev}
                            itemClassNext={paginationStyles.itemClassNext}
                            itemClassFirst={paginationStyles.itemClassFirst}
                            itemClassLast={paginationStyles.itemClassLast}
                            itemClass={paginationStyles.itemClass}
                        />
                    </div>
                </div>
            </div>
            <ModalNew
                show={showModal}
                onHide={() => setShowModal(false)}
                title={"Movimiento de una factura"}
                btnYesEvent={() => setShowModal(false)}
                hideCancelButton={true}
                btnYesName={"Aceptar"}
                size={"750"}

            >
                <div className="div" style={{ backgroundColor: "#005DBF10", padding: "7px 12px 15px 12px", borderRadius: "10px" }}>
                    <div className="col-4 display-grid mr-3">
                        <label className={IndividualStyles.crudModalLabelGris}>No. Factura</label>
                        <input className={IndividualStyles.registerInputsGris} type="text" value={storage.invoiceReducer.movements_for_invoice.sequence}
                        />
                    </div>
                    <div className="col-8 display-grid mr-2" style={{ maxWidth: "365px" }}>
                        <label className={IndividualStyles.crudModalLabelGris}>Empresa</label>
                        <input className={IndividualStyles.registerInputsGris} type="text" value={storage.invoiceReducer.movements_for_invoice.clientNit ? storage.invoiceReducer.movements_for_invoice.clientNit + " - " + storage.invoiceReducer.movements_for_invoice.clientName : ""} />
                    </div>
                </div>
                <GenericTable
                    headers={header2}
                >
                    {renderList2()}
                </GenericTable>

            </ModalNew>

        </>
    )

};
export default InvoiceHistory;