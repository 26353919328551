import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
import { isEmptyOrUndefined } from '../../helpers/helpers'

export const OrdBiTable = (props) => {
    let { data } = props
    return (
        <>
            {
                !isEmptyOrUndefined(data) &&
                data?.length > 0 &&
                data.map((e, i) => {
                    return (
                        <Row className={`${e.rowClassName} `}
                            style={e.rowCustomStyles}
                        >
                            <Col
                                className={`${tableStyles.ordBitableTitle} ${e.titleClassName}`}
                                xs={e.titleSize || 4}>
                                {e.title}
                            </Col>
                            <Col
                                className={`${tableStyles.ordBitableSubTitle} ${e.subTitleClassName}`}
                                xs={e.subTitleSize || 8}>
                                {e.subTitle}
                            </Col>
                        </Row>
                    )
                })
            }
        </>
    )
}

/* ------------------------------- USE EXAMPLE ------------------------------ */

// const biTableData = [
//     {
//         title: "Nombre comercial",
//         subTitle: "Oxitetracilina",
//         // titleSize: 5,
//         // subTitleSize: 3
//         titleClassName: "text-danger",
//         subTitleClassName: "text-info"
//     }
// ]
{/* <OrdBiTable
data={biTableData}
/> */}