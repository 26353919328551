import React, { useEffect, useState } from "react";
import {
  BoxItemColumStatic,
  BoxTable,
  ColumDinamicBody,
  ColumnDinamic,
  ColumStatic,
  HeaderColumStatic,
  Input,
  ItemColumDinamic,
  ItemColumDinamicBody,
  ItemColumStatic,
  Alert,
  BoxButton,
  ButtonSubmit,
  BoxCloneTime,
} from "./styled";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import threeDots from "../../assets/img/icons/threeDots.svg";
import CustomPopupStyles from "../../../src/components/Popup/customPopup.module.scss";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { ItemService } from "../../MedicalAccounts/styled";
import { Tooltip } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const TableStatic = ({
  changeSelect,
  locations,
  services,
  data: { setData, data: getData },
  r: { setR, },
  filterClone: { setFiltersCloneTime, filtersCloneTime },
  handleChange,
  valuesForm,
  setValuesForm,
  initialState,
}) => {
  const MySwal = withReactContent(Swal);
  const [changeX, setChangex] = useState(false);


  useEffect(() => {
    const filterOne = Object.entries(valuesForm).map(([key, value]) => {

      if (key.split("_")[0] === "mi") {
        return {
          idService: Number(key.split("_")[1]),
          name: services.find((e) => e.idService === Number(key.split("_")[1]))
            .name,
          sites: [
            { idSite: Number(key.split("_")[2]), minTime: Number(value) },
          ],
        };
      }

      if (key.split("_")[0] === "ma") {
        return {
          idService: Number(key.split("_")[1]),
          name: services.find((e) => e.idService === Number(key.split("_")[1]))
            .name,
          sites: [
            { idSite: Number(key.split("_")[2]), maxTime: Number(value) },
          ],
        };
      }

      // }
    });


    services?.forEach((service) => {

      if (typeof service?.sites === 'undefined') {
        locations.forEach(location => {
          setValuesForm(e =>
          ({
            ...e,
            [`ma_${service.idService}_${location.id}`]: ''
            , [`mi_${service.idService}_${location.id}`]: ''
          }
          ))
        })
      } else {
        const a = Object.keys(valuesForm).filter(e => e.includes(service.idService))

        for (let i = 0; i < service.sites.length; i++) {
          for (let j = 0; j < a.length; j++) {
            if (a[j].includes(service.sites[i].idSite)) {

              if (a[j].split('_')[0] === 'ma') {
                setValuesForm(e =>
                ({
                  ...e,
                  [a[j]]: service.sites[i].maxTime
                }
                ))
              }
              if (a[j].split('_')[0] === 'mi') {
                setValuesForm(e =>
                ({
                  ...e,
                  [a[j]]: service.sites[i].minTime
                }
                ))
              }



            } else {
              setValuesForm(e =>
              ({
                ...e,
                [a[j]]: ''
              }
              ))
            }
          }
        }

        service.sites?.forEach((site) => {

          setValuesForm(e => ({ ...e, [`ma_${service.idService}_${site.idSite}`]: site.maxTime, [`mi_${service.idService}_${site.idSite}`]: site.minTime }))
        });

      }

    });



    setData((state) => ({
      ...state,
      servicesTimes: filterOne,
    }));


  }, [changeSelect, services, Object.values(valuesForm).length < 1 && valuesForm]);


  useEffect(() => {
    const filterOne = Object.entries(valuesForm).map(([key, value], i) => {
      if (key.split("_")[0] === "mi") {
        return {
          idService: Number(key.split("_")[1]),
          name: services.find((e) => e.idService === Number(key.split("_")[1]))
            .name,
          sites: [
            { idSite: Number(key.split("_")[2]), minTime: Number(value) },
          ],
        };
      }

      if (key.split("_")[0] === "ma") {
        return {
          idService: Number(key.split("_")[1]),
          name: services.find((e) => e.idService === Number(key.split("_")[1]))
            .name,
          sites: [
            { idSite: Number(key.split("_")[2]), maxTime: Number(value) },
          ],
        };
      }
    });
    setData((state) => ({
      ...state,
      servicesTimes: filterOne,
    }));

    setR(true)

  }, [valuesForm]);


  useEffect(() => {
    const idService = services[0]?.idService;
    const validate = services.filter(
      (service) => service.idService === idService
    );
    if (Object?.values(getData)?.length) {
      if (
        typeof getData?.servicesTimes?.[0]?.sites[0]?.maxTime === "undefined"
      ) {
        let a = [];
        let obj = {};
        let obj1 = {};
        let rr = [];
        for (let i = 0; i < services?.length; i++) {
          obj = {};
          obj1 = {};
          rr = [];
          let x = 0;
          for (let j = 0; j < getData.servicesTimes?.length; j++) {
            if (services[i].idService === getData.servicesTimes[j].idService) {
              if (
                typeof getData.servicesTimes[j].sites[0]?.maxTime !==
                "undefined"
              ) {
                obj.idSite = getData.servicesTimes[j].sites[0].idSite;
                obj.maxTime = getData.servicesTimes[j].sites[0].maxTime;
              }
              if (
                typeof getData.servicesTimes[j].sites[0]?.minTime !==
                "undefined"
              ) {
                obj.minTime = getData.servicesTimes[j].sites[0].minTime;
                obj.idSite = getData.servicesTimes[j].sites[0].idSite;
              }
              x++;
              if (x === 2) {
                x = 0;
                rr.push(obj);
                obj = {};
              }
            }
          }
          obj.sites = [...rr];
          obj1.idService = services[i].idService;
          obj1.name = services[i].name;
          obj1.sites = [...rr];
          a.push(obj1);
        }
        getData.servicesTimes = a;
      }
    }
  }, [changeSelect, filtersCloneTime, changeX]);

  const cloneServices = (id, min, max) => {
    locations.forEach((location, i) => {
      if (i > 0) {
        setValuesForm((state) => ({
          ...state,
          [`ma_${id}_${location.id}`]: max,
          [`mi_${id}_${location.id}`]: min,
        }));
      }
    });
  };


  const handleClone = (id) => {
    const idService = Number(id);
    let minValue = Number(valuesForm[`mi_${id}_${locations[0].id}`]);
    let maxValue = Number(valuesForm[`ma_${id}_${locations[0].id}`]);

    if (
      isNaN(minValue) ||
      isNaN(maxValue) ||
      minValue < 5 ||
      maxValue < minValue ||
      minValue > 60
    ) {
      return MySwal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor, verifique los datos",
      });
    }
    return cloneServices(idService, minValue, maxValue);
  };

  const onChangeBlur = ({ target }) => {
    const value = Number(target.value);
    const name = target.name;

    if (name.split("_")[0] === "mi") {
      let nameMax = name.split("_");
      nameMax[0] = "ma";
      nameMax = nameMax.join("_");
      const maxValue = Number(valuesForm[nameMax]);

      const valueTemp = 0


      if (valueTemp === value) {
        return
      }


      if (value < 5 || value > (valuesForm[name.replace('mi', 'ma')] === '' ? 59 : valuesForm[name.replace('mi', 'ma')])) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "El tiempo mínimo no puede ser menor a 5 minutos y no puede ser mayor al tiempo máximo",
        });
        setValuesForm((state) => ({ ...state, [name]: 5 }));
        return;
      }

      if (value < 5) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "El tiempo mínimo no puede ser menor a 5 minutos y no puede ser mayor al tiempo máximo",
        });
        setValuesForm((state) => ({ ...state, [name]: 5 }));
        return;
      }



    }

    if (name.split("_")[0] === "ma") {
      let nameMin = name.split("_");
      nameMin[0] = "mi";
      nameMin = nameMin.join("_");
      const minValue = Number(valuesForm[nameMin]);

      const valueTemp = 0;

      if (valueTemp === value) {
        return
      }

      if (value > 60 || value < minValue) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "El tiempo máximo no puede superar los 60 minutos y no puede ser menor al tiempo mínimo",
        });
        setValuesForm((state) => ({ ...state, [name]: 60 }));
        return;
      }
    }
  };



  const renderOrderTypeTooltip = (text) => (
    <Tooltip>
      {text}
    </Tooltip>
  );

  return (
    <>
      {services?.length ? (
        <>
          <BoxTable
            // className="bg-danger"
            style={locations.length === 1
              ? { width: `calc(700px * ${locations.length})` }
              : locations.length <= 3 ? { width: `calc(230px * ${locations.length} + 400px )` } : { width: '100%' }}
          >
            <ColumStatic>
              <HeaderColumStatic>Servicio</HeaderColumStatic>
              {services?.length
                ? services?.map((service) => (
                  <>
                    <ItemColumStatic key={service.idService}>
                      <BoxCloneTime>
                        <ItemService>{service.name}</ItemService>
                        <CustomPopupExtend
                          triggerSrc={threeDots}
                          extraButtons={[
                            {
                              text: `Clonar tiempos`,
                              event: (e) => {
                                setFiltersCloneTime(`${service.idService}`);
                                setChangex((e) => !e);
                                handleClone(`${service.idService}`);
                              },
                              class: CustomPopupStyles.popUpGeneric,
                            },
                          ]}
                        ></CustomPopupExtend>
                      </BoxCloneTime>
                    </ItemColumStatic>
                  </>
                ))
                : "no hay servicios"}
            </ColumStatic>

            <BoxItemColumStatic
              style={locations.length === 1
                ? { width: `calc(700px * ${locations.length})` }
                : locations.length <= 3 ? { width: `calc(230px * ${locations.length})` } : { width: '100%' }}
            >
              <ColumnDinamic columns={locations?.length}>
                {locations?.map((location) => (
                  <ItemColumDinamic key={location.id}>
                    {location.name}
                  </ItemColumDinamic>
                ))}
              </ColumnDinamic>

              <ColumDinamicBody columns={locations.length} >
                {services.map((service) => (
                  <>
                    {locations.map((location) => (
                      <>
                        {location.id ===
                          service?.sites?.find((e) => e?.idSite === location?.id)
                            ?.idSite ? (
                          <ItemColumDinamicBody key={location.id}>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 100, hide: 100 }}
                              overlay={renderOrderTypeTooltip(location.name)}
                            >
                              <div style={{ display: 'flex', columnGap: '10px' }}>
                                <Input
                                  placeholder='Tiempo Mín'
                                  className="inputNumber timesPlace"
                                  type={"number"}
                                  value={
                                    valuesForm[
                                    `mi_${service.idService}_${location.id}`
                                    ]
                                  }

                                  name={`mi_${service.idService}_${location.id}`}
                                  onChange={handleChange}
                                  onBlur={onChangeBlur}
                                />
                                <Input
                                  placeholder='Tiempo Máx'
                                  className="inputNumber timesPlace"
                                  type={"number"}
                                  value={
                                    valuesForm[
                                    `ma_${service.idService}_${location.id}`
                                    ]
                                  }
                                  name={`ma_${service.idService}_${location.id}`}
                                  onChange={handleChange}
                                  onBlur={onChangeBlur}
                                />
                              </div>
                            </OverlayTrigger>

                          </ItemColumDinamicBody>
                        ) : (
                          <ItemColumDinamicBody key={location.id}>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 100, hide: 100 }}
                              overlay={renderOrderTypeTooltip(location.name)}
                            >
                              <div style={{ display: 'flex', columnGap: '10px' }}>
                                <Input
                                  placeholder='Tiempo Mín'
                                  className="inputNumber timesPlace"
                                  type={"number"}
                                  value={
                                    valuesForm[
                                    `mi_${service.idService}_${location.id}`
                                    ]
                                  }
                                  name={`mi_${service.idService}_${location.id}`}
                                  onChange={handleChange}
                                  onBlur={onChangeBlur}
                                />
                                <Input
                                  placeholder='Tiempo Máx'
                                  className="inputNumber timesPlace"
                                  type={"number"}
                                  value={
                                    valuesForm[
                                    `ma_${service.idService}_${location.id}`
                                    ]
                                  }
                                  name={`ma_${service.idService}_${location.id}`}
                                  onChange={handleChange}
                                  onBlur={onChangeBlur}
                                />
                              </div>
                            </OverlayTrigger>

                          </ItemColumDinamicBody>
                        )}
                      </>
                    ))}
                  </>
                ))}
              </ColumDinamicBody>
            </BoxItemColumStatic>
          </BoxTable>
          <BoxButton>
            <ButtonSubmit type="submit">Registrar</ButtonSubmit>
          </BoxButton>
        </>
      ) : (
        <Alert>Seleccione un Grupo de Atencion</Alert>
      )}
    </>
  );
};

export default TableStatic;
