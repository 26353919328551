import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import detailStyles from "./PODetail.module.scss";
import Back from "../../assets/img/icons/backIcon.svg";
import { Checkbox } from '@material-ui/core';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import {
    purchase_order_get_one,
    quotation_filter,
} from "../../actions/IndividualAuctionActions";

import {
    creat_purchaseOrder,
    SumpurchaseOrder_get_one,
}from "../../actions/purchaseOrderActions";


import {
    aprove_purchase_order_filter
} from "../../actions/IndividualAuctionActions";

import { 
    PURCHASEORDER_MODAL3
    } from "../../actions/actionTypes";

import SpecialTable from "../Layouts/SpecialTable/SpecialTable";
import specialTableStyles from "../Layouts/SpecialTable/SpecialTable.module.scss";
import specialTableArrow from "../../assets/img/icons/specialTableArrow.svg";
import AlertTriangle from "../../assets/img/icons/AlertTriangle.svg";
import iconShopCart from "../../assets/img/icons/iconShopCart.svg";
import auctionDetailLupa from "../../assets/img/icons/auctionDetailLupa.svg";
import auctionDetailExpand from "../../assets/img/icons/auctionDetailExpand.svg";
import { Button } from 'react-bootstrap';
import Send from "../../assets/img/icons/send.svg";
import { dropRight, reject } from "lodash";
import ModalProductsAut from './ModalProductsAut';
import ModalNew from "../Layouts/ModalNew";
import checkboxTrue from "../../assets/img/icons/checkboxTrue.svg";
import date from "../../assets/img/icons/date.svg";
import False from "../../assets/img/icons/False.svg";
import GenericTableNew from "../Layouts/GenericTableNew";
import RoundCheck from "../../assets/img/icons/RoundCheck.svg";




/////////// FUNCIONES DE LA PRIMERA SECCION /////////////////////////////

function auctionType (key){
    switch(key){
        case "consolidationreq":
            return "Consolidación de requisiciones ";
        case "buyin":
            return "Compra individual";
        default:
            return "-";
    }
}

function priority (key){
    switch(key){
        case "normal":
            return "Normal";
        case "priority":
            return "Prioritaria";
        default:
            return "-";
    }
}

function payConditions (key){
    switch(key){
        case "prepayment":
            return "Pago anticipado";
        case "delivery":
            return "Pago contraentrega";
        case "pay20":
            return "Pago a 20 días";
        case "pay30":
            return "Pago a 30 días";
        case "pay60":
            return "Pago a 60 días";
        case "pay90":
            return "Pago a 90 días";
        default:
            return "-";
    }
}


function NamePerson (list){
    var Names="" 
    if (list.first_name!= null){
        Names = list.first_name
    }
    if (list.second_name!= null && list.first_name!= null){
        
        Names = list.first_name+" "+list.second_name
    }
    var SurName=""
    if (list.first_surname!= null){
        SurName = list.first_surname
    }
    if (list.second_name!=null && list.first_surname!=null){
        SurName= list.first_surname+ " "+ list.second_surname
    }
    var CompleteName= Names+ " "+ SurName
    return CompleteName

}

var formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
});


///////////////// HEADER DE LA TABLA DE ARTICULOS /////////////////////

const article_headers =[<p>Proveedor</p>,<p>Marca</p>,<p>Precio&nbsp;Unit.</p>,<p>Cant.</p>, <p>Descuento</p>, <p>Valor&nbsp;Total</p>,  <p ><img src={iconShopCart} className={detailStyles.tableGearIcon} alt="carrito" /></p>];

function clamp (val, min, max) {
    return Math.min(Math.max(val, min), max);
};

function DetailPurchaseOrder (props) {
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [triggerMemo, setTriggerMemo] = useState(1);
  const [articleSearch, setarticleSearch] = useState("");

  //////// PERMISOS ////////////////////////////////////////////////////
    const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "APO");
  
    useEffect(() => {
        const { history } = props
            if (!myPermission?.read){
                history?.push("/purchases/inicio");
            
            }
    },);

    const [selectedList, setSelectedList] = useState([]);

    const [infoToPurchaseOrder, setinfoToPurchaseOrder]= useState([]);

    const [PurchaseOrderIndex, setPurchaseOrderIndex] = useState(0);

   useEffect(() => {
       if(!!props.selectedAuction?.id){
        dispatch( purchase_order_get_one(props.selectedAuction.id) );
        dispatch( quotation_filter({indauc: props.selectedAuction.id}));
    }
    },[props.show]);

    useEffect(() => {
        if(!!counter.purchaseOrderReducer?.codes){
            dispatch(SumpurchaseOrder_get_one(counter.purchaseOrderReducer.codes[PurchaseOrderIndex]));
        }
    },[PurchaseOrderIndex, counter.purchaseOrderReducer.modalPart3]);


    const isArticleProviderSelected = (article_id, provider_id) =>{
        let found = selectedList.find(x=>( (x.article_id ===article_id) && (x.provider_id ===provider_id) ));
        return !!found? true:false;
    }

    

    const setArticleProviderSelected = (article_id, provider_id, provider_name,quotation_total, id_quotation, pay_conditions, qty_article, unit_price,iva_value, discount_value, discount, iva) =>{        
        let newSelectedList = selectedList.filter(x => (x.article_id !== article_id));

        /////// INDIVIDUAL SUBTOTAL FOR EACH PROVIDER SELECTED 
        let subt= unit_price*qty_article

        newSelectedList.push({
            article_id: article_id,
            provider_id: provider_id,
            provider_name:provider_name,
            quotation_total:quotation_total,
            subtotal:subt,  
            iva_value:iva_value,
            discount_value: discount_value,
            unit_price: unit_price,
            id_quotation:id_quotation,
            pay_conditions: pay_conditions,
            discount: discount,
            iva:iva,

        });
        
        setSelectedList(newSelectedList);
        setTriggerMemo(triggerMemo+1);
        
    }



  

    //////////////////  SELECCIÓN DE SUBASTA /////////////////////////////
    const store_poauc= counter.individualAuctionReducer.selected_poauction;

    /////////////////  TEXTO ACORDE A ESTADO DE ARTICULO //////////////////
    const ballthingyTooltipName = (listArticles) => {
        let status=0;
        if (Array.isArray(listArticles)) {
               listArticles.map((x) => {
                    if (x.status_articles==="req_authorization") {
                        status=status+1
                        return status
                    }
                }) 
            }
        if (status>0){
            return "Lista de productos"
        }else{
            return "Lista de productos autorizados"
        } 
      };
    
    

    const buttonCancel =()=>{
        props.setDetails({show:false})
    }

    

    const stopParentClick = (event) => {
        event.stopPropagation();
    }

    const [collapseAll, setCollapseAll] = useState(true);
    
    ////////////////////// FOOTER TABLA NUEVA ////////////////////////////
    const specialTableFooter = (obj) => {
        return <div className={detailStyles.f1}>
            <p className={`${detailStyles.crudModalLabel} ${specialTableStyles.footerTitle}`}>Última Compra</p>
            <div className="d-flex mx-1">

                <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f2}`}>
                    <p className={`${detailStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>Proveedor</p>
                    <input
                        className={` ${detailStyles.crudModalInputDate} ${detailStyles.registerInputs}`}
                        type="text"
                        value={"?"}
                        disabled={true}
                        
                    />
                </div>

                <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f2}`}>
                    <p className={`${detailStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>Marca</p>
                    <input
                        className={` ${detailStyles.crudModalInputDate} ${detailStyles.registerInputs}`}
                        type="text"
                        value={"?"}
                        disabled={true}
                    />
                </div>

                <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f3}`}>
                    <p className={`${detailStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>Precio Unitario</p>
                    <input
                        className={` ${detailStyles.crudModalInputDate} ${detailStyles.registerInputs}`}
                        type="text"
                        value={"?"}
                        disabled={true}
                        
                    />
                </div>

                <div  className={`${detailStyles.Item} ${detailStyles.f1}`}>
                    <p className={`${detailStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>Cantidad</p>
                    <input
                        className={` ${detailStyles.crudModalInputDate} ${detailStyles.registerInputs}`}
                        type="text"
                        value={"?"}
                        disabled={true}
                        
                    />
                </div>

            </div>
        </div>
    }

   //////////////// ARTICULOS TABLA DE COLORES /////////////////////
   const stringAIncludesB = ( A, B) =>{
        let AA= String(A).toLowerCase();
        let BB= String(B).toLowerCase();

        return AA.includes(BB);
    }

    const listArticles = /*useMemo(*/() => {
        let fullList = [];

        //for each article make a table
        if(Array.isArray(counter.individualAuctionReducer.selected_poauction?.article_list)
        && Array.isArray(counter.individualAuctionReducer.quotations)){
            

            counter.individualAuctionReducer.selected_poauction.article_list.map( (x, index)=>{

                if (x.status_articles ==="req_authorization"){
                //filter by search
                if(stringAIncludesB( x.article?.description,articleSearch) || stringAIncludesB( x.article?.id, articleSearch) ){
                //theme
                    let mytheme="orange";

                //supers
                let superColumns = [
                    <p className="hoverPointer">
                        {index+1}
                        <img src={specialTableArrow} className={specialTableStyles.arrow} alt=">" />
                        {x.article?.description}
                    </p>,
                    <p>{x.article?.id}</p>,
                    <p>{x.qty_article} und</p>,
                   
                    <p onClick={stopParentClick}>
                    <div style={{ display: "flex",  }}>
                    {/* -------------------CHECK 1------------------------ */}
                    
                    {!!myPermission?.reject?
                    <div key={"articleSelectR"+index}
                        className={ `d-flex form-check-label  `}
                        style={{ flex: 2 , textAlignLast: "right"}}
                        
                    >
                        <input
                            className={ ` form-check-input `}
                            style={{"marginTop":".2em"}}
                            type="radio"
                            name={"authorization"+index}
                            //id="reject"
                            value="reject"
                            checked={isArticleSelectedR(x?.id_article)}
                            onChange={(e)=>setArticleSelected(x.article?.description, x?.qty_article, x?.id_article,e.target.value, x.id_quotation)}
                    
                        />
                    
                        <label
                            className={ ` form-check-label ${specialTableStyles.label_inputs_new3}`}
                            style={{marginLeft: "5px"}}
                            for="rejected"
                        >
                            Denegar&nbsp;
                        </label>
                  
                    </div>
                    :
                    <span></span>
                    }

                    {/* -------------------CHECK 2------------------------ */}
                    {!!myPermission?.approve?
                    <div key={"articleSelectA"+index}
                        className={ `d-flex form-check-label `}
                        style={{ flex: 2, }}
                        
                    
                    >
                        <input
                            className="form-check-input"
                            style={{"marginTop":".2em"}}
                            type="radio"
                            name={"authorization"+index}
                            //id="aprov"
                            value="aprov"
                            onChange={(e)=>setArticleSelected(x.article?.description,x.qty_article,x.id_article, e.target.value, index)}
                            checked={isArticleSelectedA(x?.id_article)}
                        />
                        <label
                            className={ ` form-check-label ${specialTableStyles.label_inputs_new3}`}
                            style={{marginLeft: "5px"}}
                            for="aprov"
                        >
                            Autorizar
                        </label>
                    </div>
                    :
                    <span></span>
                    } 
                    </div> 
                    </p>

                ];

                //headers are equal for all

                //body
                let bodyRows = [];
                //find providers of the current article to fill table body. gotta look into every article of every quotation
                
                //for each quotation
                counter.individualAuctionReducer.quotations.map(y => {
                    let gottenthing= null;

                    //for each quotation_article
                    if(Array.isArray(y.quotation_article_ref)){
                        const tempobj = y.quotation_article_ref.find(z => z.id_article === x.id_article)
                        if(!!tempobj && !!gottenthing===false){ 
                            gottenthing = {
                                ...tempobj,
                                provider_id: y.provider,
                                provider_name: y.provider_ref?.name,
                                quotation_total: y.total,
                                quotation_id:y.id,
                                pay_conditions: y.pay_conditions,
                            };
                        }
                    }
                    
                    //if there the current article of the auction is also inside the quotation, then...
                    if(!!gottenthing){

                        bodyRows.push(
                            <div key={"article"+index}>
                                <p>{gottenthing.provider_name}</p>
                                <p>{gottenthing.brand}</p>
                                <p>{formatter.format(gottenthing.unit_price)}</p>
                                <p>{gottenthing.qty_article}</p>
                                <p>{formatter.format(gottenthing.discount)}</p>
                                <p>{formatter.format(((gottenthing.unit_price*gottenthing.qty_article)+(gottenthing.iva_value)-gottenthing.discount))}</p>
                                <p><Checkbox 
                                    checked={gottenthing.id_quotation===x.id_quotation? true : isArticleProviderSelected(gottenthing.id_article, gottenthing.provider_id)}
                                    onChange={()=>setArticleProviderSelected(gottenthing.id_article, gottenthing.provider_id,gottenthing.provider_name,gottenthing.quotation_total,gottenthing.id_quotation,gottenthing.pay_conditions,gottenthing.qty_article, gottenthing.unit_price,gottenthing.iva_value, gottenthing.discount_value,  gottenthing.discount,gottenthing.iva )}

                                    name={"checkarticle"+y.provider+"-"+gottenthing.id_article} 
                                    color="primary"
                                    style={ {padding:0} }
                                /></p>
                            </div>
                        );
                    }
                })

                

                //footer is calculated by a separate "specialTableFooter" function cuz its too long otherwise


                //make special table with the 4 above sections and add them to full list
                fullList.push(<SpecialTable
                    theme={mytheme}
                    collapse = {collapseAll}
                    superheaders ={superColumns}
                    headers ={article_headers}
                    body    ={bodyRows}
                    footer  ={specialTableFooter(x)}
                />);
            }
            //only add those in filter

            }
             
            ///only req_autorization

            })

        }
        
        //return the entire thing
        return fullList;

    }//,[counter.individualAuctionReducer.selected_poauction, counter.individualAuctionReducer.quotations, collapseAll, articleSearch,triggerMemo]);

    
    const buttonSendDisabled=()=>{
        if ( (selectedList.length!==0 && (selectedListArticles.length!==0 || notselectedListArticles.length!==0)) && ((selectedList.length===selectedListArticles.length)||(selectedList.length===notselectedListArticles.length) || (selectedList.length===(selectedListArticles.length + notselectedListArticles.length )))){
            return false
        }else{
            return true
        }
        

    }

    //////////// MODAL PRODUCTS AUTHORIZATION  1 /////////////////////////////// 

    const sum = (list, provider_id) => {
        let subtotal=0
        let totaliva=0
        let totaldisc=0
        let total=0
        if (Array.isArray(list)) {            
            let SearchById= list.filter(x => x.provider_id === provider_id)            
            SearchById.forEach((x) => {
                subtotal+=x.subtotal
                totaliva+=x.iva_value
                totaldisc+=x.discount_value
            })
            return {subtotal: subtotal, totaliva:totaliva,totaldisc: totaldisc,total:total  } 
        }
    };

    const buttonOk = () => {

        let aprroveArticles = [...selectedListArticles];
        let providersSelected=[...selectedList];
        let rejectedArticles= [...notselectedListArticles];
        
        aprroveArticles.forEach((x,index) => {
            let coincidencia= providersSelected.find(y => y.article_id === x.id_article)
            if (!!coincidencia){
                aprroveArticles[index].subtotal = coincidencia.subtotal;
                aprroveArticles[index].iva_value= coincidencia.iva_value;
                aprroveArticles[index].discount_value= coincidencia.discount_value;
                aprroveArticles[index].unit_price= coincidencia.unit_price;
                aprroveArticles[index].provider_id= coincidencia.provider_id;
                aprroveArticles[index].discount= coincidencia.discount;
                aprroveArticles[index].provider_name= coincidencia.provider_name;
                aprroveArticles[index].iva= coincidencia.iva;
                aprroveArticles[index].auction_id= store_poauc?.id;
                aprroveArticles[index].quotation_id= coincidencia.id_quotation;
                setselectedListArticles(aprroveArticles);

            }  
        })

        rejectedArticles.forEach((x,index) => {
            let coincidencia= providersSelected.find(y => y.article_id === x.id_article)
            if (!!coincidencia){
                rejectedArticles[index].subtotal = coincidencia.subtotal;
                rejectedArticles[index].iva_value= coincidencia.iva_value;
                rejectedArticles[index].discount_value= coincidencia.discount_value;
                rejectedArticles[index].unit_price= coincidencia.unit_price;
                rejectedArticles[index].provider_id= coincidencia.provider_id;
                rejectedArticles[index].discount= coincidencia.discount;
                rejectedArticles[index].provider_name= coincidencia.provider_name;
                rejectedArticles[index].iva= coincidencia.iva;
                rejectedArticles[index].auction_id= store_poauc?.id;
                rejectedArticles[index].quotation_id= coincidencia.id_quotation;
                setnotselectedListArticles(rejectedArticles);

            }  
        })


        let newinfoToPurchaseOrder = [], ordersSaved=[] 
        if(selectedListArticles.length>0){       
            providersSelected.forEach((x) => {                 
                if (!ordersSaved.includes(x.provider_id) || ordersSaved.length === 0) {
                    //////////////// TOTAL SUM SUBTOTAL
                    let suma=sum(aprroveArticles,x.provider_id)
                    newinfoToPurchaseOrder.push({
                        provider_id: x.provider_id,
                        provider_name: x.provider_name,
                        id_quotation: x.id_quotation,
                        pay_conditions: x.pay_conditions,
                        sumsubtotal: suma.subtotal,
                        sumiva:suma.totaliva,
                        sumdiscount:suma.totaldisc,
                        sumtotal: suma.subtotal+suma.totaliva-suma.totaldisc
                    })
                    ordersSaved.push(x.provider_id)
                }                                
            })
        }
        setinfoToPurchaseOrder(newinfoToPurchaseOrder);
        

        setmodalPart1({
            ...modalPart1,
            totaltotal: 0,
            showModal1: true
        })
    };



    const [modalPart1, setmodalPart1] = useState({
        showModal1: false,
    });
    
    const [selectedListArticles, setselectedListArticles] = useState([]);
    const [notselectedListArticles, setnotselectedListArticles] = useState([]);


    const isArticleSelectedA = (id_article)=>{
        let found = selectedListArticles.find(x=>( (x.id_article === id_article)));
        return !!found;
    }
    
    const isArticleSelectedR = ( id_article)=>{
        let found = notselectedListArticles.find(x=>( (x.id_article ===id_article)));
        return !!found;
    }


    const setArticleSelected = (artitcle_description, article_qty, id_article, status, quotation) =>{

        if (status==="reject"){

            if(Array.isArray(selectedListArticles)){
                let temp = selectedListArticles;
                temp = temp.filter( (elem, index) => elem.id_article!== id_article);
                setselectedListArticles([...temp]);
            }

            let newSelectedListR = notselectedListArticles.filter((x) => ((x.id_article!==id_article)));

            newSelectedListR.push({
                article_description: artitcle_description,
                article_qty: article_qty,
                id_article: id_article,
                status:0,
                index: trigger,
                
                
            });
          
            setnotselectedListArticles([...newSelectedListR]);
        }
        if (status==="aprov"){
            if(Array.isArray(notselectedListArticles)){
                let temp = notselectedListArticles;
                temp = temp.filter( (elem, index) => elem.id_article!== id_article);
                setnotselectedListArticles([...temp]);
            }
            let newSelectedListA = selectedListArticles.filter(x => ((x.id_article!==id_article)));

            newSelectedListA.push({
                article_description: artitcle_description,
                article_qty: article_qty,
                id_article:id_article,
                status:1,
    
            });
            setselectedListArticles([...newSelectedListA]);
        }
        setTriggerMemo(triggerMemo+1);
    }


    
    //seccion "Productos serán autorizados" ____________________________________________________________________
    const renderHeadersAuthorization = [
            <th key={`artA1`}>Descripción</th>,
            <th key={`artA2`}>Proveedor</th>,
            <th key={`artA3`}>Cant.</th>,
            <th key={`artA4`}>Valor Total</th>,
       
    ]

    const ProviderName= (id)=>{
        let temp = selectedList.find(elem => elem.article_id===id);
        let nameP= temp?.provider_name;
        return nameP
    }
    const ProviderName1= (id)=>{
        let temp = selectedList.find(elem => elem.article_id===id);
        let nameP= temp?.provider_name;
        return nameP
    }

    const Total= (id)=>{
        let temp = selectedListArticles.find(elem => elem.id_article===id);
        let Total= (((temp?.unit_price*temp.article_qty)-temp?.iva_value)-temp?.discount_value);

        return Total
    }
    
    const Total1= (id)=>{
        let temp = notselectedListArticles.find(elem => elem.id_article===id);
        let Total= (((temp?.unit_price*temp.article_qty)-temp?.iva_value)-temp?.discount_value);
        return Total
    }

    const [renderAuthorization, totalProdAuthorization, totalQtyArticle] = useMemo(()=>{
        let table =[];
        let totalProdAuthorization = 0;
        let totalQtyArticle=0;
        if (Array.isArray(selectedListArticles)) {
            table = selectedListArticles.map( x => { 
                totalProdAuthorization += Total(x.id_article);
                totalQtyArticle+=x.article_qty;
                return <tr key={"ArticleAut"+x.id_article} className={`hover-table-row`}>
                    <td className={`col-md-1`}>
                        {x.article_description}
                    </td>
                    <td className={`col-md-1`}>
                        <p className="text-start">
                            {ProviderName(x.id_article)}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        {x.article_qty}
                    </td>
                    <td className={`col-md-1`}>
                        {formatter.format(Total(x.id_article))}
                    </td>
                </tr>
            });
            table.push(<tr key={"ArticleAut_fatality"} className={`hover-table-row`}>
                        <td className={`col-md-1`}> &nbsp;</td>
                        <td className={`col-md-1`}> &nbsp;</td>
                        <td className={`col-md-1`}>Total</td>
                        <td className={`col-md-1`}>{formatter.format(totalProdAuthorization)}</td>
            
                    </tr>)
          }
        
        return [table, totalProdAuthorization,totalQtyArticle];
    }, [selectedListArticles,ProviderName,Total]);

    
    //seccion "Productos serán denegados" _________________________________________________________________________
    const renderHeadersSubasta = [
        <th key={`artNA1`}>Descripción</th>,
        <th key={`artNA2`}>Proveedor</th>,
        <th key={`artNA3`}>Cant.</th>,
        <th key={`artNA4`}>Valor Total</th>,
    ]
    const [renderNotAthorization] = useMemo(()=>{
        let table =[];

        if (Array.isArray(notselectedListArticles)) {
            table = notselectedListArticles.map( (x, index) => {
                return <tr key={"ArticleNotAut"+x.id_article} className={`hover-table-row`}>
                    <td className={`col-md-1`}>
                        {x.article_description}
                        <p className={` ${detailStyles.textJustify} text-center`}  onClick={()=>setmodalJustification({showModalJ:true , index: index})} >
                            Justificación
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        <p className="text-start">
                            {ProviderName1(x.id_article)}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        {x.article_qty}
                    </td>
                    <td className={`col-md-1`}>
                        {formatter.format(Total1(x.id_article))}
                    </td>
                </tr>
            });
          }

        return [table];
    }, [notselectedListArticles, ProviderName1,Total1 ]);

    const btnNext = () => {
        if(modalPart2.showModal2){
            dispatch(  
                creat_purchaseOrder( 
                    {
                        ...PurcharseOrder,
                        account_id:counter.loginReducer.currentAccount.id,
                        user_created: counter.loginReducer.user_data.id,
                        bussiness_name: counter.loginReducer.currentAccount.bussines_name,
                        infoPO: infoToPurchaseOrder,
                        auction_title: store_poauc?.description,
                        auction_code: store_poauc?.auction_number,
                        id_warehouse:store_poauc?.id_warehouse,
                        auction_id:store_poauc?.id,
                        rejected_articles: notselectedListArticles,
                        authorised_articles: selectedListArticles,
                        user_email: counter.userReducer.user_data.email,
                    },
                    null, 
                    ()=> props.setDetails({show:false})
                ), 
            );
            
        }else{
            setmodalPart2({showModal2:true});
        }

    }
    //////////// MODAL JUSTIFICATION  /////////////////////////////// 

    const [modalJustification, setmodalJustification] = useState({
        showModalJ: false,
        index:"",
    });

    const btnJustification = (index, val) =>{
        let addJustify = [...notselectedListArticles];
        addJustify[index].justify = val;
        setnotselectedListArticles(addJustify);
        setmodalJustification({showModalJ:false})
    }

    const btnCancelJustify = (index) =>{
        let addJustify = [...notselectedListArticles];
        addJustify[index].justify = "";
        setnotselectedListArticles(addJustify);
        setmodalJustification({showModalJ:false})

    }


    //////////// MODAL PRODUCTS AUTHORIZATION  2 /////////////////////////////// 

    const [modalPart2, setmodalPart2] = useState({
        showModal2: false,
      });


    const btnCancel = () =>{
        if(modalPart2.showModal2){
            setmodalPart2({showModal2:false});
        }else{
            setmodalPart1({showModal1:false})
        }
    }


    const numberWithCommas = (x)=> {  
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }

    const btnDisabled = ()=> {
        if (modalPart2.showModal2){
            let count=0;
            infoToPurchaseOrder.map( (x, index)=>{
                if(!!x.date===false){
                    count=count+1
                }                
            })
            if(count>0){
                return true
            }else{ return false}

        }else{
            return false
        }
    }

    
    

    //// DATA TO CREAT PURCHASE ORDER
    const [PurcharseOrder, setPurcharseOrder] = useState({
        auction_title:"",
        auction_code:"",
        
    });
    
    const handleProviderDateChange = (index, val) =>{
        let addDate = [...infoToPurchaseOrder];
        addDate[index].date = val;
        setinfoToPurchaseOrder(addDate);
    }
    // const [justify, setjustify] = useState("");

    

    const listProviders = () => {

        let result =[];

        if(Array.isArray(infoToPurchaseOrder)){
            result = infoToPurchaseOrder.map( (x, index)=>{
                
                return <div className= {`${detailStyles.marginSection2} d-flex `}>
                    <div  className={`${detailStyles.f0}`}>
                        <br />
                        <input
                            className={`${detailStyles.registerInputsClear}`}
                            name="requisition_date"
                            type="text"
                            value={index +1 }
                            readOnly
                        />
                    </div>

                    <div className={` ${detailStyles.alingLine}`}>
                        <br />
                        <div className={`${detailStyles.line}`}> </div>   
                    </div>  
                
                    <div  className={`${detailStyles.f2}`}>
                        <p className={detailStyles.crudModalLabelGrey}>Proveedor</p>
                        <input
                            className={`${detailStyles.registerInputsClear}`}
                            name="proveedor"
                            type="text"
                            value={x.provider_name}
                            readOnly
                        />
                            
                    </div>
                    <div className={` ${detailStyles.alingLine}`}>
                        <br />
                        <div className={`${detailStyles.line}`}> </div>   
                    </div>  
                
                    <div  className={`${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabelGrey}>Fecha de entrega</p>
                        <input
                            className={`${detailStyles.crudModalInputDate} ${detailStyles.registerInputsBlue} `}
                            name="date_delivery"
                            type="date"
                            value={x.date}
                            onChange={(e) => handleProviderDateChange(index ,e.target.value)}
                        />
                            
                    </div>

                    {x.date?

                        <div  className={`${detailStyles.marginLeft} ${detailStyles.filter}`}>
                            <br/>
                            <img
                                src={RoundCheck}
                                alt="check"
                            />      
                        </div>
                        
                        
                    :
                        <div  className={`${detailStyles.marginLeft}`}>
                            <br/>
                            <img
                                src={RoundCheck}
                                alt="check"
                            />      
                        </div>

                    }
                  
                  
                </div>
            });

            
        }

        return result;
        
    }

    //////////////////  INFORMATION RELATED TO RESUMEN PURCHASE ORDER /////////////////////////////
    const summary_purchaseOrder= counter.purchaseOrderReducer.summary_PO;

    const renderHeadersSummary = [
        <th className="text-center" key={`indexSum1`}>Item</th>,
        <th className="text-center"  key={`indexSum2`}>Descripción de Artículo</th>,
        <th className="text-center"  key={`indexSum3`}>Cant.</th>,
        <th className="text-center"  key={`indexSum5`}>Valor Unidad</th>,
        <th className="text-center"  key={`indexSum6`}>Descuento <br />
            <div className="d-flex justify-content-around">
                <span>%</span>
                <span>$</span> 
            </div>
        </th>,
        <th className="text-center"  key={`indexSum7`}>Iva <br />
            <div className="d-flex justify-content-around">
                <span>%</span>
                <span>$</span>
            </div>
        </th>,
        <th className="text-center"  key={`indexSum8`}>Total</th>,
    ]
    const  [renderSummary, subtotal,iva, disc] = useMemo(()=>{
        let table =[];
        let subtotal=0;
        let iva=0;
        let disc=0;

        if (Array.isArray(summary_purchaseOrder.purchaseOrderItems)) {
            table = summary_purchaseOrder.purchaseOrderItems.map( (x, index) => {
                subtotal +=  x.unit_price*x.qty_article;
                iva +=  x.iva_value;
                disc += x.discount_value;
                return <tr key={"summary"+index} className={`hover-table-row`}>
                    <td className={`col-md-1`}>
                        <p className="text-center">
                            {index +1}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        <p className=" text-start">
                            {x.article_ref.description}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        <p className="text-center">
                            {x.qty_article}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        <p className="text-center">
                            {"$"+ numberWithCommas(x.unit_price)}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        <div className="d-flex justify-content-around align-items-center">
                            <p className="text-center">
                                {x.discount}
                            </p>
                            <p className="text-center">
                                {"$" +numberWithCommas(x.discount_value)}
                            </p>
                        </div>
                    </td>
                    <td className={`col-md-1`}>
                        <div className="d-flex justify-content-around align-items-center">
                            <p className="text-center">
                                {x.iva}
                            </p>
                            <p className="text-center">
                                {"$" +numberWithCommas(x.iva_value)}
                            </p>
                        </div>
                    </td>
                    <td className={`col-md-1`}>
                        <p className="text-center">
                            {"$" + numberWithCommas(x.unit_price*x.qty_article)}
                        </p>
                    </td>
                </tr>
            });
        }

        return [table, subtotal, iva, disc]
    },[summary_purchaseOrder.purchaseOrderItems]);


    const renderTableFooter=(tfoot) => {
        return tfoot;
    };

    let renderFooter = [
        <tr>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className={`${detailStyles.textFooter}`}>Subtotal</td>
          <td className={`${detailStyles.textFooter} text-center flex-wrap `}> { "$" + numberWithCommas(subtotal)}</td>
        </tr>,
        <tr>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className={`${detailStyles.textFooter}`}>IVA</td>
          <td className={`${detailStyles.textFooter} text-center`}> {"$" + numberWithCommas(iva)}</td>
        </tr>,
        <tr>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className={`${detailStyles.textFooter}`}>DESCUENTO</td>
          <td className={`${detailStyles.textFooter} text-center`}> { "$" + numberWithCommas(disc)}</td>
        </tr>,
        <tr>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className={`${detailStyles.textFooter}`}>TOTAL</td>
          <td className={`${detailStyles.textFooter} text-center`}> { "$" + numberWithCommas((subtotal+iva-disc))}</td>
        </tr>,
      ].map((item) => {
        return renderTableFooter(item);
    });

    const btnCancelSummary =() => {
        setPurcharseOrder({
            auction_title:"",
            auction_code:"",
        })
        dispatch({
            type: PURCHASEORDER_MODAL3,
            payload:false
        })
        props.setDetails({show:false})
        

    };
    const setjustify =(justify, index)=>{
        let addJustify = [...notselectedListArticles];
        addJustify[index].justify = justify;
        setnotselectedListArticles(addJustify);
    }

    //render ___________________________________________________________________________________________
 

  return (
    <div className={tableStyles.container}>

        { !!counter.individualAuctionReducer.selected_poauction_loading && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        ) }
        {!!counter.purchaseOrderReducer.loadingAuth && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        ) }

        <div className={`d-flex ${detailStyles.titleBlock}`}>
            <img 
                src={Back} 
                alt=""
                className={detailStyles.backIcon} 
                style={{height: "30px"}}
                onClick={()=>props.setDetails({show:false})}
            />
            <h1 className={detailStyles.mainTitle}>
                {!!store_poauc?.description? store_poauc.description : "Subasta"}
            </h1>
        </div>

        <div className={` ${detailStyles.bigContainer}`}>

            <div className={`${detailStyles.container2}`}>
                

                <div className={`d-flex`}>
                    <div className={`d-flex ${detailStyles.underMainTitle} ${detailStyles.f1}`}>
                        <p> 
                            <span className={detailStyles.blueText}>#{store_poauc.auction_number}</span> 
                            &ensp;&ensp;
                        </p>
                    </div>
                </div>
                
            </div>

            <p className={detailStyles.sectionTitle}>Información general</p>
            <div className={detailStyles.container2}>
                {/* row 1 */}
                <div className={`d-flex`}>
                    
                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Fecha de vencimiento</p>
                        <input
                            className={` ${detailStyles.crudModalInputDate} ${detailStyles.registerInputs}`}
                            type="date"
                            value={store_poauc?.date_expiry}
                            readOnly
                            
                        />
                    </div>

                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Forma de pago</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={payConditions(store_poauc?.pay_conditions)}
                            readOnly
                        />
                    </div>

                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Autor de la subasta</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={!!store_poauc.user? store_poauc.user.first_name+" "+store_poauc.user.first_surname: "-"}
                            readOnly
                        />
                    </div>
                    <div  className={`${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Autor de ordenes de compra</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={!!store_poauc.userPO? NamePerson (store_poauc.userPO):  "-"}
                            readOnly
                        />
                    </div>
                </div>


                {/* row 2 */}
                <div className={`d-flex`}>

                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Fecha de creación de OCs</p>
                        <input
                            className={` ${detailStyles.crudModalInputDate} ${detailStyles.registerInputs}`}
                            type="date"
                            value={(store_poauc?.created_atPurchaseO?.split(" ")[0])}
                            readOnly
                            
                        />
                    </div>
                    
                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Tipo de subasta</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={priority(store_poauc.priority)}
                            readOnly
                        />
                    </div>

                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Dirección de entrega</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={store_poauc.warehouse_ref?.address}
                            readOnly
                        />
                    </div>

                    <div  className={`${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Terminos y condiciones</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={store_poauc?.terms}
                            readOnly
                        />
                    </div>
                </div>

                {/* row 3 */}
                <div className={`d-flex `}>
                    <div  className={`${detailStyles.Item} ${detailStyles.f2}`}>
                        <p className={detailStyles.crudModalLabel}>Observaciones</p>
                        <textarea 
                            id="txtArea" 
                            rows="10" 
                            cols="10" 
                            className={detailStyles.textArea}
                            value={store_poauc?.observations}
                            readOnly
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className={detailStyles.sectionContainer}>
                <p className={detailStyles.sectionTitle}>{ballthingyTooltipName(counter.individualAuctionReducer.selected_poauction?.article_list)} ({counter.individualAuctionReducer.selected_poauction?.article_list?.length})</p>
                
                <div className={`d-flex flex-row-reverse`}>

                <div
                    className={`${detailStyles.articleControlIconNew} ${detailStyles.marginLeft}`} 
                    onClick={()=>setCollapseAll(!collapseAll)} 
                >   

                    <img 
                    src={auctionDetailExpand} 
                    className={` ${detailStyles.toggleAll} ${collapseAll? detailStyles.rotate : ""}`}
                    alt="Toggle All" 

                    />
                </div>  
                   
                    

                <div 
                    className={`
                        ${detailStyles.articleControlIcon} 
                        ${(articleSearch!=="")? detailStyles.expand : ""} 
                    `}
                >
                    <input 
                        type="text"
                        className={`
                            ${detailStyles.articleSearch} 
                            ${(articleSearch!=="")? detailStyles.expand : ""} 
                        `}
                        onChange={e=> setarticleSearch(e.target.value)}
                    ></input>
                </div>

                </div> 
                <table className={`table mb-0`}>
                    <thead className="d-flex">
                    <th className={detailStyles.flex3} ><span>&nbsp;</span> </th>
                    <th className={`${detailStyles.flex1} ${detailStyles.center} ${detailStyles.namesColum} ${detailStyles.marginTableCol}`}>Código </th>
                    <th className={`${detailStyles.flex1} ${detailStyles.center} ${detailStyles.namesColum} ${detailStyles.marginTableCol}`}>Cantidad </th>
                    <th className={detailStyles.flex1} ><span>&nbsp;</span></th>
            
                </thead>
                </table>

                

                {listArticles()}
            </div>
            
        </div>
        {ballthingyTooltipName(counter.individualAuctionReducer.selected_poauction?.article_list)==="Lista de productos"?
        <div className={detailStyles.bottomRow}>

                    {(!!myPermission?.approve || !!myPermission?.reject) ? 
                    <Button 
                        className={tableStyles.btnPrimary} 
                        onClick={()=>buttonOk()}
                        disabled={buttonSendDisabled()}
                    >
                    <img src={Send} alt="enviar" /> <span></span> Enviar
                    </Button>
                    :
                    <span></span>
                    }
                    <Button 
                        className={tableStyles.btnSecondary}  
                        onClick={()=>buttonCancel()}
                        
                    >
                        Cancelar
                    </Button>
        </div>
        :
        <span></span>
        } 
        
        {/* MODAL AUTORIZACIÓN DE PRODUCTOS*/}

        <ModalNew
            title="Autorización de Productos"
            subtitle= {modalPart2.showModal2? "Paso 2 de 2 - Fechas de entrega": "Paso 1 de 2 - Resumen"}
            show={modalPart1.showModal1}
            btnYesName= {"Continuar"}
            btnNoName= {modalPart2.showModal2? "Atrás": "Cancelar"}
            size="lg"
            btnYesEvent={()=>btnNext()}
            btnYesDisabled={btnDisabled()}
            onHide={()=>setmodalPart1({showModal1:false})}
            btnNoEvent={()=>btnCancel()}
            btnNoDisabled={false}
            hideCancelButton={false}
        >

            {modalPart2.showModal2?
            
            <div className={detailStyles.containerNew2}>

                <div className="d-flex">
                        <div  className={`${detailStyles.inputMargin} `}>
                            <p className={tableStyles.crudModalLabel}>Total de compra</p>
                            <input
                                className={`${detailStyles.registerInputsClear}`}
                                name="requisition_date"
                                type="text"
                                value={"$"+ numberWithCommas(totalProdAuthorization)}
                                readOnly
                                />
                        </div>
                        <div  className={`${detailStyles.inputMargin} `}>
                            <p className={tableStyles.crudModalLabel}>Número de artículos</p>
                            <input
                                className={`${detailStyles.registerInputsClear}`}
                                name="requisition_date"
                                type="text"
                                value={totalQtyArticle}
                                readOnly
                                />
                            
                        </div>
                        
                </div>
        
                <div className= {`${detailStyles.marginSection} d-flex `}>
                    <img
                        src={date}
                        alt="date"
                    />
                    <p className={` ${detailStyles.textPart2}`}>
                    Seleccione las fechas de entrega de las ordenes de compra a generar
                    </p>
                </div>
                {/* ARRAY DE PROOVEDORES*/}

                {listProviders()}
     
            </div>
            
            :
            <div className={`${detailStyles.containerNew2}`}>
                
            
                {/* Productos Autorizados*/}
                <div className="d-flex">
                    <img
                        src={checkboxTrue}
                        alt="check"
                        className={detailStyles.adjustIconPart2}
                    />
                    <p className={` ${detailStyles.textPart2}`}>
                        Los siguientes productos serán Autorizados
                    </p>
                </div>

                <GenericTableNew headers={renderHeadersAuthorization}>
                  {renderAuthorization}
                </GenericTableNew>

                {/* <table className={`table mb-0`}>
                    <thead className="d-flex">
                        <th className={detailStyles.flex1} ><span>&nbsp;</span> </th>
                        <th className={detailStyles.flex1} ><span>&nbsp;</span> </th>
                        <th className={`${detailStyles.flex1} ${detailStyles.center} ${detailStyles.textTotal} ${detailStyles.marginTableCol}`}>Total</th>
                        <th className={`${detailStyles.flex1} ${detailStyles.center} ${detailStyles.textTotal} ${detailStyles.marginTableCol}`}>{formatter.format(totalProdAuthorization)}</th>
            
                    </thead>
                </table> */}
                

                
                {/* Productos no autorizados */}
                <div className="d-flex">
                    <img
                        src={False}
                        alt="false"
                        className={detailStyles.adjustIconPart2}
                    />
                    <p className={` ${detailStyles.textPart2} ${detailStyles.textPart2Orange}`}>
                        Los siguientes artículos serán denegados
                    </p>
                </div>
                <GenericTableNew headers={renderHeadersSubasta}>
                    {renderNotAthorization}
                </GenericTableNew>

                <div className="d-flex">
                    <p className={` ${detailStyles.textPartFinal}`}>
                            *Las respectivas justificaciones serán enviadas al correo del autor de la Orden de compra
                    </p>
                </div>
            </div>
            
            }
            
        </ModalNew>
        
        {/* MODAL JUSTIFICACIÓN DE DENEGACIÓN*/}
        <ModalNew
            title="Denegación"
            show={modalJustification.showModalJ}
            btnYesName= {"Guardar"}
            btnNoName= {"Cancelar"}
            size="400"
            btnYesEvent={()=>btnJustification(modalJustification.index,notselectedListArticles[modalJustification.index]?.justify)}
            btnYesDisabled={false}
            onHide={()=>btnCancelJustify(modalJustification.index)}
            btnNoEvent={()=>btnCancelJustify(modalJustification.index)}
            btnNoDisabled={false}
            

        >
            <textarea
              className={`${detailStyles.inputsTextArea}`}
              name="justification"
              type="text"
              value={notselectedListArticles[modalJustification.index]?.justify}
              onChange={
                  (e)=>setjustify(e.target.value,modalJustification.index)
                }
            ></textarea>


        </ModalNew> 
        
        {/* MODAL RESUMEN DE ORDENES DE COMPRA*/}

        <ModalNew

            title={counter.purchaseOrderReducer.codes?.length? "Orden de compra " + counter.purchaseOrderReducer?.codes[PurchaseOrderIndex]:""}
            subtitle={ (PurchaseOrderIndex+1) +" de " + counter.purchaseOrderReducer.codes?.length}
            show={counter.purchaseOrderReducer.modalPart3}
            btnNoName= {"Aceptar"}
            btnNoEvent={()=>btnCancelSummary()}
            size="940"
            onHide={()=>
                dispatch({
                type: PURCHASEORDER_MODAL3,
                payload:false
            })
            }
            leftArrowEvent={()=>setPurchaseOrderIndex( clamp(PurchaseOrderIndex-1, 0, counter.purchaseOrderReducer.codes.length-1) )}
            rightArrowEvent={()=>setPurchaseOrderIndex( clamp(PurchaseOrderIndex+1, 0,  counter.purchaseOrderReducer.codes.length-1) )}
        >
            { !!counter.purchaseOrderReducer.summary_PO_loading? (
                <div className={`${detailStyles.loading}`}>
                    <Loader type="Oval" color="#003f80" height={100} width={100} />
                </div>
            )
            :null}
            <div className={detailStyles.container2}>
                {/* row 1 */}
                
                <div className={`d-flex`}>
                    
                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f15}`}>
                        <p className={detailStyles.crudModalLabel}>Proveedor</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={summary_purchaseOrder.provider_ref?.name}
                            readOnly 
                        />
                    </div>

                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>NIT</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={summary_purchaseOrder.provider_ref?.nit}
                            readOnly
                        />
                    </div>

                    <div  className={`${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Condiciones de pago</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={payConditions(summary_purchaseOrder?.pay_conditions)}
                            readOnly
                        />
                    </div>
                </div>


                {/* row 2 */}
                <div className={`d-flex`}>

                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f15}`}>
                        <p className={detailStyles.crudModalLabel}>Términos y condiciones</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={summary_purchaseOrder?.observations}
                            readOnly
                            
                        />
                    </div>

                    <div  className={`${detailStyles.inputMargin} ${detailStyles.Item} ${detailStyles.f1}`}>
                        <p className={detailStyles.crudModalLabel}>Dirección de entrega</p>
                        <input
                            className={`${detailStyles.registerInputs}`}
                            type="text"
                            value={summary_purchaseOrder.warehouse_ref?.address}
                            readOnly
                        />
                    </div>

                    <div  className={`${detailStyles.ItemCheckbox} ${detailStyles.f1}`}>
                        <div className={detailStyles.myCheckbox}>
                            <input 
                                key={"delivery"+Math.random()}
                                className={detailStyles.myCheckboxInput} 
                                type="checkbox" 
                                name="delivery" 
                                value="delivery" 
                                checked={summary_purchaseOrder?.partial_deliveries}
                            />
                            <label className={`${detailStyles.myCheckboxLabel} ${detailStyles.delivery}`} htmlFor="delivery">Contiene entregas parciales</label>
                        </div>
                    </div>
                </div>
            </div>
            <GenericTableNew headers={renderHeadersSummary}  dark={false} lowerCase={true} footer={renderFooter} >
                {renderSummary} 
            </GenericTableNew>
            
                <div className={detailStyles.containerObservations}>
                    <div className="d-flex w-100">
                        <div className= {`${detailStyles.observationsColor} ${detailStyles.sizeObservations}  d-flex flex-column`}>
                            <label htmlFor="observations" className={`${detailStyles.labelFont} ${detailStyles.marginLeftObservations}`}>
                                Observaciones
                            </label>
                            <textarea
                                name="observations"
                                className={`${detailStyles.observationsArea} ${detailStyles.marginLeftObservations}`}
                                value={summary_purchaseOrder?.observations}
                                disabled={true}
                            ></textarea>
                        </div>
                    </div>
                </div>
            
        </ModalNew> 
        

         



        









    </div>
    
        
    );
}

export default DetailPurchaseOrder ;