import React, { useEffect, useRef, useState } from 'react'
import { Row, Form, Col, Card } from 'react-bootstrap';
import { useHistory } from "react-router";
import tableStyles from '../components/Layouts/tableStyle.module.scss'
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { OrdBiTable } from '../OrderingModule/OrderingComponents/OrdBiTable';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles, TextField } from '@material-ui/core';
import ordOrgangeBook from '../assets/img/icons/ordOrgangeBook.svg'
import OrdLupa from '../assets/img/icons/OrdLupa.svg'
import blueBook from '../assets/img/icons/blueBook.svg'
import ordClearBlueCheck from '../assets/img/icons/ordClearBlueCheck.svg'
import ordClearBlueX from '../assets/img/icons/ordClearBlueX.svg'
import ordBlueSearch from '../assets/img/icons/ordBlueSearch.svg'
import TransferList from '../OrderingModule/OrderingComponents/OrdTransferList';
import { customSelectMulti } from '../components/Layouts/react-select-custom';
import makeAnimated from "react-select/animated";
import Select from "react-select";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { expRegList, formatToRcSelect, loader, validExpReg, validateEmptyString, message, isEmptyOrUndefined, capitalizeWord, swalConfirm } from '../helpers/helpers';
import { useGetMethod, useMultiGetMethod, usePostMethod } from '../Hooks/useFetch';
import { useSelector } from 'react-redux';
import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable';
export const NewMedicine = ({ isEditing, isCloning, medicineData, onHide, update, setBackSelected }) => {
    const history = useHistory();
    const store = useSelector((state) => state);
    const idEnterprise = store.loginReducer.currentAccount.id;
    const [trigger, setTrigger] = useState(0)
    const [triggerArticles, setTriggerArticles] = useState(0)
    const [filters, setFilters] = useState({
        id_account: idEnterprise,
        page: 1,
        perpage: 10,
        search: ""
    })
    const ref = useRef()
    const useStyles = makeStyles({
        input: {
            color: "#6e6f7c"
        }
    });
    useEffect(() => {
        if (isEditing) {
            medicineData = { ...medicineData, concentrationCode: medicineData?.mipresConcetrationCode }
        }
    }, [])

    const [currentDiagnostic, setCurrentDiagnostic] = useState(isEditing ? medicineData?.diagnostics : null)
    const [medicineRelation, setMedicineRelation] = useState({
        showModal: false,
        headers: [
            {
                title: "ㅤCódigo",
                className: 'pl-1'
            },
            {
                title: "Descripción",
            },
            {
                title:
                    <>
                        &nbsp;
                        &nbsp;
                    </>
                ,
            },
        ]
    })
    const classes = useStyles();
    const token = store.loginReducer.Authorization;
    const animatedComponents = makeAnimated();
    const [data, setData] = useState({
        id: "",
        cum: "",
        verificationCode: "",
        chemicalSubstance: "",
        tradeName: "",
        pharmaceuticalForm: "",
        pharmaGroup: "",
        pharmaSubgroup: "",
        volum: "",
        idMeasUnit: "",
        idPresentation: "",
        concentration: "",
        organicSystem: "",
        invima: "",
        idOrderUnit: "",
        indications: "",
        diagnostics: [],
        mipresActiveCode: "",
        mipresCode: "",
        concentrationCode: "",
        idMeadUnit: "",
        qtyPerMeasUnit: "",
        mipresPharmaMeasUnit: {},
        alternatives: [],
    })
    const [contractInfo, setContractInfo] = useState({})

    const [contractList, setContractList] = useState([])
    const [selectedActives, setSelectedActives] = useState([])
    const [transferTrigger, setTransferTrigger] = useState(0)
    /* ----------------------------- FETCHS SECTION ----------------------------- */
    /* ----------------------------- GET ----------------------------- */
    const { results: unities, load: unitiesLoader, trigger: getUnities } = useMultiGetMethod()
    const { results: contractsById, load: contractsByIdLoader, trigger: getContractsById } = useGetMethod()
    const { results: articlesList, load: articlesListLoader, trigger: getArticlesList } = useGetMethod()
    /* ---------------------------------- POST /PUT ---------------------------------- */
    const { load: createMedicineLoader, trigger: createMedicine } = usePostMethod()
    /* ------------------------------------ - ----------------------------------- */
    useEffect(() => {
        getUnities({
            multipleGet: [
                {
                    url: "/medical/medicine/getPharmaUnit/",
                    objFilters: { type: "Unidad de Medida" },
                    requestName: "recipeUnitList",
                },
                {
                    url: "/medical/medicine/getPharmaUnit/",
                    objFilters: { type: "Presentación" },
                    requestName: "presentationList",
                },
                {
                    url: "/medical/medicine/getPharmaUnit/",
                    objFilters: { type: "Unidad de receta" },
                    requestName: "measurmentUnitList",
                },
                {
                    url: "/medical/cie10diagnostics",
                    objFilters: { slim: 1, enabled: 1 },
                    requestName: "diagnostics",
                },
                {
                    url: "/coporateClients/",
                    objFilters: { id_account: idEnterprise },
                    requestName: "companies",
                    doAfterSuccess: (res) => {
                        setCompaniesToRender(res?.results)
                    }
                },
                {
                    url: '/medical/medicine/',
                    objFilters: { idAccount: idEnterprise, slim: 1, status: "enabled" },
                    token: token,
                    requestName: "medicinesList",
                },
            ],
            token: token,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUnities, idEnterprise, token, trigger])

    useEffect(() => {
        getArticlesList({
            url: '/inventory/article',
            objFilters: filters,
            token: token,
            requestName: "articlesList",
        })
        if (isEditing) {
            setData(medicineData)
            setCurrentAlternatives(formatToRcSelect(medicineData?.alternatives,
                "id",
                "name",
                "",
                "",
                "",
                false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerArticles])

    const formattedPresentations = formatToRcSelect(unities?.presentationList?.results, "id", "label", "", "", "", false)
    const formattedMeasurmentUnit = formatToRcSelect(unities?.recipeUnitList?.results, "id", "label", "", "", "", false)
    const formattedDiagnostics = formatToRcSelect(unities?.diagnostics?.results, "codigo", "codigo", "", "", "", false)
    const formattedMedicines = formatToRcSelect(unities?.medicinesList?.results, "id", "name", "", "", "code", false)
    const formattedRecipes = formatToRcSelect(unities?.measurmentUnitList?.results, "id", "description", "", "", "", false)
    let subTitleSize = isEditing ? 9 : 6;

    const biTableData = [
        {
            title: <>
                <Row className="mb-4"
                    style={{ marginLeft: '0.1rem' }}
                >
                    <Col className="m-0 p-0 ml-5" xs={6}>
                        <TextField
                            id="standard-basic"
                            className="text-secondary w-100"
                            variant="standard" inputProps={{ className: classes.input }}
                            label={<>
                                <b>
                                    CUM <span className="text-danger" >*</span>
                                </b></>}
                            type="number"
                            value={data.cum}
                            onChange={(e) => setData({ ...data, cum: e.target.value })}
                        />
                    </Col>
                    <Col className="pt-4" xs={1}>
                        "
                    </Col>
                    <Col className="m-0 p-0" xs={3}>
                        <TextField
                            id="s2" className="text-secondary w-100" variant="standard"
                            label="ㅤㅤ"
                            // type={"number"}
                            maxLength={2}
                            value={data.verificationCode}
                            inputProps={{ className: classes.input }}
                            onChange={(e) => {
                                if (
                                    validExpReg(e.target.value, expRegList.number) ||
                                    e.target.value === ""
                                ) {
                                    if (e.target.value.length > 2 || e.target.value < 0) {
                                        return
                                    } else {
                                        setData({ ...data, verificationCode: e.target.value })
                                    }
                                }
                            }}
                        />
                    </Col>
                </Row>
            </>,
            subTitle: <>
                <TextField id="standard-basic" className="text-secondary w-100" variant="standard"
                    label={<>
                        <b>
                            Sustancia química <span className="text-danger" >*</span>
                        </b></>}
                    inputProps={{ className: classes.input }}
                    value={capitalizeWord(data?.chemicalSubstance)}
                    onChange={(e) => setData({ ...data, chemicalSubstance: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0`,
        },
        {
            title: <p className="pt-2 m-0 ">Nombre comercial
                {/* <span className="text-danger" >*</span> */}
            </p>,
            subTitle: <>
                <TextField id="standard-basic" className={`w-100 ${tableStyles.darkBlueText}`} variant="standard"
                    value={capitalizeWord(data.tradeName)}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setData({ ...data, tradeName: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0`,
        },

        {
            title: <p className="pt-2 m-0">Forma farmacéutica<span className="text-danger" >*</span></p>,
            subTitle: <>
                <TextField id="standard-basic" className=" w-100" variant="standard"
                    value={data.pharmaceuticalForm}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setData({ ...data, pharmaceuticalForm: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0`,
        },
        {
            title: <p className="pt-2 m-0">Grupo farmacológico<span className="text-danger" >*</span></p>,
            subTitle: <>
                <TextField id="standard-basic" className=" w-100" variant="standard"
                    inputProps={{ className: classes.input }}
                    value={data.pharmaGroup}
                    onChange={(e) => setData({ ...data, pharmaGroup: e.target.value })} />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0`,
        },
        {
            title: <p className="pt-2 m-0">Subgrupo fármaco
                {/* <span className="text-danger" >*</span> */}
            </p>,
            subTitle: <>
                <TextField id="subfarm" className={`w-100 ${tableStyles.darkBlueText}`} variant="standard"
                    value={data.pharmaSubgroup}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setData({ ...data, pharmaSubgroup: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
        },
        {
            title: <p className="pt-2 m-0">Volumen<span className="text-danger" >*</span></p>,
            subTitle: <>
                <TextField id="volum" className={`w-100 ${tableStyles.darkBlueText}`} variant="standard"
                    type="number"
                    value={data.qtyPerPresentation}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setData({ ...data, qtyPerPresentation: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0 `,
            rowClassName: "mt-4"
        },
        {
            title: <p className="pt-2 m-0">Unidad<span className="text-danger" >*</span></p>,
            subTitle: <>
                <Autocomplete
                    sx={{
                        input: {
                            color: "#6e6f7c"
                        },
                    }}
                    value={!!medicineData?.unitLabel ? medicineData?.unitLabel : data?.idMeasUnit}
                    onChange={(event, newValue) => {
                        setData({ ...data, idMeasUnit: newValue })
                    }}
                    id="controllable-states-demo"
                    options={formattedMeasurmentUnit}
                    renderInput={(params) => <TextField {...params} label="" />}
                /></>,
            titleSize: 3,
            subTitleSize: subTitleSize,
        },
        {
            title: <p className="pt-2 m-0">Presentación<span className="text-danger" >*</span></p>,
            subTitle: <>
                <Autocomplete
                    sx={{
                        input: {
                            color: "#6e6f7c"
                        },
                    }}
                    value={!!medicineData?.presentationLabel ? medicineData?.presentationLabel : data?.idPresentation?.label}
                    onChange={(event, newValue) => {
                        setData({ ...data, idPresentation: newValue })
                    }}
                    id="controllable-states-demo"
                    options={formattedPresentations}
                    renderInput={(params) => <TextField {...params} label="" />}
                /></>,
            titleSize: 3,
            subTitleSize: subTitleSize,
        },
        {
            title: <p className="pt-2 m-0">Unidad de receta<span className="text-danger" >*</span></p>,
            subTitle: <>
                <Autocomplete
                    sx={{
                        input: {
                            color: "#6e6f7c"
                        },
                    }}
                    value={!!medicineData?.orderUnitLabel ? medicineData?.orderUnitLabel : data?.idOrderUnit}
                    onChange={(event, newValue) => {
                        setData({ ...data, idOrderUnit: newValue })
                    }}
                    id="controllable-states-demo"
                    options={formattedRecipes}
                    renderInput={(params) => <TextField {...params} label="" />}
                /></>,
            titleSize: 3,
            subTitleSize: subTitleSize,
        },
        {
            title: <p className="pt-2 m-0">Cantidad de la unidad de receta<span className="text-danger" >*</span></p>,
            subTitle: <>
                <TextField id="volum" className={`w-100 ${tableStyles.darkBlueText}`} variant="standard"
                    type="number"
                    value={data.qtyPerMeasUnit}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setData({ ...data, qtyPerMeasUnit: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
        },
        {
            title: <p className="pt-2 m-0">Concentración<span className="text-danger" >*</span></p>,
            subTitle: <>
                <TextField id="volum" className={`w-100 ${tableStyles.darkBlueText}`} variant="standard"
                    value={data.concentration}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setData({ ...data, concentration: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
        },
        {
            title: <p className="pt-2 m-0">Sistema orgánico
                {/* <span className="text-danger" >*</span> */}
            </p>,
            subTitle: <>
                <TextField id="volum" className={`w-100 ${tableStyles.darkBlueText}`} variant="standard"
                    value={data.organicSystem}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setData({ ...data, organicSystem: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0 `,
            rowClassName: "mt-4"
        },
        {
            title: <p className="pt-2 m-0">INVIMA</p>,
            subTitle: <>
                <TextField id="volum" className={`w-100 ${tableStyles.darkBlueText}`} variant="standard"
                    value={data.invima}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setData({ ...data, invima: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0`,
        },
        {
            title: <p className="pt-2 m-0">Indicaciones</p>,
            subTitle: <>
                <TextField id="volum" className={`w-100 ${tableStyles.darkBlueText}`} variant="standard"
                    value={data.indications}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setData({ ...data, indications: e.target.value })}
                />
            </>,
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0`,
        },
        {
            title: <p className="pt-2 m-0">Diagnósticos
                {/* <span className="text-danger" >*</span> */}
            </p>,
            subTitle: isEditing ? <Autocomplete
                noOptionsText={'Sin opciones'}
                multiple
                sx={{
                    input: {
                        color: "#6e6f7c"
                    },
                }}
                value={
                    currentDiagnostic === null
                        ? data.diagnostics
                        : currentDiagnostic
                }
                onChange={(event, newValue) => {
                    handleDiagnostics(newValue)
                }}
                id="controllable-states-demo"
                options={formattedDiagnostics}
                renderInput={(params) => <TextField {...params} label="" />}
            /> :
                <Autocomplete
                    multiple
                    noOptionsText={'Sin opciones'}
                    sx={{
                        input: {
                            color: "#6e6f7c"
                        },
                    }}
                    onChange={(event, newValue) => {
                        handleDiagnostics(newValue)
                    }}
                    id="controllable-states-demo"
                    options={formattedDiagnostics}
                    renderInput={(params) => <TextField {...params} label="" />}
                />,
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0 `,
            rowClassName: "mt-4",
            label: "subTitleClassName: `text-secondary m-0`,"
        },
        // {
        //     title: <p className="pt-2 m-0"><span className={`${tableStyles.ordSubTitleStyle}`} >MIPRES</span></p>,
        //     titleSize: 3,
        //     rowClassName: "mt-4",
        //     subTitleClassName: `text-secondary m-0`,
        // },
        // {
        //     title: <>Código principio activo</>,
        //     subTitle: <>
        //         <TextField id="volum" className={`w-100 ${tableStyles.darkBlueText}`} variant="standard"
        //             value={data.mipresActiveCode}
        //             inputProps={{ className: classes.input }}
        //             onChange={(e) => setData({ ...data, mipresActiveCode: e.target.value })}
        //         />
        //     </>,
        //     rowClassName: "mt-2",
        //     titleSize: 3,
        //     subTitleSize: subTitleSize,
        //     subTitleClassName: `text-secondary m-0 `,
        // },
        // {
        //     title: <>Código</>,
        //     subTitle: (
        //         <>
        //             <TextField
        //                 id="volum"
        //                 className={`w-100  ${tableStyles.darkBlueText}`}
        //                 variant="standard"
        //                 type="number"
        //                 value={isEditing ? data.mipresCode : data.code}
        //                 inputProps={{ className: classes.input }}
        //                 onChange={(e) => setData({ ...data, code: e.target.value })}
        //             />
        //         </>
        //     ),
        //     titleSize: 3,
        //     subTitleSize: subTitleSize,
        //     subTitleClassName: `text-secondary m-0 `,
        //     titleClassName: "align-self-center",
        // },
        {
            title: <>Código concentración</>,
            subTitle: (
                <>
                    <TextField
                        id="volum"
                        className={`w-100 ${tableStyles.darkBlueText}`}
                        variant="standard"
                        type="text"
                        value={data.concentrationCode}
                        inputProps={{ className: classes.input }}
                        onChange={(e) =>
                            setData({ ...data, concentrationCode: e.target.value })
                        }
                    />
                </>
            ),
            titleSize: 3,
            subTitleSize: subTitleSize,
            subTitleClassName: `text-secondary m-0 `,
            titleClassName: "align-self-center",
        },
        {
            title: <p className="pt-2 m-0">Unidad farmacéutica
            </p>,
            subTitle: <>
                <Autocomplete
                    sx={{
                        input: {
                            color: "#6e6f7c"
                        },
                    }}
                    value={!!medicineData?.mipresPharmaMeasUnit ? medicineData?.mipresPharmaMeasUnit : data?.mipresPharmaMeasUnit?.label}
                    onChange={(event, newValue) => {
                        setData({ ...data, mipresPharmaMeasUnit: newValue })
                    }}
                    id="controllable-states-demo"
                    options={formattedMeasurmentUnit}
                    renderInput={(params) => <TextField {...params} label="" />}
                /></>,
            titleSize: 3,
            subTitleSize: subTitleSize,
        },
        {
            title: <><span className={`${tableStyles.ordSubTitleStyle}`} >Selección de contratos</span></>,
            titleSize: 3,
            rowClassName: "mt-4",
            subTitleClassName: `text-secondary m-0`,
        },
    ]
    const leftData = (res) => {
        let arr = []
        res?.length > 0 && res?.forEach((e, i) => {
            arr.push({
                title: e?.contractName || e.copyName,
                data: e
            })
        })
        return arr
    }
    const [selectedCompany, setSelectedCompany] = useState()
    const [lefDataArr, setLefDataArr] = useState([])
    const [rightDataArr, setRightDataArr] = useState([])
    /* -------------------------- Here we fill leftData ------------------------- */
    useEffect(() => {
        if (!isEditing) {
            data?.contracts?.length > 0 && setLefDataArr(leftData(data?.contracts))
        } else {
            setLefDataArr([])
            data?.contracts?.length > 0 && setRightDataArr(leftData(data?.contracts))
        }
    }, [data.contracts])

    const handleCompanyClick = async (id, item) => {
        const res = await getContractsById(
            {
                url: "/medical/medicine/getContract/",
                objFilters: { corporateClient: item.id, slim: 1 },
                token: token,
            }
        )
        setContractList(res?.results)
        setTransferTrigger(transferTrigger + 1)
        setSelectedCompany(item)
        setLefDataArr(leftData(res?.results))

    }
    const [currentAlternatives, setCurrentAlternatives] = useState(null)
    const handleAlternatives = (e) => {
        setCurrentAlternatives(e);
        let arrIdEnd = []
        if (e.length > 0) {
            e.forEach((elem) => {
                arrIdEnd.push(elem.value);
            });
        }
        setData({ ...data, alternatives: arrIdEnd });
    }
    const handleDiagnostics = (e) => {
        setCurrentDiagnostic(e)
        // let arrIdEnd = [];
        // if (e.length > 0) {
        // e.forEach((elem) => {

        // setData({ ...data, diagnostics: [...data.diagnostics, elem.value] });
        // arrIdEnd.push(elem.value);
        // });
        // }
        // setIds({
        //     ...ids, arrIdDiag: arrIdEnd,
        // })
        // setData({ ...data, diagnostics: arrIdEnd });
    }
    const handleSubmit = () => {
        let validations = validateEmptyString(
            [
                { field: data.cum, message: 'El campo cum es obligatorio' },
                { field: data.chemicalSubstance, message: 'El campo sustancia química es obligatorio' },
                { field: data.verificationCode, message: 'Por favor digite el número de verificación' },
                // { field: data.tradeName, message: 'El campo nombre comercial es obligatorio' },
                { field: data.pharmaceuticalForm, message: 'El campo forma farmacéutica es obligatorio' },
                { field: data.pharmaGroup, message: 'El campo grupo farmacológico es obligatorio' },
                // { field: data.pharmaSubgroup, message: 'El campo subgrupo fármaco es obligatorio' },
                { field: data.qtyPerPresentation, message: 'El campo volumen es obligatorio' },
                { field: data.concentration, message: 'El campo concentración es obligatorio' },
                // { field: data.organicSystem, message: 'El campo sistema orgánico es obligatorio' },
                // { field: data.invima, message: 'El campo invima es obligatorio' },
                // { field: data.indications, message: 'El campo indicaciones es obligatorio' },
                { field: data.idMeasUnit, message: 'Por favor seleccione una unidad' },
                { field: data.idPresentation, message: 'Por favor seleccione una presentación' },
                { field: data.qtyPerMeasUnit, message: 'El campo cantidad de la unidad de receta es obligatorio' },
                { field: data.idOrderUnit, message: 'El campo unidad de receta es obligatorio' },
                // { field: currentDiagnostic?.length > 0 ? currentDiagnostic[0] : data.diagnostics, message: 'Por favor seleccione un diagnóstico' },
                // { field: data.mipresPharmaMeasUnit, message: 'Por favor seleccione una unidad farmacéutica' },
                // { field: selectedActives[0], message: 'Por favor seleccione un artículo' },  
            ]
        )
        // isEditing ? currentDiagnostic === null ? data.diagnostics : currentDiagnostic.length > 0 ? currentDiagnostic 
        if (validations.valid) {

            if (contractInfo?.right?.length <= 0) {
                return message('info', 'Campos vacíos', 'Debe elegir al menos un contrato')
            }
            let chosenContracts = []

            contractInfo?.right?.forEach((e, i) => {
                chosenContracts.push(e.data.id)
            })
            let arrIdEndEdit = []
            if (currentAlternatives?.length > 0) {
                currentAlternatives?.forEach((elem) => {
                    arrIdEndEdit?.push(elem.value);
                });
            }

            let method = 'POST'
            if (isEditing && isCloning) {
                isEditing = false
                method = "POST"
            }

            if (isEditing) {
                method = "PUT"
            }
            let editedDiags = []
            currentDiagnostic?.length > 0 && currentDiagnostic.forEach(e => {
                if (!isEmptyOrUndefined(e.value)) {
                    editedDiags.push(e.value)
                } else {
                    editedDiags.push(e)
                }
            })
            createMedicine({
                url: "/medical/medicine/",
                token: token,
                method: method,
                body: {
                    id: isEditing ? data.id : "",
                    cum: data.cum,
                    dv: data.verificationCode,
                    chemicalSubstance: capitalizeWord(data.chemicalSubstance),
                    tradeName: capitalizeWord(data.tradeName),
                    pharmaceuticalForm: data.pharmaceuticalForm,
                    pharmaGroup: data.pharmaGroup,
                    pharmaSubgroup: data.pharmaSubgroup,
                    idPresentation: data.idPresentation?.value || data.idPresentation,
                    idMeasUnit: data.idMeasUnit?.value || data.idMeasUnit,
                    idOrderUnit: data.idOrderUnit?.value || data?.idOrderUnit,
                    qtyPerPresentation: data.qtyPerPresentation,
                    qtyOrderUnit: data.qtyPerPresentation,
                    concentration: data.concentration,
                    organicSystem: data.organicSystem,
                    invima: data.invima,
                    indications: data.indications,
                    diagnostics: isEditing ? editedDiags : data.diagnostics,
                    mipresActiveCode: data.mipresActiveCode,
                    mipresCode: data.mipresCode,
                    mipresConcentrationCode: data.concentrationCode,
                    pharmaMeasUnit: data.mipresPharmaMeasUnit?.value || data.mipresPharmaMeasUnit,
                    articles: selectedActives,
                    contracts: chosenContracts,
                    id_account: idEnterprise,
                    alternatives: isEditing ? arrIdEndEdit : isCloning ? arrIdEndEdit : data.alternatives,

                    qtyPerMeasUnit: data.qtyPerMeasUnit

                }
                ,
                doAfterSuccess: (res) => {
                    history.push('/asistencial/medicamentos')
                    if (isEditing || isCloning) { onHide() }
                    if (isEditing || isCloning) { update() }

                    // settrigger(trigger + 1)
                    // return message("success", "good", "good")
                },
                doAfterException: (error) => {

                    if (error?.status === 409) {
                        swalConfirm({
                            title: `<span class=${tableStyles.ordClearBlueText} >Medicamento existente</span>`,
                            text: `¿Desea crear este medicamento con un dígito de verificación diferente?`,
                            confirmButtonText: "Si, continuar",
                            doAfterConfirm: () => createMedicine({
                                url: "/medical/medicine/",
                                token: token,
                                method: isEditing ? "PUT" : "POST",
                                body: {
                                    id: isEditing ? data.id : "",
                                    cum: data.cum,
                                    dv: data.verificationCode,
                                    chemicalSubstance: capitalizeWord(data.chemicalSubstance),
                                    tradeName: capitalizeWord(data.tradeName),
                                    pharmaceuticalForm: data.pharmaceuticalForm,
                                    pharmaGroup: data.pharmaGroup,
                                    pharmaSubgroup: data.pharmaSubgroup,
                                    idPresentation: data.idPresentation?.value || data.idPresentation,
                                    idMeasUnit: data.idMeasUnit?.value || data.idMeasUnit,
                                    idOrderUnit: data.idOrderUnit?.value || data?.idOrderUnit,
                                    qtyPerPresentation: data.qtyPerPresentation,
                                    qtyOrderUnit: data.qtyPerPresentation,
                                    concentration: data.concentration,
                                    organicSystem: data.organicSystem,
                                    invima: data.invima,
                                    indications: data.indications,
                                    diagnostics: isEditing ? currentDiagnostic === null ? data.diagnostics : currentDiagnostic : data.diagnostics,
                                    mipresActiveCode: data.mipresActiveCode,
                                    mipresCode: data.mipresCode,
                                    mipresConcentrationCode: data.concentrationCode,
                                    pharmaMeasUnit: data.mipresPharmaMeasUnit?.value || data.mipresPharmaMeasUnit,
                                    articles: selectedActives,
                                    contracts: chosenContracts,
                                    id_account: idEnterprise,
                                    alternatives: isEditing ? arrIdEndEdit : isCloning ? arrIdEndEdit : data.alternatives,
                                    qtyPerMeasUnit: data.qtyPerMeasUnit,
                                    dbPlus: true
                                },
                                doAfterSuccess: (res) => {
                                    if (isEditing || isCloning) { setBackSelected(res.results) }
                                    history.push('/asistencial/medicamentos')
                                    if (isEditing || isCloning) { onHide() }
                                    if (isEditing || isCloning) { update() }
                                }
                            })
                        })
                    } else {
                        message('warning', 'Advertencia', error.message)
                    }
                }
            })
        }
    }
    const getTransferListData = (data) => {
        setContractInfo(data)
    }
    useEffect(() => {
        let arr = []
        data?.articles?.length && data.articles.forEach((e, i) => {
            arr.push(e.id)
        })
        setSelectedActives(arr)
    }, [data.articles])

    const formatData = (array) => {
        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                tempList.push(
                    < tr key={index} className={`hover-table-row`} >
                        <td className="text-start"  >&nbsp;{item.barcode}</td>
                        <td className="text-start" > &nbsp;{item.description}</td>
                        <td  ><>  <input
                            key={index}
                            className="form-check-input styleCheckInputRatio mr-2 cursorPointer"
                            style={{ border: '2px solid #1a538d' }}
                            checked={selectedActives.find(x => x === item.id)}
                            onChange={() => changeBoxSelected(item.id)}
                            type='checkbox'>
                        </input></></td>
                    </tr >
                );
            });
        }
        return tempList;
    };
    const changeBoxSelected = (item) => {
        let tempSelected = selectedActives;
        if (Array.isArray(tempSelected)) {
            let target = tempSelected.find(x => x === item); // Bool 
            if (!!target) {
                tempSelected = tempSelected.filter(x => x !== item); // Si lo encuentra eliminalo
            } else {
                tempSelected.push(item); // Sino Añadelo
            }
            setSelectedActives(tempSelected);
        }
    }
    const handleFilterActives = (e) => {
        e.preventDefault()
        setTriggerArticles(triggerArticles + 1)
    }
    const relationArticles = () => {

        if (selectedActives?.length < 0) { return message('info', 'Artículos no seleccionados', 'Seleccione un articulo para relacionar') }
        setMedicineRelation({ ...medicineRelation, showModal: false })
    }
    const renderTooltip = (props) => (
        <Tooltip  {...props}>
            Quitar relación
        </Tooltip>
    );
    const [companiesToRender, setCompaniesToRender] = useState([])
    const handleCompaniesFilter = (e) => {
        let foundedNames
        let expresion = new RegExp(`${e?.toUpperCase()}.*`, "i");
        if (e.length > 0) {
            foundedNames = unities.companies?.results?.filter(dat => expresion.test(dat.copyName));
            setCompaniesToRender(foundedNames)
        }
        else {
            setCompaniesToRender(unities.companies?.results)
        }

    }

    return (
        <>
            {/* /* ----------------------------- Loaders section ---------------------------- */}
            {unitiesLoader && loader}
            {createMedicineLoader && loader}
            {articlesListLoader && loader}
            {/* /* ------------------------------- ACTIVE ARTICLES MODAL ------------------------------ */}
            <OrdModal
                title={"Artículos activos"}
                show={medicineRelation.showModal}
                subtitle={"Medicamentos"}
                btnYesName={"Relacionar"}
                btnNoName="Cancelar"
                size="700"
                btnYesEvent={() => relationArticles()}
                onHide={() => {
                    !isEditing && setSelectedActives([]);
                    setMedicineRelation({ ...medicineRelation, showModal: false })
                }}
                btnNoEvent={() => {
                    !isEditing && setSelectedActives([]);
                    setMedicineRelation({ ...medicineRelation, showModal: false })
                }}
            >
                <Col xs={12}>
                    <form onSubmit={(e) => handleFilterActives(e)}
                    >
                        <Form.Group className="mb-3 text-start" controlId="search"
                        >
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                            </Form.Label>
                            <div
                                className={`d-flex ${tableStyles.ordGrayInputSearch}`}
                            >
                                <Form.Control
                                    className={`ord-roundInput-search`}
                                    type="text"
                                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                    placeholder="Escribe aquí para buscar" />
                                <img
                                    alt="buscar"
                                    src={ordBlueSearch}
                                >
                                </img>
                            </div>
                        </Form.Group>
                    </form>
                </Col>
                <OrdTable
                    headers={medicineRelation.headers}
                    hasChildren={true}
                    paginate={{
                        activePage: filters.page,
                        totalPages: articlesList?.row_total,
                        perPage: filters.perpage,
                        pageRangeDisplayed: 3,
                        onChangePage: async (e) => {
                            const result = await getArticlesList({
                                url: '/inventory/article',
                                objFilters: { ...filters, page: e },
                                token: token,
                                requestName: "articlesList",
                            })
                            setFilters({ ...filters, page: e })
                            formatData(result?.results)
                        },
                        showTextDetails: true
                    }}
                >
                    {formatData(articlesList?.results)}
                </OrdTable>
            </OrdModal >
            {/* /* ------------------------------------ x ----------------------------------- */}
            <OrdGenericTemplate
                showBackArrow={true}
                backArrowAction={isEditing ? () => onHide() : () => history.push('/asistencial/medicamentos')}
                reduced={isEditing ? false : true}
                titleSize={isEditing && 12}
                title=
                {isEditing && isCloning ?
                    <h4
                        className={`${tableStyles.ordDarkBlueText}`}
                    >
                        <u>
                            <b>
                                {`${medicineData?.tradeName}` || "-"}
                            </b>
                        </u>
                    </h4>
                    : isEditing ?

                        <h4
                            className={`${tableStyles.ordDarkBlueText}`}
                        >
                            <u>
                                <b>
                                    {medicineData?.tradeName || "-"}
                                </b>
                            </u>
                        </h4> : "Nuevo Medicamento"}
            >
                <Row
                    className={`mb-5 ml-3 ${isEditing && tableStyles.scrollThis}`}
                    style={{ height: isEditing && "67vh" }}
                >
                    {!isEmptyOrUndefined(data.cum) && selectedActives.length === 0 &&
                        <Card
                            className={`p-1 ${tableStyles.ordOrangeCard} mb-3`}
                            style={{ width: isEditing ? '94.5%' : "71.5%", marginLeft: "11px", border: 'none' }}>

                            <Row
                                onClick={() => {
                                    if (articlesList?.results?.length > 0) {
                                        formatData(articlesList?.results)
                                        setMedicineRelation({ ...medicineRelation, showModal: true })
                                    }
                                }}
                            >
                                <Col xs={12} className={`mb-1`}>
                                    <img
                                        alt="orangebook"
                                        src={ordOrgangeBook}
                                    >
                                    </img>
                                    &nbsp;
                                    <b className={`${tableStyles.ordOrangeText}`} >
                                        Relacionar a un artículo activo en compras
                                    </b></Col>
                            </Row>
                        </Card>}
                    {selectedActives.length > 0 &&
                        <Card
                            className={`p-1 ${tableStyles.ordClearBlueCard} mb-3`}
                            style={{ width: isEditing ? '94.5%' : "71.5%", marginLeft: "11px", border: 'none' }}>
                            <Row
                                onClick={() => {
                                    if (articlesList?.results?.length > 0 && isEditing) {
                                        formatData(articlesList?.results)
                                        setMedicineRelation({ ...medicineRelation, showModal: true })
                                    }
                                }}
                                className={`${isEditing && "cursorPointer"}`}
                            >
                                <Col xs={11} className={`mb-1`}>
                                    <img
                                        alt="blueBook"
                                        src={blueBook}

                                    >
                                    </img>
                                    &nbsp;
                                    <b className={`${tableStyles.ordClearBlueText}`} >
                                        Medicamento relacionado a un artículo activo
                                    </b>
                                    &nbsp; <img
                                        alt="ordClearBlueCheck"
                                        src={ordClearBlueCheck}
                                    >
                                    </img>
                                </Col>
                                <Col onClick={() => setSelectedActives([])} xs={1} className={`mb-1 d-flex justify-content-center cursorPointer`}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <span>
                                            &nbsp; <img
                                                alt="ordClearBlueX"
                                                src={ordClearBlueX}
                                            >
                                            </img>
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Card>}
                    {/* /* ------------------------------ BI-TABLE FORM ----------------------------- */}
                    <OrdBiTable
                        data={biTableData}
                    />
                    <Row className="">
                        {/* /* ------------------------------- ENTERPRISES ------------------------------ */}
                        <Col xs={isEditing ? 3 : 2} className={`${tableStyles.ordBorderedBox} p-0  ml-4`}>

                            <TextField
                                id="standard-basic"
                                className="text-secondary w-100"
                                style={{ paddingTop: "0.7rem" }}
                                variant="standard" inputProps={{ className: classes.input }}
                                onChange={(e) => handleCompaniesFilter(e.target.value)}
                                label={<div
                                // className="py-2"
                                >
                                    &nbsp;
                                    <img
                                        src={OrdLupa}
                                        alt="lupa"
                                        width="15"
                                    >
                                    </img>
                                    <b>
                                        &nbsp; Empresas
                                    </b></div>}
                                type=""
                            />
                            <div className={`${tableStyles.scrollThis}`}
                                style={{
                                    height: "14.3rem"
                                }}
                            >
                                {
                                    // unities.companies?.results?.length
                                    companiesToRender?.length > 0 ?
                                        companiesToRender?.map((e, i) => {
                                            let ordBgAquamarine = selectedCompany?.id === e.id && tableStyles.ordBgAquamarine;
                                            let textLight = selectedCompany?.id === e.id && "text-light";
                                            return (
                                                <>
                                                    <div
                                                        key={i}
                                                        ref={ref}
                                                        id={`item${i}`}
                                                        onClick={(id) => handleCompanyClick(id.target.id, e)}
                                                        className={`${ordBgAquamarine} ${textLight} py-1 text-secondary w-100 px-2 cursorPointer ${tableStyles.f14}`} >{e?.copyName}-{e.Nit}</div>
                                                </>
                                            )
                                        }) : <div className="text-secondary text-center" >
                                            No se encontró información
                                        </div>}
                            </div>
                        </Col>
                        {/* /* ------------------------------ TRANSFER-LIST ----------------------------- */}
                        <Col xs={isEditing ? 8 : 7}
                            className={`${isEditing && 'p-0 m-0'}`}>
                            {contractsByIdLoader ? loader :
                                <TransferList
                                    leftItems={lefDataArr}
                                    rightItems={rightDataArr}
                                    setLeftItems={setLefDataArr}
                                    setRightItems={setRightDataArr}
                                    trigger={transferTrigger}
                                    setData={(data) => getTransferListData(data)}
                                    fillRightFirst={isEditing ? true : false}
                                    leftTitle="Contratos"
                                    rightTitle="Elegidos"
                                ></TransferList>}
                        </Col>
                    </Row>
                    <span className={`${tableStyles.ordSubTitleStyle} mt-3`} >Medicamentos alternativos</span>
                    <div className="p-0" style={{ width: isEditing ? '100%' : "73%" }}>
                        <Select noOptionsMessage={() => 'No hay datos'}
                            isMulti
                            value={
                                currentAlternatives === null
                                    ? formatToRcSelect(data?.alternatives,
                                        "id",
                                        "name",
                                        "",
                                        "",
                                        "",
                                        false)
                                    : currentAlternatives
                            }
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            onChange={(e) => handleAlternatives(e)}
                            options={formattedMedicines}
                            className="text-secondary"
                            placeholder={"Seleccionar..."}
                            styles={customSelectMulti}
                        ></Select>
                        <Col xs={12} className="d-flex justify-content-end pt-3 mb-3">
                            <button onClick={isEditing ? () => onHide() : () => history.push('/asistencial/medicamentos')} className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                            <button onClick={() => handleSubmit()} className={`${tableStyles.ordBtnPrimary} btn`}>
                                {isEditing && isCloning ? "Clonar" : isEditing ? "Actualizar" : "Guardar"}
                            </button>
                        </Col>
                    </div>
                </Row>
            </OrdGenericTemplate >
        </>
    )
}