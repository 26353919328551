import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getWareHouseList } from "../../actions/inventoryActions";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { ArticlesInStorage } from "./ArticlesInStorage";
import defaultimage from "../../assets/img/warehouse.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export const InventoryInStorage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const idAccount = store.loginReducer.currentAccount.id;
  const arrWareHouseList = store.inventoryReducer.wareHouseList;
  const history = useHistory();
  const [details, setDetails] = useState({
    show: false,
  });
  const MySwal = withReactContent(Swal);
  let i = 0;

  useEffect(() => {
    dispatch(getWareHouseList(idAccount,
      ()=>{
         if (arrWareHouseList?.length <= 0){
          MySwal.fire({
            icon: "info",
            text: "No existen registros para mostrar",
            confirmButtonText: "Aceptar",
          })
        }
        
      }));
  }, []);

 

  const principalRender= <div className={`w-100 ${tableStyles.containter}`}>
    {(store.inventoryReducer.inventoryS_loading) &&
            <div className="loading">
                <Loader
                type="Oval"
                color="#003f80"
                height={100}
                width={100}
                />
            </div>
          }
        <Row className="d-flex w-100 p-5">
          <Row>
            <Col xs={10}>
              <h1 className={tableStyles.title}>Inventario en almacén</h1>
            </Col>
          </Row>
          <h3 className={"mb-2"} style={{ color: "gray", fontSize: "16px" }}>
            Selecciona el almacén que quieres revisar
          </h3>
          {/* <div className={`d-flex`}> */}
          <Row>
            {arrWareHouseList?.length > 0 &&
              arrWareHouseList.map((e) => {
                return (
                  <Col
                    xs={3}
                    className={`mb-4 cursorPointer`}
                    onClick={() => setDetails({
                      show:true, 
                      selectedWarehouse: e.id_warehouse,
                      selectedWarehouseName: e.description_warehouse,
                    })}
                  >
                    <Card
                      className={"boxShadow borderAdviceBox boxShadowGray mb-2"}
                      style={{ height: "100%", borderRadius: "12px" }}
                    >
                      <img
                        style={{ borderRadius: "10px 10px 0px 0px", objectFit:"contain" }}
                        height="200px"
                        alt="imagen de almacén"
                        src={e.url || defaultimage}
                      ></img>
                      <Card.Body>
                        <Card.Text className={"subtitlesHomePage"}>
                          {e.description_warehouse}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
          </Row>
          {/* </div> */}
        </Row>
      </div>

      return (!!details.show)? 
      <ArticlesInStorage
          selectedWarehouse={details.selectedWarehouse}
          selectedWarehouseName={details.selectedWarehouseName}
          show={details.show}
          setDetails={setDetails}
          goback={()=>setDetails({...details, show: false})}
      /> 
      : 
      principalRender;
    

};
