import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Search from "../../../assets/img/icons/lupa.svg";
import download from "../../../assets/img/icons/downloadDoc.svg";
import GenericTableNew from "../../Layouts/GenericTableNew";
import {useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { convertMoneyFormat, loader, downloaFile } from "../../../helpers/helpers";
import { useGetMethod } from "../../../Hooks/useFetch";
import backIcon from "../../../assets/img/icons/atras-icon.svg";

export const OtrosiHistory = () => {
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "hiring"
    );
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const location = useLocation()
  const data = location.state.data;
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    date_from: "",
    date_until: "",
    page: 1,
    perpage: 10,
    eaccount: idEnterprise,
    contract_id: data.contract_id
  });


  const { results: historyResults, load: historyLoader, trigger: getHistoryMethod } = useGetMethod();

  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }

    getHistoryMethod({
      url: "/payroll/contract_rearranged",
      objFilters: filters,
      token: token,
    });

    // dispatch(getWorkEnvPollList(filters)); NOTE PETITION
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const renderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className="col-md-3 text-start px-3" >
          {elem.job_title ?? "-"}
        </td>
        <td className="col-md-2 text-end">{convertMoneyFormat(elem.base_salary ?? "-")}</td>
        <td className="col-md-2 text-end">{convertMoneyFormat(elem.non_const_income ?? "-")}</td>
        <td className="col-md-2 text-center">{elem.date_validity ?? "-"}</td>
        <td className="col-md-2 text-center px-3">
        <img alt="" src={download} className="cursorPointer" 
        onClick={()=>{
          window.location.href = elem.document
          // downloaFile(elem.document)
        }}
        />
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (historyResults?.results?.length > 0) {
      const elem2 = historyResults?.results;
      if (elem2?.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className="text-start px-3">
      Cargo
    </th>,
    <th key={2} className="text-end">
      Salario
    </th>,
    <th key={3} className="text-end">
      INCS
    </th>,
    <th key={4} className="text-center">Vigencia</th>,
    <th key={5} className="text-center">&nbsp;</th>,
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
    {(historyLoader) && loader}
      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
            <div className="d-flex">
            <img
              src={backIcon}
              alt="volver"
              height={40}
              className="hoverPointer "
              style={{ marginTop: "55px", marginLeft: "-30px", marginRight: "10px" }}
              onClick={() => history.push("/nomina/contratos")}
            />
            <h1 className={`${tableStyles.title} d-flex flex-column`}>
              <span>
              {data.candidate} - {data.cand_docnumber}
              </span>
            </h1>
            </div>
          <Row>
            <Col className="d-block" xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Vigencia desde</p>
              <input
                onChange={(e) => setFilters({ ...filters, date_from: e.target.value })}
                type="date"
                placeholder="Escribe..."
                className="register-inputs   fw-bold"
                name="position"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e);
                  }
                }}
              />
            </Col>
            <Col className="d-block" xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Hasta</p>
              <input
                onChange={(e) => setFilters({ ...filters, date_until: e.target.value })
              }
                type="date"
                placeholder="Escribe..."
                className="register-inputs   fw-bold"
                name="position"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e);
                  }
                }}
              />
            </Col>
            <Col
       xs={4}
       className="d-flex align-self-end gap-1"
      >
       <input
        className={tableStyles.SearchNew}
        value={filters.search}
        placeholder="Buscar..."
        type="text"
        onChange={(e) =>
          setFilters({ ...filters, search: e.target.value })
        }
        onKeyDown={(e) => {
         if (e.key === "Enter") {
          handleSearch(e)
         }
        }}
       ></input>
       <img
        className="hoverPointer"
        alt="iconSearch"
        style={{}}
        src={Search}
        height="24px"
        onClick={(e) => handleSearch(e)}
       />
      </Col>
          </Row>

          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {historyResults?.results ? filters.page : ""}
              {" de "}
              {Math.ceil(historyResults?.rowTotal / filters.perpage)
                ? Math.ceil(historyResults?.rowTotal / filters.perpage)
                : ""}{" "}
              ({historyResults?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={historyResults?.rowTotal}
              pageRangeDisplayed={5}
              onChange={(e) => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
