import React, { Component, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import Agregar from "../../assets/img/icons/aggdoc.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Atras from "../../assets/img/icons/atras-icon.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import ModalNew from "../../components/Layouts/ModalNew";

function ActiveBankNoteTypes(props) {

    const [showModal, setShowModal] = useState(false);

    let history = useHistory();
    function handleClick() {
        history.push("/tesoreria/TipoDeConsulta");
    }

    const header = [

        <th className="col-1 text-center">Codigo</th>,
        <th className="text-center">Descripción</th>,
        <th className="col-1 text-start">Est.</th>,
        <th className="col-1 text-center"></th>
    ]
    const body = [
        <tr>
            <td className="text-center">N0016248</td>
            <td className="text-center">crédito</td>
            <td className="text-center"><p style={{backgroundColor:"green",width:"15px",borderRadius:"100%"}}>* </p></td>
            <td className="text-center"><img className="text-start" src={Filtrar} onClick={() => setShowModal(true)} /></td>
        </tr>

    ]

    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                <div className="d-flex">
                    <img className={`${tableStyles.title} mr-3`} style={{cursor: "pointer"}} src={Atras} onClick={handleClick} />
                    <h1 className={tableStyles.title}>Tipos de notas de bancos activas</h1>
                </div>
                <Row className="d-flex">
                    <Col xs={2}>
                        <label className={tableStyles.crudModalLabel}>No. Orden de pago</label>
                        <input className={IndividualStyles.registerInputs} type="number"></input>
                    </Col>
                    <Col xs={4}>
                        <label className={tableStyles.crudModalLabel}>Fecha</label>
                        <input className={IndividualStyles.registerInputs} placeholder="Buscar..." type="text"></input>
                    </Col>
                    <Col xs={3}>
                        <i><img className="" style={{ marginTop: "30px" }} src={Lupa} alt="" srcset="" /></i>
                    </Col>
                    <Col xs={3} className="text-end">
                        <label style={{ marginTop: "30px" }} className="hoverPointer">Crear Tipo de Nota
                            <img className="ml-3" src={Agregar} alt="" srcset="" />

                        </label>

                    </Col>
                </Row>
                <div>
                    <ModalNew
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        title={"Ordenes de pago pendientes"}
                        subtitle={"802.203.265 - Nueva EPS"}
                        btnYesEvent={true}
                        btnYesName={"Seleccionar"}
                        size={"500"}

                    >
                        <Row className="d-flex">
                            <Col xs={5}>
                                <label className={tableStyles.crudModalLabel}>No. Orden de pago</label>
                                <input className={IndividualStyles.registerInputs} type="number"></input>
                            </Col>
                            <Col xs={6}>
                                <label className={tableStyles.crudModalLabel}>Fecha</label>
                                <input className={IndividualStyles.registerInputs} type="date"></input>
                            </Col>
                            <Col xs={1}>
                                <i><img className="mt-4" style={{ width: "25px" }} src={Lupa} alt="" srcset="" /></i>
                            </Col>
                        </Row>
                        <GenericTable
                            headers={header}
                            children={body}
                        >
                        </GenericTable>

                    </ModalNew>

                </div>
                <GenericTable
                    headers={header}
                    children={body}
                    dark={true}
                >
                </GenericTable>
            </div>

        </>
    )

};
export default ActiveBankNoteTypes;