/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useGetMethod, usePostMethod } from "../../../Hooks/useFetch";
import styles from "../../../components/Layouts/tableStyle.module.scss";
import { genderList, maritalStatusList } from "../../../helpers/constants";
import { formatToRcSelect, generateId, getAge, loader, message, swalConfirm, validateEmptyString } from "../../../helpers/helpers";
import closeInsurer from "../../../assets/img/icons/close_insurer_voluntary.svg";
import { appCustomSelectNew } from "../../../components/Layouts/react-select-custom";
import { useDispatch, useSelector } from "react-redux";
import { city_get, province_get } from "../../../actions/locationActions";

const UpdatePatient = ({ token, patient, options, filters, setOptions, detailPatientData, getListPacient, detailLoaderPatient }) => {
  // //console.log("detailPatientData: ", detailPatientData);
  const [newPatient, setNewPatient] = useState({
    documentType: "",
    document: "",
    firstName: "",
    otherNames: "",
    lastName: "",
    secondSurname: "",
    gender: "",
    birthDate: "",
    homeAddress: "",
    email: "",
    cityId: "",
    homephone: "",
    cellphone: "",
    whatsapp: "",
    bloodType: "",
    maritalStatus: "",
    provinceId: "",
    contracts: [],
  });

  const [validateWebScrapper, setValidateWebScrapper] = useState({
    modality: "onSite",
    doc: "",
    docType: "",
    entityId: "",
    entityType: "",
  });
  // //console.log("validateWebScrapper: ", validateWebScrapper);

  const [webScrapper, setWebScrapper] = useState([
    {
      index: generateId(),
      errorWS: "",
      success: "",
      message: "",
      results: "",
    },
  ]);
  // //console.log("webScrapper: ", webScrapper);

  // ANCHOR contracts state
  const [contracts, setContracts] = useState([
    {
      index: generateId(),
      contractId: "",
      corporateClientId: "",
      companyTypeTag: "eps",
      populationId: "",
      crtId: "",
      policy: "",
      isMain: 1,
      wsValidated: 1,
      const: true,
      isEnabled: 1,
      contractDescription: "",
    },
  ]);
  // //console.log("contracts: ", contracts);
  const temporalContract = contracts;
  const temporalWebScrapper = webScrapper;

  const companyTypeTag = [
    { value: "-seleccione-", label: "-seleccione-" },
    { value: "prepaid", label: "Prepagada" },
    { value: "special_agreement", label: "Convenio especial" },
  ];

  const bloodType = [
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B-", label: "B-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
  ];

  const { results: typeDocument, trigger: getDocumentTypes } = useGetMethod();
  const { results: insurersObligatory, trigger: getInsurersObligatory } = useGetMethod();

  const { results: validateWeb, load: loadValidateWeb, trigger: getValidateWeb } = useGetMethod();

  const { results: insurerVoluntary, trigger: getInsurerVoluntary } = useGetMethod();

  const { results: contractsClient, load: contractsLoader, trigger: getContracts } = useGetMethod();

  const { results: contractsVoluntary, load: contractsLoaderVolunray, trigger: getContractsVoluntary } = useGetMethod();

  const { results: poblations, load: poblationsLoader, trigger: getPoblations } = useGetMethod();
  const { results: poblationsObligatory, load: poblationsObligatoryLoader, trigger: getPoblationsObligatory } = useGetMethod();

  const { results: incomeGrupo, trigger: getIncomeGrupo } = useGetMethod();

  const { results: detailPatient, load: detailPatientLoader, trigger: getDetailPatient } = useGetMethod();

  const { load: loadCreatePatient, trigger: sendPatient } = usePostMethod();
  const dispatch = useDispatch();

  const store = useSelector((state) => state);
  const idEnterprise = store.loginReducer.currentAccount.id;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getDocumentTypes({
      url: "/payroll/document_types/",
      token: token,
    });

    getInsurersObligatory({
      url: "/medical/client/",
      token: token,
      objFilters: { contractCompanyType: "eps", status: "enabled", eaccount: idEnterprise },
    });

    getIncomeGrupo({
      url: "/medical/copaymentRate/",
      token: token,
    });

    getDetailPatient({
      url: "/medical/patient/",
      objFilters: { id: detailPatientData.results.id, eaccount: idEnterprise, },
      token: token,
    });

    getSelectCities(newPatient.provinceId);

    dispatch(
      province_get({
        entity_account: counter.loginReducer.currentAccount.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ANCHOR SET CONTRACTS
  useEffect(() => {
    let validationObl = detailPatient?.results?.contracts.find((e) => e?.isMain === 1);
    // //console.log("validationObl: ", validationObl);
    let validation = contracts?.find((e) => e?.companyTypeTag === "particular");
    // //console.log("validation: ", validation);
    if (detailPatient) {
      setNewPatient({
        ...newPatient,
        ...detailPatient?.results,
        documentType: detailPatient?.results?.docTypeId,
        document: detailPatient?.results?.document,
        lastName: detailPatient?.results?.lastName,
        cellphone: detailPatient?.results?.cellphone,
      });
      if (detailPatient?.results?.contracts.length >= 2 || validationObl !== undefined) {
        if (validation === undefined) {
          setContracts(detailPatient?.results?.contracts);
        }
      } else {
        if (validation === undefined && validationObl === undefined) {
          detailPatient?.results?.contracts.forEach((e) => {
            contracts?.push(e);
          });
        }
        // contracts.push(detailPatient?.results?.contracts);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailPatient]);

  useEffect(() => {
    if (typeDocument.results) {
      setValidateWebScrapper({
        ...validateWebScrapper,
        id: detailPatient?.results?.id,
        doc: detailPatient?.results?.document,
        docType: typeDocument.results?.find((item) => item.id === detailPatient?.results?.docTypeId)?.prefix,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailPatient.results, typeDocument.results]);

  const formatTypeDocument = formatToRcSelect(typeDocument.results, "id", "description", "prefix");

  const formattedEntityObligatory = formatToRcSelect(insurersObligatory.results, "id", "name");

  const formatedIncomeGrupo = formatToRcSelect(incomeGrupo.results, "id", "name");

  var formattedInsurerVoluntary = formatToRcSelect(insurerVoluntary.results, "id", "name");

  const formatContracts = formatToRcSelect(contractsClient.results, "id", "name");

  const formatContractsVoluntary = formatToRcSelect(contractsVoluntary.results, "id", "name");

  const formatPoblations = formatToRcSelect(poblations.results, "idPopulation", "name");
  const formatPoblationsObligatory = formatToRcSelect(poblationsObligatory.results, "idPopulation", "name");

  const getSelectCities = (id) => {
    dispatch(city_get({ province: id }, () => setLoading(false)));

    setNewPatient({ ...newPatient, provinceId: id });
  };

  const getContractsEps = (id) => {
    getContracts({
      url: "/medical/client/contract/",
      token: token,
      objFilters: {
        client: id,
        cType: "eps",
        status: "enabled",
        eaccount: idEnterprise,
      },
      doAfterSuccess: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
      },
    });
  };

  const getInsurersVoluntary = (id) => {
    getInsurerVoluntary({
      url: "/medical/client/",
      token: token,
      objFilters: { contractCompanyType: id, status: "enabled", eaccount: idEnterprise },
    });
  };

  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    // //console.log(trigger);
  }, [trigger]);

  const getContractPrepaidVoluntary = (id) => {
    getContractsVoluntary({
      url: "/medical/client/contract/",
      token: token,
      objFilters: {
        client: id,
        cType: "prepaid",
        status: "enabled",
        eaccount: idEnterprise,
      },
    });
    setTrigger(trigger + 1);
  };

  const getContractSpecialVoluntary = (id) => {
    getContractsVoluntary({
      url: "/medical/client/contract/",
      token: token,
      objFilters: {
        client: id,
        cType: "special_agreement",
        status: "enabled",
        eaccount: idEnterprise,
      },
      doAfterSuccess: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
      },
    });
  };

  const getContractPoblations = (id) => {
    getPoblations({
      url: "/medical/client/contractpopulation/",
      token: token,
      objFilters: {
        contract: id,
        status: "enabled", eaccount: idEnterprise
      },
      doAfterSuccess: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
      },
    });
  };

  const getContractPoblationsObligatory = (id) => {
    getPoblationsObligatory({
      url: "/medical/client/contractpopulation/",
      token: token,
      objFilters: {
        contract: id,
        status: "enabled",
        eaccount: idEnterprise
      },
      doAfterSuccess: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
      },
    });
  };

  const addInsurer = () => {
    let newInsurer = {
      index: generateId(),
      contractId: "",
      corporateClientId: "",
      companyTypeTag: "",
      populationId: "",
      crtId: "",
      policy: "",
      isMain: 0,
      wsValidated: 1,
      isEnabled: 1,
    };
    let validate = {
      index: generateId(),
      errorWS: "",
      success: "",
      message: "",
      results: "",
    };
    setContracts([...contracts, newInsurer]);
    setWebScrapper([...webScrapper, validate]);
  };

  const deleteInsurer = (i) => {
    setWebScrapper(webScrapper.filter((item, index) => index !== i));
    setContracts(contracts.filter((item, index) => index !== i));
  };

  const onSubmitPatient = (e) => {
    let validations = validateEmptyString([
      { field: newPatient.docTypeId, message: "El tipo de documento es obligatorio" },
      { field: newPatient.document, message: "El número de documento es obligatorio" },
      { field: newPatient.firstName, message: "El primer nombre es obligatorio" },
      { field: newPatient.lastName, message: "El primer apellido es obligatorio" },
      { field: newPatient.birthDate, message: "La fecha de nacimiento es obligatorio" },
      { field: newPatient.whatsapp, message: "El Whatsapp es obligatorio" },
      { field: newPatient.gender, message: "El genero es obligatorio" },
      { field: newPatient.email, message: "El correo es obligatorio" },
      { field: newPatient.contracts, message: "La empresa es obligatorio" },
      // { field: newPatient.qtyPerMeasUnit, message: 'El campo cantidad de la unidad de receta es obligatorio' },
    ]);
    let contractsVol = contracts.filter((e) => e.isMain === 0 && e.companyTypeTag !== "particular");
    // //console.log("contractsVol: ", contractsVol);
    let validationContracts = contracts.find((e) => e.isMain === 0 && e.companyTypeTag !== "particular");
    let validationContractsOb = contracts.find(
      (e) => e.isMain === 1 && e.crtId !== "" && e.contractId !== "" && e.corporateClientId !== "" && e.populationId !== ""
    );
    let status;
    if (validationContracts === undefined) {
      status = true;
    } else if (validationContractsOb !== undefined) {
      status = true;
      contractsVol.length > 0 &&
        contractsVol.forEach((e) => {
          if (e.companyTypeTag === "special_agreement") {
            if (e.companyTypeTag !== "" && e.contractId !== "" && e.corporateClientId !== "" && e.populationId !== "") {
              status = true;
            } else {
              status = false;
              message(
                "info",
                "Llenar campos aseguradora voluntaria",
                "Para crear una aseguradora voluntaria es necesario llenar todos los campos"
              );
              return status;
            }
          } else {
            if (e.companyTypeTag !== "" && e.contractId !== "" && e.corporateClientId !== "") {
              status = true;
            } else {
              status = false;
              message(
                "info",
                "Llenar campos aseguradora voluntaria",
                "Para crear una aseguradora voluntaria es necesario llenar todos los campos"
              );
              return status;
            }
          }
        });
    } else {
      status = false;
      message(
        "info",
        "Debe crear la aseguradora obligatoria",
        "El anterior procedimiento es prerequisito para crear una aseguradora voluntaria"
      );
    }

    if (validations.valid === true && status === true) {
      let contractParticular = contracts.find(contract => contract.companyTypeTag === "particular")
      e.preventDefault();
      swalConfirm({
        title: `¿Está seguro?`,
        text: `Se editará el paciente: ${newPatient.firstName} ${newPatient.otherNames} ${newPatient.lastName} ${newPatient.secondSurname}`,
        confirmButtonText: `Si, continuar`,
        doAfterConfirm: () => {
          sendPatient({
            url: "/medical/patient/",
            token: token,
            method: "PUT",
            body: { ...newPatient, contracts: contracts[0].contractId !== "" ? contracts : [], eaccount: idEnterprise },
            // body: { ...newPatient, contracts: contracts, eaccount: idEnterprise },
            doAfterSuccess: () => {
              getListPacient({
                url: "/medical/patient/listPatient/",
                objFilters: filters,
                token: token,
              });
              setOptions({
                ...options,
                showSearchPatient: true,
                showEditPatient: false,
              });
            },
          });
          // message("success", "Proceso realizado con éxito");
        },
      });
    }
  };

  // ------------------- CITIES SELECTS --------------------------------
  const counter = useSelector((state) => state);

  const provincesOptions = [];
  if (Array.isArray(counter.locationReducer.provinces)) {
    counter.locationReducer.provinces.map((item) => {
      provincesOptions.push({
        value: item.id,
        label: item.name,
        name: "id_province",
      });
    });
  }

  const citiesOptions = [{ value: "", label: "Seleccionar...", name: "id_province" }];

  if (Array.isArray(counter.locationReducer.cities)) {
    counter.locationReducer.cities.map((item) => {
      citiesOptions.push({
        value: item.id,
        label: item.name,
        name: "id_province",
      });
    });
  }

  const onChangeInsuranceVoluntary = (e, index) => {
    // getContractsEps(e.value);
    temporalContract[index].corporateClientId = e.value;
    setContracts(temporalContract);
    getContractSpecialVoluntary(e.value); //NEW
    getValidateWeb({
      url: "/medical/appointment/validateInfoWebscrapper/",
      token: token,
      objFilters: {
        ...validateWebScrapper,
        entityId: e.value,
        entityType: "eps",
        eaccount: idEnterprise,

      },
      doAfterSuccess: (data) => {
        if (!data.success && !data?.errorWS) {
          let aux = temporalContract[index];
          message("error", "Error", data.message);
          aux.corporateClientId = "";
          aux.contractId = "";
          aux.populationId = "";
          aux.crtId = "";
          aux.wsValidated = 0;
          temporalContract[index] = aux;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
        if (data?.errorWS === true) {
          message("error", "Validador de derechos no disponible", "Por favor complete los datos de la aseguradora manualmente");
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
        if (data?.errorWS === false && data.success === true) {
          temporalContract[index].contractId = data.results.info.contractId;
          temporalContract[index].populationId = data.results.info.populationId;
          temporalContract[index].crtId = data.results.info.crtId;

          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };

          setContracts(temporalContract);
        }
        if (data?.errorWS === false && data.success === true && data.results.affiliate === false) {
          message("error", "Validador de derechos", data.message);
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
      },
      doAfterException: (error) => {
        temporalContract[index].contractId = "";
        temporalContract[index].populationId = "";
        if (error?.errorWS === true) {
          message("error", "Validador de derechos no disponible", "Por favor complete los datos de la aseguradora manualmente");
          temporalContract[index].WSresults = {
            errorWS: error?.errorWS,
            success: error?.success,
            message: error?.message,
            results: error?.results,
          };
          setContracts(temporalContract);
        }
        //console.log("error", error);

        temporalContract[index].WSresults = {
          errorWS: error?.errorWS,
          success: error?.success,
          message: error?.message,
          results: error?.results,
        };

        setContracts(temporalContract);

        if (error?.errorWS === false && error.success === true) {
          temporalContract[index].contractId = error?.results.info.contractId;
          temporalContract[index].populationId = error?.results.info.populationId;
          temporalContract[index].crtId = error?.results.info.crtId;
          temporalContract[index].WSresults = {
            errorWS: error?.errorWS,
            success: error?.success,
            message: error?.message,
            results: error?.results,
          };
          setContracts(temporalContract);
        }
        if (error?.errorWS === false && error.success === true && error?.results.affiliate === false) {
          message("error", "Validador de derechos", error?.message);
          setWebScrapper(temporalWebScrapper);
          temporalContract[index].WSresults = {
            errorWS: error?.errorWS,
            success: error?.success,
            message: error?.message,
            results: error?.results,
          };
          setContracts(temporalContract);
        }
      },
    });
    contracts[index].contractId = "";
    setContracts(temporalContract);
  };

  const onChangeInsureObligatory = (e, index) => {
    contracts[index].contractId = "";
    contracts[index].populationId = "";
    contracts[index].crtId = "";
    // contracts[index].crtName = "";
    // contracts[index].population = "";
    // contracts[index].contractDescription = "";
    getContractsEps(e.value);
    temporalContract[index].corporateClientId = e.value;
    setContracts(temporalContract);
    getValidateWeb({
      url: "/medical/appointment/validateInfoWebscrapper/",
      token: token,
      objFilters: {
        ...validateWebScrapper,
        entityId: e.value,
        entityType: "eps",
        eaccount: idEnterprise,
      },
      doAfterSuccess: (data) => {
        // doAfterSuccess: () => {

        // },
        if (!data?.success && !data?.errorWS) {
          message("error", "Error", data?.message);
          temporalContract[index].corporateClientId = "";
          temporalContract[index].contractId = "";
          temporalContract[index].populationId = "";
          temporalContract[index].crtId = "";
          temporalContract[index].wsValidated = 0;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
        }
        if (data?.errorWS === true) {
          message("error", "Validador de derechos no disponible", "Por favor complete los datos de la aseguradora manualmente");
          temporalContract[index].wsValidated = 0;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
          setTimeout(() => {
            setTrigger(trigger + 1);
          }, 1000);
        }
        if (data?.errorWS === false && data?.success === true) {
          temporalContract[index].contractId = data.results.info.contractId;
          temporalContract[index].populationId = data?.results?.info?.populationId;
          temporalContract[index].crtId = data?.results?.info?.crtId;
          temporalContract[index].wsValidated = 1;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
          setTimeout(() => {
            setTrigger(trigger + 1);
          }, 1000);
        }
        if (data?.errorWS === false && data?.success === true && data?.results?.affiliate === false) {
          message("error", data?.message, "");
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
          setTimeout(() => {
            setTrigger(trigger + 1);
          }, 1000);
        }

      },
      doAfterException: (data) => {
        if (!data?.success && !data?.errorWS) {
          message("error", "Error", data?.message);
          temporalContract[index].corporateClientId = "";
          temporalContract[index].contractId = "";
          temporalContract[index].populationId = "";
          temporalContract[index].crtId = "";
          temporalContract[index].wsValidated = 0;

          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
          setTimeout(() => {
            setTrigger(trigger + 1);
          }, 1000);
        }
        if (data?.errorWS === true) {
          message("error", "Validador de derechos no disponible", "Por favor complete los datos de la aseguradora manualmente");
          temporalContract[index].wsValidated = 0;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
          setTimeout(() => {
            setTrigger(trigger + 1);
          }, 1000);
        }
        if (data?.errorWS === false && data?.success === true) {
          temporalContract[index].contractId = data.results.info.contractId;
          temporalContract[index].populationId = data?.results?.info?.populationId;
          temporalContract[index].crtId = data?.results?.info?.crtId;
          temporalContract[index].wsValidated = 1;
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
          setTimeout(() => {
            setTrigger(trigger + 1);
          }, 1000);
        }
        if (data?.errorWS === false && data?.success === true && data?.results?.affiliate === false) {
          message("error", data?.message, "");
          temporalContract[index].WSresults = {
            errorWS: data?.errorWS,
            success: data?.success,
            message: data?.message,
            results: data?.results,
          };
          setContracts(temporalContract);
          setTimeout(() => {
            setTrigger(trigger + 1);
          }, 1000);
        }
        dispatch(
          province_get({
            entity_account: counter.loginReducer.currentAccount.id,
          })
        );
      },
      succesAction: () => {
        setTimeout(() => {
          setTrigger(trigger + 1);
        }, 1000);
        dispatch(
          province_get({
            entity_account: counter.loginReducer.currentAccount.id,
          })
        );
      }
    });

    setContracts(temporalContract);
  };

  const onChangeCompanyType = (e, index) => {
    const temporalContract = contracts;
    temporalContract[index].companyTypeTag = e.value;
    temporalContract[index].corporateClientId = "";
    contracts[index].contractId = "";
    contracts[index].WSresults = {};
    getInsurersVoluntary(e.value);
    setContracts(temporalContract);
  };
  return (
    <>
      {(contractsLoader ||
        poblationsObligatoryLoader ||
        detailPatientLoader ||
        loadValidateWeb ||
        poblationsLoader ||
        loadCreatePatient ||
        contractsLoaderVolunray) &&
        loader}
      <div className={styles.app_container_register_app}>
        <div
          className={styles.app_container_close_app}
          onClick={() => {
            setOptions({
              ...options,
              showEditPatient: false,
              showDetailPatient: true,
              showInfoPatient: true,
            });
          }}
        >
          <div className={styles.app_close} />
        </div>
        <div className={styles.app_container_register_patient}>
          {/* ANCHOR Incripción del paciente */}
          <div style={{ marginBottom: "10px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span className={styles.app_title_register_app} style={{ fontSize: "40px" }}>
                Editar paciente
              </span>
            </div>
            <div className={styles.app_container_form_register}>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="fDocument">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Tipo de documento <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={formatTypeDocument}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    value={formatTypeDocument.find((e) => e.value === newPatient.docTypeId)}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        documentType: e.value,
                        docTypeId: e.value,
                      });
                      setValidateWebScrapper({
                        ...validateWebScrapper,
                        docType: e.extra,
                      });
                    }}
                    styles={appCustomSelectNew}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="FNumberDocument">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Número de documento <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.document}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        document: e.target.value,
                        contracts: [],
                      });
                      setValidateWebScrapper({
                        ...validateWebScrapper,
                        doc: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Primer Nombre <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.firstName}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        firstName: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="fseName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Segundo Nombre </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.otherNames}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        otherNames: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="fLastname">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Primer Apellido <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.lastName}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        lastName: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="Surname">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Segundo Apellido </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.secondSurname}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        secondSurname: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="bornDate">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Fecha de nacimiento <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="date"
                    value={newPatient.birthDate}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        birthDate: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="age">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Edad <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    placeholder="Escribe..."
                    value={`${newPatient.birthDate === "" ? "0" : getAge(newPatient.birthDate)} años`}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="whatsApp">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      WhatsApp <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.whatsapp}
                    placeholder="Escribe..."
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        whatsapp: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="gender">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Género <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={genderList}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    value={genderList.find((x) => x.value === newPatient.gender)}
                    onChange={(e) => {
                      setNewPatient({ ...newPatient, gender: e.value });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="email">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">
                      Correo electrónico <span style={{ color: "#F3E882" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="email"
                    value={newPatient.email}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        email: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="maritalStatus">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Estado civil </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={maritalStatusList}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    value={maritalStatusList.find((x) => x.value === newPatient.maritalStatus)}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        maritalStatus: e.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          {/* Información complementaria */}
          <div style={{ marginBottom: "10px" }}>
            <div>
              <span className={styles.app_subtitle_register_app}>Información complementaria</span>
            </div>
            <div className={styles.app_container_form_register}>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="providence">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Departamento </b>
                  </Form.Label>

                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={provincesOptions}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    value={provincesOptions.find((x) => x.value === newPatient.provinceId)}
                    onChange={(e) => {
                      getSelectCities(e.value);
                      setNewPatient({ ...newPatient, provinceId: e.value, cityId: "" });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="city">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Municipio </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    value={citiesOptions.find((x) => x.value === newPatient.cityId)}
                    options={citiesOptions}
                    className="text-secondary"
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        cityId: e.value,
                      });
                    }}
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="address">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Dirección </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.homeAddress}
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        homeAddress: e.target.value,
                      });
                    }}
                    placeholder="Escribe..."
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="bloodyType">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Grupo sanguíneo </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={bloodType}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    value={bloodType.find((x) => x.value === newPatient.bloodType)}
                    styles={appCustomSelectNew}
                    onChange={(e) => {
                      setNewPatient({ ...newPatient, bloodType: e.value });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="phone">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Celular</b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.cellphone}
                    placeholder="Escribe..."
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        cellphone: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="homephone">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Teléfono fijo </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    value={newPatient.homephone}
                    placeholder="Escribe..."
                    onChange={(e) => {
                      setNewPatient({
                        ...newPatient,
                        homephone: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          {/* ANCHOR Aseguradora Obligatoria */}

          {contracts?.map((e, i) => {
            let index = contracts?.findIndex((contract) => contract.isMain === 1);
            let item = contracts?.find((contract, indexCnt) => index === indexCnt);
            if (e.isEnabled === 1 && e.isMain === 1 && e.contractDescription !== "Contrato particular PARAMO") {
              return (
                <div className={styles.app_container_register_insurance}>
                  <div>
                    <span className={styles.app_subtitle_register_app}>Aseguradora obligatoria</span>
                  </div>
                  <div className={styles.app_container_form_register}>
                    <div className={styles.app_container_content_form}>
                      <Form.Group controlId="company">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b className="px-1">Empresa</b>
                          <span style={{ color: "#F3E882" }}>*</span>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          options={formattedEntityObligatory}
                          styles={appCustomSelectNew}
                          placeholder={"Seleccionar..."}
                          value={formattedEntityObligatory.find((x) => x.value === contracts[index].corporateClientId)}
                          onChange={(e) => {
                            onChangeInsureObligatory(e, index);
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                      {item.isEnabled === 1 &&
                        item.isMain === 1 &&
                        item.companyTypeTag !== "particular" &&
                        contracts[index].WSresults?.errorWS === true &&
                        contracts[index].corporateClientId !== "" ? (
                        <>
                          <Form.Group controlId="contract">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Contrato</b>
                              <span style={{ color: "#F3E882" }}>*</span>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                              options={formatContracts}
                              styles={appCustomSelectNew}
                              placeholder={"Seleccionar..."}
                              value={formatContracts.find((x) => x.value === contracts[index].contractId)}
                              onChange={(e) => {
                                temporalContract[index].contractId = e.value;
                                temporalContract[index].populationId = "";
                                setContracts(temporalContract);
                                getContractPoblationsObligatory(e.value);
                              }}
                            />
                          </Form.Group>
                        </>
                      ) : (
                        <Form.Group controlId="contractIn">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className="px-1">Contrato</b>
                            <span style={{ color: "#F3E882" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type="text"
                            disabled
                            value={
                              contracts[index].WSresults?.results?.info
                                ? contracts[index].WSresults?.results?.info?.contractDescription
                                : contracts[index]?.contractDescription
                            }
                            onChange={() => {
                              temporalContract[index].contractId = validateWeb?.results?.info?.contractId;
                            }}
                            placeholder="Escribe..."
                          />
                        </Form.Group>
                      )}
                    </div>
                    <div className={styles.app_container_content_form}>
                      {contracts[index].WSresults?.errorWS === true ? (
                        <>
                          <Form.Group controlId="population">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Población</b>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                              options={formatPoblationsObligatory}
                              styles={appCustomSelectNew}
                              placeholder={"Seleccionar..."}
                              value={formatPoblationsObligatory.find((x) => x.value === contracts[index].populationId)}
                              onChange={(e) => {
                                temporalContract[index].populationId = e.value;
                                setContracts(temporalContract);
                              }}
                            />
                          </Form.Group>
                        </>
                      ) : (
                        <Form.Group controlId="populationIn">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className="px-1">Población</b>
                            <span style={{ color: "#F3E882" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type="text"
                            disabled
                            value={
                              contracts[index].WSresults?.results?.info
                                ? contracts[index].WSresults?.results.info?.population
                                : contracts[index].population
                            }
                            onChange={() => {
                              temporalContract[index].populationId = validateWeb?.results?.info?.contractId;
                            }}
                            placeholder="Escribe..."
                          />
                        </Form.Group>
                      )}
                    </div>
                    <div className={styles.app_container_content_form}>
                      {contracts[index].WSresults?.errorWS === true ? (
                        <>
                          <Form.Group controlId="crtID">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Grupo de ingreso</b>
                              <span style={{ color: "#F3E882" }}>*</span>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                              options={formatedIncomeGrupo}
                              styles={appCustomSelectNew}
                              placeholder={"Seleccionar..."}
                              value={formatedIncomeGrupo.find((x) => x.value === contracts[index].crtId)}
                              onChange={(e) => {
                                temporalContract[index].crtId = e.value;
                                setContracts(temporalContract);
                              }}
                            />
                          </Form.Group>
                        </>
                      ) : (
                        <Form.Group controlId="crtIds">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className="px-1">Grupo de ingreso</b>
                            <span style={{ color: "#F3E882" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type="text"
                            disabled
                            value={
                              contracts[index].WSresults?.results?.info
                                ? contracts[index].WSresults?.results?.info?.crtName
                                : contracts[index]?.crtName
                            }
                            placeholder="Escribe..."
                          />
                        </Form.Group>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          })}

          {/* ANCHOR Aseguradora Voluntaria */}
          {contracts?.length > 0 &&
            contracts?.map((item, index) => {
              if (index > 0)
                return (
                  item.isEnabled === 1 &&
                  item.isMain === 0 &&
                  item.companyTypeTag !== "particular" &&
                  (item.companyTypeName ? (
                    <div className={styles.app_container_insurance_voluntary} key={index}>
                      <div className={styles.container_title_insurer_voluntary}>
                        <span className={styles.app_insurer_voluntary}>Aseguradora voluntaria</span>
                        <div>
                          <img
                            src={closeInsurer}
                            alt="close"
                            className="cursorPointer"
                            width={13}
                            style={{ color: "#A3BAD1" }}
                            onClick={() => {
                              temporalContract[index].isEnabled = 0;
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.app_container_form_register}>
                        <div className={styles.app_container_content_form}>
                          <Form.Group controlId="type">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Tipo</b>
                            </Form.Label>
                            <Form.Control
                              className={styles.app_inputs_detail_patient}
                              type="text"
                              disabled
                              value={item?.companyTypeName}
                              placeholder="-"
                            />
                          </Form.Group>
                        </div>

                        <div className={styles.app_container_content_form}>
                          <Form.Group controlId="companyInr">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Empresa</b>
                            </Form.Label>
                            <Form.Control
                              className={styles.app_inputs_detail_patient}
                              type="text"
                              disabled
                              value={item?.corporateClient}
                              placeholder="-"
                            />
                          </Form.Group>
                        </div>

                        <div className={styles.app_container_content_form}>
                          <Form.Group controlId="contractI">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Contrato </b>
                            </Form.Label>
                            <Form.Control
                              className={styles.app_inputs_detail_patient}
                              type="text"
                              disabled
                              value={item?.contractDescription}
                              placeholder="-"
                            />
                          </Form.Group>
                        </div>
                        {item?.companyTypeTag === "special_agreement" ? (
                          <div className={styles.app_container_content_form}>
                            <Form.Group controlId="populationI">
                              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className="px-1">Población</b>
                              </Form.Label>
                              <Form.Control
                                className={styles.app_inputs_detail_patient}
                                type="text"
                                disabled
                                value={item?.population}
                                placeholder="-"
                              />
                            </Form.Group>
                          </div>
                        ) : (
                          <div className={styles.app_container_content_form}>
                            <Form.Group controlId="policy">
                              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className="px-1">No. Póliza</b>
                              </Form.Label>
                              <Form.Control
                                className={styles.app_inputs_detail_patient}
                                type="text"
                                disabled
                                value={item?.policy}
                                placeholder="-"
                              />
                            </Form.Group>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div key={index} className={styles.app_container_insurance_voluntary}>
                      <div className={styles.container_title_insurer_voluntary}>
                        <span className={styles.app_insurer_voluntary}>Aseguradora voluntaria </span>
                        <div>
                          <img
                            src={closeInsurer}
                            alt="close"
                            className="cursorPointer"
                            width={13}
                            style={{ color: "#A3BAD1" }}
                            onClick={() => {
                              deleteInsurer(index);
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.app_container_form_register}>
                        <div className={styles.app_container_content_form}>
                          <Form.Group controlId="companyType">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                              <b className="px-1">Tipo de empresa</b>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                              options={companyTypeTag}
                              styles={appCustomSelectNew}
                              value={companyTypeTag.find((x) => x.value === contracts[index].companyTypeTag)}
                              placeholder={"Seleccionar..."}
                              onChange={(e) => {
                                onChangeCompanyType(e, index);
                              }}
                            />
                          </Form.Group>
                        </div>

                        {contracts[index].companyTypeTag === "special_agreement" ? (
                          <>
                            <div className={styles.app_container_content_form}>
                              <Form.Group controlId="companySp">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                  {/*ANCHOR Kate */}
                                  <b className="px-1">Empresa</b>
                                  <span style={{ color: "#F3E882" }}>*</span>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                  options={formattedInsurerVoluntary}
                                  styles={appCustomSelectNew}
                                  placeholder={"Seleccionar..."}
                                  value={formattedInsurerVoluntary.find((x) => x.value === contracts[index].corporateClientId)}
                                  onChange={(e) => onChangeInsuranceVoluntary(e, index)}
                                />
                              </Form.Group>
                            </div>
                            <div className={styles.app_container_content_form}>
                              {contracts[index]?.WSresults?.errorWS === true ? (
                                <>
                                  <Form.Group controlId="contractSp">
                                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                      <b className="px-1">Contrato</b>
                                      <span style={{ color: "#F3E882" }}>*</span>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                      options={formatContractsVoluntary}
                                      styles={appCustomSelectNew}
                                      placeholder={"Seleccionar..."}
                                      value={formatContractsVoluntary.find((x) => x.value === contracts[index].contractId)}
                                      onChange={(e) => {
                                        temporalContract[index].contractId = e.value;
                                        setContracts(temporalContract);
                                        getContractPoblations(e.value);
                                      }}
                                    />
                                  </Form.Group>
                                </>
                              ) : contracts[index].companyTypeTag === "special_agreement" &&
                                contracts[index]?.WSresults?.errorWS === false &&
                                contracts[index]?.WSresults?.success === false ? (
                                <Form.Group controlId="contractSp">
                                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className="px-1">Contrato</b>
                                    <span style={{ color: "#F3E882" }}>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    className={styles.app_inputs_detail_patient}
                                    type="text"
                                    disabled
                                    value={
                                      contracts[index].WSresults?.results?.info
                                        ? contracts[index].WSresults.results?.info?.contractDescription
                                        : "-"
                                    }
                                    onChange={() => {
                                      temporalContract[index].contractId = validateWeb?.results?.info?.contractId;
                                    }}
                                    placeholder="Escribe..."
                                  />
                                </Form.Group>
                              ) : contracts[index]?.WSresults?.errorWS === true && contracts[index]?.WSresults?.success === false ? (
                                <Form.Group controlId="contractSp">
                                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className="px-1">Contrato</b>
                                  </Form.Label>
                                  <Select noOptionsMessage={() => 'No hay datos'}
                                    options={formatContractsVoluntary}
                                    styles={appCustomSelectNew}
                                    placeholder={"Seleccionar..."}
                                    value={formatContractsVoluntary.find((x) => x.value === contracts[index].contractId)}
                                    onChange={(e) => {
                                      const temporalContract = contracts;
                                      temporalContract[index].contractId = e.value;
                                      setContracts(temporalContract);
                                      getContractPoblations(e.value);
                                    }}
                                  />
                                </Form.Group>
                              ) : (
                                <Form.Group controlId="contractK">
                                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className="px-1">Contrato</b>
                                    <span style={{ color: "#F3E882" }}>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    className={styles.app_inputs_detail_patient}
                                    type="text"
                                    disabled
                                    value={
                                      contracts[index].WSresults?.results?.info
                                        ? contracts[index].WSresults.results?.info?.contractDescription
                                        : "-"
                                    }
                                    onChange={() => {
                                      temporalContract[index].contractId = validateWeb?.results?.info?.contractId;
                                    }}
                                    placeholder="Escribe..."
                                  />
                                </Form.Group>
                              )}
                            </div>
                            <div className={styles.app_container_content_form}>
                              {contracts[index]?.WSresults?.errorWS === true ? (
                                <>
                                  <Form.Group controlId="populationEr">
                                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                      <b className="px-1">Población</b>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                      options={formatPoblations}
                                      styles={appCustomSelectNew}
                                      placeholder={"Seleccionar..."}
                                      value={formatPoblations.find((x) => x.value === contracts[index].populationId)}
                                      onChange={(e) => {
                                        temporalContract[index].populationId = e.value;
                                        setContracts(temporalContract);
                                      }}
                                    />
                                  </Form.Group>
                                </>
                              ) : contracts[index]?.WSresults?.errorWS === true && contracts[index]?.WSresults?.success === false ? (
                                <Form.Group controlId="populationEr">
                                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className="px-1">Población</b>
                                  </Form.Label>
                                  <Select noOptionsMessage={() => 'No hay datos'}
                                    options={formatPoblations}
                                    styles={appCustomSelectNew}
                                    placeholder={"Seleccionar..."}
                                    value={formatPoblations.find((x) => x.value === contracts[index].populationId)}
                                    onChange={(e) => {
                                      temporalContract[index].populationId = e.value;
                                      setContracts(temporalContract);
                                    }}
                                  />
                                </Form.Group>
                              ) : (
                                <Form.Group controlId="populationEr">
                                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className="px-1">Población</b>
                                    <span style={{ color: "#F3E882" }}>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    className={styles.app_inputs_detail_patient}
                                    type="text"
                                    disabled
                                    value={
                                      contracts[index].WSresults?.results?.info ? contracts[index].WSresults.results?.info?.population : "-"
                                    }
                                    onChange={() => {
                                      temporalContract[index].populationId = validateWeb?.results?.info?.contractId;
                                    }}
                                    placeholder="Escribe..."
                                  />
                                </Form.Group>
                              )}
                            </div>
                          </>
                        ) : (
                          // -----------------------PREPAID------------------------------
                          <>
                            <div className={styles.app_container_content_form}>
                              <Form.Group controlId="companyEr">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                  <b className="px-1">Empresa</b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                  options={formattedInsurerVoluntary}
                                  styles={appCustomSelectNew}
                                  placeholder={"Seleccionar..."}
                                  value={formattedInsurerVoluntary.find((x) => x.value === contracts[index].corporateClientId)}
                                  onChange={(e) => {
                                    const temporalContract = contracts;
                                    temporalContract[index].corporateClientId = e.value;
                                    temporalContract[index].companyTypeTag === "prepaid" && getContractPrepaidVoluntary(e.value);
                                    setContracts(temporalContract);
                                  }}
                                />
                              </Form.Group>
                            </div>

                            <div className={styles.app_container_content_form}>
                              <>
                                <Form.Group controlId="contractErs">
                                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className="px-1">Contrato</b>
                                    <span style={{ color: "#F3E882" }}>*</span>
                                  </Form.Label>

                                  <Select noOptionsMessage={() => 'No hay datos'}
                                    options={formatContractsVoluntary}
                                    styles={appCustomSelectNew}
                                    placeholder={"Seleccionar..."}
                                    value={formatContractsVoluntary.find((x) => x.value === contracts[index].contractId)}
                                    onChange={(e) => {
                                      const temporalContract = contracts;
                                      temporalContract[index].contractId = e.value;
                                      setContracts(temporalContract);
                                      getContractPoblations(e.value);
                                    }}
                                  />
                                </Form.Group>
                              </>
                            </div>
                            <div className={styles.app_container_content_form}>
                              <>
                                <Form.Group controlId="policy">
                                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className="px-1">No. de Póliza</b>
                                  </Form.Label>
                                  <Form.Control
                                    name="policy"
                                    className={styles.app_inputs_detail_patient}
                                    type="text"
                                    placeholder="Escribe..."
                                    onChange={(e) => {
                                      const temporalContract = contracts;
                                      temporalContract[index].policy = e.target.value;
                                      setContracts(temporalContract);
                                    }}
                                  />
                                </Form.Group>
                              </>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))
                );
            })}
          <div className={styles.app_container_icon_voluntary}>
            <div
              style={{
                gap: "0.6rem",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                addInsurer();
              }}
            >
              <div className={styles.btn_add_insurer} />
              <span className={styles.app_title_insurer_voluntary}>Agregar aseguradora voluntaria</span>
            </div>
            <button
              className={styles.btn_save_patient}
              onClick={(e) => {
                onSubmitPatient(e);
              }}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", backgroundColor: "#F6FEFF" }}></div>
    </>
  );
};

export default UpdatePatient;
