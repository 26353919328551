import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SlideDownButton } from '../../components/Layouts/SlideDownButton';
import { OrdGenericTemplate } from '../OrderingComponents/OrdGenericTemplate'
import OrdTable from '../OrderingComponents/OrdTable';
import Lupa2 from "../../assets/img/icons/NewLupa.svg";
import Lupa from "../../assets/img/icons/OrdLupa.svg";
import { Col, Form, Row } from 'react-bootstrap'
import ordComponentStyles from '../OrderingComponents/ordComponentStyles.module.scss'
import Select from "react-select";
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import threeDots from "../../assets/img/icons/threeDots.svg";
import CustomPopupExtend from "../../components/Popup/customPopUpExtends"
import { useGetMethod, useMultiGetMethod } from "../../Hooks/useFetch";
import { useSelector } from "react-redux";
import { loader, formatToRcSelect, downloaFile } from "../../helpers/helpers";
import { OrdSlideDownButton } from '../OrderingComponents/OrdSlideDownButton'
import flecha from "../../assets/img/icons/desplegarGris.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import FullScreenDialog from "../OrderingComponents/FullScreenDialog";
import { RenderMedicalFormulasPdf } from "../../AsistanceModule/RenderMedicalFormulasPdf";
import pdfIcon from "../../assets/img/icons/pdf.svg";



export const ReferenceHistory = (props) => {
    let history = useHistory();
    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const idCurrentAccount = store.loginReducer.currentAccount.id;
    const [trigger, setTrigger] = useState(0);
    const {results: unities,load: unitiesLoader, trigger: getUnities,} = useMultiGetMethod();
    const {results: referencesHistory, load: referencesLoader, trigger: getReferencesHistory,} = useGetMethod();
    const { results: referencesOrder, load: referencesOrderLoader, trigger: getPdfOrder } = useGetMethod()
    const doctorOptions = formatToRcSelect(unities?.doctors?.results,"idDoctor","nameDoctor","", "", "");
    const companiesOptions = formatToRcSelect(unities?.company?.results,"idCorporateClient","nameCorporateClient","", "", "");
    const contractsOptions = formatToRcSelect(unities?.contracts?.results,"id","contractName","", "", "");
    const referenceTypesOptions = formatToRcSelect(unities?.referenceTypes?.results,"id","name","", "", "");
    const [rotate, setRotate] = useState(false)
    const headers = [
        {
            title: "Identificación",
            className: "px-2 text-start"
        },
        {
            title: "Paciente",
            className: "px-2 text-start"
        },
        {
            title: "Empresa",
            className: "px-2 text-start"
        },
        {
            title: "Tipo",
            className: "px-2 text-start"
        },
        {
            title: "Contrato",
            className: "px-2 text-start"
        },
        {
            title: "Médico",
            className: "px-2 text-start"
        },
        {
            title: "Fecha",
            className: "px-2 text-center"
        },
        {
            title: <>ㅤ</>,
        },
    ]
    const [filters, setFilters] = useState({
        page: 1,
        perpage: 10,
        idAccount: idCurrentAccount,
      });
    const [fullDialogScreen, setFullDialogScreen] = useState({
    show: false,
    id: "",
    });
    const renderTooltipDoctor = ({ item }) => (
        <Tooltip>CC {item.doctor.document} <br></br> RM {item.doctor.medicalRecord}</Tooltip>
        );
    const searchButton = () => {
      setFilters({...filters, page: 1})
      setTrigger(trigger + 1)
      };
    let buttons = [
    {
        button: < img
            alt="btn"
            src={Lupa}
        >
        </img>,
    },
    {
        button: < img
            alt="btn"
            style={{
                transform: !rotate && "rotate(180deg)"
            }}
            width={10}
            src={flecha}
        >
        </img>,
    },
    ]
    const downloadPdf = (id) => {
        getPdfOrder({
            url: "/medical/pdfReference/",
            objFilters: { id: id},
            token: token,
            doAfterSuccess: (res) => {
                if(!!res.success){
                    downloaFile(res?.results[0].base64)
                }
            }
        });
    };

    const formatData = (array) => {
        let tempList = [];
        if (Array.isArray(array)) {
          array.forEach((item, index) => {    
            tempList.push(
              <tr key={index} className={`hover-table-row`}>
                <td className="text-start px-2 ">{item?.patient?.document}</td>
                <td className="text-start px-2">{item?.patient?.name}</td>
                <td className="text-start px-2">{item?.corporateClient?.name}</td>
                <td className="text-start px-2">{item?.typeRef}</td>
                <td className="text-start px-2">{item?.corporateClient?.contractName}</td>
                <td className={` ${tableStyles.ordHoverBlueText} text-start px-2`}>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipDoctor({ item })}
                  >
                    <span>{item?.doctor?.name}</span>
                  </OverlayTrigger>
                </td>
                <td className="text-center">
                  {item?.createdAt}
                </td>
                <td>
                  <CustomPopupExtend
                    triggerSrc={threeDots}
                    showDetails={true}
                    textDetails="Detalle"
                    showDetailsEvent={() =>
                      setFullDialogScreen({
                        show: true,
                        id: item.id,
                      })
                    }
                    extraButtons={
                    [
                        {
                        text: "Descargar PDF",
                        icon: pdfIcon,
                        class: "",
                        event: () => {downloadPdf(item.id); 
                        }
                    }
                        
                    ]
                    }
                  ></CustomPopupExtend>
                </td>
              </tr>
            );
          });
        }
        return tempList;
      };

    // --- GET REFERENCES HISTORY-----
    useEffect(() => {
        getReferencesHistory({
            url: "/medical/reference/",
            objFilters: filters,
            token: token,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);
    // ------------MULTIGET FILTERS------------
    useEffect(() => {
        getUnities({
          multipleGet: [
            {
              // ---------DOCTOR-----------
              url: "/medical/generals/doctors/",
              requestName: "doctors",
            },
            {
              // ---------COMPANY-----------
    
              url: "/medical/generals/company/",
              requestName: "company",
            },
            {
              // ---------CONTRACT-----------
              url: "/medical/medicine/getContract/",
              objFilters: { slim: 1 },
              requestName: "contracts",
            },
            {
              // ---------REFERENCE TYPES-----------
              url: "/medical/referecenType/",
              requestName: "referenceTypes",
            },
          ],
          token: token,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    return (
      <div className="d-flex w-100 mx-auto container pt-3">
        {referencesLoader && loader}
        {referencesOrderLoader && loader}
        {unitiesLoader && loader}
        <FullScreenDialog
        onHide={() => setFullDialogScreen(false)}
        isOpen={fullDialogScreen.show}
      >
        <RenderMedicalFormulasPdf
          pdfParams={{
            url: "/medical/pdfReference/", //ESPERAR URL
            id: fullDialogScreen.id,
          }}
          title={"Referencia y contrarreferencia"}
          backAction={() => {
            // setData({
            //   observations: "",
            // });
            setFullDialogScreen(false);
          }}
          withPagination={true}
        />
        </FullScreenDialog>
        <OrdGenericTemplate
          title={"Historial de referencia "}
          titleSize={9}
          showBackArrow={true}
          backArrowAction={() => history.push("/ordenamientos/Consultas")}
        >
        <OrdSlideDownButton
          onToggleClick={() => setRotate(!rotate)}
          buttons={buttons}
          accordionClassName={`${ordComponentStyles.OrdSlideButton} mr-1`}
        >
            <>
              <Row>
                <Col xs={3} className="">
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Fecha desde</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                      type="date"
                      placeholder=""
                      value={filters.startDate}
                      onChange={(e) => {
                        setFilters({ ...filters, startDate: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3} className="">
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Fecha hasta</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                      type="date"
                      placeholder=""
                      value={filters.endDate}
                      onChange={(e) => {
                        setFilters({ ...filters, endDate: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3} className="">
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">
                      &nbsp;Identificación del paciente
                      </b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput ${tableStyles.ordGrayInput}`}
                      autoComplete="off"
                      type="number"
                      style={{fontSize: '13.5px'}}
                      placeholder="Escribe..."
                      value={filters.documentPatient}
                      onKeyDown={e=>{
                        if (e.key === 'Enter') {
                          searchButton()
                      } 
                      }}
                      onChange={(e) => {
                        setFilters({ ...filters, documentPatient: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xs={3} className="">
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Médico</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={doctorOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => {
                        setFilters({ ...filters, doctor: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col xs={3} className="">
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Empresa</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={companiesOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => {
                        setFilters({ ...filters, company: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3} className="">
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Contrato</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={contractsOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => {
                        setFilters({ ...filters, contract: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3} className="">
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Tipo</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={referenceTypesOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => {
                        setFilters({ ...filters, typeRef: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={1} className="" style={{ alignSelf: "center" }}>
                <button
                  onClick={() => {
                    searchButton();
                  }}
                >
                  <img
                    src={Lupa2}
                    className="hoverPointer mt-4"
                    alt="lupa"
                    style={{ width: "25px", height: "25px" }}
                  />
                </button>
                </Col>
              </Row>
            </>
        </OrdSlideDownButton>
        <OrdTable
          headers={headers}
          hasChildren={true}
          shadow={true}
          paginate={{
            activePage: filters?.page,
            totalPages: referencesHistory?.rowTotal,
            perPage: filters?.perpage,
            pageRangeDisplayed: 3,
            onChangePage: async (e) => {
              const result = await getReferencesHistory({
                url: "/medical/reference/",
                objFilters: { ...filters, page: e },
                token: token,
              });
              setFilters({ ...filters, page: e });
              formatData(result?.results);
            },
            showTextDetails: true,
          }}
        >
          {formatData(referencesHistory.results)}
        </OrdTable>
        </OrdGenericTemplate>
      </div>
    );

}
