import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import moment from "moment";
import atrasIcon from "../../assets/img/icons/atras-icon.svg";
import Inventory from "./Inventory.module.scss";
import GenericTableNew from "../Layouts/GenericTableNew";
import Search from "../../assets/img/icons/lupa.svg";
import { useHistory } from "react-router-dom";
import ModalNew from "../Layouts/ModalNew";
import Select from "react-select";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import Pagination from "react-js-pagination";
import paginationStyles from "../Layouts/pagination.module.scss";
import { formatToRcSelect, message } from "../../helpers/helpers";

import { SkeletonTemplate } from "../Layouts/SkeletonTemplate";
import Swal from "sweetalert2";
import grayOutPut from "../../assets/img/icons/grayOutPut.svg";
import {
  getWareHouseList,
  getArticlesInWarehouse,
  creatMovement,  
  getInventoryAdjustmentType,
} from "../../actions/inventoryActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import deepcopy from "deepcopy";
import withReactContent from "sweetalert2-react-content";
import CustomNumUpDown from "../Layouts/CustomNumUpDown/CustomNumUpDown";
import Agregar from "../../assets/img/icons/circleAdd.svg";
import IndividualStyles from "../TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Add from "../../assets/img/icons/crearCuenta.svg";
import canecaGris from "../../assets/img/icons/canecaGris.svg";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
export const EntryAdjusment = () => {
  const [disableInput, setDisableInput] = useState(true);
  const store = useSelector((state) => state);
  const arrWarehouse = store.inventoryReducer.wareHouseList;
  const arrArticles = store.inventoryReducer.articles;
  const [selectedList, setselectedList] = useState([]);
  const [justification, setJustification] = useState("");
  const [details, setDetails] = useState("");
  const [itemCheck, setItemCheck] = useState([]);
  const [lots, setlots] = useState([]);
  const [saveList, setSaveList] = useState([]);
  const [allow, setAllow] = useState(true);
  const [trigger, setTrigger] = useState(1);
  const [search, setSearch] = useState({ search: "" });
  const [adjustmentType, setAdjustmentType] = useState(0);
  const [arrId, setArrId] = useState([]);
  const [filters, setFilters] = useState({
    id_warehouse: "",
    page: 1,
    perpage: 10,
    search: "",
  });
  let curr = new Date();
  curr.setDate(curr.getDate());
  let date = curr.toISOString().substr(0, 10);

  const Addlots = () => {
    if (lotsInfo.lot === "" || lotsInfo.date_expiry === "") {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Por favor ingrese una fecha de vencimiento y un número de lote",
        showCancelButton:false,
      });
    } else {
      let temlots = lots;
      temlots.push({
        date_expiry: lotsInfo.date_expiry,
        lot: lotsInfo.lot,
        qty_received: lotsInfo.qty_received,
      });
      setlots(temlots);
      setlotsInfo({
        lot: "",
        date_expiry: "",
        qty_received: 1,
        id_article: "",
      });
    }
  };
  const [lotsInfo, setlotsInfo] = useState({
    lot: "",
    date_expiry: "",
    qty_received: 1,
  });
  const [modalLotsControl, setmodalLotsControl] = useState({
    show: false,
  });
  const MySwal = withReactContent(Swal);

  let i = 0;
  let history = useHistory();
  const [fillpTable, setFillpTable] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWareHouseList(store.loginReducer.currentAccount.id));
    dispatch(getInventoryAdjustmentType({ eaccount: store.loginReducer.currentAccount.id, type:"input"}));
  }, []);
  useEffect(() => {
    if (filters.id_warehouse !== "") {
      dispatch(getArticlesInWarehouse(filters));
    }
  }, [filters.page, trigger]);

  let arrWh = formatToRcSelect(
    arrWarehouse,
    "id_warehouse",
    "description_warehouse"
  );

  let inventoryAdjustmentOptions = [{value:null, label:"Seleccionar..."}];
  if(Array.isArray(store.inventoryReducer.inventoryAdjustmentType)){
    store.inventoryReducer.inventoryAdjustmentType.filter(item=>item.sign === "+").map((item, index)=>{
      inventoryAdjustmentOptions.push({
        ...item,
        value: item.id,
        label: item.name,
      })
    })
  }


  const [modalArtControl, setmodalArtControl] = useState({
    show: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
    showJustification: false,
  });

  const localStyles = {
    blueInputText: {
      color: "#005dbf",
    },
    footerStyles: {
      display: "flex",
      justifyContent: "flex-end",
      width: "310%",
    },
    noBorder: { border: "none", padding: "8px" },
  };
  const editArticleAmmount = (lot, ammount) => {
    let temp = [...lots];
    if (Array.isArray(temp)) {
      let target = temp.findIndex((x) => x.lot === lot);
      if (!isNaN(target)) {
        temp[target].qty_received = Math.max(1, ammount);
        setlots(temp);
      }
    }
  };

  const [renderTableLots, amount_received] = useMemo(() => {
    let table = [];
    let amount_received = 0;
    let num_lots = 0;
    if (lots.length > 0) {
      table = lots.map((x, index) => {
        amount_received = amount_received + x.qty_received;
        num_lots = num_lots + 1;
        return (
          <tr key={"lots" + index}>
            <td>{moment(x.date_expiry).format("DD/MM/YYYY")}</td>
            <td style={{ textTransform: "uppercase" }}>{x.lot}</td>
            <td>
              <CustomNumUpDown
                id={x.lot}
                value={x.qty_received}
                onChange={editArticleAmmount}
              ></CustomNumUpDown>
            </td>
          </tr>
        );
      });
    }
    return [table, amount_received];
  }, [editArticleAmmount, lots]);

  const pTableListElem = () => {
    let elemMap;
    if (saveList !== undefined) {
      const elem2 = saveList;
      elemMap = elem2.map((elem, i) => {
        return pTableRenderElem(elem, i);
      });
    }
    return elemMap;
  };
  const isBoxSelected = (elem) => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find((x) => x.id_article === elem.id_article);
      return !!target;
    }
  };
  const deleteItems = () => {
    let templist = [...selectedList];
    templist = templist.filter(
      (fullelement) => itemCheck.indexOf(fullelement.id_article) === -1
    );
    setselectedList(templist);
    setSaveList(templist);
  };
  const changeBoxSelected = (item) => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find((x) => x.id_article == item.id_article);
      if (!!target) {
        tempSelected = tempSelected.filter(
          (x) => x.id_article != item.id_article
        );
      } else {
        let newChange = {
          ...item,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };
  const isBoxAllSelected = () => {
    let res = true;
    let counting = 0;
    if (Array.isArray(arrArticles) && Array.isArray(selectedList)) {
      arrArticles.map((item) => {
        if (selectedList.find((itemb) => item.id_article == itemb.id_article)) {
          counting++;
        } else {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = selectedList;
      if (Array.isArray(arrArticles) && Array.isArray(tempSelected)) {
        arrArticles.map((x) => {
          let found = selectedList.find(
            (obj) => obj.id_article === x.id_article
          );
          if (found?.id_article !== x.id_article) {
            let newChange = {
              ...x,
            };
            tempSelected.push(newChange);
          }
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };
  const listElem = () => {
    let elemMap;
    if (arrArticles !== undefined) {
      const elem2 = arrArticles;
      elemMap = elem2.map((elem, index) => {
        return renderElement(elem, index);
      });
    }
    return elemMap;
  };

  const pTableRenderElem = (elem, i) => {
    return (
      <tr key={"art" + elem.id_article}>
        <td className="col-md-1 text-center">
          <input
            key={"checkbox" + i + "-" + itemCheck.length}
            type="checkbox"
            checked={!!itemCheck.find((myitem) => myitem === elem.id_article)}
            name=""
            id=""
            onChange={(e) => checkToDelete(e, elem, i)}
            // checked={isBoxSelected(elem)}
            // onChange={(e) => changeBoxSelected(elem)}
          />
        </td>
        <td className="col-md-1 text-center">{i + 1}</td>
        <td className="col-md-2 text-center">{elem.id_article}</td>
        <td className="col-md-2 text-start">{elem.description_article}</td>
        <td className="cursorPointer">
          <img
            src={Agregar}
            alt="btnAgregar"
            onClick={() => handleLots(elem)}
          ></img>
        </td>
        <td className="col-md-2 text-center">
          {elem?.amount_received ? elem.amount_received : "-"}
        </td>
      </tr>
    );
  };
  const renderElement = (elem, index) => {
    return (
      <tr key={"article" + index}>
        <td className="col-md-1 text-center">{elem.id_article}</td>
        <td className="col-md-6 text-start">{elem.description_article}</td>
        <td className="col-md-1 text-center">
          <input
            type="checkbox"
            name=""
            id=""
            checked={isBoxSelected(elem)}
            onChange={() => changeBoxSelected(elem)}
          />
        </td>
      </tr>
    );
  };
  const checkToDelete = (e, elem) => {
    let checked = e.target.checked;

    let nueva = [...itemCheck];
    if (checked) {
      nueva.push(elem.id_article);
    } else {
      nueva = nueva.filter((x) => x !== elem.id_article);
    }
    setItemCheck(nueva);
  };

  useEffect(() => {
    if (selectedList.length > 0) {
      selectedList.map((e) => {
        if (e.lots?.length > 0) {
          e.lots?.map(() => {
            setAllow(false);
          });
        } else {
          setAllow(true);
        }
      });
    }
  }, [selectedList, lotsInfo]);
  const secondRenderElement = (elem, index) => {
    return (
      <tr key={"elem" + index}>
        <td className="col-md-2 text-center">{elem.code}</td>
        <td className="col-md-4 text-start">{elem.desc}</td>

        <td className="col-md-1 text-center">
          <u
            onClick={() =>
              setmodalArtControl({
                ...modalArtControl,
                showThird: true,
              })
            }
          >
            <b className={tableStyles.darkBlueText}>{elem.lote}</b>
          </u>
        </td>
        <td className="col-md-2 text-center">{elem.cant}</td>
        <td className="col-md-2 text-center">{elem.canRecv}</td>
      </tr>
    );
  };
  const thirdRenderElement = (elem, index) => {
    return (
      <tr key={"elem2" + index}>
        <td className="col-md-2 text-center">
          {moment(elem.validity).format("DD/MM/YYYY")}
        </td>
        <td className="col-md-4 text-center">{elem.lote}</td>
        <td className="col-md-2 text-center">{elem.cant}</td>
      </tr>
    );
  };
  const confirmation = (id) => {
    customSwaltAlert({
      icon: "warning",
      title: "¿Está seguro?",
      text: "Revise la información",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (selectedList.length > 0) {
          selectedList.map((e, i) => {
            if (e.lots?.length > 0) {
              e.lots?.map((e, i) => {
                setmodalArtControl({
                  ...modalLotsControl,
                  showJustification: true,
                });
              });
            } else {
              setmodalArtControl({
                ...modalLotsControl,
                showJustification: false,
              });
              return message(
                "warning",
                "Intente de nuevo",
                "Todos los articulos deben tener como minimo un lote", undefined, true
              );
            }
          });
        }
        // handleBack();
      }
    });
  };
  const pTableIsBoxAllSelected = () => {
    let res = true;
    let counting = 0;
    if (Array.isArray(selectedList) && Array.isArray(itemCheck)) {
      selectedList.map((item) => {
        if (itemCheck.find((itemb) => item.id_article == itemb)) {
          counting++;
        } else {
          res = false;
        }
      });
    }
    return res;
  };

  const pTableCheckThemAll = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(selectedList) && Array.isArray(tempSelected)) {
        selectedList.map((x) => {
          let id = x.id_article;
          let newChange = id;
          tempSelected.push(newChange);
        });
        setItemCheck(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setItemCheck([]);
    }
  };
  const renderHeadersPTable = [
    <th className="col-md-1" style={{ borderTopLeftRadius: "10px" }}>
      <input
        type="checkbox"        
        checked={pTableIsBoxAllSelected()}
        onChange={() => pTableCheckThemAll(!pTableIsBoxAllSelected())}
      />
    </th>,
    <th className="col-md-1">Item</th>,
    <th className="col-md-1">Código</th>,
    <th className="col-md-6">Descripción de articulo</th>,
    <th className="col-md-1">Lote</th>,
    <th style={{ borderTopRightRadius: "10px" }}>Cantidad</th>,
  ];
  const renderHeadersFirst = [
    <th style={{ borderTopLeftRadius: "10px" }} className="px-2">Código</th>,
    <th className="text-start">Nombre</th>,

    <th style={{ borderTopRightRadius: "10px" }}>
      <input
        type="checkbox"
        name=""
        id=""
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
  ];

  const renderHeadersThird = [
    <th style={{ borderTopLeftRadius: "10px" }}>Vencimiento</th>,
    <th>Lote</th>,
    <th style={{ borderTopRightRadius: "10px" }}>Cantidad</th>,
  ];
  /* -------------------------- HANDLE CHANGE SECTION ------------------------- */
  const handleBack = () => {
    history.push("/compras/registrarMovimiento");
  };
  const handleClickEntrybyStorage = () => {
    setmodalArtControl({
      ...modalArtControl,
      show: true,
    });
    dispatch(getArticlesInWarehouse(filters));
  };
  const handleBring = () => {
    let selListCopy = deepcopy(selectedList);
    selListCopy.forEach(article => {
      if (Array.isArray(article?.lotList)){
        article.lotList.forEach(x => {
          lots.push({
            date_expiry:x?.lotDateExpiry,
            lot:x.lot_name,
            qty_received:x.lot_qty_received
          })
        })
      }
    })
    setSaveList(selListCopy);
    setFillpTable(true);
    setDisableInput(false);
    setmodalArtControl({
      ...modalArtControl,
      showSecond: false,
      show: false,
    });
  };
  const handleLots = (elem) => {
    if (elem.lots?.length > 0) {
      setlots(elem.lots);
    }
    // dispatch(getOutputArticles(elem.id_warehouse_articles));
    setmodalLotsControl({
      show: true,
      product: elem.description_article,
      id: elem.id_article,
    });
    setmodalArtControl({
      ...modalArtControl,
      showLots: true,
    });
  };
  const handleItemsToAdd = (id_article) => {
    if (lots.length > 0) {
      let temparray = deepcopy(selectedList);
      let target = temparray.findIndex((x) => x.id_article === id_article);
      if (!isNaN(target)) {
        temparray[target].amount_received = Math.max(1, amount_received);
        temparray[target].lots = lots;
        setselectedList(temparray);
        setSaveList(temparray);
        setmodalArtControl({
          ...modalArtControl,
          showLots: false,
        });
      }
    } else {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Por favor ingrese los datos necesarios para registrar el lote",
        showCancelButton: false,
      });
    }

    setlots([]);
    setlotsInfo({ lot: "", date_expiry: "", qty_received: 1, id_article: "" });
    setmodalLotsControl({ show: false });
  };

  const cancelLots = () => {
    setmodalLotsControl({ show: false });
    setlots([]);
    setmodalArtControl({
      ...modalArtControl,
      showLots: false,
    });
  };

  const btnSaveLots = (id) => {
    if (lots.length > 0) {
      let temparray = deepcopy(selectedList);
      let target = temparray.findIndex((x) => x.id_article === id);
      if (!isNaN(target)) {
        temparray[target].qty_received = Math.max(1, amount_received);
        temparray[target].lots = lots;
        setselectedList(temparray);
      }
    } else {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Por favor ingrese los datos necesarios para registrar el lote",
        showCancelButton: false,
      });
    }

    setlots([]);
    setlotsInfo({ lot: "", date_expiry: "", qty_received: 1, id_article: "" });
    setmodalLotsControl({ show: false });
  };

  const btnCreatMovement = () => {
    setmodalArtControl({ ...modalArtControl, showJustification: false });
    dispatch(
      creatMovement({
        wm_account: store.loginReducer.currentAccount.id,
        wm_justification: justification,
        wm_observations: details,
        created_by: store.loginReducer.user_data.id,
        wm_movement_type: "inputSetting",
        wm_destination: filters.id_warehouse,
        wm_status: "legalized",
        articles: selectedList,
        adjustmentType: adjustmentType,
      })
    );
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setTrigger(trigger + 1);
    setFilters({
      ...filters,
      page: 1,
      search: search.search,
    });
  };
  return (
    <>
      {/* MODAL JUSTIFICACIÓN DE DENEGACIÓN*/}
      <ModalNew
        title="Justificación"
        show={modalArtControl.showJustification}
        btnYesName={justification !== "" && "Guardar"}
        btnNoName={"Cancelar"}
        size="400"
        btnYesEvent={justification !== "" ? () => btnCreatMovement() : null}
        btnYesDisabled={false}
        onHide={() =>
          setmodalArtControl({ ...modalArtControl, showJustification: false })
        }
        btnNoEvent={() =>
          setmodalArtControl({ ...modalArtControl, showJustification: false })
        }
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>
          ¿Porqué es necesario hacer este ajuste?
        </p>
        <textarea
          placeholder={"Escribir..."}
          id="txtArea"
          rows="10"
          cols="10"
          onChange={(e) => setJustification(e.target.value)}
          value={justification}
          style={{ height: "4rem" }}
          className={`register-inputs text-secondary`}
        ></textarea>
      </ModalNew>
      {/* /* ------------------------------- First modal ------------------------------ */}
      <ModalNew
        size="700"
        btnYesEvent={selectedList.length > 0 ? () => handleBring() : null}
        title="Artículos"
        btnYesName={"Agregar"}
        show={modalArtControl.show}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            show: false,
          })
        }
      >
        <Row className="d-flex">
          <Col xs={5}>
            <form onSubmit={(e) => handleSearch(e)}>
              <p className={tableStyles.crudModalLabel}>&nbsp;</p>
              <input
                placeHolder="Escribir..."
                className="register-inputs text-secondary"
                name="id_consumption_center"
                type="text"
                value={search.search}
                onChange={(e) =>
                  setSearch({ ...search, search: e.target.value })
                }
              />
            </form>
          </Col>

          <Col xs={1} className={`pt-3`} style={{ "align-self": "end" }}>
            <div>
              <img
                src={Search}
                className={"cursorPointer"}
                alt="Search icon"
                height={24}
                onClick={(e) => handleSearch(e)}
              />
            </div>
          </Col>
        </Row>

        <GenericTableNew dark={true} headers={renderHeadersFirst}>
          {listElem()}
        </GenericTableNew>

        <div
          className={`${paginationStyles.wrapper}`}
          style={localStyles.endPagination}
        >
          <p className={paginationStyles.paginationText}>
            Pag. {store.inventoryReducer.articles ? filters.page : ""}
            {" de "}
            {Math.ceil(store.inventoryReducer.articles_total / filters.perpage)
              ? Math.ceil(
                  store.inventoryReducer.articles_total / filters.perpage
                )
              : ""}{" "}
            ({store.inventoryReducer.articles_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={store.inventoryReducer.articles_total}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </ModalNew>

      {/* /* ------------------------------ Third modal ------------------------------ */}
      <ModalNew
        title={"Lotes"}
        show={modalArtControl.showLots}
        btnYesName={lots.length > 0 && "Aceptar"}
        btnNoName={"Cancelar"}
        btnYesEvent={
          lots.length > 0 ? () => handleItemsToAdd(modalLotsControl?.id) : null
        }
        btnNoEvent={() => cancelLots()}
        size="sz"
        onHide={() => cancelLots()}
      >
        <div
          id="arriba"
          className={`${Inventory.backgroundModal} ${Inventory.paddingButton} px-2`}
        >
          {/* ---------------------------- ROW 1 ---------------------------------- */}
          <label className={`${IndividualStyles.subtitle} `}>
             Información del producto  
          </label>
          <div className="d-flex">
            <div className={` ${IndividualStyles.ItemSmall}`}>
              <p className={IndividualStyles.crudModalLabelGris}>Producto</p>
              <input
                className={` ${IndividualStyles.registerInputs} text-secondary`}
                name="auction_title"
                type="text"
                value={modalLotsControl.product}
                readOnly
              />
            </div>
          </div>
        </div>
        {/* ============================= ROW 2 =================================== */}
        <div
          id="bajo"
          className={`${Inventory.backgroundModal_new} ${Inventory.paddingButton}`}
        >
          <div className="d-flex">
            <div className={`${IndividualStyles.ItemSmall1}`}>
              <p className={tableStyles.crudModalLabel}>Vencimiento</p>
              <input
                style={{ textTransform: "uppercase" }}
                className={`${IndividualStyles.registerInputsBlue}`}
                name="delivery_date"
                type="date"
                value={lotsInfo.date_expiry}
                onChange={(e) =>
                  setlotsInfo({
                    ...lotsInfo,
                    date_expiry: e.target.value,
                  })
                }
              />
            </div>
            <div
              className={` ${Inventory.ItemTiny_cantidad} ${IndividualStyles.inputMarginleft}`}
            >
              <p className={` ${IndividualStyles.crudModalLabel}`}>No. Lote</p>
              <input
                style={{ textTransform: "uppercase" }}
                className={`${IndividualStyles.registerInputsGris}`}
                name="qty"
                type="text"
                value={lotsInfo.lot}
                onChange={(e) =>
                  setlotsInfo({
                    ...lotsInfo,
                    lot: e.target.value.toUpperCase(),
                  })
                }
                min={0}
              />
            </div>
            <div
              className={`${IndividualStyles.Aling} ${IndividualStyles.ItemTiny2}`}
            >
              <span> ‏‏‎</span>
              <img
                src={Add}
                onClick={() => Addlots()}
                alt="añadir"
                width="5%"
                className={`${IndividualStyles.size} ${IndividualStyles.btn} ${IndividualStyles.imagePadding2}`}
              />
            </div>
          </div>
        </div>
        <GenericTableNew headers={renderHeadersThird}>
          {renderTableLots}
        </GenericTableNew>
      </ModalNew>

      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        <Row>
          <Col xs={10}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={atrasIcon}
                className={`mr-2 mb-2 cursorPointer`}
              ></img>
              Ajuste de entrada
              <img width="3%" src={grayOutPut} className={`ml-3`}></img>
            </h1>
          </Col>
        </Row>
        <Row className=" d-flex">
          <Col xs={3} className="align-self-end">
            <p className={tableStyles.crudModalLabel}>Almacén</p>
            <Select noOptionsMessage={() => 'No hay datos'}
              options={arrWh}
              className={"text-secondary"}
              defaultValue={filters.id_warehouse}
              onChange={(e) => {
                setSaveList([]);
                setFilters({ ...filters, id_warehouse: e.value });
                setselectedList([]);
              }}
              placeholder="Seleccionar..."
              styles={customSelectNewDark}
            />
          </Col>
          <Col xs={3} className="align-self-end">
            <p className={tableStyles.crudModalLabel}>Tipo de Ajuste</p>
            <Select noOptionsMessage={() => 'No hay datos'}
              options={inventoryAdjustmentOptions}
              className={"text-secondary"}
              value={inventoryAdjustmentOptions.find((x)=> x.value==adjustmentType)}
              onChange={(e) => {
                setSaveList([]);
                setAdjustmentType(e.value);
                setselectedList([]);
              }}
              placeholder="Seleccionar..."
              styles={customSelectNewDark}
            />
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Traer artículos</p>
            <input
              placeholder={"Buscar..."}
              onClick={() => handleClickEntrybyStorage()}
              className={` ${tableStyles.articleSearch} register-inputs cursorPointer`}
              name="requisition_date"
              type="text"
              disabled={filters.id_warehouse === "" ? true : false}
            />
          </Col>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel}`}>Fecha</p>
            <input
              disabled={true}
              className="register-inputs text-secondary"
              name="requisition_date"
              type="date"
              value={date}
              // value={disableInput ? "" : fillInputs.a}
            />
          </Col>
          {fillpTable && (
            <Col
              xs={12}
              className="d-flex"
              style={{ justifyContent: "flex-end" }}
            >
              <img
                width="1.5%"
                src={canecaGris}
                className="cursorPointer"
                alt="btnAgregar"
                onClick={(e) => deleteItems(e)}
              ></img>
            </Col>
          )}
          <Col xs={12}>
            {fillpTable ? (
              <>
                <GenericTableNew dark={true} headers={renderHeadersPTable}>
                  {pTableListElem()}
                </GenericTableNew>
                <Row className={`w-100 m-0`}>
                  <Col xs={9} className={`${tableStyles.clearBlueBg} px-3`}>
                    <p className={`${tableStyles.crudModalLabel} pt-2`}>
                      Observaciones
                    </p>
                    <textarea
                      onChange={(e) => setDetails(e.target.value)}
                      style={{ height: "3rem" }}
                      rows="10"
                      cols="10"
                      className="register-inputs text-secondary"
                    ></textarea>
                  </Col>
                </Row>

                <div className={`customTabs__Footer mt-5`}>
                  <Button
                    onClick={() => confirmation()}
                    className={tableStyles.btnPrimary}
                    // disabled={allow}
                  >
                    Guardar
                  </Button>
                  <Button
                    // onClick={props.hideForm}
                    variant="outline-primary"
                    className={tableStyles.btnSecondary}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            ) : (
              <>
                <SkeletonTemplate></SkeletonTemplate>
                <div className={`customTabs__Footer mt-5`}>
                  <Button className={tableStyles.btnPrimary} disabled={true}>
                    Guardar
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleBack()}
                    className={tableStyles.btnSecondary}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
