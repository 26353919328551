import Attach from "../../assets/img/icons/insertar.svg";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { MAX_SIZE_FILE } from "../../helpers/constants";
import Dropzone from 'react-dropzone';
import withReactContent from "sweetalert2-react-content";
import IndividualStyles from "../TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Excel from "../../assets/img/icons/excel.svg";
import Word from "../../assets/img/icons/word.svg";
import PDF from "../../assets/img/icons/pdf.svg";




const MULTIPLE_REGEX_VALID_FILE = /^.+\.(([pP][dD][fF]))|(xlsx|xls|csv)|(doc|docx)$/;

// let num=0;
const type_file= "csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, \
                application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, \
                application/pdf";

function FileInput (props){

    const MySwal = withReactContent(Swal);
    
    const [filenameState, setfilenameState] = useState({  
      name_doc: props.name_doc? props.name_doc: "",
      type_doc: props.type_doc? props.type_doc:"",
    });

    // const validateFile = async (acceptedFile) => {
    //   let fileValid=true;
    //   let type;
    //   let file_nanme;
    //   let valuesave;
    //   let fileUpload;

    //   if (acceptedFile[0].size > MAX_SIZE_FILE) {
    //     fileValid = false
    //     MySwal.fire({
    //       icon:'error',
    //       title:"El archivo es demasiado pesado",
    //       footer:"El tamaño máximo permitido es de 5MB"
    //     })
    //   } 
      
    //   if (new RegExp(MULTIPLE_REGEX_VALID_FILE).test(acceptedFile.type)) {
    //     fileValid = false
    //     MySwal.fire({
    //       icon:'error',
    //       title:"El formato del archivo debe ser PDF, WORD, EXCEL",
    //       footer:"El tamaño máximo permitido es de 5MB"
    //     })
    //   } else{
    //     fileValid = true;
    //   }    

    //   if (fileValid) {
        
    // onChangeDoc(acceptedFile)
        
    //   }
      
    // }


    const docType=(data) => {
        let iconDoc
        if(!!data){   
            switch(data){
                case "application/pdf":
                    iconDoc=PDF
                    break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/msword":
                    iconDoc=Word
                    break;
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "csv" || "application/vnd.ms-excel":
                    iconDoc=Excel
                    break;
                default:
                    iconDoc=PDF
                    break;
            }
        }
        return iconDoc;
    }


    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
  
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };


    const onChangeDoc =  async (file) => {

        const encode = await convertBase64(file);
        const base64 = encode.split("base64,")[1];
        const name_doc= file.name.split(".")[0];
        const extension = file.name.split('.')[1]; 
        const type_doc= file.type;

        setfilenameState({
            encode: encode,
            base64: base64,
            name_doc: name_doc,
            extension: extension,
            type_doc: type_doc,
        });

        props.onChange({
            encode: encode,
            base64: base64,
            full_name: file.name,
            name_doc: name_doc,
            extension: extension,
            type_doc: type_doc,
        });
    }


    

    return <Dropzone 
        //onDrop={ acceptedFile => validateFile(acceptedFile)} 
        onDrop={file => onChangeDoc(file[0]) }
        accept= {type_file}
    >
        {({getRootProps, getInputProps}) => (
            <section 
            className="w-100 p-1 text-center mx-auto"
            style={{
                borderRadius: 10,
                border: "1px solid #7FADDE",
                height: props.height? props.height : "100%"
            }}
            >
                {/*h-100 */}
                <div {...getRootProps()} >
                    <input 
                        {...getInputProps()} 
                        type="file" 
                        name="documents" 
                        onChange={e=>onChangeDoc(e.target.files[0])} 
                        accept= {type_file} 
                        multiple={false}
                    />
                    
                    {!!filenameState.name_doc?
                        <div id="2" className={`text-muted text-center px-3 pt-3 pb-5 ${IndividualStyles.filesDir}`}>
                            <img   
                                src= {docType(filenameState.type_doc)} 
                                alt="icon" 
                                className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.inputMargin}`} 
                            />
                            <p className={``}>{filenameState.name_doc}</p>
                        </div>  
                    : 
                        <p className={`text-muted text-center px-3 pt-3 pb-5 hoverPointer`}>Soltar archivo aquí</p>
                    } 
                    
                    <img   
                        src= {Attach} 
                        alt="añadir" 
                        className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.Aling}`} 
                    />
                </div>
            </section>
        )}
    </Dropzone>;
}

export default FileInput;



