import React, { useEffect, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import Filter from "../../assets/img/icons/filtro.svg";
import Add from "../../assets/img/icons/agregar.svg";
import Export from "../../assets/img/icons/exportar.svg";
import Gear from "../../assets/img/icons/engraneGris.svg";
import GearWhite from "../../assets/img/icons/engraneBlanco.svg";
import buscar from "../../assets/img/icons/buscar.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import lupa from "../../assets/img/icons/lupa.svg";
import agregarEspecialidad from "../../assets/img/icons/agregarEspecialidad.svg";
import Pagination from "react-js-pagination";
import GenericTableNew from "../Layouts/GenericTableNew";
import { connect, useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import GenericModal from "../Layouts/GenericModal";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";


import { functional_get, functional_edit, functional_edit_active, functional_insert, functional_get_niif } from "../../actions/functionalActions";
import "../TableUsers/tableUserStyle.scss";
import popupStyles from "../Layouts/popupEdEdit.module.css";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import CustomPopup from "../Popup/customPopup";
import {customSelectNewDark} from "../Layouts/react-select-custom";

import Select from "react-select";
import ModalNew from "../Layouts/ModalNew";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { getPermission } from "../../helpers/helpers";
import { customSwaltAlert } from "../../helpers";


function TableFunctional(props) {
    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    const listPermission = counter.loginReducer.currentAccount?.profile?.permission
    const myPermission = getPermission({ prefix: 'UnFun', listPermission })
    
    const [unconfirmedFilters, setUnconfirmedFilters] = useState({
        search: "",
        active: ""
    });

    //filtros para el functional_get
    const [filters, setFilter] = useState({
        search: "",
        active: "",
        page: 1,
        perpage: 10,
        eaccount: counter.loginReducer.currentAccount.id
    });

    //estado interno para manejar tanto la data de functional_edit como el modal que lo abre
    const [putState, setPutState] = useState({
        usage: "",
        id: "",
        account: undefined,
        description: "",
        sites: [],
        modalShow: false,
        eaccount: counter.loginReducer.currentAccount.id
    });
    
    const [trigger, setTrigger] = useState(0); //esto es para recargar la pagina


    const [loading, setLoading] = useState(true);
    useEffect(() => {
        //componentDidMount
        const { history } = props
        if (!myPermission?.read) {
            history?.push("/administracion/inicio");
        }
        dispatch(functional_get_niif(counter.loginReducer.currentAccount.id));

    }, []); //only at the start

    useEffect(() => {
        //componentDidMount
        dispatch(functional_get(filters, () => setLoading(false)));

    }, [trigger, filters.page/* , filters.active*/]); //only trigger on start or if this state is changed //filters, props, 

    // useEffect(() => {
    //     const timeOutId = setTimeout(() => dispatch(functional_get(filters)), 500);
    //     return () => clearTimeout(timeOutId);
    // }, [filters.search]); //alternative: change every time filter changes but with a delay
    //pero por que no? si era chevere

    const handleSearchButton = () => {
        setLoading(true);
        setFilter({
            ...filters,
            search: unconfirmedFilters.search,
            active: unconfirmedFilters.active,
            page: 1
        })
        setTrigger(trigger + 1);
    }

    const handleResetPutState = () => {
        setPutState({
            usage: "",
            id: "",
            account: undefined,
            description: "",
            active: "",
            sites: [],
            modalShow: false,
            eaccount: counter.loginReducer.currentAccount.id
        });
    }

    const handleChange = (event) => {
        // setFilter({
        //     ...filters,
        //     search: event.target.value
        // })
        setUnconfirmedFilters({
            ...unconfirmedFilters,
            search: event.target.value
        })
    }

    const filterChangeActive = (event) => {
        // setFilter({
        //     ...filters,
        //     active: event.target.value
        // })
        setUnconfirmedFilters({
            ...unconfirmedFilters,
            active: event.target.value
        })
    }

    const handlePageChange = (val) => {
        if (filters.page !== val) { setLoading(true) }
        setFilter({
            ...filters,
            page: val
        });
        //setTrigger(trigger+1);
    }

    function handleSubmit() {
        if (putState.description == "") {
            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Se requiere descripción',
                showCancelButton: false
            })
            return null;
        }
        if (!Array.isArray(putState.sites) || putState.sites.length < 1) {
            customSwaltAlert({
                icon: 'error',
                title: 'Error',
                text: 'Se requiere una o más sedes',
                showCancelButton: false
            })
            return null;
        }
        if (putState.account.isNaN) {
            customSwaltAlert({
                icon: 'error',
                title: 'Error',
                text: 'Se requiere una cuenta',
                showCancelButton: false
            })
            return null;
        }
        if (putState.usage === "PUT") {
            return dispatch(
                functional_edit(
                    putState.id,
                    putState,
                    () => {
                        handleResetPutState();
                        setTrigger(trigger + 1);
                    }
                )
            )
        }
        if (putState.usage === "POST") {
            return dispatch(
                functional_insert(
                    putState,
                    () => {
                        handleResetPutState();
                        setTrigger(trigger + 1);
                    }
                )
            )
        }
        return null;
    }

    const renderElement = (elem) => {

        return (
            <tr key={elem.id}>
                <td className="col-4 text-start"
                    style={{ paddingLeft: "5px" }}
                >
                    {elem.description}
                </td>
                <td className="col-6 text-center">{elem.account}</td>
                <td className="col-1">
                    <div className={tableStyles.groupElems}>
                        <div className={elem.active ? tableStyles.greenState : tableStyles.redState}>
                            {elem.active ? 'Habilitado' : 'Inhabilitado'}
                        </div>
                    </div>
                </td>
                <td className="col-1">
                    {myPermission?.edit &&
                        <CustomPopup
                            triggerSrc={threeDots}
                            showEdit={myPermission?.edit}
                            editClickEvent={() => setPutState(
                                {
                                    ...putState,
                                    id: elem.id,
                                    account: elem.account,
                                    description: elem.description,
                                    sites: elem.sites,
                                    usage: "PUT",
                                    modalShow: true
                                }
                            )}

                            showEnable={myPermission?.edit}
                            isEnabled={elem.active}
                            enableClickEvent={() => dispatch(
                                functional_edit_active(
                                    elem.id,
                                    { active: !elem.active },
                                    elem.description,
                                    () => setTrigger(trigger + 1))
                            )}
                        />
                    }
                </td>
            </tr>
        );
    }

    const listElem = () => {
        let elemMap;
        if (counter.functionalReducer.units !== undefined) {
            const elem2 = counter.functionalReducer.units;
            elemMap = elem2.map((elem) => {
                return renderElement(elem);
            });

        }
        return elemMap;
    }

    const renderHeaders = [
        <th key={0} className="px-2">Descripción</th>,
        <th key={1} className="text-center">Cta. Contable</th>,
        <th key={2} className="text-center w-100">Estado</th>,
        <th key={3}>ㅤ</th>,
        //<th key={3} className='w-1-rem'></th>,
    ]


    const getNiifName = () => {
        if (isNaN(putState.account)) {
            return "";
        }
        const gotNiif = counter.functionalReducer.niifs;
        const found = gotNiif.find(niif => niif.complete_account === putState.account);
        if (found) {
            return found.description;
        } else {
            return "";
        }
    }

    //=============================================================================================
    //react select input library section ==========================================================

    //const optionsNiifs = counter.functionalReducer.niifs;
    const optionsNiifs = () => {
        let tempNiifs = [];
        if (counter.functionalReducer.niifs !== undefined) {
            const elem2 = counter.functionalReducer.niifs;
            tempNiifs = elem2.map((elem) => {
                return {
                    value: elem.complete_account,
                    label: elem.complete_account + ": " + elem.description
                }
            });
            

        }
        tempNiifs.unshift({ label: 'Seleccionar...', value: '' })
        return tempNiifs;
    }


    const optionsSites = () => {
        const sites = counter.functionalReducer.sites;
        let coolerSites = sites;
        // let data = [{label:'Seleccionar...', value:''}]

        // if(!sites?.length) return data

        if (sites !== undefined) {
            coolerSites = sites.map((elem) => {
                return ({
                    ...elem,
                    isDisabled: ((elem.id_account != counter.loginReducer.currentAccount.id) || (!elem.active))
                })
            }
            )
        }
        coolerSites.unshift({label: "Seleccionar...", value: ""})
        return coolerSites;
    }

    const optionSitesValue = () => {
        let selectedOptions = [];
        putState.sites.forEach(mySiteId => {
            let foundOption = optionsSites().find(site => site.value === mySiteId);
            if (foundOption) {
                selectedOptions.push(foundOption);
            }
        });
        return selectedOptions;
    }

    const optionSitesOnChange = (e) => {
        const newSites = e.map(elem => elem.value);
        setPutState({
            ...putState,
            sites: newSites
        })
    }
    const handleSumit = (e) => {
        e.preventDefault();
        setFilter({
            ...filters,
            search: unconfirmedFilters.search,
            active: unconfirmedFilters.active,
            page: 1
        })
        setTrigger(trigger + 1);
    }

    //END react select input library section ========================================================
    //===============================================================================================

    return (
        <div className={tableStyles.container}>
            {(counter.functionalReducer.loading || loading) && (
                <div className="loading">
                    <Loader type="Oval" color="#003f80" height={100} width={100} />
                </div>
            )}

            <h1 className={tableStyles.title} style={{ marginLeft: "76px" }}>Unidades Funcionales</h1>
            <div className={tableStyles.tableArea}>

                <div className={tableStyles.mainRow}>
                    {
                        myPermission?.read && (
                            <form onSubmit={handleSumit}>
                                {/* <span>
                                    <img src={lupa} alt="User icon" className="filter-svg" />
                                </span> */}
                                <input
                                    className={tableStyles.searchUsersAlt}
                                    type="text"
                                    placeholder="Buscar..."
                                    onChange={handleChange}
                                />

                                {/* <select
                                    className="filter-select-2"
                                    onChange={filterChangeActive}
                                >
                                    <option value="" defaultValue>
                                        Seleccione...
                                    </option>
                                    <option value="1">Activo</option>
                                    <option value="0">Inactivo</option>
                                </select> */}

                                {/* <button className="learn-more">
                                    <span className="circle" aria-hidden="true">
                                        <span className="icon arrow"></span>
                                    </span>
                                    <span className="button-text" onClick={()=>handleSearchButton() }>
                                        Filtrar
                                    </span>
                                </button> */}

                                <button onClick={() => handleSearchButton()} >
                                    <img
                                        src={lupa}
                                        alt="User icon"
                                        className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`} />
                                </button>
                            </form>
                        )
                    }

                    <div className={tableStyles.groupElems}>                            {/* <img 
                                src={Export} 
                                alt="exportar" 
                                className={tableStyles.iconSvg}
                            />  */}
                        {myPermission?.create &&
                            <div
                                className="d-flex justify-content-end groupAddButton align-items-center"
                                style={{ marginLeft: "auto" }}
                                onClick={() => setPutState({ ...putState, usage: "POST", modalShow: true })}                            >
                                <label htmlFor="newAccident" className="darkGray fw-bold">
                                    Crear unidad funcional
                                </label>
                                <button
                                    className="addCheckButton mx-2"
                                    style={{ marginBottom: "1.5px" }}
                                />
                            </div>

                            // <img
                            //     src={Add}
                            //     alt="User icon"
                            //     className={tableStyles.iconSvg}
                            //     onClick={() => setPutState({...putState, usage:"POST", modalShow:true})}
                            // />
                        }
                    </div>
                </div>

                {putState.modalShow ?
                    <ModalNew
                        hideFooter={putState.usage === 'PUT' ? !myPermission?.edit : !myPermission?.create}
                        title="Unidades funcionales"
                        show={putState.modalShow}
                        onHide={() => handleResetPutState()}
                        btnYesEvent={() => handleSubmit()}
                    >

                        <div className="row bottom-margin-10">
                            <p className="label-inputs">Descripción</p>
                            <div className="col-md-12">
                                <input
                                    className="registerInputs2"
                                    name="description"
                                    type="text"
                                    placeholder="Descripción unidad funcional"
                                    defaultValue={putState.description}
                                    onChange={(event) => setPutState({ ...putState, description: event.target.value })}
                                />
                            </div>
                        </div>

                        <div className="row bottom-margin-10">
                            <p className="label-inputs">Cuenta contable</p>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                //defaultValue={putState.account}
                                value={optionsNiifs().filter(option => option.value == putState.account)}
                                onChange={e => setPutState({ ...putState, account: e.value })}
                                options={optionsNiifs()}
                                placeholder="Seleccionar..."
                                styles={customSelectNewDark}
                            />
                        </div>

                        <div className="row bottom-margin-10">
                            <p className="label-inputs">Sedes</p>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                defaultValue={optionSitesValue()}
                                onChange={e => optionSitesOnChange(e)}
                                options={optionsSites()}
                                isMulti
                                placeholder="Seleccionar..."
                                styles={customSelectNewDark}
                            />
                        </div>
                    </ModalNew>
                    : null}

                {
                    myPermission?.read && (
                        <>
                            <GenericTableNew fontFamilyTable={'fontFamilyTable'} headers={renderHeaders} dark={true}>
                                {listElem()}
                            </GenericTableNew>

                            <div className={paginationStyles.wrapper}>
                                <p className={paginationStyles.paginationText}>
                                    Pag. {counter.functionalReducer.total ? filters.page : ""}
                                    {" de "}
                                    {Math.ceil(counter.functionalReducer.total / filters.perpage) ?
                                        Math.ceil(counter.functionalReducer.total / filters.perpage)
                                        :
                                        ""
                                    }
                                    {" "}
                                    ({counter.functionalReducer.total} encontrados)
                                </p>
                                <Pagination
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={counter.functionalReducer.total}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                    itemClassPrev={paginationStyles.itemClassPrev}
                                    itemClassNext={paginationStyles.itemClassNext}
                                    itemClassFirst={paginationStyles.itemClassFirst}
                                    itemClassLast={paginationStyles.itemClassLast}
                                    itemClass={paginationStyles.itemClass}
                                />
                            </div>
                        </>
                    )
                }


            </div>
        </div>
    )
}


export default TableFunctional;