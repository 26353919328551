import React from "react";
import { Col, Row } from "react-bootstrap";

export const OutputByConsum = () => {
  return (
    <>
      <div className="d-flex w-100">
        <h1>h</h1>
      </div>
    </>
  );
};
