import { 
    REQUISITION_ARTICLES_FROM_LIST, 
    REQUISITION_LOADING2, 
    REQUISITION_LOADING3,
    REQUISITION_FILTER, 
    REQUISITION_FILTER_LOADING,
    REQUISITION_POST, 
    REQUISITION_GET, 
    REQUISITION_ARTICLE,
    REQUISITION_LOADING
    } from "./actionTypes";

import { 
    GO_TO_SALIDA_CONSUMO
    } from "./actionTypes";

import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { customSwaltAlert } from "../helpers/customSwaltAlert";
import { loader } from "../helpers/helpers";

const MySwal = withReactContent(Swal)


export const requisition_filter = (objFilters, doAfter) => (dispatch, getState)  => {
    dispatch({
        type: REQUISITION_FILTER_LOADING,
        loading: true,
    })
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/requisitions/?${filters}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {results, row_total} = res
        if(results?.length===0){
            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: "No hay registros para mostrar",
              showCancelButton: false,
            })
        }
        dispatch({
            type: REQUISITION_FILTER,
            results: results,
            row_total: row_total,
            loading: false,
        })
    })
    .catch((err)=>{
        console.error(err)
        dispatch({
            type: REQUISITION_FILTER_LOADING,
            loading: false,
        })
    })
    .then(()=>{
        if(!!doAfter) doAfter();
    })
}

export const requisition_export_pdf = (objFilters) => (dispatch, getState)  => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/requisitions/export/pdf/?${filters}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {key} = res
        if(!!key){
            //get the base64 file and assign it to an <a> element
            const linkSource = `data:application/pdf;base64,${key}`;
            const downloadLink = document.createElement("a");
            const fileName = "Requisiciones.pdf";
    
            //then trigger the <a> element by force
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
    })
    .catch(()=>{
        customSwaltAlert({icon:"warning", title:"Intenta de nuevo", text:"Ocurrió un error al generar el PDF", showCancelButton: false})
    })

    

}

export const requisition_get_one = (id, doAfter) => (dispatch, getState)  => {
    dispatch({
        type: REQUISITION_LOADING,
        loading: true
    })
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/requisitions/${id}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        dispatch({
            type: REQUISITION_GET,
            payload: res,
            loading: false,
        })
    })
    .catch((err)=>{
        console.error(err)
        
    })
    .then(()=>{
        if(!!doAfter) doAfter();
    })
}

export const requisition_clear_one = () => (dispatch, getState)  => {
    dispatch({
        type: REQUISITION_GET,
        loading: false,
        payload: {}
    })
}
  

export const requisition_articles_filter = (objFilters, doAfter) => (dispatch, getState)  => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/requisitions/articles/?${filters}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {results, row_total, res_articles} = res
        dispatch({
            type: REQUISITION_ARTICLE,
            results: results,
            articles: res_articles,
            row_total: row_total
        })
        
    })
    .catch((err)=>{
        console.error(err)        
    })
    .then(()=>{
        if(!!doAfter) doAfter();
    })
}
  


  export const requisition_insert = (data, executeThisAfter, loading = null) => (dispatch, getState)  => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/requisitions/`,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
    .then(response => response.json())
    .then(res => {
        if(loading){
            loading()
        }
        if(res.success){
            customSwaltAlert({
                icon:'success',
                title:'Requisición enviada',
                text:`Requisición No. ${res.num_req}`,
                showCancelButton: false,

            })
        }else{
            customSwaltAlert({
                icon:'warning',
                title:'Intenta de nuevo',
                text:`${res.message}`,
                showCancelButton: false,

            })
        }
    }).then ( ()=>{
        if(!!executeThisAfter){
            executeThisAfter();
        }
    }
    )
    .catch((err)=>{
        console.error(err);
        customSwaltAlert({
            icon:'warning',
            title:'Intenta de nuevo',
            text:'No se pudo pedir la requisición',
            footer:'Si el problema persiste comuníquese con un asesor.',
            showCancelButton: false,

        })
    })
    
      
  }

  export const requisition_update_returnal = (data, executeThisAfter) => (dispatch, getState)  => {
    dispatch({
        type: REQUISITION_LOADING,
        loading: true
    })
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/composite/requisitions/returnal/`,{
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
    .then(response => response.json())
    .then(res => {
        const {resInv, resComs} = res;

        if(resInv.success && resComs.success){
            customSwaltAlert({
                icon:'success',
                title:'Devolución enviada',
                text:`Requisición No. ${data.numreq}`,
                confirmButtonText: "Aceptar",
                showCancelButton: false,

            });
            //.then((result) => {
            //     if (result.isConfirmed || result.dismiss ) {
            //         if(!!executeThisAfter){
            //             executeThisAfter()
            //         }
            //     }
            // });
            if(!!executeThisAfter){
                executeThisAfter()
            }else{
                dispatch({
                    type: REQUISITION_LOADING,
                    loading: false
                })
            }

        }else{
            customSwaltAlert({
                icon:'warning',
                title:'Intenta de nuevo',
                text:`${resInv.message} - ${resComs.message}`,
                showCancelButton: false,

            })
        }
    })
    .catch((err)=>{
        console.error(err);
        // MySwal.fire({
        //     icon:'error',
        //     title:'Error al enviar Devolución',
        //     text:'No se pudo editar la requisición',
        //     footer:'Si el problema persiste comuníquese con un asesor.'
        // })
    })
    
      
  }

  
  

export const requisitions_articlesfromlist = (id_array, getwarehouse = false) => (dispatch, getState)  => {
    dispatch({
        type: REQUISITION_LOADING2,
        loading2: true
    })
    let ids = id_array.join()
    let extra = "";
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/requisitions/articlesfromlist/?req_ids=${ids}&getwarehouse=${!!getwarehouse}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {success, row_total, sub_list} = res;
        let {results} = res;
        if(success){
            if(Array.isArray(results) && Array.isArray(sub_list)  ){
                results = results.map(r=>{
                    let  sublist = sub_list.filter(s=>(s.id_article == r.id_article));
                    return {
                        ...r,
                        sublist: sublist,
                    }
                })
            }   
            dispatch({
                type: REQUISITION_ARTICLES_FROM_LIST,
                payload: results,
                total: row_total,
                loading2: false,
            })
        }else{
            customSwaltAlert({
                icon:'warning',
                title:'Intenta de nuevo',
                text:`${res.message}`,
                showCancelButton: false,

            })
            dispatch({
                type: REQUISITION_LOADING2,
                loading2: false
            })
        }
    })
    .catch((err)=>{
        console.error(err)
        
    })
}


export const set_consolidate_art = (newstate) => (dispatch, getState)  => {
    if(Array.isArray(newstate)){
        dispatch({
            type: REQUISITION_ARTICLES_FROM_LIST,
            payload: newstate,
            total: newstate.length,
            loading2: false,
        })
    }
}

export const consolidate_requisition = (compras, subastas, req_ids, auction, executeThisAfter, executeThisIfError = null, gotosalidaconsumo= null) => (dispatch, getState)  => {
    const token = getState().loginReducer.Authorization;
    const data = {
        compras: compras,
        subastas: subastas, 
        req_ids: req_ids,
        auction: auction,
    }
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/requisitions/consolidate/`,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
    .then(response => response.json())
    .then(res => {
        if(res.success){
            if(!!executeThisAfter){
                executeThisAfter();
            }
            customSwaltAlert({
                icon:'success',
                title:'Productos Solicitados',
                confirmButtonText: 'Ir a Salida para Consumo',
                showConfirmButton: (compras.length>0),
                cancelButtonText: "Cerrar",
                showCancelButton: true,
                html:`(${compras.length}) Productos a Salida para Consumo. <br /> (${subastas.length}) Productos a Subastas Pendientes.`
            }).then((result) => {
                if (result.isConfirmed ) {
                    //poner aca codigo para ir a consumo
                    if(compras.length>0){
                        dispatch({
                            type: GO_TO_SALIDA_CONSUMO,
                            reqIds: req_ids,
                            auction: auction,
                            mainWarehouse: auction.base_warehouse_id,
                            centroConsumo: auction.id_consumption_center,
                        });
                        if(!!gotosalidaconsumo){
                            gotosalidaconsumo()
                        }
                    }
                }
            });
        }else{
            customSwaltAlert({
                icon:'warning',
                title:'Intenta de nuevo',
                text:`${res.message}`
            })
            if(!!executeThisIfError){
                executeThisIfError();
            }
        }
    })
    .catch((err)=>{
        console.error(err);
        customSwaltAlert({
            icon:'warning',
            title:'Intenta de nuevo',
            text:'No se pudo enviar la solicitud',
            footer:'Si el problema persiste comuníquese con un asesor.',
            showCancelButton: false,
        })
        if(!!executeThisIfError){
            executeThisIfError();
        }
    })
    
}