import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
import miniRoundWhiteCheck from "../../assets/img/icons/miniRoundWhiteCheck.svg";
import '../../OrderingModule/orderingStyles.css'
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@material-ui/core';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable'
import { useMultiGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { useSelector } from 'react-redux';
import { formatToRcSelect, getPermission, loader, message, swalConfirm } from '../../helpers/helpers';
import FullScreenDialog from '../OrderingComponents/FullScreenDialog';
import { RenderMedicalFormulasPdf } from '../../AsistanceModule/RenderMedicalFormulasPdf';
export const CombosTab = (event) => {
    const store = useSelector((state) => state);
    const idEnterprise = store.loginReducer.currentAccount.id;
    const listPermission = store.loginReducer.currentAccount?.profile?.permission
    const myPermission = getPermission({ prefix: 'order', listPermission })
    const token = store.loginReducer.Authorization;
    const [data, setData] = useState({
        combo: "",
        observations: "",
    })
    const [fullDialogScreen, setFullDialogScreen] = useState({
        show: false,
        id: ""
    })
    /* ----------------------------- FETCHS SECTION ----------------------------- */
    /* ----------------------------- GET ----------------------------- */
    const { results: initialList, load: initialListLoader, trigger: getInitialList } = useMultiGetMethod()
    /* ---------------------------------- POST /PUT ---------------------------------- */
    const { load: orderComboLoader, trigger: orderCombo } = usePostMethod()
    /* ------------------------------------ - ----------------------------------- */
    const formattedCombos = formatToRcSelect(initialList.comboList?.results, "id", "name", "medicine")
    useEffect(() => {
        getInitialList({
            multipleGet: [
                {
                    url: "/medical/medicationCombo/",
                    objFilters: { idAccount: idEnterprise, enabled: 1 },
                    requestName: "comboList",
                }, {
                    url: "/medical/bodyOrgans/",
                    objFilters: { draId: 1 },
                    requestName: "bodyOrgans",
                }
            ],
            token: token,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const headers = [
        {
            title: <> &nbsp;Medicamento&nbsp;</>,
            className: "text-start col-2"
        },
        {
            title: <> &nbsp;Dosis</>,
            className: "text-start col-1"
        },
        {
            title: "Frecuencia",
            className: "text-start col-2"
        },
        {
            title: "Duración",
            className: "text-start col-2"
        },
        {
            title: "Cantidad",
            className: "text-start col-2"
        },
        {
            title: "Vía",
            className: "text-start col-1"
        },
        {
            title: "Ojo",
            className: "text-center col-2"
        },
        {
            title: "Observaciones",
            className: "text-start col-2"
        },
        {
            title: <>&nbsp;</>,
        },
    ]
    const formatData = () => {
        let tempList = [];
        if (Array.isArray(data.combo?.extra)) {
            data.combo?.extra?.forEach((item, index) => {
                tempList.push(
                    < tr key={index} className={`hover-table-row`} >
                        <td style={{ paddingLeft: "3px" }} className={`text-start col-2 ${tableStyles.pdLeft5px}`}>{item.name}</td>
                        <td className={`text-start ${tableStyles.pdLeft5px}`}>{`${item.mcdQtyDosis} ${item.mcdDosisDescription}`}</td>
                        <td className={`text-start`}> &nbsp;&nbsp;{`Cada ${item.mcdQtyFrequency} ${item.mcdFrequencyDescription}`} </td>
                        <td className={`text-start`}> {`Durante ${item.mcdQtyPeriod} ${item.mcdPeriodDescription}`}</td>
                        <td className={`text-start`}> {` ${item.mcdQtyPresentation} ${item.mcdPresentationDescription} ${item.mdcOrderUnitLabel}`}</td>
                        <td className={`text-start`}> {item.draName}</td>
                        <td className={`text-center`}>&nbsp;{item.dboName === "-" ? "N/A" : item.dboName}</td>
                        <td className={`text-start`}> &nbsp;{item.mcdObservations}</td>
                    </tr >
                );
            });
        }
        return tempList;
    };

    const handleSubmit = () => {
        orderCombo({
            url: "/medical/medicationComboOrder/",
            method: "POST",
            body: {
                clhId: event?.event?.clhId,
                morObservations: data.observations,
                mecId: data.combo?.value,
            },
            noAlert: true,
            token: token,
            succesAction: (results) => {
                setFullDialogScreen({
                    show: true,
                    id: results.results
                })
            }
        })
    }
    return (
        <>
            {/* /* --------------------------------- DIALOG --------------------------------- */}
            <FullScreenDialog
                onHide={() => setFullDialogScreen(false)}
                isOpen={fullDialogScreen.show}
            >
                <RenderMedicalFormulasPdf
                    withPagination
                    pdfParams={{
                        url: '/medical/pdfMedicationComboOrder/',
                        id: fullDialogScreen.id,
                    }}
                    title={'Fórmula de medicamentos en combo'}

                    finalize={{
                        title: "Finalizar",
                        finalizeAction: () => {
                            setData({
                                combo: "",
                                observations: "",
                            });
                            message('success', 'Orden(es) finalizada')
                            setFullDialogScreen(false)
                        }
                    }}
                    backAction={() => {
                        setData({
                            combo: "",
                            observations: "",
                        });
                        setFullDialogScreen(false)
                    }}
                />
            </FullScreenDialog>

            <div className="mx-4 mt-4 pl-5">
                {/* /* ----------------------------- LOADER SECTION ----------------------------- */}
                {initialListLoader && loader}
                {orderComboLoader && loader}
                {/* /* --------------------------- Special form Group --------------------------- */}
                < Form.Group className={`${tableStyles.ordOrangeCard} p-3 mx-5 pt-2 text-start`} controlId="cc">
                    <Row>
                        <Autocomplete
                            noOptionsText={'No se encuentra'}
                            sx={{
                                input: {
                                    color: "#6e6f7c"
                                },
                            }}
                            key={data.combo?.value}
                            value={data?.combo?.label}
                            inputValue={data?.combo?.label}
                            renderOption={(props, option) => {
                                const { label } = option;
                                return (
                                    <span
                                        {...props} style={{ color: '#6e6f7c', borderBottom: '1px solid #cecece' }}>
                                        {label}
                                    </span>
                                );
                            }}
                            onChange={(event, newValue) => {
                                setData({ ...data, combo: newValue })
                            }}
                            id="controllable-states-demo"
                            options={formattedCombos}
                            renderInput={(params) => <TextField
                                placeholder="Nombre del combo"
                                {...params} label="" />}
                        />
                    </Row>
                </Form.Group>

                <div
                    style={{ marginBottom: '48%' }}
                    className={`mx-5 text-start`}>
                    <OrdTable
                        headers={headers}
                        hasChildren={true}
                        className={`w-100`}
                    >
                        {formatData()}
                    </OrdTable>

                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="fName">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                <b className={`text-start pl-1 ${tableStyles.f14}`}> &nbsp; Observación general</b>
                            </Form.Label>
                            <textarea
                                value={data.observations}
                                placeholder={"Escribir..."}
                                rows="25"
                                cols="55"
                                style={{ height: "4rem" }}
                                onChange={(e) => setData({ ...data, observations: e.target?.value })}
                                className={`text-secondary ord-roundInput w-100`}
                            ></textarea>
                        </Form.Group>
                    </Col>
                </div>
                <div className={`${tableStyles.ordBorderTop} mt-2`}
                >
                    <Col xs={12} className="d-flex justify-content-end pt-3 mb-3">
                        <button className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                        {
                            myPermission?.create && (
                                <button
                                    onClick={
                                        () =>
                                            data.combo?.extra?.length > 0 ? swalConfirm({
                                                title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                                                text: `Se creará(n) orden(es) de medicamento(s) para ${event?.event?.firstName} ${event?.event?.lastName}`,
                                                confirmButtonText: "Si, continuar",
                                                doAfterConfirm: () => handleSubmit(),
                                            }) : message('info', 'Sin ordenes')

                                    }
                                    className={`${tableStyles.ordBtnPrimary} btn`}>
                                    <img
                                        src={miniRoundWhiteCheck}
                                        className={`mr-2`}
                                        alt="Check"
                                    >
                                    </img>
                                    Ordenar
                                </button>
                            )
                        }
                    </Col>
                </div>
            </div>
        </>
    )
}