import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { showAccountInfo } from "../../actions/headerActions";
import { getCategories } from "../../actions/loginActions";
import toBack from "../../assets/img/icons/Arrow-black.svg";
import toNext from "../../assets/img/icons/Arrow-gray.svg";
import Header from "../../components/Header/Header";
import { loader } from "../../helpers";
import layoutStyles from "../../routes/fullLayout.module.css";
import styles from "./CategorySelect.module.scss";

function CategorySelect() {
  const counter = useSelector((state) => state);
  const history = useHistory();
  const categories = counter.loginReducer?.newCategories;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!counter.loginReducer.Authenticated) {
      history.push("/login");
    }
  }, [counter.loginReducer.Authenticated, history]);

  useEffect(() => {
    dispatch(getCategories(counter.loginReducer.currentAccount.profile.id));
  }, [counter.loginReducer.currentAccount.profile.id, dispatch]);

  const handleClick = (category, alternative) => {


    history.push(
      {
        pathname: "/" + category?.url + "/inicio",
        state: {
          group: alternative,
          catDetail: {
            ...category,
            title: "Módulo de " + category.title
          }
        }
      }
    );
  };

  return (
    <div className={`${layoutStyles.fullLayout}  ${styles.bgImage} `}>
      {(categories === undefined && counter.loginReducer.loadingCategories) && loader}
      <Header isAuth={true}></Header>
      <div className={`${layoutStyles.fullLayoutBody} `}>
        <div
          className={`p-5 ${layoutStyles.fullLayoutWorkArea}`}
          onClick={() => dispatch(showAccountInfo(false))}
        >
          <div className={`mt-2 ${styles.marginRight}`}>
            <Link to={`/accountselect`}>
              <img
                alt="goBack"
                src={toBack}
              >
              </img>
            </Link>
          </div>
          <div className={`w-100`}>
            {categories?.length > 0 ?
              categories.map((e, i) => {

                return (
                  <Row className="">
                    <label className={`${styles.TitleBar} ${styles.marginRight}`}>
                      {e.description} </label>
                    {e.data?.map((mod, i) => {
                      return (
                        <>
                          <Col
                            key={`Module${i}`}
                            xs={4} >
                            <Card
                              onClick={() => handleClick(mod, e.description)}
                              className={`${styles.flex1} rounded-5 mb-2`}>
                              <Row>
                                <Col xs={3}>
                                  <img alt="icon" src={mod.img} className={styles.classIcon} />
                                </Col>
                                <Col xs={7}>
                                  <p className={`my-auto ${styles.classTitle}`}>
                                    {mod.title}
                                  </p>
                                  <p className={`my-auto ${styles.classSubTitle}`}>{
                                    mod.subtitle?.length >= 55 ? mod.subtitle.slice(0, 55) :
                                      mod.subtitle
                                  }</p>
                                </Col>
                                <Col className="d-flex pb-3" xs={2}>
                                  <img alt="icon" src={toNext} className={`${styles.classArrow} pt-3`} />
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                );
              })
              :
              <div key={0} className={"mt-2"} >
                <span>Este usuario no tiene módulos accesibles</span>
              </div>
            }
          </div>
        </div>
      </div>
    </div >
  );
}

export default CategorySelect;