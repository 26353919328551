//All actions for user component
import {
  GET_USERS,
  SET_INDEX_USER,
  SET_LOADING_USER,
  SAVE_USER_INFO,
  GET_ALL_USERS,
  COUNT_USERS,
  LOADING,
  GET_ALL_USERS_NEW,
} from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { responseSwal } from "../helpers/responseSwal";
import { customSwaltAlert } from "../helpers/customSwaltAlert";
import { users_get } from "./cuentasActions";

const MySwal = withReactContent(Swal);
export const updateUserInfo = (user) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  if (user.newPassword !== user.newPasswordConfirm) {
    return MySwal.fire({
      icon: "error",
      title: "Error al cambiar contraseña",
      text: "Las contraseñas ingresadas para cambiar no coinciden.",
    });
  }
  if (user.newPassword !== "") {
    const data = {
      email: user.email,
      old_password: user.password,
      new_password: user.newPassword,
    };
    fetch(`${URL_GATEWAY}${API_VERSION}/users/change_pass/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success || res.code >= 300) {
          return MySwal.fire({
            icon: "error",
            title: "Error al guardar",
            text: res.message,
            footer: "Si el problema contínua comuniquese con un asesor",
          });
        }
        return MySwal.fire({
          icon: "success",
          title: "Contraseña restablecida",
          text: "Se ha cambiado la contraseña correctamente",
        });
      })
      .catch((err) => console.error(err));
  }
};

export const updateCellphone = (user) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  if (user.cellphone) {
    fetch(`${URL_GATEWAY}${API_VERSION}/users/${user.id}`, {
      method: "PUT",
      body: JSON.stringify(user),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.description) {
          dispatch({
            type: SAVE_USER_INFO,
            payload: user,
          });
          return MySwal.fire({
            icon: "success",
            title: "Datos guardados",
          });
        } else {
          return MySwal.fire({
            icon: "error",
            title: "Error al guardar",
            text: `${res.description}`,
          });
        }
      })
      .catch((err) => {
        return MySwal.fire({
          icon: "error",
          title: "Error al guardar",
          text: "No fue posible actualizar la información.",
        });
      });
  }
}

export const getUsers = (objFilters) => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: LOADING, payload: true
  })

  fetch(`${URL_GATEWAY}${API_VERSION}/users/params/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: LOADING, payload: false
      })
      const { results, row_total } = res;

      dispatch({
        type: GET_USERS,
        payload: results,
        row_total: row_total
      });

      if (row_total <= 0) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "El usuario no se encuentra registrado.",
          footer: "Si el error persiste comuníquese con un asesor.",
        });
      }
    })
    .catch(() => {
      responseSwal({ icon: "error", title: "Error", text: "Ocurrío un error al listar los usuarios" })
      dispatch({
        type: LOADING, payload: false
      })
    });

  // fetch(`${URL_GATEWAY}${API_VERSION}/users/`, {
  //   method: "GET",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // })
  //   .then((response) => response.json())
  //   .then((res) => {
  //     const { data } = res;

  //     dispatch({
  //       type: SET_INDEX_USER,
  //       payload: data.length,
  //     });
  //   })
  //   .catch((err) => {
  //     console.error(err);
  //   });
};

export const getAllUsers = () => (dispatch, getState) => {

  const token = getState().loginReducer.Authorization;

  fetch(
    `${URL_GATEWAY}${API_VERSION}/users/`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        token: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      const { data } = res;

      dispatch({
        type: GET_ALL_USERS,
        payload: data,
      });

    })
    .catch((err) => {
    });
}

export const getTotalIndex = (doAfter) => (dispatch, getState) => {
  dispatch({
    type: SET_LOADING_USER,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/users/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { data } = res;
      dispatch({
        type: SET_INDEX_USER,
        payload: data.length,
        users: data,
      });
      dispatch({
        type: SET_LOADING_USER,
        loading: false,
      });
      if (!!doAfter) {
        doAfter();
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: SET_LOADING_USER,
        loading: false,
      });
    });
};

export const createUser = (obj, filters, doAfter, doAfterError) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  if (obj) {
    var data = obj;
    fetch(`${URL_GATEWAY}${API_VERSION}/users/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { message, success } = data;
        if (!success) {
          doAfterError();
          return customSwaltAlert({
            icon: "error",
            title: "Error de notificacion",
            text: message,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
          
        } else {

          customSwaltAlert({
            icon: "success",
            title: `Usuario, ${obj.first_name} creado exitosamente.`,
            text: "",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

          doAfter();
          dispatch(getUsers(filters));
          dispatch(users_get());
        }
      })
      .catch(() => {
        doAfterError()
        return customSwaltAlert({
          icon: "error",
          title: "Error al conectar",
          text:
            "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      });
  } else {

    customSwaltAlert({
      icon: "error",
      title: "Error al ingresar",
      text: "Debe ingresar los datos obligatorios para ingresar al sistema.",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  }
};

export const activationDeactivation = (email, active, filters) => (
  dispatch,
  getState
) => {
  const token = getState().loginReducer.Authorization;
  let status = active ? "deshabilitar" : "habilitar";
  if (active === true) {
    active = 0;
  } else if (active === false) {
    active = 1;
  }

  if (email != null && active != null) {
    var data = {
      email: email,
      active: active,
    };


    customSwaltAlert({
      title: `¿Está seguro de ${status} el usuario?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si estoy seguro",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type: SET_LOADING_USER,
          loading: true,
        });
        fetch(`${URL_GATEWAY}${API_VERSION}/users/activate/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.status || !res.message) {

              customSwaltAlert({
                icon: "success",
                title: "¡Usuario actualizado!",
                text: "El usuario se cambio su estado correctamente",
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })
              dispatch(getUsers(filters));
            } else {
              console.error(res.message);
            }
          })
          .catch((err) => {
            console.error(err);

            customSwaltAlert({
              icon: "error",
              title: "Error al guardar",
              text: "No se pudo cambiar el estado",
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })

          })
          .finally(() => {
            dispatch({
              type: SET_LOADING_USER,
              loading: false,
            });    
          });
      }
    });
    // fetch(`${URL_GATEWAY}${API_VERSION}/users/activate/`,{
    //     method: "POST",
    //     body: JSON.stringify(data),
    //     headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         Authorization: token,
    //     }
    // })
    // .then(response => response.json())
    // .then(data => {
    //     const {status, message, user_data, access_token} = data

    //     if( status>300 || (!status && !access_token) ){
    //         return MySwal.fire({
    //             icon:'error',
    //             title: "Error",
    //             text: message,
    //           })
    //     }else{
    //         return MySwal.fire({
    //             icon:'success',
    //             title: "Accion realizada con exito!",
    //             text: message,
    //           })
    //     }

    // })
    // .catch((err)=>{
    //     console.error(err)
    //     MySwal.fire({
    //         icon:'error',
    //         title: "Error al conectar",
    //         text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo."
    //       })
    // })
  } else {

    customSwaltAlert({
      icon: "error",
      title: "Error al ingresar",
      text: "Debe ingresar correo y contraseña para acceder al sistema.",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  }
};

export const updateUser = (obj, filters, doAfter, doAfterError) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  if (obj) {
    var data = obj;
    fetch(`${URL_GATEWAY}${API_VERSION}/users/${obj.id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {

        customSwaltAlert({
          icon: 'success',
          title: '¡Usuario actualizado!',
          text: 'El Usuario se actualizó correctamente',
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

          if (data.success){
         
          doAfter();
          dispatch(getUsers(filters));
          dispatch(users_get());
        } else {
          

          customSwaltAlert({
            icon: 'error',
            title: 'Error',
            text: `${data.description}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
          doAfterError()
        }


      })
      .catch((err) => {
        console.error(err);
        doAfterError()
        customSwaltAlert({
          icon: "error",
          title: "Error al conectar",
          text:"Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      });
  } else {

    customSwaltAlert({
      icon: "error",
      title: "Error al ingresar",
      text: "Debe ingresar los datos obligatorios para ingresar al sistema.",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  }
};

export const get_a_user = (id, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/users/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (!!doAfter) {
        doAfter();
      }
    })
    .catch((err) => {
      console.error(err);
    });

};


export const count_users = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/users/count/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      //const { data } = res;
      dispatch({
        type: COUNT_USERS,
        payload: res,
      });
    });
};

export const update_roles_users = (data, doAfter) => (dispatch, getState) => {
  dispatch({
    type: SET_LOADING_USER,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/users/updateroles/`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        //doAfter();
        // MySwal.fire({
        //   icon:'success',
        //   title:'¡Roles Actualizados!',
        //   text:'Se actualizaron los roles de los usuarios'
        // })
        dispatch(getTotalIndex(doAfter))
      } else {
        MySwal.fire({
          icon: "error",
          title: "Error al conectar",
          text: "Ocurrió un error al intentar guardar los roles",
        });
        dispatch({
          type: SET_LOADING_USER,
          loading: false,
        });

      }
    })
    .catch((err) => {
      console.error(err);
    });

};


export const getAllUsersNew = (data) => (dispatch, getState) => {
  let filters = convertFilterToString(data);
  const token = getState().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/medical/generals/users/?${filters}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;

      dispatch({
        type: GET_ALL_USERS_NEW,
        payload: results,
      });

    })
    .catch((err) => {
    });
}
