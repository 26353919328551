import { useEffect, useState } from 'react';
import Search from '../../../../assets/img/icons/lupa.svg';
import backIcon from '../../../../assets/img/icons/atras-icon.svg';
import excelIcon from '../../../../assets/img/icons/excelIcon.svg';
import { useSelector } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import '../../../TableUsers/tableUserStyle.scss';
import tableStyles from '../../../Layouts/tableStyle.module.scss';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Box, ToggleButtonGroup } from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';

import { customSelectNewDark } from '../../../../components/Layouts/react-select-custom';
import { styled } from '@mui/material/styles';

import { renderToolImportance } from '../../../../helpers/renderToolImportance';
import { OverlayTrigger } from 'react-bootstrap';
import { useGetMethod, usePostMethod } from '../../../../Hooks/useFetch';
import { convertMoneyFormat, loader } from '../../../../helpers/helpers';
import NumberFormat from "react-number-format";
import { customSwaltAlert } from "../../../../helpers";
import ReactSelectMulti from 'react-select/creatable';

import stylesTable from "../../../../components/Layouts/genericTable.module.scss";
import sheetStyles from './sheetStyles.module.scss';

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: selectedColor,
  },
}));

export const NewSheetDetail = () => {

  const [newData, setNewData] = useState([]);
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const created_by = store.loginReducer.user_data.id;

  const { year, month } = useParams();
  const history = useHistory();

  const [data, setData] = useState({
    trigger: 0,
    emptyProvisions: false,
    filters: {
      eaccount,
      search: '',
      type: 'accrued',
      adj_ids: [],
      validation: 0,
    },
  });

  const { filters, trigger, emptyProvisions } = data;

  const { results: listDetail, trigger: getListDetail, load: loadListDetail } = useGetMethod();
  const { results: listAccrued, trigger: getListAccrued, load: loadListAccried } = useGetMethod();

  const { load: loaderUpdateDetail, trigger: onUpdateDetail } = usePostMethod();
  const { load: loaderCreateDetail, trigger: onCreateDetail } = usePostMethod();

  const headerStatic = [
    <th key={1} className='text-center px-2'>
      ID
    </th>,

    <th key={2} className='text-center px-2'>
      Empleado
    </th>,
  ];


  const onGetFormatData = ({ el, adjustment, newValue, hasError, adjustmentId, emptyValue, deleteId }) => {
    const currentData = newData;
    currentData?.forEach((element, index) => {
      if (element.candidate_id === el.candidate_id) {
        element.adjustment?.forEach((adj, adjIndex) => {
          if (adj.path === adjustment.path) {
            currentData[index].adjustment[adjIndex] = {
              total_value: hasError ? '' : emptyValue ? adj?.total_value : newValue,
              path: adj?.path,
              id: deleteId ? undefined : adjustmentId ? adjustmentId : adj?.id,
            };
          }
        });
      }
    });
    return currentData;
  };

  const bodyDynamic = () => {
    const result = [];

    newData?.forEach(el => {
      result.push(
        <tr key={el.candidate_id}>
          {el.adjustment.map((adjustment, index) => (
            index === 0 ? (
              <td key={adjustment?.doc_number} className={`text-center px-2`}>
                <div>{adjustment?.doc_number ? adjustment?.doc_number : '-'}</div>
              </td>
            ) : index === 1 ? (
              <td key={adjustment?.full_name} className={`text-center px-2`}>
                <div>{adjustment?.full_name ? adjustment?.full_name : '-'}</div>
              </td>
            )
              :
              <td key={el?.path} className='text-end px-2'>
                <NumberFormat
                  allowNegative={false}
                  className={`register-inputs text-end ${tableStyles.inputPlaceholder}`}
                  placeholder='$...'
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  isNumericString={true}
                  prefix={'$'}
                  value={adjustment?.total_value || ''}
                  onBlur={(event) => {
                    const newValue = event.target.value.replaceAll(',', '').replace('$', '')?.trim();

                    if (adjustment?.id && Number(newValue)) {
                      return onUpdateDetail({
                        url: `/payroll/accrued/sheet/${year}/${month}/${adjustment.id}`,
                        token: token,
                        noAlert: true,
                        method: 'PUT',
                        body: { fee_value: newValue, id_contract: el.id_contract },
                        succesAction: () => {
                          setNewData([...onGetFormatData({ el, newValue, adjustment })]);
                        },
                        doAfterException: err => {
                          setNewData([...onGetFormatData({ el, newValue, adjustment, hasError: true })]);
                          customSwaltAlert({
                            icon: 'warning',
                            title: err.title,
                            confirmButtonText: 'Aceptar',
                            showCancelButton: false,
                            text: err.message,
                          });
                        },
                      });
                    }

                    if (adjustment?.id && Number(newValue) <= 0) {
                      return onUpdateDetail({
                        url: `/payroll/accrued/sheet/${year}/${month}/${adjustment.id}`,
                        token: token,
                        noAlert: true,
                        method: 'DELETE',
                        body: { fee_value: newValue, id_contract: el.id_contract },
                        succesAction: () => {
                          setNewData([...onGetFormatData({ el, newValue, adjustment, deleteId: true })]);
                        },
                        doAfterException: err => {
                          setNewData([...onGetFormatData({ el, newValue, adjustment, hasError: true })]);
                          customSwaltAlert({
                            icon: 'warning',
                            title: err.title,
                            confirmButtonText: 'Aceptar',
                            showCancelButton: false,
                            text: err.message,
                          });
                        },
                      });
                    }

                    if (Number(newValue) <= 0) {
                      return setNewData([...onGetFormatData({ el, newValue: '', adjustment })]);
                    }

                    onCreateDetail({
                      url: `/payroll/accrued/sheet/${year}/${month}`,
                      token: token,
                      noAlert: true,
                      method: 'POST',
                      body: {
                        eaccount,
                        created_by,
                        type_adj: filters.type,
                        id_contract: el.id_contract,
                        adj_id: listDetail?.results?.headers?.find(header => header.path === adjustment.path)?.id,
                        cand_id: el.candidate_id,
                        fee_value: Number(event.target.value.replaceAll(',', '').replace('$', '')),
                        contract_id: el?.contract
                      },
                      succesAction: (res) => {
                        setNewData([...onGetFormatData({ el, newValue, adjustment, adjustmentId: res.results })]);
                      },
                      doAfterException: err => {
                        setNewData([...onGetFormatData({ el, newValue, adjustment, hasError: true })]);
                        customSwaltAlert({
                          icon: 'warning',
                          title: 'Intenta de nuevo',
                          confirmButtonText: 'Aceptar',
                          showCancelButton: false,
                          text: err.message,
                        });
                      },
                    });
                  }}
                />
              </td>
          ))}
        </tr>
      );
    });

    return result;
  };


  const headerDynamic = listDetail?.results?.headers?.map(el => (
    <th key={el?.path} className={`text-end px-2`}>
      <div className={`${tableStyles.elipsis}`}>
        {el?.name}
      </div>
    </th>
  ));


  const footerDynamic = () => {
    const totals = newData.reduce((acc, el) => {
      el.adjustment.forEach(adjs => {
        const value = parseFloat(adjs.total_value) || 0;
        acc[adjs.path] = (acc[adjs.path] || 0) + value;
      });
      return acc;
    }, {});

    return (
      <tr>
        <td></td>
        <td>Total</td>
        {Object.entries(totals).filter(el => el[0] !== 'undefined').map(([path, totalValue]) => (
          <td className='text-end px-2' key={path} style={{ fontSize: 16 }}>{convertMoneyFormat(totalValue)}</td>
        ))}
      </tr>
    );
  };

  const onClickSwitch = (_e, type) => {
    if (!type) { return }
    setData(state => ({
      ...state,
      filters: {
        ...state.filters,
        adj_ids: [],
        type: type
      },
      trigger: state.trigger + 1
    }));
  };


  const handleOnChangeSelectAjd = (ids) => {
    setData(state => {
      if (Array.isArray(ids)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            adj_ids: ids
          }
        };
      }

      const newAdjIds = state.filters.adj_ids.includes(ids)
        ? state.filters.adj_ids.filter(adjId => adjId !== ids)
        : [...state.filters.adj_ids, ids];
      return {
        ...state,
        filters: {
          ...state.filters,
          adj_ids: newAdjIds
        }
      };
    });
  };

  const formatDataAdjustemt = (data) => {
    const formatData = [];

    data?.forEach(el => {
      formatData.push({
        ...el,
        adjustment: el.adjustment?.length
          ? [{ doc_number: el.doc_number }, { full_name: el.full_name }, ...el.adjustment,]
          : [{ doc_number: el.doc_number }, { full_name: el.full_name }]
      });
    });

    return formatData;
  };

  useEffect(() => {
    getListAccrued({
      url: `/payroll/adjustments/`,
      token: token,
      objFilters: {
        'type_adj': filters.type,
        'entity_account': eaccount,
        'status': 'enabled',
        'order_by': true
      }
    });
  }, [filters.type, eaccount, getListAccrued, token]);

  useEffect(() => {
    if (trigger) {
      const adjIdString = filters.adj_ids.join(',');
      getListDetail({
        url: `/payroll/accrued/sheet/${year}/${month}`,
        token: token,
        objFilters: {
          ...filters,
          adj_id: adjIdString
        },
        doAfterException: (err) => {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
            text: err.message,
          });
        }
      }).then((el) => {
        if (el.message && el.results.length === 0) {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
            text: el.message,
          });
        }
        setNewData(formatDataAdjustemt(el?.results?.data) || []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, getListDetail, month, year, token]);

  useEffect(() => {
    getListDetail({
      url: `/payroll/accrued/sheet/${year}/${month}`,
      token: token,
      objFilters: filters,
      doAfterException: (err) => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
          text: err.message,
        });
      }
    }).then((el) => {
      if (el.message && el.results.length === 0) {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
          text: el.message,
        });
      }
      setNewData(formatDataAdjustemt(el?.results?.data) || []);
    });

    getListAccrued({
      url: `/payroll/adjustments/`,
      token: token,
      objFilters: {
        'type_adj': filters.type,
        'entity_account': eaccount,
        'status': 'enabled',
        'order_by': true
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderBaseballTable = () => {
    return (
      <div className={`my-4 ${sheetStyles.role}`} role="region" aria-labelledby="caption" tabIndex="0">
        <table
          className={`
            ${stylesTable.genericTable}
            ${stylesTable.oneHeadDarkBlue}
            ${stylesTable.treeHeadDarkBlue}
            ${stylesTable.edgeOutside}
            ${sheetStyles.table}`
          }
        >
          <thead className={`${stylesTable.thead} ${stylesTable.theadDark}`}>
            <tr>
              {headerStatic}
              {headerDynamic}
            </tr>
          </thead>
          <tbody>
            {bodyDynamic()}

            {footerDynamic()}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      {(loadListDetail || loaderUpdateDetail || loaderCreateDetail || loadListAccried) && loader}

      <div className='w-90 mx-auto'>
        <div className='d-flex justify-content-between'>

          <div className='d-flex'>
            <img
              src={backIcon}
              alt='volver'
              width={24}
              className={`${tableStyles.title} ${tableStyles.marginBackIcon} hoverPointer`}
              onClick={() => history.goBack()}
            />

            <h1 className={`${tableStyles.title}`}>Detalle de devengados</h1>
          </div>
          <div className='align-self-end pb-3'>
            <ToggleButtonGroup
              color="primary"
              value={filters.type}
              exclusive
              size='small'
              onChange={onClickSwitch}
              aria-label="Platform"
            >
              <ToggleButton selectedColor='#005DBF' value="accrued">devengados</ToggleButton>
              <ToggleButton selectedColor='#005DBF' value="deduction">deducciones</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>

        {!emptyProvisions ? (
          <>
            <Box>
              <Box display={'grid'} gridTemplateColumns={'350px 500px 1fr 1fr'}>
                <div className='px-2'>
                  <p className={tableStyles.crudModalLabel}>{filters.type === 'accrued' ? 'Devengados' : 'Deducciones'}</p>
                  <ReactSelectMulti
                    noOptionsMessage={() => 'No hay datos'}
                    onChange={(e) => handleOnChangeSelectAjd(e.map(e => e.value))}
                    styles={{
                      ...customSelectNewDark,
                      control: provided => ({
                        ...provided,
                        border: '1px solid #7FADDE',
                        borderRadius: 5,
                        padding: 0,
                        fontSize: 12,
                        minHeight: 25,
                        maxHeight: 35
                      }),
                      valueContainer: provided => ({
                        ...provided,
                        height: '25px',
                        padding: '0 6px',
                        overflowY: 'scroll',
                      }),
                    }}
                    placeholder='Seleccionar...'
                    isMulti
                    value={
                      filters.adj_ids.length > 0
                        ? listAccrued?.results?.filter(el => filters.adj_ids.includes(el.id)).description
                        : ''
                    }
                    options={
                      listAccrued.results?.map(el => ({
                        value: el.id,
                        label: el.description
                      }))}
                  />
                </div>

                <div className='d-flex gap-1 justify-content-center align-items-end'>
                  <input
                    className={`${tableStyles.SearchNew} mr-2`}
                    style={{ flex: 1, color: `#73a6dc`, height: '27px' }}
                    name='search'
                    type='text'
                    placeholder='Buscar por empleado e id'
                    value={filters.search}
                    onChange={e =>
                      setData(state => ({
                        ...state,
                        filters: { ...state.filters, search: e.target.value },
                      }))
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setData(state => ({
                          ...state,
                          trigger: state.trigger + 1,
                          filters: { ...state.filters }
                        }));
                      }
                    }}
                  />

                  <img
                    src={Search}
                    style={{ cursor: 'pointer' }}
                    alt='Search icon'
                    height='27px'
                    onClick={() => {
                      setData(state => ({ ...state, trigger: state.trigger + 1 }));
                    }}
                  />
                </div>

                <div className={`d-flex gap-1 justify-content-center align-items-end`}>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderToolImportance('Descargar')}
                  >
                    <img
                      style={{ display: 'none' }}
                      src={excelIcon}
                      alt='planilla'
                      className='pointer'
                      height='27px'
                    />
                  </OverlayTrigger>
                </div>
              </Box>
            </Box>

            {renderBaseballTable()}

          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
