import Select from "react-select"
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import False from "../../assets/img/icons/extraBtnRedX.svg";
import SearchIcon from "../../assets/img/icons/lupa.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import iconDownload from "../../assets/img/icons/iconDownload.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import { connect } from "react-redux";
import "reactjs-popup/dist/index.css";
import Pagination from "react-js-pagination";
import { PAGE, PERPAGE } from "../../helpers/constants";
import ownStyles from "./tableAgreements.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import GenericTableNew from "../Layouts/GenericTableNew";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { Col, OverlayTrigger, Row, } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";

import deepcopy from "deepcopy";
import ModalNew from "../Layouts/ModalNew";
import {
  changeStatusAgreement,
  getAgreements,
  getAgreementsProviders,
  getOneAgreement,
  updateAgreement,
} from "../../actions/agreementActions";
import { convertMoneyFormat } from "../../helpers/helpers";
import { getPurchaseOrders } from "../../actions/purchaseOrderActions";
import TableScrollSubtotal from "../Layouts/TableScrollNew/TableScrollSubtotal";
import { customSelectNewDark } from "../Layouts/react-select-custom";
const MySwal = withReactContent(Swal);

class TableAgreements extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.props.getAgreements({
      page: 1,
      perpage: 10,
      eaccount: this.props.currentAccount.id,
      multistatus: "expired,authorized"
    });
    this.props.getAgreementsProviders();
    this.setState(this.initialState);
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  get initialState() {
    return {
      purchaseOrder: {},
      modalPurchaseOrder: false,
      redirect: null,
      filtersOP: {
        page: 1,
        perpage: 10,
        search: "",
        created_at: ""
      },
      modal: false,
      modalOC: false,
      modalFinalize: false,
      modalQuotation: false,
      editRecord: false,
      modalDate: false,
      addAgreement: {
        articles: [],
      },
      article: {},
      checkModal: false,
      countChecked: 0,
      paginationModal: {
        page: 1,
        perpage: 10,
        totalIndex: 0,
      },
      filters: {
        search: "",
        status: "",
        due_date: "",
        // current_date:CURDATE,
        eaccount: this.props.currentAccount.id,
        perpage: PERPAGE,
        page: PAGE,
        multistatus: "expired,authorized",
      },
      paginationDeliveries: {
        page: 1,
        perpage: 10,
      },
      selectedList: [],
      headerState: [
        "ID",
        "Nombre del convenio",
        "Vencimiento",
        "OCs",
        "Proveedor",
        "Cotización",
        "Estado",
        "",
      ],
    };
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getAgreements(filters)
  }
  // pagination articles 🗃
  handlePageChangeModalArticle(pageNumber) {
    let { paginationModal } = this.state;
    paginationModal.page = pageNumber;
    this.setState({ paginationModal });
  }
  // pagination deliveries 🚚
  handlePageChangemodalOC(pageNumber) {
    let { paginationDeliveries, addAgreement } = this.state;
    paginationDeliveries.page = pageNumber;
    let min =
      pageNumber * paginationDeliveries.perpage - paginationDeliveries.perpage;
    let max = pageNumber * paginationDeliveries.perpage;
    addAgreement.articles.slice(min, max);
    this.setState({ paginationDeliveries });
  }

  handleEditRecord = (agreement, edit) => {
    let { addAgreement, modal, checkModal, editRecord } = this.state;
    modal = !modal;
    editRecord = !!edit
    this.props.getOneAgreement(agreement.id)
    addAgreement = deepcopy(agreement);
    addAgreement.purchaseOrderList = deepcopy(agreement.purchaseOrder_ref)
    this.setState({ addAgreement, modal, checkModal, editRecord });
  };

  handleReply = (agreement) => {
    this.props.setLoading(true);
    let { addAgreement, redirect } = this.state;
    redirect = "/compras/subastaindividual"
    this.props.getOneAgreement(agreement.id, () => {
      addAgreement = deepcopy(agreement);
      this.props.setLoading(false);
      addAgreement.purchaseOrderList = deepcopy(agreement.purchaseOrder_ref);
      this.setState({ addAgreement, redirect });
    })
  }

  // toggleStatus = (account) => {
  // };

  // Search puc when user was typing
  onKeyUpSearch = (e) => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
    } else {
      e.preventDefault();
    }
    // this.props.getAgreements(filters);
    this.setState({ filters });
  };

  //Method to search when click in magnify lens
  handleSearch = (e) => {
    let { filters } = this.state;
    filters.page = 1;
    this.props.getAgreements(filters);
    this.setState({ filters });
  };

  toggleModal = (e) => {
    // let { modal } = this.state;
    // modal = !modal;
    // if (this.state.editRecord) {
    //   this.setState({ modal });
    // }
    // this.setState(this.initialState);
    // this.setState({ modal });
  };

  toggleModalDataSheet = (e) => {
    // let { modalDataSheet } = this.state;
    // modalDataSheet = !modalDataSheet;
    // this.setState({ modalDataSheet });
  };

  onKeyDownNumber = (e) => {
    let { errorInputs } = this.state;
    if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
      e.preventDefault();
      errorInputs = true;
      return MySwal.fire({
        icon: "error",
        title: "Este campo solo acepta números",
      });
    }
    errorInputs = false;
    this.setState({ errorInputs });
  };

  handleSubmit() {
    // this.toggleModal();
    // if (this.state.editRecord) {
    //   return this.props.updateArticle(this.state.addArticle);
    // }
    // // this.props.createArticle(this.state.addArticle);
    // this.setState(this.initialState);
  }

  //   toggle visibilty of modal to edit or create record
  handleOnHide = () => {
    let { modal, addAgreement, selectedList } = this.state;
    modal = !modal;
    addAgreement = { articles: [] };
    selectedList = [];
    this.setState({ modal, addAgreement, selectedList });
  };

  // Method to get css style for circle in status
  getStatusClass = (status) => {
    switch (status) {
      case "expired":
        return { class: tableStyles.circleRedAuto, text: "Vencido" };

      case "authorized":
        return { class: tableStyles.circleGreenAuto, text: "Autorizado" };

      default:
        return { class: tableStyles.circleRedAuto, text: "Vencido" };
    }
  };

  getCoinNickname = (coin) => {
    switch (coin) {
      case "dolar":
        return "USD";
      case "peso":
        return "COP";
      case "Dolar estadunidense":
        return "USD";
      case "Peso colombiano":
        return "COP";
      default:
        return "-";
    }
  };

  getPaymentCondition = (pay) => {
    switch (pay) {
      case "prepayment":
        return "Pago anticipado";
      case "delivery":
        return "Pago contraentrega";
      case "pay20":
        return "Pago a 20 días";
      case "pay30":
        return "Pago a 30 días";
      case "pay60":
        return "Pago a 60 días";
      case "pay90":
        return "Pago a 90 días";
      case null:
        return "-";
      default:
        return pay;
    }
  };

  onChangeForm = (event) => {
    const { name, value } = event.target;
    let { addAgreement } = this.state;
    addAgreement[name] = value;
    this.setState({ addAgreement });
  };
  onChangeCheckModal = (event, index) => {
    let { addAgreement, countChecked, selectedList, checkModal } = this.state;
    countChecked = 0;
    addAgreement.articles[index].checked =
      !addAgreement.articles[index].checked;
    let element = addAgreement.articles[index];
    if (addAgreement.articles[index].checked === false) {
      selectedList = selectedList.filter((x) => x !== element.id);
    } else if (!selectedList.includes(element.id))
      selectedList.push(element.id);

    for (let i = 0; i < addAgreement.articles.length; i++) {
      const element = addAgreement.articles[i];
      if (!!element.checked) {
        countChecked++;
      } else {
        checkModal = false;
      }
    }
    this.setState({ addAgreement, countChecked, selectedList, checkModal });
  };

  renderArticles = (article, index) => {
    let realIndex = index + 1;
    let count = `${this.state.paginationModal.page - 1 > 0
        ? this.state.paginationModal.page - 1
        : ""
      }${realIndex}`;
    if (realIndex > 1 && realIndex % 10 === 0)
      count = this.state.paginationModal.page * 10;
    return (
      <tr key={article.id} style={{ overflow: "scroll" }}>
        <td className="text-center w-10">{count}</td>
        <td className="text-start">
          {article?.article_ref?.description
            ? article.article_ref.description
            : "-"}
        </td>
        <td className="text-center w-10">{article.brand}</td>
        <td className="text-center">
          {article?.unit_price ? `${convertMoneyFormat(article.unit_price)}` : "-"}
        </td>
        <td className="text-center">
          {article.qty_article ? article.qty_article : "-"}
        </td>
        <td className="text-center">
          {article.discount ? `${convertMoneyFormat(article.iva_value)}` : "-"}
        </td>
        <td className="text-center">
          {article.discount ? `${article.discount}%` : "-"}
        </td>
        <td className="text-center">
          {article.qty_article && article.unit_price
            ? convertMoneyFormat(
              article.qty_article * article.unit_price +
              article.iva_value -
              article.discount_value
            )
            : "-"}
        </td>
        {/* <td className="text-center">
          <img src={Clock} alt="entregas" width="18" className={article?.deliveries?.length > 0 ? "" : ownStyles.clock } onClick={()=>this.setState({...this.state, modalOC:true, modal:false, article:article})}/>
        </td> */}
      </tr>
    );
  };
  renderPurcharseOrders = (purcharse) => {
    return (
      <tr key={purcharse.id}>
        <td className="text-center">
          {purcharse.created_at ? convertDateToLatinFormat(purcharse.created_at) : "-"}
        </td>
        <td className="text-center text-uppercase">
          {purcharse.purchaseOrder_number ? purcharse.purchaseOrder_number : "-"}
        </td>
        <td className="text-center w-10 hoverPointer"><img src={Filtrar} width="15" onClick={() => this.hanldePurchaseDetail(purcharse)} /></td>
      </tr>
    );
  };
  onChangeFilter = (event,) => {
    let valueSelect = event.value
    let { filters } = this.state;
    if (event.name === "provider" || event.name === "status") {
      filters[event.name] = valueSelect;
    } else {
      const { name, value } = event?.target;
      filters[name] = value;
    }
    // this.props.getAgreements(filters);
    this.setState({ filters });
  };

  nextDetails = () => {
    let { addAgreement, selectedList } = this.state;
    addAgreement.articles = addAgreement.articles.filter((x) =>
      selectedList.includes(x.id)
    );
    if (addAgreement.status === "solved") {
      addAgreement.pay_conditions = "";
    }
    this.setState({ addAgreement, selectedList });
  };

  handleUntilDate = (event) => {
    event.preventDefault();
    let { modalDate, addAgreement } = this.state;
    modalDate = !modalDate;
    addAgreement.valid_until_before = addAgreement.valid_until;
    this.setState({ modalDate, addAgreement });
  }

  // method to filter in purchase orders
  handleSearchPO = () => {
    let { filtersOP, addAgreement } = this.state;
    if (!filtersOP.search && !filtersOP.created_at) {
      addAgreement.purcharseOrderList = addAgreement.purchaseOrder_ref
    } else {
      let consult = addAgreement?.purchaseOrder_ref
      if (!!filtersOP.search) {
        const find = String(filtersOP.search).toUpperCase().trim()
        addAgreement.purcharseOrderList = consult.filter(x => !!x.purchaseOrder_number.includes(find))
        consult = consult.filter(x => !!x.purchaseOrder_number.includes(find))
      }
      if (!!filtersOP.created_at) {
        addAgreement.purcharseOrderList = consult.filter(x => new Date(x.created_at).getTime() === new Date(filtersOP.created_at).getTime())
        consult = consult.filter(x => new Date(x.created_at).getTime() === new Date(filtersOP.created_at).getTime())
      }
    }

    this.setState({ filtersOP, addAgreement })
  }

  // ver detalle ordenes de compra
  hanldePurchaseDetail = (item) => {
    let { modalPurchaseOrder, modalOC, purchaseOrder } = this.state;
    this.props.getPurchaseOrders({ id: item.id }, () => {
      purchaseOrder = this.props.purchaseOrders[0]
      this.setState({ purchaseOrder })
    }
    )
    modalPurchaseOrder = true;
    modalOC = false;
    this.setState({ modalOC, modalPurchaseOrder, purchaseOrder })
  }


  loadQuotation = (item) => {
    let { addAgreement } = this.state;
    this.props.getOneAgreement(item.id, () => {
      addAgreement = deepcopy(this.props.agreementReducer.agreementDetails[0])
      this.setState({ addAgreement })
    });
    this.setState({ ...this.state, modalQuotation: true, addAgreement })
  }


  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "Conv"
    );

  // ANCHOR RENDER METHOD
  render() {
    if (!this.props.isAuth) {
      return <Redirect to={"/login"} />;
    }

    if (!!this.state.redirect) {
      return <Redirect to={{
        pathname: this.state.redirect,
        state: { ...this.state.addAgreement, ...this.state.purchaseOrder }
      }} />
    }

    // columns for scroll table
    const columns = [
      {
        id: 'item',
        label: 'Item',
        minWidth: 80,
        align: 'center',
      },
      {
        id: 'description',
        label: 'Descripción de Artículo',
        minWidth: 80,
        align: 'center',
      },
      {
        id: 'qty_article',
        label: 'Cantidad',
        minWidth: 80,
        align: 'center',
      },
      {
        id: 'unit_price',
        label: 'Valor Unidad',
        minWidth: 80,
        align: 'center',
      },
      {
        id: 'discount',
        label:
          <div className="d-flex flex-column">
            <div className="mx-auto text-center px-3">
              <span>Descuento</span>
            </div>
            <div className="d-flex justify-content-around">
              <span>%</span>
              <span>$</span>
            </div>
          </div>,
        minWidth: 80,
        align: 'center',
      },
      {
        id: 'iva',
        label:
          <div className="d-flex flex-column">
            <div className="mx-auto text-center px-3">
              <span>IVA</span>
            </div>
            <div className="d-flex justify-content-around">
              <span>%</span>
              <span>$</span>
            </div>
          </div>,
        minWidth: 170,
        align: 'center',
      },
      {
        id: 'total',
        label: 'Total',
        minWidth: 80,
        align: 'center',
      }
    ]


    var rowsScroll = [];

    this.state.purchaseOrder?.purchaseOrderItems?.map((article, index) => {
      return rowsScroll.push({
        item: index + 1,
        description: article.article_ref.description,
        brand: article.brand,
        qty_article: article.qty_article,
        unit_price: article.unit_price,
        discount: article.discount,
        iva: article.iva,
      });
    })

    let renderHeaders = this.state.headerState
      ? this.state.headerState.map((header) => {
        return (
          <th key={`header-${header}`} className={`${ header === "Nombre del convenio" ? "text-start px-2" : "text-center"}`}>
            {header}
          </th>
        );
      })
      : "";

    // let min =
    //   this.state.paginationModal.page * this.state.paginationModal.perpage -
    //   this.state.paginationModal.perpage;
    // let max =
    //   this.state.paginationModal.page * this.state.paginationModal.perpage;
    var renderArticlesModal =
      this.state.addAgreement?.quotation_ref?.quotation_article_ref.map((article, index) => {
        return this.renderArticles(article, index);
      });

    var renderOC = this.state.addAgreement?.purcharseOrderList?.map((purcharseOrder) => {
      return this.renderPurcharseOrders(purcharseOrder);
    })
    var renderHeadersModal = [
      "#",
      "Descripción del artículo",
      "Marca",
      "Precio Unt.",
      "Cant.",
      "Descuento",
      "IVA",
      "Valor Total",
    ].map((header) => {
      return (
        <th key={`hdModal-${header}`} className={`${header === "Descripción del artículo" ? "text-start" : "text-center"}`}>
          {header}
        </th>
      );
    });

    var renderHeadersOC = ["Fecha", "No.", ""].map(
      (header) => {
        return <th className="text-center">{header}</th>;
      }
    );

    let renderAgreements = this.props?.agreementReducer?.agreements.map(
      (item) => {
        let circleClass = this.getStatusClass(item.status).class;
        const renderTooltip = (props) => (
          <Tooltip id="button-tooltip" {...props}>
            {this.getStatusClass(item.status).text}
          </Tooltip>
        );

        return (
          <tr key={item.id}>
            <td className="text-center">{item.agreement_number}</td>
            <td>{item?.name}</td>
            <td className="text-center">
              {convertDateToLatinFormat(item.valid_until)}
            </td>
            <td
              className="text-center text-primary fw-bold hoverPointer text-decoration-underline"
              onClick={() => this.setState({ ...this.state, modalOC: true, addAgreement: { ...item, purcharseOrderList: deepcopy(item.purchaseOrder_ref) } })}
            >
              {item.purchaseOrder_ref?.length}
            </td>
            <td className="text-wrap w-25">{item?.provider_ref?.name}</td>
            <td
              className="text-center text-primary text-decoration-underline hoverPointer"
              onClick={() => {
                this.loadQuotation(item)
              }}
            >
              {item.quotation_ref?.quotation_number}
            </td>
            <td className="text-center">
            <Tooltip title={this.getStatusClass(item.status).text} arrow>
                <div
            className="rounded-pill p-1 mx-auto"
            style={{
              width: 200,
              backgroundColor: item?.status === "expired" ? "#FEF7F5" : "#FAFDF6" ,
            }}
          >
            <b style={{ color: item?.status === "expired" ? "#F39682" :  "#83C036" }}>
            {this.getStatusClass(item.status).text}
            </b>
          </div>
              </Tooltip>
              {/* <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <div className={circleClass}></div>
              </OverlayTrigger> */}
            </td>
            <td className="text-center">
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                showEdit={this.myPermission().edit && item.status === "authorized" ? true : false}
                showEnable={this.myPermission().delete && item.status === "authorized" ? true : false}
                editText="Renovar"
                iconUpdate={true}
                showDetails={true}
                textDetails="Ver Detalle"
                showDetailsEvent={
                  this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "Conv"
                  )?.read ?
                    () => this.handleEditRecord(item)
                    : null
                }
                enableText="Finalizar"
                enableClickEvent={
                  this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "Conv"
                  )?.delete ?
                    () => {
                      this.setState({
                        ...this.state,
                        modalFinalize: !this.state.modalFinalize,
                        addAgreement: {
                          ...this.state.addAgreement,
                          id: item.id,
                        },
                      })
                    }
                    : null
                }
                editClickEvent={
                  this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "Conv"
                  )?.edit ?
                    () => this.handleEditRecord(item, true)
                    : null
                }
                isEnabled={item.status === "authorized" ? true : false}
                extraButtons={
                  this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "Conv"
                  )?.read ?
                    (item.status === "expired"
                      ? [
                        {
                          class: ownStyles.replyButtonPopUp,
                          text: "Replicar",
                          event: () => this.handleReply(item)
                        },
                      ]
                      : null)
                    : null
                }
              />
            </td>
          </tr>
        );
      }
    );

    return (
      <div className="w-100 mt-3">

        {this.props.loading ? (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        ) : null}
        {this.myPermission().read && (


          <div className={`w-100 ${this.props.loading ? "disabled" : ""}`}>
            <div className="pb-5 mx-5 px-5">
              <div className="row">
                <h1 className="top-h1 text-primary">Convenios</h1>
              </div>

              <Row className="">

                <Col xs={2}
                  className={``}
                >
                  <label
                    htmlFor="valid_until"
                    className={`${ownStyles.labelFont} mx-1`}
                  >
                    Fecha de vencimiento
                  </label>
                  <input
                    type="date"
                    name="valid_until"
                    className={`register-inputs`}
                    onChange={this.onChangeFilter}
                  />

                </Col>

                <Col xs={2} className={`align-self-end`}>
                  <label
                    className={`${ownStyles.labelFont} mx-1`}
                  >
                    Proveedor
                  </label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    name="provider"
                    defaultValue={{ value: "", label: "Seleccionar..." }}
                    onChange={this.onChangeFilter}
                    options={this.props.agreementReducer.listProviders?.map(
                      (prov) => (
                        { label: prov.name, value: prov.id, name: "provider" }
                      )
                    )}
                    placeholder="Seleccionar..."
                    styles={customSelectNewDark}
                  />
                </Col>

                <Col xs={2} className={`align-self-end`}>
                  <label
                    className={`${ownStyles.labelFont} mx-1`}
                  >
                    Estado
                  </label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    onChange={this.onChangeFilter}
                    options={[
                      { label: "", value: "", name: "status" },
                      { label: "Autorizado", value: "authorized", name: "status" },
                      { label: "Vencido", value: "expired", name: "status" },
                    ]}
                    placeholder="Seleccionar..."
                    styles={customSelectNewDark}
                  />
                </Col>
                <Col xs={4} className="align-self-end d-flex">
                  <input
                    className={`${tableStyles.SearchNew}`}
                    type="text"
                    name="search"
                    onKeyUp={this.onKeyUpSearch}
                    placeholder="Buscar..."
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        this.handleSearch(e)
                      }
                    }}
                  />
                  <img
                    src={SearchIcon}
                    alt="buscar"
                    height={24}
                    className="icons-svg-top-table float-left hoverPointer"
                    onClick={this.handleSearch}
                  />
                </Col>
              </Row>
              <GenericTableNew
                headers={renderHeaders}
                dark={true}
                lowerCase={true}
              >
                {renderAgreements}
              </GenericTableNew>

              {this.props.row_total > 10 && (
                <div
                  className={`${paginationStyles.wrapper} justify-content-between`}
                >
                  <p style={{ display: "inline" }}>
                    Pag. {this.state.filters.page} de{" "}
                    {Math.ceil(this.props.row_total / this.state.filters.perpage)
                      ? Math.ceil(
                        this.props.row_total / this.state.filters.perpage
                      )
                      : ""}{" "}
                    ({this.props.row_total} encontrados)
                  </p>

                  <Pagination
                    activePage={this.state.filters.page}
                    itemsCountPerPage={10}
                    totalItemsCount={
                      this.props.row_total ? this.props.row_total : 10
                    }
                    pageRangeDisplayed={10}
                    activeClass={paginationStyles.activeClass}
                    onChange={this.handlePageChange.bind(this)}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              )}
              {/* <div className="d-flex juistify-content-start">
            <button className="btn" style={{backgroundColor:"#CECECE", color:"#fff", padding:"0.5rem 1.5rem"}}>Crear cotización</button>
          </div> */}
            </div>
          </div>
        )}
        <ModalNew
          onHide={this.handleOnHide}
          show={this.state.modal}
          size="xl"
          centered
          title="Detalle de convenio"
          subtitle={this.state.addAgreement?.provider_ref?.name}
          extraButtons={
            this.state.addAgreement.status !== "expired" && this.myPermission().delete ? [
              {
                type: 1,
                name: "Finalizar convenio",
                icon: False,
                event: () =>
                  this.setState({
                    ...this.state,
                    modalFinalize: true,
                    justification: "",
                  }),
              },
            ] : null}
        >
          <span className="fw-bold text-primary list-style-circle">Información del convenio</span>
          <div className="row justify-content-between w-100">
            <div className="d-flex flex-column col-sm-3">
              <label htmlFor="create_at" className={ownStyles.labelFont}>
                Fecha de creación
              </label>
              <input
                type="date"
                name="create_at"
                className={`${ownStyles.selectInput} text-primary fw-bold`}
                disabled
                value={this.state.addAgreement?.created_at}
              />
            </div>

            <div className="d-flex flex-column col-sm-5">
              <label htmlFor="name" className={ownStyles.labelFont}>
                Nombre del convenio
              </label>
              <input
                type="text"
                name="name"
                className={`${ownStyles.selectInput} ${!!this.state.editRecord ? ownStyles.inputIconEdit : ""}`}
                value={this.state.addAgreement?.name}
                disabled={!!this.state.editRecord ? false : true}
                onChange={this.onChangeForm}
              />
            </div>

            <div className="d-flex flex-column col-sm-4">
              <label htmlFor="pay_conditions" className={ownStyles.labelFont}>
                Condiciones de pago
              </label>
              <input
                type="text"
                name="pay_conditions"
                className={`${ownStyles.selectInput} ${this.state.editRecord ? ownStyles.inputIconEdit : ""} bg-disabled-input`}
                disabled={!!this.state.editRecord ? false : true}
                value={this.getPaymentCondition(
                  this.state.addAgreement?.quotation_ref?.pay_conditions
                )}
                onChange={this.onChangeForm}
              />
            </div>
          </div>

          <div
            className={`${ownStyles.lineSection} row justify-content-start w-100 mt-3`}
          >
            <div className="d-flex flex-column col-sm-3">
              <label htmlFor="valid_from" className={ownStyles.labelFont}>
                Vigencia desde* :
              </label>
              <input
                type="date"
                name="valid_from"
                className={`${ownStyles.selectInput} text-primary fw-bold`}
                disabled
                value={this.state.addAgreement?.valid_from}
              />
            </div>
            <div className={`d-flex flex-column col-sm-3 ${ownStyles.lineBefore}`}>
              <label htmlFor="valid_until" className={ownStyles.labelFont}>
                Hasta*:
              </label>
              <input
                type="date"
                name="valid_until"
                className={`${ownStyles.selectInput} ${this.state.editRecord ? ownStyles.inputIconEditWithOriginal : ""} fw-bold text-primary`}
                value={this.state.addAgreement?.valid_until}
                disabled={this.state.editRecord ? false : true}
                onClick={(e) => this.handleUntilDate(e)}
              // onChange={this.onChangeForm}
              />
            </div>
            <div className="d-flex flex-column col-sm-9"></div>
          </div>
          <span className="fw-bold text-primary list-style-circle">Detalle de cotización base</span>
          <div className="row justify-content-between w-100 mt-2">
            <div className="d-flex flex-column col-sm-3">
              <label htmlFor="quotation_number" className={ownStyles.labelFont}>
                No. de la cotización
              </label>
              <input
                type="text"
                name="quotation_number"
                className={`${ownStyles.selectInput} text-primary fw-bold`}
                disabled
                value={this.state.addAgreement?.quotation_ref?.quotation_number}
              />
            </div>

            <div className="d-flex flex-column col-sm-5">
              <label htmlFor="name" className={ownStyles.labelFont}>
                Nombre de la cotización
              </label>
              <input
                type="text"
                name="name"
                className={ownStyles.selectInput}
                value={
                  this.state.addAgreement?.quotation_ref?.individual_auctions
                    ?.description
                }
                disabled
              />
            </div>

            <div className="d-flex flex-column col">
              <label htmlFor="quote_validity" className={ownStyles.labelFont}>
                Validez
              </label>
              <input
                type="date"
                name="quote_validity"
                className={`${ownStyles.selectInput} bg-disabled-input`}
                disabled
                value={this.state.addAgreement?.quotation_ref?.quote_validity}
              />
            </div>
            <img
            alt="icon"
              src={iconDownload}
              style={{ width: "48px", alignSelf: "flex-end", marginRight: "-22px" }}
              className="hoverPointer"
            />
          </div>
          <GenericTableNew headers={renderHeadersModal}>
            {renderArticlesModal}
          </GenericTableNew>
          {/* <div className="d-flex justify-content-end">
            <Pagination
              activePage={this.state.paginationModal.page}
              itemsCountPerPage={this.state.paginationModal.perpage}
              totalItemsCount={
                this.props?.totalArticles ? this.props.totalArticles : 10
              }
              pageRangeDisplayed={10}
              activeClass={paginationStyles.activeClass}
              onChange={this.handlePageChangeModalArticle.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div> */}
          <div className="d-flex justify-content-between">
            <h6 className="fw-bold text-primary paddingLeft1rem">TOTALES</h6>
            <h6 className="fw-bold text-primary px-3">
              ${this.state.addAgreement?.quotation_ref?.total.toLocaleString()}
            </h6>
          </div>
          <div className="d-flex w-100 justify-content-between">
            <div className="w-100">
              <label htmlFor="observations" className={ownStyles.labelFont}>
                Observaciones
              </label>
              <textarea
                type="text"
                name="observations"
                // value={this.state.addAgreement?.observations}
                className={`${ownStyles.inputText} ${ownStyles.textareaObservations} ml-2 w-100`}
              />
            </div>
          </div>
        </ModalNew>

        {/* modal quotation */}
        <ModalNew
          onHide={() => this.setState({ ...this.state, modalQuotation: false, addAgreement: {} })}
          show={this.state.modalQuotation}
          size="xl"
          centered
          title={this.state.addAgreement?.name}
          subtitle={this.state.addAgreement?.quotation_ref?.individual_auctions?.description}

        >
          <span className="fw-bold text-primary list-style-circle">Detalle de cotización base</span>
          <div className="row justify-content-between w-100">
            <div className="d-flex flex-column col">
              <label htmlFor="quotation_number" className={ownStyles.labelFont}>
                No. de la cotización
              </label>
              <input
                type="text"
                name="quotation_number"
                className={`${ownStyles.selectInput} text-primary fw-bold`}
                disabled
                value={this.state.addAgreement?.quotation_ref?.quotation_number}
              />
            </div>

            <div className="d-flex flex-column col">
              <label htmlFor="name" className={ownStyles.labelFont}>
                Nombre de la cotización
              </label>
              <input
                type="text"
                name="name"
                className={ownStyles.selectInput}
                value={
                  this.state.addAgreement?.quotation_ref?.individual_auctions
                    ?.description
                }
                disabled
              />
            </div>

            <div className="d-flex flex-column col">
              <label htmlFor="quote_validity" className={ownStyles.labelFont}>
                Validez
              </label>
              <input
                type="date"
                name="quote_validity"
                className={`${ownStyles.selectInput} bg-disabled-input`}
                disabled
                value={this.state.addAgreement?.quotation_ref?.quote_validity}
              />
            </div>

            <div className="d-flex flex-column col">
              <label htmlFor="pay_conditions" className={ownStyles.labelFont}>
                Condiciones de pago
              </label>
              <input
                type="text"
                name="pay_conditions"
                className={`${ownStyles.selectInput} bg-disabled-input`}
                disabled
                value={this.getPaymentCondition(this.state.addAgreement?.quotation_ref?.pay_conditions)}
              />
            </div>
            <img
              src={iconDownload}
              style={{ width: "48px", alignSelf: "flex-end" }}
              className="hoverPointer"
            />
          </div>
          {/* <div className={ownStyles.containerTableModal}> */}
          <GenericTableNew headers={renderHeadersModal}>
            {renderArticlesModal}
          </GenericTableNew>
          {/* </div> */}
          {/* <div className="d-flex justify-content-end">
            <Pagination
              activePage={this.state.paginationModal.page}
              itemsCountPerPage={this.state.paginationModal.perpage}
              totalItemsCount={
                this.props?.totalArticles ? this.props.totalArticles : 10
              }
              pageRangeDisplayed={10}
              activeClass={paginationStyles.activeClass}
              onChange={this.handlePageChangeModalArticle.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div> */}
          <div className="d-flex justify-content-between">
            <h6 className="fw-bold text-primary paddingLeft1rem">TOTALES</h6>
            <h6 className="fw-bold text-primary px-3">
              ${this.state.addAgreement?.quotation_ref?.total.toLocaleString()}
            </h6>
          </div>
          <div className="d-flex w-100 justify-content-between">
            <div className="w-100">
              <label htmlFor="observations" className={ownStyles.labelFont}>
                Observaciones
              </label>
              <textarea
                type="text"
                name="observations"
                readOnly
                value={this.state.addAgreement?.quotation_ref?.observations}
                className={`${ownStyles.inputText} ${ownStyles.textareaObservations} ml-2 w-100`}
              />
            </div>
          </div>
        </ModalNew>
        {/* ANCHOR MODAL PURCHASE ORDER */}
        <ModalNew
          title="Ordenes de compra"
          show={this.state.modalOC}
          size="550"
          onHide={() => {
            this.setState({
              ...this.state,
              modalOC: false,
            });
          }}
        >
          <div>
            <div className="d-flex justify-content-between">
              {/* date filter */}
              <div className="d-flex flex-column col-sm-5">
                <label htmlFor="created_at" className={`${ownStyles.labelFont} mx-1`}>Fecha</label>
                <input
                  type="date"
                  name="created_at"
                  className={`text-primary fw-bold w-100 register-inputs`}
                  onChange={(e) => this.setState({ ...this.state, filtersOP: { ...this.state.filtersOP, created_at: e.target.value } })}
                />
              </div>

              {/* number filter */}
              <div className="d-flex flex-column col-sm-6 mx-2">
                <label htmlFor="purchaseOrder_number" className={`${ownStyles.labelFont} mx-1`}>Número de orden</label>
                <input
                  type="text"
                  name="purchaseOrder_number"
                  className={`register-inputs`}
                  onChange={(e) => this.setState({ ...this.state, filtersOP: { ...this.state.filtersOP, search: e.target.value } })}
                />
              </div>
              <img
                src={SearchIcon}
                alt="buscar"
                className="icons-svg-top-table float-left hoverPointer"
                style={{ marginTop: "15px", marginLeft: "0" }}
                onClick={this.handleSearchPO}
              />
            </div>
            <GenericTableNew headers={renderHeadersOC}>
              {renderOC}
            </GenericTableNew>
            <div className="d-flex w-100 justify-content-end">
              {/* <Pagination
                activePage={this.state.paginationDeliveries.page}
                itemsCountPerPage={this.state.paginationDeliveries.perpage}
                totalItemsCount={
                  this.state?.article?.deliveries?.length
                    ? this.state.article.deliveries.length
                    : 10
                }
                pageRangeDisplayed={10}
                activeClass={paginationStyles.activeClass}
                onChange={this.handlePageChangemodalOC.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              /> */}
            </div>
          </div>
        </ModalNew>
        <ModalNew
          show={this.state.modalFinalize}
          size="md"
          title="Finalizar convenio"
          btnYesEvent={() => {
            MySwal.fire({
              icon: "warning",
              title: "¿Está seguro?",
              text: "Esta acción es irreversible",
              confirmButtonText: "Si, Continuar",
              cancelButtonText: "Cancelar",
              showCancelButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                this.props.changeStatusAgreement(
                  {
                    id: this.state.addAgreement.id,
                    status: "expired",
                    justification: this.state.justification,
                  },
                  () =>
                    this.setState({
                      ...this.state,
                      modalFinalize: false,
                      modal: false,
                    })
                )
              }
            })
          }}
          btnYesName="Finalizar"
          btnYesDisabled={!this.state.justification ? true : false}
          btnNoEvent={() =>
            this.setState({ ...this.state, modalFinalize: false })
          }
        >
          <div className="d-flex flex-column">
            <label htmlFor="justification" className={ownStyles.labelFont}>
              Justificación
            </label>
            <textarea
              name="justification"
              id=""
              cols="30"
              rows="10"
              className={ownStyles.textAreaJustification}
              onChange={(e) =>
                this.setState({ ...this.state, justification: e.target.value })
              }
            ></textarea>
          </div>
        </ModalNew>

        {/* modal date */}
        <ModalNew
          title="Cambio de fecha"
          show={this.state.modalDate}
          onHide={() => this.setState({ ...this.state, modalDate: false })}
          btnYesEvent={() => this.props.updateAgreement({ ...this.state.addAgreement },
            () => {
              this.setState(this.initialState);
              this.props.getAgreements(this.state.filters)
            })}
          btnYesDisabled={!!this.state.modalDate && !this.state.addAgreement?.justification_renew ? true : false}
        >
          <div className="d-flex flex-column">
            <div className="w-100">
              <label htmlFor="due_date_before" className={ownStyles.labelFontGray}>Fecha de vencimiento anterior</label>
              <input
                type="date"
                name="valid_until_before"
                className={` fw-bold text-primary bg-input-disabled w-100 ${ownStyles.selectInput}`}
                disabled
                value={this.state.addAgreement?.valid_until_before}
              />
            </div>
            <div className="w-100">
              <label htmlFor="valid_until" className={ownStyles.labelFont}>Nueva fecha*</label>
              <input type="date" name="valid_until" className={`fw-bold text-primary w-100 ${ownStyles.selectInput}`} onChange={this.onChangeForm} />
            </div>
            <div className="w-100">
              <label htmlFor="justification_renew" className={ownStyles.labelFont}>Justificación*</label>
              <textarea name="justification_renew" cols="30" rows="10" className={`w-100 ${ownStyles.justificationRenew}`} onChange={this.onChangeForm}></textarea>
            </div>

          </div>
        </ModalNew>

        <ModalNew
          title={this.state.purchaseOrder?.purchaseOrder_number ? `Orden de compra ${this.state.purchaseOrder?.purchaseOrder_number}` : ""}
          size="940"
          show={this.state.modalPurchaseOrder}
          onHide={() => this.setState({ ...this.state, modalPurchaseOrder: false, modalOC: true, purchaseOrder: {} })}
        >
          <div className="d-flex flex-column mb-4">
            <div className="d-flex w-100 justify-content-between">
              <div className="flex-column col-sm-5">
                <label htmlFor="provider" className={ownStyles.labelFont}>Proveedor</label>
                <input className={`w-100 ${ownStyles.inputTextClear}`} type="text" name="provider" disabled value={this.state.purchaseOrder?.provider_ref?.name} />
              </div>
              <div className="col-sm-3">
                <label htmlFor="nit" className={ownStyles.labelFont}>NIT</label>
                <input className={`w-100 ${ownStyles.inputTextClear}`} type="text" name="nit" disabled value={this.state.purchaseOrder?.provider_ref?.nit} />
              </div>
              <div className="col-sm-3">
                <label htmlFor="pay_conditions" className={ownStyles.labelFont}>Condiciones de pago</label>
                <input className={`w-100 ${ownStyles.inputTextClear}`} type="text" name="pay_conditions" disabled value={this.getPaymentCondition(this.state.purchaseOrder.pay_conditions)} />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-between">
              <div className="flex-column col-sm-5">
                <label htmlFor="terms" className={ownStyles.labelFont}>Términos y condiciones</label>
                <input className={`w-100 ${ownStyles.inputTextClear}`} type="text" name="terms" disabled value={this.state.purchaseOrder?.agreement_ref?.quotation_ref?.individual_auctions?.terms} />
              </div>
              <div className="col-sm-3">
                <label htmlFor="nit" className={ownStyles.labelFont}>Dirección de entrega</label>
                <input className={`w-100 ${ownStyles.inputTextClear}`} type="text" name="nit" disabled value={this.state.purchaseOrder?.warehouse_ref?.address} />
              </div>
              <div className="col-sm-3 d-flex justify-content-between" style={{ marginTop: "19px", alignItems: "center" }}>
                <input
                  className="border border-dark-blue form-check-input p1 check-dark-blue"
                  type="checkbox"
                  name="delivery"
                  readOnly
                  checked={!!this.state.purchaseOrder.partial_deliveries}
                />
                <label htmlFor="delivery" className={`${ownStyles.containDeliveries}`}>Contiene entregas parciales</label>
              </div>
            </div>
          </div>
          <TableScrollSubtotal
            columns={columns}
            rows={rowsScroll}
            textAreaDisabled
            valueTextarea={this.state.purchaseOrder?.observations}
            subtotal={this.state.purchaseOrder?.subtotal}
            total_discount={this.state.purchaseOrder?.total_discount}
            total_iva={this.state.purchaseOrder?.total_iva}
            total={this.state.purchaseOrder?.total}
          />
          {/* <GenericTableScroll
            sytle={{maxWidth:"50%", maring:"auto"}}
            height="100px"
            headerClassName="containHeaderTableScroll90"
            bodyClassname="containerBodyTableScroll90"
            footerClassname="containerFooterTableScroll90"
            headers={
              [
                <Col className="text-center" style={{width:"10%"}}>Item</Col>,
                <Col className="text-center">Descripción de Artículo</Col>,
                <Col className="text-center">Cant.</Col>,
                <Col className="text-center">Valor Unidad</Col>,
                <Col style={{width:"15%"}}>
                    <div className="mx-auto text-center px-3">
                      Descuento
                      <br />
                      <div className="d-flex justify-content-around">
                        <span>%</span>
                        <span>$</span>
                      </div>
                    </div>
                </Col>,
                <Col className="text-center" style={{width:"15%"}}>
                  <div className="mx-auto text-center">
                    IVA
                    <br />
                    <div className="d-flex justify-content-around">
                      <span>%</span>
                      <span>$</span>
                    </div>
                  </div>
                </Col>,
                <Col className="text-center">Total</Col>,
            ]
            }
            footer={
              [  
                <Row className="mt-2 bg-white">
                  <Col className="col-9 bg-gray-on-table">
                    <div className="ml-3 d-flex flex-column" style={{width:"65%"}}>
                    <label htmlFor="observations" className={ownStyles.labelFont}>
                      Observaciones
                    </label>
                    <textarea
                      name="observations"
                      cols="30"
                      rows="10"
                      className={ownStyles.observationsArea}
                      onChange={this.onChangeForm}
                      value={this.state.purchaseOrder?.observations}
                      disabled
                    ></textarea>
                  </div>
                  </Col>
                  <Col>
                  <Row>
                    <Col className="text-start">
                      Subtotal
                    </Col>                    
                    <Col className="flex-wrap text-center">
                      {this.state.purchaseOrder?.subtotal ? convertMoneyFormat(this.state.purchaseOrder?.subtotal) : "-"}
                    </Col>
                  </Row>
                  <Row>                  
                    <Col className="text-start">Descuento</Col>
                    <Col className="text-center">
                      {this.state.purchaseOrder?.total_discount ? convertMoneyFormat(this.state.purchaseOrder?.total_discount) : "-"}
                    </Col>
                  </Row>                                
                <Row>                  
                  <Col className="text-start">IVA</Col>
                  <Col className="text-center">
                  {this.state.purchaseOrder?.total_iva ? convertMoneyFormat(this.state.purchaseOrder?.total_iva) : "-"}
                  </Col>
                </Row>
                <Row>                  
                  <Col className="text-primary fw-bold fs-6 text-start">TOTAL</Col>
                  <Col className="text-primary fw-bold fs-6 text-center">
                  {this.state.purchaseOrder?.total ? convertMoneyFormat(this.state.purchaseOrder?.total) : "-"}
                  </Col>
                </Row>
                </Col>
                </Row>
              ]
            }
          > */}
          {/* {renderArticles} */}
          {/* </GenericTableScroll> */}
          {/* <GenericTableNew
            style={{height:"100px"}}
                headers={
                  [
                  <th className="text-center" style={{width:"10%"}}>Item</th>,
                  <th className="text-center">Descripción de Artículo</th>,
                  <th className="text-center">Cant.</th>,
                  <th className="text-center">Valor Unidad</th>,
                  <th style={{width:"15%"}}>
                      <div className="mx-auto text-center px-3">
                        Descuento
                        <br />
                        <div className="d-flex justify-content-around">
                          <span>%</span>
                          <span>$</span>
                        </div>
                      </div>
                  </th>,
                  <th className="text-center" style={{width:"15%"}}>
                    <div className="mx-auto text-center">
                      IVA
                      <br />
                      <div className="d-flex justify-content-around">
                        <span>%</span>
                        <span>$</span>
                      </div>
                    </div>
                  </th>,
                  <th className="text-center">Total</th>,
              ]}
                dark={false}
                lowerCase={true}
                footer={renderFooter}
              >
                {renderArticles}
              </GenericTableNew> */}
          {/* <div className="d-flex justify-content-between" style={{marginTop:"-80px"}}>
              <div className="ml-3 d-flex flex-column" style={{width:"65%"}}>
                  <label htmlFor="observations" className={ownStyles.labelFont}>
                    Observaciones
                  </label>
                  <textarea
                    name="observations"
                    cols="30"
                    rows="10"
                    className={ownStyles.observationsArea}
                    onChange={this.onChangeForm}
                    value={this.state.purchaseOrder?.observations}
                    disabled
                  ></textarea>
                </div>
              </div> */}
        </ModalNew>

        {/* table scroll */}



        {/* end */}

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
  agreementReducer: state.agreementReducer,
  currentAccount: state.loginReducer.currentAccount,
  loading: state.agreementReducer.loading,
  row_total: state.agreementReducer.row_total,
  loginReducer: state.loginReducer,
  purchaseOrders: state.purchaseOrderReducer.purchaseOrders,
  //   auctionArticles: state.auctionReducer.auctionArticles,
  //   totalArticles: state.auctionReducer.totalArticles,
});

const mapDispatchToProps = (dispatch) => ({
  getAgreements: (filters) => {
    dispatch(getAgreements(filters));
  },
  getAgreementsProviders: (filters) => {
    dispatch(getAgreementsProviders(filters));
  },
  changeStatusAgreement: (data, doAfter) => {
    dispatch(changeStatusAgreement(data, doAfter));
  },
  getOneAgreement: (data, doAfter) => {
    dispatch(getOneAgreement(data, doAfter));
  },
  updateAgreement: (data, doAfter) => {
    dispatch(updateAgreement(data, doAfter))
  },
  getPurchaseOrders: (params, doAfter) => {
    dispatch(getPurchaseOrders(params, doAfter))
  },
  setLoading: (payload) => {
    dispatch({
      type: "LOADING",
      payload: payload
    })
  }

});
export default connect(mapStateToProps, mapDispatchToProps)(TableAgreements);
