// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tableCieten_paddingTable__2cMO- {\n  padding: 0rem;\n}\n\n@media only screen and (max-width: 1600px) {\n  .tableCieten_paddingTable__2cMO- {\n    padding: 0 6rem;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/TableCieTen/tableCieten.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AACA;EACI;IACI,eAAA;EAEN;AACF","sourcesContent":[".paddingTable{\n    padding: 0rem;\n}\n@media only screen and (max-width:1600px){\n    .paddingTable{\n        padding: 0 6rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paddingTable": "tableCieten_paddingTable__2cMO-"
};
export default ___CSS_LOADER_EXPORT___;
