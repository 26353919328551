import React, { Component } from "react";
import Filter from "../../assets/img/icons/filtro.svg";
import Add from "../../assets/img/icons/CrearCuentaOscuro.svg";
import Export from "../../assets/img/icons/excel.svg";
import GearGray from "../../assets/img/icons/engraneGris.svg";
import EditIcon from "../../assets/img/icons/editar.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import DeactiveIcon from "../../assets/img/icons/puntoRojo.svg";
import ActiveIcon from "../../assets/img/icons/puntoVerde.svg";
import SearchIcon from "../../assets/img/icons/lupa.svg";
import SearchFileIcon from "../../assets/img/icons/SearchLocal.svg";
import popupStyles from "../Layouts/popupEdEdit.module.css";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import { connect } from "react-redux";
import PucStyles from "./Puc_CSS.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import GenericTable from "../Layouts/GenericTable";
//import ModalCreateRecord from "../Layouts/ModalCreateRecord";

import {
  getAllPUCAccounts,
  getLocalAccounts,
  getNiifAccounts,
  changeStatus,
  createPuc,
  updatePuc,
  getNiifByNumber,
  cleanNiifAccountLinked,
  getAllPUC
} from "../../actions/pucActions";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Pagination from "react-js-pagination";
import { defaultFilters, PAGE, PERPAGE } from "../../helpers/constants";
import * as deepcopy from "deepcopy";
import AccountInput from "../Layouts/AccountInput";
import ReactCodeInput from "react-code-input";
import style from "./tablePuc.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomPopup from "../Popup/customPopUpExtends";
import "./customReactCodeInput.scss";
import ModalNew from "../Layouts/ModalNew";
import GenericTableNew from "../Layouts/GenericTableNew";
import { customSwaltAlert } from "../../helpers";

const MySwal = withReactContent(Swal);

class TablePuc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titleModal: "Crear cuenta contable",
      modal: false,
      editRecord: false,
      addPuc: {
        id: "",
        code: "",
        newCode: "",
        description: "",
        niifLinked: "",
        niifDescription: "",
        uses_third: false,
        uses_cost_center: false,
        type: "",
        check_format: "",
        account_type: "",
        name_bank: "",
        num_count: "",
        check_info_bank: false,
        entity_account: this.props.currentAccount.id,
        detail: false,

      },
      totalIndex: 0,
      filters: {
        search: "",
        active: false,
        perpage: PERPAGE,
        page: PAGE,
        eaccount: this.props.currentAccount.id,
      },
      headerState: [
        {
          tittle: "No. de cuenta",
          className: "text-start px-2",
        },
        {
          tittle: "Nombre",
          className: "text-start",
        },
        {
          tittle: "Tipo",
          className: "text-center",
        },
        {
          tittle: "No. Bancario",
          className: "text-center",
        },
        {
          tittle: "Estado",
          className: "text-center w100",
        },
      ],
    };
  }

  myPermission = () => this.props.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "PUC");

  componentDidMount() {
    const { history } = this.props
    if (!this.myPermission()?.read) {
      history?.push("/compras/inicio");
    }
    this.props.getAllPUC(this.state.filters)
    this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.allAccounts !== prevProps.allAccounts) {
      this.setState({
        allAccounts: this.props.allAccounts,
      });
    }
  }

  getInitialState = () => {
    this.setState({
      titleModal: "Crear cuenta contable",
      modal: false,
      editRecord: false,
      addPuc: {
        id: "",
        code: "",
        newCode: "",
        description: "",
        niifLinked: "",
        niifDescription: "",
        uses_third: false,
        uses_cost_center: false,
        type: "",
        check_format: "",
        account_type: "",
        name_bank: "",
        num_count: "",
        check_info_bank: false,
        entity_account: this.props.currentAccount.id,
        detail: false,
      },
      totalIndex: 0,
      filters: {
        search: "",
        active: "",
        perpage: PERPAGE,
        page: PAGE,
        eaccount: this.props.currentAccount.id,
      },
      headerState: [
        {
          tittle: "No. de cuenta",
          className: "text-start px-2",
        },
        {
          tittle: "Nombre",
          className: "text-start",
        },
        {
          tittle: "Tipo",
          className: "text-center",
        },
        {
          tittle: "No. Bancario",
          className: "text-center",
        },
        {
          tittle: "Estado",
          className: "text-center w100",
        },
      ],
    });
  };

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getAllPUC(this.state.filters);
  }


  handleEditRecord = (puc) => {
    let { modal, addPuc, titleModal, copypuc } = this.state;
    modal = !this.state.modal;
    addPuc = deepcopy(puc);
    addPuc.code = addPuc.complete_account;
    if (addPuc.niifAccount) {
      addPuc.niifLinked = addPuc.niifAccount;
      this.props.getNiifByNumber(addPuc.niifLinked);
    }
    titleModal = "Editar cuenta";
    this.setState({ modal, addPuc, editRecord: true, titleModal });
    if (addPuc.type === "local") {
      if (addPuc.niifAccount) {
        setTimeout(async () => {
          let niif = this.props.niifAccounts;
          addPuc.niifDescription = niif.description;
          await this.setState({
            addPuc,
          });
        }, 500);
      }
    }
  };

  handleShowDetails = (puc) => {
    let { modal, addPuc, titleModal, copypuc } = this.state;
    modal = !this.state.modal;
    addPuc = deepcopy(puc);
    addPuc.code = addPuc.complete_account;
    addPuc["detail"] = true;
    if (addPuc?.niifAccount) {
      addPuc.niifLinked = addPuc.niifAccount;
      this.props.getNiifByNumber(addPuc.niifLinked);
    }
    titleModal = "Detalle de cuenta";
    this.setState({ modal, addPuc, titleModal });
    if (addPuc.type === "local") {
      if (addPuc.niifAccount) {
        setTimeout(async () => {
          let niif = this.props.niifAccounts;
          addPuc.niifDescription = niif.description;
          await this.setState({
            addPuc,
          });
        }, 500);
      }
    }
  };

  toggleStatus = (account) => {
    this.props.changeStatus(account);
  };

  // Search puc when user was typing
  onKeyUpSearch = (e) => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
      this.props.getAllPUC(this.state.filters);
    } else {
      e.preventDefault();
    }
    this.setState({ filters });
  };

  //Method to search when click in magnify lens
  handleSearch = () => {
    let { filters } = this.state;
    filters.page = 1;
    this.props.getAllPUC(this.state.filters);
  };

  // Search niff linked
  handleSearchNiifAccount = () => {

    this.props.getNiifByNumber(this.state.addPuc.niifLinked);
    let { addPuc } = this.state;
    setTimeout(() => {
      let niif = this.props.niifAccounts;
      if (niif.id !== null) {
        addPuc.niifDescription = niif.description;
        if (addPuc.niifDescription !== niif.description) {
          return this.handleSearchNiifAccount()
        } else {
          this.setState({ addPuc });
        }
      } else {
        addPuc.niifDescription = "NO SE ENCONTRÓ LA CUENTA NIIF"
        this.setState({ addPuc });
      }
    }, 1000);
  };

  toggleModal = (e) => {
    let { modal } = this.state;
    modal = !modal;
    this.props.cleanNiifAccountLinked()
    if (this.state.editRecord) {
      this.setState({ modal });
    }
    this.getInitialState();
    this.setState({ modal });
  };

  onChangeForm = (e) => {
    var { name, value } = e.target;
    let { addPuc } = this.state;
    if (name === "niifLinked") {
      var regex = /^[A-Za-z]+$/;
      if (String(value).match(regex) || isNaN(value)) {
        return customSwaltAlert({
          icon: "warning",
          title:'Intenta de nuevo',
          text: "El campo solo acepta texto",
          showCancelButton: false
        });
      }
    }
    addPuc[name] = value;
    this.setState({ addPuc });
  };

  onChangeCheckBox = (e) => {
    var { name } = e.target;
    let { addPuc } = this.state;
    addPuc[name] = !addPuc[name];
    this.setState({ addPuc });
  };

  onChangeAccountNumber = (value) => {
    let { addPuc } = this.state;
    if (this.state.editRecord) {
      addPuc.newCode = value;
    } else {
      addPuc.code = value;
    }
    this.setState({ addPuc });
  };

  handleSubmit() {
    this.toggleModal();
    if (this.state.editRecord) {
      if (this.state.addPuc.check_info_bank) {
        return this.props.updatePuc(this.state.addPuc);
      } else {
        let { addPuc } = this.state;
        addPuc.account_type = ""
        addPuc.name_bank = ""
        addPuc.num_count = ""
        addPuc.entity_account = this.props.currentAccount.id
        this.setState({ addPuc });
        return this.props.updatePuc(this.state.addPuc);
      }

    }
    this.props.createPuc(this.state.addPuc);
    this.getInitialState();
  }

  renderAccounts(account) {
    return (
      <tr
        key={`${account.id}${account.type}`}
        className="hover-table-row text-center align-middle"

      >
        <td className="col-md-2 text-start px-2">{account.complete_account} </td>
        <td className="col-md-6 text-start">{account.description}</td>
        <td className="col-md-1 text-uppercase">{account.type}</td>
        <td className="col-md-2 text-center">{!!account.num_count ? account.num_count : "-"}</td>
        <td className="col-md-1">
          <div
            className={`${tableStyles.groupElems}`}
          >
            <div className={account.active ? tableStyles.greenState : tableStyles.redState}>{account.active ? 'Habilitado' : 'Inhabilitado'}</div>
              <td className="position-relative mx-2" style={{ paddingLeft: "0.5rem" }}>
                <CustomPopup
                  triggerSrc={threeDots}
                  showEdit={this.myPermission()?.edit}
                  editClickEvent={() => this.handleEditRecord(account)}
                  showDetailsEvent={() => this.handleShowDetails(account)}
                  showDetails={this.myPermission()?.read}
                  showEnable={this.myPermission()?.edit}
                  isEnabled={account.active}
                  enableClickEvent={() => this.toggleStatus(account)}
                  noHover
                />
              </td>
          </div>
        </td>
      </tr>
    );
  }
  validateBoton() {
    let disableModalButton = false

    if (this.state.addPuc?.check_info_bank) {
      if (!!this.state.addPuc.code === false ||
        !!this.state.addPuc.account_type === false ||
        !!this.state.addPuc.num_count === false ||
        !!this.state.addPuc.name_bank === false ||
        !!this.state.addPuc.description === false ||
        !!this.state.addPuc.type === false) {
        disableModalButton = true
      }
    } else {
      if (!!this.state.addPuc.code === false ||
        !!this.state.addPuc.type === false ||
        !!this.state.addPuc.description === false) {
        disableModalButton = true
      }
    }
    return disableModalButton
  }
  render() {
    // const x = Math.random();
    let renderHeaders = this.state.headerState.map((header) => {
      return (
        <th>
          <div className={`${header === 'est.' || header === '' ? tableStyles.groupElems : ''} ${header.className}`}>
            {header.tittle}
          </div>
        </th>
      )
    });
    let renderAccounts = this.props.allAccounts
      ? this.props.allAccounts.map((account) => {
        return this.renderAccounts(account);
      })
      : "";

    //let disableModalButton = (!!this.state.addPuc.niifLinked===false && !!this.state.addPuc.niifLinked===false);
    const handleSumit = (e) => {
      e.preventDefault();
      let { filters } = this.state;
      filters.page = 1;
      this.props.getAllPUC(this.state.filters);
    }
    return (
      <div className={tableStyles.container}>
        {(this.props.loading) &&
          <div className="loading">
            <Loader
              type="Oval"
              color="#003f80"
              height={100}
              width={100}
            />
          </div>
        }
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Plan único de cuenta</h1>
          <div className="">

            <div className=" flex-direction-row widtDiv align-middle">
              {
                this.myPermission()?.read && (
                  <form onSubmit={handleSumit} style={{ display: "contents" }}>
                    <input
                      className={tableStyles.searchUsersAlt}
                      type="text"
                      placeholder="  Buscar..."
                      value={this.state.filters.search}
                      onChange={(e) => this.onKeyUpSearch(e)}
                    />
                    <img
                      src={SearchIcon}
                      alt="buscar"
                      className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                      onClick={this.handleSearch}
                    />
                  </form>
                )
              }

              {
                this.myPermission()?.read && (
                  <img
                    src={Export}
                    alt="exportar"
                    className={`float-right ${PucStyles.buttonExcel} ${PucStyles.btnNewHover} ${PucStyles.imagePadding} align-self-center`}
                    style={{ marginTop: "7px" }}
                  />
                )
              }

              {this.myPermission()?.create &&
                <div className={`mx-3 ${PucStyles.buttonCreatCount} align-items-center`} onClick={this.toggleModal}>

                  <p className={`${PucStyles.labelNew}`}
                    style={{ fontWeight: "900px", fontSize: "16px", paddingRight: "0.2rem" }}
                  >Crear cuenta </p>
                  <img
                    src={Add}
                    alt="añadir"
                    className={`${PucStyles.btnNewHover} ${PucStyles.imagePadding}`}
                  />
                </div>
              }

            </div>
          </div>

          <ModalNew
            hideFooter={this.state.editRecord ? !this.myPermission()?.edit : !this.myPermission()?.create }
            title={this.state.titleModal}
            show={this.state.modal}
            btnYesEvent={
              !this.state.addPuc.detail ? () => this.handleSubmit() : null
            }
            btnNoName={!this.state.addPuc.detail ? "Cancelar" : "Cancelar"}
            btnYesName="Aceptar"
            btnYesDisabled={this.validateBoton()}
            onHide={() => this.toggleModal(false)}
          >
            <div className="row justify-content-center mb-4">
              <div className=" justify-content-center mx-auto">
                <p className={`float-left labelNumberAccount ${PucStyles.label_inputs_new}`}>
                  No. de cuenta<span className={"text-warning"}>*</span>
                </p>
                <ReactCodeInput
                  type="number"
                  fields={8}
                  value={this.state.addPuc.code}
                  name="code"
                  disabled={!!this.state.addPuc.detail}
                  onChange={this.onChangeAccountNumber}
                  inputStyle={{
                    backgroundColor: "rgba(0, 93, 191, 0.1)",
                    border: "1px solid rgba(0,0,0,0.05)",
                    borderRadius: "6px",
                    padding: "1rem 0.5rem",
                    margin: "0.1rem",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    //flexDirection: "column",
                  }}
                />

                {/* <AccountInput /> */}
                <div className={`d-flex justify-content-araund ${PucStyles.divAjuste}`}>

                  <p className={`${PucStyles.divAjuste2} ${PucStyles.label_inputs_new2}`}>
                    Clase
                  </p>
                  <p className={`${PucStyles.divAjuste2} ${PucStyles.label_inputs_new2}`}>
                    Grupo
                  </p>
                  <p className={`${PucStyles.divAjuste3} ${PucStyles.label_inputs_new2}`}>
                    Cuenta
                  </p>
                  <p className={`${PucStyles.divAjuste3} ${PucStyles.label_inputs_new2}`}>
                    Subcuenta
                  </p>
                  <p className={`${PucStyles.divAjuste3} ${PucStyles.label_inputs_new2}`}>
                    Auxiliar
                  </p>


                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="  mx-auto">
                <p className={`${PucStyles.label_inputs_new}`}>Nombre<span className={"text-warning"}>*</span></p>
                <textarea
                  rows="10"
                  placeholder='Escribir...'
                  cols="10"
                  className={`${PucStyles.register_inputs_new} p-1 ${tableStyles.outlineNone} px-2`}
                  style={{color:'#58595b'}}
                  name="description"
                  type="text"
                  onChange={this.onChangeForm}
                  disabled={!!this.state.addPuc.detail}
                  value={this.state.addPuc.description || ''}
                />
              </div>
              <div
                className={` mt-3 mx-5 p-3`}
              >
                <p className={`${PucStyles.titulo}`}>Tipo de cuenta</p>
                <div className="d-flex justify-content-flex-start">
                  <div className="form-check mx-1">
                    <input
                      className="form-check-input styleCheckInputRatio"
                      type="radio"
                      name="type"
                      id="radioNiifSelect"
                      value="niif"
                      onChange={this.onChangeForm}
                      checked={this.state.addPuc.type === "niif" ? true : false}
                      disabled={!!this.state.addPuc.detail}
                    />
                    <label className={`form-check-label ${PucStyles.label_inputs_new3}`} for="radioNiifSelect">
                      NIIF
                    </label>
                  </div>
                  <div className="form-check mx-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="type"
                      id="radioLocalSelect"
                      value="local"
                      onChange={this.onChangeForm}
                      checked={
                        this.state.addPuc.type === "local" ? true : false
                      }
                      disabled={!!this.state.addPuc.detail}
                    />
                    <label className={`form-check-label ${PucStyles.label_inputs_new3}`} for="radioLocalSelect">
                      Local
                    </label>
                  </div>
                </div>
                {this.state.addPuc.type === "local" && (
                  <div className="d-flex mx-auto justify-content-center">
                    <div className="col-md-4   mt-3 mx-1 align-items-center">
                      <p className={` ${PucStyles.label_inputs_new1}`}>Núm. de cuenta</p>
                      <input
                        className={` ${PucStyles.registerInputs}`}
                        name="niifLinked"
                        placeholder='Escribir...'
                        type="text"
                        maxLength={8}
                        onChange={this.onChangeForm}
                        value={this.state.addPuc.niifLinked || ''}
                        disabled={!!this.state.addPuc.detail}
                      />
                    </div>
                    <img
                      src={SearchFileIcon}
                      alt="buscar cuenta"
                      className="icons-svg-top-table float-left hoverPointer mr-5"
                      onClick={this.handleSearchNiifAccount}
                      style={{ marginTop: "auto", maxWidth: "1.7rem" }}
                    />
                    <div className="mt-3 mx-1">
                      <p className={` ${PucStyles.label_inputs_new1}`}>
                        Cuenta de Homologación NIIF<span className={"text-warning"}>*</span>
                      </p>
                      <input
                        className={` ${PucStyles.registerInputs}`}
                        name="niifDescription"
                        type="text"
                        placeholder='...'
                        disabled={true}
                        value={this.state.addPuc.niifDescription || ''}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex  mt-3">
                <div className="form-check mx-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="uses_third"
                    id="uses_third"
                    onChange={this.onChangeCheckBox}
                    checked={this.state.addPuc.uses_third}
                    disabled={!!this.state.addPuc.detail}
                  />
                  <label className={`form-check-label ${PucStyles.label_inputs_new3}`} for="uses_third">
                    Utiliza terceros
                  </label>
                </div>
                <div className="form-check mx-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="uses_cost_center"
                    id="usesCenterCost"
                    onChange={this.onChangeCheckBox}
                    checked={this.state.addPuc.uses_cost_center}
                    disabled={!!this.state.addPuc.detail}
                  />
                  <label className={`form-check-label ${PucStyles.label_inputs_new3}`} for="usesCenterCost">
                    Exige centro de costo
                  </label>
                </div>

              </div>
              <div className="d-flex  mt-3">
                <div className="form-check mx-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="check_info_bank"
                    id="usesInfoBank"
                    onChange={this.onChangeCheckBox}
                    checked={this.state.addPuc.check_info_bank}
                    disabled={!!this.state.addPuc.detail}
                  />
                  <label className={`form-check-label ${PucStyles.label_inputs_new3}`} for="usesInfoBank">
                    Contiene Información bancaria
                  </label>
                </div>
              </div>

              {this.state.addPuc.check_info_bank ?
                <div className={" mt-3 mx-5 p-3"}>
                  <p className={` ${PucStyles.titulo}`}> Información Bancaria</p>
                  <div className="mx-auto">
                    <p className={` ${PucStyles.label_inputs_new}`}>Nombre del banco<span className={"text-warning"}>*</span></p>
                    <input
                      className={` ${PucStyles.register_inputs_new} px-2`}
                      name="name_bank"
                      type="text"
                      placeholder='Escribir...'
                      onChange={this.onChangeForm}
                      value={this.state.addPuc.name_bank || ''}
                      disabled={!!this.state.addPuc.detail}
                    />
                  </div>
                  <div className={`d-flex ${PucStyles.justify} gap-2 mt-2`}>

                    <div className={` ${PucStyles.flexDiv}  ${PucStyles.flexDiv_margin}`}>
                      <p className={` ${PucStyles.label_inputs_new}`}>Número de Cta.</p>
                      <input
                        className={` flexDiv__flexSelectInput ${PucStyles.register_inputs_new} px-2`}
                        name="num_count"
                        type="number"
                        placeholder='Escribir...'
                        maxLength={11}
                        onChange={this.onChangeForm}
                        value={this.state.addPuc.num_count}
                        disabled={!!this.state.addPuc.detail}
                      />
                    </div>

                    <div className="flexDiv">
                      <p className={`${PucStyles.label_inputs_new}`}>Tipo de Cta.</p>

                      <select
                        name="account_type"
                        className={` flexDiv__flexSelectInput ${PucStyles.register_inputs_new} px-2`}
                        onChange={this.onChangeForm}
                        disabled={!!this.state.addPuc.detail}
                        value={this.state.addPuc.account_type}>
                        <option value="">Seleccionar...</option>
                        <option value="Corriente" >Corriente</option>
                        <option value="Fondos_de_inversión">Fondos de inversión</option>
                        <option value="Ahorros">Ahorros</option>
                        <option value="Fiduciarias">Fiduciarias</option>

                      </select>
                    </div>
                  </div>
                  {/* <div>
                    
                    <p className={` ${PucStyles.label_inputs_new}`}>Formato de cheque</p>
                    <select
                        name="check_format"
                        className={` ${PucStyles.register_inputs_new}`}
                        onChange={this.onChangeForm}
                        value={this.state.addPuc.check_format}
                        disabled={!!this.state.addPuc.detail}
                    >
                        <option value="" ></option>
                        <option value="Sin_cheque">Sin Cheque</option>
                        <option value="Chequera_Personal">Chequera personal</option>
                        <option value="Chequera_Empresarial">Chequera Empresarial</option>
                          
                    </select>
                  </div> */}
                </div>
                :
                <span></span>
              }


            </div>
          </ModalNew>

          {
            this.myPermission()?.read && (
              <GenericTableNew fontFamilyTable={'fontFamilyTable'} dark={true} headers={renderHeaders}>{renderAccounts}</GenericTableNew>
            )
          }


          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {this.props.totalIndexAccount ? this.state.filters.page : ""}
              {" de "}
              {Math.ceil(this.props.totalIndexAccount / this.state.filters.perpage) ?
                Math.ceil(this.props.totalIndexAccount / this.state.filters.perpage)
                :
                ""
              }
              {" "}
              ({this.props.totalIndexAccount} encontrados)
            </p>
            <Pagination
              activePage={this.state.filters.page}
              itemsCountPerPage={10}
              totalItemsCount={this.props.totalIndexAccount}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
  currentAccount: state.loginReducer.currentAccount,
  allAccounts: state.pucReducer.allAccounts,
  niifAccounts: state.pucReducer.niifAccounts,
  localAccounts: state.pucReducer.localAccounts,
  totalIndexAccount: state.pucReducer.totalIndexAccount,
  createSucces: state.pucReducer.createSucces,
  totalIndexPuc: state.pucReducer.totalIndexPuc,
  loading: state.pucReducer.puc_loading,
});

const mapDispatchToProps = (dispatch) => ({
  getAllPUCAccounts: (filters) => {
    dispatch(getAllPUCAccounts(filters));
  },
  getNiifAccounts: async (filters) => {
    await dispatch(getNiifAccounts(filters));
  },
  getLocalAccounts: async (filters) => {
    await dispatch(getLocalAccounts(filters));
  },
  changeStatus: (status) => {
    dispatch(changeStatus(status));
  },
  createPuc: (objPuc) => {
    dispatch(createPuc(objPuc));
  },
  updatePuc: (objPuc) => {
    dispatch(updatePuc(objPuc));
  },
  getNiifByNumber: (number) => {
    dispatch(getNiifByNumber(number));
  },
  cleanNiifAccountLinked: () => {
    dispatch(cleanNiifAccountLinked())
  },
  getAllPUC: (filters) => {
    dispatch(getAllPUC(filters))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TablePuc);
