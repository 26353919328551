import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SlideDownButton } from "../../components/Layouts/SlideDownButton";
import { OrdGenericTemplate } from "../OrderingComponents/OrdGenericTemplate";
import OrdTable from "../OrderingComponents/OrdTable";
import Lupa2 from "../../assets/img/icons/NewLupa.svg";
import Lupa from "../../assets/img/icons/OrdLupa.svg";
import { Col, Form, Row } from "react-bootstrap";
import ordComponentStyles from "../OrderingComponents/ordComponentStyles.module.scss";
import Select from "react-select";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import threeDots from "../../assets/img/icons/threeDots.svg";
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import { useGetMethod, useMultiGetMethod } from "../../Hooks/useFetch";
import { useSelector } from "react-redux";
import { loader, formatToRcSelect, message, downloaFile, downloaFileAddName } from "../../helpers/helpers";
import poundSign from "../../assets/img/icons/CentrodeCosto.svg";
import pillDarkBlue from "../../assets/img/icons/pillDarkBlue.svg";
import ordSugess from "../../assets/img/icons/ordSugess.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import alertIcon from "../../assets/img/icons/alert-circle-orange.svg";
import Menos from "../../assets/img/icons/Menos.svg";
import check2 from "../../assets/img/icons/check2.svg";
import redCloseX from "../../assets/img/icons/redcloseX.svg";
import generate from "../../assets/img/icons/generate.svg";
import justificationIcon from "../../assets/img/icons/justification.svg";
import FullScreenDialog from "../OrderingComponents/FullScreenDialog";
import { RenderMedicalFormulasPdf } from "../../AsistanceModule/RenderMedicalFormulasPdf";
import OrdModal from "../OrderingComponents/OrdModal";
import pdfIcon from "../../assets/img/icons/pdfBlue.svg";
import flecha from "../../assets/img/icons/desplegarGris.svg";
import searchBlueIcon from "../../assets/img/icons/searchBlueIcon.svg";
import CloneIconClearBlue from "../../assets/img/icons/CloneIconClearBlue.svg";
import { OrdSlideDownButton } from '../OrderingComponents/OrdSlideDownButton'


export const MedicationHistory = (props) => {
  let history = useHistory();
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const idCurrentAccount = store.loginReducer.currentAccount.id;
  const { results: medicHistory, load: medicHistoryLoader, trigger: getMedicHistory, } = useGetMethod();
  const { results: unities, load: unitiesLoader, trigger: getUnities, } = useMultiGetMethod();
  const { results: medicOrder, load: medicOrderLoader, trigger: getPdfOrder } = useGetMethod()
  const [trigger, setTrigger] = useState(0);
  const [rotate, setRotate] = useState(false)
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    idAccount: idCurrentAccount,
  });
  const [fullDialogScreen, setFullDialogScreen] = useState({
    show: false,
    id: "",
    ordTag: "",
  });
  const [justification, setJustification] = useState({
    showModal: false,
    justificationText: "",
  });
  const doctorOptions = formatToRcSelect(
    unities?.doctors?.results,
    "idDoctor",
    "nameDoctor",
    "",
    "",
    ""
  );
  const contractsOptions = formatToRcSelect(
    unities?.contracts?.results,
    "id",
    "contractName",
    "",
    "",
    ""
  );
  const companiesOptions = formatToRcSelect(
    unities?.company?.results,
    "idCorporateClient",
    "nameCorporateClient",
    "",
    "",
    ""
  );
  const statusOptions = formatToRcSelect(
    unities?.status?.results,
    "id",
    "description",
    "",
    "",
    ""
  );
  const sitesOptions = formatToRcSelect(
    unities?.sites?.data,
    "id",
    "description",
    "",
    "",
    ""
  );
  const headers = [
    {
      title: "Identificación",
      className: "px-2 text-start",
    },
    {
      title: "Paciente",
      className: "px-2 text-start",
    },
    {
      title: "Médico",
      className: "px-2 text-start",
    },
    {
      title: "Sede",
      className: "px-2 text-center",
    },
    {
      title: "Tipo",
      className: "px-2 text-center",
    },
    {
      title: "Fecha de creación",
      className: "px-2 text-center",
    },
    // {
    //   title: "MIPRES",
    //   className: "px-2 text-center",
    // },
    {
      title: "Paciente recibe",
      className: "px-2 text-center",
    },
    {
      title: "Estado",
      className: "px-2 text-center",
    },
    {
      title: <>ㅤ</>,
    },
  ];
  const buttons = [
    {
      button: < img
        alt="btn"
        src={Lupa}
      >
      </img>,
    },
    {
      button: < img
        alt="btn"
        style={{
          transform: !rotate && "rotate(180deg)"
        }}
        width={10}
        src={flecha}
      >
      </img>,
    },
  ]
  const renderTooltipTypeIcon = ({ item }) => (
    <Tooltip>
      {item?.ortTag === "insurance"
        ? "Aseguradora"
        : item.ortTag === "sugess"
          ? "Sugerencia"
          : "Particular"}
    </Tooltip>
  );
  const renderTooltipSite = ({ item }) => (
    <Tooltip>
      <span>
        {item?.site?.name}
      </span>
    </Tooltip>
  );
  const renderTooltipStatusIcon = ({ item }) => (
    <Tooltip>{item.status.staDescription}</Tooltip>
  );
  const renderTooltipDoctor = ({ item }) => (
    <Tooltip><span>CC {item.doctor.document} <br></br>RM {item.doctor.medicalRecord}</span></Tooltip>
  );
  const downloadPdf = (id, ortTag, patientName) => {
    getPdfOrder({
      url: "/medical/pdfMedicationOrder/",
      objFilters: { id: id, orderType: ortTag },
      token: token,
      doAfterSuccess: (res) => {
        if (!!res.success) {
          // downloaFile(res?.results[0].base64)
          
          for (let i of res.results){
           let name  = `Formula de medicamentos - ${patientName} `
          // let base = i?.base64
          // downloadBase64(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${i?.base64?.split("'")[1]}`)
          downloaFileAddName(i?.base64, name);}
        }
      }
    });
  };
  const searchButton = () => {
    setFilters({ ...filters, page: 1 })
    setTrigger(trigger + 1)
  };
  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let condition =
          item?.ortTag === "particular"
            ? poundSign
            : item?.ortTag === "insurance"
              ? pillDarkBlue
              : ordSugess;
        let typeIcon = <img alt="type" src={condition} width="15px" />;
        let conditionStatus =
          item?.status?.staDescription === "Aprobado"
            ? check2
            : item?.status?.staDescription === "No requiere autorización"
              ? Menos
              : item?.status?.staDescription === "Pendiente"
                ? alertIcon
                : redCloseX;
        let statusIcon = (
          <img
            alt="type"
            src={conditionStatus}
            width={conditionStatus === redCloseX ? "30px" : "18px"}
          />
        );
        let statusV = item?.status?.staDescription;
        let createdDate = item?.createdAtMedicalOrders?.split("|");
        let date = createdDate[0];
        let time = createdDate[1];
        let patientRecibe = item?.patientReceived === 0 ? "NO" : item?.patientReceived === 1 ? "SI" : "N/A"

        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className="text-start px-2">{item?.patient?.document}</td>
            <td className="text-start px-2">{item?.patient?.name}</td>
            <td className={` ${tableStyles.ordHoverBlueText} text-start px-2`}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipDoctor({ item })}
              >
                <span>{item?.doctor?.name}</span>
              </OverlayTrigger>
            </td>
            <td className="text-Center ">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipSite({ item })}
              >
                <span>{item?.site?.prefix}</span>
              </OverlayTrigger>
            </td>
            <td className="text-center ">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipTypeIcon({ item })}
              >
                <span>{typeIcon}</span>
              </OverlayTrigger>
            </td>
            <td className="text-center">{date} | <b>{time}</b></td>
            {/* <td className="text-center">{item?.mipres}</td> */}
            <td className="text-center">{patientRecibe}</td>
            <td className="text-center">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipStatusIcon({ item })}
              >
                <span>{statusIcon}</span>
              </OverlayTrigger>
            </td>
            <td>
              <CustomPopupExtend
                triggerSrc={threeDots}
                extraButtons={
                  statusV === "No requiere autorización" ||
                    statusV === "Pendiente"
                    ? [
                      {
                        type: 1,
                        text: "Detalle",
                        icon: searchBlueIcon,
                        event: () => {
                          setFullDialogScreen({
                            show: true,
                            id: item.id,
                            ordTag: item.ortTag
                          })
                        }
                      },
                      {
                        type: 1,
                        text: "Clonar orden",
                        icon: CloneIconClearBlue,
                        event: () => {
                          history.push({
                            pathname: '/ordenamientos/ordenes',
                            state: { isCloning: true, cloneData: item }
                          })
                        },
                      },
                      {
                        type: 1,
                        text: "Descargar PDF",
                        icon: pdfIcon,
                        event: () => {
                          downloadPdf(item.id, item.ortTag, item?.patient?.name)
                        },
                      },
                      // {
                      //   type: 1,
                      //   text: "Generar MIPRES",
                      //   icon: generate,
                      //   event: () => {
                      //     alert("MIPRES")
                      //   },
                      // },
                    ]
                    : statusV === "Rechazado"
                      ? [
                        {
                          type: 1,
                          text: "Clonar orden",
                          icon: CloneIconClearBlue,
                          event: () => {
                            history.push({
                              pathname: '/ordenamientos/ordenes',
                              state: { isCloning: true, cloneData: item }
                            })
                          },
                        },
                        {
                          type: 1,
                          text: "Justificación",
                          icon: justificationIcon,
                          event: () => {
                            let string = ""
                            for (let i of item.medicine)
                              string = string + `\n• Medicamento ${(i?.name)}, fue rechazado por: \n${i?.auth?.authComment} \n \n`
                            setJustification({
                              ...justification,
                              showModal: true,
                              justificationText: string,
                            });
                          },
                        },
                      ]
                      : statusV === "Aprobado"
                        ? [
                          {
                            type: 1,
                            text: "Detalle",
                            icon: searchBlueIcon,
                            event: () => {
                              setFullDialogScreen({
                                show: true,
                                id: item.id,
                                ordTag: item.ortTag
                              })
                            }
                          },
                          {
                            type: 1,
                            text: "Clonar orden",
                            icon: CloneIconClearBlue,
                            event: () => { history.push({ pathname: '/ordenamientos/ordenes', state: { isCloning: true, cloneData: item } }) },
                          },
                          {
                            type: 1,
                            text: "Descargar PDF",
                            icon: pdfIcon,
                            event: () => {
                              downloadPdf(item.id, item.ortTag,  item?.patient?.name)
                            },
                          },
                          // {
                          //   type: 1,
                          //   text: "Generar MIPRES",
                          //   icon: generate,
                          //   event: () => {
                          //     alert("MIPRES")
                          //   },
                          // },
                        ]
                        : statusV === "Rechazado"
                          ? [
                            {
                              type: 1,
                              text: "Clonar orden",
                              icon: CloneIconClearBlue,
                              event: () => {
                                history.push({
                                  pathname: '/ordenamientos/ordenes',
                                  state: { isCloning: true, cloneData: item }
                                })
                              },
                            },
                            {
                              type: 1,
                              text: "Justificación",
                              icon: justificationIcon,
                              event: () => {
                                let string = ""
                                for (let i of item.medicine)
                                  string = string + `\n• Medicamento ${(i?.name)}, fue rechazado por: \n${i?.auth?.authComment} \n \n`
                                setJustification({
                                  ...justification,
                                  showModal: true,
                                  justificationText: string,
                                });
                              },
                            },
                          ]
                          : statusV === "Aprobado"
                            ? [
                              {
                                type: 1,
                                text: "Detalle",
                                icon: searchBlueIcon,
                                event: () => {
                                  setFullDialogScreen({
                                    show: true,
                                    id: item.id,
                                    ordTag: item.ortTag
                                  })
                                }
                              },
                              {
                                type: 1,
                                text: "Clonar orden",
                                icon: CloneIconClearBlue,
                                event: () => { history.push({ pathname: '/ordenamientos/ordenes', state: { isCloning: true, cloneData: item } }) },
                              },
                              {
                                type: 1,
                                text: "Descargar PDF",
                                icon: pdfIcon,
                                event: () => {
                                  downloadPdf(item.id, item.ortTag,  item?.patient?.name)
                                },
                              },

                            ]
                            : []
                }
              ></CustomPopupExtend>
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };

  // --- GET MEDICINE HISTORY-----
  useEffect(() => {
    getMedicHistory({
      url: "/medical/medicationOrder/",
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, filters.documentPatient]);
  // --- MULTI GET FILTERS VALUE-----

  useEffect(() => {
    getUnities({
      multipleGet: [
        {
          url: "/medical/generals/doctors/",
          requestName: "doctors",
        },
        {
          url: "/site/",
          objFilters: { id_account: idEnterprise },
          requestName: "sites",
        },
        {
          url: "/medical/generals/company/",
          requestName: "company",
        },
        {
          url: "/medical/medicine/getContract/",
          objFilters: { slim: 1 },
          requestName: "contracts",
        },
        {
          url: "/medical/status/medication_order/",
          requestName: "status",
        },
      ],
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilters = (e) => {
    e.preventDefault()
    setFilters({ ...filters, page: 1 })
    setTrigger(trigger + 1)   
  }

  return (
    <div className="pt-3 col-xs-2 col-md-10 col-lg-10 mx-auto">
      {medicHistoryLoader && loader}
      {unitiesLoader && loader}
      {medicOrderLoader && loader}
      <FullScreenDialog
        onHide={() => setFullDialogScreen(false)}
        isOpen={fullDialogScreen.show}
      >
        <RenderMedicalFormulasPdf
          pdfParams={{
            url: "/medical/pdfMedicationOrder/", //ESPERAR URL
            id: fullDialogScreen.id,
            ordTag: fullDialogScreen.ordTag,
          }}
          title={"Fórmula de medicamentos"}
          // miPres={{
          //   mipresAction: () => {
          //     message("success", "Mipres generado");
          //     setFullDialogScreen(false);
          //   },
          // }}
          backAction={() => {
            setFullDialogScreen(false);
          }}
          withPagination={true}
        />
      </FullScreenDialog>
      <OrdModal
        title={"Justificación"}
        show={justification.showModal}
        btnYesName="Aceptar"
        hideCancelButton
        size="500"
        btnYesEvent={() => {
          setJustification({ ...justification, showModal: false });
        }}
        onHide={() => {
          setJustification({ ...justification, showModal: false });
        }}
      >
        <Col xs={12}>
          <Form.Group className="mb-3" controlId="fName">
            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
              <b className="text-start">
                &nbsp;Razón por la que su solicitud es rechazada
              </b>
            </Form.Label>
            <textarea
              disabled
              value={justification.justificationText}
              rows="25"
              cols="55"
              style={{ height: "7rem" }}
              className={`text-secondary ord-roundInput w-100 px-4`}
            ></textarea>
          </Form.Group>
        </Col>
      </OrdModal>
      <OrdGenericTemplate
        title={"Historial de ordenes de medicamentos"}
        titleSize={12}
        showBackArrow={true}
        backArrowAction={() => history.push("/ordenamientos/Consultas")}
        titleClass={"px-2"}
      >
        {/* ---------------------------FORM-------------------------- */}
        <OrdSlideDownButton
          onToggleClick={() => setRotate(!rotate)}
          buttons={buttons}
          accordionClassName={`${ordComponentStyles.OrdSlideButton} mr-1`}
        >
          <>
            <Row>
              <Col xs={3} className="">
                <Form.Group className="" controlId="fName">
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText} `}
                  >
                    <b className="text-start">&nbsp;Fecha desde</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                    type="date"
                    placeholder=""
                    value={filters.startDate}
                    onChange={(e) => {
                      setFilters({ ...filters, startDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className="">
                <Form.Group className="" controlId="fName">
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText} `}
                  >
                    <b className="text-start">&nbsp;Fecha hasta</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                    type="date"
                    placeholder=""
                    value={filters.endDate}
                    onChange={(e) => {
                      setFilters({ ...filters, endDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className="">
                <Form.Group className="" controlId="fName">
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText} `}
                  >
                    <b className="text-start">&nbsp;Identificación del paciente</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordGrayInput}`}
                    type="number"
                    style={{ fontSize: '13.5px' }}
                    placeholder="Escribe..."
                    autoComplete="off"
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        searchButton()
                      }
                    }}
                    value={filters.documentPatient}
                    onChange={(e) => {
                      setFilters({ ...filters, documentPatient: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={3} className="">
                <form onSubmit={(e) => handleFilters(e)}>
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Médico</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={doctorOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e, doctorValue) => {
                        setFilters({ ...filters, doctor: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </form>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs={3} className="">
                <form onSubmit={(e) => handleFilters(e)}>
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Sede</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={sitesOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e, sitesValue) => {
                        setFilters({ ...filters, site: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </form>
              </Col>
              <Col xs={3} className="">
                <form onSubmit={(e) => handleFilters(e)}>
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Empresa</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={companiesOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e, companiesValue) => {
                        setFilters({ ...filters, company: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </form>
              </Col>
              
              <Col xs={3} className="">
                <form onSubmit={(e) => handleFilters(e)}>
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Contrato</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={contractsOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e, contractsValue) => {
                        setFilters({ ...filters, contract: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </form>
              </Col>
              <Col xs={2} className="">
                <form onSubmit={(e) => handleFilters(e)}> 
                  <Form.Group className="" controlId="fName">
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} `}
                    >
                      <b className="text-start">&nbsp;Estado</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={statusOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e, statusValue) => {
                        setFilters({ ...filters, status: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </form>
              </Col>
              <Col xs={1} className="" style={{ alignSelf: "center" }}>
                <button
                  onClick={() => {
                    searchButton();
                  }}
                >
                  <img
                    src={Lupa2}
                    className="hoverPointer mt-4"
                    alt="lupa"
                    style={{ width: "25px", height: "25px" }}
                  />
                </button>
              </Col>
            </Row>
          </>
        </OrdSlideDownButton>
        <OrdTable
          headers={headers}
          hasChildren={true}
          shadow={true}
          paginate={{
            activePage: filters?.page,
            totalPages: medicHistory?.rowTotal,
            perPage: filters?.perpage,
            pageRangeDisplayed: 3,
            onChangePage: async (e) => {
              const result = await getMedicHistory({
                url: "/medical/medicationOrder/",
                objFilters: { ...filters, page: e },
                token: token,
              });
              setFilters({ ...filters, page: e });
              formatData(result?.results);
            },
            showTextDetails: true,
          }}
        >
          {formatData(medicHistory.results)}
        </OrdTable>
      </OrdGenericTemplate>
    </div>
  );
};

