import React, { useEffect, useState } from "react";

import Agregar from "../../assets/img/icons/agregarEspecialidad.svg";
import Pagination from "react-js-pagination";
import Search from "../../assets/img/icons/lupa.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import GenericTableNew from "../Layouts/GenericTableNew";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import valeStyles from "../TableAccAccounts/AccAccounts_CSS.module.scss";
import Styles from "../../components/PayRollModule/Hiring/Contract.module.scss";
import { customSelectNew } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../Layouts/pagination.module.scss";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import { convertDateToLatinFormat, message } from "../../helpers/helpers";
import Loader from "react-loader-spinner";
import { updatePayrrolParam } from "../../actions/accountingActions";
import { getProvision } from "../../actions/receiptOfInvoicesActions";
export const ReceivableAges = (props) => {
    const store = useSelector((state) => state);
    const myPermission =
        store.loginReducer.currentAccount?.profile?.permission?.find(
            (x) => x.functionality?.prefix === "reFac"
        );
    const idAccount = store.loginReducer.user_data.id;
    const idEnterprise = store.loginReducer.currentAccount.id;
    const token = store.loginReducer.Authorization;
    const recAgesWallet = store.invoiceReducer?.provision;
    // //console.log("🚀 ~ file: ReceivableAges.jsx ~ line 38 ~ ReceivableAges ~ recAgesWallet", recAgesWallet)
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [trigger, setTrigger] = useState(0);
    const [filters, setFilters] = useState({
        page: 1,
        perpage: 10,
        eaccount: idEnterprise,
        description: "",
        active: "",
        // eaccount: 31,
        search: "",
        status: "",
    });

    useEffect(() => {
        if (!!!myPermission?.read) {
            history.push("/administracion/inicio");
        }
        //console.log(filters)
        dispatch(getProvision(filters));
    }, [filters.page, trigger]);

    const renderElement = (elem) => {
        return (
            <tr key={elem.id}>
                <td className="col-md-2 text-start" style={{ paddingLeft: "0.5rem" }}>
                    {elem.name}
                </td>

                <td className="col-md-2 text-center">{convertDateToLatinFormat(elem.start_days)}</td>
                <td className="col-md-2 text-center">{convertDateToLatinFormat(elem.end_days)}</td>
                <td className="col-md-2 text-center" style={{ paddingLeft: "0.5rem" }}>
                    {elem.deteriorationValue}%
                </td>
                <td className="col-md-1 text-center cursorPointer">
                    <div>
                        <img src={Filtrar} alt="lupa" onClick={()=>{
                            history.push({
                                pathname:
                                    "/administracion/TMDeCartera/EdadesPorCobrar/nueva",
                                state: {
                                    paramInfo: elem,
                                    isEditing: true,
                                },
                            })
                        }} />

                        {/* <CustomPopupExtend
                            triggerSrc = {threeDots}
                            showEdit={true}
                            editClickEvent={() =>
                                
                            }
                            isEnabled={elem.active === 1}
                            // showEnable={myPermission?.delete && true}
                            enableClickEvent={() =>
                                Swal.fire({
                                    icon: "warning",
                                    title:
                                        elem.active === 1
                                            ? "Deshabilitar parámetro"
                                            : "Habilitar parámetro",
                                    text:
                                        elem.active === 1
                                            ? "¿Desea deshabilitar la parámetro ?"
                                            : "¿Desea habilitar la parámetro ?",
                                    confirmButtonColor: "#3085d6",

                                    confirmButtonText:
                                        elem.active === 1
                                            ? "Si, deshabilitar"
                                            : "Si, habilitar",
                                    cancelButtonColor: "#d33",
                                    cancelButtonText: "Cancelar",
                                    showCancelButton: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        const send = async () => {
                                            setIsLoading(true);
                                            try {
                                                let result = await updatePayrrolParam(
                                                    {
                                                        eaccount: idEnterprise,
                                                        createdBy: idAccount,
                                                        // eaccount: 31,
                                                        active: elem.active === 1 ? 0 : 1,
                                                        id: elem.id,
                                                    },
                                                    token
                                                );
                                                if (result.success) {
                                                    setIsLoading(false);
                                                    setTrigger(trigger + 1);
                                                    message(
                                                        "success",
                                                        "Parámetro actualizado",
                                                        result.message
                                                    );
                                                } else {
                                                    setIsLoading(false);
                                                    return message(
                                                        "error",
                                                        "Ha ocurrido un error",
                                                        result.message
                                                    );
                                                }
                                            } catch (error) {
                                                setIsLoading(false);
                                                message("error", "Error", error.message);
                                            }
                                        };
                                        send();
                                    }
                                })
                            }
                        /> */}
                    </div>
                </td>
            </tr>
        );
    };

    const listElem = () => {
        let elemMap;
        if (recAgesWallet !== undefined) {
            const elem2 = recAgesWallet;
            if (elem2.length > 0) {
                elemMap = elem2.map((elem) => {
                    return renderElement(elem);
                });
            }
        }
        return elemMap;
    };

    const renderHeaders = [
        <th key={1} className="text-start">
            &nbsp; Nombre
        </th>,
        <th key={2} className="text-center">
            &nbsp; Día de inicio
        </th>,
        <th key={3} className="text-center">
            Día de fin
        </th>,
        <th key={4} className="text-center">
            Porcentaje de deterioro
        </th>,
        <th key={8}>&nbsp;</th>,
    ];

    const handleSearch = (e) => {
        e.preventDefault();
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
    };

    return (
        <>
            {isLoading && (
                <div className="loading">
                    <Loader type="Oval" color="#003f80" height={100} width={100} />
                </div>
            )}

            {store.invoiceReducer?.loadingProvision && (
                <div className="loading">
                    <Loader type="Oval" color="#003f80" height={100} width={100} />
                </div>
            )}

            <div className={tableStyles.container}>
                <div className={tableStyles.tableArea}>
                    <h1 className={tableStyles.title}>Edades de cartera por cobrar</h1>

                    <Row>
                        {/* <Col className="d-block" xs={4}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    onChange={(e) => setFilters({ ...filters, active: e.value })}
                                    options={[
                                        { value: "", label: "-Seleccione-" },
                                        { value: 1, label: "Habilitado" },
                                        { value: 0, label: "Deshabilitado" },
                                    ]}
                                    className="text-secondary"
                                    placeholder={"Seleccione"}
                                    styles={customSelectNew}
                                ></Select>
                            </form>
                        </Col> */}
                        {/* <Col className="d-block" xs={3}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <p className={`${tableStyles.crudModalLabel}`}>Tipo</p>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    onChange={(e) => setFilters({ ...filters, search: e.value })}
                                    options={[
                                        { value: "", label: "-Seleccione-" },
                                        { value: "percentage", label: "Porcentaje" },
                                        { value: "value", label: "Valor" },
                                    ]}
                                    className="text-secondary"
                                    placeholder={"Seleccione"}
                                    styles={customSelectNew}
                                ></Select>{" "}
                            </form>
                        </Col> */}

                        <Col className="d-block" xs={6}>
                            <div className="pt-4">
                                <form onSubmit={(e) => handleSearch(e)}>
                                    <input
                                        className={tableStyles.searchUsersAlt}
                                        onChange={(e) =>
                                            setFilters({ ...filters, search: e.target.value })
                                        }
                                        name="search"
                                        type="text"
                                        placeholder="Buscar..."
                                    />
                                    <img
                                        src={Search}
                                        className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                                        alt="Search icon"
                                        onClick={(e) => handleSearch(e)}
                                    />
                                </form>
                            </div>
                        </Col>
                        <Col xs={6}
                            onClick={(e) =>
                                history.push(
                                    "/administracion/TMDeCartera/EdadesPorCobrar/nueva"
                                )
                            }
                            className={`text-end `}
                            style={{
                                "align-self": "end",
                            }}
                        >
                            <span
                                className={`text-decoration-none hoverPointer
                                ${valeStyles.buttonCreatCount}  ${Styles.hoverBtnAdd}`}
                                style={{
                                    width: "max-content",
                                }}
                            >
                                <label
                                    style={{
                                        "align-self": "center",
                                        paddingRight: "8px",
                                        fontSize: "16px"
                                    }}
                                    className={`${Styles.labelNew} hoverPointer`}
                                >
                                    Nuevo parámetro
                                </label>
                                <img
                                    src={Agregar}
                                    alt="User"
                                    className={``}
                                    style={{
                                        "align-self": "center",
                                    }}
                                />
                            </span>
                        </Col>
                    </Row>

                    <GenericTableNew headers={renderHeaders} dark={true}>
                        {listElem()}
                    </GenericTableNew>

                    <div className={paginationStyles.wrapper}>
                        <p className={`${paginationStyles.paginationText} text-secondary`}>
                            Pag.{" "}
                            {store.invoiceReducer?.recAgesWallet ? filters.page : ""}
                            {" de "}
                            {Math.ceil(store.invoiceReducer.rowTotal / filters.perpage)
                                ? Math.ceil(store.invoiceReducer.rowTotal / filters.perpage)
                                : ""}{" "}
                            ({store.invoiceReducer.rowTotal} encontrados)
                        </p>
                        <Pagination
                            activePage={filters.page}
                            itemsCountPerPage={filters.perpage}
                            totalItemsCount={store.invoiceReducer.rowTotal}
                            pageRangeDisplayed={5}
                            onChange={(e) => setFilters({ ...filters, page: e })}
                            itemClassPrev={paginationStyles.itemClassPrev}
                            itemClassNext={paginationStyles.itemClassNext}
                            itemClassFirst={paginationStyles.itemClassFirst}
                            itemClassLast={paginationStyles.itemClassLast}
                            itemClass={paginationStyles.itemClass}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
