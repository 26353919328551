import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { OrdGenericTemplate } from "../OrderingComponents/OrdGenericTemplate";
import OrdTable from "../OrderingComponents/OrdTable";
import Lupa2 from "../../assets/img/icons/NewLupa.svg";
import Lupa from "../../assets/img/icons/OrdLupa.svg";
import { Col, Form, Row } from "react-bootstrap";
import ordComponentStyles from "../OrderingComponents/ordComponentStyles.module.scss";
import Select from "react-select";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import threeDots from "../../assets/img/icons/threeDots.svg";
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import { OrdSlideDownButton } from "../OrderingComponents/OrdSlideDownButton";
import { useSelector } from "react-redux";
import { useGetMethod } from "../../Hooks/useFetch";
import { loader, downloaFile, isEmptyOrUndefined, formatToRcSelect } from "../../helpers/helpers";
import flecha from "../../assets/img/icons/desplegarGris.svg";
import pdfIcon from "../../assets/img/icons/pdf.svg";
import FullScreenDialog from "../OrderingComponents/FullScreenDialog";
import makeAnimated from "react-select/animated";
import { RenderMedicalFormulasPdf } from "../../AsistanceModule/RenderMedicalFormulasPdf";
import alertIcon from "../../assets/img/icons/alert-circle-orange.svg";
import Menos from "../../assets/img/icons/Menos.svg";
import check2 from "../../assets/img/icons/check2.svg";
import redCloseX from "../../assets/img/icons/redcloseX.svg";
import justificationIcon from "../../assets/img/icons/justification.svg";
import OrdModal from "../OrderingComponents/OrdModal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export const DisabilityHistory = (props) => {
  let history = useHistory();
  const [rotate, setRotate] = useState(false);
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idCurrentAccount = store.loginReducer.currentAccount.id;
  const [trigger, setTrigger] = useState(0);
  const animatedComponents = makeAnimated();
  const [foundedDiagnostics, setFoundedDiagnostics] = useState([
    {
      value: "",
      label: "Escriba el código del diagnóstico...",
      isDisabled: true,
    },
    // { value: "", label: "Seleccione" },
  ]);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    idAccount: idCurrentAccount,
  });
  const [justification, setJustification] = useState({
    showModal: false,
    justificationText: "",
  });
  const [fullDialogScreen, setFullDialogScreen] = useState({
    show: false,
    id: "",
  });
  const { results: disabilityHistory, load: disabilityLoader, trigger: getDisabilityHistory } = useGetMethod();
  const { results: diagnostics, load: diagnosticsLoader, trigger: getDiagnostics } = useGetMethod();
  const {  load: diabilityOrderLoader, trigger: getPdfOrder } = useGetMethod();
  const renderTooltipStatusIcon = ({ item }) => (
    <Tooltip>{item.status.staDescription}</Tooltip>
  );

  const headers = [
    {
      title: "Identificación",
      className: "px-2 text-start align-middle",
    },
    {
      title: "Paciente",
      className: "px-2 text-start align-middle",
    },
    {
      title: "Diagnóstico CIE10",
      className: "px-2 text-start align-middle",
    },
    {
      title: "Fecha de inicio",
      className: "px-2 text-center align-middle",
    },
    {
      title: "Fecha de fin",
      className: "px-2 text-center align-middle",
    },
    {
      title: "Días de incapacidad",
      className: "px-2 text-center",
    },
    {
      title: "Incapacidades generadas",
      className: "px-2 text-center",
    },
    {
      title: "Días acumulados",
      className: "px-2 text-center",
    },
    {
      title: "Paciente recibe",
      className: "px-2 text-center",
    },
    {
      title: "Estado",
      className: "px-1 text-center",
    },
    {
      title: <>ㅤ</>,
    },
  ];
  let buttons = [
    {
      button: <img alt="btn" src={Lupa}></img>,
    },
    {
      button: (
        <img
          alt="btn"
          style={{
            transform: !rotate && "rotate(180deg)",
          }}
          width={10}
          src={flecha}
        ></img>
      ),
    },
  ];

  const searchButton = () => {
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };
  const downloadPdf = (id) => {
    getPdfOrder({
      url: "/medical/pdfMedicalinhabilities/",
      objFilters: { id: id },
      token: token,
      doAfterSuccess: (res) => {
        if (!!res.success) {
          downloaFile(res?.results[0].base64);
        }
      },
    });
  };

  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let accumulatedDays = item?.medicalInhabilities?.accumulatedDays;
        let classF =
          accumulatedDays >= 120 && accumulatedDays <= 149
            ? tableStyles.ordBgFileTableYellow
            : accumulatedDays >= 150 && tableStyles.ordBgFileTableRed;
            // let patientReceived = item?.patientReceived === 0 ? "N/A" : "N/A"

            let conditionStatus =item?.status?.staDescription === "Aprobado"
              ? check2
              : item?.status?.staDescription === "No requiere"
                ? Menos
                : item?.status?.staDescription === "Pendiente"
                  ? alertIcon
                  : redCloseX;
          let statusIcon = (
            <img
              alt="type"
              src={conditionStatus}
              width={conditionStatus === redCloseX ? "30px" : "18px"}
            />
          );
          let patientRecibe = item?.patientReceived === 0 ? "NO" : item?.patientReceived === 1 ? "SI" : "N/A"
        tempList.push(
          <tr key={index} className={` hover-table-row `}>
            {/* <td className={`${classF}`}>{item?.patient?.document}</td> */}
            <td className={`text-start px-2 ${classF}`}>{item?.patient?.document}</td>
            <td className={`text-start px-2 col-3 ${classF}`}>{item?.patient?.name}</td>
            <td className={`text-start px-2 col-3 ${classF}`}>{item?.nameDiagnostics}</td>
            <td className={`text-center px-2 col-3 ${classF}`}>{item?.medicalInhabilities?.startDate}</td>
            <td className={`text-center px-2 col-2 ${classF}`}>{item?.medicalInhabilities?.endDate}</td>
            <td className={`text-center ${classF}`}>{item?.medicalInhabilities?.days}</td>
            <td className={`text-center ${classF}`}>{item?.medicalInhabilities?.inhabilitiesGenerated}</td>
            <td className={`text-center ${classF}`}>{item?.medicalInhabilities?.accumulatedDays}</td>
            <td className={`text-center ${classF}`}>{patientRecibe} </td>
            <td className={`text-center ${classF}`}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipStatusIcon({ item })}
              >
                <span>{statusIcon}</span>
              </OverlayTrigger>
            </td>

            <td className={`${classF}`}>
              <CustomPopupExtend
                triggerSrc={threeDots}
                showDetails={item?.status?.staValue === "notRequired" || item?.status?.staValue === "approved"}
                textDetails="Detalle"
                showDetailsEvent={() =>
                  setFullDialogScreen({
                    show: true,
                    id: item.id,
                  })
                }
                extraButtons={ 
                  item?.status?.staValue === "notRequired" || item?.status?.staValue === "approved" ? [
                  {
                    text: "Descargar PDF",
                    icon: pdfIcon,
                    class: "",
                    event: () => {
                      downloadPdf(item.id);
                    },
                  },
                 ] : [
                  {
                    type: 1,
                    text: "Justificación",
                    icon: justificationIcon,
                    event: () => {
                      setJustification({
                        ...justification,
                        showModal: true,
                        justificationText: item?.authComment,
                      });
                    },
                  },
                ] 
              }
              ></CustomPopupExtend>
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };

  const handleFilter = (e) => {
    let nombreEncontrados;
    if (isEmptyOrUndefined(e)) {
      setFoundedDiagnostics([
        // {
        //   value: "",
        //   label: "Digite el número del diagnóstico",
        //   isDisabled: true,
        // },
        // { value: null, label: "seleccione" },
        {
          value: "",
          label: "Escriba el código del diagnóstico...",
          isDisabled: true,
        },
      ]);
    }
    if (e?.length > 0) {
      let expresion = new RegExp(`${e?.toUpperCase()}.*`, "i");
      if (e.length > 0) {
        nombreEncontrados = diagnostics.results?.filter((dat) => expresion.test(dat.codigo));
        setFoundedDiagnostics(formatToRcSelect(nombreEncontrados, "codigo", "codigo", "", "", "", true));
      } else {
        setFoundedDiagnostics([
          // {
          //   value: "",
          //   label: "Digite el número del diagnóstico",
          //   isDisabled: true,
          // },
          // { value: null, label: "seleccione" },
          {
            value: "",
            label: "Escriba el código del diagnóstico...",
            isDisabled: true,
          },
        ]);
      }
    }
  };

  // --- GET DISABILITY HISTORY-----
  useEffect(() => {
    getDisabilityHistory({
      url: "/medical/medicalinhabilities/",
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // --- GET DIAGNOSTICS -----
  useEffect(() => {
    getDiagnostics({
      url: "/medical/cie10diagnostics",
      objFilters: { slim: 1, enabled: 1 },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <div className="d-flex w-100 mx-auto container pt-3">
      {disabilityLoader && loader}
      {diabilityOrderLoader && loader}
      {diagnosticsLoader && loader}
      <FullScreenDialog onHide={() => setFullDialogScreen(false)} isOpen={fullDialogScreen.show}>
        <RenderMedicalFormulasPdf
          pdfParams={{
            url: "/medical/pdfMedicalinhabilities/", //ESPERAR URL
            id: fullDialogScreen.id,
          }}
          title={"Incapacidad"}
          withPagination={true}
          backAction={() => {
            setFullDialogScreen(false);
          }}
        />
      </FullScreenDialog>
      <OrdModal
        title={"Justificación"}
        show={justification.showModal}
        btnYesName="Aceptar"
        hideCancelButton
        size="500"
        btnYesEvent={() => {
          setJustification({ ...justification, showModal: false });
        }}
        onHide={() => {
          setJustification({ ...justification, showModal: false });
        }}
      >
        <Col xs={12}>
          <Form.Group className="mb-3" controlId="fName">
            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
              <b className="text-start">
                &nbsp;Razón por la que su solicitud es rechazada
              </b>
            </Form.Label>
            <textarea
              disabled
              value={justification.justificationText}
              rows="25"
              cols="55"
              style={{ height: "7rem" }}
              className={`text-secondary ord-roundInput w-100 px-4`}
            ></textarea>
          </Form.Group>
        </Col>
      </OrdModal>.
      <OrdGenericTemplate
        title={"Historial de incapacidades "}
        titleSize={9}
        showBackArrow={true}
        backArrowAction={() => history.push("/ordenamientos/Consultas")}
      >
        <OrdSlideDownButton
          onToggleClick={() => setRotate(!rotate)}
          buttons={buttons}
          accordionClassName={`${ordComponentStyles.OrdSlideButton} mr-1`}
        >
          <>
            <Row>
              <Col xs={3} className="">
                <Form.Group className="" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Fecha desde</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                    type="date"
                    placeholder=""
                    onChange={(e) => {
                      setFilters({ ...filters, startDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className="">
                <Form.Group className="" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Fecha hasta</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                    type="date"
                    placeholder=""
                    onChange={(e) => {
                      setFilters({ ...filters, endDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className="">
                <Form.Group className="" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Identificación del paciente</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordGrayInput}`}
                    type="number"
                    style={{fontSize: '13.5px'}}
                    placeholder="Escribe..."
                    autoComplete="off"
                    onKeyDown={e=>{
                      if (e.key === 'Enter') {
                        searchButton()
                    }  
                    }}
                    onChange={(e) => {
                      setFilters({ ...filters, documentPatient: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className="">
                <Form.Group className="" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Nombre del paciente</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordGrayInput}`}
                    type="text"
                    style={{fontSize: '13.5px'}}
                    placeholder="Escribe..."
                    autoComplete="off"
                    onKeyDown={e=>{
                      if (e.key === 'Enter') {
                        searchButton()
                    }  
                    }}
                    onChange={(e) => {
                      setFilters({ ...filters, namePatient: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={3} className="pt-2">
                <Form.Group className="mb-3" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Diagnóstico CIE10</b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onInputChange={(e) => {
                      handleFilter(e);
                    }}
                    onChange={(e) => {
                      setFilters({ ...filters, idCIE: e.value });
                    }}
                    options={foundedDiagnostics}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={ordCustomSelect}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={1} className="" style={{ alignSelf: "center" }}>
                <button
                  onClick={() => {
                    searchButton();
                  }}
                >
                  <img src={Lupa2} className="hoverPointer mt-4" alt="lupa" style={{ width: "25px", height: "25px" }} />
                </button>
              </Col>
            </Row>
          </>
        </OrdSlideDownButton>
        <OrdTable
          headers={headers}
          hasChildren={true}
          shadow={true}
          paginate={{
            activePage: filters?.page,
            totalPages: disabilityHistory?.rowTotal,
            perPage: filters?.perpage,
            pageRangeDisplayed: 3,
            onChangePage: async (e) => {
              const result = await getDisabilityHistory({
                url: "/medical/medicalinhabilities/",
                filters: { ...filters, page: e },
                token: token,
              });
              setFilters({ ...filters, page: e });
              formatData(result?.results);
            },
            showTextDetails: true,
          }}
        >
          {formatData(disabilityHistory.results)}
        </OrdTable>
      </OrdGenericTemplate>
    </div>
  );
};
