

import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GET_ENTRY_WAREHOUSE, GET_LIST_INVOICES, GET_SELECT_PAY_CONDITIONS, GET_SELECT_TYPE_INVOICE, LOADING } from "./actionTypes";
import { customSwaltAlert } from "../helpers";

const MySwal = withReactContent(Swal);

export const getListInvoices = (objFilters, loading) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pinvoices/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      loading()
      const { results, total } = res;
      dispatch({
        type: GET_LIST_INVOICES,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      loading()
      MySwal.fire({
        icon: "error",
        title: "Error al consultar",
        text: "No se pudo traer los registros",
        footer: "Si el problema persiste comuníquese con un asesor.",
      });
      console.error(err.message);
    });
};

export const getSelectTypeInvoice = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pinvoices_types/${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: GET_SELECT_TYPE_INVOICE,
        payload: results
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const getSelectPayConditions = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/treasury/pay_conditions/${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: GET_SELECT_PAY_CONDITIONS,
        payload: results
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const getEntryWarehouse = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/treasury/providers/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { result } = res;
      dispatch({
        type: GET_ENTRY_WAREHOUSE,
        payload: result
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const create_New_Invoice = (data, doAfter, executeThisAfter) => (dispatch, getselectMonth) => {
  const token = getselectMonth().loginReducer.Authorization;
  
  customSwaltAlert({
    icon: 'warning',
    title: `¿Está seguro?`,
    text: `Verifique la información`,
    showCancelButton: true
  }).then((result) => {
    if (result.isConfirmed) {
      dispatch({
        type: LOADING,
        payload: true
      })
      fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pinvoices/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      })
        .then(response => response.json())
        .then(res => {
          dispatch({
            type: LOADING,
            payload: false
          })
          if (res.success) {
            doAfter()

            customSwaltAlert({
              icon: 'success',
              title: `Creada exitosamente`,
              text: ` ${res.invoice} / ${data.pinvoice.provider_name}`,
              showCancelButton: false
            })

          } else {
            customSwaltAlert({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `${res.message}`,
              showCancelButton: false
            })
          }
        }).then(() => {
          if (!!executeThisAfter) {
            executeThisAfter();
          }
        }
        )
        .catch((err) => {
          console.error(err);
          dispatch({
            type: LOADING,
            payload: false
          })
          customSwaltAlert({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `Error al crear`,
              showCancelButton: false
            })
        })
    }
  })
}

export const edit_Invoice = (data, doAfter, executeThisAfter) => (dispatch, getselectMonth) => {
  const token = getselectMonth().loginReducer.Authorization;
  dispatch({
    type: LOADING,
    payload: true
  })
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pinvoices/`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    }
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: LOADING,
        payload: false
      })
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: `Actualizado exitosamente`,
          text: `${res.message}`,
          showCancelButton: false
        }).then(()=>{
          doAfter()
        })
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: `Intenta de nuevo`,
          text: `${res.message}`,
          showCancelButton: false
        })
      }
    }).then(() => {
      if (!!executeThisAfter) {
        executeThisAfter();
      }
    }
    )
    .catch((err) => {
      console.error(err);
      customSwaltAlert({
          icon: 'warning',
          title: `Intenta de nuevo`,
          text: `Error al actualizar`,
          showCancelButton: false
        })
    })
}
