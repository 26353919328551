import GenericTableNew from "../Layouts/GenericTableNew";
import lupaClear from "../../assets/img/icons/lupaClear.svg";
import Trash from "../../assets/img/icons/canecaGris.svg";
import Watch from "../../assets/img/icons/watch.svg";
import Agregar from "../../assets/img/icons/agregarEspecialidad.svg";


import React, { useEffect, useState } from "react";


import {
    creat_indauction,
    indauction_get_articles
} from "../../actions/IndividualAuctionActions";

import { connect, useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import popupStyles from "../Layouts/popupEdEdit.module.css";
import genericTableStyles from "../Layouts/genericTable.module.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import IndividualStyles from "./IndividualAuction_CCS.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import CustomPopup from "../Popup/customPopup";
import {customSelectStyle, customSelectStyleSmall, customSelectNew} from "../Layouts/react-select-custom";
import Select from "react-select";
import { Checkbox } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import ModalCreateRecord from "../Layouts/ModalCreateRecord";
import ModalNew from "../Layouts/ModalNew";




function ModalArticle (props){

    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    
    let listArticles = [];

    const [filters, setFilter] = useState({
        search: "",
        page: 1,
        perpage: 10,
        eaccount:counter.loginReducer.currentAccount.id,
    });
    const [selectedCC, setselectedCC] = useState({}); //props.optionInventoryFamily.find(cc => cc.id_account==counter.loginReducer.currentAccount.id)
    const [articleChanges, setArticleChanges] = useState( [] );
    const [articleNew, setArticleNew] = useState( [] );

    useEffect(()=>{
        setArticleChanges([...props.reqState?.articulos]);

    }, [props.show])

    const handlePageChange = (val) => {
        setFilter({
            ...filters,
            page: val
        });
    }

    const articleCheckHandle = (article) => {
        let articleChangesTemp = articleChanges;
        let exists = articleChangesTemp.find(x => (x.id == article.id));
        if(exists){
            articleChangesTemp = articleChangesTemp.filter(x => x != exists);
        }else{
            articleChangesTemp.push(article);
        }
        setArticleChanges(articleChangesTemp);
    }

    const articleCheckAllStatus = ()=>{
        const articles = filteredArticles();

        let allEqual = articleChanges.length>0;

        articles.map( (elem)=>{
            let exists = articleChanges.find(x => (x.id == elem.id));
            if(!(!!exists)){
                allEqual=false;
            }
        });
        return allEqual;
    };

    const articleNewAdd = (elem)=>{
        let articleNewTemp = articleNew;
        articleNewTemp.push(elem);
        setArticleNew(articleNewTemp);
        setFilter({
            ...filters,
            search:"",
            page:1,
            perpage:10,
        });
    } 

    const articleNewDelete = (elem)=>{
        
        let articleNewTemp = articleNew;

        articleNewTemp = articleNewTemp.filter(x=> !Object.is(x, elem))

        setArticleNew(articleNewTemp);
        return elem;
    }


    const articleCheckAllHandle = () => {
        let articleChangesTemp = articleChanges;

        if( !articleCheckAllStatus() ){
            const articles = filteredArticles();
            articles.map( (elem)=>{
                let exists = articleChangesTemp.find(x => (x.id == elem.id));
                if(!!exists==false){
                    articleChangesTemp.push(elem);
                }
            });
        }else{
            articleChangesTemp = [];
        }
        
        setArticleChanges(articleChangesTemp);
    }

    

    const filteredArticles = () => {
        //el metodo del dispatch trae los centros de consumo, vamos a filtrar solo los Artículos dentro de estos
        let onlyArticles = [];
        let realTotal=0;
        
        let articles = selectedCC.articles
        if(Array.isArray(articles)){
            articles.map(z=>{
                if(
                    z?.description?.toUpperCase().includes( filters.search.trim().toUpperCase() ) 
                    || 
                    z?.id?.toString().toUpperCase().includes(filters.search.trim().toUpperCase() ) 
                    ){
                    onlyArticles.push({...z, ammount: 1});
                    realTotal++;
                }
            })
        }


        onlyArticles=onlyArticles.sort( (a,b) =>(a.id - b.id) )

        return onlyArticles;
    }

    const renderList = () => {
        let table =[], butwaittheresmore=[];
        const articles = filteredArticles();
        if (Array.isArray(articles)) {
            table = articles.map( x => {
                return <tr key={"ReqModalArticleList"+x.id} className="hover-table-row">
                    <td className={`col-md-2 text-start px-2`}>
                        {x.id}
                    </td>
                    <td className={`col-md-8 text-start`}>
                        {x.description}
                    </td>
                    <td className={`col-md-2`}>
                        <input
                            className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
                            type="checkbox"
                            name={`selectedArt${x.article_id}`}
                            onChange={()=>articleCheckHandle(x)}
                            checked={!!articleChanges.find(change=> change.id === x.id)}
                            placeholder="Escribir..."
                        />
                    </td>
                </tr>
            });
        }

        if (Array.isArray(articleNew)) {
            butwaittheresmore = articleNew.map( x => {
                if(
                    x?.description?.toUpperCase().includes( filters.search.trim().toUpperCase() ) || 
                    x?.id?.toString().toUpperCase().includes( filters.search.trim().toUpperCase() ) 
                    ){
                    return <tr key={"newItems"+x.id+" "+Math.random()} className={`hover-table-row ${genericTableStyles.orange}`}>
                        <td className={`col-md-2 text-start px-2`}>
                            {x.id}
                        </td>
                        <td className={`col-md-8`}>
                            {x.description}
                        </td>
                        <td className={`col-md-1`}>
                            <img
                                src={Watch}
                                alt="Reloj"
                                className={`icons-popUp ${IndividualStyles.adjustIcon} ${IndividualStyles.orangeIcon}`}
                            />
                        </td>
                        <td className={`col-md-1`}>
                            <img
                                src={Trash}
                                alt="Eliminar"
                                className={`icons-popUp ${IndividualStyles.adjustIcon} ${IndividualStyles.orangeIcon}`}
                                onClick={() => articleNewDelete(x)}
                            />
                        </td>
                    </tr>
                }
            });

            if(butwaittheresmore.length>0){
                table = table.concat(butwaittheresmore);
            }
        }

        return table;
    }

    const paginateList = () => {
        let fullList = renderList().length > 0 ? renderList() : [];

        fullList =  fullList.slice(filters.perpage*(filters.page-1),  filters.perpage*(filters.page));
        if (fullList.length === 0){
            fullList = <tr className=""
            style={{position: "relative"}}
            >
                <td width="100%"
                style={{position: "absolute"}}
                >
                Debe seleccionar una familia de inventario
                </td>
                </tr>
        }
        if(filteredArticles().length<1 && filters.search.trim()!=""){
                let newRow = <tr key="infinityyyyyyyyy" className="hover-table-row">
                <td className={`col-md-2`}>
                    <div className={`${IndividualStyles.newArticle} px-2`}>NUEVO</div>
                </td>
                <td className={`col-md-8`}>
                    <div className={`${IndividualStyles.newArticle}`}>{filters.search.trim()}</div>
                </td>
                <td className={`col-md-2 text-center`}>
                    <img 
                        src={Agregar} 
                        alt="Agregar" 
                        className={`${IndividualStyles.adjustIcon}  ${IndividualStyles.orangeIcon}`} 
                        onClick={()=>articleNewAdd({
                            id: articleNew.length>0? "NUEVO "+articleNew.length:"NUEVO",
                            description:filters.search.trim().toUpperCase(),
                            newArticle: true,
                            ammount: 1,
                        })}
                    />
                </td>
            </tr>;
            fullList.length > 0 &&
            fullList.push(newRow);
        }
        return fullList;
    }

    const allCheckButton = (
        <input
            className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
            type="checkbox"
            name={`selectedArtAll`}
            onChange={()=>articleCheckAllHandle()}
            checked={ articleCheckAllStatus() }
            placeholder="Escribir..."
        />
    )

    const renderHeaders = [
        <th key={`ReqModalArticle1`} className="px-2"> Código</th>,
        <th key={`ReqModalArticle2`}>Descripción</th>,
        <th key={`ReqModalArticle99`} className="px-2 text-center">{allCheckButton}</th>,
    ]

    const articlesForParentForm = (parentArticles, myArticles, parentCC, myCC ) => {
        let articlesready = [];
        if(parentCC===myCC){
            //let myArticlesReady = myArticles.filter(item => !parentArticles.includes(item) )
            let myArticlesReady = myArticles.filter(item => !parentArticles.find(parentItem=> item.id==parentItem.id) )
            articlesready = [...parentArticles, ...myArticlesReady]
            return articlesready;
        }else{
            return [...myArticles];
        }
    }

    
    const savearticles =()=>{
        props.setreqState({
            ...props.reqState,
            id_consumption_center: selectedCC.value,
            cc_name: selectedCC.label,
            annual_budget: selectedCC.annual_budget,
            articulos:  articlesForParentForm(props.reqState.articulos, articleChanges.concat(articleNew), props.reqState.id_consumption_center, selectedCC.value),
        });
        setFilter({
            ...filters,
            search:"",
            page:1,
            perpage:10,
        });
        props.onHide();
    } 

    {/* //////////////////////modal //////////////////////////////// */}
    return <ModalNew
        title={props.title}
        show={props.show}
        size="lg"
        btnYesName="Agregar"
        btnYesEvent={() => savearticles()}
        onHide={props.onHide}
    >
        
        <p className={`${IndividualStyles.crudModalLabel} ${IndividualStyles.letterPadding}`}>Familia de inventario</p>
        <div className={IndividualStyles.mainRow}>
            <div  className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemSmall}`}>
                <Select noOptionsMessage={() => 'No hay datos'}
                    key={"selectCC"+selectedCC?.value}
                    value={[{value: "", label: "Seleccionar..."},...props?.optionInventoryFamily].find(x => x.value == selectedCC.value)}
                    defaultValue={{label: "Seleccionar...", value: ""}}
                    onChange={e => {
                            setselectedCC(cloneDeep(e));
                            setArticleChanges([]);
                            setArticleNew([]);
                            setFilter({...filters, search:"", page:1})
                        } 
                    } 
                    options={[{value: "", label: "Seleccionar..."},...props?.optionInventoryFamily]}
                    placeholder="Seleccionar..."
                    styles={customSelectNew}
                    maxHeight={26}
                />
            </div>
            <div  className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemSmall}`}>
                <input
                    className={tableStyles.inputText}
                    type="text"
                    placeholder="Filtrar"
                    value={filters.search}
                    onChange={(e) => setFilter({
                        ...filters,
                        search: e.target.value
                    })}
                />
            </div>

            
            <img 
                src={lupaClear} 
                alt="filtrar" 
                className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`} 
                //onClick={this.clickFilter}
            />
            
            <div  className={`${IndividualStyles.ItemTiny} ${IndividualStyles.orangeText}`}>
                {(filteredArticles().length<1) && (filters.search.trim()!="")? "Artículo no encontrado" : ""}
            </div>

        </div>

        <GenericTableNew headers={renderHeaders}>{paginateList()}</GenericTableNew>
        
        <div className={`mt-5 ${paginationStyles.wrapperReverse}`}>
            <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={filteredArticles().length + articleNew.length}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
            itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
            itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
            itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
            itemClass={paginationStyles.itemClass}
            />
        </div>

    </ModalNew>
}

export default ModalArticle;