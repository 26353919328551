import { API_VERSION, defaultFilters, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import {
  GET_LIST_POPULATION,
  SET_ROW_POPULATION,
  GET_ONE_POPULATION
} from "./actionTypes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);

export const getPopulation = (objFilters, loading) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/population/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      loading()
      var { results } = res;
      //console.log("results: ", results);
      dispatch({
        type: GET_LIST_POPULATION,
        payload: results,
      });
      dispatch({
        type: SET_ROW_POPULATION,
        payload: res.rowTotal,
      });
    })
    .catch((err) => {
      loading()
      console.error(err);

      customSwaltAlert({
        icon: "error",
        title: "Error al traer las poblaciones",
        text:
          "Ocurrió un error al intentar traer la información de las poblaciones.",
        footer: "Si el problema persiste comunícate con un asesor.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    });
};

export const createPopulation = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/admin/population/`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        token: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {  

        customSwaltAlert({
          icon: "success",
          title: "Población creada",
          text: data.description,
          confirmButtonText: "Aceptar",
          showCancelButton: false,
        }).then((response) => {
          if (response.isConfirmed) {
            doAfter()
          } else {
            doAfter()
          }
        })
      } else {
        // MySwal.fire({
        //   icon: "error",
        //   title: "Error al crear la población",
        //   text: res.message,
        //   footer: "Si el problema persiste comuníquese con un asesor.",
        // });
        
        doAfter()
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: res.message,
          footer: "Si el problema persiste comuníquese con un asesor.",
          showCancelButton:false,
          confirmButtonText: "Aceptar"
        })
      }
    })
    .catch((res) => {
      
      doAfter()
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: res.message,
        footer: "Si el problema persiste comuníquese con un asesor.",
        showCancelButton:false,
        confirmButtonText: "Aceptar"
      })
    });
}


export const getOnePopulation = (objFilters, doAfter = null) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/population/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      var { results } = res;
      dispatch({
        type: GET_ONE_POPULATION,
        payload: results,
      });
      if (!!results) {
        if (!!doAfter) {
          doAfter(results[0])
        }
      }
    })
    .catch((err) => {
      console.error(err);

      customSwaltAlert({
        icon: "error",
        title: "Error al traer las poblaciones",
        text:
          "Ocurrió un error al intentar traer la información de las poblaciones.",
        footer: "Si el problema persiste comunícate con un asesor.",
        showCancelButton:false,
        confirmButtonText: "Aceptar"
      })
    });
};

export const changeStatusPopulation = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const message = data?.edit 
    ? `Se ha actualizado la población: ${data?.name}`
    : `Se ha ${data.status ? 'habilitado' : 'deshabilitado'} la población: ${data?.name}`
  
  fetch(
    `${URL_GATEWAY}${API_VERSION}/admin/population/`,
    {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        token: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {

        customSwaltAlert({
          icon: "success",
          title: "Actualizado exitosamente",
          text: message,
          confirmButtonText: "Aceptar",
          showCancelButton:false,
        })
        .then((response) => {
          if (response.isConfirmed) {
            doAfter()
          } else {
            doAfter()
          }
        })
      } else {

        customSwaltAlert({
          icon: "error",
          title: "Error al actualizar población",
          text: res.message,
          footer: "Si el problema persiste comuníquese con un asesor.",
          confirmButtonText: "Aceptar",
          showCancelButton:false,
        })
      }
    })
    .catch((res) => {

      customSwaltAlert({
        icon: "error",
        title: "Error al actualizar población",
        text: res.message,
        footer: "Si el problema persiste comuníquese con un asesor.",
        confirmButtonText: "Aceptar",
        showCancelButton:false,
      })

    });
}