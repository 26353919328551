import React from 'react'
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
export const ColorButton = ({ text,style, variant }) => {
    return (
        <b
        className= {variant==='danger'? `${tableStyles.f14} ${tableStyles.ordOrangeBgButton}` : variant==='success'? `${tableStyles.f14} ${tableStyles.ordGreenBgButton}` : `${tableStyles.f14} ${tableStyles.ordRedBgButton}`}
            style={style}
        >
            {text || "Some Text"}
        </b>
    )
}
