import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { loader, message, swalConfirm } from "../../helpers/helpers";
import newItem from "../../assets/img/icons/ADD.svg";
import treeDots from "../../assets/img/icons/threeDots.svg";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import styles from "../../components/Layouts/tableStyle.module.scss";
import OrdTable from "../../OrderingModule/OrderingComponents/OrdTable";
import OrdModal from "../../OrderingModule/OrderingComponents/OrdModal";
import PopUp from "../../components/Popup/customPopUpExtends";
import { OrdGenericTemplate } from "../../OrderingModule/OrderingComponents/OrdGenericTemplate";

export const ImportancePatient = () => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(1);

  const [services, setServices] = useState({
    data: {},
    showDrawer: false,
    showModal: false,
    isEditing: false,
    header: [
      {
        title: "Descripción de la importancia",
        className: "col-3 text-start px-2",
      },
      {
        title: "Pacientes asociados",
        className: "col-3 text-center px-3",
      },
      {
        title: "Estado",
        className: "col-1 text-center",
      },
      {
        title: <>&nbsp;</>,
        className: "col-1 text-center",
      },
    ],
  });

  const [filters, setFilters] = useState({
    search: "",
    page: 1,
    perpage: 6,
  });

  const [importance, setImportance] = useState({
    description: "",
    objFilters: filters,
    status: "",
  });

  useEffect(() => {
    getImportancePatient({
      url: "/medical/markups/",
      token: token,
      objFilters: filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const {
    results: importancePatient,
    load: loadImportancePatient,
    trigger: getImportancePatient,
  } = useGetMethod();

  const { load: loadSendImportancePatient, trigger: sendImportancePatient } =
    usePostMethod();

  const onSubmitImportance = (item, edit) => {
    sendImportancePatient({
      url: "/medical/markups/",
      token: token,
      noAlert: true,
      method: services.isEditing || edit ? "PUT" : "POST",
      body:
        services.isEditing || edit
          ? {
              id: !!item?.id ? item?.id : importance.id,
              description: !!importance?.description
                ? importance?.description
                : null,
              status: !!(
                item?.status === "enabled" || importance.status === "enabled"
              )
                ? "disabled"
                : "enabled",
            }
          : importance,
      doAfterSuccess: () => {
        getImportancePatient({
          url: "/medical/markups/",
          token: token,
          objFilters: filters,
        });
        setImportance({ id: "", description: "", status: "" });
        setServices({ ...services, showModal: false, isEditing: false });
      },
    });
  };

  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className="text-start px-2">{item.description}</td>
            <td className="text-center">{item.patientQty}</td>
            <td>
              {item.status === "enabled" ? (
                <div className={styles.app_container_state}>
                  <span className={styles.app_state_true}>
                    {item.statusDescription}
                  </span>
                </div>
              ) : (
                <div className={styles.app_container_state}>
                  <span className={styles.app_state_false}>
                    {item.statusDescription}
                  </span>
                </div>
              )}
            </td>
            <td className="text-center">
              <PopUp
                showEdit={true}
                showEnable={true}
                position="right"
                triggerSrc={treeDots}
                editClickEvent={() => {
                  setServices({
                    ...services,
                    showModal: true,
                    isEditing: true,
                  });
                  setImportance({
                    ...importance,
                    id: item.id,
                    description: item.description,
                  });
                }}
                isEnabled={item.status === "enabled"}
                enableText={
                  item.status === "enabled" ? "Inhabilitar" : "Habilitar"
                }
                enableClickEvent={() => {
                  swalConfirm({
                    title: `<span class=${styles.ordClearBlueText} >¿Desea ${
                      item.status === "enabled" ? "inhabilitar" : "habilitar"
                    } el estado?</span>`,
                    text: ` `,
                    confirmButtonText: `Si, ${
                      item.status === "enabled" ? "inhabilitar" : "habilitar"
                    }`,
                    doAfterConfirm: () => {
                      onSubmitImportance(item, true);
                      message("success", "Proceso realizado con éxito");
                    },
                  });
                }}
              />
            </td>
          </tr>
        );
      });
      return tempList;
    }
  };

  return (
    <>
      {loadImportancePatient && loader}
      {loadSendImportancePatient && loader}
      <div className="container-fluid">
        <OrdModal
          title={"Importancia de paciente"}
          show={services.showModal}
          btnYesName={"Aceptar"}
          btnNoName={"Cancelar"}
          size={"250"}
          onHide={() => setServices({ ...services, showModal: false })}
          btnYesEvent={() => onSubmitImportance()}
          btnNoEvent={() => setServices({ ...services, showModal: false })}
        >
          <Form.Group>
            <Form.Label className={styles.app_title_input}>
              <div>
                <span className={styles.app_title_new_item}>Descripción</span>
                <span style={{ color: "#FFFF00" }}>*</span>
              </div>
            </Form.Label>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitImportance();
              }}
            >
              <Form.Control
                type="text"
                placeholder="Escribir..."
                value={importance.description}
                onChange={(e) => {
                  setImportance({ ...importance, description: e.target.value });
                }}
                className={`ord-roundInput`}
              />
            </form>
          </Form.Group>
        </OrdModal>
        <OrdGenericTemplate
          className="w-80"
          titleSize={12}
          title="Importancia de pacientes"
          buttonNewLabel={"Nuevo ítem"}
          buttonNewAction={() => {
            setServices({ ...services, showModal: true })
          }}
          searchWithNew={{
            colSize: "12",
            WSize: "100",
            onChange: (e) => {
              e.preventDefault();
              setFilters({ ...filters, search: e.target.value });
            },
            onSubmit: (e) => {
              e.preventDefault();
              setTrigger(trigger + 1);
            },
            buttonNewLabel: "Nuevo ítem",
            buttonNewAction: () => {setServices({ ...services, showModal: true })},
          }}
        >
          {/* <div className="col-md-6 col-lg-8 col-xl-7 col-xxl-7"> */}
          <div className="">
            {/* <div
              className={styles.app_container_new_patient}
              onClick={() => setServices({ ...services, showModal: true })}
            >
              <span className={styles.app_new_item}>Nuevo Ítem</span>
              <img src={newItem} alt="newItem" />
            </div> */}
            <OrdTable
              hasChildren={true}
              headers={services.header}
              paginate={{
                activePage: filters.page,
                totalPages: importancePatient?.rowTotal,
                perPage: filters.perpage,
                pageRangeDisplayed: 1,
                onChangePage: async (e) => {
                  const result = await getImportancePatient({
                    url: "/medical/markups/",
                    objFilters: { ...filters, page: e },
                    token: token,
                  });
                  setFilters({ ...filters, page: e });
                  formatData(result?.results);
                },
                showTextDetails: true,
              }}
            >
              {formatData(importancePatient.results)}
            </OrdTable>
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
};
