//All actions for Login component
import { 
    GET_PROFILES,
    GET_PROFILES_FROM_ACCOUNT,
    PERMISSION_MODULE_GET,
    PERMISSION_FUNCTIONALITY_GET,
    PERMISSION_GET,
    PERMISSION_PUT
    } from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getProfiles = () => (dispatch,getState)  => {
    const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/profile/`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {data} = res;
       dispatch({
           type: GET_PROFILES,
           payload: data
       })
    })
    .catch((err)=>{
        console.error(err)
      
    })

}

export const getProfilesFromAccount = (account) => (dispatch,getState)  => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/profile/account/${account}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {data} = res;
       dispatch({
           type: GET_PROFILES_FROM_ACCOUNT,
           payload: data
       })
    })
    .catch((err)=>{
        console.error(err)
      
    })

}

//PERMISSIONS  MANAGMENT ======================================================================
export const permission_module_get = () => (dispatch,getState)  => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/security/permission/modules/`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
       dispatch({
           type: PERMISSION_MODULE_GET,
           payload: res
       })
    })
    .catch((err)=>{ console.error(err) })

}

export const permission_functionality_get = (id_module) => (dispatch,getState)  => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/security/permission/functionality/?id_module=${id_module}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
       dispatch({
           type: PERMISSION_FUNCTIONALITY_GET,
           payload: res
       })
    })
    .catch((err)=>{ console.error(err) })

}


export const update_permissions = (data, doAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
  
    fetch(`${URL_GATEWAY}${API_VERSION}/security/permission/`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if(res.success){
  
          MySwal.fire({
            icon:'success',
            title:'¡Permisos Actualizados!',
            text:'Se actualizaron los permisos de los perfiles'
          });
          doAfter();
  
        }else{ 
  
          MySwal.fire({
            icon: "error",
            title: "Error al conectar",
            text: "Ocurrió un error al intentar guardar los permisos",
          });
  
        }
      })
      .catch((err) => {
        console.error(err);
      });
  
  };
//PERMISSIONS  MANAGMENT ======================================================================
