import GenericTableNew from "../Layouts/GenericTableNew";
import Lupa from "../../assets/img/icons/lupa.svg";
import LupaClear from "../../assets/img/icons/lupaClear.svg";
import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg";
import extraBtnCheck from "../../assets/img/icons/extraBtnCheck.svg";
import extraBtnRedX from "../../assets/img/icons/extraBtnRedX.svg";
import React, { useEffect, useState } from "react";

import {
  current_auction_filter,
  indauction_filter,
} from "../../actions/IndividualAuctionActions";

import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import IndividualStyles from "./IndividualAuction_CCS.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import { Checkbox, Tooltip } from "@material-ui/core";
import CurrentAuctionDetails from "./CurrentAuctionDetails";
import historyStyles from "./HistorialSubastas.module.scss";
import HistorialSubastasDetails from "./HistorialSubastasDetails";
import ModalNew from "../Layouts/ModalNew";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { loader } from "../../helpers/helpers";

function HistorialSubastas(props) {
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  const myPermission =()=> counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "HiAuction");
  const [trigger, setTrigger] = useState(1);


  const [filters, setFilters] = useState({
    id: "",
    eaccount: counter.loginReducer.currentAccount.id,
    auction_type: "",
    //auction_title: "",
    status: "",
    type_date: "",
    //fromDate: "",
    //toDate: "",
    multistatus: "rejected,solved",
  });

  const [finalfilters, setFinalFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: counter.loginReducer.currentAccount.id,
    multistatus: "rejected,solved",
  });

  const [details, setDetails] = useState({
    show: false,
  });

  const [miniProviders, setminiProviders]= useState({
    show:false,
  });

  useEffect(() => {
    const { history } = props
        if (!myPermission()?.read){
            history?.push("/inicio");
        
        }
  }, []);

  useEffect(() => {
    dispatch(indauction_filter(finalfilters));
  }, [finalfilters.page]);

  useEffect(() => {
    let newfilters = {...finalfilters};
    newfilters = {...newfilters, ...filters, page:1}

    setFinalFilters({...newfilters});
    dispatch(indauction_filter({...newfilters}));

  }, [trigger]);



  const ballthingyTooltipName = (backendName) => {
    switch (backendName) {
      case "unanswered":
        return "No respondida";
      case "partially":
        return "Parcialmente respondida";
      case "solved":
        return "Totalmente respondida";
    }
  };

  const typesAuction = (type) => {
    switch (type) {
      case "normal":
        return "Normal";
      case "priority":
        return "Prioritaria";
    }
  };
  const qtyAuction = (mylist) => {
    let valval = 0;
    if(Array.isArray(mylist)){
      valval = mylist.reduce((total, currentValue) =>{
        if(!isNaN(currentValue.qty_article)){
          return total + currentValue.qty_article;
        }else{
          return total;
        }
      }
      , 0)
    }
    return valval;
  };

  const countProviders = (quotation_ref) =>{
    let providerammount = 0;
    if(Array.isArray(quotation_ref)){
      providerammount= quotation_ref.length;
    }
    return providerammount;
  }

  const renderMiniProviders = (myList) => {
    let table =[];

    if (Array.isArray(myList)) {
        table = myList.map( (x, index) => {
            return <tr key={"renderMiniProviders"+index} className="hover-table-row">
                <td className={`col-md-9 text-start px-2`}>
                    {x.provider_ref?.name}
                </td>
                <td className={`col-md-3`}>
                  {x.status==="solved"?
                    <img src={extraBtnCheck} />
                  :
                    <img src={extraBtnRedX} />
                  }
                </td>
            </tr>
        });

      }
    return table;
}

  const renderHeaders = [
    <th key={`reqTH1`} className="px-2">ID</th>,
    <th key={`reqTH2`} className="px-2 text-center">Fecha orden de compra</th>,
    <th key={`reqTH3`} className="px-2 text-center">Fecha creación</th>,
    <th key={`reqTH5`}>Título  de subasta</th>,
    <th key={`reqTH6`} className="px-2 text-center">Proveedores</th>,
    <th key={`reqTH99`}></th>,
  ];




  const renderList = () => {
    let table = [];

    if (Array.isArray(counter.individualAuctionReducer.indauction)) {
      table = counter.individualAuctionReducer.indauction.map((x) => {

        let justincase = x.date_expiry? ""+x.date_expiry : "-";
       
        return (
          <tr key={"renderList" + x.id} className="hover-table-row">
            <td className="px-2 text-start"><p className={historyStyles.auctionNumber}>{x.auction_number}</p></td>
            <td>{
              x.created_atPurchaseO?
                String(x.created_atPurchaseO).split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')
              : "-"
            }</td>
            <td>{
              x.created_at?
                String(x.created_at).split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')
              : "-"
            }</td>
            <td className="text-start">{x.description}</td>
            <td>
              <p  
                className={`m-0 ${historyStyles.reqCountText}`}
                onClick={()=>setminiProviders({...miniProviders, show:true, list: x.quotation_ref})}     
              >
                {countProviders(x.quotation_ref)}
              </p>
            </td>
            <td>
              <img
                src={lupaTransparent}
                alt="detalles"
                className={` cursorPointer icons-popUp ${IndividualStyles.adjustIcon3}`}
                onClick={()=> setDetails({show: true, selectedAuction: x})}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const paginateList = () => {
    const fullList = renderList();
    return fullList.slice(
      finalfilters.perpage * (finalfilters.page - 1),
      finalfilters.perpage * finalfilters.page
    );
  };


  const myRender = <div className={tableStyles.container} style={{padding:"0 6rem"}}>
    {counter.individualAuctionReducer.indauction_loading && loader}
      <div className={IndividualStyles.container1}>
        <div className={IndividualStyles.ItemSmall}>
          <h1 className={tableStyles.title}>Historial de Subastas</h1>
        </div>
      </div>

      <div className={IndividualStyles.container2}>
        {/* ---------------------------- ROW 1 ---------------------------------- */}
        <div className="d-flex">
          
          <div
            className={`${IndividualStyles.inputMarginNew} ${IndividualStyles.ItemTiny}`}
          >
            <p className={IndividualStyles.crudModalLabel}>Fecha OC</p>
            <div className={tableStyles.deleteicon}>
                {!!filters.ocDate?
                    <span  onClick={(e)=>setFilters({...filters, ocDate:""})} >x</span>
                :
                    ""
                }
                <input
                    className={`${IndividualStyles.registerInputsBlue}`}
                    name="ocDate"
                    type="date"
                    value={filters.ocDate}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        ocDate: e.target.value,
                      })
                    }
                />
            </div>

          </div>

          <div
            className={`${IndividualStyles.inputMarginNew} ${IndividualStyles.ItemTiny}`}
          >
            <p className={IndividualStyles.crudModalLabel}>Fecha de Creación</p>

            <div className={tableStyles.deleteicon}>
                {!!filters.date?
                    <span  onClick={(e)=>setFilters({...filters, date:""})} >x</span>
                :
                    ""
                }
                <input
                    className={`${IndividualStyles.registerInputsBlue}`}
                    name="date"
                    type="date"
                    value={filters.date}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        date: e.target.value,
                      })
                    }
                />
            </div>
          </div>

          <div
            className={`${IndividualStyles.inputMarginNew} ${IndividualStyles.ItemSmall_new}`}
          >
            <p className={IndividualStyles.crudModalLabel}>&nbsp;</p>
            <input
              name="search"
              className={tableStyles.SearchNew}
              type="text"
              placeholder="Buscar..."
              value={filters.search}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  search: e.target.value,
                })
              }
              onKeyDown={e=>{
                if (e.key === 'Enter') {
                  setTrigger(trigger+1);
              }  
              }}
              disabled={false}
            />
          </div>
          
          <div
            className={`${IndividualStyles.Aling} ${IndividualStyles.inputMargin}`}
          >
            <img
              src={Lupa}
              alt="filtrar"
              height={24}
              className={`${IndividualStyles.imagePaddingNew} ${IndividualStyles.sizeNew} ${IndividualStyles.pointer} mx-3`}
              onClick={() => setTrigger(trigger + 1)}
            />
          </div>

        </div>

      </div>

      <div className={IndividualStyles.container3}>
        <GenericTableNew dark={true} headers={renderHeaders}>
          {!(!!counter.individualAuctionReducer.indauction_loading) &&
            renderList()
          }
        </GenericTableNew>
      </div>

      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag.{" "}
            {counter.individualAuctionReducer.indauction_total
              ? finalfilters.page
              : ""}
            {" de "}
            {Math.ceil(
              counter.individualAuctionReducer.indauction_total / finalfilters.perpage
            )
              ? Math.ceil(
                  counter.individualAuctionReducer.indauction_total /
                  finalfilters.perpage
                )
              : ""}{" "}
            ({counter.individualAuctionReducer.indauction_total} encontrados)
          </p>
          <Pagination
            activePage={finalfilters.page}
            itemsCountPerPage={10}
            totalItemsCount={counter.individualAuctionReducer.indauction_total}
            pageRangeDisplayed={5}
            onChange={(e) => setFinalFilters({ ...finalfilters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>

      <ModalNew
          title="Proveedores"
          show={miniProviders.show}
          onHide={()=>setminiProviders({...miniProviders, show:false, list: [] })}
          hideCancelButton={true}
          size="440"
      >
          <GenericTableNew 
              headers={[ <th key={`p1`} className="px-2">Proveedor</th>, <th key={`p2`} className="text-center">Cotizó</th>] }
          >
              {renderMiniProviders(miniProviders.list)}
          </GenericTableNew>
      </ModalNew>

    </div>


  return (!!details.show)? 
    <HistorialSubastasDetails 
    //details={details}
    selectedAuction={details.selectedAuction}
    show={details.show}
    setDetails={setDetails}
    trigger={trigger}
    setTrigger={setTrigger}
    /> 
  : 
    myRender;
}

export default HistorialSubastas;
