// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fullLayout_fullLayout__3LjrM {\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n}\n\n.fullLayout_fullLayoutBody__3iPsk {\n    display: flex;\n    flex: 1 1;\n    align-items: stretch;\n    overflow: hidden;\n}\n\n.fullLayout_fullLayoutWorkArea__1Xe18 {\n    display: flex;\n    flex: 1 1;\n    overflow-y: scroll;\n}\n\n.fullLayout_fullLayoutSideBar__3CiX7 {\n    overflow: hidden;\n}\n\n.fullLayout_zIndex800__2LVco {\n    z-index: 800 !important;\n}", "",{"version":3,"sources":["webpack://src/routes/fullLayout.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".fullLayout {\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n}\n\n.fullLayoutBody {\n    display: flex;\n    flex: 1;\n    align-items: stretch;\n    overflow: hidden;\n}\n\n.fullLayoutWorkArea {\n    display: flex;\n    flex: 1;\n    overflow-y: scroll;\n}\n\n.fullLayoutSideBar {\n    overflow: hidden;\n}\n\n.zIndex800 {\n    z-index: 800 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullLayout": "fullLayout_fullLayout__3LjrM",
	"fullLayoutBody": "fullLayout_fullLayoutBody__3iPsk",
	"fullLayoutWorkArea": "fullLayout_fullLayoutWorkArea__1Xe18",
	"fullLayoutSideBar": "fullLayout_fullLayoutSideBar__3CiX7",
	"zIndex800": "fullLayout_zIndex800__2LVco"
};
export default ___CSS_LOADER_EXPORT___;
