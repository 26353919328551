import React, { useEffect, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import Filter from "../../assets/img/icons/filtro.svg";
import Add from "../../assets/img/icons/agregar.svg";
import Export from "../../assets/img/icons/exportar.svg";
import Gear from "../../assets/img/icons/engraneGris.svg";
import GearWhite from "../../assets/img/icons/engraneBlanco.svg";
import Pagination from "react-js-pagination";
import GenericTable from "../Layouts/GenericTable";
import { connect, useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import GenericModal from "./GenericModal";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { class_get, class_edit, class_edit_active, class_insert } from "../../actions/classificationActions";
import "../TableUsers/tableUserStyle.scss";
import styles from "../TableCups/styles.module.css";

function TableClass(props){
    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    
    //filtros para el class_get
    const [filters, setFilter]= useState({
        search: "",
        active: '',
        page: 1,
        perpage: 10
    });

    //valores para class_insert y el modal
    const [modalPostShow, setmodalPostShow] =useState(false);
    const [postState, setPostState]=useState({
        descripcion: ""
    });

    //estado interno para manejar tanto la data de class_edit como el modal que lo abre
    const [putState, setPutState]=useState({
        id: 0,
        descripcion: "",
        modalShow: false
    });

    const [trigger, setTrigger] = useState(0); //esto es para recargar la pagina
    
    useEffect(() => {
        //componentDidMount
        dispatch(class_get(filters))

        /*return () => {
            //componentWillUnmount
        }*/
    },[trigger, filters.page, filters.active]); //only trigger on start or if this state is changed //filters, props, 
    
    useEffect(() => {
        const timeOutId = setTimeout(() => dispatch(class_get(filters)), 500);
        return () => clearTimeout(timeOutId);
    }, [filters.search]); //alternative: change every time filter changes but with a delay
   
    const handleChange = (event) => {
        setFilter({
            ...filters,
            page: 1,
            search: event.target.value
        })
    }

    const handlePageChange = (val) => {
        setFilter({
            ...filters,
            page: val
        });
        setTrigger(trigger+1);
    }

    const filterChangeActive = (event) => {
        setFilter({
            ...filters,
            active: event.target.value
        })
    }

    const renderElement = (elem) => {
        
        return (
          <tr key={elem.id}  className="hover-table-row text-center align-middle">
            <td className="col-md-1"> {elem.id} </td>
            <td className="col-md-6">{elem.descripcion}</td>
            <td className="col-md-1">
              <div className={elem.activo ? "circle" : "circle-red"}></div>
            </td>
            <td className="col-md-4">
                

                <Popup
                    trigger={
                    <button>
                        <img src={Gear} alt="configuracion" className="icons-gear" />
                    </button>
                    }
                    position="right center"
                >
                    <ul className={styles.popUpUl}>
                        <li
                            onClick={()=>setPutState(
                                {...putState, 
                                    id:elem.id,
                                    descripcion:elem.descripcion, 
                                    modalShow:true
                                }
                            )}
                            className={styles.popUpLiEdit}
                        >
                            Editar
                        </li>
                        <li
                            onClick={() => dispatch(
                                class_edit_active(
                                    elem.id,
                                    {activo: !elem.activo,
                                    shownName: elem.descripcion},
                                    ()=>setTrigger(trigger+1))
                                )}
                            className={elem.activo ? styles.popUpLiActiveRed: styles.popUpLiActive}
                        >
                            {elem.activo ? "Deshabilitar" : "Habilitar"}
                        </li>
                    </ul>
                </Popup>
            </td>
          </tr>
        );
    }
    
    const listElem = () => {
        let elemMap;
        if (counter.classificationReducer.classification !== undefined) {
            const elem2 = counter.classificationReducer.classification;
            elemMap = elem2.map((elem) => {
              return renderElement(elem);
            });

          }
        return elemMap;
    }

    const renderHeaders = [
        <th key={0}>Código</th>,
        <th key={1}>Descripcion</th>,
        <th key={2}>Estado</th>,
        <th key={3}>Acciones</th>,
    ]

    return(
        <div className="container">
            <div className="row">
                <h1 className="top-h1">Clasificación</h1>
            </div>
            <div className="row">
                <div className="col-md-12" >
                    <span>
                        <img src={Filter} alt="User icon" className="filter-svg" />
                    </span>
                    <input
                        className="search-users"
                        type="text"
                        placeholder="Buscar"
                        onChange={handleChange}
                    />
                    
                    <select
                        className="filter-select-2"
                        onChange={filterChangeActive}
                    >
                        <option value="" defaultValue>
                            Seleccione...
                        </option>
                        <option value="1">Activo</option>
                        <option value="0">Inactivo</option>
                    </select>

                    <button className="learn-more">
                        <span className="circle" aria-hidden="true">
                            <span className="icon arrow"></span>
                        </span>
                        <span className="button-text" onClick={()=>setTrigger(trigger+1) }>
                            Filtrar
                        </span>
                    </button>

                    <GenericModal
                        title="Editar Clasificación"
                        show={putState.modalShow}
                        onHide={() => setPutState({...putState, modalShow:false})}
                        confirmaction={()=>dispatch(
                            class_edit(
                                putState.id,
                                {descripcion: putState.descripcion},
                                ()=>{
                                    setTrigger(trigger+1);
                                    setPutState({...putState, modalShow:false})
                                }
                            )
                        )}
                    >
                        
                        <div className="row">
                            <div className="col-md-12">
                            <p className="label-inputs">Descripcion</p>
                            <input
                                className="register-inputs"
                                name="descripcion"
                                type="text"
                                placeholder="Descripcion Nueva"
                                value={putState.descripcion}
                                onChange={(event)=>setPutState({...putState, descripcion: event.target.value})}
                            />
                            </div>
                        </div>
                    </GenericModal>

                    <GenericModal
                        title="Creación de Clasificación"
                        show={modalPostShow}
                        onHide={() => setmodalPostShow(false)}
                        confirmaction={  ()=>dispatch(class_insert(postState, ()=>{setTrigger(trigger+1);setmodalPostShow(false);})) }
                    >
                        
                        <div className="row">
                            <div className="col-md-12">
                            <p className="label-inputs">Descripcion</p>
                            <input
                                className="register-inputs"
                                name="descripcion"
                                type="text"
                                placeholder="Prueba"
                                onChange={(event)=>setPostState({...postState, descripcion: event.target.value})}
                            />
                            </div>
                        </div>
                    </GenericModal>
                    <img
                    src={Add}
                    alt="User icon"
                    className="icons-svg"
                    onClick={() => setmodalPostShow(true)}
                    />

                    <img src={Export} alt="User icon" className="icons-svg" /> 
                    <GenericTable headers={renderHeaders}>{listElem()}</GenericTable>
                    <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.perpage}
                        totalItemsCount={counter.classificationReducer.total}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClassPrev={"btn-following pagination-button"}
                        itemClassNext={"btn-following pagination-button"}
                        itemClassFirst={"btn-following pagination-button"}
                        itemClassLast={"btn-following pagination-button"}
                        itemClass={"btn-primary pagination-button"}
                    />
                </div>
            </div>
        </div>
    )
}


  export default TableClass;