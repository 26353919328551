// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
// --------------- icons 💥  ----------------//
import Agregar from "../../assets/img/icons/agregarEspecialidad.svg";
import Back from "../../assets/img/icons/atras-icon.svg";
//-------------- scss styles 😄   --------------
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";

// ------------------ Functions ----------------------
import { createClosedVoucherRegistration } from "../../actions/movementsActions";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";



function ClosingVoucher(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);

  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({
    date: "",
    eaccount: storage.loginReducer.currentAccount.id,
    observations: "",
    user: storage.loginReducer.user_data.id
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "vouchers"
    );

  //--------------first functions 🍕   ----------

  useEffect(() => {
    if (!myPermission()?.create || !myPermission()?.read) {
      history?.push("/contabilidad/inicio");
    }
  }, []);

  function handleClickBack() {
    history.push("/contabilidad/Movimientos/Comprobantes");
  }

  const createRegistration = () => {
    dispatch(createClosedVoucherRegistration(info, () => {
      setTrigger(trigger + 1)
      setLoading(false)
      setInfo({
        date: "",
        eaccount: storage.loginReducer.currentAccount.id,
        observations: "",
        user: storage.loginReducer.user_data.id
      })
    }))
  }



  //--------------  Actions  🍕   --------------

  return (
    <div className="d-flex flex-column mx-2 p-2 container-fluid mx-auto">
      {(loading) &&
        <div className="loading">
          <Loader
            type="Oval"
            color="#003f80"
            height={100}
            width={100}
          />
        </div>}
      <div className="d-flex">
        
        <div className="d-flex flex-column w-90" style={{marginLeft:'3rem'}}>
          <div className={tableStyles.title}>
            <Title
              title="Comprobante de cierre de ejercicio"
              onClickIcon={() => {
                props.setShow({ showClose: false })
              }}
            />
          </div>
          <Row className="d-flex g-0" style={{ background: "rgba(0, 93, 191, 0.1)", marginTop: "31px", borderRadius: "10px", paddingRight: "16px", paddingBottom: "16px", paddingLeft: "16px", paddingTop: "16px", marginLeft: "5px" }}>
            <Col xs={4}>
              <p className={tableStyles.crudModalLabel}>Tipo de comprobante</p>
              <input
                type="text"
                name="typeVoucher"
                className={IndividualStyles.registerInputsBlue}
                value={"Cierre de actividades"}
                disabled={true}
              />
            </Col>
            <Col xs={4} style={{ paddingLeft: "1rem" }}>
              <p className={tableStyles.crudModalLabel}>Fecha de comprobante</p>
              <input
                key={"date" + trigger}
                type="date"
                name="date"
                className={IndividualStyles.registerInputsBlue}
                max={new Date().toISOString().slice(0, 10)}
                value={info.date}
                onChange={(e) => {
                  let dateSelected = new Date(e.target.value)
                  if (dateSelected < new Date()) {
                    setInfo({
                      ...info,
                      date: e.target.value,
                    })
                  }
                }
                }
              />

            </Col>


            <Col xs={12}>
              <p className={tableStyles.crudModalLabel}>Comentarios</p>
              <textarea
                key={"observations" + trigger}
                type="text"
                name="activity name"
                style={{ color: "#58595B", minHeight: "50px" }}
                className={IndividualStyles.registerInputsBlue}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    observations: e.target.value,
                  })
                }
              />
            </Col>
          </Row>


          <div className={IndividualStyles.bottomRow} style={{ paddingRight: "0px" }}>
            <Button
              className={IndividualStyles.btnPrimaryDark}
              onClick={() => (createRegistration(), setLoading(true))}
              disabled={!!(info.date == "") || !!(info.observations == "")}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClosingVoucher;



