import React, { useEffect, useState } from "react";
import bankMoveStyle from "../MovimientosBancarios/bankMoveStyles.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import StyleInvoice from "./InvoiceReceipt.module.scss"
import Atras from "../../assets/img/icons/atras-icon.svg";
import { useHistory } from "react-router-dom";
import ModalDocuments from "../../components/TableIndividualAuction/IndividualDocuments";
import { useDispatch, useSelector } from "react-redux";
//import { requisition_consecutive } from "../../actions/IndividualAuctionActions";
import GenericTable from "../../components/Layouts/GenericTableNew";
import Engra from "../../assets/img/icons/engraneGris.svg";
import ModalNew from "../../components/Layouts/ModalNew";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";

function RegisterInvoice(props) {
    const counter = useSelector(state => state);
    const [reqState, setreqState] = useState({

        auction_title: "",
        date_expiry: "",
        hour_expiry: "",
        terms: "",
        priority: "",
        id_warehouse: "",
        id_user: counter.loginReducer.user_data.id,
        account: counter.loginReducer.currentAccount.id,
        account_session: counter.loginReducer.currentAccount.name,
        id_consumption_center: 0,
        articulos: [],
        pay_conditions: "",
        coin: "",
        document: [],
        file: false,
        observations: " ",
        qty_total: 0,

    });
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(1);
    const ButtonDocuments = () => {
        setmodalDocumentsControl({
            ...modalDocumentsControl,
            show: true
        })
    }
    const [modalDocumentsControl, setmodalDocumentsControl] = useState({
        show: false,
    });
    useEffect(() => {
        //dispatch(requisition_consecutive());
    }, [trigger]);


    let history = useHistory();
    function handleClick() {
        history.push("/tesoreria/RecepcionDeFacturas");
    }

    const [showModal, setShowModal] = useState(false);

    const header = [

        <th className="text-center"><input type="checkbox" name="" id="" /></th>,
        <th className="text-center">No. Entrada</th>,
        <th className="text-center">Fecha</th>,
        <th className="text-center">Proveedor</th>,
        <th className="text-center">Factura</th>,
        <th className="text-center">Valor</th>,
        <th className="text-center"></th>
    ]
    const body = [
        <tr>
            <td className="text-center"><input type="checkbox" name="" id="" /></td>
            <td className="text-center">2314</td>
            <td className="text-center">01/06/2018</td>
            <td className="text-center">Novaventa S.A.S.</td>
            <td className="text-center">120194522</td>
            <td className="text-center">$2.000.000</td>
            <td className="text-center">
                <img className="text-start w-15"
                    style={{ width: "20px" }}
                    src={Engra}
                    onClick={() => setShowModal(true)}
                />
            </td>
        </tr>

    ]
    return (
        <>
            <div className={bankMoveStyle.centredContainer}>
                <div className={bankMoveStyle.container2}>
                    <div className={tableStyles.container1}>
                        <div className={tableStyles.ItemSmall}>
                            <div className="d-flex">
                                <img className={`${tableStyles.title} mr-3`} style={{cursor: "pointer"}} src={Atras} onClick={handleClick} />
                                <h1 className={tableStyles.title}>Registrar factura</h1>
                            </div>
                            <h5 className={StyleInvoice.subTitlerf}>Factura No. 12365498791</h5>
                        </div>
                    </div>
                    <div className={`${StyleInvoice.bgColorrf} pt-3 pb-3`}>
                        {/* ---------------------------- ROW 1 ---------------------------------- */}
                        <Row className="d-flex" style={{ width: "100%" }}>
                            <div className="mt-4">
                                <li className={tableStyles.titleTax}>Cargar factura</li>
                            </div>
                            <Col xs={4}>
                                <p className={tableStyles.crudModalLabel}>Tipo de factura</p>
                                <select
                                    className="select-register"
                                    name="id_consumption_center"
                                >
                                    <option value={""}>test</option>
                                </select>
                            </Col>

                            <Col xs={4}>
                                <p className={tableStyles.crudModalLabel}>Relacionar entradas por almacén</p>
                                <div
                                    className="register-inputs"
                                    name="requisition_date"
                                    type="text"
                                    onClick={() => setShowModal(true)}
                                />
                            </Col>
                        </Row>

                        {/*--------------------------------- ROW 3 --------------------------------- */}
                        <Row className="d-flex " style={{ width: "100%" }}>
                            <div className="mt-4">
                                <li className={tableStyles.titleTax}>Información General</li>
                            </div>

                            <Col xs={4}>
                                <p className={tableStyles.crudModalLabel}>Proveedor</p>
                                <select
                                    className="select-register"
                                    name="id_consumption_center"
                                >
                                    <option value={""}>test</option>
                                </select>
                            </Col>

                            <Col xs={4}>
                                <p className={tableStyles.crudModalLabel}>Número de factura</p>
                                <input
                                    className="register-inputs"
                                    name="requisition_date"
                                    type="text"
                                />
                            </Col>
                            <Col xs={4}>
                                <p className={tableStyles.crudModalLabel}>Fecha de emisión</p>
                                <input
                                    className="register-inputs"
                                    name="requisition_date"
                                    type="date"
                                />
                            </Col>

                        </Row>

                        {/*--------------------------------- ROW 4 --------------------------------- */}
                        <Row className="d-flex " style={{ width: "100%" }}>

                            <Col xs={4}>
                                <p className={tableStyles.crudModalLabel}>Condición de pago</p>
                                <input
                                    className="register-inputs"
                                    name="requisition_date"
                                    type="text"
                                />
                            </Col>

                            <Col xs={4}>
                                <p className={tableStyles.crudModalLabel}>Monto del documento</p>
                                <input
                                    className="register-inputs"
                                    name="requisition_date"
                                    type="text"
                                />
                            </Col>

                            <Col xs={4}>
                                <p className={tableStyles.crudModalLabel}>Fecha de vencimiento</p>
                                <input
                                    className="register-inputs"
                                    name="requisition_date"
                                    type="date"
                                />
                            </Col>

                        </Row>

                        <Row className="d-flex" style={{ width: "100%" }}>
                            <Col>
                                <p className={tableStyles.crudModalLabel}>Observaciones</p>
                                <textarea
                                    style={{ height: "3rem" }}
                                    placeholder="Escribir..."
                                    rows="5"
                                    cols="40"
                                    className="register-inputs"
                                    name="requisition_date"
                                />
                            </Col>
                        </Row>
                    </div>


                    {/*--------------------------------- ROW 5 --------------------------------- */}
                    <Row className="d-flex">
                        <Col
                            xs={12}
                            className="d-flex"
                            style={{ justifyContent: "flex-end", paddingRight: "2.5rem" }}
                        >
                            &nbsp;
                            <Button className={`${tableStyles.theadLight} mt-4`}>
                                Registrar
                            </Button>
                        </Col>
                    </Row>
                    <ModalDocuments
                        title="Anexar Documento"
                        key={"modalDocuments" + trigger}
                        show={modalDocumentsControl.show}
                        onHide={() => setmodalDocumentsControl({
                            ...modalDocumentsControl,
                            show: false
                        })}
                        reqState={reqState}
                        setreqState={setreqState}
                    />

                    <ModalNew
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        title={"Entradas de almacen"}
                        btnYesEvent={true}
                        btnYesName={"Seleccionar"}
                        size={"700"}

                    >
                        <Row className="d-flex">
                            <Col xs={4}>
                                <label className={tableStyles.crudModalLabel}>Fecha</label>
                                <input className={IndividualStyles.registerInputs} type="date"></input>
                            </Col>
                            <Col xs={4}>
                                <label className={tableStyles.crudModalLabel}>Proveedor</label>
                                <select className={IndividualStyles.registerInputs} name="" id=""></select>
                            </Col>
                            <Col xs={3}>
                                <input className={IndividualStyles.registerInputs} style={{ marginTop: "30px" }} type="text"></input>
                            </Col>
                            <Col xs={1}>
                                <i><img className="mt-4" style={{ width: "25px" }} src={Engra} alt="" srcset="" /></i>
                            </Col>
                        </Row>
                        <GenericTable
                            headers={header}
                            children={body}
                        >
                        </GenericTable>

                    </ModalNew>
                </div>
            </div>
        </>
    );
} export default RegisterInvoice;
