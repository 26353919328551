import React, { useEffect, useState } from "react";
import threeDots from "../../../assets/img/icons/threeDots.svg";
import Pagination from "react-js-pagination";
import Search from "../../../assets/img/icons/lupa.svg";
import Agregar from "../../../assets/img/icons/add-check.svg";
import editIcon from "../../../assets/img/icons/extraBtnPencil.svg";
import detailIcon from "../../../assets/img/icons/lupaTransparent.svg";
import GenericTableNew from "../../Layouts/GenericTableNew";
import { useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";
import "../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import CustomPopupStyles from "../../../components/Popup/customPopup.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import { Col, Row, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomPopupExtend from "../../Popup/customPopUpExtends";
import ModalNew from "../../Layouts/ModalNew";
import { loader,validateEmptyString } from "../../../helpers/helpers";
// import { useGetMethod, usePostMethod } from "./Hooks/useFetch";
import { useGetMethod, usePostMethod } from "../../../Hooks/useFetch";
import { responseSwal } from "../../../helpers/responseSwal";
import Loader from "react-loader-spinner";

export const PermissionManagement = (props) => {
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "gespermiss"
    );
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    page: 1,
    perpage: 10,
    eaccount: idEnterprise,
  });

  const { results: roleListResults, load: roleListLoader, trigger: getRolMethod } = useGetMethod();
  const { load: changeStatusSurveyLoader, trigger: changeStatusSurveyMethod } = usePostMethod();

  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/categoryselect");
    }

    getRolMethod({
      url: "/security/roles/",
      objFilters: filters,
      token: token,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);


  const renderElement = (elem) => {
    const options =[
      {
        text: "Ver detalle",
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          handleClickDetail(elem)
        },
        icon: detailIcon
      },
      {
        text: "Editar",
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          // setCancelModal({...cancelModal, show: true, surveyId: elem.id, title: elem.title})
          handleClickNew(elem, true)

        },
        icon: editIcon

      },
    ]


    return (
      <tr key={elem.id}>
        <td className="col-md-3 text-start px-2" >
          {elem.rolName ?? "-"}
        </td>
        <td className="col-md-2 text-center">{elem.availableModules ?? "-"}</td>
        <td className="col-md-2 text-center">{elem.usersTotal ?? "-"}</td>
        <td className="col-md-2 text-center">{elem.activeUsers ?? "-"}</td>
        <td className="col-md-2 text-center">{elem.inactiveUsers ?? "-"}</td>
        <td className="col-md-1 text-center cursorPointer">
          <div>
            <CustomPopupExtend
              noHover
              className={`align-self-end`}
              triggerSrc={threeDots}
              extraButtons={options}
            />
          </div>
        </td>
      </tr>
    );
  };






  const listElem = () => {
    let elemMap;
    if (roleListResults?.results?.length > 0) {
      const elem2 = roleListResults?.results;
      if (elem2?.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className="text-start">
      &nbsp; Nombre 
    </th>,
    <th key={2} className="text-center">
      Módulos disponibles
    </th>,
    <th key={3} className="text-center">
      Total de usuarios
    </th>,
    <th key={4} className="text-center">Usuarios activos</th>,
    <th key={5} className="text-center">Usuarios inactivos</th>,
    <th key={6} className="text-center">&nbsp;</th>,
  ];

  const handleClickNew = (elem, edit) => {
    history.push({
      pathname: "/administracion/GestionDePermisos/NuevoRol",
      state: { isEditing: edit ? true : false, dataEdit: elem},
    });
  };
  const handleClickDetail = (elem) => {
    history.push({
      pathname: "/administracion/GestionDePermisos/Detalle/",
      state: { detail: true, data: elem },
    });
  };



  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };


  const [cancelModal, setCancelModal] = useState({
    show: false,
    cancelledReason: "",
    title: "",
    surveyId: "",
  })

  const closeCancelModal = ()=>{
    setCancelModal({...cancelModal,
      show:false,
      cancelledReason: ""
    })
  }

  const sendCancelledReason = ()=>{
    let validations = validateEmptyString([
      { field: cancelModal.cancelledReason, message: "Para cancelar una encuesta debe de mandar el motivo de cancelación"},
    ])

    if (validations.valid){
    Swal.fire({
      title: `<span style=color:#003f80>¿Está seguro?</span>`,
      text: `Se cancelará la encuesta '${cancelModal.title}'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#003f80",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Continuar",
      cancelButtonText: "Cancelar",
  }).then((result) => {
      if (result.isConfirmed) {
          changeStatusSurvey("cancelled", )
      }
  })

  }


  }

  const finishSurveys = (title, id) => {
    Swal.fire({
      title: `<span style=color:#003f80>¿Está seguro?</span>`,
      text: `Se finalizará la encuesta '${title}'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#003f80",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Continuar",
      cancelButtonText: "Cancelar",
  }).then((result) => {
      if (result.isConfirmed) {
        changeStatusSurvey("completed", id)
      }
  })
  }

  const changeStatusSurvey = (method, id)=>{
    let objFilters =
      method === "cancelled"
        ? {
            id: cancelModal.surveyId,
            status: "cancelled",
            cancellationReason: cancelModal.cancelledReason,
          }
        : {
            id: id,
            status: "completed",
          };
    changeStatusSurveyMethod({
      url: "/payroll/workenviroment",
      method: "PUT",
      body: objFilters,
      token: token,
      succesAction: (info) => {
        if (method === "cancelled"){
          closeCancelModal()
        }
        if (method === "completed"){
          // closeCancelModal()
        }
        responseSwal({
          title: `<span style=color:#005dbf>${info.message}</span>`,
          icon: "success",
        })
        // message("info", "Información", info.message);
        setTrigger(trigger+1)
      },
      doAfterException: (error) => {
        responseSwal({
          text: `<span style=color:#005dbf>${error.message}</span>`,
          // title: `<span style=color:#005dbf>${error.title}</span>`,
          icon: "success",
        })
        // message("error", "Información", error.message);
      },
    });



  }

  return (
    <>
      {/* ANCHOR  CANCELLED MODAL */}
{(roleListLoader || changeStatusSurveyLoader) && loader}
      <ModalNew
        title={"Motivo de cancelación"}
        show={cancelModal.show}
        btnYesName={"Enviar"}
        btnNoName={"Cancelar"}
        size={500}
        onHide={() => closeCancelModal()}
        btnYesEvent={() => sendCancelledReason()}
        btnNoEvent={() => closeCancelModal()}
        // sizeBody={`${tableStyles.modalHeight}`}
      >
        <div className="mb-4">
          <Form.Group className=" text-start" controlId="cancelled">
            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                &nbsp;Motivo <span className={`${tableStyles.ordOrangeText}`}></span>{" "}
              </span>
            </Form.Label>
            <textarea
              placeholder={"Escribir..."}
              rows="30"
              cols="55"
              style={{ height: "5rem" }}
              value={cancelModal.cancelledReason}
              className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
              onChange={(e) => setCancelModal({ ...cancelModal, cancelledReason: e.target.value })}
            ></textarea>
          </Form.Group>
        </div>
      </ModalNew>

      <div className={tableStyles.container}>
        {store.workEnvReducer.listWorkEnvPoll_loading && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        )}
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Gestión de roles y permisos</h1>
          <Row>
            <Col
       xs={4}
       className="d-flex align-self-end gap-1"
      >
       <input
        className={tableStyles.SearchNew}
        value={filters.search}
        placeholder="Buscar..."
        type="text"
        onChange={(e) =>
          setFilters({ ...filters, search: e.target.value })
        }
        onKeyDown={(e) => {
         if (e.key === "Enter") {
          handleSearch(e)
         }
        }}
       ></input>
       <img
        className="hoverPointer"
        alt="iconSearch"
        style={{}}
        src={Search}
        height="24px"
        onClick={(e) => handleSearch(e)}
       />
      </Col>
{!!myPermission?.create ? (
       <Col
        xs={8}
        className="align-self-end"
       >
        <div className="d-flex justify-content-end">
         <div
          onClick={() => handleClickNew()}
          className={tableStyles.createNomModule}
         >
          <b className={`mr-2`}>Nuevo rol</b>
          <img
           src={Agregar}
           alt="User"
          />
          <div></div>
         </div>
        </div>
       </Col>
      ) : null}
            
          </Row>

          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {roleListResults?.results ? filters.page : ""}
              {" de "}
              {Math.ceil(roleListResults?.rowTotal / filters.perpage)
                ? Math.ceil(roleListResults?.rowTotal / filters.perpage)
                : ""}{" "}
              ({roleListResults?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={roleListResults?.rowTotal}
              pageRangeDisplayed={5}
              onChange={(e) => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
