import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { useSelector } from 'react-redux'
import { formatToRcSelect, generateId, loader } from '../helpers/helpers'
import tableStyles from '../components/Layouts/tableStyle.module.scss'
import { useGetMethod, useMultiGetMethod, usePostMethod } from '../Hooks/useFetch'
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate'
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable'
import OrdFancyGlass from '../assets/img/icons/OrdFancyGlass.svg'
import close from '../assets/img/icons/x.svg'
import ordBlueSearch from '../assets/img/icons/ordBlueSearch.svg'
import { Col, Form, Row } from 'react-bootstrap'
import { ordCustomSelect } from '../components/Layouts/react-select-custom'
import Drawer from '@mui/material/Drawer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ButtonAddClearBlueHover } from '../OrderingModule/OrderingComponents/ButtonAddClearBlueHover'
export const MedicalServices = () => {
    const store = useSelector((state) => state);
    const history = useHistory();
    const token = store.loginReducer.Authorization;
    const idEnterprise = store.loginReducer.currentAccount.id;
    const [filters, setFilters] = useState({
        page: 1,
        search: "",
        perpage: 10,
        idAccount: idEnterprise
    })
    //THIS IS THE CHOSEN SERVICES
    const [services, setServices] = useState({
        data: {
            optimalTimeQty: "",
            optimalTimeId: "",
            freqPeriod: "",
            srvConductTimeId: "",
            srvConductTimeQty: "",
        },
        showDrawer: false,
        headers: [
            {
                title: "Código",
                className: 'col-1 text-center'
            },
            {
                title: "Nombre del servicio",
                className: 'col-8 text-start'
            },
            {
                title: "Tipo",
                className: 'col-2 text-start'
            },
            {
                title:
                    <>
                        &nbsp;
                    </>
                ,
                className: 'col-1'
            },
        ]
    })
    const getInitialState = () => {
        const newVal = {
            data: {

            },
            showDrawer: false,
            headers: [
                {
                    title: "Código",
                    className: 'col-1 text-center'
                },
                {
                    title: "Nombre del servicio",
                    className: 'col-8 text-start'
                },
                {
                    title: "Tipo",
                    className: 'col-2 text-start'
                },
                {
                    title:
                        <>
                            &nbsp;
                        </>
                    ,
                    className: 'col-1'
                },
            ]
        }
        return setServices(newVal)
    }
    /* ----------------------------- FETCHS SECTION ----------------------------- */
    /* ----------------------------- GET ----------------------------- */
    const [trigger, setTrigger] = useState(0);
    const { results: medicalServicesList, load: medicalServicesListLoader, trigger: getmedicalServicesList } = useGetMethod()
    const { results: initialList, load: initialListLoader, trigger: getInitialList } = useMultiGetMethod()
    /* ---------------------------------- POST /PUT ---------------------------------- */
    const { load: updateMedicalServLoader, trigger: updateMedicalServ } = usePostMethod()
    /* ------------------------------------ - ----------------------------------- */
    useEffect(() => {
        getmedicalServicesList({
            url: '/medical/offered-services',
            objFilters: filters,
            token: token,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, filters.typeId])
    useEffect(() => {
        getInitialList({
            multipleGet: [
                {
                    url: '/medical/services-type',
                    objFilters: filters,
                    token: token,
                    requestName: "servicesType",
                },
                {
                    url: '/medical/time/',
                    objFilters: filters,
                    token: token,
                    requestName: "time"
                },
                {
                    url: '/medical/protocolConditions',
                    // objFilters: filters,
                    token: token,
                    requestName: "variables"
                },
                {
                    url: '/medical/offered-services',
                    objFilters: {
                        // page: 1,
                        // search: "",
                        // perpage: 10,
                        idAccount: idEnterprise
                    },
                    token: token,
                    requestName: "servicesToListSelect"
                },
            ],
            token: token,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (services?.data?.protocols?.length <= 0) {
            setServices({ ...services, data: { ...services.data, reviewRequired: 0 } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services.data?.protocols?.length])

    const formattedServicesType = formatToRcSelect(initialList?.servicesType?.results, "id", "name")
    const formattedOfferedServices = formatToRcSelect(initialList?.servicesToListSelect?.results, "srvId", "description")
    const formattedTime = formatToRcSelect(initialList?.time?.results, "id", "name", "value", "", "")
    const formattedVariables = formatToRcSelect(initialList?.variables?.results, "id", "name", "value", "", "")
    const formatData = (array) => {
        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                tempList.push(
                    < tr key={index} className={`hover-table-row`} >
                        <td  >{item.cupCode || "-"}</td>
                        <td className='text-start' >{item.name}</td>
                        <td className='text-start' >{item.serviceType || "-"}</td>
                        <td className='text-end' >
                            <img
                                className={"cursorPointer"}
                                alt={"Lupa"}
                                src={OrdFancyGlass}
                                onClick={() => setServices({ ...services, showDrawer: true, data: item })}
                            >
                            </img>
                            &nbsp;
                            &nbsp;
                        </td>
                    </tr >
                );
            });
        }
        return tempList;
    };
    const handleFilterServices = (e) => {
        setFilters({ ...filters, page: 1 })
        setTrigger(trigger + 1)
        e.preventDefault()
    }
    const handleDeleteParam = (e) => {
        let newArr = services.data?.protocols.filter((x) => x.attId !== e.attId);
        setServices({ ...services, data: { ...services.data, protocols: newArr } })
    }
    const handleAddParam = () => {
        let newItem = {
            attId: generateId(),
            conditions: [
                {
                    id: generateId(),
                }
            ],
            frequencyQty: "",
            srvConduct: "",
            // timeId: "",
            timId: "",

        }
        setServices({ ...services, data: { ...services.data, protocols: [...services.data.protocols, newItem] } })
    }
    const handleAddVar = (i) => {
        let newItem = {
            id: generateId(),
        }
        services.data?.protocols[i]?.conditions?.push(newItem)
    }
    const handleDeleteVar = (e, i) => {
        let newArr = services.data?.protocols[i]?.conditions?.filter((x) => x.id !== e.id);
        setServices({ ...services, data: { ...services.data, protocols: [...services.data.protocols.slice(0, i), { ...services.data.protocols[i], conditions: newArr }, ...services.data.protocols.slice(i + 1)] } })
    }
    const handleSubmit = () => {
        updateMedicalServ({
            url: '/medical/offered-services/param/',
            method: 'PUT',
            token: token,
            body: {
                srvId: String(services?.data?.srvId),
                reviewRequired: services?.data?.reviewRequired,
                medicalAuthRequired: services?.data?.reqAuth,
                optimalTimeId: services?.data?.optimalTimeId,
                optimalTimeQty: services?.data?.optimalTimeQty,
                conductTimeId: services?.data?.srvConductTimeId,
                conductTimeQty: services?.data?.srvConductTimeQty,
                // conductTimeQty: services?.data?.srvConductTimeQty,
                protocols: services?.data?.protocols,
            },
            doAfterSuccess: (result) => {
                getInitialState()
                setTrigger(trigger + 1)
            }
        }
        )
    }

    const handleChangeVariable = (currentElem, param, paramIndex, variable, condIndex) => {
        let newVal = { ...currentElem, id: currentElem.value }
        let tempState = [...services.data.protocols]
        tempState[paramIndex].conditions[condIndex] = newVal
        setServices({ ...services, data: { ...services.data, protocols: tempState } })

    }
    const handleChangeSrvConduct = (currentElem, paramIndex, srvId) => {
        let tempState = [...services.data.protocols]
        tempState[paramIndex].srvConduct = srvId
        setServices({ ...services, data: { ...services.data, protocols: tempState } })
    }
    const handleChangeSrvTimeFrequency = (currentElem, paramIndex, srvId) => {
        let tempState = [...services.data.protocols]
        tempState[paramIndex].frequencyQty = srvId
        setServices({ ...services, data: { ...services.data, protocols: tempState } })
    }
    const handleChangeSrvTimeString = (currentElem, paramIndex, srvId) => {
        let tempState = [...services.data.protocols]
        tempState[paramIndex].timId = srvId
        setServices({ ...services, data: { ...services.data, protocols: tempState } })
    }

    return (
        <>
            {/* /* ----------------------------- LOADER SECTION ----------------------------- */}
            {medicalServicesListLoader && loader}
            {initialListLoader && loader}
            {updateMedicalServLoader && loader}
            <Drawer
                anchor={"right"}
                open={services.showDrawer}
                onClose={() => getInitialState()}
                style={{ width: "50%" }}
            >
                <div
                    style={{ width: "38rem" }}
                >
                    {/* /* --------------------------------- Header --------------------------------- */}
                    <div
                        className={`d-flex justify-content-between`}
                    // style={{ width: '20rem' }}
                    >
                        <Col className="border-bottom text-secondary text-center cursorPointer" xs={1}
                            style={{ padding: "2%" }}
                            onClick={() => setServices({ ...services, showDrawer: false })}>
                            <b>
                                {/* ☠ */}
                                ✖
                            </b>
                        </Col>
                        <Col className="border-bottom p-2" xs={11}>
                            <h3 className="ordGrayText">
                                <b>
                                    Detalle del servicio
                                </b>
                            </h3>
                        </Col>
                    </div>
                    {/* /* --------------------------------- Content -------------------------------- */}
                    <div className={`d-flex justify-content-center`}
                        style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                        <Col className="pt-5 mt-2" xs={12}><Form.Group className="mb-3" controlId="fName">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                <b className="text-start"> &nbsp; Nombre del servicio</b>
                            </Form.Label>
                            <Form.Control
                                className={`ord-roundInput`}
                                type="text"
                                value={services?.data?.description}
                                disabled
                                placeholder="Escribir..."
                            />
                        </Form.Group></Col>
                    </div>
                    <div
                        className={`d-flex justify-content-center`}
                        style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                        <Col className="pt-2" xs={12}><Form.Group className="mb-3" controlId="fName">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                <b className="text-start"> &nbsp; Tipo</b>
                            </Form.Label>
                            <Form.Control
                                className={`ord-roundInput`}
                                type="text"
                                value={services?.data?.serviceType}
                                disabled
                                placeholder="Escribir..."
                            />
                        </Form.Group></Col>
                    </div>
                    <div
                        className={`d-flex justify-content-between pt-2 ${tableStyles.pl10Pr10} `} >
                        <div className="">
                            <input
                                className="form-check-input styleCheckInputRatio mr-2 cursorPointer"
                                style={{ border: '2px solid #1a538d' }}
                                onChange={(e) => setServices({ ...services, data: { ...services.data, reqAuth: services.data.reqAuth === 1 ? 0 : 1 } })}
                                checked={services?.data?.reqAuth}
                                type='checkbox'
                                id="checkReqAuth"
                            >
                            </input>
                        </div>
                        <Col className="" xs={12}>
                            &nbsp;
                            <label htmlFor="checkReqAuth" className="text-secondary cursorPointer"><b>Requiere autorización por parte de dirección médica</b></label>
                        </Col>
                    </div>
                    <div
                        className={`d-flex justify-content-between pt-2`}
                        style={{ paddingLeft: '10%', paddingRight: '10%' }}
                    >
                        <div className="">
                            <input
                                className="form-check-input styleCheckInputRatio mr-2 cursorPointer"
                                style={{ border: '2px solid #1a538d' }}
                                onChange={(e) => setServices({ ...services, data: { ...services.data, reviewRequired: services.data.reviewRequired === 1 ? 0 : 1 } })}
                                type='checkbox'
                                checked={services.data.reviewRequired}
                                id="checkboxRemision"
                            >
                            </input>
                        </div>
                        <Col className="" xs={12}>
                            &nbsp;
                            <label htmlFor="checkboxRemision" className="text-secondary cursorPointer"><b>Está sujeto al protocolo de ordenamiento  y remisión</b></label>
                        </Col>
                    </div>
                    {/* /* ----------------------------- Service params ----------------------------- */}
                    {services.data.reviewRequired === 1 && <>
                        <div>
                            <div
                                className={`d-flex justify-content-between pt-2 ${tableStyles.pl10Pr10} `}
                            >
                                <p className="pt-2 m-0"><span className={`${tableStyles.ordSubTitleStyle}`} >Parámetros de servicio</span></p>
                            </div>
                            {/* /* ------------------------------- Row Params ------------------------------- */}
                            {services?.data?.protocols?.length > 0 &&
                                services?.data?.protocols?.map((e, i) => {
                                    return (
                                        <>
                                            {/* /* -------------------------- This is the iterable PARAMS div ------------------------- */}
                                            <div
                                                key={i}
                                                className={`px-3 mb-2 d-flex justify-content-between row ${tableStyles.ordSearchSections}`}
                                                style={{ width: '80%', marginLeft: '10%' }}
                                            >
                                                {/* /* ------------------------------- Close icon ------------------------------- */}
                                                <Row>
                                                    <Col className=" pt-3 ml-3 w-100 d-flex justify-content-end p-0" xs={12}>
                                                        <div
                                                            className={`d-flex justify-content-end cursorPointer`}
                                                        >
                                                            <img
                                                                onClick={() => handleDeleteParam(e)}
                                                                src={close} alt="Cerrar" style={{ width: '10px' }} ></img>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* /* ------------------------------- Variable Select ------------------------------- */}
                                                <Row>{
                                                    e?.conditions?.length > 0 && e?.conditions.map((x, index) => {
                                                        return (<Col className="d-flex justify-content-between" xs={9}>
                                                            <Form.Group key={index} className="mb-3 text-start w-100" controlId="fName">
                                                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                                                    <b className="text-start"> &nbsp;Variable</b>
                                                                </Form.Label>
                                                                <div className="d-flex">
                                                                    <Select noOptionsMessage={() => 'No hay datos'}
                                                                        onChange={(elem) => handleChangeVariable(elem, e, i, x, index)}
                                                                        value={formattedVariables.find(y => y.value === x.id)}
                                                                        options={formattedVariables}
                                                                        className="text-secondary w-100"
                                                                        placeholder={"Seleccionar..."}
                                                                        styles={ordCustomSelect}
                                                                    />
                                                                    <img
                                                                        className="cursorPointer mx-2"
                                                                        onClick={() => handleDeleteVar(x, i)}
                                                                        src={close} alt="CerrarIn" style={{ width: '10px' }} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>)

                                                    })
                                                }
                                                </Row>
                                                <div
                                                    className={`d-flex justify-content-between pt-2 ${tableStyles.pl10Pr10} `}
                                                >
                                                    <Col className="" xs={12}>
                                                        <ButtonAddClearBlueHover
                                                            title={<b>Agregar variable</b>}
                                                            size={6}
                                                            className={`d-flex flex-row-reverse ${tableStyles.f14}`}
                                                            style={{ marginLeft: "-75px" }}
                                                            onClick={() => handleAddVar(i)}
                                                        />
                                                    </Col>
                                                </div>
                                                <>
                                                </>

                                                {/* ---------------------------- Conduct and freq ---------------------------- */}

                                                <Row className="mb-3">
                                                    <div className={`d-flex justify-content-between text-start pt-2`}>
                                                        <p className="pt-2 m-0"><span className={`${tableStyles.ordSubTitleStyle}`} >Conducta y frecuencia</span></p>
                                                    </div>
                                                    <Col className="" xs={5}>

                                                        <Select noOptionsMessage={() => 'No hay datos'}
                                                            options={formattedOfferedServices}
                                                            className="text-secondary text-start"
                                                            placeholder={"Seleccionar..."}
                                                            styles={ordCustomSelect}
                                                            value={formattedOfferedServices.find(y => y.value === e.srvConduct)}
                                                            // styles={{...ordCustomSelect, menuPortal: base => ({ ...base, zIndex: 999999 }) }}
                                                            onChange={(event) => handleChangeSrvConduct(e, i, event.value)}
                                                        ></Select>
                                                    </Col>
                                                    <Col className="" xs={3}>
                                                        <Form.Control
                                                            // onChange={(e) => setServices({ ...services, data: { ...services.data, optQty: e.target.value } })}
                                                            onChange={(event) => handleChangeSrvTimeFrequency(e, i, event.target.value)}
                                                            className={`ord-roundInput`}
                                                            type="number"
                                                            value={e?.frequencyQty}
                                                            placeholder="Escribe..."
                                                        />
                                                    </Col>
                                                    <Col className="" xs={4}>
                                                        <Select noOptionsMessage={() => 'No hay datos'}
                                                            // onChange={(e) => setServices({ ...services, data: { ...services.data, optimalTimeId: e } })}
                                                            // value={formattedTime.find(x => x.value === services?.data?.optimalTimeId)}
                                                            // onChange={(e) => setServices({ ...services, data: { ...services.data, optimalTimeId: e.value } })}
                                                            onChange={(event) => handleChangeSrvTimeString(e, i, event.value)}
                                                            options={formattedTime}
                                                            className="text-secondary text-start"
                                                            placeholder={"Seleccionar..."}
                                                            value={formattedTime.find(y => y.value === e.timId)}
                                                            styles={ordCustomSelect}
                                                        ></Select>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div
                            className={`
                            d-flex justify-content-between1 
                            pt-2 ${tableStyles.pl10Pr10} `}
                        >
                            <Col className="pt-2" xs={12}>
                                <ButtonAddClearBlueHover
                                    title={<b>Agregar parámetro</b>}
                                    size={4}
                                    className={`d-flex flex-row-reverse ${tableStyles.f14}`}
                                    style={{ marginLeft: "-11px" }}
                                    onClick={() => handleAddParam()}
                                />
                            </Col>
                        </div>
                    </>
                    }

                    <div
                        className={`d-flex justify-content-between pt-2`}
                        style={{ paddingLeft: '10%', paddingRight: '10%' }}
                    >
                        <p className="pt-2 m-0"><span className={`${tableStyles.ordSubTitleStyle}`} >Tiempo óptimo</span></p>
                    </div>
                    <div
                        className={`d-flex justify-content-between`}
                        style={{ paddingLeft: '10%', paddingRight: '10%' }}
                    >
                        <Col className="pt-2" xs={6}>
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start"> &nbsp;Período</b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    value={formattedTime.find(x => x.value === services?.data?.optimalTimeId)}
                                    onChange={(e) => setServices({ ...services, data: { ...services.data, optimalTimeId: e.value } })}
                                    options={formattedTime}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                ></Select>
                            </Form.Group>
                        </Col>
                        &nbsp;&nbsp;
                        <Col className="pt-2" xs={6}>
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start"> &nbsp;Cantidad</b>
                                </Form.Label>
                                <Form.Control
                                    onChange={(e) => setServices({ ...services, data: { ...services.data, optimalTimeQty: e.target.value } })}
                                    className={`ord-roundInput`}
                                    value={services?.data?.optimalTimeQty}
                                    placeholder="Escribir..."
                                />
                            </Form.Group>
                        </Col>
                    </div>
                    {/* {
                        services.data?.reviewRequired === 1 &&
                        <>
                            <div
                                className={`d-flex justify-content-between`}
                                style={{ paddingLeft: '10%', paddingRight: '10%' }}
                            >
                                <p className="pt-2 m-0"><span className={`${tableStyles.ordSubTitleStyle}`} >Frecuencia y conducta</span></p>
                            </div> */}

                    {/* /* --------------------------------- Second --------------------------------- */}
                    {/* <div
                                className={`d-flex justify-content-between`}
                                style={{ paddingLeft: '10%', paddingRight: '10%' }}
                            >
                                <Col className="pt-2" xs={6}>
                                    <Form.Group className="mb-3" controlId="fName">
                                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                            <b className="text-start"> &nbsp;Período</b>
                                        </Form.Label>
                                        <Select noOptionsMessage={() => 'No hay datos'}
                                            onChange={(e) => setServices({ ...services, data: { ...services.data, srvConductTimeId: e.value } })}
                                            // eslint-disable-next-line eqeqeq
                                            value={formattedTime.find(x => x.value == services?.data?.srvConductTimeId)}
                                            options={formattedTime}
                                            className="text-secondary"
                                            placeholder={"Seleccionar..."}
                                            styles={ordCustomSelect}
                                        ></Select>
                                    </Form.Group>
                                </Col>
                                &nbsp;&nbsp;
                                <Col className="pt-2" xs={6}>
                                    <Form.Group className="mb-3" controlId="fName">
                                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                            <b className="text-start"> &nbsp;Cantidad</b>
                                        </Form.Label>
                                        <Form.Control
                                            onChange={(e) => setServices({ ...services, data: { ...services.data, srvConductTimeQty: e.target.value } })}
                                            value={services?.data?.srvConductTimeQty}
                                            className={`ord-roundInput`}
                                            type="text"
                                            placeholder="Escribir..."
                                        />
                                    </Form.Group>
                                </Col>
                            </div>
                        </>
                    } */}
                    <div
                        className={`d-flex justify-content-between`}
                        style={{ paddingLeft: '10%', paddingRight: '10%' }}
                    >
                        <Col xs={12} className="d-flex justify-content-end pt-3 mb-3">
                            <button
                                onClick={() => getInitialState()}
                                className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                            <button
                                onClick={() => handleSubmit()}
                                className={`${tableStyles.ordBtnPrimary} btn`}>
                                Guardar
                            </button>
                        </Col>
                    </div>
                </div>
            </Drawer>

            <OrdGenericTemplate
                className=""
                title={"Servicios médicos"}>
                <div className="mb-5 w-90">
                    <Row>
                        <Col xs={3}>
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start"> &nbsp;Tipo</b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={formattedServicesType}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    onChange={(e) => setFilters({ ...filters, typeId: e.value })}
                                ></Select>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <form onSubmit={(e) => handleFilterServices(e)}>
                                <Form.Group className="mb-3 text-start" controlId="search"
                                >
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                    </Form.Label>
                                    <div
                                        className={`d-flex ${tableStyles.ordGrayInputSearch}`}
                                    >
                                        <Form.Control
                                            className={`ord-roundInput-search`}
                                            type="text"
                                            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                            placeholder="Escribe aquí para buscar" />
                                        <img
                                            alt="buscar"
                                            src={ordBlueSearch}
                                        >
                                        </img>
                                    </div>
                                </Form.Group>
                            </form>
                        </Col>
                        <Col xs={3}
                            className="d-flex justify-content-end"
                        >
                            <Col xs={8}>
                                <div
                                    className="d-flex justify-content-end align-items-center ordGearBlueHover"
                                    style={{ marginTop: "15px" }}
                                    onClick={() => history.push('/asistencial/tmHistoriaClinica/serviciosMedicos/parametrizacion')}
                                >
                                    <label htmlFor="parametrization" className="ordGrayText fw-bold text-center align-middle">
                                        Parametrización
                                    </label>
                                    <button
                                        name="parametrization"
                                        className="ordAddCheckButtonGearBlue mx-2"
                                    />
                                </div>
                            </Col>
                        </Col>
                    </Row>

                    <OrdTable
                        className={`${tableStyles.shade}`}
                        headers={services.headers}
                        hasChildren={true}
                        paginate={{
                            activePage: filters.page,
                            totalPages: medicalServicesList?.rowTotal,
                            perPage: filters.perpage,
                            pageRangeDisplayed: 3,
                            onChangePage: async (e) => {
                                const result = await getmedicalServicesList({
                                    url: '/medical/offered-services',
                                    objFilters: { ...filters, page: e },
                                    token: token,
                                })
                                setFilters({ ...filters, page: e })
                                formatData(result?.results)
                            },
                            showTextDetails: true
                        }}
                    >
                        {formatData(medicalServicesList?.results)}
                    </OrdTable>

                </div>
            </OrdGenericTemplate>
        </>
    )
}
