/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { URL_GATEWAY, API_VERSION } from "../../../../helpers/constants";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import {
  customSelectNew,
  customSelectNewDark,
} from "../../../Layouts/react-select-custom";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import AdjuntarOscuro from "../../../../assets/img/icons/AdjuntarOscuro.svg";
import { isEmptyOrUndefined } from "../../../../helpers/helpers";
import { customSwaltAlert } from "../../../../helpers/customSwaltAlert";
import { useGetMethod } from "../../../../Hooks/useFetch";

function InhabilityRegister() {
  const store = useSelector((state) => state);
  const animatedComponents = makeAnimated();
  const token = store.loginReducer.Authorization;
  const user_gender = store?.loginReducer?.user_data?.gender;
  const [trigger, setTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { results: diagnostics, trigger: getDiagnostics } = useGetMethod();
  const [foundedDiagnostics, setFoundedDiagnostics] = useState([
    {
      value: "",
      label: "Escriba el código del diagnóstico...",
      isDisabled: true,
    },
  ]);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    idAccount: store.loginReducer.currentAccount.id,
  });

  const [formData, setFormData] = useState({
    status: "pending",
    job_position: store?.loginReducer?.user_data?.payrollInfo?.id_jobPosition,
    type: null,
    start_date: null,
    end_date: null,
    diagnosis: null,
    support_file: null,
    filename: "",
    file_base64: "",
    employee: store?.loginReducer?.user_data?.payrollInfo?.id_candidate,
    contract: store?.loginReducer?.user_data?.payrollInfo?.id_contract,
    created_by: store.loginReducer.user_data?.id,
    entity_account: store.loginReducer.currentAccount.id,
  });

  const sendPost = async (data) => {
    setIsLoading(true);
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/inhability/`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    let result = await query.json();
    return result;
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChangeDoc = async (e) => {
    const file = e.target.files?.[0];

    if (file) {
      let encode = await convertBase64(file);
      let file_base64 = encode.split("base64,")[1];
      setFormData({
        ...formData,
        file_base64: file_base64,
        filename: file.name,
      });
    }
  };

  const optionTypes = [
    {label: 'Seleccionar...', value:''},
    { label: 'General', value: 'regular' },
    { label: 'Laboral', value: 'laboral' },
    ...(
        user_gender === 'male'
        ? [{ label: 'Licencia de paternidad', value: 'paternity_leave' }]
        : user_gender === 'female'
            ? [{ label: 'Licencia de maternidad', value: 'maternity_leave' }]
            : []
    )
  ];



  const uploadInhability = async () => {
    let error = "";
    let daylenght = 1;
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    if (!formData.job_position) {
      error = "Debe seleccionar un Cargo";
    }

    if (!formData.type) {
      error = "Debe seleccionar un tipo de incapacidad";
    }
    if (isEmptyOrUndefined(formData.diagnosis)) {
      error = "Debe escribir un diagnostico";
    }
    if (formData.file_base64 === "") {
      error = "Debe subir un archivo de incapacidad";
    }
    if (!formData.start_date) {
      error = "Debe seleccionar una fecha inicial";
    }
    if (!formData.end_date) {
      error = "Debe seleccionar una fecha final";
    }
    if (formData.start_date && formData.end_date) {
      const sDate = new Date(formData.start_date);
      const eDate = new Date(formData.end_date);
      if (eDate < sDate) {
        error =
          "La fecha de finalización debe ser mayor o igual a la fecha de inicio";
      } else {
        daylenght = Math.round(
          Math.abs((eDate.getTime() - sDate.getTime()) / oneDay)
        );
      }
    }

    if (error !== "") {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: error,
        showCancelButton: false,
      });
      return null;
    }

    let snd = await sendPost({ ...formData, days_counted: daylenght });
    if (snd.success) {
      setFormData({
        status: "pending",
        job_position:
          store?.loginReducer?.user_data?.payrollInfo?.id_jobPosition,
        type: null,
        start_date: null,
        end_date: null,
        diagnosis: "",
        support_file: null,
        filename: "",
        file_base64: "",
        employee: store?.loginReducer?.user_data?.payrollInfo?.id_candidate,
        contract: store?.loginReducer?.user_data?.payrollInfo?.id_contract,
        created_by: store.loginReducer.user_data?.id,
        entity_account: store.loginReducer.currentAccount.id,
      });
      setIsLoading(false);
      setTrigger(trigger + 1);
      customSwaltAlert({
        icon: "success",
        title: "Datos Guardados",
        text: "Incapacidad registrada",
        showCancelButton: false,
      });
    } else {
      setIsLoading(false);
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: snd.message,
        showCancelButton: false,
      });
    }
    setTrigger(trigger + 1);
  };

  useEffect(() => {
    getDiagnostics({
      url: "/medical/cie10diagnostics",
      objFilters: { slim: 1, enabled: 1, page: 1, perpage: 10 },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const handleFilter = (e) => {
    if (isEmptyOrUndefined(e)) {
      setFoundedDiagnostics([
        {
          value: "",
          label: "Escriba el código del diagnóstico...",
          isDisabled: true,
        },
      ]);
      return;
    }

    if (e?.length > 0) {
      let expresion = new RegExp(`${e?.toUpperCase()}.*`, "i");
      let nombreEncontrados = diagnostics?.results?.filter((dat) =>
        expresion.test(dat.codigo)
      );
      if (nombreEncontrados?.length > 0) {
        let formattedOptions = nombreEncontrados.map((diagnosis) => ({
          value: diagnosis.codigo,
          label: `${diagnosis?.codigo} - ${diagnosis?.descripcion}`,
        }));
        setFoundedDiagnostics([
          {
            value: "",
            label: "Escriba el código del diagnóstico...",
            isDisabled: true,
          },
          ...formattedOptions,
        ]);
      } else {
        setFoundedDiagnostics([
          {
            value: "",
            label: "No se encontraron diagnósticos",
            isDisabled: true,
          },
        ]);
      }
    }
  };

  return (
    <div className="simpleTabs__wrap">
      {isLoading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div className={` col-6 `}>
        <h4
          className="simpleTabs__thirdTitle"
          style={{ marginTop: 24, marginBottom: 7 }}
        >
          Registro de incapacidad
        </h4>
        <div className={tableStyles.genericGrayContainer}>
          <div className="d-flex flex-column" style={{ flex: 1 }}>
            <p className={tableStyles.crudModalLabel}>
              Tipo de incapacidad
              <span className="text-danger">*</span>
            </p>
            <Select
              noOptionsMessage={() => "No hay datos"}
              options={optionTypes}
              value={optionTypes?.find(obj => obj.value === formData.type)}
              onChange={e => setFormData({ ...formData, type: e.value })}
              key={trigger + 'inhType'}
              placeholder={'Seleccionar...'}
              styles={customSelectNew}
            />
          </div>

          <div className={`d-flex`}>
            <div className="d-flex flex-column  mr-2" style={{ flex: 1 }}>
              <p className={tableStyles.crudModalLabel}>
                Fecha inicial
                <span className="text-danger">*</span>
              </p>
              <input
                type="date"
                className="register-inputs"
                value={formData.start_date}
                key={trigger + "startDate"}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    start_date: e.target.value,
                  })
                }
              />
            </div>
            <div className="d-flex flex-column" style={{ flex: 1 }}>
              <p className={tableStyles.crudModalLabel}>
                Fecha final
                <span className="text-danger">*</span>
              </p>
              <input
                type="date"
                className="register-inputs"
                value={formData.end_date}
                key={trigger + "endDate"}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    end_date: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="d-flex flex-column" style={{ flex: 1 }}>
            <p className={tableStyles.crudModalLabel}>
              Diagnóstico
              <span className="text-danger">*</span>
            </p>

            <Select
              noOptionsMessage={() => "No hay datos"}
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={foundedDiagnostics.find(
                (option) => option.value === formData.diagnosis
              )}
              onInputChange={(e) => {
                handleFilter(e);
              }}
              onChange={(e) => {
                setFilters({ ...filters, idCIE: e?.value });
                setFormData({
                  ...formData,
                  diagnosis: e?.label,
                });
              }}
              options={foundedDiagnostics}
              className="text-secondary"
              placeholder={"Seleccionar..."}
              styles={customSelectNewDark}
            ></Select>
          </div>

          <div className="d-flex flex-column" style={{ flex: 1 }}>
            <p className={tableStyles.crudModalLabel}>
              Incapacidad
              <span className="text-danger">*</span>
            </p>
            <label htmlFor="fileupper" className="register-inputs d-flex">
              <p className="m-0" style={{ flex: 1 }}>
                {formData.filename}
              </p>
              <img src={AdjuntarOscuro} style={{ alignSelf: "end" }} alt="" />
            </label>

            <input
              id="fileupper"
              name="fileupper"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeDoc}
            />
          </div>
        </div>

        <div className="d-flex flex-row-reverse " style={{ marginTop: 16 }}>
          <button
            className={tableStyles.btnPrimary}
            onClick={() => uploadInhability()}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
}
export default InhabilityRegister;
