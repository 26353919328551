// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stylesOrders_w120px__2TwYG {\n  width: 120px !important;\n}\n\n.stylesOrders_w130px__30oNv {\n  width: 130px !important;\n}\n\n.stylesOrders_w160px__Ph-EI {\n  width: 160px !important;\n}", "",{"version":3,"sources":["webpack://src/components/Containers/admision/orders/stylesOrders.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AACA;EACE,uBAAA;AAEF","sourcesContent":[".w120px{\n  width: 120px !important;\n}\n\n.w130px{\n  width: 130px !important;\n}\n.w160px{\n  width: 160px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"w120px": "stylesOrders_w120px__2TwYG",
	"w130px": "stylesOrders_w130px__30oNv",
	"w160px": "stylesOrders_w160px__Ph-EI"
};
export default ___CSS_LOADER_EXPORT___;
