import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
const URL_MEDICAL = process.env.REACT_APP_URL_PROTOCOL + process.env.REACT_APP_URL_BASE + process.env.REACT_APP_URL_MEDICAL;
const API_VERSION = process.env.REACT_APP_API_VERSION;

const PatientReceived = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(sendRquest())
    }, [])
    const  sendRquest = () => (dispatch) => {
        fetch(`${URL_MEDICAL}${API_VERSION}/receivedAction/`+props.location.search, {
            method: "GET",
            body: JSON.stringify(),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(res => {
                window.close()
            })
            .catch((err) => {
                window.close()
            })
    }

    return (
        <>
        </>
    );
}
export default PatientReceived;