import React, { useEffect, useState } from "react";
import styles from "../../components/Layouts/tableStyle.module.scss";
import doc from "../../assets/img/icons/estetoscopicWhite.svg";
import location from "../../assets/img/icons/location.svg";
import search from "../../assets/img/icons/ordSearchClearBlue.svg";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatToRcSelect, loader, message, validateEmptyString } from "../../helpers/helpers";
import { useGetMethod } from "../../Hooks/useFetch";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import { AntSwitch } from "../../helpers/constants";
import threeDots from "../../assets/img/icons/threeDots.svg";
import imgIcon from "../../assets/img/icons/doctorsScheduleSetting.svg";
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import CustomPopupStyles from "../../components/Popup/customPopup.module.scss";
import NewSchedule from "./NewSchedule";
import { FormatColorReset } from "@material-ui/icons";
import CalendarPage from './../../components/Calendar/CalendarPage';

const ScheduleSettings = () => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  let history = useHistory();
  // const userId = store.loginReducer.user_data.id;
  const [filters, setFilters] = useState({
    idDoctor: "",
    idSite: "",
    idAccount: idAccount,
    search: "doc",
    showDoctorSchedule: false,
  });

  const [tab, setTab] = useState({
    page: "",
  });

  const [calendarOptionDate, setCalendarOptionDate] = useState('')
  const [objDoctor, setObjDoctor] = useState({})
  const [objSite, setObjSite] = useState({})
  const [filterSchedule, setFilterSchedule] = useState([])
  const [filterScheduleChange, setFilterScheduleChange] = useState('')


  const { results: doctorOptionsResults, load: doctorsOptionsLoader, trigger: getDoctorOptions } = useGetMethod();
  const doctorOptions = formatToRcSelect(doctorOptionsResults?.results, "mdId", "mdName", "", "", "");

  const { results: siteOptionsResults, load: siteOptionsLoader, trigger: getSiteOptions } = useGetMethod();
  const siteOptions = formatToRcSelect(siteOptionsResults?.results, "siteId", "site", "", "", "");

  useEffect(() => {
    getDoctorOptions({
      url: "/medical/generals/medical/",
      objFilters: {
        idAccount: idAccount,
      },
      token: token,
    });
    getSiteOptions({
      url: "/medical/generals/site/",
      objFilters: {
        eaccount: idAccount,
      },
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    const selectDoctor = doctorOptionsResults.results?.find(doctor => doctor.mdId === filters.idDoctor)

    if (filters.search === 'doc') {

      if (typeof selectDoctor !== 'undefined') {

        setObjSite(state => ({ ...state, idSite: '', site: '' }))
        setObjDoctor(state => ({ ...state, mdId: selectDoctor.mdId, mdName: selectDoctor.mdName, photo: selectDoctor.photo }))
      } else {
        setObjSite(state => ({ ...state, idSite: '', site: '' }))
        setObjDoctor(state => ({ ...state, mdId: '', mdName: '', photo: '' }))
      }
    }

    if (filters.search === "site") {

      const selectSite = siteOptionsResults.results?.find(site => site.siteId === filters.idSite)

      if (typeof selectSite !== 'undefined') {

        setObjDoctor(state => ({ ...state, mdId: '', mdName: '', photo: '' }))
        setObjSite(state => ({ ...state, idSite: selectSite.siteId, site: selectSite.site }))
      } else {
        setObjDoctor(state => ({ ...state, mdId: '', mdName: '', photo: '' }))
        setObjSite(state => ({ ...state, idSite: '', site: '' }))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])


  const searchDoctorSch = () => {
    let validation = filters.idDoctor === "" ? message("info", "Campo vacío", "Seleccione un médico") : false;
    // A GET METHOD HERE DOCTOR SCHEDULE
    if (validation === false) {
      setFilters({ ...filters, showDoctorSchedule: true })
    } else {
      setFilters({ ...filters, showDoctorSchedule: false })

    }

    setTab({ ...tab, page: "" })
  }




  const renderTooltipDoctor = () => <Tooltip> Por médico </Tooltip>;
  const renderTooltipLocation = () => <Tooltip> Por sede </Tooltip>;

  const [isEditingSchedule, setIsEditingSchedule] = useState({

  })

  return (
    <>
      {(doctorsOptionsLoader || siteOptionsLoader) && loader}
      <div className={styles.app_container_list_app}>
        <div className="my-3 " style={{ margin: "0px 30px 0px 30px" }}>
          <b className={`${styles.f32} ${styles.tlnTextDark}`}>Configuración de agendas</b>

          <div className="d-flex mt-5 mb-2 justify-content-between">
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipDoctor()}>
              <button
                className={`${filters.search === "doc" ? styles.appSchSettButtonActive : styles.appSchSettButton} py-2 d-flex justify-content-center`}
                style={{ width: "115px" }}
                onClick={() => {
                  setFilters({ ...filters, search: "doc", idSite: '' });
                  setTab({ ...tab, page: "" })
                }}
              >
                <img alt="doc" src={doc} height="20px"
                  className={`${filters.search === "doc" ? styles.svgWhiteFilter : styles.svgTurquoiseFilter} align-self-center  `}
                />
              </button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipLocation()}>
              <button
                className={`${filters.search === "site" ? styles.appSchSettButtonActive : styles.appSchSettButton} py-2 d-flex justify-content-center`}
                style={{ width: "115px" }}
                onClick={() => {
                  setFilters({ ...filters, search: "site", idDoctor: '' });

                  setTab({ ...tab, page: "" })
                }}
              >
                <img alt="location" src={location} height="20px"
                  className={`${filters.search === "site" ? styles.svgWhiteFilter : styles.svgTurquoiseFilter} align-self-center  `}
                />
              </button>
            </OverlayTrigger>
          </div>
          <div className={`${styles.app_back_search} d-flex w-100 mb-4`}>
            {filters.search === "doc" ? (
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <b className={`${styles.f14} ${styles.tlnTextPrimary}`}> &nbsp;Seleccionar médico</b>
                </Form.Label>
                <div className="d-flex w-100">
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={doctorOptions}
                    className="text-secondary w-100 "
                    placeholder={"Seleccione"}
                    value={doctorOptions.find((x) => x.value === filters.idDoctor)}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setFilters({ ...filters, idDoctor: e.value, showDoctorSchedule: false });
                      setTab({ ...tab, page: "" })
                    }}
                  ></Select>
                  <div className="pl-3">
                    <button
                      onClick={() => {
                        searchDoctorSch()
                      }}
                      className="d-flex"
                    >
                      <img alt="search" src={search} height="25px" style={{ height: "25px", width: "25px" }} />
                    </button>
                  </div>
                </div>
              </Form.Group>
            ) : (
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <b className={`${styles.f14} ${styles.tlnTextPrimary}`}> &nbsp;Seleccionar sede</b>
                </Form.Label>
                <div className="d-flex w-100">
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={siteOptions}
                    className="text-secondary w-100 "
                    placeholder={"Seleccione"}
                    value={siteOptions.find((x) => x.value === filters.idSite)}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setFilters({ ...filters, idSite: e.value });
                    }}
                  ></Select>
                  <div className="pl-3">
                    <button
                      onClick={() => {
                        setTab({ ...tab, page: "" })
                      }}
                      className="d-flex"
                    >
                      <img alt="search" src={search} height="25px" style={{ height: "25px", width: "25px" }} />
                    </button>
                  </div>
                </div>
              </Form.Group>
            )}
          </div>

          {filters.search && calendarOptionDate === 'week' && (objDoctor.mdId) && !isEditingSchedule.isEditing &&

            <div className={`${styles.appCardBlueDark}  p-2`}>

              <div className="p-2 d-flex">
                <b className={`${styles.f16} ${styles.tlnTextDark} w-100 pb-2 d-flex`} style={{ borderBottom: "1px solid #D6E0EB" }}>
                  Agendas
                </b>
              </div>


              {
                filterSchedule.map(schedule => (
                  <>
                    <div className="d-flex justify-content-between m-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <input

                            onChange={(event) => {
                              if (event.target.checked) {
                                setFilterScheduleChange(schedule.name)
                              } else {
                                setFilterScheduleChange('')
                              }
                            }}
                            type="checkbox"
                            name="handleTelemedicine"
                            className={`${styles.checkClearGray} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                            checked={filterScheduleChange === schedule.name}
                          />
                        </div>
                        <div>
                          <span className={`${styles.f14} ${styles.tlnTextGray} px-2`}>{schedule.name}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              }

              {
                !objSite?.idSite &&
                  <div>
                    {" "}
                    <button
                      className="addCheckButton mx-2 py-2"
                      style={{ margin: "0.5rem 0px 1rem 0px" }}
                      onClick={() => {
                        setTab({ ...tab, page: "newSchedule" });
                        // addService();
                      }}
                    />
                  </div>
              }

            </div>

          }

          {filters.search && calendarOptionDate === 'month' && (objDoctor.mdId) && !isEditingSchedule.isEditing &&

            <div className={`${styles.appCardBlueDark}  p-2`}>

              <div className="p-2 d-flex">
                <b className={`${styles.f16} ${styles.tlnTextDark} w-100 pb-2 d-flex`} style={{ borderBottom: "1px solid #D6E0EB" }}>
                  Agendas
                </b>
              </div>


              {
                filterSchedule.map(schedule => (
                  <>
                    <div className="d-flex justify-content-between m-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <input

                            onChange={(event) => {
                              if (event.target.checked) {
                                setFilterScheduleChange(schedule.name)
                              } else {
                                setFilterScheduleChange('')
                              }
                            }}
                            type="checkbox"
                            name="handleTelemedicine"
                            className={`${styles.checkClearGray} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                            checked={filterScheduleChange === schedule.name}
                          />
                        </div>
                        <div>
                          <span className={`${styles.f14} ${styles.tlnTextGray} px-2`}>{schedule.name}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              }

              {
                !objSite?.idSite &&
                  <div>
                    {" "}
                    <button
                      className="addCheckButton mx-2 py-2"
                      style={{ margin: "0.5rem 0px 1rem 0px" }}
                      onClick={() => {
                        setTab({ ...tab, page: "newSchedule" });
                        // addService();
                      }}
                    />
                  </div>
              }

            </div>

          }

          {filters.search && (objSite.idSite) &&

            <div className={`${styles.appCardBlueDark}  p-2`}>

              <div className="p-2 d-flex">
                <b className={`${styles.f16} ${styles.tlnTextDark} w-100 pb-2 d-flex`} style={{ borderBottom: "1px solid #D6E0EB" }}>
                  Agendas
                </b>
              </div>


              {
                filterSchedule.map(schedule => (
                  <>
                    <div className="d-flex justify-content-between m-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <input

                            onChange={(event) => {
                              if (event.target.checked) {
                                setFilterScheduleChange(schedule.name)
                              } else {
                                setFilterScheduleChange('')
                              }
                            }}
                            type="checkbox"
                            name="handleTelemedicine"
                            className={`${styles.checkClearGray} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                            checked={filterScheduleChange === schedule.name}
                          />
                        </div>
                        <div>
                          <span className={`${styles.f14} ${styles.tlnTextGray} px-2`}>{schedule.name}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              }
              {
                !objSite?.idSite && 
                  <div>
                    {" "}
                    <button
                      className="addCheckButton mx-2 py-2"
                      style={{ margin: "0.5rem 0px 1rem 0px" }}
                      onClick={() => {
                        setTab({ ...tab, page: "newSchedule" });
                        // addService();
                      }}
                    />
                  </div>
              }

            </div>

          }



        </div>
        <div className="d-flex align-self-end">
          <img alt="img" src={imgIcon} />
        </div>
      </div>
      <>
        <div style={{ width: "100%", height: "100%", backgroundColor: "#F6FEFF", overflow: "auto" }} >

          {tab.page === "newSchedule"
            ?
            <NewSchedule
              setTab={setTab}
              tab={tab}
              idDoctor={filters.idDoctor}
              isEditingSchedule={isEditingSchedule}
              setIsEditingSchedule={setIsEditingSchedule}
            />
            :

            <></>
          }

          {tab.page !== "newSchedule" &&
            <CalendarPage
              objDoctor={objDoctor}
              objSite={objSite}
              calendarOption={{ setCalendarOptionDate }}
              schedule={{ setFilterSchedule, filterScheduleChange }}
              filterScheduleChange={filterScheduleChange}
              setFilterScheduleChange={setFilterScheduleChange}
              isEditingSchedule={isEditingSchedule}
              setIsEditingSchedule={setIsEditingSchedule}
              setTab={setTab}
              tab={tab}
            />
          }

        </div>
      </>

    </>

  );
};

export default ScheduleSettings;
