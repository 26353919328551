import React, { useEffect, useState, useMemo} from "react";
import { useSelector, useDispatch } from "react-redux";
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import detailStyles from "./CurrentAuctionDetails.module.scss";
import PODstyles from "../TableApproveAuthorizations/PODetail.module.scss";

import GenericTableNew from "../Layouts/GenericTableNew";
import ModalNew from "../Layouts/ModalNew";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useHistory } from "react-router-dom";

import {
    creat_purchaseOrder,
    SumpurchaseOrder_get_one,
}from "../../actions/purchaseOrderActions";

import {
    current_auction_filter,
  } from "../../actions/IndividualAuctionActions";

import { 
    PURCHASEORDER_MODAL3,
    PURCHASEORDER_MODAL2,
    PURCHASE_ORDER_SUMMARY_GET_ONE,
    PURCHASEORDER_PRINCIPAL
} from "../../actions/actionTypes";
import { RiContactsBookLine } from "react-icons/ri";


var formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
});

function clamp (val, min, max) {
    return Math.min(Math.max(val, min), max);
};


function payConditions (key){
    switch(key){
        case "prepayment":
            return "Pago anticipado";
        case "delivery":
            return "Pago contraentrega";
        case "pay20":
            return "Pago a 20 días";
        case "pay30":
            return "Pago a 30 días";
        case "pay60":
            return "Pago a 60 días";
        case "pay90":
            return "Pago a 90 días";
        default:
            return "-";
    }
}

function DetailsModalPurchaseSummary (props){
    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    // const MySwal = withReactContent(Swal);
    // const today = new Date();
    const summary_purchaseOrder= counter.purchaseOrderReducer.summary_PO; 
    let history = useHistory();

    
    const [PurchaseOrderIndex, setPurchaseOrderIndex] = useState(0);

    useEffect(() => {
        if(!!counter.purchaseOrderReducer?.codes){
            dispatch(SumpurchaseOrder_get_one(counter.purchaseOrderReducer.codes[PurchaseOrderIndex]));
        }        
    },[PurchaseOrderIndex, counter.purchaseOrderReducer.modalPart3]);


    const renderHeadersSummary = [
        <th className="text-center" key={`indexSum1`}>Item</th>,
        <th className="text-center"  key={`indexSum2`}>Descripción de Artículo</th>,
        <th className="text-center"  key={`indexSum3`}>Cant.</th>,
        <th className="text-center"  key={`indexSum5`}>Valor Unidad</th>,
        <th className="text-center"  key={`indexSum6`}>Descuento <br />
            <div className="d-flex justify-content-around">
                <span>%</span>
                <span>$</span> 
            </div>
        </th>,
        <th className="text-center"  key={`indexSum7`}>Iva <br />
            <div className="d-flex justify-content-around">
                <span>%</span>
                <span>$</span>
            </div>
        </th>,
        <th className="text-center"  key={`indexSum8`}>Total</th>,
    ]
    

    const  [renderSummary, subtotal,iva, disc] = useMemo(()=>{
        let table =[];
        let subtotal=0;
        let iva=0;
        let disc=0;

        if (Array.isArray(summary_purchaseOrder.purchaseOrderItems)) {
            table = summary_purchaseOrder.purchaseOrderItems.map( (x, index) => {
                subtotal +=  x.unit_price*x.qty_article;
                iva +=  x.iva_value;
                disc += x.discount_value;
                return <tr key={"summary"+index} className={`hover-table-row`}>
                    <td className={`col-md-1`}>
                        <p className="text-center">
                            {index +1}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        <p className=" text-start">
                            {x.article_ref?.description}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        <p className="text-center">
                            {x.qty_article}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        <p className="text-center">
                            {formatter.format(x.unit_price)}
                        </p>
                    </td>
                    <td className={`col-md-1`}>
                        <div className="d-flex justify-content-around align-items-center">
                            <p className="text-center">
                                {x.discount}
                            </p>
                            <p name="kek" className="text-center">
                                {formatter.format(x.discount_value)}
                            </p>
                        </div>
                    </td>
                    <td className={`col-md-1`}>
                        <div className="d-flex justify-content-around align-items-center">
                            <p className="text-center">
                                {x.iva}
                            </p>
                            <p className="text-center">
                                {formatter.format(x.iva_value)}
                            </p>
                        </div>
                    </td>
                    <td className={`col-md-1`}>
                        <p className="text-center">
                            {formatter.format(x.unit_price*x.qty_article)}
                        </p>
                    </td>
                </tr>
            });
        }

        return [table, subtotal, iva, disc]
    },[summary_purchaseOrder.purchaseOrderItems]);


    let renderFooter = [
        <tr key={`footer1`}>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className={`${PODstyles.textFooter}`}>Subtotal</td>
          <td className={`${PODstyles.textFooter} text-center flex-wrap `}> { formatter.format(subtotal)}</td>
        </tr>,
        <tr key={`footer2`}>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className={`${PODstyles.textFooter}`}>IVA</td>
          <td className={`${PODstyles.textFooter} text-center`}> {formatter.format(iva)}</td>
        </tr>,
        <tr key={`footer3`}>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className={`${PODstyles.textFooter}`}>DESCUENTO</td>
          <td className={`${PODstyles.textFooter} text-center`}> { formatter.format(disc)}</td>
        </tr>,
        <tr key={`footer4`}>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className="invisible"></td>
          <td className={`${PODstyles.textFooter}`}>TOTAL</td>
          <td className={`${PODstyles.textFooter} text-center`}> { formatter.format((subtotal+iva-disc))}</td>
        </tr>,
      ];
    
    const [loading, setLoading] = useState(true);


    const btnCancelSummary =() => {
        dispatch({
            type: PURCHASEORDER_MODAL3,
            show:false
        });
        dispatch({
            type: PURCHASEORDER_MODAL2,
            show:false
        });
        dispatch({
            type: PURCHASEORDER_PRINCIPAL,
            show:false
        });
        dispatch( 
            current_auction_filter({page: 1,perpage: 10, eaccount: counter.loginReducer.currentAccount.id}),
        )
        // ()=> props.setfirstLayout({show:false})

    };

    {/* //////////////////////modal //////////////////////////////// */}
    return <ModalNew

    title="Orden de compra"
    subtitle={ (PurchaseOrderIndex+1) +" de " + counter.purchaseOrderReducer.codes?.length}
    show={counter.purchaseOrderReducer.modalPart3}
    btnNoName= {"Aceptar"}
    btnNoEvent={()=> btnCancelSummary()}
    size="940"
    onHide={()=> btnCancelSummary()}
    // onHide={()=>dispatch({
    //     type: PURCHASEORDER_MODAL3,
    //     payload:false
    // })}
    leftArrowEvent={()=>setPurchaseOrderIndex( clamp(PurchaseOrderIndex-1, 0, counter.purchaseOrderReducer.codes.length-1) )}
    rightArrowEvent={()=>setPurchaseOrderIndex( clamp(PurchaseOrderIndex+1, 0,  counter.purchaseOrderReducer.codes.length-1) )}
>
    <div className={PODstyles.container2}>
        {/* row 1 */}
        <div className={`d-flex`}>
            
            <div  className={`${PODstyles.inputMargin} ${PODstyles.Item} ${PODstyles.f15}`}>
                <p className={PODstyles.crudModalLabel}>Proveedor</p>
                <input
                    className={`${PODstyles.registerInputs}`}
                    type="text"
                    value={summary_purchaseOrder.provider_ref?.name}
                    readOnly 
                />
            </div>

            <div  className={`${PODstyles.inputMargin} ${PODstyles.Item} ${PODstyles.f1}`}>
                <p className={PODstyles.crudModalLabel}>NIT</p>
                <input
                    className={`${PODstyles.registerInputs}`}
                    type="text"
                    value={summary_purchaseOrder.provider_ref?.nit}
                    readOnly
                />
            </div>

            <div  className={`${PODstyles.Item} ${PODstyles.f1}`}>
                <p className={PODstyles.crudModalLabel}>Condiciones de pago</p>
                <input
                    className={`${PODstyles.registerInputs}`}
                    type="text"
                    value={payConditions(summary_purchaseOrder?.pay_conditions)}
                    readOnly
                />
            </div>
        </div>


        {/* row 2 */}
        <div className={`d-flex`}>

            <div  className={`${PODstyles.inputMargin} ${PODstyles.Item} ${PODstyles.f15}`}>
                <p className={PODstyles.crudModalLabel}>Términos y condiciones</p>
                <input
                    className={`${PODstyles.registerInputs}`}
                    type="text"
                    value={summary_purchaseOrder?.observations}
                    readOnly
                    
                />
            </div>

            <div  className={`${PODstyles.inputMargin} ${PODstyles.Item} ${PODstyles.f1}`}>
                <p className={PODstyles.crudModalLabel}>Dirección de entrega</p>
                <input
                    className={`${PODstyles.registerInputs}`}
                    type="text"
                    value={summary_purchaseOrder.warehouse_ref?.address}
                    readOnly
                />
            </div>

            <div  className={`${PODstyles.ItemCheckbox} ${PODstyles.f1}`}>
                <div className={PODstyles.myCheckbox}>
                    <input 
                        key={"delivery"+Math.random()}
                        className={PODstyles.myCheckboxInput} 
                        type="checkbox" 
                        name="delivery" 
                        value="delivery" 
                        checked={summary_purchaseOrder?.partial_deliveries}
                    />
                    <label className={`${PODstyles.myCheckboxLabel} ${PODstyles.delivery}`} htmlFor="delivery">Contiene entregas parciales</label>
                </div>
            </div>
        </div>
    </div>
    <GenericTableNew headers={renderHeadersSummary}  dark={false} lowerCase={true} footer={renderFooter} >
          {renderSummary}
    </GenericTableNew>
        <div className={PODstyles.containerObservations}>
            <div className="d-flex w-100">
                <div className= {`${PODstyles.observationsColor} ${PODstyles.sizeObservations}  d-flex flex-column`}>
                    <label htmlFor="observations" className={`${PODstyles.labelFont} ${PODstyles.marginLeftObservations}`}>
                        Observaciones
                    </label>
                    <textarea
                        name="observations"
                        className={`${PODstyles.observationsArea} ${PODstyles.marginLeftObservations}`}
                        value={summary_purchaseOrder?.observations}
                        disabled={true}
                    ></textarea>
                </div>
            </div>
        </div>
</ModalNew> 
    
}

export default DetailsModalPurchaseSummary;