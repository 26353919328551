import React from "react";
import tableStyles from "./ordComponentStyles.module.scss";
import Pagination from "react-js-pagination";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function OrdTable(props) {
  
  return (
    <>
      <table
        className={`
            ${props?.isAnAccTable ? "fold-table" : "table"}
          ${props?.lowerCase
            ? tableStyles.genericTableLower
            : tableStyles.genericTable
          }
          ${props?.hoverYellow
            ? tableStyles.genericTableAdmission
            : ''
          }
          
          ${props?.oneHeadDarkBlue ? tableStyles.oneHeadDarkBlue : ""}
          ${props?.treeHeadDarkBlue ? tableStyles.treeHeadDarkBlue : ""}
          ${props?.className ? props.className : ""}
          ${props?.shadow ? tableStyles.shade : ""}
          ${props.lastBorderNone ? tableStyles.xxx : ''}
          ${props.border ? tableStyles.borderTable : ''}
          ${props.dark ? tableStyles.theadDark2 : ''}
          ${!props.dark ? tableStyles.hoverLight : ''}
          `}
        style={props?.style}
      >
        <thead
          className={`${tableStyles.thead} ${props.clearHeader
            ? ""
            : props.dark
              ? tableStyles.theadDark
              : tableStyles.theadLight
            } ${props.theadClassName ? props.theadClassName : ''}`}
        >
          {" "}
          <tr className={`${props.accordion && props.tableStyleAccordionHeader}`}  style={{ alignItems: 'end' }}>
            {props?.headers?.length > 0 &&
              props?.headers.map((e, i) => {
                return (
                  <th className={e.className} key={`header${i}`}>
                    {e.title}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody
          style={props.accordion && { display: 'table-caption', boxShadow: '0px 0px 6px rgb(19 19 19 / 20%)' }}
          className={`${props?.fTdClearBlue ? tableStyles.firstdClearBlue : ""}`}
        >
          {props?.body?.length > 0 &&
            props?.body.map((e, i) => {
              return (
                //Renderiza tantas Row como sea el .length del body
                <>
                  <tr
                    key={"table" + i}
                  // className="hover-table-row "
                  >
                    {e.map((content, i) => {
                      // Renderiza tantos TD como sea el .length del array hijo
                      return (
                        <td
                          id={"td" + i}
                          className={`
                            ${content.selected ? content?.className + ' ' + props.selectedBg : content?.className ? content?.className : ""}`
                          }
                          key={`content${i}`}
                          style={content?.style || {}}
                        >
                          {content?.text ? content.text : content}
                        </td>

                      );

                    })}
                  </tr>
                </>
              );
            })}
          {props?.hasChildren && props.children}
        </tbody>
        {!!props.footer && <tfoot>{props.footer}</tfoot>}
      </table>
      {/* /* ------------------------------- PAGINATION ------------------------------- */}

      {props?.paginate && (
        <div className={tableStyles.wrapper}>
          {props?.paginate?.showTextDetails && (
            <>
              <p className={`${tableStyles.paginationText} text-secondary`}>
                Pag. {props?.paginate?.activePage || ""}
                {" de "}
                {Math.ceil(
                  props?.paginate?.totalPages / props?.paginate?.perPage
                )
                  ? Math.ceil(
                    props?.paginate?.totalPages / props?.paginate?.perPage
                  )
                  : ""}
                {" - "}
                {props?.paginate?.totalPages} encontrados
              </p>
            </>
          )}
          <Pagination
            activePage={props?.paginate?.activePage}
            itemsCountPerPage={props?.paginate?.perPage}
            totalItemsCount={props?.paginate?.totalPages}
            pageRangeDisplayed={props?.paginate?.pageRangeDisplayed || 5}
            onChange={(e) => props?.paginate?.onChangePage(e)}
            itemClassPrev={tableStyles.itemClassPrev}
            itemClassNext={tableStyles.itemClassNext}
            itemClassFirst={tableStyles.itemClassFirst}
            itemClassLast={tableStyles.itemClassLast}
            itemClass={tableStyles.itemClass}
          />
        </div>
      )}
    </>
  );
}

export default OrdTable;
/* ------------------------------- useExample ------------------------------- */
// ES NECESARIO QUE EL BODY SEA UN ARRAY DE ARRAYS, EL ARRAY EN LA POCISIÓN 0 EQUIVALDRÁ
// AL PRIMER HEADER Y ASI SUSESIVAMENTE, LUEGO ENTONCES, DENTRO DE ESE ARRAY HABRÁN UNA CANTIDAD
// DE PROPIEDADES, ESTAS DEBEN SER ENÚMERADAS EN EL ORDEN ESPECIFICO PARA QUE SE RENDERICEN CORRECTAMENTE
// tempList.push(
//     [{text: e?.namePatient, className: "text-danger"}, "Tubo 1", "POS 1 ", ">"],
//     ["Ibuprofeno 2", "Tubo 2", "POS 2", ">"],
//     ["Ibuprofeno 3", "Tubo 3", "POS 3", ">"]
//   );
// let info = [
//     [
//         "Ibuprofeno 1",
//         "Tubo 1",
//         "POS 1 ",
//         ">"
//     ],
//     [
//         "Ibuprofeno 2",
//         "Tubo 2",
//         "POS 2",
//         ">"
//     ],
//     [
//         "Ibuprofeno 3",
//         "Tubo 3",
//         "POS 3",
//         ">"
//     ],
// ]

// const headers = [
//     {
//         title: "Sustancia quimica",
//     },
//     {
//         title: "Sustancia Dos",
//     },
//     {
//         title: "Sustancia Tres",
//         className: "px-2 text-start"
//     },
//     {
//         title: <>&nbsp;</>,
//         className: "px-2 text-start"
//     },
// ]

// EXAMPLE USE WITH PAGINATE
//     <OrdTable
//     headers={medicineRelation.headers}
//     body={mdcRelationData}
//     hasChildren={true}
//     paginate={{
//         activePage: filters.page,
//         totalPages: articlesList?.row_total,
//         perPage: filters.perpage,
//         pageRangeDisplayed: 3,
//         onChangePage: async (e) => {
//             const result = await getArticlesList({
//                 url: '/inventory/article',
//                 objFilters: { ...filters, page: e },
//                 token: token,
//                 requestName: "articlesList",
//             })
//             setFilters({ ...filters, page: e })
//             formatData(result?.results)
//         },
//         showTextDetails: true
//     }}
// >
//     {formatData(articlesList?.results)}
// </OrdTable>

/* ------------------------ EXAMPLE WITH hasChildren ------------------------ */
// const formatData = (body) => {
//     let tempList = [];
//     if (Array.isArray(body)) {
//         body.forEach((item, index) => {
//             let bgYellow = isSelected.currentItem?.id === item.id && tableStyles.ordClearYellow
//             let bgYellowOpacity = isSelected.currentItem?.id === item.id && tableStyles.ordClearYellowOpacity20
//             let arrow = isSelected.currentItem?.id === item.id ? yellowRightArrow : ordRightArrow
//             tempList.push(
//                 < tr key={index} className={`hover-table-row`} >
//                     <td className={`${bgYellow}`} >{item.chemicalSubstance}</td>
//                     <td className={bgYellowOpacity}  >{item.presentationLabel}</td>
//                     <td className={`${bgYellowOpacity}`}  >{item.coverage}</td>
//                     <td className={`${bgYellowOpacity}`}  > <> <img
//                         alt={'felcha derecha'}
//                         className={`cursorPointer`}
//                         onClick={() => {
//                             setIsSelected({
//                                 show: true,
//                                 currentItem: item,
//                             })
//                         }}
//                         src={arrow}
//                     >
//                     </img>
//                         &nbsp;&nbsp;</></td>
//                 </tr >
//             );
//         });
//     }
//     return tempList;
// };
// eslint-disable-next-line no-lone-blocks
{
  /* <OrdTable
headers={headers}
hasChildren={true}
fTdClearBlue={true}
>
{formatData(chemicalSubstances.results)}
</OrdTable> */}
/* ----------------------- ACCORDION TABLE EXAMPLE USE ---------------------- */
// const handleToggle = (item, isOpen) => {
//     setTempTable({ ...tempTable, currentItem: item })
// }
// const formatData = () => {
//     let tempList = [];
//     if (Array.isArray(tmpArr)) {
//         tmpArr.forEach((item, index) => {
//             //console.log('ite=>', item)
//             // let orderType = item.orderType?.value
//             let isOpen = tempTable.currentItem?.id === item.id ? "open" : "fold"
//             let rotate = tempTable.currentItem?.id === item.id ? tableStyles.rotate180Degrees : ""
//             // let bgYellowOpacity = tempTable.currentItem?.id === item.id ? tableStyles.ordClearYellowOpacity20 : ""
//             // let orangeText = item?.isRequ === true ? tableStyles.ordOrangeText : ""
//             tempList.push(
//                 <>
//                     {/* /* ---------------------- FIRST ROW IS THE NORMAL VIEW ---------------------- */}
//                     <tr
//                         key={index}
//                         onClick={() => {
//                             handleToggle(item)
//                         }}
//                         className="view">
//                         <td
//                             className={`${rotate}  col-1 `}
//                         > <img
//                             src={ordDownArrow}
//                             alt="flecha abajo"
//                         ></img></td>
//                         <td className={`text-start`} >?.Prueba</td>
//                         <td className={`text-start`} >?.time?.Prueba</td>
//                         <td className={`text-start`} >?.Prueba</td>

//                     </tr>
//                     {/* /* ---------------------- SECOND ROW IS THE FOLDED CONTENT ---------------------- */}
//                     < tr
//                         key={index}
//                         className={`${isOpen} w-100`} >
//                         <td colspan="12">
//                             <div className="fold-content">
//                                 <Row>
//                                     <Col xs={4} className={`mx-2 ${tableStyles.rightLineGray}`}>
//                                         <span className={`${tableStyles.ordClearBlueText}`}>
//                                             <b>
//                                                 Concentración
//                                             </b>
//                                         </span>
//                                         <br></br>
//                                         <span>
//                                             {item?.medicine?.label}
//                                             &nbsp;
//                                             {item?.medicine?.extra5}
//                                         </span>
//                                     </Col>
//                                     <Col xs={7} className={`text-start`} >
//                                         <span className={`${tableStyles.ordClearBlueText}`}>
//                                             <b>
//                                                 Observaciones
//                                             </b>
//                                         </span>
//                                         <br></br>
//                                         <span>
//                                             {item?.observations}
//                                         </span>
//                                     </Col>
//                                 </Row>
//                             </div>
//                         </td>
//                     </tr >
//                 </>,
//             );
//         });
//     }
//     return tempList;
// };

// <OrdTable
// isAnAccTable
// headers={headers}
// hasChildren={true}
// >
// {formatData()}
// </OrdTable>
