import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import ModalNew from '../../components/Layouts/ModalNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { useEffect, useMemo, useState } from 'react';
import { customSwaltAlert, loader } from '../../helpers';
import { usePostMethod } from '../../Hooks';

export const DuplicateParameterization = props => {
  const store = useSelector(state => state);

  const [payload, setPayload] = useState({
    consumCenterOrigin: 0,
    costCenterOrigin: 0,
    consumCenterDestination: 0,
    costCenterDestination: 0,
    transacName: props.parameterization.transacName,
    eaccount: store.loginReducer.currentAccount.id,
  });

  const { trigger: onAccountForDuplicate, load: isLoadingAccountForDuplicate } = usePostMethod();

  //centro de costo
  const costCenterList = useMemo(
    () => store?.costcenterReducer?.cc?.map(el => ({ ...el, label: el.description, value: el.id })),
    [store?.costcenterReducer?.cc],
  );

  //centro de consumo
  const consumptionCenterList = useMemo(
    () =>
      store?.consumptionCenterReducer?.consumption_center?.map(el => ({
        ...el,
        label: el.description,
        value: el.id,
      })),
    [store?.consumptionCenterReducer?.consumption_center],
  );

  const validatePost = () => {
    const isValid = Object.values(payload).every(el => el);
    if (!isValid) {
      return customSwaltAlert({
        icon: 'warning',
        showCancelButton: false,
        title: 'Intenta de nuevo',
        text: 'Todos los campos son obligatorios',
        confirmButtonText: 'Aceptar',
      });
    }

    customSwaltAlert({
      icon: 'warning',
      title: '¿Estas seguro?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, continuar',
      text: `Se duplicará la parametrización: ${props.parameterization.name}`,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        onAccountForDuplicate({
          token: store.loginReducer.Authorization,
          body: payload,
          method: 'POST',
          url: '/accounting/accountFor/duplicate/',
          succesAction: () => {
            customSwaltAlert({
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
              title: 'Duplicado exitosamente',
              text: `Se ha duplicado la parametrización: ${props.parameterization.name}`,
            }).finally(() => {
              props?.doAfterSuccess();
            });
          },
          doAfterException: results => {
            customSwaltAlert({
              icon: 'warning',
              text: results.message,
              showCancelButton: false,
              title: 'Intenta de nuevo',
              confirmButtonText: 'Aceptar',
            });
          },
        });
      }
    });
  };

  useEffect(() => {
    setPayload(prev => ({ ...prev, transacName: props.parameterization.transacName }));
  }, [props.parameterization]);

  return (
    <>
      {isLoadingAccountForDuplicate && loader}

      <ModalNew
        size='550'
        show={props.isShow}
        btnYesEvent={validatePost}
        title='Duplicar parametrización'
        onHide={() => {
          props?.onHide();
          setPayload({
            consumCenterOrigin: 0,
            costCenterOrigin: 0,
            consumCenterDestination: 0,
            costCenterDestination: 0,
            transacName: props.parameterization.transacName,
            eaccount: store.loginReducer.currentAccount.id,
          });
        }}
      >
        <Row className='d-flex'>
          <Col xs={6} className='align-self-end'>
            <p className={tableStyles.crudModalLabel}>
              Centro de Costo Original
              <span className='text-danger'>*</span>
            </p>
            <Select
              options={costCenterList}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              noOptionsMessage={() => 'No hay datos'}
              onChange={e => {
                setPayload(prev => ({ ...prev, costCenterOrigin: e.value }));
              }}
            />
          </Col>

          <Col xs={6}>
            <p className={tableStyles.crudModalLabel}>
              Centro de Consumo Original
              <span className='text-danger'>*</span>
            </p>
            <Select
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              options={consumptionCenterList}
              noOptionsMessage={() => 'No hay datos'}
              onChange={e => setPayload(prev => ({ ...prev, consumCenterOrigin: e.value }))}
            />
          </Col>
        </Row>

        <Row className='d-flex mb-4'>
          <Col xs={6} className='align-self-end'>
            <p className={tableStyles.crudModalLabel}>
              Centro de Costo Destino
              <span className='text-danger'>*</span>
            </p>
            <Select
              options={costCenterList}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              noOptionsMessage={() => 'No hay datos'}
              onChange={e => setPayload(prev => ({ ...prev, costCenterDestination: e.value }))}
            />
          </Col>

          <Col xs={6}>
            <p className={tableStyles.crudModalLabel}>
              Centro de Consumo Destino
              <span className='text-danger'>*</span>
            </p>
            <Select
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              options={consumptionCenterList}
              noOptionsMessage={() => 'No hay datos'}
              onChange={e => setPayload(prev => ({ ...prev, consumCenterDestination: e.value }))}
            />
          </Col>
        </Row>
      </ModalNew>
    </>
  );
};
