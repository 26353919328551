/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import Agregar from "../../assets/img/icons/aggdoc.svg";
import Check from "../../assets/img/icons/CheckMark.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Atras from "../../assets/img/icons/atras-icon.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import ModalNew from "../../components/Layouts/ModalNew";
import DetailPayrollProvision from "./DetailPayrollProvision"

function PayrollProvision(props) {

    const [showModal, setShowModal] = useState(false);
    const [Detail, setDetail] = useState({show: false,});

    let history = useHistory();
    function handleClickr() {
        history.push("/contabilidad/autorizacionNomina/detalle");
    }

    const header = [
        <th className="col text-center">No. Comprobante</th>,    
        <th className="col text-center fw-bold" >Mes</th>,
        <th className="text-center">Año</th>,
        <th className="col text-center">Día</th>,
        <th className="col text-center">No. de empleados</th>,
        <th className="col text-center">Aportes patronales</th>,
        <th className="col text-center"></th>
    ]
    const body = [
        <tr>
            <td className="text-center">PA.65498</td> 
            <td className="text-center fw-bold" style={{color:'#005DBF'}}>Diciembre </td>
            <td className="text-center">2021</td>
            <td className="text-center">1</td>
            <td className="text-center">135</td>
            <td className="text-center">$25.000.000.000</td>
            <td className="text-center">
                <img 
                    className="text-start pointer" 
                    src={Filtrar} 
                    onClick={() => setDetail({ show: true , selectedPayroll: 1})} 
                />
            </td>
        </tr>,
        <tr>
            <td className="text-center">PA.65498</td> 
            <td className="text-center fw-bold" style={{color:'#005DBF'}}>Diciembre </td>
            <td className="text-center">2021</td>
            <td className="text-center">1</td>
            <td className="text-center">135</td>
            <td className="text-center">$25.000.000.000</td>
            <td className="text-center">
                <img 
                    className="text-start pointer" 
                    src={Filtrar} 
                    onClick={() => setDetail({ show: true , selectedPayroll: 1})} 
                />
            </td>
        </tr>,
        <tr>
        <td className="text-center">PA.65498</td> 
        <td className="text-center fw-bold" style={{color:'#005DBF'}}>Diciembre </td>
        <td className="text-center">2021</td>
        <td className="text-center">1</td>
        <td className="text-center">135</td>
        <td className="text-center">$25.000.000.000</td>
        <td className="text-center">
            <img 
                className="text-start pointer" 
                src={Filtrar} 
                onClick={() => setDetail({ show: true , selectedPayroll: 1})} 
            />
        </td>
    </tr>,
    <tr>
    <td className="text-center">PA.65498</td> 
    <td className="text-center fw-bold" style={{color:'#005DBF'}}>Diciembre </td>
    <td className="text-center">2021</td>
    <td className="text-center">1</td>
    <td className="text-center">135</td>
    <td className="text-center">$25.000.000.000</td>
    <td className="text-center">
        <img 
            className="text-start pointer" 
            src={Filtrar} 
            onClick={() => setDetail({ show: true , selectedPayroll: 1})} 
        />
    </td>
</tr>,
<tr>
            <td className="text-center">PA.65498</td> 
            <td className="text-center fw-bold" style={{color:'#005DBF'}}>Diciembre </td>
            <td className="text-center">2021</td>
            <td className="text-center">1</td>
            <td className="text-center">135</td>
            <td className="text-center">$25.000.000.000</td>
            <td className="text-center">
                <img 
                    className="text-start pointer" 
                    src={Filtrar} 
                    onClick={() => setDetail({ show: true , selectedPayroll: 1})} 
                />
            </td>
        </tr>,
        <tr>
        <td className="text-center">PA.65498</td> 
        <td className="text-center fw-bold" style={{color:'#005DBF'}}>Diciembre </td>
        <td className="text-center">2021</td>
        <td className="text-center">1</td>
        <td className="text-center">135</td>
        <td className="text-center">$25.000.000.000</td>
        <td className="text-center">
            <img 
                className="text-start pointer" 
                src={Filtrar} 
                onClick={() => setDetail({ show: true , selectedPayroll: 1})} 
            />
        </td>
    </tr>

    ]

    const myRender = <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                <div className="d-flex">
                    <h1 className={tableStyles.title}>Provisión de nómina </h1>
                </div>
                <Row className="d-flex">
                    <Col xs={2}>
                        <label className={tableStyles.crudModalLabel}>Fecha desde</label>
                        <input className={IndividualStyles.registerInputsBlue} type="date"></input>
                    </Col>
                    <Col xs={2}>
                        <label className={tableStyles.crudModalLabel}>Hasta</label>
                        <input className={IndividualStyles.registerInputsBlue} placeholder="Buscar..." type="date"></input>
                    </Col>
                    <Col xs={3}>
                        <label className={tableStyles.crudModalLabel}>&nbsp;</label>
                        <input style={{background:'#e6eff9', borderColor:'#e6eff9'}} className={tableStyles.blueSearch} placeholder="Buscar..." type="text"></input>
                    </Col>
                    <Col xs={1}>
                        <i><img className="pointer" style={{ marginTop: "30px" }} src={Lupa} alt="" srcset="" /></i>
                    </Col>
                </Row>
                <div>
                    <ModalNew
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        title={"Ordenes de pago pendientes"}
                        subtitle={"802.203.265 - Nueva EPS"}
                        btnYesEvent={true}
                        btnYesName={"Selecionar"}
                        size={"500"}

                    >
                        <Row className="d-flex">
                            <Col xs={5}>
                                <label className={tableStyles.crudModalLabel}>No. Orden de pago</label>
                                <input className={IndividualStyles.registerInputs} type="number"></input>
                            </Col>
                            <Col xs={6}>
                                <label className={tableStyles.crudModalLabel}>Fecha</label>
                                <input className={IndividualStyles.registerInputs} type="date"></input>
                            </Col>
                            <Col xs={1}>
                                <i><img className="mt-4" style={{ width: "25px" }} src={Lupa} alt="" srcset="" /></i>
                            </Col>
                        </Row>
                        <GenericTable
                            headers={header}
                            children={body}
                        >
                        </GenericTable>

                    </ModalNew>

                </div>
                <GenericTable
                    headers={header}
                    children={body}
                    dark={true}
                >
                </GenericTable>
            </div>

        return (!!Detail.show)? 
        <DetailPayrollProvision
            selectedPayroll={Detail.selectedPayroll}
            show={Detail.show}
            setDetails={setDetail}
        /> 
        : 
        myRender;
};
export default PayrollProvision;