import React, { useState } from 'react'
import { MultiTabComponent } from '../../components/Tabs/MultiTabComponent'
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate'
import Anesthesiologists from './Anesthesiologists'
import Assistants from './Assistants'
import Instrumentators from './Instrumentators'
import Offices from './Offices'
import OperatingRooms from './OperatingRooms'

const ResourceAssignment = () => {
    const [tab, setTab] = useState({
        tabName: ""
    })

  const tabs = [
    {
        id: 1,
        tabName: 'Consultorios',
        tabContent: <Offices tab={tab} setTab={setTab} />,
        onFunction: ()=>  setTab({...tab, tabName: "Consultorios"})

    },
    // {
    //     id: 2,
    //     tabName: 'Quirófanos',
    //     tabContent: <OperatingRooms tab={tab} setTab={setTab} />,
    //     onFunction: ()=>  setTab({...tab, tabName: "Quirófanos"})

    // },
    // {
    //     id: 3,
    //     tabName: 'Auxiliares',
    //     tabContent: <Assistants tab={tab.tabName} setTab={setTab}/>,
    //     onFunction: ()=>  setTab({...tab, tabName: "Auxiliares"})

    // },
    // {
    //     id: 4,
    //     tabName: 'Instrumentadores',
    //     tabContent: <Instrumentators tab={tab} setTab={setTab}/>,
    //     onFunction: ()=>  setTab({...tab, tabName: "Instrumentadores"})

    // },
    // {
    //     id: 5,
    //     tabName: 'Anestesiólogos',
    //     tabContent: <Anesthesiologists tab={tab} setTab={setTab}/>,
    //     onFunction: ()=>  setTab({...tab, tabName: "Anestesiólogos"})

    // },
]
    
  return (
    <div className={` d-flex w-90`}>
         <>
            <OrdGenericTemplate
                title={'Asignación de recursos'}
                titleSize={12}
                className="mx-2 pb-2 my-2"
                titleClass="my-2 mb-4"
                // className="w-80 my-4"
            >
                <MultiTabComponent
                    tabs={tabs}
                    // className={`pl-4`}
                ></MultiTabComponent>
            </OrdGenericTemplate>
        </>
    </div>
  )
}

export default ResourceAssignment