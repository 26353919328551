import React, { useEffect, useState } from "react";
import Adjuntar from "../../../assets/img/icons/Adjuntar.svg";
import send from "../../../assets/img/icons/send.svg";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import { useSelector, useDispatch } from "react-redux";
import ModalNew from "../../Layouts/ModalNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, Col, Nav, Row, Table } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  convertBase64,
  convertMoneyFormat,
  expRegList,
  isEmptyOrUndefined,
  message,
  placeMyIcon,
  today,
  validExpReg,
} from "../../../helpers/helpers";

import GenericTableNew from "../../Layouts/GenericTableNew";
import {
  addMedicalResults,
  getMedicalInfo,
} from "../../../actions/partnersActions";
import { formatToRcSelect } from "../../../helpers/helpers";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
export const Examns = ({ idCandidate }) => {
  const location = useLocation();
  const store = useSelector((state) => state);
  let id_candidate = location.state.id_candidate;
  let id_contract = location.state.id_contract;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const medicalInfo = store.partnersReducer.medicalInfo.medical_info;
  const historial = store.partnersReducer.medicalInfo.historial;
  const idAccount = store.loginReducer.user_data.id;
  const fullName =
    store.loginReducer.user_data.first_name +
    " " +
    store.loginReducer.user_data.first_surname;
  const token = store.loginReducer.Authorization;

  const history = useHistory();
  const [inputs, setInputs] = useState({
    base64: "",
    examDate: "",
    ext: "",
    fileName: "",
    title: "",
  });
  const [allowSend, setallowSend] = useState(false);
  const [showPaymentDetail, setShowPaymentDetail] = useState({
    show: false,
    detailsPayment: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const listPaymentDetail = () => {
    let elemMap;
    if (showPaymentDetail?.detailsPayment?.details_payments !== undefined) {
      const elem2 = showPaymentDetail?.detailsPayment?.details_payments;
      elemMap = elem2.map((elem) => {
        return renderPaymentDetail(elem);
      });
    }
    return elemMap;
  };
  useEffect(() => {
    if (
      !isEmptyOrUndefined(inputs.examDate) &&
      !isEmptyOrUndefined(inputs.title) &&
      !isEmptyOrUndefined(inputs.fileName) &&
      !isEmptyOrUndefined(inputs.base64)
    ) {
      setallowSend(true);
    } else {
      setallowSend(false);
    }
  }, [inputs]);
  const renderPaymentDetail = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">{elem.number}</td>
        <td className="col-md-4 text-center">
          {moment(elem.Fecha).format("YYYY/MM/DD")}
        </td>
        <td className="col-md-2 text-center">
          {convertMoneyFormat(
            showPaymentDetail?.detailsPayment?.fee_value
              ? showPaymentDetail?.detailsPayment?.fee_value
              : "0"
          )}
        </td>
      </tr>
    );
  };
  const paymentDetailsHeaders = [
    <th style={{ borderTopLeftRadius: "10px" }} key={`Hash`}>
      #
    </th>,
    <th key={`Date`}>Fecha</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={`ValueDetailP`}>
      Valor cuotas
    </th>,
  ];

  const dispatch = useDispatch();

  const download = (url) => {
    window.location.href = url;
  };
  useEffect(() => {
    dispatch(
      getMedicalInfo({
        candidate: idCandidate,
        entity_account: idEnterprise,
      })
    );
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const result = await addMedicalResults(
        {
          entity_account: idEnterprise,
          candidate: idCandidate,
          title: inputs.title,
          prog_date: inputs.examDate,
          done_date: inputs.examDate,
          created_by: idAccount,
          result_loaded_by: idAccount,
          result_loaded_by_name: fullName,
          base64: inputs.base64,
          filename: inputs.fileName,
        },
        token
      );

      if (result.success) {
        setTrigger(trigger + 1);
        setInputs({
          base64: "",
          examDate: "",
          ext: "",
          fileName: "",
          title: "",
        });
        dispatch(
          getMedicalInfo({
            candidate: idCandidate,
            entity_account: idEnterprise,
          })
        );
        setIsLoading(false);
        return message("success", "Éxito", "Resultados subidos exitosamente", undefined,
        true
);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message("error", "Error", error.message,  undefined,
      true
);
      return console.error(error);
    }
  };
  /* ----------------------------- HANDLE CHANGES ----------------------------- */
  const onChangeDoc = async (e) => {
    // setCounter(counter + 1);
    const file = e.target.files[0];
    const validExtensions = ["zip", "pdf", "rar", "PDF", "ZIP", "RAR"];
    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split(".");
      let extension = validExtensions.find(
        (obj) => obj === actFileExt[actFileExt.length - 1]
      );
      if (isEmptyOrUndefined(extension)) {
        // setSelectedFile(null);
        e.target.value = "";
        return message(
          "warning",
          "Advertencia!",
          `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ].toUpperCase()}`,
          undefined,
          true
        );
      }

      if (file.size > 50000000) {
        // setSelectedFile(null);
        e.target.value = "";
        return message(
          "warning",
          "Advertencia",
          "Su archivo no puede tener un tamaño superior a 5MB",
          undefined,
          true
        );
      } else {
        if (file.size <= 0) {
          // setSelectedFile(null);
          e.target.value = "";
          return message(
            "warning",
            "Advertencia",
            "Por favor, adjunte un archivo valido.",
            undefined,
            true  
          );
        }
      }
      // if (!isEmptyOrUndefined(file)) {
      //   setSelectedFile(file);
      // }
    } else {
      return console.error("Archivo vacio: ", file);
    }
    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split(".")[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(",")[1];
    let ext = type_doc.split("/");
    let finalName = `${name_doc}.${ext[1]}`;
    setInputs({
      ...inputs,
      base64: file64,
      fileName: finalName,
      ext: ext[1],
    });
  };
  return (
    <div>
      {isLoading ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      {/* /* ------------------------------ PAYMENT MODAL ----------------------------- */}
      <ModalNew
        size="540"
        title="Detalle de pagos"
        subtitle={showPaymentDetail?.detailsPayment?.third}
        show={showPaymentDetail?.show}
        // show={true}
        onHide={() => setShowPaymentDetail(false)}
      >
        <Row className={`${tableStyles.bgColorClearBlue}`}>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel} `}>No. de cuotas</p>
            <input
              disabled={true}
              value={showPaymentDetail?.detailsPayment?.installments}
              type="text"
              className="register-inputs text-secondary"
            />
          </Col>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel} `}>Valor cuotas</p>
            <input
              disabled={true}
              type="text"
              value={showPaymentDetail?.detailsPayment?.fee_value}
              className="register-inputs text-secondary"
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Total adeudado</p>
            <input
              disabled={true}
              value={showPaymentDetail?.detailsPayment?.total}
              className="register-inputs text-secondary"
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Saldo a la fecha</p>
            <input
              disabled={true}
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.balance_date
                  ? showPaymentDetail?.detailsPayment?.balance_date
                  : "0"
              )}
              className="register-inputs text-secondary"
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Descontado a la fecha
            </p>
            <input
              disabled={true}
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.discounted_date
                  ? showPaymentDetail?.detailsPayment?.discounted_date
                  : "0"
              )}
              className="register-inputs text-secondary"
            />
          </Col>
        </Row>
        <GenericTableNew headers={paymentDetailsHeaders}>
          {listPaymentDetail()}
        </GenericTableNew>
      </ModalNew>

      <Row
        style={{
          borderBottom: "1px solid #dce1e3",
          marginBottom: "10%",
        }}
      >
        {/* /* ------------------------- MEDIC INFORMATION ------------------------- */}
        <Col xs={4} className="mt-4">
          <h3 className={tableStyles.darkBlueText}>
            <b>Información médica</b>
          </h3>
          <div className="mt-4">
            <Row className="d-flex justify-content-start">
              <Col
                xs={6}
                className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
              >
                <b>Tipo de sangre</b>
              </Col>

              <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                {medicalInfo?.blood_type ? medicalInfo?.blood_type : "-"}
              </Col>
            </Row>

            <Row className="d-flex justify-content-start">
              <Col
                xs={6}
                className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
              >
                <b>RH</b>
              </Col>
              <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                {medicalInfo?.rh_factor ? medicalInfo?.rh_factor : "-"}
              </Col>
            </Row>

            <Row className="d-flex justify-content-start">
              <Col
                xs={6}
                className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
              >
                <b>Peso</b>
              </Col>
              <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                {medicalInfo?.weight_kg ? medicalInfo?.weight_kg : "-"}
              </Col>
            </Row>

            <Row className="d-flex justify-content-start">
              <Col
                xs={6}
                className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
              >
                <b>Estatura</b>
              </Col>
              <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                {medicalInfo?.heigth_cm ? medicalInfo?.heigth_cm : "-"}
              </Col>
            </Row>

            <Row className="d-flex justify-content-start">
              <Col
                xs={6}
                className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
              >
                <b>Alergias</b>
              </Col>
              <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                {medicalInfo?.known_deseases
                  ? medicalInfo?.known_deseases
                  : "-"}
              </Col>
            </Row>

            <Row className="d-flex justify-content-start">
              <Col
                xs={6}
                className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
              >
                <b>Discapacidades</b>
              </Col>
              <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                {medicalInfo?.disabilities ? medicalInfo?.disabilities : "-"}
              </Col>
            </Row>

            <Row className="d-flex justify-content-start">
              <Col
                xs={6}
                className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
              >
                <b>Medicamentos</b>
              </Col>
              <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                {medicalInfo?.drug_treatment
                  ? medicalInfo?.drug_treatment
                  : "-"}
              </Col>
            </Row>
            <Row className="d-flex justify-content-start">
              <Col
                xs={6}
                className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
              >
                <b>Enfermedades</b>
              </Col>
              <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                {medicalInfo?.known_diseases
                  ? medicalInfo?.known_diseases
                  : "-"}
              </Col>
            </Row>
          </div>
        </Col>

        <Col className={`mt-4 rounded m-3`} xs={3}>
          <h4 className={tableStyles.darkBlueText}>
            <b>Subir resultados </b>
          </h4>

          <p className={`${tableStyles.crudModalLabel} `}>
            Fecha de exámenes
            <span className={"text-warning"}>*</span>
          </p>
          <input
            key={"examDate" + trigger}
            value={inputs.examDate}
            onChange={(e) => setInputs({ ...inputs, examDate: e.target.value })}
            type="date"
            className="register-inputs text-primary"
          />

          <p className={`${tableStyles.crudModalLabel}`}>
            Descripción
            <span className={"text-danger"}>*</span>
          </p>
          <textarea
            key={"title" + trigger}
            value={inputs.title}
            onChange={(e) => setInputs({ ...inputs, title: e.target.value })}
            placeholder={"Escribir..."}
            rows="10"
            cols="10"
            style={{ height: "4rem" }}
            className={`register-inputs text-secondary`}
          ></textarea>

          <p className={`${tableStyles.crudModalLabel} `}>
            Subir resultados
            <span className={"text-danger"}>*</span>
          </p>
          <Card
            border=""
            className="w-100"
            style={{
              height: "6rem",
              width: "20rem",
              borderRadius: "10px",
              border: "1px solid #7FADDE"
            }}
          >
            <div className="d-flex justify-content-between cursorPointer">
              {!isEmptyOrUndefined(inputs.fileName) && (
                <img
                alt="icon"
                  className="cursorPointer "
                  width="20%"
                  style={{ paddingTop: "7%" }}
                  src={placeMyIcon(inputs.ext)}
                />
              )}

              <input
                key={"document" + trigger}
                style={{
                  textIndent: "-170px",
                  paddingTop: "7%",
                }}
                type="file"
                className="text-secondary"
                name="documentpdf"
                id="documentpdf"
                onChange={(e) => onChangeDoc(e)}
                multiple={false}
                placeHolder="equis"
              />
            </div>

            <label
              htmlFor="documentpdf"
              className="d-flex justify-content-end mr-1 cursorPointer"
              // style={{ marginTop: "13%" }}
            >
              <img src={Adjuntar} alt="icon"></img>
            </label>
          </Card>

          <div className={`customTabs__Footer mt-2 p-0`}>
            <Button
              className={tableStyles.btnPrimary}
              onClick={() => handleSubmit()}
              disabled={allowSend ? false : true}
            >
              <img className="cursorPointer" src={send} alt="icon" />
              &nbsp; Enviar
            </Button>
          </div>
        </Col>
        {/* <Col className=" d-flex justify-content-center" xs={1}> */}

        {/* </Col> */}
        {/* /* ---------------------- EXAMS INFORMATION --------------------- */}

        {/* <div className="> */}
        <hr
          className="m-0 p-0"
          style={{
            border: "none",
            borderLeft: "1px solid hsla(200, 10%, 50%, 100)",
            height: "25.5rem",
            width: "1px",
          }}
        ></hr>
        {/* </div> */}
        <Col className={`mt-4 rounded m-3 ${tableStyles.scrollThis}`} xs={4}>
          <h4 className={tableStyles.darkBlueText}>
            <b>Información de exámenes </b>
          </h4>
          <p className={`${tableStyles.crudModalLabel} `}>
            Fecha de próximos exámenes
          </p>
          <input
            disabled={true}
            defaultValue={store?.partnersReducer?.medicalInfo?.next_exam}
            className="register-inputs text-secondary"
          />
          <h4 className={`${tableStyles.darkBlueText} pt-4 pb-2`}>
            <b>Historial</b>
          </h4>
          {historial?.length > 0 ? (
            historial.map((e, i) => {
              let shortTitle = "";
              return (
                <Row className="d-flex justify-content-start">
                  <Col xs={3} className={`${tableStyles.darkGrayText} `}>
                    <b
                      className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                    >
                      •
                    </b>
                    <b
                      className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                    >
                      {e.done_date}
                    </b>
                  </Col>

                  <Col xs={9} className={`text-secondary`}>
                    <span className={`${tableStyles.fw700End} cursorPointer`}>
                      <u
                        className={`${tableStyles.darkGrayText} `}
                        title={e.title}
                      >
                        <b
                          onClick={() => download(e.result_location)}
                          className={`${tableStyles.darkGrayText} ${tableStyles.ellipsis}`}
                        >
                          {e.title.length >= 45
                            ? e.title.slice(0, 45) + "..."
                            : e.title}
                        </b>
                      </u>
                      <br />
                      {!isEmptyOrUndefined(e.result_loaded_by_name)
                        ? e.result_loaded_by_name
                        : "-"}
                    </span>
                  </Col>
                </Row>
              );
            })
          ) : (
            <h6 className={`${tableStyles.darkBlueText} pt-4`}>
              <b>No se encontraron fechas</b>
            </h6>
          )}
        </Col>
      </Row>
    </div>
  );
};
