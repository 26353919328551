// import { useEffect, useMemo, useState } from "react";
// import { useParams, Redirect, useHistory } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import ReactSelect from "react-select";
// import NumberFormat from "react-number-format";
// import { Accordion } from "react-bootstrap";
// import { KeyboardArrowDown } from "@material-ui/icons";
//
// // ⛳ functions ---------------------------------------//
// // import LetterEditor from "../LetterEditor/LetterEditor";
// import { responseSwal } from "../../helpers/responseSwal";
// import {
//   editCollectionLetter,
//   getCollectionsLettersDetail,
// } from "../../actions/collectionActions";
// import { customSwaltAlert, loader } from "../../helpers";
import { Title } from "../../shared/Title";
// import { useGetMethod } from "../../Hooks";
// //
// import tableStyles from "../Layouts/tableStyle.module.scss";
// import { customSelectNewDark } from "../Layouts/react-select-custom";
// import LetterPreview from "./LetterPreview";
// // ---------------------- ##### -----------------------------------//

function CollectDetails() {
  //   const history = useHistory();
  //   const store = useSelector((state) => state);
  //   const dispatch = useDispatch();
  //   const { id } = useParams();

  //   const initialState = {
  //     id: id,
  //     eaccount: store.loginReducer.currentAccount.id,
  //     collectionType: "",
  //     name: "",
  //     frecuency: undefined,
  //     sendTime: "",
  //     header: "",
  //     greetings: "",
  //     subject: "",
  //     body: "",
  //     goodBye: "",
  //     signature: "",
  //     role: "",
  //     responsibleName: "",
  //   };

  //   const [state, setState] = useState(initialState);
  //   const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  //   const [isLoading, setIsLoading] = useState(false);

  //   useEffect(() => {
  //     (async function () {
  //       try {
  //         setIsLoading(true);
  //         let res = await getCollectionsLettersDetail(
  //           {
  //             id: id,
  //             eaccount: store.loginReducer.currentAccount.id,
  //           },
  //           store.loginReducer?.Authorization
  //         );
  //         if (res.success) {
  //           const letter = res.results?.[0];
  //           setState((prev) => ({
  //             ...prev,
  //             name: letter.name,
  //             collectionType: letter?.tag,
  //             body: letter.body,
  //             frecuency: letter.frecuency,
  //             header: letter.header,
  //             greetings: letter.greetings,
  //             goodBye: letter.good_bye,
  //             role: letter.signature_role,
  //             sendTime: letter.send_time,
  //             signature: letter.signature,
  //             subject: letter.subject,
  //             responsibleName: letter.signature_name,
  //           }));
  //         } else {
  //           responseSwal({ icon: "error", title: "Error", text: res.message });
  //         }
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     })();
  //   }, [
  //     id,
  //     store.loginReducer.currentAccount.id,
  //     store.loginReducer?.Authorization,
  //   ]);

  //   const { trigger: getPaymentTypes, results: paymentTypes } = useGetMethod();

  //   useEffect(() => {
  //     async function fetchAsyncData() {
  //       await getPaymentTypes({
  //         url: "/receivable/collectionTypes/",
  //         objFilters: {
  //           page: 1,
  //           perpage: 99,
  //           status: "1",
  //           eaccount: store.loginReducer.currentAccount.id,
  //         },
  //         token: store.loginReducer.Authorization,
  //       });
  //     }
  //     fetchAsyncData().catch((error) => console.error("Error: ", error));
  //   }, [
  //     store.loginReducer.currentAccount.id,
  //     store.loginReducer.Authorization,
  //     getPaymentTypes,
  //   ]);

  //   const myPermission = () =>
  //     store.loginReducer.currentAccount?.profile?.permission?.find(
  //       (x) => x.functionality?.prefix === "collectLet"
  //     );

  //   const validatePayload = () => {
  //     if (
  //       !state.collectionType ||
  //       !state.name ||
  //       (state.collectionType === "persuasive" && !state.frecuency) ||
  //       !state.sendTime ||
  //       !state.header ||
  //       !state.greetings ||
  //       !state.subject ||
  //       !state.body ||
  //       !state.goodBye
  //     ) {
  //       return false;
  //     }
  //     return true;
  //   };

  //   const onSubmit = () => {
  //     try {
  //       setIsLoading(true);

  //       const isValid = validatePayload();

  //       if (!isValid) {
  //         customSwaltAlert({
  //           showCancelButton: false,
  //           icon: "warning",
  //           title: "Intenta de nuevo",
  //           text: "Por favor, diligencie todos los campos marcados como obligatorios.",
  //           confirmButtonText: "Aceptar",
  //         });
  //         return;
  //       }

  //       dispatch({ type: "LOADING", payload: true });
  //       dispatch(
  //         editCollectionLetter(state, () => {
  //           setState(initialState);
  //           history.push("/administracion/TMDeCartera/CartasCobro");
  //         })
  //       );
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   const joinedLetter = useMemo(
  //     () =>
  //       state.header +
  //       state.greetings +
  //       state.subject +
  //       state.body +
  //       state.goodBye +
  //       state.signature,
  //     [
  //       state.header,
  //       state.greetings,
  //       state.subject,
  //       state.body,
  //       state.goodBye,
  //       state.signature,
  //     ]
  //   );

  //   const onChangeTemplate = (result) => {
  //     setState({ ...state, ...result });
  //   };

  //   const formattedPaymentTypesList = useMemo(
  //     () =>
  //       paymentTypes.results?.map(
  //         (item) =>
  //           ({
  //             label: item.name,
  //             value: item.tag,
  //             role: item.role,
  //             name: item.responsibleName,
  //           }) || []
  //       ),
  //     [paymentTypes.results]
  //   );

  //   const renderHeader = () => {
  //     return (
  //       <div>
  //         <div
  //           className="card p-3 mt-4 border-0"
  //           style={{ borderRadius: 10, backgroundColor: "#F5F7FA" }}
  //         >
  //           <div className="row align-items-end">
  //             <div className="col">
  //               <small className="text-primary">
  //                 Tipo de cobro<span className="text-danger">*</span>
  //               </small>
  //               <ReactSelect
  //                 noOptionsMessage={() => "No hay datos"}
  //                 styles={customSelectNewDark}
  //                 placeholder="Seleccionar..."
  //                 value={formattedPaymentTypesList?.find(
  //                   (item) => item.value === state.collectionType
  //                 )}
  //                 onChange={(option) =>
  //                   setState((state) => ({
  //                     ...state,
  //                     collectionType: option.value,
  //                     role: option.role,
  //                     responsibleName: option.name,
  //                   }))
  //                 }
  //                 options={formattedPaymentTypesList}
  //               />
  //             </div>

  //             <div className="col-lg-4">
  //               <small className="text-primary">
  //                 Nombre carta de cobro<span className="text-danger">*</span>
  //               </small>
  //               <input
  //                 type="text"
  //                 className="register-inputs"
  //                 placeholder="Escribir el título..."
  //                 value={state.name}
  //                 onChange={({ target }) =>
  //                   setState((state) => ({ ...state, name: target.value }))
  //                 }
  //               />
  //             </div>

  //             <div className="col">
  //               <small className="text-primary">
  //                 Frecuencia (días)<span className="text-danger">*</span>
  //               </small>
  //               <NumberFormat
  //                 allowNegative={false}
  //                 decimalSeparator=""
  //                 className="register-inputs"
  //                 value={state.frecuency === "0" ? "  " : state.frecuency}
  //                 onChange={({ target }) =>
  //                   setState((state) => ({
  //                     ...state,
  //                     frecuency:
  //                       state.collectionType !== "persuasive" ? "" : target.value,
  //                   }))
  //                 }
  //                 disabled={state.collectionType !== "persuasive"}
  //                 placeholder={
  //                   state.frecuency === "0" ? "  " : "Escribir No. de días..."
  //                 }
  //               />
  //             </div>

  //             <div className="col">
  //               <small className="text-primary">
  //                 Hora de envío<span className="text-danger">*</span>
  //               </small>
  //               <input
  //                 type="time"
  //                 className="register-inputs"
  //                 value={state.sendTime}
  //                 onChange={({ target }) =>
  //                   setState((state) => ({ ...state, sendTime: target.value }))
  //                 }
  //                 disabled={state?.collectionType !== "persuasive"}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   };

  //   return (
  //     <>
  //       {isLoading && loader}
  //       <div className={tableStyles.tlnContainer}>
  //         <div className={`${tableStyles.tableArea}`} style={{ marginTop: 60 }}>
  //           {!myPermission().create && <Redirect to="/administracion/inicio" />}

  <Title
    title="Editar carta de cobro"
    // className={"mb-4"}
    // onClickIcon={() => {
    //   history.push("/administracion/TMDeCartera/CartasCobro");
    // }}
  />;
  //           {renderHeader()}

  //           {!isLoading && (
  //             <>
  //               <Accordion>
  //                 <div
  //                   className="card p-3 my-4 border-0 shadow"
  //                   style={{ borderRadius: 10 }}
  //                 >
  //                   <Accordion.Toggle
  //                     className="d-flex align-items-center fw-bold text-primary pointer"
  //                     eventKey="0"
  //                   >
  //                     <KeyboardArrowDown />
  //                     <span className="ms-1">Encabezado de la carta</span>
  //                   </Accordion.Toggle>
  //                   <Accordion.Collapse
  //                     className="position-relative"
  //                     eventKey="0"
  //                   >
  //                     {/* <LetterEditor
  //                       onGetResult={(result) => onChangeTemplate(result)}
  //                       name="header"
  //                       defaultValue={state.header}
  //                     /> */}
  //                   </Accordion.Collapse>
  //                 </div>
  //               </Accordion>

  //               <Accordion>
  //                 <div
  //                   className="card p-3 my-4 border-0 shadow"
  //                   style={{ borderRadius: 10 }}
  //                 >
  //                   <Accordion.Toggle
  //                     className="d-flex align-items-center fw-bold text-primary pointer"
  //                     eventKey="1"
  //                   >
  //                     <KeyboardArrowDown />
  //                     <span className="ms-1">Saludo</span>
  //                   </Accordion.Toggle>
  //                   <Accordion.Collapse
  //                     className="position-relative"
  //                     eventKey="1"
  //                   >
  //                     {/* <LetterEditor
  //                       onGetResult={(result) => onChangeTemplate(result)}
  //                       name="greetings"
  //                       defaultValue={state.greetings}
  //                     /> */}
  //                   </Accordion.Collapse>
  //                 </div>
  //               </Accordion>

  //               <Accordion>
  //                 <div
  //                   className="card p-3 my-4 border-0 shadow"
  //                   style={{ borderRadius: 10 }}
  //                 >
  //                   <Accordion.Toggle
  //                     className="d-flex align-items-center fw-bold text-primary pointer"
  //                     eventKey="2"
  //                   >
  //                     <KeyboardArrowDown />
  //                     <span className="ms-1">Asunto</span>
  //                   </Accordion.Toggle>
  //                   <Accordion.Collapse
  //                     className="position-relative"
  //                     eventKey="2"
  //                   >
  //                     {/* <LetterEditor
  //                       onGetResult={(result) => onChangeTemplate(result)}
  //                       name="subject"
  //                       defaultValue={state.subject}
  //                     /> */}
  //                   </Accordion.Collapse>
  //                 </div>
  //               </Accordion>

  //               <Accordion>
  //                 <div
  //                   className="card p-3 my-4 border-0 shadow"
  //                   style={{ borderRadius: 10 }}
  //                 >
  //                   <Accordion.Toggle
  //                     className="d-flex align-items-center fw-bold text-primary pointer"
  //                     eventKey="3"
  //                   >
  //                     <KeyboardArrowDown />
  //                     <span className="ms-1">Cuerpo de la carta</span>
  //                   </Accordion.Toggle>
  //                   <Accordion.Collapse
  //                     className="position-relative"
  //                     eventKey="3"
  //                   >
  //                     {/* <LetterEditor
  //                       onGetResult={(result) => onChangeTemplate(result)}
  //                       name="body"
  //                       defaultValue={state.body}
  //                     /> */}
  //                   </Accordion.Collapse>
  //                 </div>
  //               </Accordion>

  //               <Accordion>
  //                 <div
  //                   className="card p-3 my-4 border-0 shadow"
  //                   style={{ borderRadius: 10 }}
  //                 >
  //                   <Accordion.Toggle
  //                     className="d-flex align-items-center fw-bold text-primary pointer"
  //                     eventKey="4"
  //                   >
  //                     <KeyboardArrowDown />
  //                     <span className="ms-1">Despedida</span>
  //                   </Accordion.Toggle>
  //                   <Accordion.Collapse
  //                     className="position-relative"
  //                     eventKey="4"
  //                   >
  //                     {/* <LetterEditor
  //                       onGetResult={(result) => onChangeTemplate(result)}
  //                       name="goodBye"
  //                       defaultValue={state.goodBye}
  //                     /> */}
  //                   </Accordion.Collapse>
  //                 </div>
  //               </Accordion>

  //               <Accordion>
  //                 <div
  //                   className="card p-3 my-4 border-0 shadow"
  //                   style={{ borderRadius: 10 }}
  //                 >
  //                   <Accordion.Toggle
  //                     className="d-flex align-items-center fw-bold text-primary pointer"
  //                     eventKey="5"
  //                   >
  //                     <KeyboardArrowDown />
  //                     <span className="ms-1">Firma</span>
  //                   </Accordion.Toggle>
  //                   <Accordion.Collapse
  //                     className="position-relative"
  //                     eventKey="5"
  //                   >
  //                     {/* <LetterEditor
  //                       onGetResult={(result) => onChangeTemplate(result)}
  //                       name="signature"
  //                       defaultValue={state.signature}
  //                     /> */}
  //                   </Accordion.Collapse>
  //                 </div>
  //               </Accordion>
  //             </>
  //           )}

  //           <div className="d-flex justify-content-end mb-5">
  //             <button
  //               onClick={() => history.goBack()}
  //               className={`${tableStyles.btnSecondary} py-2 me-3`}
  //             >
  //               Cancelar
  //             </button>
  //             <button
  //               className={`${tableStyles.btnPrimary} py-2 me-3`}
  //               onClick={() => onSubmit()}
  //             >
  //               Guardar
  //             </button>
  //             <button
  //               className={`${tableStyles.btnPrimaryAlt} py-2`}
  //               onClick={() => setIsPreviewModalOpen(true)}
  //             >
  //               Previsualizar
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //       <LetterPreview
  //         isOpen={isPreviewModalOpen}
  //         content={joinedLetter}
  //         onClose={() => setIsPreviewModalOpen(false)}
  //       />
  //     </>
  //   );
}

export default CollectDetails;
