

import React, { useState } from 'react'
import { forwardRef } from 'react';
import arrowDerechaCalendario from '../../assets/img/icons/arrowDerechaCalendario.svg'
import arrowIzquierdaCalendario from '../../assets/img/icons/arrowIzquierdaCalendario.svg'
import styles from "../../components/Layouts/tableStyle.module.scss";
import DatePicker from "react-datepicker";
import moment from 'moment'
moment.locale('es')

export const UseDayCalendar = () => {

    const [startDate, setStartDate] = useState(new Date());
    const days = ['Dom','Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', ]
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    const locale = {
        localize: {
            day: n => days[n],
            month: n => months[n]
        },
        formatLong: {
            date: () => 'mm/dd/yyyy'
        }
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (

        <div style={{ display: 'flex' }} >
            <img
                onClick={() => {
                    let fecha = new Date(startDate);
                    fecha.setDate(fecha.getDate() - 1);
                    setStartDate(fecha)
                }}
                style={{ cursor: 'pointer' }} src={arrowIzquierdaCalendario} alt="arrowIzquierdaCalendario"
            />
            <button className={`${styles.tlnTextPrimary} ${styles.fwBold} p-2`} onClick={onClick} ref={ref}>
                {moment(value).format('LL')}
            </button>
            <img
                onClick={() => {
                    let fecha = new Date(startDate);
                    fecha.setDate(fecha.getDate() + 1);
                    setStartDate(fecha)
                }}
                style={{ cursor: 'pointer' }} src={arrowDerechaCalendario} alt="arrowDerechaCalendario" />

        </div>

    ));


    return {
        getDaySelected: startDate,
        component:
            <DatePicker
                locale={locale}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<ExampleCustomInput />}
            />
    }

}