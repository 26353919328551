import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "../../components/Layouts/tableStyle.module.scss";
import select from "../../assets/img/icons/ordGrayBack.svg";
import filterIcon from "../../assets/img/icons/filterIcon.svg";
import addIcon from "../../assets/img/icons/add-check.svg";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import Select from "react-select";
import ordBlueSearch from "../../assets/img/icons/ordBlueSearch.svg";
import OrdTable from "../../OrderingModule/OrderingComponents/OrdTable";
import arrow from "../../assets/img/icons/arrowDarkGrey.svg";
import disabledIcon from "../../assets/img/icons/Ellipse.svg";
import assignedIcon from "../../assets/img/icons/checkOnTable.svg";
import alertIcon from "../../assets/img/icons/alert-circle-auxiliary-red.svg";
import locationAdd from "../../assets/img/icons/locationAdd.svg";
import personIcon from "../../assets/img/icons/personAddIcon.svg";
import whiteClock from "../../assets/img/icons/whiteClock.svg";
import OrdModal from "../../OrderingModule/OrderingComponents/OrdModal";
import { AntSwitchBlue } from "../../helpers/constants";
import { Drawer } from "@mui/material";
import close from "../../assets/img/icons/appClosePatient.svg";
import CalendarPage from "../../components/Calendar/CalendarPage";

const Assistants = (tab, setTab) => {
  const [filterAssistans, setFilterAssistans] = useState({
    siteId: "",
    statusId: "",
    search: "",
    showFilters: false,
  });

  const [showDetail, setShowDetail] = useState({
    show: false,
    state: false,
    idRow: "",
  });

  useEffect(() => {
    if (tab !== "Auxiliares"){
      setShowDetail({
      show: false,
      state: false,
      idRow: "",
    })
    }
  }, [tab])

  const headers = [
    {
      //   title: "Pacinte",
      //   className: "px-2 col-2 text-start",
    },
    {
      title: "Auxiliares",
      className: "px-2 text-start",
    },
    {
      className: "px-2 text-start",
    },
  ];
  const officesList = {
    results: [
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 509,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "disabled",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "assigned",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
      {
        status: "alert",
        officeName: "Ernesto Torres",
        siteName: "Rioacha",
        officeId: 1,
      },
    ],
  };

  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let status =
          item.status === "disabled" ? disabledIcon : item.status === "assigned" ? assignedIcon : item.status === "alert" ? alertIcon : "";
        let orange = showDetail.state === true && showDetail.idRow === item.officeId ? styles.ordBgFileTableYellow : "";
        tempList.push(
          <tr
            key={item?.srvId}
            className={`hover-table-row ${orange} ${styles.cursorPointer}`}
            onClick={() => {
              setShowDetail({ ...showDetail, state: true, idRow: item.officeId, show: true });

              //   setModalAppointment({
              //     ...modalAppointment,
              //     state: true,
              //     id: item.idPatient,
              //     idApp: item.idApp,
              //     document: item.documentPatient,
              //     name: item.fullNamePatient,
              //     contractId: item.idContractCorporateClient,
              //     contract: item.contractCorporateClient,
              //     srvPrice: item.corpPrice,
              //     srvId: item.idService,
              //     applicationDateApp: item.applicationDateApp,
              //     insurance: item.companyId,
              //     company: item.company,
              //     serviceName: item.serviceName,
              //     siteName: item.siteName,
              //     cityName: item.cityName,
              //   });
            }}
          >
            <td className={`text-center px-2 ${orange} `}>
              <img alt="statusIcon" src={status} style={{ height: "20px" }} />
            </td>
            <td className={`text-start px-2 ${orange} `}>{item?.officeName}</td>
            <td className={`text-start px-2 ${orange} `}>
              <img alt="arrowIcon" src={arrow} />
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };

  const [newOffice, setNewOffice] = useState({
    siteId: "",
    domOffice: "",
    show: false,
  });

  const closeNewOfficeModal = () => {
    setNewOffice({ ...newOffice, show: false, siteId: "", domOffice: "" });
    // setTriggerTable(triggerTable + 1);
  };

  const renderPersonIcon = () => <Tooltip> Asignar</Tooltip>;
  const renderBlockIcon = () => <Tooltip> Bloquear</Tooltip>;
  const renderExpandTooltip = () => <Tooltip> Vista completa</Tooltip>;

  const [openAssignmentDrawer, setOpenAssignmentDrawer] = useState({
    show: false,
    showAssignment: false,
    menuIsOpen: true,
    showBlock: false,
  });
  const closeAssignmentsDrawer = () => {
    setOpenAssignmentDrawer({ ...openAssignmentDrawer, show: false, menuIsOpen: true, showAssignment: false, showBlock: false });
  };
  return (
    <>
      {/* Assignment drawer */}

      <Drawer anchor={"right"} open={openAssignmentDrawer.show} style={{ width: "50%" }}>
        <div style={{ width: "350px", padding: " 1rem 1rem", height: "100%" }} className="">
          <div>
            <Row>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <button onClick={() => closeAssignmentsDrawer()}>
                    <img alt="close" src={close} />
                  </button>
                </div>
              </div>
            </Row>
            {openAssignmentDrawer.menuIsOpen === true ? (
              <div className="px-2">
                <div className="d-flex text center align-self-center justify-content-center">
                  <b className={`${styles.f24} ${styles.tlnTextPrimary} text-center align-self-center d-flex `}> &nbsp;¿Qué desea hacer?</b>{" "}
                  <br />
                </div>
                <button
                  onClick={() => {
                    setOpenAssignmentDrawer({ ...openAssignmentDrawer, showAssignment: true, menuIsOpen: false });
                  }}
                  style={{ width: "259px", height: "150px" }}
                  className={`${styles.appBackBorderBlue} my-4 ${styles.appButtonHover}  p-4 mx-4 d-flex flex-column align-self-center justify-content-center text-center`}
                >
                  <img alt="personIcon" src={personIcon} height="50px" className={`${styles.AppIconHoverTurquoise} align-self-center`} />
                  <span className={`${styles.tlnTextGray} ${styles.appTextHoverTurquoise} ${styles.f16} align-self-center `}>Asignar</span>
                </button>
                <button
                  onClick={() => {
                    setOpenAssignmentDrawer({ ...openAssignmentDrawer, showAssignment: false, showBlock: true , menuIsOpen: false });
                  }}
                  style={{ width: "259px", height: "150px" }}
                  className={`${styles.appBackBorderBlue} my-4 ${styles.appButtonHover}  p-4 mx-4 d-flex flex-column align-self-center justify-content-center text-center`}
                >
                  <img alt="whiteClock" src={whiteClock} height="50px" className={`${styles.AppIconHoverTurquoise} align-self-center`} />
                  <span className={`${styles.tlnTextGray} ${styles.appTextHoverTurquoise} ${styles.f16} align-self-center `}>
                    Bloquear fecha
                  </span>
                </button>
              </div>
            ) : openAssignmentDrawer.showAssignment ? (
              <div className="px-2">
                <div className="">
                  <b className={`${styles.f32} ${styles.tlnTextPrimary} text-center `}> &nbsp;Asignación</b> <br />
                </div>
                <div className="my-2">
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Auxiliar:</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filterAssistans.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFilterAssistans({ ...filterAssistans, siteId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Instrumentador:</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filterAssistans.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFilterAssistans({ ...filterAssistans, siteId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                  <Row>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Desde:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput `}
                          type="time"
                          placeholder="Escriba..."
                          // value={dataConfig.reprog}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, reprog: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hasta:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput ${styles.tlnTextGray}
                    `}
                          type="time"
                          placeholder="Escriba..."
                          // value={dataConfig.labor}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, labor: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Fecha de inicio:</b>
                    </Form.Label>
                    <Form.Control
                      // disabled={!dataConfig.isEditing}
                      className={`ord-roundInput `}
                      type="date"
                      placeholder="Escriba..."
                      // value={dataConfig.reprog}
                      onChange={(e) => {
                        // setDataConfig({ ...dataConfig, reprog: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Repetición:</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filterAssistans.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFilterAssistans({ ...filterAssistans, siteId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                </div>
              </div>
            ) : openAssignmentDrawer.showBlock ? (
              <div className="px-2">
                <div className="">
                  <b className={`${styles.f32} ${styles.tlnTextPrimary} text-center `}> &nbsp;Bloquear fecha</b> <br />
                </div>
                <div className="my-2">
                  <Row>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Desde:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput `}
                          type="date"
                          placeholder="Escriba..."
                          // value={dataConfig.reprog}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, reprog: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hora:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput ${styles.tlnTextGray}
                    `}
                          type="time"
                          placeholder="Escriba..."
                          // value={dataConfig.labor}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, labor: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hasta:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput `}
                          type="date"
                          placeholder="Escriba..."
                          // value={dataConfig.reprog}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, reprog: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hora:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput ${styles.tlnTextGray}
                    `}
                          type="time"
                          placeholder="Escriba..."
                          // value={dataConfig.labor}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, labor: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Repetición:</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filterAssistans.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFilterAssistans({ ...filterAssistans, siteId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!openAssignmentDrawer.menuIsOpen && (
          <div
            style={{ width: "350px", padding: " 1rem 2rem", height: "100%" }}
            className="d-flex justify-content-end align-items-end align-self-end mb-4 "
          >
            <div className="px-4">
              <button onClick={() => closeAssignmentsDrawer()} className={`mr-2 btn ${styles.ordBtnSecondary}`}>
                Cancelar
              </button>
              <button
                // onClick={() => handleSubmit()}
                className={`${styles.ordBtnPrimary} btn`}
              >
                Guardar
              </button>
            </div>
          </div>
        )}
      </Drawer>

      <OrdModal
        title={"Nuevo consultorio"}
        show={newOffice.show}
        btnYesName={"Guardar"}
        btnNoName={"Cancelar"}
        size={400}
        onHide={() => closeNewOfficeModal()}
        btnYesEvent={() => alert("hago algo")}
        btnNoEvent={() => closeNewOfficeModal()}
        // sizeBody={`${styles.modalHeight}`}
      >
        <div>
          {/* <Col xs={5}> */}
          <Row>
            <div>
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <span>
                    {" "}
                    &nbsp;Sede <span className={`${styles.ordOrangeText}`}>*</span>
                  </span>
                </Form.Label>
                <Select noOptionsMessage={() => 'No hay datos'}
                  //   options={insurancesOptions}
                  isDisabled={filterAssistans.siteId}
                  className="text-secondary "
                  placeholder={"Seleccione"}
                  styles={ordCustomSelect}
                  //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                  onChange={(e) => {
                    setFilterAssistans({ ...filterAssistans, siteId: e.value });
                    // getContract(e.value);
                  }}
                ></Select>
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <span>
                    {" "}
                    &nbsp;Dominación del consultorio <span className={`${styles.ordOrangeText}`}>*</span>
                  </span>
                </Form.Label>
                <Form.Control
                  className={`ord-roundInput `}
                  type="text"
                  placeholder=""
                  // value={data.cityName}
                  onChange={(e) => {
                    //   setTriggerTable(triggerTable + 1);
                  }}
                />
              </Form.Group>
            </div>
          </Row>
        </div>
      </OrdModal>
      <Row className="d-flex">
        <Col xs={4}>
          <Row className="pt-3">
            <Col xs={4}>
              <button
                onClick={() => {
                  setFilterAssistans({ ...filterAssistans, showFilters: !filterAssistans.showFilters });
                }}
                className={`${styles.appButtonHover}`}
              >
                <img alt="filterIcon" src={filterIcon} className={`${styles.AppIconHoverTurquoise}`} />
                <b className={`${styles.tlnTextGray} ${styles.appTextHoverTurquoise} ${styles.f14} mx-2`}>Filtrar</b>
              </button>
            </Col>
            <Col xs={8}>
              {/* <button onClick={() => setNewOffice({ ...newOffice, show: true })} className={`${styles.appButtonHover}`}>
                <img alt="addIcon" src={addIcon} className={`${styles.AppIconHoverTurquoise}`} />
                <b className={`${styles.tlnTextGray} ${styles.f14} ${styles.appTextHoverTurquoise} mx-2`}>Inscribir nuevo paciente </b>
              </button> */}
            </Col>
            {filterAssistans.showFilters && (
              <>
                <Col xs={4} className="mt-2">
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <span> &nbsp;Sede</span>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filterAssistans.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFilterAssistans({ ...filterAssistans, siteId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={4} className="mt-2">
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <span> &nbsp;Estado</span>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filterAssistans.statusId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFilterAssistans({ ...filterAssistans, statusId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <div>
                  <div className={`d-flex ${styles.ordGrayInputSearch} mt-2`}>
                    <Form.Control
                      style={{ backGroundColor: "red" }}
                      className={`ord-roundInput`}
                      type="text"
                      placeholder="Escribe aquí para buscar"
                      autoComplete="off"
                      onChange={(e) => {
                        setFilterAssistans({ ...filterAssistans, search: e.target.value });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          //   setTriggerTable(triggerTable + 1);
                        }
                      }}
                    />
                    <img src={ordBlueSearch} className="p-2" alt="buscar"></img>
                  </div>
                </div>
              </>
            )}
          </Row>
          <Row>
            <div style={{ height: "70vh", overflow: "auto" }} className="mt-2">
              <OrdTable headers={headers} hasChildren={true} oneHeadDarkBlue={false} lowerCase={false}>
                {formatData(officesList?.results)}
              </OrdTable>
            </div>
          </Row>
        </Col>
        <Col xs={8} className="d-flex flex-column">
          <div className="d-flex justify-content-end mx-2 pt-3">
            {showDetail.show && (
              <>
                <div className="d-flex justify-content-end align-self-end  px-2 ">
                <span className={`${styles.tlnTextGray} ${styles.f16}`}><b>Horas asignadas: </b> 40HH</span>
                </div>
                <div className="d-flex justify-content-end align-self-end  px-2 ">
                <button className={`   ${styles.appWatchTime}         d-flex  px-2  `}>
                      <span className={`${styles.f14} px-2 `}> Ver horas</span>
                      <img alt="whiteClock" src={whiteClock} className={` px-2 align-self-center`} />
                    </button>
                </div>
                <div className="d-flex justify-content-end ">
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderExpandTooltip()}>
                <button>
                  <img alt="locationAdd" src={locationAdd} className={`${styles.filterHoverBlue}`} />
                </button>
              </OverlayTrigger>
            </div>
              </>
            )}

          
          </div>

          <div className="d-flex justify-content-center w-100 h-100">
            {showDetail.show ? (
              <div className="pt-3 text-end w-100 ml-5 mb-2">
                <CalendarPage
                optionalDoctor={true}
                views={["week"]}
                container
                className="w-100 ml-5"
                />
              
              
              
              
              
              </div>
            ) : (
              <div className="d-flex flex-column align-self-center text-center justify-content-center h-100">
                <span className={`${styles.tlnTextGray} ${styles.f20}`}>Selecciona un consultorio</span>
                <img alt="select" src={select} width="25px" className="align-self-center text-center" />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Assistants;
