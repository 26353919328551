/* eslint-disable eqeqeq */

// react, redux, router
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Styles
import styles from "../../../../components/Layouts/tableStyle.module.scss";
import stylesOrders from './stylesOrders.module.scss'
import { ordCustomSelect } from "./../../../../components/Layouts/react-select-custom";

// Hooks
import { useGetMethod } from "../../../../Hooks/useFetch";

// helpers
import {
  today,
  loader,
  convertMoneyFormatNew,
} from "../../../../helpers/helpers";

// Select, Material Ui, bootstrap
import Select from "react-select";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTheme } from "styled-components";
import { Box, Popover } from "@mui/material";

// icons
import ordBlueSearch from "../../../../assets/img/icons/ordBlueSearch.svg";
import orderIcon from "../../../../assets/img/icons/orderIcon.svg";
import labIcon from "../../../../assets/img/icons/lab.svg";
import search from "../../../../assets/img/icons/searchCalendar.svg";
import addCheck from "../../../../../src/assets/img/icons/add-check-blue-dark.svg";
import estetoscopicBlue from "../../../../../src/assets/img/icons/estetoscopicBlue.svg";

// components
import { OrdGenericTemplate } from "../../../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import OrdTable from "../../../../OrderingModule/OrderingComponents/OrdTable";
import { RenderRow } from "../../../../AdmissionModule/Orders/components";
import { ServiceOrderReplace } from "../../../../AdmissionModule/Orders";
import { Text } from "../../../UI/atoms";


export const CanceledOrders = () => {
  // states
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const isCoordAdmin =
    store?.loginReducer?.currentAccount?.profile?.prefijo === "COORADMISIONIST";

  const { colors } = useTheme()

  const [data, setData] = useState({
    trigger: 0,
    showModalDetail: false,
    filters: {
      status: "",
      idUser,
      idAccount,
      isCoordinator: isCoordAdmin === true ? 1 : 0,
      isAdmisionist: isCoordAdmin === false ? 1 : 0,
      dateMax: "",
      search: "",
      page: 1,
      perpage: 10,
    },
    amount: "",
    company: {
      id: "",
      name: "",
      nit: "",
    },
    contract: {
      id: "",
      name: "",
      sequence: "",
    },
    date: "",
    donation: "",
    hour: "",
    id: "",
    patient: {
      docType: "",
      document: "",
      id: "",
      name: "",
    },
    pendingAmountStatus: "",
    pending_amount: "",
    service: {
      description: "",
      id: "",
      name: "",
    },
    status: {
      background: "",
      fontColor: "",
      id: "",
      name: "",
    },
    modalAmount: {
      donation: 0,
      amountToDeliver: 0,
    },
  });

  const {
    filters,
    company,
    contract,
    service,
    patient,
    trigger,
    modalAmount,
    showModalDetail
  } = data;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //request
  const {
    load: loadListServiceOrder,
    results: listServiceOrder,
    trigger: getListServiceOrder,
  } = useGetMethod();

  const {
    load: loadListServiceOrderFilterState,
    results: listServiceOrderFilterState,
    trigger: getListServiceOrderFilterState,
  } = useGetMethod();

  const onChangeTrigger = () =>
    setData((state) => ({ ...state, trigger: state.trigger + 1 }));


  const onClearState = () => {
    setData((state) => ({
      ...state,
      trigger: 0,
      showModalDetail: false,
      filters: {
        status: "",
        idUser,
        idAccount,
        isCoordinator: isCoordAdmin === true ? 1 : 0,
        isAdmisionist: isCoordAdmin === false ? 1 : 0,
        dateMax: "",
        search: "",
        page: 1,
        perpage: 10,
      },
      amount: "",
      company: {
        id: "",
        name: "",
        nit: "",
      },
      contract: {
        id: "",
        name: "",
        sequence: "",
      },
      date: "",
      donation: "",
      hour: "",
      id: "",
      patient: {
        docType: "",
        document: "",
        id: "",
        name: "",
      },
      pendingAmountStatus: "",
      pending_amount: "",
      service: {
        description: "",
        id: "",
        name: "",
      },
      status: {
        background: "",
        fontColor: "",
        id: "",
        name: "",
      },
      modalAmount: {
        donation: 0,
        amountToDeliver: 0,
      },
    }));
  };

  // -------------- SECTION FUNCTIONS TABLE  --------------------------------

  const headersCoordinador = [
    {
      title: "Tipo",
      className: "px-2 text-center",
    },
    {
      title: "No. Orden",
      className: "px-2 text-center",
    },
    {
      title: "Fecha",
      className: `px-2 text-center ${stylesOrders.w120px}`,
    },
    {
      title: "Paciente",
      className: "px-2 text-start",
    },
    {
      title: "Empresa",
      className: "px-2 text-start",
    },
    {
      title: "Contrato",
      className: "px-2 text-start",
    },
    {
      title: "Motivo",
      className: "px-2 text-start",
    },
    {
      title: "Total",
      className: `px-2 text-end ${stylesOrders.w130px}`,
    },
    {
      title: "Estado",
      className: `px-2 text-center ${stylesOrders.w160px}`,
    },
    {
      // title: "",
      className: " text-center",
    },
  ];

  const headers = [
    {
      title: "Tipo",
      className: "px-2 text-center",
    },
    {
      title: "No. Orden",
      className: "px-2 text-center",
    },
    {
      title: "Fecha",
      className: `px-2 text-center ${stylesOrders.w120px}`,
    },
    {
      title: "Paciente",
      className: "px-2 text-start",
    },
    {
      title: "Empresa",
      className: "px-2 text-start",
    },
    {
      title: "Contrato",
      className: "px-2 text-start",
    },
    {
      title: "Motivo",
      className: "px-2 text-start",
    },
    {
      title: "Total",
      className: `px-2 text-end ${stylesOrders.w130px}`,
    },
    {
      title: "Estado",
      className: `px-2 text-center ${stylesOrders.w160px}`,
    },
    {
      // title: "",
      className: " text-center",
    },
  ];

  const formatData = (array) => {

    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-center px-2`}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={props => (
                  <Tooltip {...props}>
                    <span>{item?.orderType === 'service' ? 'Servicios' : 'Laboratorio'}</span>
                  </Tooltip>
                )}
              >
                <img
                  alt="pendingAmountStatus"
                  src={item?.orderType === "service" ? orderIcon : labIcon}
                  height="20px"
                  width="20px"
                />
              </OverlayTrigger>

            </td>

            <td className={`text-center px-2 mx-2`}>{item?.id || "-"}</td>

            <td className={`text-center px-2 mx-2`}>
              <span>{item?.date}</span>
              <br />
              <span>{item?.hour}</span>
            </td>

            <td className={`text-start px-2`}>
              <span>{item?.patient?.name}</span>
              <br />
              <span style={{ color: "#A2A3AB" }}>
                {`${item?.patient?.docType} ${item?.patient?.document}`}
              </span>
            </td>

            <td className={`text-start px-2`}>{item?.company?.name}</td>

            {!isCoordAdmin && (
              <td className={`text-start px-2`}>
                {item?.contract?.name || "-"}
              </td>
            )}

            {isCoordAdmin && (
              <td className={`text-start px-2`}>{item?.admisionist?.name}</td>
            )}

            <td className={`text-start px-2 ${!item?.cancelReason?.label && 'px-4'}`}>{item?.cancelReason?.label || ` - `}</td>

            <td className={`text-end px-2`}>{convertMoneyFormatNew({ textNumber: Number(item?.amount).toFixed(2), SymbolDecimal: '.' })}</td>

            <td className={`text-center px-2`}>
              <span
                style={{
                  color: `${item?.status?.fontColor}`,
                  backgroundColor: `${item?.status?.background}`,
                  borderRadius: "10px",
                  padding: "2px 10px",
                }}
              >
                {item?.status?.name || "-"}
              </span>
            </td>

            <td className={`text-center px-2 col-1`}>
              <img
                className="pointer"
                src={search}
                alt="search"
                height="15px"
                width="15px"
                onClick={() => {
                  setData(state => ({
                    ...state,
                    ...item,
                    showModalDetail: true
                  }))
                }}
              />
            </td>
          </tr >
        );
      });
    }
    return tempList;
  };

  //  ------------ SECTION MODAL CHANGE --------------------

  useEffect(() => {
    getListServiceOrder({
      token: token,
      objFilters: { ...filters, cancelledOrders: 1 },
      url: "/medical/admissions/serviceOrder/",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (modalAmount?.amountToDeliver <= 0) {
      setData((state) => ({
        ...state,
        modalAmount: {
          ...state.modalAmount,
          donation: 0,
        },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalAmount?.amountToDeliver]);

  useEffect(() => {

    getListServiceOrderFilterState({
      token: token,
      objFilters: { view: 'cancelOrder' },
      url: "/medical/admissions/statusFilters/",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const printDataInsurance = () => {
    return (
      <>
        <RenderRow title={"Aseguradora"} subTitle={company?.name ? company?.name : '-'} />
        <RenderRow title={"Contrato"} subTitle={contract?.name ? contract?.name : '-'} />
        <RenderRow title={"Grupo de ingreso"} subTitle={service?.incomeGroup ? service?.incomeGroup : '-'} />
        <RenderRow title={"Origen"} subTitle={service?.population ? service?.population : '-'} />
      </>
    );
  };

  const printDataCite = () => {
    return (
      <>
        <RenderRow title={"Sede"} subTitle={service?.siteName ? service?.siteName : '-'} />
        <RenderRow title={"Médico"} subTitle={service?.doctor ? service?.doctor : '-'} />
        <RenderRow
          title={"Servicio"}
          subTitle={`${service?.cup} - ${service?.name}`}
        />
        <RenderRow
          title={"Especialidad"}
          subTitle={service?.specialties?.length ? service?.specialties?.join(', ') : '-'}
        />
        <RenderRow title={"Fecha"} subTitle={data?.date} />
        <RenderRow title={"Hora"} subTitle={data?.hour} />
        <RenderRow
          title={"No. de historia clínica"}
          subTitle={'N/A'}
        />
        <RenderRow
          title={"Diagnóstico"}
          subTitleList={service?.diagnostics}
          subTitle={service?.diagnostics?.length ? '' : '-'}
        />
        <RenderRow
          title={"Total"}
          subTitle={convertMoneyFormatNew({ textNumber: Number(data?.amount).toFixed(2), SymbolDecimal: '.' })}
        />
      </>
    );
  };

  //  ------------- !SECTION MODAL CHANGE --------------------
  return (
    <>
      {(loadListServiceOrder || loadListServiceOrderFilterState) && loader}

      <ServiceOrderReplace
        customClassChildren={styles.replaceChildrenScroll}
        data={[]}
        title={`${data?.needsAuthorization === 0 ? 'Anulación' : 'Solicitud de anulación'}`}
        subTitle={`${patient?.docType} ${convertMoneyFormatNew({ textNumber: patient?.document, SymbolShow: true })} - ${patient?.name}`}
        show={showModalDetail}
        size={940}
        onHide={() => setData(state=>({...state, showModalDetail: false}))}
        btnNoEvent={() => setData(state=>({...state, showModalDetail: false}))}
        dataInsurance={{
          data: printDataInsurance,
          title: "Cita original",
          subTitle: "Datos de aseguradora",
        }}
        dataCite={{
          data: printDataCite,
          subTitle: "Datos de la cita",
        }}
      >
        <Box>
          <Box>
            <Box>
              <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                &nbsp;Motivo
                <span className={`${styles.ordOrangeText}`}>*</span>
              </Box>

              <Select
                isDisabled
                noOptionsMessage={() => "No hay datos"}
                className="text-secondary "
                placeholder={"Seleccionar..."}
                styles={ordCustomSelect}
                value={{ label: data?.cancelReason?.label, value: data?.cancelReason?.value }}
              />
            </Box>

            <Box my={2}>
              <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                &nbsp;Observaciones
                <span className={`${styles.ordOrangeText}`}>*</span>
              </Box>

              <textarea
                disabled
                placeholder="Escribir..."
                style={{ minHeight: "265px" }}
                className={`text-secondary ordInputAdmission w-100`}
                value={data?.cancelReason?.observation}
              />
            </Box>
          </Box>

          <Box display={"flex"} justifyContent='end'>
            <button
              className={`${styles.btnReplaceCancel}`}
              onClick={() =>{
                setData(state=>({...state, showModalDetail: false}))
              }}
            >
              Cancelar
            </button>
          </Box>
        </Box>

      </ServiceOrderReplace>

      <OrdGenericTemplate
        className="w-95 my-4"
        showBottomBorder={false}
        title={"Órdenes anuladas"}
        titleClass={"px-2"}
        titleSize={12}
      >
        <div className="mx-2 my-2 asistencialDateColorIcon">
          <Row className="d-flex px-1">
            <Col xs={2} className={`px-0 align-self-end`}>
              <Form.Group className="px-2" controlId="ordType">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                  <span>&nbsp;Estado</span>
                </Form.Label>
                <Select
                  noOptionsMessage={() => "No hay datos"}
                  options={listServiceOrderFilterState?.results?.length
                    ? [{ label: 'Seleccionar...', value: '' }, ...listServiceOrderFilterState?.results?.map((el) => ({
                      label: el?.description,
                      value: el?.value
                    }))]
                    : [{ label: 'Seleccionar...', value: '' }]
                  }
                  className="text-secondary"
                  placeholder={"Seleccionar..."}
                  styles={ordCustomSelect}
                  onChange={(e) => {
                    setData((state) => ({
                      ...state,
                      filters: { ...state.filters, status: e?.value },
                      trigger: state.trigger + 1,
                    }));
                  }}
                />
              </Form.Group>
            </Col>

            <Col xs={2} className={`px-0 align-self-end`}>
              <Form.Group className="px-2" controlId="ordType">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                  <span>&nbsp;Fecha desde</span>
                </Form.Label>
                <input
                  className={`ord-roundInput2`}
                  style={filters.dateMin ? { color: '#1a538d' } : {}}
                  type="date"
                  id="date-start"
                  value={filters.dateMin}
                  onChange={(e) => {
                    setData((state) => ({
                      ...state,
                      filters: { ...state.filters, dateMin: e.target.value },
                    }));
                  }}
                  min={today()}
                />
              </Form.Group>
            </Col>

            <Col xs={2} className={`px-0 align-self-end`}>
              <Form.Group className=" px-2" controlId="ordType">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                  <span>&nbsp;Hasta</span>
                </Form.Label>
                <input
                  className={`ord-roundInput2`}
                  type="date"
                  id="date-end"
                  value={filters.dateMax}
                  style={filters.dateMax ? { color: '#1a538d' } : {}}
                  onChange={(e) => {
                    setData((state) => ({
                      ...state,
                      filters: { ...state.filters, dateMax: e.target.value },
                    }));
                  }}
                  min={filters.dateMin}
                />
              </Form.Group>
            </Col>

            <Col xs={4} className="align-self-end">
              <Form.Group className=" text-start" controlId="fName">
                <div
                  className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}
                >
                  <input
                    className={`ord-roundInput ${styles.appInputDate} w-100`}
                    type="text"
                    placeholder="Escribe aquí para buscar"
                    autoComplete="off"
                    onChange={(e) => {
                      setData((state) => ({
                        ...state,
                        filters: { ...state.filters, search: e.target.value },
                      }));
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onChangeTrigger();
                      }
                    }}
                  />
                  <img
                    src={ordBlueSearch}
                    className="pointer"
                    alt="buscar"
                    onClick={() => onChangeTrigger()}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col xs={2} className={`align-self-end d-flex justify-content-end`}>
              <div
                className={styles.backGroundAquamarineHover}
                style={{
                  borderRadius: '5px',
                  padding: '0px 7px'
                }}
              >
                <Text
                  classNameBox={`${styles.hoverOrdAquaMarine}`}
                  title={'Anular orden'}
                  iconPath={addCheck}
                  iconHeight='18px'
                  fontWeight={'bold'}
                  handleClick={handleClick}
                  pointer
                  padding={'3px 7px'}
                  color={colors.ordDarkGray}
                  classNameImg={styles.colorGray}

                />
              </div>

              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Box>
                  <Text
                    classNameBox={styles.customPopover}
                    title={'Órdenes de servicio'}
                    iconPath={orderIcon}
                    iconHeight='15px'
                    pointer
                    color={colors.ordDarkGray}
                    classNameImg={styles.colorGray}
                    textPosition={1}
                    iconPosition={0}
                  />

                  <Text
                    classNameBox={styles.customPopover}
                    color={colors.ordDarkGray}
                    title={'Órdenes de proveedores'}
                    iconPath={estetoscopicBlue}
                    iconHeight='15px'
                    classNameImg={styles.colorGray}
                    textPosition={1}
                    iconPosition={0}
                    pointer
                  />
                </Box>
              </Popover>
            </Col>

          </Row>
          {/* SECTION TABLE */}
          <Row>
            <div>
              <OrdTable
                shadow
                headers={isCoordAdmin ? headersCoordinador : headers}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                //   fTdClearBlue={true}
                paginate={{
                  activePage: filters.page,
                  totalPages: listServiceOrder?.rowTotal,
                  perPage: filters.perpage,
                  pageRangeDisplayed: 3,
                  onChangePage: async (e) => {
                    setData((state) => ({ 
                      ...state, 
                      trigger: trigger + 1,
                      filters:{
                        ...state.filters,
                        page: e
                      }
                    }));
                  },
                  showTextDetails: true,
                }}
              >
                {formatData(listServiceOrder?.results || [])}
              </OrdTable>
            </div>
          </Row>
                
        </div>
      </OrdGenericTemplate>
    </>
  );
};