import React, { useState } from "react";
import { Card, Col, Form, Row } from 'react-bootstrap'
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
import { useSelector } from 'react-redux';
import { usePostMethod } from '../../Hooks/useFetch';
import { loader, validateEmptyString, message, swalConfirm, today, day, isEmptyOrUndefined, getPermission } from '../../helpers/helpers';
import FullScreenDialog from '../OrderingComponents/FullScreenDialog';
import { RenderMedicalFormulasPdf } from '../../AsistanceModule/RenderMedicalFormulasPdf';
export const AttendanceCertTab = (patientData) => {
    const store = useSelector((state) => state);
    const loginReducer = store?.loginReducer
    const doctorFullNameStore =`${loginReducer?.user_data?.first_name} ‍‍‍‍‍${loginReducer?.user_data?.first_surname} ${loginReducer?.user_data?.second_surname}`
    const listPermission = store.loginReducer.currentAccount?.profile?.permission
    const myPermission = getPermission({ prefix: 'order', listPermission })
    const doctorName = `${patientData?.patientData?.medFirstName}`
    const doctorSurname = `${patientData?.patientData?.medFirstSurname}`
    const doctorFullName = `${doctorName} ‍‍‍‍‍${doctorSurname} `
    const signatureDoctor = patientData?.patientData?.medSignature
    const { load: createOrderLoading, trigger: createOrder } = usePostMethod()
    const [fullDialogScreen, setFullDialogScreen] = useState({
        show: false,
        id: ""
    })
    const token = store?.loginReducer?.Authorization;
    const [data, setData] = useState({
        observations: "",
        dateCert: "",
    })

    function myMinDate() {
        let minDay = today().split("-")
        let todayDay = minDay[2]
        minDay[2] = String(todayDay - 2)
        if (minDay[2] < 10) {
            minDay[2] = "0" + minDay[2]
        }
        let lastDate = minDay.join("-")
        return lastDate
    }
    let validations = validateEmptyString([
        { field: data.dateCert, whithOutAlert: true },
        { field: data?.observations, whithOutAlert: true },
    ]);
    const testingTime = () => {
        let final = false
        let userDay = data.dateCert.split("-")
        let splittedDay = Number(userDay[2])
        let today = Number(day())
        let calculatedDay = Math.abs(today - splittedDay)

        if (calculatedDay >= 3) {

            final = false
        } else {

            final = true
        }
        return final
    }
    function handleSubmit() {
        createOrder({
            url: "/medical/assistCertificationOrder/",
            token: token,
            method: "POST",
            noAlert: true,
            body: {
                clhId: patientData?.patientData?.clhId,
                morObservation: data?.observations,
                morDate: data.dateCert,
            },
            succesAction: (results) => {
                setFullDialogScreen({
                    show: true,
                    id: results.results
                })
            }
        });
    }

    return (
        <>
            <FullScreenDialog
                onHide={() => setFullDialogScreen(false)}
                isOpen={fullDialogScreen.show}
            >
                <RenderMedicalFormulasPdf
                    pdfParams={{
                        url: '/medical/assistCertificationOrderPDF/',
                        id: fullDialogScreen.id,
                    }}
                    title={'Certificado de asistencia'}
                    finalize={{
                        title: "Finalizar",
                        finalizeAction: () => {
                            setData({
                                dateCert: "",
                                observations: "",
                            });
                            message('success', 'Certificado de asistencia generado');
                            setFullDialogScreen(false)
                        }
                    }}
                    backAction={() => {
                        setData({
                            dateCert: "",
                            observations: "",
                        });
                        setFullDialogScreen(false)
                    }}
                />
            </FullScreenDialog>
            <div className={` ${tableStyles.ordContainer}  mt-3 p-3`}>
                {createOrderLoading && loader}
                {/* {initialListLoader && loader} */}
                <Row
                    style={{ marginBottom: '28%' }}
                    className={`${tableStyles.ordSearchSections} mx-5 pt-2 p-2 text-start`}>

                    <Col xs={12} className={`text-center `} >
                        <h3 className={`${tableStyles.ordDarkBlueText}`} >
                            <b>
                                Certificado de asistencia
                            </b>
                        </h3>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={9} className={``}>
                                <p className={'text-secondary pt-4'}>Se certifica que el paciente de la referencia asistió a la institución el dia</p>
                            </Col>
                            <Col xs={3}>
                                <Form.Group controlId="CertDate">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                        <b className="text-start">&nbsp;Fecha</b>
                                    </Form.Label>
                                    <Form.Control className={`ord-roundInput ${tableStyles.ordGrayOrdersInputs}`} type="date" placeholder=""
                                        value={data.dateCert}
                                        onChange={(e) => {
                                            setData({ ...data, dateCert: e.target.value });
                                        }}
                                        max={today()}
                                        min={myMinDate()}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="fName">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                <b className="text-start">&nbsp;Observaciones</b>
                            </Form.Label>
                            <textarea
                                onChange={(e) => setData({ ...data, observations: e.target.value })}
                                value={data.observations}
                                placeholder={"Escribir..."}
                                rows="25"
                                cols="55"
                                style={{ height: "3rem" }}
                                className={`text-secondary ord-roundInput w-100`}
                            ></textarea>
                        </Form.Group></Col>
                    <Col xs={5}>
                        <Card
                            className={`bordered ${tableStyles.ordBordersemiCard} d-flex align-items-center `}
                        >
                            {!isEmptyOrUndefined(signatureDoctor) ?
                                <img src={signatureDoctor}
                                    alt="firma"
                                    className="p-2"
                                    style={{ width: '15rem', height: '5rem' }}
                                />
                                :
                                <div className="d-flex justify-content-center align-content-center h-100 align-items-center">
                                    <div className={`${tableStyles.tlnTextGray} ${tableStyles.f14} px-4 py-2 text-center `}>
                                        <span className="align-self-center">No hay firma disponible</span>
                                    </div>
                                </div>
                            }

                        </Card>
                    </Col>
                    <Col xs={12} className='mt-3' style={{marginLeft:'4px'}}>
                        <p className={'text-secondary '}>
                          <b>Médico: </b>{ (doctorFullName?.includes('null') || doctorFullName?.length <= 0 ) ? doctorFullNameStore : doctorFullName }
                        </p>
                    </Col>
                </Row>
                <Row className={`${tableStyles.ordBorderTop} mt-2`}>
                    <Col xs={12} className="d-flex justify-content-end pt-3 mb-3">
                        <button className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                        {
                            myPermission?.create && (
                                <button
                                    onClick={() => {
                                        !testingTime() ? message('info', 'Verifique la fecha ingresada', 'Debe seleccionar unicamente las fechas que se encuentran activas en el calendario') :
                                            validations.valid === true ? swalConfirm({
                                                title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                                                text: `Se generará certificado de asistencia para ${patientData?.patientData?.firstName} ${patientData?.patientData?.lastName}`,
                                                confirmButtonText: "Si, continuar",
                                                doAfterConfirm: () => handleSubmit(),
                                            }) : message('info', 'Debe llenar todos los campos')
                                    }}
                                    className={`${tableStyles.ordBtnPrimary} btn`}>
                                    Generar
                                </button>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </>
    )
}
