
import {
  GET_BANK_NOTES,
  GET_DOCUMENTS,
  GET_EGRESS,
  GET_RECEIPTBOX,
  GET_RECONSIGNMENTS,
  GET_PAYMENT_TYPES,
} from "../actions/actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { message } from "../helpers/helpers";
import { customSwaltAlert } from "../helpers";

const MySwal = withReactContent(Swal);

export const getBankNotes = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_BANK_NOTES,
    loading:true
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/banking_notes/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_BANK_NOTES,
        payload: results,
        total: total,
        loading:false
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_BANK_NOTES,
        payload: [],
        total: 0,
        loading:false
      });
      console.error(err.message);
    });
};

export const getDocuments = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_DOCUMENTS,
    loading : true
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/documents/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_DOCUMENTS,
        payload: results,
        total: total,
        loading : false
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_DOCUMENTS,
        payload: [],
        total: 0,
        loading : false
      });
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "No se pudo traer los registros",
      });
    });
};

export const getEgress = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_EGRESS,
    loading: true
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/payments/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_EGRESS,
        payload: results,
        total: total,
        loading: false
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_EGRESS,
        payload: [],
        total: 0,
        loading: false
      });
      MySwal.fire({
        icon: "error",
        title: "Error al consultar",
        text: "No se pudo traer los registros",
        footer: "Si el problema persiste comuníquese con un asesor.",
      });
    });
};

export const getReceiptBox = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_RECEIPTBOX,
    loading:true
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/collections_ack/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_RECEIPTBOX,
        payload: results,
        total: total,
        loading: false
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_RECEIPTBOX,
        payload: [],
        total: 0,
        loading: false
      });
      MySwal.fire({
        icon: "error",
        title: "Error al consultar",
        text: "No se pudo traer los registros",
        footer: "Si el problema persiste comuníquese con un asesor.",
      });
    });
};

export const getReconsigments = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_RECONSIGNMENTS,
    loading: true
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/check_redeposit/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_RECONSIGNMENTS,
        payload: results,
        total: total,
        loading: false
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_RECONSIGNMENTS,
        payload: [],
        total: 0,
        loading: false
      });
      MySwal.fire({
        icon: "error",
        title: "Error al consultar",
        text: "No se pudo traer los registros",
        footer: "Si el problema persiste comuníquese con un asesor.",
      });
    });
};

export const getPaymentTypes = (req, res) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/payment_types/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: GET_PAYMENT_TYPES,
        payload: results,
      });
    })
    .catch((err) => {
      MySwal.fire({
        icon: "error",
        title: "Error al consultar",
        text: "No se pudo traer los registros",
        footer: "Si el problema persiste comuníquese con un asesor.",
      });
    });
};
export const getPdfTest = async (data, token) => {
  let filters = convertFilterToString(data);
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/treasury/pdf_doc/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    console.error(error.message);
    // return message("error", "Error", "No ha sido posible cargar el documento");
  }
};

export const getPdfVoucherHistory = async (data, token) => {
  let filters = convertFilterToString(data);
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/export/journalVoucherPdf/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "No ha sido posible cargar el documento");
  }
};

export const getPdfCashFlow = async (data, token) => {
  let filters = convertFilterToString(data);
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/export/cashFlowPdf/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "No ha sido posible cargar el documento");
  }
};


export const getPdfrestultStatus = async (data, token) => {
  let filters = convertFilterToString(data);
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/export/restultStatus/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    console.error(error.message);
    // return message("error", "Error", "No ha sido posible cargar el documento");
  }
};

export const getPdfgeneralBalance = async (data, token) => {
  let filters = convertFilterToString(data);
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/export/generalBalance/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    console.error(error.message);
    // return message("error", "Error", "No ha sido posible cargar el documento");
  }
};