//-------------- components 🍕   --------------------------------------------
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { OrdGenericTemplate } from "../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import OrdTable from "../../OrderingModule/OrderingComponents/OrdTable";
import CustomPopupExtend from "../../../src/components/Popup/customPopUpExtends";
import Select from "react-select";
import { ordCustomSelect } from "../../../src/components/Layouts/react-select-custom";
import { OrdSlideDownButton } from "../../OrderingModule/OrderingComponents/OrdSlideDownButton";
import { downloadExcel, downloaFile, formatToRcSelect, getPermission, message } from '../../helpers/helpers';
import { useHistory } from "react-router-dom";
import DatePicker from "sassy-datepicker";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { tlnCustomSelect } from '../../../src/components/Layouts/react-select-custom';

//-------------- styles 🍕   --------------------------------------------
import tableStyles from "../../../src/components/Layouts/tableStyle.module.scss";
import CustomPopupStyles from "../../../src/components/Popup/customPopup.module.scss";
import ordComponentStyles from "../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss";
import styles from "./cardsStyles.module.scss"
//-------------- icons 🍕   --------------------------------------------
import threeDots from "../../assets/img/icons/threeDots.svg";
import Download from "../../assets/img/icons/pdfClearBlueIcon.svg";
import DownloadExcel from "../../assets/img/icons/excelVeryClearBlue.svg";
import ordBlueSearch from "../../assets/img/icons/ordBlueSearch.svg";
import OrdLupa from "../../assets/img/icons/OrdLupa.svg";
import downGrey from "../../assets/img/icons/desplegarGris.svg";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../helpers/helpers";
import { CompareArrowsOutlined } from "@material-ui/icons";
import Attach from "../../assets/img/icons/insertar.svg";


// ---------------- functions 🛬 ---------------------------//
import { responseSwal } from "../../helpers/responseSwal";
import OrdModal from "../../OrderingModule/OrderingComponents/OrdModal";

function ConsultAppointments(props) {
  //-------------- const 🍕   --------------------------
  const history = useHistory();
  const [data, setData] = useState([]);
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const listPermission = store.loginReducer.currentAccount?.profile?.permission
  const myPermission = getPermission({ listPermission, prefix: 'contulCit' })
  const [trigger, setTrigger] = useState(0);
  const [showModalJust, setShowModalJust] = useState({ show: false });
  const [showModal, setShowModal] = useState({
    status: false,
    date: "",
    idApp: "",
    idService: "", idPopulation: "", idContractCorporateClient: "", statusApp: ""
  });
  const [showModal2, setShowModal2] = useState({
    status: false,
  });
  const [showFields, setShowFields] = useState({
    status: false,
  });
  const [fields, setFields] = useState({
    idAPP: "",
    transacCode: "",
    bank: "",
    file: "",
    typeFile: "",
    idUser: "",
    fileName: ""
  });

  const [filters, setFilters] = useState({
    doctor: "",
    company: "",
    status: "",
    startDate: "",
    endDate: "",
    search: "",
    page: 1,
    perpage: 10,
    excel: 1
  });

  const [filtersModal, setFiltersModal] = useState({
    date: new Date(),
  });
  const [doctor, setDoctor] = useState({});
  const [schedule, setSchedule] = useState({});
  const [rotate, setRotate] = useState(false);
  const { results: cancelMotiveResults, load: cancelMotiveLoader, trigger: getCancelMotive } = useGetMethod();
  const cancelOptions = formatToRcSelect(cancelMotiveResults?.results, "canId", "canDescription", "", "", "");

  const { results: ListApp, load: ListAppLoader, trigger: getListApp, } = useGetMethod();
  const { results: ListStateApp, trigger: getListStateApp } = useGetMethod();
  const { results: ListCompany, trigger: getListCompany } = useGetMethod();
  const { results: ListDoctors, trigger: getListDoctors } = useGetMethod();
  const { results: docPdf, load: docPdfLoader, trigger: getDocPdf } = useGetMethod();
  const { load: updateAppLoad, trigger: updateApp } = usePostMethod()
  const { load: hoursLoad, results: hours, trigger: getListHours } = useGetMethod();
  const headers = [
    {
      title: "Paciente",
      className: "text-start px-2",
    },
    {
      title: "Médico",
      className: "text-start px-2",
    },
    {
      title: "Tipo",
      className: "text-center"
    },
    {
      title: "Fecha Solicitud",
    },
    {
      title: "Fecha de cita",
    },
    {
      title: "Empresa",
      className: "text-start px-2",
    },
    {
      title: "Valor",
      className: "text-end px-2"
    },
    {
      title: "Duración",
      className: "text-center"
    },
    {
      title: "Estado",
      className: "text-center"
    },
    {
      title: <>&nbsp;</>,
    },
  ];

  //-------------- useEffect 🍕   --------------------------

  useEffect(() => {
    setFilters({ ...filters, page: 1 })
    if (filters.endDate !== ""){
        if(filters.startDate < filters.endDate){
          getListApp({
            url: "/medical/generals/appointments/",
            objFilters: filters,
            token: token,
            doAfterSuccess: (result) => { formatData(result.results) }
          });
        } else{
          message("info", "Validar campos", "La fecha del campo 'Fecha Hasta' debe ser mayor al la fecha del campo 'Fecha desde'")
        }
    }else{
      getListApp({
        url: "/medical/generals/appointments/",
        objFilters: filters,
        token: token,
        doAfterSuccess: (result) => { formatData(result.results) }
      });
    }
  }, [filters.doctor, filters.company, filters.status, trigger]);
  useEffect(() => {
    getListApp({
      url: "/medical/generals/appointments/",
      objFilters: filters,
      token: token,
      doAfterSuccess: (result) => { formatData(result.results) }
    });
  }, [filters.page]);

  useEffect(() => {
    getListStateApp({
      url: "/medical/generals/stateAppointments/",
      token: token,
    });
    getListCompany({
      url: "/medical/generals/company/",
      token: token,
    });
    getListDoctors({
      url: "/medical/generals/doctors/",
      token: token,
    });
    getCancelMotive({
      url: "/medical/generals/cancellationReason/",
      // objFilters: filters,
      token: token,
    });    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!showModal.idPopulation && !!showModal.idContractCorporateClient && !!showModal.idService && !!showModal.statusApp && !!showModal.idDoctor && !!showModal.idApp) {
      getListHours({
        url: "/medical/appointment/availabilitySelectionByOneSpecialist/",
        token: token,
        objFilters: {
          dateSearch: moment(filtersModal.date).format("YYYY-MM-DD"),
          idPopulation: showModal.idPopulation,
          rv: "True",
          idContractCorporateClient: showModal.idContractCorporateClient,
          idService: showModal.idService,
          statusApp: showModal.statusApp,
          idApp: showModal.idApp,
          idDoctor: showModal.idDoctor,
          typeSearch: "reprogram",
          project: "appointment",
        }
      })
    }

  }, [showModal.idApp, filtersModal.date]);



  //-------------- options Selects 🍕   --------------------------
  let optionsDoctors = [
    { key: "default", value: "", label: "Todos", id: "" },
  ];
  if (Array.isArray(ListDoctors?.results)) {
    ListDoctors.results.forEach((item) => {
      optionsDoctors.push({
        value: item.idDoctor,
        label: item.nameDoctor,
        key: item.idDoctor + "doctor",
      });
    });
  }

  let optionsCompany = [
    { key: "default", value: "", label: "Todas", id: "" },
  ];
  if (Array.isArray(ListCompany?.results)) {
    ListCompany.results.forEach((item) => {
      optionsCompany.push({
        value: item.idCorporateClient,
        label: item.nameCorporateClient,
        key: item.idCorporateClient + "company",
      });
    });
  }

  let optionsState = [
    { key: "default", value: "", label: "Todos", id: "" },
  ];
  if (Array.isArray(ListStateApp?.results)) {
    ListStateApp.results.forEach((item) => {
      optionsState.push({
        value: item.statusTag,
        label: item.description,
        key: item.statusTag + "status",
      });
    });
  }

  //-------------- format data to table 🍕   --------------------------
  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array?.forEach((e, index) => {
        let container = (
          <div
            className="rounded-pill p-1"
            style={{ backgroundColor: `${e?.bgColorStatus}` }}
          >
            <b style={{ color: `${e?.fontColorStatus}` }}>{e?.statusApp}</b>
          </div>
        );
        tempList.push([
          {
            text: <> <div> <div>{e?.namePatient}</div><div>{e?.documentPatient}</div></div></>,
            className: "text-start px-2"
          },
          {
            text: <> <div> <div>{e?.nameDoctor}</div><div>{e?.documentDoctor}</div></div></>,
            className: "text-start px-2"
          },
          {
            text: e?.type,
            className: "text-center"
          },
          {
            text: e?.applicationDateApp,
            className: "px-2"
          },
          {
            text: e?.dateAttentionApp,
            className: "px-2"
          },
          {
            text: e?.company,
            className: "text-start px-2"
          },
          {
            text: "$" + numberWithCommas(e?.price),
            className: "text-end col-1"
          },
          {
            text: e?.appDuration,
            className: "text-center col-1"
          },
          container,
          (e.statusApp !== "Pendiente por validación" && e.statusApp !== "Pendiente de autorización") && (
            <CustomPopupExtend
              triggerSrc={threeDots}
              extraButtons={
                (e.statusApp === "Pendiente de pago")
                  ? [
                    myPermission?.create ? (
                      {
                        text: "Ver detalle",
                        event: () => history.push({
                          pathname: "/telemedicina/detalleCita/",
                          state: {
                            idApp: e?.idApp,
                          },
                        }),
                        class: CustomPopupStyles.popUpGeneric
                      }
                    ) : { event: () => history.push() },
                    {
                      text: "Anular",
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => setShowModalJust({ show: true, idApp: e?.idApp }),
                    },
                    {
                      text: "Editar",
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => setShowModal2({ ...showModal2, idApp: e.idApp, statusApp: e.statusApp, status: true, namePatient: e.namePatient, type: e.type, doctor: e.nameDoctor, date: e.dateAttentionApp, value: e.price, serviceName: e.serviceName }),
                    }
                  ]
                  :
                  (e.statusApp === "Atención pendiente")
                    ? [
                      myPermission?.create ? (
                        {
                          text: "Ver detalle",
                          event: () => history.push({
                            pathname: "/telemedicina/detalleCita/",
                            state: {
                              idApp: e?.idApp,
                            },
                          }),
                          class: CustomPopupStyles.popUpGeneric
                        }) : { event: () => history.push() },
                      {
                        text: "Anular",
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => setShowModalJust({ show: true, idApp: e?.idApp }),
                      },
                      myPermission?.edit ? (
                        {
                          text: "Reprogramar",
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => setShowModal({ ...showModal, status: true, date: e?.dateApp, idApp: e?.idApp, idService: e?.idService, idPopulation: e?.idPopulation, idContractCorporateClient: e?.idContractCorporateClient, statusApp: e?.statusApp, idDoctor: e?.idDoctor }),
                        }
                      ) : { event: () => history.push() },
                    ]
                    :
                    (e.statusApp === "Atención parcial") ?
                      [
                        myPermission?.create ? (
                          {
                            text: "Ver detalle",
                            event: () => history.push({
                              pathname: "/telemedicina/detalleCita/",
                              state: {
                                idApp: e?.idApp,
                              },
                            }),
                            class: CustomPopupStyles.popUpGeneric
                          }) : { event: () => history.push() },
                        myPermission?.edit ? (
                          {
                            text: "Reprogramar",
                            class: CustomPopupStyles.popUpGeneric,
                            event: () => setShowModal({ ...showModal, status: true, date: e?.dateApp, idApp: e?.idApp, idService: e?.idService, idPopulation: e?.idPopulation, idContractCorporateClient: e?.idContractCorporateClient, statusApp: e?.statusApp, idDoctor: e?.idDoctor }),
                          }

                        ) : { event: () => history.push() },
                      ]
                      :
                      (e.statusApp === "Fallida") ?

                        [
                          myPermission?.create ? (
                            {
                              text: "Ver detalle",
                              event: () => history.push({
                                pathname: "/telemedicina/detalleCita/",
                                state: {
                                  idApp: e?.idApp,
                                },
                              }),
                              class: CustomPopupStyles.popUpGeneric
                            }) : { event: () => history.push() },
                          myPermission?.edit ? (
                            {
                              text: "Reprogramar",
                              class: CustomPopupStyles.popUpGeneric,
                              event: () => setShowModal({ ...showModal, status: true, date: e?.dateApp, idApp: e?.idApp, idService: e?.idService, idPopulation: e?.idPopulation, idContractCorporateClient: e?.idContractCorporateClient, statusApp: e?.statusApp, idDoctor: e?.idDoctor }),
                            }
                          ) : { event: () => history.push() },
                        ]
                        : myPermission?.create ? (
                          [
                            {
                              text: "Ver detalle",
                              class: CustomPopupStyles.popUpGeneric,
                              event: () => history.push({
                                pathname: "/telemedicina/detalleCita/",
                                state: {
                                  idApp: e?.idApp,
                                },
                              }),
                            }
                          ]
                        ) : []

              }
            >
            </CustomPopupExtend>),
        ]);
      });
    }
    setData(tempList);
    // return tempList;
  };

  let buttonsSlideDown = [
    {
      button: <img alt="btn" src={OrdLupa}></img>,
    },
    {
      button: (
        <img
          alt="btn"
          style={{
            transform: !rotate && "rotate(180deg)",
          }}
          width={10}
          src={downGrey}
        ></img>
      ),
    },
  ];

  //-------------- Actions 🍕   --------------------------
  const downloadDoc = () => {
    getDocPdf({
      url: '/medical/generals/appointmentExportPdf/',
      objFilters: filters,
      token: token,
      doAfterSuccess: (results) => downloaFile(results.key)
    });
  }
  const downloadExcelx = () => {
    getDocPdf({
      url: '/medical/generals/appointmentExportExcel/',
      objFilters: filters,
      token: token,
      doAfterSuccess: (results) => downloadExcel(results.results, 'Tabla consulta de citas')
    });
  }

  const methodUpdate = (data) => {
    if (!data.canId) {
      return responseSwal({
        icon: "info",
        title: "El motivo es obligatorio",
        text: "Debe seleccionar el motivo de la anulación de la cita.",
      });
    }
    updateApp({
      url: "/medical/appointment/",
      token: token,
      noAlert: true,
      method: "PUT",
      body: {
        id: data.idApp,
        status: "cancelled",
        canId: data.canId
      }
      ,
      doAfterSuccess: (results) => {
        message("success", "Cita anulada", "")
        setShowModalJust({ ...showModalJust, show: false, justification: "" })
        setTrigger(trigger + 1)

      },


    })

  }

  const methodSavePayment = (idApp) => {
    if (fields.file !== "" && fields.fileName !== "" && fields.typeFile !== "" && idApp) {
      updateApp({
        url: "/medical/appointment/registerPayment/",
        token: token,
        noAlert: true,
        method: "POST",
        body: {
          idAPP: idApp,
          transacCode: fields.transacCode,
          bank: fields.bank,
          file: fields.file,
          typeFile: fields.typeFile,
          idUser: store.loginReducer.user_data.id

        }
        ,
        doAfterSuccess: (results) => {
          message("success", "Cita actualizada", "")
          setTrigger(trigger + 1)
          setShowModal2({ ...showModal2, status: false })
          setShowFields({ ...showFields, status: false })
          setFields({ ...fields, idAPP: "", transacCode: "", bank: "", file: "", typeFile: "", idUser: "", fileName: "" })

        },


      })
    } else {
      message("warning", "Dilegenciar campos obligatorios", "")
    }



  }


  const methodReprogram = (idApp, idDoctor, idSchedule, appDateTime, appEndDateTime) => {
    if (!!idApp && !!idDoctor && !!idSchedule && !!appDateTime && !!appEndDateTime) {
      let dateNew = appDateTime
      updateApp({
        noAlert: true,
        url: "/medical/appointment/",
        token: token,
        method: "PUT",
        body: {
          id: idApp,
          med_id: idDoctor,
          sch_id: idSchedule,
          status: "reprogrammed",
          appDatetime: appDateTime,
          appEndDatetime: appEndDateTime,

        }
        ,
        doAfterSuccess: (results) => {
          setShowModal({
            ...showModal,
            status: false,
            date: "",
            idApp: "",
            idService: "",
            idPopulation: "",
            idContractCorporateClient: "",
            statusApp: ""
          });
          setDoctor({ ...doctor, idDoctor: "" })
          setSchedule({ ...schedule, idSchedule: "", hourStart: "", hourEnd: "" })
          setTrigger(trigger + 1)
          message("success", "Fecha reprogramada", "Nueva fecha " + dateNew)

        }
      })
    } else {
      message("info", "Señor usuario,", "Por favor seleccione especialista y horario para reprogramar su cita")
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };

  const handleClickDoctor = (e) => {
    if (doctor.idDoctor === e.idDoctor) {
      setDoctor({ idDoctor: "" });
    } else {
      setDoctor({
        ...doctor,
        idDoctor: e.idDoctor
      });
    }
  };

  const handleClickShedule = (e) => {
    if (schedule.hourStart === e.hourStart) {
      setSchedule({ idSchedule: "", hourStart: "" });
    } else {
      setSchedule({
        ...schedule,
        idSchedule: e.idSchedule,
        hourStart: e.hourStart,
        hourEnd: e.hourEnd,
      });
    }
  };

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const onChangeDoc = async (e) => {
    var f = e.target.files[0];
    if (f) {
      var name_doc = f.name;
      var ext = f.name.split(".")[1];
      var type_doc = f.type;
      if (ext !== "pdf" && ext !== "zip" && ext !== "rar") {
        return responseSwal({
          icon: "error",
          title: "El formato del archivo debe ser PDF o ZIP",
          footer: "El tamaño máximo permitido es de 5MB",
        });
      }
      let encode = await convertBase64(e.target.files[0]);
      let file64 = encode.split(",")[1]
      setFields({
        ...fields,
        fileName: name_doc,
        file: file64,
        typeFile: type_doc.split("/")[1]
      });
    }
  };

  //-------------- Principal return 🍕   --------------------------
  return (
    <div className={`${tableStyles.tlnContainer} mt-4`}>
      <OrdModal
        show={showModal.status}
        title="Cambio de fecha"
        size={"xl"}
        btnYesName={"Aceptar"}
        btnYesEvent={() => methodReprogram(showModal.idApp, doctor.idDoctor, schedule.idSchedule, moment(filtersModal.date).format("YYYY-MM-DD") + " " + schedule.hourStart, moment(filtersModal.date).format("YYYY-MM-DD") + " " + schedule.hourEnd)}
        btnNoEvent={() => { setShowModal({ ...showModal, status: false }); setDoctor({ ...doctor, idDoctor: "" }); setSchedule({ ...schedule, idSchedule: "", hourStart: "", hourEnd: "" }) }}
        onHide={() => {
          setShowModal({ ...showModal, status: false }); setDoctor({ ...doctor, idDoctor: "" }); setSchedule({ ...schedule, idSchedule: "", hourStart: "", hourEnd: "" })
        }}
      >

        <Row>

          <Col xs={6}>
            <Form.Group className="mb-3 text-start" controlId="fName">
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`} style={{ paddingLeft: "3px" }}>
                <b>Fecha actual</b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput`}
                type="date"
                placeholder=""
                value={showModal.date}
                readOnly
              />
            </Form.Group>
          </Col>

          <Col xs={6}>
            <Form.Group className="mb-3 text-start" controlId="fName">
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`} style={{ paddingLeft: "3px" }}>
                <b> Nueva fecha</b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput`}
                type="date"
                placeholder=""
                value={moment(filtersModal.date).format("YYYY-MM-DD")}
                readOnly
              />
            </Form.Group>
          </Col>


        </Row>
        <Row>
          <Col xs={3}>
            <DatePicker minDate={new Date()} onChange={(newDate) => setFiltersModal({ ...filtersModal, date: newDate })} selected={filtersModal.date} />
          </Col>

          {(hours?.results?.length > 0) ?
            <>
              {!!doctor.idDoctor ?
                <Col xs={5}>

                  <div style={{ overflow: "auto", boxShadow: "0px 0px 6px rgb(43 71 139 / 15%)", height: "275px", padding: "5px" }}>
                    {!!doctor.idDoctor ?

                      (hours?.results?.filter((x) => x.idDoctor === doctor.idDoctor))[0]?.byHour?.map((item, index) => {
                        return (
                          <div key={index} style={{ display: "flex", gap: "1rem" }}>
                            {item.schedules.map((item, index) => {
                              return (
                                <div key={index} className={styles.app_container_map}>
                                  <div
                                    className={
                                      item.hourStart === schedule.hourStart
                                        ? styles.app_container_hours_active
                                        : styles.app_container_hours
                                    }
                                    onClick={() => handleClickShedule(item)}
                                  >
                                    <span
                                      style={{
                                        color:
                                          item.hourStart === schedule.hourStart
                                            ? "#FFFFFF"
                                            : "#6E6F7C",
                                      }}
                                      className={styles.hours}
                                    >
                                      {item.hourStart}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })
                      :
                      []
                    }
                  </div>

                </Col>
                :
                <Col xs={5}>
                  <div style={{ boxShadow: "0px 0px 6px rgb(43 71 139 / 15%)", height: "275px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <span className={styles.app_title_card} style={{ textAlign: "center" }}>
                      Seleccione especialista
                    </span>

                  </div>


                </Col>
              }


              <Col xs={4}>
                <div className={styles.app_container_card_component}>
                  {hours?.results?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          item?.idDoctor === doctor.idDoctor
                            ? styles.app_card_doctor_active
                            : styles.app_card_doctor
                        }
                        onClick={() => handleClickDoctor(item)}
                      >
                        <div>
                          <img
                            className={styles.app_container_img}
                            src={item?.image}
                            alt="doctor-img"
                          />
                        </div>
                        <div className={styles.container_name_doctor}>
                          <span
                            className={styles.app_title_card}
                            style={{
                              color: item?.idDoctor === doctor.idDoctor ? "#FFFFFF" : "#6E6F7C",
                            }}
                          >
                            {item?.doctor}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </>
            :
            (<Col xs={9}>
              <div style={{ boxShadow: "0px 0px 6px rgb(43 71 139 / 15%)", height: "275px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <span className={styles.app_title_card} style={{ textAlign: "center" }}>
                  No hay médicos disponibles
                </span>

              </div>


            </Col>)

          }




        </Row>




      </OrdModal>
      <OrdModal
        show={showModal2.status}
        title="Editar estado"
        size={"n"}
        btnYesName={"Guardar"}
        btnYesEvent={() => methodSavePayment(showModal2.idApp)}
        btnNoEvent={() => { setShowModal2({ ...showModal2, status: false }); setShowFields({ ...showFields, status: false }); setFields({ ...fields, idAPP: "", transacCode: "", bank: "", file: "", typeFile: "", idUser: "", fileName: "" }) }}
        onHide={() => {
          setShowModal2({ ...showModal2, status: false }); setShowFields({ ...showFields, status: false }); setFields({ ...fields, idAPP: "", transacCode: "", bank: "", file: "", typeFile: "", idUser: "", fileName: "" })
        }}
      >
        <Row className="mb-3">
          <Col xs={12} className="d-flex mb-3 ">
            <b className={tableStyles.tlnTextDark}>Detalle</b>
          </Col>
          <Col xs={6} className="d-flex">
            <b className={`${tableStyles.tlnTextGray} fs-14px`}>Paciente</b>
          </Col>
          <Col xs={6} className="d-flex">
            <span className={`${tableStyles.tlnTextGray} fs-14px`}>{showModal2.namePatient}</span>
          </Col>
          <Col xs={6} className="d-flex">
            <b className={`${tableStyles.tlnTextGray} fs-14px`}>Tipo de atención</b>
          </Col>
          <Col xs={6} className="d-flex">
            <span className={`${tableStyles.tlnTextGray} fs-14px`}>{showModal2.type}</span>
          </Col>
          <Col xs={6} className="d-flex">
            <b className={`${tableStyles.tlnTextGray} fs-14px`}>Especialista</b>
          </Col>
          <Col xs={6} className="d-flex">
            <span className={`${tableStyles.tlnTextGray} fs-14px`}>{showModal2.doctor}</span>
          </Col>
          <Col xs={6} className="d-flex">
            <b className={`${tableStyles.tlnTextGray} fs-14px`}>Fecha</b>
          </Col>
          <Col xs={6} className="d-flex">
            <span className={`${tableStyles.tlnTextGray} fs-14px`}>{showModal2.date}</span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6} className="d-flex">
            <b className={`${tableStyles.tlnTextGray} fs-14px`}>Servicio</b>
          </Col>
          <Col xs={6} className="d-flex">
            <span className={`${tableStyles.tlnTextGray} fs-14px`}>{showModal2.serviceName}</span>
          </Col>
          <Col xs={6} className="d-flex">
            <b className={`${tableStyles.tlnTextGray} fs-14px`}>Valor</b>
          </Col>
          <Col xs={6} className="d-flex">
            <span className={`${tableStyles.tlnTextGray} fs-14px`}>{!!showModal2.value ? "$" + numberWithCommas(showModal2.value) : "-"}</span>
          </Col>

        </Row>

        {!!(showModal2.statusApp === "Pendiente de pago") && (!!(showFields.status === false)) && (
          <Row>
            <Col xs={12} className={"text-end mb-4"}>
              <button
                className={`${tableStyles.ordBtnDarkBlueSlim} w-100`}
                onClick={() => setShowFields({ ...showFields, status: true })}
                disabled={false}
              >
                Registrar pago con transferencia
              </button>
            </Col>
          </Row>
        )
        }
        {!!(showFields.status) && (
          <Row>
            <Col xs={12}>
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label
                  className={`m-0 ${tableStyles.ordDarkBlueText} fs-14px`}
                > <b>Código de transferencia <span className={"text-danger"}>*</span></b> </Form.Label>

                <div className={`d-flex ${tableStyles.ordInputSearch}`}>
                  <Form.Control
                    className={`ord-roundInput`}
                    style={{ color: "#6e6f7c" }}
                    type="text"
                    placeholder="Escribe..."
                    onChange={(e) => setFields({ ...fields, transacCode: e.target.value })}
                  />
                </div>

              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label
                  className={`m-0 ${tableStyles.ordDarkBlueText} fs-14px`}
                ><b>Entidad de origen <span className={"text-danger"}>*</span></b> </Form.Label>

                <div className={`d-flex ${tableStyles.ordInputSearch}`}>
                  <Form.Control
                    className={`ord-roundInput`}
                    style={{ color: "#6e6f7c" }}
                    type="text"
                    placeholder="Escribe..."
                    onChange={(e) => setFields({ ...fields, bank: e.target.value })}
                  />
                </div>

              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label
                  className={`m-0 ${tableStyles.ordDarkBlueText} fs-14px`}
                > <b>Anexar evidencia <span className={"text-danger"}>*</span></b> </Form.Label>
                <label
                  htmlFor="furatFile"
                  className={`bg-transparent w-100 ${tableStyles.tlnTextGray}`}
                  style={{ borderRadius: "5px", border: "1px solid #a3bad1", height: "32px", display: "flex", justifyContent: "space-between", paddingRight: "10px", padding: "0.25rem 0.4rem" }}
                >
                  <input
                    id="furatFile"
                    type="file"
                    name="furat"
                    accept="application/pdf"
                    className="d-none w-100"
                    onChange={(e) => onChangeDoc(e)}
                  />

                  {fields?.fileName !== "" ? fields?.fileName : "Cargar documento"}
                  <img src={Attach} alt="agregar archivo" width="14" />
                </label>
              </Form.Group>
            </Col>
          </Row>

        )

        }
      </OrdModal>
      <OrdModal
        title="Motivo de anulación"
        show={showModalJust.show}
        btnYesName={"Aceptar"}
        btnNoName={"Cancelar"}
        size={"200"}
        onHide={() => setShowModalJust({ show: false })}
        btnYesEvent={() => methodUpdate(showModalJust)}
        btnNoEvent={() => setShowModalJust({ show: false })}
      >
        <div className="m-4">
          <Select noOptionsMessage={() => 'No hay datos'}
            options={cancelOptions}
            className="text-secondary"
            value={cancelOptions.filter((x) => x.value === showModalJust.canId)}
            placeholder={"Seleccionar..."}
            onChange={(e) => {
              setShowModalJust({ ...showModalJust, canId: e.value });
            }}
            styles={ordCustomSelect}
          />
        </div>
      </OrdModal>
      {(ListAppLoader || docPdfLoader || hoursLoad || updateAppLoad || cancelMotiveLoader) && (
        // || detailPacientLoader
        <Loader
          className="loading"
          type="Oval"
          color="#003f80"
          height={100}
          width={100}
        />
      )}
      {

        <OrdGenericTemplate showBottomBorder={false} title={"Consulta de citas"}>
          {
            myPermission?.read && (
              <>
                <div className="d-flex justify-content-end p-0 cursorPointer">
                  <img alt={"downloadPDF"} style={{ marginRight: "1rem" }} src={Download} onClick={() => downloadDoc()} />
                  <img alt={"downloadExcel"} src={DownloadExcel} onClick={() => downloadExcelx()} />
                </div>
                <OrdSlideDownButton
                  onToggleClick={() => setRotate(!rotate)}
                  buttons={buttonsSlideDown}
                  accordionClassName={ordComponentStyles.OrdSlideButton2}
                >
                  <Row className={`px-2`}>
                    <Col xs={4}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`} style={{ paddingLeft: "3px" }}>
                          <b>Médico</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          options={optionsDoctors}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                          onChange={(e) => {setFilters({ ...filters, doctor: e.value })
                          setTrigger(trigger + 1)
                        }}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`} style={{ paddingLeft: "3px" }}>
                          <b>Empresa</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          options={optionsCompany}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                          onChange={(e) => {setFilters({ ...filters, company: e.value })
                            setTrigger(trigger + 1)
                            }}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`} style={{ paddingLeft: "3px" }}>
                          <b>Estado</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          options={optionsState}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                          onChange={(e) => {setFilters({ ...filters, status: e.value })
                          setTrigger(trigger + 1)
                        }}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col xs={2}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`} style={{ paddingLeft: "3px" }}>
                          <b>Fecha desde</b>
                        </Form.Label>
                        <Form.Control
                          className={`ord-roundInput`}
                          type="date"
                          placeholder=""
                          onChange={(e) => {setFilters({ ...filters, startDate: e.target.value })
                        }}
                          onKeyDown={e=>{
                            if (e.key === 'Enter') {
                              setTrigger(trigger + 1)
                          }
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={2}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`} style={{ paddingLeft: "3px" }}>
                          <b>Fecha hasta</b>
                        </Form.Label>
                        <Form.Control
                          className={`ord-roundInput`}
                          type="date"
                          placeholder=""
                          onChange={(e) => {setFilters({ ...filters, endDate: e.target.value })
                        }}
                          onKeyDown={e=>{
                            if (e.key === 'Enter') {
                              setTrigger(trigger + 1)

                          }
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={8}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label
                          className={`m-0 ${tableStyles.ordDarkBlueText}`}
                        ></Form.Label>
                        <form onSubmit={(e) => handleSearch(e)}>
                          <div className={`d-flex ${tableStyles.ordBlueInputSearch}`}>
                            <Form.Control
                              className={`ord-roundInput-search`}
                              type="text"
                              placeholder="Nombre del paciente o No. de ID"
                              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                            />
                            <img className='cursorPointer' src={ordBlueSearch} onClick={(e) => setTrigger(trigger + 1)} alt={"search"}></img>
                          </div>
                        </form>
                      </Form.Group>
                    </Col>
                  </Row>
                </OrdSlideDownButton>


                <OrdTable
                  headers={headers}
                  body={data}
                  className={tableStyles.ordTableShadow}
                  paginate={{
                    activePage: filters.page,
                    totalPages: ListApp?.rowTotal,
                    perPage: filters.perpage,
                    pageRangeDisplayed: 3,
                    onChangePage: async (e) => {
                      setFilters({ ...filters, page: e })
                    },
                    showTextDetails: true
                  }}
                ></OrdTable>
              </>
            )
          }
        </OrdGenericTemplate>

      }
    </div>
  );
}
export default ConsultAppointments;
