import React, { Component, useState, useEffect } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import Agregar from "../../assets/img/icons/aggdoc.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Atras from "../../assets/img/icons/atras-icon.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import ModalNew from "../../components/Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import Send from "../../assets/img/icons/send.svg";
import {
  sendInvoices,
  getListCompanies,
  getInvoicesType,
  getInvoicesToSend
} from "../../actions/billingActions";
import { count_users } from "../../actions/userActions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


function DetReceiptInvoicesInBilling(props) {

  const storage = useSelector((state) => state);
  const dispatch = useDispatch();

  const [sendinfo, setSendinfo] = useState({
    description: "",

  });


  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };


  const buttonReceive = () => {
    dispatch(
      sendInvoices({
        eaccount: storage.loginReducer.currentAccount.id,
        user: storage.loginReducer.user_data.id,
        invoices: props.selectedInvoices,
        fullname: storage.loginReducer.user_data.first_name + " " + storage.loginReducer.user_data.first_surname,
        description: sendinfo.description

      },
        () => {
          dispatch(getInvoicesToSend({ eaccount: storage.loginReducer.currentAccount.id }));
          dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
          dispatch(getInvoicesType({ eaccount: storage.loginReducer.currentAccount.id }));
          props.setDetails({ show: false });
        }
      )
    )
  }

  const header = [
    <th className="text-center">No. Factura </th>,
    <th className="text-center">Fecha factura</th>,
    <th className="text-end px-4">Valor</th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(props.selectedInvoices)) {
      table = props.selectedInvoices.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">
            <td >{x.sequence}</td>
            <td>{x.exp_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")}</td>
            <td className="text-end" style={{ paddingRight: "20px" }} >{"$" + numberWithCommas(x.total_amount)}</td>
          </tr>
        );
      });
    }
    return table;
  };

  var today = new Date();

  return (
    <>
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem", width: "60%" }}
      >
        <div className="d-flex">
          <img
            className={`${tableStyles.title} mr-3c`}
            src={Atras}
            style={{ cursor: "pointer" }}
            onClick={() => props.setDetails({ show: false })}
          />
          {(storage.billingReducer.loading_send_invoices) &&
            <div className="loading">
              <Loader
                type="Oval"
                color="#003f80"
                height={100}
                width={100}
              />
            </div>
          }
          <h1 className={tableStyles.title}>Acta de facturación</h1>
        </div>
        <div className="text-end">
          <p>{ }</p>
        </div>
        <Row className="d-flex">
          <Col>
            <p>
              Por medio del presente documento se da constancia de el envío de
              las facturas anexadas a el departamento de cartera, el día{" "}
              {String(today.getDate()).padStart(2, "0")}, del mes{" "}
              {String(today.getMonth() + 1).padStart(2, "0")} del{" "}
              {today.getFullYear()}.
            </p>
          </Col>
        </Row>

        <GenericTable className="mb-6" headers={header} dark={true}>
          {renderList()}
        </GenericTable>

        <div className="d-flex mt-5">
          <div className="col-4 mt-4">
            <p className={`${IndividualStyles.labelInfo} mb-0`}>
              {storage.loginReducer.user_data.first_name + " " + storage.loginReducer.user_data.first_surname}
            </p>
            <p className={`${IndividualStyles.labelInfo} mb-0`}>
              {!!(storage.loginReducer?.user_data?.payrollInfo) == ! null ? storage.loginReducer?.user_data?.payrollInfo?.job_title : "Departamento de facturación"}
            </p>
            <p className={`${IndividualStyles.labelInfo} mb-0`}>
              C.C:{storage.loginReducer?.user_data?.doc_num}
            </p>
          </div>
          <div className="col-8">
            <div className="display-grid">
              <label className={`${tableStyles.crudModalLabel} m-0`}>
                Descripción
              </label>
              <textarea
                style={{ outline: 0, padding: '10px' }}
                className={IndividualStyles.textAreaBig}
                name="textarea"
                rows="3"
                cols="50"
                value={sendinfo.description}
                onChange={(e) =>
                  setSendinfo({
                    ...sendinfo,
                    description: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>
        </div>
        <div className={IndividualStyles.bottomRow}>
          <Button
            style={{ border: 1, padding: 8 }}
            className={`${tableStyles.buttonExtra3} d-block`}
            onClick={() => buttonReceive()}
            disabled={!!(sendinfo.description == "")}
          >
            <img src={Send} alt="my image" style={{ height: "16px", weight: "16px" }} /> <span></span> Enviar a cartera
          </Button>
          <Button
            style={{ border: 1, padding: 8 }}
            className={tableStyles.btnSecondary}
            onClick={() => props.setDetails({ show: false })}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
}
export default DetReceiptInvoicesInBilling;