import React, { useEffect, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import LupaClara from "../../assets/img/icons/lupaClear.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
// import { Button, Col, Form, Row } from "react-bootstrap";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import iconBack from "../../assets/img/icons/iconBack.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
// import { SlideDownButton } from "../../components/Layouts/SlideDownButton";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { getEgress, getPaymentTypes, getPdfTest } from "../../actions/consultAction";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
//import paginationStyles from "../../components/Layouts/paginhandleClicktion.module.scss";
import { getProviders } from "../../actions/providersActions";
import { getNiifAccounts } from "../../actions/AccountsActions";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import { message } from "../../helpers/helpers";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";
function Egress(props) {
    let history = useHistory();
    function handleClick() {
        history.push("/tesoreria/TipoDeConsulta");
    }
    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }
    const ShowOptions = () => {
        if (showOpt?.show && showOpt?.arrow) {
            setShowOpt({
                ...showOpt,
                show: false,
                arrow: false,
            });
        } else {
            setShowOpt({
                ...showOpt,
                show: true,
                arrow: true,
            });
        }
    };
    const [showOpt, setShowOpt] = useState({
        show: false,
        arrow: false,
    });
    // eslint-disable-next-line no-unused-vars
    const [trigger, setTrigger] = useState(1);
    const [trigger2, setTrigger2] = useState(1);
    const counter = useSelector(state => state);//entra aca para acceder a todos los reducers
    const dispatch = useDispatch(); //usa este metodo para ejecutar un action
    const [showPdf, setShowPdf] = useState(false)
    const [base64, setBase64] = useState("")
    const getPdf = async (id) => {
        const result = await getPdfTest(
            { id: id, doc: 5 },
            counter.loginReducer.Authorization,
        )
        if (result?.success) {
            return setBase64(result.pdf[0].key.split("'")[1])
        } else {
            message('error', 'Ha ocurrido un error', result?.message)
        }
    }
    const [Egress, setEgress] = useState({
        entity_account: counter.loginReducer.currentAccount.id,
        eaccount: counter.loginReducer.currentAccount.id,
        page: 1,
        perpage: 10,
        id_pay_type: "",
        niif_account: "",
        provider_id: "",
        transaction_date_since: "",
        transaction_date_until: "",
        amount_from: "",
        amount_to: "",
    })
    // const [showModal, setShowModal] = useState(false)

    useEffect(
        () => {
            dispatch(getEgress(Egress))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            Egress.page,
            Egress.id_pay_type,
            Egress.niif_account,
            Egress.provider_id,
            Egress.transaction_date_since,
            Egress.transaction_date_until,
            trigger2
        ] //cada vez que esto cambie, ejecutar la funcion de arriba.
    );
    useEffect(() => {
        dispatch(getProviders({ slim: 1 }));
        dispatch(getNiifAccounts({ eaccount: counter.loginReducer.currentAccount.id, check_info_bank: 1 }));
        dispatch(getPaymentTypes({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);
    const handlePageChange = (val) => {
        setEgress({
            ...Egress,
            page: val
        });
        getEgress(Egress)
    }

    const BtnSearch = () => {
        resetReq();
        dispatch(getEgress(Egress))
    }

    const resetReq = () => {
        setEgress({
            ...Egress,
            page: 1,
            perpage: 10,

        });
    }
    const header = [

        <th className="text-center">No. Cpte </th>,
        <th className="text-center">Fecha</th>,
        <th className="text-center">NIT</th>,
        <th className="text-center">Nombre</th>,
        <th className="text-center">Cuenta</th>,
        <th className="text-center">Descripción</th>,
        <th className="text-center">Valor</th>,
        <th className="text-center">&nbsp;</th>
    ]
    const body = []
    if (Array.isArray(counter.consultReducer.egress)) {
        // eslint-disable-next-line array-callback-return
        counter.consultReducer.egress.map((item) => {
            body.push(
                <tr>
                    <td className="text-center">{item.jrnl_voucher}</td>
                    <td className="text-center">{convertDateToLatinFormat(item.transaction_date)}</td>
                    <td className="text-center">{item.nit ? item.nit : "-"}</td>
                    <td className="text-center">{item.provider_name}</td>
                    <td className="text-center">{item.account_nbr}</td>
                    <td className="text-center">{item.observations}</td>
                    <td className="text-center">$ {numberWithCommas(item.total_amount)}</td>
                    <td className="text-start">
                        <img src={Filtrar}
                            className={tableStyles.cursorPointer}
                            // eslint-disable-next-line no-sequences
                            onClick={() => (getPdf(item.id), setShowPdf(true))}
                            style={{ cursor: "pointer" }}
                            alt="filter"
                        />
                    </td>
                </tr>
            );
        })
    }
    let optionPaymentType = []
    optionPaymentType.push({
        value: "",
        label: "Seleccionar...",
        valueName: "a",
    })
    if (Array.isArray(counter.consultReducer.payment_types)) {
        // eslint-disable-next-line array-callback-return
        counter.consultReducer.payment_types.map((item) => {
            optionPaymentType.push({
                value: item.id,
                label: item.description,
            }
            );
        })
    }

    let optionProvider = []
    optionProvider.push({
        value: "",
        label: "Seleccionar...",
        valueName: "a",
    })
    if (Array.isArray(counter.providerReducer.providers)) {
        // eslint-disable-next-line array-callback-return
        counter.providerReducer.providers.map((item) => {
            optionProvider.push({
                value: item.id,
                label: item.nit + " - " + item.name,
                valueName: item.name
            }
            );
        })
    }
    var optionNiifAccounts = []
    optionNiifAccounts.push({
        value: "",
        label: "Seleccionar...",
        key: "qg",
    })
    if (Array.isArray(counter.pucReducer.niifAccounts)) {
        // eslint-disable-next-line array-callback-return
        counter.pucReducer.niifAccounts.map((item) => {
            optionNiifAccounts.push({
                value: item.id,
                label: item.complete_account + " - " + item.description,
                key: item.id + "qg",
            }
            )
        });
    }
    const handleSumit = (e) => {
        e.preventDefault();
        setTrigger2(trigger2 + 1)
    }
    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                {(counter.consultReducer.egress_loading) &&
                    <div className="loading">
                        <Loader
                            type="Oval"
                            color="#003f80"
                            height={100}
                            width={100}
                        />
                    </div>
                }
                <div 
                    className="d-flex mb-4" 
                    style={{ marginTop: '30px', alignItems: 'end' }}
                >
                    <Title
                        title="Consulta de egresos"
                        onClickIcon={handleClick}
                    />
                </div>

                <div>

                    <div>
                        <div
                            className="div align-items-end"
                            style={{ gap: '0.5rem 1rem', flexWrap: 'wrap' }}
                        >

                            <div className={tableStyles.containerSelect}>
                                <label className={tableStyles.stylesLabel}>Tipo de pago</label>
                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    placeholder="Seleccionar..."
                                    styles={customSelectNewDark}
                                    options={optionPaymentType}
                                    onChange={(e) => setEgress({
                                        ...Egress,
                                        id_pay_type: e.value,
                                    })}
                                />
                            </div>

                            <div className={tableStyles.containerSelect}>
                                <label className={tableStyles.stylesLabel}>Cuenta contable</label>
                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    placeholder="Seleccionar..."
                                    styles={customSelectNewDark}
                                    options={optionNiifAccounts}
                                    onChange={(e) => setEgress({
                                        ...Egress,
                                        niif_account: e.value,

                                    })}
                                />
                            </div>

                            <div className={tableStyles.containerSelect}>
                                <label className={tableStyles.stylesLabel}>Proveedor</label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    placeholder="Seleccionar..."
                                    styles={customSelectNewDark}
                                    options={optionProvider}
                                    onChange={(e) => setEgress({
                                        ...Egress,
                                        provider_id: e.value,
                                    })}
                                />
                            </div>

                            <div className={tableStyles.containerDate}>
                                <label className={tableStyles.stylesLabel}>Fecha desde</label>
                                <input
                                    className={IndividualStyles.registerInputsBlue}
                                    name="transaction_date_since"
                                    type="date"
                                    onChange={(e) => setEgress({
                                        ...Egress,
                                        transaction_date_since: e.target.value,
                                    })}
                                />
                            </div>

                            <div className={tableStyles.containerDate}>
                                <label className={tableStyles.stylesLabel}>Hasta</label>
                                <input
                                    className={IndividualStyles.registerInputsBlue}
                                    name="transaction_date_until"
                                    type="date"
                                    onChange={(e) => setEgress({
                                        ...Egress,
                                        transaction_date_until: e.target.value,
                                    })}
                                />
                            </div>

                            <div className={tableStyles.containerSelect}>
                                <label className={tableStyles.stylesLabel}>Valor desde</label>
                                <form onSubmit={handleSumit}>
                                    <input
                                        className={IndividualStyles.registerInputsGris}
                                        name="amount_from"
                                        type="number"
                                        placeholder="Escribir..."
                                        onChange={(e) => setEgress({
                                            ...Egress,
                                            amount_from: e.target.value,
                                        })}
                                    />
                                </form>
                            </div>

                            <div className={tableStyles.containerSelect}>
                                <label className={tableStyles.stylesLabel}>Valor hasta</label>
                                <form onSubmit={handleSumit}>
                                    <input
                                        placeholder="Escribir..."
                                        className={IndividualStyles.registerInputsGris}
                                        name="amount_to"
                                        type="number"
                                        onChange={(e) => setEgress({
                                            ...Egress,
                                            amount_to: e.target.value,
                                        })}
                                    />
                                </form>
                            </div>

                            <div>
                                <label>
                                    <img
                                        style={{ width: '25px' }}
                                        className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`}
                                        onClick={() => BtnSearch()}
                                        src={Lupa}
                                        alt="buscar"
                                    />
                                </label>
                            </div>

                        </div>
                    </div>
                </div>

                <GenericTable
                    headers={header}
                    children={body}
                    dark={true}
                >
                </GenericTable>
                <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                        Pag.{" "}
                        {counter.consultReducer.totalEgress
                            ? Egress.page
                            : ""}
                        {" de "}
                        {Math.ceil(counter.consultReducer.totalEgress / Egress.perpage)
                            ? Math.ceil(counter.consultReducer.totalEgress / Egress.perpage) : ""}{" "}
                        ({counter.consultReducer.totalEgress} encontrados)
                    </p>
                    <Pagination
                        activePage={Egress.page}
                        itemsCountPerPage={Egress.perpage}
                        totalItemsCount={counter.consultReducer.totalEgress}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext}
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                    />
                </div>
            </div>
            <ModalNew
                title="Consulta de egresos"
                show={showPdf}
                btnNoName={"Cancelar"}
                size="700"
                btnYesDisabled={false}
                // eslint-disable-next-line no-sequences
                onHide={() => (setBase64(""), setShowPdf(false))}
                // eslint-disable-next-line no-sequences
                btnNoEvent={() => (setBase64(""), setShowPdf(false))}
                btnNoDisabled={false}
            >
                <PdfViewer
                    downloadable
                    file={`data:application/pdf;base64,${base64}`}></PdfViewer>
            </ModalNew>
        </>
    )

};
export default Egress;