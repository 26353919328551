import Attach from "../../assets/img/icons/insertar.svg";
import React, { useEffect, useState } from "react";
import ModalNew from "../Layouts/ModalNew";
import Swal from "sweetalert2";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { defaultFilters, MAX_SIZE_FILE, PAGE, PERPAGE } from "../../helpers/constants";
import Dropzone from 'react-dropzone';
import withReactContent from "sweetalert2-react-content";
import { Button } from 'react-bootstrap';
import IndividualStyles from "./IndividualAuction_CCS.module.scss";
import Excel from "../../assets/img/icons/excel.svg";
import Word from "../../assets/img/icons/word.svg";
import PDF from "../../assets/img/icons/pdf.svg";




const MULTIPLE_REGEX_VALID_FILE = /^.+\.(([pP][dD][fF]))|(xlsx|xls|csv)|(doc|docx)$/;
let size=0;
let num=0;
const type_file= "csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, \
                application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, \
                application/pdf"
function ModalDocuments (props){

    const MySwal = withReactContent(Swal);
    //const [TotalSize, setTotalSize] = useState(0);
    
    const [filenameState, setfilenameState] = useState({  
      name_doc: "",
      type_doc:"",
    });
    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
  
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
    const validateFile = async (acceptedFile) => {
      let fileValid
      let type
      let file_nanme
      let valuesave
      
      let fileUpload
      size=acceptedFile[0].size+ size
      if (size > MAX_SIZE_FILE) {
        fileValid = false
        MySwal.fire({
          icon:'error',
          title:"El archivo es demasiado pesado",
          footer:"El tamaño máximo permitido es de 5MB"
        })
      } if (new RegExp(MULTIPLE_REGEX_VALID_FILE).test(acceptedFile.type)) {
        fileValid = false
        MySwal.fire({
          icon:'error',
          title:"El formato del archivo debe ser PDF, WORD, EXCEL",
          footer:"El tamaño máximo permitido es de 5MB"
        })
      } else{
        fileValid = true;
      }    
      if (fileValid) {
        
        let tempReq = props.reqState;
        tempReq.file=acceptedFile;
        props.setreqState(tempReq);

        
      }
    }
    const docType=(data) => {
      let iconDoc
      if(!!data){   
          switch(data){
              case "application/pdf":
                  iconDoc=PDF
                  break;
              case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/msword":
                  iconDoc=Word
                  break;
              case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "csv" || "application/vnd.ms-excel":
                  iconDoc=Excel
                  break;
              default:
                  iconDoc=PDF
                  break;
          }
      }
      return iconDoc;
  }
    const upFile = async (file) => {
      if(!!file === false){
        return null;
      }
      num= props.reqState.document.length+1
      if (num > 3) {
        MySwal.fire({
          icon:'error',
          title:"El número de archivos insertados supera el maximo permitido",
          footer:"El número maximo de archivos permitidos es de 3"
        })
      }else {
        let encode = await convertBase64(file);
        let file_nanme= file.name.split(".")[0]
        let type= file.type    
        let valueSave = encode.split(',')[1]; 
        let tempReq = props.reqState;
        tempReq.document.push({base64:valueSave,file_name:file_nanme,type:type});
        props.setreqState(tempReq);
        props.onHide();
        setfilenameState({name_doc:""})
      }
    }
    const onChangeDoc=  (e) => {
      var f =e.target.files[0]
      var name_doc= f.name.split(".")[0];
      var type_doc=f.type;
      setfilenameState({name_doc:name_doc,type_doc:type_doc});
      let tempReq = props.reqState;
      tempReq.file=(f);
      props.setreqState(tempReq);
    }


    

    {/* //////////////////////modal //////////////////////////////// */}
    return <ModalNew
        title={props.title}
        show={props.show}
        btnYesName="Subir"
        btnNoName="Cancelar"
        btnYesEvent={() => upFile(props.reqState?.file)}
        btnYesDisabled = { !(!!props.reqState?.file)}
        onHide={props.onHide}
    >
     <Dropzone onDrop={acceptedFile => validateFile(acceptedFile)} accept= {type_file}>
                  {({getRootProps, getInputProps}) => (
                    <section className="border w-100 h-100 p-1 text-center mx-auto">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} type="file" name="documents" onChange={onChangeDoc} accept= {type_file} multiple={false}/>
                        {!!filenameState.name_doc?
                          <div id="2" className={`text-muted text-center "px-3 pt-3 pb-5": "p-5" ${IndividualStyles.filesDir}`}>
                          <img   src= {docType(filenameState.type_doc)} alt="icon" className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.inputMargin}`} />
                          <p className={``}>{filenameState.name_doc}</p>
                          </div>  
   
                        : 
                        
                          <p className={`text-muted text-center "px-3 pt-3 pb-5": "p-5"} hoverPointer`}>{"Soltar archivo aquí"}</p>

                        } 
                        
                        { <img   src= {Attach} alt="añadir" className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.Aling}`} />}
                        
                        {/* {typeof (this.state.addArticle.data_sheet) === 'string' && this.state.addArticle.data_sheet !== ""  ? (
                        <div>
                          <p className={`text-muted text-center $ "px-5 pt-3 pb-5": "p-5"} hoverPointer`}>{this.state.addArticle.data_sheet ? this.state.addArticle.description : ""}</p>                          
                          <a className="text-center" href={this.state.addArticle.data_sheet} target="_blank">Descargar</a>
                        </div>
                        ): (
                          <p className={`text-muted text-center ${this.state.fileValid && this.state.addArticle.data_sheet !== "" ? "px-5 pt-3 pb-5": "p-5"} hoverPointer`}>{this.state.fileValid && this.state.fileUpload ? this.state.fileUpload[0].name : "Soltar archivo aquí"}</p>
                        )} */}
                        
                        
                      </div>
                    </section>
                  )}
      </Dropzone>
        

    </ModalNew>

    
}

export default ModalDocuments;



