import React, { Component, useState, useEffect } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Excel from "../../assets/img/icons/excel.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Pagination from "react-js-pagination";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { useSelector, useDispatch } from "react-redux";
import {
  getClient,
  getBalanceSum,
  getBalanceTotal,
} from "../../actions/receiptOfInvoicesActions";
import CompanyBalance from "./CompanyBalance";
import "../management/Management.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";
function ReportPurse(props) {
  const storage = useSelector((state) => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    client: "",
    consolidated: true,
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const [details, setDetails] = useState({
    show: false,
    client_id: "",
  });

  const [filters1, setFilters1] = useState({
    page: 1,
    perpage: 10,
    client: "",
    consolidated: false,
    orderType: "",
    reportType: true,
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const numberWithCommas = (x) => {
    var parts = x?.toString()?.split(".");
    if (parts !== undefined) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(".");
    }
  };

  useEffect(() => {
    dispatch(getBalanceSum(filters1));
    dispatch(getBalanceTotal(filters));
  }, [trigger, filters1.page]);

  useEffect(() => {
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
  }, []);

  let history = useHistory();
  function handleClick() {
    history.push("/cartera/Saldo/SaldoDeEmpresa");
  }

  let optionsClient = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (Array.isArray(storage.invoiceReducer.clients)) {
    storage.invoiceReducer.clients.map((item) => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + "clients",
      });
    });
  }

  let optionsOrders = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
    { key: "default1", value: "DESC", label: "Mayor a menor", id: "1" },
    { key: "default2", value: "ASC", label: "Menor a mayor", id: "2" },
  ];

  const header = [
    <th className="text-start px-2">NIT</th>,
    <th className="text-start px-2">Empresa</th>,
    <th className="text-end px-2">Saldo</th>,
    <th className="text-end px-2">30 días</th>,
    <th className="text-end px-2">60 días</th>,
    <th className="text-end px-2">90 días</th>,
    <th className="text-end px-2">180 días</th>,
    <th className="text-end px-2">360 días</th>,
    <th className="text-end px-2">Mayor a 360 días</th>,
    <th className="text-end px-2">Corriente</th>,
    <th className="text-end px-2"></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.balance_sum)) {
      table = storage.invoiceReducer.balance_sum.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">
            <td className="text-start px-2">{x.nit}</td>
            <td className="text-start px-2">{x.client}</td>
            <td className="text-end px-2">{"$" + numberWithCommas(x?.pending)}</td>
            <td className="text-end px-2">
              {"$" + numberWithCommas(x?.period_m30 ? x?.period_m30 : 0)}
            </td>
            <td className="text-end px-2">
              {"$" + numberWithCommas(x?.period_31_60 ? x?.period_31_60 : 0)}
            </td>
            <td className="text-end px-2">
              {"$" + numberWithCommas(x?.period_61_90)}
            </td>
            <td className="text-end px-2">
              {"$" + numberWithCommas(x?.period_91_180)}
            </td>
            <td className="text-end px-2">
              {"$" + numberWithCommas(x?.period_181_360)}
            </td>
            <td className="text-end px-2">
              {"$" + numberWithCommas(x?.period_M360 ? x?.period_M360 : 0)}
            </td>
            <td className="text-end px-2">{"$" + numberWithCommas(x?.not_due)}</td>
            <td className="text-center px-2">
              <img
                className="text-start pointer"
                src={Filtrar}
                onClick={() => setDetails({ show: true, client_id: x.id })}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const principalRender = (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >
      {storage.invoiceReducer.balance_sum_loading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      
      <div className={tableStyles.title}>
        <Title
          title="Informe de cartera"
          onClickIcon={() => {
            history.push('/cartera/inicio')
          }}
        />
      </div>

      <div className="d-flex card-container-principal">
        <div className="d-flex card-container">
          <div className="card-principal">
            <label>Saldo</label>
            <p>
              {!!storage.invoiceReducer.balance_total?.pending
                ? "$" +
                numberWithCommas(
                  storage.invoiceReducer.balance_total?.pending
                )
                : "$"}
            </p>
          </div>
          <div className="card-divwhite"></div>
          <div className="card-principal">
            <label>Corriente</label>
            <p>
              {!!storage.invoiceReducer.balance_total?.not_due
                ? "$" +
                numberWithCommas(
                  storage.invoiceReducer.balance_total?.not_due
                )
                : "$"}
            </p>
          </div>
        </div>
        <div className="yo">
          <div className="card-mediaQ">
            <div className="d-flex card-container-2">
              <div className="card-segundario">
                <p>30 dìas</p>
                <p>
                  {!!storage?.invoiceReducer.balance_total?.period_m30
                    ? "$" +
                    numberWithCommas(
                      storage?.invoiceReducer?.balance_total?.period_m30
                    )
                    : "$"}
                </p>
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>60 dìas</p>
                <p>
                  {!!storage.invoiceReducer.balance_total?.period_31_60
                    ? "$" +
                    numberWithCommas(
                      storage?.invoiceReducer?.balance_total?.period_31_60
                    )
                    : "$"}
                </p>
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>90 dìas</p>
                <p>
                  {!!storage.invoiceReducer.balance_total?.period_61_90
                    ? "$" +
                    numberWithCommas(
                      storage?.invoiceReducer?.balance_total?.period_61_90
                    )
                    : "$"}
                </p>
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>180 dìas</p>
                <p>
                  {!!storage?.invoiceReducer?.balance_total?.period_91_180
                    ? "$" +
                    numberWithCommas(
                      storage?.invoiceReducer?.balance_total?.period_91_180
                    )
                    : "$"}
                </p>
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>360 dìas</p>
                <p>
                  {!!storage?.invoiceReducer?.balance_total?.period_181_360
                    ? "$" +
                    numberWithCommas(
                      storage?.invoiceReducer?.balance_total?.period_181_360
                    )
                    : "$"}
                </p>
              </div>
              <div className="card-divgray"></div>
              <div className="card-segundario">
                <p>Mayor a 360 dìas</p>
                <p>
                  {!!storage.invoiceReducer?.balance_total?.period_M360
                    ? "$" +
                    numberWithCommas(
                      storage?.invoiceReducer?.balance_total?.period_M360
                    )
                    : "$"}
                </p>
              </div>
              <div className="card-divgray"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-3 align-items-center mt-2">

        <div className={tableStyles.containerSelect}>
          <form onSubmit={(e) => handleSearch(e)}>
            <label className={tableStyles.stylesLabel}>Empresa</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsClient}
              placeholder="Seleccionar..."
              onChange={(e) =>
                setFilters1({
                  ...filters1,
                  client: e.value,
                })
              }
              styles={customSelectNewDark}
              name="id_client"
            />
          </form>
        </div>

        <div className={tableStyles.containerSelect}>
          <form onSubmit={(e) => handleSearch(e)}>
            <label className={tableStyles.stylesLabel}>
              Ordenar saldo
            </label>
            <Select noOptionsMessage={() => 'No hay datos'}
              options={optionsOrders}
              placeholder="Seleccionar..."
              onChange={(e) =>
                setFilters1({
                  ...filters1,
                  orderType: e.value,
                })
              }
              styles={customSelectNewDark}
              name="id_oder"
            />
          </form>
        </div>

        <div>
          <i>
            <form onSubmit={(e) => handleSearch(e)}>
              <img
                style={{ marginTop: "30px" }}
                src={Lupa}
                alt=""
                srcset=""
                onClick={() => {
                  setTrigger(trigger + 1);
                  setFilters1({ ...filters1, page: 1 });
                }}
              />
            </form>
          </i>
        </div>

        <div className="text-end" style={{ marginLeft: 'auto' }}>
          <i>
            <img style={{ marginTop: "30px" }} src={Excel} alt="" srcset="" />
          </i>
        </div>

      </div>

      <GenericTable headers={header} dark={true}>
        {renderList()}
      </GenericTable>

      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {storage.invoiceReducer.balance_sum ? filters1.page : ""}
            {" de "}
            {Math.ceil(
              storage.invoiceReducer?.balance_sum_total / filters1.perpage
            )
              ? Math.ceil(
                storage.invoiceReducer.balance_sum_total / filters1.perpage
              )
              : ""}{" "}
            ({storage.invoiceReducer.balance_sum_total} encontrados)
          </p>
          <Pagination
            activePage={filters1.page}
            itemsCountPerPage={10}
            totalItemsCount={storage.invoiceReducer.balance_sum_total}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters1({ ...filters1, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </div>
  );

  return !!details.show ? (
    <CompanyBalance
      client_id={details.client_id}
      show={details.show}
      setDetails={setDetails}
    />
  ) : (
    principalRender
  );
}
export default ReportPurse;
