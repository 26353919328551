import React from "react";
import { generateId } from "../../../../../helpers/helpers";
import styles from "./tableComponentNom.module.scss";
import detailsClosed from "../../../../../assets/img/icons/detailsClosed.svg";
import topArrow from "../../../../../assets/img/icons/topArrow.svg";
import { useState } from "react";
import { useEffect } from "react";

const TableComponentNom = ({ headers, body, hasAccordion, key }) => {
//  const [isOpen, setIsOpen] = useState([
//     {id: }
//  ]);

const [bodyData, setBodyData] = useState(body)
useEffect(() => {
    // let tempArr = bodyData
    // tempArr.forEach(element => {
    //     element.id = generateId()
    //     element.isOpen = false
    // });
    setBodyData((prevState) => prevState.map((e) => ({
      ...e,
      id: generateId(),
      isOpen: false
    })))
}, [])


const changeIsOpen = (id)=>{
    let tempArr = bodyData
    let findRow = tempArr.find((e)=> e.id === id)
    findRow.isOpen = !findRow.isOpen
    setBodyData(tempArr)
}




 return (
  <div>
   <table className={styles.tablePrincipal }
   key={key}
   >
    <tr >
     {hasAccordion && <th
    className={styles.tableThLeft}

     >&nbsp;</th>}
     {headers.length > 0 &&
      headers?.map((elem, ind) => {
        let stylees = (ind === 0  && !hasAccordion) ?  styles.tableThLeft   : ind === headers.length - 1 ? styles.tableThRight : styles.tableHeader
       return (
        <th
         key={generateId()}
         className={`${elem.className}  ${stylees} `}
        >
         {elem.label ?? "-"}
        </th>
       );
      })}
    </tr>

    {bodyData.length > 0 &&
     bodyData.map((elem) => {
      return (
       <>
        <tr
         key={generateId()}
         className={`${elem.rowClassName ?? "" } ${styles.tableRowBody} ${elem.isOpen && styles.rowIsOpen}`}
        >
         {/* {hasAccordion && ( */}
          <td className={`d-flex justify-content-center ` }
          key={generateId()}
          >
           <button onClick={() => changeIsOpen(elem.id)}
           >

            {elem.isOpen ?
            <img
            width={10}
            height={10}
             alt="icon"
             src={  topArrow }
             className={styles.iconAccordion}
            />
          :
          <img
            width={10}
            height={10}
             alt="icon"
             src={ elem?.isOpen? topArrow : detailsClosed }
             className={styles.iconAccordion}
            />
          }
            
           </button>
          </td>
         {/* )} */}
         {elem?.data?.length > 0 &&
          elem?.data?.map((data) => {
           return <td
           key={generateId()}
           className={`${elem.elemClassName} ${data.className}`}>{data.label ?? "-"}</td>;
          })}
        </tr>
        {(elem?.bodyAccordion && elem?.isOpen) && (
         <tr className={ `${styles.tableBodyAccordion}`}>
          <td
           colSpan={5}
           className="px-0"
          >
           {elem.bodyAccordion}
          </td>
         </tr>
        )}
       </>
      );
     })}
   </table>
  </div>
 );
};

export default TableComponentNom;
