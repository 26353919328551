import React, { Component, useState } from "react";
import recibocaja from "../../assets/img/icons/recibocaja.svg";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import "../Consultation/Consultation.scss";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";




function DefiMovement(props) {

    let history = useHistory();
    function handleClickReciboDeCaja() {
        history.push("/tesoreria/ConfiMovimiento/RecibosDeCaja");
    }
    function handleClickPagosFacturasCausadas() {
        history.push("/tesoreria/ConfiMovimiento/PagosFacturasCausadas");
    }
    function handleClickTrasladoFondo() {
        history.push("/tesoreria/ConfiMovimiento/TrasladoDeFondos");
    }
    function handleClickNotasBancarias() {
        history.push("/tesoreria/ConfiMovimiento/NotasBancarias");
    }
    function handleClickCosignacionFecha() {
        history.push("/tesoreria/ConfiMovimiento/ConsignacionesFecha");
    }
    function handleClickDevolucionCheques() {
        history.push("/tesoreria/ConfiMovimiento/DevolucionDeCheques");
    }
    function handleClickReconsiCheque() {
        history.push("/tesoreria/ConfiMovimiento/ReconsiganacionDeCheques");
    }
    function handleClickCruceCuentas() {
        history.push("/tesoreria/ConfiMovimiento/CruceDeCuentas");
    }

    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                <h1 className={tableStyles.title}>Definición de movimientos</h1>
                <Row className="pt-5 d-flex">
                    <Col xs={3} className="pt-5">
                        <div className="bottonn" onClick={handleClickReciboDeCaja} >
                            <div className="col-auto pt-4 text-center" >
                                <img className="hovimg" src={recibocaja} alt="" srcset="" />
                                <div className="borhov">
                                    <p>Recibos de caja</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  xs={3} className="pt-5">
                        <div className="bottonn" onClick={handleClickPagosFacturasCausadas} >
                            <div className="col-auto pt-4 text-center" >
                                <img className="hovimg" src={recibocaja} alt="" srcset="" />
                                <div className="borhov">
                                    <p className="texto-">Pago facturas causadas</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3} className="pt-5">
                        <div className="bottonn" onClick={handleClickTrasladoFondo} >
                            <div className="col-auto pt-4 text-center" >
                                <img className="hovimg" src={recibocaja} alt="" srcset="" />
                                <div className="borhov">
                                    <p className="texto-">Traslado de fondos</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3} className="pt-5">
                        <div className="bottonn" onClick={handleClickNotasBancarias} >
                            <div className="col-auto pt-4 text-center" >
                                <img className="hovimg" src={recibocaja} alt="" srcset="" />
                                <div className="borhov">
                                    <p className="texto-">Notas bancarias</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3} className="pt-5">
                        <div className="bottonn" onClick={handleClickCosignacionFecha} >
                            <div className="col-auto pt-4 text-center" >
                                <img className="hovimg" src={recibocaja} alt="" srcset="" />
                                <div className="borhov">
                                    <p className="texto-">Consignaciones a la fecha</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3} className="pt-5">
                        <div className="bottonn" onClick={handleClickDevolucionCheques} >
                            <div className="col-auto pt-4 text-center" >
                                <img className="hovimg" src={recibocaja} alt="" srcset="" />
                                <div className="borhov">
                                    <p className="texto-">Devolución de cheques</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3} className="pt-5">
                        <div className="bottonn" onClick={handleClickReconsiCheque} >
                            <div className="col-auto pt-4 text-center" >
                                <img className="hovimg" src={recibocaja} alt="" srcset="" />
                                <div className="borhov">
                                    <p className="texto-">Reconsignación de cheques</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3} className="pt-5">
                        <div className="bottonn" onClick={handleClickCruceCuentas} >
                            <div className="col-auto pt-4 text-center" >
                                <img className="hovimg" src={recibocaja} alt="" srcset="" />
                                <div className="borhov">
                                    <p className="texto-">Cruce CxC y CxP</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    
                    
                </Row>
            </div>

        </>
    )

};
export default DefiMovement;