
import GenericTableNew from "../Layouts/GenericTableNew";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import AlertTriangle from "../../assets/img/icons/AlertTriangle.svg";
import checkboxTrue from "../../assets/img/icons/checkboxTrue.svg";
import Req_Trasladar from "../../assets/img/icons/Req_Trasladar.svg";

import React, { useEffect, useState, useMemo } from "react";

import { getIF } from "../../actions/inventoryFamilyActions";
import {getManufacturers} from "../../actions/articlesActions";
import { getAllMu} from "../../actions/measurementUnitsActions";
import { 
    consolidate_requisition,
    requisitions_articlesfromlist,
    set_consolidate_art,
    requisition_get_one,
    requisition_update_returnal,
    } from "../../actions/requisitionActions";
import { REQUISITION_GET} from "../../actions/actionTypes";

import {  useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import reqStyles from "./Requisitions.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import genericTableStyles from "../Layouts/genericTable.module.scss";
import ReqModalArticleCreate from "./ReqModalArticleCreate";
import ModalNew from "../Layouts/ModalNew";
import {customSelectNew, customSelectNewDark} from "../Layouts/react-select-custom";
import Select from "react-select";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import ReqModalConsolidatePart2 from "./ReqModalConsolidatePart2"
import tableStylesScroll from "../Layouts/genericTableScroll.module.scss";
import detailsClosed from "../../assets/img/icons/detailsClosed.svg";

//import CollapsibleTable from "./tabletest";
import tabletest from "./tabletest.module.scss";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useHistory } from "react-router-dom";
import { loader } from "../../helpers/helpers";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

function ReqModalConsolidate (props){
    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const history = useHistory();

    const [trigger, setTrigger] = useState(1);
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({
        warehouse:{},
        page: 1,
        perpage:10,
        pageCon: 1,
        perpageCon:10,
    });

    const [warehousecontrol, setwarehousecontrol] = useState({
        page: 1,
        perpage:7,
        id_article:0,
        showModal: false,
        reserved:0,
        warehouseArray: [],
    });

    const [modalPart2, setmodalPart2]= useState(false);

    useEffect(() => {

    },[]);

    useEffect(() => {
        if(Array.isArray(props.requisitions)){
            let only_ids = props.requisitions.map(x => x.id)
            dispatch( requisitions_articlesfromlist( only_ids ) );
        }
        
    },[trigger, filters.pageCon]);

    const radiobuttonHandler = (radioval, warehouseval) => {
        const mywarehouseart =  findWarehouseArticle(warehouseval, warehousecontrol.id_article);


        let warehouseArray = [...warehousecontrol.warehouseArray];
        let ammountToReserve = 0, newreserve=warehousecontrol.reserved;
        let found = warehouseArray.findIndex(x=>x.id_warehouse == mywarehouseart.id_warehouse);

        if(found >= 0){
            ammountToReserve = warehouseArray[found].taken;
            warehouseArray = warehouseArray.filter(x=>x.id_warehouse != mywarehouseart.id_warehouse);
            newreserve = warehousecontrol.reserved-ammountToReserve;
        }else{
            ammountToReserve = Math.min(mywarehouseart.available, (warehousecontrol.qty_article - warehousecontrol.reserved) )
            if(!isNaN(ammountToReserve) && ammountToReserve>0){
                warehouseArray.push({
                    ...mywarehouseart,
                    warehousename: warehouseval.label,
                    taken: ammountToReserve,
                });
                newreserve = warehousecontrol.reserved+ammountToReserve;
            }
        }


        setwarehousecontrol({
            ...warehousecontrol, 
            //radio: parseInt(radioval),
            reserved: newreserve,
            warehouseArray: warehouseArray,
        });
    }

    const editArticleHandler = (/*id_article, id_requisitions*/) => {
        //go into array and add selected warehouse to the article
        const id_article = warehousecontrol.id_article;
        //const selectedwarehouse = warehousecontrol.selectedwarehouse;
        const warehouseArray = warehousecontrol.warehouseArray;

        if(!!id_article && Array.isArray(warehouseArray)){
            let temp = counter.requisitionReducer.consolidate_art;

            //find article
            let foundIndex = temp.findIndex(x => 
                (x.id_article == id_article) 
                //&& (x.id_requisitions == id_requisitions) 
                );

            //add warehousearticle
            //as of sept 8 it must be multiple articles so this will be an array
            //validate existing array, then add or remove.
            //if no array, create array and set selected as only item
            
            //ver 2
            temp[foundIndex] = {
                ...temp[foundIndex],
                warehousearticle: warehouseArray,
            };

            //ver 1.5 previous code to add them one by one
            // let existingWarehouseArray = temp[foundIndex]?.warehousearticle;
            // if(Array.isArray(existingWarehouseArray)){
            //     if(existingWarehouseArray.findIndex(x=>(x.id_warehouse==selectedwarehouse.id_warehouse)) >= 0){
            //         let newarray = existingWarehouseArray.filter(x=>(x.id_warehouse==selectedwarehouse.id_warehouse));
            //         temp[foundIndex] = {
            //             ...temp[foundIndex],
            //             warehousearticle: newarray,
            //         };
            //     }else{
            //         let newarray = [...existingWarehouseArray];
            //         newarray.push(selectedwarehouse);
            //         temp[foundIndex] = {
            //             ...temp[foundIndex],
            //             warehousearticle: newarray,
            //         };
            //     }
            // }else{
            //     temp[foundIndex] = {
            //         ...temp[foundIndex],
            //         warehousearticle: [selectedwarehouse],
            //     };
            // }

            //previous code
            //ver 1
            // temp[foundIndex] = {
            //     ...temp[foundIndex],
            //     warehousearticle: selectedwarehouse,
            // };

            dispatch( set_consolidate_art(temp) );
            setwarehousecontrol({page: 1, perpage:7, showModal:false, reserved:0, warehouseArray: []})
        }
        
    }

    const findWarehouseArticle = (whichwarehouse, id_article, qty_reservec = null) => {
        if( Array.isArray(whichwarehouse.articles) ){
            let found = whichwarehouse.articles.find(x => x.id_article == id_article);
            if (!!found){
                let qty_taken= 0;
                if(!!qty_reservec){
                    qty_taken= Math.min(found.available, qty_reservec);
                }
                return {
                    ...found,
                    warehousename: whichwarehouse.label,
                    taken: qty_taken
                };
            }
        }
        return {
            id: 0,
            description:"-",
            id_warehouse:0,
            id_article:0,
            min_stock:0,
            max_stock:0,
            available:0,
            reserved: 0,
            warehousename: !!whichwarehouse?.label? whichwarehouse.label :"-" ,
            taken: 0,
        };
    }

    // const renderHeaders = [
    //     <th key={`ReqModalConsolidate1`} className={tableStylesScroll.f1}><p className={`m-0 ${reqStyles.smallerHeader}`}>Item</p></th>,
    //     <th key={`ReqModalConsolidate2`} className={tableStylesScroll.f3}><p className={`m-0 ${reqStyles.smallerHeader}`}>Artículo</p></th>,
    //     <th key={`ReqModalConsolidate3`} ><p className={`m-0 ${reqStyles.smallerHeader}`}>Solicitado</p></th>,
    //     <th key={`ReqModalConsolidate4`} ><p className={`m-0 ${reqStyles.smallerHeader}`}>Stock&nbsp;min</p></th>,
    //     <th key={`ReqModalConsolidate5`} ><p className={`m-0 ${reqStyles.smallerHeader}`}>Stock&nbsp;Max</p></th>,
    //     <th key={`ReqModalConsolidate6`} ><p className={`m-0 ${reqStyles.smallerHeader}`}>Disponible</p></th>,
    //     <th key={`ReqModalConsolidate7`} ><p className={`m-0 ${reqStyles.smallerHeader}`}>Almacén</p></th>,
    //     <th key={`ReqModalConsolidate8`} className={tableStylesScroll.f1}></th>,
    // ]

    const useRowStyles = makeStyles({
        root: {
          '& > *': {
            borderBottom: 'unset',
          },
        },
      });

    const mainheaders = [
        "Articulos",
        "Solicitado",
        "Almacén",
        ""
    ]

    
    const [collapsedRows, setcollapsedRows] = useState([]);
    const togglecollapserow = (id_article) =>{
        let temparray = [...collapsedRows];
        let itscollapsed = collapsedRows.find(x=> x==id_article);
        if(!!itscollapsed){
            temparray = temparray.filter(x=> x!=id_article);
        }else{
            temparray.push(id_article);
        }
        setcollapsedRows(temparray);
    }
    
    function Row(props) {
        const { row } = props;
        //const [open, setOpen] = useState(false);
        const open = !!(collapsedRows.find(x=> x==row.id_article));
        const classes = useRowStyles();

        let defaultwarehouse = findWarehouseArticle(filters.warehouse, row.id_article, row.qty_article);
        let warehousearticle = [];
        if(Array.isArray(row.warehousearticle) && row.warehousearticle.length>0){
            warehousearticle = row.warehousearticle;
        }else{
            if(defaultwarehouse?.id != 0){
                warehousearticle = [defaultwarehouse];
            }
        }
        //let warehousearticle = row.warehousearticle;
     
        
    
        return (
        <React.Fragment>
    
            {/* table row summary shown */}
            <TableRow className={`
                ${(props.index%2 == 0)? tabletest.tablerowEven : ""}
                ${tabletest.tablerow} 
                ${classes.root}
            `}>
    
                <TableCell>
                    <IconButton 
                    aria-label="expand row" 
                    size="small" 
                    //onClick={() => setOpen(!open)}
                    onClick={() => togglecollapserow(row.id_article)}
                    >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
        
                {/*component="th" scope="row" */}
                <TableCell > {row.article?.description} </TableCell> 
                <TableCell align="center">{row.qty_article}</TableCell>
                <TableCell align="center">{Array.isArray(warehousearticle)? warehousearticle.length : "-"}</TableCell>
                <TableCell >
                    {!!filters.warehouse.value?
                        <img
                            src={Req_Trasladar}
                            alt="Transladar"
                            className={`${reqStyles.adjustIconTransladar}`}
                            onClick={e=>{
                                    setwarehousecontrol({
                                        ...warehousecontrol, 
                                        showModal:true, 
                                        id_article: row.id_article,
                                        qty_article: row.qty_article,
                                        //radio: row.warehousearticle?.id_warehouse,
                                    })
                                }
                            }
                        />
                    :
                        <span>&nbsp;&nbsp;</span>
                    }
                </TableCell>
            </TableRow>
    
            {/* subtable in row details */}
            <TableRow className={`${(props.index%2 == 0)? tabletest.tablerowEven : ""}`}>
            {Array.isArray(warehousearticle) && warehousearticle.length>0 && 
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                    <p className={tabletest.subtableTitle}>
                        Almacenes
                    </p>
                    <Table size="small" aria-label="purchases">
                    {/* subtable headers */}
                    <TableHead>
                        <TableRow className={tabletest.subtableTh}>
                        <TableCell align="left">No.</TableCell>
                        <TableCell align="left">Almacén</TableCell>
                        <TableCell align="center">Stock min</TableCell>
                        <TableCell align="center">Stock max</TableCell>
                        <TableCell align="center">Disponible</TableCell>
                        <TableCell align="center">A retirar</TableCell>

                        </TableRow>
                    </TableHead>
                    {/* subtable headers */}
                    <TableBody>
                        {warehousearticle.map((warehouserow, index) => {
                            return (
                                <TableRow key={warehouserow.id} className={tabletest.subtableTr}>
                                    <TableCell align="left"> {warehouserow.id} </TableCell>
                                    <TableCell align="left">{warehouserow.warehousename} </TableCell>
                                    <TableCell align="center">{warehouserow.min_stock}</TableCell>
                                    <TableCell align="center">{warehouserow.max_stock}</TableCell>
                                    <TableCell align="center">{warehouserow.available}</TableCell>
                                    <TableCell align="center">{warehouserow.taken} </TableCell>
                                </TableRow>
                                )
                        }
                        
                        )}
                    </TableBody>
    
                    </Table>
                </Box>
                </Collapse>
            </TableCell>
            }
            </TableRow>
        </React.Fragment>
        );
    }
    

    
    //main table 
    const CollapsibleTable = () => {
        return (
        <Paper >
            <TableContainer className={tabletest.table}>
                <Table stickyHeader aria-label="sticky collapsible table">
                {/* table headers here */}
                <TableHead  className={tabletest.head}>
                    <TableRow>
                    <TableCell />
                    {mainheaders.map( (item, index)=> 
                        <TableCell align="center">{item}</TableCell>
                    )}
                    </TableRow>
                </TableHead>
                
                {/* table body is generated from array */}
                <TableBody className={tabletest.body}>
                    {counter.requisitionReducer.consolidate_art.map((row, index) => (
                    <Row 
                        key={row.name} 
                        index={index}
                        row={row} 
                    />
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            <div className={` pt-2 ${paginationStyles.wrapper}`}>
          <p className={paginationStyles.paginationText}>
            Pag. {counter.requisitionReducer.consolidate_art.length ? filters.pageCon : ""}
            {" de "}
            {Math.ceil(counter.requisitionReducer.consolidate_art.length / filters.perpageCon)
              ? Math.ceil(
                  counter.requisitionReducer.consolidate_art.length / filters.perpageCon
                )
              : ""}{" "}
            ({counter.requisitionReducer.consolidate_art.length} encontrados)
          </p>
          <Pagination
            activePage={filters.pageCon}
            itemsCountPerPage={10}
            totalItemsCount={counter.requisitionReducer.consolidate_art.length}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters({ ...filters, pageCon: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
        </Paper>
        );
    }

    // const renderList = () => {
    //     let table =[];

    //     if (Array.isArray(counter.requisitionReducer.consolidate_art)) {
    //         table = counter.requisitionReducer.consolidate_art.map( x => {

                
    //             //let warehousearticle = findWarehouseArticle(filters.warehouse, x.id_article);
    //             let warehousearticle = !!x.warehousearticle? x.warehousearticle :findWarehouseArticle(filters.warehouse, x.id_article);
                
    //             let qty_article_temp = !!x.qty_article? x.qty_article: 0;
    //             //let extraclass = !!warehousearticle?.available? "" : genericTableStyles.transladar;
    //             let extraclass = (warehousearticle?.available >= qty_article_temp)? "" : genericTableStyles.transladar;
                
    //             //hover-table-row causes issues with generictablescroll
    //             return <details key={"ReqModalConsolidateList"+x.id_article} className={`${extraclass}`}>
                    
    //                 <summary>
    //                     <section key={"ReqModalConsolidateList"+x.id_article} className={`${extraclass}`}>
    //                         <td className={tableStylesScroll.f1}>
    //                             {/*x.id_article*/}
    //                             <img src={detailsClosed} alt=">" className={tableStylesScroll.marker}/>
    //                         </td>
    //                         <td className={tableStylesScroll.f3}>
    //                             {x.article?.description}
    //                         </td>
    //                         <td>
    //                             {x.qty_article}
    //                         </td>
    //                         <td>
    //                             {warehousearticle?.min_stock}
    //                         </td>
    //                         <td>
    //                             {warehousearticle?.max_stock}
    //                         </td>
    //                         <td>
    //                             {(warehousearticle?.available >= qty_article_temp)?
    //                                 warehousearticle?.available
    //                             :
    //                                 <p className={`m-0 ${reqStyles.orangeText}`}>{warehousearticle?.available}</p>
    //                             }
    //                         </td>
    //                         <td >
    //                             {
    //                             //warehousearticle?.description
    //                             warehousearticle?.warehousename
    //                             }
    //                         </td>
    //                         <td className={tableStylesScroll.f1}>
    //                         {!!filters.warehouse.value?
    //                             <img
    //                                 src={Req_Trasladar}
    //                                 alt="Transladar"
    //                                 className={`${reqStyles.adjustIconTransladar}`}
    //                                 onClick={e=>{
    //                                         setwarehousecontrol({
    //                                             ...warehousecontrol, 
    //                                             showModal:true, 
    //                                             id_article: x.id_article,
    //                                             qty_article: x.qty_article,
    //                                             //radio: x.warehousearticle?.id_warehouse,
    //                                         })
    //                                     }
    //                                 }
    //                             />
    //                         :
    //                             <span>&nbsp;&nbsp;</span>
    //                         }
                            
    //                         </td>
    //                     </section>
    //                 </summary>
    //                 <div className={ tableStylesScroll.rowSubSection}> 
    //                     <section>
    //                         <td>a</td>
    //                         <td>b</td>
    //                         <td>c</td>
    //                         <td>d</td>
    //                         <td>e</td>
    //                         <td>f</td>
    //                     </section>
    //                     <section>
    //                         <td>a</td>
    //                         <td>b</td>
    //                         <td>c</td>
    //                         <td>d</td>
    //                         <td>e</td>
    //                         <td>f</td>
    //                     </section>
    //                 </div>
    //             </details>
    //         });

    //       }
    //     return table;
    // }

    // const paginateList = () => {
    //     const fullList = renderList();
    //     return fullList.slice(filters.perpage*(filters.page-1),  filters.perpage*(filters.page));
    // }

    const btnCancel = () =>{
        if(modalPart2){
            setmodalPart2(false);
        }else{
            props.onHide();
        }
    }

    const btnNext = () => {
        let error=""
        if(modalPart2){
            props.setfreezeConsolidate(true);
            setLoading(true)
            dispatch( 
                consolidate_requisition(
                    //compras
                    filterForPart2(true), 

                    //subastas
                    filterForPart2(false),

                    //requisiciones para actualizar
                    props.requisitions.map(x => x.id),

                    //creacion de individual_auction aka subasta
                    {
                        id_user: counter.loginReducer.user_data.id,
                        account: counter.loginReducer.currentAccount.id,
                        auction_type: "consolidationreq",
                        id_consumption_center: props.selected_cc_id,
                        base_warehouse_id: filters.warehouse.value, 
                    },

                    //funcion para despues de que se ejecute
                    ()=>{
                        setLoading(false)
                        props.reloadparent();
                        props.onHide();
                    },

                    ()=>{
                        setLoading(false)
                        props.setfreezeConsolidate(false);
                    },
                    ()=>{
                        setLoading(false)
                        history.push("/compras/salidaconsumo");
                    }
                ) 
            );

        }else{
            if(!!filters.warehouse?.value===false){
                error="Debe seleccionar un Almacén";
            }

            if(!!error){
                customSwaltAlert({
                    icon: "warning",
                    title: "Intenta de nuevo",
                    text: error,
                    showCancelButton: false,
                  });
                  return null;
            }

            setmodalPart2(true);
        }
    }

    //used in part2
    const filterForPart2 = (forcompras)=>{
        let table =[];

        if (Array.isArray(counter.requisitionReducer.consolidate_art)) {
            counter.requisitionReducer.consolidate_art.map(x =>{
                //let temp1 = !!x.warehousearticle? x.warehousearticle : findWarehouseArticle(filters.warehouse, x.id_article)
                // if(forcompras){
                //     if(!!temp1.available===true && (!isNaN(temp1.available) && (temp1.available >= x.qty_article))  ){
                //         table.push({
                //             ...x,
                //             warehousearticle: temp1
                //             })
                //     }
                // }else{
                //     if(!!temp1.available===false || (temp1.available < x.qty_article) ){
                //         table.push({
                //             ...x,
                //             warehousearticle: temp1
                //         })
                //     }
                // }
                let defaultwarehouse = findWarehouseArticle(filters.warehouse, x.id_article, x.qty_article);
                let warehousearticle = [];
                if(Array.isArray(x.warehousearticle) && x.warehousearticle.length>0){
                    warehousearticle = x.warehousearticle;
                }else{
                    if(defaultwarehouse?.id != 0){
                        warehousearticle = [defaultwarehouse];
                    }
                }

                let haswarehouse = (Array.isArray(warehousearticle) && warehousearticle.length > 0);
                let totalTaken = 0;
                if(haswarehouse){
                    totalTaken = warehousearticle.reduce( 
                        (total, current)=>{
                                return total + current.taken
                            }
                        ,
                            0
                    )
                }
                
                if(forcompras){
                    if(haswarehouse===true && (!isNaN(totalTaken) && (totalTaken >= x.qty_article))  ){
                        table.push({...x,
                            warehousearticle:warehousearticle
                        });
                    }
                }else{
                    if(haswarehouse===false || (totalTaken < x.qty_article) ){
                        table.push({...x,
                            warehousearticle:warehousearticle
                        });
                    }
                }
                
                
                
            })
          }
        return table;
        
    }

    //seccion modal de escoger almacén
    const renderHeadersWarehouse = [
        <div key={`damnit1`} >&nbsp;</div>,
        <div key={`damnit2`} className={tableStylesScroll.f5}>Almacén</div>,
        <div key={`damnit3`} className={tableStylesScroll.f5}>Disponible</div>,
    ]
    const renderListWarehouse = () => {
        let table =[];

        if (Array.isArray(props.optionStorage)) {
            props.optionStorage.map( x => {

                let warehousearticle = findWarehouseArticle(x, warehousecontrol.id_article);
                let qty_article_temp = !!warehousecontrol.qty_article? warehousecontrol.qty_article: 0;
                
                //getting tired of scrolling trough useless data, if it has 0 it shall not be displayed
                if(warehousearticle?.available > 0){
                table.push(
                <section key={"warehousemodal"+x.value} className={``}>
                    <td >
                        <input
                            className={`form-check-input ${reqStyles.checkbox}`}
                            type="checkbox"
                            name="warehouseselected"
                            id="warehouseselected"
                            value={x.value}
                            onChange={
                                e => {
                                    //if(!!(warehousearticle?.available >= qty_article_temp)){
                                        radiobuttonHandler(e.target.value, x)
                                    //}else{
                                    //    return null
                                    //}
                                }
                            }
                            //checked={warehousecontrol.radio === x.value}
                            checked={warehousecontrol.warehouseArray.findIndex(y=>y.id_warehouse == x.value)>=0}
                            //disabled={!!(warehousearticle?.available < qty_article_temp )}
                        />
                    </td>
                    <td className={tableStylesScroll.f5}>
                        {x.label}
                    </td>
                    <td className={tableStylesScroll.f5}>
                        {!!warehousearticle?.available?
                            warehousearticle?.available
                        :
                            <p className={`m-0 ${reqStyles.orangeText}`}>0</p>
                        }
                    </td>
                </section>
                )
                }
                //end map
            });

        }

        if(table.length <= 0){
            table.push(<tr key={"warehousemodalDefault"} className={`hover-table-row`}>
                    <td> &nbsp; </td>
                    <td className={tableStylesScroll.f5}> No hay almacén con este articulo disponible</td>
                    <td className={tableStylesScroll.f5}>  </td>
                </tr>)
        }

        return table;
    }

    const paginateListWarehouse = () => {
        const fullList = renderListWarehouse();
        return fullList.slice(warehousecontrol.perpage*(warehousecontrol.page-1),  warehousecontrol.perpage*(warehousecontrol.page));
    }

    //end seccion modal almacén

    return (
        <>
        {loading && loader}
        <ModalNew
            title="Consolidar"
            subtitle= {modalPart2? "Paso 2 de 2 - Resumen": "Paso 1 de 2 - Seleccionar Almacén"}
            show={props.show}
            btnYesName= {modalPart2? "Solicitar": "Siguiente"}
            btnNoName= {modalPart2? "Atrás": "Cancelar"}
            size="700"
            btnYesEvent={()=>btnNext()}
            btnYesDisabled={props.freezeConsolidate}
            onHide={()=>props.onHide()}
            btnNoEvent={()=>btnCancel()}
            btnNoDisabled={props.freezeConsolidate}
            hideCancelButton={false}
        >

            {modalPart2?
            
                <ReqModalConsolidatePart2
                    findWarehouseArticle={ (a,b) => findWarehouseArticle(a,b) }
                    filterCompra={filterForPart2(true)}
                    filterSubasta={filterForPart2(false)}
                ></ReqModalConsolidatePart2>
            :

            //==============================================================================================
            //==================================== step 1 ===========================================
            //==============================================================================================
            <div className={reqStyles.container2}>

                    <div className="d-flex" style={{ zIndex: 3}}>
                        <div  className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
                            <p className={tableStyles.crudModalLabel}>Almacén</p>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                value={props.optionStorage.find(x=> x.value == filters.warehouse.value)}
                                onChange={ e => setFilters({...filters, warehouse: e}) } 
                                options={[{value: "", label: "Seleccionar..."},...props.optionStorage]}
                                placeholder="Seleccionar..."
                                styles={customSelectNew}
                                maxHeight={22}
                            />
                            
                        </div>
                        
                        <div  className={`${reqStyles.ItemMedium}`}>
                            {/*empty div flexing container len*/}
                        </div>
                    </div>

                {CollapsibleTable()}

                {(counter.requisitionReducer.loading) &&
                    <div className="mx-auto">
                        <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                        />
                    </div>
                }
                
                {/* <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                        Pag. {counter.requisitionReducer.consolidate_art?.length ? filters.page : ""} 
                        {" de "}
                        {Math.ceil(counter.requisitionReducer.consolidate_art?.length / filters.perpage) ? 
                            Math.ceil(counter.requisitionReducer.consolidate_art?.length / filters.perpage)
                        : 
                            "" 
                        }
                        {" "}
                        ({counter.requisitionReducer.consolidate_art?.length} encontrados)
                    </p>
                    
                    <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={10}
                        totalItemsCount={counter.requisitionReducer.consolidate_art?.length}
                        pageRangeDisplayed={5}
                        onChange={ (e) => setFilters({...filters, page: e}) }
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext }
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                    />
                </div> */}

            </div>
            }


            <ModalNew 
                title="Almacenes"
                show={warehousecontrol.showModal}
                onHide={()=>setwarehousecontrol({page: 1, perpage:7, showModal:false, reserved:0, warehouseArray: []})} //...warehousecontrol, 
                btnYesEvent={()=>editArticleHandler()}
                btnYesDisabled={
                    Array.isArray(warehousecontrol.warehouseArray)===false
                    ||
                    (Array.isArray(warehousecontrol.warehouseArray)===true && warehousecontrol.warehouseArray.length <= 0)
                }
            >
                
                <div className={reqStyles.container2}>
                    <GenericTableScroll  
                    headers={renderHeadersWarehouse}
                    body={!(counter.requisitionReducer.loading) &&
                            //paginateListWarehouse()
                            renderListWarehouse()
                        }
                    />
                        
                    
                    {/* {renderListWarehouse().length > warehousecontrol.perpage  &&
                    <Pagination
                        activePage={warehousecontrol.page}
                        itemsCountPerPage={warehousecontrol.perpage}
                        totalItemsCount={props.optionStorage?.length}
                        pageRangeDisplayed={5}
                        onChange={ (e) => setwarehousecontrol({...warehousecontrol, page: e}) }
                        itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
                        itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
                        itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
                        itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
                        itemClass={paginationStyles.itemClass}
                    />
                    } */}

                </div>
            </ModalNew>
            
        </ModalNew>
        </>
    )

}



export default ReqModalConsolidate;