// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import GenericTable from "../Layouts/GenericTableNew";
import { Col, Row } from "react-bootstrap";
import ModalNew from "../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";
import { Tooltip } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

// --------------- icons 💥  ----------------//
import Lupa from "../../assets/img/icons/lupa.svg";
import Agregar from "../../assets/img/icons/add-check.svg";
import Excel from "../../assets/img/icons/excel.svg";
import Word from "../../assets/img/icons/word.svg";
import PDF from "../../assets/img/icons/pdf.svg";
import Cerrar from "../../assets/img/icons/littleClose.svg";
import Adjuntar from "../../assets/img/icons/insertar.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Atras from "../../assets/img/icons/atras-icon.svg";

//-------------- scss styles 😄   --------------
import CustomPopupExtend from "../Popup/customPopUpExtends";
import Select from "react-select";
import { customSelectNew, customSelectNewDark } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import tableStylesScroll from "../Layouts/genericTableScroll.module.scss";

// ------------------ Functions ----------------------
import { createCreditCard, getCardCredit, registerCreditPayment } from "../../actions/accountingMovementsActions";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import { getAllConCenter } from "../../actions/consumptionCenterActions";
import { getProviders } from "../../actions/providersActions";
import { listThirdParties } from "../../actions/accountingConsultsActions";
import ModalDocuments from "../TableIndividualAuction/IndividualDocuments";




function CreatePaymentsTC(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const counter = useSelector(state => state);
    const [trigger, setTrigger] = useState(1);
    const [trigger2, setTrigger2] = useState(1);
    const [triggerModal, setTriggerModal] = useState(1);
    const [modalDetails, setModalDetails] = useState({});
    const [modalDocumentsControl, setmodalDocumentsControl] = useState({ show: false });

    const ButtonDocuments = () => {
        setmodalDocumentsControl({
            ...modalDocumentsControl,
            show: true
        })
    };
    const [createPaymentCredit, setCreatePaymentCredit] = useState({
        eaccount: counter.loginReducer.currentAccount.id,
        user: counter.loginReducer.user_data.id,
        creditCardId: props.info
    });
    const [showModal, setShowModal] = useState({
        show: false
    });
    const [showModal1, setShowModal1] = useState({
        show: false
    });
    function handleClick() {
        props?.setDetails({ show: false })
    }
    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }
    const docType = (data) => {
        let iconDoc
        if (!!data.file) {
            switch (data.file.type) {
                case "application/pdf":
                    iconDoc = PDF
                    break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/msword":
                    iconDoc = Word
                    break;
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "csv" || "application/vnd.ms-excel":
                    iconDoc = Excel
                    break;
                default:
                    iconDoc = PDF
                    break;
            }
        }
        return iconDoc;
    }
    const [reqState, setreqState] = useState({
        auction_title: "",
        date_expiry: "",
        hour_expiry: "",
        terms: "",
        priority: "",
        id_warehouse: "",
        id_user: counter.loginReducer.user_data.id,
        account: counter.loginReducer.currentAccount.id,
        account_session: counter.loginReducer.currentAccount.name,
        id_consumption_center: 0,
        articulos: [],
        pay_conditions: "",
        coin: "",
        document: [],
        file: false,
        observations: " ",
        qty_total: 0,

    });
    const resetStatusFile = (event) => {
        if (!!event) {
            event.stopPropagation();
        }
        setreqState({
            auction_title: "",
            date_expiry: "",
            hour_expiry: "",
            terms: "",
            priority: "",
            id_warehouse: "",
            id_user: counter.loginReducer.user_data.id,
            account: counter.loginReducer.currentAccount.id,
            account_session: counter.loginReducer.currentAccount.name,
            id_consumption_center: 0,
            articulos: [],
            pay_conditions: "",
            coin: "",
            document: [],
            file: false,
            observations: " ",
            qty_total: 0,

        })
    }

    const myPermission = () => counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "PettyCash");
    //--------------first functions 🍕   --------------
    useEffect(() => {
        dispatch(getProviders({ slim: 1 }));
        dispatch(getCardCredit({ eaccount: counter.loginReducer.currentAccount.id, id: props.info }));
        dispatch(listThirdParties({ eaccount: counter.loginReducer.currentAccount.id }))
        dispatch(getAllConCenter({ active: 1, eaccount: counter.loginReducer.currentAccount.id, lite: true }));
    }, [trigger]);
    useEffect(() => {
        dispatch(getCardCredit({ eaccount: counter.loginReducer.currentAccount.id, id: props.info }));
    }, [trigger2]);

    let optionsThird = [{ key: "", value: "", label: "Seleccionar..." }];
    if (Array.isArray(counter.accountingReducer.listThirdParties)) {
        counter.accountingReducer.listThirdParties.map((item, index) => {
            optionsThird.push({
                value: item.id,
                label: item.nit + " - " + item.description,
                key: index + "third",
            });
        });
    }
    let optionProvider = [{ key: "", value: "", label: "Seleccionar..." }]
    if (Array.isArray(counter.providerReducer.providers)) {
        counter.providerReducer.providers.map((item) => {
            optionProvider.push({
                value: item.id,
                label: item.nit + " - " + item.name,
                valueName: item.name
            }
            );
        })
    }

    let optionsCostCenter = [{ key: "", value: "", label: "Seleccionar... " }];
    if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
        counter.consumptionCenterReducer.consumption_center.map((item, index) => {
            optionsCostCenter.push({
                value: item.id,
                label: item.description,
                key: index + "cc",
            });
        });
    }

    //-------------- Table 🍕   --------------

    const header = [
        <div className={`col-1 text-centar`}>Fecha</div>,
        <div className={`col-2 text-centar`}>No. Factura</div>,
        <div className={`col-3 text-start`}>Centro de consumo</div>,
        <div className={`col-4 text-start`}>Concepto</div>,
        <div className={`col-1 text-center`}>Valor</div>,
        <div className={`col`}>&nbsp;</div >,
    ]
    let total = 0;
    const renderList = () => {
        let tempList = [];

        if (Array.isArray(counter.accountingMovementsReducer.getCardCredit?.payments)) {
            counter.accountingMovementsReducer.getCardCredit.payments.map((item, index) => {
                total = total + Number(item.totalAmount)
                tempList.push(
                    <section2 className={`d-flex`}>
                        <div className="col-1">{convertDateToLatinFormat(item.date)}</div>
                        <div className="col-2">{item.invoiceNbr}</div>
                        <div className="col-3 text-start">{item.ccName}</div>
                        <div className="col-4 text-start">{item.description}</div>
                        <div className="col-1 text-end">$ {numberWithCommas(item.totalAmount)}</div>
                        <div className="col text-start" style={{ marginLeft: "1rem" }}>
                            <img  className="hoverPointer" src={Filtrar} onClick={() => (setShowModal1({ show: true }), setModalDetails(item))} />
                        </div>

                    </section2>
                );
            })
        }
        return tempList;
    }

    //-------------- select options 🍕   --------------
    const [loading, setLoading] = useState(false)


    //--------------  Actions  🍕   --------------
    const registerpayment = () => {
        setLoading(true)
        createPaymentCredit.base64 = reqState?.document[0]?.base64;
        createPaymentCredit.fileName = reqState?.document[0]?.file_name + "." + reqState?.document[0]?.type.split("/")[1];
        createPaymentCredit.type_doc = reqState?.document[0]?.type.split("/")[1];
        dispatch(registerCreditPayment(createPaymentCredit, () => (
            setShowModal({ show: false }),
            setTrigger2(trigger2 + 1),
            setCreatePaymentCredit({
                eaccount: counter.loginReducer.currentAccount.id,
                user: counter.loginReducer.user_data.id,
                creditCardId: props.info
            }),
            resetStatusFile())
            ,() => setLoading(false)
            ))
    }


    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                {(counter.accountingMovementsReducer.card_loading || loading) &&
                    <div className="loading">
                        <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                        />
                    </div>
                }
                <div className="d-flex">
                    <img className={`${tableStyles.title} mr-3 cursorPointer`} src={Atras} onClick={handleClick} />
                    <h1 className={tableStyles.title}>Registro de pagos con TC</h1>
                </div>
                <Row>
                    <Col xs={3}>
                        <label className={tableStyles.crudModalLabel}>Responsable*</label>
                        <input
                            className={IndividualStyles.registerInputsGris}
                            value={counter.accountingMovementsReducer.getCardCredit?.cardName}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={3}>
                        <label className={tableStyles.crudModalLabel}>Tarjeta*</label>
                        <input
                            className={IndividualStyles.registerInputsGris}
                            value={!!counter.accountingMovementsReducer.getCardCredit?.bankName ?
                                counter.accountingMovementsReducer.getCardCredit.bankName
                                + " - " +
                                counter.accountingMovementsReducer.getCardCredit?.creditCardNbr : ""}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={6} className="text-end hoverPointer align-self-end" >
                        {myPermission()?.create ?
                        <div className="d-flex justify-content-end ">
                        <div
                         onClick={() => setShowModal({ show: true, creat: true })}
                         className={tableStyles.createNomModule}
                        >
                         <b className={`mr-2`}>Registrar pago</b>
                         <img
                          src={Agregar}
                          alt="User"
                         />
                         <div>
                         </div>
                        </div>
                       </div>
                            // <label className="" style={{ marginTop: "30px", padding: "5px", width: "190px" }}>
                            //     <img
                            //         className="ml-3 hoverPointer"
                            //         style={{ width: "18px" }}
                            //         src={Agregar} />
                            // </label>
                            :
                            ""
                        }
                    </Col>
                </Row>

                <div>
                    <ModalNew
                        show={showModal.show}
                        onHide={() => { setShowModal({ show: false }) }}
                        title={"Registro de Pago"}
                        btnYesEvent={() => registerpayment()}
                        btnNoEvent={() => (
                            setShowModal({ show: false }),
                            setTrigger(trigger + 1),
                            setCreatePaymentCredit({
                                eaccount: counter.loginReducer.currentAccount.id,
                                user: counter.loginReducer.user_data.id,
                                creditCardId: props.info
                            }), resetStatusFile())}
                        btnYesName={"Guardar"}
                        btnYesDisabled={
                            !!createPaymentCredit.description === false ||
                                !!createPaymentCredit.ccId === false ||
                                !!createPaymentCredit.date === false ||
                                !!createPaymentCredit.totalAmount === false ||
                                !!createPaymentCredit.thirdParty === false ||
                                !!createPaymentCredit.taxAmount === false ||
                                !!createPaymentCredit.taxAmount === false ||
                                !!createPaymentCredit.invoiceNbr === false ? true : false
                        }
                        size={"500"}

                    >
                        <Row className="d-flex">
                            <Col xs={12}>
                                <label className={tableStyles.crudModalLabel}>Concepto*</label>
                                <input
                                    maxLength="255"
                                    key={"concept" + trigger}
                                    type="text"
                                    name="activity name"
                                    onChange={(e) => setCreatePaymentCredit({
                                        ...createPaymentCredit,
                                        description: e.target.value
                                    })}
                                    style={!(showModal.edit || showModal.creat) ? { paddingLeft: "5px", color: "#58595B", backgroundColor: "#F5F7FA" } : { paddingLeft: "5px", color: "#58595B" }}
                                    className={IndividualStyles.registerInputsClearBlue}
                                    disabled={!(showModal.edit || showModal.creat)}
                                    placeholder="Escribir..."
                                />
                            </Col>
                        </Row>
                        <Row className="d-flex">
                            <Col xs={12}>
                                <label className={tableStyles.crudModalLabel}>Centro de consumo*</label>
                                <Select 
                                    noOptionsMessage={() => 'No hay datos'}
                                    placeholder="Seleccionar..."
                                    key={"account" + trigger}
                                    options={optionsCostCenter}
                                    styles={customSelectNewDark}
                                    onChange={(e) =>
                                        setCreatePaymentCredit({
                                            ...createPaymentCredit,
                                            ccId: e.value,
                                        })
                                    }
                                    isDisabled={!(showModal.edit || showModal.creat)}
                                />
                            </Col>
                        </Row>
                        <Row className="d-flex">
                            <Col xs={6}>
                                <label className={tableStyles.crudModalLabel}>Fecha*</label>
                                <input
                                    key={"date" + trigger}
                                    type="date"
                                    name="activity name"
                                    // value={creatPettyCash.name}
                                    style={{ paddingLeft: "5px", color: "#7FADDE", fontWeight : "bold" }}
                                    onChange={(e) => setCreatePaymentCredit({
                                        ...createPaymentCredit,
                                        date: e.target.value
                                    })}
                                    className={IndividualStyles.registerInputsClearBlue}
                                    disabled={!(showModal.edit || showModal.creat)}
                                    placeholder="Escribir..."
                                />
                            </Col>
                            <Col xs={6}>
                                <label className={tableStyles.crudModalLabel}>Valor*</label>
                                <NumberFormat
                                    key={"amount" + trigger}
                                    allowNegative={false}
                                    style={{ paddingLeft: "5px", color: "#7FADDE", fontWeight : "bold" }}
                                    className={IndividualStyles.registerInputsClearBlue}
                                    name="valuepetty"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"$"}
                                    // value={creatPettyCash.totalAmountRegistro de Pago}
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        setCreatePaymentCredit({
                                            ...createPaymentCredit,
                                            totalAmount: value,
                                        })
                                    }}
                                    placeholder="Escribir..."

                                ></NumberFormat>
                            </Col>
                            <Col className="mt-3">
                                <span
                                    className={`${tableStyles.crudModalLabel} fs-6 fw-bold`}
                                >
                                    Detalle de la factura
                                </span>
                            </Col>
                        </Row>
                        <Row className="d-flex">
                            <Col xs={12}>
                                <label className={tableStyles.crudModalLabel}>Tercero*</label>

                                <Select 
                                    noOptionsMessage={() => 'No hay datos'}
                                    placeholder="Seleccionar..."
                                    key={"terce" + trigger}
                                    options={optionsThird}
                                    styles={customSelectNewDark}
                                    onChange={(e) =>
                                        setCreatePaymentCredit({
                                            ...createPaymentCredit,
                                            thirdParty: e.value,
                                        })
                                    }
                                    isDisabled={!(showModal.edit || showModal.creat)}
                                />
                            </Col>
                        </Row>

                        <Row className="d-flex">
                            <Col xs={6}>
                                <label className={tableStyles.crudModalLabel}>No. factura*</label>
                                <input
                                    key={"number" + trigger}
                                    type="text"
                                    name="activity name"
                                    onChange={(e) => setCreatePaymentCredit({
                                        ...createPaymentCredit,
                                        invoiceNbr: e.target.value
                                    })}
                                    style={!(showModal.edit || showModal.creat) ? { paddingLeft: "5px", color: "#58595B", backgroundColor: "#F5F7FA" } : { paddingLeft: "5px", color: "#58595B" }}
                                    className={IndividualStyles.registerInputsClearBlue}
                                    disabled={!(showModal.edit || showModal.creat)}
                                    placeholder="Escribir..."
                                />
                            </Col>
                            <Col xs={6}>
                                <label className={tableStyles.crudModalLabel}>Impuestos*</label>
                                <NumberFormat
                                    key={"amount" + trigger}
                                    allowNegative={false}
                                    style={!(showModal.edit || showModal.creat) ? { paddingLeft: "5px", color: "#58595B", backgroundColor: "#F5F7FA" } : { paddingLeft: "5px", color: "#58595B" }}
                                    className={IndividualStyles.registerInputsClearBlue}
                                    name="valuepetty"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"$"}
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        setCreatePaymentCredit({
                                            ...createPaymentCredit,
                                            taxAmount: value,
                                        })
                                    }}
                                    placeholder="Escribir..."

                                ></NumberFormat>
                            </Col>

                        </Row>

                        <Row className="d-flex">
                            <Col xs={6}>
                                <label className={tableStyles.crudModalLabel}>Factura*</label>
                                <div
                                    className={`${IndividualStyles.registerInputsClearBlue} hoverPointer d-flex`}
                                    style={{ backgroundColor: "#fff" }}
                                    type="text"
                                    onClick={() => ButtonDocuments()}
                                >
                                    <img src={docType(reqState)} alt="" srcset="" />
                                    <p
                                        className={tableStyles.crudModalLabel}
                                        style={{ marginTop: "0.2rem" }}
                                    >
                                        {reqState.file.name}
                                        {reqState.file.name ?
                                            <img
                                                src={Cerrar}
                                                style={{ marginLeft: "5px" }}
                                                onClick={(e) => resetStatusFile(e)} /> : ""}
                                    </p>
                                    <img
                                        style={{ marginLeft: "auto", width: "16px" }}
                                        src={Adjuntar} alt=""

                                    />
                                </div>
                            </Col>
                        </Row>

                    </ModalNew>

                </div>
                <GenericTableScroll
                    headers={header}
                    dark={true}
                    body={renderList()}
                    typeHead={"2"}
                >

                </GenericTableScroll>
                <div className={`d-flex`}>
                    <div className="col-1"></div>
                    <div className="col-2"></div>
                    <div className="col-3"></div>
                    <div className="col-4 text-center"><b style={{ fontSize: "18px", color: "#005DBF" }}>Total</b></div>
                    <div className="col-2 text-start"><b style={{ fontSize: "18px", color: "#005DBF" }}>$ {numberWithCommas(total)}</b></div>
                </div>
                <ModalDocuments
                    title="Anexar Documento"
                    key={"modalDocuments" + trigger}
                    show={modalDocumentsControl.show}
                    onHide={() => setmodalDocumentsControl({
                        ...modalDocumentsControl,
                        show: false
                    })}
                    reqState={reqState}
                    setreqState={setreqState}
                />
                <ModalNew
                    show={showModal1.show}
                    onHide={() => { setShowModal1({ show: false }) }}
                    title={"Registro de Pago"}
                    btnNoEvent={() => { setShowModal1({ show: false }) }}
                    btnNoName={"Aceptar"}
                    size={"500"}

                >
                    <Row className="d-flex">
                        <Col xs={12}>
                            <label className={tableStyles.crudModalLabel}>Concepto*</label>
                            <input
                                key={"concept" + trigger}
                                value={modalDetails.description}
                                type="text"
                                name="activity name"
                                className={IndividualStyles.registerInputsClearBlue}
                                disabled={true}
                                placeholder={"Escribir..."}
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex">
                        <Col xs={12}>
                            <label className={tableStyles.crudModalLabel}>Centro de consumo*</label>
                            <Select 
                                noOptionsMessage={() => 'No hay datos'}
                                value={{ label: modalDetails.ccName, value: "0" }}
                                key={"account" + trigger}
                                options={optionsCostCenter}
                                placeholder="Seleccionar..."
                                styles={customSelectNewDark}
                                isDisabled={true}
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex">
                        <Col xs={6}>
                            <label className={tableStyles.crudModalLabel}>Fecha*</label>
                            <input
                                key={"date" + trigger}
                                type="date"
                                style={{ paddingLeft: "5px", color: "#7FADDE", fontWeight : "bold" }}
                                value={modalDetails.date}
                                className={IndividualStyles.registerInputsClearBlue}
                                disabled={true}
                            />
                        </Col>
                        <Col xs={6}>
                            <label className={tableStyles.crudModalLabel}>Valor*</label>
                            <NumberFormat
                                key={"amount" + trigger}
                                allowNegative={false}
                                style={{ paddingLeft: "5px", color: "#7FADDE", fontWeight : "bold" }}
                                className={IndividualStyles.registerInputsClearBlue}
                                name="valuepetty"
                                thousandsGroupStyle="thousand"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"$"}
                                value={modalDetails.totalAmount}
                                isDisabled={true}
                                placeholder="Escribir..."
                                readOnly
                            ></NumberFormat>
                        </Col>

                    </Row>
                    <Row className="d-flex">
                        <Col xs={12}>
                            <label className={tableStyles.crudModalLabel}>Tercero*</label>

                            <Select 
                                noOptionsMessage={() => 'No hay datos'}
                                placeholder="Seleccionar..."
                                key={"terce" + trigger}
                                options={optionsThird}
                                value={{ label: modalDetails.thirdPartyName, value: "0" }}
                                styles={customSelectNew}
                                isDisabled={true}
                            />
                        </Col>
                    </Row>

                    <Row className="d-flex">
                        <Col xs={6}>
                            <label className={tableStyles.crudModalLabel}>No. factura*</label>
                            <input
                                key={"number" + trigger}
                                type="text"
                                value={modalDetails.invoiceNbr}
                                className={IndividualStyles.registerInputsClearBlue}
                                disabled={true}
                                placeholder="Escribir..."
                            />
                        </Col>
                        <Col xs={6}>
                            <label className={tableStyles.crudModalLabel}>Impuestos*</label>
                            <NumberFormat
                                key={"amount" + trigger}
                                allowNegative={false}
                                style={!(showModal.edit || showModal.creat) ? { paddingLeft: "5px", color: "#58595B", backgroundColor: "#F5F7FA" } : { paddingLeft: "5px", color: "#58595B" }}
                                className={IndividualStyles.registerInputsClearBlue}
                                name="valuepetty"
                                thousandsGroupStyle="thousand"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"$"}
                                value={modalDetails.taxAmount}
                                isDisabled={true}
                                placeholder="Escribir..."
                                readOnly

                            ></NumberFormat>
                        </Col>

                    </Row>

                    <Row className="d-flex">
                        <Col xs={6}>
                            <label className={tableStyles.crudModalLabel}>Factura*</label>
                            <div
                                className={`${IndividualStyles.registerInputsClearBlue} d-flex`}
                                style={{ backgroundColor: "#fff" }}
                                type="text"
                            // onClick={() => ButtonDocuments()}
                            >

                                <img src={docType(reqState)} alt="" srcset="" />
                                {/* <Link to={modalDetails.fileUrl}> */}
                                <a
                                    href={modalDetails.fileUrl}
                                    className={tableStyles.crudModalLabel}
                                    style={{ marginTop: "0.2rem", width: "145px" }}
                                >
                                    {modalDetails.fileUrl}
                                </a>
                                {/* </Link> */}
                                <img
                                    style={{ marginLeft: "auto", width: "16px" }}
                                    src={Adjuntar} alt=""

                                />
                            </div>
                        </Col>
                    </Row>

                </ModalNew>
            </div>

        </>
    )

};
export default CreatePaymentsTC;