import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Close from "../../assets/img/icons/modalClose.svg";
import blockIcon from "../../assets/img/icons/blockIcon.svg";
import block from "../../assets/img/icons/block.svg";
import modalnewBack from "../../assets/img/icons/modalnewBack.svg";
import modalnewNext from "../../assets/img/icons/modalnewNext.svg";
import editIcon from "../../assets/img/icons/editPencil.svg";
import styles from "../../components/Layouts/modalnew.module.scss";
import "../../OrderingModule/orderingStyles.css"
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function renderExtraButton(btn, index) {
    {/* poner aca botones extra con este formato.*/ }
    {/* crear un prop como este ejemplo
      extraButtons={[
          {
            type:1,
            name:"test",
            icon: extraBtnCheck,
            event: ()=>{console.log("extra event here 1")}
          }
      ]}
   */}
    let extraClass = "";
    switch (btn?.type) {
        case 2:
            //fondo blanco borde azul claro
            extraClass = tableStyles.buttonExtra2
            break;
        case 3:
            //fondo azul oscuro, texto blanco
            extraClass = tableStyles.buttonExtra3
            break;
        default:
            //fondo blanco borde azul oscuro
            extraClass = tableStyles.buttonExtra1
            break;
    }

    return (!!btn?.event) && (
        <Button
            key={"extraBtn" + index}
            onClick={btn?.event}
            className={`${extraClass} ${styles.mr16}`}
            disabled={btn?.disabled}
        >
            {!!btn?.icon && <img src={btn?.icon} alt="extraBtn" />}
            <p className={`m-0`}>{!!btn?.name ? btn?.name : "Extra"}</p>
        </Button>
    )
}

function OrdModal(props) {
    const mainContent = (
        <div className={`${styles.body} ${props.styleBody}`}>
            {props.children}

            <div className={props.hideFooter ? '' : styles.footer}>
                {props.hideBtnYes ? '' : !!props.btnYesEvent ?
                    <button
                        onClick={props.btnYesEvent}
                        className={`${tableStyles.ordBtnPrimary} ${props.btnYesStyle} ${styles.ml16}`}
                        disabled={props.btnYesDisabled}
                        style={props.iconBtnYes ? {display:'flex',justifyContent:'center', alignItems:'center',gap:'7px'}:{}}
                    >   
                        {props.iconBtnYes &&
                            <img className={props.iconBtnYesColor} height={17} src={props.iconSrc} alt="icon" />
                        }
                        {props.btnYesName ? props.btnYesName : "Guardar"}
                    </button>
                    : ""}
                {!props.hideCancelButton && (
                    <button
                        //for cases where i need a different action for the modal's negative button
                        //pick btnNoEvent, if not asigned, repeat the closing event
                        onClick={!!props.btnNoEvent ? props.btnNoEvent : props.onHide}
                        className={`${tableStyles.ordBtnSecondary} ${props.btnCancelStyle}`}
                        disabled={props.btnNoDisabled}
                    >
                        {props.btnNoName ? props.btnNoName : "Cancelar"}
                    </button>
                )}

                <div className={styles.extraButtons}>
                    {
                        (Array.isArray(props.extraButtons)) && (
                            props.extraButtons.map((x, index) => renderExtraButton(x, index))
                        )
                    }
                </div>


            </div>
        </div>
    );

    const additionalContent = (
        <div className={`${props.styleAdditionalContent ? styles.body + " " + props.styleAdditionalContent : styles.body} `}>
            {props.additionalContent}
        </div>
    );

    const renderToolImportance = (text) => (
        <Tooltip>
            <span style={{ color: "#c99489" }}>{text}</span>
        </Tooltip>
    );

    return (
        <Modal
            //{...props}
            //options: sm, sm2, lg and xl
            //if not asigned, default to medium md
            onHide={props.onHide}
            show={props.show}
            size={props.size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={styles.container}

        >
            <Modal.Header className={` ${props?.headerBorderNone}`}>
                <div className={`${styles.divHeaderModal} ${props?.headerModalClassName}`} style={props?.iconBlock?.active ? { display: 'flex', alignItems: 'center' } : {}}>
                    <h2 className={`${tableStyles.ordModalTitle} ${props?.titleClassName} pt-2`} style={props?.iconBlock?.active ? { flex: '1' } : {}} >
                        {props.title}
                    </h2>


                    {!!props.subtitle && <h3 className={`${styles.subtitle} ${props.subtitleStyle}`}
                        style={{ color: props?.colorSubtitle ? `${props.colorSubtitle}` : '#003F80' }}
                    >
                        {props.subtitle}
                    </h3>
                    }



                    {(!!props.leftArrowEvent && !!props.rightArrowEvent) ?
                        [<img
                            key="atras"
                            src={modalnewBack}
                            alt="atras"
                            className={styles.leftArrowIcon}
                            onClick={props.leftArrowEvent}
                            style={{ cursor: "pointer" }}
                        />,
                        <img
                            key="next"
                            src={modalnewNext}
                            alt="next"
                            className={styles.rightArrowIcon}
                            onClick={props.rightArrowEvent}
                        />]
                        : props?.iconBlock?.active ?
                            <div>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    className={styles.app_container_tool_websc}
                                    overlay={renderToolImportance(props?.iconBlock?.isBlock ? 'Desbloquear' : 'Bloquear')}
                                >
                                    <span>
                                        <img
                                            height={16}
                                            width={"22px"}
                                            alt={"Bloquear"}
                                            src={props?.iconBlock?.isBlock ? blockIcon : block}
                                            onClick={props?.onBlock}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    className={styles.app_container_tool_websc}
                                    overlay={renderToolImportance('Editar')}
                                >
                                    <span>
                                        <img
                                            height={16}
                                            width={"22px"}
                                            src={editIcon}
                                            alt="Editar"
                                            style={{ cursor: "pointer" }}
                                            onClick={props.onEdit}
                                        // style={{ marginRight: '30px', marginTop: '4px' }}
                                        />
                                    </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    className={styles.app_container_tool_websc}
                                    overlay={renderToolImportance('Cerrar')}
                                >
                                    <span>
                                        <img
                                            height={24}
                                            width={"22px"}
                                            src={Close}
                                            alt="Cerrar"
                                            style={{ cursor: "pointer" }}
                                            onClick={props.onHide}                                        // style={{ marginRight: '30px', marginTop: '4px' }}
                                        />
                                    </span>
                                </OverlayTrigger>

                                {/* <img
                                    src={blockIcon}
                                    alt="Bloquear"
                                    className={styles.closeIconModal}
                                    onClick={props.onBlock}
                                    style={{ marginRight: '60px', marginTop: '4px' }}
                                />
                                <img
                                    src={editIcon}
                                    alt="editar"
                                    className={styles.closeIconModal}
                                    onClick={props.onEdit}
                                    style={{ marginRight: '30px', marginTop: '4px' }}
                                /> */}



                                {/* <img

                                    src={Close}
                                    alt="Cerrar"
                                    className={styles.closeIconModal}
                                    onClick={props.onHide}
                                /> */}

                            </div>
                            :
                            <img
                                src={Close}
                                alt="Cerrar"
                                className={styles.closeIconModal}
                                onClick={props.onHide}
                            />
                    }

                </div>
            </Modal.Header>

            {!!props.additionalContent &&
                <Modal.Body className={`${props.modalBodyStyles ? props.modalBodyStyles : ''}`}>
                    {additionalContent}
                </Modal.Body>
            }

            {/* <Modal.Footer className={` ${props?.footerModalClassName}`}>
                {mainContent}
            </Modal.Footer> */}

            {
                props.cancelFooter
                    ?
                    <Modal.Footer className={` ${props?.footerModalClassName}`}>
                        <div></div>
                    </Modal.Footer>
                    :
                    <Modal.Footer className={` ${props?.footerModalClassName}`}>
                        {mainContent}
                    </Modal.Footer>
            }

        </Modal>
    );

}

export default OrdModal;
