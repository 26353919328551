import React from 'react'
import '../../../OrderingModule/orderingStyles.css'
import { Row, Col } from 'react-bootstrap'
import yellowExclude from "../../../assets/img/icons/yellowExclude.svg";
import greenDownArrow from "../../../assets/img/icons/greenDownArrow.svg";
import redArrow from "../../../assets/img/icons/redArrow.svg";
import tableStyles from '../../../components/Layouts/tableStyle.module.scss'
import { useHistory } from "react-router";
import { PseudoSideBarAccordion } from '../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion';
import { useSelector } from 'react-redux';
import { onCapitalLetter } from '../../../helpers/helpers';

export const OrderingHomeContent = () => {
    let history = useHistory();
    const storage = useSelector((state) => state);
    const { all_categories } = useSelector((state) => state?.loginReducer);
    const accountingModules = all_categories?.find((cat) => cat?.name === 'Ordenamientos')
    const { modules } = accountingModules
    // const titles = [
    //     {
    //         title: 'Ordenes',
    //         content: [
    //             { subtitle: "Ordenar", action: () => history.push('/ordenamientos/ordenes') },
    //             { subtitle: "Consultas", action: () => history.push("/ordenamientos/Consultas") }]
    //     },
    //     {
    //         title: 'Informes', content: [
    //             { subtitle: "Medicamentos Ordenados", action: () => history.push("/ordenamientos/medicamentosOrdenados") },
    //             { subtitle: "Informe de referencia", action: () => history.push("/ordenamientos/informeDeReferencia") },
    //             { subtitle: "Servicios Ordenados", action: () => history.push("/ordenamientos/serviciosOrdenados")}]
    //     },
    //     {
    //         title: 'Dirección médica', content: [
    //             { subtitle: "Autorizar medicamento", action: () => history.push("/ordenamientos/autorizarMedicamento") },
    //             { subtitle: "Autorizar servicios", action: () => history.push("/ordenamientos/autorizarServicio") },
    //             { subtitle: "Autorizar incapacidad", action: () => history.push("/ordenamientos/autorizarIncapacidad") }
    //         ]
    //     },
    // ]
    const contentDynamic = modules?.map((module) => {
        const textJoin = onCapitalLetter({text:module?.name})
        return {
            title: textJoin,
            content: module?.functionalities?.map((subModule)=>{
                let subModuleName = onCapitalLetter({text: subModule?.name})
                let returnValue = <></>
                let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === subModule?.prefix
                  )?.read
                if(permissionValidation){
                    returnValue = { subtitle: subModuleName, action: () => history.push(subModule?.url) };
                  }
                return returnValue
            })
        }
    })
    return (
        <div>
            {/* /* ---------------------------------- ICONS --------------------------------- */}
            <Row>
                <Col xs={3} className="cursorPointer mb-3">
                    <div
                        className={`${tableStyles.cardBoxContainer} mr-2 m-2`}
                    // onClick={() => history.push("/gerencia/indicadores/cartera")}
                    >
                        <div className={`d-flex text-start justify-content-between`}>
                            <div>
                                <p className="text-secondary">Solicitudes pendientes</p>
                                <p className={tableStyles.cardBoxBottomText}>
                                    0
                                </p>
                            </div>
                            <img
                                src={yellowExclude}
                                alt={"yellow"}
                                className={tableStyles.cardBoxBottomText}
                            ></img>
                        </div>
                    </div>
                </Col>
                <Col xs={3} className="cursorPointer">
                    <div
                        className={`${tableStyles.cardBoxContainer} mr-2 m-2`}
                    // onClick={() => history.push("/gerencia/indicadores/cartera")}
                    >
                        <div className={`d-flex text-start justify-content-between`}>
                            <div>
                                <p className="text-secondary">Formulas sin MIPRES</p>
                                <p className={tableStyles.cardBoxBottomText}>
                                    0
                                </p>
                            </div>
                            <img
                                src={greenDownArrow}
                                alt={"green"}
                                className={tableStyles.cardBoxBottomText}
                            ></img>
                        </div>
                    </div>
                </Col>
                <Col xs={3} className="cursorPointer">
                    <div
                        className={`${tableStyles.cardBoxContainer} mr-2 m-2`}
                    // onClick={() => history.push("/gerencia/indicadores/cartera")}
                    >
                        <div className={`d-flex text-start justify-content-between`}>
                            <div>
                                <p className="text-secondary">Entregas realizadas</p>
                                <p className={tableStyles.cardBoxBottomText}>
                                    0
                                </p>
                            </div>
                            <img
                                src={redArrow}
                                alt={"red"}
                                className={tableStyles.cardBoxBottomText}
                            ></img>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* /* ----------------------------- PSEUDO*/}

            {<PseudoSideBarAccordion accordionItems={contentDynamic} />}
        </div>
    )
}
