import React, { useState, useEffect } from 'react'
import tableStyles from "../../Layouts/tableStyle.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import lupa from "../../../assets/img/icons/lupa.svg";
import threeDots from "../../../assets/img/icons/threeDots.svg";

import Pagination from "react-js-pagination";
import GenericTable from "../../Layouts/GenericTable";
import ModalNew from '../../Layouts/ModalNew';
import Select, { components } from "react-select";
import {
    collection_get,
    getListNiifAcount,
    collection_edit_active,
    collection_insert,
    collection_edit,
    collection_get_one,
} from "../../../actions/collectionsActions";
import paginationStyles from "../../Layouts/pagination.module.scss";
import GenericTableNew from '../../Layouts/GenericTableNew';
import CustomPopup from "../../Popup/customPopup";
import { functional_get } from "../../../actions/functionalActions";
import Loader from 'react-loader-spinner';
import { customSelectNew3 } from './../../Layouts/react-select-custom';
import { optionsSelect, valueSelect } from '../../../helpers/helpers';
import SelectComponent from '../../SelectComponent/SelectComponent';
import { customSwaltAlert } from './../../../helpers/customSwaltAlert';
import { IOSSwitch } from '../../../helpers/constants';

import cash from "../../../assets/img/icons/moneySymbol2.svg";
import cardCredit from "../../../assets/img/icons/cardCredit.svg";
import transfer from "../../../assets/img/icons/transfer.svg";
import { useGetMethod } from '../../../Hooks';

const optionsIcons = [
    { value: "cash", label: "", icon: cash },
    { value: "cardCredit", label: "", icon: cardCredit },
    { value: "transfer", label: "", icon: transfer }
];

const Option = (props) => (
    <components.Option {...props} className="country-option">
        <img src={props.data.icon} alt="logo" className="country-logo" />
        {props.data.label}
    </components.Option>
);


export const TypesCollections = (props) => {

    const counter = useSelector(state => state);
    const token = counter.loginReducer.Authorization;
    const eaccount = counter.loginReducer.currentAccount.id;
    const userId = counter.loginReducer.user_data.id;
    const { cc: listNiifData } = counter.listNiifReducer

    const { cc: collectionGetOne, loading: loadCollectionGetOne } = counter.collectionGetOneReducer
    const { loading: loadCollection } = counter.collectionReducer

    const [paginationFunctionalUnit, setPaginationFunctionalUnit] = useState({
        pageFunctionalUnit: 1,
        perpageFunctionalUnit: 5,
        totalFunctionalUnit: 0,
    })

    const { 
        results: listTypesCollections,
        load: loadListTypesCollections,
        trigger: getListTypesCollections
    } = useGetMethod();

    const [putState, setPutState] = useState({
        userId: userId,
        eaccount: counter.loginReducer.currentAccount.id,
        usage: "",
        id: "",
        depoTypeName: undefined,
        depoTypeLabel: undefined,
        haveFranchise: undefined,
        haveReference: undefined,
        haveSupport: undefined,
        niifAccount: undefined,
        haveChange: undefined,
        haveBank: undefined,
        image: '',
        showFuncitonalUnits: false,
        functional_units: [],
        copyCenterCost: {},
    });

    console.log({putState})

    const dispatch = useDispatch();

    const history = useHistory();

    const [filters, setFilter] = useState({
        search: "",
        active: "",
        page: 1,
        perpage: 10,
        eaccount: counter.loginReducer.currentAccount.id
    });

    const [unconfirmedFilters, setUnconfirmedFilters] = useState({
        search: "",
        active: ""
    });

    const [trigger, setTrigger] = useState(0);
    const myPermission = () => counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "reca");
    let endCut = (paginationFunctionalUnit.perpageFunctionalUnit * paginationFunctionalUnit.pageFunctionalUnit)
    let startCut = (paginationFunctionalUnit.perpageFunctionalUnit * paginationFunctionalUnit.pageFunctionalUnit) - paginationFunctionalUnit.perpageFunctionalUnit
    endCut = endCut === 0 ? paginationFunctionalUnit.perpageFunctionalUnit : endCut
    let copyFunctionalArray = putState.copyCenterCost.functional_units
    let newArray = []
    let totalUnits = 0;

    const [selectedCountry, setSelectedCountry] = useState("");

    const handleChangeIcons = (value) => {
        setSelectedCountry(value);

        setPutState(state => ({
            ...state,
            image: value?.value
        }))

    };

    const handleChange = (event) => {
        setUnconfirmedFilters({
            ...unconfirmedFilters,
            search: event.target.value
        })
    }

    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <img src={selectedCountry.icon} alt="s-logo" className="selected-logo" />
            {children}
        </components.SingleValue>
    );



    if (copyFunctionalArray) {
        totalUnits = putState.copyCenterCost.functional_units.length
        newArray = copyFunctionalArray.slice(startCut, endCut)
    }
    const renderListUnits =
        newArray.map((item) => {
            return (
                <tr>
                    <td>{item.description}</td>
                </tr>
            )
        })

    const handleSumit = (e) => {
        e.preventDefault();
        setFilter({
            ...filters,
            search: unconfirmedFilters.search,
            active: unconfirmedFilters.active,
            page: 1
        })
        setTrigger(trigger + 1);
    }

    const handleSearchButton = () => {
        setFilter({
            ...filters,
            search: unconfirmedFilters.search,
            active: unconfirmedFilters.active,
            page: 1
        })
        setTrigger(trigger + 1);
    }

    const handleResetPutState = () => {
        setPutState({
            userId: userId,
            eaccount: counter.loginReducer.currentAccount.id,
            usage: "",
            id: "",
            depoTypeName: undefined,
            depoTypeLabel: undefined,
            haveFranchise: undefined,
            haveReference: undefined,
            haveChange: undefined,
            haveSupport: undefined,
            haveBank: undefined,
            niifAccount: undefined,
            image: undefined,
            showFuncitonalUnits: false,
            functional_units: [],
            copyCenterCost: {},
        });

        setSelectedCountry("")
    }

    const handleResetPaginationResetFunctionalUnits = () => {
        setPutState({
            showFuncitonalUnits: false,
            copyCenterCost: {},
        })
        setPaginationFunctionalUnit({
            pageFunctionalUnit: 1,
            perpageFunctionalUnit: 5,
            totalFunctionalUnit: 0
        })
    }

    function handleSubmit() {

        if (!putState?.depoTypeLabel) {
            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Se requiere la descripción',
                showCancelButton: false
            })
            return undefined;
        }

        if (!putState?.image) {
            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Se requiere el icono',
                showCancelButton: false
            })
            return undefined;
        }

        if (!putState?.niifAccountId) {
            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Se requiere la cuenta contable',
                showCancelButton: false
            })
            return undefined;
        }
        if (putState.usage === "PUT") {
            return dispatch(
                collection_edit(
                    putState.id,
                    putState,
                    () => {
                        handleResetPutState();
                        setTrigger(trigger + 1);
                    }
                )
            )
        }
        if (putState.usage === "POST") {
            return dispatch(
                collection_insert(
                    putState,
                    () => {
                        handleResetPutState();
                        setTrigger(trigger + 1);
                    }
                )
            )
        }
        return undefined;
    }

    const handlePageChangeUnitFunctional = (val) => {
        setPaginationFunctionalUnit({
            ...paginationFunctionalUnit,
            pageFunctionalUnit: val
        });
    }

    const handlePageChange = (val) => {
        setFilter({
            ...filters,
            page: val
        });
        setTrigger(state => state + 1)
    }

    const renderHeaders = [
        <th key={`1${Date.now()}`} className="px-2">Descripción</th>,
        <th key={`2${Date.now()}`} className="px-2 text-center">Franquicia</th>,
        <th key={`3${Date.now()}`} className="px-2 text-center">Referencia</th>,
        <th key={`4${Date.now()}`} className="px-2 text-center">Vuelto</th>,
        <th key={`5${Date.now()}`} className="px-2 text-center">Adjunto</th>,
        <th key={`6${Date.now()}`} className="px-2 text-center">Cuenta contable</th>,
        <th key={`7${Date.now()}`} className="text-center w100">Estado</th>,
        <th key={`8${Date.now()}`}>ㅤ</th>,
    ]

    const renderElement = (elem) => {
        const count = elem.functional_units;
        let renderCount = ""
        if (count)
            renderCount = count.length

        return (
            <tr key={elem.id} cme="hover-table-row">
                <td className="text-start px-2">{elem?.depoTypeLabel || '-'}</td>
                <td className="text-center px-2">{elem?.haveFranchise || '-'}</td>
                <td className="text-center px-2">{elem?.haveReference || '-'}</td>
                <td className="text-center px-2">{elem?.haveChange || '-'}</td>
                <td className="text-center px-2">{elem?.haveSupport || '-'}</td>
                <td className="text-center px-2">{elem?.niifAccountCompleteAccount || elem.haveSupport}</td>
                <td className="text-center px-2">
                    <div className={tableStyles.groupElems}>
                        <div className={elem.active ? tableStyles.greenState : tableStyles.redState}>
                            {elem.active ? 'Habilitado' : 'Inhabilitado'}
                        </div>
                    </div>
                </td>
                <td className="col-md-1">
                    {(myPermission()?.edit) ?
                        <CustomPopup
                            triggerSrc={threeDots}
                            showEdit={myPermission()?.edit && elem?.active}
                            editClickEvent={() => {
                                dispatch(collection_get_one({ eaccount: counter.loginReducer.currentAccount.id, depoTypeId: elem.depoTypeId }))
                            }}
                            showEnable={myPermission()?.delete}
                            isEnabled={elem.active}
                            enableClickEvent={() => {
                                dispatch(
                                    collection_edit_active(
                                        elem.depoTypeId,
                                        {
                                            depoTypeId: elem.depoTypeId,
                                            depoTypeName: elem.depoTypeName,
                                            depoTypeLabel: elem.depoTypeLabel,
                                            eaccount: counter.loginReducer.currentAccount.id,
                                            haveFranchise: elem.haveFranchise,
                                            haveReference: elem.haveReference,
                                            haveSupport: elem.haveSupport,
                                            niifAccount: elem.niifAccount,
                                            userId: userId,
                                            active: !elem.active
                                        },
                                        elem.depoTypeLabel,
                                        () => setTrigger(trigger + 1))
                                )
                            }}
                        />
                        : ""
                    }
                </td>
            </tr>
        );
    }

    const listElem = () => {
        let elemMap;
        if (listTypesCollections?.results !== undefined) {
            const elem2 = listTypesCollections?.results;
            elemMap = elem2.map((elem) => {
                return renderElement(elem);
            });
        }
        return elemMap;
    }


    useEffect(() => {
        
        getListTypesCollections({
            url: "/medical/admissions/depositType/",
            token: token,
            objFilters: filters
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);


    useEffect(() => {
        if (Object.values(collectionGetOne || {}).length) {
            setPutState(
                {
                    ...putState,
                    id: collectionGetOne?.depoTypeId,
                    depoTypeId: collectionGetOne?.depoTypeId,
                    depoTypeLabel: collectionGetOne?.depoTypeLabel,
                    usage: "PUT",
                    modalShow: true,
                    userId: userId,
                    eaccount: counter.loginReducer.currentAccount.id,
                    depoTypeName: collectionGetOne?.depoTypeLabel,
                    haveFranchise: collectionGetOne?.haveFranchise ? true : false,
                    haveReference: collectionGetOne?.haveReference ? true : false,
                    haveChange: collectionGetOne?.haveChange ? true : false,
                    haveSupport: collectionGetOne?.haveSupport ? true : false,
                    haveBank: collectionGetOne?.haveBank ? true : false,
                    niifAccount: collectionGetOne?.niifAccountId,
                    niifAccountId: collectionGetOne?.niifAccountId,
                    image: collectionGetOne?.image,
                    showFuncitonalUnits: false,
                    functional_units: [],
                    copyCenterCost: {},
                }
            )
            setSelectedCountry(optionsIcons?.find(el => el?.value === collectionGetOne?.image))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionGetOne])

    useEffect(() => {

        if (!myPermission()?.read) {
            history?.push("/administracion/inicio");
        }
        dispatch(functional_get({ perpage: 10 })); //active:1, 
        dispatch(getListNiifAcount({ active: 1, eaccount: counter.loginReducer.currentAccount.id }));
        setPutState(state => ({ ...state, modalShow: false }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='mt-4' style={{ width: '90%' }} >

            <div style={{ display: 'none' }}>
                <svg id='start' width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.38954 0.818237L12.0381 6.20396L17.961 7.0729L13.6753 11.2627L14.6867 17.1819L9.38954 14.3858L4.0924 17.1819L5.10383 11.2627L0.818115 7.0729L6.74097 6.20396L9.38954 0.818237Z" stroke="#005DBF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>

            {
                (counter.collectionReducer.loading || loadCollection || loadCollectionGetOne || loadListTypesCollections) && (
                    <div className="loading">
                        <Loader type="Oval" color="#003f80" height={100} width={100} />
                    </div>
                )
            }

            < div className={tableStyles.mainRow} style={{ marginBottom: '10px', alignItems: 'center' }
            }>
                {
                    myPermission()?.read && (
                        <form onSubmit={handleSumit}>
                            <input
                                style={{ width: '300px' }}
                                className={tableStyles.searchUsersAlt}
                                type="text"
                                placeholder="Buscar..."
                                onChange={handleChange}
                            />

                            <button onClick={() => handleSearchButton()} >
                                <img src={lupa} alt="User icon" className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`} />
                            </button>
                        </form>
                    )

                }

                <div className={tableStyles.groupElems}>

                {/* this is delete , watch task => AD-512 */}
                    {false &&
                        <div
                            className="d-flex justify-content-end groupAddButton align-items-center"
                            onClick={() => setPutState({ ...putState, usage: "POST", modalShow: true })}                          >
                            <label htmlFor="newAccident" className="darkGray fw-bold">
                                Agregar recaudo
                            </label>
                            <button
                                className="addCheckButton mx-2"
                                style={{ marginBottom: "1.5px" }}
                            />
                        </div>
                    }
                </div>
            </div >

            {
                putState.modalShow ?
                    <ModalNew
                        hideFooter={putState.usage === 'PUT' ? !myPermission()?.edit : !myPermission()?.create}
                        title={`${putState.usage === 'POST' ? 'Agregar' : 'Editar'} Recaudo`}
                        show={putState.modalShow}
                        onHide={() => handleResetPutState()}
                        btnYesEvent={() => handleSubmit()}
                        btnYesName='Aceptar'
                    >
                        <div className="row">

                            <div className="col-md-8">

                                <p className={`${tableStyles.crudModalLabel}`}>
                                    Descripción <b style={{ color: "#FF8B00" }}>*</b>
                                </p>
                                <row>
                                    <div className="col-md-12">
                                        <input
                                            disabled
                                            className="register-inputs"
                                            name="description"
                                            type="text"
                                            placeholder="Escribir…"
                                            defaultValue={putState.depoTypeLabel}
                                            // onChange={(event) => setPutState({ ...putState, depoTypeName: event.target.value, depoTypeLabel: event.target.value })}
                                        />
                                    </div>
                                </row>
                            </div>

                            <div className="col-md-4">
                                <p className={`${tableStyles.crudModalLabel}`}>
                                    Icono <b style={{ color: "#FF8B00" }}>*</b>
                                </p>
                                <Select
                                    isDisabled
                                    placeholder={'Seleccionar...'}
                                    value={selectedCountry}
                                    options={optionsIcons}
                                    // onChange={handleChangeIcons}
                                    styles={customSelectNew3}
                                    components={{
                                        Option,
                                        SingleValue
                                    }}
                                />
                            </div>

                        </div>

                        <div className="d-flex w-100 gap-2 align-items-center my-2">
                            <IOSSwitch
                                disabled
                                inputProps={{ "aria-label": "ant design" }}
                                name='haveFranchise'
                                checked={putState.haveFranchise}
                                // onChange={(event) => {
                                //     setPutState({ ...putState, haveFranchise: event.target.checked })
                                // }}
                            />
                            <span className='text-secondary'>Franquicia</span>
                        </div>



                        <div className="d-flex w-100 gap-2 align-items-center my-2">
                            <IOSSwitch
                                disabled
                                inputProps={{ "aria-label": "ant design" }}
                                name='haveReference'
                                checked={putState.haveReference}
                                // onChange={(event) => {
                                //     setPutState({ ...putState, haveReference: event.target.checked })
                                // }}
                            />
                            <span className='text-secondary'>Referencia</span>
                        </div>

                        <div className="d-flex w-100 gap-2 align-items-center my-2">
                            <IOSSwitch
                                disabled
                                inputProps={{ "aria-label": "ant design" }}
                                name='haveChange'
                                checked={putState.haveChange}
                                // onChange={(event) => {
                                //     setPutState({ ...putState, haveChange: event.target.checked })
                                // }}
                            />
                            <span className='text-secondary'>Opción de vuelto</span>
                        </div>

                        <div className="d-flex w-100 gap-2 align-items-center my-2">
                            <IOSSwitch
                                disabled
                                inputProps={{ "aria-label": "ant design" }}
                                name='haveSupport'
                                checked={putState.haveSupport}
                                // onChange={(event) => {
                                //     setPutState({ ...putState, haveSupport: event.target.checked })
                                // }}
                            />
                            <span className='text-secondary'>Relacionar archivos</span>
                        </div>

                        <div className="d-flex w-100 gap-2 align-items-center my-2">
                            <IOSSwitch
                                disabled
                                inputProps={{ "aria-label": "ant design" }}
                                name='haveBank'
                                checked={putState.haveBank}
                                // onChange={(event) => {
                                //     setPutState({ ...putState, haveBank: event.target.checked })
                                // }}
                            />
                            <span className='text-secondary'>Banco</span>
                        </div>

                        <div className='p-2 mb-4' style={{ borderRadius: '10px', background: '#005dbf0d' }}>
                            <p className={`${tableStyles.crudModalLabel}`}>
                                Cuenta contable <b style={{ color: "#FF8B00" }}>*</b>
                            </p>
                            <row>
                                <SelectComponent
                                    options={optionsSelect({
                                        list: listNiifData,
                                        label: 'description',
                                        value: 'id'
                                    })}

                                    value={valueSelect({
                                        list: listNiifData,
                                        findId: 'id',
                                        findLabel: 'description',
                                        value: putState.niifAccountId
                                    })}

                                    onChange={({ value }) => {
                                        setPutState({ ...putState, niifAccountId: value })
                                        console.log({a: putState?.niifAccountId})
                                    }}
                                />
                            </row>
                        </div>

                    </ModalNew>
                    : undefined
            }

            {
                putState.showFuncitonalUnits ?
                    (
                        <ModalNew
                            title={putState.copyCenterCost.description ? putState.copyCenterCost.description : "Centro de costo"}
                            show={putState.showFuncitonalUnits}
                            onHide={() => handleResetPaginationResetFunctionalUnits()}
                        >
                            <div >
                                <GenericTable headers={[(<th key={4}>Unidades funcionales</th>)]}>
                                    {renderListUnits}
                                </GenericTable>
                                <Pagination
                                    activePage={paginationFunctionalUnit.pageFunctionalUnit}
                                    itemsCountPerPage={paginationFunctionalUnit.perpageFunctionalUnit}
                                    totalItemsCount={totalUnits}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChangeUnitFunctional}
                                    itemClassPrev={paginationStyles.itemClassPrev}
                                    itemClassNext={paginationStyles.itemClassNext}
                                    itemClassFirst={paginationStyles.itemClassFirst}
                                    itemClassLast={paginationStyles.itemClassLast}
                                    itemClass={paginationStyles.itemClass}
                                />
                            </div>

                        </ModalNew>
                    ) : undefined
            }

            {
                myPermission()?.read && (
                    <GenericTableNew fontFamilyTable={'fontFamilyTable'} style={{ marginTop: '0px' }} dark={true} headers={renderHeaders}>
                        {listElem()}
                    </GenericTableNew>
                )
            }

            {
                myPermission()?.read && (

                    <div className={paginationStyles.wrapper}>
                        <p className={paginationStyles.paginationText}>
                            Pag. {listTypesCollections?.results?.length ? filters?.page : ""}
                            {" de "}
                            {Math.ceil(listTypesCollections?.rowTotal / filters?.perpage) ?
                                Math.ceil(listTypesCollections?.rowTotal / filters?.perpage)
                                :
                                ""
                            }
                            {" "}
                            ({listTypesCollections?.rowTotal} encontrados)
                        </p>
                        <Pagination
                            activePage={filters?.page}
                            itemsCountPerPage={10}
                            totalItemsCount={listTypesCollections?.rowTotal}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClassPrev={paginationStyles.itemClassPrev}
                            itemClassNext={paginationStyles.itemClassNext}
                            itemClassFirst={paginationStyles.itemClassFirst}
                            itemClassLast={paginationStyles.itemClassLast}
                            itemClass={paginationStyles.itemClass}
                        />
                    </div>
                )
            }
        </div >
    )
}
