import { Radio } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import makeAnimated from 'react-select/animated';

import Adjuntar from '../../../assets/img/icons/Adjuntar.svg';
import x from '../../../assets/img/icons/Cerrar.svg';
import backArrow from '../../../assets/img/icons/atras-icon.svg';
import iconCamera from '../../../assets/img/icons/blueCamera.svg';

import { useGetMethod } from '../../../Hooks/useFetch';
import { GET_EMPLOYEE_INFO } from '../../../actions/actionTypes';
import {
  getArl,
  getCandidateInfo,
  getCandidateList,
  getEPS,
  getEndowmentGroups,
  getPensionFund,
  updateCollaborator,
} from '../../../actions/partnersActions';
import { getBankingEntities } from '../../../actions/providersActions';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import {
  convertBase64,
  formatToRcSelect,
  isEmptyOrUndefined,
  message,
} from '../../../helpers/helpers';

import SelectComponent from '../../SelectComponent/SelectComponent';

import IndividualStyles from '../../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSelectMulti, customSelectNew } from '../../Layouts/react-select-custom';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import userStyles from '../../userProfile/UserProfile.module.scss';
import Styles from './partners.module.scss';

export const NewPartner = () => {
  const store = useSelector(state => state);
  const animatedComponents = makeAnimated();

  const paramsLocation = useLocation().state;
  const isEditing = paramsLocation?.isEditing;
  const employeeToEdit = paramsLocation?.employeeToEdit;
  const currentPercentage = paramsLocation?.currentPercentage;
  const module = paramsLocation?.module;
  const hasPermission = {
    read: paramsLocation?.hasPermission,
    create: paramsLocation?.hasPermission,
    update: paramsLocation?.hasPermission,
  };

  const bankList = store.providerReducer.bankingEntities;
  const arrEmployees = store.partnersReducer.employee_list;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const arrEps = store.partnersReducer.eps_list;
  const arrPensionFund = store.partnersReducer.pension_fund_list;
  const arrEndowmentGroups = store.partnersReducer.endowment_groups;
  const arrArl = store.partnersReducer.arl_list;
  const token = store.loginReducer.Authorization;
  let employeeInfo = store.partnersReducer?.employee_info;

  const [assign, setAssign] = useState(false);
  const [currentEndowment, setCurrentEndowment] = useState(null);
  const [info, setInfo] = useState([]);
  const [clearData, setclearData] = useState();
  const [counter, setCounter] = useState(0);

  let asterisk = <b style={{ color: '#FF8B00' }}>*</b>;

  const accTypes = [
    { value: 'A', label: 'Ahorros' },
    { value: 'CO', label: 'Corriente' },
  ];
  const myPermission = paramsLocation?.hasPermission
    ? hasPermission
    : store.loginReducer.currentAccount?.profile?.permission?.find(
        x => x.functionality?.prefix === 'position',
      );

  const { results: listRiskLevel, trigger: getListRiskLevel } = useGetMethod();
  const { results: listCompensantionCenter, trigger: getListCompensantionCenter } = useGetMethod();

  const [files, setFiles] = useState([
    {
      filename: null,
      support_type: 's3_photo',
      base64: null,
    },
    {
      filename: null,
      support_type: 'cv',
      base64: null,
    },
    {
      filename: null,
      support_type: 's3_bank_certificate_doc',
      base64: null,
    },
    {
      filename: null,
      support_type: 's3_data_verification',
      base64: null,
    },
    {
      filename: null,
      support_type: 's3_clinic_history',
      base64: null,
    },
  ]);
  const [image, setimage] = useState({
    file: '',
    file64: '',
    fileUrl: employeeInfo?.photo,
  });
  const [inputs, setInputs] = useState({
    cv: null,
    bank: undefined,
    colaborator: { value: undefined },
    eps: undefined,
    arl: undefined,
    pensionFund: { value: undefined },
    type: null,
    documents: files,
    endowmentGroups: null,
    bankNumber: null,
    pensioner: false,
    risk_level_id: undefined,
    compensation_center_entity: undefined,
    payment_method: 'Transferencia',
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setclearData(clearData + 1);
    dispatch({
      type: GET_EMPLOYEE_INFO,
      payload: {},
    });
    setimage({ file: '', file64: '', fileUrl: employeeInfo?.photo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearData]);

  const formattedEmployees = formatToRcSelect(
    arrEmployees,
    'id_contract',
    'full_name',
    'id_candidate',
    '',
    'job_title',
  );

  const fotmattedEndowments = formatToRcSelect(
    arrEndowmentGroups,
    'id',
    'description',
    '',
    '',
    false,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const bankListFormatted = formatToRcSelect(bankList, 'id', 'description', '');
  const epsListFormatted = formatToRcSelect(arrEps, 'id', 'description', '');
  const pensionFundFormatted = formatToRcSelect(arrPensionFund, 'id', 'description', '');
  const arlListFormatted = formatToRcSelect(arrArl, 'id', 'description', '');
  const riskLevelOptions = formatToRcSelect(
    listRiskLevel.results,
    'riskLevelId',
    'riskLevelDescription',
    '',
    '',
    'riskLevelValue',
    true,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '%',
  );
  const compensationCenterOptions = formatToRcSelect(
    listCompensantionCenter.results,
    'id',
    'alias',
    '',
    '',
    '', //
    true,
  );
  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/nomina/inicio');
    }
    if (isEditing) {
      if (!isEditing) {
        setInputs({ ...inputs, bankNumber: employeeInfo.bank_account_nbr });
      }
      dispatch(
        getCandidateInfo(
          {
            id_candidate: employeeToEdit?.id_candidate,
            id_contract: employeeToEdit?.id_contract,
          },
          res => {
            setInputs({
              cv: null,
              bank: res.results?.bankingEntity ?? '',
              colaborator: { value: employeeToEdit?.id_candidate },
              eps: res.results?.hce_id ?? '',
              arl: res.results?.occupational_risk_entity ?? '',
              risk_level_id: res.results?.risk_level_id ?? '',
              compensation_center_entity: res.results?.compensation_center_id ?? '',
              pensionFund: pensionFundFormatted.find(
                obj => obj.value === employeeInfo?.pension_fund_entity,
              ) || {label: employeeInfo?.pension_description, value: employeeInfo?.pension_fund_entity},
              type: accTypes.find(obj => obj.value === employeeToEdit?.bank_account_type),
              documents: files,
              endowmentGroups: null,
              bankNumber: employeeToEdit.bank_account_nbr,
              pensioner: !!res?.results?.pensioner ?? false,
              payment_method: employeeInfo.payment_method,
            });
          },
        ),
      );
    }
    dispatch(getCandidateList({ status: 'signed', eaccount: idEnterprise }));
    dispatch(getBankingEntities());
    dispatch(getEPS());
    dispatch(getPensionFund());
    dispatch(getArl());
    dispatch(getEndowmentGroups({ active: 1 }));

    getListRiskLevel({
      url: `/payroll/occupationalRiskLevels/`,
      objFilters: { riskLevelId: inputs?.risk_level_id },
      token: token,
    });
    getListCompensantionCenter({
      url: `/payroll/compensation-center-entities`,
      objFilters: { compensation_center_entity: inputs?.compensation_center_entity },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImagePick = async e => {
    if (module === 'miNomina') {
      return;
    }

    if (!e.target.files[0]) {
      return;
    }
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];

    let f = e.target.files[0];
    let name_doc = f.name.split('.')[0];
    let type_doc = f.type;
    let ext = type_doc.split('/');

    let finalName = `${name_doc}.${ext[1]}`;

    setimage({
      ...image,
      file: e.target.files[0],
      file64: file64,
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });

    files[0].base64 = file64;
    files[0].filename = finalName;
  };
  const cvFile = () => {
    if (employeeInfo.documents?.length > 0) {
      let name = employeeInfo.documents
        ?.find(cv => cv.prefix === 'cv')
        ?.s3_location?.split('/')
        .pop();
      return name;
    }
  };
  useEffect(() => {
    employeeInfo?.endowmentGroups?.length > 0 ? setAssign(true) : setAssign(false);
  }, [employeeInfo?.endowmentGroups]);

  /* ----------------------------- HANDLE CHANGES ----------------------------- */
  const onChangeDoc = async (e, supportType) => {
    setCounter(counter + 1);
    const file = e.target.files[0];
    const validExtensions = ['zip', 'pdf', 'rar'];
    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split('.');
      let extension = validExtensions.find(obj => obj === actFileExt[actFileExt.length - 1]);
      if (isEmptyOrUndefined(extension)) {
        e.target.value = '';
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ].toUpperCase()}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }

      if (file.size > 50000000) {
        e.target.value = '';
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Su archivo no puede tener un tamaño superior a 5MB',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        if (file.size <= 0) {
          e.target.value = '';
          return customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'Por favor, adjunte un archivo valido.',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      }
    } else {
      return console.error('Archivo vacio: ', file);
    }
    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split('.')[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    let ext = type_doc.split('/');
    let finalName = `${name_doc}.${ext[1]}`;
    setInfo({
      ...info,
      file64: file64,
      fileName: name_doc,
      type_doc: type_doc,
    });
    switch (supportType) {
      case 'cv':
        files[1].filename = finalName;
        files[1].base64 = file64;
        break;
      case 's3_bank_certificate_doc':
        files[2].filename = finalName;
        files[2].base64 = file64;
        break;

      case 's3_data_verification':
        files[3].filename = finalName;
        files[3].base64 = file64;
        break;
      case 's3_clinic_history':
        files[4].filename = finalName;
        files[4].base64 = file64;
        break;

      default:
        break;
    }
  };
  const handleSubmit = async () => {
    let remoteCvLocation = employeeInfo?.documents
      ?.find(cv => cv.prefix === 'cv')
      ?.s3_location?.toString();

    let remoteDocVerificated = employeeInfo?.s3_data_verification?.toString();

    if (isEmptyOrUndefined(remoteCvLocation) && isEmptyOrUndefined(files[1].base64)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El documento de hoja de vida es obligatorio',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    if (
      isEmptyOrUndefined(remoteDocVerificated) &&
      isEmptyOrUndefined(employeeToEdit?.s3_data_verification?.toString()) &&
      isEmptyOrUndefined(files[3].filename)
    ) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El documento de verificación de datos es obligatorio',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    try {
      if (isEmptyOrUndefined(inputs.colaborator)) {
        setIsLoading(false);
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Seleccione un colaborador',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      if (isEmptyOrUndefined(inputs.eps)) {
        setIsLoading(false);
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Seleccione una EPS',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      if (isEmptyOrUndefined(inputs?.pensionFund)) {
        setIsLoading(false);
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Seleccione un fondo de pensiones',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      if (inputs.payment_method === 'Transferencia') {
        if (isEmptyOrUndefined(inputs.bankNumber)) {
          setIsLoading(false);
          return message('warning', 'Advertencia', 'Escriba un número de cuenta', undefined, true);
        }
        if (isEmptyOrUndefined(inputs.type)) {
          setIsLoading(false);
          return message('warning', 'Advertencia', 'Seleccione un tipo de cuenta', undefined, true);
        }
        if (isEmptyOrUndefined(inputs.bank)) {
          setIsLoading(false);
          return message('warning', 'Advertencia', 'Seleccione un banco', undefined, true);
        }
      }

      if (isEmptyOrUndefined(inputs.arl)) {
        setIsLoading(false);
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Seleccione una ARL',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      if (isEmptyOrUndefined(inputs.risk_level_id)) {
        setIsLoading(false);
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Seleccione un nivel de riesgo ocupacional',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      if (isEmptyOrUndefined(inputs.compensation_center_entity)) {
        setIsLoading(false);
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Seleccione una caja de compensación',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      if (inputs.pensioner === false) {
        if (isEmptyOrUndefined(inputs?.pensionFund?.value)) {
          setIsLoading(false);
          return customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'Seleccione un fondo de pensiones',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      }
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: isEditing
          ? `¿ Deseas actualizar los datos de ${employeeToEdit?.full_name} ?`
          : `${
              employeeInfo.full_name
            }  comenzará a hacer parte de la nómina de ${store.loginReducer?.currentAccount?.name.toUpperCase()}`,
        confirmButtonText: 'Si, Continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            setIsLoading(true);
            try {
              const result = await updateCollaborator(
                {
                  id_candidate: employeeInfo.id_candidate || employeeToEdit?.id_candidate,
                  id_contract: employeeInfo.id_contract || employeeToEdit?.id_contract,
                  full_name: employeeInfo.full_name || employeeToEdit?.full_name,
                  entity_account: idEnterprise,
                  healthcare_entity: inputs.eps,
                  pension_fund_entity: inputs.pensionFund?.value,
                  occupational_risk_entity: inputs.arl,
                  bank_account_nbr: inputs?.bankNumber,
                  bank_account_type: inputs?.type?.value,
                  bankingEntity: inputs?.bank,
                  status: 'verified',
                  contract_status: 'current',
                  endowmentGroups: inputs.endowmentGroups,
                  document: files,
                  percentage: isEditing ? currentPercentage : 20,
                  risk_level_id: inputs?.risk_level_id,
                  compensation_center_entity: inputs?.compensation_center_entity,
                  pensioner: inputs?.pensioner,
                  payment_method: inputs?.payment_method,
                },
                token,
              );

              if (result.success) {
                setIsLoading(false);

                return customSwaltAlert({
                  icon: 'success',
                  title: isEditing ? 'Actualizado exitosamente' : 'creado exitosamente',
                  text: isEditing
                    ? `Se ha actualizado el candidato: ${employeeInfo.full_name}`
                    : `Se ha creado el candidato: ${employeeInfo.full_name}`,
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                }).finally(() => {
                  if (module === 'miNomina') {
                    history.goBack();
                  } else {
                    history.push({
                      pathname: '/nomina/colaborador/curriculo',
                      state: {
                        employeeInfo: isEditing
                          ? { ...employeeToEdit, isEditing: true }
                          : employeeInfo,
                      },
                    });

                    dispatch({
                      type: GET_EMPLOYEE_INFO,
                      payload: {
                        id_contract: '',
                        full_name: '',
                        birthdate: '',
                        description: '',
                        job_position: '',
                        job_title: '',
                        consumption_center: '',
                        functional_unit_description: '',
                        start_date: '',
                        site_id: '',
                        site_name: '',
                        site_address: '',
                        base_salary: '',
                        non_const_income: '',
                        hce_id: '',
                        hce_description: '',
                        pension_fund_entity: '',
                        pension_description: '',
                        occupational_risk_entity: '',
                        occupational_risk_description: '',
                        bank_account_nbr: '',
                        bank_account_type: '',
                        bankingEntity: '',
                      },
                    });

                    setInputs({
                      cv: null,
                      bank: null,
                      colaborator: {},
                      eps: null,
                      arl: '',
                      pensionFund: null,
                      type: null,
                      documents: files,
                      endowmentGroups: null,
                      risk_level_id: null,
                      compensation_center_entity: null,
                    });
                  }
                });
              } else {
                setIsLoading(false);
                return customSwaltAlert({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: result.message,
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                });
              }
            } catch (error) {
              setIsLoading(false);
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: error.message,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            }
          };
          send();
        }
      });
    } catch (error) {
      setIsLoading(false);
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error.message,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

  const handleBack = () => {
    setclearData(clearData + 1);
    history.goBack();
  };
  const getEmployeeInfo = e => {
    dispatch({
      type: GET_EMPLOYEE_INFO,
      payload: {
        id_contract: '',
        full_name: '',
        birthdate: '',
        description: '',
        job_position: '',
        job_title: '',
        consumption_center: '',
        functional_unit_description: '',
        start_date: '',
        site_id: '',
        site_name: '',
        site_address: '',
        base_salary: '',
        non_const_income: '',
        hce_id: '',
        hce_description: '',
        pension_fund_entity: '',
        pension_description: '',
        occupational_risk_entity: '',
        occupational_risk_description: '',
        bank_account_nbr: '',
        bank_account_type: '',
        bankingEntity: '',
      },
    });
    setFiles([
      {
        filename: null,
        support_type: 's3_photo',
        base64: null,
      },
      {
        filename: null,
        support_type: 'cv',
        base64: null,
      },
      {
        filename: null,
        support_type: 's3_bank_certificate_doc',
        base64: null,
      },
      {
        filename: null,
        support_type: 's3_data_verification',
        base64: null,
      },
      {
        filename: null,
        support_type: 's3_clinic_history',
        base64: null,
      },
    ]);
    setInputs({
      cv: null,
      bank: '',
      colaborator: {},
      eps: null,
      arl: '',
      pensionFund: null,
      type: null,
      documents: files,
      endowmentGroups: null,
      risk_level_id: '',
      compensation_center_entity: '',
      pensioner: false,
    });
    if (!isEmptyOrUndefined(e?.value)) {
      setInputs({ ...inputs, colaborator: e });
      dispatch(getCandidateInfo({ id_candidate: e.extra, id_contract: e?.value }));
    }
  };

  const handleEndowments = e => {
    setCurrentEndowment(e);
    let arrIdEnd = [];

    if (e.length > 0) {
      e.forEach(elem => {
        arrIdEnd.push(elem.value);
      });
    }
    setInputs({ ...inputs, endowmentGroups: arrIdEnd });
  };

  const onChangeCheckBox = () => {
    if (inputs.pensioner) {
      setInputs({ ...inputs, pensioner: false });
    } else {
      setInputs({ ...inputs, pensioner: true });
    }
  };

  const handleChangePaymentMethod = (value, key) => {
    setInputs({ ...inputs, [key]: value });
  };

  return (
    <>
      {isLoading ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      <div className='w-100 container'>
        <Row>
          <Col xs={10} style={{ marginLeft: '-32px' }}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={backArrow}
                className={`mr-2 mb-2 cursorPointer`}
                alt='icon'
              ></img>
              {isEditing ? 'Editar colaborador' : 'Nuevo colaborador'}
            </h1>
          </Col>
        </Row>
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <div className='d-flex justify-content-between'>
          <div className='mt-4' style={{ width: '75%' }}>
            {isEditing ? (
              <>
                <p className={`${tableStyles.crudModalLabel} `}>Colaborador seleccionado</p>
                <input
                  disabled={true}
                  value={employeeToEdit?.full_name}
                  className='register-inputs text-secondary'
                  name='position'
                />
              </>
            ) : (
              <>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Seleccionar colaborador
                  <span className={'text-danger'}>*</span>
                </p>
                <SelectComponent
                  key={'colab'}
                  onChange={e => getEmployeeInfo(e)}
                  options={formattedEmployees}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNew}
                  value={formattedEmployees.find(x => x.value === inputs?.colaborator?.value)}
                ></SelectComponent>
              </>
            )}

            <Row className='mt-2'>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Código de contrato</p>
                <input
                  disabled={true}
                  value={
                    isEditing
                      ? employeeToEdit?.id_contract
                      : employeeInfo?.id_contract
                        ? employeeInfo?.id_contract
                        : ''
                  }
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Nombre del cargo</p>
                <input
                  disabled={true}
                  value={
                    isEditing
                      ? employeeToEdit?.job_title
                      : employeeInfo?.job_title
                        ? employeeInfo?.job_title
                        : ''
                  }
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Fecha inicio
                  <span className={'text-danger'}>*</span>
                </p>
                <input
                  value={
                    isEditing
                      ? employeeToEdit?.start_date
                      : employeeInfo?.start_date
                        ? employeeInfo?.start_date
                        : ''
                  }
                  disabled={true}
                  type='date'
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
            </Row>
            <h6 className={`${tableStyles.darkBlueText} mt-4`}>
              <b>• Contabilidad</b>
            </h6>

            <Row className=''>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Centro de consumo</p>
                <input
                  disabled={true}
                  value={
                    isEditing
                      ? employeeToEdit?.consumption_center
                      : employeeInfo?.consumption_center
                        ? employeeInfo?.consumption_center
                        : ''
                  }
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Unidad funcional</p>
                <input
                  disabled={true}
                  value={
                    isEditing
                      ? employeeToEdit?.functional_unit_description
                      : employeeInfo?.functional_unit_description
                        ? employeeInfo?.functional_unit_description
                        : ''
                  }
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Salario</p>
                <NumberFormat
                  disabled={true}
                  className={`register-inputs text-secondary`}
                  name='rnd_value_note'
                  allowNegative={false}
                  prefix='$'
                  value={
                    isEditing
                      ? employeeToEdit?.base_salary
                      : employeeInfo?.base_salary
                        ? employeeInfo?.base_salary
                        : ''
                  }
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                />
              </Col>
            </Row>

            <Row className=''>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Ingreso no constitutivo</p>
                <NumberFormat
                  disabled={true}
                  className={`register-inputs text-secondary`}
                  name='ingress_not_const'
                  allowNegative={false}
                  prefix='$'
                  value={
                    isEditing
                      ? employeeToEdit?.non_const_income
                      : employeeInfo?.non_const_income
                        ? employeeInfo?.non_const_income
                        : ''
                  }
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Sede</p>
                <input
                  value={
                    isEditing
                      ? employeeToEdit?.site_name
                      : employeeInfo?.site_name
                        ? employeeInfo?.site_name
                        : ''
                  }
                  disabled={true}
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Dirección</p>
                <input
                  value={
                    isEditing
                      ? employeeToEdit?.site_address
                      : employeeInfo?.site_address
                        ? employeeInfo?.site_address
                        : ''
                  }
                  disabled={true}
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
            </Row>

            <Row className=''>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>EPS</p>
                <SelectComponent
                  value={epsListFormatted.find(x => x.value === inputs.eps)}
                  onChange={({ value }) => {
                    setInputs({ ...inputs, eps: value });
                  }}
                  options={epsListFormatted}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNew}
                ></SelectComponent>
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>ARL</p>
                <SelectComponent
                  isDisabled={module === 'miNomina'}
                  value={arlListFormatted.find(x => x.value === inputs.arl)}
                  onChange={({ value }) => {
                    if (value === '') {
                      setInputs({ ...inputs, arl: value, risk_level_id: '' });
                    } else {
                      setInputs({ ...inputs, arl: value });
                    }
                  }}
                  options={arlListFormatted}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNew}
                ></SelectComponent>
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Niveles de riesgo ocupacional</p>

                <SelectComponent
                  isDisabled={module === 'miNomina'}
                  options={riskLevelOptions}
                  value={riskLevelOptions.find(x => x.value === inputs.risk_level_id)}
                  onChange={({ value }) => {
                    setInputs({ ...inputs, risk_level_id: value });
                  }}
                  className='text-secondary'
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Caja de compensación</p>

                <SelectComponent
                  options={compensationCenterOptions}
                  value={compensationCenterOptions.find(
                    x => x.value === inputs.compensation_center_entity,
                  )}
                  onChange={({ value }) => {
                    setInputs({ ...inputs, compensation_center_entity: value });
                  }}
                  className='text-secondary'
                />
              </Col>
              {inputs.pensioner === false && (
                <Col xs={4}>
                  <p className={`${tableStyles.crudModalLabel} `}>Fondo de pensiones</p>
                  <SelectComponent
                    onChange={e => setInputs({ ...inputs, pensionFund: e })}
                    value={
                      inputs.pensionFund === undefined
                        ? pensionFundFormatted.find(
                            obj => obj.value === employeeToEdit?.pension_fund_entity,
                          ) || ''
                        : inputs.pensionFund || ''
                    }
                    options={pensionFundFormatted}
                    className='text-secondary'
                    placeholder='Seleccionar...'
                    styles={customSelectNew}
                  ></SelectComponent>
                </Col>
              )}
              <Col className=' align-self-center ' xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>&nbsp;</p>
                <div className='d-flex'>
                  <input
                    disabled={module === 'miNomina'}
                    checked={inputs.pensioner}
                    id={`checkBox-`}
                    key={`keyCheckbox-`}
                    onChange={e => onChangeCheckBox(e)}
                    className={` form-check-input p1 `}
                    type='checkbox'
                    style={{ width: '16px', height: '16px', border: ' 1.5px solid #7FADDE' }}
                  />
                  <label className={`${tableStyles.darkBlueText} ${tableStyles.f12} px-2 pt-1`}>
                    Pensionado
                  </label>
                </div>
              </Col>
            </Row>

            {isEmptyOrUndefined(inputs?.colaborator?.value) ? (
              <></>
            ) : (
              <>
                <Row
                  className={` mt-4 pb-2 ${tableStyles.bgClearBlue}`}
                  style={{ borderRadius: '10px' }}
                >
                  <h6 className={`${tableStyles.darkBlueText} pt-1`}>
                    <b>• Método de pago</b>
                  </h6>
                  <div className='d-flex'>
                    <div className='d-flex'>
                      <Radio
                        checked={inputs.payment_method === 'Transferencia' ? true : false}
                        onClick={() => {
                          if (module !== 'miNomina') {
                            handleChangePaymentMethod('Transferencia', 'payment_method');
                          }
                        }}
                        name='alphabetic'
                        style={{ color: '#005dbf' }}
                      />
                      <label
                        className={`${tableStyles.darkBlueText} ${tableStyles.f12} px-2 pt-1 align-self-center`}
                      >
                        Transferencia
                      </label>
                    </div>
                    <div className='d-flex'>
                      <Radio
                        checked={inputs.payment_method === 'Cheque' ? true : false}
                        onClick={() => {
                          if (module !== 'miNomina') {
                            handleChangePaymentMethod('Cheque', 'payment_method');
                          }
                        }}
                        name='alphabetic'
                        style={{ color: '#005dbf' }}
                      />
                      <label
                        className={`${tableStyles.darkBlueText} ${tableStyles.f12} px-2 pt-1 align-self-center`}
                      >
                        Cheque
                      </label>
                    </div>
                  </div>
                  {inputs.payment_method === 'Transferencia' && (
                    <>
                      <h6 className={`${tableStyles.darkBlueText} pt-1`}>
                        <b>• Cuenta bancaria del empleado</b>
                      </h6>
                      <Col xs={4}>
                        <p className={`${tableStyles.crudModalLabel} `}>No. Cuenta{asterisk}</p>
                        <input
                          disabled={module === 'miNomina'}
                          onChange={e => {
                            setInputs({ ...inputs, bankNumber: e.target.value });
                          }}
                          value={inputs.bankNumber}
                          placeholder='Escribe...'
                          className='register-inputs text-secondary'
                          type='number'
                        />
                      </Col>
                      <Col xs={4}>
                        <p className={`${tableStyles.crudModalLabel} `}>Tipo{asterisk}</p>
                        <SelectComponent
                          isDisabled={module === 'miNomina'}
                          value={
                            inputs.type === null
                              ? accTypes.find(obj => obj.value === employeeInfo?.bank_account_type)
                              : inputs.type
                          }
                          options={accTypes}
                          className='text-secondary'
                          onChange={e => setInputs({ ...inputs, type: e })}
                          placeholder={'Seleccionar...'}
                          styles={customSelectNew}
                        ></SelectComponent>
                      </Col>
                      <Col xs={4}>
                        <p className={`${tableStyles.crudModalLabel} `}>Banco{asterisk}</p>
                        <SelectComponent
                          isDisabled={module === 'miNomina'}
                          value={bankListFormatted.find(x => x.value === inputs.bank)}
                          onChange={({ value }) => {
                            setInputs({ ...inputs, bank: value });
                          }}
                          options={bankListFormatted}
                          className='text-secondary'
                          placeholder={'Seleccionar...'}
                          styles={customSelectNew}
                        ></SelectComponent>
                      </Col>
                    </>
                  )}
                </Row>
                <h6 className={`${tableStyles.darkBlueText} mt-2`}>
                  <b>• Anexos</b>
                </h6>
                <Row className=''>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Hoja de vida
                      <span className={'text-warning'}>*</span>
                    </p>
                    <Dropzone disabled={module === 'miNomina'}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className='d-flex justify-content-between'
                          style={{
                            border: '1px solid #7FADDE',
                            borderRadius: '5px',
                            height: '52%',
                          }}
                        >
                          <input
                            key={`cv-${counter}`}
                            disabled={
                              inputs.colaborator === null || module === 'miNomina' ? true : false
                            }
                            {...getInputProps()}
                            // type='file'
                            className='register-inputs text-secondary'
                            name='documents'
                            onChange={e => module !== 'miNomina' && onChangeDoc(e, 'cv')}
                            multiple={false}
                          />
                          {!isEmptyOrUndefined(files[1].filename) ? (
                            <p
                              style={{ margin: '0' }}
                              className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsis} hoverPointer`}
                            >
                              {files[1].filename} &nbsp;
                              {!isEmptyOrUndefined(files[1].filename) &&
                                isEmptyOrUndefined([1].base64) && (
                                  <img
                                    width='20px'
                                    className='cursorPointer'
                                    // onClick={() => {
                                    //   files[1].base64 = '';
                                    //   files[1].filename = '';
                                    // }}
                                    alt='icon'
                                    src={x}
                                  ></img>
                                )}
                            </p>
                          ) : (
                            <p
                              className={`${IndividualStyles.crudModalLabelGris} hoverPointer
                          ${IndividualStyles.ellipsis}`}
                            >
                              {employeeInfo?.documents
                                ?.find(cv => cv.prefix === 'cv')
                                ?.s3_location?.split('/')
                                .pop()}
                            </p>
                          )}
                          {isEmptyOrUndefined(
                            employeeInfo?.documents
                              ?.find(cv => cv.prefix === 'cv')
                              ?.s3_location?.split('/')
                              .pop(),
                          ) ? (
                            <img
                              className={Styles.filterImage}
                              src={Adjuntar}
                              title='añadir'
                              width='6%'
                              alt='icon'
                            />
                          ) : (
                            <img
                              className={Styles.filterImage}
                              onClick={
                                isEmptyOrUndefined(cvFile())
                                  ? null
                                  : () => {
                                      if (module !== 'miNomina') {
                                        window.location.href = employeeInfo?.documents?.find(
                                          cv => cv.prefix === 'cv',
                                        )?.s3_location;
                                      }
                                    }
                              }
                              alt='icon'
                              width='6%'
                              src={Adjuntar}
                              title='descargar'
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Resultados verificación de datos
                      <span className={'text-danger'}>*</span>
                    </p>
                    <Dropzone>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className='d-flex justify-content-between'
                          style={{
                            border: '1px solid #7FADDE',
                            borderRadius: '5px',
                            height: '52%',
                          }}
                        >
                          <input
                            key={`verification-${counter}`}
                            {...getInputProps()}
                            disabled={
                              inputs.colaborator === null || module === 'miNomina' ? true : false
                            }
                            type='file'
                            className='register-inputs text-secondary'
                            name='documents'
                            onChange={e =>
                              module !== 'miNomina' && onChangeDoc(e, 's3_data_verification')
                            }
                            multiple={false}
                          />
                          {!isEmptyOrUndefined(files[3].filename) ? (
                            <p
                              style={{ margin: '0' }}
                              className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsis} hoverPointer`}
                            >
                              {files[3].filename} &nbsp;
                              {!isEmptyOrUndefined(files[3].filename) &&
                                isEmptyOrUndefined([3].base64) && (
                                  <img
                                    alt=''
                                    width='20px'
                                    className='cursorPointer'
                                    onClick={() => {
                                      files[3].base64 = '';
                                      files[3].filename = '';
                                    }}
                                    src={x}
                                  ></img>
                                )}
                            </p>
                          ) : (
                            <p
                              className={`${IndividualStyles.crudModalLabelGris} hoverPointer
                          ${IndividualStyles.ellipsis}`}
                            >
                              {isEditing
                                ? employeeToEdit?.s3_data_verification?.split('/').pop()
                                : employeeInfo?.s3_data_verification?.split('/').pop()}
                            </p>
                          )}
                          {isEmptyOrUndefined(employeeInfo?.s3_data_verification) ? (
                            <img
                              className={Styles.filterImage}
                              src={Adjuntar}
                              title='añadir'
                              width='16px'
                              alt='cion'
                            />
                          ) : (
                            <img
                              className={Styles.filterImage}
                              alt='icon'
                              onClick={
                                isEmptyOrUndefined(employeeInfo?.s3_data_verification)
                                  ? null
                                  : () => {
                                      if (module !== 'miNomina') {
                                        window.location.href = employeeInfo?.s3_data_verification;
                                      }
                                    }
                              }
                              width='16px'
                              src={Adjuntar}
                              title='descargar'
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>Exámenes de ingreso</p>
                    <Dropzone>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className='d-flex justify-content-between'
                          style={{
                            border: '1px solid #7FADDE',
                            borderRadius: '5px',
                            height: '52%',
                          }}
                        >
                          <input
                            key={`history-${counter}`}
                            {...getInputProps()}
                            disabled={
                              inputs.colaborator === null || module === 'miNomina' ? true : false
                            }
                            type='file'
                            className='register-inputs text-secondary'
                            name='documents'
                            onChange={e =>
                              module !== 'miNomina' && onChangeDoc(e, 's3_clinic_history')
                            }
                            multiple={false}
                          />

                          {!isEmptyOrUndefined(files[4].filename) ? (
                            <p
                              style={{ margin: '0' }}
                              className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsis} hoverPointer`}
                            >
                              {files[4].filename} &nbsp;
                              {!isEmptyOrUndefined(files[4].filename) &&
                                isEmptyOrUndefined([4].base64) && (
                                  <img
                                    alt=''
                                    width='20px'
                                    className='cursorPointer'
                                    onClick={() => {
                                      files[4].base64 = '';
                                      files[4].filename = '';
                                    }}
                                    src={x}
                                  ></img>
                                )}
                            </p>
                          ) : (
                            <p
                              className={`${IndividualStyles.crudModalLabelGris} hoverPointer
                          ${IndividualStyles.ellipsis}`}
                            >
                              {isEditing
                                ? employeeToEdit?.s3_clinic_history?.split('/').pop()
                                : employeeInfo?.s3_clinic_history?.split('/').pop()}
                            </p>
                          )}
                          {isEmptyOrUndefined(employeeInfo?.s3_clinic_history) ? (
                            <img
                              className={Styles.filterImage}
                              src={Adjuntar}
                              title='añadir'
                              width='16px'
                              alt='icon'
                            />
                          ) : (
                            <img
                              className={Styles.filterImage}
                              onClick={
                                isEmptyOrUndefined(employeeInfo?.s3_clinic_history)
                                  ? null
                                  : () => {
                                      window.location.href = employeeInfo?.s3_clinic_history;
                                    }
                              }
                              alt='icon'
                              width='16px'
                              src={Adjuntar}
                              title='descargar'
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </Col>

                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Certificado de cuenta bancaria
                    </p>
                    <Dropzone>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className='d-flex justify-content-between'
                          style={{
                            border: '1px solid #7FADDE',
                            borderRadius: '5px',
                            height: '52%',
                          }}
                        >
                          <input
                            key={`bank-certify-${counter}`}
                            {...getInputProps()}
                            disabled={
                              inputs.colaborator === null || module === 'miNomina' ? true : false
                            }
                            type='file'
                            className='register-inputs text-secondary'
                            name='documents'
                            onChange={e =>
                              module !== 'miNomina' && onChangeDoc(e, 's3_bank_certificate_doc')
                            }
                            multiple={false}
                          />
                          {!isEmptyOrUndefined(files[2].filename) ? (
                            <p
                              style={{ margin: '0' }}
                              className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsis} hoverPointer`}
                            >
                              {files[2].filename} &nbsp;
                              {!isEmptyOrUndefined(files[2].filename) &&
                                isEmptyOrUndefined([2].base64) && (
                                  <img
                                    alt=''
                                    width='20px'
                                    className='cursorPointer'
                                    onClick={() => {
                                      files[2].base64 = '';
                                      files[2].filename = '';
                                    }}
                                    src={x}
                                  ></img>
                                )}
                            </p>
                          ) : (
                            <p
                              className={`${IndividualStyles.crudModalLabelGris} hoverPointer
                          ${IndividualStyles.ellipsis}`}
                            >
                              {isEditing
                                ? employeeToEdit?.s3_bank_certificate_doc?.split('/').pop()
                                : employeeInfo?.s3_bank_certificate_doc?.split('/').pop()}
                            </p>
                          )}
                          {isEmptyOrUndefined(employeeInfo?.s3_bank_certificate_doc) ? (
                            <img
                              className={Styles.filterImage}
                              src={Adjuntar}
                              title='añadir'
                              width='16px'
                              alt=''
                            />
                          ) : (
                            <img
                              alt='icon'
                              className={Styles.filterImage}
                              onClick={
                                isEmptyOrUndefined(employeeInfo?.s3_bank_certificate_doc)
                                  ? null
                                  : () => {
                                      window.location.href = employeeInfo?.s3_bank_certificate_doc;
                                    }
                              }
                              width='16px'
                              src={Adjuntar}
                              title='descargar'
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </Row>{' '}
                <Col xs={6} style={{ marginTop: '1.6%' }}>
                  <div
                    style={{ alignSelf: 'flex-end', borderRadius: '6px' }}
                    className={`d-flex p-1`}
                  >
                    <div>
                      <input
                        disabled={module === 'miNomina'}
                        onChange={e => setAssign(e.target.checked)}
                        className='mt-2 border border-dark-blue form-check-input p1 check-dark-blue'
                        type='checkbox'
                        checked={assign}
                        id='assi'
                      ></input>
                    </div>
                    <div>
                      <label
                        htmlFor='assi'
                        className={`${tableStyles.crudModalLabel} text-secondary`}
                      >
                        <b>Asignar dotación</b>
                      </label>
                    </div>
                  </div>
                </Col>
              </>
            )}

            {assign && (
              <>
                <p className={`${tableStyles.crudModalLabel} `}>Grupos dotacionales</p>
                <Col xs={8}>
                  <SelectComponent
                    isMulti
                    value={
                      currentEndowment === null
                        ? formatToRcSelect(
                            employeeInfo?.endowmentGroups,
                            'id',
                            'description',
                            '',
                            '',
                            '',
                            false,
                          )
                        : currentEndowment
                    }
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={e => handleEndowments(e)}
                    options={fotmattedEndowments}
                    className='text-secondary'
                    placeholder={'Seleccionar...'}
                    styles={customSelectMulti}
                  ></SelectComponent>
                </Col>
              </>
            )}
          </div>

          <hr
            className='mt-5'
            style={{
              border: 'none',
              borderLeft: '1px solid hsla(200, 10%, 50%, 100)',
              height: '100vh',
              width: '1px',
            }}
          ></hr>

          <Col xs={2}>
            <Card
              border=''
              className='w-100 mt-5'
              style={{ width: '20%', height: '10rem', border: '1px solid #7FADDE' }}
            >
              {image?.fileUrl ? (
                <>
                  <img
                    style={{ position: 'absolute' }}
                    className={`${tableStyles.x} mt-2 mr-2`}
                    src={x}
                    onClick={() =>
                      setimage({
                        file: '',
                        file64: '',
                        fileUrl: '',
                      })
                    }
                    alt='Eliminar'
                  />
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                    }}
                    src={image?.fileUrl}
                    alt='imagen usuario '
                  />
                </>
              ) : !isEmptyOrUndefined(employeeInfo?.photo) ? (
                <>
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                    }}
                    src={employeeInfo?.photo || ''}
                    alt='imagen usuario'
                  />
                </>
              ) : isEditing ? (
                <>
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                    }}
                    src={employeeToEdit?.photo || ''}
                    alt='imagen usuario'
                  />
                </>
              ) : (
                <>
                  <span className='mt-4'></span>
                  <p className={` text-center text-secondary pt-5`}>Insertar imagen</p>
                </>
              )}

              <label htmlFor='imageUpload' className={`${userStyles.imageInputLabel} `}>
                <img
                  className={`${userStyles.changepfp} ${userStyles.hoverPointer}  `}
                  src={iconCamera}
                  alt='cambiar imagen'
                />
              </label>
              <input
                disabled={module === 'miNomina'}
                accept='image/png, image/jpeg'
                id='imageUpload'
                name='imageUpload'
                type='file'
                onChange={handleImagePick}
                className={`${userStyles.imageInputBtn} mb-4`}
              />
            </Card>

            {!isEmptyOrUndefined(inputs.colaborator) && (
              <div style={{ marginTop: '1.2rem' }}>
                <h6 className={`${tableStyles.darkBlueText}`}>
                  <b>{employeeInfo?.full_name ? employeeInfo?.full_name : ''}</b>
                </h6>
                <h3 className={`${tableStyles.crudModalLabel} `}>{`${
                  employeeInfo?.document_type ? employeeInfo?.document_type : ''
                } ${employeeInfo?.doc_number ? employeeInfo?.doc_number : ''}`}</h3>
                <h3 className={`${tableStyles.crudModalLabel} `}>{`${
                  employeeInfo?.job_title ? employeeInfo?.job_title : ''
                }`}</h3>
                <h3 className={`${tableStyles.crudModalLabel} `}>{`${
                  employeeInfo?.age ? `${employeeInfo?.age} años` : ''
                }`}</h3>
              </div>
            )}
          </Col>
          {/* </div> */}
        </div>

        <div className={`customTabs__Footer mt-2`}>
          {!!myPermission?.create && (
            <Button
              onClick={() => handleSubmit()}
              className={tableStyles.btnPrimary}
              disabled={!isEmptyOrUndefined(inputs.colaborator) ? false : true}
            >
              Guardar
            </Button>
          )}

          <Button
            variant='outline-primary'
            onClick={() => handleBack()}
            className={tableStyles.btnSecondary}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
