import React, { useEffect, useState } from "react";
import { OrdCard } from "../OrderingComponents/OrdCard";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { OrdGenericTemplate } from "../OrderingComponents/OrdGenericTemplate";
import { OrdSlideDownButton } from "../OrderingComponents/OrdSlideDownButton";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import OrdLupa from "../../assets/img/icons/OrdLupa.svg";
import desplegarGris from "../../assets/img/icons/desplegarGris.svg";
import ordBlueSearch from "../../assets/img/icons/ordBlueSearch.svg";
import { Col, Form, Row } from "react-bootstrap";
import ordComponentStyles from "../OrderingComponents/ordComponentStyles.module.scss";
import Select from "react-select";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { loader, formatToRcSelect, swalConfirm, message, today } from "../../helpers/helpers";
import Tooltip from "react-bootstrap/Tooltip";
import { useGetMethod, useMultiGetMethod, usePostMethod } from "../../Hooks/useFetch";
import { useSelector } from "react-redux";
import OrdModal from "../OrderingComponents/OrdModal";
import moment from "moment";

export const AuthInhabilities = () => {
  const { results: unities, load: unitiesLoader, trigger: getUnities } = useMultiGetMethod();
  const { results: authDisabilityResults, load: authDisabilityLoader, trigger: getDisabilityResults } = useGetMethod();
  const { results: contractsResults, load: contractsLoader, trigger: getContracts } = useGetMethod();
  const { load: putAuthServiceLoader, trigger: putAuthService } = usePostMethod();
  const [rotate, setRotate] = useState(false);
  let buttons = [
    {
      button: <img alt="btn" src={OrdLupa}></img>,
    },
    {
      button: (
        <img
          alt="btn"
          style={{
            transform: !rotate && "rotate(180deg)",
          }}
          width={10}
          src={desplegarGris}
        ></img>
      ),
    },
  ];
  const store = useSelector((state) => state);
  const idUser = store.loginReducer.user_data.id;
  const token = store.loginReducer.Authorization;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const doctorOptions = formatToRcSelect(unities?.doctors?.results, "idDoctor", "nameDoctor", "", "", "");
  const contractsOptions = formatToRcSelect(contractsResults?.results, "id", "contractName", "", "", "");
  const companiesOptions = formatToRcSelect(unities?.company?.results, "idCorporateClient", "nameCorporateClient", "", "", "");
  const [justification, setJustification] = useState({
    showModal: false,
    justification: "",
  });
  const [filters, setFilters] = useState({
    idAccount: idEnterprise,
    auth: 1,
  });
  const [data, setData] = useState({
    id: "",
    prefix: "inhability",
    userId: idUser,
  });
  const [trigger, setTrigger] = useState(0);
  const [trigger2, setTrigger2] = useState(0);
  const filterStateBtns =
    unities?.status?.results?.length > 0 &&
    unities?.status?.results?.map((item, index) => {
      return {
        btnTitle: (
          <button
            style={{color: item?.color}}
            className={`btn p-2 ${tableStyles.ordAuthBtn} ${tableStyles.f14}`}
          >
            {item?.number} {item?.description}(s)
          </button>
        ),
        btnSize: 3,
        action: () => {
          setFilters({ ...filters, status: item?.id });
          setTrigger(trigger + 1);
        },
      };
    });


  const approvedButton = (item) => {
    setData({ ...data, action: "open", id: item?.id });
    swalConfirm({
      title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
      text: `Se aprobará el medicamento ${item?.name}, para paciente: ${item?.patient?.name}`,
      confirmButtonText: "Si, continuar",
      doAfterConfirm: () => {
        putAuthService({
          url: "/medical/authOrder/",
          token: token,
          method: "PUT",
          body: {
            id: item?.id,
            prefix: "inhability",
            userId: idUser,
            action: "approved",
          },
          doAfterSuccess: () => {
            setTrigger(trigger + 1);
          },
        });
      },
    });
  };

  const rejectedButton = (item) => {
    setJustification({ ...justification, showModal: true });
    setData({ ...data, action: "rejected", id: item?.id });
  };
  const renderTooltipRejected = () => (
    <Tooltip>
      <span>Rechazar</span>
    </Tooltip>
  );
  const renderTooltipApproved = () => (
    <Tooltip>
      <span>Aprobar</span>
    </Tooltip>
  );
  const titleCard = (item) => {
    let colorBg =
      item?.status?.staDescription === "Pendiente"
        ? tableStyles.ordOrangeBgButton
        : item?.status?.staDescription === "Aprobado"
        ? tableStyles.ordGreenBgButton
        : tableStyles.ordRedBgButton;
    return (
      <div className={`d-flex ${tableStyles.ordGrayBorderBottom} justify-content-between`}>

        <div className={`py-3 d-flex align-middle align-items-center `}>
        {/* <Col xs={10} className="d-flex"> */}
        {/* <Col > */}
        {/* <div className="d-flex"> */}
          <div className="d-flex p-2">
            <span className="pr-2">
              <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>Días incapacidad: &nbsp;</b>
            </span>
            <span className={`text-secondary fw-normal`}> {item?.medicalInhabilities?.days}</span>
          </div>
          {/* </Col>
        <Col > */}
          <div className="d-flex p-2">
            <span className="pr-2">
              <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>Incapacidad generadas: &nbsp;</b>
            </span>
            <span className={`text-secondary fw-normal`}> {item?.medicalInhabilities?.inhabilitiesGenerated}</span>
          </div>
          {/* </Col>
        <Col > */}
          <div className="d-flex p-2">
            <span className="pr-2">
              <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>Días acumulados de incapacidad: &nbsp;</b>
              <span className={`text-secondary fw-normal`}>{item?.medicalInhabilities?.accumulatedDays} </span>
            </span>
          </div>
          {/* </Col>
        <Col > */}
          <div className="d-flex p-2">
            <span className="pr-2">
              <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>Fecha de solicitud: &nbsp;</b>
            </span>
            <span className={`text-secondary fw-normal`}> {item.date}</span>
          </div>
          {/* </Col>
        <Col > */}
          <div className="p-2">
            <b className={`${tableStyles.f14} ${colorBg}`}> {item?.status?.staDescription} </b>{" "}
          </div>
          {/* </div> */}
          {/* </Col> */}
          {/* </Col> */}
          
      </div>

{/* <div className="d-flex justify-content-end"> */}
{item?.status?.staDescription === "Pendiente" && (
<div className={`d-flex justify-content-end align-items-center `} >
 {/* <Col className={`d-flex justify-content-end`} xs={2}> */}
 <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipRejected()}>
   <div
     className={tableStyles.denyCardRed}
     onClick={() => {
       rejectedButton(item);
     }}
   >
     &nbsp; &nbsp;
   </div>
 </OverlayTrigger>
 &nbsp; &nbsp;
 {/* <div className={tableStyles.checkCardGreen}> &nbsp; &nbsp;</div> */}
 <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipApproved()}>
   <div
     className={tableStyles.checkCardGreen}
     onClick={() => {
       approvedButton(item);
     }}
   >
     {" "}
     &nbsp; &nbsp;
   </div>
 </OverlayTrigger>
 {/* </Col> */}
</div>
)}
{/* </div> */}

{/* </div> */}
      </div>
      
    );
  };
  const bodyCard = (item) => {
    return (
      <>
        {/* #1 */}
        <Row className={`p-0`}>
          <Col xs={3} className="col-3 m-0 p-0">
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Médico</b>
          </Col>
          <Col xs={3}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Paciente</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Identificación</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Empresa</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Contrato</b>
          </Col>
        </Row>
        {/* #2 */}
        <Row className={`p-0`}>
          <Col xs={3} className="col-3 m-0 p-0">
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>{item?.doctor?.name} </span>
          </Col>
          <Col xs={3}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>{item?.patient?.name}</span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}> {item?.patient?.docTypeDesc}  {item?.patient?.document}</span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>{item?.corporateClient?.name}</span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>{item?.corporateClient?.contractName}</span>
          </Col>
        </Row>
        {/* #3 */}
        <Row className={`p-0`}>
          <Col xs={12} className="col-3 m-0 p-0">
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Diagnóstico(s)</b>
          </Col>
        </Row>
        {/* #4 */}
        <Row className={`p-0`}>
          <Col xs={12} className="col-3 m-0 p-0">
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>{item?.nameDiagnostics}</span>
          </Col>
        </Row>
        {/* #5 */}
        <Row className={`p-0`}>
          <Col xs={12} className="col-3 m-0 p-0">
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Justificación</b>
          </Col>
        </Row>
        {/* #6 */}
        <Row className={`p-0`}>
          <Col xs={12} className="col-3 m-0 p-0">
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>{item?.justification}</span>
          </Col>
        </Row>
      </>
    );
  };

  const handleSubmit = () => {
    putAuthService({
      url: "/medical/authOrder/",
      token: token,
      method: "PUT",
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
      },
    });
  };
  // --- GET DISABILITY HISTORY-----
  useEffect(() => {
    getDisabilityResults({
      url: "/medical/medicalinhabilities/",
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // --- MULTI GET FILTERS VALUE-----
  useEffect(() => {
    getUnities({
      multipleGet: [
        {
          url: "/medical/generals/doctors/",
          requestName: "doctors",
        },
        {
          url: "/medical/generals/company/",
          requestName: "company",
        },
        {
          url: "/medical/ordersReqAuth/",
          objFilters: { prefix: "inhabilities", idAccount: idEnterprise},
          requestName: "status",
        },
      ],
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <>
      {authDisabilityLoader && loader}
      {unitiesLoader && loader}
      {contractsLoader && loader}
      {putAuthServiceLoader && loader}
      <OrdModal
        title={"Justificación"}
        show={justification.showModal}
        btnYesName="Aceptar"
        hideCancelButton
        size="700"
        btnYesEvent={() => {
          if (data?.authComment) {
            if (data.authComment.length > 50) {
              swalConfirm({
                title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                text: `Recuerda que la presente justificación será enviada al paciente`,
                confirmButtonText: "Si, continuar",
                doAfterConfirm: () => {
                  handleSubmit();
                  setJustification({ ...justification, showModal: false });
                },
              });
              setData({ ...data, authComment: "" })
            } else {
              message("info", "", "La justificación debe tener mínimo 50 caracteres");
            }
          } else {
            message("info", "", "La justificación es obligatoria");
          }
        }}
        onHide={() => {
          setJustification({ ...justification, showModal: false });
        }}
      >
        <Col xs={12}>
          <Form.Group className="mb-3" controlId="fName">
            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
              <b className="text-start">&nbsp;Explique la razón por la que rechaza esta solicitud</b>
            </Form.Label>
            <textarea
              placeholder={"Escribir..."}
              onChange={(e) => setData({ ...data, authComment: e.target.value })}
              rows="25"
              cols="55"
              style={{ height: "7rem" }}
              className={`text-secondary ord-roundInput w-100`}
            ></textarea>
          </Form.Group>
        </Col>
      </OrdModal>
      <div className="w-90 mx-1 pt-3">
        <OrdGenericTemplate
          className="w-100"
          titleSize={6}
          colBtnSize={6}
          colbtnClass={"p-0 m-0"}
          title={"Autorizar incapacidades"}
          buttons={filterStateBtns}
        >
          <div className="w-100 mx-1">
            <OrdSlideDownButton
              onToggleClick={() => setRotate(!rotate)}
              buttons={buttons}
              accordionClassName={ordComponentStyles.OrdSlideButton}
            >
              <Row className={`px-2`}>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Fecha desde</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                      type="date"
                      placeholder=""
                      value={filters.startDate}
                      onChange={(e) => {
                        if (e.target.value > today()) {
                          return message(
                            "info",
                            "",
                            "El filtro 'fecha desde', debe ser menor a la fecha actual"
                          )
                        }else if (filters.endDate !== "" && e.target.value > filters.endDate) {
                          return message(
                            "info",
                            "",
                            "El filtro 'fecha desde', debe ser menor al filtro 'fecha hasta'"
                          )
                        }
                        if(filters.endDate){
                          setFilters({ ...filters, startDate: e.target.value });
                        }else{
                          setFilters({ ...filters, startDate: e.target.value, endDate: today()});
                        }
                        const diff = moment(e.target.value).diff( moment('1990-01-01'), 'years')
                        setFilters({ ...filters, startDate: e.target.value });
                        if(diff > 0){
                          setTimeout(() => {
                            setTrigger(trigger + 1);
                          }, 100);
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp; Fecha hasta</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                      type="date"
                      placeholder=""
                      value={filters.endDate}
                      onChange={(e) => {
                        setFilters({ ...filters, endDate: e.target.value });
                        const diff = moment(e.target.value).diff( moment('1990-01-01'), 'years')
                        setFilters({ ...filters, startDate: e.target.value });
                        if(diff > 0){
                          setTimeout(() => {
                            setTrigger(trigger + 1);
                          }, 100);
                        }
                      }}
                    />{" "}
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Médico</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={doctorOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => {
                        setFilters({ ...filters, doctor: e.value });
                        setTrigger(trigger + 1);
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Empresa</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={companiesOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => {
                        setFilters({ ...filters, company: e.value, contract: null });
                        setTrigger(trigger + 1);
                        setTrigger2(trigger2 + 1);
                        getContracts({
                          url: "/medical/medicine/getContract/",
                          objFilters: { slim: 1, corporateClient: e.value },
                          requestName: "contracts",
                          token: token,
                        });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Contrato</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={contractsOptions}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      key={"contract" + trigger2}
                      styles={ordCustomSelect}
                      onChange={(e) => {
                        setFilters({ ...filters, contract: e.value });
                        setTrigger(trigger + 1);
                      }}
                    ></Select>
                  </Form.Group>
                </Col>

                <Col xs={6}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
                    <div className={`d-flex ${tableStyles.ordGrayInputSearch}`}>
                      <Form.Control
                        style={{ backGroundColor: "red" }}
                        className={`ord-roundInput`}
                        type="text"
                        placeholder="Escribe aquí para buscar"
                        autoComplete="off"
                        onChange={(e) => {
                          setFilters({ ...filters, search: e.target.value });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setTrigger(trigger + 1);
                          }
                        }}
                      />
                      <img src={ordBlueSearch} className="p-2" alt="Buscar"></img>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </OrdSlideDownButton>
            {authDisabilityResults?.results?.length > 0 &&
              authDisabilityResults?.results?.map((item, index) => {
                return <OrdCard key={index} className="my-3" title={titleCard(item)} body={bodyCard(item)}></OrdCard>;
              })}
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
};
