import React from 'react'
import { Title, BasicTemplate as StyledTemplate } from './../../UI/atoms/';

export const BasicTemplate = ({ children, title, marginTitle }) => {
    return (
        <StyledTemplate   >
            <Title title={title} margin={marginTitle} />
            {children}
        </StyledTemplate>
    )
}
