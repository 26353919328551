import GenericTableNew from "../Layouts/GenericTableNew";
import Lupa from "../../assets/img/icons/lupa.svg";
// import LupaClear from "../../assets/img/icons/lupaClear.svg";
import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg";
import React, { useEffect, useState } from "react";

import { aprove_purchase_order_filter } from "../../actions/IndividualAuctionActions";

import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import AuthorizationsStyles from "./Authorizations.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import { Tooltip } from "@material-ui/core";
import DetailPurchaseOrder from "./DetailPurchaseOrder";
import Loader from "react-loader-spinner";
import ReactSelect from "react-select";
import { customSelectNewDark } from "../Layouts/react-select-custom";

function ApprovePurchaseOrder(props) {
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  const myPermission =
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "APO"
    );
  const [trigger, setTrigger] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    id: "",
    eaccount: counter.loginReducer.currentAccount.id,
    auction_type: "",
    auction_title: "",
    status: "",
    date_expiry: "",
  });
  const [details, setDetails] = useState({
    show: false,
  });

  useEffect(() => {
    const { history } = props;
    if (!myPermission?.read) {
      history?.push("/purchases/inicio");
    }
    setLoading(true);
    dispatch(aprove_purchase_order_filter(filters, ()=>setLoading(false)));
  }, [trigger, filters.page, filters.auction_type, filters.status]);

  const ballthingyTooltipName = (listArticles) => {
    let status = 0;
    if (Array.isArray(listArticles)) {
      listArticles.map((x) => {
        if (x.status_articles === "req_authorization") {
          status = status + 1;
          return status;
        }
      });
    }
    if (status > 0) {
      return "Requiere autorización";
    } else {
      return "Revisado";
    }
  };

  const typesAuction = (type) => {
    switch (type) {
      case "normal":
        return "Normal";
      case "priority":
        return "Prioritaria";
    }
  };
  const qtyAuction = (mylist) => {
    let valval = 0;
    if (Array.isArray(mylist)) {
      valval = mylist.reduce((total, currentValue) => {
        if (!isNaN(currentValue.qty_article)) {
          return total + currentValue.qty_article;
        } else {
          return total;
        }
      }, 0);
    }
    return valval;
  };
  const [infoAuction, setinfoAuction] = useState({
    showModal: false,
  });
  const DetailModal = (x) => {
    setinfoAuction({
      ...infoAuction,
      showModal: true,
      id: x,
    });
  };

  const renderHeaders = [
    <th key={`reqTH2`} className="px-4">ID</th>,
    <th key={`reqTH3`} className="text-center px-2">Artículos</th>,
    <th key={`reqTH4`}>Tipo de subasta</th>,
    <th key={`reqTH5`} className="px-2">Título de cotización</th>,
    <th key={`reqTH6`} className="text-center px-4">Inicio</th>,
    <th key={`reqTH7`}className="text-center px-4">Vence</th>,
    <th key={`reqTH8`} className="text-center px-4">Estado</th>,
    <th key={`reqTH99`} className="px-4"></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(counter.individualAuctionReducer.apo)) {
      table = counter.individualAuctionReducer.apo.map((x) => {
        let justincase = x.date_expiry ? "" + x.date_expiry : "-";
        return (
          <tr key={"renderList" + x.id} className="hover-table-row">
            <td className="text-uppercase px-4 text-start">{x.auction_number}</td>
            <td className="text-center">{x.articles}</td>
            <td className="text-start">{typesAuction(x.priority)}</td>
            <td className="px-2">
              {" "}
              <p className={`${AuthorizationsStyles.textLeft}`}>
                {" "}
                {x.description}{" "}
              </p>
            </td>
            <td className="text-center">
              {x.created_at?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")}
            </td>
            <td className="text-center">
              {justincase.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")}
            </td>
            <td className="text-center">
              <Tooltip
                title={
                  x.status_articles.includes("req_authorization")
                    ? "Requiere Autorización"
                    : "Atendida"
                }
                arrow
              >
                <div
                  className={`
                    ${AuthorizationsStyles.popupReqActive} 
                    ${
                      x.status_articles.includes("req_authorization")
                        ? tableStyles.alertTriangle
                        : tableStyles.greenCheck
                    }
                  `}
                ></div>
              </Tooltip>
            </td>

            <td>
              {/* {!!myPermission?.read? */}
              <img
                src={lupaTransparent}
                alt="detalles"
                className={`icons-popUp hoverPointer ${AuthorizationsStyles.adjustIcon3}`}
                onClick={() => setDetails({ show: true, selectedAuction: x })}
              />
              {/* :
                    <span></span>
                    } */}
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const principalRender = (
    <div className={tableStyles.container} style={{ padding: "0 6rem" }}>
      {loading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div className={AuthorizationsStyles.container1}>
        <div className={AuthorizationsStyles.ItemSmall}>
          <h1 className={tableStyles.title}>Autorizar orden de compra</h1>
        </div>
      </div>
      <div className={AuthorizationsStyles.container2}>
        {/* ---------------------------- ROW 1 ---------------------------------- */}
        <div className="d-flex">
          {/* <div
            className={`${AuthorizationsStyles.inputMarginNew} ${AuthorizationsStyles.Item1}`}
          >
            <p className={AuthorizationsStyles.crudModalLabel}>ID</p>
            <input
              className={`${AuthorizationsStyles.registerInputs}`}
              name="ID"
              type="text"
              value={filters.id}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  id: e.target.value,
                })
              }
              disabled={false}
            />
          </div> */}

          <div
            className={`${AuthorizationsStyles.inputMarginNew} ${AuthorizationsStyles.Item2}`}
          >
            <p className={AuthorizationsStyles.crudModalLabel}>
              Tipo de Subasta
            </p>
            <ReactSelect noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar..."
              name="auction_type"
              styles={customSelectNewDark}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  auction_type: e.value,
                })
              }
              value={[
                { label: "Seleccionar...", value: "" },
                { label: "Normal", value: "normal" },
                { label: "Prioritaria", value: "priority" },
              ].find((x) => x.value === filters.auction_type)}

              options={[
                { label: "Seleccionar...", value: "" },
                { label: "Normal", value: "normal" },
                { label: "Prioritaria", value: "priority" },
              ]}
            />
          </div>
          <div
            className={` ${AuthorizationsStyles.inputMarginNew} ${AuthorizationsStyles.Item2}`}
          >
            <p className={AuthorizationsStyles.crudModalLabel}>Estado</p>
            {/* <select
              name="status"
              className={`${AuthorizationsStyles.selectRegister}`}
              style={{ cursor: "pointer" }}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  status: e.target.value,
                })
              }
              value={filters.status}
            >
              <option value=""></option>
              <option value="req_authorization">Requiere Autorización</option>
              <option value="solved,reject">Atendido</option>
            </select> */}
            <ReactSelect noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar..."
              name="status"
              styles={customSelectNewDark}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  status: e.value,
                })
              }
              value={[
                { label: "Seleccionar...", value: "" },
                { label: "Requiere Autorización", value: "req_authorization" },
                { label: "Atendido", value: "solved,reject" },
              ].find((x) => x.value === filters.status)}

              options={[
                { label: "Seleccionar...", value: "" },
                { label: "Requiere Autorización", value: "req_authorization" },
                { label: "Atendido", value: "solved,reject" },
              ]}
            />
          </div>
          <div
            className={`${AuthorizationsStyles.inputMarginNew} ${AuthorizationsStyles.Item2}`}
          >
            <p className={AuthorizationsStyles.crudModalLabel}>
              Fecha de vencimiento
            </p>
            <div className={tableStyles.deleteicon}>
              {filters.date_expiry ? (
                <span
                  onClick={(e) => setFilters({ ...filters, date_expiry: "" })}
                >
                  x
                </span>
              ) : (
                ""
              )}
              <input
                className={`register-inputs`}
                style={{ cursor: "pointer" }}
                name="fromDate"
                type="date"
                value={filters.date_expiry}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    date_expiry: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div
            className={`${AuthorizationsStyles.inputMarginNew} ${AuthorizationsStyles.Item3}`}
          >
            <p className={AuthorizationsStyles.crudModalLabel}>
              Título de cotización
            </p>
            <input
              className={`${tableStyles.SearchNew}`}
              type="text"
              placeholder="Buscar..."
              value={filters.auction_title}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  auction_title: e.target.value,
                })
              }
              onKeyDown={e=>{
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1)
                }   
              }}
            />
          </div>
          <div className={`${AuthorizationsStyles.Aling}`}>
            <img
              src={Lupa}
              alt="filtrar"
              className={`${AuthorizationsStyles.imagePaddingNew} ${AuthorizationsStyles.sizeNew} ${AuthorizationsStyles.pointer}`}
              onClick={() => setTrigger(trigger + 1)}
            />
          </div>
        </div>
      </div>

      <div className={AuthorizationsStyles.container3}>
        <GenericTableNew dark={true} headers={renderHeaders}>
          {renderList()}
        </GenericTableNew>
      </div>

      {/* <div className={AuthorizationsStyles.bottom}> */}
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag.{" "}
            {counter.individualAuctionReducer.apo_total ? filters.page : ""}
            {" de "}
            {Math.ceil(
              counter.individualAuctionReducer.apo_total / filters.perpage
            )
              ? Math.ceil(
                  counter.individualAuctionReducer.apo_total / filters.perpage
                )
              : ""}{" "}
            ({counter.individualAuctionReducer.apo_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={counter.individualAuctionReducer.apo_total}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      {/* </div> */}
    </div>
  );

  return !!details.show ? (
    <DetailPurchaseOrder
      //details={details}
      selectedAuction={details.selectedAuction}
      show={details.show}
      setDetails={setDetails}
    />
  ) : (
    principalRender
  );
}

export default ApprovePurchaseOrder;
