// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".partners_filterImage__34a4a {\n  filter: brightness(0) saturate(100%) invert(23%) sepia(82%) saturate(2001%) hue-rotate(197deg);\n  cursor: pointer;\n}\n\n.partners_textareaStyle__1t6Ok {\n  border: 1px solid #7FADDE;\n  border-radius: 5px;\n  width: 100%;\n  padding: 0 0.5rem;\n  font-size: 12px;\n  outline: none;\n  min-height: 3.5rem;\n  color: #6c757d;\n}", "",{"version":3,"sources":["webpack://src/components/PayRollModule/partners/partners.module.scss"],"names":[],"mappings":"AACA;EACI,8FAAA;EACA,eAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;EACA,cAAA;AAAJ","sourcesContent":["\n.filterImage{\n    filter: brightness(0) saturate(100%) invert(23%) sepia(82%) saturate(2001%) hue-rotate(197deg);\n    cursor: pointer;\n}\n\n.textareaStyle {\n    border: 1px solid #7FADDE;\n    border-radius: 5px;\n    width: 100%;\n    padding: 0 0.5rem;\n    font-size: 12px;\n    outline: none;\n    min-height: 3.5rem;\n    color: #6c757d;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterImage": "partners_filterImage__34a4a",
	"textareaStyle": "partners_textareaStyle__1t6Ok"
};
export default ___CSS_LOADER_EXPORT___;
