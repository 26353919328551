import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Pagination from "react-js-pagination";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import iconProvisions from "../../assets/img/icons/cloneIcon.svg";
import Lupa from "../../assets/img/icons/lupa.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import CustomPopupStyles from "../../components/Popup/customPopup.module.scss";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import { formatToRcSelect, loader, message } from "../../helpers/helpers";
import { useHasPermissionByTagModule } from "../../Hooks";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import GenericTable from "../Layouts/GenericTableNew";
import ModalNew from "../Layouts/ModalNew";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import SelectComponent from "../SelectComponent/SelectComponent";
import Styles from "./Consultations.module.scss";

const monthOptions = [
    { label: "Seleccionar...", value: "" },
    { label: "Enero", value: 1 },
    { label: "Febrero", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Mayo", value: 5 },
    { label: "Junio", value: 6 },
    { label: "Julio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Septiembre", value: 9 },
    { label: "Octubre", value: 10 },
    { label: "Noviembre", value: 11 },
    { label: "Diciembre", value: 12 },
];

function AccountingReceiptProvision() {
    const history = useHistory();
    const counter = useSelector((state) => state);
    const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "plandenom");
    useHasPermissionByTagModule({ module: "nomina", hasRead: myPermission?.read });
    const token = counter.loginReducer.Authorization;
    const eaccount = counter.loginReducer.currentAccount.id;
    const [trigger, setTrigger] = useState(1);
    const [filters, setFilter] = useState({
        page: 1,
        perpage: 10,
        month: undefined,
        year: "",
        eaccount,
        status: "",
    });

    const { load: provisionListLoader, results: provisionList, trigger: provisionGetMethod } = useGetMethod();
    const { load: statusListLoader, results: statusList, trigger: statusGetMethod } = useGetMethod();

    const { load: generateProvisionLoader, trigger: generateProvisionMethod } = usePostMethod();

  const { load: deleteProvisionLoader, trigger: deleteProvisionMethod } = usePostMethod();

    const statusOptions = formatToRcSelect(statusList?.results, "name", "value", "", "", "");
    useEffect(() => {
        provisionGetMethod({
            url: "/payroll/provision-voucher/",
            objFilters: filters,
            token: token,
        });

        statusGetMethod({
            url: "/payroll/journal-status/",
            token: token,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eaccount, filters.page, trigger]);

    const [showModal, setShowModal] = useState({
        show: false,
        from: "",
    });
    const [generate, setGenerate] = useState({
        eaccount,
        month: "",
        year: "",
        created_by: counter.loginReducer.user_data.id,
    });

    const [showValidation, setShowValidation] = useState(false);
    const { load: validationLoader, results: validationResults, trigger: validationMethod } = useGetMethod();

    const headersValidation = [
        <th
            key={1}
            className="pl-1 col-6 text-start"
            style={{ paddingLeft: "10px" }}
        >
            Concepto
        </th>,
        <th
            key={2}
            className="pl-1 col-6 text-start"
        >
            Centro de consumo
        </th>,
    ];

    const handleValidation = () => {
        validationMethod({
            url: "/payroll/review-params/",
            objFilters: {
                eaccount,
                month: generate.month,
                year: generate.year,
                type: "payroll_provision",
            },
            token: token,
            doAfterSuccess: () => {
                closeModal();
                setShowValidation(true);
            },
        });
    };

    const generateProvisions = (isFromValidation) => {
        if (isFromValidation) {
            handleValidation();
        } else {
            generateProvisionMethod({
                token,
                body: generate,
                method: "POST",
                url: "/payroll/create-provision-voucher/",
                succesAction: (results) => {
                    customSwaltAlert({
                        showCancelButton: false,
                        icon: "success",
                        title: "Creado exitosamente",
                        text: results.message,
                        confirmButtonText: "Aceptar",
                    }).then(async (response) => {
                        if (response.isConfirmed) {
                            setTrigger(trigger + 1);
                            closeModal();
                        }
                    });
                },
                doAfterException: (results) => {
                    customSwaltAlert({
                        showCancelButton: false,
                        icon: "warning",
                        title: "Intenta de nuevo",
                        text: results?.message,
                        confirmButtonText: "Aceptar",
                    });
                },
            });
        }
    };

  const onDeleteProvisions = (item) => {
    deleteProvisionMethod({
      token,
      body: {
        id: item.jrnlId,
        eaccount: eaccount,
        status: item.status
      },
      method: 'DELETE',
      url: '/accounting/journalVoucher/',
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: results.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            setTrigger(trigger + 1);
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  }

    const handlePageChange = (val) => {
        setFilter({
            ...filters,
            page: val,
        });
    };

    const closeModal = () => {
        setShowModal({ show: false });
        setGenerate((state) => ({
            ...state,
            eaccount,
            month: "",
            year: "",
        }));
    };

    const header = [
        <th
            key={1}
            className="pl-1 col-2 text-start"
            style={{ paddingLeft: "10px" }}
        >
            No. comprobante
        </th>,
        <th
            key={2}
            className="pl-1 col-2 text-start"
        >
            Concepto
        </th>,
        <th
            key={3}
            className="pl-1 col-2 text-center"
        >
            Fecha de creación
        </th>,
        <th
            key={3}
            className="pl-1 col-2 text-center"
        >
            Estado
        </th>,

        <th
            key={8}
            className="col-1 text-center"
            style={{ paddingLeft: "10px" }}
        >
            &nbsp;
        </th>,
    ];

    const body = () => {
        return provisionList?.results?.length > 0 ? (
            provisionList?.results.map((item, ind) => {
                return (
                    <tr key={"payroll" + ind}>
                        <td
                            className="text-start col-2"
                            style={{ paddingLeft: "10px" }}
                        >
                            {item.docNbr ?? "-"}
                        </td>
                        <td className="text-start col-2">{item.jvDetail ?? "-"}</td>
                        <td className="text-center col-2">{item.createdAt.split("T")[0]}</td>
                        <td className="text-center col-1">
                            <div
                                className="rounded-pill p-1"
                                style={{
                                    backgroundColor: item.statusBackground,
                                }}
                            >
                                <b style={{ color: item.statusFontcolor }}>{item.statusLabel}</b>
                            </div>
                        </td>

            <td className='col-1'>
              {item.status !== 'not_generated' && (
                <CustomPopupExtend
                  showDetails={item.status === 'generated'}
                  noHover
                  triggerSrc={threeDots}
                  showDetailsEvent={() => {
                    history.push({
                      pathname: '/contabilidad/Consultas/detalleComprobanteContable',
                      state: { journal: item.jrnlId, from: 'provision' },
                    });
                  }}
                  textDetails='Ver Detalle'
                  showDelete={item.status === 'generated' || item.status === 'error'}
                  deleteEvent={() => onDeleteProvisions(item)}
                  extraButtons={[
                    {
                      text: 'Ver fallo',
                      icon: iconProvisions,
                      class: CustomPopupStyles.popUpGeneric,
                      event:
                        item.status === 'error'
                          ? () => {
                              message(
                                'warning',
                                'Comprobante fallido',
                                item?.errorLogs,
                                undefined,
                                true,
                              );
                            }
                          : null,
                    },
                  ]}
                />
              )}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan='8' className='text-center'>
          <p style={{ marginTop: '10px' }}>No hay información registrada</p>
        </td>
      </tr>
    );
  };

    const renderPrincipal = (
        <div
            className={` ml-5 ${tableStyles.container}`}
            style={{ marginRight: "3rem" }}
        >
            {(provisionListLoader || statusListLoader || generateProvisionLoader || validationLoader || deleteProvisionLoader) && loader}

            <ModalNew
                show={showValidation}
                hideCancelButton
                title={"Verificación de parametrización"}
                btnYesEvent={() => setShowValidation(false)}
                btnYesName={"Aceptar"}
                size={"750"}
            >
                <div className="d-flex justify-content-center">
                    <b className="text-muted">Conceptos no parametrizados</b>
                </div>
                <div
                    style={{
                        overflowY: "auto",
                        maxHeight: "400px",
                        marginTop: 5,
                        zIndex: 0,
                        position: "relative",
                    }}
                >
                    <GenericTable
                        headers={headersValidation}
                        dark={true}
                        styleHead={{
                            position: "sticky",
                            top: 0,
                            zIndex: 10,
                        }}
                    >
                        {validationResults?.results?.length > 0 ? (
                            validationResults?.results.map((item, ind) => {
                                return (
                                    <tr key={"payroll" + ind}>
                                        <td
                                            className="text-start col-6"
                                            style={{ paddingLeft: "10px" }}
                                        >
                                            {item.conceptName ?? "-"}
                                        </td>
                                        <td className="text-start col-6">{item.consumCenterName ?? "-"}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td
                                    colSpan="8"
                                    className="text-center"
                                >
                                    <p style={{ marginTop: "10px" }}>No hay información disponible</p>
                                </td>
                            </tr>
                        )}
                    </GenericTable>
                </div>
            </ModalNew>
            <ModalNew
                show={showModal.show}
                onHide={() => {
                    closeModal();
                }}
                title={showModal.from === "validation" ? "Verificación de parametrización" : "Generar comprobante de nómina"}
                btnYesEvent={() => {
                    generateProvisions(Boolean(showModal.from === "validation"));
                }}
                btnYesName={showModal.from === "validation" ? "Verificar" : "Generar"}
                btnYesDisabled={!!myPermission?.create && (!!(generate.year === "") || !!(generate.month === ""))}
                btnNoEvent={() => {
                    closeModal();
                }}
                btnNoName={"Cancelar"}
                size={"550"}
            >
                <Row className="d-flex mb-2">
                    <Col
                        xs={6}
                        className=" align-self-end"
                    >
                        <p className={tableStyles.crudModalLabel}>Mes</p>
                        <SelectComponent
                            value={monthOptions.filter((x) => x.value === generate.month)}
                            styles={customSelectNewDark}
                            placeholder={"Selecciodaar..."}
                            key={"state" + trigger}
                            onChange={(e) => {
                                setGenerate({
                                    ...generate,
                                    month: e.value,
                                });
                            }}
                            options={monthOptions}
                        ></SelectComponent>{" "}
                    </Col>
                    <Col
                        xs={6}
                        className=""
                    >
                        <div className="">
                            <p className={tableStyles.crudModalLabel}>Año</p>
                            <input
                                className="register-inputs"
                                type="number"
                                placeholder="Escribir..."
                                value={generate.year}
                                onChange={(e) =>
                                    setGenerate({
                                        ...generate,
                                        year: e.target.value,
                                    })
                                }
                            ></input>
                        </div>
                    </Col>
                </Row>
            </ModalNew>

            <div className="d-flex">
                <h1 className={tableStyles.title}>Comprobante contable provisión</h1>
            </div>

            {!!myPermission?.read && (
                <>
                    <Row className="align-items-end">
                        <Col
                            xs={2}
                            className=""
                        >
                            <div className="">
                                <p className={tableStyles.crudModalLabel}>Fecha desde</p>
                                <input
                                    className="register-inputs"
                                    type="date"
                                    placeholder="Escribir..."
                                    value={filters.dateFrom}
                                    onChange={(e) =>
                                        setFilter({
                                            ...filters,
                                            dateFrom: e.target.value,
                                            page: 1,
                                        })
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            setTrigger(trigger + 1);
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                        <Col
                            xs={2}
                            className=""
                        >
                            <div className="">
                                <p className={tableStyles.crudModalLabel}>Fecha hasta</p>
                                <input
                                    className="register-inputs"
                                    type="date"
                                    placeholder="Escribir..."
                                    value={filters.dateUntil}
                                    onChange={(e) =>
                                        setFilter({
                                            ...filters,
                                            dateUntil: e.target.value,
                                            page: 1,
                                        })
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            setTrigger(trigger + 1);
                                        }
                                    }}
                                />
                            </div>
                        </Col>

                        <Col xs={2}>
                            <p className={tableStyles.crudModalLabel}>Estado</p>
                            <SelectComponent
                                value={statusOptions.filter((x) => x.value === filters.status)}
                                styles={customSelectNewDark}
                                placeholder={"Seleccionar..."}
                                key={"state" + trigger}
                                onChange={(e) => {
                                    setFilter({
                                        ...filters,
                                        status: e.value,
                                        page: 1,
                                    });
                                    setTrigger(trigger + 1);
                                }}
                                options={statusOptions}
                            ></SelectComponent>{" "}
                        </Col>

                        <Col
                            xs={6}
                            className="d-flex justify-content-between align-items-end"
                        >
                            <div>
                                <img
                                    className="hoverPointer align-self-end"
                                    alt="iconSearch"
                                    style={{}}
                                    src={Lupa}
                                    height="24px"
                                    onClick={() => setTrigger(trigger + 1)}
                                />
                            </div>
                            <div className="d-flex gap-2">
                                <div>
                                    <button
                                        onClick={() => setShowModal({ show: true, from: "validation" })}
                                        className={tableStyles.btnPrimaryAlt}
                                    >
                                        Verificar parametrización
                                    </button>
                                </div>
                                {!!myPermission?.create && (
                                    <div className="d-flex justify-content-end gap-2">
                                        <span
                                            className="darkGray fw-bold align-self-center"
                                            style={{ color: "#58595B", fontSize: "13px" }}
                                        >
                                            Generar comprobante{" "}
                                        </span>
                                        <div
                                            className={Styles.btnpreviw}
                                            style={{ width: "31px", height: "31px" }}
                                            onClick={() => setShowModal({ show: true })}
                                        ></div>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <div></div>
                    <GenericTable
                        headers={header}
                        dark={true}
                    >
                        {body()}
                    </GenericTable>
                </>
            )}

            <div className={paginationStyles.wrapper}>
                <p className={` ${paginationStyles.paginationText} darkGray`}>
                    pag. {filters.page}
                    {" de "}
                    {Math.ceil(provisionList.rowTotal / filters.perpage) ? Math.ceil(provisionList.rowTotal / filters.perpage) : "1"} (
                    {" " + provisionList.rowTotal}
                    {" encontrados"})
                </p>
                <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.perpage}
                    totalItemsCount={provisionList.rowTotal}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClassPrev={`${paginationStyles.itemClassPrev} `}
                    itemClassNext={`${paginationStyles.itemClassNext} `}
                    itemClassFirst={`${paginationStyles.itemClassFirst} `}
                    itemClassLast={`${paginationStyles.itemClassLast} `}
                    itemClass={paginationStyles.itemClass}
                />
            </div>
        </div>
    );

    return renderPrincipal;
}
export default AccountingReceiptProvision;
