import React, { useEffect, useState } from "react";

import send from "../../../assets/img/icons/send.svg";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, Col, Nav, Row, Table } from "react-bootstrap";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  convertBase64,
  convertDateToLatinFormat,
  isEmptyOrUndefined,
  message,
  today,
} from "../../../helpers/helpers";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  addAnnotation,
  getAnnotations,
} from "../../../actions/partnersActions";
import Loader from "react-loader-spinner";

export const DevAndMotivation = ({ idCandidate }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  let employeeInfo = store.partnersReducer.employee_info;
  let id_candidate = location.state.id_candidate;
  let id_contract = location.state.id_contract;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [isLoading, setIsLoading] = useState(false);
  const idAccount = store.loginReducer.user_data.id;
  const fullName =
    store.loginReducer.user_data.first_name +
    " " +
    store.loginReducer.user_data.first_surname;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [inputs, setInputs] = useState({
    noteDate: "",
    results: "",
    title: "",
  });
  const [options, setOptions] = useState({
    performanceCheck: false,
    devPlan: false,
    incentive: false,
    annotations: true,
    sanctions: false,
  });
  const [allowSend, setallowSend] = useState(false);
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    if (
      !isEmptyOrUndefined(inputs.noteDate) &&
      !isEmptyOrUndefined(inputs.results) &&
      !isEmptyOrUndefined(inputs.title)
    ) {
      setallowSend(true);
    } else {
      setallowSend(false);
    }
  }, [inputs]);

  useEffect(() => {
    dispatch(getAnnotations({ annotations: 1, id_contract, id_candidate }));
  }, []);
  const handleSubmit = async () => {
    setIsLoading(true);
    if (inputs.noteDate > today()) {
      return message(
        "warning",
        "Fecha no valida",
        "La fecha no puede ser superior a " + convertDateToLatinFormat(today()),  undefined,
        true
      );
    }
    try {
      const result = await addAnnotation(
        {
          employee: idCandidate,
          title: inputs.title,
          ann_date: inputs.noteDate,
          description: inputs.results,
          created_by: idAccount,
          created_by_name: fullName,
          entity_account: idEnterprise,
        },
        token
      );

      if (result.success) {
        setTrigger(trigger + 1);
        dispatch(getAnnotations({ annotations: 1, id_contract, id_candidate }));
        setInputs({
          noteDate: "",
          results: "",
          title: "",
        });
        setIsLoading(false);
        return message("success", "Éxito", " Anotación guardada exitosamente",  undefined,
        true
);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      return message(
        "error",
        "Error",
        "Ha ocurrido un arror al intentar enviar",  undefined,
        true
      );
    }
  };
  /* ----------------------------- HANDLE CHANGES ----------------------------- */
  // const onChangeDoc = async (e) => {
  //   // setCounter(counter + 1);
  //   const file = e.target.files[0];
  //   const validExtensions = ["zip", "pdf", "rar"];
  //   if (!isEmptyOrUndefined(file)) {
  //     let actFileExt = file.name?.split(".");
  //     let extension = validExtensions.find(
  //       (obj) => obj === actFileExt[actFileExt.length - 1]
  //     );
  //     if (isEmptyOrUndefined(extension)) {
  //       // setSelectedFile(null);
  //       e.target.value = "";
  //       return message(
  //         "warning",
  //         "Advertencia!",
  //         `No se permiten documentos con extensión .${actFileExt[
  //           actFileExt.length - 1
  //         ].toUpperCase()}`
  //       );
  //     }

  //     if (file.size > 50000000) {
  //       // setSelectedFile(null);
  //       e.target.value = "";
  //       return message(
  //         "warning",
  //         "Advertencia",
  //         "Su archivo no puede tener un tamaño superior a 5MB"
  //       );
  //     } else {
  //       if (file.size <= 0) {
  //         // setSelectedFile(null);
  //         e.target.value = "";
  //         return message(
  //           "warning",
  //           "Advertencia",
  //           "Por favor, adjunte un archivo valido."
  //         );
  //       }
  //     }
  //     // if (!isEmptyOrUndefined(file)) {
  //     //   setSelectedFile(file);
  //     // }
  //   } else {
  //     return console.error("Archivo vacio: ", file);
  //   }
  //   /* ------------------------------------ x ----------------------------------- */
  //   let f = e.target.files[0];
  //   let name_doc = f.name.split(".")[0];
  //   let type_doc = f.type;
  //   let encode = await convertBase64(e.target.files[0]);
  //   let file64 = encode.split(",")[1];
  //   let ext = type_doc.split("/");
  //   let finalName = `${name_doc}.${ext[1]}`;
  //   setInputs({
  //     ...inputs,
  //     base64: file64,
  //     fileName: finalName,
  //     ext: ext[1],
  //   });
  // };
  return (
    <div>
      {isLoading ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      <Row
        style={{
          borderBottom: "1px solid #dce1e3",
          marginBottom: "10%",
        }}
      >
        {/* /* ------------------------- UPLOAD NEW RESULTS ------------------------- */}
        <Col className="mt-4" xs={2}>
          <Row>
            <button
              type="button"
              className={`mb-4 p-2 rounded-pill ${tableStyles.f95pt}
              ${
                options.performanceCheck
                  ? tableStyles.currentBtnStyle
                  : tableStyles.blueBtnHover
              }`}
              onClick={() =>
                setOptions({
                  performanceCheck: true,
                  devPlan: false,
                  incentive: false,
                  annotations: false,
                  sanctions: false,
                })
              }
            >
              <b>Evaluación de desempeño</b>
            </button>

            <button
              type="button"
              className={`mb-4 p-2 rounded-pill 
              ${
                options.devPlan
                  ? tableStyles.currentBtnStyle
                  : tableStyles.blueBtnHover
              }`}
              onClick={() =>
                setOptions({
                  performanceCheck: false,
                  devPlan: true,
                  incentive: false,
                  annotations: false,
                  sanctions: false,
                })
              }
            >
              <b>Plan de desarrollo</b>
            </button>

            <button
              type="button"
              className={`mb-4 p-2 rounded-pill 
              ${
                options.incentive
                  ? tableStyles.currentBtnStyle
                  : tableStyles.blueBtnHover
              }`}
              onClick={() =>
                setOptions({
                  performanceCheck: false,
                  devPlan: false,
                  incentive: true,
                  annotations: false,
                  sanctions: false,
                })
              }
            >
              <b>Incentivos</b>
            </button>

            <button
              type="button"
              className={`mb-4 p-2 rounded-pill 

               ${
                 options.annotations
                   ? tableStyles.currentBtnStyle
                   : tableStyles.blueBtnHover
               }`}
              onClick={() =>
                setOptions({
                  performanceCheck: false,
                  devPlan: false,
                  incentive: false,
                  annotations: true,
                  sanctions: false,
                })
              }
            >
              <b> Anotaciones</b>
            </button>

            <button
              type="button"
              className={`mb-4 p-2 rounded-pill 
              ${
                options.sanctions
                  ? tableStyles.currentBtnStyle
                  : tableStyles.blueBtnHover
              }`}
              onClick={() =>
                setOptions({
                  performanceCheck: false,
                  devPlan: false,
                  incentive: false,
                  annotations: false,
                  sanctions: true,
                })
              }
            >
              <b> Sanciones</b>
            </button>
          </Row>
        </Col>

        <Col className={`mt-4 rounded m-3`} xs={5}>
          <h4 className={tableStyles.darkBlueText}>
            <b>Anotaciones</b>
          </h4>
          <Row>
            <Col xs={7}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Autor de la nota
                <span className={"text-warning"}>*</span>
              </p>
              <input
                value={fullName}
                disabled={true}
                className="register-inputs text-secondary"
              />
            </Col>

            <Col xs={5}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Fecha de la nota
                <span className={"text-warning"}>*</span>
              </p>
              <input
                key={"noteDate" + trigger}
                value={inputs.noteDate}
                onChange={(e) => {
                  setInputs({ ...inputs, noteDate: e.target.value });
                }}
                type="date"
                max={today()}
                className="register-inputs text-secondary"
              />
            </Col>
          </Row>
          <p className={`${tableStyles.crudModalLabel} `}>
            Título
            <span className={"text-warning"}>*</span>
          </p>
          <input
            key={"title" + trigger}
            value={inputs.title}
            onChange={(e) => {
              setInputs({ ...inputs, title: e.target.value });
            }}
            className="register-inputs text-secondary mb-4"
            placeHolder="Escribir..."
          />

          <CKEditor
            key={"ckEditor" + trigger}
            // style={{
            //   border: "1px red solid",
            // }}
            editor={ClassicEditor}
            value={inputs.results}
            className="register-inputs text-secondary"
            // data={
            //   reducers.payrollReducer.accidentDetails?.result
            //     ? reducers.payrollReducer.accidentDetails?.result
            //     : state.registerResults?.result
            // }
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setInputs({ ...inputs, results: data });
            }}
          />

          <div className={`mt-2 p-0 d-flex justify-content-start`}>
            <Button
              className={tableStyles.btnPrimary}
              onClick={() => handleSubmit()}
              disabled={allowSend ? false : true}
            >
              <img className="cursorPointer" src={send} />
              &nbsp; Enviar
            </Button>
          </div>
        </Col>
        {/* /* -- DON'T ERASE THIS, YOU WILL NEED IT, THIS IS THE BACKGROUND BLUE FORM -- */}
        {/* 
        <Col className={`${tableStyles.bgClearBlue} mt-4 rounded m-3`} xs={5}>
          <h4 className={tableStyles.darkBlueText}>
            <b>Subir nuevos resultados</b>
          </h4>
          <p className={`${tableStyles.crudModalLabel} `}>
            Fecha
            <span className={"text-warning"}>*</span>
          </p>
          <input type="date" className="register-inputs text-secondary" />
          <p className={`${tableStyles.crudModalLabel} `}>
            Título de la evaluación
            <span className={"text-warning"}>*</span>
          </p>
          <input className="register-inputs text-secondary" />
          <p className={`${tableStyles.crudModalLabel} `}>
            Porcentaje de éxito
            <span className={"text-warning"}>*</span>
          </p>
          <input className="register-inputs text-secondary" placeHolder="%" />

          <p className={`${tableStyles.crudModalLabel} `}>Subir resultados</p>
          <label
            htmmlFor="documentsNR"
            className="d-flex justify-content-between "
            style={{
              border: "1px solid #01a0f6",
              borderRadius: "5px",
              height: "5rem",
            }}
          >
            <div
              className={`rounded 
            ${
              !isEmptyOrUndefined(inputs.fileName) &&
              tableStyles.bgColorClearBlue
            }`}
            >
              {!isEmptyOrUndefined(inputs.fileName) && (
                <img
                  className="cursorPointer ml-3 m-0 p-0 pb-2"
                  width="5%"
                  src={placeMyIcon(inputs.ext)}
                />
              )}
              <input
                style={{
                  textIndent: "-170px",
                }}
                type="file"
                className="text-secondary"
                name="documentsNR"
                onChange={(e) => onChangeDoc(e)}
                multiple={false}
              />
            </div>
            <img
              className="cursorPointer"
              width="20px"
              src={Adjuntar}
              title="Adjuntar"
            />
          </label>
          <div className={`customTabs__Footer mt-2 p-0`}>
            <Button
              className={tableStyles.btnPrimary}
              onClick={() => handleSubmit()}
              disabled={allowSend ? false : true}
            >
              <img className="cursorPointer" src={send} />
              &nbsp; Enviar
            </Button>
          </div>
        </Col> */}

        {/* /* ---------------------- VERTICAL LINE --------------------- */}
        {/* <Col className=" d-flex justify-content-center" xs={1}> */}
        {/* <hr
          className="m-0 p-0"
          style={{
            border: "none",
            borderLeft: "1px solid hsla(200, 10%, 50%, 100)",
            height: "100%",
            width: "1px",
          }}
        ></hr> */}
        {/* </Col> */}
        {/* /* ---------------------- CURRENT DISCOUNTS AND PAYOUTS --------------------- */}
        <Col
          className=""
          xs={4}
          style={{
            borderLeft: "1px solid hsla(200, 10%, 50%, 100)",
            paddingTop: 4,
            borderColor: "#dce1e3",
          }}
        >
          <div
            className="pt-2"
            style={{
              height: "60vh",
              width: "68vh",
              overflow: "auto",
            }}
          >
            {employeeInfo?.annotations?.length > 0 ? (
              employeeInfo?.annotations.map((e, i) => {
                return (
                  <div
                    key={i}
                    className={`${tableStyles.cardBoxContainer} p-2 mr-2 m-2`}
                    style={{ width: "94%" }}
                  >
                    <p className={tableStyles.cardBoxTitle}>{e.title}</p>
                    <hr></hr>

                    <div
                      // className={`d-flex justify-content-center`}
                      dangerouslySetInnerHTML={{
                        __html: isEmptyOrUndefined(e.description)
                          ? "<h1 class='text-secondary text-center'>No hay descripción para esta anotación</h1>"
                          : e.description,
                      }}
                    ></div>

                    <div
                      className={`d-flex text-start justify-content-between pt-4`}
                    >
                      <p className={tableStyles.cardBoxBottomText}>
                        {e.created_by_name}
                      </p>
                      <p className={tableStyles.cardBoxBottomText}>
                        {convertDateToLatinFormat(e.ann_date)}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <h4 className={tableStyles.darkBlueText}>
                  <b>Este colaborador no tiene notas</b>
                </h4>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
