//import SASS && Mui material && icons
import { Box } from '@mui/material';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import Select from 'react-select';
import tableStyle from "../../../components/Layouts/tableStyle.module.scss";


export const CardAddCoinsBase = ({options, onChange }) => {
    return (
        <div className={`${tableStyle.cardCoins}`} >
            <div className={`${tableStyle.cardCoinsImg}`}></div>

            <div>

                <div className={`${tableStyle.grid2} mb-2`}>

                    <Box>

                        <Box style={{ marginBottom:'15px' }}>
                            <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
                                &nbsp;Tipo de recaudo
                            </Box>


                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={options}
                                className="text-secondary w-100"
                                placeholder={"Seleccionar..."}
                                styles={ordCustomSelect}
                                // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                onChange={(e) => {
                                    // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                    onChange({label:e.label, value:e.value})
                                }}
                            />
                        </Box>

                        <Box>

                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                isDisabled
                                className="text-secondary w-100"
                                placeholder={"Seleccionar..."}
                                styles={ordCustomSelect}
                            />
                        </Box>

                    </Box>

                    <Box>

                        <Box
                            style={{ marginTop: '18px', marginBottom:'15px' }}
                        >
                            <Box>

                                <Select
                                    isDisabled
                                    noOptionsMessage={() => 'No hay datos'}
                                    className="text-secondary w-100"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                />
                            </Box>
                        </Box>


                        <Box>
                            <Box>
                                <Select
                                    isDisabled
                                    noOptionsMessage={() => 'No hay datos'}
                                    className="text-secondary w-100"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                />
                            </Box>
                        </Box>



                    </Box>


                </div>


            </div>
        </div>
    )
}