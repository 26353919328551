import GenericTableNew from "../Layouts/GenericTableNew";
import lupaClear from "../../assets/img/icons/lupaClear.svg";
import Trash from "../../assets/img/icons/canecaGris.svg";
import Agregar from "../../assets/img/icons/agregarEspecialidad.svg";


import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import genericTableStyles from "../Layouts/genericTable.module.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import reqStyles from "./Requisitions.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import { customSelectNew} from "../Layouts/react-select-custom";
import Select from "react-select";
import cloneDeep from 'lodash/cloneDeep';
import ModalNew from "../Layouts/ModalNew";




function ModalArticle (props){

    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const myData = counter.loginReducer.user_data;

    let listArticles = [];

    // const [props.filtersArticle, props.setFilterArticle] = useState({
    //     search: "",
    //     page: 1,
    //     perpage: 10,
    //     account:counter.loginReducer.currentAccount.id
    // });

    //const [props.selectedCC, props.setselectedCC] = useState(props.optionCostCenter.find(cc => cc.value==props.reqState?.id_consumption_center));

    // --------------------------aquí
    // const [props.selectedCC, props.setselectedCC] = useState(props.optionCostCenter.find(cc => cc.id_user==myData?.id));
    // const [props.articleChanges, props.setArticleChanges] = useState( [] );
    // const [props.setArticleNew, props.setArticleNew] = useState( [] );

    useEffect(()=>{
        //here's a problem: 
        //how do you assign a select's default value if the contents are generated by a dispatch in the same component?
        //...
        //give the option generation to the parent, then onComponentMount search it for the value
        //let myman = props.optionCostCenter.find(cc => cc.value==props.reqState?.id_consumption_center)
        let myman = props.optionCostCenter.find(cc => cc.id_user==myData?.id)
        props.setselectedCC(myman);
        cross_CC_article(myman)
        props.setArticleChanges(props.reqState?.articulos);
    }, [props.show])

    const handlePageChange = (val) => {
        props.setFilterArticle({
            ...props.filtersArticle,
            page: val
        });
    }

    const articleCheckHandle = (article) => {
        let articleChangesTemp = props.articleChanges;
        let exists = articleChangesTemp.find(x => (x.id == article.id));
        if(exists){
            articleChangesTemp = articleChangesTemp.filter(x => x != exists);
        }else{
            articleChangesTemp.push(article);
        }
        props.setArticleChanges(articleChangesTemp);
    }

    //const articleCheckAllStatus = (props.articleChanges.length>0);
    const articleCheckAllStatus = ()=>{
        const articles = filteredArticles();

        let allEqual = props.articleChanges.length>0;

        articles.map( (elem)=>{
            let exists = props.articleChanges.find(x => (x.id == elem.id));
            if(!(!!exists)){
                allEqual=false;
            }
        });

        return allEqual;
    };
    
    const articleNewAdd = (elem)=>{
        let articleNewTemp = props.setArticleNew;
        articleNewTemp.push(elem);
        props.setArticleNew(articleNewTemp);
        props.setFilterArticle({
            search:"",
            page:1,
            perpage:10,
        });
    } 

    const articleNewDelete = (elem)=>{
        
        let articleNewTemp = props.setArticleNew;

        articleNewTemp = articleNewTemp.filter(x=> !Object.is(x, elem))

        props.setArticleNew(articleNewTemp);
    }

    const articleCheckAllHandle = () => {
        let articleChangesTemp = props.articleChanges;

        if( !articleCheckAllStatus() ){
            const articles = filteredArticles();
            articles.map( (elem)=>{
                let exists = articleChangesTemp.find(x => (x.id == elem.id));
                if(!!exists==false){
                    articleChangesTemp.push(elem);
                }
            });
        }else{
            articleChangesTemp = [];
        }
        
        props.setArticleChanges(articleChangesTemp);
    }

    

    const filteredArticles = () => {
        //el metodo del dispatch trae los centros de consumo, vamos a filtrar solo los Artículos dentro de estos
        let onlyArticles = [];
        let realTotal=0;
        let tempArticles = [];
        
        let myarticles = props.selectedCC?.articles;
        if(Array.isArray(myarticles)){
            myarticles.map(z=>{
                    if(
                        z?.description?.toUpperCase().includes( props.filtersArticle.search.trim().toUpperCase() ) 
                        || 
                        z?.id?.toString().toUpperCase().includes(props.filtersArticle.search.trim().toUpperCase() ) 
                        ){

                        //bug fix: check if article is already included first
                        if(!!onlyArticles.find(existing => existing.id == z.id)===false){
                            onlyArticles.push({...z, ammount: 1});
                            realTotal++;
                        }
                    }
                });
        }

        onlyArticles=onlyArticles.sort( (a,b) =>(a.id - b.id) )

        return onlyArticles;
    }

    const renderList = () => {
        let table =[], butwaittheresmore=[];
        const articles = filteredArticles();

        if (Array.isArray(articles)) {
            table = articles.map( x => {
                return <tr key={"ReqModalArticleList"+x.id} className="hover-table-row">
                    <td className={`col-md-2 text-start px-2`} >
                        {x.id}
                    </td>
                    <td className={`col-md-8 text-start`}>
                        {x.description}
                    </td>
                    <td className={`col-md-2`}>
                        <input
                            className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
                            type="checkbox"
                            name={`selectedArt${x.article_id}`}
                            onChange={()=>articleCheckHandle(x)}
                            checked={!!props.articleChanges.find(change=> change.id ==x.id)}
                        />
                    </td>
                </tr>
            });

        }
        
        if (Array.isArray(props.setArticleNew)) {
            butwaittheresmore = props.setArticleNew.map( x => {
                if(
                    x?.description?.toUpperCase().includes( props.filtersArticle.search.trim().toUpperCase() ) || 
                    x?.id?.toString().toUpperCase().includes( props.filtersArticle.search.trim().toUpperCase() ) 
                    ){
                    return <tr key={"newItems"+x.id+" "+Math.random()} className={`hover-table-row ${genericTableStyles.orange}`}>
                        <td className={`col-md-2`}>
                            {x.id}
                        </td>
                        <td className={`col-md-8`}>
                            {x.description}
                        </td>
                        <td className={`col-md-2`}>
               
                            <img
                                src={Trash}
                                alt="Eliminar"
                                className={`icons-popUp ${reqStyles.adjustIcon} ${reqStyles.orangeIcon}`}
                                onClick={() => articleNewDelete(x)}
                            />
                        </td>
                    </tr>
                }
            });

            if(butwaittheresmore.length>0){
                table = table.concat(butwaittheresmore);
            }
        }

        return table;
    }

    const paginateList = () => {
        let fullList = renderList();

        fullList =  fullList.slice(props.filtersArticle.perpage*(props.filtersArticle.page-1),  props.filtersArticle.perpage*(props.filtersArticle.page));

        if(filteredArticles().length<1 && props.filtersArticle.search.trim()!=""){
                let newRow = <tr key="infinityyyyyyyyy" className="hover-table-row">
                <td className={`col-md-2 text-start`}>
                    <div className={`${reqStyles.newArticle}`}>NUEVO</div>
                </td>
                <td className={`col-md-8`}>
                    <div className={`${reqStyles.newArticle}`}>{props.filtersArticle.search.trim()}</div>
                </td>
                <td className={`col-md-2`}>
                    <img 
                        src={Agregar} 
                        alt="Agregar" 
                        className={`${reqStyles.adjustIcon}  ${reqStyles.orangeIcon}`} 
                        onClick={()=>articleNewAdd({
                            id: props.setArticleNew.length>0? "NUEVO "+props.setArticleNew.length:"NUEVO",
                            description:props.filtersArticle.search.trim().toUpperCase(),
                            newArticle: true,
                            ammount: 1,
                        })}
                    />
                </td>
            </tr>;
            
            fullList.push(newRow);
        }        
        return fullList;
    }

    const allCheckButton = (
        <input
            className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
            type="checkbox"
            name={`selectedArtAll`}
            onChange={()=>articleCheckAllHandle()}
            checked={ articleCheckAllStatus() }
        />
    )

    const renderHeaders = [
        <th key={`ReqModalArticle1`} className="px-2 text-start">Código</th>,
        <th key={`ReqModalArticle2`}>Descripción</th>,
        <th key={`ReqModalArticle99`} className="text-center">{allCheckButton}</th>,
    ]

    // const props.articlesForParentForm = (parentArticles, myArticles, parentCC, myCC) => {
    //     let articlesready = [];
    //     if(parentCC===myCC){
    //         //let myArticlesReady = myArticles.filter(item => !parentArticles.includes(item) )
    //         let myArticlesReady = myArticles.filter(item => !parentArticles.find(parentItem=> item.id==parentItem.id) )
    //         articlesready = [...parentArticles, ...myArticlesReady]
    //         return articlesready;
    //     }else{
    //         return [...myArticles];
    //     }
    // }

    
    // const props.savearticles =()=>{
    //     props.setreqState({
    //         ...props.reqState,
    //         id_consumption_center: props.selectedCC.value,
    //         cc_name: props.selectedCC.label,
    //         annual_budget: props.selectedCC.annual_budget,
    //         articulos:  props.articlesForParentForm(props.reqState.articulos, props.articleChanges.concat(props.setArticleNew), props.reqState.id_consumption_center, props.selectedCC.value),
    //     });
    //     //props.setArticleChanges([]);
    //     //props.setArticleNew([]);
    //     props.setFilterArticle({
    //         search:"",
    //         page:1,
    //         perpage:10,
    //     });
    //     props.onHide();
    // } 

    const cross_CC_article = (costcenter) =>{
        let arr1 = costcenter?.families;

        let allarticles = counter.requisitionReducer.articles;
        let myarticles = [];

        if(Array.isArray(allarticles)){
            allarticles.map(x => {
                let arr2 = x.families;
                let isFound = arr1?.some( ai => arr2.includes(ai) );
                if(!!isFound){
                    myarticles.push(x);
                }
            });
        }

        let clonecc = cloneDeep(costcenter);

        props.setselectedCC({
            ...clonecc,
            articles: myarticles,
        });

    }

    {/* //////////////////////modal //////////////////////////////// */}
    return <ModalNew
        title={props.title}
        show={props.show}
        size="lg"
        btnYesEvent={() => props.savearticles()}
        onHide={props.onHide}
    >
        
        <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
        <div className={tableStyles.mainRow}>
            <div  className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
                {(Array.isArray(props.optionCostCenter) && props.optionCostCenter.length>1)?
                    <Select noOptionsMessage={() => 'No hay datos'}
                        key={"selectCC"+props.selectedCC?.value}
                        defaultValue={props.selectedCC}
                        onChange={e => {
                                //props.setselectedCC(cloneDeep(e));
                                cross_CC_article(e);
                                props.setArticleChanges([]);
                                props.setArticleNew([]);
                                props.setFilterArticle({...props.filtersArticle, search:"", page:1})
                            } 
                        } 
                        options={[{label: "Seleccionar...", "value": ""}, ...props.optionCostCenter]}
                        placeholder="Seleccionar..."
                        styles={customSelectNew}
                        maxHeight={26}
                    />
                :
                    <input
                        className={tableStyles.inputText}
                        type="text"
                        placeholder=""
                        value={props.selectedCC?.label}
                        disabled
                    />
                }
            </div>
            
            <div  className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
                <input
                    className="register-inputs"
                    type="text"
                    placeholder="Escribir..."
                    value={props.filtersArticle.search}
                    onChange={(e) => props.setFilterArticle({
                        ...props.filtersArticle,
                        search: e.target.value
                    })}
                />
            </div>

            
            <img 
                src={lupaClear} 
                alt="filtrar" 
                className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`} 
                //onClick={this.clickFilter}
            />
            
            <div  className={`${reqStyles.ItemTiny} ${reqStyles.orangeText}`}>
                {(filteredArticles().length<1) && (props.filtersArticle.search.trim()!="")? "Artículo no encontrado" : ""}
            </div>

        </div>

        <GenericTableNew headers={renderHeaders}>{paginateList()}</GenericTableNew>
        
        <div className={paginationStyles.wrapperReverse}>
            <Pagination
            activePage={props.filtersArticle.page}
            itemsCountPerPage={props.filtersArticle.perpage}
            totalItemsCount={filteredArticles().length + props.setArticleNew.length}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
            itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
            itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
            itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
            itemClass={paginationStyles.itemClass}
            />
        </div>

    </ModalNew>
}

export default ModalArticle;