import React from 'react'
import { Accordion, Card, Col, Row } from "react-bootstrap";
// import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import tlnComponentStyles from './TlnComponentStyles.module.scss'

// import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const TlnAccordion = (props) => {
    return (
        <>
            <Accordion className={`w-100 ${tlnComponentStyles.ordAccordion} ${props.classAccordion ? props.classAccordion : 'mb-3'}`}
            >
                <Accordion.Toggle
                    eventKey={!!props.keyToogle ? props.keyToogle : "1"}
                    className={`w-100 accordionToggleButton  `}
                >
                    <Card.Body>
                        <Row
                            className={`d-flex justify-content-center`}
                            style={{ alignItems: 'center' }}
                        >
                            <Col
                                xs={5}
                                className="d-flex justify-content-start"
                            >
                                <span
                                    className={`text-secondary`}
                                >
                                    <code
                                        className={`m-3 text-secondary accordionToggleButton`}>{`>`}</code>
                                    <b>{props.title}</b>{props.titleVal}
                                </span>
                            </Col>
                            <Col
                                xs={4}
                                className="d-flex justify-con tent-start"
                            >
                                <span
                                    className={`text-secondary`}
                                >
                                    <b>{props.subtitle}</b>
                                    <span className={`text-secondary`} > {props.subtitleVal}</span>
                                </span>
                            </Col>
                            <Col
                                className="d-flex justify-content-end"
                                xs={2}
                            >
                                <span
                                    className={`text-secondary`}
                                >
                                    <b style={{ color: props?.fontcolor, backgroundColor: props?.backgroundColor, borderRadius: "10px", padding: "2px 22px" }}  >{props?.status}</b>
                                </span>
                            </Col>
                            <Col
                                className="text-end"
                                xs={1}
                            >
                                {props?.buttons?.length > 0 &&
                                    props?.buttons.map((e, i) => {
                                        return (
                                            <img className="px-2" src={e.img} alt="Editar" onClick={() => e.action()} />
                                        )
                                    })}

                                {/* <CustomPopupExtend
                                    showEnable={true}
                                    isEnabled={true}
                                    triggerSrc={props.triggerSrc && props.triggerSrc}
                                    enableClickEvent={() => alert("Deshabilitar")}
                                    showEdit={true}
                                    editText="Editar"
                                    editClickEvent={() => props?.editClickEvent()}
                                >
                                </CustomPopupExtend> */}
                            </Col>
                        </Row>
                    </Card.Body>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={!!props.keyCollapse ? props.keyCollapse : "1"}>
                    <Card.Body>
                        <Row>
                            <Col
                                xs={11}
                                style={{
                                    marginLeft: "56px",
                                }}
                            >
                                {props.children}
                            </Col>
                        </Row>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>
        </>
    )
}
