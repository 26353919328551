import React, { useEffect, useState } from "react";
import { getPermission, loader, message } from "../../helpers/helpers";
import { useSelector } from "react-redux";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import { OrdGenericTemplate } from './../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { Box } from '@mui/material';
import { Button } from './../../components/UI/atoms/button/Button';
import { useTheme } from 'styled-components';
import OrdTable from './../../OrderingModule/OrderingComponents/OrdTable';
import { UseDayCalendar } from './../../components/DayCalendar/UseDayCalendar';
import moment from "moment";
import imgDiscount from "../../assets/img/icons/app_aprovado.svg";
import imgDiscountGray from "../../assets/img/icons/minusGray.svg";
import usePatientAdmissionStore from "../../Hooks/usePatientAdmissionStore";
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { customSwaltAlertAsistencial } from "../../helpers";

const PatientAdmission = () => {

  // --------------------------- States  -------------------------

  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const site = store.userReducer.site;
  const eaccount = store.loginReducer.currentAccount.id;
  const createdBy = store.loginReducer.user_data.id
  const { colors } = useTheme()
  const history = useHistory();
  const listPermission = store.loginReducer.currentAccount?.profile?.permission
  const myPermission = getPermission({ prefix: 'admitPat', listPermission })
  const idUser = store.loginReducer.user_data.id;
  const siteId = store.userReducer.site

  const { setEvents, setActiveEvent, setImage, clearStateAdmissionPatient } = usePatientAdmissionStore()

  const { component: DayCalendar, getDaySelected } = UseDayCalendar()

  const [data, setData] = useState({
    selects: {
      listProfiles: null,
      listAccountsProfiles: null,
      listSites: null,
    },
    table: {
      listDataTable: null,
      dataSelectedSize: 0,
      dataArrayOrder: null,
      filters: {
        page: 1,
        perpage: 10,
        pageRangeDisplayed: 3,
        search: '',
        admitted: 0,
        date: moment(getDaySelected).format('YYYY-MM-DD'),
        site,
        eaccount,
        createdBy
      },
      formatDataTable: null,
      trigger: 0,
    },
    modal: {
      isEditing: null,
      show: null
    },
    dataSchema: [],
    dataArray: []

  })

  const { table, modal, dataSchema } = data


  const headers = [
    {
      title: "",
      className: `px-2 ${data?.table?.filters?.admitted ? '' : tableStyles.minWidth50px}`,
    },
    {
      title: "Fecha",
      className: `px-2 fw-bold ${tableStyles.minWidth200px} text-center`,
    },
    {
      title: "Paciente",
      className: `text-start ${tableStyles.minWidth300px} px-2 fw-bold`,
    },
    {
      title: "Servicio",
      className: `text-start px-2 fw-bold ${tableStyles.minWidth300px}`,
    },
    {
      title: "Sede",
      className: `text-center px-2 fw-bold ${tableStyles.minWidth300px}`,
    },
    {
      title: "Hora",
      className: `text-start px-2 fw-bold ${tableStyles.minWidth150px} text-center`,
    },
    {
      title: "Médico",
      className: `text-start px-2 fw-bold ${tableStyles.minWidth200px}`,
    },
    {
      title: "Descuento",
      className: "text-center px-2 fw-bold",
    },
    {
      title: "Anticipo",
      className: "text-center px-2 fw-bold"
    },
  ]

  //  ---------------------------Petitions -------------------------

  const {
    load: updateSiteLoader,
    trigger: updateSite
  } = usePostMethod();

  const {
    load: loaderCreateNewSite,
    trigger: createNewSite
  } = usePostMethod();

  const {
    results: listDataTable,
    trigger: getListDataTable,
    load: loadListDataTable
  } = useGetMethod()

  const {
    trigger: getCashClosingConfig,
    load: loadCashClosingConfig
  } = useGetMethod()

  // //  --------------------------- Modal -------------------------
  const postModal = () => {
    setData(state => (
      {
        ...state,
        modal: { ...state.modal, show: true, isEditing: false },
        dataSchema: {
          userId: null,
          profileId: null,
          sites: [],
          eaccount, createdBy,
          actionType: 'post'
        }
      }));
  };

  const closeModal = () => {
    setData(state => ({
      ...state,
      modal: {
        isEditing: null,
        show: null
      },
    }));
  };

  //  --------------------------- Methods ------------------------------

  const editSites = () => {
    updateSite({
      url: "/medical/admissions/sites/",
      token: token,
      method: "PUT",
      body: { ...dataSchema, sites: dataSchema.sites?.map(e => (e.value)) },
      succesAction: (res) => {
        closeModal()
        setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));
        message("success", `<span class=${tableStyles.ordClearBlueText}>${res.message}</span>`)
      },
      doAfterException: (res) => {
        message("success", `<span class=${tableStyles.ordClearBlueText}>${res.message}</span>`)
      }
    })
  };

  const createAssigment = () => {

    createNewSite({
      url: "/medical/admissions/sites/",
      token: token,
      method: "POST",
      body: { ...dataSchema, sites: dataSchema.sites?.map(e => (e.value)) },
      succesAction: (res) => {
        closeModal()
        setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));
        message("success", `<span class=${tableStyles.ordClearBlueText}>${res.message}</span>`)
      },
      doAfterException: (res) => {
        message("success", `<span class=${tableStyles.ordClearBlueText}>${res.message}</span>`)
      }
    })
  };

  const selectedAction = (id, isSelected) => {

    const sitesSelected = table?.listDataTable?.filter(e => e?.selected === true)

    if (sitesSelected?.length) {
      const cc = table?.listDataTable.find(e => e.id === id)?.patient.document

      const filter = sitesSelected?.filter(e => e.patient.document === cc)

      if (!filter?.length) {
        return
      }
    }

    const filterData = table.listDataTable.map(el => {
      if (el.id === id) {
        return {
          ...el, selected: isSelected
        }
      } else {
        return el
      }
    })

    const dataSchemaFilter = filterData.filter(el => el.selected === true)

    setData(state => (
      {
        ...state,
        dataSchema: dataSchemaFilter,
        table: { ...state.table, dataSelectedSize: dataSchemaFilter.length, listDataTable: filterData }
      }
    ))
  }


  // -------------------------------- Validations -----------------------
  const validationRequest = (item) => {

    if (dataSchema.profileId === null) {
      return message("warning", `<span class=${tableStyles.ordClearBlueText}>El Cargo es obligatorio</span>`)
    }

    if (dataSchema.userId === null) {
      return message("warning", `<span class=${tableStyles.ordClearBlueText}>El Usuario es obligatorio</span>`)
    }

    if (!dataSchema.sites.length) {
      return message("warning", `<span class=${tableStyles.ordClearBlueText}>La Sede es obligatoria</span>`)
    }

    modal.isEditing ? editSites(item) : createAssigment();

  };

  const patientValidation = () => {
    
    customSwaltAlertAsistencial({
      icon: 'success',
      title: `Paciente validado`,
      text: dataSchema[0].patient.firstName + ' ' + dataSchema[0].patient.lastName,
      showCancelButton: false,
      confirmButtonText: "Aceptar",
    }).then((result) => {

      if (result.isConfirmed) {
        setEvents(dataSchema)
        dataSchema?.length
          ? setActiveEvent({ ...dataSchema[0], progress: 1 })
          : setActiveEvent({ progress: 1 })

        history.push({ pathname: "/admision/admitirPacientes/informacionBasica" });
      }
    });
  }
  
  //-------------- format data to table 🍕   --------------------------
  const formatData = (array) => {
    let tempList = [];


    if (Array.isArray(array)) {
      array?.forEach((el) => {

        tempList.push([
          !data?.table?.filters?.admitted
            ? {
              text:
                <input
                  type="checkbox"
                  name="handleTelemedicine"
                  className={` ${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}  ${tableStyles.appCheckClear}  form-check-input p1 mx-4`}
                  checked={el?.selected || false}
                  onChange={(e) => selectedAction(el.id, e.target.checked)}

                />,
              selected: el.selected,
              className: "text-start px-2"

            } :
            {
              text: <div></div>,
              className: "px-2",
              selected: el.selected
            },
          {
            text:
              <div>{el.date || '-'}</div>,
            className: "text-center px-2",
            selected: el.selected
          },
          {
            text:
              <div>
                <div>{`${el.patient.firstName} ${el.patient.lastName}`}</div>
                <div style={{ color: colors.darkGray2 }}>C.C. {el.patient.document}</div>
              </div>,
            className: "text-start px-2",
            selected: el.selected
          },
          {
            text:
              <div>{el.service.name || '-'}</div>,
            selected: el.selected,
            className: "text-start px-2"

          },
          {
            text:
              <div>{el.siteName || '-'}</div>,
            selected: el.selected,
            className: "text-center px-2"

          },
          {
            text:
              <div>{ moment(el.hour, 'hh:mm').format('h:mm a')  || '-'}</div>,
            selected: el.selected,
            className: "text-center px-2"

          },
          {
            text:
              <div>{el.fullNameDoctor || '-'}</div>,
            selected: el.selected,
            className: "text-start px-2"

          },

          {
            text:
              // <div>{el.service.discount}</div>,
              <div>
                {
                  el.service.discount <= 0 || el.service.discount === null
                    ? <img alt="imgDiscountGray" src={imgDiscountGray} height={26}/>
                    : <img alt="imgDiscount" src={imgDiscount} height={22}/>
                }
              </div>,
            selected: el.selected,
            className: "text-center px-2"

          },
          {
            text:
              <div>
                {
                  el.service.advance <= 0 || el.service.discount === null
                    ? <img alt="imgDiscountGray" src={imgDiscountGray} height={26}/>
                    : <img alt="imgDiscount" src={imgDiscount} height={22}/>
                }
              </div>,
            selected: el.selected,
            className: "text-center px-2"

          },

        ]);
      });
    }

    setData(state => ({ ...state, table: { ...state.table, dataArrayOrder: array, formatDataTable: tempList } }))
  };

  //-------------- useEffect 🍕   --------------------------

  useEffect(() => {
    formatData(table?.listDataTable || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.listDataTable])


  useEffect(() => {
    if (table.trigger) {

      if (table.filters.search || table.filters.admitted !== null || table.filters.date !== null) {
        getListDataTable({
          url: `/medical/patientAdmission/`,
          objFilters: table.filters,
          token: token
        })
      } else {
        getListDataTable({
          url: `/medical/patientAdmission/`,
          objFilters: table.filters,
          token: token
        })

      }

    }
    // else if (table.filters.page) {

    //   getListDataTable({
    //     url: `/medical/patientAdmission/`,
    //     objFilters: { eaccount: eaccount, page: table.filters.page, perpage: table.filters.perPage },
    //     token: token
    //   })
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.trigger, table.filters.page])


  useEffect(() => {
    if (loadListDataTable === false) {
      if (!listDataTable?.results?.length) {

        setData(state => (
          {
            ...state,
            table: { ...state.table, listDataTable: [] }
          }
        ))
      } else {

        const filter = listDataTable?.results



        setData(state => (
          {
            ...state,
            table: { ...state.table, listDataTable: filter }
          }
        ))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listDataTable])


  useEffect(() => {
    setData(state => ({
      ...state,
      table: {
        ...state.table,
        trigger: table.trigger + 1,
        filters: {
          ...state.table.filters, date: moment(getDaySelected).format('YYYY-MM-DD'),
        }
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDaySelected])

  useEffect(() => {

    getCashClosingConfig({
      url: `/medical/admissions/cashClosingConfig/`,
      objFilters: { userId: idUser, siteId, eaccount },
      token: token
    }).then((res) => {
      if (res?.success) {
        if (res?.results?.hasPendingCashClosing) {
          customSwaltAlertAsistencial({
            icon: 'warning',
            title: `Intenta de nuevo`,
            text: 'Para poder seguir admitiendo pacientes, es necesario que realice un cierre parcial',
            showCancelButton: false,
            confirmButtonText: "Aceptar",
          }).then(() => {
            history.push({ pathname: 'inicio', })
          })
        }
      }
    })


    if (!myPermission?.read) {
      history.push({ pathname: 'inicio', })

    } else {

      getListDataTable({
        url: `/medical/patientAdmission/`,
        objFilters: table.filters,
        token: token
      })

      setImage('')
    }

    clearStateAdmissionPatient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //-------------- Component --------------------------

  return (
    <>
      {(loaderCreateNewSite || loadListDataTable || updateSiteLoader || loadCashClosingConfig) && loader}

      <OrdGenericTemplate
        className={`w-90 mt-4 mx-2`}
        showBottomBorder={false}
        title={"Admitir pacientes"}
        titleSize={12}
        searchWithNew={{
          searchWith: '70',
          searchIcon:true,
          onClickSearchIcon:() => {
            setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }))
          },
          onChange: (e) => {
            setData(state => ({ ...state, table: { ...state.table, filters: { ...state.table.filters, search: e.target.value } } }));
          },
          buttonNewAction: () => postModal(),
          onSubmit: (e) => {
            e.preventDefault();
            setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));;
          },
        }}
        filterCheck={{
          label: 'Mostrar citas admitidas',
          classNameLabel: '1',
          classNameInput: '0',
          onChange: ({ target }) => {
            setData(state => ({
              ...state,
              table: {
                ...state.table,
                trigger: table.trigger + 1,
                filters: { ...state.table.filters, admitted: target.checked ? 1 : 0 },
                dataSelectedSize: false
              }
            }));  
            
          }
        }}
        filterDate={{
          component: DayCalendar,
        }}
      >


        {
          !listDataTable?.results?.length
            ?
            <Alert
              key={'primary'}
              style={{ border: `1px solid #00b4cc ` }}
              className='text-center fw-bold mt-5 w-50 m-auto'
            >
              <span className={tableStyles.ordAquaMarineText}>No hay resultados encontrados.</span>
            </Alert>

            :
            <>

              <OrdTable
                headers={headers}
                body={table?.formatDataTable}
                className={`${tableStyles.ordTableShadow}`}
                selectedBg={`${tableStyles.selectedBg}`}
                paginate={{
                  activePage: table?.filters?.page,
                  totalPages: listDataTable?.rowTotal || 3,
                  perPage: table?.filters?.perpage,
                  pageRangeDisplayed: table?.filters?.pageRangeDisplayed,
                  onChangePage: async (e) => {
                    setData(state => ({ ...state, table: { ...state.table, filters: { ...state.table.filters, page: e } } }))
                  },
                  showTextDetails: true
                }}
              />

              {
                (myPermission?.create || myPermission?.update) && (
                  <Box className="d-flex justify-content-end">
                    <Button
                      className={`${tableStyles}.ordBtnPrimary} btn`}
                      disabled={table.dataSelectedSize ? false : true}
                      width='auto'
                      padding='8px'
                      fontSize='14px'
                      border='none'
                      backgroundColor={colors.ordAquaMarine}
                      backgroundColorHover={colors.ordAquaMarine + '90'}
                      onClick={patientValidation}
                    >
                      Admitir paciente
                    </Button>
                  </Box>
                )
              }

            </>


        }





      </OrdGenericTemplate>

    </>
  )
}

export default PatientAdmission