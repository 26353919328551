import { useEffect, useState } from 'react';
// icons - images
import { AddCircle } from '@mui/icons-material';
import { CancelOutlined } from '@material-ui/icons';
// css
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
//hooks
import { useGetMethod } from '../../Hooks/useFetch';
import { useSelector } from 'react-redux';
//components
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';
import ReactSelectAsync from 'react-select/async';

export const OnwerModule = ({
  form,
  index,
  props,
  defaultModuleOptions,
  defaultUserOptions,
  handleModuleOwner,
  addModuleOwnerFields,
  removeModuleOwnerFields,
}) => {
  const storage = useSelector(state => state);
  const token = storage.loginReducer.Authorization;
  const [userOptions, setUserOptions] = useState([{ value: '', label: 'Seleccionar...' }]);
  const { trigger: getSelectUsers, results: usersSelectResults } = useGetMethod();
  const onLoadUsers = async (inputValue, callback) => {
    if (inputValue.length >= 3 || inputValue === '') {
      const results = await getSelectUsers({
        url: '/users/params/',
        objFilters: {
          page: 1,
          perpage: 10,
          name: inputValue,
          active: 1,
        },
        token: token,
      });
      const options = [
        { value: '', label: 'Seleccionar...' },
        ...results?.results.map(({ id, first_name, first_surname }) => ({
          value: id,
          label: `${first_name} ${first_surname}`,
        })),
      ];
      callback(options);
    }
  };
  useEffect(() => {
    const results = usersSelectResults?.results;
    if (results) {
      const usersOptions = [
        { value: '', label: 'Seleccionar...' },
        ...results.map(({ id, first_name, first_surname }) => ({
          value: id,
          label: `${first_name} ${first_surname}`,
        })),
      ];
      setUserOptions(usersOptions);
    }
  }, [usersSelectResults]);
  return (
    <Row key={index} className='align-items-end' style={{ width: '70%' }}>
      <Col lg={5}>
        <p className={tableStyles.crudModalLabel}>Módulo</p>
        <Select
          noOptionsMessage={() => 'No hay datos'}
          key={'module' + index}
          options={defaultModuleOptions}
          placeholder='Seleccionar...'
          isDisabled={props?.detailAcc}
          styles={customSelectNewDark}
          onChange={e => handleModuleOwner(e, 'module', index)}
          value={defaultModuleOptions.find(x => x.value === form.module)}
        />
      </Col>
      <Col lg={5}>
        <p className={tableStyles.crudModalLabel}>Usuario responsable</p>
        <ReactSelectAsync
          noOptionsMessage={() => 'No hay datos'}
          key={'owner' + index}
          defaultOptions={defaultUserOptions}
          loadOptions={onLoadUsers}
          isDisabled={props?.detailAcc}
          placeholder='Seleccionar...'
          styles={customSelectNewDark}
          onChange={e => handleModuleOwner(e, 'owner', index)}
          value={
            defaultUserOptions.find(x => x.value === form.owner) ||
            userOptions.find(x => x.value === form.owner)
          }
        />
      </Col>
      <Col lg={2} className='d-flex align-items-end justify-content-around gap-4'>
        {index === 0 && !props?.detailAcc && (
          <AddCircle className={`${tableStyles.addButtonAccount}`} onClick={addModuleOwnerFields} />
        )}
        {index >= 1 && !props?.detailAcc && (
          <CancelOutlined
            className={`${tableStyles.addButtonAccount}`}
            onClick={() => removeModuleOwnerFields(index)}
          />
        )}
      </Col>
    </Row>
  );
};