import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import {
  GET_RECEIPT_OF_INVOICES,
  GET_STATUS,
  GET_CLIENT,
  GET_DETAIL_RECORD,
  GET_HISTORY,
  GET_ONE_MOVEMENT,
  GET_CONTRACTS,
  GET_ACCOUNTABLES,
  GET_BALANCE_SUM,
  GET_BALANCE_TOTAL,
  GET_ONE_BALANCE,
  GET_PROVISION,
  GET_DETAIL_DETERIORATE,
  SEND_INVOICE,
  GET_CALENDAR,
  PAYLEGAL_COLLECTION_LOADING,
  GET_DAY_CALENDAR,
  GET_DETAIL_CALENDAR,
  GET_TYPE_ACTIVITY,
  GET_COLLECTION_LETTER,
  LOADING_RECEPIT_OF_INVOICES
} from "./actionTypes";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { message } from "../helpers/helpers";
import { customSwaltAlert } from "../helpers";
const MySwal = withReactContent(Swal);

export const getReceiptInvoices = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_RECEIPT_OF_INVOICES,
    results: [],
    total: 0,
    loading: true
  });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/receiptRecord/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count } = res;
        dispatch({
          type: GET_RECEIPT_OF_INVOICES,
          results: results,
          total: row_count,
          loading: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_RECEIPT_OF_INVOICES,
          results: [],
          total: 0,
          loading: false,
        });
        console.error(err.message);
      });
  };

  export const getStatus = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/status/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results} = res;
        dispatch({
          type: GET_STATUS,
          results: results,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  export const getClient = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/client/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results} = res;
        dispatch({
          type: GET_CLIENT,
          results: results,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  export const getDetailRecord = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_DETAIL_RECORD,
      results: [],
      loading: true,
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/receiptRecord/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results} = res;
        dispatch({
          type: GET_DETAIL_RECORD,
          results: results,
          loading: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_DETAIL_RECORD,
          results: [],
          loading: false,
        });
        console.error(err.message);
      });
  };

  export const getHistory = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    dispatch({
      type: GET_HISTORY,
      loading: true
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/invoice/history/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_HISTORY,
          results: results,
          total: row_count,
          loading: false
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_HISTORY,
          results: [],
          total: 0,
          loading: false
        });
        console.error(err.message);
      });
  };

  export const getOneMovement = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/invoiceMovements/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_ONE_MOVEMENT,
          results: results,
          total: row_count,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  export const getContracts = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/contract/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_CONTRACTS,
          results: results,
          total: row_count,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };


  export const getAccountable = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_ACCOUNTABLES,
      results: [],
      total: 0,
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/accountable/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_ACCOUNTABLES,
          results: results,
          total: row_count,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  export const getBalanceSum = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_BALANCE_SUM,
      loading :true
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/balance/sum/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_BALANCE_SUM,
          results: results,
          total: row_count,
          loading :false
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BALANCE_SUM,
          results: [],
          total: 0,
          loading :false
        });
        console.error(err.message);
      });
  };

  export const getBalanceTotal = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_BALANCE_TOTAL,
      loading: true
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/balance/sum/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_BALANCE_TOTAL,
          results: results,
          total: row_count,
          loading: false
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BALANCE_TOTAL,
          results: [],
          total: 0,
          loading: false
        });
        console.error(err.message);
      });
  };

  export const getOneBalance = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_ONE_BALANCE,
      results: [],
      total:0,
      loading: true, 
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/balance/byClient/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_ONE_BALANCE,
          results: results,
          total:row_count,
          loading: false, 
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ONE_BALANCE,
          results: [],
          total:0,
          loading: false, 
        });
        console.error(err.message);
      });
  };

  export const getProvision = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_PROVISION,
      loading: true,
      rowTotal:0
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/expirationPeriods/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results} = res;
        dispatch({
          type: GET_PROVISION,
          results: results,
          loading: false,
          rowTotal: res.row_count
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_PROVISION,
          results: [],
          loading: false,
          rowTotal: 0
        });
        console.error(err.message);
      });
  };

  export const getDetailDeteriorate = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_DETAIL_DETERIORATE,
      loading: true
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/balance/deterioratedDetail/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results} = res;
        dispatch({
          type: GET_DETAIL_DETERIORATE,
          results: results,
          loading: false
        });

      })
      .catch((err) => {
        dispatch({
          type: GET_DETAIL_DETERIORATE,
          results: [],
          loading: false
        });
        console.error(err.message);
      });
  };

  
  export const creatProvision = (data, doAfter) => (dispatch,getState) => {
    
    const token = getState().loginReducer.Authorization;
    customSwaltAlert({
      icon: "warning",
      title: `¿Está seguro?`,
      text: `Se registrara una provision` ,
      confirmButtonText: "Si, Continuar",
      showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
          dispatch({
            type: GET_BALANCE_TOTAL,
            loading: true
          });
          fetch(
            `${URL_GATEWAY}${API_VERSION}/receivable/deteriorationProvision/`,
            {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
              },
            }
          )
            .then((response) => response.json())
            .then((res) => {
              if (res.success) {
                dispatch({
                  type: GET_BALANCE_TOTAL,
                  loading: false
            })
            customSwaltAlert({
              icon: "success",
              title: `Provision realizada exitosamente`,
              text: `Se ha registrado una provision: ${res.results.sequence}` ,
              showCancelButton: false,
            })
                dispatch({
                  type: GET_BALANCE_TOTAL,
                  loading: false
            })
              }else{
                dispatch({
                  type: GET_BALANCE_TOTAL,
                  loading: false
            })
               return customSwaltAlert({
                icon: "warning",
                title: `Intenta de nuevo`,
                text: res.message,
                showCancelButton: false,
                
              })
            }
            })
            .catch((res) => {
              dispatch({
                type: GET_BALANCE_TOTAL,
                loading: false
            })
              return customSwaltAlert({
                icon: "warning",
                title: `Intenta de nuevo`,
                text: res.message,
                showCancelButton: false,
                
              })
            });
        }
      });
  };


  export const PayLegalCollection = (data, doAfter) => (dispatch,getState) => {
    dispatch({
      type: PAYLEGAL_COLLECTION_LOADING,
      loading: true
    })
    const token = getState().loginReducer.Authorization;
    customSwaltAlert({
      icon: "warning",
      title: `¿Está seguro?`,
      text: `Se realizara un cobro pre-jurídico` ,
      confirmButtonText: "Si, Continuar",
      showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
          fetch(
            `${URL_GATEWAY}${API_VERSION}/receivable/collectionLetter/collect`,
            {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                
                token: token,
              },
            }
          )
            .then((response) => response.json())
            .then((res) => {
              dispatch({
                type: PAYLEGAL_COLLECTION_LOADING,
                loading: false,
              })
              if (res.success) {

                customSwaltAlert({
                  icon: "success",
                  title: `Cobro enviado exitosamente`,
                  text: res.results.nit +"-" +res.results.name,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false,
                })
                
                .then((response)=>{
                  if(response.isConfirmed){
                    doAfter();
                  }else{
                    doAfter();
                  }
                })
              }else{
                
                customSwaltAlert({
                  icon: "warning",
                  title: `Intenta de nuevo`,
                  text: res.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false,
                  
                })

              }
            })
            .catch((res) => {
              customSwaltAlert({
                  icon: "warning",
                  title: `Intenta de nuevo`,
                  text: res.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false,
                  
                })
            });
        }else{
          dispatch({
            type: PAYLEGAL_COLLECTION_LOADING,
            loading: false,
          })
        }
      });
  };

  export const receiveInvoices = (data, doAfter) => (dispatch,getState) => {
    dispatch({
      type: LOADING_RECEPIT_OF_INVOICES,
      loading: true,
    })
    const token = getState().loginReducer.Authorization;
          fetch(
            `${URL_GATEWAY}${API_VERSION}/receivable/receiptRecord/receive/`,
            {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                
                token: token,
              },
            }
          )
            .then((response) => response.json())
            .then((res) => {
              if (res.success) {
                dispatch({
                  type: LOADING_RECEPIT_OF_INVOICES,
                  loading: false,
                })
                MySwal.fire({
                  icon: "success",
                  title: "Acta recibida",
                  text: "Acta de facturación " + res.results.sequence,
                  confirmButtonText: "Aceptar",
                }).then((response)=>{
                  if(response.isConfirmed){
                    doAfter();
                  }else{
                    doAfter();
                  }
                })
              }else{
                dispatch({
                  type: LOADING_RECEPIT_OF_INVOICES,
                  loading: false,
                })
                MySwal.fire({
                  icon: "error",
                  title: "Error al recibir acta",
                  text: res.message,
                  
                });
              }
            })
            .catch((res) => {
              dispatch({
                type: LOADING_RECEPIT_OF_INVOICES,
                loading: false,
              })
              MySwal.fire({
                icon: "error",
                title: "Error al recibir acta",
                text: res.message,
                
              });
            });

  };

  export const receiveRecord = (data) => (dispatch,getState) => {
    const token = getState().loginReducer.Authorization;

    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se realizara una radicación ${data?.type === 'V' ? 'virtual' :data?.type === 'P' ? 'física' : '' }` ,
      icon: "warning",
      confirmButtonText: "Si, Continuar",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `${URL_GATEWAY}${API_VERSION}/receivable/receiptRecord/file/`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              
              token: token,
            },
          }
        )
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              customSwaltAlert({
                icon: "success",
                title: `Radicado exitosamente`,
                text: "Radicación Realizada",
                confirmButtonText: "Aceptar",
                showCancelButton: false,
              }).then((response)=>{
                if(response.isConfirmed){
                  window.location.reload(); 
                }
                else{
                  window.location.reload(); 
                }
              })
            }else{
              customSwaltAlert({
                icon: "warning",
                title: `Error al realizar la radicación`,
                text: res.message,
                
                confirmButtonText: "Aceptar",
                showCancelButton: false,
              })
              
            }
          })
          .catch((res) => {
            customSwaltAlert({
                icon: "warning",
                title: `Error al realizar la radicación`,
                text: res.message,
                
                confirmButtonText: "Aceptar",
                showCancelButton: false,
              })
          });
      }
    });

  };

  export const sendInvoice = (data) => (dispatch,getState) => {
    const token = getState().loginReducer.Authorization;
          fetch(
            `${URL_GATEWAY}${API_VERSION}/receivable/receiptRecord/addInvoice/`,
            {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                
                token: token,
              },
            }
          ).then((response) => response.json())
          .then((res) => {
            if (res.success) {
              let num= Math.random()
              dispatch({
                type: SEND_INVOICE,
                results: num,
              });
              
            }else{
              MySwal.fire({
                icon: "error",
                title: "Error al agregar la factura",
                text: res.message,
                
              });
            }
          })
          .catch((err) => {
            console.error(err.message);
          });

  };

  export const getCalendar = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/calendar/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_CALENDAR,
          results: results,
          total: row_count,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
  export const getDayCalendar = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    dispatch({
      type: GET_DAY_CALENDAR,
      loading: true
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/activity/byDate/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_DAY_CALENDAR,
          results: results,
          total: row_count,
          loading: false
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_DAY_CALENDAR,
          loading: false
        });
        console.error(err.message);
      });
  };

  export const resetDetailCalendar  = () => (dispatch, getState) => {
    dispatch({
      type: GET_DAY_CALENDAR,
      results: [],
      total: 0,
      loading: false
    });

  }

  export const getDetailCalendar = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_DETAIL_CALENDAR,
      results: [],
      total: 0,
      loading: true
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/activity/cards/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_DETAIL_CALENDAR,
          results: results,
          total: row_count,
          loading: false
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_DETAIL_CALENDAR,
          results: [],
          total: 0,
          loading: true
        });
        console.error(err.message);
      });
  };
  export const getTypeActivity = (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    // const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/receivable/activityType/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count} = res;
        dispatch({
          type: GET_TYPE_ACTIVITY,
          results: results,
          total: row_count,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
  export const newObservation = (data,doAfter) => (dispatch,getState) => {
    const token = getState().loginReducer.Authorization;
     dispatch({
       type: PAYLEGAL_COLLECTION_LOADING,
       loading: true
      })
          fetch(
            `${URL_GATEWAY}${API_VERSION}/receivable/activity/`,
            {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                
                token: token,
              },
            }
          ).then((response) => response.json())
          .then((res) => {
            if (res.success) {
              dispatch({
                type: PAYLEGAL_COLLECTION_LOADING,
                loading: false
            })
            MySwal.fire({
                icon: "success",
                title: "Evento agregado",
                text:res.results.name,
                confirmButtonText: "Aceptar"
            }).then((response)=>{
              if(response.isConfirmed){
                doAfter() 
              }else{
                doAfter()
              }
            })
            }else{
              dispatch({
                type: PAYLEGAL_COLLECTION_LOADING,
                loading: false
            })
            MySwal.fire({
                icon: "error",
                title: "Error al agregar observación",
                text: res.message,
                
            });
            }
        })
        .catch((res) => {
          dispatch({
            type: PAYLEGAL_COLLECTION_LOADING,
            loading: false
        })
            MySwal.fire({
              icon: "error",
              title: "Error al agregar evento",
              text: res.message,
              
            });
        });
    }

    export const getLetters = (objFilters) => (dispatch, getState) => {
      const token = getState().loginReducer.Authorization;
      const filters = convertFilterToString(objFilters);
      fetch(`${URL_GATEWAY}${API_VERSION}/receivable/collectionLetter/?${filters}`, {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
             dispatch({
            type: GET_COLLECTION_LETTER,
            results: res.results,
            
          });
          }
        
        })
        .catch((error) => {
          console.error(error.message);
          return message("error", "Error", "Ha ocurrido un error");
        });
    };

    
export const sendLetter = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/receivable/collectionLetter/collect`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let results = await query.json();
    if (results.success) {
      return results;
    } else {
      return message("error", "Error", results.message);
    }
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "Ha ocurrido un error");
  }
};