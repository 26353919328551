import Select from 'react-select';
import { Col, Form, Row } from "react-bootstrap";
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import NumberFormat from 'react-number-format';
import { useTheme } from 'styled-components';
import { Text } from '../../../components/UI/atoms';
import close from "../../../assets/img/icons/close-blue.svg";
import moneySymbol from "../../../assets/img/icons/moneySymbol.svg";
import cardCredit from "../../../assets/img/icons/cardCredit.svg";
import transfer from "../../../assets/img/icons/transfer.svg";


export const CardCollectionDynamic = ({ data, dataActive, activeEvent, setActiveEvent, handleRemoveDeposit, totalAmount, listFranchise, listBank, valuesPay }) => {

  const { colors } = useTheme()

  const img = data?.image === 'cash' ? moneySymbol : data?.image === 'cardCredit' ? cardCredit : transfer

  return (
    <Row className=" mt-2" style={{ maxWidth: '1100px' }}>
      <Col xs={11} className="">

        <div className={`${tableStyles.addCardWhite} d-flex my-3 py-4 px-2`}>

          <Col xs={2} className="align-self-center">
            <div className="d-flex align-self-center justify-content-center">
              <div
                className={`${tableStyles.addCardWhite} d-flex justify-content-center align-self-center m-0 my-0`}
                style={{ width: "100px", height: "72px", alignItems: 'center', display: 'flex' }}
              >
                <img alt="arrowIcon" height='50px' src={img} />

              </div>
            </div>
          </Col>

          <Col xs={5} className="px-2">
            <Form.Group className=" text-start mb-2" controlId="">
              <Text
                title={'Tipo de recaudo'}
                color={colors.ordDarkBlueText}
                fontSize={'14px'}
                required
              />

              <Form.Control
                className={`ord-roundInput `}
                type="text"
                placeholder={data?.depoTypeName}
                disabled
              />
            </Form.Group>

            {
              data?.haveChange ? (
                <div className="d-flex">
                  <Col xs={!data?.pendingAmount ? 12 : 6}>
                    <Form.Group className=" text-start mr-2" controlId="">
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                        <span> &nbsp;Vuelto total </span>
                      </Form.Label>
                      <NumberFormat
                        disabled
                        allowNegative={false}
                        className={`register-inputs`}
                        placeholder="Escribe..."
                        style={{ maxHeight: '32px', height: '32px', fontSize: '14px', borderColor: '#a3bad1' }}
                        thousandSeparator={","}
                        decimalSeparator={"."}
                        isNumericString={true}
                        prefix={'$'}
                        value={data?.pendingAmount}
                      />
                    </Form.Group>
                  </Col>
                  {
                    data?.pendingAmount ? (
                      <Col xs={6}>
                        <Form.Group className=" text-start ml-2" controlId="">
                          <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                            <span> &nbsp;¿Donar el vuelto?</span>
                          </Form.Label>
                          <Select
                            noOptionsMessage={() => 'No hay datos'}
                            options={[{ label: 'Si', value: 1 }, { label: 'No', value: 0 }]}
                            value={data?.donateChangeName ? { label: data?.donateChangeName, value: data?.donateChangeName } : ''}
                            className="text-secondary "
                            placeholder={"Seleccionar..."}
                            styles={ordCustomSelect}
                            onChange={({ value, label }) => {

                              setActiveEvent({
                                ...activeEvent,
                                listDeposits: activeEvent?.listDeposits
                                  ?.map(el => (el?.depoTypeId === data?.depoTypeId)
                                    ? { ...el, donateChange: value, donateChangeName: label }
                                    : el
                                  ),
                              })

                            }}
                          />
                        </Form.Group>
                      </Col>
                    ) : ''
                  }

                </div>
              ) : ''
            }

            {
              data?.haveFranchise ? (
                <div className="d-flex mt-2">
                  <Col xs={12}>
                    <Form.Group className=" text-start ml-2" controlId="">
                      <Text
                        title={'Franquicia'}
                        color={colors.ordDarkBlueText}
                        fontSize={'14px'}
                        required
                      />

                      <Select noOptionsMessage={() => 'No hay datos'}
                        options={listFranchise?.results?.map(e => ({ value: e.fraId, label: e.fraName }))}
                        className="text-secondary "
                        placeholder={"Seleccionar..."}
                        styles={ordCustomSelect}
                        value={data?.franchise ? { label: data?.franchiseName, value: data?.franchise } : ''}
                        onChange={({ value, label }) => {

                          setActiveEvent({
                            ...activeEvent,
                            listDeposits: activeEvent?.listDeposits
                              ?.map(el => (el?.depoTypeId === data?.depoTypeId)
                                ? { ...el, franchise: value, franchiseName: label }
                                : el
                              ),
                          })

                        }}
                      ></Select>
                    </Form.Group>
                  </Col>

                </div>
              ) : ''
            }

            {
              data?.haveBank ? (
                <div className="d-flex mt-2">
                  <Col xs={12}>
                    <Form.Group className=" text-start ml-2" controlId="">
                      <Text
                        title={'Banco'}
                        color={colors.ordDarkBlueText}
                        fontSize={'14px'}
                        required
                      />
                      <Select
                        noOptionsMessage={() => 'No hay datos'}
                        options={listBank?.results?.map(el => ({ label: el?.description, value: el?.id })) || []}
                        className="text-secondary "
                        placeholder={"Seleccionar..."}
                        styles={ordCustomSelect}
                        value={data?.bank ? { label: data?.bankName, value: data?.bank } : ''}
                        onChange={({ value, label }) => {

                          setActiveEvent({
                            ...activeEvent,
                            listDeposits: activeEvent?.listDeposits
                              ?.map(el => (el?.depoTypeId === data?.depoTypeId)
                                ? { ...el, bank: value, bankName: label }
                                : el
                              ),
                          })

                        }}
                      />
                    </Form.Group>
                  </Col>

                </div>
              ) : ''
            }


          </Col>

          <Col xs={5} className="px-2">
            <Form.Group className=" text-start mb-2" controlId="">
              <Text
                title={'Monto pagado'}
                color={colors.ordDarkBlueText}
                fontSize={'14px'}
                required
              />

              <NumberFormat
                allowNegative={false}
                className={`register-inputs`}
                placeholder="Escribe..."
                style={{ maxHeight: '32px', height: '32px', fontSize: '14px', borderColor: '#a3bad1' }}
                thousandSeparator={","}
                decimalSeparator={"."}
                isNumericString={true}
                prefix={'$'}
                value={data?.amount || ''}
                onValueChange={({ value, floatValue, formattedValue }) => {
                  const pendingAmount = (value && (Number(totalAmount) >= Number(value))) ? 0 : Number && (Number(value) > Number(totalAmount)) ? Number(value) - Number(totalAmount) : 0
                  const totalChange = (value && (Number(totalAmount) > Number(value))) ? Number(totalAmount) - Number(value) : 0

                  let amountTotal = 0
                  activeEvent?.listDeposits?.forEach(el => {
                    if (el?.depoTypeId === data?.depoTypeId) amountTotal += Number(value)
                    else amountTotal += Number(el?.amount)
                  })


                  setActiveEvent({
                    ...activeEvent,
                    amountTotal,
                    listDeposits: activeEvent?.listDeposits
                      ?.map(el => (el?.depoTypeId === data?.depoTypeId)
                        ? { ...el, amount: value, pendingAmount, totalChange }
                        : el
                      ),

                  })

                }}
              />
            </Form.Group>

            {
              data?.haveChange ? (
                <div className="d-flex">
                  {
                    data?.pendingAmount ? (
                      <Col xs={6}>
                        <Form.Group className=" text-start mr-2" controlId="">
                          <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                            <span> &nbsp;¿Cuánto? </span>
                            {data?.donateChange
                              ? <span className={`${tableStyles.ordOrangeText}`}>*</span>
                              : ''
                            }

                          </Form.Label>
                          <NumberFormat
                            allowNegative={false}
                            className={`register-inputs`}
                            placeholder="Escribe..."
                            style={{ maxHeight: '32px', height: '32px', fontSize: '14px', borderColor: '#a3bad1' }}
                            thousandSeparator={","}
                            decimalSeparator={"."}
                            isNumericString={true}
                            prefix={'$'}
                            value={data?.donation || ''}
                            onValueChange={({ value, floatValue, formattedValue }) => {

                              setActiveEvent({
                                ...activeEvent,
                                listDeposits: activeEvent?.listDeposits
                                  ?.map(el => (el?.depoTypeId === data?.depoTypeId)
                                    ? { ...el, donation: value }
                                    : el
                                  ),
                              })

                            }}
                          />
                        </Form.Group>
                      </Col>
                    ) : ''
                  }

                  {
                    data?.pendingAmount ? (
                      <Col xs={6}>
                        <Form.Group className=" text-start ml-2" controlId="">
                          <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                            <span> &nbsp;¿Vuelto entregado?</span>
                          </Form.Label>
                          <Select noOptionsMessage={() => 'No hay datos'}
                            options={[{ label: 'Si', value: 1 }, { label: 'No', value: 0 }]}
                            className="text-secondary "
                            placeholder={"Seleccionar..."}
                            styles={ordCustomSelect}
                            value={data?.turnedDeliveredName ? { label: data?.turnedDeliveredName, value: data?.turnedDelivered } : ''}
                            onChange={({ value, label }) => {

                              setActiveEvent({
                                ...activeEvent,
                                listDeposits: activeEvent?.listDeposits
                                  ?.map(el => (el?.depoTypeId === data?.depoTypeId)
                                    ? { ...el, turnedDelivered: value, turnedDeliveredName: label }
                                    : el
                                  ),
                              })

                            }}
                          />
                        </Form.Group>
                      </Col>
                    ) : ''
                  }


                </div>
              ) : ''
            }


            {
              data?.haveReference ? (
                <Col xs={12} className='mt-2'>
                  <Form.Group className=" text-start mr-2" controlId="">
                    <Text
                      title={'No. referencia'}
                      color={colors.ordDarkBlueText}
                      fontSize={'14px'}
                      required
                    />

                    <input
                      className={`register-inputs`}
                      style={{ maxHeight: '32px', height: '32px', fontSize: '14px', borderColor: '#a3bad1' }}
                      type="number"
                      placeholder="Escribe..."
                      value={data?.referenceNumber || ''}
                      onChange={({ target }) => {

                        setActiveEvent({
                          ...activeEvent,
                          listDeposits: activeEvent?.listDeposits
                            ?.map(el => (el?.depoTypeId === data?.depoTypeId)
                              ? { ...el, referenceNumber: target?.value }
                              : el
                            ),
                        })

                      }}
                    />
                  </Form.Group>
                </Col>
              ) : ''
            }

          </Col>

        </div>

      </Col>

      <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>

        <span
          className={tableStyles.hoverClose}
          onClick={() => handleRemoveDeposit(data?.depoTypeId)}
        >
          <img
            src={close}
            alt="cerrar"
            style={{ padding: '5px', boxShadow: '0px 0px 2px #ccc', background: 'white', borderRadius: '100%', cursor: 'pointer' }}
          />
        </span>

      </Col>

    </Row>
  )
}