import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Cerrar from "../../assets/img/icons/xCuadroBlanco.svg";
import "../Layouts/modal.scss";
import { connect } from "react-redux";

function GenericModal (props) {
    return (
      <Modal
        // {...props}
        title = {props.title}
        show = {props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header >
          <div className="div-header-modal">
            <h2>{props.title}</h2>
            <img src={Cerrar} alt="User icon" className="icons-svg" />
          </div>
        </Modal.Header>
        <Modal.Body>
      
          <div className="container">
           {props.children}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Cancelar</Button>
          <Button onClick={props.confirmaction}>Guardar</Button>
        </Modal.Footer>
      </Modal>
    );

}


export default GenericModal;
