import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// ------------- ICONS 💥 --------------------------//
import False from "../../assets/img/icons/extraBtnRedX.svg";
import SearchIcon from "../../assets/img/icons/lupa.svg";
import CheckMark from "../../assets/img/icons/CheckMark.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Alert2 from "../../assets/img/icons/Alert2.svg";
// ------------- ICONS 💥 --------------------------//
import paginationStyles from "../Layouts/pagination.module.scss";
import { connect } from "react-redux";
import "reactjs-popup/dist/index.css";
import Pagination from "react-js-pagination";
import { PAGE, PERPAGE } from "../../helpers/constants";
import ownStyles from "./tableWarehouseEntry.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import GenericTableNew from "../Layouts/GenericTableNew";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
// import CustomPopupExtend from "../Popup/customPopUpExtends";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import deepcopy from "deepcopy";
import ModalNew from "../Layouts/ModalNew";
import { getAuthStatus, getWarehouseEntries, getWarehouseEntryDetail, getWEProblems, saveArticleInWarehouse, updateWarehouseEntry } from "../../actions/warehouseActions";
import { storage_get } from "../../actions/storageActions";
import NumberFormat from "react-number-format";
// import genericTableStyles from "../";
import genericTableStyles from "../Layouts/genericTable.module.scss";
import ReactSelect from "react-select";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
const MySwal = withReactContent(Swal);

class AuthEntryWarehouse extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.props.getWEProblems();
    this.props.getAuthStatus({prefix:"authWE"});
    this.props.getWarehouseEntries({ page: PAGE, perpage: PERPAGE, eaccount: this.props.currentAccount.id, we_req_auth:1});
    this.props.storage_get({ id_account: this.props.currentAccount.id })
    this.setState(this.initialState);

  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  get initialState() {
    return {
      purchaseOrder: {},
      modalPurchaseOrder: false,
      redirect: null,
      modal: false,
      modalJustification: false,
      editRecord: false,
      addWarehouseEntry: {},
      addArticle:{},
      paginationModal: {
        page: 1,
        perpage: 10,
        totalIndex: 0,
      },
      filters: {
        search: "",
        status: "",
        due_date: "",
        we_req_auth:1,
        eaccount: this.props.currentAccount.id,
        perpage: PERPAGE,
        page: PAGE,
      },
      headerState: [
        "ID",
        "No. factura o revisión",
        "Almacén",
        "Fecha",
        "Estado",
        "",
      ],
    };
  }


  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "authWE"
    );


  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.props.getWarehouseEntries(filters)
    this.setState({ filters });
  }

  //Method to search when click in magnify lens
  handleSearch = (e) => {
    let { filters } = this.state;
    filters.page = 1;
    this.props.getWarehouseEntries(filters);
    this.setState({ filters });
  };

  statusOptions = ()=>{
    let arr = [{label: "Seleccionar...", value:"", name:"we_status"}];
    (Array.isArray(this.props.status)) && this.props?.status?.map(status => {
      arr.push({label: status.id, value: status.name, name:"we_status"})
    })
    return arr
  }

  wareHouseOptions = ()=>{
    let arr = [{label: "Seleccionar...", value:"", name:"we_warehouse_id"}];
    this.props.listWarehouses?.map(storage => {
      arr.push({label: storage.description, value: storage.id, name:"we_warehouse_id"})
    })
    return arr
  }
  problemOptions = ()=>{
    let arr = [{label: "Seleccionar...", value:"", name:"we_warehouse_id"}];
    this.props.problems?.map(pro => {
      arr.push({label: pro.value, value: pro.id, name:"we_warehouse_id"})
    })
    return arr
  }
  onKeyDownNumber = (e) => {
    let { errorInputs } = this.state;
    if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
      e.preventDefault();
      errorInputs = true;
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Este campo solo acepta números",
        showCancelButton: false
      });
    }
    errorInputs = false;
    this.setState({ errorInputs });
  };

  getStatusAuth = (status) => {
    switch (status) {
      case "reqAuth":
        return { class: tableStyles.warningIcon, text: "Sin revisar"};
      case "pending" :
        return { class: tableStyles.checkMark, text: "Autorizada"};
      case "rejected":
        return { class: tableStyles.deniedCross, text: "Denegada" };      
      case "solved":
        return { class: tableStyles.checkMark, text: "Autorizada"};
      case "authorized":
        return { class: tableStyles.checkMark, text: "Autorizada"};
      default:
        return { class: "", text: "-" };
        break;
    }
    

  }

  // Method to get css style for circle in status
  getStatusClass = (status) => {
    switch (status) {
      case "expired":
        return { class: tableStyles.circleRedAuto, text: "Vencido" };

      case "vigent":
        return { class: tableStyles.circleGreenAuto, text: "vigente" };

      case "solved":
        return { class: tableStyles.circleGreenAuto, text: "Recibido" };

      case "expired":
        return { class: tableStyles.circleRedAuto, text: "Vencido" };

      case "vigent":
        return { class: tableStyles.circleGreenAuto, text: "vigente" };

      case "reqAuth":
        return { class: tableStyles.warningIcon, text: "Requiere autorización" };

      case "denied":
        return { class: tableStyles.deniedCross, text: "Denegado" };

      case "authorized":
        return { class: tableStyles.checkMark, text: "Autorizado" };

      default:
        return { class: tableStyles.circleRedAuto, text: "Vencido" };
    }
  };

  getCoinNickname = (coin) => {
    switch (coin) {
      case "dolar":
        return "USD";
      case "peso":
        return "COP";
      case "Dolar estadunidense":
        return "USD";
      case "Peso colombiano":
        return "COP";
      default:
        return "-";
    }
  };

  getPaymentCondition = (pay) => {
    switch (pay) {
      case "prepayment":
        return "Pago anticipado";
      case "delivery":
        return "Pago contraentrega";
      case "pay20":
        return "Pago a 20 días";
      case "pay30":
        return "Pago a 30 días";
      case "pay60":
        return "Pago a 60 días";
      case "pay90":
        return "Pago a 90 días";
      case null:
        return "-";
      default:
        return pay;
    }
  };

  onChangeForm = (event) => {
    const { name, value } = event.target;
    let { addWarehouseEntry } = this.state;
    addWarehouseEntry[name] = value;
    this.setState({ addWarehouseEntry });
  };

  onChangeFilter = (event) => {
    const { name, value, type } = event?.target ?? event;
    let { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };

  handleOnHide = () => {
    let { modal, addWarehouseEntry } = this.state;
    modal = !modal;
    addWarehouseEntry = {};
    this.setState({ modal, addWarehouseEntry });
  }

  onKeyUpSearch = (e) => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
      this.props.getWarehouseEntries(filters);
    } else {
      e.preventDefault();
    }
    this.setState({ filters });
  };

  handleEditRecord = (entry) => {
    let { modal, addWarehouseEntry } = this.state;
    modal = !modal;
    this.props.getWarehouseEntryDetail(entry.id, () => {
      addWarehouseEntry = deepcopy(this.props.wEntry);
      this.setState({ addWarehouseEntry });
    })
    this.setState({ modal});
  }


  handlePageChangeModalArticle = (pageNumber) => {
    let { paginationModal, addWarehouseEntry } = this.state;
    paginationModal.page = pageNumber;
    let min =
      pageNumber * paginationModal.perpage - paginationModal.perpage;
    let max = pageNumber * paginationModal.perpage;
    addWarehouseEntry.purchase_order_ref?.purchaseOrderItems.slice(min, max);
    this.setState({ paginationModal });
  }

  handleAuthorization = () => {
    customSwaltAlert({
      icon:"warning",
      title:"¿Está seguro?",
      text:`Se autorizará la entrada ${this.state.addWarehouseEntry.we_number}`,
      showCancelButton:true,
      cancelButtonText:"Cancelar",
      showConfirmButton:true,
      confirmButtonText:"Si, autorizar"
    }).then((result)=>{
      if (!!result.isConfirmed) {
        this.props.updateWarehouseEntry({id:this.state.addWarehouseEntry.id, we_status:"authorized", we_number:this.state.addWarehouseEntry.we_number}, ()=> this.setState({...this.state, modal:false}));        
      }
    })
  }

  handleDenied = () => {
    customSwaltAlert({
      icon:"warning",
      title:"¿Está seguro?",
      text:`Se denegará la entrada ${this.state.addWarehouseEntry.we_number}`,
      showCancelButton:true,
      cancelButtonText:"Cancelar",
      showConfirmButton:true,
      confirmButtonText:"Si, denegar"
    }).then((result)=>{
      if (!!result.isConfirmed) {
        this.setState({...this.state, modalJustification:true})
      }
    })
  }


  handleOnChangeArticle = (event) => {
    const {name, value} = event.target
    let {addArticle} = this.state;
    addArticle[name] = value;
    this.setState({addArticle});
  }

  // ANCHOR RENDER METHOD
  render() {

    if (!this.props.isAuth) {
      return <Redirect to={"/login"} />;
    }

    if (!!this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              ...this.state.addWarehouseEntry,
              ...this.state.purchaseOrder,
            },
          }}
        />
      );
    }

    let renderHeaders = this.state.headerState
      ? this.state.headerState.map((header) => {
        return (
          <th key={`header-${header}`} className="text-center">
            {header}
          </th>
        );
      })
      : "";

    var renderHeadersModal = [
      "Item",
      "Descripción del artículo",
      "Lotes",
      "Cant. ordenada",
      "Cant. recibida",
      "Stock min",
      "Stock max",
      "Disponible",
      "",
    ].map((header) => {      
      let dynamicWith = "auto";
      switch (header) {
        case "Descripción del artículo":
          dynamicWith = "15.5rem"
          break;
        case "Lotes":
          dynamicWith = "70px"
          break;      
        case "Cant. ordenada":
          dynamicWith = "9rem"
          break;      
        case "Stock max":
          dynamicWith = "8rem"
          break;      
        case "Stock min":
          dynamicWith = "7rem"
          break;      
        default:
          dynamicWith = "auto"
          break;
      }
      return (
        <th key={`hdModal-${header}`} className="text-center px-2" style={{width:dynamicWith}}>
          {header}
        </th>
      );
    });

    // ANCHOR render entries
    let renderWarehouseEntries = this.props?.warehouseEntries?.map((item) => {
      const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          {this.getStatusAuth(item.we_status).text}
        </Tooltip>
      );

      return (

        <tr key={item.id} className="text-center p-2">
          <td>{item.we_number}</td>
          <td>
            {item?.we_billNumber ? item?.we_billNumber : "-"}
          </td>
          <td>{item?.warehouse_description}</td>
          <td>{convertDateToLatinFormat(item.entry_date)}</td>
          <td>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <div>
                {<div className={this.getStatusAuth(item.we_status).class}></div>}
              </div>

            </OverlayTrigger>
          </td>
          <td>
            <img className="hoverPointer" src={Filtrar} alt="Ver detalle" width={12} onClick={() => this.handleEditRecord(item)} />
          </td>
        </tr>
      );
    }
    );

    return (
      <div className="w-100 mt-3">

        {this.props.loading ? (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        ) : null}
        {!!this.myPermission()?.read ? (
          <form onSubmit={e=>this.handleSearch()} className={`w-100 ${this.props.loading ? "disabled" : ""}`}>
            <div className="pb-5 mx-5 px-5">
              <div className="row">
                <h1 className="top-h1 text-primary">
                  Autorizar entradas a almacén
                </h1>
              </div>
              <div className="row">
                <div className="col-md-16 align-middle ">
                  <div className="row">
                    <div className="">
                      <div
                        className={`${ownStyles.selectGroup} col-2`}
                        style={{ maxWidth: "12rem" }}
                      >
                        <label
                          htmlFor="we_number"
                          className={`${ownStyles.labelFont}`}
                        >
                          No. de entrada
                        </label>
                        <input
                          type="text"
                          name="we_number"
                          className={`register-inputs`}
                          onChange={this.onChangeFilter}
                          onKeyDown={e=>{
                            if (e.key === 'Enter') {
                              this.handleSearch()
                            }
                          }}
                          placeholder={"Escribir..."}
                        />
                      </div>
                      <div
                        className={`${ownStyles.selectGroup} col-2`}
                        style={{ maxWidth: "12rem" }}
                      >
                        <label
                          htmlFor="we_billNumber"
                          className={`${ownStyles.labelFont}`}
                        >
                          No. Factura o remisión
                        </label>
                        <input
                          type="text"
                          name="we_billNumber"
                          className={`register-inputs`}
                          onChange={this.onChangeFilter}
                          onKeyDown={e=>{
                            if (e.key === 'Enter') {
                              this.handleSearch()
                            }   
                          }}
                          placeholder={"Escribir..."}

                        />
                      </div>
                      <div
                        className={`${ownStyles.selectGroup} col-2`}
                        style={{ maxWidth: "12rem" }}
                      >
                        <label
                          htmlFor="we_warehouse_id"
                          className={`${ownStyles.labelFont}`}
                        >
                          Almacén
                        </label>
                        <ReactSelect noOptionsMessage={() => 'No hay datos'}
                        placeholder="Seleccionar..."
                        name="we_warehouse_id"
                        styles={customSelectNewDark}
                        onChange={(e)=>{
                          this.onChangeFilter(e);
                          this.handleSearch()
                        }
                        }
                        options={this.wareHouseOptions()}
                      />
                      </div>
                      <div
                        className={`${ownStyles.selectGroup} col-2`}
                        style={{ maxWidth: "12rem" }}
                      >
                        <label
                          htmlFor="entry_date"
                          className={`${ownStyles.labelFont}`}
                        >
                          Fecha
                        </label>
                        <input
                          type="date"
                          name="entry_date"
                          className={`register-inputs`}
                          onChange={this.onChangeFilter}
                          placeholder={"Escribir..."}
                        />
                      </div>
                      <div
                        className={`${ownStyles.selectGroupWithOutMargin} col-2`}
                        style={{ maxWidth: "12rem" }}
                      >
                        <label
                          htmlFor="we_status"
                          className={`${ownStyles.labelFont}`}
                        >
                          Estado
                        </label>
                        <ReactSelect noOptionsMessage={() => 'No hay datos'}
                        placeholder="Seleccionar..."
                        name="we_status"
                        styles={customSelectNewDark}
                        onChange={(e)=>{
                          this.onChangeFilter(e);
                          this.handleSearch()
                        }
                        }
                        options={this.statusOptions()}
                      />
                      </div>
                      <img
                        src={SearchIcon}
                        alt="buscar"
                        className="icons-svg-top-table float-left hoverPointer"
                        onClick={this.handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <GenericTableNew
                headers={renderHeaders}
                dark={true}
                lowerCase={true}
              >
                {renderWarehouseEntries}
              </GenericTableNew>

              {this.props.row_total > 0 && (
                <div
                  className={`${paginationStyles.wrapper} justify-content-between`}
                >
                  <p style={{ display: "inline" }}>
                    Pag. {this.state.filters.page} de{" "}
                    {Math.ceil(this.props.row_total / this.state.filters.perpage)
                      ? Math.ceil(
                        this.props.row_total / this.state.filters.perpage
                      )
                      : ""}{" "}
                    ({this.props.row_total} encontrados)
                  </p>

                  <Pagination
                    activePage={this.state.filters.page}
                    itemsCountPerPage={10}
                    totalItemsCount={
                      this.props.row_total ? this.props.row_total : 10
                    }
                    pageRangeDisplayed={10}
                    activeClass={paginationStyles.activeClass}
                    onChange={this.handlePageChange.bind(this)}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              )}
              {/* <div className="d-flex juistify-content-start">
            <button className="btn" style={{backgroundColor:"#CECECE", color:"#fff", padding:"0.5rem 1.5rem"}}>Crear cotización</button>
          </div> */}
            </div>
          </form>
        ) : null}

        {/* ANCHOR modal articles */}
        <ModalNew
          onHide={this.handleOnHide}
          show={this.state.modal}
          size="940"
          centered
          title={`Detalle de entrada`}
          extraButtons={
            this.myPermission().approve && 
            this.state.addWarehouseEntry?.we_status === "reqAuth" ?
            [
            {
              type:1,
              name:"Denegar",
              icon: False,
              event: ()=>{this.handleDenied()}
            },
            {
              type:1,
              name:"Autorizar",
              icon: CheckMark,
              event: ()=>{this.handleAuthorization()}
            }
        ] : null}
        >
          {/* ---------------------INPUTS READONLY--------------------- */}
          <div className="d-flex justify-content-around">
            <div className="col p-2">
              <label htmlFor="purchaseOrder" className={ownStyles.labelFont}>
                No. de orden de compra
              </label>
              <input
                type="text"
                className={"register-inputs"}
                readonly
                value={this.state.addWarehouseEntry?.purchaseOrder_number}
              />
            </div>
            <div className="col p-2">
              <label htmlFor="purchaseOrder" className={ownStyles.labelFont}>
                No. de factura o remisión
              </label>
              <input
                type="text"
                className={"register-inputs"}
                readonly
                value={this.state.addWarehouseEntry?.we_billNumber}
              />
            </div>
            <div className="col p-2">
              <label htmlFor="purchaseOrder" className={ownStyles.labelFont}>
                Almacen
              </label>
              <input
                type="text"
                className={"register-inputs"}
                readonly
                value={this.state.addWarehouseEntry?.warehouse_description}
                
              />
            </div>
            <div className="col p-2">
              <label htmlFor="purchaseOrder" className={ownStyles.labelFont}>
                Fecha
              </label>
              <input
                type="date"
                className={"register-inputs"}
                readonly
                value={this.state.addWarehouseEntry?.entry_date}
              />
            </div>
          </div>
          {/* ---------------------END INPUTS--------------------- */}

          <GenericTableNew headers={renderHeadersModal} treeHeadDarkBlue>
            {this.state.addWarehouseEntry?.articles?.map((article, index) => {              
              let addArticle = (!article.min_stock && !article.max_stock && !article.available ) ? true : false;
              return (
                <tr className={addArticle ? genericTableStyles.orange : null}>
                  <td>{index+1}</td>
                  <td className={`text-truncate`} style={{ maxWidth: "220px" }} title={article.description}>{article.description}</td>
                  <td className={`hoverPointer text-primary fw-bold text-decoration-underline`}>
                    {article.lots?.length}
                  </td>
                  <td>{article.qty_ordened ? article.qty_ordened : "-"}</td>
                  <td>{article.qty_received ? article.qty_received : "-"}</td>
                  <td>{article.min_stock ? article.min_stock : "-"}</td>
                  <td>{article.max_stock ? article.max_stock : "-"}</td>
                  <td>{article.available ? article.available : "-"}</td>
                  <td>
                    {!!addArticle && (
                      <img 
                        src={Alert2}
                        alt="No se encuentra en el almacén"
                        className="hoverPointer"
                        width={18}
                        onClick={() => this.setState({ modalLots: true, addArticle:{...this.state.addArticle, id_article:article.id_article} })}
                        />
                        )}
                  </td>
                </tr>
              )
            })}
          </GenericTableNew>
          {this.state.wEntry?.articles?.length > 10 && (
            <div className="d-flex justify-content-end">
              <Pagination
                activePage={this.state.paginationModal.page}
                itemsCountPerPage={this.state.paginationModal.perpage}
                totalItemsCount={
                  this.state.wEntry?.articles?
                  this.state.wEntry?.articles?.length : 10
                }
                pageRangeDisplayed={10}
                activeClass={paginationStyles.activeClass}
                onChange={this.handlePageChangeModalArticle.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          )}
          
          {/* ------------ REQ AUTH ----------- */}
          <div className="d-flex">
            <div className={ownStyles.containerObservations}>
              <div className="col">
                {/* -------------------------------------observations */}
                  <div className="d-flex">
                    <div className="col">
                      <label htmlFor="observations" className={ownStyles.labelFont}>
                        Observaciones
                      </label>
                      <textarea
                        name="observations"
                        readOnly
                        value={this.state.addWarehouseEntry?.observations}
                        className={ownStyles.textareaObservationsAuth}
                      />
                    </div>
                  </div>
                  {/* ------------------------------------- */}
                <div className="d-flex justify-content-between">
                  <div className="col-5">
                    <label htmlFor="we_problem" className={ownStyles.labelFont}>
                      ¿Cuál es el problema?
                    </label>
                    <ReactSelect noOptionsMessage={() => 'No hay datos'}
                    isDisabled
                        placeholder="Seleccionar..."
                        name="we_warehouse_id"
                        value={this.problemOptions().find((x)=> x.value === this.state.addWarehouseEntry?.we_problem)}
                        styles={customSelectNewDark}
                        options={this.problemOptions()}
                      />
                  </div>
                  <div className="col ml-3">
                    <label
                      htmlFor="we_additional_info"
                      className={ownStyles.labelFont}
                    >
                      Información adicional
                    </label>
                    <input
                      name="we_additional_info"
                      type="text"
                      readOnly
                      value={this.state.addWarehouseEntry?.we_additional_info}
                      className={`w-100 register-inputs darkGray`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ------------ END AUTH ----------- */}
        </ModalNew>

        {/* modal lots */}

        {/* ANCHOR modal agregar artículo */}
        <ModalNew
          title={"Agregar artículo"}
          subtitle={"Almacén General"}
          btnYesName="Guardar"
          btnYesEvent={()=>{            
            this.props.saveArticleInWarehouse({...this.state.addArticle, id_warehouse:this.state.addWarehouseEntry.we_warehouse_id},
              ()=>{
                this.props.getWarehouseEntryDetail(this.state.addWarehouseEntry.id)
                this.setState({...this.state, modalLots:false});
              })
            }}
          show={this.state.modalLots}
          size="400"
          onHide={() => this.setState({ ...this.state, modalLots: false, addArticle:{}})}
        >
          <div className="col-12">
            <label htmlFor="purchaseOrder" className={ownStyles.labelFont}>
              Nombre del artículo
            </label>
            <input
              type="text"
              name="description"
              className={`${"register-inputs"} w-100`}
              onChange={(e)=>{this.handleOnChangeArticle(e)}}
              value={this.state.addArticle?.description}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <label className={ownStyles.labelFont} htmlFor="min_stock">
                Stock min
              </label>
              <input
                type="number"
                min={1}
                name="min_stock"
                className={`${"register-inputs"} w-100`}
                value={this.state.addArticle?.min_stock}
                onChange={(event)=>{
                  const value = event.target.value;
                  if (value < 1) return customSwaltAlert({icon:"error", title:"Intenta de nuevo", text:"Stock min inválido", showCancelButton: false})
                  this.setState({
                    ...this.state,
                    addArticle:{
                      ...this.state.addArticle, 
                      min_stock:event.target.value
                    }
                  });
                }}
                onBlur={()=>{
                  let value = this.state.addArticle.min_stock;
                  if (this.state.addArticle.max_stock && parseInt(this.state.addArticle.max_stock) < parseInt(this.state.addArticle.min_stock)) {
                    customSwaltAlert({icon:"error", title:"Intenta de nuevo", text:"Max stock inválido", showCancelButton: false});
                    value = ""
                  }else if (parseInt(this.state.addArticle.min_stock) < 1 ) {
                    customSwaltAlert({icon:"error", title:"Intenta de nuevo", text:"Max stock inválido", showCancelButton: false});
                    value = ""
                  }
                  this.setState({...this.state, addArticle:{...this.state.addArticle, min_stock:value}})
                }}
               />
            </div>
            <div className="col-6">
              <label className={ownStyles.labelFont} htmlFor="max_stock">
                Stock max
              </label>
              <input
                type="number"
                name="max_stock"
                className={`${"register-inputs"} w-100`}
                value={this.state.addArticle?.max_stock}
                onChange={(event)=>{                  
                  const value = event.target.value;
                  this.setState({
                    ...this.state,
                    addArticle:{
                      ...this.state.addArticle, 
                      max_stock:value
                    }
                  });
                }}
                onBlur={()=>{
                  let value = this.state.addArticle.max_stock;
                  if (this.state.addArticle.min_stock && parseInt(this.state.addArticle.max_stock) < parseInt(this.state.addArticle.min_stock)) {
                    customSwaltAlert({icon:"error", title:"Intenta de nuevo", text:"Max stock inválido", showCancelButton: false});
                    value = ""
                  }else if (parseInt(this.state.addArticle.max_stock) < 1 ) {
                    customSwaltAlert({icon:"error", title:"Intenta de nuevo", text:"Max stock inválido", showCancelButton: false});
                    value = ""
                  }
                  this.setState({...this.state, addArticle:{...this.state.addArticle, max_stock:value}})
                }}
               />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label className={ownStyles.labelFont} htmlFor="purchaseOrder_number">
                No. de reorden
              </label>
              <input
                name="purchaseOrder_number"
                type="text"
                className={`${"register-inputs"} w-100`}
                readonly
                value={this.state.addWarehouseEntry?.purchase_order_ref?.purchaseOrder_number}
              />
            </div>
            <div className="col-6">
              <label className={ownStyles.labelFont} htmlFor="available">
                Cant. disponible
              </label>
              <input
                name="available"                
                className={`${"register-inputs"} w-100`}
                value={this.state.addArticle?.available}
                onChange={(event)=>{
                  this.setState({
                    ...this.state,
                    addArticle:{
                      ...this.state.addArticle, 
                      available:event.target.value
                    }
                  });
                }}
                onBlur={()=>{
                  let value = this.state.addArticle.available;
                  if (value < 1) value = "";
                  this.setState({...this.state, addArticle:{...this.state.addArticle, available:value}})
                }}
               />
            </div>
          </div>
          {/* <GenericTableNew headers={[
            <th className="text-center">Vencimiento</th>,
            <th className="text-center">Lote</th>,
            <th className="text-center">Cantidad</th>,
          ]}>
            {this.state.addWarehouseEntry?.purchase_order_ref?.purchaseOrderItems[0].po_scheduling_ref.length > 0 &&
              this.state.addWarehouseEntry?.purchase_order_ref?.purchaseOrderItems[0].po_scheduling_ref[0].lot_ref?.map(lot => {
                return (
                  <tr className="text-center">
                    <td>{convertDateToLatinFormat(lot.lot_date_expiration)}</td>
                    <td>{lot.id}</td>
                    <td>{lot.lot_qty_received}</td>
                  </tr>
                )
              })}
          </GenericTableNew> */}

        </ModalNew>

        {/* end modal lots */}

        <ModalNew
          title="Denegación"
          show={this.state.modalJustification}
          btnYesEvent={()=>{            
            this.props.updateWarehouseEntry(
              {
                id:this.state.addWarehouseEntry.id,
                we_status:"rejected",
                we_justification:this.state.justification                
              }
            , ()=> this.setState({...this.state, modalJustification:false, modal:false})
            )
            } 
          }
          onHide={()=>this.setState({...this.state, modalJustification:false, justification:null})}
        >
          <div className="d-flex">
            <d className="col">
              <label htmlFor="justification" className={ownStyles.labelFont}>Justificación</label>
              <textarea 
                style={{height:"6rem"}}
                name="justification" cols="40"
                rows="10"
                className={ownStyles.textareaObservationsAuth}
                onChange={(e)=>{
                this.setState({...this.state, justification:e.target.value})
                }}>
                
              </textarea>
            </d>
          </div>
        </ModalNew>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
  agreementReducer: state.agreementReducer,
  currentAccount: state.loginReducer.currentAccount,
  loginReducer: state.loginReducer,
  warehouseEntries: state.entryWarehouseReducer.warehouseEntries,
  row_total: state.entryWarehouseReducer.row_total,
  problems: state.entryWarehouseReducer.problems,
  listWarehouses: state.storageReducer.list,
  loading: state.entryWarehouseReducer.loading,
  status: state.entryWarehouseReducer.status,
  wEntry: state.entryWarehouseReducer.wEntry,
});

const mapDispatchToProps = (dispatch) => ({
  getWarehouseEntries: (params) => {
    dispatch(getWarehouseEntries(params))
  },
  storage_get: (params) => {
    dispatch(storage_get(params))
  },
  getWEProblems: (params) => {
    dispatch(getWEProblems(params))
  },
  getAuthStatus: (params) => {
    dispatch(getAuthStatus(params))
  },
  updateWarehouseEntry: (data, doAfter) => {
    dispatch(updateWarehouseEntry(data, doAfter))
  },
  saveArticleInWarehouse: (data, doAfter) => {
    dispatch(saveArticleInWarehouse(data, doAfter))
  },
  getWarehouseEntryDetail: (we_id, doAfter) => {
    dispatch(getWarehouseEntryDetail(we_id, doAfter))
  },
  setLoading: (payload) => {
    dispatch({
      type: "LOADING",
      payload: payload,
    });
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthEntryWarehouse);
