import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from "@material-ui/core/TableRow";
import styles from "./tableScrollStyles.module.scss";
import { convertMoneyFormat } from "../../../helpers/helpers";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 400,
  },
  text:{
    color:"#58595B"
  }
});

function createDataWithCheck({
  checked,
  delivery,
  item,
  description,
  brand,
  unit_price,
  qty_article,
  discount,
  iva,
}) {
  const discount_value = (unit_price * qty_article) * (discount / 100 );
  const iva_value = (unit_price * qty_article - discount_value) * (iva / 100);
  const total = unit_price * qty_article + iva_value - discount_value;

  return {
    checked,
    delivery,
    item,
    description,
    brand,
    qty_article,
    unit_price: convertMoneyFormat(unit_price),
    discount: (
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-around">
          <span>{`${discount}%`}</span>
          <span className="text-wrap text-break">{convertMoneyFormat(discount_value)}</span>
        </div>
      </div>
    ),
    iva: (
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-around">
          <span>{`${iva}%`}</span>
          <span>{convertMoneyFormat(iva_value)}</span>
        </div>
      </div>
    ),
    total: convertMoneyFormat(total),
  };
}

function createData({
  item,
  description,
  brand,
  unit_price,
  qty_article,
  discount,
  iva,
}) {
  const discount_value = (unit_price * qty_article * discount) / 100;
  const iva_value = unit_price * qty_article * (iva / 100);
  const total = unit_price * qty_article + iva_value - discount_value;

  return {
    item,
    description,
    brand,
    qty_article,
    unit_price: convertMoneyFormat(unit_price),
    discount: (
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-around">
          <span>{`${discount}%`}</span>
          <span className="text-wrap text-break">{convertMoneyFormat(discount_value)}</span>
        </div>
      </div>
    ),
    iva: (
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-around">
          <span>{`${iva}%`}</span>
          <span>{convertMoneyFormat(iva_value)}</span>
        </div>
      </div>
    ),
    total: convertMoneyFormat(total),
  };
}

export default function TableScrollNew(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // example column
  // {
  //   id: 'population',
  //   label: 'Population',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toLocaleString('en-US'),
  // }
  const columns = props.columns;

  const rows = [];

  if (!!props.makeDataWithCheck){
    props.rows?.map((row) => {
      return rows.push(createDataWithCheck(row));
    });    
  }else{
    props.rows?.map((row) => {
      return rows.push(createData(row));
    });

  }


  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={column.className ? column.className : styles.headers}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <TableRow className={styles.stickyValues1}>
            <TableCell rowSpan={4} colSpan={props.columns ? props.columns.length-2 : 5} className={styles.colWhite} style={{padding:5}}>
              <div className="d-flex flex-column px-2">
                <label htmlFor={"observations"} className={styles.labelFont}>
                  Observaciones
                </label>
                <textarea
                  name="observations"
                  disabled={props?.textAreaDisabled}
                  className={styles.textareaStyle}
                  value={props?.valueTextarea}
                  onChange={props?.onChangeTextArea}
                  style={{flex:1}}
                />
              </div>
            </TableCell>
            <TableCell align="left" colSpan={0} className={classes.text} style={{padding:3}}>
              Subtotal
            </TableCell>
            <TableCell align="center" className={classes.text} style={{padding:3}}>
              {props?.subtotal ? convertMoneyFormat(props?.subtotal) : "-"}
            </TableCell>
          </TableRow>
          <TableRow className={styles.stickyValues2}>
            <TableCell align="left" className={classes.text} style={{padding:3}}>Descuento</TableCell>
            <TableCell align="center" className={classes.text} style={{padding:3}}>
              {props.total_discount
                ? convertMoneyFormat(props?.total_discount)
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow className={styles.stickyValues3}>
            <TableCell align="left" className={classes.text} style={{padding:3}}>IVA</TableCell>
            <TableCell align="center" className={classes.text} style={{padding:3}}>
              {props.total_iva ? convertMoneyFormat(props?.total_iva) : "-"}
            </TableCell>
          </TableRow>
          <TableRow className={styles.stickyValues4}>
            <TableCell align="left" colSpan={0} className={classes.text} style={{padding:3}}>
              Total
            </TableCell>
            <TableCell align="center" className={classes.text} style={{padding:3}}>
              {props.total ? convertMoneyFormat(props.total) : "-"}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
