import React, { Component } from "react";
import GenericTableNew from "../Layouts/GenericTableNew";
import Pagination from "react-js-pagination";
import { getMu } from "../../actions/measurementUnitsActions";
import { activationDeactivation } from "../../actions/measurementUnitsActions";
import { createMu } from "../../actions/measurementUnitsActions";
import Search from "../../assets/img/icons/lupa.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import * as deepcopy from "deepcopy";
import { updateMu } from "../../actions/measurementUnitsActions";
import ModalNew from "../Layouts/ModalNew";
import { connect } from "react-redux";
import popupStyles from "../Layouts/popupEdEdit.module.css";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import CustomPopup from "../Popup/customPopup";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

class TableMeasures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: "Crear Unidades de medida",
      filters: {
        page: 1,
        search: "",
        id_account: this.props.currentAccount.id
      },

      measurements: {
        description: "",
        prefix: "",
        observations: "",
        active: true,
        id_account: this.props.currentAccount.id
      },
      // headerState: ["Prefijo", "Descripción",  "Est.", ""],
      headerState: [
        {
          tittle: "Prefijo",
          className: "text-start px-3",
        },
        {
          tittle: "Observaciones",
          className: "text-center",
        },
        {
          tittle: "Estado",
          className: "text-center w100 mx-4",
        },
      ],
      editRecord: false,
      modal: false,
    };
  }

  myPermission = () => this.props.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "UnMed");

  componentDidMount() {
    const { history } = this.props
    if (!this.myPermission()?.read) {
      history?.push("/inicio");
    }

    this.props.getMu(this.state.filters);
    this.getInitialState();
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });

    this.props.getMu(this.state.filters);
  }

  handleChange(event) {


    let { filters } = this.state;
    filters.search = event.target.value;
    this.setState({ filters });
  }

  handleClick() {
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters })
    this.props.getMu(this.state.filters);
  }

  toggleModal = (e) => {
    this.getInitialState();
    this.setState({
      modal: !this.state.modal,
    });
  };

  getInitialState = () => {
    this.setState({
      titleModal: "Crear Unidades de medida",
      filters: {
        page: 1,
        search: "",
        id_account: this.props.currentAccount.id
      },

      measurements: {
        description: "",
        prefix: "",
        observations: "",
        active: true,
        id_account: this.props.currentAccount.id
      },
      headerState: [
        {
          tittle: "Prefijo",
          className: "text-start px-3",
        },
        {
          tittle: "Descripción",
          className: "text-center",
        },
        {
          tittle: "Observaciones",
          className: "text-center",
        },
        {
          tittle: "Estado",
          className: "text-center w100 mx-4",
        },
      ],
      editRecord: false,
      modal: false,
    });
  };
  onChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { measurements } = this.state;
    measurements[name] = value;
    this.setState({ measurements });
  };

  handleEditRecord = (medidas) => {
    let { modal, measurements, titleModal } = this.state;

    modal = !this.state.modal;

    measurements = deepcopy(medidas);

    titleModal = "Editar Unidad de Medida";
    this.setState({ modal, measurements, editRecord: true, titleModal });
  };

  handleSubmit() {
    if (this.state.editRecord) {
      this.setState({
        modal: !this.state.modal,
      });

      return this.props.updateMu(this.state.measurements, this.state.filters);
    }

    this.props.createMu(this.state.measurements, this.state.filters);
    this.setState({
      modal: !this.state.modal,
    });
  }

  renderMeasures(measures) {
    return (
      <tr
        key={measures.id}
        className="hover-table-row text-center align-middle"
      >
        <td className="text-start col-4 px-3"> {measures.prefix} </td>
        <td className="text-center col-3">{measures.description}</td>
        <td className="text-center col-3">{!!measures.observations ? measures.observations : ` - `}</td>
        <td className="col-2">
          <div className={tableStyles.groupElems}>
            {/* <div className={measures.active ? "circle" : "circle-red"}></div> */}
            <div className={`${measures.active ? tableStyles.greenState : tableStyles.redState} mx-4`}>
              {measures.active ? 'Habilitado' : 'Inhabilitado'}
            </div>
            {(this.myPermission()?.edit ) &&
              <CustomPopup
                triggerSrc={threeDots}
                showEdit={this.myPermission()?.edit}
                editClickEvent={() => this.handleEditRecord(measures)}
                showEnable={this.myPermission()?.edit}
                isEnabled={measures.active}
                enableClickEvent={() =>
                  this.props.activationDeactivation(
                    measures.id,
                    measures.active,
                    this.state.filters,
                    measures.description
                  )}
              />
            }

            {/* <Popup
            trigger={
              <button>
                {" "}
                <img src={Gear} alt="configuracion"  className={tableStyles.iconsGear} />
              </button>
            }
            position="right center"
          >
            <ul className="popUp-ul">
              <li
                className="hoverPointer"
                onClick={() => this.handleEditRecord(measures)}
              >
                <span>
                  <img src={Edit} alt="configuracion" className="icons-popUp" />
                  Editar
                </span>
              </li>
              <li
                onClick={() =>
                  this.props.activationDeactivation(
                    measures.id,
                    measures.active,
                    this.state.filters,
                    measures.description
                  )
                }
                className="hoverPointer"
              >
                <div
                  className={
                    measures.active ? "circle-red-icon" : "circle-icon"
                  }
                >
                  <p className="link-icon">
                    {measures.active ? "Deshabilitar" : "Habilitar"}
                  </p>
                </div>
              </li>
            </ul>
          </Popup> */}

          </div>
        </td>
      </tr>
    );
  }
  render() {
    let listMeasurements;
    const handleSumit = (e) => {
      e.preventDefault();
      let { filters } = this.state;
      filters.page = 1;
      this.setState({ filters })
      this.props.getMu(this.state.filters);
    }
    if (this.props.measurements != undefined) {
      const measure = this.props.measurements;
      listMeasurements = measure.map((medidas) => {
        return this.renderMeasures(medidas);
      });
    }
    let renderHeaders = this.state.headerState.map((header) => {
      return (
        <th>
          <div className={`${header === 'est.' || header === '' ? tableStyles.groupElems : ''} ${header.className}`}>
            {header.tittle}
          </div>
        </th>
      )
    });
    return (
      <div className={tableStyles.container}>
        {(this.props.loading) &&
          <div className="loading">
            <Loader
              type="Oval"
              color="#003f80"
              height={100}
              width={100}
            />
          </div>
        }
        {(this.props.measurementUnitsReducer?.loading) &&
          <div className="loading">
            <Loader
              type="Oval"
              color="#003f80"
              height={100}
              width={100}
            />
          </div>
        }
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Unidades de Medida</h1>
          <div className={tableStyles.mainRow}>
            {
              this.myPermission()?.read && (
                <form className="mt-3" onSubmit={handleSumit}>
                  <input
                    className={tableStyles.searchUsersAlt}
                    type="text"
                    placeholder="Buscar..."
                    onChange={(e) => this.handleChange(e)}
                  />

                  <img
                    src={Search}
                    alt="Search icon"
                    className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                    onClick={(e) => this.handleClick(e)}
                  />
                </form>
              )
            }
            <div className={tableStyles.groupElems}>
              {/* <img src={Export} alt="exportar" className={tableStyles.iconSvg} /> */}
              {this.myPermission()?.create &&
                <div
                  className="col d-flex justify-content-end groupAddButton align-items-center"
                  onClick={() => this.toggleModal(true)}
                >
                  <label htmlFor="newAccident" className="darkGray fw-bold">
                    Crear unidad de medida
                  </label>
                  <button
                    className="addCheckButton mx-2"
                  />
                </div>
              } 
            </div>

          </div>


          <ModalNew
            hideFooter={this.state.editRecord ? !this.myPermission()?.edit : !this.myPermission()?.create }
            title={this.state.titleModal}
            show={this.state.modal}
            btnYesEvent={() => this.handleSubmit()}
            onHide={() => this.toggleModal(false)}
          >
            <div className="row mb-2">
              <div className="col-md-12">
                <p className="label-inputs text-secondary">
                  Nombre
                  <span className="text-danger">*</span>
                </p>
                <input
                  className="register-inputs"
                  name="description"
                  type="text"
                  placeholder="Descripción Unidad de medida"
                  onChange={this.onChangeForm}
                  value={this.state.measurements.description}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-12">
                <p className="label-inputs text-secondary">
                  Prefijo
                  <span className="text-danger">*</span>
                </p>
                <input
                  className="register-inputs"
                  name="prefix"
                  type="text"
                  placeholder="Prefijo Unidad de medida"
                  onChange={this.onChangeForm}
                  value={this.state.measurements.prefix}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <p className="label-inputs text-secondary">Observaciones</p>
                <textarea
                  maxlength="100"
                  className="register-inputs"
                  style={{ height: '3rem' }}
                  name="observations"
                  type="text"
                  placeholder="Comentarios"
                  onChange={this.onChangeForm}
                  value={this.state.measurements.observations}
                  rows="4"
                  cols="50">

                </textarea>

              </div>
            </div>
          </ModalNew>

          {
            this.myPermission()?.read && (
              <GenericTableNew headers={renderHeaders} dark={true}>
                {listMeasurements}
              </GenericTableNew>
            )
          }

          {
            this.myPermission()?.read && (
              <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                  Pag. {this.props.totalMeasures ? this.state.filters.page : ""}
                  {" de "}
                  {Math.ceil(this.props.totalMeasures / this.state.filters.perpage) ?
                    Math.ceil(this.props.totalMeasures / this.state.filters.perpage)
                    :
                    ""
                  }
                  {" "}
                  ({this.props.totalMeasures} encontrados)
                </p>
                <Pagination
                  activePage={this.state.filters.page}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.totalMeasures}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            )
          }


        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
  currentAccount: state.loginReducer.currentAccount,
  measurements: state.measurementUnitsReducer.measurements,
  totalMeasures: state.measurementUnitsReducer.totalMeasures,
  loading: state.measurementUnitsReducer.measurement_loading
});

const mapDispatchToProps = (dispatch) => ({
  getMu: (filters) => {
    dispatch(getMu(filters));
  },

  activationDeactivation: (id, status, filters, description) => {
    dispatch(activationDeactivation(id, status, filters, description));
  },
  updateMu: (obj, filters) => {
    dispatch(updateMu(obj, filters));
  },
  createMu: (obj, filters) => {
    dispatch(createMu(obj, filters));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableMeasures);
