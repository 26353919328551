import React, { Component } from "react";
import Filter from "../../assets/img/icons/filtro.svg";
import Lupa from "../../assets/img/icons/lupa.svg";
import Add from "../../assets/img/icons/agregar.svg";
import Export from "../../assets/img/icons/exportar.svg";
import GearGray from "../../assets/img/icons/engraneGris.svg";
import EditIcon from "../../assets/img/icons/editar.svg";
import DeactiveIcon from "../../assets/img/icons/puntoRojo.svg";
import ActiveIcon from "../../assets/img/icons/puntoVerde.svg";
import { connect } from "react-redux";
import GenericTable from "../Layouts/GenericTable";
import {
  getCieten,
  changeStatus,
} from "../../actions/cietenActions";
// import Popup from "reactjs-popup";
// import "reactjs-popup/dist/index.css";
import CustomPopup from "../Popup/customPopup";
import Pagination from "react-js-pagination";
import popupStyles from "../Layouts/popupEdEdit.module.css";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import  style  from "./tableCieten.module.scss";
import { defaultFilters } from "../../helpers/constants";
class TableCieten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCieten: {
        id: "",
        description: "",
        active: "",
      },
      filters: {
        search: "",
        perpage: 10,
        page: this.props.page,
        enabled:'',
      },
      headerState: ["Código", "Descripción", "est."],
    };
    this.timer = null;
  }

  componentDidMount() {
    this.props.getCieten(this.state.filters);
    //this.getInitialState();
  }
  
  componentDidUpdate(prevProps, prevState){
    if(prevProps.page !== this.props.page){
      let {filters} = this.state
      filters.page = this.props.page
      this.setState({ filters })
    }

    if(prevState.filters !== this.state.filters ) {
      if(prevState.filters.search !== this.state.filters.search ) {
        this.handleCheck();
      }else{
        this.props.getCieten(this.state.filters);
      }
    }

  }

  // getInitialState = () => {
  //   this.setState({
  //     addCieten: {
  //       id: "",
  //       description: "",
  //       active: "",
  //     },
  //     filters: {
  //       search: "",
  //       perpage: 10,
  //       page: this.props.page,
  //     },
  //     headerState: ["Código", "Descripción", "est."],
  //   });
  // };
  handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      //this.toggleCheck();
      this.props.getCieten(this.state.filters);
    }, 500);
  }

  handlePageChange(pageNumber) {
    // let { filters } = this.state;
    // filters.page = pageNumber;
    // this.setState({ filters });
    this.setState({ filters: {...this.state.filters, page: pageNumber} });
    //this.props.getCieten(this.state.filters);
  }

  handleEnabledChange(val){
    //HEY LISTEN: si cambias el estado asi, no puedes compararlo con prevstate por que apuntan al mismo objeto que fue editado
    // let { filters } = this.state;
    // filters.enabled = val;
    // this.setState({ filters });

    //si lo cambias asi estas creando un nuevo objeto y luego re-asignandolo. asi es mas facil comparar con prevstate
    //att: ed
    this.setState({ filters: {...this.state.filters, enabled: val} });
  }

  handleSearchChange(val){
    // let { filters } = this.state;
    // filters.search = val;
    // this.setState({ filters });
    this.setState({ filters: {...this.state.filters, search: val} });
  }

  toggleStatus = (cieten) => {
    this.props.changeStatus(cieten, this.state.filters);
  };

  handleSearch(string) {
    let { filters } = this.state;
    filters.search = string;
    this.setState({ filters });
  }

  onKeyUpSearch = (e) => {
    const value = e.target.value;
    let { filters } = this.state;
    let timeout
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      filters.search = value;
      filters.page = 1
      this.setState({ filters })
      this.props.getCieten(filters);
      clearTimeout(timeout)
    }, 1000);
  };
  // onKeyUpSearch = (e) => {
  //   const value = e.target.value;
  //   if(value !== ""){
  //     if(e.keyCode === 13){
  //       let { filters } = this.state;
  //       filters.search = value;
  //       filters.page = 1
  //       this.setState({ filters })
  //       this.props.getCieten(filters);
  //     }else{
  //       e.preventDefault();
  //     }
  //   }else{
  //     this.props.getCieten(defaultFilters);
  //   }
  // };

  handleClick = (e) => {
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters })
    this.props.getCieten(filters);

  }

  onChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let { addCieten } = this.state;
    addCieten[name] = value;
    this.setState({ addCieten });
  };

  renderCieten(cieten) {
    return (
      <tr key={cieten.id}>
        <td>{cieten.id} </td>
        <td>{cieten.description}</td>
        <td>
          <div className={tableStyles.groupElems}>
            {/* <div className={cieten.active ? "circle" : "circle-red"}></div> */}
          <div className={cieten.active ? tableStyles.circleGreen : tableStyles.circleRed}></div>
            
            <CustomPopup
              showEdit = {false}

              showEnable = {true}
              isEnabled = {cieten.active}
              enableClickEvent = {() => this.toggleStatus(cieten)}
            />
          </div>
        </td>

      </tr>
    );
  }

  render() {
    let renderHeaders = this.state.headerState.map((header) => {
      return <th><div className={header === 'est.' || header === '' ? tableStyles.groupElems: ''}>{header}</div></th>;
    });
    const renderCieten = this.props.cieten
      ? this.props.cieten.map((item) => {
          return this.renderCieten(item);
        })
      : "";
    return (
      <div className={tableStyles.container}>
        <h1 className={tableStyles.title}>Impresiones Diagnosticas CIE-10</h1>
        <div className={tableStyles.tableArea}>
          <div className={tableStyles.mainRow}>
            <div>
              <input
                className={tableStyles.searchUsersAlt}
                type="text"
                placeholder="Buscar"
                //onKeyUp={this.onKeyUpSearch}
                onChange={e=>this.handleSearch(e.target.value)}
              />
              <img src={Lupa} 
              alt="filtrar" 
              className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
              onClick={e => this.handleClick(e)}
              />
            </div>

            <div className={tableStyles.groupElems}>
              <img
                src={Export}
                alt="exportar"
                className={tableStyles.iconSvg}
              />
              <img
                src={Add}
                alt="añadir"
                className={tableStyles.iconSvg}
                onClick={this.toggleModal}
              />
            </div>

          </div>

          <GenericTable headers={renderHeaders}>{renderCieten}</GenericTable>
         
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {this.props.totalCieten ? this.state.filters.page : ""} 
              {" de "}
                {Math.ceil(this.props.totalCieten / this.state.filters.perpage) ? 
                  Math.ceil(this.props.totalCieten / this.state.filters.perpage)
                : 
                  "" 
                }
                {" "}
              ({this.props.totalCieten} encontrados)
            </p>
            <Pagination
              activePage={this.state.filters.page}
              itemsCountPerPage={10}
              totalItemsCount={this.props.totalCieten}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext }
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
  cieten: state.cietenReducer.cieten,
  totalCieten: state.cietenReducer.totalCieten,
  page : state.cietenReducer.page
});

const mapDispatchToProps = (dispatch) => ({
  getCieten: async (filters) => {
    await dispatch(getCieten(filters));
  },
  changeStatus: (objCieten, filters) => {
    dispatch(changeStatus(objCieten, filters));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableCieten);
