import React, { Component, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Lupa from "../../assets/img/icons/lupa.svg";
import Agregar from "../../assets/img/icons/aggdoc.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Atras from "../../assets/img/icons/atras-icon.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import ModalNew from "../../components/Layouts/ModalNew";
import myStyles from "./InvoiceBilling.module.scss";
import {convertDateToLatinFormat, monthArray} from "../../helpers/convertDateFormat";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import {
    getReceiptRecordOne,
 } from "../../actions/billingActions";

 var formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
});

function BillingCertificateDetails(props) {
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const [showModal, setShowModal] = useState(false);

    let history = useHistory();
    const today = new Date();

    function handleClick() {
        if(!!props.goBack){
            props.goBack();
        }else{
            history.push("/cartera/Factura/RecepcionDeFacturas");
        }
    }

    useEffect(()=>{
        if(props.detail?.id){
            dispatch( 
                getReceiptRecordOne({
                    id: props.detail.id,
                    eaccount: counter.loginReducer.currentAccount.id,
                })
            );
        }
    }, [props.detail?.show]);

    const header = [

        <th className="text-center">No. Factura  </th>,
        <th className="text-center">Fecha factura</th>,
        <th className="text-center">Empresa</th>,
        <th className="text-center">Valor</th>
    ]
    const body = [
        <tr>
            <td className="text-center">BC0115456</td>
            <td className="text-center">01/06/2018</td>
            <td className="text-center">Sanitas S.A</td>
            <td className="text-center">$ 500.000</td>
        </tr>

    ]

    const renderList = () => {
        let tempList = [];
        if (Array.isArray(counter.billingReducer.receipt_chosen?.invoices)) {
            counter.billingReducer.receipt_chosen?.invoices.map((item, index)=>{
                tempList.push(
                    <tr key={index}>
                        <td className="text-center">{item.invoice}</td>
                        <td className="text-center">{convertDateToLatinFormat(item.date)}</td>
                        <td className="text-center">{item.company}</td>
                        <td className="text-center">{item.amount? formatter.format(item.amount): "-"}</td>
                    </tr>
                );
            })
        }
        return tempList;
    }

    return (
        <>
            <div className={` ml-5 mr-3 ${tableStyles.container}`} >
                <div className="d-flex">
                    <img 
                        className={`${tableStyles.title} mr-3 ${tableStyles.cursorPointer}`} 
                        src={Atras} 
                        onClick={handleClick} 
                    />
                    <h1 className={tableStyles.title}>Acta de facturación</h1>
                </div>

                <div className={`d-flex`} style={{justifyContent: "space-between"}}>
                    <p className={`text-start`} style={{color: props.detail?.status_fontcolor}}> { props.detail?.status=="Enviada"? "Enviado por " + props.detail?.generatorFullName : props.detail?.status=="Recibido"? !!(props.detail?.receiver_name)? "Recibido por "+  props.detail?.receiver_name : "Recibido por -" : "Actualizada por " + props.detail?.generatorFullName}</p>
                    <p className={`${myStyles.codeText} text-end`}>{props.detail?.sequence}</p>
                </div>

                <Row className="d-flex">
                    <Col>
                        <p>
                            Por medio del presente documento se da constancia del envío de las
                            facturas anexadas a departamento de cartera,
                            el día {today.getDate()}, del mes {monthArray[today.getMonth()]} del año {today.getFullYear()}.
                        </p>
                    </Col>
                </Row>

                <GenericTable
                    className="mb-6"
                    headers={header}
                    children={renderList()}
                    dark={true}
                >
                </GenericTable>
                {(counter.billingReducer.receipt_chosen_loading) &&
                    <div className="mx-auto">
                        <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                        />
                    </div>
                }

                <div className="d-flex">
                    <p className={`${myStyles.greyText}`} style={{flex:3}}>
                        {props.detail?.generatorFullName} <br />
                        Asistente de facturación <br />
                        C.C: {props.detail?.generatorDocNum}
                    </p>

                    <div className="d-flex flex-column" style={{flex:9, marginLeft: 20}}>
                        <label className={`${tableStyles.crudModalLabel} m-0`}>Descripción</label>
                        <textarea 
                        name="textarea" 
                        className={myStyles.detailZone}
                        rows="3" 
                        cols="50"
                        value={props.detail?.summary}
                        disabled
                        ></textarea>
                    </div>

                </div>
            </div>

        </>
    )

};
export default BillingCertificateDetails;