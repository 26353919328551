import { API_VERSION, defaultFilters, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import {
  CREATE_ARTICLE,
  GET_LIST_MANUFACTURERS_TO_SELECT,
  GET_LIST_INVENTORY_FAMILIES_TO_SELECT,
  GET_ALL_ARTICLES,
  SET_INDEX_ARTICLES,
  SET_TOTAL_INDEX_ARTICLES,
  SUCCESS_UPLOAD,
  LOADING,
  OPTIONS_IVA
} from "./actionTypes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

//Fields for messages errors
const requiredFields = {
  description: "descripción",
  inventory_family: "familia de inventario",
  barcode: "número de cuenta",
  reference: "referencia",
  cost_price: "precio de costo",
  average_cost: "costo promedio"
};

const MySwal = withReactContent(Swal);

// ANCHOR GET ALL ARTICLES
export const getArticles = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  const id_account = getState().loginReducer.currentAccount.id
  dispatch({
    type:LOADING,
    payload:true
  })
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/article?id_account=${id_account}&${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      var { results, row_total, total_index } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      if (results)
        results = results.sort((a, b) =>
          new Date(a.create_at) > new Date(b.create_at) ? -1 : 1
        );
      dispatch({
        type: GET_ALL_ARTICLES,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_ARTICLES,
        payload: row_total,
      });
      dispatch({
        type: SET_TOTAL_INDEX_ARTICLES,
        payload: total_index,
      });
      dispatch({
        type:LOADING,
        payload:false
      })
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type:LOADING,
        payload:false
      })
      
      customSwaltAlert({
        icon: "error",
        title: "Error al traer los artículos",
        text: "Ocurrió un error al intentar traer la información de los artículos.",
        footer: "Si el problema persiste comunícate con un asesor.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    });
};

// ANCHOR GET LIST OF ACTIVE INVENTORY FAMILIES
export const getActiveInventoryFamilies = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization
  const id_account = getState().loginReducer.currentAccount.id
  dispatch({
    type:LOADING,
    payload:true
  })
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory_Family/active?id_account=${id_account}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const {results} = res
      dispatch({
        type: GET_LIST_INVENTORY_FAMILIES_TO_SELECT,
        payload: results
      })
      dispatch({
        type:LOADING,
        payload:false
      })
    })
    .catch((error)=>{
      dispatch({
        type:LOADING,
        payload:false
      })
      console.error(error);
      
      customSwaltAlert({
        icon:'error',
        title:"Ocurrió un error al traer la lista de familias de inventario",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    })
}

// ANCHOR GET MANUFACTURERS OF ARTICLES
export const getManufacturers = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization
  dispatch({
    type:LOADING,
    payload:true
  })
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/article/manufacturers/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const {results} = res
      dispatch({
        type: GET_LIST_MANUFACTURERS_TO_SELECT,
        payload: results
      })
      dispatch({
        type:LOADING,
        payload:false
      })
    })
    .catch((err) => {
      console.error(err)

      customSwaltAlert({
        icon:'error',
        title:"Ocurrió un error al traer la lista de laboratorios",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })

      customSwaltAlert({
        icon:'error',
        title:"Ocurrió un error al traer la lista de laboratorios",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })

      dispatch({
        type:LOADING,
        payload:false
      })
    })
}

// ANCHOR CREATE ARTICLE
export const createArticle = (objArticle, filters) => async (dispatch, getState) => {
  let keyError = "";
  let success = "";
  const id_account = getState().loginReducer.currentAccount.id
  for (const prop in objArticle) {
    if (Object.hasOwnProperty.call(objArticle, prop)) {
      const element = objArticle[prop];
      if (requiredFields[prop])
        if (element === "" || element === null) keyError = requiredFields[prop];
    }
  }
  const created_at = Date.now();
  const updated_at = Date.now();
  objArticle["create_at"] = created_at;
  objArticle["updated_at"] = updated_at;

  objArticle.active = true;

  if (!objArticle.handle_batch) {
    objArticle.handle_batch = false;
  }

  const token = getState().loginReducer.Authorization
  if (!objArticle.consignment && !objArticle.property_) {
    customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe escoger si es propiedad de la empresa o es consignación',
      confirmButtonText: 'Aceptar',
      showCancelButton: false
    })
    success = "failed";

  } else {
    if (objArticle.property_) {
      objArticle.property_ = true
    }
    if (objArticle.consignment) {
      objArticle.consignment = true
    }
  }

  if (keyError !== "") {
    dispatch({
      type: CREATE_ARTICLE,
      payload: false,
    });
    customSwaltAlert({
      icon: "warning",
      title: "Intenta de nuevo",
      text: "Debe llenar todos los campos para agregar un artículo",
      confirmButtonText: 'Aceptar',
      showCancelButton: false
    })
    success = "failed";

  } else {
    for (const prop in objArticle) {
      if (Object.hasOwnProperty.call(objArticle, prop)) {
        const element = objArticle[prop];
        if (element === "" || element === null) delete objArticle[prop];
      }
    }
    dispatch({
      type: LOADING,
      payload: true
    })
    objArticle.id_account = id_account;
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/article`, {
      method: "POST",
      body: JSON.stringify(objArticle),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {

          customSwaltAlert({
            icon: 'success',
            title: `Creado exitosamente`,
            text:`Se ha creado el articulo: ${objArticle.description}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false
          })

          setTimeout(() => {
            dispatch(getArticles(filters))
          }, 100);
          dispatch({
            type: LOADING,
            payload: false
          })
          success = "ok";
        } else {
          dispatch({
            type: LOADING,
            payload: false
          })

          customSwaltAlert({
            icon: 'warning',
            title: `Intenta de nuevo`,
            text: `${res.message}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false
          })
        success = "failed";
        }
      })
      .catch((err) => {
        dispatch({
          type: LOADING,
          payload: false
        })
        console.error(err);

        customSwaltAlert({
          icon: 'warning',
          title: `Intenta de nuevo`,
          text: `Ocurrió un error al guardar el artículo`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false
        })
        success = "failed";
      })
  }
  return success;
}
// ANCHOR CHANGE STATUS
export const changeStatus = (objArticle, filters) => (dispatch, getState) => {
  const updated_at = Date.now();
  objArticle["updated_at"] = updated_at;
  const token = getState().loginReducer.Authorization;
  let status = objArticle.active ? "deshabilitar" : "habilitar";

  customSwaltAlert({
    title: `¿Está seguro?`,
    text: `Se va ${status} el artículo ${objArticle.description}`,
    icon: "warning",
    showCancelButton: true
  })
  .then((result) => {
    if (result.isConfirmed) {
      dispatch({
        type:LOADING,
        payload:true
      })
      fetch(`${URL_GATEWAY}${API_VERSION}/inventory/article/status/${objArticle.id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            setTimeout(() => {

              customSwaltAlert({
                icon: "success",
                title: `Actualizado exitosamente`,
                text: `Se ha ${objArticle.active ? 'deshabilitado' : 'habilitado'} el artículo: ${objArticle.description}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })

              dispatch(getArticles(filters));
              dispatch({
                type:LOADING,
                payload:false
              })
            }, 1500);
          } else {
            dispatch({
              type:LOADING,
              payload:false
            })

            customSwaltAlert({
              icon: "warning",
              title: `Intenta de nuevo`,
              text: `${res.message}`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })

          }
        })
        .catch((err) => {
          dispatch({
            type:LOADING,
            payload:false
          })
          console.error(err);

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: "No se pudo cambiar el estado",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        });
    }
  });
};

//ANCHOR ADD DATA SHEET TO ARTICLE
export const addDataSheet = (objArticle, filters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization
  if (!objArticle.id) {

    customSwaltAlert({
      icon: "error",
      title: "Error al guardar",
      text: "Si el problema persiste comuníquese con un asesor.",
      footer: "Si el problema persiste comuníquese con un asesor.",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })

  }
  if (!objArticle.data_sheet) {

    customSwaltAlert({
      icon: "error",
      title: "Error al guardar",
      text: "No se encontró ningún archivo para guardar.",
      footer: "No se encontró ningún archivo para guardar.",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })

  }
  var data = new FormData()
  data.append('file', objArticle.data_sheet[0])
  data.append('article_id', objArticle.id)
  dispatch({
    type:LOADING,
    payload:true
  })  
  fetch(`${URL_GATEWAY}${API_VERSION}/files/article/datasheet`, {
    method: 'POST',
    body: data,
    headers: {
      Authorization: token,
    }
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {

        customSwaltAlert({
          icon: 'success',
          title: res.message,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

        dispatch({
          type: SUCCESS_UPLOAD,
          payload: true
        })
        dispatch(getArticles(filters))
        dispatch({
          type:LOADING,
          payload:false
        })
      } else {
        dispatch({
          type: SUCCESS_UPLOAD,
          payload: false
        })
        dispatch({
          type:LOADING,
          payload:false
        })

        customSwaltAlert({
          icon: 'error',
          title: res.message,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      }
    })
    .catch((error) => {
      dispatch({
        type:LOADING,
        payload:false
      })
      dispatch({
        type: SUCCESS_UPLOAD,
        payload: false
      })

      customSwaltAlert({
        icon: "error",
        title: "Error al guardar",
        text: "No se logró guardar el archivo",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    })
}

//ANCHOR DELETE DATA SHEET TO ARTICLE
export const deleteDataSheet = (objArticle,filters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization
  if (!objArticle.id) {

    customSwaltAlert({
      icon: "error",
      title: "Error al eliminar",
      text: "No se logró eliminar el archivo",
      footer: "Si el problema persiste comuníquese con un asesor.",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  }
  let longName = objArticle.data_sheet    
  let filename = ""
  let count = 0, index=0;
  let length = longName.length
  for (let i = 0; i < longName.length; i++) {
    const character = longName[i];
    if (count <= 3) {
      if (character === "/") {
        count++
        index=i+1;        
      }
    }
  }
  longName = String(longName).substr(index,length)
  for (let j = 0; j < longName.length; j++) {
    const c = longName[j];
    if (c === "?") {
      filename = longName.substr(0, j)
    }
  }
  filename = filename === "" ? longName : filename
  dispatch({
    type:LOADING,
    payload:true
  })  
  fetch(`${URL_GATEWAY}${API_VERSION}/files/article/datasheet?filename=${filename}&article_id=${objArticle.id}`,{
    method:'DELETE',        
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    }
  })
  .then((response) => response.json())
  .then((res) => {    
    if (res.success) {
     
      customSwaltAlert({
        icon:'success',
        title:res.message,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })

      dispatch({
        type:SUCCESS_UPLOAD,
        payload:true
      })
      dispatch({
        type:LOADING,
        payload:false
      })
      dispatch(getArticles(filters))
    }else{
      dispatch({
        type:SUCCESS_UPLOAD,
        payload:false
      })
      dispatch({
        type:LOADING,
        payload:false
      })

      customSwaltAlert({
        icon:'error',
        title:res.message,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    }
  })
  .catch((error)=>{
    console.error(error)
    dispatch({
      type:LOADING,
      payload:false
    })
    dispatch({
      type:SUCCESS_UPLOAD,
      payload:false
    })

    customSwaltAlert({
      icon: "error",
      title: "Error al eliminar",
      text: "No se logró eliminar el archivo",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  })
}
// UPDATE ARTICLE
export const updateArticle = (objArticle, doAfter) => (dispatch, getState) => {
  
  let keyError = "";
  for (const prop in objArticle) {
    if (Object.hasOwnProperty.call(objArticle, prop)) {
      const element = objArticle[prop];
      if (requiredFields[prop])
      if (element === "" || element === null) keyError = requiredFields[prop];
    }
  }
  const updated_at = Date.now();
  objArticle["updated_at"] = updated_at;

  const token = getState().loginReducer.Authorization

  if (keyError !== "") {
    dispatch({
      type: CREATE_ARTICLE,
      payload: false,
    });
    return customSwaltAlert({
      icon: "warning",
      title: `Intenta de nuevo`,
      text: "Debe llenar todos los campos para agregar un artículo",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })

  }else{
    for (const prop in objArticle) {
      if (Object.hasOwnProperty.call(objArticle, prop)) {
        const element = objArticle[prop];
        if (element === "" || element === null) delete objArticle[prop];
      }
    }    
    
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/article`,{
      method: "PUT",
      body: JSON.stringify(objArticle),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then((response) => response.json())
    .then((res) => {
        if(res.success){

          customSwaltAlert({
            icon:'success',
            title: `Actualizado exitosamente`,
            text:`Se ha actualizado el articulo: ${objArticle?.description}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          }).then((result) => {
            if (result.isConfirmed || result.dismiss ) {
                if(!!doAfter){
                  // doAfter()
                }
            }
            // dispatch(getArticles(doAfter))
        });
        
          // if(!!filters){
          //   setTimeout(() => {
          //     dispatch(getArticles(filters))
          //   }, 100);
          // }
        }else{

          customSwaltAlert({
            icon:'error',
            title:`${res.message}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        }
    })
    .catch((err)=>{
      console.error(err);
      customSwaltAlert({
        icon:'error',
        title:`Ocurrió un error al actualizar el artículo`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    
    })

  }
}

export const getOptionsIVA = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/vat/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;

      dispatch({
        type: OPTIONS_IVA,
        payload: results,
      });
    })
    .catch((err) => {
      console.error(err.message);
      dispatch({
        type: OPTIONS_IVA,
        payload: [],
      });
    });
};