import { useDispatch, useSelector } from "react-redux"
import { onOpenDateModal } from "../actions/onOpenDateModal"
import { onCloseDateModal } from './../actions/onCloseDateModal';


const useUiModalStore = () => {

    const { isDateModalOpen } = useSelector(state => state.uiModalReducer)
    const { activeEvent } = useSelector(state => state.calendarReducer)
    const dispatch = useDispatch()

    const openDateModal = () => {
        dispatch(onOpenDateModal())
    }

    const closeDateModal = () => {
        dispatch(onCloseDateModal())
    }

    return {
        isDateModalOpen,
        openDateModal,
        closeDateModal,
        activeEvent
    }
}

export default useUiModalStore