//moment
import moment from 'moment'
import 'moment/locale/es';

//react
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

//icons
import iconHandshake from "../../../../../src/assets/img/icons/iconHandshake.svg";
import ordBlueSearch from "../../../../../src/assets/img/icons/ordBlueSearch.svg";
import ordBackArrow from "../../../../../src/assets/img/icons/ordBackArrow.svg";
import deleteWhite from "../../../../../src/assets/img/icons/deleteWhite.svg";
import saveWhite from "../../../../../src/assets/img/icons/saveWhite.svg";
import iconClose from "../../../../../src/assets/img/icons/modalClose.svg";
import Basura from "../../../../../src/assets/img/icons/Basura.svg";
import alertCircle from "../../../../../src/assets/img/icons/alert-circle-auxiliary-red.svg";

//styles
import styles from "../../../../components/Layouts/tableStyle.module.scss";

//helper
import { convertMoneyFormatNew, customSwaltAlertAsistencial, capitalizeWords, loader, isEmptyOrUndefined, generateId } from '../../../../helpers';

//hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../../../../Hooks';

//components
import { Title, Text, Line } from '../../../UI/atoms'
import { BoxCard, BoxFooter } from '../../../UI/molecules'
import { Box, Drawer } from '@mui/material'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import OrdTable from '../../../../OrderingModule/OrderingComponents/OrdTable';
import Select from "react-select";
import { ordCustomSelect } from '../../../Layouts/react-select-custom';
import NumberFormat from 'react-number-format';
import { Button } from './../../../UI/atoms/button/Button';

moment.locale('es');


export const DailyClosingDetails = () => {

    //states
    const myDate = moment()
    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const userId = store.loginReducer.user_data.id;
    const siteId = store.userReducer.site
    const eaccount = store.loginReducer.currentAccount.id;
    const location = useLocation()
    const isCoordAdmin = (store?.loginReducer?.currentAccount?.profile?.prefijo === 'COORADMISIONIST')
    const [listCurrentReferenceNo, setListCurrentReferenceNo] = useState([])
    
    const [filters, setFilters] = useState({
        userId,
        siteId,
        eaccount,
        search: '',
        cashClosingId: 'current',
        depositTypeFilter: undefined,
        page: 1,
        perpage: 10,
    })

    const [updateCashClosing, setUpdateCashClosing] = useState({
        updateVouchers: 0,
        data: []
    })

    const [createCashClosing, setCreateCashClosing] = useState({
        userId, siteId,
        ccAmount: 0,
        ccDescription: undefined,
        cashDetail: []
    })

    const [newGridData, setNewGridData] = useState([])
    const [newListCashClosingGridDataVoucher, setNewListCashClosingGridDataVoucher] = useState([])
    const [conditionalStateDrawer, setConditionalStateDrawer] = useState(0)
    const [canAddCurrency, setCanAddCurrency] = useState(true)
    const [newListCurrencyDenomination, setNewListCurrencyDenomination] = useState([])
    const [newListCurrencyDenominationCopy, setNewListCurrencyDenominationCopy] = useState([])
    const [newDepositsData, setNewDepositsData] = useState([])
    const [collectionsIds, setCollectionsIds] = useState([])
    const [disabledSaveCashClosing, setDisabledSaveCashClosing] = useState(true)
    const [disabledSaveCashClosingVoucher, setDisabledSaveCashClosingVoucher] = useState(true)
    const [disabledCashClosing, setDisabledCashClosing] = useState(false)
    const [modalDetailCashClosing, setModalDetailCashClosing] = useState(false)
    const [drawerDetailCashClosing, setDrawerDetailCashClosing] = useState(false)
    const [voucherDepositTypeName, setVoucherDepositTypeName] = useState(undefined)
    const [countCurrency, setCountCurrency] = useState(0)
    let totalCounted = 0

    const [patientTotalValues, setPatientTotalValues] = useState({
        appsCount: 0,
        totalDeposit: 0
    })

    const [trigger, setTrigger] = useState(0)
    const [locationTrigger, setLocationTrigger] = useState(1)

    const history = useHistory();
    const { colors } = useTheme()


    //  --------------------------- Petitions -------------------------

    const {
        results: listCashClosing,
        trigger: getListCashClosing,
        load: loaderListCashClosing
    } = useGetMethod()

    const {
        results: listCurrencyDenomination,
        load: loadListCurrencyDenomination,
        trigger: getListCurrencyDenomination
    } = useGetMethod();

    const {
        results: listDepositType,
        load: loaderListDepositType,
        trigger: getListDepositType
    } = useGetMethod()

    const {
        load: loadListServiceOrder,
        results: listServiceOrder,
        trigger: getListServiceOrder,
    } = useGetMethod();

    const {
        load: loadSaveCashClosing,
        trigger: onSaveCashClosing,
    } = usePostMethod();


    const handleOnSaveCashClosing = () => {

        customSwaltAlertAsistencial({
            icon: "warning",
            title: "Está seguro",
            text: `verifique la información`,
            showCancelButton: true,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                onSaveCashClosing({
                    url: "/medical/admissions/cashClosing/",
                    token: token,
                    method: "PUT",
                    body: updateCashClosing,
                    succesAction: (data) => {

                        customSwaltAlertAsistencial({
                            icon: "success",
                            title: "Actualizado exitosamente",
                            text: data?.message,
                            showCancelButton: false,
                        })

                        setDisabledCashClosing(false)
                        setDisabledSaveCashClosing(true)
                        setModalDetailCashClosing(false)

                    },
                    doAfterException: (error) => {

                        customSwaltAlertAsistencial({
                            icon: "warning",
                            title: "Intenta de nuevo",
                            text: error.message,
                            showCancelButton: false,
                        })

                    }
                })
            }
        })
    }

    const handleOnSaveCashClosingVoucher = () => {

        customSwaltAlertAsistencial({
            icon: "warning",
            title: "Está seguro",
            text: `verifique la información`,
            showCancelButton: true,
        }).then(({ isConfirmed }) => {
            onSaveCashClosing({
                url: "/medical/admissions/cashClosing/",
                token: token,
                method: "PUT",
                body: {
                    data: updateCashClosing?.data?.map(el => ({ ...el, voucherNo: el?.voucherNo?.trim() })),
                    updateVouchers: 1
                },
                succesAction: (data) => {

                    customSwaltAlertAsistencial({
                        icon: "success",
                        title: "Actualizado exitosamente",
                        text: data?.message,
                        showCancelButton: false,
                    })

                    setNewListCashClosingGridDataVoucher(listCashClosing?.results?.gridData)
                    setDisabledSaveCashClosingVoucher(true)
                    setModalDetailCashClosing(false)
                    setDrawerDetailCashClosing(true)

                },
                doAfterException: (error) => {

                    customSwaltAlertAsistencial({
                        icon: "warning",
                        title: "Intenta de nuevo",
                        text: error.message,
                        showCancelButton: false,
                    })

                }
            })
        })


    }

    const handleOnCashClosing = () => {

        if (disabledSaveCashClosing) {
            customSwaltAlertAsistencial({
                icon: "warning",
                title: "Verifique la información",
                text: 'Antes de hacer el cierre, verifique que los números de autorización sean los correctos, de no ser así, afectará el proceso de facturación',
                confirmButtonText: 'Ir a cierre parcial',
                showCancelButton: true,
            }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                    setDrawerDetailCashClosing(true)
                }
            })
        }
    }

    const headers = [
        {
            title: "Tipo",
            className: "px-2 text-center",
        },
        {
            title: "No. Orden",
            className: "px-2 text-center",
        },
        {
            title: "",
            className: "px-2",
        },
        {
            title: "Paciente",
            className: "px-2 text-start",
        },
        {
            title: "No. Autorización",
            className: "px-2 text-start",
        },
        {
            title: "Empresa",
            className: "px-2 text-start",
        },
        {
            title: "Recaudo",
            className: "px-2 text-start",
        },
        {
            title: "Total recaudo",
            className: "px-2 pe-4 text-end",
        },
    ];

    const headersCashClosing = [
        {
            title: "Recaudo",
            className: "px-2 text-start",
        },
        {
            title: "Cantidad",
            className: "px-2 text-center",
        },
        {
            title: "Total",
            className: "px-2 text-end",
        },
        {
            title: "",
            className: "px-2 text-end",
        },
    ];

    const headersDetailCashClosing = [
        {
            title: "Voucher",
            className: "px-2 text-start",
        },
        {
            title: "Entidad",
            className: "px-2 text-center",
        },
        {
            title: "Total",
            className: "px-2 text-end",
        },
    ];

    const formatData = (array) => {

        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                tempList.push(
                    <tr key={item?.id} className={`hover-table-row `}>
                        <td className={`text-center px-2`}>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={props => (
                                    <Tooltip {...props}>
                                        <span>Servicio</span>
                                    </Tooltip>
                                )}
                            >
                                <img
                                    alt="pendingAmountStatus"
                                    src={iconHandshake}
                                    height="20px"
                                    width="20px"
                                    className={styles.filterOrdAquaMarine}
                                />

                            </OverlayTrigger>
                        </td>

                        <td className={`text-center px-2 mx-2`}>
                            <span
                                className={`${styles.ordAquaMarineText} text-decoration-underline pointer`}
                                onClick={() => {
                                    const data = listServiceOrder?.results?.find(el => el?.id === item?.appId)
                                    history.push({
                                        pathname: `/admision/ordenesServicios/${item?.appId}`,
                                        state: { ...data, depositTypeFilter: filters.depositTypeFilter, page: filters.page }
                                    });
                                }}
                            >
                                {item?.orderNo || "-"}
                            </span>
                        </td>

                        <td className={`text-center ps-2 mx-2`}>
                            {
                                item?.isReplaced === 1
                                    ? (
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={props => (
                                                <Tooltip {...props}>
                                                    <span>Es un reemplazo</span>
                                                </Tooltip>
                                            )}
                                        >
                                            <img
                                                alt="pendingAmountStatus"
                                                src={alertCircle}
                                                height="20px"
                                                width="20px"
                                            />
                                        </OverlayTrigger>
                                    ) : ''
                            }

                        </td>

                        <td className={`text-start px-2 mx-2`}>
                            {capitalizeWords(item?.patientName || '-')}
                        </td>

                        <td className={`text-start p-2`} style={{ width: '120px' }}>
                            <input
                                type="text"
                                className='ord-roundInput2'
                                placeholder='Escribir...'
                                value={newGridData?.find(el => el?.appId === item?.appId)?.referenceNo || ''}
                                style={{ color: '#6E6F7C' }}
                                onChange={({ target }) => {
                                    
                                    const resGridData = newGridData?.map(el => el?.appId === item?.appId ? { ...el, referenceNo: target.value } : el)
                                    const listReferenceNo = resGridData?.map(el=>el?.referenceNo)

                                     if(JSON.stringify(listReferenceNo) === JSON.stringify(listCurrentReferenceNo)){
                                        setDisabledSaveCashClosing(true)
                                        setDisabledCashClosing(false)
                                    }else {
                                        setDisabledSaveCashClosing(false)
                                        setDisabledCashClosing(true)
                                        setUpdateCashClosing(state => ({
                                            ...state,
                                            data: resGridData?.filter(item => item?.referenceNo !== '')?.map(el => ({
                                                appId: el?.appId,
                                                depositTypeId: el?.despositTypeId,
                                                voucherNo: el?.voucherNo,
                                                referenceNo: el?.referenceNo
                                            }))
                                        }))
                                    }

                                    setNewGridData(resGridData)
                                }}
                            />

                        </td>

                        <td className={`text-start px-2`}>{item?.companyName || "-"}</td>

                        <td className={`text-start px-2`}>
                            {listDepositType?.results
                                ?.find(depot => depot?.depoTypeId === item?.despositTypeId)?.depoTypeName || '-'
                            }
                        </td>

                        <td className={`text-end px-2 pe-4`}>
                            {convertMoneyFormatNew({ textNumber: item?.totalAmount }) || '-'}
                        </td>

                    </tr >
                );
            });
        }
        return tempList;
    };

    const formatDataCashClosing = (array) => {

        let tempList = [];

        if (Array.isArray(array)) {

            array.forEach((item, index) => {

                totalCounted += item?.totalDeposit
                tempList.push(
                    <tr key={item?.id}>
                        <td className={`text-start px-2`} style={{ width: '190px' }} >
                            {
                                item?.id ? (
                                    <Select
                                        formatOptionLabel={formatOptionLabel}
                                        noOptionsMessage={() => "No hay datos"}
                                        options={newListCurrencyDenomination}
                                        className={`text-secondary`}
                                        placeholder={"Seleccionar..."}
                                        styles={ordCustomSelect}
                                        value={item?.cdId ? {
                                            label: item?.cdLabel,
                                            value: item?.cdId,
                                            cdValue: item?.cdValue,
                                            customAbbreviation: item?.cdLabel,
                                        } : ''}
                                        onChange={({ value, label, cdValue, customAbbreviation }) => {

                                            const filter = newDepositsData?.map(el => {

                                                if (el?.id === item?.id) {
                                                    const totalDeposit = (Number(cdValue) * Number(item?.cdQuantity || 0));

                                                    return {
                                                        ...el,
                                                        cdValue,
                                                        cdId: value,
                                                        cdLabel: customAbbreviation,
                                                        totalDeposit: totalDeposit
                                                    }
                                                }
                                                return el
                                            })

                                            const isValidAdd = filter?.filter(elem => elem?.id).every(el => !isEmptyOrUndefined(el?.cdId) && Number(el?.cdQuantity) > 0)


                                            const copyNewListCurrencyDenominationCopy = newListCurrencyDenominationCopy?.map(elem => {
                                                if (value === elem?.cdId) {
                                                    return { ...elem, isActive: false }
                                                }

                                                return elem
                                            })


                                            const a = copyNewListCurrencyDenominationCopy?.map(el => {
                                                if (el?.cdId === item?.cdId) {
                                                    return { ...el, isActive: true }
                                                }

                                                return el

                                            })


                                            setNewListCurrencyDenominationCopy(a)
                                            setNewListCurrencyDenomination(a?.filter(el => el?.isActive === true))
                                            setCanAddCurrency(isValidAdd)
                                            setNewDepositsData(filter)

                                        }}
                                    />
                                ) : listDepositType?.results?.find(dep => dep?.depoTypeId === Number(item?.depositType))?.depoTypeName || '-'
                            }

                        </td>

                        <td>

                            {
                                item?.id
                                    ? (
                                        <NumberFormat

                                            allowNegative={false}
                                            className={`register-inputs ${styles.inputPlaceholder} text-center`}
                                            placeholder="Escribir..."
                                            style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1', width: '90px', color: '#6E6F7C' }}
                                            thousandSeparator={","}
                                            isNumericString={true}
                                            prefix={''}
                                            disabled={!item?.cdId}
                                            value={item?.cdQuantity ? convertMoneyFormatNew({ textNumber: item?.cdQuantity, convertNumber: true }) : ''}
                                            onValueChange={({ value, floatValue, formattedValue }) => {

                                                const filter = newDepositsData?.map(el => {
                                                    if (el?.id === item?.id) {
                                                        return {
                                                            ...el,
                                                            cdQuantity: value,
                                                            totalDeposit: (Number(item?.cdValue) * Number(value || 0))
                                                        }
                                                    }
                                                    return el
                                                })

                                                const isValidAdd = filter?.filter(elem => elem?.id).every(el => !isEmptyOrUndefined(el?.cdId) && Number(el?.cdQuantity) > 0)

                                                setCanAddCurrency(isValidAdd)

                                                setNewDepositsData(filter)

                                            }}
                                        />

                                    )
                                    : (
                                        <span
                                            className={`${styles.ordAquaMarineText} text-center px-2 text-decoration-underline pointer`}
                                            onClick={() => {

                                                getListCashClosing({
                                                    url: `/medical/admissions/cashClosing/`,
                                                    objFilters: { ...filters, depositTypeFilter: location?.state?.depositTypeFilter, page: location?.state?.page },
                                                    token: token
                                                }).then((res) => {
                                                    if (res?.success) {
                                                        setNewListCashClosingGridDataVoucher(
                                                            res?.results?.gridData?.filter(el => el?.depositTypeLabel === item?.depositTypeLabel)
                                                        )
                                                        setModalDetailCashClosing(true)
                                                        setDrawerDetailCashClosing(false)
                                                        setVoucherDepositTypeName(item?.depositTypeLabel)
                                                    }
                                                })

                                            }}
                                        >
                                            {item?.appsCount || 0}
                                        </span>
                                    )
                            }

                        </td>

                        <td className={`text-end px-2`}>
                            {convertMoneyFormatNew({ textNumber: (item?.totalDeposit || 0) })}
                        </td>

                        <td className={`text-end px-2`}>
                            {
                                item?.id ? (
                                    <img
                                        className={`${styles.hoverFilterOrdAquaMarine} pointer`}
                                        src={Basura}
                                        alt="delete"
                                        onClick={() => onRemoveCurrency(item)}
                                    />
                                ) : ''
                            }

                        </td>

                    </tr>
                );
            });
        }

        return tempList;
    };

    const formatDataDetailCashClosing = (array) => {

        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                tempList.push(
                    <tr key={item?.id} className={`hover-table-row`}>
                        <td className={`text-start px-2`} style={{ width: '150px' }}>
                            <input
                                value={newListCashClosingGridDataVoucher?.find(el => el?.appId === item?.appId)?.voucherNo || ''}
                                type="text"
                                className='ord-roundInput2'
                                placeholder='Escribir...'
                                style={{ color: '#6E6F7C' }}
                                onChange={({ target }) => {

                                    const resGridData = newListCashClosingGridDataVoucher?.map(el => el?.appId === item?.appId ? { ...el, voucherNo: target.value } : el)

                                    let isValid = true
                                    listCashClosing?.results?.gridData?.filter(el => el?.depositTypeLabel === item?.depositTypeLabel)?.forEach((el, index) => {
                                        const currentVoucherNo = el?.voucherNo

                                        if (currentVoucherNo?.length) {
                                            const newVoucherNo = resGridData[index]?.voucherNo?.trim()

                                            if (!newVoucherNo?.length) {
                                                isValid = false
                                            }
                                        }
                                    })

                                    if (!isValid) {
                                        setDisabledSaveCashClosingVoucher(true)
                                    } else {
                                        setDisabledSaveCashClosingVoucher(false)

                                        setUpdateCashClosing(state => ({
                                            ...state,
                                            data: resGridData?.filter(item => item?.voucherNo !== '')?.map(el => ({
                                                appId: el?.appId,
                                                depositTypeId: el?.despositTypeId,
                                                voucherNo: el?.voucherNo,
                                            }))
                                        }))
                                    }

                                    setNewListCashClosingGridDataVoucher(resGridData)
                                }}
                            />
                        </td>

                        <td>
                            <span
                                className={`text-center px-2 `}
                                onClick={() => {
                                    setModalDetailCashClosing(true)
                                    setDrawerDetailCashClosing(false)
                                }}
                            >
                                {item?.bankName || '-'}
                            </span>
                        </td>

                        <td className={`text-end px-2`}>
                            {convertMoneyFormatNew({ textNumber: (item?.totalAmount || 0) })}
                        </td>

                    </tr>
                );
            });
        }
        return tempList;
    };

    const onAddCurrency = () => {

        setCanAddCurrency(false)
        setCountCurrency(state => state + 1)

        const object = {
            id: generateId(),
            cdId: '',
            cdQuantity: 0,
            totalDeposit: 0
        }

        setNewDepositsData([
            ...newDepositsData,
            object
        ])

        const ids = []

        ids.push(object.id)

        setCollectionsIds([...collectionsIds, object.id])

        const x = newListCurrencyDenomination?.map(el => {
            if ([...collectionsIds, object.id]?.includes(el?.id)) {
                return { ...el, active: false }
            }
            return el

        })

        setNewListCurrencyDenomination(x?.filter(el => el?.isActive === true))
    }

    const onRemoveCurrency = (item) => {

        setCountCurrency(state => state - 1)

        let ids = collectionsIds.filter(el => el !== item?.id)

        const filter = newDepositsData?.filter(el => el?.id !== item?.id)

        const isValidAdd = filter?.filter(elem => elem?.id).every(el => !isEmptyOrUndefined(el?.cdId) && Number(el?.cdQuantity) > 0)

        const rstNewList = newListCurrencyDenominationCopy?.map(el => {
            if (el?.cdId === item?.cdId) {
                return {
                    ...el,
                    isActive: true
                }
            }

            return el
        })

        setNewListCurrencyDenominationCopy(rstNewList)

        setNewListCurrencyDenomination(rstNewList.filter(el => el.isActive === true))

        setNewDepositsData(filter)

        setCanAddCurrency(isValidAdd)

        setCollectionsIds(ids)
    }

    const onClearStateDrawer = () => {
        const clearNewListCurrency = newDepositsData?.filter(el => el?.id === undefined && el?.depositType !== 'replacedDeposits')
        setNewDepositsData(clearNewListCurrency)
        setCreateCashClosing({
            userId, siteId,
            ccAmount: 0,
            ccDescription: undefined,
            cashDetail: []
        })
        setConditionalStateDrawer(state => state + 1)
        setCanAddCurrency(true)
        setCountCurrency(0)
    }

    const setInitialStateListCurrency = (res) => {

        const listCurrency = res?.results?.map(el =>
            ({ ...el, isActive: true }))?.filter(el => el?.cdType === 'cash' || el?.cdType === 'coin')
            ?.map(el => ({
                ...el,
                label: `${el?.cdType === 'cash' ? 'Billete de' : 'Moneda de'} ${el?.cdValue}`,
                value: el?.cdId,
                cdValue: el?.cdValue,
                customAbbreviation: `${el?.cdType === 'cash' ? 'Billete de' : 'Moneda de'} ${convertMoneyFormatNew({ textNumber: el?.cdValue })}`
            }))

        setNewListCurrencyDenomination(listCurrency)
        setNewListCurrencyDenominationCopy(listCurrency)
    }

    const canCreateCashClosing = () => {

        if ((Number(totalCounted) - Number(location?.state?.totalAmount)) < 0) {

            return !(canAddCurrency && createCashClosing?.ccDescription?.trim())
        }

        return !canAddCurrency
    }

    const onCreateCashClosing = () => {
        const dataCashClosing = {
            userId,
            siteId,
            ccAmount: totalCounted,
            ccDescription: (Number(totalCounted) - Number(location?.state?.totalAmount)) < 0
                ? createCashClosing?.ccDescription : undefined,
            cashDetail: newDepositsData?.filter(el => el?.id)?.map((el => ({
                cdId: el?.cdId,
                cdQuantity: Number(el?.cdQuantity)
            })))
        }

        customSwaltAlertAsistencial({
            icon: "warning",
            title: "¿Está seguro?",
            text: 'Se guardará el cierre parcial No . ' + listCashClosing?.results?.cashClosingDailyId || '',
            showCancelButton: true,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                onSaveCashClosing({
                    url: "/medical/admissions/cashClosing/",
                    token: token,
                    method: "POST",
                    body: dataCashClosing,
                    // noAlert:true,
                    succesAction: (data) => {

                        customSwaltAlertAsistencial({
                            icon: "success",
                            title: "Cierre parcial guardado",
                            text: 'Se ha guardado el cierre parcial de forma exitosa',
                            showCancelButton: false,
                        }).then(() => {

                            history.push({
                                pathname: "/admision/cierreDiario/",
                                state: {
                                    cashClosing: true
                                }
                            })
                        })

                        setDisabledCashClosing(false)
                        setDisabledSaveCashClosing(true)
                        setModalDetailCashClosing(false)
                    },
                    doAfterException: (error) => {

                        customSwaltAlertAsistencial({
                            icon: "warning",
                            title: "Intenta de nuevo",
                            text: error.message,
                            showCancelButton: false,
                        })

                    }
                })
            }
        })


    }

    const onCalculatedTotal = (res) => {
        if (res?.results?.gridData?.length) {
            let difference = 0
            let patientsCount = 0
            let totalAmount = 0
            let appsCount = 0
            let totalDeposit = 0

            res?.results?.gridData?.forEach(el => {
                difference = Number(el?.difference) + Number(difference)
                patientsCount = Number(el?.patientsCount) + Number(patientsCount)
                totalAmount = Number(el?.totalAmount) + Number(totalAmount)
            })

            res?.results?.depositsData?.forEach(el => {
                appsCount = Number(el?.appsCount) + Number(appsCount)

                totalDeposit = el?.depositType === 'replacedDeposits'
                    ? 0 + Number(totalDeposit)
                    : Number(el?.totalDeposit) + Number(totalDeposit)

            })

            setPatientTotalValues(state => ({
                ...state,
                appsCount,
                totalDeposit
            }))

            setNewGridData(res?.results?.gridData)
            setNewListCashClosingGridDataVoucher(res?.results?.gridData)
            setNewDepositsData(res?.results?.depositsData)

        }
    }

    useEffect(() => {

        if (locationTrigger && location?.state?.depositTypeFilter) {
            setLocationTrigger(0)
            setFilters(state => ({
                ...state,
                depositTypeFilter: location?.state?.depositTypeFilter,
                page: location?.state?.page
            }))
            getListCashClosing({
                url: `/medical/admissions/cashClosing/`,
                objFilters: { ...filters, depositTypeFilter: location?.state?.depositTypeFilter, page: location?.state?.page },
                token: token
            }).then((res) => {
                onCalculatedTotal(res)
                
            })
        } else {
            getListCashClosing({
                url: `/medical/admissions/cashClosing/`,
                objFilters: { ...filters },
                token: token
            }).then((res) => {
                onCalculatedTotal(res)
                setListCurrentReferenceNo(res?.results?.gridData?.map(el => el?.referenceNo))
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger])

    useEffect(() => {
        setInitialStateListCurrency(listCurrencyDenomination)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditionalStateDrawer])

    useEffect(() => {

        if (location?.state !== undefined) {

            getListDepositType({
                url: "/medical/admissions/depositType/",
                token: token,
                objFilters: { eaccount }
            });

            getListCurrencyDenomination({
                url: "/medical/admissions/currencyDenomination/",
                token: token,
                objFilters: {
                    cdStatus: 'enabled',
                    curId: 1,
                    curName: 'COP'
                }
            }).then((res) => {
                setInitialStateListCurrency(res)
            })

            getListServiceOrder({
                objFilters: {
                    isCoordinator: isCoordAdmin ? 1 : 0,
                    isAdmisionist: isCoordAdmin ? 0 : 1,
                    idUser: userId,
                    eaccount,
                },
                token: token,
                url: "/medical/admissions/serviceOrder/",
            });

        } else {
            history.goBack()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formatOptionLabel = ({ value, label, customAbbreviation }) => (
        <div>{customAbbreviation}</div>
    );

    return (
        <>
            {(
                loaderListCashClosing ||
                loadListServiceOrder ||
                loadSaveCashClosing ||
                loadListCurrencyDenomination ||
                loaderListDepositType

            ) && loader}

            <Box
                display={'grid'}
                gridTemplateColumns={'350px 1fr'}
                width={'100%'}
            >
                <Box
                    width={'100%'}
                    padding='10%'
                    paddingTop={'40px'}
                    paddingBottom={'50px'}
                    borderRight={`4px solid ${colors.ordAquaMarine}`}
                    overflow='scroll'
                    display={'flex'}
                    flexDirection={'column'}
                >

                    <Title
                        title={'Cierre diario'}
                        margin='0 0 2rem 0'
                        textAlign='center'
                    />

                    {
                        listCashClosing?.results?.depositsData?.map(el => {
                            return (
                                <BoxCard
                                    colorName={el?.depositType === filters?.depositTypeFilter ? 'white' : colors.darkerGray}
                                    colorValue={el?.depositType === filters?.depositTypeFilter ? 'white' : colors.darkerGray}
                                    colorTitle={colors.ordAquaMarine}
                                    fontWeightTitle={'bold'}
                                    fontWeightName='bold'
                                    margin='0 0 1rem 0'
                                    marginItem='0 0 0 auto'
                                    disabled={el?.depositType === 'replacedDeposits'}
                                    pointer
                                    title={el?.depositType === 'replacedDeposits'
                                        ? 'ANULADO'
                                        : listDepositType?.results?.find(depot => depot?.depoTypeId === Number(el?.depositType))?.depoTypeName?.toUpperCase()
                                    }
                                    textAlign={'center'}
                                    arrayObjItems={[
                                        {
                                            name: 'Total recaudo',
                                            value: `${el?.depositType === 'replacedDeposits' ? '-' : ''}${convertMoneyFormatNew({ textNumber: el?.totalDeposit } || '-')}`
                                        },
                                        { name: 'Recaudos', value: el?.appsCount || '-' }]
                                    }
                                    filterActive={
                                        el?.depositType === filters?.depositTypeFilter
                                    }
                                    onClickFilter={() => {
                                        if (filters.depositTypeFilter !== undefined && filters.depositTypeFilter === el?.depositType) {
                                            setFilters(state => ({
                                                ...state,
                                                depositTypeFilter: undefined
                                            }))

                                        } else {
                                            setFilters(state => ({
                                                ...state,
                                                depositTypeFilter: el?.depositType
                                            }))
                                        }

                                        setTrigger(state => state + 1)
                                        setFilters(state => ({ ...state, page: 1 }))
                                    }}
                                />
                            )
                        })
                    }

                    <Box mt={'auto'} style={{ marginBottom: '25px' }}>
                        <Box display={'grid'} gridTemplateColumns='1fr 1fr'>
                            <Text
                                title='Total'
                                color={colors.darkGray2}
                                fontWeight={'bold'}
                            />

                            <Text
                                textAlign={'end'}
                                color={colors.darkGray}
                                fontWeight={'bold'}
                                title={convertMoneyFormatNew({ textNumber: patientTotalValues?.totalDeposit })}
                                margin='0 0 0 auto'
                            />
                        </Box>

                        <Line
                            backgroundColor={colors.darkGray}
                            width='auto'
                            margin='0.5rem 0'
                        />

                        {/* <Box display={'grid'} gridTemplateColumns='1fr 1fr'>
                            <Text
                                title='Ordenes'
                                color={colors.darkGray2}
                                fontWeight={'bold'}
                            />

                            <Text
                                textAlign={'end'}
                                color={colors.darkGray}
                                fontWeight={'bold'}
                                title={patientTotalValues?.appsCount || '-'}
                                margin='0 0 0 auto'
                            />
                        </Box> */}
                    </Box>

                </Box>

                <Box
                    style={{ overflow: 'scroll', marginBottom: '100px', backgroundColor: '#f6feff' }}
                >

                    <Box paddingTop={'40px'} margin={'auto 100px auto 30px'}>

                        <Box className='d-flex align-items-center justify-content-between mb-4'>
                            <Box className='d-flex gap-3 align-items-center'>
                                <img
                                    className={`cursorPointer`}
                                    src={ordBackArrow}
                                    onClick={() => history.push({ pathname: "/admision/cierreDiario/" })}
                                    alt='iconBack'
                                />
                                <Title
                                    title={'Recaudado'}
                                    margin='0'
                                />
                            </Box>

                            <Box display={'flex'} justifyContent='space-between' alignItems={'center'} paddingRight={'5px'}>
                                <Title title={myDate.format('LL')} color={colors.ordAquaMarine} fontSize={'16px'} />
                            </Box>

                        </Box>



                        <Box>

                            <div
                                className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} ${styles.ordGrayInputSearch} align-self-end w-50`}

                            >
                                <input
                                    className={`${styles.appInputDate} ${styles.ordGrayInputSearch} ${styles.darkerGrayText} w-100`}
                                    type="text"
                                    placeholder="Escribe aquí para buscar"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setFilters((state) => ({
                                            ...state,
                                            search: e.target.value
                                        }));
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            setTrigger(state => state + 1)
                                        }
                                    }}
                                />
                                <img
                                    src={ordBlueSearch}
                                    className="pointer"
                                    alt="buscar"
                                    onClick={() => setTrigger(state => state + 1)}
                                />
                            </div>
                            <OrdTable
                                shadow
                                headers={headers}
                                hasChildren={true}
                                oneHeadDarkBlue={false}
                                lowerCase={false}
                                paginate={{
                                    activePage: filters.page,
                                    totalPages: listCashClosing?.rowTotal,
                                    perPage: filters.perpage,
                                    pageRangeDisplayed: 3,
                                    onChangePage: async (e) => {
                                        setTrigger((state) => (state + 1));
                                        setFilters(state => ({
                                            ...state,
                                            page: e
                                        }))
                                    },
                                    showTextDetails: true,
                                }}
                            >
                                {formatData(listCashClosing?.results?.gridData || [])}
                            </OrdTable>
                        </Box>

                        <Box
                            display={'grid'}
                            gridTemplateColumns={'20% 20% 20% 20% 20%'}
                            width='85%'
                            padding={'0 20px'}
                            margin={'-15px 0'}
                            fontWeight='bold'
                            color={colors.ordDarkGray}
                        >

                        </Box>

                    </Box>

                </Box>

                <BoxFooter
                    className={'animate__animated animate__fadeIn'}
                    saveDisabled={disabledSaveCashClosing}
                    onclickSave={handleOnSaveCashClosing}
                    width={'calc(100% - 27rem)'}
                    left='26.7rem'
                    padding={'0 7rem 0 0'}
                    widthSave={'150px'}
                    bgSave={colors.ordAquaMarine}
                    imgPathSave={saveWhite}
                    orderSave={2}
                    orderCancel={0}
                    onclickCancel={() => history.goBack()}
                    extraBtn={[
                        {
                            title: 'Cierre parcial',
                            width: '160px',
                            bg: `${colors.ordDarkBlueText}`,
                            order: 1,
                            // action: handleClosePartial,
                            border: '1px solid #1a538d',
                            backgroundColorHover: '#1a538d',
                            opacityHover: '0.7',
                            imgPath: deleteWhite,
                            disabled: disabledCashClosing,
                            onClick: handleOnCashClosing
                        }
                    ]}
                />

            </Box>

            <Drawer
                anchor={'right'}
                open={drawerDetailCashClosing}
                onClose={() => {
                    onClearStateDrawer()
                    setDrawerDetailCashClosing(false)
                }}
            >
                <Box padding={3}>
                    <Box mb={2}>
                        <img
                            className={`${styles.closeDrawerAsistencial} pointer`}
                            src={iconClose}
                            alt="close"
                            onClick={() => {
                                onClearStateDrawer()
                                setDrawerDetailCashClosing(false)
                            }}
                            height={'28px'}
                            width={'28px'}
                        />
                    </Box>

                    <Box paddingX={4}>

                        <Box>
                            <Box
                                fontSize={'36px'}
                                className={`${styles.ordDarkBlueText} fw-bold`}
                                mb={0}
                            >
                                Cierre parcial
                            </Box>

                            <Text title='Recaudos en efectivo' />

                        </Box>

                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            height={'80vh'}
                        >
                            <OrdTable
                                shadow
                                headers={headersCashClosing}
                                hasChildren={true}
                                oneHeadDarkBlue={false}
                                lowerCase={false}
                                style={{ minWidth: '400px' }}
                            >

                                {formatDataCashClosing(
                                    newDepositsData?.filter(el =>
                                        (Number(el?.depositType) !== listDepositType?.results?.find(dep => dep?.depoTypeLabel === 'cash')?.depoTypeId)
                                        && (el?.depositType !== 'replacedDeposits')
                                    )
                                    || []
                                )}
                            </OrdTable>

                            {
                                canAddCurrency && (countCurrency < newListCurrencyDenominationCopy?.length) ? (
                                    <div
                                        className={`${styles.app_container_icon_new} ms-1 animate__animated animate__fadeIn`}
                                        onClick={onAddCurrency}
                                    >
                                        <div className={styles.tlnBtnNew} />
                                        <span>Agregar moneda</span>
                                    </div>

                                ) : ''
                            }

                            <Box className='mt-auto ms-1 animate__animated animate__fadeIn'>
                                <Box
                                    borderRadius={3}
                                    paddingX={3}
                                    paddingY={2}
                                    bgcolor={'#F5FCFD'}
                                    className='d-flex gap-3 mt-4'
                                >
                                    <Box>
                                        <Text
                                            title='Total contado'
                                            fontSize={'12px'}
                                            classNameBox={'ms-1'}
                                        />
                                        <NumberFormat
                                            disabled
                                            allowNegative={false}
                                            className={`register-inputs ${styles.inputPlaceholder}`}
                                            placeholder="$..."
                                            style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                                            thousandSeparator={","}
                                            decimalSeparator={"."}
                                            isNumericString={true}
                                            prefix={'$'}
                                            value={totalCounted}
                                        />

                                    </Box>

                                    <Box>
                                        <Text
                                            title='Total en el sistema'
                                            fontSize={'12px'}
                                            classNameBox={'ms-1'}
                                        />
                                        <NumberFormat
                                            disabled
                                            allowNegative={false}
                                            className={`register-inputs ${styles.inputPlaceholder}`}
                                            placeholder="$..."
                                            style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                                            thousandSeparator={","}
                                            decimalSeparator={"."}
                                            isNumericString={true}
                                            prefix={'$'}
                                            value={location?.state?.totalAmount}
                                        />

                                    </Box>

                                    <Box>
                                        <Text
                                            title='Diferencia'
                                            fontSize={'12px'}
                                            classNameBox={'ms-1'}
                                        />
                                        <input
                                            disabled
                                            type="text"
                                            className={`register-inputs ${styles.inputPlaceholder}`}
                                            style={{
                                                maxHeight: '32px',
                                                height: '32px',
                                                borderColor: '#A3BAD1',
                                                color: (Number(totalCounted) - Number(location?.state?.totalAmount)) < 0 ? '#F39682' : '#58595b'
                                            }}
                                            value={`${(Number(totalCounted) - Number(location?.state?.totalAmount)) < 0
                                                ? '$ ' + convertMoneyFormatNew({
                                                    textNumber: (Number(totalCounted) - Number(location?.state?.totalAmount)),
                                                    SymbolShow: true
                                                })
                                                : '$0'}`
                                            }
                                        />

                                    </Box>

                                </Box>

                                {
                                    (Number(totalCounted) - Number(location?.state?.totalAmount)) < 0
                                        ? (
                                            <Box className='ms-1 animate__animated animate__fadeIn'>
                                                <Box className='d-flex gap-2 mt-3'>
                                                    <img
                                                        src={alertCircle}
                                                        alt="warning"
                                                    />

                                                    <Text
                                                        title='El total del conteo no coincide con el recaudo registrado en el sistema'
                                                        fontSize={'14px'}
                                                        color={'#F39682'}
                                                        fontWeight={'bold'}
                                                    />
                                                </Box>

                                                <Box className='mt-3 ms-1 mb-3'>
                                                    <Text
                                                        title='Nota'
                                                        fontSize={'14px'}
                                                        fontWeight={'bold'}
                                                        required
                                                    />


                                                    <textarea
                                                        placeholder={"Escribir..."}
                                                        value={createCashClosing?.ccDescription || ''}
                                                        onChange={({ target }) => {
                                                            setCreateCashClosing(state => ({
                                                                ...state,
                                                                ccDescription: target.value
                                                            }))
                                                        }}
                                                        rows="25"
                                                        cols="55"
                                                        style={{ height: "5rem" }}
                                                        className={`text-secondary ord-roundInput2 w-100 outlineNone p-2`}
                                                    ></textarea>

                                                </Box>
                                            </Box>
                                        )
                                        : ''
                                }


                            </Box>

                            <Box display='flex' columnGap='10px' className='my-4' justifyContent={'flex-end'}>
                                <Button
                                    backgroundHover={colors.ordAquaMarine}
                                    backgroundColorHover={colors.ordAquaMarine}
                                    backgroundColor={"white"}
                                    color={colors.ordAquaMarine}
                                    colorHover='white'
                                    width='100px'
                                    padding='8px'
                                    fontSize='14px'
                                    onClick={() => {
                                        onClearStateDrawer()
                                        setDrawerDetailCashClosing(false)
                                    }}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    disabled={canCreateCashClosing()}
                                    width='100px'
                                    padding='8px'
                                    fontSize='14px'
                                    border='none'
                                    backgroundColor={colors.ordAquaMarine}
                                    backgroundColorHover={colors.ordAquaMarine + '90'}
                                    onClick={onCreateCashClosing}
                                >
                                    Guardar
                                </Button>
                            </Box>


                        </Box>
                    </Box>
                </Box>
            </Drawer>

            <Drawer
                anchor={'right'}
                open={modalDetailCashClosing}
                onClose={() => {
                    setVoucherDepositTypeName(undefined)
                    setModalDetailCashClosing(false)
                    setDrawerDetailCashClosing(true)
                    setNewListCashClosingGridDataVoucher(listCashClosing?.results?.gridData)
                }}
            >
                <Box padding={3}>
                    <Box mb={2}>
                        <img
                            className={`${styles.closeDrawerAsistencial} pointer`}
                            src={iconClose}
                            alt="close"
                            onClick={() => {
                                setVoucherDepositTypeName(undefined)
                                setModalDetailCashClosing(false)
                                setDrawerDetailCashClosing(true)
                                setNewListCashClosingGridDataVoucher(listCashClosing?.results?.gridData)
                            }}
                            height={'28px'}
                            width={'28px'}
                        />
                    </Box>

                    <Box paddingX={4}>

                        <Box>
                            <Box
                                fontSize={'36px'}
                                className={`${styles.ordDarkBlueText} fw-bold`}
                                mb={0}
                            >
                                Listado de {voucherDepositTypeName?.toLowerCase() === 'transfer' ? 'transferencias' : 'vouchers'}
                            </Box>
                        </Box>

                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            height={'80vh'}
                        >
                            <OrdTable
                                shadow
                                headers={headersDetailCashClosing}
                                hasChildren={true}
                                oneHeadDarkBlue={false}
                                lowerCase={false}
                                style={{ minWidth: '400px' }}
                            >
                                {formatDataDetailCashClosing(newListCashClosingGridDataVoucher || [])}
                            </OrdTable>


                            <Box display='flex' columnGap='10px' className='mt-auto' justifyContent={'flex-end'}>
                                <Button
                                    backgroundHover={colors.ordAquaMarine}
                                    backgroundColorHover={colors.ordAquaMarine}
                                    backgroundColor={"white"}
                                    color={colors.ordAquaMarine}
                                    colorHover='white'
                                    width='100px'
                                    padding='8px'
                                    fontSize='14px'
                                    onClick={() => {
                                        setModalDetailCashClosing(false)
                                        setDrawerDetailCashClosing(true)
                                        setNewListCashClosingGridDataVoucher(listCashClosing?.results?.gridData)
                                    }}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    disabled={disabledSaveCashClosingVoucher}
                                    width='100px'
                                    padding='8px'
                                    fontSize='14px'
                                    border='none'
                                    backgroundColor={colors.ordAquaMarine}
                                    backgroundColorHover={colors.ordAquaMarine + '90'}
                                    onClick={handleOnSaveCashClosingVoucher}
                                >
                                    Guardar
                                </Button>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Drawer>

        </>
    )
}