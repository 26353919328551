import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { 
    GLOSS_REGISTER,
    GLOSS_POST_REPLY,
    GLOSS_POST_NOTE,
    GET_SETTLED_BILLING,
    GET_GLOSS_TYPE,
    GET_GLOSS,
    GET_ONE_GLOSS,
} from "./actionTypes";
import { customSwaltAlert } from "../helpers";

const MySwal = withReactContent(Swal);


export const getGloss = (objFilters) => (dispatch, getCreate) => {
    dispatch({
      type: GET_GLOSS,
      loading: true,
    });
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/objection/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count } = res;
  
        dispatch({
          type: GET_GLOSS,
          payload: results,
          total: row_count,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err.message);
        dispatch({
          type: GET_GLOSS,
          loading: false,
        });
      });
  };

  
export const getOneGloss = (objFilters) => (dispatch, getCreate) => {
    dispatch({
      type: GET_ONE_GLOSS,
      loading: true,
    });
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/objection/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count } = res;
  
        dispatch({
          type: GET_ONE_GLOSS,
          payload: results,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err.message);
        dispatch({
          type: GET_ONE_GLOSS,
          loading: false,
        });
      });
  };

export const postGloss = (data, doAfter = null) => (dispatch, getCreate) => {
  dispatch({
    type: GLOSS_REGISTER,
    loading: true,
  });
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/objection/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
        if (res.success) {
          customSwaltAlert({
            icon: "success",
            title: "Nota registrada exitosamente",
            text: `Se ha registrado una glosa no. ${res.results?.sequence}`,
            confirmButtonText: "Aceptar",
            showCancelButton: false,
        }).then((result) => {
                if(!!doAfter){
                  doAfter();
                }
            });
            
        } else {
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `${res.message}`,
            confirmButtonText: "Aceptar",
            showCancelButton: false,
        })}

        dispatch({
            type: GLOSS_REGISTER,
            loading: false,
        });

    })
    .catch((err) => {
      console.error(err.message);
      dispatch({
        type: GLOSS_REGISTER,
        loading: false,
      });
    });
};



export const getSettledBilling = (objFilters) => (dispatch, getCreate) => {

  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/invoice/Filed/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_count } = res;

      dispatch({
        type: GET_SETTLED_BILLING,
        payload: results,
        total: row_count,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};



export const getGlossType = (objFilters) => (dispatch, getCreate) => {

    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/objectionType/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_count } = res;
  
        dispatch({
          type: GET_GLOSS_TYPE,
          payload: results,
          total: row_count,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  

  export const postGlossReply = (data, doAfter = null) => (dispatch, getCreate) => {
    dispatch({
      type: GLOSS_POST_REPLY,
      loading: true,
    });
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/objection/reply/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
          if (res.success) {
            if(!!doAfter){
              doAfter();
            }
          } else {
              MySwal.fire({
                icon: 'error',
                title: `${res.message}`
              });
          }
          dispatch({
            type: GLOSS_POST_REPLY,
            loading: false,
          });
      })
      .catch((err) => {
        console.error(err.message);
        dispatch({
          type: GLOSS_POST_REPLY,
          loading: false,
        });
      });
  };

  export const postNewNote = (data, doAfter = null) => (dispatch, getCreate) => {
    dispatch({
      type: GLOSS_POST_NOTE,
      loading: true,
    });
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/note/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
          if (res.success) {
            MySwal.fire({
              icon: 'success',
              title: `Nota registrada`,
              text: `No. Nota ${res.results?.sequence}`
            }).then((result) => {
                if(!!doAfter){
                  doAfter();
                }
            });
          } else {
              MySwal.fire({
                icon: 'error',
                title: `${res.message}`
              });
          }
          dispatch({
            type: GLOSS_POST_NOTE,
            loading: false,
          });
  
      })
      .catch((err) => {
        console.error(err.message);
        dispatch({
          type: GLOSS_POST_NOTE,
          loading: false,
        });
      });
  };
  
  
