import React, { useEffect, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import LupaClara from "../../assets/img/icons/lupaClear.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import iconBack from "../../assets/img/icons/iconBack.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { getPdfTest, getReceiptBox } from "../../actions/consultAction"
import { getCorporateClients } from "../../actions/corporateClientsActions";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import { message } from "../../helpers/helpers";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";

function ReceiptBox(props) {

    let history = useHistory();
    function handleClick() {
        history.push("/tesoreria/TipoDeConsulta");
    }
    const numberWithCommas = (x) => {
        var parts = String(x).split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }
    const ShowOptions = () => {
        if (showOpt?.show && showOpt?.arrow) {
            setShowOpt({
                ...showOpt,
                show: false,
                arrow: false,
            });
        } else {
            setShowOpt({
                ...showOpt,
                show: true,
                arrow: true,
            });
        }
    };
    const [showOpt, setShowOpt] = useState({
        show: false,
        arrow: false,
    });

    // eslint-disable-next-line no-unused-vars
    const [trigger, setTrigger] = useState(1);
    const [trigger2, setTrigger2] = useState(1);
    const counter = useSelector(state => state);//entra aca para acceder a todos los reducers
    const dispatch = useDispatch(); //usa este metodo para ejecutar un action
    const [ReceiptBox, setReceiptBox] = useState({
        entity_account: counter.loginReducer.currentAccount.id,
        page: 1,
        perpage: 10,
        total_amount_from: "",
        total_amount_to: "",
        receipt_date_since: "",
        receipt_date_until: "",
        coporate_client: "",
    })
    const [showPdf, setShowPdf] = useState(false)
    const [base64, setBase64] = useState("")
    const getPdf = async (id) => {
        const result = await getPdfTest(
            { id: id, doc: 4 },
            counter.loginReducer.Authorization,
        )
        if (result?.success) {
            return setBase64(result.pdf[0].key.split("'")[1])
        } else {
            message('error', 'Ha ocurrido un error', result?.message)
        }
    }
    useEffect(
        () => {
            dispatch(getReceiptBox(ReceiptBox))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            ReceiptBox.page,
            ReceiptBox.coporate_client,
            ReceiptBox.receipt_date_since,
            ReceiptBox.receipt_date_until,
            trigger2
        ] //cada vez que esto cambie, ejecutar la funcion de arriba. 
    );
    useEffect(() => {
        dispatch(getCorporateClients({ id_account: counter.loginReducer.currentAccount.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);

    const handlePageChange = (val) => {
        setReceiptBox({
            ...ReceiptBox,
            page: val
        });
        getReceiptBox(ReceiptBox)
    }
    const BtnSearch = () => {
        resetReq();
        dispatch(getReceiptBox(ReceiptBox));
    }

    const resetReq = () => {
        setReceiptBox({
            ...ReceiptBox,
            page: 1,
            perpage: 10,

        });
    }

    var optionClients = []
    optionClients.push({
        value: "",
        label: "Seleccionar...",
        key: "wweh",
    }
    )
    if (Array.isArray(counter.corporateclientsReducer.corporateClients)) {
        // eslint-disable-next-line array-callback-return
        counter.corporateclientsReducer.corporateClients.map((item) => {
            optionClients.push({
                value: item.id,
                label: item.Nit + " - " + item.copyName,
                key: item.id + "wweh",
            }
            )
        });
    }
    const header = [

        <th className="text-center">No. Recibo</th>,
        <th className="text-center">Fecha</th>,
        <th className="text-center">NIT</th>,
        <th className="text-center">Cliente</th>,
        <th className="text-center">Valor recibo</th>,
        <th className="text-center">No. Cpte</th>,
        <th className="text-center">&nbsp;</th>
    ]
    const body = [
    ]
    if (Array.isArray(counter.consultReducer.receiptBox)) {
        // eslint-disable-next-line array-callback-return
        counter.consultReducer.receiptBox.map((item) => {
            body.push(
                <tr>
                    <td className="text-center">{item.consec}</td>
                    <td className="text-center">{convertDateToLatinFormat(item.receipt_date)}</td>
                    <td className="text-center">{item.Nit}</td>
                    <td className="text-center">{item.copyName}</td>
                    <td className="text-center">$ {numberWithCommas(item.total_amount)}</td>
                    <td className="text-center">{item.jrnl_voucher} </td>
                    <td className="px-2 text-start">
                        <img src={Filtrar}
                            className={tableStyles.cursorPointer}
                            // eslint-disable-next-line no-sequences
                            onClick={() => (getPdf(item.id), setShowPdf(true))}
                            alt="filter"
                        />
                    </td>
                </tr>
            );
        })
    }

    const handleSumit = (e) => {
        e.preventDefault();
        setTrigger2(trigger2 + 1)
    }

    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                {(counter.consultReducer.receiptBox_loading) &&
                    <div className="loading">
                        <Loader
                            type="Oval"
                            color="#003f80"
                            height={100}
                            width={100}
                        />
                    </div>
                }
                <div 
                    className="d-flex mb-4" 
                    style={{ marginTop: '30px', alignItems: 'end' }}
                >
                    <Title
                        title="Consulta de recibos de caja"
                        onClickIcon={handleClick}
                    />
                </div>

                <div
                    className="div align-items-end"
                    style={{ gap: '0.5rem 1rem', flexWrap: 'wrap' }}
                >
                    <div className={tableStyles.containerSelect}>
                        &nbsp;<label className={tableStyles.stylesLabel}>Cliente</label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                            placeholder="Seleccionar..."
                            styles={customSelectNewDark}
                            options={optionClients}
                            onChange={(e) => setReceiptBox({
                                ...ReceiptBox,
                                coporate_client: e.value
                            })}
                        />
                    </div>

                    <div className={tableStyles.containerSelect}>
                        &nbsp;<label className={tableStyles.stylesLabel}>Valor desde</label>
                        <form onSubmit={handleSumit}>
                            <input
                                placeholder="Escribir..."
                                className={IndividualStyles.registerInputsGris}
                                name="total_amount_from"
                                type="number"
                                onChange={(e) => setReceiptBox({
                                    ...ReceiptBox,
                                    total_amount_from: e.target.value,
                                })}
                            />
                        </form>
                    </div>

                    <div className={tableStyles.containerSelect}>
                        &nbsp;<label className={tableStyles.stylesLabel}>Valor hasta</label>
                        <form onSubmit={handleSumit}>
                            <input
                                placeholder="Escribir..."
                                className={IndividualStyles.registerInputsGris}
                                name="total_amount_to"
                                type="number"
                                onChange={(e) => setReceiptBox({
                                    ...ReceiptBox,
                                    total_amount_to: e.target.value,
                                })}
                            />
                        </form>
                    </div>

                    <div className={tableStyles.containerDate}>
                        &nbsp;<label className={tableStyles.stylesLabel}>Fecha desde</label>
                        <input
                            className={IndividualStyles.registerInputsBlue}
                            name="receipt_date_since"
                            type="date"
                            onChange={(e) => setReceiptBox({
                                ...ReceiptBox,
                                receipt_date_since: e.target.value,
                            })}
                        />
                    </div>

                    <div className={tableStyles.containerDate}>
                        &nbsp;<label className={tableStyles.stylesLabel}>Hasta</label>
                        <input
                            className={IndividualStyles.registerInputsBlue}
                            name="receipt_date_until"
                            type="date"
                            onChange={(e) => setReceiptBox({
                                ...ReceiptBox,
                                receipt_date_until: e.target.value,
                            })}
                        />
                    </div>

                    <div>
                        <label>
                            <img
                                style={{ width: '25px' }}
                                className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`}
                                onClick={() => BtnSearch()}
                                src={Lupa}
                                alt="search"
                            />
                        </label>
                    </div>

                </div>

                <GenericTable
                    headers={header}
                    children={body}
                    dark={true}
                >
                </GenericTable>
                <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                        Pag.{" "}
                        {counter.consultReducer.totalReceiptBox
                            ? ReceiptBox.page
                            : ""}
                        {" de "}
                        {Math.ceil(counter.consultReducer.totalReceiptBox / ReceiptBox.perpage)
                            ? Math.ceil(counter.consultReducer.totalReceiptBox / ReceiptBox.perpage) : ""}{" "}
                        ({counter.consultReducer.totalReceiptBox} encontrados)
                    </p>
                    <Pagination
                        activePage={ReceiptBox.page}
                        itemsCountPerPage={ReceiptBox.perpage}
                        totalItemsCount={counter.consultReducer.totalReceiptBox}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext}
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                    />
                </div>
            </div>
            <ModalNew
                title="Consulta de recibos de caja"
                show={showPdf}
                btnNoName={"Cancelar"}
                size="700"
                btnYesDisabled={false}
                // eslint-disable-next-line no-sequences
                onHide={() => (setBase64(""), setShowPdf(false))}
                // eslint-disable-next-line no-sequences
                btnNoEvent={() => (setBase64(""), setShowPdf(false))}
                btnNoDisabled={false}
            >
                <PdfViewer
                    downloadable
                    file={`data:application/pdf;base64,${base64}`}></PdfViewer>
            </ModalNew>
        </>
    )

};
export default ReceiptBox;