import React from 'react'
import Select from 'react-select';
import { Col, Form, Row } from "react-bootstrap";
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";
import moneySymbol from "../../../assets/img/icons/moneySymbol.svg";
import close from "../../../assets/img/icons/close-blue.svg";
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import NumberFormat from 'react-number-format';
import { useTheme } from 'styled-components';
import { Text } from '../../../components/UI/atoms';

export const CardCollectionCash = ({ activeEvent, setActiveEvent, handleRemoveDeposit, totalAmount }) => {

  const { colors } = useTheme()

  return (
    <Row className=" mt-2" style={{ width: '970px' }}>
      <Col xs={10} className="">

        <div className={`${tableStyles.addCardWhite} d-flex my-3 py-3 px-2`}>

          <Col xs={2} className="align-self-center">
            <div className="d-flex align-self-center justify-content-center">
              <div
                className={`${tableStyles.addCardWhite} d-flex justify-content-center align-self-center m-0 my-0`}
                style={{ width: "100px", height: "72px", alignItems: 'center', display: 'flex' }}
              >
                <img alt="arrowIcon" height='50px' src={moneySymbol} className="" />

              </div>
            </div>
          </Col>

          <Col xs={5} className="px-2">
            <Form.Group className=" text-start mb-2" controlId="">
              <Text
                title={'Tipo de recaudo'}
                color={colors.ordDarkBlueText}
                fontSize={'14px'}
                required
              />

              <Form.Control
                className={`ord-roundInput `}
                type="text"
                placeholder="Efectivo"
                disabled
              />
            </Form.Group>

            <div className="d-flex">
              <Col xs={6}>
                <Form.Group className=" text-start mr-2" controlId="">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Vuelto total </span>
                  </Form.Label>
                  <NumberFormat
                    disabled
                    allowNegative={false}
                    className={`register-inputs`}
                    placeholder="Escribe..."
                    style={{ maxHeight: '32px', height: '32px', fontSize: '14px', borderColor: '#a3bad1' }}
                    thousandSeparator={","}
                    decimalSeparator={"."}
                    isNumericString={true}
                    prefix={'$'}
                    value={activeEvent?.depositValues?.cash?.amountTotal}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className=" text-start ml-2" controlId="">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;¿Donar el vuelto?</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={[{ label: 'Si', value: 1 }, { label: 'No', value: 0 }]}
                    className="text-secondary "
                    placeholder={"Seleccionar..."}
                    styles={ordCustomSelect}
                    value={{
                      label: activeEvent?.depositValues?.cash?.donateChange ? 'Si' : 'No',
                      value: activeEvent?.depositValues?.cash?.donateChange
                    }}
                    onChange={(e) => {
                      setActiveEvent({
                        ...activeEvent,
                        depositValues: {
                          ...activeEvent?.depositValues,
                          cash: { ...activeEvent?.depositValues?.cash, donateChange: e.value }
                        }
                      })
                    }}
                  />
                </Form.Group>
              </Col>
            </div>
          </Col>

          <Col xs={5} className="px-2">
            <Form.Group className=" text-start mb-2" controlId="">
              <Text
                title={'Monto pagado'}
                color={colors.ordDarkBlueText}
                fontSize={'14px'}
                required
              />

              <NumberFormat
                allowNegative={false}
                className={`register-inputs`}
                placeholder="Escribe..."
                style={{ maxHeight: '32px', height: '32px', fontSize: '14px', borderColor: '#a3bad1' }}
                thousandSeparator={","}
                decimalSeparator={"."}
                isNumericString={true}
                prefix={'$'}
                value={activeEvent?.depositValues?.cash?.amount}
                onValueChange={({ value, floatValue, formattedValue }) => {
                  const pendingAmount = (value && (Number(totalAmount) >= Number(value))) ? 0 : Number && (Number(value) > Number(totalAmount)) ? Number(value) - Number(totalAmount) : 0
                  const totalChange = (value && (Number(totalAmount) > Number(value))) ? Number(totalAmount) - Number(value) : 0
                  setActiveEvent({
                    ...activeEvent,
                    depositValues: {
                      ...activeEvent?.depositValues,
                      cash: { ...activeEvent?.depositValues?.cash, amount: value, pendingAmount, totalChange }
                    }
                  })
                }}
              />
            </Form.Group>
            <div className="d-flex">
              <Col xs={6}>
                <Form.Group className=" text-start mr-2" controlId="">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;¿Cuánto? </span>
                    {activeEvent?.depositValues?.cash?.donateChange 
                      ? <span className={`${tableStyles.ordOrangeText}`}>*</span>
                      : ''
                    }
                    
                  </Form.Label>
                  <NumberFormat
                    allowNegative={false}
                    className={`register-inputs`}
                    placeholder="Escribe..."
                    style={{ maxHeight: '32px', height: '32px', fontSize: '14px', borderColor: '#a3bad1' }}
                    thousandSeparator={","}
                    decimalSeparator={"."}
                    isNumericString={true}
                    prefix={'$'}
                    value={activeEvent?.depositValues?.cash?.donation}
                    onValueChange={({ value, floatValue, formattedValue }) => {
                      setActiveEvent({
                        ...activeEvent,
                        depositValues: {
                          ...activeEvent?.depositValues,
                          cash: { ...activeEvent?.depositValues?.cash, donation: value }
                        }
                      })
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className=" text-start ml-2" controlId="">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;¿Vuelto entregado?</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={[{ label: 'Si', value: 1 }, { label: 'No', value: 0 }]}
                    className="text-secondary "
                    placeholder={"Seleccionar..."}
                    styles={ordCustomSelect}
                    value={{
                      label: activeEvent?.depositValues?.cash?.turnedDelivered ? 'Si' : 'No',
                      value: activeEvent?.depositValues?.cash?.turnedDelivered
                    }}
                    onChange={(e) => {
                      setActiveEvent({
                        ...activeEvent,
                        depositValues: {
                          ...activeEvent?.depositValues,
                          cash: { ...activeEvent?.depositValues?.cash, turnedDelivered: e.value }
                        }
                      })
                    }}
                  />
                </Form.Group>
              </Col>
            </div>
          </Col>

        </div>

      </Col>

      <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>

        <span
          className={tableStyles.hoverClose}
          onClick={handleRemoveDeposit}
        >
          <img
            src={close}
            alt="cerrar"
            style={{ padding: '5px', boxShadow: '0px 0px 2px #ccc', background: 'white', borderRadius: '100%', cursor: 'pointer' }}
          />
        </span>

      </Col>

    </Row>
  )
}