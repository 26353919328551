import { loader } from "../../../helpers/helpers";
import React, { useEffect } from 'react'
import { OrdGenericTemplate } from '../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { Box } from '@mui/material';
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";
import { useTheme } from 'styled-components';
import { Button, Text } from '../../../components/UI/atoms';
import next from "../../../assets/img/icons/ordRightArrow.svg";
import addCheck from "../../../assets/img/icons/add-check.svg";
import modalClose from "../../../assets/img/icons/modalClose.svg";
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import usePatientAdmissionStore from '../../../Hooks/usePatientAdmissionStore';
import { useHistory } from "react-router-dom";
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import Select from 'react-select';
import { useState } from 'react';
import { useGetMethod } from '../../../Hooks/useFetch';
import { useSelector } from 'react-redux';
import { isEmpty } from "../../../helpers";
import { DrawerAndAlert } from "../components";

export const PatientInfoAdditional = () => {

    //  ---------------------------Hooks && LocalStorage-------------------------

    const { activeEvent, setActiveEvent } = usePatientAdmissionStore()

    const {
        patient,
        insurances,
    } = activeEvent

    //  ---------------------------States && Store -------------------------
    const store = useSelector((state) => state);

    const history = useHistory();

    const eaccount = store.loginReducer.currentAccount.id;

    const token = store.loginReducer.Authorization;

    const [formError, setFormError] = useState({})

    const listMarialtStatus = [
        { label: 'Soltero(a)', value: 'single' },
        { label: 'Casado(a)', value: 'married' },
        { label: 'Unión libre', value: 'freeunion' },
        { label: 'Viudo(a)', value: 'widowed' },
        { label: 'Divorciado(a)', value: 'divorced' },
    ]

    const listBloodType = [
        { label: 'A+', value: 'A+' },
        { label: 'A-', value: 'A-' },
        { label: 'B+', value: 'B+' },
        { label: 'B-', value: 'B-' },
        { label: 'AB+', value: 'AB+' },
        { label: 'AB-', value: 'AB-' },
        { label: '0+', value: '0+' },
        { label: '0-', value: '0-' },
    ]

    //  ---------------------------Theme -------------------------
    const { colors } = useTheme()

    //  --------------------------- Validations Form -----------------------

    const handleChangeValidation = (objValidation) => {
        switch (objValidation.type) {
            case 'number':
                validInputTypeNumber(objValidation)
                break;
            case 'text':
                validInputTypeText(objValidation)
                break;
            case 'date':
                validInputTypeDate(objValidation)
                break;
            case 'email':
                validInputTypeEmail(objValidation)
                break;
            default:
                new Error('type no validado')
                break;
        }
    }

    const validForm = () => {

        const insurancesRequire = insurances?.find(el => el?.main === 1)
        delete insurancesRequire?.policyNumber

        const insurancesNotRequire = insurances?.filter((el, i) => el?.main === 0)
        const dataNotRequire = []

        insurancesNotRequire?.forEach(el => {
            dataNotRequire.push(el?.contract, el?.ctypeId, el?.id, el?.main, el?.population)
        })

        const isValidFields =
            !Object.values(formError)?.length
            && !isEmpty(patient?.useGlasses)
            && !isEmpty(patient?.ocupation)
            && Object.values(insurancesRequire)?.every(el => el !== null)
            && dataNotRequire?.every(el => el !== null && el !== undefined && el !== '')

        return isValidFields
    }

    const validInputTypeNumber = ({ name, value, min, max, messageMin, messageMax }) => {

        if (value.trim().length < min) {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: messageMin, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMin}</span>
                }
            }))
            return
        }

        if (value.trim().length > max) {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: messageMax, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMax}</span>
                }
            }))
            return
        }

        const filter = formError
        delete filter[name]
        setFormError(filter)
    }

    const validInputTypeText = ({ name, value, min, max, messageMin, messageFirstLetter, messageMax }) => {

        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(value[0])) {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: messageFirstLetter, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageFirstLetter}</span>
                }
            }))
            return
        }

        if (value.trim().length < min) {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: messageMin, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMin}</span>
                }
            }))
            return
        }

        if (value.trim().length > max) {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: messageMax, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMax}</span>
                }
            }))
            return
        }

        const filter = formError
        delete filter[name]
        setFormError(filter)

    }

    const validInputTypeDate = ({ name, value, maxDate, message, messageMax }) => {
        if (value.trim() === '') {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: message, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{message}</span>
                }
            }))
            return

        }

        if (value.trim() >= maxDate) {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: messageMax, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMax}</span>
                }
            }))
            return

        }

        const filter = formError
        delete filter[name]
        setFormError(filter)

    }

    const validInputTypeEmail = ({ name, value, min, max, messageMin, messageInvalidEmail, messageMax }) => {
        let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

        if (value.trim().length < min) {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: messageMin, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMin}</span>
                }
            }))
            return
        }

        if (value.trim().length > max) {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: messageMax, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMax}</span>
                }
            }))
            return
        }

        if (!validEmail.test(value)) {
            setFormError(state => ({
                ...state,
                [name]: {
                    messageError: messageInvalidEmail, className: 'border border-danger outlineDanger',
                    component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageInvalidEmail}</span>
                }
            }))
            return
        }

        const filter = formError
        delete filter[name]
        setFormError(filter)

    }

    //  --------------------------- Actions -------------------------
    const addInsurer = () => {
        let res = []

        const newInsurer = {
            id: null,
            main: 0,
            contract: null,
            policyNumber: null,
            population: null,
            incomeGroup: null,
        }

        const insurancesVoluntary = insurances?.filter((el, i) => el?.main === 0)
        const insurancesRequired = insurances?.find((el, i) => el?.main === 1)

        res = insurancesVoluntary?.length
            ? [...insurancesVoluntary, insurancesRequired, newInsurer] : [insurancesRequired, newInsurer]

        setActiveEvent({
            ...activeEvent,
            insurances: res
        })

    }

    const removeInsurer = (id) => {
        const insurancesVoluntary = insurances?.filter(el => el.main === 0)?.filter((el, i) => i !== id)

        const insurancesRequired = insurances?.find((el, i) => el?.main === 1)

        setActiveEvent({
            ...activeEvent,
            insurances: insurancesVoluntary?.length ? [...insurancesVoluntary, insurancesRequired] : [insurancesRequired]
        })

    }

    const clearInsurances = () => {

        let res = []

        const insurancesRequire = insurances?.filter(el => el.main === 1)
        const insurancesNotRequire =
            insurances?.filter((el, i) => el?.main === 0)
                ?.map(el => ({
                    contract: el?.contract,
                    ctypeId: el?.ctypeId,
                    id: el?.id,
                    main: el?.main,
                    policyNumber: el?.policyNumber,
                    population: el?.population
                }))

        if (insurancesNotRequire.length) {

            res = [
                ...insurancesNotRequire,
                ...insurancesRequire
            ]

        } else {
            res = insurancesRequire
        }

        setActiveEvent(
            {
                ...activeEvent,
                insurances: res
            }
        )
    }

    const getContractsOptions = (indice) => {

        let res = []

        const insurancesList = insurances?.filter(e => e?.main === 0)

        const contracts = listContracts?.results?.filter(state =>
            state?.corporateClient === insurancesList[indice]?.id)
            ?.map(el => ({ label: el?.contractName, value: el?.id, ctypeId: el?.ctypeId }))

        const ctypeIds = insurancesList?.filter(el => el?.ctypeId !== undefined).map(el => el.ctypeId)

        const ctypeIdsExclude = ctypeIds

        contracts?.forEach(el => {
            if (insurancesList[indice]?.ctypeId) {
                if (el.ctypeId === insurancesList[indice]?.ctypeId) res?.push(el)
            }

            else if (!ctypeIdsExclude?.includes(el?.ctypeId)) {
                res?.push(el)
            }

        })
        return res
    }

    //  --------------------------- Petitions -------------------------
    const {
        results: listCompany,
        load: loaderListCompany,
        trigger: getListCompany
    } = useGetMethod();

    const {
        results: listContracts,
        load: loaderListContracts,
        trigger: getListContracts
    } = useGetMethod();

    const {
        results: listKindship,
        load: loaderListKindship,
        trigger: getListKindship
    } = useGetMethod();

    const {
        results: listOrigen,
        load: loaderListOrigen,
        trigger: getListOrigen
    } = useGetMethod();

    const {
        results: listCopaymentRate,
        load: loaderCopaymentRate,
        trigger: getListCopaymentRate
    } = useGetMethod();

    //  ---------------------------Effects -------------------------

    useEffect(() => {
        getListCompany({
            url: "/coporateClients/",
            objFilters: { id_account: eaccount },
            token: token,
        });

        getListContracts({
            url: "/medical/medicine/getContract/",
            token: token,
        });


        getListCopaymentRate({
            url: "/medical/client/copaymentRate/",
            token: token,
            objFilters: { tm: true }
        });

        getListOrigen({
            url: "/medical/client/contractpopulation/",
            objFilters: { eaccount, status: 'enabled' },
            token: token,
        });

        getListKindship({
            url: "/medical/kindship/",
            objFilters: { eaccount },
            token: token,
        });

        clearInsurances()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //  ---------------------------Component -------------------------
    return (
        <>
            {(loaderListCompany || loaderListKindship || loaderListContracts || loaderListOrigen || loaderCopaymentRate) && loader}

            <OrdGenericTemplate
                rowStyle={{alignItems:'center'}}
                extraHeader={<DrawerAndAlert patientDocument={patient?.document} patientId={patient?.id}/>}
                titleSize={6}
                className={`w-90 mt-4 mx-2 `}
                showBottomBorder={false}
                title={"Admisión del paciente"}
                showBackArrow
                backArrowAction={() => {
                    setActiveEvent({ ...activeEvent, progress: 1 })
                    history.push({ pathname: "/admision/admitirPacientes/informacionBasica" });
                }}
            >
                <ProgressBar bg1={true} className={'mt-4 mb-3'} />

                <div
                    className='my-4'
                    style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'start' }}
                >
                    <Text
                        title={'Información de adicional'}
                        color={colors.ordAquaMarine}
                        fontWeight='bold'
                    />
                    <div
                        style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr' }}
                    >

                        <div
                            style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}
                        >
                            <div>
                                <Text
                                    title={'Ocupación'}
                                    color={colors.darkBlue}
                                    required
                                />

                                <input
                                    type="text"
                                    placeholder="Escribir..."
                                    className={`register-inputs-gray text-secondary ${formError['ocupation'] !== undefined && formError['ocupation'].className}`}
                                    defaultValue={patient?.ocupation || ''}
                                    value={patient?.ocupation}
                                    onChange={({ target }) => {

                                        handleChangeValidation(
                                            {
                                                type: target.type,
                                                value: target.value,
                                                name: 'ocupation',
                                                min: 2,
                                                max: 20,
                                                messageMin: 'Mínimo 2 caracteres',
                                                messageMax: 'Máximo 20 caracteres',
                                                messageFirstLetter: 'Invalido'
                                            })

                                        setActiveEvent(
                                            {
                                                ...activeEvent,
                                                patient: { ...activeEvent.patient, ocupation: target.value }
                                            }
                                        )
                                    }}
                                />
                                {formError['ocupation'] !== undefined ? formError['ocupation'].component : ''}

                            </div>

                            <div>
                                <Text
                                    title={'Estado civil'}
                                    color={colors.darkBlue}
                                />
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={listMarialtStatus}
                                    value=
                                    {{
                                        label: listMarialtStatus.find(el => el.value === patient?.maritalStatus)?.label,
                                        value: patient?.maritalStatus
                                    }}

                                    className="text-secondary w-100 "
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    onChange={(e) => {
                                        setActiveEvent(
                                            {
                                                ...activeEvent,
                                                patient: { ...activeEvent.patient, maritalStatus: e.value }
                                            }
                                        )
                                    }}
                                ></Select>
                            </div>

                            <div>
                                <Text
                                    title={'Grupo Sanguíneo'}
                                    color={colors.darkBlue}
                                />
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={listBloodType}
                                    className="text-secondary w-100 "
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    value=
                                    {{
                                        label: listBloodType.find(el => el.value === patient?.bloodType)?.label,
                                        value: patient?.bloodType
                                    }}
                                    onChange={(e) => {
                                        setActiveEvent(
                                            {
                                                ...activeEvent,
                                                patient: { ...activeEvent.patient, bloodType: e.value }
                                            }
                                        )
                                    }}
                                ></Select>
                            </div>
                        </div>

                        <div
                            style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}
                        >
                            <div>
                                <Text
                                    title={'¿Usa lentes de contacto?'}
                                    color={colors.darkBlue}
                                />

                                <Box display={'flex'} columnGap='20px' ml={'4px'}>
                                    <div className="d-flex">
                                        <input
                                            type="radio"
                                            className={`form-check-input mr-1`}
                                            checked={patient?.useContactLens}
                                            onChange={({ target }) => {
                                                setActiveEvent(
                                                    {
                                                        ...activeEvent,
                                                        patient: { ...activeEvent.patient, useContactLens: target.checked ? 1 : 0 }
                                                    }
                                                )
                                            }}


                                        />
                                        <span className="text-secondary">SI</span>
                                    </div>

                                    <div className="d-flex">
                                        <input
                                            type="radio"
                                            className={`form-check-input mr-1`}
                                            checked={!patient?.useContactLens}
                                            onChange={({ target }) => {
                                                setActiveEvent(
                                                    {
                                                        ...activeEvent,
                                                        patient: { ...activeEvent.patient, useContactLens: target.checked ? 0 : 1 }
                                                    }
                                                )
                                            }}
                                        />
                                        <span className="text-secondary">NO</span>
                                    </div>
                                </Box>
                            </div>

                            <div>
                                <Text
                                    title={'¿Usa gafas?'}
                                    color={colors.darkBlue}
                                    required
                                />

                                <Box display={'flex'} columnGap='20px' ml={'4px'}>
                                    <div className="d-flex">
                                        <input
                                            type="radio"
                                            className={`form-check-input mr-1`}
                                            checked={patient?.useGlasses}
                                            onChange={({ target }) => {
                                                setActiveEvent(
                                                    {
                                                        ...activeEvent,
                                                        patient: { ...activeEvent.patient, useGlasses: target.checked ? 1 : 0 }
                                                    }
                                                )
                                            }}
                                        />
                                        <span className="text-secondary">SI</span>
                                    </div>

                                    <div className="d-flex">
                                        <input
                                            type="radio"
                                            className={`form-check-input mr-1`}
                                            checked={!patient?.useGlasses}
                                            onChange={({ target }) => {
                                                setActiveEvent(
                                                    {
                                                        ...activeEvent,
                                                        patient: { ...activeEvent.patient, useGlasses: target.checked ? 0 : 1 }
                                                    }
                                                )
                                            }}
                                        />
                                        <span className="text-secondary">NO</span>
                                    </div>
                                </Box>
                            </div>
                        </div >

                    </div>
                </div>
                <hr />

                <div
                    className='my-4'
                    style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'start' }}
                >
                    <Text
                        title={'Aseguradora obligatoria'}
                        color={colors.ordAquaMarine}
                        fontWeight='bold'
                    />
                    <div
                        style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr' }}
                    >

                        <div
                            style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}
                        >
                            <div>
                                <Text
                                    title={'Empresa'}
                                    color={colors.darkBlue}
                                    required
                                />

                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    className="text-secondary w-100 "
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    options={listCompany?.results?.map(state => ({ label: state?.copyName, value: Number(state.id) }))}
                                    value=
                                    {{
                                        label: listCompany?.results?.find(state => Number(state?.id) === Number(insurances?.find(e => e?.main === 1)?.id))?.copyName,
                                        value: Number(listCompany?.results?.find(state => Number(state?.id) === Number(insurances?.find(e => e?.main === 1)?.id))?.id)
                                    }}

                                    onChange={({ value }) => {
                                        setActiveEvent(
                                            {
                                                ...activeEvent,
                                                insurances: activeEvent?.insurances?.map(el => {
                                                    if (el?.main === 1) {
                                                        return {
                                                            ...el,
                                                            id: value,
                                                            contract: null,
                                                            incomeGroup: null,
                                                            population: null
                                                        }
                                                    }
                                                    return el
                                                })
                                            }
                                        )
                                    }}
                                />
                            </div>

                            <div>
                                <Text
                                    title={'Contrato'}
                                    color={colors.darkBlue}
                                    required
                                />
                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    className="text-secondary w-100 "
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    options={
                                        listContracts?.results
                                            ?.filter(state => state?.corporateClient === insurances?.find(e => e?.main === 1)?.id)
                                            ?.map(el => ({ label: el?.contractName, value: el?.id }))
                                    }

                                    value=
                                    {{
                                        label: listContracts?.results?.find(state => state?.id === insurances?.find(e => e?.main === 1)?.contract)?.contractName,
                                        value: listContracts?.results?.find(state => state?.id === insurances?.find(e => e?.main === 1)?.contract)?.id
                                    }}
                                    onChange={(e) => {
                                        setActiveEvent(
                                            {
                                                ...activeEvent,
                                                insurances: activeEvent?.insurances?.map(el => el?.main === 1 ? { ...el, contract: e.value } : el)
                                            }
                                        )
                                    }}
                                />


                            </div>

                            <div>
                                <Text
                                    title={'Origen'}
                                    color={colors.darkBlue}
                                    required
                                />
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={
                                        listOrigen?.results?.filter(state => state.idContract === insurances?.find(e => e.main === 1)?.contract)
                                            .map(state => ({ label: state.name, value: state.idPopulation }))
                                    }

                                    value={{
                                        label: listOrigen?.results?.find(e => Number(e.idPopulation) === insurances?.find(e => e.main === 1)?.population)?.name,
                                        value: listOrigen?.results?.find(e => Number(e.idPopulation) === insurances?.find(e => e.main === 1)?.population)?.idPopulation
                                    }}

                                    className="text-secondary w-100 "
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    onChange={(e) => {
                                        setActiveEvent(
                                            {
                                                ...activeEvent,
                                                insurances: activeEvent?.insurances?.map(el => el?.main === 1 ? { ...el, population: e.value } : el)
                                            }
                                        )
                                    }}
                                ></Select>


                            </div>
                        </div>

                        <div
                            style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}
                        >
                            <div>
                                <Text
                                    title={'Grupo de ingreso'}
                                    color={colors.darkBlue}
                                    required
                                />
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={
                                        listCopaymentRate?.results
                                            ?.filter(el => el?.corpclientId === insurances?.find(el => el?.main === 1)?.id)
                                            ?.map(state => ({ label: state.copaymentRateName, value: state.crtId }))
                                    }
                                    value={{
                                        label: listCopaymentRate?.results?.find(e => e.crtId === insurances?.find(e => e.main === 1)?.incomeGroup)?.copaymentRateName,
                                        value: listCopaymentRate?.results?.find(e => e.crtId === insurances?.find(e => e.main === 1)?.incomeGroup)?.crtId
                                    }}
                                    className="text-secondary w-100 "
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    onChange={(e) => {
                                        setActiveEvent(
                                            {
                                                ...activeEvent,
                                                insurances: activeEvent?.insurances?.map(el => el?.main === 1 ? { ...el, incomeGroup: e.value } : el)
                                            }
                                        )
                                    }}
                                ></Select>

                            </div>

                        </div >

                    </div>
                </div>
                <hr />

                <div
                    className='my-4'
                    style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'start' }}
                >
                    <Text
                        title={'Aseguradoras voluntarias'}
                        color={colors.ordAquaMarine}
                        fontWeight='bold'
                    />

                    <div>

                        {
                            insurances?.filter(e => e.main === 0)?.map((el, indice) => (
                                <div
                                    style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr' }}
                                    className='mb-4 '
                                >

                                    <div
                                        style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}
                                    >
                                        <div>
                                            <Text
                                                title={'Empresa'}
                                                color={colors.darkBlue}
                                                required
                                            />

                                            <Select
                                                noOptionsMessage={() => 'No hay datos'}
                                                className="text-secondary w-100 "
                                                placeholder={"Seleccionar..."}
                                                styles={ordCustomSelect}

                                                options={listCompany?.results?.map(state => ({ label: state?.copyName, value: Number(state.id) }))}

                                                value={{
                                                    label: listCompany?.results
                                                        ?.find(state => Number(state?.id) === Number(insurances?.filter(e => e?.main === 0)[indice]?.id))?.copyName,

                                                    value: Number(listCompany?.results
                                                        ?.find(state => Number(state?.id) === Number(insurances?.filter(e => e?.main === 0)[indice]?.id))?.id)
                                                }}

                                                onChange={({ value }) => {
                                                    let res = []

                                                    const insurancesFilter =
                                                        insurances?.filter((el, i) => el?.main === 0)

                                                    if (insurancesFilter.length) {
                                                        const clearInsurance = insurancesFilter[indice]
                                                        clearInsurance.contract = null
                                                        clearInsurance.population = null
                                                        clearInsurance.ctypeId = null
                                                        clearInsurance.id = value

                                                        res = [
                                                            ...insurancesFilter?.map((el, i) => i === indice ? clearInsurance : el),
                                                            ...insurances?.filter(el => el.main === 1)
                                                        ]

                                                    } else {
                                                        res = insurances?.filter(el => el.main === 1)
                                                    }

                                                    setActiveEvent(
                                                        {
                                                            ...activeEvent,
                                                            insurances: res
                                                        }
                                                    )
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <Text
                                                title={'Contrato'}
                                                color={colors.darkBlue}
                                                required
                                            />

                                            <Select
                                                noOptionsMessage={() => 'No hay datos'}
                                                className="text-secondary w-100 "
                                                placeholder={"Seleccionar..."}
                                                styles={ordCustomSelect}
                                                options={getContractsOptions(indice)}

                                                value=
                                                {{
                                                    label: listContracts?.results
                                                        ?.find(state => state?.id === insurances?.filter(e => e?.main === 0)[indice]?.contract)?.contractName,

                                                    value: listContracts?.results
                                                        ?.find(state => state?.id === insurances?.filter(e => e?.main === 0)[indice]?.contract)?.id
                                                }}

                                                onChange={({ value, ctypeId }) => {
                                                    let res = []

                                                    const insurancesFilter =
                                                        insurances?.filter((el, i) => el?.main === 0)

                                                    if (insurancesFilter.length) {
                                                        const clearInsurance = insurancesFilter[indice]
                                                        clearInsurance.contract = value
                                                        clearInsurance.ctypeId = ctypeId
                                                        clearInsurance.incomeGroup = null
                                                        clearInsurance.population = null

                                                        res = [
                                                            ...insurancesFilter?.map((el, i) => i === indice ? clearInsurance : el),
                                                            ...insurances?.filter(el => el.main === 1)
                                                        ]

                                                    } else {
                                                        res = insurances?.filter(el => el.main === 1)
                                                    }

                                                    setActiveEvent(
                                                        {
                                                            ...activeEvent,
                                                            insurances: res
                                                        }
                                                    )
                                                }}
                                            />


                                        </div>

                                        <div>
                                            <Text
                                                title={'Origen'}
                                                color={colors.darkBlue}
                                                required
                                            />
                                            <Select
                                                noOptionsMessage={() => 'No hay datos'}
                                                className="text-secondary w-100 "
                                                placeholder={"Seleccionar..."}
                                                styles={ordCustomSelect}

                                                options={
                                                    listOrigen?.results
                                                        ?.filter(state => state?.idContract === insurances?.filter(e => e?.main === 0)[indice]?.contract)
                                                        ?.map(el => ({ label: el?.name, value: el?.idPopulation }))
                                                }

                                                value=
                                                {{
                                                    label: listOrigen?.results
                                                        ?.find(state => Number(state.idPopulation) === insurances?.filter(e => e?.main === 0)[indice]?.population)?.name,

                                                    value: listOrigen?.results
                                                        ?.find(state => Number(state.idPopulation) === insurances?.filter(e => e?.main === 0)[indice]?.population)?.idPopulation
                                                }}

                                                onChange={({ value }) => {
                                                    let res = []

                                                    const insurancesFilter =
                                                        insurances?.filter((el, i) => el?.main === 0)

                                                    if (insurancesFilter.length) {
                                                        const clearInsurance = insurancesFilter[indice]
                                                        clearInsurance.population = value


                                                        res = [
                                                            ...insurancesFilter?.map((el, i) => i === indice ? clearInsurance : el),
                                                            ...insurances?.filter(el => el.main === 1)
                                                        ]

                                                    } else {
                                                        res = insurances?.filter(el => el.main === 1)
                                                    }

                                                    setActiveEvent(
                                                        {
                                                            ...activeEvent,
                                                            insurances: res
                                                        }
                                                    )
                                                }}
                                            />


                                        </div>
                                    </div>

                                    <div
                                        style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}
                                    >

                                        <div>
                                            <Text
                                                title={'No. póliza'}
                                                color={colors.darkBlue}
                                            />

                                            <input
                                                type="number"
                                                placeholder="Escribir..."
                                                className={`register-inputs-gray text-secondary}`}
                                                value={insurances?.filter(e => e?.main === 0)[indice]?.policyNumber}
                                                onChange={({ target }) => {

                                                    let res = []

                                                    const insurancesFilter =
                                                        insurances?.filter((el, i) => el?.main === 0)

                                                    if (insurancesFilter.length) {
                                                        const clearInsurance = insurancesFilter[indice]
                                                        clearInsurance.policyNumber = target?.value


                                                        res = [
                                                            ...insurancesFilter?.map((el, i) => i === indice ? clearInsurance : el),
                                                            ...insurances?.filter(el => el.main === 1)
                                                        ]

                                                    } else {
                                                        res = insurances?.filter(el => el.main === 1)
                                                    }

                                                    setActiveEvent(
                                                        {
                                                            ...activeEvent,
                                                            insurances: res
                                                        }
                                                    )
                                                }}
                                            />

                                        </div>
                                        <Box display={'flex'} alignItems='end'>
                                            <Text
                                                iconPath={modalClose}
                                                iconAlt='eliminar aseguradora'
                                                handleClick={() => removeInsurer(indice)}
                                                iconPosition='0'
                                                textPosition={'1'}
                                            />
                                        </Box>
                                    </div>

                                </div>
                            ))
                        }


                        <Text
                            title={'Agregar aseguradora'}
                            iconPath={addCheck}
                            iconAlt='Agregar aseguradora'
                            classNameBox={'pointer d-inline-flex'}
                            handleClick={addInsurer}
                        />

                    </div>

                </div>
                <hr />

                <div
                    className='my-4'
                    style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'start' }}
                >
                    <Text
                        title={'Acompañante'}
                        color={colors.ordAquaMarine}
                        fontWeight='bold'
                    />
                    <div
                        style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr' }}
                    >

                        <div
                            style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}
                        >
                            <div>
                                <Text
                                    title={'Nombre'}
                                    color={colors.darkBlue}
                                />


                                <input
                                    type="text"
                                    placeholder="Escribir..."
                                    className={`register-inputs-gray text-secondary ${formError['companionName'] !== undefined && formError['companionName'].className}`}
                                    value={activeEvent?.companion?.name}
                                    onChange={({ target }) => {

                                        handleChangeValidation(
                                            {
                                                type: target.type,
                                                value: target.value,
                                                name: 'companionName',
                                                min: 2,
                                                max: 20,
                                                messageMin: 'Mínimo 2 caracteres',
                                                messageMax: 'Máximo 20 caracteres',
                                                messageFirstLetter: 'Invalido'
                                            })

                                        setActiveEvent({ ...activeEvent, companion: { ...activeEvent.companion, name: target.value } })

                                    }}
                                />
                                {formError['companionName'] !== undefined ? formError['companionName'].component : ''}

                            </div>

                            <div>
                                <Text
                                    title={'Apellido'}
                                    color={colors.darkBlue}
                                />

                                <input
                                    type="text"
                                    placeholder="Escribir..."
                                    className={`register-inputs-gray text-secondary ${formError['companionLastName'] !== undefined && formError['companionLastName'].className}`}
                                    value={activeEvent?.companion?.lastName}
                                    onChange={({ target }) => {

                                        handleChangeValidation(
                                            {
                                                type: target.type,
                                                value: target.value,
                                                name: 'companionLastName',
                                                min: 0,
                                                max: 20,
                                                messageMin: 'Mínimo 0 caracteres',
                                                messageMax: 'Máximo 20 caracteres',
                                                messageFirstLetter: 'Invalido'
                                            })

                                        setActiveEvent({ ...activeEvent, companion: { ...activeEvent.companion, lastName: target.value } })

                                    }}
                                />
                                {formError['companionLastName'] !== undefined ? formError['companionLastName'].component : ''}
                            </div>

                            <div>
                                <Text
                                    title={'Parentesco'}
                                    color={colors.darkBlue}
                                />
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={listKindship?.results?.filter(el=> el?.value !== '')?.map(e => ({ label: e.name, value: e.id }))}
                                    className="text-secondary w-100 "
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    value={{
                                        label: listKindship?.results?.find(e => e.id === activeEvent?.companion?.kinship)?.name,
                                        value: listKindship?.results?.find(e => e.id === activeEvent?.companion?.kinship)?.id,
                                    }}
                                    onChange={(e) => {
                                        setActiveEvent({ ...activeEvent, companion: { ...activeEvent.companion, kinship: e.value } })

                                    }}
                                ></Select>
                            </div>
                        </div>

                        <div
                            style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}
                        >
                            <div>
                                <Text
                                    title={'Dirección'}
                                    color={colors.darkBlue}
                                />

                                <input
                                    type="text"
                                    className={`register-inputs-gray text-secondary ${formError['companionHomeAddress'] !== undefined && formError['companionHomeAddress'].className}`}
                                    placeholder="Escribir..."
                                    value={activeEvent?.companion?.adress}
                                    onChange={({ target }) => {

                                        handleChangeValidation(
                                            {
                                                type: target.type,
                                                value: target.value,
                                                name: 'companionHomeAddress',
                                                min: 0,
                                                max: 20,
                                                messageMin: 'Mínimo 0 caracteres',
                                                messageMax: 'Máximo 20 caracteres',
                                                messageFirstLetter: 'Invalido'
                                            })
                                        setActiveEvent({ ...activeEvent, companion: { ...activeEvent.companion, adress: target.value } })

                                    }}
                                />
                                {formError['companionHomeAddress'] !== undefined ? formError['companionHomeAddress'].component : ''}
                            </div>

                            <div>
                                <Text
                                    title={'Celular'}
                                    color={colors.darkBlue}
                                />

                                <input
                                    type="number"
                                    placeholder="Escribir..."
                                    className={`register-inputs-gray text-secondary ${formError['companionCellphone'] !== undefined && formError['companionCellphone'].className}`}
                                    value={activeEvent?.companion?.cellphone}
                                    onChange={({ target }) => {

                                        handleChangeValidation(
                                            {
                                                type: target.type,
                                                value: target.value,
                                                name: 'companionCellphone',
                                                min: 0,
                                                max: 10,
                                                messageMin: 'Mínimo 0 caracteres',
                                                messageMax: 'Máximo 10 caracteres',
                                                messageFirstLetter: 'Invalido'
                                            })

                                        setActiveEvent({ ...activeEvent, companion: { ...activeEvent.companion, cellphone: target.value } })
                                    }}
                                />
                                {formError['companionCellphone'] !== undefined ? formError['companionCellphone'].component : ''}
                            </div>
                        </div >

                    </div>
                </div>

                <Box className="d-flex justify-content-end my-5 gap-4">
                    <Button 
                        className={`btn d-flex gap-2 justify-content-center align-items-center ${tableStyles.btnIconWhiteHover}`}
                        width='100px'
                        padding='8px'
                        fontSize='14px'
                        border={`2px solid ${colors.ordAquaMarine}`}
                        backgroundColor={'white'}
                        backgroundColorHover={colors.ordAquaMarine}
                        colorHover={'white'}
                        color={colors.ordAquaMarine}
                        onClick={() => {
                            setActiveEvent({ ...activeEvent, progress: 1 })
                            history.push({ pathname: "/admision/admitirPacientes/informacionBasica" });
                        }}
                    >
                        <img 
                            style={{ transform: 'rotate(180deg)' }} 
                            className={`${tableStyles.svgTurquoiseFilter}`}
                            src={next} 
                            alt="next" 
                        />
                        Atrás
                    </Button>

                    <Button
                        className={`btn d-flex gap-2 justify-content-center align-items-center`}
                        disabled={!validForm()}
                        width='130px'
                        padding='8px'
                        fontSize='14px'
                        border='none'
                        backgroundColor={colors.ordAquaMarine}
                        backgroundColorHover={colors.ordAquaMarine + '90'}
                        onClick={() => {
                            setActiveEvent({ ...activeEvent, progress: 3 })
                            history.push({ pathname: "/admision/admitirPacientes/recaudos" });
                        }}
                    >
                        Siguiente
                        <img className={`${tableStyles.iconWhite}`} src={next} alt="next" />
                    </Button>


                </Box>

            </OrdGenericTemplate>

        </>
    )
}
