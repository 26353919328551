import React, { useEffect, useState } from "react";
import { OrdAccordion } from "../OrderingModule/OrderingComponents/OrdAccordion";
import { OrdGenericTemplate } from "../OrderingModule/OrderingComponents/OrdGenericTemplate";
import threeDots from "../assets/img/icons/threeDots.svg";
import { useGetMethod, usePostMethod } from "../Hooks/useFetch";
import { formatToRcSelect, loader, message, validateEmptyString } from "../helpers/helpers";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import tableStyles from "../components/Layouts/tableStyle.module.scss";
import OrdModal from "../OrderingModule/OrderingComponents/OrdModal";
import Select from "react-select";
import { Col, Form, Row } from "react-bootstrap";
import { ordCustomSelect } from "../components/Layouts/react-select-custom";

export const CareGroups = (props) => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const [trigger, setTrigger] = useState(1);
  const [close, setClose] = useState(true);
  const [data, setData] = useState({
    eaccount: idAccount,
    companyTypes: [],
    name: "",
    // suggestions: "",
  });
  const [filters, setFilters] = useState({
    name: "",
    eaccount: idAccount,
  });
  const { results: careGroupResults, load: careGroupLoader, trigger: getCareGroup } = useGetMethod();
  const { load: updateComboLoader, trigger: updateCombo } = usePostMethod();
  useEffect(() => {
    getCareGroup({
      url: "/medical/attentionGroup/",
      objFilters: filters,
      token: token,
    });
    if (close) {
      setClose(false);
    } else {
      setClose(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  //   -------------- CARD ----------------
  const body = (data) => {
    let tempList = [];
    if (Array.isArray(data)) {
      data.map((item, index) => {
        tempList.push(
          <tr key={index + "careGroupResults"} className={``}>
            <td className="col-1 px-1 text-secondary">{item.cTypeName}</td>
          </tr>
        );
      });
      return tempList;
    }
  };
  // const footer = (data) => {
  //   let x = (
  //     <div className="pt-4">
  //       <b className="text-secondary">Recomendaciones</b>
  //       <hr style={{ margin: "0px" }} />
  //       <label className="text-secondary">{data ? data : "-"}</label>
  //     </div>
  //   );
  //   return x;
  // };
  //   -------------- INHABILITE METHOD --------------------------------
  const handleSubmit = (data) => {
    
    let statusEs = ''
    if(data?.status === "enabled") statusEs="habilitado"
    if(data?.status === "disabled") statusEs="deshabilitado"
    
    updateCombo({
      url: "/medical/attentionGroup/",
      token: token,
      method: "PUT",
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
      },
      succesAction: () =>
        message(
          "success",
          `<span class=${tableStyles.ordClearBlueText}>Proceso completado </span>`,
          `Grupo '${data?.name}' ${statusEs} exitosamente`
        ),
    });
  };
  // ------------------------- MODAL ----------------------------
  const [showCareGroupsModal, setShowComboModal] = useState({
    show: false,
    isEditing: false,
  });
  const openNewCombo = () => {
    setShowComboModal({ ...showCareGroupsModal, show: true, isEditing: false });
  };
  const openEditCombo = (item) => {
    let idCompanyType = [];
    setShowComboModal({ ...showCareGroupsModal, show: true, isEditing: true });
    item.companyTypes.forEach((companyTypes) => {
      idCompanyType.push(companyTypes.cTypeId);
    });
    setData({ ...data, name: item.name, companyTypes: idCompanyType, id: item.id }); //suggestions were here
  };

  const closeCareGroupModal = () => {
    setShowComboModal({ ...showCareGroupsModal, show: false, isEditing: false });
    setData({ ...data, companyTypes: [], name: "", id: "" });
  };

  //   -------------- CREATE NEW CARE GROUP ------------------------------

  const { load: createLoader, trigger: createNewCareGroup } = usePostMethod();
  const { load: editLoader, trigger: editCareGroupMethod } = usePostMethod();

  const createCareGroup = () => {
    createNewCareGroup({
      url: "/medical/attentionGroup/",
      token: token,
      method: "POST",
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
        closeCareGroupModal();
      },
      succesAction: () =>
        message(
          "success",
          `<span class=${tableStyles.ordClearBlueText}>Proceso completado </span>`,
          `Combo ${data?.name} creado exitosamente`
        ),
    });
  };

  const editCareGroup = () => {
    editCareGroupMethod({
      url: "/medical/attentionGroup/",
      token: token,
      method: "PUT",
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
        closeCareGroupModal();
      },
      succesAction: () =>
        message(
          "success",
          `<span class=${tableStyles.ordClearBlueText}>Proceso completado </span>`,
          `Grupo de atención: ${data?.name} editado exitosamente`
        ),
    });
  };

  const validationRequest = () => {
    let validations = validateEmptyString([{ field: data.name, message: "El nombre del grupo de atención es obligatorio" }]);
    let servicesValidation =
      data.companyTypes.length > 0
        ? true
        : message(
          "info",
          "Valide la cantidad de tipos de empresa",
          "Para crear un grupo de atención debe seleccionar como mínimo (1) tipo de empresa."
        );

    if (validations.valid && servicesValidation) {
      showCareGroupsModal?.isEditing ? editCareGroup() : createCareGroup();
    } else {
    }
  };

  //   --------------- SERVICES SELECT ---------------

  const { results: companyTypesResults, load: companyTypesLoader, trigger: getCompanyTypes } = useGetMethod();
  useEffect(() => {
    getCompanyTypes({
      url: "/medical/client/companytype/",
      // objFilters: data,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const companyTypeOptions = formatToRcSelect(companyTypesResults?.results, "id", "name", "", "", "");

  const handleAlternatives = (e) => {
    // setCurrentAlternatives(e);
    let arrIdEnd = [];
    if (e.length > 0) {
      e.forEach((elem) => {
        arrIdEnd.push(parseInt(elem.value));
      });
    }
    setData({ ...data, companyTypes: arrIdEnd });
  };

  return (
    <>
      {careGroupLoader && loader}
      {updateComboLoader && loader}
      {createLoader && loader}
      {editLoader && loader}
      {companyTypesLoader && loader}

      <OrdModal
        title={showCareGroupsModal.isEditing ? "Editar grupo de atención" : "Nuevo grupo de atención"}
        show={showCareGroupsModal.show}
        btnYesName={showCareGroupsModal.isEditing ? "Editar" : "Crear"}
        btnNoName={"Cancelar"}
        size={700}
        onHide={() => closeCareGroupModal()}
        btnYesEvent={() => validationRequest()}
        btnNoEvent={() => closeCareGroupModal()}
        sizeBody={`${tableStyles.modalHeight}`}
      >
        <div>
          {/* <Col xs={5}> */}
          <div>
            <Form.Group className="mb-3 text-start" controlId="fName">
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b> &nbsp;Nombre del grupo de atención</b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput `}
                type="text"
                placeholder="Escriba..."
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </Form.Group>
          </div>

          {/* </Col> */}
          <Row className="d-flex justify-content-between">
            <Col xs={12}>
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b> &nbsp;Tipo de compañía</b>
                </Form.Label>
                <Select noOptionsMessage={() => 'No hay datos'}
                  isMulti
                  onChange={(e) => {
                    handleAlternatives(e);
                  }}
                  styles={ordCustomSelect}
                  options={companyTypeOptions}
                  placeholder="Seleccione"
                  className="text-sm "
                  value={companyTypeOptions.filter((item) => data.companyTypes.includes(item.value))}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* <div>
            <Form.Group className="mb-3" controlId="fName">
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <b className="text-start">&nbsp;Recomendaciones</b>
              </Form.Label>
              <textarea
                value={data?.suggestions}
                placeholder={"Escribir..."}
                rows="40"
                cols="50"
                style={{ height: "4rem" }}
                className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
                key={trigger + "observations"}
                onChange={(e) =>
                  setData({
                    ...data,
                    suggestions: e.target.value,
                  })
                }
              ></textarea>
            </Form.Group>
          </div> */}
        </div>
      </OrdModal>
      <OrdGenericTemplate
        className="w-90"
        showBottomBorder={false}
        title={"Configuración de grupos de atención"}
        titleSize={12}
        buttonNewLabel={"Nuevo grupo de atención"}
        buttonNewAction={() => {
          openNewCombo();
        }}
        searchWithNew={{
          colSize: "12",
          WSize: "90",
          buttonNewLabel: "Nuevo grupo",
          onChange: (e) => {
            setFilters({ ...filters, name: e.target.value });
          },
          buttonNewAction: () => openNewCombo(),
          onSubmit: (e) => {
            e.preventDefault();
            setTrigger(trigger + 1);
          },
        }}
      >
        <div className="w-90 mx-1">
          {careGroupResults?.results?.length > 0 &&
            careGroupResults.results.map((item, index) => {
              return (
                <OrdAccordion
                  key={item?.id}
                  triggerSrc={threeDots}
                  title={item?.name}
                  editClickEvent={() => openEditCombo(item)}
                  enableClickEvent={() =>
                    Swal.fire({
                      icon: "warning",
                      title: item?.status?.staValue === "enabled" ? "Deshabilitar grupo de atención" : "Habilitar grupo de atención",
                      text:
                        item?.status?.staValue === "enabled"
                          ? "¿Desea deshabilitar el grupo de atención?"
                          : "¿Desea habilitar el grupo de atención?",
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: item?.status?.staValue === "enabled" ? "Si, deshabilitar" : "Si, habilitar",
                      cancelButtonColor: "#d33",
                      cancelButtonText: "Cancelar",
                      showCancelButton: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        let newStatus = item?.status?.staValue === "enabled" ? "disabled" : "enabled";
                        let idCompanyType = [];
                        item.companyTypes.forEach((companyTypes) => {
                          idCompanyType.push(companyTypes.cTypeId);
                        });
                        handleSubmit({
                          id: item.id,
                          status: newStatus,
                          name: item.name,
                          eaccount: idAccount,
                          companyTypes: idCompanyType,
                          // suggestions: item.suggestions,
                        });
                      }
                    })
                  }
                  isEnabled={item?.status?.staValue === "enabled" ? true : false}
                  status={item?.status?.staDescription}
                  fontcolor={item?.status?.fontColor}
                  backgroundColor={item?.status?.bgColor}
                  body={body(item?.companyTypes)}
                // footer={footer(item?.suggestions)}
                ></OrdAccordion>
              );
            })}
        </div>
      </OrdGenericTemplate>
    </>
  );
};
