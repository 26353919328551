import React, { useEffect, useState } from "react";

import GenericTableNew from "../Layouts/GenericTableNew";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";
import ModalNew from "../Layouts/ModalNew";
import withReactContent from "sweetalert2-react-content";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import CustomPopup from "../Popup/customPopup";
import { Col, Row, Button } from "react-bootstrap";
import { message } from "../../helpers/helpers";
import {
  changeContranct,
  getContractTypesList,
} from "../../actions/tmPayRollActions";
import { useHistory } from "react-router";
import threeDots from "../../assets/img/icons/threeDots.svg";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";



export const ContracTypes = (props) => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const arrContractType = store.tmPayRollReducer?.contractTypesList;
  const [desc, setDesc] = useState("");
  const [benefits, setBenefits] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const [modalAlertControl, setModalAlertControl] = useState({
    showDetails: false,
  });
  let i = 0;
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const history = useHistory();

  useEffect(() => {
    dispatch(getContractTypesList());
  }, [trigger]);

  const handleDetails = (e) => {
    setDesc(e.description);
    setBenefits(e.benefits);
    setModalAlertControl({
      ...modalAlertControl,
      showDetails: true,
    });
  };

  const localStyles = { checkBox: { height: "27px", width: "18%" } };
  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "contType"
    );
  useEffect(() => {
    !myPermission()?.read && history.push("/administracion/inicio");
  }, []);

  const renderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className="col-md-3 text-start px-4 " >
          {elem.description}
        </td>
        <td className="col-md-2 text-center">{elem.collaborators}</td>
        <td className="col-md-2 text-center">{elem.discountHealth}</td>
        <td className="col-md-2 text-center">{elem.discountPension}</td>
        <td className="col-md-2 text-center">{elem.transportationAssistance}</td>
        <td className="col-md-2 text-center">
          <div
            className={
              elem.status ? tableStyles.greenState : tableStyles.redState
            }
          >
            {elem.status ? 'Habilitado': 'Inhabilitado'}
          </div>
        </td>

        <td className="col-md-1 ">
          {!!myPermission()?.edit || !!myPermission()?.delete ? (
            <CustomPopup
              triggerSrc = {threeDots}
              viewDetails={true && !!myPermission()?.edit}
              detailsClcikEvent={() => handleDetails(elem)}
              showEnable={true && !!myPermission()?.delete}
              isEnabled={elem.status}
              enableClickEvent={() =>

                customSwaltAlert({
                  icon: "warning",
                  title: '¿Está seguro?',
                  text: elem.status
                    ? `Se va deshabilitar el tipo de contrato: ${elem.description}` 
                    : `Se va habilitar el tipo de contrato: ${elem.description}`,
                  showCancelButton:true
                }).then((result) => {
                  if (result.isConfirmed) {
                    const send = async () => {
                      try {
                        let result = await changeContranct(
                          elem.id,
                          elem.status === 0 ? 1 : 0,
                          token
                        );
                        setTrigger(trigger + 1);
                        if (result.success) {
                          
                          customSwaltAlert({
                            icon:'success',
                            title:'Actualizado exitosamente',
                            text:`Se ha ${elem.status ? 'deshabilitado': 'habilitado'} el tipo de contrato: ${elem.description}`,
                            showCancelButton:false,
                          })

                        } else {
                          return customSwaltAlert({
                            icon:'warning',
                            title:'Intenta de nuevo',
                            text:result.message,
                            showCancelButton:false,
                            confirmButtonText:'Aceptar',
                          })
                          
                        }
                      } catch (error) {

                        customSwaltAlert({
                          icon:'warning',
                          title:'Intenta de nuevo',
                          text:result.message,
                          showCancelButton:false,
                          confirmButtonText:'Aceptar'
                        })

                      }
                    };
                    send();
                  }
                })
              }
            />
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (arrContractType !== undefined) {
      const elem2 = arrContractType;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={i + 1} className="text-start px-4">
      Descripción
    </th>,
    <th key={i + 1} className="text-center">
      Activos
    </th>,
    <th key={i + 1} className="text-center">
      Salud
    </th>,
    <th key={i + 1} className="text-center">
      Pensión
    </th>,
    <th key={i + 1} className="text-center px-2">
      Aux. Transporte
    </th>,
     <th key={i + 1} className="text-center">
     Estado
   </th>,
    <th key={i + 1}>&nbsp;</th>,
  ];

  return (
    <>
      {/* /* ------------------------------ Contract type modal ------------------------------ */}
      <ModalNew
        // btnYesEvent={}
        btnYesName="Aceptar"
        size="650"
        title={"Tipo de contrato"}
        show={modalAlertControl.showDetails}
        onHide={() =>
          setModalAlertControl({
            ...modalAlertControl,
            showDetails: false,
          })
        }
      >
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <h6 className={`${tableStyles.darkBlueText} mt-2`}>
          <b>Información Básica</b>
        </h6>
        <Row className="d-flex ">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Descripción
              {/* <span className={"text-danger"}>*</span> */}
            </p>
            <input
              value={desc}
              placeholder="Escribe..."
              className="register-inputs text-secondary"
              name="name"
              disabled={true}
            />
          </Col>
        </Row>

        <Row className="d-flex">
          <Col xs={4} className="d-flex pt-3">
            <input
              style={localStyles.checkBox}
              checked={
                benefits.length > 0 && benefits.find((e) => e.name === "ARL")
              }
              placeholder="Escribe..."
              type="checkbox"
              name="name"
              disabled={true}
            />
            <p className={`text-secondary`}>&nbsp;&nbsp;ARL</p>
          </Col>
          <Col xs={4} className="d-flex pt-3">
            <input
              style={localStyles.checkBox}
              placeholder="Escribe..."
              type="checkbox"
              name="name"
              disabled={true}
              checked={
                benefits.length > 0 && benefits.find((e) => e.name === "Salud")
              }
            />
            <p className={`text-secondary`}>&nbsp;&nbsp;Salud</p>
          </Col>
          <Col xs={4} className="d-flex pt-3">
            <input
              style={localStyles.checkBox}
              placeholder="Escribe..."
              type="checkbox"
              name="name"
              disabled={true}
              checked={
                benefits.length > 0 &&
                benefits.find((e) => e.name === "Pensión")
              }
            />
            <p className={`text-secondary`}>&nbsp;&nbsp;Pensión</p>
          </Col>
        </Row>
        <Row className="d-flex justifi-content-center">
          <Col xs={4} className="d-flex pt-3">
            <input
              style={localStyles.checkBox}
              placeholder="Escribe..."
              type="checkbox"
              name="name"
              disabled={true}
              checked={
                benefits.length > 0 && benefits.find((e) => e.name === "Prima")
              }
            />
            <p className={`text-secondary`}>&nbsp;&nbsp;Prima</p>
          </Col>
          <Col xs={4} className="d-flex pt-3">
            <input
              style={localStyles.checkBox}
              placeholder="Escribe..."
              type="checkbox"
              name="name"
              disabled={true}
              checked={
                benefits.length > 0 &&
                benefits.find((e) => e.name === "Cesantías")
              }
            />
            <p className={`text-secondary`}>&nbsp;&nbsp;Cesantias</p>
          </Col>
          <Col xs={4} className="d-flex pt-3">
            <input
              style={localStyles.checkBox}
              placeholder="Escribe..."
              type="checkbox"
              name="name"
              disabled={true}
              checked={
                benefits.length > 0 &&
                benefits.find((e) => e.name === "Vacaciones")
              }
            />
            <p className={`text-secondary`}>&nbsp;&nbsp;Vacaciones</p>
          </Col>
        </Row>
      </ModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Tipos de contrato</h1>
          <GenericTableNew fontFamilyTable={'fontFamilyTable'} className="pb-3" headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>
        </div>
      </div>
    </>
  );
};
