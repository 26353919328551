// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ModalNew from "../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import EditUserPermissions from "./EditUserPermissions";
import Pagination from "react-js-pagination";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
// --------------- icons 💥  ----------------//
import Lupa from "../../assets/img/icons/lupa.svg";
import LupaClear from "../../assets/img/icons/lupaClear.svg";
import Agregar from "../../assets/img/icons/IconoPersona.svg";
import UserGeneral from "../../assets/img/icons/userGeneral.png";
import userLogo from "../../assets/img/header/user-img.png";
import threeDots from "../../assets/img/icons/threeDots.svg";
//-------------- scss styles 😄   --------------
import CustomPopupExtend from "../Popup/customPopUpExtends";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Styles from "./pettyCashStyles.module.scss";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import paginationStyles from "../Layouts/pagination.module.scss";

// ------------------ Functions ----------------------
import {
  userPermission,
  listUsers,
  deleteUserPermission,
} from "../../actions/accountingActions";
import { Title } from "../../shared";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


function UserPermissions(props) {
  const history = useHistory()
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);
  const [triggerModal, setTriggerModal] = useState(1);
  const [Detail, setDetail] = useState({
    show: false,
    selectedUser: ""
  });
  const [showModal, setShowModal] = useState({
    show: false,
  });

  const [info, setInfo] = useState({
    search: "",
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const [info2, setInfo2] = useState({
    search: "",
    eaccount: storage.loginReducer.currentAccount.id,
    module: "pettyCash"
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "userPerm"
    );

  //--------------first functions 🍕   -----------
  useEffect(() => {
    dispatch(userPermission(info));

  }, [trigger]);

  useEffect(() => {
    dispatch(listUsers(info2));
  }, [info2.page, triggerModal]);

  //-------------- Table 🍕   --------------

  const header = [
    <div className={`col-1`}>&nbsp;</div >,
    <div className={`col-11`}>Nombre</div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingReducer.listUsers)) {
      storage.accountingReducer.listUsers.map((item, index) => {
        tempList.push(
          <section2 className={`d-flex`} key={index}>
            <div className="text-center col-1" style={{ paddingLeft: "12px" }}>
              <input
                className=""
                type="radio"
                name="selected"
                disabled={false}
                onChange={() => setDetail({ ...Detail, selectedUser: item.id })}
              />
            </div>
            <div className="text-start col-11" style={{ paddingLeft: "12px" }}>{item.fullName}</div>
          </section2>
        );
      });
    }
    return tempList;
  };
  const handleSumit = (e) => {
    e.preventDefault();
    setTrigger(trigger + 1);
  }

  const handleSearchModal = (e) => {
    e.preventDefault();
    setTriggerModal(triggerModal + 1);
  };



  const myRender =
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >
      {(storage.accountingReducer.permission_loading) &&
        <div className="loading">
          <Loader
            type="Oval"
            color="#003f80"
            height={100}
            width={100}
          />
        </div>
      }
      <div className="d-flex flex-column w-90">
        <div className={` ${tableStyles.title} mb-0`}>
          <Title
            title="Permisos de usuario"
            onClickIcon={() => {
              history.push('/contabilidad/inicio')
            }}
          />
        </div>
        <p className={`${Styles.title7} m-0`}>Usuarios con acceso a caja menor</p>
      </div>
      <Row className="d-flex" style={{ marginTop: "8px" }}>
        <Col xs={6}>
          <form onSubmit={handleSumit}>
            <input
              className={tableStyles.SearchNew}
              placeholder="Buscar..."
              type="text"
              onChange={(e) =>
                setInfo({
                  ...info,
                  search: e.target.value,
                })
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1)
                }
              }}
            ></input>
          </form>
        </Col>
        <Col xs={1}>
          <i>
            <img
              src={Lupa}
              onClick={() => setTrigger(trigger + 1)}
              alt="new"
              className="cursorPointer"
            />
          </i>
        </Col>
        <Col
          xs={5}
          className="text-end"
          onClick={() => setShowModal({ show: true, creat: true })}
        >
          {myPermission()?.create ? (
            <label
              className={Styles.bgBtn}
              style={{ padding: "3px", width: "140px", borderRadius: "10px", fontSize: "12px", fontWeight: "700", fontStyle: "bold", cursor: "pointer" }}
            >
              {" "}
              Agregar Usuario
              <img
                style={{ width: "16px", marginLeft: "7px" }}
                src={Agregar}
              />
            </label>
          ) : (
            ""
          )}
        </Col>
      </Row>

      <div className={`d-lflex`} style={{ maxHeight: "610px", overflowY: "auto", overflowX: "hidden" }}>
        <Row>
          {storage.accountingReducer.pettyCashPermission?.map((x, realindex) => (
            <Col xs={6} key={realindex}>
              <div className={`${Styles.card} d-flex`}>
                <div className={`${Styles.profilepicWrapper} col-2`} style={{ alignSelf: "center", textAlign: "center" }}>
                  <img className={`${Styles.profilepic} ${Styles.imageFormat}`} src={!!x.imageUrl ? x.imageUrl : userLogo} alt="" srcset="" />
                </div>
                <div className="col-6" style={{ alignSelf: "center" }}>
                  <p className={`${Styles.title1} m-0`}> {x.userFullName}</p>
                  <p className={`${Styles.title2} m-0`}>{!!x.jobPosition ? x.jobPosition : "Cargo"}</p>
                  {/* <p className={`${Styles.title3} m-0`}>Último ingreso</p> */}
                </div>
                <div className="col-3" style={{ alignSelf: "center", textAlign: "center" }}>
                  <p className={`${Styles.title4} m-0`}>{x.permissionLabel}</p>
                </div>
                <div className="col-1" style={{ alignSelf: "center", textAlign: "center" }}>
                  <CustomPopupExtend
                    noHover
                    triggerSrc={threeDots}
                    showDelete={myPermission()?.delete}
                    deleteEvent={() => dispatch(deleteUserPermission({ user: x.user, userFullName: x.userFullName, eaccount: storage.loginReducer.currentAccount.id },
                      () => {
                        setTrigger(trigger + 1)
                      }))}
                    showEdit={myPermission()?.edit}
                    editClickEvent={() => { setDetail({ show: true, selectedUser: x.user }) }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <div>
        <ModalNew
          show={showModal.show}
          onHide={() => {
            setShowModal({ show: false });
          }}
          title={"Listado de usuarios"}
          btnYesEvent={() => { setDetail({ ...Detail, show: true }); setShowModal({ show: false }) }}
          btnYesName={"Seleccionar"}
          btnYesDisabled={!!(Detail.selectedUser == "")}
          size={"400"}
        >
          <Row className="d-flex">
            {(storage.accountingReducer.listUsers_loading) &&
              <div className="loading">
                <Loader
                  type="Oval"
                  color="#003f80"
                  height={100}
                  width={100}
                />
              </div>
            }
            <Col xs={10}>
              <form onSubmit={(e) => handleSearchModal(e)}>
                <input
                  type="text"
                  name="name"
                  value={info2.search}
                  style={{ color: "#58595B" }}
                  onChange={(e) =>
                    setInfo2({
                      ...info2,
                      search: e.target.value,
                    })
                  }
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={false}
                />
              </form>
            </Col>
            <Col xs={1}>
              <i>
                <img
                  src={LupaClear}
                  onClick={() => setTriggerModal(triggerModal + 1)}
                />
              </i>
            </Col>
          </Row>

          <GenericTableScroll
            headers={header}
            headerColor={"clear"}
            body={renderList()}
            typeHead={"3"}
          ></GenericTableScroll>

        </ModalNew>


      </div>
    </div>
  return (!!Detail.show) ?
    <EditUserPermissions
      selectedUser={Detail.selectedUser}
      show={Detail.show}
      setDetails={setDetail}
    />
    :
    myRender;
}
export default UserPermissions;
