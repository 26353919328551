import { API_VERSION, defaultFilters, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import {
  GET_NIIF_ACCOUNTS,
  GET_LOCAL_ACCOUNTS,
  SET_INDEX_NIIF,
  SET_INDEX_LOCAL_ACCOUNTS,
  GET_ALL_ACCOUNTS,
  SET_INDEX_PUC,
  GET_BUSINESS_TYPE,
  GET_SOCIETY_TYPE,
  SET_TOTAL_INDEX_PUC,
} from "./actionTypes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { accounts_get } from "./cuentasActions";
import { customSwaltAlert } from "../helpers";

//Fields for sites and messages errors
const requiredFields = {
  description: "descripción",
  code: "número de cuenta",
  active: "estado",
  class_: "clase",
  type: "tipo de cuenta",
  check_format: "check_format",
  type_count: "type_count",
  num_count: "num_count",
  account_type: "account_type"

};

const MySwal = withReactContent(Swal);

// ANCHOR GET ALL ACCOUNTS
export const getAllPUCAccounts = (objFilters) => (dispatch, getState) => {

  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/puc/accounts?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      var { results, row_total, totalIndex } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      if (results)
        results = results.sort((a, b) =>
          new Date(a.create_at) > new Date(b.create_at) ? -1 : 1
        );
      dispatch({
        type: GET_ALL_ACCOUNTS,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_PUC,
        payload: row_total,
      });
      dispatch({
        type: SET_TOTAL_INDEX_PUC,
        payload: totalIndex,
      });
    })
    .catch((err) => {
      console.error(err);
      MySwal.fire({
        icon: "error",
        title: "Error al traer las cuentas",
        text:
          "Ocurrió un error al intentar traer la información de las cuentas.",
        footer: "Si el problema persiste comunícate con un asesor.",
      });
    });
};

// ANCHOR GET METHOD FOR NIIF ACCOUNTS
export const getNiifAccounts = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/niif?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_total } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      dispatch({
        type: GET_NIIF_ACCOUNTS,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_NIIF,
        payload: row_total,
      });
    })
    .catch((error) => {
      console.error(error);

      customSwaltAlert({
        icon:'warning',
        title:'Intenta de nuevo',
        text:`Ocurrió un error al intentar traer la información de las cuentas.`,
        showCancelButton: true
      })

    });
};

// ANCHOR GET NIIF BY NUMBER
export const getNiifByNumber = (number) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const eaccount = getState().loginReducer.currentAccount.id;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/niif/${number}?eaccount=${eaccount}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_NIIF_ACCOUNTS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      console.error(error);
      MySwal.fire({
        icon: "error",
        title: "Error al traer la cuenta",
        text: "Ocurrió un error al intentar traer la información de la cuenta.",
        footer: "Si el problema persiste conmuníquese con un asesor.",
      });
    });
};

// ANCHOR GET LOCAL ACCOUNTS
export const getLocalAccounts = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/localaccount?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_total } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      dispatch({
        type: GET_LOCAL_ACCOUNTS,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_LOCAL_ACCOUNTS,
        payload: row_total,
      });
    })
    .catch((error) => {
      console.error(error);
      MySwal.fire({
        icon: "error",
        title: "Error al traer las cuentas",
        text:
          "Ocurrió un error al intentar traer la información de las cuentas.",
        footer: "Si el problema persiste conmuníquese con un asesor.",
      });
    });
};

export const cleanNiifAccountLinked = () => (dispatch, getState) => {
  dispatch({
    type: GET_NIIF_ACCOUNTS,
    payload: []
  })
}

export const getBusinessType = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/businessType/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: GET_BUSINESS_TYPE,
        payload: results,
      });
    })
    .catch((error) => {
      console.error(error);
      MySwal.fire({
        icon: "error",
        title: "Error al traer las cuentas",
        text:
          "Ocurrió un error al intentar traer los tipos de empresas.",
        footer: "Si el problema persiste conmuníquese con un asesor.",
      });
    });
}
export const getSocietyType = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/societyType/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: GET_SOCIETY_TYPE,
        payload: results,
      });
    })
    .catch((error) => {
      console.error(error);
      MySwal.fire({
        icon: "error",
        title: "Error al traer las cuentas",
        text:
          "Ocurrió un error al intentar traer los tipos de sociedad.",
        footer: "Si el problema persiste conmuníquese con un asesor.",
      });
    });
}

export const creationAccounts = (data, executeThisAfter, executeThisAfterErr) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/export/accounts/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    }
  })
    .then(response => response.json())
    .then(res => {
      if (res.status === 200) {
        customSwaltAlert({
          icon: 'success',
          title: 'Creada exitosamente',
          text: `Se ha creado la cuenta: ${data.bussines_name}`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        dispatch(accounts_get({ page: 1, perpage: 10 }))

        if (!!executeThisAfter) {
          executeThisAfter();
        }
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        if (!!executeThisAfterErr) {
          executeThisAfterErr();
        }
      }
    })
    .catch((err) => {
      console.error(err);
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `${err.message}`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    })
}

export const updateAccounts = (datos, executeThisAfter, executeThisAfterErr) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/composite/accounts/${datos.id}`, {
    method: "PUT",
    body: JSON.stringify(datos),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      
      if (data.code === 400 || data.statusCode === 400) {
        
        executeThisAfterErr()
         return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${data.message}`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      }
      
      customSwaltAlert({
        icon: "success",
        title: "Actualizado exitosamente",
        text: `Se ha actualizado la cuenta: ${datos.bussines_name}`,
        showCancelButton: false
      });

      dispatch(accounts_get({ page: 1, perpage: 10 }))
      if (!!executeThisAfter) {
        executeThisAfter();
      } 
    })
    .catch((err) => {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `${err.message}`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      if (!!executeThisAfterErr) {
        executeThisAfterErr();
      }
    });
}