//import Reacts natives && React Router
import { useHistory, useLocation } from "react-router";
import { useState } from "react";

//import hooks
import { useFormatData } from "../../Hooks/";

//import SASS && Mui material && icons
import { Box } from "@mui/material";
import tableStyle from "../../components/Layouts/tableStyle.module.scss";

//import Icons
import adjuntar from "../../assets/img/icons/AdjuntarRecto.svg";
import countNotes from "../../assets/img/icons/countNotes.svg";
import iconClose from "../../assets/img/icons/close_insurer_voluntary.svg";
import download from "../../assets/img/icons/downloadArrow2.svg";

//import Helpers
import { customSwaltAlertAsistencial } from './../../helpers/customSwaltAlertAsistencial';
import { getFilenameByURL, onChangeFile } from './../../helpers/helpers';

//import Components
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { OrdGenericTemplate } from "../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import { DrawerHistorialChat, ServiceOrderCart } from "./components";
import OrdTable from "../../OrderingModule/OrderingComponents/OrdTable";
import OrdModal from "../../OrderingModule/OrderingComponents/OrdModal";
import { useEffect } from "react";
import moment from "moment";
import Tooltip from "react-bootstrap/Tooltip";

export const ServiceOrderDetail = () => {

  // STATES
  const history = useHistory();
  const { state: dataService } = useLocation()
  const [data, setData] = useState({ showModal: false, showDrawer: false })
  const [activeDocument, setActiveDocument] = useState({
    show: false,
    fileName: '',
    fileUrl: ''
  })


  // TABLE
  const tableHeader = [
    {
      title: "Documento",
      className: "px-2 col-4",
    },
    {
      title: "Fecha adjunto",
      className: "text-center px-2 col-4",
    },
    {
      title: "Estado",
      className: " text-center px-2 col-2",
    },

    {
      title: " ",
      className: "px-2 col-2",
    },
  ];
  const [lastDocument, setLastDocument] = useState('')

  const onToggleDocument = (document) => {

    if (lastDocument !== document?.fileName) {
      setActiveDocument(state => ({
        ...state,
        show: true,
        fileName: document?.fileName,
        fileUrl: document?.fileUrl
      }))
    } else {
      setActiveDocument(state => ({
        ...state,
        show: !state.show,
        fileName: document?.fileName,
        fileUrl: document?.fileUrl
      }))
    }
    setLastDocument(document?.fileName)
  }

  const tableBodyStructure = (item) => [
    {
      text: <div className="pointer" onClick={() => onToggleDocument(item)}>{item?.fileName || '-'}</div>,
      className: `text-start px-2`,
    },
    {
      text: (
        <>
          {moment(item?.modificationDate).format('YYYY-MM-DD') || '-'}
        </>
      ),
      className: "text-center px-2",
    },
    {
      text: (
        // ordGreenBgButton
        <div className={`${tableStyle.ordRedBgButton}`}>
          inválido
        </div>
      ),
      className: "text-center",
    },
    {
      text: (
        <div className="d-flex justify-content-around px-2">
          <img
            alt="adjuntó"
            className={`${tableStyle.filterBlockAdmission} pointer`}
            height={20}
            src={adjuntar}
            width={10}
            onClick={() => editDocument(document)}
          />

          <div
            className="pointer d-flex"
            onClick={() => onStateDrawer(true)}
          >
            {/* ${tableStyle.filterBlockAdmission}  */}
            <img
              alt="notes"
              height={20}
              src={countNotes}
              width={20}
              className={`${tableStyle.filterOrdAquaMarine}`}
            />
            <span
              className={`${tableStyle.textCountNotes} ${tableStyle.filterWhite}`}
            >
              3
            </span>
          </div>

        </div>
      ),
      className: "",
    },
  ];

  // const listTableBody = [{ name: 'documento pdf' }, 2, 3, 4];

  const { formatData } = useFormatData({ tableBodyStructure, listTableBody: dataService?.supportFiles || [] });

  // ACTIONS
  const onStateModal = (stateModal) => setData(state => ({ ...state, showModal: stateModal }))

  const onStateDrawer = (stateDrawer) => setData(state => ({ ...state, showDrawer: stateDrawer }))

  const onClearData = () => setData({})

  const clearDocument = () => setData(state => ({ ...state, fileName: '', filetype: '', File: '' }))

  const editDocument = ({ name }) => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Esta seguro?',
      text: `Se editará el documento: ${name}`,
      showCancelButton: true
    }).then(({ isConfirmed }) => {
      if (isConfirmed) onStateModal(true)
    })
  }

  const changeDocument = (document) => {

    const dataFile = onChangeFile(document)

    dataFile.then(el => {
      setData(state => ({
        ...state,
        ...el
      }))
    })
  }

  // EFFECTS

  useEffect(() => {

    if (dataService === undefined) {
      const idSplit = history.location.pathname.split('/')
      const id = idSplit[idSplit.length - 1]

      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `La orden numero ${id}. No se encuentra en el listado de ordenes de servicio`,
        showCancelButton: false
      })

      history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataService])

  return (
    <>
      <OrdGenericTemplate
        className={`${tableStyle.app_mr_100}`}
        showBackArrow
        title={"Detalle de orden"}
        backArrowAction={() => {
          if (dataService?.depositTypeFilter) {
            history.push({
              pathname: `/admision/detalleCierreDiario/`,
              state: { depositTypeFilter: dataService?.depositTypeFilter, page: dataService?.page }
            });
          } else {
            history.goBack();
          }

        }}
      >
        <Box className={`${tableStyle.ml2rem} mb-5`}>
          <Box
            className={`${tableStyle.ordAquaMarineText} ${tableStyle.fs18px} fw-bold mb-3`}
          >
            Información del paciente
          </Box>

          <ServiceOrderCart data={dataService} />

          <span className={`${tableStyle.ordDarkGrayText} ${tableStyle.fs13px}`}>
            Registrado por:<span className="fw-bold">&nbsp;{dataService?.admittedByName || '-'}, {`${dataService?.admittedDate} ${dataService?.admittedHour}`}</span>
          </span>

          
          {
            dataService?.supportFiles?.length
              ? (
                <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} gap={3} mt={1}>

                  <Box height={"300px"} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                    {activeDocument?.show
                      ? (
                        <Box>
                          {
                            ['png', 'jpg', 'jpeg', 'svg']?.includes(activeDocument?.fileUrl?.split('.')[activeDocument?.fileUrl?.split('.')?.length - 1])
                              ? (
                                <img
                                  className={`${tableStyle.responsiveImg} animate__animated animate__fadeIn `}
                                  src={activeDocument?.fileUrl}
                                  alt={activeDocument?.fileName}
                                />
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 200, hide: 200 }}
                                  overlay={<Tooltip>Descargar</Tooltip>}
                                >
                                  <a className={tableStyle.linkDownload} href={activeDocument?.fileUrl} download>
                                    <span className={`${tableStyle.ordDarkGrayText} ${tableStyle.fs14px}`}>
                                      {getFilenameByURL(activeDocument?.fileUrl)}
                                    </span>
                                    <img height={17} width={17} src={download} alt="descargar documento" />
                                  </a>
                                </OverlayTrigger>
                              )
                          }
                        </Box>
                      )
                      : ''
                    }
                  </Box>

                  <Box>
                    <OrdTable
                      hoverYellow
                      lastBorderNone
                      body={formatData}
                      headers={tableHeader}
                      className="mt-0  w-100"
                    />
                    <Box display={'flex'} justifyContent={'end'} gap={2}>
                      <button className={`${tableStyle.btnCancelAdmission}`}>Cancelar</button>
                      <button className={`${tableStyle.btnSaveAdmission}`}>Guardar</button>
                    </Box>
                  </Box>
                </Box>
              ) : ''
          }


        </Box>
      </OrdGenericTemplate>

      <OrdModal
        title="Nuevo documento"
        show={data?.showModal}
        btnYesName={"Guardar"}
        size={"200"}
        hideCancelButton
        btnYesEvent={() => { }}
        onHide={() => onClearData()}
        btnNoEvent={() => onClearData()}
      >

        <Box>
          <span className={`${tableStyle.ordAquaMarineText}`}>Anexar</span>
          <span className={`${tableStyle.ordOrangeText}`}>*</span>
        </Box>

        <Box>
          <label
            htmlFor="file"
            className={`bg-transparent w-100 
                  ${tableStyle.appInputFileAdmission} 
                  ${tableStyle.tlnTextGray} 
                  ${tableStyle.cursorPointer} 
                  ${tableStyle.f14} 
                  align-self-end mt-2`}
          >

            <Box display={'flex'} gap={1} alignItems={'center'}>
              <span>{data?.fileName ? data?.fileName : "Cargar documento"}</span>
              {data?.fileName && (
                <img
                  width={10}
                  src={iconClose}
                  alt="clearFile"
                  className={tableStyle.styleIconCloseFile}
                  onClick={() => clearDocument()}
                />
              )}
            </Box>

            <input
              id="file"
              type="file"
              name="file"
              accept="application/pdf"
              className="d-none w-100"
              placeholder="Cargar documento"
              onChange={(document) => changeDocument(document)}
            />

            <img src={adjuntar} alt="agregar archivo" height={16} className={`${tableStyle.filterOrdAquaMarine}`} />
          </label>
        </Box>

      </OrdModal>

      <DrawerHistorialChat
        open={data?.showDrawer}
        onClose={() => onStateDrawer(false)}
        title='Seguimiento pendiente'
        chatList={[
          { name: 'jhan', message: 'este es el mensaje del chat este es el mensaje del chat este es el mensaje del chat este es el mensaje del chat este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' },
          { name: 'jhan', message: 'este es el mensaje del chat', date: '10/12/2022 10pm' }
        ]}
      />



    </>
  );

};
