//All actions for Login component
import {
    GET_CURRENCY_ALL,
    LOAD_GET_CURRENCY_ALL,
} from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { customSwaltAlert } from "../helpers/customSwaltAlert";


export const getCurrencyAll = (objFilters) => (dispatch, getState) => {
    dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: true });
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
    const url = typeof objFilters !== 'undefined' ? `${URL_GATEWAY}${API_VERSION}/medical/admissions/currencyDenomination/?${filters}` : `${URL_GATEWAY}${API_VERSION}/medical/admissions/currencyDenomination/`

    fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            const { results, row_total } = res
            dispatch({
                type: GET_CURRENCY_ALL,
                results: results,
                row_total: row_total
            })
            dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: false });
        })
        .catch((err) => {
            console.error(err)
            dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: false });
        })
}

export const currency_edit_active = (codigo, data, description, executeThisAfter) => (dispatch, getState) => {
    
    const token = getState().loginReducer.Authorization;
    let status = data.cdStatus === 'disabled' ? "deshabilitará" : "habilitará"
    let status2 = data.cdStatus === 'disabled' ? "deshabilitado" : "habilitado"
    
    customSwaltAlert({
        icon: 'warning',
        title: `¿Está seguro?`,
        text: `Se ${status} la moneda: ${data.curShortName}`,
        showCancelButton: true,
        confirmButtonText: 'Si, Continuar',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: true });
            fetch(`${URL_GATEWAY}${API_VERSION}/medical/admissions/currencyDenomination/`, {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                }
            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === 200 || res.status === 201) {
                        dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: false });
                        return customSwaltAlert({
                            icon: 'success',
                            title: 'Actualizado exitosamente',
                            text: `Se ha ${status2} la moneda: ${data.curShortName}`,
                            confirmButtonText:'Aceptar',
                            showCancelButton:false
                          })
                
                    } else {
                        
                        customSwaltAlert({
                            icon: 'warning',
                            title: 'Intenta de nuevo',
                            text: `${res.message}`,
                            confirmButtonText: 'Aceptar',
                            showCancelButton: false
                        })

                    }
                    dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: false });
                }).then(
                    () => { executeThisAfter(); }
                )
                .catch((err) => {
                    customSwaltAlert({
                        icon: 'warning',
                        title: 'Intenta de nuevo',
                        text: 'No se pudo cambiar el estado',
                        confirmButtonText: 'Aceptar',
                        showCancelButton: false
                    })

                    dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: false });
                })
        }
    })

}

export const currency_insert = (data, executeThisAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: true });

    fetch(`${URL_GATEWAY}${API_VERSION}/medical/admissions/currencyDenomination/`, {
        method: "POSt",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {

            dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: false });

            if (res.success) {

                customSwaltAlert({
                    icon: 'success',
                    title: 'Creado exitosamente',
                    text: res.title,
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                })

                executeThisAfter()

            } else {
                customSwaltAlert({
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: res?.message,
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                })

                executeThisAfter()
            }


        }).catch((err) => {

            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: err?.message,
                confirmButtonText: 'Aceptar',
                showCancelButton: false
            })

            executeThisAfter()

            dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: false });
        })


}

export const currency_update = (codigo, data, executeThisAfter) => (dispatch, getState) => {

    dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: true });
    const token = getState().loginReducer.Authorization;

    fetch(`${URL_GATEWAY}${API_VERSION}/medical/admissions/currencyDenomination/`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.success) {

                customSwaltAlert({
                    icon: 'success',
                    title: 'Actualizado exitosamente',
                    text: res.title,
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                })

                executeThisAfter()
            } else {

                customSwaltAlert({
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: 'No se pudo actualizar la moneda',
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                })

                executeThisAfter()
            }
            dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: false });
        }).then(
            () => { executeThisAfter(); }
        )
        .catch((err) => {
            console.error(err);

            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'No se pudo actualizar la moneda',
                confirmButtonText: 'Aceptar',
                showCancelButton: false
            })

            dispatch({ type: LOAD_GET_CURRENCY_ALL, loading: false });
        })

}