import React, { useEffect } from "react";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import backArrow from "../../../assets/img/icons/atras-icon.svg";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useGetMethod } from "../../../Hooks/useFetch";
import CardComponent from "./CardComponent/CardComponent";
import { loader } from "../../../helpers/helpers";

const RolDetail = () => {
 const history = useHistory();
 const location = useLocation();
 const store = useSelector((state) => state);
 const idEnterprise = store.loginReducer.currentAccount.id;
 const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "gespermiss");
 const token = store.loginReducer.Authorization;
 const rolInfo = location?.state?.data ?? {};
 const goBackFunction = () => {
  history.push("/administracion/GestionDePermisos");
 };

 const { results: moduleListResults, load: modulelistLoader, trigger: getModuleListMethod } = useGetMethod();
 const permissions = [
  { label: "Crear", value: "create" },
  { label: "Leer", value: "read" },
  { label: "Editar", value: "edit" },
  { label: "Eliminar", value: "delete" },
  { label: "Aprobar", value: "approve" },
  { label: "Rechazar", value: "reject" },
 ];
 useEffect(() => {
  if (!!!myPermission?.read) {
   history.push("/categoryselect");
  }

  getModuleListMethod({
   url: "/security/permissions_by_profile/",
   objFilters: { eaccount: idEnterprise, idProfile: rolInfo.id },
   token: token,
   doAfterSuccess: (res) => {},
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [idEnterprise]);

 const bodyCard = (elem) => {
  return (
   <>
    <Row>
     <Col xs={4}></Col>
     <Col
      xs={8}
      className=" d-flex text-center"
     >
      {permissions.map((per) => {
       return (
        <Col xs={2}>
         <p className={`${tableStyles.crudModalLabel} `}>{per.label}</p>
        </Col>
       );
      })}
     </Col>
    </Row>
    {elem?.sections?.length > 0 &&
     elem?.sections?.map((sec) => {
      return (
       <Row>
        <Col xs={12}>
         <div className="d-flex gap-2">
          <input
           checked={true}
           disabled={true}
           id={`checkBox-${sec.idSection}`}
           key={`keyCheckbox-${sec.idSection}`}
           className={`border border-dark-blue form-check-input p1 check-dark-blue`}
           type="checkbox"
           style={{ width: "16px", height: "16px" }}
          />
          <span className={`${tableStyles.darkBlueDarker} ${tableStyles.f12} mt-1`}>{sec.nameSection}</span>
         </div>
         {sec.pages.length > 0 &&
          sec.pages.map((page) => {
           return (
            <Row>
             <Col xs={4}>
              <div className="d-flex gap-2 mx-4">
               <input
                checked={true}
                disabled={true}
                id={`checkBoxPage-${page.idPage}`}
                key={`keyCheckboxPage-${page.idPage}`}
                className={`border border-dark-blue form-check-input p1 check-dark-blue`}
                type="checkbox"
                style={{ width: "16px", height: "16px" }}
               />
               <span className={`${tableStyles.darkBlueDarker} ${tableStyles.f12} mt-1`}>{page.namePage}</span>
              </div>
             </Col>
             <Col
              xs={8}
              className={`d-flex justify-content-center text-center`}
             >
              {permissions.map((per) => {
               let isSelected = page?.permissions?.[per.value];
               return (
                <Col xs={2}>
                 <input
                  checked={isSelected}
                  disabled={true}
                  id={`Permission-${page.idPage}`}
                  key={`Permission-${page.idPage}`}
                  className={`border border-dark-blue form-check-input p1 check-dark-blue`}
                  type="checkbox"
                  style={{ width: "16px", height: "16px" }}
                 />
                </Col>
               );
              })}
             </Col>
            </Row>
           );
          })}
        </Col>
       </Row>
      );
     })}
   </>
  );
 };

 return (
  <>
  {modulelistLoader && loader}
   <div className="w-80 mx-4">
    <Row>
     <Col xs={10}>
      <h1 className={`${tableStyles.title} d-flex`}>
       <img
        onClick={() => goBackFunction()}
        src={backArrow}
        alt="icon"
        className={`mr-3 cursorPointer`}
       />
       <div className="flex-column">
        <div>Detalle del rol</div>
       </div>
      </h1>
      <br />
      <div
       className={`${tableStyles.f16} ${tableStyles.darkGrayText} `}
       style={{ marginLeft: "40px" }}
      >
       <b className={`${tableStyles.clearBlueText} ${tableStyles.f20} `}> {rolInfo.rolName}</b> <br />
       Módulos a los que tiene permiso este rol
      </div>
     </Col>
    </Row>
    <div className="mx-4">
     <Row
      className="my-4"
      style={{ marginLeft: "10px" }}
     >
      <div className="px-2 ml-2">
       {moduleListResults?.results?.length > 0 &&
        moduleListResults?.results?.map((e) => {
         return (
          <CardComponent
           title={e.name}
           subtitle={e.subtitle}
           body={bodyCard(e)}
           key={e.id + "cars"}
          />
         );
        })}
      </div>
     </Row>
     <Row></Row>
    </div>
   </div>
  </>
 );
};

export default RolDetail;
