import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import tableStyles from "../../Layouts/tableStyle.module.scss";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "../../Layouts/customTabsSimple.scss";
import InhabilityRegister from "./Inhability/InhabilityRegister";
import OvertimeRegister from "./Overtime/OvertimeRegister";
import { RandComplaint } from "./RandComplaint/RandComplaint";
import Surveys from "./Surveys/Surveys";
import PerformanceSurvey from "./performanceSurvey/PerformanceSurvey";

function MiNomina(props) {
  const store = useSelector((state) => state);
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "minoreg"
    );

  const [profile, setProfile] = useState(1);

  return (
    <div className={tableStyles.container} style={{ paddingBottom: "40px" }}>
      <h1 className={tableStyles.title} style={{ marginBottom: 0 }}>
        Registros
      </h1>

      <div className="simpleTabs__Container">
        <TabContainer defaultActiveKey={profile}>
          <Nav className="flex-row simpleTabs">
            <Nav.Item key={1}>
              <Nav.Link eventKey={1} onClick={() => setProfile(1)}>
                Incapacidad
              </Nav.Link>
            </Nav.Item>
            <Nav.Item key={2}>
              <Nav.Link eventKey={2} onClick={() => setProfile(2)}>
                Horas Extras
              </Nav.Link>
            </Nav.Item>
            <Nav.Item key={3}>
              <Nav.Link eventKey={3} onClick={() => setProfile(3)}>
                Reporte y queja
              </Nav.Link>
            </Nav.Item>
            <Nav.Item key={4}>
              <Nav.Link eventKey={4} onClick={() => setProfile(4)}>
                Clima laboral
              </Nav.Link>
            </Nav.Item>
            <Nav.Item key={5}>
              <Nav.Link eventKey={5} onClick={() => setProfile(5)}>
                Evaluación
              </Nav.Link>
            </Nav.Item>
            <div style={{ flex: 1 }} className="simpleTabs__filler"></div>
          </Nav>
          <TabContent
            className="simpleTabs__relative"
            style={{ overflowY: "unset" }}
          >
            <TabPane
              key={"panelTab_1"}
              eventKey={1}
              className="simpleTabs__Area"
            >
              <InhabilityRegister profile={profile} />
            </TabPane>
            <TabPane
              key={"panelTab_2"}
              eventKey={2}
              className="simpleTabs__Area"
            >
              <OvertimeRegister profile={profile} />
            </TabPane>
            <TabPane
              key={"panelTab_3"}
              eventKey={3}
              className="simpleTabs__Area"
            >
              <RandComplaint></RandComplaint>
            </TabPane>
            <TabPane
              key={"panelTab_4"}
              eventKey={4}
              className="simpleTabs__Area"
            >
              <Surveys/>
            </TabPane>
            <TabPane
              key={"panelTab_5"}
              eventKey={5}
              className="simpleTabs__Area"
            >
              <PerformanceSurvey/>
            </TabPane>
          </TabContent>
        </TabContainer>
      </div>
    </div>
  );
}

export default MiNomina;
