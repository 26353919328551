import React, { Component, useState, useEffect } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Excel from "../../assets/img/icons/excel.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import { customSelectNew } from "../../components/Layouts/react-select-custom";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import {
  getClient,
  getBalanceSum,
  getBalanceTotal,
} from "../../actions/receiptOfInvoicesActions";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import CompanyBalance from "./CompanyBalance";
import { filter } from "lodash";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";

function BalanceInquiry(props) {
  const history = useHistory();
  const storage = useSelector((state) => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [details, setDetails] = useState({
    show: false,
    client_id: "",
  });
  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "balanceInq"
    );

  useEffect(() => {
    if (!!!myPermission()?.read) {
      history.push("/cartera/inicio");
    }
  }, []);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: storage.loginReducer.currentAccount.id,
    client: "",
    consolidated: false,
    reportType: true,
  });

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };

  useEffect(() => {
    dispatch(getBalanceSum(filters));
    dispatch(
      getBalanceTotal({
        consolidated: true,
        eaccount: storage.loginReducer.currentAccount.id,
      })
    );
  }, [trigger, filters.page]);

  useEffect(() => {
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
  }, []);

  let optionsClient = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (Array.isArray(storage.invoiceReducer.clients)) {
    storage.invoiceReducer.clients.map((item) => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + "clients",
      });
    });
  }

  const header = [
    <th className="text-start px-3">NIT</th>,
    <th className="text-start px-2">Empresa</th>,
    <th className="text-end px-2">Valor vencido</th>,
    <th className="text-end px-2">Valor por vencer</th>,
    <th className="text-end px-2">Deudas por cobrar</th>,
    <th className="text-end px-2">Anticipos</th>,
    <th className="text-end px-2">Saldo cartera</th>,
    <th className="text-end px-2"></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.balance_sum)) {
      table = storage.invoiceReducer.balance_sum.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">
            <td className="px-2">{x.nit}</td>
            <td className="text-start px-2">{x.client}</td>
            <td className="text-end px-2">{"$" + numberWithCommas(x.expired)}</td>
            <td className="text-end px-2">{"$" + numberWithCommas(x.not_due)}</td>
            <td className="text-end px-2">{"$" + numberWithCommas(x.pending)}</td>
            <td className="text-end px-2">
              {"$" + numberWithCommas(x.anticipated)}
            </td>
            <td className="text-end px-2">
              {"$" + numberWithCommas(x.sum_pending)}
            </td>
            <td className="text-center px-2">
              <img
                className="text-start cursorPointer"
                src={Filtrar}
                onClick={() => setDetails({ show: true, client_id: x.id })}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };
  const footer = [
    <tr>
      <th className="text-center"></th>
      <th className="text-center" style={{ color: "#58595B" }}>
        TOTALES
      </th>
      <th className="text-end p-0" style={{ color: "#58595B" }}>
        {storage.invoiceReducer.balance_total?.expired
          ? "$" + numberWithCommas(storage.invoiceReducer.balance_total.expired)
          : ""}
      </th>
      <th className="text-end p-0" style={{ color: "#58595B" }}>
        {storage.invoiceReducer.balance_total?.not_due
          ? "$" +
          numberWithCommas(storage.invoiceReducer.balance_total?.not_due)
          : ""}
      </th>
      <th className="text-end p-0" style={{ color: "#58595B" }}>
        {storage.invoiceReducer.balance_total?.pending
          ? "$" +
          numberWithCommas(storage.invoiceReducer.balance_total?.pending)
          : ""}
      </th>
      <th className="text-end p-0" style={{ color: "#58595B" }}>
        {storage.invoiceReducer.balance_total?.anticipated
          ? "$" +
          numberWithCommas(storage.invoiceReducer.balance_total?.anticipated)
          : ""}
      </th>
      <th className="text-end p-0" style={{ color: "#58595B" }}>
        {storage.invoiceReducer.balance_total?.sum_pending
          ? "$" +
          numberWithCommas(storage.invoiceReducer.balance_total?.sum_pending)
          : ""}
      </th>
      <th className="text-end "></th>
    </tr>,
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };


  const principalRender = (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >
      {(storage.invoiceReducer.balance_sum_loading) &&
        <div className="loading">
          <Loader
            type="Oval"
            color="#003f80"
            height={100}
            width={100}
          />
        </div>
      }
      
      <div className={tableStyles.title}>
        <Title
          title="Consulta de saldos"
          onClickIcon={() => {
            history.push('/cartera/inicio')
          }}
        />
      </div>

      <div className="d-flex gap-3 align-items-end">
        <div className={tableStyles.containerSelect}>
          <form onSubmit={(e) => handleSearch(e)}>
            <label className={tableStyles.stylesLabel}>Empresa</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar..."
              options={optionsClient}
              className="text-secondary cursorPointer"
              styles={customSelectNewDark}
              onChange={(e) => setFilters({ ...filters, client: e.value })}
            />
          </form>
        </div>

        <div>
          <i>
            <form onSubmit={(e) => handleSearch(e)}>
              <img
                style={{ marginTop: "30px" }}
                src={Lupa}
                alt=""
                srcset=""
                onClick={() => {
                  setTrigger(trigger + 1);
                  setFilters({ ...filters, page: 1 });
                }}
              />
            </form>
          </i>
        </div>

        <div className="text-end" style={{ marginLeft: 'auto' }}>
          <i>
            <img style={{ marginTop: "30px" }} src={Excel} alt="" srcset="" />
          </i>
        </div>

      </div>

      <GenericTable headers={header} footer={footer} dark={true}>
        {renderList()}
      </GenericTable>

      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {storage.invoiceReducer.balance_sum ? filters.page : ""}
            {" de "}
            {Math.ceil(
              storage.invoiceReducer?.balance_sum_total / filters.perpage
            )
              ? Math.ceil(
                storage.invoiceReducer.balance_sum_total / filters.perpage
              )
              : ""}{" "}
            ({storage.invoiceReducer.balance_sum_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={storage.invoiceReducer.balance_sum_total}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </div>
  );

  return !!details.show ? (
    <CompanyBalance
      client_id={details.client_id}
      show={details.show}
      setDetails={setDetails}
    />
  ) : (
    principalRender
  );
}
export default BalanceInquiry;
