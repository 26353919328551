import question from "../../../assets/img/icons/question.svg";
import backArrow from "../../../assets/img/icons/atras-icon.svg";
import React, { useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  formatToRcSelect,
  isEmptyOrUndefined,
  message,
} from "../../../helpers/helpers";
import {
  customSelectNew,

} from "../../Layouts/react-select-custom";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import {
  complementary_docs_get_sections,
  createNewJobPosition,
  getAllJobPositions,
  getWorkTimes,
} from "../../../actions/positionsActions";
import { getConCenter } from "../../../actions/consumptionCenterActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router";
import Loader from "react-loader-spinner";
import { useGetMethod } from "../../../Hooks/useFetch";
import SelectComponent from "../../SelectComponent/SelectComponent";
import ReactSelect from "react-select";
import {components} from "react-select";

export const NewPosition = (props) => {
  const location = useLocation();
  let detail = location.state?.detail;
  let posData = location.state?.data;
  let checkedDocs = isEmptyOrUndefined(location.state?.data?.documents)
    ? []
    : JSON.parse("[" + location.state.data.documents + "]");
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "position"
    );
  // const arrAreas = store.consumptionCenterReducer?.consumption_center;
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const arrJobsPosition = store.positionReducer?.jobsPositionsList;
  const arrWorkTimes = store.positionReducer.workTimes;
  const token = store.loginReducer.Authorization;
  const { results: areaOptionsResults, load: areaOptionsLoader, trigger: getAreaOptions} = useGetMethod()
  useEffect(() => {
    getAreaOptions({
     url: "/consumption_center/",
     objFilters: {
         eaccount: idEnterprise,
         active : 1,
         lite: 1,
         handleResponse: 1,
       },
     token: token,
     doAfterSuccess: (res) => {
     },
    });
}, [])
  const formattedAreas = formatToRcSelect(areaOptionsResults?.results, "id", "description");
  const formattedTimes = formatToRcSelect(arrWorkTimes, "id", "description");
  const { results: dependencies, load: dependenciesLoader, trigger: getDependencies } = useGetMethod()
  const [arrDocs, setArrDocs] = useState([1]);
  const [inputs, setInputs] = useState({
    positionName: "",
    jobFunctions: "",
    selectedArea: null,
    area: {value: null},
    isLeader: false,
    superior: {value: null},
    extraHour: false,
    workTime: {value: null},
    dependency: null
  });
  const [isLoading, setIsLoading] = useState(false)
  const [able, setAble] = useState(false);
  const history = useHistory();
  const formattedPositions = formatToRcSelect(
    arrJobsPosition,
    "id",
    "job_title"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!myPermission?.read) {
      history.push("/login");
    }
    dispatch(complementary_docs_get_sections());

    dispatch(getAllJobPositions({ slim: 1, active: 1, entity_account: store.loginReducer.currentAccount.id }));
    dispatch(
      getConCenter({
        page: 1,
        perpage: 999,
        account: store.loginReducer.currentAccount.id,
      })
    );
    dispatch(getWorkTimes({ slim: 1 }));
    let bodyFilters  = detail? { eaccount: store.loginReducer.currentAccount.id} :{ eaccount: store.loginReducer.currentAccount.id,  active : 1} 
    getDependencies(
      {
        url: `/payroll/dependencies`,
        objFilters:  bodyFilters,
        token: token
      }
    )
  }, []);
  let optionsDependencies = [{ key: "default", value: "", label: "Seleccionar...", id: "" }];
  if (Array.isArray(dependencies?.results)) {
    dependencies?.results.forEach((item) => {
      optionsDependencies.push({
              value: item.id,
              label: item.title,
              key: item.id + "dependency",
          });
      });
  }
  const handleBack = () => {
    history.push("/nomina/cargos");
  };
  const renderSections = () => {
    let sections = [];
    if (Array.isArray(store.positionReducer.comp_docs_sections)) {
      store.positionReducer.comp_docs_sections.map((elem, index) => {
        let docs = [];
        if (Array.isArray(store.positionReducer.comp_docs)) {
          const filteredDocs = store.positionReducer.comp_docs.filter(
            (doc) => doc.section === elem.section
          );
          filteredDocs.map((elem2, index2) => {
            let found;
            checkedDocs[0]?.length > 0
              ? (found = checkedDocs[0].findIndex((obj) => obj === elem2.id))
              : (found = -1);

            const generaterow = (
              <Col xs={12} key={elem2.id} className="d-flex">
                &nbsp;
                {detail ? (
                  <Col xs={1}>
                  <input
                    checked={found !== -1 && true}
                    disabled={detail ? true : false}
                    id={`checkBox-${elem2.id}`}
                    onChange={(e) => handleCheck(elem2, e)}
                    className={`border border-dark-blue form-check-input p1 check-dark-blue`}
                    type="checkbox"
                    style={{width: "16px", height: "16px"}}
                  />
                  </Col>
                ) : elem2.id === 1 ? (
                   <Col xs={1}> 
                  <input
                    checked={true}
                    disabled={true}
                    id={`checkBox-${elem2.id}`}
                    onChange={(e) => handleCheck(elem2, e)}
                    className={`border border-dark-blue form-check-input p1 check-dark-blue`}
                    type="checkbox"
                    style={{width: "16px", height: "16px"}}

                  />
                  </Col> 
                ) : (
                    <Col xs={1}> 
                  <input
                    // checked={elem2.id === 1 ? true : false}
                    disabled={elem2.id === 1 && true}
                    id={`checkBox-${elem2.id}`}
                    onChange={(e) => handleCheck(elem2, e)}
                    className={`border border-dark-blue form-check-input p1 check-dark-blue`}
                    type="checkbox"
                    style={{width: "16px", height: "16px"}}

                  />
                   </Col>
                )}
                &nbsp;
                <Col >
                <label
                  for={`checkBox-${elem2.id}`}
                  className="pb-2 text-secondary"
                >
                  {elem2.description}
                </label>
                </Col>
              </Col>
            );
            docs.push(generaterow);
          });
        }
        const generateSec = (
          <Col xs={4} key={index} 
          style={{whiteSpace: "break-spaces"}}
          >
            <div >
              <p
                style={{ fontSize: "inherit" }}
                className={`
              ${tableStyles.crudModalLabel}
              display-4 pb-2`}
              >
                {elem.section}
              </p>
              {docs}
            </div>
          </Col>
        );
        sections.push(generateSec);
      });
    }
    return (
      <div key={"sectionwrap"} className={"d-flex justify-content-between"}>
        {sections}
      </div>
    );
  };

  const oneOfEachSection = () => {
    if (
      Array.isArray(store.positionReducer.comp_docs) &&
      Array.isArray(store.positionReducer.comp_docs_sections)
    ) {
      const paso0 = store.positionReducer.comp_docs.filter((x) => x.id !== 1);
      const paso1 = paso0
        .filter((item) => arrDocs.includes(item.id))
        .map((x) => x.section);
      const paso2 = paso1.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      const paso3 =
        paso2.length === store.positionReducer.comp_docs_sections.length;

      return paso3;
    }

    return false;
  };

  const handleCheck = (elem2, e) => {
    let bag = [...arrDocs];
    if (e.target.checked) {
      bag.push(elem2.id);
    } else {
      bag = bag.filter((x) => x !== elem2.id);
    }
    setArrDocs(bag);
  };
  useEffect(() => {
    if (
      isEmptyOrUndefined(inputs.positionName) ||
      /* isEmptyOrUndefined(inputs.superior?.value) || */
      isEmptyOrUndefined(inputs.area?.value) ||
      isEmptyOrUndefined(inputs.workTime?.value) ||
      isEmptyOrUndefined(inputs.jobFunctions)
    ) {
      setAble(false);
    } else {
      setAble(true);
    }
  }, [
    inputs.positionName,
    /* inputs.superior?.value, */
    inputs.area?.value,
    inputs.workTime?.value,
    inputs.jobFunctions,
  ]);


  const handleSubmit = async () => {
    if (!oneOfEachSection()) {
      return message(
        "warning",
        "Intenta de nuevo",
        "Debe seleccionar al menos un documento por sección",
        undefined,
        true
      );
    }
    try {
      setIsLoading(true);
      const result = await createNewJobPosition(
        inputs.positionName,
        inputs.jobFunctions,
        inputs.area?.value,
        inputs.area?.label,
        inputs.isLeader,
        inputs.superior?.value,
        inputs.extraHour,
        inputs.workTime?.value,
        idEnterprise,
        1,
        idAccount,
        arrDocs,
        token
        ,inputs.dependency
      );

      if (result.success) {
        setIsLoading(false);
        handleBack();
        return message(
          "success",
          "Creado exitosamente",
          `Se ha creado el cargo: ${inputs.positionName}`,
          undefined,
          true
        );
      } else {
        setIsLoading(false);
        return message("error", "Ha ocurrido un error", result.message,  undefined,
        true
);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return message("error", "Error", error.message,  undefined,
      true
);
    }
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No hay datos</span> 
      </components.NoOptionsMessage>
    );
  };
  /* -------------------------- HANDLE CHANGE SECTION ------------------------- */
  return (
    <div className="w-100 container">
      {(isLoading|| dependenciesLoader || areaOptionsLoader) && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div className={tableStyles.mLeft2}>
      <Row>
        <Col xs={10}>
          <h1 className={`${tableStyles.title}`}
            style={{marginLeft: "-38px"}}
          >
            <img
              onClick={() => handleBack()}
              src={backArrow}
              className={`mr-2 mb-1 cursorPointer`}
              alt="icon"
              height="29px"

            ></img>
            <span className={`${tableStyles.titleAdministrative} mx-2`}>

            {detail ? posData.job_title : "Nuevo cargo"}
              </span>
          </h1>
        </Col>
      </Row>
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <h6 className={`${tableStyles.darkBlueText} mt-2`}>
          <b>Información general</b>
        </h6>
        {/* /* -------------------------------- FIRST ROW ------------------------------- */}
        <Row className="d-flex ">
          <Col xs={4}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Nombre del cargo
              <span className={"text-danger"}>*</span>
            </p>
            <input
              disabled={detail ? true : false}
              placeholder="Escribe..."
              value={detail ? posData.job_title : inputs.positionName}
              onChange={(e) =>
                setInputs({ ...inputs, positionName: e.target.value })
              }
              className="register-inputs text-secondary"
              name="position"
            />
          </Col>

          <Col xs={2}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Dependencia
              <span className={"text-danger"}>*</span>
            </p>

            <ReactSelect
              components={{ NoOptionsMessage }}
              isDisabled={detail ? true : false}
              value={
                detail
                  ? optionsDependencies.find(
                    (obj) => obj.value === posData?.dependencie_id
                  )
                  : optionsDependencies.find(
                    (obj) => obj.id === inputs.dependency
                  )
              }
              className="text-secondary"
              options={optionsDependencies}
              onChange={(e) => setInputs({ ...inputs, dependency: e.value })}
              placeholder={"Seleccionar..."}
              styles={customSelectNew}
            ></ReactSelect>
          </Col>
  
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Cargo superior directo
            </p>

            <ReactSelect
              components={{ NoOptionsMessage }}
              isDisabled={detail ? true : false}
              value={
                detail
                  ? formattedPositions.find(
                    (obj) => obj.value === posData.supervisor_pos
                  )
                  : formattedPositions.find(
                    (obj) => obj.id === inputs.superior?.value
                  )
              }
              className="text-secondary"
              options={formattedPositions}
              onChange={(e) => setInputs({ ...inputs, superior: e })}
              // value={formattedPositions.find(
              //   (obj) => obj.id === inputs.superior?.value
              // )}
              placeholder={"Seleccionar..."}
              styles={customSelectNew}
            ></ReactSelect>
          </Col>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Área asociada
              <span className={"text-danger"}>*</span>
            </p>
            <ReactSelect
            components={{ NoOptionsMessage }}
              isDisabled={detail ? true : false}
              className="text-secondary"
              onChange={(e) => setInputs({ ...inputs, area: e })}
              value={
                detail
                  ? { value: "", label: posData.area_name }
                  : formattedAreas.find((obj) => obj.id === inputs.area?.value)
              }
              options={formattedAreas}
              placeholder={"Seleccionar..."}
              styles={customSelectNew}
            ></ReactSelect>
          </Col>
        </Row>
        {/* /* ------------------------------- SECOND ROW ------------------------------- */}
        <Row className="d-flex ">
          <Col xs={6} style={{ marginTop: "1.6%" }}>
            <div
              style={{ alignSelf: "flex-end", borderRadius: "6px" }}
              className={`d-flex ${tableStyles.bgClearBlue} p-1`}
            >
              <div>
                <input
                  disabled={detail ? true : false}
                  style={{ marginTop: "40%" }}
                  className="border border-dark-blue form-check-input p1 check-dark-blue"
                  type="checkbox"
                  checked={detail ? posData.extra_hours : inputs.extraHour}
                  onChange={(e) =>
                    setInputs({ ...inputs, extraHour: e.target.checked })
                  }
                ></input>
              </div>
              <div>
                <p className={`${tableStyles.crudModalLabel}`}>
                  <b>Derecho a registrar horas extra</b>
                </p>
              </div>
            </div>
          </Col>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Jornada
              <span className={"text-danger"}>*</span>
            </p>
            <ReactSelect
            components={{ NoOptionsMessage }}
              isDisabled={detail ? true : false}
              className="text-secondary"
              options={formattedTimes}
              value={
                detail
                  ? formattedTimes.find(
                    (obj) => obj.value === posData.work_time
                  )
                  : formattedTimes.find(
                    (obj) => obj.id === inputs.workTime?.value
                  )
              }
              // value={formattedTimes.find(
              //   (obj) => obj.id === inputs.workTime?.value
              // )}
              onChange={(e) => setInputs({ ...inputs, workTime: e })}
              placeholder={"Seleccionar..."}
              styles={customSelectNew}
            ></ReactSelect>
          </Col>
          <Col xs={3}>
            <div className="d-flex pt-4">
              <input
                disabled={detail ? true : false}
                style={{ marginTop: "1%" }}
                className="border border-dark-blue form-check-input p1 check-dark-blue"
                checked={detail ? posData.area_leader : inputs.isLeader}
                onChange={(e) =>
                  setInputs({ ...inputs, isLeader: e.target.checked })
                }
                type="checkbox"
              />
              &nbsp;
              <small className="text-secondary">Es líder de area</small>
              &nbsp;
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Será quién reciba y gestione las solicitudes y reportes de
                    los empleados del area
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <img
                    className=" pb-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    src={question}
                    alt="icon"
                  ></img>
                </span>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <Row className="d-flex "></Row>
        <Row className="d-flex">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Funciones del cargo
              <span className={"text-danger"}>*</span>
            </p>
            <textarea
              disabled={detail ? true : false}
              value={detail ? posData.job_description : inputs.jobFunctions}
              placeholder="Escribir..."
              className={`register-inputs text-secondary`}
              style={{ height: "100%" }}
              row="40"
              col="40"
              name="justification"
              type="text"
              onChange={(e) =>
                setInputs({ ...inputs, jobFunctions: e.target.value })
              }
            ></textarea>
          </Col>
        </Row>
        <Row className="d-flex mt-5">
          <Col xs={12}>
            <hr></hr>
            <h6 className={`${tableStyles.darkBlueText} mt-2`}>
              <b>Documentos obligatorios</b>
            </h6>
          </Col>
        </Row>
        {renderSections()}
        <div className={`customTabs__Footer mt-5`}>
        { !!myPermission.create &&
          <Button
            onClick={() => handleSubmit()}
            className={tableStyles.btnPrimary}
            disabled={able ? false : true}
          >
            Guardar
          </Button>
        }

          <Button
            variant="outline-primary"
            onClick={() => handleBack()}
            className={tableStyles.btnSecondary}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
};
