//All actions for user component
import {
  GET_CON_CENTER_ONE,LOADING,
   GET_CON_CENTER, SET_INDEX_CON_CENTER, GET_AUX_NIIF , COSTCENTER_LOAD } from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { responseSwal } from "../helpers/responseSwal";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);


export const getConCenter = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_CON_CENTER,
      loading: true,
    });
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
    fetch(
      `${URL_GATEWAY}${API_VERSION}/consumption_center/params/?${filters}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        const { results, row_total } = res;
       
        dispatch({
          type: GET_CON_CENTER,
          payload: results,
          loading: false,
        });
        dispatch({
          type: SET_INDEX_CON_CENTER,
          payload: row_total,
        });
        dispatch({type: LOADING, payload:false})
      })
      .catch((err) => {
        console.error(err)
        dispatch({
          type: GET_CON_CENTER,
          payload: [],
          loading: false,
        });
        // responseSwal({icon:"error", title:"Error", text:"Ocurrió un error al traer los centros de consumo"})
        
      });
   
  };

  export const getAllConCenter = (objFilters) => (dispatch, getState) => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
    fetch(
      `${URL_GATEWAY}${API_VERSION}/consumption_center/?${filters}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        //const { data } = res;
        //const datalen = Array.isArray(data)? data.length: 0;
       
        dispatch({
          type: GET_CON_CENTER,
          payload: res,
        });
        // dispatch({
        //   type: SET_INDEX_CON_CENTER,
        //   payload: datalen,
        // });
      })
      .catch((err) => {
        console.error(err);
        // responseSwal({icon:"error", title:"Error", text:"Ocurrió un error al traer los centros de consumo"})
      });
   
  };

export const getNiif = (data) => (dispatch, getState) =>{
    const token = getState().loginReducer.Authorization;
    let info = data.toString();
      
    fetch(`${URL_GATEWAY}${API_VERSION}/admin/niifforconsumption/?account=${info}`, {
      method: "GET",
      
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
      }
  })
      .then(response => response.json())
      .then(res => {
          dispatch({type:GET_AUX_NIIF,
            payload: res.niifs
          })
      }).catch(error => console.error(error))

  }



  export const activationDeactivation = (id, status, filters, description) => (
    dispatch,
    getState
  ) => {
    const token = getState().loginReducer.Authorization;
    let active = status ? "deshabilitará" : "habilitará";
    let activet = status ? "deshabilitado" : "habilitado";
    if (status === true) {
      status = 0;
    } else if (status === false) {
      status = 1;
    }
   let data = {
       id: id,
       active: status
   };
    if (id != null && status != null) {
      customSwaltAlert({
        title: `¿Está seguro?`,
        icon: "warning",
        text:`Se ${active} el centro de consumo: ${description}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch({
            type: GET_CON_CENTER,
            loading: true,
          });
          fetch(
            `${URL_GATEWAY}${API_VERSION}/consumption_center/activate/`,
            {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                
                token: token,
              },
            }
          )
            .then((response) => response.json())
            .then((res) => {
              if (res.status || !res.message) {

                customSwaltAlert({
                  icon: "success",
                  title: "Actualizado exitosamente",
                  text:`Se ha ${activet} el centro de consumo: ${description}`,
                  confirmButtonText:'Aceptar',
                  showCancelButton:false
                })
                dispatch(getConCenter(filters));
              } else {
              }
            })
            .catch((err) => {
              customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: "No se pudo cambiar el estado",
                footer: "Si el problema persiste comuníquese con un asesor.",
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })
            })
            .finally(() => {
              dispatch({
                type: GET_CON_CENTER,
                loading: false,
              });
            });;
        }
      });
    } else {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Debe ingresar correo y contraseña para acceder al sistema.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    }
  };


  export const updateCCenter = (obj, filters) => (dispatch, getState) => {
    
    const token = getState().loginReducer.Authorization;
    let {description, users, functional_unit_id, account,annual_budget, effective_start_date, effective_end_date } = obj;
    let combi = [];
    for(let m in obj.consumption_combination){
        
        let obji = {};
        obji.cost_centers_id = obj.consumption_combination[m].cost_centers_id;
        obji.id_inventory_family = obj.consumption_combination[m].id_inventory_family;
        obji.id_niif = obj.consumption_combination[m].id_niif;
        combi.push({...obji});
    }
    const res = {
      description: description,
      users: users,
      functional_unit_id: functional_unit_id,
      consumption_combination: combi,
      account:account,
      annual_budget : annual_budget,
      effective_start_date : effective_start_date,
      effective_end_date : effective_end_date,
    }
    if (description != "" && res.consumption_combination[0] != undefined  ) {
      dispatch({ type:COSTCENTER_LOAD, loading: true });
      var data = obj;
      fetch(`${URL_GATEWAY}${API_VERSION}/consumption_center/${obj.id}`, {
        method: "PUT",
        body: JSON.stringify(res),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
  
          token: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {

          const { success, message} = data
          if(success === true){


              customSwaltAlert({
                icon: "success",
                title: "Actualizado exitosamente",
                text: `Se ha actualizado el centro de consumo: ${obj.description}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })

              dispatch(getConCenter(filters));
          } else{

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text:`${message}`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
          }
          dispatch({ type:COSTCENTER_LOAD, loading: false });
         
        })
        .catch((err) => {
          dispatch({ type:COSTCENTER_LOAD, loading: false });

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

        });
    } else {

      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Debe ingresar los datos obligatorios para actualizar el registro.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    }
  };

  export const createCCenter = (obj, filters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const {description} = obj;
    dispatch({type: LOADING, payload:true})
    dispatch({ type:COSTCENTER_LOAD, loading: true });
    if (description != ""  ) {
      var data = obj;
      fetch(`${URL_GATEWAY}${API_VERSION}/consumption_center/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch({type: LOADING, payload:false})
          dispatch({ type:COSTCENTER_LOAD, loading: false });
          const { success, message} = data
          if(success === true){

            customSwaltAlert({
              icon: "success",
              title:'Creado exitosamente',
              text: `Se ha creado el centro de consumo: ${description}`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
            dispatch(getConCenter(filters));
          } else{
            dispatch({type: LOADING, payload:false})
            dispatch({ type:COSTCENTER_LOAD, loading: false });

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text:`${message}`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
          }
          
            
  
          
        })
        .catch((err) => {
          dispatch({ type:COSTCENTER_LOAD, loading: false });
          dispatch({type: LOADING, payload:false})

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

        });
    } else {
      dispatch({type: LOADING, payload:false})

      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Debe ingresar los datos obligatorios para crear el centro de consumo.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    }
    
  };

  export const getACosumptiontCenter = (id, doAfter) =>(dispatch, getState) =>{
 
    const token = getState().loginReducer.Authorization;
   
    fetch(
      `${URL_GATEWAY}${API_VERSION}/consumption_center/${id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          
          token: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: GET_CON_CENTER_ONE,
          payload: res,
        });
      
      })
      .catch((err) => {
        console.error(err);
        responseSwal({icon:"error", title:"Error", text:"Ocurrió un error al traer el centro de consumo"})
      })
      .then(()=>{
        if(doAfter instanceof Function) doAfter();
      })
  }


  
