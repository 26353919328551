import {
    AUTH_LOADING_CHANGE,
    PURCHASEORDER_MODAL3,
    PURCHASE_ORDER_SUMMARY_GET_ONE,
    PURCHASE_ORDER_SUMMARY_GET_ONE_LOADING
} from "./actionTypes";
import {
    aprove_purchase_order_filter
} from "./IndividualAuctionActions";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
//import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { convertFilterToString } from "../helpers/convertToFilter";
import IndividualStyles from "../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { customSwaltAlert } from "../helpers/customSwaltAlert";
import { GET_PURCHASE_ORDERS, GET_PAY_CONDITIONS, LOADING, PURCHASEORDER_PRINCIPAL } from "./actionTypes";
import { current_auction_filter } from "./IndividualAuctionActions";

const MySwal = withReactContent(Swal)


export const getPurchaseOrders = (params, doAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(params);
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder?${filters}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            accept: 'application/json',
            Authorization: token
        }
    })
        .then(response => response.json())
        .then(res => {
            dispatch({
                type: GET_PURCHASE_ORDERS,
                payload: res.results
            })
            doAfter()
        })
        .catch(() => {
            return customSwaltAlert({
                icon: "error",
                title: "Error",
                text: "Ocurrió un error al traer la información de ordenes de compra",
                showCancelButton: false,
            })
        })
}


export const getPayConditions = (params) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(params);
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/pay-conditions?${filters}`, {
        method: "GET",
        headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.success) {
                dispatch({
                    type: GET_PAY_CONDITIONS,
                    payload: res.results
                })
            } else {
                return customSwaltAlert({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al traer las condiciones de pago",
                    showCancelButton: false,
                })
            }
        })
        .catch(() => {
            return customSwaltAlert({
                icon: "error",
                title: "Error",
                text: "Ocurrió un error al traer las condiciones de pago",
                showCancelButton: false,
            })
        })
}


export const createPurchaseOrderGeneric = (data, doAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    dispatch({ type: LOADING, payload: true })
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchase-order/create`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            dispatch({ type: LOADING, payload: false })
            if (res.success) {
                customSwaltAlert({
                    icon: "success",
                    title: "Orden de compra generada",
                    text: `${res.results}`,
                    showCancelButton: false,
                })
                if (!!doAfter) doAfter();

            } else {
                return customSwaltAlert({
                    icon: "success",
                    title: "Error",
                    text: `${res.message}`,
                    showCancelButton: false,

                })
            }
        })
        .catch(() => {
            dispatch({ type: LOADING, payload: false })
            return customSwaltAlert({
                icon: "error",
                title: "Error",
                text: "Ocurrió un error al crear la orden de compra",
                showCancelButton: false,
            })
        })
}



export const get_many_purchase_orders = (data) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    let filters = convertFilterToString(data);
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/get_many_purchaseOrders/?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.success) {
                dispatch({
                    type: PURCHASEORDER_MODAL3,
                    show: true,
                    codes: res.result,
                })
            }
        })
        .catch((err) => {
            console.error(err);
            customSwaltAlert({
                icon: 'error',
                title: 'Error al buscar Orden de compra',
                footer: 'Si el problema persiste comuníquese con un asesor.',
                showCancelButton: false
            })
        })
}




export const creat_purchaseOrder = (data, executeThisAfter, props) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const separator = (orders) => {
        let clause = ""
        orders.map((x, index) => {
            if (index === 0) {
                clause += x;
            } else {
                clause += " / " + x;
            }

        })
        return clause
    }
    customSwaltAlert({
        icon: "warning",
        showCancelButton: true,
        title: "<span style=color:#01A0F6>¿Está seguro?<span>",
        text: "Se autorizará este producto",
        cancelButtonText: 'Cancelar',
        confirmButtonText: "Si, Continuar",
        focusConfirm: false,
        customClass: {
            confirmButton: `${IndividualStyles.btnPrimary}`,
            cancelButton: `${IndividualStyles.btnSecondary}`
        }


    }).then((result) => {
        if (result.isConfirmed) {
            dispatch({
                type: AUTH_LOADING_CHANGE,
                loading: true,
            })
            fetch(`${URL_GATEWAY}${API_VERSION}/composite/export/purchaseOrder/`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                }
            })
                .then(response => response.json())
                .then(res => {
                    if (res.success && res.ordersGenerated) {
                        dispatch({
                            type: AUTH_LOADING_CHANGE,
                            loading: false
                        })
                        customSwaltAlert({
                            icon: 'success',
                            title: "<span style=color:#01A0F6>Orden de Compra Generada<span>",
                            html: <p>{res.description} <br></br> {separator(res.ordersGenerated)}</p>,
                            confirmButtonText: "Ver",
                            showCancelButton: false

                        }).then((response) => {
                            // //console.log(response)
                            if (response.isConfirmed) {
                                dispatch({
                                    type: PURCHASEORDER_MODAL3,
                                    show: true,
                                    codes: res.ordersGenerated,
                                })
                                dispatch(aprove_purchase_order_filter({ page: 1, perpage: 10, eaccount: getState().loginReducer.currentAccount.id }))
                            } else if (MySwal.DismissReason) {
                                //console.log("ESTOY AQUI")
                                if (!!props) {
                                    props();
                                }
                            }
                            //     //console.log("ESTOY AQUI")
                            //     dispatch({
                            //         type: PURCHASEORDER_PRINCIPAL,
                            //         show:true
                            //     });
                            //     dispatch(current_auction_filter({page:1, perpage:10}))
                            // }
                        })

                    } else if (res.success && res.Rejected_articles) {
                        dispatch({
                            type: AUTH_LOADING_CHANGE,
                            loading: false,
                        })
                        customSwaltAlert({
                            icon: 'success',
                            title: "<span style=color:#01A0F6>Artículos denegados satisfactoriamente <span>",
                            html: <p>{separator(res.id_articles)}</p>,
                            confirmButtonText: "Ok",
                            showCancelButton: false

                        }).then((response) => {
                            if (response.isConfirmed) {
                                dispatch(aprove_purchase_order_filter({ page: 1, perpage: 10, eaccount: getState().loginReducer.currentAccount.id }))
                                if (!!props) {
                                    props();
                                }
                            } else if (MySwal.DismissReason) {
                                if (!!props) {
                                    props();
                                }
                            }
                        })
                    }
                    else {
                        customSwaltAlert({
                            icon: 'error',
                            title: 'Error',
                            text: `${res.message}`,
                            showCancelButton: false
                        })
                    }
                }).then(() => {
                    if (!!executeThisAfter) {
                        executeThisAfter();
                    }
                }
                )
                .catch((err) => {
                    dispatch({
                        type: AUTH_LOADING_CHANGE,
                        loading: false,
                    })
                    console.error(err);
                    customSwaltAlert({
                        icon: 'error',
                        title: 'Error al crear',
                        text: 'No se pudo crear la orden de compra',
                        footer: 'Si el problema persiste comuníquese con un asesor.',
                        showCancelButton: false
                    })
                })
        }
    })
}


export const SumpurchaseOrder_get_one = (code) => (dispatch, getState) => {
    dispatch({
        type: PURCHASE_ORDER_SUMMARY_GET_ONE_LOADING,
        loadingP: true
    })
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrderOne/${code}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            dispatch({
                type: PURCHASE_ORDER_SUMMARY_GET_ONE,
                res: res,
                loading: false
            })
        })
        .catch((err) => {
            console.error(err)
            dispatch({
                type: PURCHASE_ORDER_SUMMARY_GET_ONE_LOADING,
                loading: false
            })

        })
}
