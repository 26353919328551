import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Style from "../../components/Payroll/payrollStyles.module.scss";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Robot from "../../assets/img/icons/bx_bot.svg";
import Tel from "../../assets/img/icons/phone.svg";
import Correo from "../../assets/img/icons/icon_Email.svg";
import Lupa from "../../assets/img/icons/lupa.svg";
import Loader from "react-loader-spinner";
import {
  getClient,
  getDayCalendar,
} from "../../actions/receiptOfInvoicesActions";
import SituationPurseDetail from "./SituationPurseDetail";
import { customSwaltAlert } from "../../helpers";
import { Title } from "../../shared";
// import {GET_DETAIL_CALENDAR} from "../../actions/actionTypes"

function SituationPurse() {
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filter, setFilter] = useState({
    year: "",
    month: "",
    client: ""

  });
  const [details, setDetails] = useState({ show: false });
  const isLoading = counter.invoiceReducer?.loadingCalendar;

  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "taxCalenda"
    );


  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push("/contabilidad/inicio");
    }
    dispatch(
      getDayCalendar({
        year: !!filter.year,
        month: !!filter.month,
        client: !!filter.client,
      })
    );
    // counter.invoiceReducer?.dayCalendar = []
  }, []);
  useEffect(() => {
    dispatch(getClient({ eaccount: counter.loginReducer.currentAccount.id }));
  }, [trigger]);

  const optionMonth = [
    { value: "", label: "Seleccionar..." },
    { value: "1", label: "Enero" },
    { value: "2", label: "Febrero" },
    { value: "3", label: "Marzo" },
    { value: "4", label: "Abril" },
    { value: "5", label: "Mayo" },
    { value: "6", label: "Junio" },
    { value: "7", label: "Julio" },
    { value: "8", label: "Agosto" },
    { value: "9", label: "Septiembre" },
    { value: "10", label: "Octubre" },
    { value: "11", label: "Noviembre" },
    { value: "12", label: "Diciembre" },
  ];

  const optionYear = [{ value: "", label: "Seleccionar..." }];
  for (let step = 2020; step < 2050; step++) {
    optionYear.push({ value: step, label: step });
  }

  let optionsEvents = [
    { key: "", value: "", label: "Seleccionar..." },
  ];
  if (Array.isArray(counter.accountingConsultsReducer.listEventTypes)) {
    counter.accountingConsultsReducer.listEventTypes.forEach((item, index) => {
      optionsEvents.push({
        value: item.id,
        label: item.name,
        key: index + "eventType",
      });
    });
  }
  let optionsClient = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (Array.isArray(counter.invoiceReducer?.clients)) {
    counter.invoiceReducer.clients.forEach((item) => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + "clients",
      });
    });
  }
  const search = () => {
    if (filter.year === "") {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un año',
        showCancelButton: false
      })
    }
    if (filter.month === "") {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un mes',
        showCancelButton: false
      })
    }
    if (filter.client === "") {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione una empresa',
        showCancelButton: false
      })
    }
    dispatch(
      getDayCalendar({
        year: !!filter.year ? filter.year : "",
        month: !!filter.month ? filter.month : "",
        client: !!filter.client ? filter.client : "",
      })
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    search()
    // setFilter({ ...filter, page: 1 });
    // setTrigger(trigger + 1);
  };

  const renderCalendar = (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >
      {isLoading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}

      <div className={tableStyles.title}>
        <Title
          title="Situación de cartera"
          onClickIcon={() => {
            history.push('/cartera/inicio')
          }}
        />
      </div>

      <div className={`d-flex gap-3 align-items-end`}>

        <div className={tableStyles.containerSelect}>
          <form onSubmit={(e) => handleSearch(e)}>
            &nbsp;<label className={tableStyles.stylesLabel}>Año</label>
            <Select noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar..."
              // value={!!filter.year ? optionYear.find(option => option.value == filter.year) : optionYear.find(option => option.value == currYear)}
              options={optionYear}
              styles={customSelectNewDark}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  year: e.value,
                })
              }
            />
          </form>
        </div>

        {!!filter.year && (
          <div className={tableStyles.containerSelect}>
            <form onSubmit={(e) => handleSearch(e)}>
              &nbsp;<label className={tableStyles.stylesLabel}>Mes</label>
              <Select noOptionsMessage={() => 'No hay datos'}
                placeholder="Seleccionar..."
                options={optionMonth}
                styles={customSelectNewDark}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    month: e.value,
                  })
                }
              />
            </form>
          </div>
        )}

        {!!filter.month && (
          <div className={tableStyles.containerSelect}>
            <form onSubmit={(e) => handleSearch(e)}>
              &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
              <Select noOptionsMessage={() => 'No hay datos'}
                placeholder="Seleccionar..."
                options={optionsClient}
                styles={customSelectNewDark}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    client: e.value,
                    clientName: e.label,
                  })
                }
              />
            </form>
          </div>
        )}

        <div>
          &nbsp;<label className={tableStyles.crudModalLabel}>ㅤ</label>
          <form onSubmit={(e) => handleSearch(e)}>
            <img
              src={Lupa}
              className={"cursorPointer"}
              onClick={() =>
                search()
              }
            />
          </form>
        </div>
        <div style={{ flex: 1 }}></div>
      </div>
      <div className="container-month mt-4">
        <div className="container-nameday d-flex flex-nowrap">
          <div
            className={Style.name_day}
            style={{ borderRadius: "10px 0 0 0" }}
          >
            <p>Dom</p>
          </div>
          <div className={Style.name_day}>
            <p>Lun</p>
          </div>
          <div className={Style.name_day}>
            <p>Mar</p>
          </div>
          <div className={Style.name_day}>
            <p>Mié</p>
          </div>
          <div className={Style.name_day}>
            <p>Jue</p>
          </div>
          <div className={Style.name_day}>
            <p>Vie</p>
          </div>
          <div
            className={Style.name_day}
            style={{ borderRadius: "0 10px 0 0" }}
          >
            <p>Sáb</p>
          </div>
        </div>
        <div className={Style.containerDay}>
          {Array.isArray(counter?.invoiceReducer?.dayCalendar) &&
            counter?.invoiceReducer?.dayCalendar?.map((item) => (
              <div
                className={`${Style.number_day}`}
                style={{ height: "150px" }}
              >
                <p className="text-day">{item.day}</p>
                {item?.data?.detail?.length >= 1 ? (
                  <div
                    className={Style.day_icons}
                    style={{ backgroundColor: "#005DBF", marginTop: "5px" }}
                  >
                    <div
                      onClick={() =>
                        setDetails({
                          ...details,
                          show: true,
                          info: item,
                          filter: filter,
                        })
                      }
                    >
                      <b className="text-white">{item?.data.qty}</b>
                      {item?.data?.detail?.map((x) => (
                        <img
                          style={{
                            marginLeft: "5px",
                            width: "15px",
                            verticalAlign: "baseline",
                          }}
                          src={
                            x.icon === "bot"
                              ? Robot
                              : x.icon === "email"
                                ? Correo
                                : x.icon === "phone"
                                  ? Tel
                                  : ""
                          }
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  return !!details.show ? (
    <SituationPurseDetail
      setDetails={setDetails}
      info={details.info}
      filter={details.filter}
      edit={details.edit}
      details={details.details}
      show={details.show}
      trigger={trigger}
    />
  ) : (
    renderCalendar
  );
}
export default SituationPurse;
