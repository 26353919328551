import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UPDATE_ALL_LOGIN_DATA } from './actions/actionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { usePostMethod } from './Hooks/useFetch'
import { Spinner } from 'react-bootstrap'
import { Order } from './OrderingModule/Orders/Order'
export const RedirectionPage = () => {
    let { id, token, clhId, appId } = useParams();
    const store = useSelector((state) => state);
    const dispatch = useDispatch();
    const [error, setError] = useState({
        message: "",
        status: ""
    })
    const [isLoading, setIsLoading] = useState(true)
    const cleanLoginReducer = {
        user_data: {},
        password: "",
        accounts: [],
        currentAccount: null,
        error: null,
        loading: false,
        Authorization: "",
        Authenticated: false,
        all_categories: [],
        newCategories: [],
    }
    /* ----------------------------- FETCHS SECTION ----------------------------- */
    /* ---------------------------------- POST /PUT ---------------------------------- */
    const { results: doLoginResults, trigger: doLogin } = usePostMethod()
    //console.log("doLoginResults: ", doLoginResults);
    /* ------------------------------------ - ----------------------------------- */
    function title() {
        let final = 'Redirigiendo';
        if (id === "1") { final = "Redirigiendo a ordenamientos" }
        if (id === "2") { final = "Redirigiendo a Segunda ruta" }
        return final
    }
    useEffect(() => {
        dispatch({
            type: UPDATE_ALL_LOGIN_DATA,
            payload: cleanLoginReducer,
        })
        doLogin({
            url: "/security/doLogin/",
            token: "-",
            method: "POST",
            body: {
                token: token,
                validate: {
                    clh_id: clhId,
                    app_id: appId,
                },
            },
            noAlert: true,
            doAfterSuccess: (results) => {
                //console.log("results success: ", results);
                dispatch({
                    type: UPDATE_ALL_LOGIN_DATA,
                    payload: results.results,
                });
            },
            doAfterException: (error) => {
                setError(error)
                setIsLoading(false)
                if (error.status === 401) {
                    //console.log("error: ", error.status);
                    window.open("about:blank", "_self");
                    window.close();
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {!store.loginReducer.Authenticated ?

                isLoading ?
                    <div>
                        <div className="d-flex justify-content-center" >

                            <div className="d-flex justify-content-between" >
                                <h1>
                                    {title()}&nbsp;
                                    <Spinner className='pb-2' style={{ width: '8px', height: '2px', marginBottom: '9px' }} variang="info" animation="grow" size='sm' />&nbsp;
                                    <Spinner className='pb-2' style={{ width: '8px', height: '2px', marginBottom: '9px' }} variang="info" animation="grow" size='sm' />&nbsp;
                                    <Spinner className='pb-2' style={{ width: '8px', height: '2px', marginBottom: '9px' }} variang="info" animation="grow" size='sm' /></h1>
                            </div>
                        </div>
                        <div className={`mt-5 d-flex justify-content-center`}>
                            <Spinner style={{ width: "11rem", height: "11rem" }} animation="border" variant="primary" />
                        </div>
                    </div> :
                    <div className="p-0 m-0" >
                        <h1 className="text-center">Ha ocurrido un error.</h1>
                        <br></br>
                        <h3 className="text-primary text-center fwBold"> Status: {error?.status || "N/A"} - {error?.message || "Indefinido"}</h3>
                    </div>
                :
                <Order
                    fromTeleMed={{
                        externalLink: true,
                        data: {
                            document: doLoginResults?.results?.currentAccount?.appointment?.patientDoc,
                            docType: doLoginResults?.results?.currentAccount?.appointment?.docType,
                            clhId: doLoginResults?.results?.currentAccount?.appointment?.clHistory,
                        }
                    }}
                ></Order>
            }
        </>
    )
}
