import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

//styles
import tableStyles from "../../../../components/Layouts/tableStyle.module.scss";

//icons
import orderIcon from "../../../../../src/assets/img/icons/orderIcon.svg";
import OrdLupa from "../../../../../src/assets/img/icons/OrdLupa.svg";
import ordBlueSearch from "../../../../../src/assets/img/icons/ordBlueSearch.svg";
import lightCheckGreen from "../../../../../src/assets/img/icons/lightcheckGreen.svg";
import closeOrange from "../../../../../src/assets/img/icons/closeOrange.svg";


//components
import { OrdGenericTemplate } from "../../../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import { Box } from "@mui/material";
import OrdTable from "../../../../OrderingModule/OrderingComponents/OrdTable";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

//hooks
import { useGetMethod, usePostMethod } from "../../../../Hooks";
import { useHistory } from "react-router";

//helpers
import { convertMoneyFormatNew, customSwaltAlertAsistencial, getPermission, loader } from "../../../../helpers";
import { ServiceOrderReplace } from "../../../../AdmissionModule/Orders";
import { RenderRow } from "../../../../AdmissionModule/Orders/components";
import { ordCustomSelect } from "../../../Layouts/react-select-custom";


export const Cancellations = (props) => {

    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const userId = store.loginReducer.user_data.id;
    const idAccount = store.loginReducer.currentAccount.id;
    // const isCoordAdmin = (store?.loginReducer?.currentAccount?.profile?.prefijo === 'COORADMISIONIST')
    const listPermission = store.loginReducer.currentAccount?.profile?.permission;
    const myPermission = getPermission({ prefix: 'autAnulacion', listPermission })
    const history = useHistory()

    const [filters, setFilters] = useState({
        idUser: userId,
        idAccount,
        isCoordinator: myPermission?.read ? 1 : 0,
        dateMin: undefined,
        dateMax: undefined,
        search: undefined,
        pendingOrders: 1,
        page: 1,
        perpage: 10
    });

    const [dataDetail, setDataDetail] = useState()
    const [modal, setModal] = useState(false)
    const [triggers, setTriggers] = useState(0);

    const [dataHttp, setDataHttp] = useState({
        userId,
        patientId: undefined,
        appStatus: undefined,
        cancelId: undefined,
        cancelObservation: undefined,
    })

    const {
        load: loaderListServiceOrders,
        results: listServiceOrders,
        trigger: getListServiceOrders
    } = useGetMethod()


    const {
        trigger: httpPost,
        load: loaderHttpPost
    } = usePostMethod()

    const headers = [
        {
            title: "No. Orden",
            className: `text-center px-3 ${tableStyles.minWidth100px}`,
        },
        {
            title: "Fecha",
            className: `text-center px-2 ${tableStyles.minWidth100px}`,
        },
        {
            title: "Hora",
            className: `text-center px-2 ${tableStyles.minWidth100px}`,
        },
        {
            title: "Admisionista",
            className: "text-start px-2",
        },
        {
            title: "Tipo",
            className: "text-center px-2",
        },
        {
            title: "Motivo",
            className: "text-start px-2",
        },
        {
            title: "Total",
            className: "text-end px-2",
        },
        {
            title: "Estado",
            className: "text-center px-2",
        },
        {
            title: "",
            className: "text-start px-2",
        },
    ]

    const renderToolService = (text) => (
        <Tooltip>
            <span className={tableStyles.ordDarkGrayText}>{'Servicio'}</span>
        </Tooltip>
    );

    const formatData = (array) => {
        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                tempList.push(
                    <tr key={index} className={`hover-table-row`}>
                        <td className="text-center px-3 ">
                            <div style={{ width: '75px' }}>
                                {item?.id || '-'}
                            </div>
                        </td>
                        <td className="text-center px-2">
                            <div className={tableStyles?.minWidth100px}>
                                {item?.date || '-'}
                            </div>
                        </td>
                        <td className="text-center px-2">
                            <div style={{ width: '75px' }}>
                                {item?.hour || '-'}
                            </div>
                        </td>
                        <td className="text-start px-2">
                            {item?.admisionist?.name || '-'}
                        </td>
                        <td className="text-center px-2">
                            {item?.orderType === 'service' ? (

                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 250 }}
                                        overlay={renderToolService()}
                                    >
                                        <img
                                            className={""}
                                            alt={"orderIcon"}
                                            src={orderIcon}
                                            width={20}
                                        />

                                    </OverlayTrigger>

                                </>
                            ) : ''}

                        </td>
                        <td className={`text-start px-2`}>
                            <div style={{ width: '180px' }} className={`${tableStyles.elipsis}`}>{item?.cancelReason?.label || '-'}</div>
                        </td>
                        <td
                            className="text-end px-2"
                            style={{ width: '180px' }}
                        >
                            {convertMoneyFormatNew({ textNumber: item?.amount }) || '-'}
                        </td>
                        <td className="text-center px-2" >

                            <div
                                style={{
                                    background: item?.status?.background,
                                    padding: '5px 6px',
                                    width: '120px',
                                    borderRadius: '10px',
                                    fontSize: '11px',
                                    color: item?.status?.fontColor,
                                }}
                            >
                                {item?.status?.name}
                            </div>
                        </td>
                        <td className="text-center px-3">
                            <img
                                className={`${tableStyles.svgTurquoiseFilter} cursorPointer`}
                                alt={"arrow"}
                                src={OrdLupa}
                                width={12}
                                onClick={() => {
                                    if (item?.status?.value?.toLowerCase() === 'pending_cancelled') {
                                        setDataHttp(state => ({
                                            ...state,
                                            appStatus: 'cancelled',
                                            cancelId: item?.cancelReason?.value,
                                            cancelObservation: item?.cancelReason?.observation,
                                            patientId: item?.patient?.id,
                                            cancelationData: {
                                                appId: item?.id,
                                                approveCancelation: 0
                                            }
                                        }))
                                    }

                                    if (item?.status?.value?.toLowerCase() === 'pending_replaced') {
                                        setDataHttp(state => ({
                                            ...state,
                                            appStatus: 'replaced',
                                            patientId: item?.patient?.id,
                                            cancelId: item?.cancelReason?.value,
                                            cancelObservation: item?.cancelReason?.observation,
                                            replacementData: {
                                                currentAppId: item?.id,
                                                newAppId: item?.newAppId,
                                                approveCancelation: 1
                                            }
                                        }))
                                    }

                                    setDataDetail(item)
                                    setModal(true)
                                }}
                            />
                        </td>
                    </tr >
                );
            });
        }
        return tempList;
    };

    const printDataInsurance = () => {
        return (
            <>
                <RenderRow title={"Aseguradora"} subTitle={dataDetail?.company?.name || '-'} />
                <RenderRow title={"Contrato"} subTitle={dataDetail?.contractName || '-'} />
                <RenderRow title={"Grupo de ingreso"} subTitle={dataDetail?.service?.incomeGroup || '-'} />
                <RenderRow title={"Origen"} subTitle={dataDetail?.service?.population || '-'} />
            </>
        );
    };

    const printDataCite = () => {
        return (
            <>
                <RenderRow title={"Sede"} subTitle={dataDetail?.service?.siteName || '-'} />
                <RenderRow title={"Médico"} subTitle={dataDetail?.service?.doctor || '-'} />
                <RenderRow
                    title={"Servicio"}
                    subTitle={dataDetail?.service?.name || '-'}
                />
                <RenderRow title={"Fecha"} subTitle={dataDetail?.date || '-'} />
                <RenderRow title={"Hora"} subTitle={dataDetail?.hour || '-'} />
                <RenderRow title={"No. de historia clínica"} subTitle={'N/A' || '-'} />
                <RenderRow
                    title={"Diagnóstico"}
                    subTitleList={dataDetail?.service?.diagnostics}
                    subTitle={dataDetail?.service?.diagnostics?.length ? '' : '-'}

                />
                <RenderRow title={"Total"} subTitle={convertMoneyFormatNew({ textNumber: dataDetail?.service?.price }) || '-'} />
            </>
        );
    };

    const onActionHttpPost = ({ action, patient }) => {

        customSwaltAlertAsistencial({
            icon: "warning",
            title: "¿Está seguro?",
            text: `Se ${action === 'denied' ? 'denegará la orden' : 'autorizará la orden'} ${dataDetail?.id} asignada al paciente: ${patient}`,
            showCancelButton: true,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                httpPost({
                    url: "/medical/admissions/changeServiceOrderStatus/",
                    token: token,
                    method: "POST",
                    body: {
                        ...dataHttp,
                        [dataHttp?.appStatus === 'cancelled' ? 'cancelationData' : 'replacementData']: {
                            ...dataHttp?.[dataHttp?.appStatus === 'cancelled' ? 'cancelationData' : 'replacementData'],
                            approveCancelation: action === 'denied' ? 0 : 1
                        }
                    },
                    succesAction: () => { },
                    doAfterSuccess: (res) => {

                        setDataDetail(undefined)
                        setModal(false)
                        setTriggers(state => state + 1)
                        setDataHttp({
                            userId,
                            patientId: undefined,
                            appStatus: undefined,
                            cancelId: undefined,
                            cancelObservation: undefined,
                        })

                        customSwaltAlertAsistencial({
                            icon: "success",
                            title: "Actualizado exitosamente",
                            // text: `Se ha anulado la orden ${dataDetail?.id} del paciente: ${patient}`,
                            text: res?.message,
                            showCancelButton: false,
                        })


                    },
                    doAfterException: (error) => {
                        customSwaltAlertAsistencial({
                            icon: "warning",
                            title: "Intenta de nuevo",
                            text: error?.message,
                            showCancelButton: false,
                        })

                    }
                })
            }
        })
    }

    useEffect(() => {

        if (myPermission?.read) {
            getListServiceOrders({
                url: "/medical/admissions/serviceOrder/",
                objFilters: filters,
                token: token,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggers])

    useEffect(() => {
        if (!myPermission?.read) {

            customSwaltAlertAsistencial({
                icon: "warning",
                title: "Intenta de nuevo",
                text: `No tiene permiso para ingresar a esta sección`,
                showCancelButton: false,
            }).finally(() => {
                history.push('/admision/inicio/')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>

            {(
                loaderListServiceOrders ||
                loaderHttpPost
            ) && loader}

            {
                myPermission?.read ? (
                    <div style={{ marginRight: '100px' }}>

                        <OrdGenericTemplate
                            className="w-100 mx-2"
                            titleSize={12}
                            colBtnSize={6}
                            colbtnClass={"p-0 m-0"}
                            title={"Autorizar anulaciones de órdenes"}
                        >
                            <Box display={'grid'} gridTemplateColumns={'330px 1fr'} gap={3}>

                                <Box className="d-flex gap-3">
                                    <Box>
                                        <span className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                            <b className="text-start">&nbsp;Fecha desde</b>
                                        </span>
                                        <input
                                            className={`ord-roundInput2`}
                                            style={filters.dateMin ? { color: '#1a538d' } : {}}
                                            type="date"
                                            placeholder=""
                                            value={filters.dateMin}
                                            onChange={(e) => {
                                                setFilters({ ...filters, dateMin: e.target.value, dateMax: '' });
                                                setTriggers(state => state + 1)
                                            }}
                                        />
                                    </Box>

                                    <Box>
                                        <span className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                            <b className="text-start">&nbsp;Hasta</b>
                                        </span>
                                        <input
                                            disabled={!filters?.dateMin}
                                            className={`ord-roundInput2`}
                                            style={filters.dateMax ? { color: '#1a538d' } : {}}
                                            type="date"
                                            value={filters.dateMax}
                                            min={filters.dateMin}
                                            onChange={(e) => {
                                                setFilters({ ...filters, dateMax: e.target.value });
                                                setTriggers(state => state + 1)
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box
                                    className={`d-flex ${tableStyles.ordGrayInputSearch} align-items-center justify-content-between p-2 mt-auto`}
                                    style={{ height: '33px' }}
                                >
                                    <input
                                        className={`ord-roundInput-search w-100 pe-2`}
                                        type="text"
                                        onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                        placeholder="Escribe aquí para buscar"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                setTriggers(state => state + 1);
                                            }
                                        }}
                                    />

                                    <img
                                        alt="buscar"
                                        src={ordBlueSearch}
                                        className="pointer p-0"
                                        onClick={() => setTriggers(state => state + 1)}
                                    >
                                    </img>
                                </Box>

                            </Box>

                            <Box className={'mb-3 asistencialDateColorIcon'}>

                                <OrdTable
                                    shadow
                                    hasChildren={true}
                                    headers={headers}
                                    paginate={{
                                        showTextDetails: true,
                                        activePage: filters.page,
                                        totalPages: listServiceOrders?.rowTotal || 0,
                                        perPage: filters.perpage,
                                        pageRangeDisplayed: 3,
                                        onChangePage: async (e) => {
                                            setFilters({ ...filters, page: e });
                                            setTriggers(state => state + 1)
                                        },
                                    }}
                                >
                                    {formatData(listServiceOrders?.results)}
                                </OrdTable>

                            </Box>
                        </OrdGenericTemplate>


                        <ServiceOrderReplace
                            data={[]}
                            title={"Anulación de orden"}
                            subTitle={`${dataDetail?.patient?.docType} ${convertMoneyFormatNew({ textNumber: dataDetail?.patient?.document, SymbolShow: true, SymbolDecimal: '.' })} - ${dataDetail?.patient?.name}`}
                            show={modal}
                            size={940}
                            onHide={() => {
                                setDataDetail(undefined)
                                setModal(false)
                                setDataHttp({
                                    userId,
                                    patientId: undefined,
                                    appStatus: undefined,
                                    cancelId: undefined,
                                    cancelObservation: undefined,
                                })
                            }}
                            btnNoEvent={() => {
                                setDataDetail(undefined)
                                setModal(false)
                                setDataHttp({
                                    userId,
                                    patientId: undefined,
                                    appStatus: undefined,
                                    cancelId: undefined,
                                    cancelObservation: undefined,
                                })
                            }}
                            dataInsurance={{
                                data: printDataInsurance,
                                title: "Cita original",
                                subTitle: "Datos de aseguradora",
                            }}
                            dataCite={{
                                data: printDataCite,
                                subTitle: "Datos de la cita",
                            }}
                        >
                            <Box>
                                <Box>
                                    <Box>
                                        <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                                            &nbsp;Motivo
                                            <span className={`${tableStyles.ordOrangeText}`}>*</span>
                                        </Box>

                                        <Select
                                            isDisabled={true}
                                            noOptionsMessage={() => "No hay datos"}
                                            value={dataDetail?.cancelReason}
                                            className="text-secondary "
                                            placeholder={"Seleccionar..."}
                                            styles={ordCustomSelect}
                                        />
                                    </Box>

                                    <Box my={2}>
                                        <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                                            &nbsp;Observaciones
                                            <span className={`${tableStyles.ordOrangeText}`}>*</span>
                                        </Box>

                                        <textarea
                                            value={dataDetail?.cancelReason?.observation}
                                            disabled
                                            placeholder="Escribir..."
                                            style={{ minHeight: "265px" }}
                                            className={`text-secondary ordInputAdmission w-100 p-2`}
                                        />
                                    </Box>
                                </Box>

                                <Box display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"} columnGap={2}>
                                    <button
                                        className={`${tableStyles.btnBlue} d-flex justify-content-center gap-2 align-items-center`}
                                        onClick={() => {
                                            setDataDetail(undefined)
                                            setModal(false)
                                        }}
                                    >
                                        Cancelar
                                    </button>

                                    <button
                                        className={`${tableStyles.btnOrange} d-flex justify-content-center gap-2 align-items-center`}
                                        onClick={() => onActionHttpPost({ action: 'denied', patient: dataDetail?.patient?.name })}
                                    >
                                        <div>Denegar</div>
                                        <img src={closeOrange} alt="closeIcon" />
                                    </button>

                                    <button
                                        className={`${tableStyles.btnGreen} d-flex justify-content-center gap-2 align-items-center`}
                                        onClick={() => onActionHttpPost({ action: 'authorize', patient: dataDetail?.patient?.name })}
                                    >
                                        <div>Autorizar</div>
                                        <img src={lightCheckGreen} alt="checkIcon" />
                                    </button>

                                </Box>

                            </Box>

                        </ServiceOrderReplace>
                    </div>
                ) : ''
            }


        </>
    );
};
