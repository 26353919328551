import GenericTableNew from "../Layouts/GenericTableNew";
import Lupa from "../../assets/img/icons/lupa.svg";
import LupaClear from "../../assets/img/icons/lupaClear.svg";
import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg";
import React, { useEffect, useState } from "react";

import {
  current_auction_filter,
} from "../../actions/IndividualAuctionActions";

import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import IndividualStyles from "./IndividualAuction_CCS.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import { Tooltip } from "@material-ui/core";
import {RiCloseCircleFill} from 'react-icons/ri';
import CurrentAuctionDetails from "./CurrentAuctionDetails";
import Loader from "react-loader-spinner";
import { 
  PURCHASEORDER_PRINCIPAL,
} from "../../actions/actionTypes";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
function CurrentAuction(props) {
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  const myPermission =()=> counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "CuAuction");
  const [trigger, setTrigger] = useState(1);
  const [showOpt, setShowOpt] = useState({  
    show: false,
    arrow: false,
    });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    id: "",
    eaccount: counter.loginReducer.currentAccount.id,
    auction_type: "",
    auction_title: "",
    status: "",
    type_date: "",
    fromDate: "",
    toDate: "",
    showModal: false,
  });

  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({
    show: false,
    selectedAuction:{}
  });

  useEffect(() => {
    const { history } = props
        if (!myPermission()?.read){
            history?.push("/compras/inicio");
        
        }
    dispatch(current_auction_filter(filters, ()=> setLoading(false)));
  }, [trigger, filters.page]);

  const ballthingyTooltipName = (backendName) => {
    switch (backendName) {
      case "unanswered":
        return "No respondida";
      case "partially":
        return "Parcialmente respondida";
      case "solved":
        return "Totalmente respondida";
    }
  };

  const typesAuction = (type) => {
    switch (type) {
      case "normal":
        return "Normal";
      case "priority":
        return "Prioritaria";
    }
  };
  const qtyAuction = (mylist) => {
    let valval = 0;
    if(Array.isArray(mylist)){
      valval = mylist.reduce((total, currentValue) =>{
        if(!isNaN(currentValue.qty_article)){
          return total + currentValue.qty_article;
        }else{
          return total;
        }
      }
      , 0)
    }
    return valval;
  };

  const renderHeaders = [
    <th key={`reqTH2`} className="px-2">Vence</th>,
    <th key={`reqTH3`} className="px-2 text-center">Productos</th>,
    <th key={`reqTH4`} className="px-2 text-center">Tipo de subasta</th>,
    <th key={`reqTH5`} className="px-2 text-start">Titulo de subasta</th>,
    <th key={`reqTH6`} className="px-2 text-center">Inicio</th>,
    <th key={`reqTH7`} className="px-2 text-center">Estado</th>,
    <th key={`reqTH99`}></th>,
  ];

  const ShowOptions = ()=>{
    if (showOpt?.show && showOpt?.arrow){
      setShowOpt({
        ...showOpt,
        show: false,
        arrow: false,
        });}else{
          setShowOpt({
            ...showOpt,
            show: true,
            arrow: true,
            });
        }
  };


//   useEffect(() => {
//     if(details.show){
//         dispatch({
//             type: PURCHASEORDER_PRINCIPAL,
//             show:true
//         });
//     }else{
//         dispatch({
//             type: PURCHASEORDER_PRINCIPAL,
//             show:false
//         });
//     }
// }, [details.show]);


const btnNext =(x) => {
  dispatch({
      type: PURCHASEORDER_PRINCIPAL,
      show:true
  });
  setDetails({show: true, selectedAuction: x})

};



  const renderList = () => {
    let table = [];

    if (Array.isArray(counter.individualAuctionReducer.auctions)) {
      table = counter.individualAuctionReducer.auctions.map((x) => {

        let justincase = x.date_expiry? ""+x.date_expiry : "-";
       
        return (
          <tr key={"renderList" + x.id} className="hover-table-row">
            <td className="px-2 text-start">{justincase.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}</td>
            <td>{ qtyAuction(x.article_list) }</td>
            <td>{typesAuction(x.priority)}</td>
            <td className="px-2 text-start">{x.description}</td>
            <td>{x.created_at.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}</td>
            <td>
              <Tooltip title={ballthingyTooltipName(x.status)} arrow>
                <div
            className="rounded-pill p-1 mx-auto"
            style={{
              width: 200,
              backgroundColor: x?.status === "unanswered" ? "#FEF7F5" : x?.status=== "partially" ? "rgb(254, 250, 238)" : "#FAFDF6" ,
            }}
          >

            <b style={{ color: x?.status === "unanswered" ? "#F39682" : x?.status=== "partially" ? "rgb(233, 180, 25)" : "#83C036" }}>
            {ballthingyTooltipName(x.status)}
            </b>
          </div>
              </Tooltip>
            </td>
            <td>
              <img
                src={lupaTransparent}
                alt="detalles"
                className={`icons-popUp ${IndividualStyles.adjustIcon3} hoverPointer`}
                //onClick={() => setFilters({ ...filters, showModal: true })}
                onClick={()=> btnNext(x)}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const paginateList = () => {
    const fullList = renderList();
    return fullList.slice(
      filters.perpage * (filters.page - 1),
      filters.perpage * filters.page
    );
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({...filters,page: 1,perpage: 10});  
    setTrigger(trigger + 1); 
    setLoading(true)
    
};

const AcutionTypeOptions = [{value: "", label: "Seleccionar..."},{value: "normal", label: "Normal"},{value: "priority", label: "Prioritaria"}]


const AcutionStatusOptions = [
  {value: "", label: "Seleccionar..."},
  {value:"unanswered", label: "No respondida"},
  {value:"partially", label: "Parcialmente respondida"},
  {value:"solved", label: "Totalmente respondido"}
]

const AcutionDateOptions = [
  {value: "", label: "Seleccionar..."},
  {value:"Vencimiento", label: "Vencimiento"},
  {value:"Inicio", label: "Inicio"},
]



  const myRender = <div className={tableStyles.container} style={{padding:"0 6rem"}}>
            {(loading) &&
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
          }
      <div className={IndividualStyles.container1}>
        <div className={IndividualStyles.ItemSmall}>
          <h1 className={tableStyles.title}>Subastas vigentes</h1>
        </div>
      </div>
      <div className={IndividualStyles.container2}>
        <div
          className={`${IndividualStyles.Aling} ${IndividualStyles.inputMargin} `}
        >
          <div
          className ={ `${IndividualStyles.Boton} `} onClick={()=>ShowOptions()}>
          <img
            src={LupaClear}
            alt="añadir"
            className={`mx-3  ${IndividualStyles.pointerNew} ${IndividualStyles.sizeNew2}`}
          />
          <span className= {`${IndividualStyles.proArrowWrapper}`}>
          {!!showOpt.arrow?
            <span className={`${IndividualStyles.proArrow2}`}> </span>
            :
            <span className={`${IndividualStyles.proArrow}`}> </span>
          } 
          </span>
          </div>
        </div>
      </div>
      {!!showOpt.show?
      <div className={IndividualStyles.container2}>
        {/* ---------------------------- ROW 1 ---------------------------------- */}
        <div className="d-flex">
          <div
            className={`${IndividualStyles.inputMarginNew} ${IndividualStyles.ItemTiny}`}
          >
            <form  onSubmit={(e) => handleSearch(e)}>
            <p className={IndividualStyles.crudModalLabel}>ID</p>
            <input
              className={`${IndividualStyles.registerInputs}`}
              name="ID"
              type="text"
              value={filters.id}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  id: e.target.value,
                })
              }
              disabled={false}
              placeholder="Escribir..."
            />
            </form>
          </div>

          <div
            className={`${IndividualStyles.inputMarginNew} ${IndividualStyles.ItemTiny}`}
          >
            <form  onSubmit={(e) => handleSearch(e)}>
              <p className={IndividualStyles.crudModalLabel}>Tipo de Subasta</p>
              <Select noOptionsMessage={() => 'No hay datos'}
                name="auction_type"
                styles={customSelectNewDark}
                placeholder={"Seleccionar..."}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    auction_type: e.value,
                  })
                }
                
                options={AcutionTypeOptions}
              >
              </Select>
            </form>

            
          </div>

          <div
            className={`${IndividualStyles.inputMarginNew} ${IndividualStyles.ItemSmall_new}`}
          >
            <p className={IndividualStyles.crudModalLabel}>Título de subasta</p>
            <form  onSubmit={(e) => handleSearch(e)}>
              <input
                className={`${IndividualStyles.registerInputs}`}
                name="auction_title"
                type="text"
                value={filters.auction_title}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    auction_title: e.target.value,
                  })
                }
                disabled={false}
                placeholder="Escribir..."
              />
            </form>
          </div>

          <div className={` ${IndividualStyles.ItemTiny}`}>
            <p className={IndividualStyles.crudModalLabel}>Estado</p>
            <form  onSubmit={(e) => handleSearch(e)}>
            <Select noOptionsMessage={() => 'No hay datos'}
              name="status"
              styles={customSelectNewDark}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  status: e.value,
                })
              }
              options={AcutionStatusOptions}
              placeholder="Seleccionar..."
            >
            </Select>
            </form>
          </div>
        </div>

        {/* ============================= ROW 2 =================================== */}
        <div
          id="arriba"
          className={`${IndividualStyles.backgroundModal} ${IndividualStyles.paddingButton}  ${IndividualStyles.imagePadding}`}
        >
          <div className="d-flex">
            <div
              className={`${IndividualStyles.inputMarginNew} ${IndividualStyles.Item}`}
            >
              <p className={IndividualStyles.crudModalLabelGris}>Fecha de:</p>
              <form  onSubmit={(e) => handleSearch(e)}>
              <Select noOptionsMessage={() => 'No hay datos'}
                name="type_date"
                styles={customSelectNewDark}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    type_date: e.value,
                  })
                }
                options = {AcutionDateOptions}
                placeholder={"Seleccionar..."}
              >
              </Select>
              </form>
            </div>
            <div
              className={` ${IndividualStyles.Item3}`}
            >
              <div className="d-flex justify-content-between">
                <label className={IndividualStyles.crudModalLabelGris}>Desde</label>
                {filters.fromDate && (
                  <span className="hoverPointer align-self-end" onClick={()=>setFilters({...filters, fromDate:""})}>
                    <RiCloseCircleFill color="#003f80" title="Limpiar"/>
                  </span>
                )}
              </div>
              <form  onSubmit={(e) => handleSearch(e)}>
              <input
                className={`${!filters.type_date? IndividualStyles.registerInputsGris : IndividualStyles.registerInputsBlue}`}
                name="fromDate"
                type="date"
                value={filters.fromDate}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    fromDate: e.target.value,
                  })
                }
                disabled={!filters.type_date}
                placeholder="Escribir..."
              />
              </form>
            </div>
            <div className={`${IndividualStyles.line}`}>
            <span >-</span>
            </div>

            <div className={` ${IndividualStyles.Item2}`}>
            <div className="d-flex justify-content-between">
                <label className={IndividualStyles.crudModalLabelGris}>Hasta</label>
                {filters.toDate && (
                  <span className="hoverPointer align-self-end" onClick={()=>setFilters({...filters, toDate:""})}>
                    <RiCloseCircleFill color="#003f80" title="Limpiar"/>
                  </span>
                )}
              </div>
              <form  onSubmit={(e) => handleSearch(e)}>
              <input
                className={`${!filters.type_date? IndividualStyles.registerInputsGris : IndividualStyles.registerInputsBlue}`}
                name="toDate"
                type="date"
                value={filters.toDate}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    toDate: e.target.value,
                  })
                }
                disabled={!filters.type_date}
                placeholder={"Seleccionar..."}
              />
              </form>
            </div>
            <div
              className={`${IndividualStyles.Aling} ${IndividualStyles.inputMargin}`}
            >
              <img
                src={Lupa}
                alt="filtrar"
                className={`${IndividualStyles.imagePaddingNew} ${IndividualStyles.sizeNew} ${IndividualStyles.pointer} mx-3`}
                onClick={ () =>{ setFilters({...filters,page: 1,perpage: 10});  setTrigger(trigger + 1); setLoading(true)}}
              />
            </div>
          </div>
        </div>
      </div>
      :
        <span ></span>
      } 

      <div className={IndividualStyles.container3}>
        <GenericTableNew dark={true} headers={renderHeaders}>
          {renderList()}
        </GenericTableNew>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag.{" "}
            {counter.individualAuctionReducer.auctions_total
              ? filters.page
              : ""}
            {" de "}
            {Math.ceil(
              counter.individualAuctionReducer.auctions_total / filters.perpage
            )
              ? Math.ceil(
                  counter.individualAuctionReducer.auctions_total /
                    filters.perpage
                )
              : ""}{" "}
            ({counter.individualAuctionReducer.auctions_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={Number(counter.individualAuctionReducer.auctions_total)}
            pageRangeDisplayed={5}
            onChange={(e) => {setFilters({ ...filters, page: e });  if (filters.page !== e){ setLoading(true) }}}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>

      <div className={IndividualStyles.bottom}>
      
      </div>

      {/* {reqState.showModal? 
                <ReqModalPending
                    title="Detalle de Requisición Pendiente"
                    btnYesName="Aceptar"
                    btnNoName="Devolución"
                    doIFdispatch = {hasNewArticle(reqState.article_list)}
                    reqState={reqState}
                    reqId = {reqState.id}
                    show={true}
                    btnClonar={btnClonar}
                    onHide={()=>setreqState({showModal:false})}
                    reloadparent = {()=>setTrigger(trigger+1)}
                />
            :""} */}
    </div>


  return (!!counter.purchaseOrderReducer.principalLayout)? 
    <CurrentAuctionDetails 
    //details={details}
    selectedAuction={details.selectedAuction}
    show={counter.purchaseOrderReducer.principalLayout}
    setDetails={setDetails}
    trigger={trigger}
    setTrigger={setTrigger}
    /> 
  : 
    myRender;
}

export default CurrentAuction;
