import { Box } from '@mui/material';
import React from 'react'
import styled from "styled-components";
import { Text } from '../../atoms';
import { useTheme } from 'styled-components';
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';
import EllipseAdmission from "../../../../../src/assets/img/icons/EllipseAdmission.svg";
import appAprovado from "../../../../../src/assets/img/icons/app_aprovado.svg";

const StyledBoxCard = styled.div`
    padding: {({ padding }) => padding ? padding : '0px'};
    margin: ${({ margin }) => margin ? margin : '0'};
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 'normal'};
    column-gap: ${({ columnGap }) => columnGap ? columnGap : '10px'};
    display: ${({ display }) => display ? display : 'block'};
    background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'block'};
    align-items: ${({ alignItems }) => alignItems ? alignItems : 'auto'};
    box-shadow: ${({ boxShadow, theme }) => boxShadow ? boxShadow : `1px 1px 8px ${theme.colors.darkGray2}`};
    border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '12px'};
    width: ${({ width }) => width ? width : '100%'};
    border: ${({ border }) => border ? border : 'auto'};
    `


export const BoxCard = (
    {
        className,
        fontWeightTitle,
        nameCheck,
        valueCheck,
        handleChangeCheck,
        padding,
        margin,
        columnGap,
        title,
        width,
        borderRadius,
        boxShadow,
        arrayObjItems = [],
        textAlignTitle,
        colorTitle,
        colorName,
        fontWeightName,
        colorValue,
        fontWeightValue,
        disabled,
        border,
        backgroundColor,
        marginItem,
        pointer,
        filterActive,
        onClickFilter,
        close,
    }) => {

    const { colors } = useTheme()


    return (
        <StyledBoxCard
            className={`${className} animate__animated animate__fadeIn ${(pointer && !disabled) ? 'pointer' : ''} ${filterActive ? tableStyles.filterActiveDailyClosing : ''} `}
            padding={padding}
            margin={margin}
            columnGap={columnGap}
            boxShadow={boxShadow}
            width={width}
            borderRadius={borderRadius}
            backgroundColor={disabled ? colors.darkGray3 : backgroundColor}
            border={disabled ? `1px solid ${colors.darkGray2}` : 'auto'}
            onClick={()=>{
                if(!disabled && onClickFilter){
                    onClickFilter()
                }
            }}
            style={close ? {background:'#CFE9AD'} : {}}
        >

            <Box 
                position={'relative'} 
                className='w-100' 
                borderBottom={`1px solid ${close ? '#83C036' : '#CCD9E6'}`} 
                height='35px' 
                display={'flex'} 
                alignContent='center' 
                justifyContent={'center'}
            >
                <Text
                    title={title}
                    fontWeight={fontWeightTitle}
                    textAlign={textAlignTitle}
                    display='flex'
                    color={disabled ? colors.darkerGray : filterActive ? tableStyles.filterActiveDailyClosing : colorTitle}
                />

                {
                    filterActive
                        ? (
                            <Box 
                                position={'absolute'} 
                                right={'5px'} 
                                top={'3px'}
                            >
                                <img className='justify-content-end' src={EllipseAdmission} alt="" />
                            </Box>
                        ) : ''
                }
                
                {
                    close
                        ? (
                            <Box 
                                position={'absolute'} 
                                right={'5px'} 
                                top={'3px'}
                            >
                                <img className={`justify-content-end ${tableStyles?.filterGreenIconSuccess}`} src={appAprovado} alt="" />
                            </Box>
                        ) : ''
                }
            </Box>


            <Box padding={'5px 0px'}>
                {
                    arrayObjItems.map((element, i) => (
                        <Box
                            key={i}
                            gridTemplateColumns='1fr 1fr'
                            display={'grid'}
                            margin='5px 10px'

                        >
                            <Text
                                color={colorName}
                                fontWeight={fontWeightName}
                                title={element.name}
                            />
                            <Text
                                title={element.value}
                                color={colorValue}
                                fontWeight={fontWeightValue}
                                textAlign='end'
                                margin={marginItem}
                            />
                        </Box>
                    ))
                }

            </Box>




        </StyledBoxCard>
    )
}
