import GenericTableNew from "../Layouts/GenericTableNew";
import Lupa from "../../assets/img/icons/lupa.svg";
import paperplane from "../../assets/img/icons/paperplane.svg";
import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg";
import Select from "react-select"

import React, { useEffect, useState } from "react";

import {
    update_indauc,
    indauction_filter,
} from "../../actions/IndividualAuctionActions";


import {getAllConCenter} from "../../actions/consumptionCenterActions";

import {  useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
// import popupStyles from "../Popup/customPopup.module.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import {customSelectNewDark} from "../Layouts/react-select-custom";
import { Tooltip } from '@material-ui/core';
import pendingStyles from "./AuctionPending.module.scss";
import ModalNew from "../Layouts/ModalNew";
import PendingAuctionModalArticles from "./PendingAuctionModalArticles";
import { convertDateToLatinFormat } from "../../helpers/helpers";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function ballthingyTooltipName (backendName) {
    switch(backendName){
        case "pending": return "Pendiente";
        case "solved": return "Atendida";
        case "rejected": return "Rechazada";
        case "unanswered": return "No respondido";
        case "partially": return "Parcialmente respondido";
        default: return "?";
    }
}

function statusBallToClass (name){
    switch(name){
        case "pending":
            return tableStyles.circleYellowAuto
        case "solved":
            return tableStyles.circleGreenAuto
        case "rejected":
            return tableStyles.circleRedAuto
        default:
            return tableStyles.circleBlackAuto
    }
}

function TablePendingAuctions (){
    const counter = useSelector(state => state);
    const dispatch = useDispatch();    
    
    const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "InAuction");
    const myProfilePrefix = counter.loginReducer.currentAccount?.profile?.prefijo;

    const [trigger, setTrigger] = useState(1);

    const [filters, setFilters] = useState({
        page: 1,
        perpage:10,
        eaccount: counter.loginReducer.currentAccount.id,
        auction_type: "consolidationreq",
        multistatus: "rejected,pending",
        status: "",
        cc_id: "",
        //multistatus: "rejected,pending,unanswered",
    });

    const [modalReqControl, setmodalReqControl]= useState({
        show:false,
    });

    const [modalArticlesControl, setmodalArticlesControl]= useState({
        show:false,
    });
    
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch( getAllConCenter({eaccount: counter.loginReducer.currentAccount.id}) );
        // dispatch( storage_get({
        //     page:1, 
        //     perpage:3000,
        //     id_account: counter.loginReducer.currentAccount.id,
        // }) );
    },[]);

    useEffect(() => {
        dispatch( indauction_filter(filters, () => setLoading(false) ) );
    },[trigger, filters.page, filters.cc_id, filters.status]);

    const optionConCenter = () => {
        let tempModules = [
            {
                value: "",
                label: "Seleccionar...",
            } 
        ];
        if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
            counter.consumptionCenterReducer.consumption_center.map((elem) => {
                let label = elem.description
                if(Array.isArray(elem.consumption_combination)){
                    if(elem.consumption_combination.length <1){
                        label += "*"
                    }
                }else{
                    label += "*"
                }
                tempModules.push({
                    value: elem.id,
                    label: label,
                });
            });

          }
        return tempModules;
    }
    
    const renderHeaders = [
        <th key={`reqTH1`} className="px-2">Cant.&nbsp;Requisición</th>,
        <th key={`reqTH2`}>Centro de Consumo</th>,
        <th key={`reqTH3`} className="px-2 text-center">Fecha</th>,
        <th key={`reqTH4`} className="px-2 text-center" >Cant.&nbsp;Artículo</th>,
        <th key={`reqTH5`} className="px-2 text-center">Estado</th>,
        <th key={`reqTH99`}></th>,
    ]

    const renderList = () => {
        let table =[];

        if (Array.isArray(counter.individualAuctionReducer.indauction)) {
            table = counter.individualAuctionReducer.indauction.map( x => {
                let reqdateformat = new Date(x.created_at);
                return <tr key={"renderList"+x.id} className="hover-table-row">

                    
                    <td className={`col-md-1 text-start`}>
                        <p 
                        className={`m-0 ${pendingStyles.reqCountText} text-start px-2`}
                        onClick={()=>setmodalReqControl({...modalReqControl, showModal:true, req_list: x.req_list})}
                        >
                            { Array.isArray(x.req_list)? x.req_list.length: "?"  }
                        </p>
                        
                    </td>
                    <td className={`col-md-3 text-start `}>
                        {(!!x.cc_ref?.description)? x.cc_ref?.description: "-"}
                    </td>
                    <td className={`col-md-2`}>
                        {/* { (!!x.created_at)? x.created_at.replace("T", " "): "-" } */}
                        {convertDateToLatinFormat(reqdateformat)}
                    </td>
                    <td className={`col-md-1`}>
                        { Array.isArray(x.article_list)? x.article_list.length: "?"  }
                        
                    </td>
                    <td className={`col-md-1`}>
                        <Tooltip title={ballthingyTooltipName(x.status)} arrow="true">
                        <div
                            className="rounded-pill p-1 mx-auto"
                            style={{
                            width: 200,
                            backgroundColor: x?.status === "solved" ? "#FAFDF6" : x?.status=== "pending" ? "rgb(254, 250, 238)" : "#FEF7F5" ,
                            }}
                        >
                            <b style={{ color: x?.status === "solved" ? "#83C036" : x?.status=== "pending" ? "rgb(233, 180, 25)" : "#F39682" }}>
                            {ballthingyTooltipName(x.status)}
                            </b>
                        </div>
                            {/* <div className={`${pendingStyles.popupReqActive} ${statusBallToClass(x.status)}`}>
                            </div> */}
                        </Tooltip>
                    </td>
                    <td className={`col-md-1`}>
                        {//(x.status==="pending" || x.status==="rejected") && ()
                            (x.status==="pending")?
                                (myProfilePrefix=="ADM" || myProfilePrefix=="SUP")?
                                <img 
                                    src={paperplane}
                                    alt="enviar"
                                    className={`icons-popUp ${pendingStyles.adjustIconDetalles}`}
                                    onClick={()=>setmodalArticlesControl({...modalArticlesControl, show:true, selectedAuction: x, readonly:false})}
                                />
                                :
                                <span>&ensp;&emsp;</span>
                            :
                                (myPermission?.reject && (myProfilePrefix=="ADM" || myProfilePrefix=="SUP"))?
                                <img 
                                    src={lupaTransparent}
                                    alt="detalles"
                                    className={`icons-popUp ${pendingStyles.adjustIconDetalles}`}
                                    onClick={()=>setmodalArticlesControl({...modalArticlesControl, show:true, selectedAuction: x, readonly:true})}
                                />
                                :
                                <span>&ensp;&emsp;</span>
                        }
                    </td>
                </tr>
            });

          }
        return table;
    }

    const renderRequisitionList = (myList) => {
        let table =[];

        if (Array.isArray(myList)) {
            table = myList.map( x => {
                return <tr key={"renderRequisitionList"+x.num_req} className="hover-table-row">
                    <td className={`col-md-12 text-uppercase`}>
                        {x.num_req}
                    </td>
                </tr>
            });

          }
        return table;
    }


    return (
        <div className={tableStyles.container} style={{padding:"0 6rem"}}>
                        {(loading) &&
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
          }

            {(counter.individualAuctionReducer.indauction_loading) &&
                <div className="loading">
                    <Loader
                    type="Oval"
                    color="#003f80"
                    height={100}
                    width={100}
                    />
                </div>
            }

            <div className={`d-flex`}>
                <div className={`${pendingStyles.Item} ${pendingStyles.f2}`}>
                    <h1 className={tableStyles.title}>Subastas Pendientes</h1>
                </div>
            </div>

            

            <div className={`d-flex flex-column`}>
                    {/* ---------------------------- ROW 1 ---------------------------------- */}
                    <div className="d-flex">

                        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
                            <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                defaultValue={optionConCenter().find(x => x.value == filters.cc_id)}
                                onChange={e => setFilters({...filters, cc_id: e.value}) } 
                                options={optionConCenter()}
                                placeholder="Seleccionar..."
                                styles={customSelectNewDark}
                                maxHeight={22}
                            />
                        </div>

                        <div  className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
                            <p className={tableStyles.crudModalLabel}>Fecha</p>
                            
                            <div className={tableStyles.deleteicon}>
                                {!!filters.created_at?
                                    <span  onClick={()=>setFilters({...filters, created_at:""})}>x</span>
                                :
                                    ""
                                }
                                <input
                                    className={`register-inputs ${tableStyles.crudModalInputDate}`}
                                    style={{height:"27px"}}
                                    name="requisition_date"
                                    type="date"
                                    value={filters.created_at}
                                    onChange={(e)=>setFilters({...filters, created_at:e.target.value})}
                                />
                            </div>
                        </div>


                        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
                            <p className={tableStyles.crudModalLabel}>Estado</p>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                defaultValue={{value:"", label: "Seleccionar..."}}
                                onChange={ e => setFilters({...filters, status: e.value}) } 
                                options={[
                                    {value:"", label: "Seleccionar..."},
                                    {value:"pending", label: "Pendiente"},
                                    //{value:"solved", label: "Atendida"},
                                    //{value:"unanswered", label: "No respondido"},
                                    //{value:"partially", label: "Parcialmente respondido"},
                                    {value:"rejected", label: "Rechazada"},
                                ]}
                                placeholder="Seleccionar..."
                                styles={customSelectNewDark}
                                maxHeight={22}
                            />
                        </div>
                        
                        <div className={`${pendingStyles.Item}`}>
                            <p className={tableStyles.crudModalLabel}>&nbsp;</p>
                            <img 
                                    src={Lupa}
                                    alt="filtrar"
                                    height={24}
                                    className={`${tableStyles.iconSvg}`} 
                                    onClick={()=> {setTrigger(trigger+1) ; setLoading(true)}}
                                />
                        </div>
                        
                        <div className={`${pendingStyles.Item} ${pendingStyles.f3}`}>
                            <p className={tableStyles.crudModalLabel}>&nbsp;</p>
                        </div>

                    </div>

            </div>

            <div className={`d-flex flex-column ${pendingStyles.f1}`}>
                <GenericTableNew dark={true} headers={renderHeaders}>
                    {renderList()}
                </GenericTableNew>
                <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                        Pag. {counter.individualAuctionReducer.indauction_total ? filters.page : ""} 
                        {" de "}
                        {Math.ceil(counter.individualAuctionReducer.indauction_total / filters.perpage) ? 
                            Math.ceil(counter.individualAuctionReducer.indauction_total / filters.perpage)
                        : 
                            "" 
                        }
                        {" "}
                        ({counter.individualAuctionReducer.indauction_total} encontrados)
                    </p>
                    <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.perpage}
                        totalItemsCount={counter.individualAuctionReducer.indauction_total}
                        pageRangeDisplayed={5}
                        onChange={ (e) => {setFilters({...filters, page: e}); if (filters.page !== e){ setLoading(true) } }}
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext }
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                    />
                </div>


            </div>

            <div className={pendingStyles.bottom}>
                <div className={pendingStyles.bottomRow}>
                    {/* {myPermission?.approve &&
                    <Button 
                        className={tableStyles.btnPrimary} 
                        onClick={()=>buttonOk()}
                        //disabled={selectedList.length<1}
                    >
                        Agregar a Subasta
                    </Button>
                    }
                    
                    <Button 
                        className={tableStyles.btnSecondary}  
                        onClick={()=>buttonCancel()}
                    >
                        Cancelar
                    </Button> */}

                    <span style={{"height": "33px"}}> &nbsp; </span>
                </div>
            </div>

        <ModalNew
            title="Consolidados"
            show={modalReqControl.showModal}
            onHide={()=>setmodalReqControl({...modalReqControl, showModal:false, req_list: [] })}
            hideCancelButton={true}
            size="440"
        >
            <GenericTableNew 
                headers={[ <th key={`reqTH1`} className="text-center">Número de Requisición</th>] }
            >
                {renderRequisitionList(modalReqControl.req_list )}
            </GenericTableNew>
        </ModalNew>

        {(myProfilePrefix=="ADM" || myProfilePrefix=="SUP") && (
        <PendingAuctionModalArticles
            show={modalArticlesControl.show}
            onHide={()=>setmodalArticlesControl({...modalArticlesControl, show:false})}
            selectedAuction={modalArticlesControl.selectedAuction}
            updateparent={()=>setTrigger(trigger+1)}
            readonly={modalArticlesControl.readonly}
            rejectionable={(myPermission?.reject && (myProfilePrefix=="ADM" || myProfilePrefix=="SUP"))}
        >
        </PendingAuctionModalArticles>
        )}

        </div>
    )

}



export default TablePendingAuctions;