import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
// import Subasta from "../../assets/img/icons/auction.svg";
import { Link, matchPath } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import "./sidebar.scss";
import style from "../../routes/fullLayout.module.css";
// import store from "../../store";
// import engraneAzul from "../../assets/img/icons/engraneAzul.svg";
// import Compra from "../../assets/img/icons/Compra.svg";
// import CentrodeCosto from "../../assets/img/icons/CentrodeCosto.svg";
// import Group from "../../assets/img/icons/Group.svg";
// import Facturacion from "../../assets/img/icons/Facturacion.svg";
// import CandadoMaestro from "../../assets/img/icons/CandadoMaestro.svg";
// import HojaReq from "../../assets/img/icons/HojaReq.svg";
import Cruz from "../../assets/img/icons/Cruz.svg";
// import blueCards from "../../assets/img/icons/blueCards.svg";
// import Inventory from "../../assets/img/icons/Inventory.svg";
import Hamburgesa from "../../assets/img/icons/Hamburgesa.svg";
import HamburgesaOrd from "../../assets/img/icons/HamburgesaOrd.svg";
import { removeAccents } from "../../helpers";
// import Autorizacion from "../../assets/img/icons/Autorizacion.svg";
// import Almacen from "../../assets/img/icons/Almacen.svg";
// import IconUserProfile from "../../assets/img/icons/IconUser.svg";
// import logOut from "../../assets/img/icons/logOut.svg";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedMenu: true,
      itemActive: "",
    };
  }

  onClickToggleMenu = () => {
    this.setState({ collapsedMenu: !this.state.collapsedMenu });
  };

  onClickToggleSubMenu = () => {
    if (this.state.collapsedMenu) {
      this.onClickToggleMenu();
    } else {
      let { itemActive } = this.state;
      if (itemActive !== "") {
        this.setState({ itemActive: "" });
      } else {
        this.setState({ itemActive: "pro-inner-item-active" });
      }
    }
  };
  renderFunctionalites(functionality) {
    const myUrl = this.props?.location?.pathname;
    if (
      this.props.loginReducer?.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === functionality?.prefix
      )?.read
    ) {
      return (
        <div>
          <MenuItem
            className={`${!!matchPath(myUrl, functionality?.url) ? "menuItemActive" : null
              } activeHover`}
          >
            <Link to={functionality?.url}>
              <p
                className="m-0"
                style={{ paddingLeft: "5px", whiteSpace: "initial" }}
              >
                {functionality?.name}
              </p>
            </Link>
          </MenuItem>
        </div>
      );
    } else {
      return null;
    }
  }
  renderModules(module) {
    const functionalities = module?.functionalities?.map((x) => {
      return this.renderFunctionalites(x);
    });
    if (Array.isArray(functionalities)) {
      if (functionalities.length === 0) {
        return <span />;
      } else {
        return (
          <SubMenu
            title={module.name}
            icon={
              <div id={module.id}>
                <img
                  id={module.name + "-" + module.id}
                  alt={module.name}
                  src={module.img}
                  style={{ width: "30px", height: "30px" }}
                  class="icons-sidebar"
                />
              </div>
            }
            className={`${this.state.itemActive} `}
            onClick={this.onClickToggleSubMenu}
          >
            {functionalities}
          </SubMenu>
        );
      }
    }
  }
  isAssistanceModule = () => {
    let value = false;
    window.location.pathname.includes("ordenamientos")
      ? (value = true)
      : window.location.pathname.includes("asistencial")
        ? (value = true)
        : window.location.pathname.includes("telemedicina")
          ? (value = true)
          : window.location.pathname.includes("cuentasMedicas")
            ? (value = true)
            : window.location.pathname.includes("admision")
              ? (value = true)
              : (value = false);
    return value;
  };

  render() {
    const myUrl = this.props.location.pathname;
    const permissionReq =
      this.props.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "Req"
      );
    const permissionUnFun =
      this.props.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "UnFun"
      );
    const permissionCCost =
      this.props.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "CCost"
      );
    const permissionsCliEmp =
      this.props.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "CliEmp"
      );
      const category = this.props?.loginReducer?.all_categories?.find(
        (x) => removeAccents(x.name || '')?.toLowerCase() === removeAccents(this.props.loginReducer.category || '')?.toLowerCase()
      );
      const categoryOrder = category?.modules?.sort((a, b) => a.id - b.id);
      const modules = categoryOrder?.map((x) => {
        return this.renderModules(x);
      }) || <></>;

    return (
      <div
        className={`${style.fullLayoutSideBar} shadow rounded d-inline-block full-height`}
      >
        <ProSidebar
          collapsed={this.state.collapsedMenu}
          className={style.zIndex800}
        >
          <Menu
            id="menu1"
            iconShape="square"
          // id="MenuItem" className="menuBar"
          >
            <MenuItem
              // icon={<HiOutlineMenu className="icon-sidebar" />}
              icon={
                <img
                  alt="Hamburger"
                  src={this.isAssistanceModule() ? HamburgesaOrd : Hamburgesa}
                  onClick={() =>
                    this.setState({ collapsedMenu: !this.state.collapsedMenu })
                  }
                  className="Hamburger-sidebar"
                />
              }
            >
              <div style={{ textAlign: "end" }}>
                <img
                  src={Cruz}
                  alt="Ocultar"
                  onClick={this.onClickToggleMenu}
                  className="CruzBar"
                ></img>
              </div>
            </MenuItem>
            {modules}

            {/* 
            [
            <SubMenu
              title={`TM DE ADMINISTRACIÓN.`}
              icon={
                <div id="engrane1">
                  <img
                    id="iconAdmin"
                    alt="Tabla maestra administración"
                    src={engraneAzul}
                    // style={{filter:"grayscale(100%)"}}
                    class="icons-sidebar"
                  />
                </div>
              }
              className={`${this.state.itemActive} adminSideBar`}
              onClick={this.onClickToggleSubMenu}
            >
             
              <MenuItem className={!!matchPath(myUrl, '/servicios')? "menuItemActive":null} >
                  <Link to="/servicios">Servicios</Link>
              </MenuItem>

              <MenuItem>Especialidades</MenuItem>

              <MenuItem>Clientes - pacientes</MenuItem>

              <MenuItem>Tipo de clientes</MenuItem>

              <MenuItem>Tipo de contwratos</MenuItem> */}
            {/* 
              {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                (x) => x.functionality?.prefix === "Sites"
              )?.read ? (
                <MenuItem
                  className={`${
                    !!matchPath(myUrl, "/administracion/sedes") ? "menuItemActive" : null
                  } activeHover`}
                >
                  <Link to="/administracion/sedes">
                    <label style={{ "paddingLeft": "5px" }}></label>
                    <label>Sedes</label>
                  </Link>
                </MenuItem>
              ) : (
                ""
              )} 

 
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/administracion/cups") ? "menuItemActive" : null
                } activeHover`}
              >
                <Link to="/administracion/cups">
                  <label style={{ "paddingLeft": "5px" }}></label>
                  <label>CUPS</label>
                </Link>
              </MenuItem>
              {permissionsCliEmp?.read ? (
                <MenuItem
                  className={`${
                    !!matchPath(myUrl, "/administracion/corporateClients")
                      ? "menuItemActive"
                      : null
                  } activeHover`}
                >
                  <Link to="/administracion/corporateClients">
                  <label style={{ "paddingLeft": "5px" }}></label>
                  <label>Clientes - empresas</label>
                  </Link>
                </MenuItem>
              ) : (
                ""
              )}
            </SubMenu>
            ,
            <SubMenu
              title={`TM DE COMPRA.`}
              icon={
                <img
                  alt="Tabla maestra de compra"
                  src={Compra}
                  class="icons-sidebar"
                />
              }
              // icon={<HiOutlineChartPie className="icon-sidebar" />}
              className={`${this.state.itemActive}`}
              onClick={this.onClickToggleSubMenu}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/administracion/familia-inventario")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/administracion/familia-inventario">
                  <label style={{ "paddingLeft": "5px" }}></label>
                  <label>Familia de Inventario</label>
                </Link>
              </MenuItem>
              {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                  (x) => x.functionality?.prefix === "Art"
                )?.read ? (
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/administracion/articulos-activos")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/administracion/articulos-activos">
                  <label style={{ "paddingLeft": "5px" }}></label>
                  <label>Artículos activos</label>
                </Link>
              </MenuItem>
                ) : ("")}
              {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                (x) => x.functionality?.prefix === "CCon"
              )?.read ? (
                <MenuItem
                  className={`${
                    !!matchPath(myUrl, "/administracion/centro-de-consumo")
                      ? "menuItemActive"
                      : null
                  } activeHover`}
                >
                  <Link to="/administracion/centro-de-consumo">
                    <label style={{ "padding-left": "5px" }}></label>
                    <label>Centro de Consumo</label>
                  </Link>
                </MenuItem>
              ) : (
                ""
              )}

              {this.props.loginReducer.currentAccount?.profile?.permission?.find( (x) => x.functionality?.prefix === "UnMed" )?.read &&
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/administracion/unidades-medida")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/administracion/unidades-medida">
                  <label style={{ "paddingLeft": "5px" }}></label>
                  <label>Unidades de Medida</label>{" "}
                </Link>
              </MenuItem>
              }

              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/administracion/storage") ? "menuItemActive" : null
                } activeHover`}
              >
                <Link to="/administracion/storage">
                  <label style={{ "paddingLeft": "5px" }}></label>
                  <label>Almacenes</label>
                </Link>
              </MenuItem>)}

              {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                (x) => x.functionality?.prefix === "prov"
              )?.read && (
                <MenuItem
                  className={`${
                    !!matchPath(myUrl, "/administracion/proveedores") ? "menuItemActive" : null
                  } activeHover`}
                >
                  <Link to="/administracion/proveedores">
                    <label style={{ "padding-left": "5px" }}></label>
                    <label>Proveedores</label>
                  </Link>
                </MenuItem>
              )}
            </SubMenu>
            ,
            <SubMenu
                title={`TM DE CONTABILIDAD.`}
                icon={
                  <img
                    alt="Tabla maestra DE CONTABILIDAD"
                    src={CentrodeCosto}
                    class="icons-sidebar"
                  />
                }
                // icon={<HiOutlineCalculator className="icon-sidebar"/>}
                onClick={this.onClickToggleSubMenu}
              >
                {permissionUnFun?.read ? (
                  <MenuItem
                    className={`${
                      !!matchPath(myUrl, "/administracion/functional")
                        ? "menuItemActive"
                        : null
                    } activeHover`}
                  >
                    <Link to="/administracion/functional">
                      <label style={{ "paddingLeft": "5px" }}></label>
                      <label>Unidades funcionales</label>
                    </Link>
                  </MenuItem>
                ) : (
                  <span />
                )}

                {permissionCCost?.read ? (
                  <MenuItem
                    className={`${
                      !!matchPath(myUrl, "/administracion/costcenter")
                        ? "menuItemActive"
                        : null
                    } activeHover`}
                  >
                    <Link to="/administracion/costcenter">
                      <label style={{ "paddingLeft": "5px" }}></label>
                      <label>Centro de Costos</label>
                    </Link>
                  </MenuItem>
                  {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "Art"
                  )?.read ? (
                    <MenuItem
                      className={`${
                        !!matchPath(myUrl, "/administration/articulos-activos")
                          ? "menuItemActive"
                          : null
                      } activeHover`}
                    >
                      <Link to="/administration/articulos-activos">
                        <label style={{ paddingLeft: "5px" }}></label>
                        <label>Artículos activos</label>
                      </Link>
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "CCon"
                  )?.read ? (
                    <MenuItem
                      className={`${
                        !!matchPath(myUrl, "/administration/centro-de-consumo")
                          ? "menuItemActive"
                          : null
                      } activeHover`}
                    >
                      <Link to="/administration/centro-de-consumo">
                        <label style={{ "padding-left": "5px" }}></label>
                        <label>Centro de Consumo</label>
                      </Link>
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  <MenuItem
                    className={`${
                      !!matchPath(myUrl, "/administracion/puc") ? "menuItemActive" : null
                    } activeHover`}
                  >
                    <Link to="/administracion/puc">
                      <label style={{ "paddingLeft": "5px" }}></label>
                      <label>Plan único de cuentas</label>
                    </Link>
                  </MenuItem>
                ) : (
                  ""
                )}
              </SubMenu>
            ,
          ]

*/}
            {/* <SubMenu
              title={`TM DE HISTORIA CLÍNICA.`}
              icon={<img alt="Tabla maestra DE HISTORIA CLÍNICA" src={Group}  />}
              // icon={<HiOutlineClipboardList className="icon-sidebar" />}
              onClick={this.onClickToggleSubMenu}
            >
              <MenuItem
                className={
                  !!matchPath(myUrl, "/impresiones-diagnosticas")
                    ? "menuItemActive"
                    : null
                }
              >
                <Link to="/impresiones-diagnosticas">Impr. DX CIE-10</Link>
              </MenuItem>

              <MenuItem>Impr. DX Ampliada</MenuItem>
            </SubMenu> */}

            {/*los condicionales (a)?b:c necesitan tener un else obligatoriamente
              normalmente un string vacio seria ignorado por react ""
              pero en la libreria react-pro-sidebar sucede un crash si se pone un "" en el else dentro del tag <Menu>
              asi que usen <span /> para que sea ignorado correctamente
            */}
            {/* {!!myUrl?.includes("/compras/") ? 
[ 
            <SubMenu
              title={`REQUISICIONES.`}
              icon={
                <img alt="REQUISICIONES" src={HojaReq} class="icons-sidebar" />
              }
              // icon={<HiTruck className="icon-sidebar" />}
              onClick={this.onClickToggleSubMenu}
              className={`${this.state.itemActive} adminSideBar`}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/compras/requisicionnueva")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/compras/requisicionnueva">
                  <label style={{ "padding-left": "5px" }}></label>
                  <label>Crear Requisición</label>
                </Link>
              </MenuItem>
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/compras/requisicionpendiente")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/compras/requisicionpendiente">
                  <label style={{ "padding-left": "5px" }}></label>
                  <label>
                    Requisiciones <br></br>pendientes insumos
                  </label>
                </Link>
              </MenuItem>
            </SubMenu>   
            ,
            <SubMenu
              title={`Subastas`}
              icon={<img alt="Subastas" src={Subasta} class="icons-sidebar" />}
              onClick={this.onClickToggleSubMenu}
            >
              {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                (x) => x.functionality?.prefix === "InAuction"
              )?.create ? (
                <MenuItem
                  className={`${
                    !!matchPath(myUrl, "/compras/subastaindividual")
                      ? "menuItemActive"
                      : null
                  } activeHover`}
                >
                  <Link to="/compras/subastaindividual">
                    <label style={{ "paddingLeft": "5px" }}></label>
                    <label>Crear subasta</label>
                  </Link>
                </MenuItem>
              ) : (
                ""
              )}

              {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                 (x) => x.functionality?.prefix === "HiAuction"
              )?.read ? (
                <MenuItem
                  className={`${
                    !!matchPath(myUrl, "/compras/subastapendiente")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                  <Link to="/compras/subastapendiente">
                    <label style={{ "paddingLeft": "5px" }}></label>
                    <label>Subasta Pendiente</label>
                  </Link>
                </MenuItem>
              ) : (
                ""
              )}

                  {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "Conv"
                  )?.read ? (
                    <MenuItem
                      className={`${
                        !!matchPath(myUrl, "/purchases/convenios")
                          ? "menuItemActive"
                          : null
                      } activeHover`}
                    >
                      <Link to="/purchases/convenios">
                        <label style={{ paddingLeft: "5px" }}></label>
                        <label>Convenios</label>
                      </Link>
                    </MenuItem>
                  ) : (
                    ""
                  )}
                </SubMenu>
                ,
                <SubMenu
                  title={`AUTORIZACIONES`}
                  icon={
                    <img
                      alt="Tabla de autorizaciones"
                      src={Autorizacion}
                      class="icons-sidebar"
                    />
                  }
                  className={`${this.state.itemActive}`}
                  onClick={this.onClickToggleSubMenu}
                >
                  {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "AuthReq"
                  )?.read ? (
                    <MenuItem
                      className={`${
                        !!matchPath(myUrl, "/purchases/authorizeRequisitions")
                          ? "menuItemActive"
                          : null
                      } activeHover`}
                    >
                      <Link to="/purchases/authorizeRequisitions">
                        <label style={{ "padding-left": "5px" }}></label>
                        <label>
                          Autorizar <br></br> requisiciones
                        </label>
                      </Link>
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "AuthConv"
                  )?.read ? (
                    <MenuItem
                      className={`${
                        !!matchPath(myUrl, "/purchases/authConv")
                          ? "menuItemActive"
                          : null
                      } activeHover`}
                    >
                      <Link to="/purchases/authConv">
                        <label style={{ paddingLeft: "5px" }}></label>
                        <label>
                          Autorizar <br></br> convenios
                        </label>
                      </Link>
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === "APO"
                  )?.read && (
                    <MenuItem
                      className={`${
                        !!matchPath(myUrl, "/purchases/AOrdenCompra")
                          ? "menuItemActive"
                          : null
                      } activeHover`}
                    >
                      <Link to="/purchases/AOrdenCompra">
                        <label style={{ paddingLeft: "5px" }}></label>
                        <label>
                          Autorizar <br></br>ordenes de compra
                        </label>
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
                ,
                <SubMenu
                  title={`ARCHIVOS MAESTROS.`}
                  icon={
                    <img
                      alt="Archivos maestros"
                      src={CandadoMaestro}
                      className="icons-sidebar"
                    />
                  }
                  className={`${this.state.itemActive}`}
                  onClick={this.onClickToggleSubMenu}
                  // icon={<HiOutlineMenu className="icon-sidebar" />}
                >
                  <MenuItem
                    className={`${
                      !!matchPath(myUrl, "/purchases/cuentascontables")
                        ? "menuItemActive"
                        : null
                    } activeHover`}
                  >
                    <Link to="/purchases/cuentascontables">
                      <label style={{ paddingLeft: "5px" }}></label>
                      <label>Cuentas contables</label>
                    </Link>
                  </MenuItem>
                </SubMenu>     
                ,
                <SubMenu
                  title={`Almacen`}
                  icon={<img alt="Almacen" src={Almacen} class="icons-sidebar" />}
                  onClick={this.onClickToggleSubMenu}
                >
                  <MenuItem
                      className={`${
                        !!matchPath(myUrl, "/purchases/registrarEntradaAlmacen")
                        ? "menuItemActive"
                        : null
                      } activeHover`}
                  >
                      <Link to="/purchases/registrarEntradaAlmacen">
                        <label style={{ "padding-left": "5px" }}></label>
                        <label>Registrar entrada</label>
                      </Link>
                  </MenuItem>

                </SubMenu>

                ]
            ) : (
              <span />
            )}

            {!!myUrl?.includes("/configuration/") ? (
              <SubMenu
                title={`CUENTA`}
                icon={
                  <img
                    alt="userProfile"
                    src={IconUserProfile}
                    class="icons-sidebar"
                  />
                }
                className={`${this.state.itemActive} adminSideBar`}
                onClick={this.onClickToggleSubMenu}
              >
                <MenuItem
                  className={`${
                    !!matchPath(myUrl, "/compras/subastasvigentes")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                  <Link to="/subastasvigentes">
                    <label style={{ "paddingLeft": "5px" }}></label>
                    <label>Vigentes</label>
                  </Link>
                </MenuItem>
              ) : (
                ""
              )}

              {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                 (x) => x.functionality?.prefix === "HiAuction"
              )?.read ? (
                <MenuItem
                  className={`${
                    !!matchPath(myUrl, "/subastashistorial")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                  <Link to="/subastashistorial">
                    <label style={{ "paddingLeft": "5px" }}></label>
                    <label>Historial</label>
                    </Link>
                    </MenuItem>
              ) : (
                ""
              )}

            </SubMenu>
            ,
            <SubMenu
              title={`AUTORIZACIONES`}
              icon={
                <img
                  alt="Tabla de autorizaciones"
                  src={Autorizacion}
                  class="icons-sidebar"
                />
              }
              className={`${this.state.itemActive}`}
              onClick={this.onClickToggleSubMenu}
            >
              {this.props.loginReducer.currentAccount?.profile?.permission?.find(
                (x) => x.functionality?.prefix === "AuthReq"
              )?.read ? (
                <MenuItem
                  className={`${
                    !!matchPath(myUrl, "/compras/authorizeRequisitions")
                      ? "menuItemActive"
                      : null
                  } activeHover`}
                >
                  <Link to="/compras/authorizeRequisitions">
                    <label style={{ "padding-left": "5px" }}></label>
                    <label>Cuentas</label>
                  </Link>
                </MenuItem>
              ) : (
                ""
              )}
            </SubMenu>
            , 
            <SubMenu
             title={`ARCHIVOS MAESTROS.`}
             icon={
               <img
                 alt="Archivos maestros"
                 src={CandadoMaestro}
                 class="icons-sidebar"
               />
             }
             onClick={this.onClickToggleSubMenu}
             // icon={<HiOutlineMenu className="icon-sidebar" />}
           >
             <MenuItem
               className={`${
                 !!matchPath(myUrl, "/compras/cuentascontables")
                   ? "menuItemActive"
                   : null
               } activeHover`}
             >
               <Link to="/compras/cuentascontables">
                 <label style={{ "padding-left": "5px" }}></label>
                 <label>Cuentas contables</label>
               </Link>
             </MenuItem>
           </SubMenu>
        ]
          : <span/>}
           */}

            {/* <SubMenu
            title={`CONFIGURACIÓN.`}
            icon={
              <img
                alt="Configuración"
                src={engraneAzul}
                class="icons-sidebar"
              />
            }
            // icon={<HiViewGridAdd className="icon-sidebar" />}
            onClick={this.onClickToggleSubMenu}
            className={`${this.state.itemActive} adminSideBar`}
          >
            <MenuItem
              className={`${
                !!matchPath(myUrl, "/configuration/usuarios") ? "menuItemActive" : null
              } activeHover`}
            >
              <Link to="/configuration/usuarios">
                <label style={{ "padding-left": "5px" }}></label>
                <label>Usuarios</label>
              </Link>
            </MenuItem>

            <MenuItem
              className={`${
                !!matchPath(myUrl, "/configuration/cuentas") ? "menuItemActive" : null
              } activeHover`}
            >
              <Link to="/configuration/cuentas">
                <label style={{ "padding-left": "5px" }}></label>
                <label>Cuentas</label>
              </Link>
            </MenuItem>

            {this.props.loginReducer.currentAccount?.profile?.prefijo == "ADM"
              && [
                  <MenuItem
                    className={`${
                      !!matchPath(myUrl, "/configuration/gestionroles")
                        ? "menuItemActive"
                        : null
                    } activeHover`}
                  >
                    <Link to="/configuration/gestionroles">
                      <label style={{ "padding-left": "5px" }}></label>
                      <label>Gestion de Roles</label>
                    </Link>
                  </MenuItem>,

                  <MenuItem
                    className={`${
                      !!matchPath(myUrl, "/configuration/gestionpermisos")
                        ? "menuItemActive"
                        : null
                    } activeHover`}
                  >
                    <Link to="/configuration/gestionpermisos">
                      <label style={{ "padding-left": "5px" }}></label>
                      <label>Gestion de Permisos</label>
                    </Link>
                  </MenuItem>,
                ]
              }
          </SubMenu>
             */}
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}

export default withRouter(Sidebar);
