import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message } from "../helpers/helpers";
import { responseSwal } from "../helpers/responseSwal";
import { GET_CONTRACT_TYPES_LIST } from "./actionTypes";

export const getContractTypesList = () => (dispatch, getState) => {
  // dispatch({
  //   type: GET_CONTRACT_TYPES_LIST,
  //   payload: res.results,
  // payload: contract.results,
  // });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contractstypes`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_CONTRACT_TYPES_LIST,
        payload: res.results,
      });
    })
    .catch((error) => {
      responseSwal({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    });
};

export const changeContranct = async (id, active, token) => {
  try {
    let data = {
      id,
      active,
    };
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/contractstypes`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.error(error);
    return message("error", "Error", error.message);
  }
};
