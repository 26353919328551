import React, { useEffect, useState } from "react";
import specialTableStyles from "./SpecialTable.module.scss";

function pickStyle (theme) {
    switch(theme){
        case "orange":
            return specialTableStyles.orangeTheme;
        case "gray":
            return specialTableStyles.grayTheme;
        case "darkblue":
            return specialTableStyles.darkBlueTheme;
        default: 
            return specialTableStyles.blueTheme;

    }
}

function SpecialTable (props){
    //set a props for collapse start value
    const [collapse, setCollapse] = useState((props.collapse!=null)? props.collapse : false);

    const collapseClass = collapse? specialTableStyles.collapse : "";
    const collapseSuper = collapse? specialTableStyles.collapseSuper : "";

    useEffect(() => {
        if(props.collapse!=null){
            setCollapse(props.collapse);
        }
    }, [props.collapse]);

    return (
      <div className={`${specialTableStyles.table} ${pickStyle(props.theme)}`} >

        <div className={`${specialTableStyles.superhead} ${collapseSuper}`} onClick ={()=>setCollapse(!collapse)}>
            {props.superheaders}
        </div>

        <div className={`${specialTableStyles.head} ${collapseClass}`}>
            {props.headers}
        </div>

        <div className={`${specialTableStyles.bodycontainer} ${collapseClass}`}>
            <div className={`${specialTableStyles.body}`}>
                {props.body}
            </div>
        </div>
        
        {!!props.footer && 
        (<div className={`${specialTableStyles.foot} ${collapseClass}`}>
            {props.footer}
        </div>)}

      </div>
    );
}

export default SpecialTable;
