import deepcopy from "deepcopy";
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FiSend } from "react-icons/fi";
import { connect } from "react-redux";
import { forgotPassword, validateUser } from "../../actions/loginActions";
import bioWel from "../../assets/img/icons/bioWel.svg";
import carbonEmail from "../../assets/img/icons/carbonEmail.svg";
import carbonPassKey from "../../assets/img/icons/carbonPassKey.svg";
import carbonUserAvatar from "../../assets/img/icons/carbonUserAvatar.svg";
import leftSpiral from "../../assets/img/icons/leftSpiral.svg";
import rightSpiral from "../../assets/img/icons/rightSpiral.svg";
import { validateEmail } from "../../helpers/helpers";
import { responseSwal } from "../../helpers/responseSwal";
import "../../index.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import "./login-style.scss";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
      forgotPassword: false,
    };
  }

  onChange = (e) => {
    let name = e.target.name;
    const value = e.target.value;
    if (name === "username") this.setState({ username: value });
    if (name === "password") this.setState({ password: value });
    if (name === "email") this.setState({ email: value });
  };

  componentDidMount() {
    const { history } = this.props;
    if (this?.props.isAuth) {
      history?.push("/accountselect");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isAuth !== this.props.isAuth) {
      const { history } = this?.props;
      if (this.props.isAuth) {
        history?.push("/accountselect");
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let { username } = this.state;
    let { password } = this.state;
    this.props.validateFieldsLogin(username, password);
  };
  checkForgotPassword() {
    let { email } = this.state;
    let changeemail = deepcopy(email);
    if (!!!validateEmail(changeemail)) {
      return responseSwal({
        icon: "error",
        title: "Error",
        text: "Correo no válido",
      });
    } else {
      this.props.forgotPassword(this.state);
    }
  }

  render() {
    // //console.log("InitialState", this.state);
    return (
      <>
        <div
          style={{
            backgroundColor: "#F8FAFB",
            overflowX: "clip",
          }}
        >
          <Row>
            <Col xs={3}>
              <div
                style={{
                  paddingTop: "85%",
                }}
              >
                <img
                  style={{ width: "85%", marginTop: "8%" }}
                  src={leftSpiral}
                  alt="Spiral"
                />
              </div>
            </Col>

            {this.state?.forgotPassword ? (
              <Col xs={6} className="pb-5">
                <form
                  style={{
                    marginTop: "10%",
                    borderRadius: "2%",
                    backgroundColor: "white",
                  }}
                  className={`${tableStyles.shade} mx-5`}
                  id="formLogin"
                  onSubmit={this.handleSubmit}
                >
                  <div
                    className="pb-5"
                    style={{ padding: "8rem", textAlign: "center" }}
                  >
                    <img src={bioWel} alt="BIO" />
                  </div>

                  <div className="d-flex justify-content-between row">
                    <div
                      className=" mx-auto bg-transparent col-12 bg-info"
                      style={{
                        paddingLeft: "14%",
                        paddingRight: "14%",
                      }}
                    >
                      <h5 className={`${tableStyles.darkBlueText} `}>
                        <b>¿Olvidó su contraseña?</b>
                      </h5>
                      <p className={`subtitle2 ${tableStyles.f16}`}>
                        Ingrese su correo electrónico y le enviaremos una
                        contraseña provisional de ingreso
                      </p>
                    </div>
                  </div>

                  <div className="form-group-login">
                    <div
                      style={{ "background-color": "#FFFF", display: "flex", justifyContent: "end" }}
                      className="input-group mb-3 input-blue-border fw-bold mt-3 mx-auto"
                    >
                      <img
                        src={carbonEmail}
                        alt="email icon"
                        className="input-group-prepend"
                      />
                      <input
                        type="email"
                        placeholder="Correo electrónico"
                        name="email"
                        className="p-3 border-0 input-text-login  w-90"
                        style={{
                          "background-color": "#FFFF",
                          "outline-style": "none",
                        }}
                        value={this.state.email}
                        onChange={this.onChange}
                        aria-describedby="basic-addon1"
                        aria-label="Usuario"
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-between row "
                    style={{ paddingBottom: "10%" }}
                  >
                    <div className="row  mt-2 p-0">
                      <div className={`col-8 d-flex justify-content-end`}>
                        <Button
                          style={{
                            width: "35%",
                            backgroundColor: "#dfe3e6",
                            color: "#005dbf",
                            borderColor: "transparent",
                          }}
                          size="md"
                          onClick={() =>
                            this.setState({ forgotPassword: false })
                          }
                        >
                          Atrás
                        </Button>
                      </div>

                      <div
                        className="col-3 d-flex justify-content-end"
                        style={{ paddingRight: "3%" }}
                      >
                        {/* <Button
                          style={{ width: "35%", borderColor: "transparent" }}
                          size="md"
                          className={tableStyles.btnPrimary}
                        >
                          <img className="cursorPointer" src={send} />
                          &nbsp; Enviar&nbsp;
                        </Button> */}

                        <Button
                          variant="primary"
                          className="btnSendFgtPwd d-flex"
                          style={{
                            alignItems: "center",
                            placeContent: "center",
                          }}
                          onClick={() => this.checkForgotPassword()}
                          disabled={!this.state.email}
                        >
                          <FiSend style={{ marginRight: "10px" }} />
                          <div>Enviar</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            ) : (
              <Col xs={6} className="pb-5">
                <form
                  style={{
                    marginTop: "10%",
                    borderRadius: "2%",
                    backgroundColor: "white",
                  }}
                  className={`${tableStyles.shade} mx-5`}
                  id="formLogin"
                  onSubmit={this.handleSubmit}
                >
                  <div
                    className="pb-5"
                    style={{ padding: "8rem", textAlign: "center" }}
                  >
                    <img src={bioWel} alt="ERP" />
                  </div>

                  <div className="form-group-login">

                    <div
                      style={{ "background-color": "#FFFF" }}
                      className="input-blue-border fw-bold login_input"
                    >
                      <img
                        src={carbonUserAvatar}
                        alt="User icon"
                        className="m-auto"
                      />
                      <input
                        type="email"
                        placeholder="USUARIO"
                        name="username"
                        className="py-3 border-0 input-text-login"
                        style={{
                          "background-color": "#FFFF",
                          "outline-style": "none",
                          borderWidth: "1px",
                          paddingLeft: '15px'
                        }}
                        value={this.state.username}
                        onChange={this.onChange}
                        aria-describedby="basic-addon1"
                        aria-label="Usuario"
                      />
                    </div>

                    <div
                      style={{ "background-color": "#FFFF" }}
                      className="input-group input-blue-border fw-bold login_input"
                    >
                      <img
                        src={carbonPassKey}
                        alt="Lock icon"
                        className="m-auto"
                      />
                      <input
                        type="password"
                        placeholder="CONTRASEÑA"
                        name="password"
                        className=" py-3 border-0 input-text-login"
                        style={{
                          "background-color": "#FFFF",
                          "outline-style": "none",
                          borderWidth: "1px",
                          paddingLeft: '15px'
                        }}
                        value={this.state.password}
                        onChange={this.onChange}
                      />
                    </div>

                  </div>

                  <div
                    className="d-flex justify-content-between row"
                    style={{ paddingBottom: "10%", marginTop: '15px' }}
                  >
                    <div
                      className=" mx-auto bg-transparent col-6"
                      style={{ paddingLeft: "13%", alignContent: "center" }}
                    >
                      <label
                        // to="/login/olvidarcontrasena"
                        onClick={() => this.setState({ forgotPassword: true })}
                        className="cursorPointer"
                        style={{
                          color: "#005DBF",
                          "text-decoration": "None",
                          "vertical-align": "-webkit-baseline-middle",
                          "font-style": "normal",
                          "font-size": "14px",
                          "line-height": "15,23px",
                          letterSpacing: "0.06px",
                        }}
                      >
                        &nbsp; ¿Olvidó su contraseña?
                      </label>
                    </div>

                    <div className="col-6">
                      <Button
                        style={{ width: "74%", borderRadius: "7px", border: '2px solid transparent' }}
                        disabled={!this.state.password || !this.state.username}
                        type="submit"
                        variant={
                          !this.state.password || !this.state.username
                            ? "secondary"
                            : "primary"
                        }
                        className=" mt-3"
                        size="lg"
                      >
                        ACCEDER
                      </Button>{" "}
                    </div>
                  </div>
                </form>
              </Col>
            )}

            <Col xs={3}>
              <div
                style={{
                  paddingBottom: "85%",
                }}
              >
                <img
                  style={{ width: "85%", marginLeft: "15%" }}
                  src={rightSpiral}
                  alt="Spiral"
                />
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  validateFieldsLogin: (username, password) => {
    dispatch(validateUser(username, password));
  },
  forgotPassword: (account) => {
    dispatch(forgotPassword(account));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
