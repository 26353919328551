import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GET_DISCIPLINARY_PROCESS } from "./actionTypes";
import { message } from "../helpers/helpers";

const MySwal = withReactContent(Swal);

export const getDisciplinaryProcess = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_DISCIPLINARY_PROCESS,
    loading: true
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/payroll/disciplinary-processes?${filters}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      const { results, rowTotal } = res;
      dispatch({
        type: GET_DISCIPLINARY_PROCESS,
        payload: results,
        rows: rowTotal,
        loading: false
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_DISCIPLINARY_PROCESS,
        payload: [],
        rows: 0,
        loading: false
      });
      console.error(err.message);
    });
};

export const updateDisciplinaryProcess = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/disciplinary-process-management/${data.id}`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message("error", "Error", result.message,  undefined,
      true
);
    }
  } catch (error) {
    console.error(error);
    return message("error", "Error", "Ha ocurrido un error",  undefined,
    true
);
  }
};
