import { PrintTwoTone } from "@material-ui/icons";
import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { message } from "../helpers/helpers";
import { responseSwal } from "../helpers/responseSwal";
import {
  COMPLEMENTARY_DOCS_SECTIONS,
  GET_ALL_POSITIONS,
  GET_WORK_TIMES,
  JOB_POS_AREAS,
  PAYROLL_STATUS,
} from "./actionTypes";

export const getAllJobPositions = (objFilters, doafter=null) => (dispatch, getState) => {
  const filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/job_position/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if(doafter){
        doafter()
      }
      dispatch({
        type: GET_ALL_POSITIONS,
        payload: res.results,
        total: res.row_total,
      });
    })
    .catch((error) => {
      if(doafter){
        doafter()
      }
      responseSwal({
        icon: "error",
        title: "Error",
        text: "Error al cargar información",
      });
    });
};
export const getWorkTimes = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/work_times/`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_WORK_TIMES,
        payload: res.results,
        total: res.row_total,
      });
    })
    .catch((error) => {
      responseSwal({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    });
};
// payroll/job_position/
export const createNewJobPosition = async (
  job_title,
  job_description,
  area_id,
  area_name,
  area_leader,
  supervisor_pos,
  extra_hours,
  work_time,
  entity_account,
  active,
  created_by,
  comp_doc_ids,
  token
  ,dependency
) => {
  try {
    let data = {
      job_title,
      job_description,
      area_id,
      area_name,
      area_leader,
      supervisor_pos,
      extra_hours,
      work_time,
      entity_account,
      active,
      created_by,
      comp_doc_ids,
      token,
      dependency,
    };
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/job_position/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    console.log('%cpositionsActions.js line:118 result', 'color: white; background-color: #007acc;', result);
    if (result?.success) {
      return result;
    } else {
      console.log('%cpositionsActions.js line:123 a', 'color: white; background-color: #007acc;', "a");
      return result
    }
  } catch (error) {
    console.error(error);
    return message("error", "Error", error.message);
  }
};
export const complementary_docs_get_sections = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/complementary_docs_sections/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, sections } = res;
      dispatch({
        type: COMPLEMENTARY_DOCS_SECTIONS,
        payload: results,
        sections: sections,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const job_position_areas = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/job_position_areas/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: JOB_POS_AREAS,
        payload: results,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const list_status = (params) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(params);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/status/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results } = res;
      dispatch({
        type: PAYROLL_STATUS,
        payload: results,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};
export const changePositionStatus = async (id, active, token) => {
  try {
    let data = {
      id,
      active,
    };
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/job_position/`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.error(error);
    return message("error", "Error", error.message);
  }
};
