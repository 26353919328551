import React, { Component, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import Alert from "../../assets/img/icons/alert-circle-orange.svg";
import Check from "../../assets/img/icons/CheckMark.svg";
import GenericTable from "../Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import Atras from "../../assets/img/icons/atras-icon.svg";
import excel from "../../assets/img/icons/excel.svg";
import Select from "react-select";
import { customSelectSpecialClear, customSelectSpecialDark } from "../../components/Layouts/react-select-custom";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import ModalNew from "../Layouts/ModalNew";
import { Box, Button as btnMui } from '@mui/material';

function DetailPayrollProvision(props) {

    const [showModal, setShowModal] = useState(false);

    let history = useHistory();
    function handleClick() {
        history.push("/contabilidad/autorizacionNomina");
    }

    const header = [

        <th className="col text-center">Nombre</th>,
        <th className="text-center">Total</th>,
        <th className="col text-center"></th>,
    ]
    const body = [
        <tr>
            <td className="text-center">Prestamos </td>
            <td className="text-center">$7.000.000</td>
            <td className="text-center">
                <img className="text-start pointer" src={Filtrar} onClick={() => setShowModal(true)} />
            </td>
        </tr>,
        <tr>
        <td className="text-center">Prestamos </td>
        <td className="text-center">$7.000.000</td>
        <td className="text-center">
            <img className="text-start pointer" src={Filtrar} onClick={() => setShowModal(true)} />
        </td>
    </tr>,
    <tr>
    <td className="text-center">Prestamos </td>
    <td className="text-center">$7.000.000</td>
    <td className="text-center">
        <img className="text-start pointer" src={Filtrar} onClick={() => setShowModal(true)} />
    </td>
</tr>,
<tr>
            <td className="text-center">Prestamos </td>
            <td className="text-center">$7.000.000</td>
            <td className="text-center">
                <img className="text-start pointer" src={Filtrar} onClick={() => setShowModal(true)} />
            </td>
        </tr>,
        <tr>
        <td className="text-center">Prestamos </td>
        <td className="text-center">$7.000.000</td>
        <td className="text-center">
            <img className="text-start pointer" src={Filtrar} onClick={() => setShowModal(true)} />
        </td>
    </tr>,
    <tr>
    <td className="text-center">Prestamos </td>
    <td className="text-center">$7.000.000</td>
    <td className="text-center">
        <img className="text-start pointer" src={Filtrar} onClick={() => setShowModal(true)} />
    </td>
</tr>,
<tr>
            <td className="text-center">Prestamos </td>
            <td className="text-center">$7.000.000</td>
            <td className="text-center">
                <img className="text-start pointer" src={Filtrar} onClick={() => setShowModal(true)} />
            </td>
        </tr>,
        <tr>
        <td className="text-center">Prestamos </td>
        <td className="text-center">$7.000.000</td>
        <td className="text-center">
            <img className="text-start pointer" src={Filtrar} onClick={() => setShowModal(true)} />
        </td>
    </tr>,
    <tr>
    <td className="text-center">Prestamos </td>
    <td className="text-center">$7.000.000</td>
    <td className="text-center">
        <img className="text-start pointer" src={Filtrar} onClick={() => setShowModal(true)} />
    </td>
</tr>,
<tr>
    <td className="text-center fw-bold" style={{color:'#58595b'}}>TOTAL </td>
    <td className="text-center fw-bold" style={{color:'#58595b'}}>$25.000.000</td>
    <td className="text-center">
        
    </td>
</tr>

    ]
    const headerModal = [

        <th className="col text-center">ID</th>,
        <th className="text-center">Empleado</th>,
        <th className="col text-center">Total</th>,
    ]
    const bodyModal = [
        <tr>
            <td className="text-center">1.145.654.984 </td>
            <td className="text-center">Melisa Villanueva</td>
            <td className="text-center">$500.000</td>
        </tr>,
        <tr>
            <td className="text-center">1.145.654.984 </td>
            <td className="text-center">Melisa Villanueva</td>
            <td className="text-center">$500.000</td>
        </tr>,
        <tr>
            <td className="text-center">1.145.654.984 </td>
            <td className="text-center">Melisa Villanueva</td>
            <td className="text-center">$500.000</td>
        </tr>,
        <tr>
            <td className="text-center">1.145.654.984 </td>
            <td className="text-center">Melisa Villanueva</td>
            <td className="text-center">$500.000</td>
        </tr>,
        <tr>
            <td className="text-center"></td>
            <td className="text-center fw-bold"  style={{color:'#58595b'}}>TOTAL</td>
            <td className="text-center fw-bold"  style={{color:'#58595b'}}>$5000.000</td>
        </tr>,

    ]

    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ width: "50%", marginRight: "3rem" }}>
                <div>
                    <div className="d-flex">
                        <img className={`${tableStyles.title} mr-3 pointer`} src={Atras} onClick={() => props.setDetails({ show: false })} />
                        <label className={tableStyles.titlePayrollProvision}>
                            <b>Diciembre 30 - 2021</b> <br />
                            <span
                                style={{ fontSize: "18px" }}>
                                Provisión de nómina </span>
                        </label>
                    </div>

                    

                    <Row>
                        <Col xs={8}>
                            <label className={tableStyles.subTitlePayrollProvision}>
                                Aportes patronales</label>
                        </Col>
                        <Col xs={4} className='d-flex justify-content-end align-items-center'>
                            <img src={excel} alt="excel" />
                        </Col>
                    </Row>
                </div>
                <GenericTable
                    headers={header}
                    children={body}
                    dark={true}
                >
                </GenericTable>

                <btnMui
                    style={{textTransform: 'none',marginLeft:'auto'}} 
                    className={tableStyles.btnCancelStyle}
                    onClick={()=>{
                        handleClick()
                    }}
                    >
                    Cancelar
                </btnMui>
            </div>
            <ModalNew
                show={showModal}
                onHide={() => setShowModal(false)}
                title={"Detalle de deducción"}
                subtitle={"Libranza ITAU"}
                btnYesEvent={false}
                size={"500"}

            >
                <Box display={'flex'} justifyContent='end'>
                    <img src={excel} alt="excel" />
                </Box>

                <GenericTable
                    headers={headerModal}
                    children={bodyModal}
                >
                </GenericTable>



            </ModalNew>

        </>
    )

};
export default DetailPayrollProvision;