import React, { useEffect } from "react";
import { useState } from "react";
import { OrdGenericTemplate } from "../OrderingModule/OrderingComponents/OrdGenericTemplate";
import tableStyles from "../components/Layouts/tableStyle.module.scss";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { ordCustomSelect, appDarkCustomSelect } from "../components/Layouts/react-select-custom";
import add from "../assets/img/icons/addDocument.svg";
import { useSelector } from "react-redux";
import OrdTable from "../OrderingModule/OrderingComponents/OrdTable";
import OrdModal from "../OrderingModule/OrderingComponents/OrdModal";
import { useGetMethod, usePostMethod } from "../Hooks/useFetch";
import ordBlueSearch from "../assets/img/icons/ordBlueSearch.svg";
import icon_search from "../assets/img/icons/ordSearchClearBlue.svg";
import clearIcon from "../assets/img/icons/close.svg";
import { formatToRcSelect, loader, swalConfirm, validateEmptyString } from "../helpers/helpers";
import NumberFormat from "react-number-format";
import { useHistory } from 'react-router-dom';

const RequestDiscount = () => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const userId = store.loginReducer.user_data.id;
  const [triggerTable, setTriggerTable] = useState(0);
  const [triggerAppointment, setTriggerAppointment] = useState(0);
  const history = useHistory()
  const [data, setData] = useState({
    contractId: "",
    srvId: "",
    patientId: "",
    typeDiscount: "",
    discountQty: "",
  });
  // //console.log("data: ", data);
  const [requestInfo, setRequestInfo] = useState({
    idUser: userId,
    eaccount: idAccount,
    contractId: "",
    srvId: "",
    patientId: "",
    typeDiscount: "",
    srvPrice: "",
  });
  const { results: listPatientResults, load: listPatientLoader, trigger: getListPatient } = useGetMethod();
  const { results: listAppointmentResults, load: listAppointmentLoader, trigger: getListAppointment } = useGetMethod();
  const { results: insurancesResults, load: insurancesLoader, trigger: getInsurancesOptions } = useGetMethod();
  const { results: servicesResults, load: servicesLoader, trigger: getServicesOptions } = useGetMethod();
  const { results: servicesAppResults, load: servicesApp, trigger: getServicesApp } = useGetMethod();
  const { load: sendDiscountLoader, trigger: sendDiscount } = usePostMethod();
  const insurancesOptions = formatToRcSelect(insurancesResults?.results?.contracts, "corporateClientId", "corporateClient", "", "", "");
  const servicesOptions = formatToRcSelect(servicesResults?.results, "srvId", "srvName", "srvPrice", "", "");
  const servicesAppOptions = formatToRcSelect(servicesAppResults?.results, "id", "name", "", "", "");
  const typeOptions = [
    { value: "", label: "-Seleccione" },
    { value: "Porcentaje", label: "Porcentaje" },
    { value: "Valor", label: "Valor" },
  ];
  const [modalPatient, setModalPatient] = useState({
    eaccount: idAccount,
    discountFilter: true,
    page: 1,
    perpage: 10,
    show: false,
    state: false,
    id: 0,
  });
  const [filterAppointment, setFilterAppointment] = useState({
    eaccount: idAccount,
    page: 1,
    perpage: 10,
    search: "",
    modality: "on_site"
  })
  const [modalAppointment, setModalAppointment] = useState({
    perpage: 10,
    show: false,
    eaccount: idAccount,
    page: 1,
    state: false,
    id: "",
    companyTypeTag: "particular",
    app: false
  });
  useEffect(() => {
    getListPatient({
      url: "/medical/patient/listPatient/",
      objFilters: modalPatient,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerTable]);

  useEffect(() => {
    getListAppointment({
      url: "/medical/generals/appointments/",
      objFilters: filterAppointment,
      token: token,
    });
    getServicesApp({
      url: "/medical/generals/services/",
      objFilters: { eaccount: idAccount },
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAppointment]);

  useEffect(() => {
    if (modalAppointment.app) {
      setData({ ...data, isDisabled: true });
    } else {
      setData({ ...data, isDisabled: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalAppointment.app]);
  const headers = [
    {
      title: "Paciente",
      className: "px-2 col-6 text-start",
    },
    {
      title: "No. identificación",
      className: "px-2 col-4 text-start",
    },
  ];
  const headersAppointment = [
    {
      title: "Paciente",
      className: "px-2 col-5 text-start",
    },
    {
      title: "Servicio",
      className: "px-2 col-5 text-start",
    },
    {
      title: "Fecha",
      className: "px-2 col-2 text-center",
    },
  ];
  const closePatientModal = () => {
    setModalPatient({ ...modalPatient, show: false, filterPatient: "", state: true });
    setTriggerTable(triggerTable + 1);
  };
  const closeAppointmentModal = () => {
    setModalAppointment({ ...modalAppointment, show: false, search: "", state: true });
    setFilterAppointment({ ...filterAppointment, search: "", srvId: "", startDate: "", endDate: "", });
    setTriggerAppointment(triggerAppointment + 1);
  };

  const backHistory = () => {
    history.goBack()
  }

  const selectedPatient = () => {
    setData({
      ...data,
      patientId: modalPatient.id,
      document: modalPatient.document,
      name: modalPatient.name,
      insurance: "",
      contract: "",
      srvId: "",
      srvPrice: '',
      typeDiscount: '',
      amount: '',
      finalValue: '',
      comments: ''
    });
    getInsurances();
    closePatientModal();

  };
  const selectedAppointment = () => {
    setData({
      ...data,
      patientId: modalAppointment.id,
      document: modalAppointment.document,
      name: modalAppointment.name,
      contractId: modalAppointment.contractId,
      srvPrice: modalAppointment.srvPrice,
      srvId: modalAppointment.srvId,
      insurance: modalAppointment.companyId,
      idApp: modalAppointment.idApp,
      applicationDateApp: modalAppointment.applicationDateApp,
      contract: modalAppointment.contract,
      company: modalAppointment.company,
      serviceName: modalAppointment.serviceName,
      siteName: modalAppointment.siteName,
      cityName: modalAppointment.cityName,
    });
    closeAppointmentModal();
  };
  //  ANCHOR TABLE INFO
  const formatDataAppointment = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let orange = modalAppointment.state === true && modalAppointment.idApp === item.idApp ? tableStyles.ordBgFileTableYellow : "";
        tempList.push(
          <tr
            key={item?.srvId}
            className={`hover-table-row ${orange}`}
            onClick={() => {
              setModalAppointment({
                ...modalAppointment,
                state: true,
                id: item.idPatient,
                idApp: item.idApp,
                document: item.documentPatient,
                name: item.fullNamePatient,
                contractId: item.idContractCorporateClient,
                contract: item.contractCorporateClient,
                srvPrice: item.corpPrice,
                srvId: item.idService,
                applicationDateApp: item.applicationDateApp,
                insurance: item.companyId,
                company: item.company,
                serviceName: item.serviceName,
                siteName: item.siteName,
                cityName: item.cityName,
              });
            }}
          >
            <td className={`text-start px-2 ${orange} `}>{item.fullNamePatient}</td>
            <td className={`text-start px-2 ${orange} `}>{item?.serviceName}</td>
            <td className={`text-center px-2 ${orange} `}>{item?.dateApp}</td>
          </tr>
        );
      });
    }
    return tempList;
  };
  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let orange = modalPatient.state === true && modalPatient.id === item.id ? tableStyles.ordBgFileTableYellow : "";
        let fullName = `${item?.first_name} ${item?.other_names} ${item?.last_name} ${item?.second_surname}`;
        tempList.push(
          <tr
            key={item?.srvId}
            className={`hover-table-row ${orange}`}
            onClick={() => {
              setModalPatient({ ...modalPatient, state: true, id: item.id, document: item.document, name: fullName });
            }}
          >
            <td className={`text-start px-2 ${orange} `}>{fullName}</td>
            <td className={`text-start px-2 ${orange} `}>{item?.document}</td>
          </tr>
        );
      });
    }
    return tempList;
  };
  // ANCHOR Selects petitions
  const getInsurances = (id) => {
    getInsurancesOptions({
      url: "/medical/patient/",
      objFilters: { id: modalAppointment.app ? modalAppointment.id : modalPatient.id, companyTypeTag: "particular", eaccount: idAccount, isMain: false },
      token: token,
    });
  };
  const getContract = (insurance) => {
    let item = insurancesResults?.results?.contracts.filter((contract) => contract.corporateClientId === insurance);
    setData({
      ...data,
      contractId: item && item[0]?.contractId,
      contract: item && item[0]?.contractDescription ? item[0]?.contractDescription : "-",
      insurance: insurance,
      srvId: "",
      typeDiscount: "", amount: "", finalValue: "", srvPrice: "",
    });
    getServices(item[0]?.contractId ? item[0]?.contractId : "");
  };
  const getServices = (id) => {
    getServicesOptions({
      url: "/medical/generals/serviceByContract/",
      objFilters: { contractId: id, eaccount: idAccount },
      token: token,
    });
  };
  // -----------------------------
  const amountOnChangeInput = (e) => {
    setData({ ...data, amount: e });
    getFinalValue(e);
  };
  const getFinalValue = (e) => {
    let finalValue;
    let valorInicial = data?.srvPrice ? data?.srvPrice : "";
    if (data.typeDiscount !== "") {
      if (data?.typeDiscount === "Porcentaje") {
        let qtyDiscount = e / 100;
        let percentage = valorInicial - (valorInicial * qtyDiscount);
        finalValue = percentage ? percentage : "";
        setData({ ...data, qtyDiscount: qtyDiscount, finalValue: finalValue, amount: e });
      } else {
        let value = valorInicial - e;
        finalValue = value ? value : "";
        setData({ ...data, qtyDiscount: e, finalValue: finalValue, amount: e });
      }
    } else {
      setData({ ...data, finalValue: finalValue });
    }
  };
  //  SUBMIT FORM
  const validationsRequest = () => {
    let validations;
    if (modalAppointment.app) {
      validations = validateEmptyString([
        { field: data.amount, message: "La cantidad es obligatoria" },
        { field: data.typeDiscount, message: "El tipo es obligatorio" },
      ]);
    } else {
      validations = validateEmptyString([
        { field: data.amount, message: "La cantidad es obligatoria" },
        { field: data.srvId, message: "El servicio es obligatorio" },
        { field: data.typeDiscount, message: "El tipo es obligatorio" },
        { field: data.insurance, message: "La aseguradora es obligatoria" },
        { field: data.name, message: "El paciente es obligatorio" },
      ]);
    }
    if (validations.valid) {
      setRequestInfo({
        ...requestInfo,
        contractId: data.contractId,
        srvId: data.srvId,
        patientId: data.patientId,
        typeDiscount: data.typeDiscount,
        qtyDiscount: data.qtyDiscount,
        srvPrice: data.srvPrice,
      });
      modalAppointment.app ? sendRequestApp() : sendRequestDiscount();
    }
  };
  const sendRequestDiscount = () => {
    let symbol = data.typeDiscount === "Porcentaje" ? "%" : "$"
    let textData = symbol === '%' ? `${data.amount}${symbol}` : `${symbol}${data.amount}`

    swalConfirm({
      title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
      text: `Se solicitará un descuento de ${textData} para  el paciente: ${data.name}`,
      confirmButtonText: "Si, continuar",
      doAfterConfirm: () => {
        sendDiscount({
          url: "/medical/appointment/requestDiscountWithOutApp/",
          token: token,
          method: "POST",
          body: {
            idUser: userId,
            eaccount: idAccount,
            contractId: data.contractId,
            srvId: data.srvId,
            patientId: data.patientId,
            typeDiscount: data.typeDiscount,
            qtyDiscount: data.qtyDiscount,
            discountQty: data.qtyDiscount,
            srvPrice: data.srvPrice,
            comments: data.comments,
          },
          doAfterSuccess: () => {
            setTriggerTable(triggerTable + 1);
            clearData();
          },
        });
      },
    });
  };
  const sendRequestApp = () => {
    let symbol = data.typeDiscount === "Porcentaje" ? "%" : "$"
    let textData = symbol === '%' ? `${data.amount}${symbol}` : `${symbol}${data.amount}`

    swalConfirm({
      title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
      text: `Se solicitará un descuento de ${textData} para  el paciente: ${data.name}`,
      confirmButtonText: "Si, continuar",
      doAfterConfirm: () => {
        sendDiscount({
          url: "/medical/appointment/discountOfOneAppointment/",
          token: token,
          method: "POST",
          body: {
            idUser: userId,
            eaccount: idAccount,
            typeDiscount: data.typeDiscount,
            qtyDiscount: data.qtyDiscount,
            comments: data.comments,
            idApp: data.idApp,
          },
          doAfterSuccess: () => {
            setTriggerAppointment(triggerAppointment + 1);
            clearData();
          },
        });
      },
    });
  };
  const clearData = () => {
    setData({
      ...data,
      name: "",
      document: "",
      insurance: "",
      contract: "",
      srvId: "",
      srvPrice: "",
      typeDiscount: "",
      amount: "",
      comments: "",
      applicationDateApp: "",
      siteName: "",
      cityName: "",
      company: "",
      serviceName: "",
      finalValue: "",
    });
    setModalAppointment({ ...modalAppointment, idApp: "" })
  };

  const handleClickPatient = () => {
    setModalAppointment({ ...modalAppointment, app: false })
    setModalPatient({ ...modalPatient, show: true });
    // clearData();
  }
  // //console.log( "El 555: ", modalAppointment)

  return (
    <>
      {(listPatientLoader || insurancesLoader || servicesLoader || sendDiscountLoader || servicesApp || listAppointmentLoader) && loader}
      <OrdModal
        title={"Seleccionar cita"}
        show={modalAppointment.show}
        btnYesName={"Seleccionar"}
        btnNoName={"Cancelar"}
        size={800}
        onHide={() => closeAppointmentModal()}
        btnYesEvent={() => selectedAppointment()}
        btnNoEvent={() => closeAppointmentModal()}
        sizeBody={`${tableStyles.modalHeight}`}
      >
        <div>
          {/* <Col xs={5}> */}
          <Row>
            <Col xs={3}>
              <Form.Group className=" text-start" controlId="fName">
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}>
                  <b> &nbsp;</b>
                </Form.Label>
                <div className={`d-flex ${tableStyles.appGrayInputSearch} ${tableStyles.f12}`}>
                  <Form.Control
                    style={{ backGroundColor: "red" }}
                    className={`ord-roundInput    ${tableStyles.appInputDate}`}
                    type="text"
                    placeholder="Nombre o ID del paciente"
                    autoComplete="off"
                    onChange={(e) => {
                      setFilterAppointment({ ...filterAppointment, search: e.target.value });
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setTriggerAppointment(triggerAppointment + 1);
                        setFilterAppointment({ ...filterAppointment, page: 1 });
                      }
                    }}
                  />
                  <img src={ordBlueSearch} className="" alt="buscar"></img>
                </div>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <div className="w-100">
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}>
                  <b> &nbsp;</b>
                </Form.Label>
                <Select noOptionsMessage={() => 'No hay datos'}
                  options={servicesAppOptions}
                  className="text-secondary "
                  placeholder={"Servicios"}
                  styles={appDarkCustomSelect}
                  value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                  onChange={(e) => {
                    setFilterAppointment({ ...filterAppointment, srvId: e.value, });
                  }}
                ></Select>
              </div>
            </Col>
            <Col xs={6}>
              <div className="d-flex">
                <Col xs={5}>
                  <Form.Group className="mb-3 text-start" controlId="startName">
                    <Form.Label className={`m-0 ${tableStyles.clearBlueText} ${tableStyles.f14} d-flex flex-column w-100`}>
                      <span>&nbsp; &nbsp;Fecha inicio</span>
                    </Form.Label>
                    <input
                      className={` ${tableStyles.appInputDate}`}
                      placeholder="Date"
                      type="date"
                      value={filterAppointment.startDate}
                      onChange={(e) => {
                        setFilterAppointment({ ...filterAppointment, startDate: e.target.value });
                      }}
                    />
                  </Form.Group>

                </Col>
                <Col xs={5} className="mx-3">
                  <Form.Group className="mb-3 text-start " controlId="endDat">
                    <Form.Label className={`m-0 ${tableStyles.clearBlueText} ${tableStyles.f14} d-flex flex-column w-100`}>
                      <span>&nbsp; &nbsp;Fecha fin</span>
                    </Form.Label>
                    <input
                      className={` ${tableStyles.appInputDate} `}
                      type="date"
                      placeholder=""
                      value={filterAppointment.endDate}
                      onChange={(e) => {
                        setFilterAppointment({ ...filterAppointment, endDate: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xs={2} className=" justify-content-start align-items-center  mb-2">
                  <Form.Label className={`m-0 ${tableStyles.ordClearBlueText} ${tableStyles.f14} d-flex flex-column w-100`}>
                    <b> &nbsp;</b>
                  </Form.Label>
                  <img
                    className="hoverPointer"
                    height="27px"
                    alt="search"
                    src={icon_search}
                    onClick={() => {
                      setTriggerAppointment(triggerAppointment + 1);
                      setFilterAppointment({ ...filterAppointment, page: 1 });
                    }}
                  />
                </Col>
              </div>
            </Col>
          </Row>
          <div>
            <OrdTable
              headers={headersAppointment}
              hasChildren={true}
              oneHeadDarkBlue={false}
              lowerCase={false}
              fTdClearBlue={true}
              paginate={{
                activePage: filterAppointment.page,
                totalPages: listAppointmentResults?.rowTotal,
                perPage: filterAppointment.perpage,
                pageRangeDisplayed: 1,
                onChangePage: async (e) => {
                  const result = await getListAppointment({
                    url: "/medical/generals/appointments/",
                    objFilters: { ...filterAppointment, page: e },
                    token: token,
                  });
                  setFilterAppointment({ ...filterAppointment, page: e });
                  formatDataAppointment(result?.results);
                },
                showTextDetails: true,
              }}
            >
              {formatDataAppointment(listAppointmentResults?.results)}
            </OrdTable>
          </div>
        </div>
      </OrdModal>
      <OrdModal
        title={"Seleccionar paciente"}
        show={modalPatient.show}
        btnYesName={"Seleccionar"}
        btnNoName={"Cancelar"}
        size={500}
        onHide={() => closePatientModal()}
        btnYesEvent={() => selectedPatient()}
        btnNoEvent={() => {
          closePatientModal()
        }}
        sizeBody={`${tableStyles.modalHeight}`}
      >
        <div>
          {/* <Col xs={5}> */}
          <div xs={6}>
            <Form.Group className="mb-3 text-start" controlId="fName">
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
              {/* <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipSearch()}> */}

              <div className={`d-flex ${tableStyles.ordGrayInputSearch}`}>
                <Form.Control
                  style={{ backGroundColor: "red" }}
                  className={`ord-roundInput`}
                  type="text"
                  placeholder="Escribe aquí para buscar"
                  autoComplete="off"
                  onChange={(e) => {
                    setModalPatient({ ...modalPatient, filterPatient: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setTriggerTable(triggerTable + 1);
                    }
                  }}
                />
                <img src={ordBlueSearch} className="p-2" alt="buscar"></img>
              </div>

              {/* </OverlayTrigger> */}
            </Form.Group>
          </div>
          <div>
            <OrdTable
              headers={headers}
              hasChildren={true}
              oneHeadDarkBlue={false}
              lowerCase={false}
              fTdClearBlue={true}
              paginate={{
                activePage: modalPatient.page,
                totalPages: listPatientResults?.rowTotal,
                perPage: modalPatient.perpage,
                pageRangeDisplayed: 1,
                onChangePage: async (e) => {
                  const result = await getListPatient({
                    url: "/medical/patient/listPatient/",
                    objFilters: { ...modalPatient, page: e },
                    token: token,
                  });
                  setModalPatient({ ...modalPatient, page: e });
                  formatData(result?.results);
                },
                showTextDetails: true,
              }}
            >
              {formatData(listPatientResults?.results)}
            </OrdTable>
          </div>
        </div>
      </OrdModal>
      <OrdGenericTemplate className="w-90 my-4" showBottomBorder={false} title={"Solicitar descuento"} titleSize={12}>
        <div className="w-90 mx-1 my-3">
          <Col xs={2} className={`${tableStyles.ordClearBlueCard} my-3`}>
            <div className="w-100 p-2 d-flex align-items-center ">
              <button
                onClick={() => {
                  setModalAppointment({ ...modalAppointment, show: true, app: true });
                }}
              >
                <img src={add} alt="seleccionarCita" height="15px" />
                <b className={`${tableStyles.ordClearBlueText}`}> Seleccionar cita</b>
              </button>
            </div>
          </Col>

          <div className={`${tableStyles.ordClearBlueCard} `}>
            <Row className={`p-4 `}>
              <Col xs={3}>
                <div className="d-flex flex-column w-100">
                  <Form.Group className="mb-3 text-start" controlId="fPatient">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}>
                      <b> &nbsp;Paciente</b>
                    </Form.Label>
                    <div className="d-flex w-100">
                      <button
                        className="cursorPointer w-100"
                        onClick={() => handleClickPatient()}>
                        <Form.Control
                          className={`ord-roundInput  ${tableStyles.appSearchPatientButton}  cursorPointer`}
                          autoComplete="off"
                          type="text"
                          placeholder="Escriba..."
                          value={data.name}
                          onChange={(e) => {
                            setTriggerTable(triggerTable + 1);
                            setData({ ...data, insurance: "" });
                          }}
                        />
                      </button>
                    </div>
                  </Form.Group>
                </div>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Identificación</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput `}
                    disabled
                    type="text"
                    placeholder=""
                    value={data.document}
                    onChange={(e) => {
                      setTriggerTable(triggerTable + 1);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Aseguradora</span>
                  </Form.Label>

                  {modalAppointment.app ? (
                    <Form.Control className={`ord-roundInput `} disabled type="text" placeholder="" value={data.company} />
                  ) : (
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={insurancesOptions}
                      isDisabled={data.isDisabled}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setData({ ...data, insurance: e.value, srvId: "", typeDiscount: "", amount: "", finalValue: "" });
                        getContract(e.value);
                      }}
                    ></Select>
                  )}
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex justify-content-between`}>
                    <span> &nbsp;Contrato</span>
                    <div className="d-flex justify-content-end ">
                      {modalAppointment.app && (
                        <img
                          src={clearIcon}
                          width={15}
                          className={"cursorPointer"}
                          alt="closeIcon"
                          style={{ marginRight: "-10px" }}
                          onClick={() => {
                            clearData();
                          }}
                        />
                      )}
                    </div>
                  </Form.Label>
                  <Form.Control className={`ord-roundInput `} disabled type="text" placeholder="" value={data.contract} />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Servicio</span>
                  </Form.Label>
                  {modalAppointment.app ? (
                    <Form.Control
                      disabled
                      className={`ord-roundInput`}
                      type="text"
                      placeholder="Escribe..."
                      value={data.serviceName}
                      onChange={(e) => { }}
                    />
                  ) : (
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={servicesOptions}
                      isDisabled={data.isDisabled}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      value={servicesOptions.find((x) => x.value === data.srvId)}
                      onChange={(e) => {
                        setData({ ...data, srvId: e.value, srvPrice: e.extra, typeDiscount: "", amount: "", finalValue: "" });
                      }}
                    ></Select>
                  )}
                </Form.Group>
              </Col>
              {modalAppointment.app &&
                <>
                  <Col xs={3}>
                    <Form.Group className="mb-3 text-start" controlId="fName">
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                        <span> &nbsp;Ubicación</span>
                      </Form.Label>
                      <Form.Control
                        className={`ord-roundInput `}
                        disabled
                        type="text"
                        placeholder=""
                        value={data.cityName}
                        onChange={(e) => {
                          setTriggerTable(triggerTable + 1);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-3 text-start" controlId="fName">
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                        <span> &nbsp;Sede</span>
                      </Form.Label>
                      <Form.Control
                        className={`ord-roundInput `}
                        disabled
                        type="text"
                        placeholder=""
                        value={data.siteName}
                        onChange={(e) => {
                          setTriggerTable(triggerTable + 1);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-3 text-start" controlId="fName">
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                        <span> &nbsp;Fecha y hora</span>
                      </Form.Label>
                      <Form.Control
                        className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                        disabled
                        type="text"
                        placeholder=""
                        value={data.applicationDateApp}
                        onChange={(e) => {
                          setTriggerTable(triggerTable + 1);
                        }}
                      />
                    </Form.Group>
                  </Col> </>}



            </Row>
          </div>

          <div className={``}>
            <Row className={`py-4`}>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Valor inicial</span>
                  </Form.Label>

                  <NumberFormat
                    allowNegative={false}
                    className={'form-control ord-roundInput'}
                    decimalSeparator={"."}
                    disabled
                    id="srvPrice"
                    isNumericString={true}
                    maxLength={12}
                    prefix={'$'}
                    thousandSeparator={","}
                    thousandsGroupStyle="thousand"
                    value={data.srvPrice}
                    onValueChange={({ value, floatValue, formattedValue }) => {
                      setData({ ...data, startDate: Number(value) });
                    }}
                  />

                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Tipo</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={typeOptions}
                    className="text-secondary "
                    placeholder={"Seleccione"}
                    value={typeOptions.find((x) => x.value === data.typeDiscount)}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setData({ ...data, typeDiscount: e.value, amount: "", finalValue: "" });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Cantidad</span>
                  </Form.Label>

                  {data.typeDiscount === "Valor" ? (
                    <div className="input-group ">
                      <div className="input-group-prepend">
                        <span className="input-group-text ord-roundInput">&nbsp;$</span>
                      </div>
                      <NumberFormat
                        className={'form-control ord-roundInput'}
                        allowNegative={false}
                        id="amount"
                        thousandsGroupStyle="thousand"
                        thousandSeparator
                        value={data.amount}
                        onValueChange={(e) => {
                          amountOnChangeInput(e.value);
                        }}
                        maxLength={12}
                      ></NumberFormat>
                    </div>
                  ) : data.typeDiscount === "Porcentaje" ? (
                    <div className="input-group ">
                      <NumberFormat
                        className={'form-control ord-roundInput'}
                        allowNegative={false}
                        id="percentage"
                        thousandsGroupStyle="thousand"
                        thousandSeparator
                        value={data.amount}
                        onValueChange={(e) => {
                          amountOnChangeInput(e.value);
                        }}
                        maxLength={12}
                      ></NumberFormat>
                      <div className="input-group-prepend">
                        <span className="input-group-text ord-roundInput"> &nbsp;%</span>
                      </div>
                    </div>
                  ) : (
                    <NumberFormat
                      className={'form-control ord-roundInput'}
                      allowNegative={false}
                      id="amount"
                      thousandsGroupStyle="thousand"
                      thousandSeparator
                      value={data.amount}
                      onValueChange={(e) => {
                        amountOnChangeInput(e.value);
                      }}
                      maxLength={12}
                    ></NumberFormat>
                  )}
                </Form.Group>
              </Col>

              <Col xs={3}>
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Valor final</span>
                  </Form.Label>

                  <NumberFormat
                    // allowNegative={false}
                    className={'form-control ord-roundInput'}
                    decimalSeparator={"."}
                    disabled
                    id="finalValue"
                    isNumericString={true}
                    maxLength={12}
                    prefix={'$'}
                    thousandSeparator={","}
                    thousandsGroupStyle="thousand"
                    value={data.finalValue}
                    onValueChange={({ value, floatValue, formattedValue }) => {
                      setData({ ...data, finalValue: Number(value) });
                    }}
                  />

                </Form.Group>
              </Col>
              <div>
                <Form.Group className="mb-3" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span className="text-start">&nbsp;Comentarios</span>
                  </Form.Label>
                  <textarea
                    value={data?.comments}
                    placeholder={"Escribir..."}
                    rows="40"
                    cols="50"
                    style={{ height: "4rem" }}
                    className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
                    key={triggerTable + "observations"}
                    onChange={(e) =>
                      setData({
                        ...data,
                        comments: e.target.value,
                      })
                    }
                  ></textarea>
                </Form.Group>
              </div>
            </Row>
          </div>
          <div className="d-flex w-full justify-content-end">
            <div className="mx-4">
              <button
                onClick={() => backHistory()}
                className={`${tableStyles.ordBtnSecondary} p-2`}
              >
                Cancelar
              </button>
            </div>
            <div>
              <button
                className={`${tableStyles.ordBtnPrimary}  p-2`}
                onClick={() => {
                  validationsRequest();
                }}
              >
                Enviar solicitud{" "}
              </button>
            </div>
          </div>
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default RequestDiscount;
