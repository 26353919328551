
import React, { useEffect, useState, useMemo } from "react";
import {useHistory} from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import {customSelectNewDark} from "../Layouts/react-select-custom";
import Select from "react-select";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button } from 'react-bootstrap';

import Back from "../../assets/img/icons/atras-icon.svg";
import weirdArrow from "../../assets/img/icons/weirdArrow.svg";

import ModalNew from "../Layouts/ModalNew";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import tableStylesScroll from "../Layouts/genericTableScroll.module.scss";

import { requisition_filter } from "../../actions/requisitionActions";
import { storage_get } from "../../actions/storageActions";
import {getAllConCenter} from "../../actions/consumptionCenterActions";
import {requisitions_articlesfromlist} from "../../actions/requisitionActions";
import {
    lots_from_warehouse,
    update_salida_consumo_reducer,
    go_go_salida_consumo,
} from "../../actions/salidaConsumoActions";

//import CollapsibleTable from "./tabletest";
import tabletest from "../Requisitions/tabletest.module.scss";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import myStyles from "./SalidaConsumo.module.scss";
import { convertDateToLatinFormat } from "../../helpers/helpers";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

function SalidaConsumo(){
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const today = new Date();
    const history = useHistory();

    const [filters, setFilters] = useState({
        created_by: counter.loginReducer.user_data.id,
        cc_id: counter.salidaConsumoReducer.centroConsumo,
        warehouse_id: counter.salidaConsumoReducer.mainWarehouse,
        eaccount: counter.loginReducer.currentAccount.id,
    });

    const [reserve, setreserve] = useState({
        obs: "",
    });

    const [lotControl, setlotControl] = useState({
        show: false,
    });

    
    const [reqControl, setreqControl] = useState({
        show: false,
        checked: [],
    });

    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        dispatch( getAllConCenter({eaccount: counter.loginReducer.currentAccount.id, lite:true}) );
        dispatch( storage_get({id_account: counter.loginReducer.currentAccount.id, lite:true}));
    },[]);

    useEffect(() => {
        setFilters({
            ...filters, 
            warehouse_id: counter.salidaConsumoReducer.mainWarehouse,
            cc_id: counter.salidaConsumoReducer.centroConsumo,
        })
    },[counter.salidaConsumoReducer.mainWarehouse, counter.salidaConsumoReducer.centroConsumo]);

    useEffect(() => {
        if(lotControl.show){
            dispatch( lots_from_warehouse(lotControl) );
        }
    },[lotControl.show]);

    useEffect(()=>{
        if(reqControl.show && !!filters.cc_id && !!filters.warehouse_id){
            dispatch(requisition_filter({
                eaccount: counter.loginReducer.currentAccount.id, 
                cc_id: filters.cc_id,
                basewarehouse: filters.warehouse_id,
                output:1
            }));
        }
    }, [reqControl.show]); //filters.warehouse_id

    useEffect(() => {
        dispatch( requisitions_articlesfromlist(counter.salidaConsumoReducer.reqIds, true));
    },[trigger]);


    const reqHeaders = [
        <div key={`damnit1`} className={tableStylesScroll.f1}>&nbsp;</div>,
        <div key={`damnit2`} className={tableStylesScroll.f1}>No.Req</div>,
        <div key={`damnit3`} className={tableStylesScroll.f2}>Fecha</div>,
        <div key={`damnit4`} className={tableStylesScroll.f3}>Concepto</div>,
    ]

    const requisitionCheckHandle = (reqIn) => {
        let reqChanges = reqControl.checked;
        let exists = reqChanges.find(x => (x.id == reqIn.id));
        if(exists){
            reqChanges = reqChanges.filter(x => x != exists);
        }else{
            reqChanges.push(reqIn);
        }
        setreqControl({ ...reqControl, checked: reqChanges});
    }

    const reqRender = () =>{
        let reslitst = [];
        let total=0;
        if(Array.isArray(counter.requisitionReducer.requisitions)){
            counter.requisitionReducer.requisitions.map((item)=>{
                total += item.lot_qty_received;
                let temprow = <section className={`d-flex`}>
                    <td className={tableStylesScroll.f1}>
                        <input 
                            type="checkbox"
                            onChange={()=>requisitionCheckHandle(item)}
                            checked={ !!reqControl.checked.find(change=> change.id ==item.id) }
                        />
                    </td>
                    <td className={tableStylesScroll.f1}>
                        {item.num_req}
                    </td>
                    <td className={tableStylesScroll.f2}>
                        {convertDateToLatinFormat(item.requisition_date)}
                    </td>
                    <td className={`${tableStylesScroll.f3} text-start`}>
                        {item.concept} 
                    </td>
                </section>
                reslitst.push(temprow);
            })
        
        }
        return reslitst;

    }

    const setSalidaConsumoReducer = () => {
        let requids = [];
        if(Array.isArray(requids)){
            requids = reqControl.checked.map(x=> x.id);
        }
        dispatch(
            update_salida_consumo_reducer(
                requids ,
                filters.warehouse_id ,
                filters.cc_id
            )
        );
        setreqControl({show:false, checked:[]})
        setTrigger(trigger+1);
    }

    const optionWarehouse = () => {
        let tempModules = [
            {
                value: "",
                label: "Seleccionar...",
            } 
        ];
        if (Array.isArray(counter.storageReducer.list)) {
            counter.storageReducer.list.map((elem) => {
                tempModules.push({
                    value: elem.id,
                    label: elem.id+": "+elem.description,
                });
            });

          }
        return tempModules;
    }

    const optionConCenter = () => {
        let tempModules = [
            {
                value: "",
                label: "Seleccionar...",
            } 
        ];
        if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
            counter.consumptionCenterReducer.consumption_center.map((elem) => {
                tempModules.push({
                    value: elem.id,
                    label: elem.id+": "+elem.description,
                });
            });

          }
        return tempModules;
    }

    const lotHeaders = [
        <div key={`damnit2`} className={tableStylesScroll.f3}>Vencimiento</div>,
        <div key={`damnit3`} className={tableStylesScroll.f3}>Lote</div>,
        <div key={`damnit4`} className={tableStylesScroll.f2}>Cantidad</div>,
    ]

    const [lotRender, lotTotal] = useMemo(() => {
        let reslitst = [];
        let total = 0;
        if(Array.isArray(counter.salidaConsumoReducer.lots)){
            counter.salidaConsumoReducer.lots.map((item)=>{
                total += item.lot_qty_received;
                let temprow = <section className={`d-flex`}>
                    <td className={tableStylesScroll.f3}>
                        {item.lot_date_expiration}
                    </td>
                    <td className={tableStylesScroll.f3}>
                        {item.lot_name}
                    </td>
                    <td className={tableStylesScroll.f2}>
                        {item.lot_qty_received} 
                    </td>
                </section>
                reslitst.push(temprow);
            })
        }
        return [reslitst, total];
    }, [counter.salidaConsumoReducer.lots])

    const useRowStyles = makeStyles({
        root: {
          '& > *': {
            borderBottom: 'unset',
          },
        },
      });

    const mainheaders = [
        "Código",
        "Descripción del Articulo",
        "Solicitado",
        "Almacén"
    ]
    
    const [collapsedRows, setcollapsedRows] = useState([]);
    const togglecollapserow = (id_article) =>{
        let temparray = [...collapsedRows];
        let itscollapsed = collapsedRows.find(x=> x==id_article);
        if(!!itscollapsed){
            temparray = temparray.filter(x=> x!=id_article);
        }else{
            temparray.push(id_article);
        }
        setcollapsedRows(temparray);
    }
    
    function Row(props) {
        const { row } = props;
        const open = !!(collapsedRows.find(x=> x==row.id_article));
        const classes = useRowStyles();
    
        return (
        <React.Fragment>
    
            {/* table row summary shown */}
            <TableRow className={`
                ${(props.index%2 == 0)? tabletest.tablerowEven : ""}
                ${tabletest.tablerow} 
                ${classes.root}
            `}>
    
                <TableCell>
                    <IconButton 
                    aria-label="expand row" 
                    size="small" 
                    //onClick={() => setOpen(!open)}
                    onClick={() => togglecollapserow(row.id_article)}
                    >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
        
                {/*component="th" scope="row" */}
                <TableCell > {row.article?.id} </TableCell> 
                <TableCell > {row.article?.description} </TableCell> 
                <TableCell align="center">{row.qty_article}</TableCell>
                <TableCell align="center">{Array.isArray(row.sublist)? row.sublist.length : "-"}</TableCell>
            </TableRow>
    
            {/* subtable in row details */}
            <TableRow className={`${(props.index%2 == 0)? tabletest.tablerowEven : ""}`}>
            
            {Array.isArray(row.sublist) && row.sublist.length>0 && 
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                    <p className={tabletest.subtableTitle}>
                        Almacenes
                    </p>
                    <Table size="small" aria-label="purchases">
                    {
                    //subtable headers
                    }
                    <TableHead>
                        <TableRow className={tabletest.subtableTh}>
                            <TableCell align="left">No.</TableCell>
                            <TableCell align="left">Almacén</TableCell>
                            <TableCell align="center">Lote</TableCell>
                            <TableCell align="center">Disponible</TableCell>
                            <TableCell align="center">A retirar</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                    //subtable headers 
                    }
                    <TableBody>
                        {row.sublist.map((warehouserow, index) => (
                        <TableRow key={index} className={tabletest.subtableTr}>
                            <TableCell align="left" width="10%"> {index+1} </TableCell>
                            <TableCell align="left"  width="45%">{warehouserow.description} </TableCell>
                            <TableCell align="center"  width="15%"> 
                                <div 
                                    className={tableStyles.circleAddButton}
                                    style={{margin: "auto"}}
                                    onClick={ ()=>setlotControl({
                                        ...lotControl, 
                                        show:true, 
                                        id_warehouse: warehouserow.id_warehouse,
                                        warehousearticle: warehouserow.id_warehousearticle,
                                        description: warehouserow.description,
                                        article: row.article?.description,
                                    }) } 
                                >
                                </div> 
                            </TableCell>
                            <TableCell align="center"  width="15%">{warehouserow.available}</TableCell>
                            <TableCell align="center" width="15%">{warehouserow.qty} </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
    
                    </Table>
                </Box>
                </Collapse>
            </TableCell>
            }

            </TableRow>
        </React.Fragment>
        );
    }
    
    
    
    //main table 
    const CollapsibleTable = () => {
        return (
        <Paper >
            <TableContainer className={tabletest.table}>
                <Table stickyHeader aria-label="sticky collapsible table">
                {/* table headers here */}
                <TableHead  className={`${tabletest.head} ${tabletest.dark}`}>
                    <TableRow>
                    <TableCell className={tabletest.dark} />
                    {mainheaders.map( (item)=> 
                        <TableCell align="center" className={`${tabletest.dark}`}>{item}</TableCell>
                    )}
                    </TableRow>
                </TableHead>
                
                {/* table body is generated from array */}
                <TableBody className={tabletest.body}>
                    {counter.requisitionReducer.consolidate_art
                    .filter(row=>(Array.isArray(row.sublist) && row.sublist.length>0))
                    .map((row, index) => (
                    <Row 
                        key={row.name} 
                        index={index}
                        row={row} 
                    />
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        </Paper>
        );
    }



    const buttonOk = () =>{
        let warehousearticlesreserves = []
        counter.requisitionReducer.consolidate_art
        .filter(row=>(Array.isArray(row.sublist) && row.sublist.length>0))
        .map(row=> {
            row.sublist.map(subrow => {
                warehousearticlesreserves.push(subrow)
            })
        });

        dispatch( go_go_salida_consumo(
                {
                    ...filters,
                    req_ids: counter.salidaConsumoReducer.reqIds,
                    warehousearticlesreserves:  warehousearticlesreserves,
                    obs: reserve.obs,
                },
                ()=>{setTrigger(trigger+1)}
            )
        );
    }

    const buttonCancel = () =>{
        
    }

    return (
        <div className={tableStyles.container}>
            {/* { !!counter.individualAuctionReducer.selected_loading && (
            <div className="loading">
                <Loader type="Oval" color="#003f80" height={100} width={100} />
            </div>
            ) } */}

            <div className={`d-flex ${myStyles.titleBlock}`}>
                <img 
                    src={Back} 
                    className={myStyles.backIcon} 
                    onClick={()=>history.push("/compras/registrarMovimiento")}
                />
                <h1 className={myStyles.mainTitle}>Salida para Consumo </h1>
                <img 
                    src={weirdArrow}
                    className={myStyles.backIcon} 
                />
            </div>
            
            <div className={` ${myStyles.bigContainer}`}>

                <div className={`d-flex`}>
                    <div className={`${myStyles.item} ${myStyles.mr16} ${myStyles.f1}`}>
                        <label className={myStyles.label}>Centro de Consumo</label>
                        {/* <input className={myStyles.input} type="text"/> */}
                        <Select noOptionsMessage={() => 'No hay datos'}
                            value={optionConCenter().find(x => x.value == filters.cc_id)}
                            onChange={e => setFilters({...filters, cc_id: e.value}) } 
                            options={optionConCenter()}
                            placeholder={filters.cc_id}
                            styles={customSelectNewDark}
                            maxHeight={22}
                        />
                    </div>

                    
                    <div className={`${myStyles.item} ${myStyles.mr16} ${myStyles.f1}`}>
                        <label className={myStyles.label}>Almacén Principal</label>
                        {/* <input className={myStyles.input} type="text" disabled/> */}
                        <Select noOptionsMessage={() => 'No hay datos'}
                            value={optionWarehouse().find(x => x.value == filters.warehouse_id)}
                            onChange={e => setFilters({...filters, warehouse_id: e.value}) } 
                            options={optionWarehouse()}
                            placeholder={filters.warehouse_id}
                            styles={customSelectNewDark}
                            maxHeight={22}
                        />
                    </div>
                    
                    <div className={`${myStyles.item} ${myStyles.mr16} ${myStyles.f1}`}>
                        <label className={myStyles.label}>Requisiciones</label>
                        <input 
                            className={`register-inputs ${myStyles.inputReq}`} 
                            type="text"
                            value={
                                Array.isArray(counter.salidaConsumoReducer.reqIds)?
                                    counter.salidaConsumoReducer.reqIds.join(",")
                                : 
                                    ""
                            }
                            onClick={()=>
                                (!!filters.cc_id && !!filters.warehouse_id)?
                                    setreqControl({...reqControl, show:true})
                                :
                                    customSwaltAlert({
                                        icon:'warning',
                                        title:"Intenta de nuevo",
                                        text:'Seleccione un Centro de Consumo y Almacén Primero',
                                        showCancelButton: false
                                    })
                            }
                        />
                    </div>
                    
                    
                    <div className={`${myStyles.item} ${myStyles.f1}`}>
                        <label className={myStyles.label}>Fecha</label>
                        <input 
                            className={`register-inputs`} 
                            type="date" 
                            value={today.toISOString().slice(0, 10)} 
                            disabled
                        />
                    </div>
                </div>

                {CollapsibleTable()}

                <div className= {`d-flex flex-column`}>
                    <label htmlFor="observations" className={`${myStyles.label}`}>
                        Observaciones
                    </label>
                    <textarea
                        name="observations"
                        className={`${myStyles.observationsArea}`}
                        value={reserve.obs}
                        onChange={e=>setreserve({...reserve, obs: e.target.value})}
                    ></textarea>
                </div>

            </div>

            <div className={myStyles.bottomRow}>
                <Button 
                    className={tableStyles.btnPrimary} 
                    onClick={()=>buttonOk()}
                >
                    Enviar
                </Button>
                
                <Button 
                    className={tableStyles.btnSecondary}  
                    onClick={()=>buttonCancel()}
                >
                    Cancelar
                </Button>
            </div>

            <ModalNew
                title="Lotes"
                show={lotControl.show}
                onHide={ ()=>setlotControl({show:false}) } 
                btnNoName={"Cerrar"}
            >
                <div className={`${myStyles.articleDetails}`}>
                    <p className={`${myStyles.articleDetails__title}`}>Información del Producto</p>
                    <p className={`${myStyles.articleDetails__label}`}>Producto</p>
                    <p className={`${myStyles.articleDetails__text}`}>{lotControl.description} - {lotControl.article}</p>
                </div>
                <GenericTableScroll
                    headers={lotHeaders}
                    body={
                        counter.salidaConsumoReducer.lots_loading?
                            <section className="mx-auto">
                                <Loader
                                type="Oval"
                                color="#003f80"
                                height={50}
                                width={50}
                                />
                            </section>
                        :
                            lotRender
                    }
                />
                <div className={`col-md-3`}>
                    <p className={`${myStyles.articleDetails__label__blue}`}>Restante</p>
                    <p className={`${myStyles.articleDetails__text}`}>{lotTotal}</p>
                </div>


            </ModalNew>

            <ModalNew
                title="Requisiciones"
                show={reqControl.show}
                onHide={ ()=>setreqControl({show:false, checked:[]}) } 
                btnYesEvent={()=>setSalidaConsumoReducer()}
                btnYesName="Traer"
            >
                {/* <div className={`${myStyles.item} ${myStyles.mr16} ${myStyles.f1}`}>
                    <label className={myStyles.label}>Almacén</label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                        value={optionWarehouse().find(x => x.value == filters.warehouse_id)}
                        onChange={e => setFilters({...filters, warehouse_id: e.value}) } 
                        options={optionWarehouse()}
                        placeholder=""
                        styles={customSelectNewDark}
                        maxHeight={22}
                    />
                </div> */}
                <GenericTableScroll
                    headers={reqHeaders}
                    body={
                        counter.requisitionReducer.requisitions_loading?
                            <section className="mx-auto">
                                <Loader
                                    type="Oval"
                                    color="#003f80"
                                    height={50}
                                    width={50}
                                />
                            </section>
                        :
                            reqRender()
                    }
                />


            </ModalNew>
        </div>
    );
}

export default SalidaConsumo;