// location

import { API_VERSION, defaultFilters, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import {
  CITY_GET,
  PROVINCE_GET,
  COUNTRY_GET,
  PROVINCE_GET_ALTERNATIVE,
  CITY_GET_ALTERNATIVE,
  PROVINCE_GET_ALL,
  CITY_GET_ALL,
  COMPANYTYPE,
} from "./actionTypes";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { customSwaltAlert } from "../helpers/customSwaltAlert";
const MySwal = withReactContent(Swal);

export const city_get = (objFilters, doAfter=null) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/cities/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { data } = res;
      if (objFilters.alternative_cities) {
        dispatch({
          type: CITY_GET_ALTERNATIVE,
          payload: data,
        });
      } else {
        dispatch({
          type: CITY_GET,
          payload: data,
        });
        if(!!doAfter){
        doAfter(); 
      }
    }
    })
    .catch((err) => {
      if(!!doAfter){
        doAfter(); 
      }
      return console.error(err);
      // MySwal.fire({
      //   icon: "error",
      //   title: "Error al conectar",
      //   text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
      // })
    });
};
export const city_get_all = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/cities/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { data } = res;
      dispatch({
        type: CITY_GET_ALL,
        payload: data,
      });
    }).catch((error)=>{
      console.error(error)
    })
};

export const getCompanyType = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/medical/client/companytype/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: COMPANYTYPE,
        payload: res,
      });
    }).catch((error)=>{
      console.error(error)
    })
};

export const province_get = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/provinces/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  }).then((response) => response.json())
      .then((res) => {
        const { data } = res;
        if (objFilters.alternative_provinces) {
          dispatch({
            type: PROVINCE_GET_ALTERNATIVE,
            payload: data,
          });
        }else{
          dispatch({
            type: PROVINCE_GET,
            payload: data,
          });
        }
      }).catch((err) =>{
        console.error(err);
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        });
      });
  };
export const province_get_all = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/provinces/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { data } = res;
      dispatch({
        type: PROVINCE_GET_ALL,
        payload: data,
      });
    })
    .catch((err) => {
      return console.error(err);
    });
};

export const getCountries = (params) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  // const id_account = getState().loginReducer.currentAccount.id;
  const filters = convertFilterToString(params);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/countries?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: COUNTRY_GET,
        payload: res.results,
      });
    })
    .catch((err) => {
      return console.error(err);
    });
};
