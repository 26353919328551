import React, { useEffect, useState } from "react";
import { loader, onCapitalLetter } from "../../helpers/helpers";
import { useSelector } from "react-redux";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Form, Row } from "react-bootstrap";
import CustomPopupStyles from "../../../src/components/Popup/customPopup.module.scss";
import threeDots from "../../assets/img/icons/threeDots.svg";
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import { OrdGenericTemplate } from './../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from './../../OrderingModule/OrderingComponents/OrdModal';
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import Select from "react-select";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import userLogo from "../../assets/img/header/user-img.png";
import OrdTableAccordion from "../../OrderingModule/OrderingComponents/OrdTableAccordion";
import { customSwaltAlertAsistencial } from './../../helpers/customSwaltAlertAsistencial';


const AssignmentLocations = () => {


    // --------------------------- States  -------------------------

    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const eaccount = store.loginReducer.currentAccount.id;
    const createdBy = store.loginReducer.user_data.id

    const [data, setData] = useState({
        selects: {
            listProfiles: null,
            listAccountsProfiles: null,
            listSites: null,
        },
        table: {
            listDataTableFilter: null,
            listDataTable: null,
            dataArrayOrder: null,
            filters: {
                page: 1,
                perPage: 10,
                pageRangeDisplayed: 3,
                search: ''
            },
            formatDataTable: null,
            headersTable: [
                {
                    title: "Personal",
                    className: "text-start px-5 fw-bold",
                },
                {
                    title: "Cargo",
                    className: "text-start px-2 fw-bold",
                },
                {
                    title: "Sedes",
                    className: "text-center fw-bold"
                },
                {
                    title: "",
                    className: ""
                },
            ],
            trigger: 0,
        },
        modal: {
            isEditing: null,
            show: null
        },
        dataSchema: {
            userId: null,
            profileId: null,
            sites: [],
            eaccount, createdBy,
            actionType: null
        },

    })

    const { selects, table, modal, dataSchema } = data
    const [name, setName] = useState('')

    // constantes
    const message1 = `${dataSchema.sites.length > 1 ? 'sedes' : 'Sede'}`
    const message2 = `${dataSchema.sites.length > 1 ? 'las' : 'la'}`
    const message4 = `${dataSchema.sites.length > 1 ? 'asignadas' : 'asignada'}`
    const message5 = `${dataSchema.sites.length > 1 ? 'han' : 'ha'}`


    //  ---------------------------Petitions -------------------------

    const {
        load: updateSiteLoader,
        trigger: updateSite
    } = usePostMethod();

    const {
        load: loaderCreateNewSite,
        trigger: createNewSite
    } = usePostMethod();


    const {
        results: listProfiles,
        trigger: getListProfiles,
        load: loadListProfiles
    } = useGetMethod()

    const {
        results: listAccountsProfiles,
        trigger: getListAccountsProfiles,
        load: loadListAccountsProfiles
    } = useGetMethod()

    const {
        results: listDataTable,
        trigger: getListDataTable,
        load: loadListDataTable
    } = useGetMethod()

    const {
        results: listSites,
        trigger: getListSites,
        load: loadListSites
    } = useGetMethod()



    // //  --------------------------- Modal -------------------------
    const postModal = () => {
        setData(state => (
            {
                ...state,
                modal: { ...state.modal, show: true, isEditing: false },
                dataSchema: {
                    userId: null,
                    profileId: null,
                    sites: [],
                    eaccount, createdBy,
                    actionType: 'post'
                }
            }));
    };

    const closeModal = () => {
        setData(state => ({
            ...state,
            modal: {
                isEditing: null,
                show: null
            },
        }));
    };

    //  --------------------------- Methods ------------------------------

    const editSites = () => {

        updateSite({
            url: "/medical/admissions/sites/",
            token: token,
            method: "PUT",
            body: { ...dataSchema, sites: dataSchema.sites?.map(e => (e.value)) },
            succesAction: (res) => {
                const sedesLength = `${dataSchema.sites.length > 1 ? 'Sedes' : 'Sede'}`
                const messageUpdate = `${dataSchema.sites.length > 1 ? 'actualizadas' : 'actualizada'}`
                closeModal()
                setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));

                customSwaltAlertAsistencial({
                    icon: "success",
                    title: `${sedesLength} ${messageUpdate} exitosamente`,
                    text: `Se ${message5} actualizado ${message2} ${message1} a: ${name}`,
                    showCancelButton: false,
                })

            },
            doAfterException: (res) => {
                closeModal()
                customSwaltAlertAsistencial({
                    icon: "warning",
                    title: `Intenta de nuevo`,
                    text: `${res.message}`,
                    showCancelButton: false,
                })
            }
        })
    };

    const createAssigment = () => {
        const nameFull = selects.listAccountsProfiles.find(e => e?.value === dataSchema?.userId)?.label
        createNewSite({
            url: "/medical/admissions/sites/",
            token: token,
            method: "POST",
            body: { ...dataSchema, sites: dataSchema.sites?.map(e => (e.value)) },
            succesAction: (res) => {
                
                closeModal()
                setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));
                //  ${dataSchema?.sites?.map(e => JSON.stringify(e.x)).join(",")}
                customSwaltAlertAsistencial({
                    icon: "success",
                    title: `${onCapitalLetter({ text: message1 })} ${message4} exitosamente`,
                    text: `Se ${message5} asignado ${message2} ${message1} a: ${nameFull}`,
                    showCancelButton: false,
                })
            },
            doAfterException: (res) => {
                customSwaltAlertAsistencial({
                    icon: "warning",
                    title: `Intenta de nuevo`,
                    text: `El usuario: ${nameFull}, ya cuenta con sedes habilitadas para este cargo`,
                    showCancelButton: false,
                })
            }
        })
    };

    const disabledAssigment = (item) => {
        customSwaltAlertAsistencial({
            title: `¿Está seguro?`,
            text: `Se inhabilitará a ${item.userFullName}`,
            confirmButtonText: `Si, Continuar`,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                updateSite({
                    url: "/medical/admissions/sites/",
                    token: token,
                    method: "PUT",
                    body: { ...item, status: 0, eaccount, createdBy, sites: item.sites?.map(e => (e.siteId)) },
                    succesAction: (res) => {
                        closeModal()
                        setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));

                        customSwaltAlertAsistencial({
                            icon: "success",
                            title: `Asignación inhabilitada exitosamente`,
                            text: `Se ha inhabilitado a: ${item?.userFullName}`,
                            showCancelButton: false,
                        })

                    },
                    doAfterException: (res) => {
                        customSwaltAlertAsistencial({
                            icon: "warning",
                            title: `Intenta de nuevo`,
                            text: `${res.message}`,
                            showCancelButton: false,
                        })
                    }
                })
            }
        })
    };

    const enableAssigment = (item) => {
        customSwaltAlertAsistencial({
            title: `¿Está seguro?`,
            text: `Se habilitará a ${item.userFullName}`,
            confirmButtonText: `Si, Continuar`,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                updateSite({
                    url: "/medical/admissions/sites/",
                    token: token,
                    method: "PUT",
                    body: { ...item, status: 1, eaccount, createdBy, sites: item.sites?.map(e => (e.siteId)) },
                    succesAction: (res) => {
                        closeModal()
                        setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));

                        customSwaltAlertAsistencial({
                            icon: "success",
                            title: `Asignación habilitada exitosamente`,
                            text: `Se ha habilitado a: ${item?.userFullName}`,
                            showCancelButton: false,
                        })

                    },
                    doAfterException: (res) => {
                        customSwaltAlertAsistencial({
                            icon: "warning",
                            title: `Intenta de nuevo`,
                            text: `${res.message}`,
                            showCancelButton: false,
                        })
                    }
                })
            }
        })
    };


    const accordionBody = (id) => (
        <div
            style={
                table?.dataArrayOrder[id]?.status === 0
                    ? { textAlign: 'start', opacity: '0.3' }
                    : { textAlign: 'start' }
            }
        >

            <p className="fw-bold" style={{ color: '#00b4cc' }}>Sedes</p>

            <ul style={{ listStyle: 'none', padding: '0', fontSize: '14px' }} className="text-secondary">
                {
                    table?.dataArrayOrder[id]?.sites?.map((e, i) => (
                        <li key={i}>{e?.siteName.toLowerCase()}</li>
                    ))
                }
            </ul>

        </div>
    )


    // -------------------------------- Validations -----------------------
    const validationRequest = (item) => {

        if (dataSchema.profileId === null) {

            return customSwaltAlertAsistencial({
                icon: "warning",
                title: `Intenta de nuevo`,
                text: `Campo cargo es obligatorio`,
                showCancelButton: false,
            })
        }

        if (dataSchema.userId === null) {

            return customSwaltAlertAsistencial({
                icon: "warning",
                title: `Intenta de nuevo`,
                text: `Campo usuario es obligatorio `,
                showCancelButton: false,
            })

        }

        if (!dataSchema.sites.length) {

            return customSwaltAlertAsistencial({
                icon: "warning",
                title: `Intenta de nuevo`,
                text: `Campo sede es obligatorio `,
                showCancelButton: false,
            })

        }

        modal.isEditing ? editSites(item) : createAssigment();

    };

    //-------------- format data to table 🍕   --------------------------
    const formatData = (array) => {
        let tempList = [];
        const actives = array.filter(e => e.status === 1)
        const inactives = array.filter(e => e.status === 0)
        const dataArrayOrder = [...actives, ...inactives]

        if (Array.isArray(dataArrayOrder)) {
            dataArrayOrder?.forEach((e, index) => {

                tempList.push([
                    {
                        text:
                            <div
                                style={
                                    !e.status
                                        ? { display: 'flex', columnGap: '10px', alignItems: 'center', opacity: '0.3' }
                                        : { display: 'flex', columnGap: '10px', alignItems: 'center' }
                                }>

                                {/* <div style={{ height: '30px', width: '30px', borderRadius: '100%', background: '#ccc' }}></div> */}
                                <div style={{ height: '35px', width: '35px', borderRadius: '100%', border: '1px solid #00b4cc', display: 'flex', overflow: 'hidden' }}>
                                    <img width={'100%'} src={e.userImage === '' ? userLogo : e.userImage} alt='img' /></div>
                                <div>{e?.userFullName?.toLowerCase()?.replace(/\b\w/g, l => l.toUpperCase())}</div>
                            </div>,
                        className: "text-start px-2"
                        // className: "text-start px-2 d-flex align-items-center"
                    },
                    {
                        text:
                            <div style={!e.status ? { opacity: '0.3' } : {}}>
                                {e?.profileName?.toLowerCase()?.replace(/\b\w/g, l => l.toUpperCase())}
                            </div>,
                        className: "text-start px-2 "
                    },
                    {
                        text:
                            <div style={
                                !e.status
                                    ? {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        columnGap: '10px',
                                        alignItems: 'center',
                                        opacity: '0.3',
                                        marginLeft: '10px'
                                    }
                                    : {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        columnGap: '10px',
                                        alignItems: 'center',
                                        marginLeft: '10px'
                                    }

                            }

                            >
                                <div>{e?.sites?.length}</div>
                            </div>,
                        // className: "text-center d-flex align-items-center"
                        className: "text-center"
                    },
                    {
                        text: (
                            <CustomPopupExtend
                                triggerSrc={threeDots}
                                center
                                extraButtons={
                                    e?.status ?
                                        [
                                            {
                                                text: "Editar",
                                                class: CustomPopupStyles.popUpGeneric,
                                                event: () => {
                                                    setData(state => ({
                                                        ...state,
                                                        modal: { isEditing: true, show: true },
                                                        dataSchema: { ...state.dataSchema, ...e }
                                                    }))

                                                    setName(e?.userFullName)
                                                },
                                            },
                                            {
                                                text: `${'Inhabilitar'}`,
                                                class: CustomPopupStyles.popUpGeneric,
                                                event: () => {
                                                    setData(state => ({
                                                        ...state,
                                                        dataSchema: { ...state.dataSchema, ...e }
                                                    }))

                                                    disabledAssigment(e)
                                                },
                                            },
                                        ]
                                        : [
                                            {
                                                text: `${e.status ? 'Inhabilitar' : 'Habilitar'}`,
                                                class: CustomPopupStyles.popUpGeneric,
                                                event: () => {
                                                    setData(state => ({
                                                        ...state,
                                                        dataSchema: { ...state.dataSchema, ...e }
                                                    }))
                                                    enableAssigment(e)
                                                },
                                            },
                                        ]
                                }
                            />
                        ),
                    }


                ]);
            });
        }

        setData(state => ({ ...state, table: { ...state.table, dataArrayOrder, formatDataTable: tempList } }))
    };


    //-------------- useEffect 🍕   --------------------------

    useEffect(() => {
        formatData(table?.listDataTable || [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.listDataTable])

    useEffect(() => {

        if (table.trigger) {

            if (table.filters.search) {
                getListDataTable({
                    url: `/medical/admissions/sites/`,
                    objFilters: { eaccount: eaccount, search: table.filters.search, page: table.filters.page, perpage: table.filters.perPage },
                    token: token
                })
            } else {
                getListDataTable({
                    url: `/medical/admissions/sites/`,
                    objFilters: { eaccount: eaccount, page: table.filters.page, perpage: table.filters.perPage },
                    token: token
                })

            }

        } else if (table.filters.page) {

            getListDataTable({
                url: `/medical/admissions/sites/`,
                objFilters: { eaccount: eaccount, page: table.filters.page, perpage: table.filters.perPage },
                token: token
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.trigger, table.filters.page])

    // useEffect(() => {

    //     if (dataSchema?.profileId) {
    //         getListAccountsProfiles({
    //             url: `/medical/generals/accountsProfiles/`,
    //             objFilters: { eaccount: eaccount, profileId: dataSchema.profileId },
    //             token: token
    //         })
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dataSchema?.profileId])

    useEffect(() => {
        if (loadListSites === false) {

            const filter = listSites?.results?.map
                (el => ({ value: el.siteId, label: el.site }))

            setData(state => (
                {
                    ...state,
                    selects: { ...state.selects, listSites: filter }
                }
            ))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listSites])

    useEffect(() => {
        if (loadListAccountsProfiles === false) {

            const filter = listAccountsProfiles?.results?.map
                (el => ({ value: el.userId, label: el.userFullName }))

            setData(state => (
                {
                    ...state,
                    selects: { ...state.selects, listAccountsProfiles: filter }
                }
            ))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listAccountsProfiles])

    useEffect(() => {
        if (loadListProfiles === false) {

            const filter = listProfiles?.results?.map
                (el => ({ value: el.profileId, label: el.profileName }))

            setData(state => (
                {
                    ...state,
                    selects: { ...state.selects, listProfiles: filter }
                }
            ))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listProfiles])

    useEffect(() => {
        if (loadListDataTable === false) {
            if (!listDataTable?.results?.length) {

                setData(state => (
                    {
                        ...state,
                        table: { ...state.table, listDataTable: [] }
                    }
                ))
            } else {

                const filter = listDataTable?.results
                setData(state => (
                    {
                        ...state,
                        table: { ...state.table, listDataTable: filter }
                    }
                ))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listDataTable])

    useEffect(() => {

        getListProfiles({
            url: `/medical/generals/profiles/`,
            token: token
        })

        getListSites({
            url: `/medical/generals/site/`,
            objFilters: { eaccount: eaccount, modality: 'on_site', status: 'enabled' },
            token: token
        })

        getListDataTable({
            url: `/medical/admissions/sites/`,
            objFilters: { eaccount: eaccount, profileId: dataSchema.profileId, page: table.filters.page, perpage: table.filters.perPage },
            token: token
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //-------------- Component --------------------------

    return (
        <>
            {(loadListAccountsProfiles || loadListProfiles || loadListSites || loaderCreateNewSite || loadListDataTable || updateSiteLoader) && loader}

            <OrdModal
                title={modal.isEditing ? "Editar asignación" : "Nueva asignación"}
                show={modal.show}
                btnYesName={modal.isEditing ? "Guardar" : "Guardar"}
                btnNoName={"Cancelar"}
                size={550}
                onHide={() => closeModal()}
                btnYesEvent={() => validationRequest()}
                btnNoEvent={() => closeModal()}
                sizeBody={`${tableStyles.modalHeight}`}
            >
                <div>
                    <Row>
                        <Form.Group className="mb-3" controlId="fName">

                            <div className="mb-2">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">
                                        &nbsp;Cargo
                                        <span className={`${tableStyles.ordOrangeText}`}>*</span>
                                    </b>
                                </Form.Label>

                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={selects.listProfiles}
                                    defaultValue={modal.isEditing
                                        ? { label: dataSchema.profileName, value: dataSchema.profileId }
                                        : []}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    isDisabled={modal.isEditing}

                                    onChange={(e) => {
                                        setData(state => ({
                                            ...state,
                                            dataSchema: { ...state.dataSchema, profileId: e.value }
                                        }))

                                        getListAccountsProfiles({
                                            url: `/medical/generals/accountsProfiles/`,
                                            objFilters: { eaccount: eaccount, profileId: e.value },
                                            token: token
                                        })
                                    }}
                                />
                            </div>

                            <div className="mb-2">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">
                                        &nbsp;Usuario
                                        <span className={`${tableStyles.ordOrangeText}`}>*</span>
                                    </b>
                                </Form.Label>

                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={selects.listAccountsProfiles}
                                    defaultValue={modal.isEditing
                                        ? { label: dataSchema.userFullName, value: dataSchema.userId }
                                        : []}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    // isDisabled={!data?.position }
                                    isDisabled={modal.isEditing ? true : dataSchema.profileId === null}
                                    onChange={(e) => {
                                        setData(state => ({
                                            ...state,
                                            dataSchema: { ...state.dataSchema, userId: e.value }
                                        }))
                                    }}
                                />
                            </div>


                            <div className="mb-2">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">
                                        &nbsp;Sede
                                        <span className={`${tableStyles.ordOrangeText}`}>*</span>
                                    </b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    placeholder={"Seleccionar..."}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={selects.listSites}
                                    defaultValue={modal.isEditing
                                        ? dataSchema.sites?.map((state) => ({ label: state.siteName, value: state.siteId }))
                                        : []}
                                    onChange={(e) => {
                                        setData(state => ({
                                            ...state,
                                            dataSchema: { ...state.dataSchema, sites: e }
                                        }))
                                    }}

                                />

                            </div>


                        </Form.Group>
                    </Row>

                </div>
            </OrdModal>

            <OrdGenericTemplate
                className={`${tableStyles.W900} mt-4 mx-5 px-0`}
                showBottomBorder={false}
                title={"Asignación de Sedes"}
                titleSize={12}
                buttonNewLabel={"Nueva asignación"}
                buttonNewAction={() => {
                    // openNewCombo();
                }}

                searchWithNew={{
                    searchIcon: true,
                    onClickSearchIcon: () => {
                        setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));
                    },
                    searchWith: '70',
                    buttonNewLabel: "Nueva asignación",
                    onChange: (e) => {

                        setData(state => ({ ...state, table: { ...state.table, filters: { ...state.table.filters, search: e.target.value } } }));;

                    },
                    buttonNewAction: () => postModal(),
                    onSubmit: (e) => {
                        e.preventDefault();
                        setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));
                    },
                }}
            >

                <div style={{ width: '900px' }}>

                    <OrdTableAccordion

                        headers={table.headersTable}
                        headersGridColumn={'2fr 1fr 80px 40px'}
                        body={table?.formatDataTable}
                        className={`${tableStyles.ordTableShadow}`}
                        accordionBody={accordionBody}
                        paginate={{
                            activePage: table?.filters?.page,
                            totalPages: listDataTable?.rowTotal || 0,
                            perPage: table?.filters?.perPage,
                            pageRangeDisplayed: table?.filters?.pageRangeDisplayed,
                            onChangePage: async (e) => {
                                setData(state => ({ ...state, table: { ...state.table, filters: { ...state.table.filters, page: e } } }))
                            },
                            showTextDetails: true
                        }}
                    />

                </div>

            </OrdGenericTemplate>
        </>
    )
}

export default AssignmentLocations