

import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { 
    GET_CALENDAR_MEDICAL_EXAMS,
    SELECT_CALENDAR_MEDICAL_EXAMS,
} from "../actions/actionTypes";

const MySwal = withReactContent(Swal);

export const getCalendarMonthFull = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/medical_exam/calendar/?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            const { fullmonth } = res;
            dispatch({
                type: GET_CALENDAR_MEDICAL_EXAMS,
                payload: fullmonth,
            });
        })
        .catch((err) => {
            console.error(err.message);
        });
};
