import React, { useEffect, useState } from 'react'
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate'
import OrdModal from '../OrderingModule/OrderingComponents/OrdModal'
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable'
import CustomPopupExtend from '../components/Popup/customPopUpExtends';
import CustomPopupStyles from "../components/Popup/customPopup.module.scss";
import threeDots from "../assets/img/icons/threeDots.svg";
import { useGetMethod, usePostMethod } from '../Hooks/useFetch';
import { useSelector } from 'react-redux';
import { loader, message } from "../helpers/helpers"
import { Col, Form, Row } from 'react-bootstrap';
import tableStyles from "../components/Layouts/tableStyle.module.scss";

export const CancellationOrderReason = () => {
    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const idCurrentAccount = store.loginReducer.currentAccount.id;
    const idAccount = store.loginReducer.user_data.id;
    const [newReason, setNewReason] = useState({
        showModal: false,
        isEditing: false
    })
    const [filters, setFilters] = useState({
        search: ""
    })
    const [trigger, setTrigger] = useState(0)
    const [data, setData] = useState({})
    const { load: editLoader, trigger: EditCancellationReason } = usePostMethod();
    const { load: createLoader, trigger: CreateCancellationReason } = usePostMethod();
    const headers = [
        {
            title: "Descripción",
            className: "text-start px-2",
        },
        {
            title: "Creado por",
            className: "text-start px-2",
        },
        {
            title: "Estado",
            className: "text-center px-2",
        },
        {
            title: <>&nbsp;</>,
        },
    ];
    const { results: cancellationOrder, load: cancellationOrderLoader, trigger: getCancellationOrderReason } = useGetMethod()
    useEffect(() => {

        getCancellationOrderReason({
            url: "/medical/CancellationOrderReason/",
            objFilters: { idAccount: idCurrentAccount, search: filters?.search},
            token: token,
        });
    }, [trigger]);

    const editReason = (isChangeStatus = false, item = null) => {
        let body = {}
        if(item){
            body = {id: item?.id, idAccount: idCurrentAccount, description: item?.description}
        }else{
            body = {id: data?.id, idAccount: idCurrentAccount, description: data?.description }
        }
        if (isChangeStatus) {
            if (item?.status?.value === 'enabled') {
                body.status = 'disabled'
            } else {
                body.status = 'enabled'
            }
        }
        EditCancellationReason({
            url: "/medical/CancellationOrderReason/",
            token: token,
            method: "PUT",
            body: body,
            doAfterSuccess: () => {
                setNewReason({ ...newReason, showModal: false, isEditing: false })
                setTrigger(trigger + 1)
                setData()
            },
            succesAction: () =>
                message(
                    "success",
                    `<span class=${tableStyles.ordClearBlueText}>Motivo actualizado </span>`
                ),
        });
    }; 
    const createReason = () => {
        if (data?.description === "" || !data?.description  ) { return message("info", `<span class=${tableStyles.ordClearBlueText}>Campos vacíos</span>`,"La descripción es obligatoria" ) }
        let body = {
            description: data.description,
            idAccount: idCurrentAccount,
            createdBy: idAccount,
        }
        CreateCancellationReason({
            url: "/medical/CancellationOrderReason/",
            token: token,
            method: "POST",
            body: body,
            doAfterSuccess: () => {
                setNewReason({ ...newReason, showModal: false, isEditing: false })
                setTrigger(trigger + 1)
                setData()
            },
            succesAction: () =>
                message(
                    "success",
                    `<span class=${tableStyles.ordClearBlueText}>Motivo creado exitosamente</span>`
                ),
        });
    };
    const formatData = (array) => {
        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                tempList.push(
                    <tr key={index} className={`hover-table-row`}>
                        <td className="text-start px-2 col-6">{item?.description}</td>
                        <td className="text-start px-2 col-3" >{item?.user?.name}</td>
                        <td className="text-center px-2 col-2">
                            <Col xs={12} style={{ color: item?.status?.fontColor, backgroundColor: item?.status?.backgroundColor, padding: "3px", borderRadius: "10px" }}>
                                {item?.status?.description}
                            </Col>
                        </td>
                        <td className="text-start px-2 col-1">
                            <CustomPopupExtend
                                className="align-self-end"
                                triggerSrc={threeDots}
                                extraButtons={[
                                    {
                                        text: "Editar",
                                        class: CustomPopupStyles.popUpGeneric,
                                        event: () => {
                                            setData(item)
                                            setNewReason({ ...newReason, showModal: true, isEditing: true });
                                        },
                                    },
                                    {
                                        text: item?.status?.value === "enabled" ? "Deshabilitar" : "Habilitar",
                                        class: CustomPopupStyles.popUpGeneric,
                                        event: () => {
                                            editReason(true, item)
                                        },
                                    }

                                ]}
                            ></CustomPopupExtend>
                        </td>
                    </tr>
                );
            });
        }
        return tempList;
    };
    return (
        <>
            {cancellationOrderLoader && loader}
            {editLoader && loader}
            {createLoader && loader}
            <OrdGenericTemplate
                className="w-90"
                title={"Motivos de cancelación de ordenes de medicamentos"}
                titleSize={12}
                buttonNewLabel={"Nuevo Motivo"}
                buttonNewAction={() => {
                    setNewReason({ ...newReason, showModal: true });
                }}
                searchWithNew={{
                    colSize: "12",
                    WSize: "100",
                    buttonNewLabel: "Nuevo motivo",
                    onChange: (e) => {
                        setFilters({ ...filters, search: e.target.value });
                    },
                    buttonNewAction: () => setNewReason({ ...newReason, showModal: true }),
                    onSubmit: (e) => {
                        e.preventDefault();
                        setTrigger(trigger + 1);
                    },
                }}
            >
                <OrdTable
                    headers={headers}
                    hasChildren={true}
                    shadow={true}
                    oneHeadDarkBlue={false}
                    lowerCase={false}
                >
                    {formatData(cancellationOrder?.results)}
                </OrdTable>
                <OrdModal
                    title={newReason?.isEditing ? "Editar Motivo" : "Nuevo Motivo"}
                    show={newReason.showModal}
                    btnYesName="Aceptar"
                    btnNoName="Cancelar"
                    size="500"
                    onHide={() => { setNewReason({ ...newReason, showModal: false, isEditing: false }); setData() }}
                    btnYesEvent={() => newReason.isEditing ? editReason(false) : createReason()}
                    btnNoEvent={() => { setNewReason({ ...newReason, showModal: false, isEditing: false }); setData() }}
                >
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-3 text-start" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} `}>
                                    <span>&nbsp;<b>Descripción</b></span>
                                </Form.Label>
                                <textarea
                                    className={`form-control ${tableStyles.f16}`}
                                    id="descriptionReason"
                                    rows="2"
                                    value={data?.description ? data.description : null}
                                    style={{ color: "#6e6f7c", minHeight: "130px", maxheight: "130px", resize: "none" }}
                                    type="text"
                                    placeholder="Escribir..."
                                    onChange={(e) => setData({ ...data, description: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </OrdModal>

            </OrdGenericTemplate>
        </>
    )
}
