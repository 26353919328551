import { GET_CORPORATECLIENTS, GET_CIIU } from "./actionTypes";

import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { responseSwal } from "../helpers/responseSwal";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);

export const getCorporateClients = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_CORPORATECLIENTS,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/coporateClients/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_total } = res;
      dispatch({
        type: GET_CORPORATECLIENTS,
        payload: results,
        total: row_total,
        loading: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CORPORATECLIENTS,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const createCorportateClient =
  (obj, filters, doAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    if (
      obj.copyName !== "" &&
      // obj.companyType !== "" &&
      obj.Nit !== "" &&
      obj.id_ciiu !== "" &&
      obj.id_country !== "" &&
      obj.id_province !== "" &&
      obj.id_city !== "" &&
      obj.address !== "" &&
      obj.phone !== "" &&
      obj.email !== ""
    ) {
      fetch(`${URL_GATEWAY}${API_VERSION}/coporateClients/`, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success || data.code === 401) {
            return customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: data.message,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
          }

          customSwaltAlert({
            icon: "success",
            title: "Cliente-Empresa creado exitosamente",
            text: `Se ha creado cliente-empresa: ${obj.copyName}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

          
          if (!!doAfter) doAfter();
          dispatch(getCorporateClients(filters));
        })
        .catch((err) => {

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

        });
    } else {

      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Debe ingresar los datos obligatorios para crear el Cliente-Empresa.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })

    }
  };

  export const getCIIU = () => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/admin/ciiu`, {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: GET_CIIU,
          payload: res.results,
        });
      })
      .catch((err) => {
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "Ocurrió un error al traer la información de la actividad económica",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        });
      });
  };

export const updateCorporateClient = (obj, filters, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: GET_CORPORATECLIENTS,
    loading: true,
  });
  if (
    obj.copyName !== "" &&
    obj.companyType !== "" &&
    obj.Nit !== "" &&
    obj.id_ciiu !== "" &&
    obj.id_country !== "" &&
    obj.id_province !== "" &&
    obj.id_city !== "" &&
    obj.address !== "" &&
    obj.phone !== "" &&
    obj.email !== ""
  ) {
    fetch(`${URL_GATEWAY}${API_VERSION}/coporateClients/`, {
      body: JSON.stringify(obj),
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: GET_CORPORATECLIENTS,
          loading: false,
        });
        if (!data.success || data.code === 401) {
          return customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: data.message,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        }

        customSwaltAlert({
          icon: "success",
          title: "Cliente - Empresa actualizado",
          text: `Cliente - Empresa actualizado correctamente`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

        doAfter()

        dispatch(getCorporateClients(filters));
      })
      .catch((err) => {
        dispatch({
          type: GET_CORPORATECLIENTS,
          loading: false,
        });
        customSwaltAlert({
          icon: "error",
          title: "Error al conectar",
          text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

      });
  } else {

    customSwaltAlert({
      icon: "error",
      title: "Error al ingresar",
      text: "Debe ingresar los datos obligatorios para actualizar el registro.",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })

  }
};

export const activationDesactivation = (id, status, filters, description) => (
  dispatch,
  getState
) => {
  const token = getState().loginReducer.Authorization;
  
  let active = status ? "deshabilitar" : "habilitar";
  if (status === true) {
    status = 0;
  } else if (status === false) {
    status = 1;
  }

 let data = {
     id: id,
     enabled: status,
     copyName: description,
 };
  if (id != null && status != null) { 
    

    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se ${!status ? 'deshabilitará' : 'habilitara'} el cliente empresa: ${description}`,
      icon: "warning",
      showCancelButton: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        
        dispatch({
          type: GET_CORPORATECLIENTS,
          loading: true,
        });

        fetch(`${URL_GATEWAY}${API_VERSION}/coporateClients/`,{
            body: JSON.stringify(data),
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
          .then((response) => response.json())
          .then((res) => {

            dispatch({
              type: GET_CORPORATECLIENTS,
              loading: false,
            });

            if (res.success || !res.message) {

              customSwaltAlert({
                icon: "success",
                title: "Actualizado exitosamente",
                text: `Se acaba de ${active} el cliente empresa: ${description}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })

              dispatch(getCorporateClients(filters));
            } else {
            }
          })
          .catch((err) => {

            dispatch({
              type: GET_CORPORATECLIENTS,
              loading: false,
            });

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: "No se pudo cambiar el estado" + err,
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })

          });
      }
    });
  } else {

    customSwaltAlert({
      icon: "warning",
      title: "Intenta de nuevo",
      text: "Debe ingresar correo y contraseña para acceder al sistema.",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  }
};