// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";


// --------------- icons 💥  ----------------//
import Imprimir from "../../assets/img/icons/imprimir.svg";

//-------------- scss styles 😄   --------------
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Styles from "./Reports.module.scss"
// ------------------ Functions ----------------------
import { majorBalanceReport } from "../../actions/reportsActions";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import { message } from "../../helpers/helpers";
import { convertFilterToString } from "../../helpers/convertToFilter";
import { API_VERSION, URL_GATEWAY } from "../../helpers/constants";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import { Title } from "../../shared";

// ------------------ Functions ----------------------

function MajorYBalance(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const storage = useSelector(state => state);
    const token = storage.loginReducer.Authorization;
    const [trigger, setTrigger] = useState(1);
    const [showSection, setShowSection] = useState(false);
    const [showPdf, setShowPdf] = useState(false)
    const [base64, setBase64] = useState("")

    // const getPdf = async () => {
    //     const result = await getPdfTest(
    //         "",
    //         storage.loginReducer.Authorization,
    //         "treasury/consultation_of_bank_notes"
    //     )
    //     if (result?.success) {
    //         return setBase64(result?.result?.base64)
    //     } else {
    //         setShowPdf(false)
    //         message('error', 'Ha ocurrido un error', result?.message)
    //     }
    // }
    // useEffect(() => {
    //     if(showPdf){getPdf()}
    // }, [showPdf])

    const [info, setInfo] = useState({
        reportType: "HighAndBalance",
        dateFrom: "",
        dateUntil: "",
        eaccount: storage.loginReducer.currentAccount.id,
        page: 1,
        perpage: 10,
        excludeVoucher: false,
        dateReport: false,
        pagination: false,
        sendInfo: false,
        groupAccounts: false,
        level: ""
    });

    const myPermission = () => storage.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "major&bala");

    //--------------first functions 🍕   -----------

    useEffect(() => {
        if (!myPermission()?.read) {
            history?.push("/contabilidad/inicio");
        }
    }, []);

    useEffect(() => {
        if (!!info.dateFrom != "" && !!info.dateUntil != "") {
            dispatch(majorBalanceReport(info, () => null));
        }

    }, [info.page]);

    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }



    //-------------- select options 🍕   --------------

    let optionsLevel = [
        { key: "level0", value: "", label: "Seleccionar..." },
        { key: "level1", value: 1, label: "1 - Clase" },
        { key: "level2", value: 2, label: "2 - Grupo" },
        { key: "level3", value: 3, label: "3 - Cuenta" },
        { key: "level4", value: 4, label: "4 - Subcuenta" },
        { key: "level5", value: 5, label: "5 - Auxiliar" },
    ];


    //--------------  Actions  🍕   --------------

    const generateReport = () => {
        dispatch(majorBalanceReport(info, () => { setInfo({ ...info, sendInfo: true }) }));
        setShowSection(true)
    }

    const getPdf = async () => {
        setBase64("");
        // const result = await getPdfTest(
        //   "",
        //   storage.loginReducer.Authorization,
        //   "treasury/consultation_of_bank_notes"
        // )
        // if (result?.success) {
        //   return setBase64(result?.result?.base64)
        // } else {
        //   setShowPdf(false)
        //   message('error', 'Ha ocurrido un error', result?.message)
        // }
        const treatedInfo = { ...info };
        delete treatedInfo.page;
        delete treatedInfo.perpage;
        const filters = convertFilterToString(treatedInfo);
        await fetch(`${URL_GATEWAY}${API_VERSION}/composite/documentPdf_generalDaily/?${filters}`,
            {
                method: "PUT",
                headers: {
                    accept: "application/json",
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "pdf_body": "majorAndBalance",
                    "pdf_row": "majorAndBalanceRow",
                }),
            }
        )
            .then((response) => response.json())
            .then(res => {
                if (res.success) {
                    let treatPdf = res.key.replace(/^b'/gm, "").replace(/=*'$/gm, "");
                    setBase64(treatPdf);
                } else {
                    setShowPdf(false);

                    customSwaltAlert({
                        icon: "warning",
                        title: "Intenta de nuevo",
                        text: `Ha ocurrido un error, no ha sido posible cargar el documento`,
                        confirmButtonText: 'Aceptar',
                        showCancelButton: false
                    });
                }
            }).catch(err => {
                console.error(err);
            })

    }

    useEffect(() => {
        if (showPdf) { getPdf() }
    }, [showPdf])

    //--------------  Array  🍕   --------------

    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                <div className={tableStyles.title}>
                    <Title
                        title="Informe mayor y balance"
                        onClickIcon={() => {
                            history.push('/contabilidad/inicio')
                        }}
                    />
                </div>
                <div className="d-flex">
                    {/*----------------------------FIRST SECTION---------------------------------- */}
                    <div
                        className={`${tableStyles.container}`}
                        style={{
                            width: "35%",
                            paddingLeft: "0px",
                            borderRight: "1px solid #CECECE",
                            marginRight: "1px",
                        }}
                    >
                        <Row className="d-flex">
                            <Col xs={6}>
                                <p className={tableStyles.crudModalLabel}>Fecha desde<span className={"text-warning"}>*</span></p>
                                <input
                                    className={IndividualStyles.registerInputsBlue}
                                    type="date"
                                    value={info.dateFrom}
                                    onChange={(e) => setInfo({
                                        ...info,
                                        dateFrom: e.target.value
                                    })}
                                />
                            </Col>
                            <Col xs={6}>
                                <p className={tableStyles.crudModalLabel}>Hasta<span className={"text-warning"}>*</span></p>
                                <input
                                    className={IndividualStyles.registerInputsBlue}
                                    type="date"
                                    value={info.dateUntil}
                                    onChange={(e) => setInfo({
                                        ...info,
                                        dateUntil: e.target.value
                                    })}
                                />
                            </Col>
                        </Row>

                        <Row
                            className="d-flex"
                            style={{ paddingTop: "24px", paddingBottom: "16px" }}
                        >
                            <p
                                className={tableStyles.crudModalLabel}
                                style={{ fontWeight: "700", fontSize: "16px" }}
                            >
                                Opciones
                            </p>
                        </Row>

                        <Row className="d-flex">
                            <Col xs={1} style={{ paddingLeft: "18px" }}>
                                <input
                                    className="border border-dark-blue form-check-input p1 check-dark-blue"
                                    type="checkbox"
                                    name="edit"
                                    value="selectedAll"
                                    checked={info.dateReport}
                                    onChange={e => { setInfo({ ...info, dateReport: e.target.checked }) }}
                                />
                            </Col>
                            <Col xs={11} style={{ paddingLeft: "1px" }}>
                                <p
                                    className={tableStyles.crudModalLabel}
                                    style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                                >
                                    No imprimir fecha en informe
                                </p>
                            </Col>
                        </Row>

                        <Row className="d-flex">
                            <Col xs={1} style={{ paddingLeft: "18px" }}>
                                <input
                                    className="border border-dark-blue form-check-input p1 check-dark-blue"
                                    type="checkbox"
                                    name="edit"
                                    value="selectedAll"
                                    checked={info.excludeVoucher}
                                    onChange={e => setInfo({ ...info, excludeVoucher: e.target.checked })}
                                />
                            </Col>
                            <Col xs={11} style={{ paddingLeft: "1px" }}>
                                <p
                                    className={tableStyles.crudModalLabel}
                                    style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                                >
                                    Excluir los comprobantes de cierre
                                </p>
                            </Col>
                        </Row>

                        <Row className="d-flex">
                            <Col xs={1} style={{ paddingLeft: "18px" }}>
                                <input
                                    className="border border-dark-blue form-check-input p1 check-dark-blue"
                                    type="checkbox"
                                    name="edit"
                                    value="selectedAll"
                                    checked={info.pagination}
                                    onChange={e => setInfo({ ...info, pagination: e.target.checked })}
                                />
                            </Col>
                            <Col xs={11} style={{ paddingLeft: "1px" }}>
                                <p
                                    className={tableStyles.crudModalLabel}
                                    style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                                >
                                    Imprimir en páginas numeradas
                                </p>
                            </Col>
                        </Row>

                        <Row className="d-flex">
                            <Col xs={1} style={{ paddingLeft: "18px" }}>
                                <input
                                    className="border border-dark-blue form-check-input p1 check-dark-blue"
                                    type="checkbox"
                                    name="edit"
                                    value="selectedAll"
                                    checked={info.groupAccounts}
                                    onChange={e => setInfo({ ...info, groupAccounts: e.target.checked })}
                                />
                            </Col>
                            <Col xs={11} style={{ paddingLeft: "1px" }}>
                                <p
                                    className={tableStyles.crudModalLabel}
                                    style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                                >
                                    Agrupar cuentas
                                </p>
                            </Col>
                        </Row>

                        {info.groupAccounts ?
                            <Row className="d-flex" style={{ marginTop: "8px", paddinLeft: "34px" }}>
                                <Col xs={6}>
                                    <p className={tableStyles.crudModalLabel} style={{ paddinLeft: "6px" }}>Organizar por<span className={"text-warning"}>*</span></p>
                                    <Select
                                        noOptionsMessage={() => 'No hay datos'}
                                        key={"typeVoucher" + trigger}
                                        options={optionsLevel}
                                        placeholder="Seleccionar..."
                                        styles={customSelectNewDark}
                                        onChange={(e) =>
                                            setInfo({
                                                ...info,
                                                level: e.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            :
                            <span></span>
                        }

                        <Row
                            className="d-flex"
                            style={{ marginTop: "40px", marginButton: "5px" }}
                        >
                            <Col xs={3} style={{ marginRight: "5%" }}>
                                <Button
                                    className={`${IndividualStyles.btnPrimary}`}
                                    onClick={() => {
                                        setShowSection(false);
                                        setInfo({
                                            reportType: "HighAndBalance",
                                            dateFrom: "",
                                            dateUntil: "",
                                            eaccount: storage.loginReducer.currentAccount.id,
                                            page: 1,
                                            perpage: 10,
                                            excludeVoucher: false,
                                            dateReport: false,
                                            pagination: false,
                                            sendInfo: false,
                                            groupAccounts: false,
                                            level: ""
                                        });
                                        setTrigger(trigger + 1)
                                    }
                                    }
                                    disabled={false}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col xs={7}>
                                <Button
                                    className={IndividualStyles.btnPrimaryDark}
                                    onClick={() => generateReport()}
                                    disabled={!!info.dateFrom == "" || !!info.dateUntil == "" || !!info.groupAccounts ? !!(info.level == "") ? true : false : false}
                                >
                                    Generar informe
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    {/*----------------------------END FIRST SECTION---------------------------------- */}

                    {/*----------------------------SECOND SECTION---------------------------------- */}
                    {showSection ?
                        <div style={{ width: "65%", display: "flex", flexDirection: "column", paddingLeft: "40px" }}>

                            {storage.reportsReducer.loadingMB &&
                                <div className="loading">
                                    <Loader type="Oval" color="#003f80" height={100} width={100} />
                                </div>
                            }

                            <div className=" display-grid col-12" style={{ textAlignLast: "end" }}>
                                <div className="col" style={{ textAlignLast: "end" }}>
                                    <img
                                        alt=""
                                        className={`${Styles.btn}`}
                                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                                        src={Imprimir}
                                        onClick={() => setShowPdf(true)}
                                    />
                                    {/* <img className={` ${Styles.btn}`} src={Excel} /> */}
                                </div>
                            </div>

                            <div style={{ marginTop: "9px", backgroundColor: "#FFFF", padding: "21px", height: "100%", width: "100%", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)" }}>
                                <Row>
                                    <Col xs={3}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                                        >
                                            MAYOR Y BALANCE

                                        </label>
                                    </Col>
                                    <Col xs={4}></Col>
                                    <Col xs={5} style={{ textAlign: "end" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#CECECE" }}
                                        >
                                            Fundación Oftamológica del Caribe

                                        </label>

                                    </Col>

                                </Row>
                                {storage.reportsReducer.dateReport3 ?

                                    <span></span>
                                    :
                                    <Row>
                                        <Col xs={3} >
                                            <label
                                                style={{ fontWeight: "400", fontSize: "11px", color: "#58595B" }}
                                            >
                                                Del día {info.dateFrom?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')} al día {info.dateUntil?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
                                            </label>
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col xs={3}>
                                        <label
                                            style={{ fontWeight: "400", fontSize: "11px", color: "#58595B" }}
                                        >
                                            LOCAL
                                        </label>
                                    </Col>
                                </Row>

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px" }}>
                                    <Col xs={1}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                                        >
                                            Cuenta
                                        </label>
                                    </Col>
                                    <Col xs={3}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                                        >
                                            Nombre
                                        </label>
                                    </Col>
                                    <Col xs={3} style={{ textAlign: "end" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                                        >
                                            Saldo un día antes de {info.dateFrom?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
                                        </label>
                                    </Col>
                                    <Col xs={1} style={{ textAlign: "center" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                                        >
                                            Débitos
                                        </label>
                                    </Col>
                                    <Col xs={1} style={{ textAlign: "center" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                                        >
                                            Créditos
                                        </label>
                                    </Col>
                                    <Col xs={3} style={{ textAlign: "end" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                                        >
                                            Saldo al {info.dateUntil?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
                                        </label>
                                    </Col>

                                </div>

                                {storage.reportsReducer.getMajorBalance?.map((x, realindex) => (

                                    <div className={tableStyles.rwoReport} key={"table3" + realindex}>
                                        <div className="col-1">
                                            <label
                                                style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                                            >
                                                {!!x.accountNbr ? x.accountNbr : "-"}
                                            </label>
                                        </div>
                                        <div className="col-3" >
                                            <label
                                                style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                                            >
                                                {!!x.name ? x.name : "-"}
                                            </label>
                                        </div>
                                        <div className="col-3" style={{ textAlign: "end" }}>
                                            <label
                                                style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                                            >
                                                {!!x.balance1 ? "$" + numberWithCommas(x.balance1) : "-"}
                                            </label>
                                        </div>
                                        <div className="col-1" style={{ textAlign: "center" }}>
                                            <label
                                                style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                                            >
                                                {!!x.debit ? "$" + numberWithCommas(x.debit) : "-"}
                                            </label>
                                        </div>
                                        <div className="col-1" style={{ textAlign: "center" }}>
                                            <label
                                                style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                                            >
                                                {!!x.credit ? "$" + numberWithCommas(x.credit) : "-"}
                                            </label>
                                        </div>
                                        <div className="col-3" style={{ textAlign: "end" }}>
                                            <label
                                                style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                                            >
                                                {!!x.balance2 ? "$" + numberWithCommas(x.balance2) : "-"}
                                            </label>
                                        </div>
                                    </div>
                                ))}

                                {storage.reportsReducer.pagination3 ?

                                    <Col xs={12} style={{ textAlign: "end" }}>

                                        <label
                                            style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                                        >
                                            {Math.ceil(storage.reportsReducer?.getMajorBalance_total / info.perpage) ? info.page + "/" + Math.ceil(storage.reportsReducer?.getMajorBalance_total / info.perpage) : ""}
                                        </label>


                                    </Col>
                                    :
                                    <span></span>
                                }


                            </div>
                            {/* ----- pagination #️⃣ ----- */}
                            <div className={IndividualStyles.bottom} style={{ marginTop: "14px" }}>
                                <div className={paginationStyles.wrapper}>
                                    <p className={paginationStyles.paginationText}>
                                        Pag.{" "}
                                        {storage.reportsReducer.getMajorBalance_total
                                            ? info.page
                                            : ""}
                                        {" de "}
                                        {Math.ceil(
                                            storage.reportsReducer?.getMajorBalance_total / info.perpage
                                        )
                                            ? Math.ceil(
                                                storage.reportsReducer.getMajorBalance_total /
                                                info.perpage
                                            )
                                            : ""}
                                    </p>
                                    <Pagination
                                        activePage={info.page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={Number(storage.reportsReducer.getMajorBalance_total)}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => setInfo({ ...info, page: e })}
                                        itemClassPrev={paginationStyles.itemClassPrev}
                                        itemClassNext={paginationStyles.itemClassNext}
                                        itemClassFirst={paginationStyles.itemClassFirst}
                                        itemClassLast={paginationStyles.itemClassLast}
                                        itemClass={paginationStyles.itemClass}
                                    />

                                </div>
                            </div>

                            {/*----------------------------END SECOND SECTION---------------------------------- */}

                        </div>
                        :
                        <span></span>
                    }

                </div>
            </div>
            <ModalNew
                title="Informe mayor y balance"
                show={showPdf}
                btnNoName={"Cancelar"}
                size="700"
                btnYesDisabled={false}
                onHide={() => setShowPdf(false)}
                btnNoEvent={() => setShowPdf(false)}
                btnNoDisabled={false}
            >
                <PdfViewer
                    downloadable
                    file={`data:application/pdf;base64,${base64}`}></PdfViewer>
            </ModalNew>
        </>
    )

};
export default MajorYBalance;