import React, { Component } from "react";
import Adding from "../../assets/img/icons/agregarEspecialidad.svg";
// import Export from "../../assets/img/icons/excel.svg";
import SearchIcon from "../../assets/img/icons/lupa.svg";
import PdfIcon from "../../assets/img/icons/pdfIcon.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import { connect } from "react-redux";
import GenericTable from "../Layouts/GenericTableNew";
import ModalNew from "../Layouts/ModalNew";
import "reactjs-popup/dist/index.css";
import Pagination from "react-js-pagination";
import question from "../../assets/img/icons/question.svg";
import {
  MAX_SIZE_FILE,
  PAGE,
  PERPAGE,
} from "../../helpers/constants";
import * as deepcopy from "deepcopy";
import style from "./tableArticles.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";
import Creatable from "react-select/creatable";
import Dropzone from "react-dropzone";
import { AiFillCloseCircle } from "react-icons/ai";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import {
  getActiveInventoryFamilies,
  getManufacturers,
  createArticle,
  getArticles,
  changeStatus,
  addDataSheet,
  deleteDataSheet,
  updateArticle,
  getOptionsIVA
} from "../../actions/articlesActions";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { Link } from "react-router-dom";
import {
  customSelectStyle,
  customSelectStyleSmall,
  customSelectNew,
  customSelectNewDark
} from "../Layouts/react-select-custom";
import threeDots from "../../assets/img/icons/threeDots.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NumberFormat from "react-number-format";
import CustomPopup from "../Popup/customPopUpExtends";
import { customSwaltAlert } from './../../helpers/customSwaltAlert';
const MySwal = withReactContent(Swal);

class TableArticles extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    setTimeout(async () => {
      await this.props.getArticles(this.initialState.filters);
      await this.props.getManufacturers();
      await this.props.getActiveInventoryFamilies();
      await this.props.getOptionsIVA(this.initialState.filtersIva);
      let { optionsManufacturers } = this.state;
      setTimeout(() => {
        if (this.props.listManufacturers !== undefined) {
          this.props.listManufacturers.map((item) => {
            if (item) {
              optionsManufacturers.push({
                value: item,
                label: String(item).replace(/^\w/, (c) => c.toUpperCase()),
              });
            }
          });
        }
        this.setState({
          optionsManufacturers,
        });
      }, 2000);
    }, 1000);
    this.setState(this.initialState);
  }

  componentDidUpdate(prevProps) {
    let { optionsManufacturers } = this.state;
    if (prevProps.success_upload !== this.props.success_upload) {
      if (this.props.success_upload) {
        if (this.state.modalDataSheet) {
          this.toggleModalDataSheet();
        }
      }
    }
    if (prevProps !== this.props) {
      if (Array.isArray(this.props.listManufacturers)) {
        optionsManufacturers = [];
        this.props.listManufacturers.map((item) => {
          if (item) {
            optionsManufacturers.push({
              value: item,
              label: String(item).replace(/^\w/, (c) => c.toUpperCase()),
            });
          }
        });
        if (
          Array.isArray(optionsManufacturers) &&
          Array(optionsManufacturers).length > 0
        ) {
          optionsManufacturers = optionsManufacturers.filter(
            (x, i, a) => a.indexOf(x) == i
          );
          this.setState({
            optionsManufacturers,
          });
        }
      }
    }
  }

  get initialState() {
    return {
      titleModal: "Crear Artículo",
      modal: false,
      modalDataSheet: false,
      editRecord: false,
      showDetails: false,
      unicUnit: false,
      addArticle: {
        id_account: this.props.currentAccount.id,
        description: null,
        barcode: null,
        property_: null,
        consignment: null,
        handle_batch: null,
        handle_serial: null,
        reference: null,
        cost_price: null,
        tradename: null,
        data_sheet: null,
        active: null,
        manufacturer: "",
        inventory_family: null,
        measurement_units: [],
        add_info: [],
        req_authorization: false,
        iva: null,
      },
      optionsManufacturers: [],
      manufacturerValue: "",
      inventoryFamilySelected: {},
      measurementUnitSelected: {},
      totalIndex: 0,
      filters: {
        search: "",
        perpage: PERPAGE,
        page: PAGE,
        id_account: this.props.currentAccount.id,
        eaccount: this.props.currentAccount.id,
      },
      filtersIva: {
        eaccount: this.props.currentAccount.id,
      },
      filtersTemp: {
        search: "",
        perpage: 10,
        page: 1,
        id_account: this.props.currentAccount.id,
      },
      errorInputs: false,
      fileValid: false,
      fileUpload: null,
      // headerState: ["Código", "Descripción", "Flia. de Inventario", "Est.", "ㅤ"],
      headerState: [
        // {
        //   tittle: "Código",
        //   className: "text-center px-2 ",
        // },
        {
          tittle: "Descripción",
          className: "text-start px-2 ",
        },
        {
          tittle: "Flia. de Inventario",
          className: "text-start ",
        },
        {
          tittle: "Estado",
          className: "text-center w100",
        },
        {
          tittle: "",
          className: "text-start ",
        },
      ],
    };
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getArticles(this.state.filters);
  }

  handleEditRecord = (puc) => {
    let { modal, addArticle, titleModal, manufacturerValue } = this.state;
    modal = !this.state.modal;
    addArticle = deepcopy(puc);
    manufacturerValue = addArticle.manufacturer;
    titleModal = "Editar artículo";
    this.setState({
      modal,
      addArticle,
      editRecord: true,
      showDetails: false,
      titleModal,
      manufacturerValue,
    });
  };
  handleShowDetails = (puc) => {
    let { modal, addArticle, titleModal, manufacturerValue } = this.state;
    modal = !this.state.modal;
    addArticle = deepcopy(puc);
    manufacturerValue = addArticle.manufacturer;
    titleModal = "Detalle de artículo";
    this.setState({
      modal,
      addArticle,
      showDetails: true,
      editRecord: false,
      titleModal,
      manufacturerValue,
    });
  };
  toggleStatus = (account) => {
    this.props.changeStatus(account, this.state.filters);
  };

  // Search puc when user was typing
  onKeyUpSearch = (e) => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
      this.props.getArticles(this.state.filters);
    } else {
      e.preventDefault();
    }
    this.setState({ filters });
  };

  //Method to search when click in magnify lens
  handleSearch = (e) => {
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters });
    this.props.getArticles(this.state.filters);
  };

  toggleModal = (e) => {
    let { modal } = this.state;
    modal = !modal;
    if (modal) {
      this.props.getActiveInventoryFamilies();
      this.props.getManufacturers();
    }
    if (this.state.editRecord || this.state.showDetails) {
      this.setState({ modal });
    }
    this.setState(this.initialState);
    this.setState({ modal });
  };

  toggleModalDataSheet = (e) => {
    let { modalDataSheet } = this.state;
    modalDataSheet = !modalDataSheet;
    this.setState({ modalDataSheet });
  };

  onChangeForm = (e) => {
    var { name, value } = e.target;
    let { addArticle, errorInputs, fileValid, fileUpload } = this.state;
    if (name === "property_") {
      addArticle.consignment = null;
    }
    if (name === "consignment") {
      addArticle.property_ = null;
    }
    if (name === "quantity" && this.state.unicUnit) {
      addArticle.quantity = 1;
    }
    if (e.target.type === "number") {
      if (isNaN(value)) {
        return MySwal.fire({
          icon: "error",
          title: "Este campo solo acepta números",
        });
      }
    }
    if (name === "data_sheet") {
      let file = e.target.files[0];
      if (file.size > MAX_SIZE_FILE) {
        fileValid = false;
        return MySwal.fire({
          icon: "error",
          title: "El archivo es demasiado pesado",
          footer: "El tamaño máximo permitido es de 5MB",
        });
      } else if (file.type !== "application/pdf") {
        fileValid = false;

        return MySwal.fire({
          icon: "error",
          title: "El formato del archivo debe ser PDF",
          footer: "El tamaño máximo permitido es de 5MB",
        });
      } else {
        fileValid = true;
        fileUpload = e.target.files;
        addArticle.data_sheet = e.target.files;
      }
      this.setState({ fileValid, addArticle, fileUpload });
    }
    if (!errorInputs && name !== "data_sheet") {
      addArticle[name] = value;
      this.setState({ addArticle });
    }
  };

  onKeyDownNumber = (e) => {
    let { errorInputs } = this.state;
    if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
      e.preventDefault();
      errorInputs = true;
      return MySwal.fire({
        icon: "error",
        title: "Este campo solo acepta números",
      });
    }
    errorInputs = false;
    this.setState({ errorInputs });
  };

  onChangeItemsIF = (e, item) => {
    var { name, value } = e.target;
    let { addArticle } = this.state;
    if (name.includes("additional_info")) {
      addArticle.add_info.forEach((element) => {
        if (element.id_add_info === item.id) {
          let i = addArticle.add_info.indexOf(element);
          addArticle.add_info.splice(i, 1);
        }
      });
      addArticle.add_info.push({ id_add_info: item.id, article_info: value });
    }
    if (name.includes("measurment_unit")) {
      addArticle.measurement_units.forEach((element) => {
        if (element.id_mu === item.id) {
          let i = addArticle.measurement_units.indexOf(element);
          addArticle.measurement_units.splice(i, 1);
        }
      });
      addArticle.measurement_units.push({ id_mu: item.id, quantity: value });
    }
    this.setState({ addArticle });
  };

  onChangeCheckBox = (e) => {
    var { name } = e.target;
    let { addArticle } = this.state;
    addArticle[name] = !addArticle[name];
    this.setState({ addArticle });
  };

  onChangeAccountNumber = (value) => {
    let { addArticle } = this.state;
    if (this.state.editRecord) {
      addArticle.newCode = value;
    } else {
      addArticle.code = value;
    }
    this.setState({ addArticle });
  };

  onchangeSelectFamily = (newValue) => {
    let { addArticle, inventoryFamilySelected } = this.state;
    if (newValue) {
      addArticle.inventory_family = newValue.value;
    } else {
      addArticle.inventory_family = null;
    }
    this.props.listInventoryFamilies.forEach((element) => {
      if (element.id === newValue.value) {
        inventoryFamilySelected = element;
      }
    });
    this.setState({ addArticle, inventoryFamilySelected });
  };

  onchangeSelectIva = (newValue) => {
    let { addArticle } = this.state;
    if (newValue) {
      addArticle.iva = Number(newValue.value);
    } else {
      addArticle.iva = null;
    }
    this.setState({ addArticle });
  };

  //ANCHOR Onchange for select in measurement units 🙆‍♂️
  onchangeSelectMeasurement = (newValue) => {
    let { addArticle, measurementUnitSelected } = this.state;
    if (newValue) {
      addArticle.measurement_units = newValue.value;
      measurementUnitSelected = newValue;
    } else {
      addArticle.measurement_units = null;
    }
    // this.props.listInventoryFamilies.forEach((element) => {
    //   if (element.id === newValue.value) {
    //     measurementUnitSelected = element;
    //   }
    // });
    this.setState({ addArticle, measurementUnitSelected });
  };

  onFocusInventoryFamily = (event) => {
    if (this.state.editRecord) event.target.blur();
  };
  validateFile = (acceptedFiles) => {
    let { fileValid, fileUpload, addArticle } = this.state;
    fileUpload = acceptedFiles;
    let file = acceptedFiles[0];
    if (file.size > MAX_SIZE_FILE) {
      fileValid = false;
      MySwal.fire({
        icon: "error",
        title: "El archivo es demasiado pesado",
        footer: "El tamaño máximo permitido es de 5MB",
      });
    } else if (file.type !== "application/pdf") {
      fileValid = false;
      MySwal.fire({
        icon: "error",
        title: "El formato del archivo debe ser PDF",
        footer: "El tamaño máximo permitido es de 5MB",
      });
    } else {
      fileValid = true;
    }
    if (fileValid) {
      addArticle.data_sheet = acceptedFiles;
    }
    this.setState({ fileValid, fileUpload, addArticle });
  };

  async handleSubmit() {
    if (this.state.editRecord) {
      return this.props.updateArticle(
        this.state.addArticle,
        this.state.filtersTemp
        // () =>getArticles(this.state.filters)
      );
    }
    if (
      !this.state.addArticle.description ||
      !this.state.addArticle.inventory_family
    ) {
      this.setState({
        ...this.initialState,
        modal: true,
      });
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Debe llenar los campos obligatorios",
        showCancelButton:false
      });
      return;
    }
    if (
      this.state.addArticle.add_info.length !==
      this.state.inventoryFamilySelected.additional_info.length
    ) {
      this.setState({
        modal: true,
      });
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text:"La relación entre información adicional ingresada y la familia de inventario debe ser igual",
        showCancelButton:false
      });
      return;
    }
    // if (
    //   this.state.addArticle.measurement_units.length !==
    //   this.state.inventoryFamilySelected.measurements_list.length
    // ) {
    //   return MySwal.fire({
    //     icon: "error",
    //     title:"Error",
    //     text:
    //       "La relación entre las unidades de medida ingresada y la familia de inventario debe ser igual",
    //   });
    // }

    const result = await this.props.createArticle(this.state.addArticle, this.state.filters);
    if (result !== "failed") {
      this.setState(this.initialState);
    }
  }

  handleAddDataSheet = (article) => {
    let { addArticle } = this.state;
    addArticle = deepcopy(article);
    this.toggleModalDataSheet();
    this.setState({ addArticle });
  };

  submitDataSheet = (article) => {
    if (
      this.state.addArticle.data_sheet === null ||
      this.state.addArticle.data_sheet === ""
    ) {
      return MySwal.fire({
        icon: "error",
        title: "No seleccionó ningún archivo",
      });
    } else {
      if (this.state.modalDataSheet) {
        this.toggleModalDataSheet();
      }
    }
    this.props.addDataSheet(article, this.state.filters);
  };

  handleChangeCreatable = (newValue, actionMeta) => {
    let { addArticle, manufacturerValue } = this.state;
    if (newValue) {
      addArticle.manufacturer = newValue.value;
      manufacturerValue = newValue.value;
    } else {
      addArticle.manufacturer = "";
      manufacturerValue = "";
    }
    this.setState({ addArticle, manufacturerValue });
  };

  handleInputChange = (inputValue, actionMeta) => {
    let { manufacturerValue } = this.state;
    manufacturerValue = inputValue;
    this.setState({ manufacturerValue });
  };

  onInputChangeFamily = (inputValue, actionMeta) => {
    let { inventoryFamilyValue } = this.state;
    inventoryFamilyValue = inputValue;
    this.setState({ inventoryFamilyValue });
  };

  renderAccounts(account) {
    let iFamily = "";
    if (account.inventory_family[0]) {
      iFamily = account.inventory_family[0].description;
    }
    return (
      <tr
        key={`art-${account.id}${account.description}`}
        className="hover-table-row align-middle"
      >
        {/* <td className="col-md-2">{account.id}</td> */}
        <td className="col-md-6 text-start px-2">{account.description}</td>
        <td className="col-md-4 text-start">{iFamily}</td>
        <td className="">
          <div
            className={
              account.active ? tableStyles.greenState : tableStyles.redState
            }
          >
            {account.active ? 'Habilitado' : 'Inhabilitado'}
          </div>
        </td>
        <td
          className="position-relative mx-2"
          style={{ paddingLeft: "0.5rem" }}
        >
          <CustomPopup
            noHover
            triggerSrc={threeDots}
            showEdit={this.myPermission()?.edit}
            editClickEvent={() => this.handleEditRecord(account)}
            showEnable={this.myPermission()?.edit}
            showDetails={this.myPermission()?.read}
            textDetails="Ver Detalle"
            showDetailsEvent={() => this.handleShowDetails(account)}
            isEnabled={account.active}
            showAddDataSheet={this.myPermission()?.read}
            enableClickEvent={() => this.toggleStatus(account)}
            addDataSheetEvent={() => this.handleAddDataSheet(account)}
          />
        </td>
      </tr>
    );
  }

  renderAddinfo(item, length) {
    let deafultValue = null;
    if (this.state.editRecord || this.state.showDetails) {
      if (item.prefix) {
        for (const key in this.state.addArticle.measurement_units) {
          if (
            Object.hasOwnProperty.call(
              this.state.addArticle.measurement_units,
              key
            )
          ) {
            const element = this.state.addArticle.measurement_units[key];
            if (element.id_mu === item.id) {
              deafultValue = element.quantity;
            }
          }
        }
      } else if (item.info) {
        for (const key in this.state.addArticle.add_info) {
          if (Object.hasOwnProperty.call(this.state.addArticle.add_info, key)) {
            const element = this.state.addArticle.add_info[key];
            if (element.id_add_info === item.id) {
              deafultValue = element.article_info;
            }
          }
        }
      }
    }
    return (
      <div
        className={`col-md-${length === 1 ? "6" : "6"} `}
        key={`${item.info ? item.info : item.description}-${item.id}`}
      >
        <span className={`${style.crudModalTopLabel}`}>
          {item.info ? item.info : item.prefix} *
        </span>
        <input
          placeholder="Escribir..."
          key={`${item.info ? item.info : item.description}-${item.id}`}
          type="text"
          name={
            item.info
              ? `additional_info-${item.id}`
              : `measurment_unit-${item.id}`
          }
          className="col-md-4 p-2 register-inputs"
          onChange={(e) => {
            this.onChangeItemsIF(e, item);
          }}
          disabled={this.state.editRecord || this.state.showDetails}
          value={
            this.state.editRecord || this.state.showDetails
              ? deafultValue
              : null
          }
        />
      </div>
    );
  }

  //   toggle visibilty of modal to edit or create record
  handleOnHide = () => {
    let { modal } = this.state;
    modal = !modal;
    this.setState({ modal });
  };

  handleOnHideModalDataSheet = () => {
    let { modalDataSheet, fileUpload, addArticle, fileValid } = this.state;
    fileUpload = null;
    addArticle.data_sheet = null;
    fileValid = false;
    modalDataSheet = !modalDataSheet;
    this.setState({ modalDataSheet, fileUpload, addArticle, fileValid });
  };

  discardFile = () => {
    let { addArticle } = this.state;
    MySwal.fire({
      title: `¿Está seguro de eliminar la ficha técnica?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#003f80",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, estoy seguro",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.toggleModalDataSheet();
        this.props.deleteDataSheet(addArticle, this.state.filters);
        addArticle.data_sheet = "";
      }
    });
  };
  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "Art"
    );
  renderTooltip = (props) => (
    <Tooltip placement="top-end" id="button-tooltip" {...props}>
      Requerirá autorización previa de un jefe o lider para cada orden de compra
    </Tooltip>
  );
  saveButton = () => {
    let sw = false;
    if (
      !this.state.addArticle.description ||
      !this.state.addArticle.cost_price ||
      !this.state.addArticle.inventory_family ||
      !this.state.addArticle.tradename
    ) {
      sw = true;
    } else {
      sw = false;
    }
    return sw;
  };

  render() {
    let renderHeaders = this.state.headerState
      ? this.state.headerState.map((header) => {
        return <th className={` ${header.className}`}>{header.tittle}</th>;
      })
      : "";
    let renderAccounts = this.props.articles
      ? this.props.articles.map((art) => {
        return this.renderAccounts(art);
      })
      : "";

    let optionsRender = [];
    if (this.props.listInventoryFamilies) {
      this.props.listInventoryFamilies.map((item) => {
        optionsRender.push({
          value: item.id,
          label: item.description,
        });
      });
    }
    let optionsiVA = [];
    if (this.props.listOptionsIva) {
      this.props.listOptionsIva.map((item) => {
        optionsiVA.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    let additionalInfo = [];
    let measurementsUnits = [];
    if (this.state.addArticle.inventory_family) {
      this.props.listInventoryFamilies.map((element) => {
        if (this.state.addArticle.inventory_family.length > 0) {
          if (element.id === this.state.addArticle.inventory_family[0].id) {
            element.measurements_list.map((unit) => {
              return measurementsUnits.push(unit);
            });
            element.additional_info.map((item) => {
              return additionalInfo.push(item);
            });
          }
        } else {
          if (element.id === this.state.addArticle.inventory_family) {
            element.measurements_list.map((unit) => {
              return measurementsUnits.push(unit);
            });
            element.additional_info.map((item) => {
              return additionalInfo.push(item);
            });
          }
        }
      });
    }

    let optionsMeasurementUnits = [];
    if (measurementsUnits) {
      measurementsUnits.map((item) => {
        optionsMeasurementUnits.push({
          value: item.id,
          label: item.description,
        });
      });
    }
    let renderAdditionalInfo =
      additionalInfo.length > 0
        ? additionalInfo.map((item) => {
          let length = additionalInfo.length;
          return this.renderAddinfo(item, length);
        })
        : "";
    let showSelectedInventoryFamily = { label: "", value: "" };
    if (Array.isArray(this.state.addArticle?.inventory_family)) {
      showSelectedInventoryFamily = {
        label:
          this.state.editRecord || this.state.showDetails
            ? this.state.addArticle?.inventory_family[0]?.description
            : this.state?.inventoryFamilyValue,
        value: this.state.addArticle?.inventory_family[0]?.id,
      };
    }
    const handleSumit = (e) => {
      e.preventDefault();
      let { filters } = this.state;
      filters.page = 1;
      this.setState({ filters });
      this.props.getArticles(this.state.filters);
    }
    let showSelectedIva = {};
    if (this.state.editRecord || this.state.showDetails) {
      let auxArray =
        this.props.listOptionsIva?.filter(
          (x) => Number(x.id) === Number(this.state.addArticle.iva)
        );
      showSelectedIva.label = auxArray[0]?.name;
      showSelectedIva.value = Number(auxArray[0]?.id);
    }
    let showSelectedMU = {};
    if (this.state.editRecord || this.state.showDetails) {
      let auxArray =
        this.state.addArticle?.inventory_family[0].measurements_list.filter(
          (x) => x.id === this.state.addArticle.measurement_unit
        );
      showSelectedMU.label = auxArray[0]?.description;
      showSelectedMU.value = auxArray[0]?.id;
    }
    return (
      <div className={`w-100 ${this.props.loading ? "disabled" : ""}`}>
        {this.props.loading ? (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        ) : null}
        {!!this.myPermission()?.read ? (
          <div className={tableStyles.container}>
            <div className={tableStyles.tableArea}>
              <h1 className="top-h1 mb-5">Artículos</h1>
              <div className="row">
                <ModalNew
                  hideFooter={this.state.editRecord ? !this.myPermission()?.edit : !this.myPermission()?.create}
                  id="modalDatasheet"
                  title="Cargar ficha técnica"
                  size="265"
                  show={this.state.modalDataSheet}
                  onHide={() => this.handleOnHideModalDataSheet()}
                  btnYesEvent={() =>
                    this.state.showDetails
                      ? ""
                      : this.submitDataSheet(this.state.addArticle)
                  }
                >
                  <form
                    enctype="multipart/form-data"
                    action={() => this.submitDataSheet(this.state.addArticle)}
                  >
                    <div className="d-flex">
                      {this.state.addArticle.data_sheet ? (
                        <div
                          className={`${style.discardFile} hoverPointer`}
                          onClick={() => this.discardFile()}
                        >
                          <AiFillCloseCircle />
                        </div>
                      ) : null}
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          this.validateFile(acceptedFiles)
                        }
                        accept=".pdf"
                        noClick={
                          this.state.addArticle.data_sheet !== "" &&
                          this.state.addArticle.data_sheet !== undefined &&
                          this.state.addArticle.data_sheet !== null
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section
                            className={`${this.state.addArticle.data_sheet
                              ? style.borderAddDataSheet
                              : "border"
                              } w-100 h-100 p-1 text-center mx-auto`}
                          >
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                type="file"
                                name="data_sheet"
                                onChange={this.onChangeForm}
                                accept="application/pdf"
                                multiple={false}
                              />
                              {this.state.fileValid ||
                                this.state.addArticle.data_sheet ? (
                                <img
                                  src={PdfIcon}
                                  alt="pdf icon"
                                  key="img-icon-pdf"
                                  className=""
                                />
                              ) : null}
                              {typeof this.state.addArticle.data_sheet ===
                                "string" &&
                                this.state.addArticle.data_sheet !== "" ? (
                                <div>
                                  <p
                                    className={`text-muted text-center hoverPointer`}
                                  >
                                    {this.state.addArticle.data_sheet
                                      ? this.state.addArticle.description
                                      : ""}
                                  </p>
                                  <a
                                    className="text-center"
                                    href={this.state.addArticle.data_sheet}
                                    target="_blank" rel="noreferrer"
                                  >
                                    Descargar
                                  </a>
                                </div>
                              ) : (
                                <p
                                  className={`text-muted text-center ${this.state.fileValid &&
                                    this.state.addArticle.data_sheet !== ""
                                    ? "px-5 pt-3 pb-5"
                                    : "p-5"
                                    } hoverPointer`}
                                >
                                  {this.state.fileValid && this.state.fileUpload
                                    ? this.state.fileUpload[0].name
                                    : "Soltar archivo aquí"}
                                </p>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </form>
                </ModalNew>
                <ModalNew
                  title={this.state.titleModal}
                  show={this.state.modal}
                  btnYesDisabled={this.saveButton()}
                  size="550"
                  onHide={() => this.handleOnHide()}
                  btnYesEvent={
                    this.state.showDetails ? null : () => this.handleSubmit()
                  }
                >
                  <div className="row ">
                    <div className="col-md-6">
                      <p className={`${style.crudModalTopLabel}`}>
                        Descripción *
                      </p>
                      <input
                        // placeholder="Acetaminofén"
                        className="register-inputs"
                        name="description"
                        type="text"
                        placeholder="Escribir..."
                        onChange={this.onChangeForm}
                        value={this.state.addArticle.description}
                        disabled={this.state.showDetails}
                      />
                    </div>
                    <div className="col-md-6">
                      <p className={`${style.crudModalTopLabel}`}>
                        Familia de Inventario *
                      </p>
                      <Select 
                        noOptionsMessage={() => 'No hay datos'}
                        name="inventory_family"
                        placeholder="Seleccionar..."
                        styles={customSelectNewDark}
                        options={[
                          {label: 'Seleccionar...', value:''},
                          ...optionsRender
                        ]}
                        onChange={this.onchangeSelectFamily}
                        onInputChange={this.onInputChangeFamily}
                        isDisabled={
                          this.state.editRecord || this.state.showDetails
                        }
                        defaultValue={showSelectedInventoryFamily.label ? {
                          label: showSelectedInventoryFamily.label,
                          value: showSelectedInventoryFamily.value,
                        } : ''}
                        onFocus={this.onFocusInventoryFamily}
                      />
                    </div>
                    <div className="col-md-6">
                      <p className={`${style.crudModalTopLabel}`}>
                        Nombre comercial *
                      </p>
                      <input
                        placeholder="Escribir..."
                        className="register-inputs"
                        name="tradename"
                        type="text"
                        onChange={this.onChangeForm}
                        disabled={
                          this.state.editRecord || this.state.showDetails
                        }
                        value={this.state.addArticle.tradename}
                      />
                    </div>
                    <div
                      className="col-md-6"
                      style={{ "align-self": "flex-end" }}
                    >
                      <div className="form-check">
                        <input
                          placeholder="Escribir..."
                          disabled={this.state.showDetails}
                          className="form-check-input"
                          type="checkbox"
                          name="req_authorization"
                          onChange={this.onChangeCheckBox}
                          checked={this.state.addArticle.req_authorization}
                        />
                        <label
                          className="form-check-label label_inputs_new3"
                          for="req_Authorization"
                        >
                          Requerirá autorización
                        </label>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip}
                        >
                          <img
                            style={{ "vertical-align": "top" }}
                            src={question}
                          ></img>
                        </OverlayTrigger>
                      </div>
                    </div>
                    {optionsMeasurementUnits &&
                      this.state.addArticle.inventory_family ? (
                      <div>
                        <label className={`${style.subtitle}`}>
                          Unidades de medida
                        </label>
                        <div className="row">
                          <div
                            className="col-md-5"
                            style={{ "align-self": "flex-end" }}
                          >
                            <p className={`${style.crudModalTopLabel}`}>
                              Unidad de medida *
                            </p>
                            <Select 
                              noOptionsMessage={() => 'No hay datos'}
                              name="measurement_units"
                              placeholder="Seleccionar..."
                              styles={customSelectNewDark}
                              options={[{label: 'Seleccionar...', value:''},...optionsMeasurementUnits]}
                              onChange={this.onchangeSelectMeasurement}
                              // onInputChange={this.onInputChangeFamily}
                              defaultValue={showSelectedMU?.label ? showSelectedMU : ''}
                              isDisabled={
                                this.state.editRecord || this.state.showDetails
                              }
                            />
                          </div>

                          <div
                            className="col-md-3 form-check"
                            style={{ "align-self": "flex-end" }}
                          >
                            <input
                              type="checkbox"
                              name="unit"
                              checked={
                                (this.state.editRecord &&
                                  this.state.addArticle.quantity === 1) ||
                                  this.state.unicUnit
                                  ? true
                                  : false
                              }
                              className="form-check-input"
                              onClick={() =>
                                this.setState({
                                  unicUnit: !this.state.unicUnit,
                                  addArticle: {
                                    ...this.state.addArticle,
                                    quantity: 1,
                                  },
                                })
                              }
                              disabled={this.state.showDetails}
                            />

                            <label
                              htmlFor="unit"
                              className="form-check-label label_inputs_new3"
                            >
                              Unidad única
                            </label>
                          </div>
                          <div className="col-md-4">
                            <label
                              className={`${style.crudModalTopLabel}`}
                              htmlFor="quantity"
                            >
                              Cantidad o medida *
                            </label>
                            <input
                              placeholder="Escribir..."
                              type="number"
                              name="quantity"
                              className=" register-inputs"
                              onChange={this.onChangeForm}
                              disabled={
                                this.state.editRecord ||
                                this.state.unicUnit ||
                                this.state.showDetails
                              }
                              value={this.state.addArticle.quantity}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {renderAdditionalInfo &&
                      this.state.addArticle.inventory_family ? (
                      <label className={`${style.subtitle}`}>
                        Información adicional:
                      </label>
                    ) : (
                      ""
                    )}
                    {renderAdditionalInfo}
                    <div className="col-md-6">
                      <span className={`${style.crudModalTopLabel}`}>
                        Código de barras *
                      </span>
                      <input
                        type="text"
                        placeholder="Escribir..."
                        name="barcode"
                        className="col-md-6  register-inputs"
                        onChange={this.onChangeForm}
                        disabled={
                          this.state.editRecord || this.state.showDetails
                        }
                        value={
                          this.state.editRecord || this.state.showDetails
                            ? this.state.addArticle.barcode
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-6 ">
                      <span
                        className={`${style.crudModalTopLabel}`}
                        for="reference"
                      >
                        Referencias *
                      </span>
                      <input
                        id="reference"
                        name="reference"
                        placeholder="Escribir..."
                        type="text"
                        className="col-md-4 mx-auto  register-inputs"
                        onChange={this.onChangeForm}
                        disabled={
                          this.state.editRecord || this.state.showDetails
                        }
                        value={
                          this.state.editRecord || this.state.showDetails
                            ? this.state.addArticle.reference
                            : null
                        }
                      />
                    </div>
                    <div className="row"></div>
                    <div className="col-md-6" style={{ display: "flex" }}>
                      <div
                        className="col-md-3 form-check "
                        style={{ flex: "auto" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="property_"
                          id="property_"
                          value="property_"
                          onChange={this.onChangeForm}
                          checked={this.state.addArticle.property_}
                          disabled={
                            this.state.editRecord || this.state.showDetails
                          }
                        />
                        <label
                          className="form-check-label label_inputs_new3"
                          for="radioNiifSelect"
                        >
                          Propiedad
                        </label>
                      </div>
                      <div
                        className="col-md-3 form-check"
                        style={{ flex: "auto" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="consignment"
                          id="consignment"
                          value="consignment"
                          onChange={this.onChangeForm}
                          checked={this.state.addArticle.consignment}
                          disabled={
                            this.state.editRecord || this.state.showDetails
                          }
                        />
                        <label
                          className="form-check-label label_inputs_new3"
                          for="radioLocalSelect"
                        >
                          Consignación
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6" style={{ display: "flex" }}>
                      <div
                        className="col-md-3 form-check"
                        style={{ flex: "auto" }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="handle_batch"
                          onChange={this.onChangeCheckBox}
                          checked={this.state.addArticle.handle_batch}
                          disabled={
                            this.state.editRecord || this.state.showDetails
                          }
                        />
                        <label
                          className="form-check-label label_inputs_new3"
                          for="uses_third"
                        >
                          Maneja lote
                        </label>
                      </div>
                      <div
                        className="col-md-3 form-check"
                        style={{ flex: "auto" }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="handle_serial"
                          onChange={this.onChangeCheckBox}
                          checked={this.state.addArticle.handle_serial}
                          disabled={
                            this.state.editRecord || this.state.showDetails
                          }
                        />
                        <label
                          className="form-check-label label_inputs_new3"
                          for="handle_serial"
                        >
                          Maneja serial
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <p className={`${style.crudModalTopLabel}`}>
                        Laboratorio o fabricante
                      </p>
                      <Creatable
                        placeholder='Seleccionar...'
                        isClearable={true}
                        blurInputOnSelect={true}
                        name="manufacturer"
                        onInputChange={this.handleInputChange}
                        options={[{label: 'Seleccionar...', value:''}, ...this.state.optionsManufacturers]}
                        styles={customSelectNew}
                        onChange={this.handleChangeCreatable}
                        defaultValue={this.state.manufacturerValue ? {
                          label: this.state.manufacturerValue,
                          value: this.state.manufacturerValue,
                        } : ''}
                        formatCreateLabel={() =>
                          "Crear... " + String(this.state.manufacturerValue)
                        }
                        isDisabled={this.state.showDetails}
                      />
                    </div>

                    <div className="col-md-6">
                      <span className={`${style.crudModalTopLabel}`}>
                        Precio de costo *
                      </span>
                      <NumberFormat
                        placeholder="Escribir..."
                        allowNegative={false}
                        id="cost_price"
                        className={`register-inputs `}
                        prefix="$"
                        thousandsGroupStyle="thousand"
                        thousandSeparator
                        name="cost_price"
                        disabled={this.state.showDetails}
                        value={
                          this.state.editRecord || this.state.showDetails
                            ? this.state.addArticle.cost_price
                            : null
                        }
                        onValueChange={(values) => {
                          const { value } = values;
                          let { addArticle } = this.state;
                          addArticle.cost_price = value;
                          this.setState({ addArticle });
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <p className={`${style.crudModalTopLabel}`}>
                        IVA
                      </p>
                      <Select 
                        noOptionsMessage={() => 'No hay datos'}
                        placeholder='Seleccionar...'
                        name="iVA"
                        styles={customSelectNewDark}
                        options={[{label: 'Seleccionar...', value:''},...optionsiVA]}
                        onChange={this.onchangeSelectIva}
                        isDisabled={
                          this.state.editRecord || this.state.showDetails
                        }
                        defaultValue={showSelectedIva.label ?{
                          label: showSelectedIva.label,
                          value: showSelectedIva.value,
                        } : ''}
                      />

                    </div>

                    <div className={`col-md-6 d-flex`} style={{ marginTop: "25px" }}>
                      <div className={`flex-grow-1 form-check`}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="dotation"
                          onChange={this.onChangeCheckBox}
                          checked={!!this.state.addArticle.dotation}
                          disabled={this.state.showDetails}
                        />
                        <label className="form-check-label label_inputs_new3">
                          Es dotación
                        </label>
                      </div>
                    </div>
                  </div>
                </ModalNew>
                <div className={tableStyles.mainRow}>
                  {
                    this.myPermission()?.read && (
                      <form className="mt-3" onSubmit={handleSumit}>
                        <input
                          className={`${tableStyles.searchUsersAlt}`}
                          type="text"
                          placeholder="Buscar..."
                          onChange={(e) => this.onKeyUpSearch(e)}
                        />

                        <img
                          src={SearchIcon}
                          alt="Search icon"
                          className={`${tableStyles.iconSvg} ${style.iconSvgMargin}`}
                          onClick={(e) => this.handleSearch(e)}
                        />
                      </form>
                    )
                  }

                  {this.myPermission()?.create && (

                    <div className={`${style.principalScreenBtns}`}>
                      <Link
                        className={`text-decoration-none ${style.buttonCreatCount} groupAddButton align-items-center`}
                        onClick={() => this.toggleModal(true)}
                        style={{
                          width: "max-content",
                        }}
                      >
                        <label htmlFor="newAccident" className="darkGray fw-bold">
                          Crear artículo
                        </label>

                        <img
                          src={Adding}
                          alt="User"
                          className={`${style.btnNewHover} 
                  ${tableStyles.iconSvg}
                  `}
                          style={{}}
                        />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              {
                this.myPermission()?.read && (
                  <GenericTable fontFamilyTable={'fontFamilyTable'} dark headers={renderHeaders}>
                    {renderAccounts}
                  </GenericTable>
                )
              }

              {
                this.myPermission()?.read && (
                  <div
                    className={`${paginationStyles.wrapper} justify-content-between`}
                  >
                    <p style={{ display: "inline" }}>
                      Pag. {this.state.filters.page} de{" "}
                      {Math.ceil(this.props.index / this.state.filters.perpage)
                        ? Math.ceil(this.props.index / this.state.filters.perpage)
                        : ""}{" "}
                      ({this.props.index} encontrados)
                    </p>
                    <Pagination
                      activePage={this.state.filters.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.props.index}
                      pageRangeDisplayed={4}
                      activeClass={paginationStyles.activeClass}
                      onChange={this.handlePageChange.bind(this)}
                      itemClassPrev={paginationStyles.itemClassPrev}
                      itemClassNext={paginationStyles.itemClassNext}
                      itemClassFirst={paginationStyles.itemClassFirst}
                      itemClassLast={paginationStyles.itemClassLast}
                      itemClass={paginationStyles.itemClass}
                    />
                  </div>
                )
              }

            </div>
          </div>
         ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentAccount: state.loginReducer.currentAccount,
  isAuth: state.loginReducer.Authenticated,
  articles: state.articleReducer.articles,
  listInventoryFamilies: state.articleReducer.listInventoryFamilies,
  listManufacturers: state.articleReducer.listManufacturers,
  index: state.articleReducer.index,
  totalIndex: state.articleReducer.totalIndex,
  success_upload: state.articleReducer.success_upload,
  loading: state.articleReducer.loading,
  listOptionsIva: state.articleReducer.listOptions,
});

const mapDispatchToProps = (dispatch) => ({
  getActiveInventoryFamilies: () => {
    dispatch(getActiveInventoryFamilies());
  },
  getManufacturers: () => {
    dispatch(getManufacturers());
  },
  createArticle: async (objPuc, filters) => {
    return await dispatch(createArticle(objPuc, filters));
  },
  getArticles: (objFilters) => {
    dispatch(getArticles(objFilters));
  },
  changeStatus: (objArticle, filters) => {
    dispatch(changeStatus(objArticle, filters));
  },
  updateArticle: (objArticle, doAfter) => {
    dispatch(updateArticle(objArticle, doAfter));
  },
  addDataSheet: (objArticle, filters) => {
    dispatch(addDataSheet(objArticle, filters));
  },
  deleteDataSheet: (objArticle, filters) => {
    dispatch(deleteDataSheet(objArticle, filters));
  },
  getOptionsIVA: (filtersIva) => {
    dispatch(getOptionsIVA(filtersIva));
  },
  // updateArticle:(objArticle) => {
  //   dispatch(updateArticle(objArticle))
  // }
});
export default connect(mapStateToProps, mapDispatchToProps)(TableArticles);
