//All actions for header component
import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { SHOW_ACCOUNT_INFO } from "./actionTypes";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const showAccountInfo = (status) => (dispatch,getState)  => {
    dispatch({
        type:SHOW_ACCOUNT_INFO,
        payload:status
    })
}