import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import tableStyles from '../../components/Layouts/tableStyle.module.scss'

export const DxAids = () => {
  let itemTomap = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  return (
    <>
      <div>
        <Row className="mb-3 mt-3">
          <Col xs={4}><p className="pt-2 pl-3 m-0"><span className={`${tableStyles.ordDarkBlueText}`} >
            <b>
              Ayuda diagnóstica
            </b>
          </span></p></Col>
          <Col xs={3}><p className="pt-2 pl-3 m-0"><span className={`${tableStyles.ordDarkBlueText}`} > <b>
          &nbsp;&nbsp; Frecuencia
          </b></span></p></Col>
        </Row>
        {itemTomap.length > 0 &&
          itemTomap.map((e, i) => {
            return (
              <>
                <Row>
                  <Col xs={7}>
                    <Row>
                      <Col xs={7}>
                        <Form.Group className="mb-3" controlId="fName"
                        >
                          <Form.Control
                            className={`ord-roundInput`}
                            type="text"
                            value="Besamestazona"
                            disabled
                            placeholder="Escribir..."
                          />

                        </Form.Group>
                      </Col>
                      <Col xs={2}>
                        <Form.Group className="mb-3" controlId="fName"
                        >
                          <Form.Control
                            className={`ord-roundInput`}
                            type="text"
                            value="Besamestazona"
                            disabled
                            placeholder="Escribir..."
                          />

                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group className="mb-3" controlId="fName"
                        >
                          <Form.Control
                            className={`ord-roundInput`}
                            type="text"
                            value="Besamestazona"
                            disabled
                            placeholder="Escribir..."
                          />

                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )
          })
        }

      </div>
    </>
  )
}
