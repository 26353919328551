import React from 'react'
import styled from "styled-components";
import { Input } from '../../atoms';
import { useTheme } from "styled-components";
import Select from 'react-select';
import { ordCustomSelect } from '../../../Layouts/react-select-custom';


const StyledBoxInputSelect = styled.div`
    padding: ${({ padding }) => padding ? padding : '0px'};
    margin: ${({ margin }) => margin ? margin : '0'};
    display: grid;
    grid-template-columns: 70% 30%; 
    width:  ${({ width }) => width ? width : '296px'};
`

export const BoxInputSelect = (
    {
        width,
        nameInput,
        valueInput,
        placeholderInput,
        typeInput,
        padding,
        margin,
        arrayOptions,
        backgroundColorSelect,
        nameSelect,
        handleChangeSelect,
        onblurSelect,
        colorSelect,
        marginSelect,
        fontWeightSelect,
        className,
        valueSelect
    }) => {

    const { colors } = useTheme()

    return (
        <StyledBoxInputSelect
            padding={padding}
            margin={margin}
            width={width}
            className={`${className} animate__animated animate__fadeIn`}
        >
            <Input
                name={nameInput}
                value={valueInput}
                placeholder={placeholderInput}
                type={typeInput}
                width={'100%'}
                borderBottomRightRadius={'0'}
                borderTopRightRadius='0'
                borderRight='none'
            />

            <Select noOptionsMessage={() => 'No hay datos'}
                options={arrayOptions}
                className="text-secondary w-100 "
                placeholder={"Seleccione"}
                value={valueSelect}
                name={nameSelect}
                styles={ordCustomSelect}
                onChange={handleChangeSelect}
            ></Select>

            {/* <Select noOptionsMessage={() => 'No hay datos'}
                valueSelect={valueSelect}
                fontSize={'13px'}
                width={'100%'}
                padding={'0px'}
                arrayOptions={arrayOptions}
                border={`1px solid ${colors.ordDarkBlueText}`}
                backgroundColor={backgroundColorSelect}
                borderTopLeftRadius='0px'
                borderBottomLeftRadius='0px'
                handleChangeSelect={handleChangeSelect}
                name={nameSelect}
                onblurSelect={onblurSelect}
                color={colorSelect}
                margin={marginSelect}
                fontWeight={fontWeightSelect}
            /> */}

        </StyledBoxInputSelect>
    )
}
