import React, { Component } from "react";
import Filter from "../../assets/img/icons/filtro.svg";
import Add from "../../assets/img/icons/agregar.svg";
import Export from "../../assets/img/icons/exportar.svg";
import Gear from "../../assets/img/icons/engraneGris.svg";
import GearWhite from "../../assets/img/icons/engraneBlanco.svg";
import GearGray from "../../assets/img/icons/engraneGris.svg";
import EditIcon from "../../assets/img/icons/editar.svg";
import EraseICon from "../../assets/img/icons/canecaGris.svg";
import DeactiveIcon from "../../assets/img/icons/puntoRojo.svg";
import ActiveIcon from "../../assets/img/icons/puntoVerde.svg";
import Popup from "reactjs-popup";
//import "reactjs-popup/dist/index.css";
import styles from "./customPopup.module.scss";

function CustomPopup(props) {
  return (
    <Popup
      trigger={
        <button>
          <img src={props.triggerSrc ? props.triggerSrc : Gear} alt="configuracion" className="icons-gear" />
        </button>
      }
      position="right center"
      styles={styles.popupContent}
    >
      <ul className={styles.popUpUl}>
        {props.showEdit ? (
          <li
            onClick={() => props.editClickEvent()}
            className={styles.popUpLiEdit}
          >
            Editar
          </li>
        ) : null}

        {props.showEnable ? (
          <li
            onClick={() => props.enableClickEvent()}
            className={
              props.isEnabled ? styles.popUpLiActiveRed : styles.popUpLiActive
            }
          >
            {props.isEnabled ? "Deshabilitar" : "Habilitar"}
          </li>
        ) : null}
        {/* /* ---------------------------- Add more buttons ---------------------------- */}
        {props.newButton
          ? props.newButton.length > 0 &&
            props.newButton.map((e, i) => {
              return (
                <li
                  onClick={() => e.btnAction(e)}
                  className={
                    //  e.btnIcon //The idea is to do this dynamic as well, but i need the details icon right now, and haven't so much time to do it :'(
                    styles.popUpShowDetails
                  }
                >
                  {e.btnText}
                </li>
              );
            })
          : null}
      </ul>
    </Popup>
  );
}

export default CustomPopup;

// newButton: [
//   {
//     btnAction: func,
//     btnText: string,
//     btnIcon: string, //not implemented
//   },
// ];
