import React, { Fragment, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { TlnAccordion } from '../TelemedicineModule/TelemedicineComponents/TlnAccordion';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import Pen from "../assets/img/icons/penFullGray.svg";
import Arrow from "../assets/img/icons/orangeNoBGArrow.svg";
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable';
import add from "../assets/img/icons/add-check.svg";
import trash from "../assets/img/icons/outLineGrayTrash.svg";
import { Col, Form, Row } from 'react-bootstrap'
import tableStyles from "../../src/components/Layouts/tableStyle.module.scss";
import Swal from 'sweetalert2';
import Select from "react-select";
import { tlnCustomSelect } from '../../src/components/Layouts/react-select-custom';
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from 'react-redux';
import { getServices, getSpecialties, getSympton, updateSpecialties, createSpecialties } from '../actions/telemedicineAction';
import Loader from 'react-loader-spinner';
import withReactContent from 'sweetalert2-react-content';

function Specialties(props) {
    const store = useSelector(state => state);
    const MySwal = withReactContent(Swal);
    const ListSpecialties = store.telemedicineReducer.listSpecialties;
    const ListSympton = store.telemedicineReducer.listSympton;
    const ListServices = store.telemedicineReducer.listServices;
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);
    const [trigger, setTrigger] = useState(0);
    const [triggerService, setTriggerService] = useState(0);
    const [triggerSympton, setTriggerSympton] = useState(0);
    const [data, setData] = useState({});
    const [dataSympton, setDataSympton] = useState({});
    const [tableSympton, setTableSympton] = useState([]);
    const [dataService, setDataService] = useState({});
    const [tableService, setTableService] = useState([]);

    useEffect(() => {
        dispatch(getSpecialties(filters, () => setLoading(false)))
        dispatch(getSympton("", () => setLoading(false)))
        dispatch(getServices())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);
    const headers = [
        {
            title: "Servicio",
            className: "text-start px-2"
        },
        {
            title: " ",
            className: "text-start px-2 col-1"
        },
    ]
    const headers2 = [
        {
            title: "Síntomas",
            className: "text-start px-2"
        },
        {
            title: " ",
            className: "text-start px-2 col-1"
        },
    ]
    const [state, setState] = useState({
        specialties: false,
        type: "",
    });
    const toggleDrawer = (anchor, open, type) => (event) => {
        if (
            event?.type === "keydown" &&
            (event?.key === "Tab" || event?.key === "Shift")
        ) {
            return;
        }
        if (open === false) {
            setData({});
            setTableService([]);
            setTableSympton([]);
        }
        setState({ ...state, [anchor]: open, type: type });
    };
    const renderList = () => {
        let accordion = [];
        if (Array.isArray(ListSpecialties)) {
            accordion = ListSpecialties.map((x, index) => {
                return (
                    <TlnAccordion
                        key={index + x.spe_name}
                        title={"Especialidad: "}
                        titleVal={x.spe_name}
                        subtitle={"Prioridad:"}
                        subtitleVal={x.priority}
                        buttons={[{
                            img: Pen,
                            action: () => openDrawerEdit(x),
                        },
                        {
                            img: trash,
                            action: () => { valiDelete({ id: x.spe_id, status: 0 }, x.spe_name) }
                        }]}
                    >
                        <div className="text-secondary">
                            <b >Síntomas</b>
                            <hr className="my-2" />
                            <span className="my-3">
                                {Array.isArray(x.symptoms) ? x.symptoms.map((e, index) => { return (x.symptoms.length !== index + 1 ? e.description + ", " : e.description) }) : "No tiene sintomas relacionados"}
                            </span>
                        </div>
                        <div className="text-secondary mt-4">
                            <b >Servicios</b>
                            <hr className="my-2" />
                            <span className="my-3">
                                {Array.isArray(x.services) ? x.services.map((e, index) => { return (x.services.length !== index + 1 ? e.description + ", " : e.description) }) : "No tiene servicios relacionados"}
                            </span>
                        </div>

                    </TlnAccordion>
                );
            })
        }
        return accordion;
    };
    const valiDelete = (params, name) => {
        Swal.fire({
            title: `<span style=color:#00B4CC>¿Está seguro?</span>`,
            text: `Se eliminará la especialidad: ${name}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#003f80",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Continuar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                dispatch(updateSpecialties(params, () => { setLoading(false); setTrigger(trigger + 1) }))
            }
        })
    };
    let buttons = [
        {
            btnTitle: "Nueva especialidad",
            btnVariant: "gray",
            btnSize: 4,
            btnIcon: <div className={tableStyles.tlnBtnNew}></div>,
            action: toggleDrawer("specialties", true, "create"),
            btnTitleStyle: {
                fontSize: "14px", fontWeight: "700", textAlign: "end", display: "flex", justifyContent: "end", alignItems:'center'
            }
        },
    ];
    let optionsPriority = [
        { key: "default", value: "", label: "Seleccione..." },
    ];
    if (Array.isArray(ListSpecialties)) {
        ListSpecialties.map((item, index) => {
            optionsPriority.push({
                value: index + 1,
                label: index + 1,
                key: index + "545",
            });
        });
        optionsPriority.push({ value: ListSpecialties?.length + 1, label: ListSpecialties?.length + 1, key: ListSpecialties?.length + 1 + "545" })
    }
    let optionsSympton = [
        { key: "default", value: "", label: "Seleccione..." },
    ];
    if (Array.isArray(ListSympton)) {
        ListSympton.map((item, index) => {
            optionsSympton.push({
                value: item.sym_id,
                id: item.sym_id,
                label: item.sym_name,
                description: item.sym_name,
                key: index + "545",
            });
        });
    }
    let optionsService = [
        { key: "default", value: "", label: "Seleccione..." },
    ];
    if (Array.isArray(ListServices)) {
        ListServices.map((item, index) => {
            optionsService.push({
                value: item.id,
                cup: item.id,
                value2: item.srv_id,
                label: item.service,
                description: item.service,
                key: index + item.srv_id + "545",
            });
        });
    }
    const formatDataSymptom = (body) => {
        let tempList = [];
        if (Array.isArray(body)) {
            body.forEach((item, index) => {
                tempList.push(
                    < tr key={index + item.value}>
                        <td className="text-start px-2" >{item.description}</td>
                        <td className="cursorPointer" onClick={() => deleteItem(item, "symptom")}>X</td>
                    </tr >
                );
            });
        }
        return tempList;
    };
    const handleClickAddSymptom = (e) => {
        let isDuplicate = validateDuplicate(e, "symptom")
        if (isDuplicate) {
            MySwal.fire({
                icon: "error",
                title: "Sintoma duplicado",
                text: "El Sintoma ya ha sido ingresado en la tabla",
            });
        } else {
            setDataSympton({});
            setTriggerSympton(triggerSympton + 1)
            let nwArr = tableSympton
            nwArr.push(e)
            setTableSympton(nwArr)
        }
    }
    const formatDataService = (body) => {
        let tempList = [];
        if (Array.isArray(body)) {
            body.forEach((item, index) => {
                tempList.push(
                    < tr key={index + item.value}>
                        <td className="text-start px-2" >{item.description}</td>
                        <td className="cursorPointer" onClick={() => deleteItem(item, "service")}>X</td>
                    </tr >
                );
            });
        }
        return tempList;
    };
    const handleClickAddService = (e) => {
        let isDuplicate = validateDuplicate(e, "service")
        if (isDuplicate) {
            MySwal.fire({
                icon: "error",
                title: "Servico duplicado",
                text: "El servicio ya ha sido ingresado en la tabla",
            });
        } else {
            setDataService({});
            setTriggerService(triggerService + 1);
            let nwArr = tableService
            nwArr.push(e)
            setTableService(nwArr)
        }
    }
    const deleteItem = (item, type) => {
        if (type === "symptom") {
            let founded = tableSympton.filter((x) => x.id !== item.id)
            setTableSympton(founded)
        }
        if (type === "service") {
            let founded = tableService.filter((x) => x.cup !== item.cup)
            setTableService(founded)
        }
    }
    const openDrawerEdit = (item) => {
        setData({ priority: item.priority, name: item.spe_name, id: !!item.spe_id ? item.spe_id : "" })
        setTableService(!!item.services ? item.services : [])
        setTableSympton(!!item.symptoms ? item.symptoms : []);
        setState({ ...state, specialties: true, type: "edit" })
    }
    const posSpecialties = () => {
        let serviceArr = [];
        let symptomArr = [];
        tableService.forEach((x) => serviceArr.push(x.value))
        tableSympton.forEach((x) => symptomArr.push(x.value))
        let info = {
            name: data.name,
            priority: data.priority,
            service: serviceArr,
            symptoms: symptomArr
        }
        setLoading(true);
        dispatch(createSpecialties(info, () => {
            setLoading(false);
            setTrigger(trigger + 1);
            setState({ ...state, specialties: false, type: "edit" })
            setData({});
            setTableService([]);
            setTableSympton([]);
        }))
    }
    const editSpecialties = () => {
        let serviceArr = [];
        let symptomArr = [];
        tableService.forEach((x) => serviceArr.push(x.cup))
        tableSympton.forEach((x) => symptomArr.push(x.id))
        let info = {
            name: data.name,
            priority: data.priority,
            id: data.id,
            service: serviceArr,
            symptoms: symptomArr
        }
        setLoading(true);
        dispatch(updateSpecialties(info, () => {
            setLoading(false);
            setTrigger(trigger + 1);
            setState({ ...state, specialties: false, type: "edit" })
            setData({});
            setTableService([]);
            setTableSympton([]);
        }))
    }
    const validateInput = () => {
        let inputName = data.name;
        let inputPriority = data.priority;
        let inputSympton = tableSympton.length;
        let inputService = tableService.length;
        if (!!inputPriority && !!inputName && inputService > 0 && inputSympton > 0) {
            return false;
        } else {
            return true;
        }
    }
    const validateDuplicate = (item, type) => {
        if (type === "symptom") {
            let founded = tableSympton.length > 0 && tableSympton.filter((x) => x.id === item.id)
            if (founded.length > 0) {
                return true;
            } else {
                return false;
            }
        }
        if (type === "service") {
            let founded = tableService.length > 0 && tableService.filter((x) => x.cup === item.cup)
            if (founded.length > 0) {
                return true;
            } else {
                return false;
            }
        }

    }
    return (
        <div className={`${tableStyles.tlnContainer} mt-4`}>
            {(loading) &&
                <div className="loading">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            }
            <OrdGenericTemplate
                buttons={buttons}
                className="w-100"
                showBottomBorder={false}
                title={"Especialidades"}
                searchWithNew={{
                    onChange: (e) => {
                        setFilters({ ...filters, name: e.target.value })
                    },
                    colSize: "12",
                    onSubmit: (e) => {
                        e.preventDefault();
                        setLoading(true);
                        setTrigger(trigger + 1);
                    },
                }}
            // buttonNewAction={() => history.push('/asistencial/CombosDeCirugia/nuevo')}
            >
                <div className="w-100 mx-1 mb-5">
                    {renderList()}
                </div>
            </OrdGenericTemplate>
            <Fragment key={"specialties"}>
                <Drawer
                    anchor={"right"}
                    open={state["specialties"]}
                    onClose={toggleDrawer("specialties", false, "")}
                    classes={{ width: "100%" }}
                >
                    <div className={`${tableStyles.tlndrawer__header}`}>
                        <button
                            className={`${tableStyles.tlndrawer__close}`}
                            onClick={() => setState({ ...state, specialties: false })}
                        >
                        </button>
                        <div className={`${tableStyles.tlndrawer__title} w-100 text-start px-3`}>
                            <span>{state.type === "create" ? "Nueva especialidad" : "Editar especialidad"}</span>
                        </div>
                    </div>
                    <div className={`p-5 ${tableStyles.tlndrawer__content}`} style={{ width: "600px" }}>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3 text-start" controlId="2">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                        <b className="px-1">Nombre de especialidad</b>
                                    </Form.Label>
                                    <Form.Control
                                        className={`ord-roundInput`}
                                        type="text"
                                        value={data?.name}
                                        placeholder="Escribe..."
                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="mb-3 text-start" controlId="fName">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                        <b className="px-1">Prioridad</b>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        options={optionsPriority}
                                        className="text-secondary"
                                        value={optionsPriority.find(
                                            (item) => item.value === data.priority
                                        )}
                                        placeholder={"Seleccionar..."}
                                        styles={tlnCustomSelect}
                                        onChange={(e) => {
                                            setData({ ...data, priority: e.value });
                                            //console.log(e)
                                        }}
                                    ></Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="mb-3 text-start" controlId="fName">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                        <b className="px-1">Servicio</b>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        key={triggerService}
                                        options={optionsService}
                                        className="text-secondary"
                                        placeholder={"Seleccionar..."}
                                        styles={tlnCustomSelect}
                                        onChange={(e) =>
                                            setDataService(e)
                                        }
                                    ></Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <div className="text-end cursorPointer" onClick={() => !!dataService.value ? handleClickAddService(dataService) : null}>
                                    <span className="fw-bold" style={{ color: "#F39682" }}>Añadir a la tabla</span>
                                    <img src={Arrow} alt="add" className="px-2" srcset="" />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <OrdTable
                                    headers={headers}
                                    hasChildren={true}
                                // fTdClearBlue={true}
                                >
                                    {formatDataService(tableService)}
                                </OrdTable>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="mb-3 text-start" controlId="fName">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                        <b className="px-1">Síntomas</b>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        key={triggerSympton}
                                        options={optionsSympton}
                                        className="text-secondary"
                                        placeholder={"Seleccionar..."}
                                        styles={tlnCustomSelect}
                                        onChange={(e) =>
                                            setDataSympton(e)
                                        }
                                    ></Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <div className="text-end cursorPointer" onClick={() => !!dataSympton.value ? handleClickAddSymptom(dataSympton) : null}>
                                    <span className="fw-bold" style={{ color: "#F39682" }}>Añadir a la tabla</span>
                                    <img src={Arrow} alt="add" className="px-2" srcset="" />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <OrdTable
                                    headers={headers2}
                                    hasChildren={true}
                                // fTdClearBlue={true}
                                >
                                    {formatDataSymptom(tableSympton)}
                                </OrdTable>
                            </Col>
                            <Col xs={12}>
                                <div className="text-end">
                                    <button
                                        onClick={toggleDrawer("specialties", false, "")}
                                        className={`mr-2 btn ${tableStyles.tlnBtnPrimaryligth}`}>Cancelar</button>
                                    <button
                                        className={`${tableStyles.tlnBtnPrimary} btn`}
                                        disabled={validateInput()}
                                        onClick={() => state.type === "create" ? posSpecialties() : editSpecialties()}>
                                        Guardar
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Drawer>
            </Fragment>
        </div >
    )
}
export default Specialties;
