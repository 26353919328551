import React, { Component, useState, useEffect } from "react";
import bankMoveStyle from "../../TreasuryModule/MovimientosBancarios/bankMoveStyles.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import Atras from "../../assets/img/icons/atras-icon.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPdfTest,
  requisition_consecutive,
} from "../../actions/IndividualAuctionActions";
import Engra from "../../assets/img/icons/engraneGris.svg";
import Imprimir from "../../assets/img/icons/imprimir.svg";
import Style from "./Glosses.module.scss";
import { getDetGlossHistory } from "../../actions/billingActions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { message } from "../../helpers/helpers";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";

function GlossHistoryDetail(props) {
  const storage = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showPdf, setShowPdf] = useState(false);
  const [base64, setBase64] = useState("");
  const token = storage.loginReducer.Authorization;

  const getPdf = async () => {
    const result = await getPdfTest(
      {
        id: props?.selectedGloss,
        doc: 6,
      },
      token
    );
    
    if (result?.success) {
      return setBase64(result?.pdf[0]?.key?.split("'")[1]);
    } else {
      message(
        "error",
        "Ha ocurrido un error",
        "No ha sido posible cargar el documento"
      );
      return setShowPdf(false)
    }
  };
  useEffect(() => {
    getPdf();
  }, []);

  useEffect(() => {
    if (!!props.selectedGloss) {
      dispatch(
        getDetGlossHistory({
          id: props.selectedGloss,
          eaccount: storage.loginReducer.currentAccount.id,
        })
      );
    }
  }, [
    props.show,
    props.selectedGloss,
    dispatch,
    storage.loginReducer.currentAccount.id,
  ]);

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };

  return (
    <>
      <ModalNew
        title="Detalle de glosa"
        show={showPdf}
        btnNoName={"Cancelar"}
        size="700"
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer
          downloadable
          file={`data:application/pdf;base64,${base64}`}
        ></PdfViewer>
      </ModalNew>
      <div className={bankMoveStyle.centredContainer}>
        <div className={bankMoveStyle.container2}>
          <div className={tableStyles.container1}>
            <div className={tableStyles.ItemSmall}>
              <div className="d-flex">
                <img
                  className={`${tableStyles.title} mr-3 cursorPointer`}
                  src={Atras}
                  onClick={() => props.setDetails({ show: false })}
                />
                {storage.billingReducer.loading_gloss_history_det && (
                  <div className="loading">
                    <Loader
                      type="Oval"
                      color="#003f80"
                      height={100}
                      width={100}
                    />
                  </div>
                )}
                <h1 className={tableStyles.title}>Historial de glosas</h1>
              </div>
              <Row>
                <Col xs={12} className="d-flex justify-content-between">
                  <div>
                    <h5
                      className="mb-2"
                      style={{
                        color: "#005DBF",
                        fontSize: "15px",
                        fontWeight: "normal",
                        marginLeft: "40px",
                      }}
                    >
                      Factura No.{" "}
                      {storage.billingReducer.gloss_history_detail.invoice_nbr}{" "}
                    </h5>
                  </div>
                  <div>
                    <img
                      className={`${tableStyles.cursorPointer} `}
                      // style={{ paddingLeft: "5px", paddingRight: "5px" }}
                      src={Imprimir}
                      onClick={() => setShowPdf(true)}
                    />{" "}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className={`pt-1 pb-3`}
            style={{
              backgroundColor: "#F2F7FC",
              borderRadius: "10px",
              paddingLeft: "10px",
              marginLeft: "40px",
            }}
          >
            {/* ---------------------------- ROW 1 ---------------------------------- */}
            <Row className="d-flex" style={{ width: "100%" }}>
              <div className="mt-1">
                <label className={tableStyles.titleTax}>
                  Información de factura
                </label>
              </div>
              <Col xs={7}>
                <p className={tableStyles.crudModalLabel}>Empresa</p>
                <input
                  className="register-inputs"
                  style={{
                    background: "#F5F7FA",
                    border: "0.7px solid rgba(0, 93, 191, 0.5)",
                    color: "#58595B",
                  }}
                  type="text"
                  value={storage.billingReducer.gloss_history_detail.clientName}
                  readOnly
                />
              </Col>
              <Col xs={5}>
                <p className={tableStyles.crudModalLabel}>No. Factura</p>
                <input
                  className="register-inputs"
                  style={{
                    background: "#F5F7FA",
                    border: "0.7px solid rgba(0, 93, 191, 0.5)",
                    color: "#58595B",
                  }}
                  type="text"
                  value={
                    storage.billingReducer.gloss_history_detail.invoice_nbr
                  }
                  readOnly
                />
              </Col>
            </Row>

            {/*--------------------------------- ROW 2 --------------------------------- */}
            <Row className="d-flex" style={{ width: "100%" }}>
              <Col xs={7}>
                <p className={tableStyles.crudModalLabel}>Tipo de glosa</p>
                <input
                  className="register-inputs"
                  style={{
                    background: "#F5F7FA",
                    border: "0.7px solid rgba(0, 93, 191, 0.5)",
                    color: "#58595B",
                  }}
                  type="text"
                  value={storage.billingReducer.gloss_history_detail.type}
                  readOnly
                />
              </Col>
              <Col xs={5}>
                <p className={tableStyles.crudModalLabel}>Fecha de glosa</p>
                <input
                  className="register-inputs"
                  style={{
                    background: "#F5F7FA",
                    border: "0.7px solid rgba(0, 93, 191, 0.5)",
                    color: "#58595B",
                  }}
                  type="date"
                  value={storage.billingReducer.gloss_history_detail.date}
                  readOnly
                />
              </Col>
            </Row>

            {/*--------------------------------- ROW 3 --------------------------------- */}
            <Row className="d-flex " style={{ width: "100%" }}>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Valor de la glosa</p>
                <input
                  className="register-inputs"
                  style={{
                    background: "#F5F7FA",
                    border: "0.7px solid rgba(0, 93, 191, 0.5)",
                    color: "#58595B",
                  }}
                  name="requisition_date"
                  type="text"
                  value={
                    storage.billingReducer.gloss_history_detail?.total_amount
                      ? "$" +
                        numberWithCommas(
                          Number(
                            storage.billingReducer.gloss_history_detail
                              .total_amount
                          )
                        )
                      : "$"
                  }
                  readOnly
                />
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  Responsable de glosa
                </p>
                <input
                  className="register-inputs"
                  style={{
                    background: "#F5F7FA",
                    border: "0.7px solid rgba(0, 93, 191, 0.5)",
                    color: "#58595B",
                  }}
                  name="requisition_date"
                  type="text"
                  value={
                    storage.billingReducer.gloss_history_detail.objection_acc
                  }
                  readOnly
                />
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  Responsable de registro
                </p>
                <input
                  className="register-inputs"
                  style={{
                    background: "#F5F7FA",
                    border: "0.7px solid rgba(0, 93, 191, 0.5)",
                    color: "#58595B",
                  }}
                  name="requisition_date"
                  type="text"
                  value={
                    storage.billingReducer.gloss_history_detail.registry_acc
                  }
                  readOnly
                />
              </Col>
            </Row>

            <Row className="d-flex" style={{ width: "100%" }}>
              <Col>
                <p className={tableStyles.crudModalLabel}>Descripción</p>
                <textarea
                  style={{
                    height: "3rem",
                    background: "#F5F7FA",
                    color: "#58595B",
                  }}
                  placeholder="Escribir..."
                  rows="5"
                  cols="40"
                  className={Style.textAreaGris}
                  name="requisition_date"
                  value={
                    storage.billingReducer.gloss_history_detail.description
                  }
                  disabled
                />
              </Col>
            </Row>
          </div>

          <div
            className="mt-4"
            style={{ marginLeft: "40px", paddingLeft: "10px" }}
          >
            <div className="mt-3 " style={{ marginBottom: "19px" }}>
              <label
                className={tableStyles.titleTax}
                style={{ fontSize: "15px" }}
              >
                Registro de la gestión de la glosa
              </label>
            </div>
            <div className="d-flex" style={{ paddingLeft: "24px" }}>
              {storage.billingReducer?.gloss_history_detail?.answers?.length >
                0 && <div className={Style.lineConten}></div>}

              <div>
                {storage.billingReducer?.gloss_history_detail?.answers?.map(
                  (e, i) => {
                    return (
                      <div className="d-flex mb-4">
                        <div className={Style.circuleLine}></div>
                        <p className="ml-3 text-secondary">{e.created_at}</p>
                        <p className="ml-5 text-secondary">{e.action}</p>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GlossHistoryDetail;
