/* eslint-disable react-hooks/exhaustive-deps */
import { inputMuiGray, isEmptyOrUndefined, loader } from "../../../helpers/helpers";
import React from 'react'
import { OrdGenericTemplate } from '../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { Box, TextField } from '@mui/material';
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";
import { useTheme } from 'styled-components';
import { Button, Text } from '../../../components/UI/atoms';
import next from "../../../assets/img/icons/ordRightArrow.svg";
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { useHistory } from "react-router-dom";
import { useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import file from "../../../assets/img/icons/AdjuntarRecto.svg";
import questionBlueClear from "../../../assets/img/icons/questionBlueClear.svg";
import CustomPopupStyles from "../../../components/Popup/customPopup.module.scss";
import threeDots from "../../../assets/img/icons/threeDots.svg";
import add from "../../../assets/img/icons/grayAdd.svg";
import CustomPopupExtend from "../../../components/Popup/customPopUpExtends";
import { Form, Row } from "react-bootstrap";
import OrdModal from "../../../OrderingModule/OrderingComponents/OrdModal";
import usePatientAdmissionStore from './../../../Hooks/usePatientAdmissionStore';
import { useGetMethod, usePostMethod } from './../../../Hooks/useFetch';
import { calculateCollectionOfAdmissionPatient, convertMoneyFormatNew, customSwaltAlertAsistencial, depositType, onValidFielTypeFile } from "../../../helpers";
import { CardCollectionBase, CardCollectionDynamic, CardTotal, DrawerAndAlert, RescheduleAppointment } from './../components';
import { ServiceOrderReplace } from "../../Orders";
import { RenderRow } from "../../Orders/components";
import { ordCustomSelect } from "../../../components/Layouts/react-select-custom";
import Select from "react-select";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useHandleUploadFileAws } from "../../../Hooks";


export const PatientInfoCollected = () => {

    //  ---------------------------Hooks && LocalStorage-------------------------
    const store = useSelector((state) => state);
    const siteId = store.userReducer.site
    const [showModalReschedule, setShowModalReschedule] = useState(false)
    const [rescheduleAppointment, setRescheduleAppointment] = useState({})
    const [cancelAppointment, setCancelAppointment] = useState(null)
    const history = useHistory();
    const { colors } = useTheme()
    const { activeEvent, setActiveEvent, events, setEvents, clearStateAdmissionPatient, image } = usePatientAdmissionStore()
    const token = store.loginReducer.Authorization;
    const userName = `${store.loginReducer.user_data.first_name} ${store.loginReducer.user_data.second_name}`
    const userId = store.loginReducer.user_data.id;
    const idAccount = store.loginReducer.currentAccount.id;
    const eaccount = store.loginReducer.currentAccount.id;
    const [valuesPay, setValuesPay] = useState({ amount: 0, discount: 0, advance: 0, amountTotal: 0 })
    const createdBy = store.loginReducer.user_data.id
    const [modal, setModal] = useState({ active: false, index: undefined })
    const [modalCopago, setModalCopago] = useState({
        show: false,
        index: undefined,
        data: {
            userId,
            appId: undefined,
            srvId: undefined,
            fileUrl: undefined,
            fileName: undefined,
            motiveId: undefined
        }
    })

    const [nameTitleFilesUploader, setNameTitleFilesUploader] = useState({})
    const [collectionCrud, setCollectionCrud] = useState({
        isActiveAddNewCollection: true,
        options: [],
    })

    //  --------------------------- Petitions -------------------------
    const {
        results: listDepositType,
        load: loaderListDepositType,
        trigger: getListDepositType
    } = useGetMethod();

    const {
        load: loaderListAppointments,
        trigger: getListAppointments
    } = useGetMethod();

    const {
        results: listBank,
        load: loaderListBank,
        trigger: getListBank
    } = useGetMethod();

    const {
        load: loaderListSupport,
        trigger: getListSupport
    } = useGetMethod();

    const {
        results: listCancellationReason,
        load: loaderListCancellationReason,
        trigger: getListCancellationReason
    } = useGetMethod();

    const {
        results: listFranchise,
        load: loaderListFranchise,
        trigger: getListFranchise
    } = useGetMethod();

    const {
        trigger: sendDataForm,
        load: loadSendDataForm
    } = usePostMethod()

    const {
        trigger: onSubmitCancellationReason,
        load: loaderOnSubmitCancellationReason
    } = usePostMethod()

    const {
        results: listCompany,
        load: loaderListCompany,
        trigger: getListCompany
    } = useGetMethod();

    const {
        trigger: getCashClosingConfig,
        load: loadCashClosingConfig
    } = useGetMethod()

    const {
        isLoading: loadUploadFileAws,
        trigger: onUploadFileAws
    } = useHandleUploadFileAws()

    //  --------------------------- Actions -------------------------

    const printDataInsurance = () => {
        return (
            <>
                <RenderRow title={"Aseguradora"} subTitle={cancelAppointment?.detailAppointment?.company || '-'} />
                <RenderRow title={"Contrato"} subTitle={cancelAppointment?.detailAppointment?.contractCorporateClient || '-'} />
                <RenderRow title={"Grupo de ingreso"} subTitle={cancelAppointment?.detailAppointment?.crtName || '-'} />
                <RenderRow title={"Origen"} subTitle={cancelAppointment?.detailAppointment?.population || '-'} />
            </>
        );
    };

    const printDataCite = () => {
        const date = cancelAppointment?.detailAppointment?.applicationDateApp.split(' ')
        return (
            <>
                <RenderRow title={"Sede"} subTitle={cancelAppointment?.detailAppointment?.siteName || '-'} />
                <RenderRow title={"Médico"} subTitle={cancelAppointment?.detailAppointment?.nameDoctor || '-'} />
                <RenderRow title={"Servicio"} subTitle={cancelAppointment?.detailAppointment?.serviceName || '-'} />
                <RenderRow title={"Fecha"} subTitle={date?.length ? `${date[0]}` : '-'} />
                <RenderRow title={"Hora"} subTitle={date?.length ? `${date[1]} ${date[2]}` : '-'} />
                <RenderRow title={"No. de historia clínica"} subTitle={"N/A"} />
                <RenderRow
                    title={"Diagnóstico"}
                    subTitle={"falta el key en el endpoint"}
                />
                <RenderRow title={"Total"} subTitle={convertMoneyFormatNew({ textNumber: cancelAppointment?.detailAppointment?.price }) || '-'} />
            </>
        );
    };

    const onCloseModal = useCallback(() => setShowModalReschedule(false), [showModalReschedule]);
    const onOpenModal = () => setShowModalReschedule(true);

    const handleRemoveDeposit = (depoTypeId) => {
        let amountTotal = 0
        activeEvent?.listDeposits?.forEach(el => {
            if (el?.depoTypeId !== depoTypeId) {
                amountTotal += Number(el?.amount)
            }
        })

        const listDeposits = activeEvent?.listDeposits?.filter(el => el?.depoTypeId !== depoTypeId)

        setActiveEvent({
            ...activeEvent,
            amountTotal,
            listDeposits
        })

        if (!listDeposits?.length) {
            setCollectionCrud(state => ({
                ...state,
                isActiveAddNewCollection: true
            }))
        }
    }

    const handleAddDeposit = () => {
        let options = []
        const deposit = Object.values(activeEvent?.deposit)
        depositType.forEach(el => {
            if (!deposit.includes(el.value)) {
                options.push(el)
            }
        })

        setCollectionCrud(state => ({
            ...state,
            options,
            isActiveAddNewCollection: true
        }))
    }

    const validFormat = (target, extensions) => {
        const ext = extensions.split(',')
        const file = target.files
        if (!ext.includes(`.${file[0].type.split('/')[1]}`)) {
            customSwaltAlertAsistencial({
                icon: "warning",
                title: "Intenta de nuevo",
                text: 'Formato incorrecto',
                confirmButtonText: "Aceptar",
                showCancelButton: false,
            })
            return false
        }
        return true
    }

    const dataPost = () => {

        const dataSend = {
            modality: 'on_site',
            userName,
            userId,
            sitePrefix: activeEvent?.sitePrefix,
            siteId, eaccount, createdBy,
            appId: activeEvent?.id,
            statusCode: activeEvent?.appStatus,
            totalAmount: valuesPay?.amountTotal,
            companion: activeEvent?.companion,

            patient: { ...activeEvent?.patient, photo: image },

            insurances: activeEvent?.insurances?.map(el => ({
                ...el,
                insuranceName: listCompany?.results?.find(company => Number(company?.id) === Number(el?.id))?.copyName
            })),

            deposits: activeEvent?.listDeposits?.map(el => {
                if (el?.depoTypeName?.toLowerCase() === 'efectivo' && Number(el?.amount) >= valuesPay?.amountTotal) {
                    return {
                        depoTypeId: el?.depoTypeId,
                        amount: Number(valuesPay?.amountTotal),
                        franchise: el?.franchise,
                        bank: el?.bank,
                        referenceNumber: el?.referenceNumber,
                        donation: el?.donation,
                        pendingAmount: el?.pendingAmount,
                        depoTypeName: el?.depoTypeName
                    }
                }
                return {
                    depoTypeId: el?.depoTypeId,
                    amount: Number(el?.amount),
                    franchise: el?.franchise,
                    bank: el?.bank,
                    referenceNumber: el?.referenceNumber,
                    donation: el?.donation,
                    pendingAmount: el?.pendingAmount,
                }
            }),

            appsInfo: events?.map(el => ({
                id: el?.id,
                modality: 'on_site',
                siteId: el?.siteId,
                sitePrefix: el?.sitePrefix,
                services: [{
                    amount: el?.service?.amount,
                    discount: el?.service?.discount,
                    cupCode: el?.service?.cupCode,
                    name: el?.service?.name,
                    authorizationNumber: el?.service?.authorizationNumber,
                    supportFiles: el?.service?.files !== undefined
                        ? Object.values(el?.service?.files)
                        : undefined
                }]
            }))
        }

        return dataSend
    }

    const handleSaveForm = () => {

        customSwaltAlertAsistencial({
            icon: "warning",
            title: "¿Está seguro?",
            text: 'Verifique la información',
            confirmButtonText: "Si, Continuar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
        }).then(rs => {
            if (rs.isConfirmed) {
                sendDataForm({
                    url: `/medical/patientAdmission/`,
                    token: token,
                    method: "POST",
                    body: dataPost(),
                    succesAction: () => { },
                    doAfterSuccess: (response) => {
                        if (response.status === 200 || response.status === 201) {
                            customSwaltAlertAsistencial({
                                icon: "success",
                                title: "Creada exitosamente",
                                text: `Se ha creado la admisión exitosamente`,
                                showCancelButton: false,
                            }).then(() => {
                                getCashClosingConfig({
                                    url: `/medical/admissions/cashClosingConfig/`,
                                    objFilters: { userId, siteId, eaccount },
                                    token: token
                                }).finally(() => {
                                    history.replace('/admision/inicio')
                                    clearStateAdmissionPatient()
                                })
                            })
                        }
                    },
                    doAfterException: (error) => {
                        customSwaltAlertAsistencial({
                            icon: "warning",
                            title: "Intenta de nuevo",
                            text: error.message || 'error x',
                            showCancelButton: false,
                        })
                    },
                })
            }
        })
    }

    const onSaveFormCancellationReason = () => {

        const dataUpdate = {
            app_observations: cancelAppointment?.fields?.canDescription,
            canId: cancelAppointment?.fields?.canMotive,
            id: cancelAppointment?.detailAppointment?.idApp,
            idUser: userId,
            status: "cancelled",
            patient: cancelAppointment?.detailAppointment?.fullNamePatient
        }

        customSwaltAlertAsistencial({
            icon: "warning",
            title: "¿Está seguro?",
            text: `Se anulará el servicio: ${cancelAppointment?.detailAppointment?.serviceName}`,
            confirmButtonText: "Si, Continuar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
        }).then(rs => {
            if (rs.isConfirmed) {
                onSubmitCancellationReason({
                    url: `/medical/appointment/`,
                    token: token,
                    noAlert: true,
                    method: "PUT",
                    body: dataUpdate,
                    doAfterSuccess: (response) => {
                        if (response.status === 200 || response.status === 201) {
                            customSwaltAlertAsistencial({
                                icon: "success",
                                title: "Anulada exitosamente",
                                text: `Se ha anulado el servicio: ${cancelAppointment?.detailAppointment?.serviceName}`,
                                showCancelButton: false,
                            }).then(() => {
                                history.replace('/admision/inicio')
                                clearStateAdmissionPatient()
                            })
                        }
                    },
                    doAfterException: (error) => {
                        customSwaltAlertAsistencial({
                            icon: "warning",
                            title: "Intenta de nuevo",
                            text: error.message,
                            showCancelButton: false,
                        })
                    },
                })
            }
        })
    }

    const validForm = () => {
        const calcMoney = (Number(valuesPay?.amountTotal || 0) - Number(activeEvent?.amountTotal || 0))
        const isValidServices = true
        const isValidCollections = calcMoney <= 0
        return isValidServices && isValidCollections
    }

    const onDiscardService = (idService) => {
        customSwaltAlertAsistencial({
            icon: "warning",
            title: "¿Está seguro?",
            text: `Se descartará el servicio ${activeEvent?.service?.name}`,
            confirmButtonText: "Si, Continuar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
        }).then((res) => {
            if (res.isConfirmed) {
                customSwaltAlertAsistencial({
                    icon: "success",
                    title: "Descartado exitosamente",
                    text: `Se ha descartado el servicio ${activeEvent?.service?.name}`,
                    confirmButtonText: "Aceptar",
                    showCancelButton: false,
                }).then(() => {

                    history.replace('/admision/inicio')
                    clearStateAdmissionPatient()
                })

            }
        })
    }

    const getSupport = (el) => {

        const { appContract, service } = el

        getListSupport({
            url: "/medical/admissions/getConfigSupportFiles/",
            token: token,
            objFilters: {
                contractId: appContract?.contractId,
                offeredService: service?.id
            },
            doAfterSuccess: (response) => {

                setModal(state => ({
                    ...state,
                    unique: el.id,
                    action: 'addSupport',
                    active: true,
                    documents: response?.results
                }))
            },
            doAfterException: (error) => {
                customSwaltAlertAsistencial({
                    icon: "warning",
                    title: "Intenta de nuevo",
                    text: error.message,
                    showCancelButton: false,
                })
            },
        })
    }

    const validateSaveDocuments = () => {

        if (!Object.values(nameTitleFilesUploader).length || Object.values(nameTitleFilesUploader)?.some(el => el === '')) {
            return customSwaltAlertAsistencial({
                icon: "warning",
                title: "Intenta de nuevo",
                text: `Debe adjuntar todos los documentos`,
                confirmButtonText: "Aceptar",
                showCancelButton: false,
            })
        }

        customSwaltAlertAsistencial({
            icon: "success",
            title: "Cargados exitosamente",
            text: `Se han cargado los documentos de forma exitosa`,
            confirmButtonText: "Aceptar",
            showCancelButton: false,
        })

        setModal(state => ({ ...state, active: false }))

    }

    const validateModalCopago = () => {
        const { motiveId, fileName } = modalCopago.data

        if (isEmptyOrUndefined(motiveId) || isEmptyOrUndefined(fileName)) {
            return customSwaltAlertAsistencial({
                icon: "warning",
                title: "Intenta de nuevo",
                text: 'Todos los campos son obligatorios',
                confirmButtonText: "Aceptar",
                showCancelButton: false,
            })
        }

        sendDataForm({
            url: `/medical/patientAdmission/`,
            token: token,
            noAlert: true,
            method: "PUT",
            body: { ...modalCopago.data },
            succesAction: (response) => {
                customSwaltAlertAsistencial({
                    icon: "success",
                    title: "Copago anulado exitosamente",
                    text: `Se ha anulado el copago del servicio: ${events[modalCopago.index]?.service?.name}`,
                    showCancelButton: false,
                })
                onHideModalCopago()

                // setActiveEvent({
                //     ...activeEvent,
                //     service: { ...activeEvent.service, amount: 0 }
                // })

                const res = events
                res[modalCopago.index].service.amount = 0
                res[modalCopago.index].service.discount = 0
                res[modalCopago.index].service.advance = 0

                let amount = 0
                let discount = 0
                let advance = 0


                res?.forEach(el => {
                    if (el?.id !== events[modalCopago.index].id) {
                        amount += Number(el?.service.amount)
                        discount += Number(el?.service.discount)
                        advance += Number(el?.service.advance)
                    }
                })

                let amountTotal = (amount - (discount + advance))

                setEvents(res)

                setValuesPay(state => ({
                    ...state,
                    amount,
                    discount,
                    advance,
                    amountTotal
                }))
            },
            doAfterException: (error) => {
                customSwaltAlertAsistencial({
                    icon: "warning",
                    title: "Intenta de nuevo",
                    text: error.message,
                    showCancelButton: false,
                })
            },
        })
    }

    const clearSaveDocuments = () => {

        setModal(state => ({
            ...state,
            // active: false,
            files: {}
        }))


        const newEvent = {
            ...events[modal?.index],
            service: {
                ...events[modal?.index].service,
                files: undefined
            }
        }
        const res = events
        res[modal?.index] = newEvent

        setEvents(res)

        setModal(state => ({
            ...state,
            index: undefined
        }))

        setNameTitleFilesUploader({})
    }

    const onHideModalCopago = () => {
        setModalCopago({
            show: false,
            index: undefined,
            data: {
                userId,
                appId: undefined,
                srvId: undefined,
                fileUrl: undefined,
                fileName: undefined,
                motiveId: undefined,
            }
        })
    }

    const renderTooltip = (props) => (
        <Tooltip  {...props} >
            <div className={`${tableStyles.darkerGrayText}`}>
                Scanner de carnet, carta, certificado, etc.
            </div>
        </Tooltip>
    );


    //  ---------------------------Effects -------------------------

    React.useEffect(() => {
        if (events.length) {
            const filterServices = []
            events.forEach(el => {
                filterServices.push({
                    amount: el?.service?.amount,
                    discount: el?.service?.discount,
                    advance: el?.service?.advance
                })
            })

            setValuesPay(calculateCollectionOfAdmissionPatient(filterServices))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events])

    React.useEffect(() => {
        if (cancelAppointment?.show) {

            getListAppointments({
                url: "/medical/generals/appointments/",
                objFilters: { appId: cancelAppointment?.id, eaccount: idAccount, },
                token: token,
                doAfterSuccess: (res) => {
                    const data = res?.results[0]

                    if (Object.values(data).length) {
                        setCancelAppointment(state => ({
                            ...state,
                            detailAppointment: data
                        }))
                    }
                },
            })

            getListCancellationReason({
                url: "/medical/generals/cancellationReason/",
                token: token
            })
        }

    }, [cancelAppointment?.show])

    React.useEffect(() => {

        getListCompany({
            url: "/coporateClients/",
            objFilters: { id_account: eaccount },
            token: token,
        });

        getListDepositType({
            url: "/medical/admissions/depositType/",
            token: token,
            objFilters: { eaccount }
        });

        getListFranchise({
            url: "/accounting/franchise/",
            token: token,
            objFilters: { eaccount }
        });

        getListBank({
            url: "/admin/bankingEntities",
            token: token,
            objFilters: { eaccount }
        });

        getListCancellationReason({
            url: "/medical/generals/cancellationReason/",
            token: token
        })

        if (!activeEvent.deposit) {
            setActiveEvent({
                ...activeEvent,
                deposit: { cash: 'cash' }
            })
        }

        if (activeEvent?.listDeposits?.length) {
            setCollectionCrud(state => ({
                ...state,
                isActiveAddNewCollection: false,
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //  ---------------------------Component -------------------------
    return (
        <>
            {(
                loaderListDepositType ||
                loaderListAppointments ||
                loaderListBank ||
                loaderListFranchise ||
                loaderListCancellationReason ||
                loaderListSupport ||
                loaderOnSubmitCancellationReason ||
                loaderListCompany ||
                loadCashClosingConfig ||
                loadUploadFileAws ||
                loadSendDataForm
            ) && loader}

            <OrdModal
                title={modal.action === 'addSupport' ? 'Cargar documentos' : ''}
                show={modal.active}
                onHide={() => setModal(state => ({ ...state, active: false }))}
                btnYesName="Guardar"
                btnYesEvent={validateSaveDocuments}
                btnNoName='Limpiar'
                btnNoEvent={clearSaveDocuments}
            >

                <Box display='grid'>


                    {
                        modal?.documents?.map((document, i) => (
                            <Box className={modal?.documents?.length === i + 1 ? 'my-3' : 'mt-3'} key={document?.supportId}>
                                <Text title={document?.fileName} required />
                                <label
                                    htmlFor={document?.supportId}
                                    className={`bg-transparent w-100 ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f14} align-self-end`}
                                    style={{
                                        border: '1px solid #a2a3ab',
                                        borderRadius: '5px',
                                        fontSize: "12px",
                                        height: "32px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: 'center',
                                        paddingRight: "10px",
                                        padding: "0.25rem 0.4rem",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}

                                >
                                    <Text
                                        title={nameTitleFilesUploader[`${document?.fileName.split(' ')[0]}_${document?.supportId}`] || 'Cargar documento'}
                                        color={colors.gray}
                                        fontSize='13px'
                                    />
                                    <img src={file} alt="agregar archivo" width="18px" height='18px' />
                                </label>

                                <input
                                    id={document?.supportId}
                                    type="file"
                                    accept={document?.fileExtensions}
                                    className="d-none w-100"
                                    placeholder="Cargar documento"
                                    onChange={({ target }) => {
                                        
                                        const file = target.files[0]
                                        const documentName = `${document?.fileName.split(' ')[0]}_${document?.supportId}`

                                        onUploadFileAws({
                                            bucketName: 'ttnsalud',
                                            body: file,
                                            keyBody: `${file.name}`,
                                            location: 'support_files_admission/temp/'
                                        }).then(({ success,url }) => {
                                            
                                            if(success){

                                                const filter = events?.map(state => state.id === modal.unique
                                                    ? {
                                                        ...state,
                                                        service: {
                                                            ...state.service,
                                                            files: {
                                                                ...state.service.files,
                                                                [documentName]: {
                                                                    fileName: file?.name,
                                                                    base64: url,
                                                                    fileSupportTypeId: document?.supportId
                                                                }
                                                            },
                                                        }
                                                    }
                                                    : state)
    
                                                setModal(state =>
                                                ({
                                                    ...state,
                                                    files: { ...state.files, [documentName]: file?.name }
                                                }))
    
                                                setEvents(filter)
    
    
                                                setNameTitleFilesUploader(state => ({ ...state, [documentName]: file?.name }))

                                            }else{
                                                customSwaltAlertAsistencial({
                                                    icon: 'warning',
                                                    title: 'Intenta de nuevo',
                                                    text: 'Ha ocurrido un error al momento de subir el archivo',
                                                    showCancelButton: false
                                                })
                                            }

                                        })
                                    }}
                                />
                            </Box>
                        ))

                    }

                </Box>

            </OrdModal>

            <OrdModal
                title='Anular copago'
                show={modalCopago.show}
                onHide={onHideModalCopago}
                btnYesName="Anular"
                btnYesEvent={validateModalCopago}
            >
                <Box>

                    <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                        &nbsp;Motivo
                        <span className={`${tableStyles.ordOrangeText}`}>*</span>
                    </Box>

                    <Select
                        noOptionsMessage={() => "No hay datos"}
                        options={listCancellationReason?.results?.map(el => ({ label: el?.canDescription, value: el?.canId }))}
                        className="text-secondary "
                        placeholder={"Seleccionar..."}
                        styles={ordCustomSelect}
                        onChange={({ value }) => {
                            setModalCopago(state => ({
                                ...state,
                                data: {
                                    ...state.data,
                                    motiveId: value
                                }
                            }))
                        }}
                    />

                </Box>


                <Box my={2}>
                    <Box className="d-flex align-items-center gap-2">

                        <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                            &nbsp;Evidencia de derecho de anulación
                            <span className={`${tableStyles.ordOrangeText}`}>*</span>
                        </Box>

                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <img src={questionBlueClear} alt="questionBlueClear" />
                        </OverlayTrigger>

                    </Box>

                    <label
                        htmlFor='copagoFile'
                        className={`bg-transparent w-100 ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f14} align-self-end`}
                        style={{
                            border: '1px solid #a2a3ab',
                            borderRadius: '5px',
                            fontSize: "12px",
                            height: "32px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: 'center',
                            paddingRight: "10px",
                            padding: "0.25rem 0.4rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}

                    >
                        <Text
                            // title={nameTitleFilesUploader[`${document?.fileName.split(' ')[0]}_${document?.supportId}`] || 'Cargar documento'}
                            title={modalCopago?.data?.fileName || 'Cargar evidencia'}
                            color={colors.gray}
                            fontSize='13px'
                        />
                        <img src={file} alt="agregar archivo" width="18px" height='18px' />
                    </label>

                    <input
                        id='copagoFile'
                        type="file"
                        accept=".jpg,.png,.jpeg,.pdf"
                        className="d-none w-100"
                        placeholder="Cargar evidencia"
                        onChange={({ target }) => {

                            const isValidFile = onValidFielTypeFile({ id: 'copagoFile' })

                            if (isValidFile) {

                                const file = target.files[0]
                                const fileName = file?.name

                                let reader = new FileReader();

                                reader.readAsDataURL(file);

                                reader.onload = function () {
                                    let rst = reader.result;

                                    setModalCopago(state => ({
                                        ...state,
                                        data: {
                                            ...state.data,
                                            fileName: fileName,
                                            fileUrl: rst,
                                        }
                                    }))
                                }
                            }
                        }}
                    />
                </Box>

            </OrdModal>

            <ServiceOrderReplace
                data={[]}
                size={940}
                show={cancelAppointment?.show}
                title={"Solicitud de anulación"}
                subTitle={`
                            ${convertMoneyFormatNew({ textNumber: cancelAppointment?.patient?.document, SymbolShow: true })} - 
                            ${cancelAppointment?.patient?.firstName || ''} 
                            ${cancelAppointment?.patient?.otherNames || ''}
                            ${cancelAppointment?.patient?.lastName || ''}
                        `}

                onHide={() => {
                    setCancelAppointment(null)
                }}
                btnNoEvent={() => setCancelAppointment(null)}
                dataInsurance={{
                    data: printDataInsurance,
                    subTitle: "Datos de aseguradora",
                }}
                dataCite={{
                    data: printDataCite,
                    subTitle: "Datos de la cita",
                }}
            >

                <Box>
                    <Box>
                        <Box>
                            <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                                &nbsp;Motivo
                                <span className={`${tableStyles.ordOrangeText}`}>*</span>
                            </Box>

                            <Select
                                noOptionsMessage={() => "No hay datos"}
                                options={listCancellationReason?.results?.map(el => ({ label: el?.canDescription, value: el?.canId }))}
                                className="text-secondary "
                                placeholder={"Seleccionar..."}
                                styles={ordCustomSelect}
                                onChange={({ value }) => {
                                    setCancelAppointment(state => ({
                                        ...state,
                                        fields: { ...state.fields, canMotive: value }
                                    }))
                                }}
                            />
                        </Box>

                        <Box my={2}>
                            <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                                &nbsp;Observaciones
                                <span className={`${tableStyles.ordOrangeText}`}>*</span>
                            </Box>

                            <textarea
                                minLength={5}
                                placeholder="Escribir..."
                                style={{ minHeight: "265px" }}
                                className={`text-secondary ordInputAdmission w-100 p-2`}
                                onChange={({ target }) => {
                                    setCancelAppointment(state => ({
                                        ...state,
                                        fields: { ...state.fields, canDescription: target.value }
                                    }))
                                }}
                            />
                        </Box>
                    </Box>

                    <Box display={"grid"} gridTemplateColumns={"120px 180px"} columnGap={2} justifyContent={'end'}>
                        <button
                            className={`${tableStyles.btnReplaceCancel}`}
                            onClick={() => {
                                setCancelAppointment(null)
                            }}
                        >
                            Cancelar
                        </button>

                        <button
                            disabled={
                                !(cancelAppointment?.fields?.canDescription && cancelAppointment?.fields?.canMotive)
                            }
                            className={`${tableStyles.btnReplace} ${tableStyles.btnReplaceHover}`}
                            onClick={onSaveFormCancellationReason}
                        >
                            Solicitar anulación
                        </button>
                    </Box>
                </Box>

            </ServiceOrderReplace>


            {
                showModalReschedule && (
                    <RescheduleAppointment
                        token={token}
                        userId={userId}
                        idAccount={idAccount}
                        showModal={showModalReschedule}
                        onCloseModal={onCloseModal}
                        appointment={rescheduleAppointment}
                    />
                )
            }

            <OrdGenericTemplate
                rowStyle={{ alignItems: 'center' }}
                extraHeader={<DrawerAndAlert patientDocument={activeEvent?.patient?.document} patientId={activeEvent?.patient?.id} />}
                titleSize={6}
                className={`w-90 mt-4 mx-2`}
                showBottomBorder={false}
                title={"Admisión del paciente"}
                showBackArrow
                backArrowAction={() => {
                    setActiveEvent({ ...activeEvent, progress: 2 })
                    history.push({ pathname: "/admision/admitirPacientes/informacionAdicional" });
                }}
            >
                <ProgressBar bg1 bg2 className={'mt-4 mb-3'} />

                <Box color={colors.ordAquaMarine} className={`fw-bold mt-4 mb-3`}>Servicios</Box>

                {
                    events?.map((el, i) => (

                        <Box key={el?.id} className={`${tableStyles.cardStyles} mb-4`}>
                            <Box
                                display={'grid'}
                                columnGap='10px'
                                gridTemplateColumns='1fr 100px'
                            >

                                <Box
                                    display={'grid'}
                                    gap='10px'
                                    gridTemplateColumns='1fr'
                                >

                                    <Box
                                        display={'grid'}
                                        gap='20px'
                                        gridTemplateColumns='1fr 1fr 1fr 1fr 1fr'
                                        alignItems={'start'}
                                    >
                                        <div>
                                            <Text title={'Servicio'} color={colors.gray} fontSize='13px' />
                                            <Text title={`${(i + 1)}. ${el?.service?.name}`} color={colors.darkBlue2} fontWeight='bold' />
                                        </div>

                                        <div>
                                            <Text title={'Aseguradora'} color={colors.gray} fontSize='13px' />
                                            <Text title={el?.service?.corporateClient} color={colors.darkBlue2} />
                                        </div>

                                        <div>
                                            <Text title={'Contrato'} color={colors.gray} fontSize='13px' />
                                            <Text title={el?.service?.contract} color={colors.darkBlue2} />
                                        </div>

                                        <div>
                                            <Text title={'Grupo de ingreso'} color={colors.gray} fontSize='13px' />
                                            <Text
                                                title={el?.service?.incomeGroup === null ? '' : el?.service?.incomeGroup}
                                                color={colors.darkBlue2}
                                            />
                                        </div>

                                        <div style={{ borderBottom: '1px solid #A2A3AB' }}>
                                            <Text title={'Valor'} color={colors.gray} fontSize='13px' />
                                            <span className={tableStyles.darkerBlueText}>
                                                {convertMoneyFormatNew({ textNumber: el?.service?.amount || 0, SymbolDecimal: '.' })}
                                            </span>
                                        </div>

                                    </Box>

                                    <Box
                                        display={'grid'}
                                        columnGap='20px'
                                        gridTemplateColumns='1fr 1fr 1fr 1fr 1fr'
                                    >
                                        <div>
                                            <Text title={'Médico'} color={colors.gray} fontSize='13px' />
                                            <Text title={el?.fullNameDoctor} color={colors.darkBlue2} />
                                        </div>

                                        <div>
                                            <Text title={'Anticipo'} color={colors.gray} fontSize='13px' />
                                            <span className={tableStyles.darkerBlueText}>
                                                {convertMoneyFormatNew({ textNumber: el?.service?.advance || 0, SymbolDecimal: '.' })}
                                            </span>
                                        </div>


                                        <div>
                                            <Text title={'Descuento'} color={colors.gray} fontSize='13px' />
                                            <span className={tableStyles.darkerBlueText}>
                                                {convertMoneyFormatNew({ textNumber: el?.service?.discount || 0, SymbolDecimal: '.' })}
                                            </span>
                                        </div>


                                        <div>
                                            <TextField
                                                label="No. Autorización"
                                                type="text"
                                                variant="standard"
                                                size="small"
                                                sx={inputMuiGray}
                                                defaultValue={el?.service?.authorizationNumber}
                                                onChange={({ target }) => {

                                                    const filter = events?.map(state => state.id === el.id
                                                        ? { ...state, service: { ...state.service, authorizationNumber: target.value } }
                                                        : state)

                                                    setEvents(filter)
                                                }}
                                            />
                                        </div>

                                        <div className="mt-1">
                                            {/* <TextField
                                                    label="Cargar soportes"
                                                    type="text"
                                                    variant="standard"
                                                    size="small"
                                                    sx={inputMuiGray}
                                                /> */}

                                            <label
                                                // htmlFor="furatFile"
                                                className={`bg-transparent w-100 ${tableStyles.appInputLine} ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f14} align-self-end mt-2`}
                                                style={{

                                                    fontSize: "12px",
                                                    height: "32px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    paddingRight: "10px",
                                                    padding: "0.25rem 0.4rem",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}

                                                onClick={() => {
                                                    getSupport(el)

                                                    setModal(state => ({
                                                        ...state,
                                                        index: i
                                                    }))

                                                    const xx = {}

                                                    Object.entries(events[i]?.service?.files || {})?.forEach(file => {
                                                        xx[file[0]] = file[1]?.fileName
                                                    })


                                                    setNameTitleFilesUploader(
                                                        Object.values(events[i]?.service?.files || {})?.length
                                                            ? xx
                                                            : {}
                                                    )
                                                }}
                                            >
                                                <Text
                                                    title={Object.values(el?.service?.files || {})?.length ? 'Soportes cargados' : 'Cargar soportes'}
                                                    color={colors.ordAquaMarine}
                                                    fontSize='13px'
                                                />

                                                <img src={file} className={tableStyles.darkGrayFilter} alt="agregar archivo" width={8} />
                                            </label>


                                        </div>

                                    </Box>
                                </Box>


                                <Box
                                    display='flex'
                                    alignItems={'center'}
                                    justifyContent='center'
                                >

                                    <div
                                        className={`${tableStyles.addGrayCircle} d-flex align-items-center justify-content-center`}
                                        style={{ width: '40px', height: '40px' }}
                                    >
                                        <CustomPopupExtend
                                            noHover
                                            position="right center"
                                            className={`align-self-end`}
                                            triggerClass={`${tableStyles.hoverPopup} wpx30`}
                                            triggerSrc={threeDots}
                                            extraButtons={[
                                                {
                                                    text: "Descartar",
                                                    class: `
                                                        ${CustomPopupStyles.popUpGeneric} 
                                                        ${tableStyles.ordAquaMarineBgHover}
                                                    `,
                                                    event: () => {
                                                        onDiscardService(el?.service?.id)
                                                    },
                                                },
                                                {
                                                    text: "Reprogramar",
                                                    class: `
                                                        ${CustomPopupStyles.popUpGeneric} 
                                                        ${tableStyles.ordAquaMarineBgHover}
                                                    `,
                                                    event: () => {
                                                        onOpenModal()
                                                        setRescheduleAppointment(el)
                                                    },
                                                },
                                                {
                                                    text: "Anular cita",
                                                    class: `
                                                        ${CustomPopupStyles.popUpGeneric} 
                                                        ${tableStyles.ordAquaMarineBgHover}
                                                    `,
                                                    event: () => {
                                                        setCancelAppointment(state => ({
                                                            ...state,
                                                            show: true,
                                                            ...el
                                                        }))
                                                    },
                                                },
                                                    (el?.service?.amount) ? (
                                                    {
                                                        text: "Anular copago",
                                                        class: `
                                                            ${CustomPopupStyles.popUpGeneric} 
                                                            ${tableStyles.ordAquaMarineBgHover}
                                                        `,
                                                        event: () => {
                                                            setModalCopago(state => ({ 
                                                                ...state,
                                                                show: true, 
                                                                index: i, 
                                                                data:{
                                                                    ...state.data,
                                                                    appId: el?.id,
                                                                    srvId: el?.service?.id,
                                                                },
                                                            }))
                                                        },
                                                    }
                                                ) : undefined
                                            ]}
                                        ></CustomPopupExtend>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                    ))
                }

                <CardTotal valuesPay={valuesPay} />

                {
                    (valuesPay.amountTotal) ? (
                        <>
                            <Row>
                                <Form.Label className={`m-0 ${tableStyles.tlnTextPrimary} ${tableStyles.f14}`}>
                                    <b className="text-start">&nbsp;Recaudo</b>
                                </Form.Label>
                            </Row>



                            {
                                activeEvent?.listDeposits?.map(deposit => (
                                    <CardCollectionDynamic
                                        data={deposit}
                                        handleRemoveDeposit={handleRemoveDeposit}
                                        activeEvent={activeEvent}
                                        setActiveEvent={setActiveEvent}
                                        totalAmount={valuesPay.amountTotal}
                                        listFranchise={listFranchise}
                                        listBank={listBank}
                                        valuesPay={valuesPay}
                                    />
                                ))
                            }

                            {collectionCrud?.isActiveAddNewCollection &&
                                <CardCollectionBase
                                    dataOptions={activeEvent?.listDeposits?.map(el => el?.depoTypeId) || []}
                                    hasDelete={!activeEvent?.listDeposits?.length === 0}
                                    options={listDepositType?.results?.map(el => ({ label: el?.depoTypeName, value: el?.depoTypeId, image: el?.image }))}
                                    onChange={({ value, label, image }) => {
                                        const activeDepositType = listDepositType?.results?.find(el => el?.depoTypeId === value)
                                        const listDeposits = activeEvent?.listDeposits?.length
                                            ? [...activeEvent?.listDeposits, activeDepositType]
                                            : [activeDepositType]
                                        const isExistCash = activeEvent?.listDeposits?.filter(el => el?.depoTypeLabel === 'EFECTIVO' && el?.isEditable === 0)

                                        if (isExistCash?.length) {
                                            customSwaltAlertAsistencial({
                                                icon: "warning",
                                                title: "Intenta de nuevo",
                                                text: 'No puedes agregar dos recaudos de tipo <b>efectivo</b>',
                                                confirmButtonText: "Aceptar",
                                                showCancelButton: false,
                                            })

                                            setCollectionCrud(state => ({
                                                ...state,
                                                isActiveAddNewCollection: false,
                                            }))

                                            return
                                        } else {
                                            setActiveEvent({
                                                ...activeEvent,
                                                listDeposits
                                            })

                                            setCollectionCrud(state => ({
                                                ...state,
                                                isActiveAddNewCollection: false,
                                            }))
                                        }


                                    }}
                                />
                            }

                            <div className="d-flex" style={{ maxWidth: '850px' }}>

                                {
                                    (!collectionCrud?.isActiveAddNewCollection && activeEvent?.listDeposits?.length < listDepositType?.results?.length) ? (
                                        <button
                                            className={`${tableStyles.appButtonHover}`}
                                            onClick={handleAddDeposit}
                                        >
                                            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14} ${tableStyles.appTextHoverTurquoise}`}>
                                                &nbsp;Agregar otro recaudo
                                            </b>
                                            &nbsp;<img alt="addIcon" src={add} height='17px' className={`${tableStyles.AppIconHoverTurquoise}`} />
                                        </button>
                                    ) : ''
                                }

                                <div
                                    className={`ms-auto fw-bold gap-5 d-flex 
                                    ${activeEvent?.amountTotal < valuesPay?.amountTotal
                                            ? tableStyles.orangeLightText : isNaN(valuesPay?.amountTotal - activeEvent?.amountTotal)
                                                ? tableStyles.orangeLightText : tableStyles.darkerBlueText}`
                                    }
                                >
                                    <span>Faltante</span>
                                    <span>
                                        {convertMoneyFormatNew({
                                            textNumber: (valuesPay?.amountTotal - activeEvent?.amountTotal) <= 0
                                                ? 0 : isNaN(valuesPay?.amountTotal - activeEvent?.amountTotal)
                                                    ? valuesPay?.amountTotal : (valuesPay?.amountTotal - activeEvent?.amountTotal)
                                        })}
                                    </span>
                                </div>

                            </div>
                        </>
                    ) : ''
                }




                <Box className="d-flex justify-content-end my-5 gap-4">
                    <Button
                        className={`btn d-flex gap-2 justify-content-center align-items-center ${tableStyles.btnIconWhiteHover}`}
                        width='100px'
                        padding='8px'
                        fontSize='14px'
                        border={`2px solid ${colors.ordAquaMarine}`}
                        backgroundColor={'white'}
                        backgroundColorHover={colors.ordAquaMarine}
                        colorHover={'white'}
                        color={colors.ordAquaMarine}
                        onClick={() => {
                            setActiveEvent({ ...activeEvent, progress: 2 })
                            history.push({ pathname: "/admision/admitirPacientes/informacionAdicional" });
                        }}
                    >
                        <img style={{ transform: 'rotate(180deg)' }} className={`${tableStyles.svgTurquoiseFilter}`} src={next} alt="next" />
                        Atrás
                    </Button>

                    <Button
                        className={`btn d-flex gap-2 justify-content-center align-items-center`}
                        disabled={!validForm()}
                        width='130px'
                        padding='8px'
                        fontSize='14px'
                        border='none'
                        backgroundColor={colors.ordAquaMarine}
                        backgroundColorHover={colors.ordAquaMarine + '90'}
                        onClick={handleSaveForm}
                    >
                        Guardar
                        <img className={`${tableStyles.iconWhite}`} src={next} alt="next" />
                    </Button>


                </Box>

            </OrdGenericTemplate>

        </>
    )
}
