//-------------- components 🍕   --------------------------------------------
import React, { useEffect, useState } from "react";
import { OrdCard } from "../OrderingModule/OrderingComponents/OrdCard";
import tableStyles from "../components/Layouts/tableStyle.module.scss";
import { OrdGenericTemplate } from "../OrderingModule/OrderingComponents/OrdGenericTemplate";
import { OrdSlideDownButton } from "../OrderingModule/OrderingComponents/OrdSlideDownButton";
import { ordCustomSelect } from "../components/Layouts/react-select-custom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import OrdLupa from "../assets/img/icons/OrdLupa.svg";
import desplegarGris from "../assets/img/icons/desplegarGris.svg";
import redClose from "../assets/img/icons/redClose.svg";
import greenCircleCheck from "../assets/img/icons/greenCircleCheck.svg";
import denyCircleHover from "../assets/img/icons/denyCircleHover.svg";
import greenCircleCheckHover from "../assets/img/icons/greenCircleCheckHover.svg";
import ordBlueSearch from "../assets/img/icons/ordBlueSearch.svg";
import { Col, Form, Row } from "react-bootstrap";
import { ColorButton } from "../OrderingModule/OrderingComponents/ColorButton";
import ordComponentStyles from "../OrderingModule/OrderingComponents/ordComponentStyles.module.scss";
import Select from "react-select";
import { useSelector } from "react-redux";

import { useGetMethod, usePostMethod } from "../Hooks/useFetch";
import Loader from "react-loader-spinner";
import { message } from "../helpers/helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import OrdModal from "../OrderingModule/OrderingComponents/OrdModal";

export const AdditionalQuota = (props) => {
  //-------------- const 🍕   --------------------------
  const MySwal = withReactContent(Swal);
  const store = useSelector((state) => state);
  const [filters, setFilters] = useState({
    idDoctor: "",
    service: "",
    status: "",
    startDate: "",
    endDate: "",
    search: "",
    idAdvisor: "",
  });
  const [trigger, setTrigger] = useState(0);
  const [justification, setJustification] = useState({
    showModal: false,
    justification: "",
  });
  const token = store.loginReducer.Authorization;
  const user = store.loginReducer.user_data.id;

  // GETS 🎈
  const {
    results: ListDoctors,
    load: ListDoctorsLoader,
    trigger: getListDoctors,
  } = useGetMethod();
  const {
    results: ListServices,
    load: ListServicesLoader,
    trigger: getListServices,
  } = useGetMethod();
  const {
    results: ListAdvisors,
    load: ListAdvisorsLoader,
    trigger: getListAdvisors,
  } = useGetMethod();
  const {
    results: ListAdditionalQuotas,
    load: ListAdditionalQuotasLoader,
    trigger: getListAdditionalQuotas,
  } = useGetMethod();
  const { load: updateQuotasLoad, trigger: updateQuotas } = usePostMethod();

  const methodUpdate = (idApp, idUser, namePatient, dateQuota, typeProcess) => {
    //console.log(dateQuota, "dateQuota");
    //console.log(dateQuota.split(" ")[2], "dateQuota2");

    MySwal.fire({
      icon: "warning",
      title: "¿Está seguro?",
      text:
        "Se aprobará cupo adicional a las " +
        dateQuota?.split(" ")[1] +
        " " +
        dateQuota.split(" ")[2] +
        " Paciente: " +
        namePatient,
      confirmButtonText: "Si, continuar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        updateQuotas({
          url: "/medical/appointment/additionalQuotas/",
          token: token,
          noAlert: true,
          method: "PUT",
          body: {
            idApp: idApp,
            comments: justification.justification,
            idUser: idUser,
            typeProcess: typeProcess,
          },
          doAfterSuccess: (results) => {
            MySwal.fire({
              icon: "success",
              title: "Se ha aprobado",
              html: `${dateQuota?.split(" ")[1]} ${dateQuota.split(" ")[2]
                } <br/> Paciente:  ${namePatient}`,
            });
            setTrigger(trigger + 1);
          },
        });
      }
    });
  };

  const methodRejectedUpdate = (
    idApp,
    idUser,
    namePatient,
    dateQuota,
    typeProcess
  ) => {
    if (justification.justification.length > 0) {
      updateQuotas({
        url: "/medical/appointment/additionalQuotas/",
        token: token,
        noAlert: true,
        method: "PUT",
        body: {
          idApp: idApp,
          comments: justification.justification,
          idUser: idUser,
          typeProcess: typeProcess,
        },
        doAfterSuccess: (results) => {
          setJustification({ showModal: false, justification: "" });
          MySwal.fire({
            icon: "success",
            title: "Cupo adicional rechazado",
            html: `${dateQuota?.split(" ")[1]} ${dateQuota.split(" ")[2]
              } <br/> Paciente:  ${namePatient}`,
          });
          setTrigger(trigger + 1);
        },
      });
    } else {
      message("info", "La justificación es obligatoria")
    }
  };

  const rejectedMethod = (
    idApp,
    idUser,
    namePatient,
    dateQuota,
    typeProcess
  ) => {
    MySwal.fire({
      icon: "warning",
      title: "¿Está seguro?",
      text:
        "Se rechazará un cupo adicional en la franja horaria de las " +
        dateQuota?.split(" ")[1],
      confirmButtonText: "Si, continuar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setJustification({
          ...justification,
          idApp: idApp,
          idUser: idUser,
          namePatient: namePatient,
          dateQuota: dateQuota,
          typeProcess: typeProcess,
          showModal: true,
        });
      }
    });
  };

  //-------------- useEffect 🍕   --------------------------

  useEffect(() => {
    getListDoctors({
      url: "/medical/generals/doctors/",
      token: token,
    });
    getListServices({
      url: "/medical/generals/services/",
      token: token,
    });
    getListAdvisors({
      url: "/medical/generals/advisors/",
      token: token,
    });
  }, []);

  useEffect(() => {
    getListAdditionalQuotas({
      url: "/medical/appointment/additionalQuotas/",
      token: token,
      objFilters: filters,
    });
  }, [
    filters.startDate,
    filters.endDate,
    filters.service,
    filters.status,
    filters.idDoctor,
    filters.idAdvisor,
    trigger,
  ]);

  //-------------- options Selects 🍕   --------------------------
  let optionsDoctors = [{ key: "default", value: "", label: "Todos", id: "" }];
  if (Array.isArray(ListDoctors?.results)) {
    ListDoctors.results.forEach((item) => {
      optionsDoctors.push({
        value: item.idDoctor,
        label: item.nameDoctor,
        key: item.idDoctor + "doctor",
      });
    });
  }

  let optionsServices = [{ key: "default", value: "", label: "Todos", id: "" }];
  if (Array.isArray(ListServices?.results)) {
    ListServices.results.forEach((item) => {
      optionsServices.push({
        value: item.id,
        label: item.name,
        key: item.id + "service",
      });
    });
  }

  let optionsAdvisor = [{ key: "default", value: "", label: "Todos", id: "" }];
  if (Array.isArray(ListAdvisors?.results)) {
    ListAdvisors.results.forEach((item) => {
      optionsAdvisor.push({
        value: item.id,
        label: item.name,
        key: item.id + "advisor",
      });
    });
  }

  const [circleBtn, setCircleBtn] = useState({
    check: greenCircleCheck,
    deny: redClose,
  });
  let aleatorio = Math.random();
  // const history = useHistory();
  const [rotate, setRotate] = useState(false);
  // #1ABCD2
  let test = [1, 2, 3, 4, 5, 6, 7, 8, 8, 9, 9, 5];
  let buttons = [
    {
      button: <img alt="btn" src={OrdLupa}></img>,
    },
    {
      button: (
        <img
          alt="btn"
          style={{
            transform: !rotate && "rotate(180deg)",
          }}
          width={10}
          src={desplegarGris}
        ></img>
      ),
    },
  ];
  const renderTooltipAccept = (props) => <Tooltip {...props}>Aceptar</Tooltip>;
  const renderTooltipDeny = (props) => <Tooltip {...props}>Rechazar</Tooltip>;

  const titleCard = (e, i) => {
    return (
      <Row className={`py-3 ${tableStyles.ordGrayBorderBottom}`} key={i}>
        <Col xs={3} className="align-self-center">
          <div className="d-flex">
            <span className="pr-2">
              <b
                className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
              >
                Asesor: &nbsp;
              </b>
            </span>
            <span className={`text-secondary fw-normal`}>
              {e?.fullNameAdvisor}
            </span>
          </div>
        </Col>
        <Col xs className="align-self-center">
          <div className="d-flex">
            <span className="pr-2">
              <b
                className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
              >
                Fecha de solicitud: &nbsp;
              </b>
            </span>
            <span className={`text-secondary fw-normal`}>{e?.dateRequest}</span>
          </div>
        </Col>
        <div className="align-self-center w-auto">
          <ColorButton
            style={{ width: "100%", fontSize: "15px" }}
            text={
              e?.status === "pending"
                ? "Pendiente"
                : e?.status === "approved"
                  ? "Aprobado"
                  : "Rechazado"
            }
            variant={
              e?.status === "pending"
                ? "danger"
                : e?.status === "approved"
                  ? "success"
                  : "rejected"
            }
          />
        </div>
        {e?.status === "pending" && (
          <div className={`w-auto d-flex`} key={i}>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipDeny}
            >
              <div
                className={tableStyles.denyCardRed}
                onClick={() =>
                  rejectedMethod(
                    e?.idApp,
                    user,
                    e?.fullNamePatient,
                    e?.dateQuota,
                    "rejected"
                  )
                }
              >
                {" "}
                &nbsp; &nbsp;
              </div>
            </OverlayTrigger>
            &nbsp; &nbsp;
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipAccept}
            >
              <div
                className={tableStyles.checkCardGreen}
                onClick={() =>
                  methodUpdate(
                    e?.idApp,
                    user,
                    e?.fullNamePatient,
                    e?.dateQuota,
                    "approved"
                  )
                }
              >
                {" "}
                &nbsp; &nbsp;
              </div>

            </OverlayTrigger>
          </div>
        )}

      </Row>
    );
  };

  const bodyCard = (e, i) => {
    return (
      <>
        {/* #1 */}
        <Row className={`p-0`} key={i}>
          <Col xs={3} className="col-3 m-0 p-0">
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
              Médico
            </b>
          </Col>
          <Col xs={3}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
              Paciente
            </b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
              Identificación
            </b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
              Aseguradora
            </b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
              Contrato
            </b>
          </Col>
        </Row>
        {/* #2 */}
        <Row className={`p-0`}>
          <Col xs={3} className="col-3 m-0 p-0">
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {" "}
              {e?.fullNameDoctor}
            </span>
          </Col>
          <Col xs={3}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {e?.fullNamePatient}
            </span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {e?.patientDocument}
            </span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {e?.nameCorporateClient}
            </span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {e?.nameContract}
            </span>
          </Col>
        </Row>
        {/* #3 */}
        <Row className={`pt-2`}>
          <Col xs={3} className="col-3 m-0 p-0">
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
              Servicio
            </b>
          </Col>
          <Col xs={3}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
              Fecha del cupo
            </b>
          </Col>
        </Row>
        {/* #4 */}
        <Row className={`p-0`}>
          <Col xs={3} className="col-3 m-0 p-0">
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {" "}
              {e?.serviceName}
            </span>
          </Col>
          <Col xs={3}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {e?.dateQuota}
            </span>
          </Col>
        </Row>
        {/* #5 */}
        <Row className={`pt-2`}>
          <Col xs={12} className="col-3 m-0 p-0">
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
              Justificación
            </b>
          </Col>
        </Row>
        {/* #6 */}
        <Row className={`p-0`}>
          <Col xs={12} className="col-3 m-0 p-0">
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {e?.justify}
            </span>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <div className="w-90 mx-1 pt-3">
        {(ListAdditionalQuotasLoader || updateQuotasLoad) && (
          <Loader className="loading" type="Oval" color="#003f80" height={100} width={100} />
        )}
        {/* /* --------------------------- JUSTIFICATION MODAL -------------------------- */}
        <OrdModal
          title={"Justificación"}
          show={justification.showModal}
          btnYesName="Aceptar"
          btnNoName="Cancelar"
          size="500"
          btnYesEvent={() =>
            methodRejectedUpdate(
              justification.idApp,
              justification.idUser,
              justification.namePatient,
              justification.dateQuota,
              justification.typeProcess
            )
          }
          onHide={() => {
            setJustification({ showModal: false, justification: "" });
          }}
          btnNoEvent={() => {
            setJustification({ showModal: false, justification: "" });
          }}
        >
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="fName">
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <span className="text-start">&nbsp;Explique la razón del rechazo de cupo</span>
              </Form.Label>
              <textarea
                placeholder={"Escribir..."}
                value={justification.justification}
                onChange={(e) => {
                  setJustification({
                    ...justification,
                    justification: e.target.value,
                  });
                }}
                rows="25"
                cols="55"
                style={{ height: "7rem" }}
                className={`text-secondary ord-roundInput w-100`}
              ></textarea>
            </Form.Group>
          </Col>
        </OrdModal>

        <OrdGenericTemplate
          className="w-100"
          titleSize={6}
          colBtnSize={6}
          colbtnClass={"p-0 m-0"}
          title={"Cupos adicionales"}
        // buttons={filterStateBtns}
        >
          <div className="w-100 mx-1">
            <OrdSlideDownButton
              onToggleClick={() => setRotate(!rotate)}
              buttons={buttons}
              accordionClassName={`${ordComponentStyles.OrdSlideButton} mr-1`}
            >
              <Row className={`px-2`}>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Fecha solicitud desde</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput`}
                      type="date"
                      placeholder=""
                      onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Fecha solicitud hasta</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput`}
                      type="date"
                      placeholder=""
                      onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Servicio</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={optionsServices}
                      className="text-secondary"
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => setFilters({ ...filters, service: e.value })}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Estado</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={[
                        { key: "default", value: "", label: "Todos", id: "" },
                        { value: "pending", label: "Pendiente" },
                        { value: "approved", label: "Aprobado" },
                        { value: "rejected", label: "Rechazado" },
                      ]}
                      className="text-secondary"
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => setFilters({ ...filters, status: e.value })}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Médico</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={optionsDoctors}
                      className="text-secondary"
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => setFilters({ ...filters, idDoctor: e.value })}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Asesor</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={optionsAdvisor}
                      className="text-secondary"
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      onChange={(e) => setFilters({ ...filters, idAdvisor: e.value })}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  {/* <div class="input-group">
                                    <input class="form-control border-right-0" />
                                    <span class="input-group-append bg-white border-left-0">
                                        <span class="input-group-text bg-transparent">
                                            <img
                                                src={OrdLupa}
                                            >
                                            </img>
                                        </span>
                                    </span>
                                </div> */}
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
                    <div className={`d-flex`} style={{ gap: "1rem" }}>
                      <Form.Control
                        className={tableStyles.btn_search}
                        type="text"
                        autoComplete="off"
                        placeholder="Nombre o ID del paciente"
                        onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setTrigger(trigger + 1);
                          }
                        }}
                      />
                      <img src={ordBlueSearch} width={19} alt="buscar" className="cursorPointer" onClick={() => setTrigger(trigger + 1)} />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </OrdSlideDownButton>
            {ListAdditionalQuotas?.results?.length > 0 &&
              ListAdditionalQuotas?.results?.map((e, i) => {
                return <OrdCard className="my-3" title={titleCard(e, i)} body={bodyCard(e, i)}></OrdCard>;
              })}
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
};
export default AdditionalQuota;
