import React from 'react'
import styled from "styled-components";
import { Button, Text } from '../../atoms';
import { useTheme } from "styled-components";
import { Box } from '@mui/material';


const StyledBoxFooter = styled.div`
    padding: ${({ padding }) => padding ? padding : '0 5em 0 0'};
    left: ${({ left }) => left ? left : '0'};
    display: ${({ display }) => display ? display : 'flex'};
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'end'};
    height: ${({ height }) => height ? height : '100px'};
    background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'white'};
    width: ${({ width }) => width ? width : 'calc(100% - 5.3em)'};
    position: ${({ position }) => position ? position : 'absolute'};
    border-top:  ${({ borderTop, theme }) => borderTop ? borderTop : `1px solid ${theme.colors.ordAquaMarine}`};
    column-gap: ${({ columnGap }) => columnGap ? columnGap : '15px'};
    align-items: ${({ alignItems }) => alignItems ? alignItems : 'center'};
    margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '0.3em'};
    bottom: 0;
    
`


export const BoxFooter = ({
    classSaveColor,
    imgPathSave,
    imgHeight,
    onclickSave,
    onclickCancel,
    saveDisabled,
    orderSave,
    orderCancel,
    extraBtn,
    bgSave,
    widthSave,
    titleCancel,
    titleSave,
    left,
    className,
    padding,
    display,
    height,
    backgroundColor,
    width,
    margin,
    columnGap,
    borderTop,
    marginLeft,
}) => {

    const { colors } = useTheme()

    return (
        <StyledBoxFooter
            className={`${className} animate__animated animate__fadeIn`}
            padding={padding}
            margin={margin}
            columnGap={columnGap}
            width={width}
            left={left}
            borderTop={borderTop}
            marginLeft={marginLeft}
            backgroundColor={backgroundColor}
        >
            <Button
                color={colors.ordAquaMarine}
                colorHover='white'
                backgroundColorHover={colors.ordAquaMarine}
                border={`1px solid ${colors.ordAquaMarine}`}
                backgroundColor={'white'}
                order={orderCancel ? orderCancel : 0}
                padding='10px'
                onClick={onclickCancel}

            >
                {titleCancel ? titleCancel : 'Cancelar'}
            </Button>

            <Button
                backgroundColorHover={saveDisabled ? '#ccc' : 'auto'}
                backgroundColor={saveDisabled ? '#ccc' : bgSave}
                border={saveDisabled ? 'none' : 'auto'}
                cursor={saveDisabled ? 'initial' : 'pointer'}
                width={widthSave}
                disabled={saveDisabled}
                order={orderSave ? orderSave : 1}
                onClick={onclickSave}
                padding='10px'
            >
                <Box display='flex' columnGap={'5px'} justifyContent='center' alignItems={'center'}>
                    {titleSave ? <div>{titleSave}</div> : <span>Guardar</span>}

                    {imgPathSave &&
                        <img className={classSaveColor} src={imgPathSave} height={imgHeight} alt="" />
                    }
                </Box>

            </Button>

            {
                extraBtn?.map((el, i) => (
                    <Button
                        className={`d-flex gap-2 align-items-center justify-content-center ${el?.className}` || ''}
                        padding='10px'
                        key={i}
                        order={el?.order}
                        backgroundColor={el?.disabled ? '#ccc' : el.bg}
                        width={el?.width}
                        onClick={el?.onClick}
                        backgroundColorHover={el?.disabled ? '#ccc' : el?.backgroundColorHover}
                        border={el?.disabled ? '#ccc' : el?.border}
                        opacityHover={el?.opacityHover}
                        imgHeight={el?.imgHeight}
                        disabled={el?.disabled}
                        cursor={el?.disabled ? 'initial' : 'pointer'}
                    >

                        {el?.title ? <div>{el?.title}</div> : ''}

                        {
                            el?.imgPath &&
                            <img className={el?.classNameImg} src={el?.imgPath} height={el?.imgHeight} alt="" />
                        }
                    </Button>
                ))
            }
        </StyledBoxFooter >
    )
}
