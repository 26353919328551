import React, { Component, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Lupa from "../../assets/img/icons/lupa.svg";
import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg"
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Pagination from "react-js-pagination";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Tooltip } from '@material-ui/core';
import { Button, Col, Form, Row } from "react-bootstrap";
import ModalNew from "../../components/Layouts/ModalNew";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import { useHistory } from "react-router-dom";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import { message } from "../../helpers/helpers";

import {
    getListCompanies,
    getHistoryStatus,
    getHistory,
    getReceiptRecord,
} from "../../actions/billingActions";
import {
    getAccountable,
} from "../../actions/receiptOfInvoicesActions"
import BillingCertificateDetails from "./BillingCertificateDetails";
import { getPdfTest } from "../../actions/consultAction";
import { Title } from "../../shared";

var formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
});

function BillingCertificate(props) {
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const history = useHistory();
    const token = counter.loginReducer.Authorization;
    const [trigger, setTrigger] = useState(0);

    const [detail, setDetail] = useState({ show: false });

    const [filters, setFilters] = useState({
        eaccount: counter.loginReducer.currentAccount.id,
        page: 1,
        perpage: 10,
        //created_by: counter.loginReducer.user_data.id,
    });

    const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "billingRec");


    useEffect(() => {
        if (!myPermission?.read || !myPermission?.create) {
            history.push("/categoryselect");
        }
        dispatch(
            getListCompanies({
                eaccount: counter.loginReducer.currentAccount.id,
            })
        );
        dispatch(
            getHistoryStatus({
                type: "receiptMin",
                eaccount: counter.loginReducer.currentAccount.id,
            })
        );
        dispatch(
            getAccountable({ type: "receiptMinute", eaccount: counter.loginReducer.currentAccount.id })
        );
    }, []);
    const [showPdf, setShowPdf] = useState(false)
    const [base64, setBase64] = useState("");
    const getPdf = async (id) => {
        const result = await getPdfTest(
            {
                id: id,
                doc: 17,
            },
            token
        );
        if (result?.success) {
            return setBase64(result.pdf[0].key.split("'")[1]);
        } else {
            message(
                "error",
                "Ha ocurrido un error",
                "No ha sido posible cargar el documento"
            );
        }
    };
    // useEffect(()=>{
    //     if(!!filters.client){
    //         dispatch( 
    //             getListAcountable({
    //                     client: filters.client,
    //                     eaccount: counter.loginReducer.currentAccount.id,
    //             })
    //         );
    //     }
    // }, [filters.client]);

    useEffect(() => {
        dispatch(
            getReceiptRecord(filters)
        );
    }, [trigger]);

    let optionCompanies = [{ key: "default", value: "", label: "Seleccionar...", id: "" }];
    if (Array.isArray(counter.billingReducer.companies)) {
        counter.billingReducer.companies.map((item) => {
            optionCompanies.push({
                key: item.id,
                value: item.id,
                label: item.copyName,

                id: item.id,
                companyType: item.companyType,
                copyName: item.copyName,
                notification_email: item.notification_email,
                Nit: item.Nit,
                address: item.address,
                phone: item.phone,
                email: item.email,
                cityId: item.cityId,
                city: item.city,
            })
        });
    }

    let optionAcountable = [{ key: "default", value: "", label: "Seleccionar...", id: "", name: "" }];
    if (Array.isArray(counter.invoiceReducer.accountables)) {
        counter.invoiceReducer.accountables.map((item) => {
            optionAcountable.push({
                key: item.id,
                value: item.id,
                label: item.name,

                id: item.id,
                name: item.name,
            })
        });
    }

    let optionStatus = [{ key: "default", value: "", label: "Seleccionar...", name: "" }];
    if (Array.isArray(counter.billingReducer.billing_history_status)) {
        counter.billingReducer.billing_history_status.map((item) => {
            optionStatus.push({
                key: item.id,
                value: item.id,
                label: item.value,

                id: item.id,
                name: item.name,
                value: item.value,
                prefix: item.prefix,
                color_id: item.color_id,
                background: item.background,
                fontcolor: item.fontcolor,
            })
        });
    }

    const renderList = () => {
        let tempList = [];
        if (Array.isArray(counter.billingReducer.receipt_record)) {
            counter.billingReducer.receipt_record.map((item, index) => {
                tempList.push(
                    <tr key={index}>
                        <td className="text-center">{item.sequence}</td>
                        <td className="text-center">{convertDateToLatinFormat(item.exp_date)}</td>
                        <td className="text-center">{item.clientNit}</td>
                        <td className="text-center">{item.clientName}</td>
                        <td className="text-center">{item.generatorFullName}</td>
                        <td className="text-center">{item.total_amount ? formatter.format(item.total_amount) : "-"}</td>
                        <td className="text-center p-2">
                            <Tooltip title={item.status}>
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        margin: "0 auto",
                                        padding: '5px 10px',
                                        width: '130px',
                                        borderRadius: "10px",
                                        backgroundColor: item.status === 'Recibido' ? '#FAFDF6' : '#f2c94c12',
                                        color: item.status === 'Recibido' ? '#83C036' : '#f2c94c',
                                    }}
                                >{item.status}</div>
                            </Tooltip>
                        </td>
                        <td className="text-center px-2">
                            <img
                                src={lupaTransparent}
                                alt=""
                                srcset=""
                                className={tableStyles.cursorPointer}
                                // onClick={()=>setDetail({show:true, ...item})}
                                onClick={() => {
                                    getPdf(item.id)
                                    setShowPdf(true)
                                }}
                            />
                        </td>
                    </tr>
                );
            })
        }
        return tempList;
    }

    let i = 0
    const options = [
        { value: i += 1, label: 'Pepe' },
        { value: i += 1, label: 'Juancho' },
        { value: i += 1, label: 'Valentina' },
    ]

    const header3 = [

        <th className="text-center">No. Acta</th>,
        <th className="text-center">Fecha factura</th>,
        <th className="text-center">NIT</th>,
        <th className="text-center">Empresa</th>,
        <th className="text-center">Responsable</th>,
        <th className="text-center">Valor total</th>,
        <th className="text-center">Estado</th>,
        <th className="text-center"></th>
    ]
    const body3 = [
        <tr>
            <td className="text-center">AF0256301 </td>
            <td className="text-center">01/06/2018 </td>
            <td className="text-center">11050501</td>
            <td className="text-center">Sanitas S.A</td>
            <td className="text-center">Andrea Gonzales</td>
            <td className="text-center">$ 7.000.000</td>
            <td className="text-center">-</td>
            <td className="text-center"><img src={Lupa} alt="" srcset="" /></td>
        </tr>
    ]
    const handleSearch = (e) => {
        e.preventDefault();
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
    };

    const returnDefault = (<>
        <div 
            className={` ml-5 ${tableStyles.container}`} 
            style={{ marginRight: "3rem", marginTop:'30px' }}
        >
            <Title
                title="Actas de facturación"
                className={'mb-2'}
                onClickIcon={() => {
                    history.push('/facturacion/inicio')
                }}
            />

            <div>
                <div className="div gap-3 align-items-end">
                    <div className={`display-grid ${tableStyles.containerSelect}`}>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <label className={tableStyles.crudModalLabel}>No. Acta</label>
                            <input
                                placeholder="Escribir..."
                                className={IndividualStyles.registerInputs}
                                type="text"
                                value={filters.minuteNumber}
                                onChange={e => setFilters({ ...filters, minuteNumber: e.target.value })}
                            />
                        </form>
                    </div>
                    <div className={`display-grid ${tableStyles.containerSelect}`}>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <label className={tableStyles.crudModalLabel}>Empresa</label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                placeholder="Seleccionar..."
                                value={optionCompanies.find(option => option.id == filters.client)}
                                onChange={(e) => setFilters({
                                    ...filters,
                                    client: e.id,
                                })}
                                options={optionCompanies}
                                styles={customSelectNewDark}
                            />
                        </form>
                    </div>
                    <div className={`display-grid ${tableStyles.containerSelect}`}>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <label className={tableStyles.crudModalLabel}>Responsable</label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                value={optionAcountable.find(option => option.name == filters.accountable)}
                                onChange={(e) => setFilters({
                                    ...filters,
                                    accountable: e.name,
                                })}
                                options={optionAcountable}
                                placeholder="Seleccionar..."
                                styles={customSelectNewDark}
                            />
                        </form>
                    </div>
                    <div className={`display-grid ${tableStyles.containerSelect}`}>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <label className={tableStyles.crudModalLabel}>Estado</label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                value={optionStatus.find(option => option.name === filters.status)}
                                onChange={(e) => setFilters({
                                    ...filters,
                                    status: e.name,
                                })}
                                options={optionStatus}
                                placeholder="Seleccionar..."
                                styles={customSelectNewDark}
                            />
                        </form>
                    </div>
                    <div style={{ alignSelf: 'end' }} className={`display-grid ${tableStyles.containerSelect}`}>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <i><img
                                className={`mt-4 ${tableStyles.cursorPointer}`}
                                style={{ width: "25px" }}
                                src={Lupa}
                                alt=""
                                srcset=""
                                onClick={e => { setTrigger(trigger + 1); setFilters({ ...filters, page: 1 }); }}
                            /></i>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <GenericTable
                    headers={header3}
                    children={renderList()}
                    dark={true}
                >
                </GenericTable>
            </div>
            {(counter.billingReducer.receipt_record_loading) &&
                <div className="mx-auto">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            }

            <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                    Pag. {counter.billingReducer.receipt_record_total ? filters.page : ""}
                    {" de "}
                    {Math.ceil(counter.billingReducer.receipt_record_total / filters.perpage)
                        ? Math.ceil(counter.billingReducer.receipt_record_total / filters.perpage)
                        : ""}{" "}
                    ({counter.billingReducer.receipt_record_total} encontrados)
                </p>
                <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.perpage}
                    totalItemsCount={counter.billingReducer.receipt_record_total}
                    pageRangeDisplayed={5}
                    onChange={val => { setTrigger(trigger + 1); setFilters({ ...filters, page: val }) }}
                    itemClassPrev={`${paginationStyles.itemClassPrev} `}
                    itemClassNext={`${paginationStyles.itemClassNext} `}
                    itemClassFirst={`${paginationStyles.itemClassFirst} `}
                    itemClassLast={`${paginationStyles.itemClassLast} `}
                    itemClass={paginationStyles.itemClass}
                />
            </div>
        </div>
        <ModalNew
            title="Detalle de acta"
            show={showPdf}
            btnNoName={"Cancelar"}
            size="700"
            btnYesDisabled={false}
            onHide={() => setShowPdf(false)}
            btnNoEvent={() => setShowPdf(false)}
            btnNoDisabled={false}
        >
            <PdfViewer
                downloadable
                file={`data:application/pdf;base64,${base64}`}></PdfViewer>
        </ModalNew>

    </>);

    const returnDetails = <BillingCertificateDetails
        detail={detail}
        setDetail={setDetail}
        goBack={() => setDetail({ show: false })}
    />

    return detail.show ? returnDetails : returnDefault;

};
export default BillingCertificate;