import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import styles from "../../components/Layouts/tableStyle.module.scss";
import useCalendarBlockTextarea from '../../Hooks/useCalendarBlockTextarea';

const BlockAgenda = () => {

    const { setBlockTextarea } = useCalendarBlockTextarea()

    const [comment, setComment] = useState('')

    return (
        <Form.Group className="mb-3 mt-2" >
            <Form.Label className={`m-0 ${styles.ordDarkBlueText} `}>
                <b className="text-start">&nbsp;Justificación del bloqueo*</b>
            </Form.Label>
            <textarea
                value={comment}
                placeholder={"Escribir..."}
                rows="40"
                cols="50"
                style={{ height: "10rem", resize: 'none' }}
                className={`text-secondary ord-roundInput w-100 ${styles.outlineNone}`}
                // key={trigger + "observations"}
                onChange={(e) => {
                    setComment(e.target.value)
                }}
                onBlur={() => {
                    setBlockTextarea(comment)
                }}
            ></textarea>
        </Form.Group>


    )
}



export default BlockAgenda