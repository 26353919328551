import AWS from 'aws-sdk';
import { useState } from 'react';
import { generateId, getEnvs, isEmptyOrUndefined } from '../helpers';

export const useHandleUploadFileAws = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [results, setResults] = useState({ success: false })

    const handleUploadAws = async (props) => {

        const bodyS3 = props?.body, bucketNameS3 = props?.bucketName, keyBodyS3 = props?.keyBody, locationS3 = props?.location

        if (isEmptyOrUndefined(bodyS3)) {
            console.error('Parameter body is required')
            return
        };

        if (isEmptyOrUndefined(locationS3)) {
            console.error('Parameter locations is required')
            return
        };

        if (isEmptyOrUndefined(bucketNameS3)) {
            console.error('Parameter bucketName is required');
            return
        }

        if (isEmptyOrUndefined(keyBodyS3)) {
            console.error('Parameter keyBody is required')
            return
        };

        const { ACCESS_KEY_ID_AWS, SECRET_ACCESS_KEY_AWS, REGION_AWS } = getEnvs()

        // S3 Bucket Name
        const S3_BUCKET = bucketNameS3;

        // S3 Region
        const REGION = REGION_AWS;

        // S3 Credentials
        AWS.config.update({
            accessKeyId: ACCESS_KEY_ID_AWS,
            secretAccessKey: SECRET_ACCESS_KEY_AWS,
        });

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET, },
            region: REGION,
        });

        // Files Parameters
        const params = {
            Bucket: S3_BUCKET,
            Key: `${locationS3}${generateId(10)}_${keyBodyS3}`,
            Body: bodyS3,
        };

        setIsLoading(true)

        let upload = s3.upload(params).promise()

        const res = await upload.then((res, data) => {

            setResults({ success: true, url: res.Location });
            res = { success: true, url: res.Location }
            return res

        }).finally(() => {

            setIsLoading(false)

        }).catch((err) => {
            setResults({ success: false })
            console.error('Error al cargar el archivo en S3:', err);
            return { success: false, message: `Error al cargar el archivo en S3: ${err}`}
        })

        return res
    }

    return {
        isLoading,
        results,
        trigger: handleUploadAws
    }

}