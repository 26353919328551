import GenericTableNew from "../Layouts/GenericTableNew";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import paginationStyles from "../Layouts/pagination.module.scss";
import { Checkbox, Tooltip } from "@material-ui/core";
import tableStyles from "../Layouts/tableStyle.module.scss";
import registerWarehouseStyles from "./registerWarehouse.module.scss";
import Back from "../../assets/img/icons/backIcon.svg";
import Watch2 from "../../assets/img/icons/Reloj.svg";
import Watch from "../../assets/img/icons/watch.svg";
import { Button } from "react-bootstrap";
import ModalNew from "../Layouts/ModalNew";
import RoundCheck from "../../assets/img/icons/RoundCheck.svg";
import {
  get_purchaseOrder_Articles_inWarehouse,
  get_purchaseOrder_Programming_inWarehouse,
} from "../../actions/warehouseActions";
import RegisterEntry from "./RegisterEntryByWarehouse";

function payConditions(key) {
  switch (key) {
    case "prepayment":
      return "Pago anticipado";
    case "delivery":
      return "Pago contraentrega";
    case "pay20":
      return "Pago a 20 días";
    case "pay30":
      return "Pago a 30 días";
    case "pay60":
      return "Pago a 60 días";
    case "pay90":
      return "Pago a 90 días";
    default:
      return "-";
  }
}
function DetailPurchaseOrder(props) {
  const counter = useSelector((state) => state);  
  const dispatch = useDispatch();
  const [registerEntry, setregisterEntry] = useState({
    show: false,
  });
  const [check, setcheck] = useState({
    checked: false,
  });
  const [modalProgramming, setmodalProgramming] = useState({
    show: false,
  });

  useEffect(() => {
    dispatch(
      get_purchaseOrder_Articles_inWarehouse({
        idPO: props.selectedPurchaseOrder.id,
      })
    );
  }, []);

  const [filterProg, setfilterProg] = useState({
    page:1,
    perpage:10,
    idprog:""
  }); 

  const ButtonModalProg = (x) => {
    dispatch(
    get_purchaseOrder_Programming_inWarehouse({page:filterProg.page,perpage:filterProg.perpage,idprog:x.id}))
    setmodalProgramming({
      ...modalProgramming,
      show: true,
      articleSelected: x,
    });
  };
  const [articleChanges, setArticleChanges] = useState([]);
  const [articlesSelectedProg, setarticlesSelectedProg] = useState([]);
  const [articlesFixedDeliveries, setarticlesFixedDeliveries]=useState([]);

  const articleCheckHandle = (articleProgramming) => {
    let articleChangesTemp = articleChanges;
    let exists = articleChangesTemp.find((x) => x.id === articleProgramming.id);
    if (exists) {
      articleChangesTemp = articleChangesTemp.filter((x) => x !== exists);
    } else {
      articleChangesTemp.push(articleProgramming);
    }
    setArticleChanges(articleChangesTemp);
  };
  const qtyAuction = (mylist) => {
    let valval = 0;
    if (Array.isArray(mylist)) {
      valval = mylist.reduce((total, currentValue) => {
        if (!isNaN(currentValue.qty_article)) {
          return total + currentValue.qty_article;
        } else {
          return total;
        }
      }, 0);
    }
    return valval;
  };

  

  const selectedProgrammins = (article,id_article) => {
    let articleTemp = articleChanges;
    articleTemp = articleTemp.filter((x) => x.id_article === id_article);
    let selected = article;
    selected['amount_received']=qtyAuction(articleTemp);
    selected['programmings']=articleChanges;
    setarticlesSelectedProg(selected);
    setmodalProgramming({
      ...modalProgramming,
      show: false,
    });

    // let articleTemp=article
    // articleTemp = articleTemp.filter((x) => x.id_article === id_article);
    // let selected = articlesSelectedProg;
    // selected = selected.filter((x) => x.id_article !== id_article);
    // selected.push({ amount: qtyAuction(articleTemp), id_article, });
    // setmodalProgramming({
    //     ...modalProgramming,
    //     show: false,
    // });


  };

  const cleanArticlesProg = (id) => {
    let articleTemp = articleChanges;
    articleTemp = articleTemp.filter((x) => x.id_article === id);
    setArticleChanges(articleTemp);
    setmodalProgramming({
      ...modalProgramming,
      show: false,
    });
  };


  const [checkHandle, setcheckHandle] = useState({
      checked:false
  });

  const checkHandleEntry= ()=>{
      if (checkHandle.checked){
        setcheckHandle({checked:false})
      }else{
        setcheckHandle({checked:true})
      }
  }

  const buttonOk=()=>{
    if (checkHandle.checked){
        let articleTemp= counter.entryWarehouseReducer.articles.filter((x) => x.deliverys === 1 && x.status === "pending");
        setarticlesFixedDeliveries(articleTemp)
    }
    setregisterEntry({show:true})
  }

  // const buttonCancel=()=>{
  //   if (checkHandle.checked){
  //       let articleTemp= counter.entryWarehouseReducer.articles.filter((x) => x.deliverys === 1);
  //       setarticlesFixedDeliveries(articleTemp)
  //   }
  //   setregisterEntry({show:true})
  // }

  

  const renderFixedDeliveryItems = [
    <th key={`RW1`} className="px-2 text-start">Entrega</th>,
    <th key={`RW2`} className="px-2 text-center">Item</th>,
    <th key={`RW3`} className="px-2 text-center">Código</th>,
    <th key={`RW4`}>Descripción de Artículo</th>,
    <th key={`RW5`} className="px-2 text-center">Cant. Ordenada</th>,
  ];

  const renderArticlesFixed = () => {
    let table = [];
    let index = 0;
    if (Array.isArray(counter.entryWarehouseReducer.articles)) {
      table = counter.entryWarehouseReducer.articles.map((x, realindex) => {
        if (x.deliverys === 1) {
          index += 1;
          return (
            <tr
              key={"renderArticlesFixed" + realindex}
              className="hover-table-row"
            >
              <td>
                <div className={`${registerWarehouseStyles.iconsIntoTable}`}>
                  {x.pendings > 1 ? <img src={Watch2} /> : <img src={Watch} />}

                  <Tooltip
                    title={
                      x.status === "pending" ? "Entrega pendiente" : "Entregado"
                    }
                  >
                    <div
                      className={`
                                            ${
                                              registerWarehouseStyles.popupReqActive
                                            } ${
                        x.status === "pending" && !checkHandle.checked
                          ? registerWarehouseStyles.alertTriangle
                          : registerWarehouseStyles.greenCheck}
                      }
                        `}
                    ></div>
                  </Tooltip>
                </div>
              </td>
              <td>{index + 1}</td>
              <td>{x.id_article}</td>
              <td className="text-start">{x.description}</td>
              <td>{x.qty_article}</td>
            </tr>
          );
        }
      });
    }
    return table;
  };

  const renderDeliveryItems = [
    <th key={`RW1`} className="px-2 text-start">Entrega</th>,
    <th key={`RW2`}>Item</th>,
    <th key={`RW3`}>Código</th>,
    <th key={`RW4`}>Descripción de Artículo</th>,
    <th key={`RW5`}>Cant. a recibir</th>,
  ];
  const renderArticlesPartial = () => {
    let table = [];
    let index = 0;
    // let ids_articles = [];
    if (Array.isArray(counter.entryWarehouseReducer.articles)) {
      table = counter.entryWarehouseReducer.articles.map((x, realindex) => {
        if (x.deliverys > 1) {
          // let found = ids_articles.includes(x.id_article);
          // if (!!found) {
            index += 1;
            return (
              <tr
                key={"renderArticlesPartial" + realindex}
                className="hover-table-row"
              >
                <td>
                  <div className= {`${registerWarehouseStyles.iconsIntoTable}`}>
                    {x.pendings > 1 ? (
                      <img src={Watch2} onClick={() => ButtonModalProg(x)} />
                    ) : (
                      <img src={Watch} onClick={() => ButtonModalProg(x)} />
                    )}

                    <Tooltip
                      title={
                        x.status === "pending"
                          ? "Entrega pendiente"
                          : "Entregado"
                      }
                    >
                      <div
                        className={`${registerWarehouseStyles.popupReqActive} ${x.status === "pending"
                            ? registerWarehouseStyles.alertTriangle
                            : registerWarehouseStyles.greenCheck}
                        `}
                      ></div>
                    </Tooltip>
                  </div>
                </td>
                <td>{index}</td>
                <td>{x.id_article}</td>
                <td className="text-start">{x.description}</td>
                <td>
                  {x.amount_received? x.amount_received: "-"}
                  {/* {foundAmountEntry(x.id_article)} */}
                </td>
              </tr>
            );
          // }
        }
        // ids_articles.push(x.id_article);
      });
    }
    return table;
  };

  const renderProgrammings = [
    <th key={`PR1`}>#</th>,
    <th key={`PR2`}>Fecha</th>,
    <th key={`PR3`}>Cantidad</th>,
    <th key={`PR4`}></th>,
  ];

  const [renderProgrammingsItems] = useMemo(() => {
    let table = [];
    if (Array.isArray(counter.entryWarehouseReducer.programmings)) {
      table = counter.entryWarehouseReducer.programmings.map((x, realindex) => {
        if (x.id_article === modalProgramming.articleSelected?.id_article) {
          return (
            <tr
              key={"renderArticlesProgramming" + realindex}
              className="hover-table-row"
            >
              <td>
                {!!articleChanges.find((y) => y.id === x.id) ? (
                  <img
                    src={RoundCheck}
                    className={registerWarehouseStyles.filter}
                  ></img>
                ) : (
                  <img
                    src={RoundCheck}
                  ></img>
                )}
              </td>
              <td>{x.delivery_date}</td>
              <td>{x.qty_article}</td>
              <td>
                <input
                  className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
                  type="checkbox"
                  name={`selectedArt${x.article_id}`}
                  onChange={() => articleCheckHandle(x)}
                  defaultChecked={x.status === "solved" ? true:false}
                  checked={!!articleChanges.find((change) => change.id === x.id)}
                  disabled={x.status === "solved" ? true:false}
                />
              </td>
            </tr>
          );
        }
      });
    }
    return [table];
  }, [
    counter.entryWarehouseReducer.programmings,
    modalProgramming.articleSelected?.id_article,
    articleCheckHandle,
    articleChanges
  ]);

  //render ___________________________________________________________________________________________

  const  renderDatils=<div className={tableStyles.container}>
      <div className={`d-flex ${registerWarehouseStyles.titleBlock}`}>
        <img
          alt = "back"
          src={Back}
          className={registerWarehouseStyles.backIcon}
          onClick={() => props.setDetails({ show: false })}
        />
        <h1 className={registerWarehouseStyles.mainTitle}>
          {"Selección de entregas"}
        </h1>
      </div>

      <div className={` ${registerWarehouseStyles.bigContainer}`}>
        <div className={`${registerWarehouseStyles.container2}`}>
          <div className={`d-flex`}>
            <div
              className={`d-flex ${registerWarehouseStyles.underMainTitle} ${registerWarehouseStyles.f1}`}
            >
              <p>
                <span className={registerWarehouseStyles.blueText}>
                  Orden de compra{" "}
                  {props.selectedPurchaseOrder.purchaseOrder_number}
                </span>
                &ensp;&ensp;
              </p>
            </div>
          </div>
        </div>

        {/* information */}

        <div className={registerWarehouseStyles.container2}>
          {/* row 1 */}
          <div className={`d-flex`}>
            <div
              className={`${registerWarehouseStyles.inputMargin}  ${registerWarehouseStyles.f2}`}
            >
              <p className={registerWarehouseStyles.crudModalLabel}>
                Proveedor
              </p>
              <input
                className={`register-inputs  ${registerWarehouseStyles.background}`}
                type="text"
                value={props.selectedPurchaseOrder.name_provider}
                readOnly
                placeholder="Escribir..."
              />
            </div>

            <div
              className={`${registerWarehouseStyles.inputMargin}  ${registerWarehouseStyles.f1}`}
            >
              <p className={registerWarehouseStyles.crudModalLabel}>NIT</p>
              <input
                className={`register-inputs ${registerWarehouseStyles.background}`}
                type="text"
                value={props.selectedPurchaseOrder.nit}
                readOnly
                placeholder="Escribir..."
              />
            </div>

            <div
              className={`${registerWarehouseStyles.inputMargin}  ${registerWarehouseStyles.f1}`}
            >
              <p className={registerWarehouseStyles.crudModalLabel}>
                Condiciones de pago
              </p>
              <input
                className={`register-inputs ${registerWarehouseStyles.background}`}
                type="text"
                value={payConditions(
                  props.selectedPurchaseOrder.pay_conditions
                )}
                readOnly
                placeholder="Escribir..."
              />
            </div>
          </div>

          {/* row 2 */}
          <div className={`d-flex`}>
            <div
              className={`${registerWarehouseStyles.inputMargin}  ${registerWarehouseStyles.f2}`}
            >
              <p className={registerWarehouseStyles.crudModalLabel}>
                Términos y condiciones
              </p>
              <input
                className={`register-inputs ${registerWarehouseStyles.background}`}
                type="text"
                value={props.selectedPurchaseOrder.observations}
                readOnly
                placeholder="Escribir..."
              />
            </div>

            <div
              className={`${registerWarehouseStyles.inputMargin}  ${registerWarehouseStyles.f1}`}
            >
              <p className={registerWarehouseStyles.crudModalLabel}>Almacén</p>
              <input
                className={`register-inputs ${registerWarehouseStyles.background}`}
                type="text"
                value={props.selectedPurchaseOrder.address}
                readOnly
                placeholder="Escribir..."
              />
            </div>

            <div className={` ${registerWarehouseStyles.inputMargin}  ${registerWarehouseStyles.f1} ${registerWarehouseStyles.ItemMedium} ${registerWarehouseStyles.ItemCheckbox} `}>
              {/* <div className={`${registerWarehouseStyles.ItemCheckbox}`}>
                <div className={tableStyles.myCheckbox}> */}
                  <input
                    key={"urgentbox" + Math.random()}
                    className={tableStyles.myCheckboxInput}
                    type="checkbox"
                    name="inputDonate"
                    defaultChecked={ props.selectedPurchaseOrder?.deliverys>1? true: false}
                  />
                  <label
                    className={`${tableStyles.myCheckboxLabel} ${registerWarehouseStyles.urgente}`}
                    htmlFor="urgent"
                  >
                    Contiene entregas parciales
                  </label>
                {/* </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* fixed delivery items*/}
        <div className={registerWarehouseStyles.contentInputs}>
          <p className={registerWarehouseStyles.sectionTitle}>
            {"Artículos de entrega fija > " +
              props.selectedPurchaseOrder.delivery_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1') + " >"}
          </p>
          <input
                  className={`form-check-input ${tableStyles.myCheckboxInput} ${registerWarehouseStyles.marginTop}`}
                  type="checkbox"
                  name={`entrys`}
                  onChange={checkHandleEntry}
                  //checked={!!articleChanges.find((change) => change.id === x.id)}
            />
            <p className={registerWarehouseStyles.sectionTitleGrey}>Recibir</p>
        </div>

        <GenericTableNew dark={true} headers={renderFixedDeliveryItems}>
          {renderArticlesFixed()}
        </GenericTableNew>

        {/* line */}
        <div className={`${registerWarehouseStyles.line}`}> </div>

        <p className={registerWarehouseStyles.sectionTitleClearBlue}>
          Artículos con entregas parciales{" "}
        </p>

        <GenericTableNew headers={renderDeliveryItems}>
          {renderArticlesPartial()}
        </GenericTableNew>

        <div className={registerWarehouseStyles.bottomRow}>
          <Button
            className={tableStyles.btnPrimary}
            onClick={()=>buttonOk()}
            //disabled={buttonSendDisabled()}
          >
            Guardar
          </Button>
          <Button
            className={`${tableStyles.btnSecondary} ${registerWarehouseStyles.inputMargin}`}
            onClick={() => props.setDetails({ show: false })}
          >
            Cancelar
          </Button>
        </div>
      </div>

      <ModalNew
        title={"Entregas parciales"}
        show={modalProgramming.show}
        btnYesName={"Seleccionar"}
        btnYesEvent={() =>
          selectedProgrammins(
            // modalProgramming.articleSelected?.id_article,
            // modalProgramming.articleSelected?.id,
            modalProgramming.articleSelected,
            modalProgramming.articleSelected?.id_article
          )
        }
        hideCancelButton={true}
        size="sz"
        onHide={() =>cleanArticlesProg(modalProgramming.articleSelected?.id_article)}
        btnYesDisabled={!!articleChanges.length>0? false:true}
      >
        <div
          id="arriba"
          className={`${registerWarehouseStyles.backgroundModal} ${registerWarehouseStyles.paddingButton}`}
        >
          {/* ---------------------------- ROW 1 ---------------------------------- */}
          <div className="d-flex">
            <div
              className={` ${registerWarehouseStyles.inputMarginLR} ${registerWarehouseStyles.f2}`}
            >
              <p className={registerWarehouseStyles.crudModalLabel}>Producto</p>
              <input
                className={` register-inputs`}
                name="product"
                type="text"
                value={modalProgramming.articleSelected?.description}
                readOnly
                placeholder="Escribir..."
              />
            </div>

            <div
              className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.f1}`}
            >
              <p className={` ${registerWarehouseStyles.crudModalLabel}`}>
                Código
              </p>
              <input
                className={` register-inputs`}
                name="code"
                type="text"
                value={modalProgramming.articleSelected?.id_article}
                readOnly
                placeholder="Escribir..."
              />
            </div>
          </div>
          {/* ---------------------------- ROW 2 ---------------------------------- */}
          <div className="d-flex">
            <div
              className={`${registerWarehouseStyles.inputMarginLR} ${registerWarehouseStyles.f2}`}
            >
              <p className={registerWarehouseStyles.crudModalLabel}>
                Dirección de entrega
              </p>
              <input
                className={` register-inputs`}
                name="address"
                type="text"
                value={props.selectedPurchaseOrder?.address}
                readOnly
                placeholder="Escribir..."
              />
            </div>

            <div
              className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.f1}`}
            >
              <p className={` ${registerWarehouseStyles.crudModalLabel}`}>
                Cantidad
              </p>
              <input
                className={` register-inputs`}
                name="amount"
                type="text"
                value={modalProgramming.articleSelected?.qty_article}
                readOnly
                placeholder="Escribir..."
              />
            </div>
          </div>
        </div>
        <p className={registerWarehouseStyles.sectionTitle}>
          Programación solicitada
        </p>
        <GenericTableNew dark={false} headers={renderProgrammings}>
          {renderProgrammingsItems}
        </GenericTableNew>
      </ModalNew>
    </div>
    return (!!registerEntry.show)? 
        <RegisterEntry
        articlesFixed={articlesFixedDeliveries.concat(articlesSelectedProg)}
        show={registerEntry.show}
        // SetregisterEntryshow={setregisterEntry}
        poSelected={props.selectedPurchaseOrder}
        setSelected={props.setDetails}
        /> 
    : 
    renderDatils;
}

export default DetailPurchaseOrder;
