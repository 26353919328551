// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import GenericTable from "../Layouts/GenericTableNew";
import { Button, Col, Row } from "react-bootstrap";
import ModalNew from "../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
// --------------- icons 💥  ----------------//
import Lupa from "../../assets/img/icons/lupa.svg";
import Agregar from "../../assets/img/icons/add-check.svg";
import question from "../../assets/img/icons/question.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
//-------------- scss styles 😄   --------------
import CustomPopupExtend from "../Popup/customPopUpExtends";
import Select from "react-select";
import {
  customSelectNew,
  customSelectNewDark,
} from "../../components/Layouts/react-select-custom";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Styles from "./pettyCashStyles.module.scss";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import tableStylesScroll from "../Layouts/genericTableScroll.module.scss";

import * as TooltipOther from "@material-ui/core";

// ------------------ Functions ----------------------
import {
  getListTypeExpense,
  getOneTypeExpense,
  creatTypeExpense,
  changeStatusTypeExpense,
  updateTypeExpense,
} from "../../actions/accountingActions";

import { getNiifAccounts } from "../../actions/pucActions";
import { Title } from "../../shared";

function CostTypes(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);
  const [triggerModal, setTriggerModal] = useState(1);
  const [showModal, setShowModal] = useState({
    show: false,
  });

  const [creatType, setCreatType] = useState({
    description: "",
    niifAccount: "",
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const [info, setInfo] = useState({
    search: "",
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "CostTypes"
    );

  //--------------first functions 🍕   ----------

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Corresponde a la cuenta del P.U.C. a la que se le asignará al tipo de
      gasto
    </Tooltip>
  );

  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push("/contabilidad/inicio");
    }
  }, []);

  useEffect(() => {
    dispatch(getListTypeExpense(info));
  }, [trigger]);

  useEffect(() => {
    dispatch(
      getNiifAccounts({
        active: 1,
        eaccount: storage.loginReducer.currentAccount.id,
        uses_third: 1,
        uses_cost_center: 1,
      })
    );
  }, []);

  useEffect(() => {
    if (!!showModal.selectedType) {
      dispatch(
        getOneTypeExpense(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            id: showModal.selectedType,
          },
          setCreatType
        )
      );
    }
  }, [triggerModal]);

  //-------------- Table 🍕   --------------

  const header = [
    <div className={`col-3`}>No.Cuenta</div>,
    <div className={`col-3 text-start`}>Nombre</div>,
    <div className={`col-4 text-start`}>Descripción del gasto</div>,
    <div className={`col-1 `}>Estado</div>,
    <div className={`col-1`}>&nbsp;</div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingReducer.listTypesExpense)) {
      storage.accountingReducer.listTypesExpense.map((item, index) => {
        tempList.push(
          <section2 className={`d-flex`}>
            <div className="col-3">{item.niifAccountNbr}</div>
            <div className="col-3 text-start">{item.niifAccountName}</div>
            <div className="col-4 text-start">{item.description}</div>
            <div className="col-1">
              <TooltipOther.Tooltip title={item.statusName} arrow>
                {/* <div
                  className={`
                                        ${IndividualStyles.popupReqActive} ${item.statusName === "Deshabilitado"
                      ? tableStyles.circleRed
                      : tableStyles.circleGreen
                    }
                                    `}
                ></div> */}

                <div
                  className="rounded-pill p-1"
                  style={{
                    backgroundColor: item.statusName !== "Deshabilitado" ? "#FAFDF6" : "#FEF7F5",
                  }}
                >
                  <b style={{ color: item.statusName !== "Deshabilitado" ? "#83C036" : "#F39682" }}>{item.statusName !== "Deshabilitado"
                    ? "Habilitado"
                    : "Inhabilitado"}</b>
                </div>
              </TooltipOther.Tooltip>
            </div>
            <div className="col-1">
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                showEnable={myPermission()?.delete}
                isEnabled={item.statusName === "Habilitado"}
                enableClickEvent={() => changeStatus(item.id, item.statusName === 'Habilitado' ? 1 : 0, item.description)}
                showEdit={myPermission()?.edit}
                editClickEvent={() => {
                  setShowModal({
                    show: true,
                    edit: true,
                    selectedType: item.id,
                  });
                  setTriggerModal(triggerModal + 1);
                }}
              />
            </div>
          </section2>
        );
      });
    }
    return tempList;
  };

  //-------------- select options 🍕   --------------

  let optionsAccount = [{ key: "", value: "", label: "Seleccionar..." }];
  if (Array.isArray(storage.pucReducer.niifAccounts)) {
    storage.pucReducer.niifAccounts.map((item, index) => {
      optionsAccount.push({
        value: item.id,
        label: item.complete_account + " - " + item.description,
        key: index + "account",
      });
    });
  }

  //--------------  Actions  🍕   --------------

  const creatNewType = () => {
    if (showModal.creat) {
      dispatch(
        creatTypeExpense(
          {
            description: creatType.description,
            niifAccount: creatType.niifAccount,
            eaccount: storage.loginReducer.currentAccount.id,
          },
          () => {
            setShowModal({ show: false });
            dispatch(
              getListTypeExpense({
                eaccount: storage.loginReducer.currentAccount.id,
              })
            );
            setCreatType({
              description: "",
              niifAccount: "",
              eaccount: storage.loginReducer.currentAccount.id,
            });
          }
        )
      );
    } else {
      dispatch(
        updateTypeExpense(
          {
            description: creatType.description,
            niifAccount: creatType.niifAccount,
            eaccount: storage.loginReducer.currentAccount.id,
            id: showModal.selectedType,
          },
          () => {
            setShowModal({ show: false });
            dispatch(
              getListTypeExpense({
                eaccount: storage.loginReducer.currentAccount.id,
              })
            );
            setCreatType({
              description: "",
              niifAccount: "",
              eaccount: storage.loginReducer.currentAccount.id,
            });
          }
        )
      );
    }
  };

  const changeStatus = (id_activity, status, description) => {
    dispatch(
      changeStatusTypeExpense(
        { eaccount: storage.loginReducer.currentAccount.id, id: id_activity, status, description },
        () => {
          dispatch(
            getListTypeExpense({
              eaccount: storage.loginReducer.currentAccount.id,
            })
          );
        }
      )
    );
  };
  const handleSumit = (e) => {
    e.preventDefault();
    setTrigger(trigger + 1);
  }


  return (
    <>
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        {(storage.accountingReducer.expense_loading) &&
          <div className="loading">
            <Loader
              type="Oval"
              color="#003f80"
              height={100}
              width={100}
            />
          </div>
        }
        
        <div className={tableStyles.title}>
          <Title
            title="Configurar tipo de gasto"
            onClickIcon={() => {
              history.push('/contabilidad/inicio')
            }}
          />
        </div>

        <Row className="d-flex">
          <Col xs={7}>
            <div className="display-grid mt-1">
              <label
                className={`${tableStyles.crudModalLabel}`}
              >ㅤ</label>
              <form onSubmit={handleSumit}>
                <input
                  className={tableStyles.SearchNew}
                  placeholder="Buscar por No.cuenta, nombre o descripción del gasto..."
                  type="text"
                  onChange={(e) =>
                    setInfo({
                      ...info,
                      search: e.target.value,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1)
                    }
                  }}
                ></input>
              </form>
            </div>
          </Col>
          <Col xs={1}>
            <div className="display-grid mt-1">
              <label
                className={`${tableStyles.crudModalLabel}`}
              >ㅤ</label>
              <i>
                <img
                  src={Lupa}
                  onClick={() => setTrigger(trigger + 1)}
                  height="24px"
                  alt="icon"
                  className="cursorPointer"
                />
              </i>
            </div>
          </Col>

          {myPermission()?.create ? (
            <Col
              xs={4}
              className="text-end  d-flex align-self-end justify-content-end"
            // onClick={() => setShowModal({ show: true, creat: true })}
            >
              {/* <label
                className={Styles.bgBtn}
                style={{ marginTop: "20px", padding: "5px", width: "190px", cursor: "pointer" }}
              >
                {" "}
                Crear Tipo
                <img
                  className="ml-3 "
                  style={{ width: "18px" }}
                  src={Agregar}
                />
              </label> */}
              <div
                onClick={() => setShowModal({ show: true, creat: true })}
                className={tableStyles.createNomModule}
              >
                <b className={`mr-2`}>Crear tipo</b>
                <img
                  src={Agregar}
                  alt="User"
                />
                <div></div>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <div>
          <ModalNew
            show={showModal.show}
            onHide={() => {
              setShowModal({ show: false });
              setCreatType({
                description: "",
                niifAccount: "",
                eaccount: storage.loginReducer.currentAccount.id,
              });
            }}
            title={
              showModal.creat ? "Crear tipo de gasto" : "Editar tipo de gasto"
            }
            btnYesEvent={
              showModal.creat || showModal.edit ? () => creatNewType() : null
            }
            btnYesName={showModal.creat ? "Guardar" : "Editar"}
            btnYesDisabled={
              !!(creatType.description == "") || !!(creatType.niifAccount == "")
            }
            size={"400"}
          >
            <Row className="d-flex" style={{ marginButton: "7px" }}>
              <Col xs={12} >
                <p className={tableStyles.crudModalLabel}>
                  Descripción
                </p>
                <input
                  type="text"
                  name="name"
                  value={creatType.description}
                  style={{ color: "#58595B" }}
                  onChange={(e) =>
                    setCreatType({
                      ...creatType,
                      description: e.target.value,
                    })
                  }
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={!(showModal.edit || showModal.creat)}
                  placeholder="Escribir..."
                />
              </Col>
            </Row>
            <Row className="d-flex" >
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel} style={{
                  display
                    : "contents"
                }}>Cuenta</label>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip()}
                >
                  <img
                    src={question}
                    alt="icon"
                  ></img>
                </OverlayTrigger>

                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder="Seleccionar..."
                  key={"account" + trigger}
                  options={optionsAccount}
                  styles={customSelectNewDark}
                  value={optionsAccount.find(
                    (item) => item.value === Number(creatType.niifAccount)
                  )}
                  onChange={(e) =>
                    setCreatType({
                      ...creatType,
                      niifAccount: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>
          </ModalNew>
        </div>
        <GenericTableScroll
          headers={header}
          dark={true}
          body={renderList()}
          typeHead={"2"}
        >
          {/* {renderList()} */}
        </GenericTableScroll>
      </div>
    </>
  );
}
export default CostTypes;
