import React from "react";
import "../../../OrderingModule/orderingStyles.css";
import { PseudoSideBarAccordion } from "../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion";
import { useHistory } from "react-router-dom";
import { onCapitalLetter } from "../../../helpers/helpers";
import { useSelector } from "react-redux";
export const AssistanceHomeContent = () => {
  let history = useHistory();
  const { all_categories } = useSelector((state) => state?.loginReducer);
    const accountingModules = all_categories?.find((cat) => cat?.name === 'Asistencial')
    const { modules } = accountingModules
    const storage = useSelector((state) => state);

  // const titles = [
  //   {
  //     title: "Tablas Maestras de atención de citas",
  //     content: [
  //       {
  //         subtitle: "Listado de pacientes",
  //         action: () => history.push("/asistencial/ListadoPacientes"),
  //       },
  //       {
  //         subtitle: "Configuración de observaciones",
  //         action: () => history.push("/asistencial/configuracionObservaciones"),
  //       },
  //       {
  //         subtitle: "Configuración de importancias",
  //         action: () => history.push("/asistencial/ImportanciaPacientes"),
  //       },
  //       {
  //         subtitle: "Configuración de agendas",
  //         action: () => history.push("/asistencial/configuracionAgenda"),
  //       },
  //       {
  //         subtitle: "Asignación de recursos",
  //         action: () => history.push("/asistencial/AsignacionRecursos"),
  //       },
  //       {
  //         subtitle: "Configuración de combos de servicios",
  //         action: () => history.push("/asistencial/CombosServicios"),
  //       },
  //       {
  //         subtitle: "Motivos de anulación",
  //         action: () => history.push("/asistencial/MotivoAnulacion"),
  //       },
  //       {
  //         subtitle: "Grupos de atención",
  //         action: () => history.push("/asistencial/gruposDeAtencion"),
  //       },

  //     ],
  //   },

  //   {
  //     title: "Tablas Maestras de ordenamiento",
  //     content: [
  //       {
  //         subtitle: "Combos de cirugía",
  //         action: () => history.push("/asistencial/CombosDeCirugia"),
  //       },
  //       {
  //         subtitle: "Justificaciones",
  //         action: () => history.push("/asistencial/Justificaciones"),
  //       },
  //       {
  //         subtitle: "Medicamentos",
  //         action: () => history.push("/asistencial/medicamentos"),
  //       },
  //       {
  //         subtitle: "Motivos de cancelación de orden de medicamentos",
  //         action: () => history.push("/asistencial/MotivosCancelacionOrden"),
  //       },
  //     ],
  //   },
  //   {
  //     title: "Tablas Maestras de telemedicina",
  //     content: [
  //       {
  //         subtitle: "Especialidades",
  //         action: () => history.push("/asistencial/especialidades"),
  //       },
  //       {
  //         subtitle: "Síntomas",
  //         action: () => history.push("/asistencial/sintomas"),
  //       },
  //     ],
  //   },
  //   {
  //     title: "Tablas Maestras de historia clínica",
  //     content: [
  //       {
  //         subtitle: "Diagnósticos ampliados",
  //         action: () => alert("Diagnósticos ampliados"),
  //       },
  //       {
  //         subtitle: "Servicios médicos",
  //         action: () =>
  //           history.push("/asistencial/tmHistoriaClinica/serviciosMedicos"),
  //       },
  //     ],
  //   },
  //   {
  //     title: "Tablas Maestras de admisiones",
  //     content: [
  //       {
  //         subtitle: "Motivos de Anulación",
  //         action: () =>
  //           history.push("/asistencial/tmAdmisiones/motivosDeAnulacion"),
  //       },
  //       {
  //         subtitle: "Asignación de sedes",
  //         action: () =>
  //           history.push("/asistencial/tmAdmisiones/asignacionDeSedes"),
  //       },
  //       {
  //         subtitle: "Configuración",
  //         action: () =>
  //           history.push("/asistencial/tmAdmisiones/configuracion"),
  //       },
  //       {
  //         subtitle: "Listado de pacientes",
  //         action: () =>
  //           history.push("/asistencial/ListadoPacientes"),
  //       },
  //     ],
  //   },
  // ];
  const contentDynamic = modules?.map((module) => {
    const textJoin = onCapitalLetter({text:module?.name})
    let name = textJoin.replace("Tm", "TM")
    return {
        title: name,
        content: module?.functionalities?.map((subModule)=>{
            let subModuleName = onCapitalLetter({text: subModule?.name})
            let returnValue = <></>
                let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === subModule?.prefix
                  )?.read
                if(permissionValidation){
                    returnValue = { subtitle: subModuleName, action: () => history.push(subModule?.url) };
                  }
                return returnValue
        })
    }
})
  return (
    <div>
      {/* /* ----------------------------- PSEUDO-SIDEBAR--------------------------*/}
      {<PseudoSideBarAccordion accordionItems={contentDynamic} />}
    </div>
  );
};
