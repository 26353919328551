import { useHistory, useLocation } from "react-router";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { Box } from "@mui/material"
//
import backIcon from "../../../assets/img/icons/iconBack.svg"
//
import { customSwaltAlertAsistencial, loader } from "../../../helpers";
//
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import parse from 'html-react-parser';
//
import { Text, TextGrayInBrackets, TextGrayOnBrackets } from "../componentsConsents";
import { usePostMethod } from "../../../Hooks";
import { useEffect } from "react";

export const DetailConsent = () => {

    const { state } = useLocation();
    const history = useHistory()
    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const idAccount = store.loginReducer.currentAccount.id;
    const createdBy = store.loginReducer.user_data.id;
    // const currentAccount = store.loginReducer.currentAccount;
    const [pdfLoader, setPdfLoader] = useState(false)

    const [data, setData] = useState({
        dataTemporary: {
            nameTemp: state?.data?.name || '',
            codeTemp: state?.data?.code || '',
            versionTemp: state?.data?.version || '',
            effectiveDateTemp: state?.data?.validity || '',
        },
        httpData: {
            name: state?.data?.name || '',
            code: state?.data?.code || '',
            version: state?.data?.version || '',
            validity: state?.data?.validity || '',
            bodyOfText: {
                introduction: state?.data?.bodyOfText?.introduction || '',
                meaning: state?.data?.bodyOfText?.meaning || '',
                risks: state?.data?.bodyOfText?.risks || '',
                recomendations: state?.data?.bodyOfText?.recomendations || '',
                additionalComments: state?.data?.bodyOfText?.additionalComments || '',
                benefits: state?.data?.bodyOfText?.benefits || '',
                declarations: state?.data?.bodyOfText?.declarations || '',
                withdrawal: state?.data?.bodyOfText?.withdrawal || '',
            },
            revocationOfConsent: {
                revocation: state?.data?.revocationOfConsent?.revocation || '',
            },
            footer: state?.data?.footer
        },
        modal: false
    })


    const {
        load: loaderNewConsent,
        trigger: newConsent
    } = usePostMethod();


    const validationSave = () => {

        newConsent({
            token,
            url: "/medical/admissions/consents/",
            method: state?.action === 'save' ? 'POST' : "PUT",
            body: {
                createdBy,
                id: state?.action === 'save' ? undefined : state?.data?.httpData?.id,
                eAccount: idAccount,
                name: state?.data?.httpData?.name,
                code: Number(state?.data?.httpData?.code),
                version: state?.data?.httpData?.version,
                validity: state?.data?.httpData?.validity,
                introduction: state?.data?.httpData?.bodyOfText?.introduction,
                meaning: state?.data?.httpData?.bodyOfText?.meaning,
                risks: state?.data?.httpData?.bodyOfText?.risks,
                recomendations: state?.data?.httpData?.bodyOfText?.recomendations,
                additionalComments: state?.data?.httpData?.bodyOfText?.additionalComments,
                benefits: state?.data?.httpData?.bodyOfText?.benefits,
                declarations: state?.data?.httpData?.bodyOfText?.declarations,
                withdrawal: state?.data?.httpData?.bodyOfText?.withdrawal,
                footer: state?.data?.httpData?.footer,
                revocation: state?.data?.httpData?.revocationOfConsent?.revocation,
            },

            succesAction: (rst) => {
                customSwaltAlertAsistencial({
                    icon: 'success',
                    title: `${state?.data?.httpData?.id ? 'Actualizado' : 'Creado'} exitosamente`,
                    text: `Se ha ${state?.data?.httpData?.id ? 'actualizado' : 'creado'} el consentimiento: ${state?.data?.httpData?.name}`,
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                }).then(() => {
                    history.replace('/asistencial/tmAdmisiones/consentimientos')
                })
            },

            doAfterException: (rst) => {
                customSwaltAlertAsistencial({
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: rst.message,
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                })
            },
        });



    };

    const pdfRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: `consentimiento ${data?.httpData?.name}`,
    })

    useEffect(() => {

        if (state.action !== 'detail') {

            setData({
                dataTemporary: {
                    nameTemp: state?.data?.httpData?.name || '',
                    codeTemp: state?.data?.httpData?.code || '',
                    versionTemp: state?.data?.httpData?.version || '',
                    effectiveDateTemp: state?.data?.httpData?.validity || '',
                },
                httpData: {
                    name: state?.data?.httpData?.name || '',
                    code: state?.data?.httpData?.code || '',
                    version: state?.data?.httpData?.version || '',
                    validity: state?.data?.httpData?.validity || '',
                    bodyOfText: {
                        introduction: state?.data?.httpData?.bodyOfText?.introduction || '',
                        meaning: state?.data?.httpData?.bodyOfText?.meaning || '',
                        risks: state?.data?.httpData?.bodyOfText?.risks || '',
                        recomendations: state?.data?.httpData?.bodyOfText?.recomendations || '',
                        additionalComments: state?.data?.httpData?.bodyOfText?.additionalComments || '',
                        benefits: state?.data?.httpData?.bodyOfText?.benefits || '',
                        declarations: state?.data?.httpData?.bodyOfText?.declarations || '',
                        withdrawal: state?.data?.httpData?.bodyOfText?.withdrawal || '',
                    },
                    revocationOfConsent: {
                        revocation: state?.data?.httpData?.revocationOfConsent?.revocation || '',
                    },
                    footer: state?.data?.httpData?.footer || ''
                },
                modal: false
            })
        }


        if (state.action === 'detail') {
            setData({
                // dataTemporary: {
                //     nameTemp: state?.data?.httpData?.name || '',
                //     codeTemp: state?.data?.httpData?.code || '',
                //     versionTemp: state?.data?.httpData?.version || '',
                //     effectiveDateTemp: state?.data?.httpData?.validity || '',
                // },
                httpData: {
                    name: state?.data?.name || '',
                    code: state?.data?.code || '',
                    version: state?.data?.version || '',
                    validity: state?.data?.validity || '',
                    bodyOfText: {
                        introduction: state?.data?.introduction || '',
                        meaning: state?.data?.meaning || '',
                        risks: state?.data?.risks || '',
                        recomendations: state?.data?.recomendations || '',
                        additionalComments: state?.data?.additionalComments || '',
                        benefits: state?.data?.benefits || '',
                        declarations: state?.data?.declarations || '',
                        withdrawal: state?.data?.withdrawal || '',
                    },
                    revocationOfConsent: {
                        revocation: state?.data?.revocation || '',
                    },
                    footer: state?.data?.footer || ''
                },
                modal: false
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {(pdfLoader || loaderNewConsent) && loader}

            <div className={`${tableStyles.layoutBase}`}>

                <div className="d-flex gap-3 align-items-center">
                    <img
                        alt="regresar"
                        className={`${tableStyles.filterOrdAquaMarine} pointer`}
                        onClick={() => history.goBack()}
                        src={backIcon}
                    />
                    <div className={`${tableStyles.f40} ${tableStyles.ordDarkBlueText} fw-bold`}>
                        {state?.action === 'detail' ? 'Detalle consentimiento' : 'Previsualización'}
                    </div>
                </div>

                <Box className="d-flex mt-4">
                    <Box
                        className="mb-5"
                        style={{ maxWidth: '1000px', width: '80%', minHeight: '75vh', boxShadow: '0px 0px 6px #ccc', borderRadius: '10px' }}
                    >
                        <Box
                            ref={pdfRef}
                            style={{ width: '100%', padding: '35px 20px' }}
                            className={tableStyles.ordDarkGrayText}
                        >
                            <Box className={`${tableStyles.styleCardConsent} h-auto shadow-none mt-0 p-0 mb-4`}>
                                <Box className="d-flex flex-column gap-3">
                                    <img
                                        width={137}
                                        src={state?.data.logoAccount || state?.currentAccount?.entityLogo || ''}
                                        alt="logo"
                                    />

                                    <Box>
                                        <Box className={`${tableStyles.darkerBlueText} fw-bold`}>
                                            {state?.data?.bussinesNameAccount || state?.currentAccount?.bussines_name || ''}
                                        </Box>

                                        <Box className={`d-flex gap-2 ${tableStyles.f12} ${tableStyles.darkerGrayText}`}>
                                            <Text title={'Nit'} subtitle={state?.data?.nitAccount || state?.currentAccount?.nit || ''} />
                                            <Text title={'Teléfono'} subtitle={state?.data?.phoneAccount || state?.currentAccount?.phone || ''} />
                                            <Text title={'Email'} subtitle={state?.data?.emailAccount || state?.currentAccount?.email || ''} />
                                            <Text title={'Dirección'} subtitle={state?.data?.addressAccount || state?.currentAccount?.address || ''} />
                                        </Box>

                                    </Box>


                                </Box>

                                <Box className="d-flex flex-column align-items-end justify-content-around gap-3 align-self-end">
                                    <Box className="text-end">
                                        <Box className={`${tableStyles.f14} ${tableStyles.ordAquaMarineText} text-uppercase fw-bold`}>
                                            {data?.httpData?.name}
                                        </Box>
                                        <Box className={`d-flex gap-2 ${tableStyles.f12} ${tableStyles.darkerGrayText}`}>
                                            <Text title={'Código'} subtitle={data?.httpData?.code}></Text>
                                            <Text title={'Versión'} subtitle={data?.httpData?.version}></Text>
                                            <Text title={'Vigencia'} subtitle={data?.httpData?.validity}></Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="mb-4">
                                <Box className={`${tableStyles.marginBottom15px} row`}>
                                    <Box className="col-3 ">
                                        <TextGrayOnBrackets active title={'Fecha'} />
                                    </Box>
                                </Box>

                                <Box className={`${tableStyles.marginBottom15px} row`}>
                                    <Box className="col-6 ">
                                        <TextGrayOnBrackets title={'Paciente'} active />
                                    </Box>
                                    <Box className="col-3 ">
                                        <TextGrayOnBrackets title={'Identificación'} active />
                                    </Box>
                                    <Box className="col-3 ">
                                        <TextGrayOnBrackets title={'Teléfono'} active />
                                    </Box>
                                </Box>

                                <Box className={`${tableStyles.marginBottom15px} row`}>
                                    <Box className="col-6 ">
                                        <TextGrayOnBrackets title={'Procedimiento'} active />
                                    </Box>
                                    <Box className="col-3 ">
                                        <TextGrayOnBrackets title={'Ojo a operarse'} active />
                                    </Box>
                                    <Box className="col-3 ">
                                        <TextGrayOnBrackets title={'Responsables'} active />
                                    </Box>
                                </Box>

                            </Box>

                            <Box className={`${tableStyles.f12} mb-4`} style={{ textAlign: 'justify' }} >
                                {parse(data?.httpData?.bodyOfText?.introduction || '')}
                            </Box>

                            <Box className="mb-4">
                                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                                    Significado del procedimiento
                                </Box>
                                <Box className={`${tableStyles.f12} mb-4`} >
                                    {parse(data?.httpData?.bodyOfText?.meaning || '')}
                                </Box>
                            </Box>

                            <Box className="mb-4">
                                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                                    Riesgos
                                </Box>
                                <Box className={`${tableStyles.f12} mb-4`} >
                                    {parse(data?.httpData?.bodyOfText?.risks || '')}
                                </Box>
                            </Box>

                            <Box className="mb-4">
                                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                                    Beneficios
                                </Box>
                                <Box className={`${tableStyles.f12} mb-4`} >
                                    {parse(data?.httpData?.bodyOfText?.benefits || '')}
                                </Box>
                            </Box>

                            <Box className="mb-4">
                                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                                    Recomendaciones
                                </Box>
                                <Box className={`${tableStyles.f12} mb-4`} >
                                    {parse(data?.httpData?.bodyOfText?.recomendations || '')}
                                </Box>
                                {console.log(data?.httpData, 'data?.httpData')}
                                {console.log(state?.data, 'state?.data')}
                            </Box>

                            <Box className="mb-4">
                                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                                    Comentarios adicionales
                                </Box>
                                <Box className={`${tableStyles.f12} mb-4`} >
                                    {parse(data?.httpData?.bodyOfText?.additionalComments || '')}
                                </Box>
                            </Box>

                            <Box className="mb-4">
                                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                                    Declaratorias
                                </Box>
                                <Box className={`${tableStyles.f12} mb-4`} >
                                    {parse(data?.httpData?.bodyOfText?.declarations || '')}
                                </Box>
                            </Box>

                            <Box
                                style={{ textAlign: 'justify', marginBottom: '80px' }}
                            >
                                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                                    Consentimiento o desistimiento informado
                                </Box>
                                <Box className={`${tableStyles.f12} mb-4`} >
                                    {parse(data?.httpData?.bodyOfText?.withdrawal || '')}
                                </Box>
                            </Box>

                            <Box className="row mb-5" style={{ width: '85%' }}>
                                <Box className="col-4">
                                    <TextGrayInBrackets typeSignature={'Firma del Paciente'} typeId={'C.C'} active />
                                </Box>

                                <Box className="col-4">
                                    <TextGrayInBrackets typeSignature={'Firma del acompañante'} typeId={'C.C'} active />
                                </Box>

                                <Box className="col-4">
                                    <TextGrayInBrackets typeSignature={'Cirujano'} typeId={'R.M.'} active />
                                </Box>

                            </Box>

                            <Box className="row align-items-center mb-2" style={{ width: '85%' }}>
                                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold d-flex gap-3 align-items-end`}>
                                    <span>REVOCATORIA DE LO CONSENTIDO:</span>
                                    <span className="col-6">
                                        <TextGrayOnBrackets className='w-100' active title={'Fecha'} classNameTitle='fw-lighter' />
                                    </span>
                                </Box>
                            </Box>

                            <Box style={{ marginBottom: '70px' }}>
                                <Box className={`${tableStyles.f12}`} >
                                    {parse(data?.httpData?.revocationOfConsent?.revocation || '')}
                                </Box>
                            </Box>

                            <Box className="row mt-5" style={{ width: '85%' }}>
                                <Box className="col-4">
                                    <TextGrayInBrackets typeSignature={'Paciente o representante legal'} typeId={'C.C'} active />
                                </Box>

                                <Box className="col-4">
                                    <TextGrayInBrackets typeSignature={'Acompañaste (s)'} typeId={'C.C'} active />
                                </Box>

                                <Box className="col-4">
                                    <TextGrayInBrackets typeSignature={'Cirujano'} typeId={'R.M.'} active />
                                </Box>

                            </Box>

                            <Box style={{ margin: 'auto', marginTop: '110px', fontSize: '10px', textAlign: 'center', width: '95%' }}>
                                {parse(data?.httpData?.footer || '')}
                            </Box>

                        </Box>
                    </Box>

                    <Box>
                        <Box className="ms-4 position-absolute">

                            <button
                                style={{ width: '192px', marginBottom: '15px' }}
                                className={`${tableStyles.btnSaveAdmission} d-block`}
                                onClick={() => {
                                    if (state?.action === 'detail') {
                                        setPdfLoader(true)
                                        setTimeout(() => {
                                            setPdfLoader(false)
                                        }, [2000])

                                        handlePrint()

                                    } else validationSave()
                                }}
                            >
                                {state?.action === 'detail' ? 'Descargar' : 'Guardar'}
                            </button>

                            <button
                                style={{ width: '192px' }}
                                className={`${tableStyles.btnCancelAdmission} d-block`}
                                onClick={() => history.replace('/asistencial/tmAdmisiones/consentimientos')}
                            >
                                Cancelar
                            </button>


                        </Box>
                    </Box>
                </Box>

            </div>
        </>
    )

}