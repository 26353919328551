import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GET_PINVOICES } from "./actionTypes";
import { customSwaltAlert } from "../helpers";

const MySwal = withReactContent(Swal);

export const getPinvoices = (objFilters, loading) => (dispatch, getCreate) => {
    
    dispatch({
        type: GET_PINVOICES,
        payload: [],
        total: 0,
    });
    
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pinvoices/?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (loading) {
                loading()
            }
            const { results, total } = res;
            dispatch({
                type: GET_PINVOICES,
                payload: results,
                total: total,
            });
        })
        .catch((err) => {
            if (loading) {
                loading()
            }
            console.error(err.message);
        });
};
export const sentCrossingAccounts = (data, doAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    customSwaltAlert({
        title: `¿Está seguro?`,
        text: "Verifique la información",
        icon: "warning",
        showCancelButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            fetch(
                `${URL_GATEWAY}${API_VERSION}/treasury/cross_accounts/`,
                {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        token: token,
                    },
                }
            )
                .then((response) => response.json())
                .then((res) => {
                    doAfter();
                    if (res.success === true) {
                        customSwaltAlert({
                            // title: `¿Está seguro?`,
                            icon: "success",
                            title: res.message,
                            showCancelButton: false,
                        })

                    } else {
                        customSwaltAlert({
                            icon: "success",
                            title: `Intenta de nuevo`,
                            text: res.message,
                            showCancelButton: false,
                        })
                    }
                })
                .catch((res) => {
                    customSwaltAlert({
                        icon: "success",
                        title: `Intenta de nuevo`,
                        text: res.message,
                        showCancelButton: false,
                    })
                });
        }
    });
};


