import AWS from 'aws-sdk';
import { getEnvs, isEmptyOrUndefined } from './';
import { useState } from 'react';

export const useHandleUploadFileAws = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [results, setResults] = useState('')

    const handleUploadAws = async (props) => {

        const bodyS3 = props?.body, bucketNameS3 = props?.bucketName, keyBodyS3 = props?.keyBody

        if (isEmptyOrUndefined(bodyS3)) {
            console.error('Parameter body is required')
            return
        };

        if (isEmptyOrUndefined(bucketNameS3)) {
            console.error('Parameter bucketName is required');
            return
        }

        if (isEmptyOrUndefined(keyBodyS3)) {
            console.error('Parameter keyBody is required')
            return
        };

        const { ACCESS_KEY_ID_AWS, SECRET_ACCESS_KEY_AWS, REGION_AWS } = getEnvs()

        // S3 Bucket Name
        const S3_BUCKET = bucketNameS3;

        // S3 Region
        const REGION = REGION_AWS;

        // S3 Credentials
        AWS.config.update({
            accessKeyId: ACCESS_KEY_ID_AWS,
            secretAccessKey: SECRET_ACCESS_KEY_AWS,
        });

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        // Files Parameters
        const params = {
            Bucket: S3_BUCKET,
            Key: keyBodyS3,
            Body: bodyS3,
        };

        // Uploading file to s3
        let upload = s3.putObject(params).on("httpUploadProgress", (evt) => {
            // File uploading progress
            console.log("Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%");
            console.log(evt,'evt')
            setIsLoading(true)
        }).promise()

        await upload.then((res, data) => {
            setResults(res)
            console.log({res, data})
        }).finally(()=>{
            setIsLoading(false)
        })
    }

    return {
        isLoading,
        results,
        trigger: handleUploadAws
    }

}