import { API_VERSION, defaultFilters, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import {
  GET_NIIF_ACCOUNTS,
  GET_LOCAL_ACCOUNTS,
  SET_INDEX_NIIF,
  SET_INDEX_LOCAL_ACCOUNTS,
  GET_ALL_ACCOUNTS,
  SET_INDEX_PUC,
  SET_TOTAL_INDEX_PUC,
  CREATE_PUC_ACCOUNT_SUCCESS,
  ERROR_CREATE_PUC_ACCOUNT,
  PUC_LOADING,
} from "./actionTypes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

//Fields for sites and messages errors
const requiredFields = {
  description: "descripción",
  code: "número de cuenta",
  active: "estado",
  class_: "clase",
  type: "tipo de cuenta",
  check_format:"check_format",
  account_type:"account_type",
  name_bank:"name_bank",
  num_count:"num_count",
  entity_account:"currentAccount.id",

};

const MySwal = withReactContent(Swal);

// ANCHOR GET ALL ACCOUNTS
export const getAllPUCAccounts = (objFilters) => (dispatch, getState) => {
 
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/puc/accounts?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      var { results, row_total, totalIndex } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      dispatch({
        type: GET_ALL_ACCOUNTS,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_PUC,
        payload: row_total,
      });
      dispatch({
        type: SET_TOTAL_INDEX_PUC,
        payload: totalIndex,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_ALL_ACCOUNTS,
        payload: [],
      });

      customSwaltAlert({
        icon: "error",
        title: "Error al traer las cuentas",
        text:
          "Ocurrió un error al intentar traer la información de las cuentas.",
        footer: "Si el problema persiste comunícate con un asesor.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    });
};

// ANCHOR GET METHOD FOR NIIF ACCOUNTS
export const getNiifAccounts = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/niif?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_total } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      dispatch({
        type: GET_NIIF_ACCOUNTS,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_NIIF,
        payload: row_total,
      });
    })
    .catch((error) => {
      console.error(error);
      
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text:
          "Ocurrió un error al intentar traer la información de las cuentas.",
        footer: "Si el problema persiste conmuníquese con un asesor.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    });
};

// ANCHOR GET NIIF BY NUMBER
export const getNiifByNumber = (number) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const eaccount = getState().loginReducer.currentAccount.id;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/niif/${number}?eaccount=${eaccount}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_NIIF_ACCOUNTS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      console.error(error);

      customSwaltAlert({
        icon: "error",
        title: "Error al traer la cuenta",
        text: "Ocurrió un error al intentar traer la información de la cuenta.",
        footer: "Si el problema persiste conmuníquese con un asesor.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    });
};

// ANCHOR GET LOCAL ACCOUNTS
export const getLocalAccounts = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/localaccount?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_total } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      dispatch({
        type: GET_LOCAL_ACCOUNTS,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_LOCAL_ACCOUNTS,
        payload: row_total,
      });
    })
    .catch((error) => {
      console.error(error);

      customSwaltAlert({
        icon: "error",
        title: "Error al traer las cuentas",
        text:
          "Ocurrió un error al intentar traer la información de las cuentas.",
        footer: "Si el problema persiste conmuníquese con un asesor.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    });
};

// ANCHOR CHANGE STATUS ACCOUNT
export const changeStatus = (objPuc) => (dispatch, getState) => {
  const updated_at = Date.now();
  const IDCurrent= getState().loginReducer.currentAccount.id;
  const defaultFiltersPuc= {
    perpage : 10,
    page : 1,
    search :"",
    eaccount: IDCurrent,
  }
  objPuc["updated_at"] = updated_at;
  const token = getState().loginReducer.Authorization;
  let status = objPuc.active ? "deshabilitar" : "habilitar";

  customSwaltAlert({
    icon: "warning",
    title: `¿Está seguro?`,
    text:`Se va ${status} la cuenta: ${objPuc.description}`,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      objPuc.active = !objPuc.active;
      objPuc.active = objPuc.active !== false ? 1 : 0;
      let type = objPuc.type === "local" ? "localaccount" : objPuc.type;
      objPuc.code = objPuc.complete_account;
      if (objPuc.reserved) {
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "Esta cuenta es reservada y no se puede editar.",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        
      }
      if (objPuc.type === "niif" && objPuc.localAccount && objPuc.active === 0)

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Al deshabilitar, también deshabilitará la cuenta local.`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      fetch(`${URL_GATEWAY}${API_VERSION}/admin/${type}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(objPuc),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            let status = res.data.active ? "habilitado" : "deshabilitado";
            setTimeout(() => {

              customSwaltAlert({
                icon: "success",
                title: `Actualizada exitosamente`,
                text: `Se ha ${status} la cuenta: ${objPuc.description}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })

              dispatch(getAllPUCAccounts(defaultFiltersPuc));
            }, 1500);
          } else {
            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: `${res.message}`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
          }
        })
        .catch((err) => {

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: "No se pudo cambiar el estado",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        });
    } else {

    }
  });
};

// ANCHOR CREATE PUC
export const createPuc = (objPuc) => (dispatch, getState) => {
  const created_at = Date.now();
  const updated_at = Date.now();
  const IDCurrent= getState().loginReducer.currentAccount.id;
  const defaultFiltersPuc= {
    perpage : 10,
    page : 1,
    search :"",
    eaccount: IDCurrent,
  }
  objPuc["created_at"] = created_at;
  objPuc["updated_at"] = updated_at;
  const token = getState().loginReducer.Authorization;

  dispatch({
    type:PUC_LOADING,
    payload:true
  }) 

  // if (keyError !== "") {
  //   dispatch({
  //     type: CREATE_PUC_ACCOUNT_SUCCESS,
  //     payload: false,
  //   });
  //   return MySwal.fire({
  //     icon: "error",
  //     title: "Error al crear",
  //     text: "Debe llenar todos los campos para agregar una cuenta o subcuenta",
  //     footer: `El campo ${keyError} es obligatorio`,
  //   });
  // } else {
    let type = objPuc.type === "local" ? "localaccount" : objPuc.type;
    if (type === "niif") { delete objPuc["niifLinked"]; delete objPuc['niifDescription'] 
    }
    if (type === "localaccount") {
      if (objPuc.niifLinked !== "" && objPuc.niifLinked !== null) {
        dispatch(getNiifByNumber(objPuc.niifLinked));
      } else {
        dispatch({
          type:PUC_LOADING,
          payload:false
        })
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "Debe llenar todos los campos para agregar una cuenta o subcuenta",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      }
      
    }
    setTimeout(async () => {

      objPuc.uses_third = objPuc.uses_third != null ? objPuc.uses_third : false;
      objPuc.uses_cost_center = objPuc.uses_cost_center != null ? objPuc.uses_cost_center : false;
      objPuc.check_info_bank = objPuc.check_info_bank != null ? objPuc.check_info_bank : false;
      if (type === "localaccount") {
        var niifLink = getState().pucReducer.niifAccounts;
        if ((objPuc.uses_cost_center !== niifLink.uses_cost_center || objPuc.uses_third !== niifLink.uses_third)) {
          if((niifLink !== undefined && niifLink !== null)){
            if (typeof niifLink === 'object') {
              let complete_account = niifLink.complete_account
              let code = objPuc.code
              if (complete_account && code) {
                if (complete_account.length !== code.length || complete_account === null || complete_account === undefined) {

                  customSwaltAlert({
                    icon: "warning",
                    title: "Intenta de nuevo",
                    text: 'Error, debe elegir una cuenta NIIF válida',
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })

                  dispatch({
                    type:PUC_LOADING,
                    payload:false
                  })

                }
              }
              } else {
              let code = objPuc.code;
              if (niifLink.length !== code.length) {
                
                dispatch({
                  type:PUC_LOADING,
                  payload:false
                })

                return customSwaltAlert({
                  icon: "warning",
                  title: "Intenta de nuevo",
                  confirmButtonText:'Aceptar',
                  showCancelButton:false
                })             
              }
            }
          }else {  
            dispatch({
              type:PUC_LOADING,
              payload:false
            })           
            return customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: 'Error, debe elegir una cuenta NIIF válida',
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })

                         
          }       
        }   
          fetch(`${URL_GATEWAY}${API_VERSION}/admin/${type}`, {
            method: "POST",
            body: JSON.stringify(objPuc),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          })
            .then((response) => response.json())
            .then((res) => {
              if (res.success) {

                customSwaltAlert({
                  icon: "success",
                  title: `Creada exitosamente`,
                  text: `Se ha creado la cuenta: ${objPuc.description}`,
                  confirmButtonText:'Aceptar',
                  showCancelButton:false
                })

                

                dispatch({
                  type: CREATE_PUC_ACCOUNT_SUCCESS,
                  payload: true,
                });

                
                
                dispatch(getAllPUC(defaultFiltersPuc));


                dispatch({
                  type:PUC_LOADING,
                  payload:false
                })

              } else {

                customSwaltAlert({
                  icon: "warning",
                  title: "Intenta de nuevo",
                  text: `${res.message}`,
                  confirmButtonText:'Aceptar',
                  showCancelButton:false
                })

                dispatch({
                  type:PUC_LOADING,
                  payload:false
                })
              }
            })
            .catch((err) => {
              console.error(err);

              dispatch({
                type:PUC_LOADING,
                payload:false
              })

              dispatch({
                type: ERROR_CREATE_PUC_ACCOUNT,
                payload: err,
              });
            });
      } else {
            fetch(`${URL_GATEWAY}${API_VERSION}/admin/${type}`, {
              method: "POST",
              body: JSON.stringify(objPuc),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
              },
            })
              .then((response) => response.json())
              .then((res) => {
                if (res.success) {
                  
                  customSwaltAlert({
                    icon: "success",
                    title: `Cuenta creada exitosamente`,
                    text: `Se ha creado la cuenta:${objPuc.description}`,
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })

                  dispatch({
                    type: CREATE_PUC_ACCOUNT_SUCCESS,
                    payload: true,
                  });
                  dispatch(getAllPUC(defaultFiltersPuc));

                  dispatch({
                    type:PUC_LOADING,
                    payload:false
                  })

                } else {

                  customSwaltAlert({
                    icon: "warning",
                    title: "Intenta de nuevo",
                    text: `La cuenta no se encuentra registrada`,
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })

                  dispatch({
                    type:PUC_LOADING,
                    payload:false
                  })
                }
              })
              .catch((err) => {
                
                customSwaltAlert({
                  icon: "warning",
                  title: "Intenta de nuevo",
                  text: `Ocurrió un error al tratar de crear la cuenta`,
                  confirmButtonText:'Aceptar',
                  showCancelButton:false
                })

                console.error(err);
                dispatch({
                  type: ERROR_CREATE_PUC_ACCOUNT,
                  payload: err,
                });

                dispatch({
                  type:PUC_LOADING,
                  payload:false
                })
              });
          }
        }, 500);
  
};

// ANCHOR UPDATE PUC
export const updatePuc = (objPuc) => (dispatch, getState) => {
  dispatch({
    type: GET_ALL_ACCOUNTS,
    loading: true,
  });
  const updated_at = Date.now();
  objPuc["updated_at"] = updated_at;
    const token = getState().loginReducer.Authorization;
    const IDCurrent= getState().loginReducer.currentAccount.id;
    const defaultFiltersPuc= {
      perpage : 10,
      page : 1,
      search :"",
      eaccount: IDCurrent,
    }
    let type = objPuc.type === "local" ? "localaccount" : objPuc.type;
    if (type === "niif") delete objPuc["niifLinked"];
    if (type === "localaccount" && !objPuc.niifLinked) {
      dispatch({
        type: GET_ALL_ACCOUNTS,
        loading: false,
      });
      dispatch(getAllPUC(defaultFiltersPuc));
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Para las cuentas locales es obligatorio una homologación NIIF.",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })

    }
    fetch(`${URL_GATEWAY}${API_VERSION}/admin/${type}`, {
      method: "PUT",
      body: JSON.stringify(objPuc),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatch({
            type: GET_ALL_ACCOUNTS,
            loading: false,
          });

          customSwaltAlert({
            icon: "success",
            title: `Actualizado exitosamente`,
            text: `Se ha actualizado el plan único de cuenta: ${objPuc.description}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

          dispatch({
            type: CREATE_PUC_ACCOUNT_SUCCESS,
            payload: true,
          });
          dispatch(getAllPUC(defaultFiltersPuc));
        } else {
          dispatch({
            type: GET_ALL_ACCOUNTS,
            loading: false,
          });
          dispatch(getAllPUC(defaultFiltersPuc));

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `${res.message}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
          console.error(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(getAllPUC(defaultFiltersPuc));

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "Ocurrió un error al guardar los cambios",
          footer: "Si el problema persiste comuníquese con un asesor.",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      });
  
};

export const cleanNiifAccountLinked = () => (dispatch, getState) => {
  dispatch({
    type: GET_NIIF_ACCOUNTS,
    payload: []
  })
}

// función renovada para traer el listado de cuentas
export const getAllPUC = (params) => (dispatch, getState) => {
  dispatch({
    type: GET_ALL_ACCOUNTS,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(params)
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/allpuc/?${filters}`,{
    method: 'GET',
    headers: {
      accept: 'application/json',
      "Content-Type": 'application/json',
      Authorization: token
    }
  }).then(response => response.json())
  .then(res => {
    if (res.success){
      dispatch({
        type:GET_ALL_ACCOUNTS,
        payload:res.results,
        loading: false
      })
      dispatch({
        type:SET_INDEX_PUC,
        payload:res.totalIndex
      })
      // dispatch({
      //   type:SET_TOTAL_INDEX_PUC,
      //   payload:res.totalIndex
      // })
    }
  }).catch(()=>{
    dispatch({
      type:GET_ALL_ACCOUNTS,
      payload:[],
      loading: false
    })
    
    customSwaltAlert({
      icon:"error", title:"Error", text:"Ocurrió un error al listar las cuentas",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  })
}