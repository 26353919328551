//All actions for Login component
import {
    FUNCTIONAL_GET,
    FUNCTIONAL_GETONE,
    FUNCTIONAL_EDIT,
    FUNCTIONAL_ACTIVES,
    FUNCTIONAL_GET_NIIF,
    FUNCTIONAL_LOADING,
} from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal)

export const functional_get = (objFilters, doafter = null) => (dispatch, getState) => {
    dispatch({ type: FUNCTIONAL_LOADING, loading: true });
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/composite/functional/?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            if (doafter) {
                doafter()
            }
            const { results, row_total } = res
            dispatch({
                type: FUNCTIONAL_GET,
                payload: results,
                row_total: row_total
            })

            dispatch({ type: FUNCTIONAL_LOADING, loading: false });
        })
        .catch((err) => {
            console.error(err);
            dispatch({ type: FUNCTIONAL_LOADING, loading: false });
            if (doafter) {
                doafter()
            }

        })
}

export const get_active_functional = () => (dispatch, getState) => {
    dispatch({ type: FUNCTIONAL_LOADING, loading: true });
    const token = getState().loginReducer.Authorization;

    fetch(
        `${URL_GATEWAY}${API_VERSION}/functional/active/`,
        {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

                Authorization: token,
            },
        }
    )
        .then((response) => response.json())
        .then((res) => {
            const { results } = res;
            dispatch({
                type: FUNCTIONAL_ACTIVES,
                payload: results,
            });
            dispatch({ type: FUNCTIONAL_LOADING, loading: false });
        })
        .catch((err) => {
            dispatch({ type: FUNCTIONAL_LOADING, loading: false });
        });
}

export const functional_edit_active = (codigo, data, description, executeThisAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    let status = data.active ? "habilitar" : "deshabilitar"
    let status2 = data.active ? "habilitado" : "deshabilitado"
    dispatch({ type: FUNCTIONAL_LOADING, loading: true });
    
    customSwaltAlert({
        icon: 'warning',
        title: `¿Está seguro?`,
        text: `Se va ${status} la unidad funcional: ${description}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
            fetch(`${URL_GATEWAY}${API_VERSION}/composite/functional/${codigo}`, {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                }
            })
                .then(response => response.json())
                .then(res => {
                    if (res.status === 200) {

                        customSwaltAlert({
                            icon: 'success',
                            title: 'Actualizada exitosamente',
                            text: `Se ha ${status2} la unidad funcional: ${description}`,
                            confirmButtonText:'Aceptar',
                            showCancelButton:false
                          })
                    } else {

                        customSwaltAlert({
                            icon: 'warning',
                            title: 'Intenta de nuevo',
                            text: `${res.message}`,
                            confirmButtonText:'Aceptar',
                            showCancelButton:false
                          })
                    }
                    dispatch({ type: FUNCTIONAL_LOADING, loading: false });
                }).then(
                    () => { executeThisAfter(); }
                )
                .catch((err) => {
                    console.error(err);

                    customSwaltAlert({
                        icon: 'warning',
                        title: 'Intenta de nuevo',
                        text: 'No se pudo cambiar el estado',
                        footer: 'Si el problema persiste comuníquese con un asesor.',
                        confirmButtonText:'Aceptar',
                        showCancelButton:false
                      })

                    dispatch({ type: FUNCTIONAL_LOADING, loading: false });
                })
        } else{
            dispatch({ type: FUNCTIONAL_LOADING, loading: false });
        }
    })

}

export const functional_get_niif = (id) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    dispatch({ type: FUNCTIONAL_LOADING, loading: true });
    fetch(`${URL_GATEWAY}${API_VERSION}/admin/niif/forfunctional/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    }).then(response => response.json())
        .then(res => {
            const { niifs, sites } = res
            dispatch({
                type: FUNCTIONAL_GET_NIIF,
                niifs: niifs,
                sites: sites
            });
            dispatch({ type: FUNCTIONAL_LOADING, loading: false });
        })
        .catch((err) => {
            console.error(err)
            dispatch({ type: FUNCTIONAL_LOADING, loading: false });
        })
}

export const functional_edit = (codigo, data, executeThisAfter) => (dispatch, getState) => {
    if (data.description.trim()) {
        dispatch({ type: FUNCTIONAL_LOADING, loading: true });
        const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/composite/functional/${codigo}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            }
        })
            .then(response => response.json())
            .then(res => {
                if (res.status === 200) {
                    
                    customSwaltAlert({
                        icon: 'success',
                        title: 'Actualizada exitosamente',
                        text: `Se ha actualizado la unidad funcional: ${data.description}`,
                        confirmButtonText:'Aceptar',
                        showCancelButton:false
                      })

                } else {

                    customSwaltAlert({
                        icon: 'warning',
                        title: 'Intenta de nuevo',
                        text: `${res.message}`,
                        confirmButtonText:'Aceptar',
                        showCancelButton:false
                      })
                }
                dispatch({ type: FUNCTIONAL_LOADING, loading: false });
            }).then(
                () => { executeThisAfter(); }
            )
            .catch((err) => {
                console.error(err);

                customSwaltAlert({
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: 'No se pudo cambiar el estado',
                    footer: 'Si el problema persiste comuníquese con un asesor.',
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })

                dispatch({ type: FUNCTIONAL_LOADING, loading: false });
            })
    } else {

        customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'Razón Social no puede estar vacia',
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

    }

}



export const functional_insert = (data, executeThisAfter) => (dispatch, getState) => {
    dispatch({ type: FUNCTIONAL_LOADING, loading: true });
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/composite/functional/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.status === 200) {

                customSwaltAlert({
                    icon: 'success',
                    title: 'Creada exitosamente',
                    text: `Se ha creado la unidad funcional: ${data.description} `,
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })

            } else {
                
                customSwaltAlert({
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: `${res.message}`,
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })
            }
            dispatch({ type: FUNCTIONAL_LOADING, loading: false });
        }).then(
            () => { executeThisAfter(); }
        )
        .catch((err) => {
            console.error(err);

            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'No se pudo crear el registro',
                footer: 'Si el problema persiste comuníquese con un asesor.',
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })

            dispatch({ type: FUNCTIONAL_LOADING, loading: false });
        })


}