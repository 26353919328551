import React, { useEffect, useState } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
import orangeNoBGArrow from "../../assets/img/icons/orangeNoBGArrow.svg";
import Update from "../../assets/img/icons/Update.svg";
import ordDownArrow from "../../assets/img/icons/ordDownArrow.svg";
import miniRoundWhiteCheck from "../../assets/img/icons/miniRoundWhiteCheck.svg";
import cleanCloseIcon from "../../assets/img/icons/cleanCloseIcon.svg";
import ordSearchClearBlue from "../../assets/img/icons/ordSearchClearBlue.svg";
import poundSign from "../../assets/img/icons/CentrodeCosto.svg";
import pillDarkBlue from "../../assets/img/icons/pillDarkBlue.svg";
import ordSugess from "../../assets/img/icons/ordSugess.svg";
import '../../OrderingModule/orderingStyles.css'
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@material-ui/core';
import { customSelectMulti, ordCustomSelect } from '../../components/Layouts/react-select-custom';
import Select from "react-select";
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable'
import OrdModal from '../OrderingComponents/OrdModal';
import { Radio } from "@material-ui/core";
import { useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import makeAnimated from "react-select/animated";
import { useGetMethod, useMultiGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { formatToRcSelect, isEmptyOrUndefined, loader, calculatePresentationMedicine, validateEmptyString, message, swalConfirm, today, getPermission } from '../../helpers/helpers';
import "../../OrderingModule/OrderingComponents/OrdAccordionTable.scss"
import FullScreenDialog from '../OrderingComponents/FullScreenDialog';
import { RenderMedicalFormulasPdf } from '../../AsistanceModule/RenderMedicalFormulasPdf';
import { OrdCustomToast } from '../OrderingComponents/OrdCustomToast';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
export const MedicineTab = (event) => {
    const MySwal = withReactContent(Swal);
    const [trigger, setTrigger] = useState(0)
    let isCloning = event?.clone?.isCloning
    let medicineCloneArr = event?.clone?.cloneData
    const [justification, setJustification] = useState({
        showModal: false,
        justification: ""
    })
    const store = useSelector((state) => state);
    const animatedComponents = makeAnimated();
    const [triggerAdvSch, setTriggerAdvSch] = useState(0)
    const [trigger2, setTrigger2] = useState(0)
    const [loaderAdvSrch, setLoaderAdvSrch] = useState(true)
    const [advSearch, setAdvSearch] = useState({
        showModal: false,
        radio: '',
        diagnostics: '',
        idPresentation: '',
        selectedMdc: '',
        keyWords: '',
    })
    const [data, setData] = useState({
        medicine: "",
        qty: "",
        unity: "",
        each: "",
        time: "",
        during: "",
        period: "",
        way: "",
        eye: "",
        observations: "",
        id: "",
        presentationNumber: "",
        presentationDescr: "",
        orderType: "",
        generalObservations: isCloning ? medicineCloneArr?.morObservations : ""
    })
    const [foundedDiagnostics, setFoundedDiagnostics] = useState([{ value: "", label: "Escriba el codigo del diagnostico...", isDisabled: true }])
    const token = store.loginReducer.Authorization;
    const listPermission = store.loginReducer.currentAccount?.profile?.permission
    const myPermission = getPermission({ prefix: 'order', listPermission })
    const idEnterprise = store.loginReducer.currentAccount.id;
    const [tmpArr, setTmpArr] = useState([])
    const [currentDiagnostics, setCurrentDiagnostics] = useState(null)
    const [modalDate, setModalDate] = useState({ show: false })
    /* ----------------------------- FETCHS SECTION ----------------------------- */
    /* ----------------------------- GET ----------------------------- */
    const { results: unities, load: unitiesLoader, trigger: getUnities } = useMultiGetMethod()
    const { results: bodyOrgans, load: bodyOrgansLoader, trigger: getBodyOrgans } = useGetMethod()
    const { results: orderType, load: orderTypeLoader, trigger: getOrderType } = useGetMethod()
    const { results: medicinesList, load: medicinesLoader, trigger: getMedicines } = useGetMethod()
    const { results: medicineCloneList, load: medicineCloneListLoader, trigger: getMedicineCloneList } = useGetMethod()
    const { results: orderTypeToCloneList, load: orderTypeToCloneLoader, trigger: getOrderTypeToClone } = useGetMethod()
    const { results: medicinesListNoSlim, load: medicinesLoaderNoSlim, trigger: getMedicinesNoSlim } = useGetMethod()
    /* ---------------------------------- POST /PUT ---------------------------------- */
    const { load: createOrderLoading, trigger: createOrder } = usePostMethod()
    /* ------------------------------------ - ----------------------------------- */
    const [fullDialogScreen, setFullDialogScreen] = useState({
        show: false,
        id: ""
    })
    const validateFreqPeriod = () => {
        if (!!data?.each && !!data?.during) {
            let cadaT = Number(data.each) * Number(data?.time?.extra);
            let duranteT = Number(data.during) * Number(data.period.extra);
            if (cadaT <= duranteT) {
                return false;
            } else {
                return true;
            }
        }
    }
    const validateDuplicate = () => {
        let founded = tmpArr.length > 0 && tmpArr.filter(x => x?.medicine?.value === data?.medicine?.value)
        if (founded.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    // //console.log("tmpArr=>", tmpArr)
    function handleSubmit() {
        let toSend = []
        let numItemsWithJustification = 0;
        tmpArr.length > 0 && tmpArr.forEach((e, i) => {
            if (!!e.justification) {
                if (e.justification !== "") {
                    numItemsWithJustification = numItemsWithJustification + 1;
                }
            }
            toSend.push({
                ortId: e?.orderType?.extra,
                omeIdOrderUnit: e?.medicine?.extra6,
                timIdFrequency: e?.time?.value,
                timIdPeriod: e?.period?.value,
                draId: e?.way?.value,
                omeIdPresentation: e?.medicine?.extra10, // TODO
                dboId: e?.eye?.value,
                mdcId: e?.medicine?.value,
                meoQty: e?.qty,
                meoQtyFrequency: e?.each,
                meoQtyPeriod: e?.during,
                meoObservations: e?.observations,
                meoQtyPresentation: e?.presentationNumber, //presentationNumber
                justification: e?.justification
            })
        })
        createOrder({
            url: "/medical/medicationOrder/",
            token: token,
            method: "POST",
            body: {
                // isCovid: checked,
                clhId: event?.event?.clhId,
                morObservations: data?.generalObservations,
                documentPatient: event?.documentPatient,
                morDate: modalDate?.morDate,
                medicines: toSend
            },
            noAlert: true,
            succesAction: (results) => {
                MySwal.fire({
                    icon: "success",
                    title: `<span style=${tableStyles.ordClearBlueText}>Orden de medicamento creada exitosamente<span>`,
                    text: numItemsWithJustification < tmpArr?.length ? "" : `Se ha(n) enviado (${numItemsWithJustification}) solicitud(es) de medicamento(s) a dirección médica `,
                    showConfirmButton: true,
                    confirmButtonText: numItemsWithJustification < tmpArr?.length ? "Ver orden" : "Aceptar"
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if (numItemsWithJustification < tmpArr?.length) {
                                setTmpArr([]);
                                setFullDialogScreen({
                                    show: true,
                                    id: results.results,
                                });
                            }
                        }
                        setTmpArr([]);

                    })
                setModalDate({ show: false })

                // clear field Observations tab Medicamentos
                setData(state=>({...state, generalObservations:''}))
            },
            doAfterException: (error) => {
                if (error?.error === "duplication") {
                    swalConfirm({
                        title: `<span class=${tableStyles.ordClearBlueText} >Medicamento duplicado</span>`,
                        text: error?.message,
                        confirmButtonText: "Si, continuar",
                        doAfterConfirm: () => {
                            setModalDate({ show: true })
                        },
                    });
                } else {
                    message(
                        "warning",
                        "No se puede registrar la orden de medicamentos",
                        error?.message
                    )
                }
            }
            // succesAction: (results) => {
            //     setFullDialogScreen({
            //         show: true,
            //         id: results.results
            //     })
            // }
        })

    }
    const formattedPresentations = formatToRcSelect(unities?.presentationList?.results, "id", "label", "", "", "")
    useEffect(() => {
        getMedicines({
            url: '/medical/medicine/',
            objFilters: {
                idAccount: idEnterprise,
                slim: 1,
                contract: event?.event?.contractCorpClient,
                diagnostic: event?.event?.incomingDiag?.value,
                orderType: data?.orderType?.value
            },
            token: token,
        })
        getOrderTypeToClone({
            url: "/medical/orderType/",
            token: token,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.orderType, event?.contractCorpClient, event?.event?.incomingDiag?.value])

    useEffect(() => {
        setLoaderAdvSrch(true)
        /* -------------------------- ADVANCE SEARCH MODAL -------------------------- */
        getMedicinesNoSlim({
            url: '/medical/medicine/',
            objFilters: {
                idAccount: idEnterprise,
                contract: event?.event?.contractCorpClient,
                diagnostic: event?.event?.incomingDiag?.value,
                slim: 1,
                search: advSearch.keyWords,
                diagnostics: advSearch.diagnostics,
                presentation: advSearch.idPresentation,
                orderBy: advSearch.radio
                , orderType: data?.orderType?.value
            },
            token: token,
            doAfterSuccess: () => setLoaderAdvSrch(false),
            doAfterException: () => setLoaderAdvSrch(false)
        })
    }, [triggerAdvSch, advSearch.diagnostics, advSearch.idPresentation, advSearch.radio, data?.orderType?.value])


    useEffect(() => {
        getUnities({
            multipleGet: [
                {
                    url: "/medical/medicine/getPharmaUnit/",
                    objFilters: { type: "Presentación" },
                    requestName: "presentationList",
                },

                {
                    url: "/medical/routeAdministration/",
                    requestName: "ways",
                },
                {
                    url: "/medical/time/",
                    objFilters: { id_account: idEnterprise },
                    requestName: "times",
                    token: token,
                },
                {
                    url: "/medical/cie10diagnostics",
                    objFilters: { slim: 1, enabled: 1 },
                    requestName: "diagnostics",
                },
                {
                    url: "/medical/orderType/",
                    requestName: "orderType",
                },
            ],
            token: token,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUnities, idEnterprise, token,])
    useEffect(() => {
        getBodyOrgans({
            url: "/medical/bodyOrgans/",
            objFilters: { draId: 1 },
            token: token
        })
    }, [])
    useEffect(() => {
        getOrderType({
            url: "/medical/orderType/",
            token: token
        })
    }, [])
    // //console.log("bodyOrgans=>", bodyOrgans?.results)
    useEffect(() => {
        let validations = validateEmptyString(
            [
                { field: data.qty, whithOutAlert: true },
                { field: data?.each, whithOutAlert: true },
                { field: data.time?.extra, whithOutAlert: true },
                { field: data.during, whithOutAlert: true },
                { field: data.period?.value, whithOutAlert: true },
                { field: data?.medicine?.extra, whithOutAlert: true },
                { field: data?.medicine?.extra2, whithOutAlert: true },
            ]
        )
        if (validations.valid) {
            let medicine = {
                mdcQtyDosis: data?.qty,
                mcdQtyFrequency: data?.each,
                frequencyValue: data.time?.extra,
                mcdQtyPeriod: data.during,
                periodValue: data.period?.extra,
                qtyPerMeasUnit: data?.medicine?.extra,
                qtyPerPresentation: data?.medicine?.extra2,
                presentationDescrption: data?.medicine?.extra3
            }
            let pres = calculatePresentationMedicine(medicine)
            setData({ ...data, presentationNumber: pres.result, presentationDescr: pres.presentation })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.qty, data.medicine, data?.each, data.time?.extra, data.during, data.period?.value])
    useEffect(() => {
        setTrigger2(trigger2 + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderType?.results])
    const formattedMedicines = isCloning ?
        formatToRcSelect(
            medicineCloneList.results,
            "id",
            "name",
            "qtyPerMeasUnit",
            "qtyPerPresentation",
            "",
            false,
            "presentationDescrption",
            "orderUnitDescription",
            "presentationDescrption",
            "idReceiptUnit",
            "IsDisabled",
            "color",
            "reqAuth",
            "idPresentation",
        )
        :
        formatToRcSelect(medicinesList?.results,
            "id",
            "name",
            "qtyPerMeasUnit",
            "qtyPerPresentation",
            "",
            false,
            "presentationDescrption",
            "orderUnitDescription",
            "presentationDescrption",
            "idReceiptUnit",
            "IsDisabled",
            "color",
            "reqAuth",
            "idPresentation",
        )
    const formattedBodyOrgans = formatToRcSelect(bodyOrgans?.results, "id", "name", "", "", "", false)
    const formattedTimes = formatToRcSelect(unities?.times?.results, "id", "name", "value")
    const formattedWays = formatToRcSelect(unities?.ways?.results, "id", "name", "", "", "", false)
    const formattedOrderType = formatToRcSelect(orderType?.results, "name", "name", "id", "", "", false)
    // const formattedOrderType = unities?.orderType?.results?.length > 0 && unities?.orderType?.results?.map((e, i) => {
    //     return {
    //         value: e?.name,
    //         extra: e?.id,
    //         label: e?.name
    //         // <span key={i} ><img alt="sign" src={
    //         //     e?.name === "Particular" ?
    //         //         poundSign :
    //         //         e?.name === "Aseguradora" ?
    //         //             pillDarkBlue : ordSugess
    //         // } width="15px" /> &nbsp;{e?.name}</span>
    //     }
    // })
    // //console.log("formattedOrderType: ", formattedOrderType);



    const advSearchHeaders = [
        {
            title: "ID",
            className: "px-2 text-start col-2"
        },
        {
            title: "Descripción",
            className: "text-start col-4"
        },
    ]
    const renderTooltip = (props) => (
        <Tooltip  {...props}>
            {props}
        </Tooltip>
    );
    const renderOrderTypeTooltip = (text) => (
        <Tooltip>
            {text}
        </Tooltip>
    );
    const [tempTable, setTempTable] = useState({
        headers: [
            {
                title: <>&nbsp;</>,
                className: "col-1"
            },
            {
                title: "Medicamento",
            },
            {
                title: "Dosis",
            },
            {
                title: "Frecuencia",
            },
            {
                title: "Duración",
            },
            {
                title: "Cantidad",
            },
            {
                title: "Vía",
            },
            {
                title: "Órgano",
            },
            {
                title: <>&nbsp;</>,
            },
        ],
        currentItem: {}
    })
    const handleToggle = (item, isOpen) => {
        let accordion = isOpen === "open" ? "fold" : "open"
        let newItem = { ...item, isOpen: accordion }
        setTempTable({ ...tempTable, currentItem: newItem })
    }
    const deleteItem = (item) => {
        let { id } = item
        let newArr = tmpArr.length > 0 && tmpArr.filter(x => x.id !== id)
        setTmpArr(newArr)
    }
    const rewindItem = (item) => {
        let rewindedData = { ...item, eye: {} }
        setData(rewindedData)
        deleteItem(item)
    }

    const [reqItems, setReqItems] = useState(0)

    const formatData = () => {
        let tempList = [];
        if (Array.isArray(tmpArr)) {
            tmpArr.forEach((item, index) => {
                let orderType = item.orderType?.name
                let isOpen = tempTable.currentItem?.id === item.id ? tempTable.currentItem.isOpen : "fold"
                let rotate = tempTable.currentItem?.id === item.id ? tempTable.currentItem.isOpen === "open" ? tableStyles.rotate180Degrees : tableStyles.rotate0Degrees : tableStyles.rotate0Degrees
                let orangeText = item?.isRequ === true ? tableStyles.ordOrangeText : ""
                tempList.push(
                    <>
                        {/* /* ---------------------- FIRST ROW IS THE NORMAL VIEW ---------------------- */}
                        <tr
                            key={index + "Val12"}
                            onClick={(e) => {
                                handleToggle(item, isOpen)
                            }}
                            className="view">
                            <td
                                className={`${rotate} col-1 `}
                            > <img
                                src={ordDownArrow}
                                alt="flecha abajo"
                            ></img></td>
                            <td className={`text-start ${orangeText} col-2 px-2`}>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderOrderTypeTooltip(orderType)}>
                                    <span>
                                        <img alt="sign" src={
                                            orderType === "Particular" ?
                                                poundSign :
                                                orderType === "Aseguradora" ?
                                                    pillDarkBlue : ordSugess
                                        } width="15px" />
                                    </span>
                                </OverlayTrigger>
                                &nbsp;
                                <b>
                                    {item?.medicine?.label}</b>
                            </td>
                            <td className={`text-start ${orangeText}`} >{`${item?.qty} ${item?.medicine?.extra4}`}</td>
                            <td className={`text-start ${orangeText}`} >{`Cada ${item?.each} ${item?.time?.label}`}</td>
                            <td className={`text-start ${orangeText}`} >{`${item?.during} ${item?.period?.label}`}</td>
                            <td className={`text-start ${orangeText}`} >{`${item?.presentationNumber} ${item?.presentationDescr}(s)`}</td>
                            <td className={`text-start ${orangeText}`} >{`${item?.way?.label}`}</td>
                            <td className={`text-start ${orangeText}`} >{`${item?.eye?.label || "-"}`}</td>
                            <td className={`text-start ${orangeText}`} >
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip("Editar")}
                                >
                                    <span>
                                        <img
                                            onClick={() => rewindItem(item)}
                                            className="cursorPointer"
                                            src={Update}
                                            width="15px"
                                            alt="Rebobinar"
                                        >
                                        </img>
                                    </span>
                                </OverlayTrigger>
                                &nbsp;
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip("Eliminar")}
                                >
                                    <span>
                                        <img
                                            className="cursorPointer"
                                            onClick={() => deleteItem(item)}
                                            src={cleanCloseIcon}
                                            width="10px"
                                            alt="Eliminar"
                                        >
                                        </img> </span>
                                </OverlayTrigger>
                            </td>

                        </tr>
                        {/* /* ---------------------- SECOND ROW IS THE FOLDED CONTENT ---------------------- */}
                        < tr
                            key={index}
                            className={`${isOpen} w-100`}
                        >
                            <td colspan="12">
                                <div className="fold-content">
                                    <Row>
                                        <Col xs={4} className={`mx-2 ${tableStyles.rightLineGray}`}>
                                            <span className={`${tableStyles.ordClearBlueText}`}>
                                                <b>
                                                    Concentración
                                                </b>
                                            </span>
                                            <br></br>
                                            <span>
                                                {item?.medicine?.label}
                                                &nbsp;
                                                {item?.medicine?.extra5}
                                            </span>
                                        </Col>
                                        <Col xs={7} className={`text-start`} >
                                            <span className={`${tableStyles.ordClearBlueText}`}>
                                                <b>
                                                    Observaciones
                                                </b>
                                            </span>
                                            <br></br>
                                            <span>
                                                {item?.observations}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </td>
                        </tr >
                    </>,
                );
            });
        }
        return tempList;
    };
    // //console.log("🚀 ~ file: MedicineTab.jsx ~ line 333 ~ MedicineTab ~ reqItems", reqItems)
    const formatDataAdvSearch = () => {
        let tempList = [];
        if (Array.isArray(medicinesListNoSlim?.results)) {
            medicinesListNoSlim.results?.forEach((item, index) => {
                let bgYellow = item?.id === advSearch?.selectedMdc ? tableStyles.ordClearYellow : ""
                let sw = item?.IsDisabled === 0 || isEmptyOrUndefined(item?.IsDisabled)
                let colorL = isEmptyOrUndefined(item?.color) ? "#697387" : item.color
                tempList.push(
                    < tr key={index} className={`hover-table-row cursorPointer `}
                        style={{ opacity: `${sw ? "1.0" : "0.5"}`, pointerEvents: `${sw ? "visible" : "none"}` }}
                        onClick={sw ? () => { setAdvSearch({ ...advSearch, selectedMdc: item.id === advSearch.selectedMdc ? "" : item.id }) } : null}
                    >
                        <td style={{ color: colorL }} className={`text-start ${bgYellow}`} >  &nbsp;{item.id}</td>
                        <td style={{ color: colorL }} className={`text-start ${bgYellow}`}> &nbsp; {item?.name} - {item?.code}</td>
                    </tr >
                );
            });
        }
        return tempList;
    };
    // /* ------------------------- HANDLE CHANGES SECTION ------------------------- */
    const handleRadioChange = (event) => {
        setAdvSearch({ ...advSearch, radio: event.target.value });
    };
    const handleAddToTable = () => {
        // //console.log("MyDATA=>", data)
        let freqValidate = validateFreqPeriod()
        if (freqValidate) {
            return message(
                "info",
                "Campos erróneos",
                "La frecuencia del medicamento NO debe ser mayor a la duración."
            );
        }
        let isDuplicate = validateDuplicate(data)
        if (isDuplicate) {
            return message(
                "info",
                "Medicamento duplicado",
                "El medicamento ya ha sido registrado en el combo",
            );
        }
        // if (data.way?.label === "Oftálmico") {
        if (data.way?.value === 1) {
            if (isEmptyOrUndefined(data.eye?.value)) {
                return message(
                    "info",
                    "Campos erróneos",
                    "Debe diligenciar el campo Órgano",
                );
            }
        }
        let validations = validateEmptyString(
            [
                { field: data.orderType?.value, message: "Debe diligenciar el campo tipo de orden" },
                { field: data?.medicine?.label, message: "Debe diligenciar el campo de medicamentos" },
                { field: data.qty, message: "Debe diligenciar el campo cantidad" },
                { field: data?.each, message: "Debe diligenciar el campo cada" },
                { field: data.time?.label, message: "Debe diligenciar el campo tiempo" },
                { field: data.during, message: "Debe diligenciar el campo durante" },
                { field: data.period?.value, message: "Debe diligenciar el campo período" },
                { field: data.way?.label, message: "Debe diligenciar el campo Vía" },
                { field: data.observations, message: "Debe diligenciar el campo observaciones" },
            ]
        )
        if (validations.valid) {
            if (data?.medicine?.extra9 === 1) {
                swalConfirm({
                    title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                    text: `Este medicamento no se encuentra dentro del plan de beneficios del paciente`,
                    confirmButtonText: "Si, continuar",
                    doAfterConfirm: () => setJustification({ ...justification, showModal: true, justification: data?.justification || "" })
                })
            }
            else {
                let newData = { ...data, id: Date.now(), isRequ: false }
                tmpArr.push(newData)
                formatData(tmpArr)
                setData({
                    ...data,
                    medicine: "",
                    qty: "",
                    unity: "",
                    each: "",
                    time: "",
                    during: "",
                    period: "",
                    way: "",
                    eye: "",
                    observations: "",
                    id: "",
                    presentationNumber: "",
                    presentationDescr: "",
                    orderType: "",
                })
            }
        }
    }
    const addJustifiedMdc = () => {
        if (isEmptyOrUndefined(justification.justification)) {
            return message('info', 'Campo obligatorio', 'Se requiere una justificación')
        }
        let newData = { ...data, id: Date.now(), justification: justification.justification, isRequ: true }
        tmpArr.push(newData)
        formatData(tmpArr)
        setData({
            medicine: "",
            qty: "",
            unity: "",
            each: "",
            time: "",
            during: "",
            period: "",
            way: "",
            eye: "",
            observations: "",
            id: "",
            presentationNumber: "",
            presentationDescr: "",
            orderType: "",
        })
        setReqItems(reqItems + 1)
        setJustification({ showModal: false, justification: "" })

    }
    const handleDiagnostics = (e) => {
        setCurrentDiagnostics(e)
        let arrIdEnd = [];
        if (e.length > 0) {
            e.forEach((elem) => {
                arrIdEnd.push(elem.value);
            });
        }
        setAdvSearch({ ...advSearch, diagnostics: arrIdEnd });
    }
    const handleFilter = (e) => {
        let nombreEncontrados
        if (isEmptyOrUndefined(e)) {
            setFoundedDiagnostics([{ value: "", label: "Digite el número del diagnóstico", isDisabled: true }])
        }
        if (e.length > 0) {
            let expresion = new RegExp(`${e?.toUpperCase()}.*`, "i");
            if (e.length > 0) {
                nombreEncontrados = unities?.diagnostics?.results?.filter(dat => expresion.test(dat.codigo));
                setFoundedDiagnostics(formatToRcSelect(nombreEncontrados, "codigo", "codigo", "", "", "descripcion", false))
            }
            else {
                setFoundedDiagnostics([{ value: "", label: "Digite el número del diagnóstico", isDisabled: true }])
            }
        }
    }

    async function getMdc(id) {
        await getMedicineCloneList({
            url: '/medical/medicine/',
            objFilters: {
                idAccount: idEnterprise,
                slim: 1,
                contract: event?.event?.contractCorpClient,
                diagnostic: event?.event?.incomingDiag?.value,
                // orderType: item?.mdcId,
                orderType: id,
            },
            token: token,
        })
    }
    useEffect(() => {
        /* ------------------ TO COMPARE WHAT COMES WITH WHAT GOES ------------------ */
        // ortId: e?.orderType?.extra,
        // omeIdOrderUnit: e?.medicine?.extra6,
        // timIdFrequency: e?.time.value,
        // timIdPeriod: e?.period?.value,
        // draId: e?.way.value,
        // omeIdPresentation: e?.presentationNumber,
        // dboId: e?.eye?.value,
        // mdcId: e?.medicine?.value,
        // meoQty: e?.qty,
        // meoQtyFrequency: e?.time?.value,
        // meoQtyPeriod: e?.during,
        // meoObservations: e?.observations,
        // meoQtyPresentation: e?.presentationDescr,
        // justification: e.justification
        /* ----------------- BURNED DATA TO MAKE THIS WORK PROPERTY ----------------- */
        if (isCloning === true) {
            const formattedOrderTypeIn = orderTypeToCloneList.results?.length > 0 ? orderTypeToCloneList.results?.map((e, i) => {
                return {
                    value: e?.name,
                    extra: e?.id,
                    label: <div key={`orderType${trigger}`} ><img alt="sign" src={
                        e?.name === "Particular" ?
                            poundSign :
                            e?.name === "Aseguradora" ?
                                pillDarkBlue : ordSugess
                    } width="15px" /> &nbsp;{e?.name}</div>
                }
            }) : []
            let newCloneArr = []
            medicineCloneArr?.medicine?.forEach(item => {
                getMdc(item?.mdcId)
                newCloneArr.push({
                    during: item?.period?.meoQtyPeriodSTR,
                    each: item?.frequency?.meoQtyFrequencySTR,
                    eye: formattedBodyOrgans.find(x => x.value === item?.dbo?.dboId),
                    generalObservations: medicineCloneArr.morObservations,
                    id: item?.mdcId,
                    isRequ: item?.reqJust?.isReq === 1 ? true : false,
                    medicine: formattedMedicines?.find(x => x.value === item?.mdcId),
                    observations: item?.meoObservations,
                    orderType: formattedOrderTypeIn.length > 0 && formattedOrderTypeIn?.find(x => x?.value === item?.medOrderType?.name),
                    period: formattedTimes?.find(x => x.value === item?.period?.timIdPeriod),
                    presentationDescr: item?.presentation?.omeDescription,
                    presentationNumber: item?.presentation?.mdcQtyPerPresentationSTR,
                    qty: item.presentation?.meoQtyPresentationSTR,
                    time: formattedTimes?.find(x => x.value === item?.frequency?.timIdFrequency),
                    way: formattedWays.find(x => x.value === item?.dra?.draId),
                    justification: item?.reqJust?.justification || "",
                })
            })
            return setTmpArr(newCloneArr)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isCloning,
        formattedMedicines.length,
        formattedBodyOrgans.length
    ])
    const handleBringMedicineAdvSearch = () => {
        if (!isEmptyOrUndefined(advSearch.selectedMdc)) {
            let foundedMdc = formattedMedicines.length > 0 && formattedMedicines.find(x => x.value === advSearch.selectedMdc)
            setData({ ...data, medicine: foundedMdc, isAdvSearch: true });
            setAdvSearch({ ...advSearch, selectedMdc: "", showModal: false })
        } else {
            return message('info', 'Seleccione un medicamento', ' Debe seleccionar al menos un médicamento')
        }
    }
    // //console.log("fullDialogScreen => ", fullDialogScreen)
    return (
        <>
            {/* /* --------------------------------- DIALOG --------------------------------- */}
            <FullScreenDialog
                id="FullScreenDialog"
                onHide={() => setFullDialogScreen(false)}
                isOpen={fullDialogScreen.show} >
                {reqItems > 0 &&
                    <OrdCustomToast
                        position='bottomLeft'
                        width='42%'
                        text={`Se ha(n) enviado (${reqItems}) solicitud(es) de medicamento(s) a dirección médica `}
                        onClose={() => setReqItems(0)}
                    />}
                <RenderMedicalFormulasPdf
                    withPagination
                    pdfParams={{
                        url: '/medical/pdfMedicationOrder/',
                        id: fullDialogScreen.id,
                    }}
                    title={'Fórmula de medicamentos'}
                    finalize={{
                        title: "Finalizar",
                        finalizeAction: () => {
                            setData({
                                medicine: "",
                                qty: "",
                                unity: "",
                                each: "",
                                time: "",
                                during: "",
                                period: "",
                                way: "",
                                eye: "",
                                observations: "",
                                id: "",
                                presentationNumber: "",
                                presentationDescr: "",
                                orderType: {},
                                generalObservations: ""
                            });
                            setTmpArr([])
                            setReqItems(0)
                            message('success', 'Orden(es) finalizada')
                            setFullDialogScreen(false)
                        }
                    }}
                    backAction={() => {
                        setData({
                            medicine: "",
                            qty: "",
                            unity: "",
                            each: "",
                            time: "",
                            during: "",
                            period: "",
                            way: "",
                            eye: "",
                            observations: "",
                            id: "",
                            presentationNumber: "",
                            presentationDescr: "",
                            orderType: {},
                            generalObservations: ""
                        });
                        setReqItems(0)
                        setTmpArr([])
                        setFullDialogScreen(false)
                    }}
                />
            </FullScreenDialog>

            <div className="mx-4 mt-4 pl-5">
                {unitiesLoader && loader}
                {bodyOrgansLoader && loader}
                {createOrderLoading && loader}
                {medicinesLoader && loader}
                {orderTypeToCloneLoader && loader}
                {orderTypeLoader && loader}
                {/* /* ------------------------------- ADVANCED SEARCH MODAL ------------------------------ */}
                <OrdModal
                    title={"Búsqueda avanzada"}
                    show={advSearch.showModal}
                    btnYesName="Seleccionar"
                    btnNoName="Cancelar"
                    size="940"
                    btnYesEvent={() => handleBringMedicineAdvSearch()}
                    onHide={() => {
                        setAdvSearch({ ...advSearch, showModal: false })
                    }}
                    btnNoEvent={() => {
                        setAdvSearch({ ...advSearch, showModal: false })
                    }}
                >
                    {/* /* --------------------------- Special form Group --------------------------- */}
                    < Form.Group
                        className={`mb-4 pt-2 text-start`} controlId="cc">
                        <Row className={`px-1`}>
                            <Col className={`${tableStyles.bgClearBlue} ${tableStyles.bdRadius10}`} xs={3}>
                                <p className={`${tableStyles.ordDarkBlueText} pt-2 mb-0`}>
                                    <b>
                                        Buscar por:
                                    </b>
                                </p>
                                <Form.Group className="" controlId="ordType">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f13}`}>
                                        Palabras clave
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(e) => {
                                            setAdvSearch({ ...advSearch, keyWords: e.target.value })
                                        }}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                setTriggerAdvSch(triggerAdvSch + 1)
                                            }
                                        }}
                                        key={'key' + trigger}
                                        value={advSearch?.keyWords}
                                        className={`ord-roundInput`} type="text" placeholder="" />
                                </Form.Group>
                                <Form.Group className="" controlId="ordType">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f13}`}>
                                        Presentación
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        onChange={(e) => setAdvSearch({ ...advSearch, idPresentation: e?.value })}
                                        value={formattedPresentations.find(x => x.value === advSearch.idPresentation)}
                                        options={formattedPresentations}
                                        className="text-secondary"
                                        placeholder={"Seleccione"}
                                        styles={ordCustomSelect}
                                    ></Select>
                                </Form.Group>
                                <Form.Group className="" controlId="ordType">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f13}`}>
                                        Diagnósticos
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        isMulti
                                        onChange={(e) => {
                                            handleDiagnostics(e)
                                        }}
                                        onInputChange={(e) => {
                                            handleFilter(e)
                                        }}
                                        value={
                                            currentDiagnostics
                                        }
                                        components={animatedComponents}
                                        id="controllable-states-demo"
                                        options={foundedDiagnostics}
                                        className="text-secondary"
                                        placeholder={"Seleccione"}
                                        styles={customSelectMulti}
                                    ></Select>
                                </Form.Group>
                                <p className={`${tableStyles.ordDarkBlueText} pt-2`}>
                                    <b>
                                        Organizar por:
                                    </b>
                                </p>

                                <Row className={`d-fle`} >
                                    <Col className={`p-0 m-0`} xs={10}>
                                        <Col className={`d-flex`} xs={12}>

                                            <label
                                                for={`createdAt`}
                                                className={`cursorPointer text-secondary ${tableStyles.f13}`}
                                            >
                                                <Radio
                                                    checked={advSearch.radio === 'createdAt'}
                                                    onChange={(e) => handleRadioChange(e)}
                                                    value="createdAt"
                                                    name="createdAt"
                                                    className={'text-info'}
                                                    id="createdAt"
                                                />
                                                Fecha de creación
                                            </label>
                                        </Col>
                                        <Col className={`d-flex`} xs={12}>

                                            <label
                                                for={`frequency`}
                                                className={`cursorPointer text-secondary ${tableStyles.f13}`}
                                            >   <Radio
                                                    checked={advSearch.radio === 'frequency'}
                                                    onChange={(e) => handleRadioChange(e)}
                                                    value="frequency"
                                                    name="frequency"
                                                    className={'text-info'}
                                                    id="frequency"
                                                />
                                                Frecuencia de uso
                                            </label>
                                        </Col>
                                        <Col className={`d-flex`} xs={12}>

                                            <label
                                                for={`alphabetic`}
                                                className={`cursorPointer text-secondary ${tableStyles.f13}`}
                                            ><Radio
                                                    checked={advSearch.radio === 'alphabetic'}
                                                    onChange={(e) => handleRadioChange(e)}
                                                    value="alphabetic"
                                                    name="alphabetic"
                                                    className={'text-info'}
                                                    id="alphabetic"
                                                />
                                                Orden alfabético
                                            </label>
                                        </Col>
                                    </Col>
                                </Row>
                                <Col className={`d-flex justify-content-center cursorPointer p-0 pt-3 pb-2`} xs={12}>
                                    <button
                                        onClick={() => {
                                            setAdvSearch({
                                                showModal: true,
                                                radio: '',
                                                diagnostics: '',
                                                idPresentation: '',
                                                selectedMdc: '',
                                                keyWords: ''
                                            })
                                            setCurrentDiagnostics(null)
                                        }}
                                        className={`mr-2  btn ${tableStyles.ordBtnSecondary}`}>
                                        Limpiar filtros</button>
                                </Col>
                            </Col>
                            {/* /* ------------------------------- Table Side ------------------------------- */}
                            <Col className={`${tableStyles.scrollThis}`} xs={9}
                                style={{ height: '89vh' }}
                            >
                                {loaderAdvSrch && loader}
                                <OrdTable
                                    key={trigger}
                                    className={`${tableStyles.shade} ${tableStyles.bdRadius10}  my-0`}
                                    headers={advSearchHeaders}
                                    hasChildren={true}
                                >
                                    {formatDataAdvSearch()}
                                </OrdTable>
                            </Col>

                        </Row>
                    </Form.Group>
                </OrdModal >
                {/* /* --------------------------- JUSTIFICATION MODAL -------------------------- */}
                <OrdModal
                    title={"Justificación"}
                    show={justification.showModal}
                    btnYesName="Aceptar"
                    btnNoName="Cancelar"
                    size="700"
                    btnYesEvent={() => addJustifiedMdc()}
                    onHide={() => {
                        setJustification({ ...justification, showModal: false })
                    }}
                    btnNoEvent={() => {
                        setJustification({ ...justification, showModal: false })
                    }}
                >
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="fName">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                <b className="text-start">&nbsp;Explique la razón por la que solicita este medicamento</b>
                            </Form.Label>
                            <textarea
                                placeholder={"Escribir..."}
                                value={justification.justification}
                                onChange={(e) => {
                                    setJustification({ ...justification, justification: e.target.value });
                                }}
                                rows="25"
                                cols="55"
                                style={{ height: "7rem" }}
                                className={`text-secondary ord-roundInput w-100`}
                            ></textarea>
                        </Form.Group></Col>
                </OrdModal>
                {/* /* --------------------------- DATE MODAL --------------------------- */}
                <OrdModal
                    title={"Fecha de la orden"}
                    show={modalDate.show}
                    btnYesName="Aceptar"
                    btnNoName="Cancelar"
                    size="700"
                    btnYesEvent={() => handleSubmit()}
                    onHide={() => {
                        setModalDate({ show: false })
                    }}
                    btnNoEvent={() => {
                        setModalDate({ show: false })
                    }}
                >
                    <Col xs={12}>
                        <Form.Group controlId="startDate">
                            <Form.Label
                                className={`m-0 ${tableStyles.ordDarkBlueText} `}
                            >
                                <b className="text-start">&nbsp;Validez a partir de</b>
                            </Form.Label>
                            <Form.Control
                                className={`ord-roundInput ${tableStyles.ordGrayOrdersInputs}`}
                                type="date"
                                placeholder=""
                                onChange={(e) => {
                                    setModalDate({ ...modalDate, morDate: e.target.value });
                                }}
                                min={today()}
                            />
                        </Form.Group>
                    </Col>

                </OrdModal>
                {/* /* --------------------------- Special form Group --------------------------- */}
                < Form.Group
                    className={`${tableStyles.ordSearchSections} mb-4 mx-5 pt-2 text-start`} controlId="cc" >
                    <Row>
                        <Col className={`d-flex justify-content-end`} xs={12}>
                            <u
                                onClick={() => setAdvSearch({ ...advSearch, showModal: true })}
                                className={`${tableStyles.ordClearBlueText} cursorPointer`}>
                                Búsqueda avanzada
                            </u>
                            &nbsp;
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className={`px-2`}>
                        <Col xs={3}>
                            <Form.Group className="mb-3 text-start" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                    <b>&nbsp;Tipo de orden</b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    value={data.orderType}
                                    key={'orderType' + trigger2}
                                    options={formattedOrderType}
                                    onChange={(e) => setData({ ...data, orderType: e, medicine: "" })}
                                    className="text-secondary"
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                ></Select>
                            </Form.Group></Col>
                        <Col xs={9} className="pt-4">
                            <Autocomplete
                                noOptionsText={'No se encuentra'}
                                value={data.medicine}
                                sx={{
                                    input: {
                                        color: data?.medicine?.extra8 === "#F39682" ?
                                            data?.medicine?.extra8 : "#6e6f7c"
                                    }
                                }}
                                onChange={(event, newValue) => {
                                    setData({ ...data, medicine: newValue, isAdvSearch: false })
                                }}
                                onInputChange={(event, newValue) => {
                                    if (!data.isAdvSearch) { setData({ ...data, medicine: newValue }) } else { setData({ ...data, isAdvSearch: false }) };
                                }}
                                id="Medicines"
                                renderOption={(props, option) => {
                                    const { label, extra7, extra8 } = option;
                                    return (
                                        <span
                                            title={
                                                extra8 === "#F39682" ? 'Requiere autorización' :
                                                    extra7 === 1 && 'incompatible'
                                            }
                                            {...props} style={{ color: extra8 || '#6e6f7c', borderBottom: '1px solid #cecece' }}>
                                            {label}
                                        </span>
                                    );
                                }}
                                getOptionDisabled={(option) => option.extra7 === 1}
                                options={formattedMedicines}
                                renderInput={(params) =>
                                    <TextField
                                        placeholder="Seleccionar medicamento"
                                        {...params} label="" />}
                            />
                        </Col>
                    </Row>
                    {/* #2 */}
                    <Row>
                        <Col xs={12}>
                            <span
                                style={{
                                    letterSpacing: "-0.8px",
                                    fontSize: "12px"
                                }}
                                className={` px-2 text-secondary fw-normal`}>
                                Los medicamentos recetados como particular serán asumidos por el paciente y no se les generará mipres en caso de ser no pos.
                            </span>
                        </Col>
                    </Row>
                    <Row className={`px-2`}>
                        <Col xs={3}>
                            <Form.Group className="mb-3" controlId="ordType">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                    <b>&nbsp;Cantidad</b>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    value={data.qty}
                                    onChange={(e) => {
                                        setData({ ...data, qty: e.target.value });
                                    }}
                                    className={`ord-roundInput`}
                                    placeholder=""
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={3}>
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">&nbsp;Unidad</b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    value={[{ value: data?.medicine?.value, label: data?.medicine?.extra4 }]}
                                    isDisabled
                                    className="text-secondary"
                                    placeholder={"Unidad"}
                                    styles={ordCustomSelect}
                                ></Select>
                            </Form.Group></Col>
                        <Col xs={3}>
                            <Form.Group className="mb-3" controlId="ordType">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                    <b>&nbsp;Cada</b>
                                </Form.Label>
                                <Form.Control
                                    value={data.each}
                                    onChange={(e) => {
                                        setData({ ...data, each: e.target.value });
                                    }}
                                    className={`ord-roundInput`} type="number" placeholder="" />
                            </Form.Group></Col>
                        <Col xs={3}>
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">&nbsp;Tiempo</b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    value={data.time}
                                    options={formattedTimes}
                                    className="text-secondary"
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                    onChange={(e) => setData({ ...data, time: e })}
                                ></Select>
                            </Form.Group></Col>
                    </Row>
                    {/* #3 */}
                    <Row className={`px-2`}>
                        <Col xs={3}>
                            <Form.Group className="mb-3" controlId="ordType">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                    <b>&nbsp;Durante</b>
                                </Form.Label>
                                <Form.Control
                                    options={formattedTimes}
                                    value={data.during}
                                    onChange={(e) => {
                                        setData({ ...data, during: e.target.value });
                                    }}
                                    className={`ord-roundInput`} type="number" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col xs={3}>
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">&nbsp;Período</b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={formattedTimes}
                                    value={data.period}
                                    onChange={(e) => {
                                        setData({ ...data, period: e });
                                    }}
                                    className="text-secondary"
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                ></Select>
                            </Form.Group></Col>
                        <Col xs={3}>
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">&nbsp;Vía</b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={formattedWays}
                                    value={data.way}
                                    onChange={(e) => {
                                        setData({ ...data, way: e })
                                        if (!isEmptyOrUndefined(e.value)) {
                                            getBodyOrgans({
                                                url: "/medical/bodyOrgans/",
                                                objFilters: { draId: e.value },
                                                token: token
                                            })
                                        }
                                    }}
                                    className="text-secondary"
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                ></Select>
                            </Form.Group></Col>
                        {
                            bodyOrgans?.results?.length > 0 &&
                            <Col xs={3}>
                                <Form.Group className="mb-3" controlId="fName">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                        <b className="text-start">&nbsp;Órgano</b>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        options={formattedBodyOrgans}
                                        value={data.eye}
                                        onChange={(e) => {
                                            setData({ ...data, eye: e })
                                        }}
                                        className="text-secondary"
                                        placeholder={"Seleccione"}
                                        styles={ordCustomSelect}
                                    ></Select>
                                </Form.Group></Col>}
                    </Row>
                    {/* #4 */}
                    <Row className={`px-2`}>
                        <Col xs={3}>
                            <Form.Group className="mb-3" controlId="ordType">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                    <b>&nbsp;&nbsp;Presentación</b>
                                </Form.Label>
                                <Row className={`d-flex align-items-center  `}>
                                    <Col
                                        className={` ${tableStyles.f13} d-flex align-items-center bg-light text-secondary ml-3 tLeftBLeftBorder justify-content-center`}
                                        xs={5}
                                    >
                                        <span>
                                            {data.presentationNumber}
                                        </span>
                                    </Col>
                                    <Col
                                        className={` ${tableStyles.f12} d-flex align-items-center bg-light text-secondary tRighttBrightBorder justify-content-center text-center`}
                                        xs={5}
                                    >
                                        <span >
                                            {data.presentationDescr}
                                        </span>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col xs={8}>
                            <Form.Group className="mb-3" controlId="fName">
                                {/* <form onSubmit={(e) => {
                                e.preventDefault();
                                handleAddToTable()
                            }}> */}
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">&nbsp;Observaciones</b>
                                </Form.Label>
                                <textarea
                                    placeholder={"Escribir..."}
                                    value={data.observations}
                                    onChange={(e) => {
                                        setData({ ...data, observations: e.target.value });
                                    }}
                                    rows="25"
                                    cols="55"
                                    style={{ height: "2.2rem" }}
                                    className={`text-secondary ord-roundInput w-100`}
                                ></textarea>
                                {/* </form> */}
                            </Form.Group></Col>
                        <Col xs={1} className="pt-4"
                            onClick={() => handleAddToTable()}
                        >
                            <img
                                className="cursorPointer"
                                width={"28px"}
                                alt="Añadir"
                                src={orangeNoBGArrow}>
                            </img>
                        </Col>
                    </Row>

                </Form.Group >

                <div
                    style={{ marginBottom: '15%' }}
                    className={` mx-5 pt-2 text-start`}>
                    <OrdTable
                        isAnAccTable
                        key={`ordT${trigger}`}
                        headers={tempTable.headers}
                        hasChildren={true}
                    >
                        {formatData()}
                    </OrdTable>

                    <Col className="mt-5" xs={12}>
                        <Form.Group className="mb-3" controlId="fName">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                <b className="text-start">&nbsp;Observación general</b>
                            </Form.Label>
                            <textarea
                                onChange={(e) => setData({ ...data, generalObservations: e.target.value })}
                                value={data.generalObservations}
                                placeholder={"Escribir..."}
                                rows="25"
                                cols="55"
                                style={{ height: "4rem" }}
                                className={`text-secondary ord-roundInput w-100`}
                            ></textarea>
                        </Form.Group>
                    </Col>
                </div>
                <Row className={`${tableStyles.ordBorderTop}`}>
                    <Col xs={11} className="d-flex justify-content-end pt-3">
                        <button
                            onClick={() => setAdvSearch({ ...advSearch, show: false })}
                            className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                        {
                            myPermission?.create && (
                                <button
                                    onClick={() =>
                                        tmpArr.length > 0 ? swalConfirm({
                                            title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                                            text: `Se creará(n) orden(es) de medicamento(s) para ${event?.event?.firstName} ${event?.event?.lastName}`,
                                            confirmButtonText: "Si, continuar",
                                            doAfterConfirm: () => handleSubmit(),
                                        }) : message('info', 'Sin ordenenes')
                                    }
                                    className={`${tableStyles.ordBtnPrimary} btn`}>
                                    <img
                                        src={miniRoundWhiteCheck}
                                        className={``}
                                        alt="check"
                                    >
                                    </img>
                                    &nbsp;&nbsp;Ordenar
                                </button>
                            )
                        }
                    </Col>
                </Row>
            </div >
        </>
    )
}