import { Box } from '@mui/material'
import React from 'react'

export const Text = ({ title, subtitle }) => {
  return (
    <Box>
      <span className={`fw-bold`}>{title}:</span>
      <span>&nbsp;{subtitle}</span>
    </Box>
  )
}
