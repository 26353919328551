import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import { useHistory, } from "react-router-dom/cjs/react-router-dom.min";
import backIcon from "../../../assets/img/icons/atras-icon.svg";
import alertCircle from "../../../assets/img/icons/alert-circle-orange.svg";
import rejectedIcon from "../../../assets/img/icons/extraBtnRedX.svg";
import check from "../../../assets/img/icons/check2.svg";
import PersonIcon from "../../../assets/img/icons/anonymus.svg";
import note from "../../../assets/img/icons/ordSugess.svg";
import { Col, OverlayTrigger, Row } from "react-bootstrap";
import { convertMoneyFormat, isEmptyOrUndefined, loader, validateEmptyString } from "../../../helpers/helpers";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { useGetMethod, usePostMethod } from "../../../Hooks/useFetch";
import ModalNew from "../../Layouts/ModalNew";
import { customSwaltAlert } from "../../../helpers/customSwaltAlert";

const EmployeeDetailAuth = ({ employeeDetail, setEmployeeDetail, setData, dataIn, bonus_pay_data }) => {
 const store = useSelector((state) => state);
 const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "planCes" || x.functionality?.prefix === "planPrim" );
 const token = store.loginReducer.Authorization;
 const history = useHistory();
 const { load: sendCommentLoader, trigger: sendCommentMethod } = usePostMethod();
 const { results: detailInfoResults, load: detailInfoLoader, trigger: getDetailInfoMethod } = useGetMethod();
 const eaccount = store.loginReducer.currentAccount.id;
 const data = employeeDetail.dataEmployee;
 const dataPayroll = employeeDetail?.dataPayroll;
 useEffect(() => {
  if (!!!myPermission?.read) {
   history.push("/nomina/inicio");
  }
  let body = {
   candidateId: data.candidateId,
   eaccount: eaccount,
   ...(employeeDetail.page === 'severance' && { year: dataPayroll.detailPage.paymentYear })
  };
  if (employeeDetail.page === 'bonus'){
    if (!isEmptyOrUndefined(bonus_pay_data?.startDate) && !isEmptyOrUndefined(bonus_pay_data?.endDate)){
      body = {
        ...body,
        startDate: bonus_pay_data.startDate,
        endDate: bonus_pay_data.endDate
      }
    } else {
      body = {
        ...body,
        monthRange: dataPayroll.detailPage.range,
        year: dataPayroll.detailPage.paymentYear,
      }
    }
  }

  setCommentsModal({
   ...setCommentsModal,
   comments: data?.comment,
  });
  getDetailInfoMethod({
   url: "/payroll/detailEmployeeDeduAccru",
   objFilters: body,
   token: token,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 const goBack = () => {
  setEmployeeDetail({ ...employeeDetail, show: false, trigger: employeeDetail.trigger + 1 });
  setData({ ...dataIn, trigger: dataIn.trigger + 1 });
 };
 const renderTooltipMessage = () => (
  <Tooltip>
   <span> Añadir comentario </span>
  </Tooltip>
 );

 const [commentsModal, setCommentsModal] = useState({
  show: false,
  comments: data?.nameEmployee,
  detail: false,
 });
 const sendComment = () => {
  let validations = validateEmptyString([{ field: commentsModal.comments, message: "El comentario es obligatorio" }]);

  let urlPut = employeeDetail.page !== "severance" ? "/payroll/detail_bonus_pay" : "/payroll/severance/detail/";
  let body =
   employeeDetail.page !== "severance"
    ? {
       idDetail: data.id,
       comment: commentsModal.comments,
       eaccount: eaccount,
      }
    : {
       severanceDetId: data.severanceDetId,
       comment: commentsModal.comments,
       eaccount: eaccount,
      };
  if (validations.valid) {
   sendCommentMethod({
    url: urlPut,
    method: "PUT",
    body: body,
    token: token,
    succesAction: (info) => {
     closeCommentModal();
     customSwaltAlert({
      icon: "success",
      title: "El comentario ha sido enviado",
      confirmButtonText: "Aceptar",
      showCancelButton: false,
     });
     setData({ ...dataIn, trigger: dataIn.trigger + 1 });
    },
    doAfterException: (error) => {
     customSwaltAlert({
      icon: "error",
      title: error.message,
      confirmButtonText: "Aceptar",
      showCancelButton: false,
     });
    },
   });
  }
 };
 const closeCommentModal = () => {
  setCommentsModal({ ...commentsModal, show: false });
 };

 const renderDetailPayment = () =>{
return (
    <>
      <Row className="my-2">
       <span className={`${tableStyles.darkBlueText} ${tableStyles.f20} fw-bold  pb-4`}>
        {employeeDetail.page !== "severance" ? "Pago de primas" : "Pago de cesantías e intereses"}{" "}
       </span>
       <br />
      </Row>
      <div className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
       <Row>
        <Col xs={6}>
         <span className={` fw-bold`}>Tipo de documento</span>
        </Col>
        <Col xs={6}>{employeeDetail.page !== "severance" ? data?.docEmployee : data?.docNumber}</Col>
       </Row>
       <Row>
        <Col xs={6}>
         <span className={` fw-bold`}>No. de documento</span>
        </Col>
        <Col xs={6}>{employeeDetail.page !== "severance" ? data?.docEmployee : data?.docNumber}</Col>
       </Row>
       <Row>
        <Col xs={6}>
         <span className={` fw-bold`}>Salario</span>
        </Col>
        <Col xs={6}>{convertMoneyFormat(employeeDetail.page !== "severance" ? data?.salaryEmployee : data?.averageSalary ?? "-")}</Col>
       </Row>
       <Row>
        <Col xs={6}>
         <span className={` fw-bold`}>Dias laborados</span>
        </Col>
        <Col xs={6}>{employeeDetail.page !== "severance" ? data?.workDays : data?.totalDaysWork}</Col>
       </Row>
       {employeeDetail.page !== "severance" ? (
        <>
         <Row>
          <Col xs={6}>
           <span className={` fw-bold`}>Primas</span>
          </Col>
          <Col xs={6}>{convertMoneyFormat(data?.bonusPayment ?? "-")}</Col>
         </Row>
         <Row>
          <Col xs={6}>
           <span className={` fw-bold`}>Primas INCS</span>
          </Col>
          <Col xs={6}>{convertMoneyFormat(data?.inscBonusPayment ?? "-")}</Col>
         </Row>
        </>
       ) : (
        <>
         <Row>
          <Col xs={6}>
           <span className={` fw-bold`}>Cesantías</span>
          </Col>
          <Col xs={6}>{convertMoneyFormat(data?.severancePayment ?? "-")}</Col>
         </Row>
         <Row>
          <Col xs={6}>
           <span className={` fw-bold`}>Cesantías INCS</span>
          </Col>
          <Col xs={6}>{convertMoneyFormat(data?.severancePaymentInsc ?? "-")}</Col>
         </Row>
         <Row>
          <Col xs={6}>
           <span className={` fw-bold`}>Intereses</span>
          </Col>
          <Col xs={6}>{convertMoneyFormat(data?.severanceInterest ?? "-")}</Col>
         </Row>
         <Row>
          <Col xs={6}>
           <span className={` fw-bold`}>Intereses INCS</span>
          </Col>
          <Col xs={6}>{convertMoneyFormat(data?.severanceInterestInsc ?? "-")}</Col>
         </Row>
        </>
       )}
       {employeeDetail.page !== "severance" && (
        <Row>
         <Col xs={6}>
          <span className={` fw-bold`}>Total a pagar</span>
         </Col>
         <Col xs={6}>{convertMoneyFormat((Number(data?.bonusPayment) + Number(data?.inscBonusPayment)).toFixed(2)) ?? "-"}</Col>
        </Row>
       )}
      </div>
     </>
)
 }

 const renderDetailAccrued = ()=>{
    return <>
    <Row>
         <span className={`${tableStyles.darkBlueText} ${tableStyles.f15} fw-bold  pb-4`}>Devengados </span>
         <br />
        </Row>
        <div className="my-2">
         {detailInfoResults?.results?.accrued?.length > 0 ? (
          detailInfoResults?.results?.accrued?.map((elem, index) => {
           return (
            <Row className={`${tableStyles.f12}`}>
             <div className="d-flex">
                <Col xs={4} className="d-flex justify-content-end">
              <span className={`${tableStyles.darkBlueText} fw-bold  mx-2`}>{convertMoneyFormat(elem?.avg.toFixed(2))} </span>
                </Col>
              <br />
              <Col xs={6} className="d-flex">
               <div>
                <div
                 style={{
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  border: "2px solid #005DBF",
                  width: "10px",
                  height: "10px",
                  marginRight: "-6px",
                  position: "relative",
                 }}
                >
                 &nbsp;
                </div>
                <div></div>
               </div>
               <div style={{ borderLeft: "2px solid #005dbf", paddingLeft: "10px" }}>
                <span className={`${tableStyles.darkGrayText} fw-bold`}>{elem?.label}</span>
                {elem.info.length > 0 &&
                 elem.info.map((dev, ind) => {
                    let textKey;
                    let keyValue;
                    if (elem.label === "Horas extras"){
                        keyValue= dev.overtimeDate
                        textKey = `${dev?.hourCounted} ${dev?.overtimeDescription} x ${convertMoneyFormat(dev?.hourPaid.toFixed(2))} `
                    }else if (elem.label === "Comisiones"){
                        keyValue= dev?.monthName
                        textKey = `${convertMoneyFormat(dev?.commisionAmount.toFixed(2))}`
                    }else{
                        keyValue= dev?.monthName
                        textKey = `${convertMoneyFormat(dev?.amount.toFixed(2)) }  `
                    }

                  return (
                   <div>
                    <span className={`${tableStyles.darkBlueText}  mr-2`}>{keyValue} |</span>
                    <span className={`${tableStyles.darkGrayText}`}>{textKey}</span>
                   </div>
                  );
                 })}
               </div>
              </Col>
             </div>
            </Row>
           );
          })
         ) : (
          <div className={`${tableStyles.darkGrayText} ${tableStyles.f12}`}>El empleado no tiene devengados registrados</div>
         )}
        </div>
    </>
 }

 const renderDetailDeductions = ()=>{
   return <>
    <Row>
         <span className={`${tableStyles.darkBlueText} ${tableStyles.f15} fw-bold  pb-4`}>Deducciones </span>
         <br />
        </Row>
        <div className="my-2">
        {detailInfoResults?.results?.deductions?.length > 0 ? (
          detailInfoResults?.results?.deductions?.map((elem, index) => {
           return (
            <Row className={`${tableStyles.f12}`}>
             <div className="d-flex">
                <Col xs={4} className="d-flex justify-content-end">
              <span className={`${tableStyles.darkBlueText} fw-bold  mx-2`}>{convertMoneyFormat(elem?.avg.toFixed(2))} </span>
                </Col>
              <br />
              <Col xs={6} className="d-flex">
               <div>
                <div
                 style={{
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  border: "2px solid #005DBF",
                  width: "10px",
                  height: "10px",
                  marginRight: "-6px",
                  position: "relative",
                 }}
                >
                 &nbsp;
                </div>
                <div></div>
               </div>
               <div style={{ borderLeft: "2px solid #005dbf", paddingLeft: "10px" }}>
                <span className={`${tableStyles.darkGrayText} fw-bold`}>{elem?.label}</span>
                {elem.info.length > 0 &&
                 elem.info.map((acc, ind) => {
                    let textKey;
                    let keyValue;
                    if (elem.label === "Permisos"){
                        keyValue= acc.monthName
                        textKey = `${convertMoneyFormat(acc?.permissionAmount.toFixed(2))}`
                    }else{
                        keyValue= acc?.monthName
                        textKey = `${acc?.amount}  `
                    }
                  return (
                   <div>
                    <span className={`${tableStyles.darkBlueText}  mr-2`}> {keyValue} |</span>
                    <span>{textKey}</span>
                   </div>
                  );
                 })}
               </div>
              </Col>
             </div>
            </Row>
           );
          })
         ) : (
          <div className={`${tableStyles.darkGrayText} ${tableStyles.f12}`}>El empleado no tiene deducciones registradas</div>
         )}
        </div>
    </>
 }
 return (
  <>
   {(sendCommentLoader || detailInfoLoader) && loader}
   <ModalNew
    title="Comentario"
    show={commentsModal.show}
    btnYesName={"Guardar"}
    btnNoName={"Cerrar"}
    size="600"
    btnYesEvent={sendComment}
    btnYesDisabled={data?.comment === "" || data?.comment === null ? false : true}
    onHide={() => closeCommentModal()}
    btnNoEvent={() => closeCommentModal()}
    btnNoDisabled={false}
   >
    <p className={tableStyles.crudModalLabel}>Comentarios de empleado</p>
    <textarea
     placeholder={"Escribir..."}
     id="txtArea"
     rows="10"
     cols="10"
     disabled={data?.comment === "" || data?.comment === null ? false : true}
     onChange={(e) => setCommentsModal({ ...commentsModal, comments: e.target.value })}
     defaultValue={commentsModal.comments}
     style={{
      height: "4rem",
      borderRadius: "5px",
      border: "1.5px solid #7FADDE",
      padding: "0 0.05rem",
      width: "100%",
     }}
     className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
    ></textarea>
    <p className={`${tableStyles.f12} ${tableStyles.ordDarkGrayText}`}>*El mensaje será enviado al autor de la planilla</p>
   </ModalNew>
   <div className="w-80 mx-4">
    <h2
     style={{ color: "#005dbf", marginBottom: "50px" }}
     className={`${tableStyles.title} fw-bold d-flex gap-3 align-items-center`}
    >
     <div>
      <img
       onClick={() => {
        goBack();
       }}
       src={backIcon}
       height={"30px"}
       alt="backArrow"
       className={` pointer`}
      />
     </div>
     <div className={`${tableStyles.iconUserCircleAccounting} mx-2`}>
      <img
       alt=""
       src={PersonIcon}
      />
     </div>
     <div>
      <div className="d-flex">
       {" "}
       {employeeDetail.page !== "severance" ? data?.nameEmployee : data?.candidateFullName}
       <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltipMessage()}
       >
        <img
         alt="goal"
         src={note}
         className={`align-self-center mx-2 ${tableStyles.svgFilterDarkBlue} cursorPointer`}
         onClick={() => {
          setCommentsModal({ ...commentsModal, show: true });
         }}
         height="30px"
         width="30px"
        />
       </OverlayTrigger>
      </div>
      {dataPayroll?.detailPage?.status?.name === "review" ? (
       <div>
        <div
         className="d-flex gap-2 align-items-center"
         style={{ margin: "10px 0px 0px 5px", color: "#ff8b00" }}
        >
         <span style={{ fontSize: "15px" }}>Revisión pendiente</span>
         <img
          height={20}
          src={alertCircle}
          alt="alertCircle"
         />
        </div>
       </div>
      ) : dataPayroll?.detailPage?.status?.name === "rejected" ? (
       <div>
        <div
         className="d-flex gap-2 align-items-center"
         style={{ margin: "10px 0px 0px 5px", color: "rgba(236, 28, 36, 1)" }}
        >
         <span style={{ fontSize: "15px" }}>Planilla rechazada</span>
         <img
          height={15}
          src={rejectedIcon}
          alt="rejectedIcon"
         />
        </div>
       </div>
      ) : (
       dataPayroll?.detailPage?.status?.name === "approved" && (
        <div>
         <div
          className="d-flex gap-2 align-items-center"
          style={{ margin: "10px 0px 0px 5px", color: "#00a551" }}
         >
          <span style={{ fontSize: "15px" }}>Planilla autorizada</span>
          <img
           height={20}
           src={check}
           alt="check"
          />
         </div>
        </div>
       )
      )}
     </div>
    </h2>
    <Row className="m-2 mt-6">
    <Col
      xs={4}
      className="px-4"
     >
        {renderDetailPayment()}
     </Col>
     <Col
      xs={8}
      style={{ backgroundColor: "#F5F7FA" }}
     >
      <Row className="py-2">
       <Col xs={6}>
        {renderDetailAccrued()}
       </Col>
       <Col xs={6}>
        {renderDetailDeductions()}
       </Col>
      </Row>
     </Col>
    </Row>
    <Grid className="d-flex justify-content-end gap-3 mt-3">
     <Button
      style={{ textTransform: "none", marginRight: "8px" }}
      className={tableStyles.btnCancelStyle}
      onClick={() => {
       goBack();
      }}
     >
      Cancelar
     </Button>
    </Grid>
   </div>
  </>
 );
};

export default EmployeeDetailAuth;
