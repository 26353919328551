// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import GenericTable from "../Layouts/GenericTableNew";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import { Col, Row } from "react-bootstrap";
import ModalNew from "../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import threeDots from "../../assets/img/icons/threeDots.svg";
import Gear from "../../assets/img/icons/engraneGris.svg";
import CustomPopupStyles from "../../components/Popup/customPopup.module.scss";

// --------------- icons 💥  ----------------//
import Lupa from "../../assets/img/icons/lupa.svg";
import Agregar from "../../assets/img/icons/agregarEspecialidad.svg";

//-------------- scss styles 😄   --------------
import Select from "react-select";
import { customSelectNew, customSelectNewDark } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Styles from "./Population.module.scss";

// ------------------ Functions ----------------------
import {
    getPopulation,
    createPopulation,
    getOnePopulation,
    changeStatusPopulation
} from "../../actions/populationActions";

import {
    getClient,
} from "../../actions/receiptOfInvoicesActions";
import Loader from "react-loader-spinner";
import GenericTableNew from "../Layouts/GenericTableNew";
import CustomPopup from "../Popup/customPopup";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import { formLabelClasses } from "@mui/material";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import { getPermission } from "../../helpers/helpers";

function TablePopulation(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const storage = useSelector(state => state);
    const listPermission = storage.loginReducer.currentAccount?.profile?.permission
    const [trigger, setTrigger] = useState(1);
    const [loading, setLoading] = useState(true);
    const [triggerModal, setTriggerModal] = useState(1);
    const [showModal, setShowModal] = useState({
        show: false
    });
    const [creatActivity, setCreatActivity] = useState({
        clientId: undefined,
        description: "",
        eaccount: storage.loginReducer.currentAccount.id,
        id: "",
        status: "",
    });
    const [info, setInfo] = useState({
        search: "",
        id_account: storage.loginReducer.currentAccount.id,
        page: 1,
        perpage: 10,
    });

    const myPermission = getPermission({ prefix: 'Population', listPermission })


    //--------------first functions 🍕   --------------

    useEffect(() => {
        if (!myPermission?.create || !myPermission?.read) {
            history?.push("/administracion/inicio");
        }
    }, []);

    useEffect(() => {
        dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
    }, []);


    useEffect(() => {
        setLoading(true)
        dispatch(getPopulation(info, () => setLoading(false)));

    }, [trigger]);

    useEffect(() => {
        if (!!showModal.selectedPopulation) {
            dispatch(
                getOnePopulation(
                    {
                        id_account: storage.loginReducer.currentAccount.id,
                        id: showModal.selectedPopulation,
                    },
                    setCreatActivity
                )
            );
        }
    }, []);

    //-------------- Table 🍕   --------------
    const renderHeaders = [
        // <th key={1} className={`text-center`}>Código</th>,
        <th key={2} className={`px-3`}>Descripción</th>,
        <th key={3}>Cliente-Empresa</th>,
        <th key={4} className={`text-center`}>
            Estado
        </th>,
        <th key={5}>
            &nbsp;
        </th>,
    ];

    const renderElement = (item, index) => {
        return (
            <tr key={index} className={``}>
                {/* <td className="col-1">{item.code}</td> */}
                <td className="col-6 text-start px-3">{item.description}</td>
                <td className="col-4 text-start">{item.clientName}</td>
                <td className="col-1">
                    <Tooltip title={!!(item.status === 1) ? "Habilitado" : "Inhabilitado"} arrow>
                        <div
                            className={`
                            ${IndividualStyles.popupReqActive} ${item.status === 0
                                    ? tableStyles.redState
                                    : tableStyles.greenState
                                }
                        `}
                        >{item.status ? 'Habilitado' : 'Inhabilitado'}</div>
                    </Tooltip>
                </td>
                <td className="col-1">
                    {
                        myPermission?.edit && (
                            <CustomPopup
                                triggerSrc={threeDots}
                                showEnable={myPermission?.delete}
                                
                                isEnabled={item.status === 0 ? false : true}
                                enableClickEvent={() => changeStatus(item.code, item.status, item.description)}
                                enableText={item.status === 0 ? "Habilitar" : "Deshabilitar"}
                                showEdit={myPermission?.edit}
                                editClickEvent={() => {
                                    setShowModal({ show: true, edit: true, selectedPopulation: item.code }); setTriggerModal(triggerModal + 1)
                                    setCreatActivity({
                                        description: item.description,
                                        clientId: item.clientId,
                                        status: item.status,
                                        id: item.code
                                    })
                                }}

                            />
                        )
                    }
                </td>
            </tr>
        );
    }

    const listElem = () => {
        let elemMap;
        if (storage.populationReducer.listPopulation !== undefined) {
            const elem2 = storage.populationReducer.listPopulation;
            elemMap = elem2.map((elem) => {
                return renderElement(elem);
            });

        }
        return elemMap;
    }

    //-------------- select options 🍕   --------------

    let optionsClient = [
        { key: "default", value: "", label: "Seleccionar...", id: "" },
    ];
    if (Array.isArray(storage.invoiceReducer.clients)) {
        storage.invoiceReducer.clients.map((item) => {
            optionsClient.push({
                value: item.id,
                label: item.copyName,
                key: item.id + "clients",
            });
        });
    }

    //--------------  Actions  🍕   --------------

    const creatNewActivity = () => {
        if (showModal.creat) {
            setLoading(true)
            dispatch(
                createPopulation({
                    eaccount: creatActivity.eaccount,
                    clientId: creatActivity.clientId,
                    description: creatActivity.description,

                },
                    () => {
                        setShowModal({ show: false })
                        setTrigger(trigger + 1)
                        setCreatActivity({
                            description: "",
                            clientId: "",
                            eaccount: storage.loginReducer.currentAccount.id

                        });

                    }
                )
            );
        } else {
            // dispatch(
            //     updateReteica({
            //     city: creatActivity.city,
            //     ciiu: creatActivity.ciiu,
            //     rate:creatActivity.rate,
            //     eaccount: storage.loginReducer.currentAccount.id,
            //     id: showModal.selectedReteICA

            // },
            //     ()=>{
            //         setShowModal({show:false})
            //         dispatch(getListReteICA({eaccount: storage.loginReducer.currentAccount.id}));
            //         setCreatActivity({
            //             city:"",
            //             ciiu:"",
            //             rate:"",
            //             eaccount : storage.loginReducer.currentAccount.id,
            //         });
            //     }
            // )
            // );
        }
    };
    const handleSumit = (e) => {
        e.preventDefault();
        setTrigger(trigger + 1)
    }

    const changeStatus = (id_activity, statusItem, nameItem) => {
        
        const statusString = statusItem ? 'deshabilitará' : 'habilitará'

        customSwaltAlert({
            title: `¿Está seguro?`,
            text:`Se ${statusString} la población ${nameItem}`,
            confirmButtonText: "Si, continuar",
            cancelButtonText: "Cancelar",
          }).then((result)=>{
            if (result.isConfirmed) {
                setLoading(true)
                dispatch(
                    changeStatusPopulation(
                        { eaccount: storage.loginReducer.currentAccount.id, id: id_activity, status: !!(statusItem == 1) ? 0 : 1, name: nameItem },
                        () => {
                            setTrigger(trigger + 1)
                        }
                    )
                );
            }
          })
        
    };

    const editPopulation = () => {
        setLoading(true)
        dispatch(
            changeStatusPopulation(
                {
                    eaccount: storage.loginReducer.currentAccount.id,
                    id: creatActivity.id,
                    status: creatActivity.status,
                    name: creatActivity.description,
                    clientId: creatActivity.clientId,
                    edit:true
                },
                () => {
                    setShowModal({ show: false })
                    setTrigger(trigger + 1)
                    setCreatActivity({
                        description: "",
                        clientId: "",
                        eaccount: storage.loginReducer.currentAccount.id,
                        id: "",
                        status: ""
                    });
                    setLoading(false)
                }
            )
        );
    }

    const [filters, setFilters] = useState({
        page: 1,
        perpage: 10
    })

    const handlePageChange = (pageNumber) => {
        setInfo({ ...info, page: pageNumber })
        // setFilters({...filters, page: pageNumber})
        // filters.page = pageNumber;
        // this.setState({ filters });

        setTrigger(trigger + 1)

        // this.props.getSites(this.state.filters);
    }

    return (
        <>
            {(loading) &&
                <div className="loading">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            }
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                <div className="d-flex">
                    <h1 className={tableStyles.title}>Población</h1>
                </div>
                <Row className="d-flex align-items-end">
                    <form className="d-flex w-80 mb-2" onSubmit={handleSumit}>
                        {
                            myPermission?.read && (
                                <Col>
                                    <div className="d-flex gap-2">
                                        <input
                                            className={tableStyles.searchUsersAlt}
                                            placeholder="Buscar..."
                                            type="text"
                                            onChange={(e) => setInfo({
                                                ...info,
                                                search: e.target.value
                                            })}
                                        ></input>

                                        <img
                                            style={{ cursor: "pointer" }}
                                            src={Lupa}
                                            onClick={() => setTrigger(trigger + 1)}
                                            alt='lupa'
                                        />
                                    </div>
                                </Col>
                            )
                        }
                    </form>
                    {myPermission?.create &&
                        <div style={{ width: '20%' }} className='d-flex align-items-end justify-content-end'>
                            <div
                                className="d-flex align-items-center justify-content-end groupAddButton"
                                style={{ marginTop: "15px", width: '200px' }}
                                onClick={() => setShowModal({ show: true, creat: true })}
                            >
                                <label htmlFor="newAccident" className="darkGray fw-bold">
                                    Crear Población
                                </label>
                                <button
                                    className="addCheckButton mx-2"
                                    style={{ marginBottom: "1.5px", marginTop: '0.1rem' }}
                                />
                            </div>
                        </div>
                    }

                </Row>
                <div>
                    <ModalNew
                        hideFooter={showModal.edit ? !myPermission?.edit : !myPermission?.create}
                        show={showModal.show}
                        onHide={() => {
                            setShowModal({ show: false });
                            setCreatActivity({
                                description: "",
                                clientId: "",
                                eaccount: storage.loginReducer.currentAccount.id,
                            })
                        }}
                        title={showModal.creat ? "Crear Población" : showModal.edit && 'Editar Población'}
                        // btnYesEvent={showModal.creat || showModal.edit ? () => creatNewActivity() : null}
                        btnYesEvent={showModal.edit ? () => editPopulation() : () => creatNewActivity()}
                        btnYesName={showModal.creat ? "Guardar" : "Editar"}
                        btnYesDisabled={!!(creatActivity.clientId == undefined || creatActivity.clientId == "") || !!(creatActivity.description == "")}
                        size={"500"}
                        btnNoEvent={() => {
                            setShowModal({ show: false });
                            setCreatActivity({
                                description: "",
                                clientId: "",
                                eaccount: storage.loginReducer.currentAccount.id,
                            });
                        }
                        }

                    >
                        <Row className="d-flex">
                            <Col xs={12}>
                                <p className={tableStyles.crudModalLabel}>Cliente-Empresa<span className={"text-danger"}>*</span></p>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    placeholder="Seleccionar..."
                                    key={"code" + trigger}
                                    options={optionsClient}
                                    value={optionsClient.find(
                                        (item) => item.value === `${creatActivity.clientId}`
                                    )}
                                    styles={customSelectNewDark}
                                    onChange={(e) =>
                                        setCreatActivity({
                                            ...creatActivity,
                                            clientId: e.value,
                                        })
                                    }
                                    isDisabled={!(showModal.edit || showModal.creat)}
                                />
                            </Col>
                        </Row>

                        <Row className="d-flex">
                            <Col xs={12}>
                                <p className={tableStyles.crudModalLabel}>Descripción<span className={"text-danger"}>*</span></p>
                                <input
                                    key={"detail" + trigger}
                                    className={IndividualStyles.registerInputsClearBlue}
                                    type="text"
                                    style={{ color: "#58595B" }}
                                    value={creatActivity.description}
                                    onChange={(e) =>
                                        setCreatActivity({
                                            ...creatActivity,
                                            description: e.target.value,
                                        })
                                    }
                                />
                            </Col>
                        </Row>

                    </ModalNew>

                </div>

                {
                    myPermission?.read && (
                        <GenericTableNew dark={true} headers={renderHeaders}>{listElem()}</GenericTableNew>
                    )
                }


                {
                    myPermission?.read && (
                        <div className={paginationStyles.wrapper}>
                            <p className={paginationStyles.paginationText}>
                                Pag. {storage.populationReducer.totalPopulation ? info.page : ""}
                                {" de "}
                                {Math.ceil(storage.populationReducer.totalPopulation / info.page) ?
                                    Math.ceil(storage.populationReducer.totalPopulation / info.page)
                                    :
                                    ""
                                }
                                {" "}
                                ({storage.populationReducer.totalPopulation} encontrados)
                            </p>
                            <Pagination
                                activePage={info.page}
                                itemsCountPerPage={10}
                                totalItemsCount={storage.populationReducer.totalPopulation}
                                pageRangeDisplayed={5}
                                onChange={(e) => handlePageChange(e)}
                                itemClassPrev={paginationStyles.itemClassPrev}
                                itemClassNext={paginationStyles.itemClassNext}
                                itemClassFirst={paginationStyles.itemClassFirst}
                                itemClassLast={paginationStyles.itemClassLast}
                                itemClass={paginationStyles.itemClass}
                            />
                        </div>
                    )
                }



            </div>

        </>
    )

};
export default TablePopulation;

