// espera un array de json con los siguientes keys 
// amount , discount, advance
export const calculateCollectionOfAdmissionPatient = (services=[]) => {
    if(!Array.isArray(services) || !services?.length) {
        return {message:'Digite un array correcto'}
    }

    const result = {
        amount: 0,
        discount: 0,
        advance: 0,
    }

    let amountTotal
    
    for (let index = 0; index < services?.length; index++) {
        const service = services[index];
        if(service['amount'] !== undefined && service['amount'] !== null){
            result.amount +=  Number(service.amount)
        }
        
        if(service['discount'] !== undefined && service['discount'] !== null){
            result.discount +=  Number(service.discount)
        }
        
        if(service['advance'] !== undefined && service['advance'] !== null){
            result.advance +=  Number(service.advance)
        }
        
    }
    
    const totalDiscount = (result.discount + result.advance)

    if(Number(result.amount) ===  Number(totalDiscount)){
        amountTotal = 0
    }else{
        amountTotal = result.amount - totalDiscount
    }
    

    return {...result, amountTotal }

}