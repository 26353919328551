// by jhan ortegon

import { useCallback, useEffect, useState } from "react";

// icons - images
import lupa from "../../assets/img/icons/lupa.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";

// css
import "reactjs-popup/dist/index.css";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// hooks
import { useSelector, useDispatch } from "react-redux";

// helpers
import { customSwaltAlert } from "../../helpers";

// services
import {
  costcenter_get,
  costcenter_edit,
  costcenter_edit_active,
  costcenter_insert,
} from "../../actions/costcenterActions";
import { functional_get } from "../../actions/functionalActions";

// Components
import Loader from "react-loader-spinner";
import ModalNew from "../Layouts/ModalNew";
import Pagination from "react-js-pagination";
import CustomPopup from "../Popup/customPopup";
import GenericTable from "../Layouts/GenericTable";
import GenericTableNew from "../Layouts/GenericTableNew";

const initialStateFilters = {
  search: "",
  active: "",
  page: 1,
  perpage: 10,
};

function TableCostCenter(props) {
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();

  //para no hacer get hasta que el usuario este listo
  const [unconfirmedFilters, setUnconfirmedFilters] = useState({
    search: "",
    active: "",
  });

  //filtros para el get
  const [filters, setFilter] = useState({
    ...initialStateFilters,
    eaccount: counter.loginReducer.currentAccount.id,
  });

  const [paginationFunctionalUnit, setPaginationFunctionalUnit] = useState({
    pageFunctionalUnit: 1,
    perpageFunctionalUnit: 5,
    totalFunctionalUnit: 0,
  });

  //estado interno para manejar tanto la data de edit como el modal que lo abre
  const [putState, setPutState] = useState({
    usage: "",
    id: "",
    account: "",
    code: "",
    description: "",
    functional_units: [],
    modalShow: false,
    showFuncitonalUnits: false,
    copyCenterCost: {},
    eaccount: counter.loginReducer.currentAccount.id,
  });
  const myPermission = useCallback(
    () =>
      counter.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "CCost"
      ),
    [counter.loginReducer.currentAccount?.profile?.permission]
  );

  useEffect(() => {
    const { history } = props;

    if (!myPermission()?.read) {
      history?.push("/administracion/inicio");
    }
  }, [myPermission, props]);

  useEffect(() => {
    dispatch(functional_get({ perpage: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      costcenter_get({
        ...initialStateFilters,
        eaccount: counter.loginReducer.currentAccount.id,
      })
    );
  }, [counter.loginReducer.currentAccount.id, dispatch]);

  const handleSearchButton = () => {
    setFilter({
      ...filters,
      search: unconfirmedFilters.search,
      active: unconfirmedFilters.active,
      page: 1,
    });

    dispatch(
      costcenter_get({
        ...filters,
        search: unconfirmedFilters.search,
        active: unconfirmedFilters.active,
        page: 1,
      })
    );
  };

  const handleResetPutState = () => {
    setPutState({
      usage: "",
      id: "",
      account: "",
      description: "",
      code: "",
      active: "",
      functional_units: [],
      modalShow: false,
      showFuncitonalUnits: false,
      copyCenterCost: {},
      eaccount: counter.loginReducer.currentAccount.id,
    });
  };

  const handleResetPaginationResetFunctionalUnits = () => {
    setPutState({
      showFuncitonalUnits: false,
      copyCenterCost: {},
    });
    setPaginationFunctionalUnit({
      pageFunctionalUnit: 1,
      perpageFunctionalUnit: 5,
      totalFunctionalUnit: 0,
    });
  };

  const handleChange = (event) => {
    setUnconfirmedFilters({
      ...unconfirmedFilters,
      search: event.target.value,
    });
  };

  const handlePageChange = (val) => {
    setFilter({
      ...filters,
      page: val,
    });

    dispatch(
      costcenter_get({
        ...filters,
        page: val,
      })
    );
  };

  const handlePageChangeUnitFunctional = (val) => {
    setPaginationFunctionalUnit({
      ...paginationFunctionalUnit,
      pageFunctionalUnit: val,
    });
  };

  function handleSubmit() {
    if (putState.description === "") {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Se requiere una descripción",
        showCancelButton: false,
      });
      return null;
    }
    if (putState.code === "") {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Se requiere un código",
        showCancelButton: false,
      });
      return null;
    }
    if (putState.usage === "PUT") {
      return dispatch(
        costcenter_edit(putState.id, putState, () => {
          handleResetPutState();
          dispatch(
            costcenter_get({
              ...initialStateFilters,
              eaccount: counter.loginReducer.currentAccount.id,
            })
          );
        })
      );
    }
    if (putState.usage === "POST") {
      return dispatch(
        costcenter_insert(putState, () => {
          handleResetPutState();
          dispatch(
            costcenter_get({
              ...initialStateFilters,
              eaccount: counter.loginReducer.currentAccount.id,
            })
          );
        })
      );
    }
    return null;
  }

  const renderElement = (elem) => {
    return (
      <tr key={elem.id} className="hover-table-row">
        <td className="col-md-1 text-start px-3">
          {elem.code ? elem.code : "  -"}
        </td>
        <td className="col-md-5 text-start px-2">{elem.description}</td>
        <td className="col-md-1">
          <div className={tableStyles.groupElems}>
            <div
              className={
                elem.active ? tableStyles.greenState : tableStyles.redState
              }
            >
              {elem.active ? "Habilitado" : "Inhabilitado"}
            </div>
          </div>
        </td>
        <td className="col-md-1">
          {myPermission()?.edit && (
            <CustomPopup
              triggerSrc={threeDots}
              showEdit={myPermission()?.edit}
              editClickEvent={() =>
                setPutState({
                  ...putState,
                  id: elem.id,
                  code: elem.code,
                  description: elem.description,
                  functional_units: elem.fu_ids,
                  usage: "PUT",
                  modalShow: true,
                })
              }
              showEnable={myPermission()?.edit}
              isEnabled={elem.active}
              enableClickEvent={() =>
                dispatch(
                  costcenter_edit_active(
                    elem.id,
                    { active: !elem.active },
                    elem.description,
                    () =>
                      dispatch(
                        costcenter_get({
                          ...initialStateFilters,
                          eaccount: counter.loginReducer.currentAccount.id,
                        })
                      )
                  )
                )
              }
            />
          )}
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (counter.costcenterReducer.cc !== undefined) {
      const elem2 = counter.costcenterReducer.cc;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={`1${Date.now()}`} className="px-3 ">
      Código
    </th>,
    <th key={`2${Date.now()}`} className="px-2">
      Descripción
    </th>,
    <th key={`3${Date.now()}`} className="text-center w100">
      Estado
    </th>,
    <th key={`4${Date.now()}`}>ㅤ</th>,
  ];

  let endCut =
    paginationFunctionalUnit.perpageFunctionalUnit *
    paginationFunctionalUnit.pageFunctionalUnit;
  let startCut =
    paginationFunctionalUnit.perpageFunctionalUnit *
      paginationFunctionalUnit.pageFunctionalUnit -
    paginationFunctionalUnit.perpageFunctionalUnit;
  endCut =
    endCut === 0 ? paginationFunctionalUnit.perpageFunctionalUnit : endCut;
  let copyFunctionalArray = putState.copyCenterCost.functional_units;
  let newArray = [];
  let totalUnits = 0;
  if (copyFunctionalArray) {
    totalUnits = putState.copyCenterCost.functional_units.length;
    newArray = copyFunctionalArray.slice(startCut, endCut);
  }
  const renderListUnits = newArray.map((item, key) => {
    return (
      <tr key={key}>
        <td>{item.description}</td>
      </tr>
    );
  });
  const handleSumit = (e) => {
    e.preventDefault();
    setFilter({
      ...filters,
      search: unconfirmedFilters.search,
      active: unconfirmedFilters.active,
      page: 1,
    });
    dispatch(
      costcenter_get({
        ...filters,
        search: unconfirmedFilters.search,
        active: unconfirmedFilters.active,
        page: 1,
      })
    );
  };

  return (
    <div className={tableStyles.container}>
      {counter.costcenterReducer.loading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}

      <h1 className={tableStyles.title} style={{ marginLeft: "76px" }}>
        Centros de Costos
      </h1>
      <div className={tableStyles.tableArea}>
        <div className={tableStyles.mainRow}>
          {myPermission()?.read && (
            <form onSubmit={handleSumit}>
              <input
                className={tableStyles.searchUsersAlt}
                type="text"
                placeholder="Buscar..."
                onChange={handleChange}
              />

              <button onClick={() => handleSearchButton()}>
                <img
                  src={lupa}
                  alt="User icon"
                  className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                />
              </button>
            </form>
          )}

          <div className={tableStyles.groupElems}>
            {myPermission()?.create && (
              <div
                className="d-flex justify-content-end groupAddButton align-items-center"
                onClick={() =>
                  setPutState({ ...putState, usage: "POST", modalShow: true })
                }
              >
                <label htmlFor="newAccident" className="darkGray fw-bold">
                  Crear centro de costo
                </label>
                <button
                  className="addCheckButton mx-2"
                  style={{ marginBottom: "1.5px" }}
                />
              </div>
            )}
          </div>
        </div>

        {putState.modalShow ? (
          <ModalNew
            hideFooter={
              putState.usage === "PUT"
                ? !myPermission()?.edit
                : !myPermission()?.create
            }
            title="Centro de Costos"
            show={putState.modalShow}
            onHide={() => handleResetPutState()}
            btnYesEvent={() => handleSubmit()}
          >
            <div className="row bottom-margin-10">
              <div className="col-md-3">
                <p className="label-inputs text-primary">código</p>
                <input
                  className="register-inputs"
                  name="number"
                  type="number"
                  placeholder="..."
                  defaultValue={putState.code}
                  onChange={(event) =>
                    setPutState({ ...putState, code: event.target.value })
                  }
                />
              </div>
              <div className="col-md-9">
                <p className="label-inputs text-primary">Descripción</p>
                <input
                  className="register-inputs"
                  name="description"
                  type="text"
                  placeholder="Descripción centro de costos"
                  defaultValue={putState.description}
                  onChange={(event) =>
                    setPutState({
                      ...putState,
                      description: event.target.value,
                    })
                  }
                />
              </div>
            </div>
          </ModalNew>
        ) : null}

        {/* Modal to show all functional units by cost center */}
        {putState.showFuncitonalUnits ? (
          <ModalNew
            title={
              putState.copyCenterCost.description
                ? putState.copyCenterCost.description
                : "Centro de costo"
            }
            show={putState.showFuncitonalUnits}
            onHide={() => handleResetPaginationResetFunctionalUnits()}
          >
            <div>
              {myPermission()?.read && (
                <>
                  <GenericTable
                    headers={[<th key={4}>Unidades funcionales</th>]}
                  >
                    {renderListUnits}
                  </GenericTable>
                  <Pagination
                    activePage={paginationFunctionalUnit.pageFunctionalUnit}
                    itemsCountPerPage={
                      paginationFunctionalUnit.perpageFunctionalUnit
                    }
                    totalItemsCount={totalUnits}
                    pageRangeDisplayed={5}
                    onChange={handlePageChangeUnitFunctional}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </>
              )}
            </div>
          </ModalNew>
        ) : null}

        <GenericTableNew
          dark={true}
          fontFamilyTable={"fontFamilyTable"}
          headers={renderHeaders}
        >
          {listElem()}
        </GenericTableNew>

        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {counter.costcenterReducer.total ? filters.page : ""}
            {" de "}
            {Math.ceil(counter.costcenterReducer.total / filters.perpage)
              ? Math.ceil(counter.costcenterReducer.total / filters.perpage)
              : ""}{" "}
            ({counter.costcenterReducer.total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={counter.costcenterReducer.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </div>
  );
}

export default TableCostCenter;
