import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import {
    GET_PURCHASEORDERSTATUS,
    GET_ARTICLES_IN_PURCHASE_ORDERS,
    GET_INFO_PROVIDER_IN_PURCHASE_ORDERS,
    GET_PROGRAMMING_IN_PURCHASE_ORDERS
} from "./actionTypes";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal)


export const get_purchaseOrderStatus = (objFilters) => (dispatch, getState)  => {
    dispatch({
        type: GET_PURCHASEORDERSTATUS,
        loading: true
    })
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/purchaseOrderStatus/?${filters}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {results, row_total} = res

        dispatch({
            type: GET_PURCHASEORDERSTATUS,
            results: results,
            row_total: row_total,
            loading: false
        })
    })
    .catch((err)=>{
        dispatch({
            type: GET_PURCHASEORDERSTATUS,
            results: [],
            row_total: 0,
            loading: false
        })
        console.error(err)
        
    })
}

export const get_articlesInPurchaseOrder = (objFilters) => (dispatch, getState)  => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/detail_purchaseOrderArticles/?${filters}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {results} = res

        dispatch({
            type: GET_ARTICLES_IN_PURCHASE_ORDERS,
            results: results,
        })
    })
    .catch((err)=>{
        console.error(err)
        
    })
}

export const get_providerInfo_InPOStatus = (objFilters) => (dispatch, getState)  => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/detail_infoProvider/?${filters}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {results} = res
        dispatch({
            type: GET_INFO_PROVIDER_IN_PURCHASE_ORDERS,
            results: results,
        })
    })
    .catch((err)=>{
        console.error(err)
        
    })
}

export const get_programming_InPOStatus = (objFilters) => (dispatch, getState)  => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/detail_programmingPurchaseOrder/?${filters}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        const {results, row_total} = res
        dispatch({
            type: GET_PROGRAMMING_IN_PURCHASE_ORDERS,
            results: results,
            row_total:row_total,
        })
    })
    .catch((err)=>{
        console.error(err)
        
    })
}

export const changeStatus = (obj, doAfter= null) => (dispatch, getState)  => {
    const token = getState().loginReducer.Authorization;
    const eaccount = getState().loginReducer.currentAccount.id;
            fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/update_StatusPurchaseOrder/` ,{
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify(obj),
            })
            
            .then(response => response.json())
                .then(res => {
                    if(res.success){
                        customSwaltAlert({
                            icon:'success',
                            title:  `Orden de compra anulada`,
                            text: `${res.purchaseOrder}`,
                            showCancelButton: false,
                        })
                        dispatch(get_purchaseOrderStatus({page:1,perpage:10,eaccount: eaccount }))
                    }else{
                        customSwaltAlert({
                            icon:'error',
                            title:'Error',
                            text:`${res.message}`,
                            showCancelButton: false,
                        })
                    }
                    if(doAfter){
                        doAfter()
                    }
                })
    
    
}