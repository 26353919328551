import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import styles from "./styles.module.scss";
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "sassy-datepicker";
import Carousel, { consts } from "react-elastic-carousel";
import searchCalendar from "../../../assets/img/icons/searchCalendar.svg";
import { appCustomSelectNew, ordCustomSelect } from "../../../components/Layouts/react-select-custom";
import arrowDerechaCalendario from "../../../assets/img/icons/arrowDerechaCalendario.svg";
import arrowIzquierdaCalendario from "../../../assets/img/icons/arrowIzquierdaCalendario.svg";
import OrdTable from "../../../OrderingModule/OrderingComponents/OrdTable";
import OrdModal from "../../../OrderingModule/OrderingComponents/OrdModal";
import CreatableSelect from "react-select/creatable";
import { Radio } from "@material-ui/core";
import stylesTableFile from "../../../components/Layouts/tableStyle.module.scss";
import icon_search from "../../../assets/img/icons/ordSearchClearBlue.svg";
import addIcon from "../../../assets/img/icons/add-check.svg";
import userImg from "../../../assets/img/header/user-img.png";
import clearIcon from "../../../assets/img/icons/close.svg";
import sendIcon from "../../../assets/img/icons/icon_message.svg";
import { useGetMethod, useMultiGetMethod, usePostMethod } from "../../../Hooks/useFetch";
import {
  formatToRcSelect,
  generateId,
  isEmptyOrUndefined,
  loader,
  message,
  validateEmail,
  validateEmptyString,
} from "../../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import FullScreenDialog from "../../../OrderingModule/OrderingComponents/FullScreenDialog";
import { RenderMedicalFormulasPdf } from "../../../AsistanceModule/RenderMedicalFormulasPdf";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import moment from "moment";
import { Box } from "@mui/material";
import { customSwaltAlertAsistencial } from "../../../helpers";
moment.locale("en-US");


const Shedule = (props) => {
  const ref = useRef(null);
  const [colorImgDoctor, setColorImgDoctor] = useState(true)
  const store = useSelector((state) => state);
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const fullNamePatient = `${props?.detailPatient?.results?.firstName} ${props?.detailPatient?.results?.otherNames} ${props?.detailPatient?.results?.lastName} ${props?.detailPatient?.results?.secondSurname}`;
  const { results: ticketResults, load: ticketLoader, trigger: getTicket } = useGetMethod();
  const procedure = props?.procedure && props?.procedure;
  const bussines_name = store?.loginReducer?.currentAccount?.bussines_name;
  const dispatch = useDispatch();

  const renderArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <img src={arrowIzquierdaCalendario} alt="arrow" width={10} />
      ) : (
        <img src={arrowDerechaCalendario} alt="arrow" width={10} />
      );
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };

  const renderTooltipTypeIcon = (e) => <Tooltip>{e}</Tooltip>;

  const renderTooltipSchedule = (e) => (
    <Tooltip >
      <div style={{ color: '#6e6f7c', fontSize: '12px', fontWeight: 'bold' }}>
        {e}
      </div>
    </Tooltip>
  )


  const handleClickDoctor = (e, ind, indexDoctor) => {

    if (scheduleInfoRow[ind].idDoctor === e.idDoctor) {
      scheduleInfoRow[ind].idDoctor = "";
      scheduleInfoRow[ind].idContractCorporateClient = "";
      scheduleInfoRow[ind].idDoctorContract = "";
      scheduleInfoRow[ind].schId = "";
      scheduleInfoRow[ind].idSchedule = "";
      scheduleInfoRow[ind].index = "";
    } else {
      scheduleInfoRow[ind].idDoctor = e.idDoctor;
      scheduleInfoRow[ind].schId = e.schId;
      scheduleInfoRow[ind].idSchedule = e.idSchedule;
      scheduleInfoRow[ind].index = indexDoctor;
      scheduleInfoRow[ind].idContractCorporateClient = e.idContractCorporateClient;
      scheduleInfoRow[ind].idDoctorContract = e.idDoctorContract;
      let arrTemp = scheduleDoctorTime;
      arrTemp[ind] = e
    }

    setColorImgDoctor(state => !state)
  };

  const handleClickSchedule = (e, ind, unique) => {
    if (scheduleInfoRow[ind].hourStart === e.hourStart) {

      handleBlockSchedulesHoursSelected({
        unique,
        hourSelected: e?.hourStart,
        dateSelected: e?.date,
        idSchedule: e?.idSchedule,
        type: 0,
      })

      scheduleInfoRow[ind].idSchedule = "";
      scheduleInfoRow[ind].hourStart = "";
      scheduleInfoRow[ind].hourEnd = "";
      scheduleInfoRow[ind].appointment = "";
      scheduleInfoRow[ind].idTime = "";
    } else {

      handleBlockSchedulesHoursSelected({
        unique,
        hourSelected: e?.hourStart,
        dateSelected: e?.date,
        idSchedule: e?.idSchedule,
        type: 1
      })

      scheduleInfoRow[ind].idSchedule = e.idSchedule;
      scheduleInfoRow[ind].hourStart = e.hourStart;
      scheduleInfoRow[ind].hourEnd = e.hourEnd;
      scheduleInfoRow[ind].appointment = e.appointment;
      scheduleInfoRow[ind].idTime = ind;
    }
  };

  // ------------------ MODAL FUNCTIONS ----------------
  const [showModal, setShowModal] = useState(false);

  const SearchHeaders = [
    {
      title: "ㅤID",
      className: "pl-1",
    },
    {
      title: "Descripción",
    },
    {
      title: "Acrónimos",
    },
  ];

  const [filters, setFilters] = useState({
    id_account: idEnterprise,
    modality: store.filterCheckTeleScheduleReducer.checked ? 'tmed' : "on_site", //
    contractId: props?.contractId, //
    companyType: props?.companyType,
    idAccount: idEnterprise,
    crtId: "",
    cclientId: props?.corporateClientId, //Client id
    popId: props?.populationId, //Population Id
    keyWords: [], //Multi Input
    orderBy: "name", //???
    acronym: "",
    page: 1,
    perpage: 10,
  });

  const [trigger, setTrigger] = useState(0);
  const { results: servicesResults, load: servicesLoader, trigger: getServices } = useGetMethod();
  const { results: citeAppointmentResults, load: loadCiteAppointment, trigger: getCiteAppointment } = useGetMethod();
  const { results: servicesRequestQuotaResults, load: servicesRequestQuotaLoader, trigger: getServicesRequestQuota } = useGetMethod();
  const { load: locationsLoader, trigger: getLocations } = useGetMethod();
  const { results: medicianList, load: medicianListLoader, trigger: getMedicianList } = useGetMethod();
  const { results: ScheduleDoctor, load: scheduleDoctorLoader, trigger: getScheduleDoctor } = useGetMethod();
  const { results: unities, load: unitiesLoader, trigger: getUnities } = useMultiGetMethod();
  const { load: createReminderLoader, trigger: createReminderMethod } = usePostMethod();
  const { load: blockAgendaLoader, trigger: blockAgendaMethod } = usePostMethod();
  const { load: unblockAgendaMethodLoader, trigger: unlockAgendaMethod } = usePostMethod();
  const { load: requestQuotaLoader, trigger: requestQuotaMethod } = usePostMethod();
  const { load: createAppointmentLoader, trigger: createAppointmentMethod } = usePostMethod();
  const { results: careGroupOptionsResults, load: careGroupOptionsLoader, trigger: getCareGroupOptions } = useGetMethod();
  const { load: loadPostSmartQue, trigger: createSmartQueMethod } = usePostMethod();

  const [dataCreateAppointment, setDataCreateAppointment] = useState({
    idPatient: props?.detailPatient?.results?.otherNames,
    appDatetime: "",
    appEndDatetime: "",
    idSch: "",
    idDoctor: "",
    idDoctorContract: "",
    idService: "",
    idCorporateClient: "",
    idContractCorporateClient: "",
    idPopulation: "",
    createdBy: idUser,
    webScrapping: "",
    idCompanyType: "",
    modality: "",
    category: "",
    status: "",
    multiSelected: false,
  });

  useEffect(() => {
    if (props?.wsValidated === 0) {
      setDataCreateAppointment({ ...dataCreateAppointment, webScrapping: false });
    } else {
      setDataCreateAppointment({ ...dataCreateAppointment, webScrapping: true });
    }
    if (props?.crtName !== undefined) {
      setDataCreateAppointment({ ...dataCreateAppointment, category: props?.crtName });
    } else {
      setDataCreateAppointment({ ...dataCreateAppointment, category: "" });
    }

    getUnities({
      multipleGet: [
        {
          url: "/medical/generals/site/",
          // objFilters: { type: "Unidad de Medida" },
          requestName: "site",
        },
        {
          url: "/medical/generals/medical/",
          // objFilters: { type: "Presentación" },
          requestName: "medical",
        },
        {
          url: "/medical/generals/specialty/",
          // objFilters: { type: "Unidad de receta" },
          requestName: "specialty",
        },
        {
          url: "/medical/generals/serviceType/",
          // objFilters: { slim: 1, enabled: 1 },
          requestName: "serviceType",
        },
        {
          url: "/medical/appointment/serviceEntity/",
          objFilters: {
            modality: store.filterCheckTeleScheduleReducer.checked === true ? 'tmed' : "on_site", //
            contractId: props?.contractId, //
            crtId: "",
            cclientId: props?.corporateClientId, //Client id
            popId: props?.populationId, //Population Id
            keyWords: [], //Multi Input
            companyType: props?.companyType, //Company type
            idAccount: idEnterprise,
          },
          requestName: "services",

          // token: token,
          // doAfterSuccess: () => {
          // },
        },
      ],
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUnities, idEnterprise, token, trigger,]);


  useEffect(() => {
    getServices({
      url: "/medical/appointment/serviceEntity/",
      objFilters: { ...filters, modality: store.filterCheckTeleScheduleReducer.checked ? 'tmed' : "on_site" },
      token: token,
      doAfterSuccess: () => {

        setFilters({
          ...filters,
          id_account: idEnterprise,
          modality: store.filterCheckTeleScheduleReducer.checked === true ? 'tmed' : "on_site", //
          contractId: props?.contractId, //
          crtId: "",
          cclientId: props?.corporateClientId, //Client id
          popId: props?.populationId, //Population Id
          keyWords: [], //Multi Input
          orderBy: "name", //???
          acronym: "",
          page: 1,
          perpage: 10,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, trigger, store.filterCheckTeleScheduleReducer.checked]);


  useEffect(() => {
    setScheduleInfoRow([])
  }, [store.filterCheckTeleScheduleReducer.checked])



  const [triggerRender, setTriggerRender] = useState(0);
  useEffect(() => { }, [triggerRender]);

  const specialtyOptions = formatToRcSelect(unities?.specialty?.results, "spe_id", "spe_name", "", "", "");
  const siteOptions = formatToRcSelect(unities?.site?.results, "siteId", "site", "", "", "");
  const medicOptions = formatToRcSelect(unities?.medical?.results, "idDoctor", "mdName", "", "", "");
  const serviceTypeOptions = formatToRcSelect(unities?.serviceType?.results, "serviceTypeId", "serviceType", "", "", "");
  const serviceOptions = formatToRcSelect(unities?.services?.results, "srvId", "srvName", "", "", "");
  const formattedMedicianList = formatToRcSelect(medicianList.results, "mdId", "mdName", "", "", "");
  const careGroupOptions = formatToRcSelect(careGroupOptionsResults?.results, "id", "name", "", "", "");
  const serviceRequestQuotaOptions = formatToRcSelect(servicesRequestQuotaResults?.results, "srvId", "srvName", "", "", "");

  const articlesList = {
    results: [
      {
        id: "hola",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
      {
        id: "hola2",
        description: "aaaaaa",
        acron: "acronn",
      },
    ],
  };
  const [isSelected, setIsSelected] = useState({
    state: false,
    id: "",
  });
  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let orange = isSelected.state === true && isSelected.id === item.srvId ? stylesTableFile.ordBgFileTableYellow : "";

        tempList.push(
          <tr
            key={item?.srvId}
            className={`hover-table-row ${orange}`}
            onClick={() => {
              setIsSelected({ ...isSelected, state: true, id: item.srvId });
            }}
          >
            <td className={`text-start px-2 ${orange} `}>{item?.srvId}</td>
            <td className={`text-start px-2 ${orange} `}>{item?.srvName}</td>
            <td className={`text-start px-2 ${orange} `}>{item?.acronym}</td>
          </tr>
        );
      });
    }
    return tempList;
  };
  const createOption = (label) => ({
    label,
    value: label,
  });
  const [stateInput, setStateInput] = useState({
    inputValue: "",
    value: [],
  });
  let tempArray = [];
  stateInput.value.forEach((elem) => {
    tempArray.push(elem.label);
  });
  const handleKeyDown = (event) => {
    const { inputValue, value } = stateInput;
    if (inputValue) {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case "Enter":
        case "Tab":
          let tempArray = value;
          tempArray.push(createOption(event.target.value));
          setStateInput({ inputValue: "", value: tempArray });
      }
    }
  };
  const handleInputChange = (inputValue, tipo) => {
    if (typeof inputValue == "string") {
      setStateInput({ ...stateInput, inputValue: inputValue });
      let tempArray = [];
      stateInput.value.forEach((elem) => {
        tempArray.push(elem.label);
      });
      setFilters({ ...filters, keyWords: tempArray });
    } else if (Array.isArray(inputValue)) {
      setStateInput({ ...stateInput, value: inputValue });
    }
  };

  const [searchInfo, setSearchInfo] = useState({
    service: procedure?.serviceId ? procedure?.serviceId : "",
    location: "",
    site: "",
    medic: "",
  });
  const searchValues = () => {
    setSearchInfo({ ...searchInfo, service: isSelected.id });
    closeModal();
  };

  const [locationList, setLocationList] = useState([[{ value: "", label: "-Seleccione-" }]]);
  const [siteList, setSiteList] = useState([[{ value: "", label: "-Seleccione-" }]]);

  const closeModal = () => {
    setFilters({ ...filters, orderBy: "name", keyWords: [] });
    setShowModal(false);
    setStateInput({ value: [], inputValue: "" });
    setIsSelected({ ...isSelected, state: !isSelected.state, id: "" });
    setTrigger(trigger + 1);
  };
  const handleChangeService = (e, i) => {
    scheduleInfoRow[i].service = e.value;
    scheduleInfoRow[i].location = "";
    scheduleInfoRow[i].site = "";
    scheduleInfoRow[i].medic = "";
    scheduleInfoRow[i].optionsRow.calendarSearch = false;
    scheduleInfoRow[i].time = "";
    scheduleInfoRow[i].hourStart = "";
    scheduleInfoRow[i].hourEnd = "";
    scheduleInfoRow[i].schId = "";
    scheduleInfoRow[i].idDoctor = "";


    if (!isEmptyOrUndefined(e.value)) {
      getLocations({
        url: "/medical/appointment/locationService/",
        objFilters: { modality: store.filterCheckTeleScheduleReducer.checked === true ? 'tmed' : "on_site", srvId: e.value, idAccount: idEnterprise },
        token: token,
        doAfterSuccess: (data) => {
          locationList[i] = formatToRcSelect(data.results, "id", "name", "sites");
        },
      });
    }
  };
  const handleChangeLocation = (e, ind) => {
    scheduleInfoRow[ind].location = e.value;
    scheduleInfoRow[ind].site = "";
    scheduleInfoRow[ind].medic = "";

    siteList[ind] = formatToRcSelect(e.extra, "id", "siteName", "");

    getMedicianList({
      url: "/medical/appointment/medicalContract/",
      objFilters: {
        srvId: scheduleInfoRow[ind].service,
        idAccount: idEnterprise,
        city: e.value,
        popId: props?.popId,
        contractId: props?.contractId
      },
      token: token,
      doAfterSuccess: (data) => { },
    });
  };
  const handleChangeSite = (e, ind) => {
    scheduleInfoRow[ind].site = e.value;
    scheduleInfoRow[ind].medic = "";
  };
  const handleChangeMedician = (e, ind) => {
    scheduleInfoRow[ind].medic = e.value;
  };

  const [triggerRow, setTriggerRow] = useState(0);
  // ANCHOR SEARCH SCHEDULE ROW
  const openScheduleRow = (e, i) => {
    let validations;
    if (!dataCreateAppointment.multiSelected) {
      validations = validateEmptyString([
        { field: scheduleInfoRow[i].service, message: "El campo 'Servicio' es obligatorio para la búsqueda" },
        { field: scheduleInfoRow[i].location, message: "El campo 'Ubicación' es obligatorio para la búsqueda" },
      ]);
    }

    if (!validations.valid) {
      return
    }

    if (!dataCreateAppointment.multiSelected && validations.valid) {

      getScheduleDoctor({
        url: "/medical/appointment/availability/",
        objFilters: {
          attentionGroup: props?.attGroupId,
          eaccount: idEnterprise,
          idService: scheduleInfoRow[i].service,
          city: scheduleInfoRow[i].location,
          idContractcorporateClient: props?.contractId,
          idCorporateClient: props?.corporateClientId,
          idPopulation: props?.populationId,
          idDoctor: scheduleInfoRow[i]?.medic,
          site: scheduleInfoRow[i]?.site,
          modality: store.filterCheckTeleScheduleReducer.checked ? 'tmed' : "on_site",
        },
        token: token,
        doAfterSuccess: (data) => {
          
          props?.postTicketFunction("agenda_availability")
          let scheduleTempArr = scheduleInfoResults
          scheduleTempArr[i] = data;
          
          let dateArr = scheduleInfoRow;
          let dateV;
          dateArr[i].optionsRow.calendarSearch = true;
          dateV = data?.date.split("/");
          dateV = `${dateV[2]}-${dateV[1]}-${dateV[0]}`;
          dateArr[i].date = new Date(dateV);
          // setScheduleActive(false)
          // setScheduleInfoRow(dateArr);

          setScheduleInfoResults(
            scheduleTempArr?.map((el, index) => {
              if (index === i) {
                return data
              } else {
                return ({
                  ...el,
                  unique: generateId()
                })
              }
            })
          )
  
          setScheduleInfoRow(scheduleInfoRow?.map((el, index) => {
            if (index === i) {
              return {
                ...el,
                time: "",
                hourStart: "",
                hourEnd: "",
                schId: "",
                idDoctor: "",
                date: new Date(dateV),
                optionsRow:{
                  ...el?.optionsRow,
                  calendarSearch: true
                }
              }
            } else return el
          }));


          triggerPicker[i] = triggerPicker[i] + 1;
          setTimeout(() => {
            setTriggerRender(triggerRender + 1);
          }, 200);
        },
        doAfterException: (data) => {
          message("info", data.message);
        },
      });
    } else {
      let dateArr = scheduleInfoRow;
      dateArr[i].optionsRow.calendarSearch = true;
      setScheduleInfoRow(dateArr);
    }
  };


  const [triggerPicker, setTriggerPicker] = useState([0]);
  const [scheduleInfoRow, setScheduleInfoRow] = useState([
    {
      service: procedure?.serviceId ? procedure?.serviceId : "",
      location: "",
      site: "",
      medic: "",
      idDoctor: "",
      date: "",
      time: "",
      optionsRow: {
        showDetailHours: true,
        calendarSearch: false,
      },
      idSchedule: "",
      hourStart: "",
      hourEnd: "",
      appointment: "",
      schId: "",
      idContractCorporateClient: "",
      idDoctorContract: "",
      idTime: "",
      appObservation: "",
    },
  ]);
  

  const addNewServiceRow = () => {
    scheduleInfoRow.push({
      service: "",
      location: "",
      site: "",
      medic: "",
      idDoctor: "",
      date: "",
      time: "",
      optionsRow: {
        showDetailHours: true,
        calendarSearch: false,
      },
      idSchedule: "",
      hourStart: "",
      hourEnd: "",
      appointment: "",
      schId: "",
      idContractCorporateClient: "",
      idDoctorContract: "",
      idTime: "",
    });
    locationList.push([{ value: "", label: "-Seleccione-" }]);
    siteList.push([{ value: "", label: "-Seleccione-" }]);
    triggerPicker.push(0);
  };

  const deleteServiceRow = (item) => {
    let tempSch = scheduleDoctorTime
    tempSch.splice(item, 1)
    setScheduleDoctorTime(tempSch)
    let tempArray = scheduleInfoRow;
    tempArray.splice(item, 1);
    setScheduleInfoRow(tempArray);
    triggerPicker[item] = triggerPicker[item] + 1;
  };

  useEffect(() => {
    // if (dataCreateAppointment.multiSelected) {
    getCareGroupOptions({
      url: "/medical/attentionGroup/",
      objFilters: { eaccount: idEnterprise, status: "enabled" },
      token: token,
    });
    setScheduleInfoRow([
      {
        service: procedure?.serviceId ? procedure?.serviceId : "",
        location: "",
        site: "",
        medic: "",
        idDoctor: "",
        date: "",
        time: "",
        optionsRow: {
          showDetailHours: true,
          calendarSearch: false,
        },
        idSchedule: "",
        hourStart: "",
        schId: "",
        appObservation: "",
      },
    ]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreateAppointment.multiSelected]);


  useEffect(() => {
    dispatch({
      type: 'ACTIVE_FILTER_CHECK_TELE',
      payload: true
    })
  }, [])

  const closeModalReminder = () => {
    setModalReminder({
      appId: "",
      cluEmail: {
        inputValue: "",
        value: [],
      },
      emailSubject: "Recordatorio de cita",
      emailBody: props?.insureSelected.isMain === 0 ? `<p>Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir! </p>` : `<p> Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir!</p>`,
      fileBase64: "",
      show: false,
    });
    setScheduleInfoRow([
      {
        service: procedure?.serviceId ? procedure?.serviceId : "",
        location: "",
        site: "",
        medic: "",
        idDoctor: "",
        date: "",
        time: "",
        optionsRow: {
          showDetailHours: true,
          calendarSearch: false,
        },
        idSchedule: "",
        hourStart: "",
        hourEnd: "",
        appointment: "",
        schId: "",
        idContractCorporateClient: "",
        idDoctorContract: "",
        idTime: "",
      },
    ]);
    setDataCreateAppointment({ ...dataCreateAppointment, category: "" });
  };
  // ANCHOR CREATE REMINDER
  const createReminder = () => {

    let tempValues = []
    stateInputReminder?.value.forEach((e) => {
      tempValues.push(e.value)
    })
    setModalReminder({ ...modalReminder, cluEmail: tempValues });
    let validations = validateEmptyString([{ field: modalReminder.emailSubject, message: "El campo 'Asunto' es obligatorio" }]);

    let emailValidation;
    if (tempValues?.length > 0) {
      tempValues.forEach((e) => {
        if (validateEmail(e) === true) {
          emailValidation = true;
        } else {
          message("info", "Validar correo", "Valide su correo");
        }
      })
    } else {
      message("info", "Campos vacíos", "El campo 'Para' es obligatorio");
    }
    let appId = []
    appId.push(modalReminder.appId)
    if (validations.valid && emailValidation) {
      customSwaltAlertAsistencial({
        icon: "warning",
        title: "¿Está seguro?",
        text: `Se enviará el recordatorio de cita`,
        confirmButtonText: "Si, Continuar",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const appIds = []
          appId?.forEach(c => { c?.forEach(f => { appIds?.push(f) }) })
          createReminderMethod({
            url: "/medical/appointment/paramsEmail/",
            method: "POST",
            body: {
              appIds,
              cluEmail: tempValues,
              emailSubject: modalReminder.emailSubject,
              emailBody: modalReminder.emailBody,
              fileBase64: modalReminder.fileBase64,
            },
            token: token,
            doAfterSuccess: (info) => {
              closeModalReminder();
              message("info", info.message);
            },
            doAfterException: (error) => {
              // setData({ ...data, allowExtraQuota: false});
              // message("error", "Error", error.message);
            },
          });
        }
      });
    }
  };
  // ANCHOR CREATE APPOINTMENT
  const createAppointment = () => {
    let body = []

    let statusValidation = {
      isValid: true,
      message: '',
    }

    scheduleInfoRow.forEach((e, i) => {
      body.push(
        {
          idPatient: props?.detailPatient?.results?.id,
          appDatetime: `${moment(e.date).format("YYYY-MM-DD")} ${e.hourStart}`,
          appEndDatetime: `${moment(e.date).format("YYYY-MM-DD")} ${e.hourEnd}`,
          idSch: e.idSchedule,
          idDoctor: e.idDoctor,
          idDoctorContract: e.idDoctorContract,
          idService: e.service,
          idCorporateClient: props?.corporateClientId,
          idContractCorporateClient: e.idContractCorporateClient,
          idPopulation: props?.popId,
          createdBy: idUser,
          rv: props?.wsValidated === 0 ? "False" : "True",
          idCompanyType: props?.companyType,
          modality: store.filterCheckTeleScheduleReducer.checked ? 'tmed' : "on_site",
          origin: "on_site",
          category: dataCreateAppointment.category,
          status: "pending_payment",
          eaccount: idEnterprise,
          appObservations: e.appObservation,
        }
      )

      if (isEmptyOrUndefined(e.date)) statusValidation = { isValid: false, message: 'La fecha es obligatoria' }
      if (isEmptyOrUndefined(e.hourStart)) statusValidation = { isValid: false, message: 'La hora de la cita es obligatoria' }
      if (isEmptyOrUndefined(e.idDoctor)) statusValidation = { isValid: false, message: 'El médico es obligatorio' }
      if (isEmptyOrUndefined(e.service)) statusValidation = { isValid: false, message: 'El servicio es obligatorio' }
    })


    if (statusValidation.isValid) {
      customSwaltAlertAsistencial({
        icon: "warning",
        title: "¿Está seguro?",
        text: scheduleInfoRow?.length === 1 ?
          `Se agendará una cita al paciente ${fullNamePatient} el día ${moment(scheduleInfoRow[0].date).format("YYYY-MM-DD")} a las ${scheduleInfoRow[0].hourStart.slice(0, scheduleInfoRow[0].hourStart?.length - 3)
          } ` :
          `Se agendarán (${scheduleInfoRow?.length}) citas para el paciente ${fullNamePatient}`,
        confirmButtonText: "Si, Continuar",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          createAppointmentMethod({
            url: "/medical/appointment/createAppointments/",
            method: "POST",
            body: { apps: body },
            token: token,
            doAfterSuccess: (info) => {
              props?.postTicketFunction("appointment_registration", info?.results?.appIds)

              getTicket({
                url: "/medical/appointment/concecutive/",
                objFilters: {
                  idConsec: 23
                },
                token: token,
                doAfterSuccess: (res) => {
                  props?.setTicketValue(res.results)
                },
              });
            },
            succesAction: (info) => {

              getCiteAppointment({
                url: "/medical/appointment/",
                objFilters: {
                  appId: info?.results?.appIds[0]
                },
                token: token,
                doAfterSuccess: (data) => {
                  if (data?.results?.ctName?.toLowerCase() === 'Prepagada'.toLowerCase()
                    && data?.results?.modality?.toLowerCase() === 'tmed'.toLowerCase()) {

                    createSmartQueMethod({
                      url: "/medical/appointment/smartQue/",
                      method: "POST",
                      body: {
                        "idApp": info?.results?.appIds[0],
                      },
                      noAlert: true,
                      token: token,
                      doAfterSuccess: (info) => {
                        // setRequestQuotaModal({ ...requestQuotaModal, show: false, lockIdApp: "", comments: "" })
                      },
                      doAfterException: (error) => {
                        // message("info", "Información", error.message);
                      },
                    });

                  }
                },
              });

              customSwaltAlertAsistencial({
                icon: "success",
                title: "Cita registrada",
                text: info.message,
                confirmButtonText: "Ver Recordatorio",
              }).then((result) => {
                if (result.isConfirmed) {
                  setModalReminder({ ...modalReminder, show: true, appId: info?.results?.appIds });
                }
              });
            },
            doAfterException: (error) => {
              customSwaltAlertAsistencial({
                icon: "warning",
                title: "Intenta de nuevo",
                text: error.message,
                showCancelButton: false
              })
            },
          });
        }
      });
    } else {
      customSwaltAlertAsistencial({
        icon: "warning",
        title: "Intenta de nuevo",
        text: statusValidation.message,
        showCancelButton: false,
      })
    }

  };
  // ANCHOR DATE PICKER ONCHANGE
  const onChangeDatePicker = (i, newDate) => {

    scheduleInfoRow[i].date = newDate;
    scheduleInfoRow[i].date = newDate;

    getScheduleDoctor({
      url: "/medical/appointment/availability/",
      objFilters: {
        attentionGroup: props?.attGroupId,
        eaccount: idEnterprise,
        idService: scheduleInfoRow[i].service,
        city: scheduleInfoRow[i].location,
        idContractcorporateClient: props?.contractId,
        idCorporateClient: props?.corporateClientId,
        idPopulation: props?.populationId,
        dateSearch: moment(newDate).format("YYYY-MM-DD"),
        idDoctor: scheduleInfoRow[i]?.medic,
        site: scheduleInfoRow[i]?.site,
        modality: store.filterCheckTeleScheduleReducer.checked ? 'tmed' : "on_site",
      },
      token: token,
      doAfterSuccess: (data) => {
        
        props?.postTicketFunction("agenda_availability")
        let scheduleTempArr = scheduleInfoResults
        scheduleTempArr[i] = data;

        setScheduleInfoResults(
          scheduleTempArr?.map((el, index) => {
            if (index === i) {
              return data
            } else {
              return ({
                ...el,
                unique: generateId()
              })
            }
          })
        )

        setScheduleInfoRow(scheduleInfoRow?.map((el, index) => {
          if (index === i) {
            return {
              ...el,
              time: "",
              hourStart: "",
              hourEnd: "",
              schId: "",
              idDoctor: "",
              date: newDate
            }
          } else return el
        }));

        setTimeout(() => {
          setTriggerRender(triggerRender + 1);
        }, 200);

      },
    });
  };

  const handleBlockSchedulesHoursSelected = ({ hourSelected, dateSelected, idSchedule, type = 1, unique }) => {

    let listSchedulesHoursFormat = scheduleInfoResults?.map(scheduleInfo => {

      if (unique === scheduleInfo?.unique) {
        return scheduleInfo
      } else {
        return {
          ...scheduleInfo,
          results: scheduleInfo?.results?.map(el => {
            let schedules = {
              ...el,
              schedule: el?.schedule?.map(itemSchedule => {
                return {
                  ...itemSchedule,
                  hours: itemSchedule?.hours?.map(hour => {
                    if (hour?.hourStart === hourSelected && hour?.date === dateSelected) {
                      return { ...hour, appointment: type === 1, lastHoursSelectedByUser: hour?.hourStart, lastDateSelectedByUser: hour?.date }
                    } else if (hour?.hourStart === hour?.lastHoursSelectedByUser && hour?.date === hour?.lastDateSelectedByUser) {
                      return { ...hour, appointment: false }
                    }

                    return hour
                  })
                }
              })
            }
            return schedules
          })
        }
      }
    })

    setScheduleInfoResults(listSchedulesHoursFormat)
  }

  const [scheduleDoctorTime, setScheduleDoctorTime] = useState([{}]);
  const [scheduleInfoResults, setScheduleInfoResults] = useState([{}]);
  const [modalReminder, setModalReminder] = useState({
    appId: "",
    cluEmail: {
      inputValue: "",
      value: [],
    },
    emailSubject: "Recordatorio de cita",
    emailBody: props?.insureSelected.isMain === 0 ? `<p>Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir! </p>` : `<p> Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir!</p>`,
    fileBase64: "",
    show: false,
  });
  
  const createOptionReminder = (label) => ({
    label,
    value: label,
  });
  const [stateInputReminder, setStateInputReminder] = useState({
    inputValue: "",
    value: [{ label: `${props?.detailPatient?.results?.email}`, value: `${props?.detailPatient?.results?.email}` },],
  });
  let tempArrayReminder = [];
  stateInputReminder.value.forEach((elem) => {
    tempArrayReminder.push(elem.label);
  });
  const handleKeyDownReminder = (event) => {
    const { inputValue, value } = stateInputReminder;
    if (inputValue) {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case "Enter":
        case "Tab":
          let tempArray = value;
          tempArray.push(createOptionReminder(event.target.value));
          setStateInputReminder({ inputValue: "", value: tempArray });
      }
    }
  };
  const handleInputChangeReminder = (inputValue, tipo) => {
    if (typeof inputValue == "string") {
      setStateInputReminder({ ...stateInputReminder, inputValue: inputValue });
      let tempArray = [];
      stateInput.value.forEach((elem) => {
        tempArray.push(elem.label);
      });
    } else if (Array.isArray(inputValue)) {
      setStateInputReminder({ ...stateInputReminder, value: inputValue });
    }
  };


  const [requestQuotaModal, setRequestQuotaModal] = useState({
    show: false,
    appDateTime: "",
    srvId: "",
    schId: "",
    doctorId: "",
    ctypeId: props?.companyType,
    attGroupId: props?.attGroupId,
    eaccount: idEnterprise,
    comments: "",
    lockIdApp: "",
    contractId: props?.contractId,
  })
  
  const openRequestQuotaModal = (srvId, sch, date, idDoctor, site) => {
    
    let appDateTime = `${moment(date).format("YYYY-MM-DD")} ${sch.time}`;
    blockAgendaMethod({
      url: "/medical/appointment/lockTimeSlot/",
      method: "POST",
      body: {
        "schId": sch.idSchedule,
        "appDateTime": appDateTime,
        "userId": idUser,
        eaccount: idEnterprise,
      },
      noAlert: true,
      token: token,
      doAfterSuccess: (info) => {
        setRequestQuotaModal({
          ...requestQuotaModal,
          show: true,
          srvId: srvId,
          schId: sch.idSchedule,
          appDateTime: appDateTime,
          doctorId: idDoctor,
          lockIdApp: info.results
        })
      },
      doAfterException: (error) => {
      },
    });


    getServicesRequestQuota({
      url: "/medical/appointment/technicalDataServices/",
      objFilters: {
        modality: store.filterCheckTeleScheduleReducer.checked ? 'tmed' : "on_site",
        contractId: props?.contractId,
        cclientId: props?.corporateClientId,
        popId: props?.populationId,
        doctorId: idDoctor,
        siteId: sch.siteId,
      },
      token: token,
      doAfterSuccess: () => {
      },
    });

    //   setRequestQuotaModal({...requestQuotaModal,
    //     show: true,
    //     srvId: srvId,
    //     schId: sch.idSchedule,
    //     appDateTime: appDateTime,
    //     doctorId: idDoctor,
    // })
  }

  const closeRequestQuotaModal = () => {
    unlockAgendaMethod({
      url: "/medical/appointment/unlockTimeSlot/",
      method: "DELETE",
      body: {
        "appId": requestQuotaModal.lockIdApp,
        eaccount: idEnterprise,
      },
      noAlert: true,
      token: token,
      doAfterSuccess: (info) => {
        setRequestQuotaModal({ ...requestQuotaModal, show: false, lockIdApp: "", comments: "" })
      },
      doAfterException: (error) => {
      },
    });

  }

  const sendRequestQuota = () => {
    let namePatient = `${props?.detailPatient?.results?.firstName} ${props?.detailPatient?.results?.lastName} `
    requestQuotaMethod({
      url: "/medical/appointment/requestExtraQuota/",
      method: "POST",
      body: {
        "appDateTime": requestQuotaModal.appDateTime,
        "schId": requestQuotaModal.schId,
        "doctorId": requestQuotaModal.doctorId,
        "ctypeId": requestQuotaModal.ctypeId,
        "srvId": requestQuotaModal.srvId,
        "attGroupId": requestQuotaModal.attGroupId,
        comments: requestQuotaModal.comments,
        cluId: props?.detailPatient?.results?.id,
        contractId: requestQuotaModal.contractId,
        cluName: namePatient,
        rv: props?.wsValidated === 0 ? "False" : "True",
        popId: props?.populationId,
        eaccount: idEnterprise,
        userId: idUser,
        cclientId: props?.corporateClientId,
        crtName: props?.crtName
      },
      token: token,
      // doAfterSuccess: (info) => {
      //  message("info", info.message)
      // },
      // doAfterException: (error) => {
      //   message("info", error.message)
      // },
      doAfterSuccess: () => {
        props?.postTicketFunction("appointment_registration")
        getTicket({
          url: "/medical/appointment/concecutive/",
          objFilters: {
            idConsec: 23
          },
          token: token,
          doAfterSuccess: (res) => {
            props?.setTicketValue(res.results)
          },
        });
      },
      succesAction: (info) => {
        Swal.fire({
          icon: "success",
          title: "Cita registrada",
          text: info.message,
          confirmButtonText: "Ver Recordatorio",
        }).then((result) => {
          if (result.isConfirmed) {
            setModalReminder({
              ...modalReminder, show: true,
              appId: info?.results?.appIds
            });
            closeRequestQuotaModal()
          }
        });
      },
      doAfterException: (error) => {
        message("info", "Información", error.message);
      },
    });
  }


  return (
    <>
      {servicesLoader && loader}
      {loadPostSmartQue && loader}
      {unitiesLoader && loader}
      {locationsLoader && loader}
      {medicianListLoader && loader}
      {scheduleDoctorLoader && loader}
      {createReminderLoader && loader}
      {createAppointmentLoader && loader}
      {careGroupOptionsLoader && loader}
      {servicesRequestQuotaLoader && loader}
      {requestQuotaLoader && loader}
      {loadCiteAppointment && loader}
      {/* {ScheduleDoctor && loader} */}

      {/* REQUEST  EXTRA QUOTA */}
      {/* ANCHOR QUOTA MODAL */}
      <OrdModal
        title={"Solicitar cupo extra"}
        show={requestQuotaModal.show}
        btnYesName={"Solicitar"}
        btnNoName={"Cancelar"}
        size={400}
        onHide={() => closeRequestQuotaModal()}
        btnYesEvent={() => sendRequestQuota()}
        btnNoEvent={() => closeRequestQuotaModal()}
        sizeBody={`${tableStyles.modalHeight}`}
      >
        <div>
          <Form.Group controlId="servicio" className="py-2">
            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
              <b className={`${styles.app_title} px-1`}>Servicio</b>
            </Form.Label>
            <Select noOptionsMessage={() => 'No hay datos'}
              className="text-secondary"
              placeholder={"Seleccionar..."}
              styles={appCustomSelectNew}
              options={serviceRequestQuotaOptions}
              value={serviceRequestQuotaOptions.find((x) => x.value === requestQuotaModal.srvId)}
              onChange={(e) => setRequestQuotaModal({ ...requestQuotaModal, srvId: e.value })}
            />
          </Form.Group>
          <Form.Group controlId="servicio" className="py-2">
            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
              <b className={`${styles.app_title} px-1`}>Comentarios</b>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              className={`ord-roundInput `}
              value={requestQuotaModal.comments}
              onChange={(e) => setRequestQuotaModal({ ...requestQuotaModal, comments: e.target.value })}
            />
          </Form.Group>
        </div>
      </OrdModal>

      <FullScreenDialog onHide={() => closeModalReminder()} isOpen={modalReminder.show}>
        <div className="d-flex">
          <div>
            <RenderMedicalFormulasPdf
              pdfParams={{
                url: "/medical/appointment/pdf/",
                objFilters: { appIds: String(modalReminder.appId) },
                // objFilters: { appIds: 713 },
                doAfterSuccess: (response) => {
                  setModalReminder({ ...modalReminder, fileBase64: response.results[0].base64 });
                },
              }}
              title={"Recordatorio de cita"}
              goBack={true}
              goBackSmallDelete={true}
              backAction={() => {
                closeModalReminder();
              }}
            />
          </div>
          <div className=" d-flex justify-content-center" style={{ height: "100vh", width: "100vh", backgroundColor: "#F6FEFF" }}>
            <div className="p-2 align-self-center">
              <div>
                <b className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f32}`}>Enviar</b>
              </div>
              <div className={`${styles.app_container_calendar}`}>
                <Form.Group className="mb-3 text-start" controlId="to">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Para</span>
                  </Form.Label>
                  <CreatableSelect
                    components={{ DropdownIndicator: null }}
                    inputValue={stateInputReminder.inputValue}
                    className={`${tableStyles.heightSelectApp}`}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(e) => handleInputChangeReminder(e, "onchange")}
                    onInputChange={(e) => handleInputChangeReminder(e, "oninputchange")}
                    onKeyDown={(e) => {
                      handleKeyDownReminder(e);
                    }}
                    placeholder="Escribe..."
                    styles={ordCustomSelect}
                    value={stateInputReminder.value}
                  />
                </Form.Group>
                <Form.Group className="mb-3 text-start" controlId="subject">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Asunto</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput `}
                    type="text"
                    placeholder=""
                    value={modalReminder.emailSubject}
                    onChange={(e) => {
                      setModalReminder({ ...modalReminder, emailSubject: e.target.value });
                    }}
                  />
                </Form.Group>
                <div className="my-2" style={{ maxWidth: "350px" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setModalReminder({ ...modalReminder, emailBody: data });
                    }}
                    data={modalReminder.emailBody}
                  />
                </div>
                <div className={`d-flex my-2 `}>
                  <span className={`${tableStyles.appPdfReminderPin} px-2`}>Recordatorio de cita PDF</span>
                </div>
                <div className=" d-flex justify-content-end align-items-end align-self-end my-2 ">
                  <button
                    onClick={() => {
                      createReminder();
                    }}
                    className={`${tableStyles.ordBtnPrimary} btn`}
                  >
                    Enviar <img alt="sendIcon" src={sendIcon} className={`${tableStyles.svgWhiteFilter}`} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FullScreenDialog>
      {/* !SECTION RENDER REMINDER */}

      {/* ADVANCED SEARCH */}
      <OrdModal
        title={"Búsqueda avanzada"}
        show={showModal}
        // subtitle={"Medicamentos"}
        btnYesName={"Seleccionar"}
        btnNoName="Cancelar"
        size="940"
        btnYesEvent={() => searchValues()}
        onHide={() => {
          closeModal();
        }}
        btnNoEvent={() => {
          closeModal();
        }}
      >
        <Col xs={12} className="d-flex">
          <Col xs={4}>
            <div className={`${styles.filterSearchCard} `}>
              <b className={`${styles.app_title} px-1`}>Variables</b>
              {/* <Row> */}
              <div className="">
                <div className="col pr-0">
                  <span className={`${styles.selectLabelsSearch} px-1`}>Palabras claves</span>

                  <CreatableSelect
                    components={{ DropdownIndicator: null }}
                    inputValue={stateInput.inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(e) => handleInputChange(e, "onchange")}
                    onInputChange={(e) => handleInputChange(e, "oninputchange")}
                    onKeyDown={(e) => {
                      handleKeyDown(e);
                    }}
                    placeholder="Escribe una variable..."
                    styles={appCustomSelectNew}
                    value={stateInput.value}
                  />
                </div>
              </div>
              <div className="">
                <Form.Group controlId="especialidad">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <span className={`${styles.selectLabelsSearch} px-1`}>Especialidad</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    options={specialtyOptions}
                    onChange={(e) => {
                      setFilters({ ...filters, specialtyId: e.value });
                    }}
                  />
                </Form.Group>
              </div>
              <div className="">
                <Form.Group controlId="tipo">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <span className={`${styles.selectLabelsSearch} px-1`}>Tipo</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    options={serviceTypeOptions}
                    onChange={(e) => {
                      setFilters({ ...filters, typeId: e.value });
                    }}
                  />
                </Form.Group>
              </div>
              <div className="">
                <Form.Group controlId="sede">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <span className={`${styles.selectLabelsSearch} px-1`}>Sede</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    options={siteOptions}
                    onChange={(e) => {
                      setFilters({ ...filters, siteId: e.value });
                    }}
                  />
                </Form.Group>
              </div>
              <div className="">
                <Form.Group controlId="medico">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <span className={`${styles.selectLabelsSearch} px-1`}>Médico</span>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    options={medicOptions}
                    onChange={(e) => {
                      setFilters({ ...filters, idDoctor: e.value });
                    }}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <span className={`${styles.selectLabelsSearch} px-1`}>Acrónimo</span>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type="text"
                    // value={detailPatient?.results?.lastName}
                    placeholder="Escribe..."
                    onChange={(e) => {
                      setFilters({ ...filters, acronym: e.target.value });
                    }}
                  />
                </Form.Group>
              </div>
              <div className="">
                <Form.Group controlId="fName">
                  {/* <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}> */}
                  <b className={`${styles.app_title} px-1`}>Variables</b>
                  {/* </Form.Label> */}
                  <div>
                    <Radio
                      checked={filters.orderBy === "name" ? false : true}
                      onClick={() => {
                        setFilters({ ...filters, orderBy: "creation" });
                      }}
                      // onChange={(e) => handleRadioChange(e)}
                      // value="alphabetic"
                      name="alphabetic"
                      className={"text-info"}
                      id="alphabetic"
                    />
                    <span className={`col-6 ${stylesTableFile.app_subtitle_card_order}`}>Fecha de creación</span>
                  </div>
                  <div>
                    <Radio
                      checked={filters.orderBy === "name" ? true : false}
                      // onChange={(e) => handleRadioChange(e)}
                      onClick={() => {
                        setFilters({ ...filters, orderBy: "name" });
                      }}
                      // value="alphabetic"
                      name="alphabetic"
                      className={"text-info"}
                      id="alphabetic"
                    />
                    <span className={`col-6 ${stylesTableFile.app_subtitle_card_order}`}>Orden alfabético</span>
                  </div>
                </Form.Group>
              </div>
              <div className=" d-flex justify-content-end align-items-end  mb-2">
                <img
                  className="hoverPointer"
                  height="27px"
                  alt="search"
                  src={icon_search}
                  // style={{height: '100px'}}
                  onClick={() => {
                    setTrigger(trigger + 1);
                  }}
                />
              </div>
              {/* </Row> */}
            </div>
          </Col>
          <Col xs={8}>
            <div className="">
              <OrdTable
                headers={SearchHeaders}
                hasChildren={true}
                paginate={{
                  activePage: filters.page,
                  totalPages: articlesList?.row_total,
                  // totalPages: articlesList?.length,
                  perPage: filters.perpage,
                  pageRangeDisplayed: 3,
                  onChangePage: async (e) => {
                    const result = await getServices({
                      url: "/appointment/serviceEntity/",
                      objFilters: {
                        filters,
                        modality: store.filterCheckTeleScheduleReducer.checked ? 'tmed' : "on_site"
                        , page: e
                      },
                      token: token,
                    });
                    setFilters({ ...filters, page: e });
                    formatData(result?.results);
                  },
                  showTextDetails: true,
                }}
                shadow={true}
              >
                {formatData(servicesResults?.results)}
              </OrdTable>
            </div>
          </Col>
        </Col>
      </OrdModal>

      <div className={`px-2 my-2  mb-2 `}>
        <input
          type="checkbox"
          name="handleTelemedicine"
          className={` ${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear} cursorPointer form-check-input p1 `}
          checked={dataCreateAppointment.multiSelected}
          onClick={(e) => {
            message("info", "Selección múltiple", "Esta funcionalidad se encuentra en desarrollo")
            // setDataCreateAppointment({ ...dataCreateAppointment, multiSelected: !dataCreateAppointment.multiSelected });
          }}
        />
        <Form.Label className={`m-0 ${tableStyles.tlnTextGray} ${tableStyles.f12} `}>
          <span className={` px-2`}>Selección múltiple avanzada</span>
        </Form.Label>
      </div>
      {scheduleInfoRow?.length > 0 &&
        scheduleInfoRow.map((e, i) => {
          let datePickerBorder = dataCreateAppointment.multiSelected ? "#F7B4A5" : "#00B4CC";
          let cardContainer = dataCreateAppointment.multiSelected ? styles.app_container_calendar_multi : styles.app_container_calendar;
          let line = dataCreateAppointment.multiSelected ? styles.app_line_search_multi : styles.app_line_search;
          let titleSearch = dataCreateAppointment.multiSelected ? styles.app_title_search_multi : styles.app_title_search;
          return (
            <>
              <div className={`${cardContainer} ${styles.app_transition} mb-3`} key={i}>
                {/* ANCHOR ROW SEARCH */}
                <div className="mb-3">
                  <Box display={'grid'} columnGap='20px' gridTemplateColumns='1fr 1fr 1fr 1fr 70px' >
                    <div>
                      {!dataCreateAppointment.multiSelected ? (
                        <Form.Group controlId="servicio">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className={`${styles.app_title} px-1`}>Servicio</b>
                          </Form.Label>

                          <Select noOptionsMessage={() => 'No hay datos'}
                            className="text-secondary"
                            placeholder={"Seleccionar..."}
                            styles={appCustomSelectNew}
                            options={servicesResults?.results?.map(e => ({ label: e.srvName, value: e.srvId }))}
                            value={
                              servicesResults?.results?.map(e => ({ label: e.srvName, value: e.srvId }))?.find((x) => x.value === scheduleInfoRow[i].service)
                              || []
                            }
                            onChange={(e) => handleChangeService(e, i)}
                          />
                        </Form.Group>
                      ) : (
                        <Form.Group controlId="attGroup">
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className={`${styles.app_title} px-1`}>Grupos de atención</b>
                          </Form.Label>
                          <Select noOptionsMessage={() => 'No hay datos'}
                            className="text-secondary"
                            placeholder={"Seleccionar..."}
                            styles={appCustomSelectNew}
                            options={careGroupOptions}
                            value={careGroupOptions.find((x) => x.value === searchInfo.service)}
                          // onChange={(e) => handleChangeService(e)}
                          />
                        </Form.Group>
                      )}
                    </div>

                    <div>
                      <Form.Group controlId="ubicacion">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b className={`${styles.app_title} px-1`}>Ubicación</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          className="text-secondary"
                          placeholder={"Seleccionar..."}
                          styles={appCustomSelectNew}
                          value={locationList[i]?.find((x) => x.value === scheduleInfoRow[i].location) || []}
                          options={locationList[i]}
                          onChange={(e) => handleChangeLocation(e, i)}
                        />
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group controlId="sede">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b className={`${styles.app_title} px-1`}>Sede</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          className="text-secondary"
                          placeholder={"Seleccionar..."}
                          styles={appCustomSelectNew}
                          value={siteList[i].find((x) => x.value === scheduleInfoRow[i].site)}
                          options={siteList[i]}
                          onChange={(e) => handleChangeSite(e, i)}
                        />
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group controlId="medicoSearch">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b className={`${styles.app_title} px-1`}>Médico</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          className="text-secondary"
                          placeholder={"Seleccionar..."}
                          styles={appCustomSelectNew}
                          value={formattedMedicianList.find((x) => x.value === scheduleInfoRow[i].medic)}
                          options={formattedMedicianList}
                          onChange={(e) => handleChangeMedician(e, i)}
                        />
                      </Form.Group>
                    </div>

                    <div
                      className="col-1 d-flex justify-content-start align-items-end m-0 p-0"
                    >
                      <div className="d-flex justify-content-end ">
                        {i >= 1 && (
                          <img
                            src={clearIcon}
                            width={15}
                            className={"cursorPointer"}
                            alt="closeIcon"
                            style={{ marginRight: "-40px", marginTop: "-100px" }}
                            onClick={() => {
                              deleteServiceRow(i);
                            }}
                          />
                        )}
                      </div>

                      <img
                        src={searchCalendar}
                        width={"25px"}
                        alt="search-icon"
                        style={{ width: "25px" }}
                        onClick={() => openScheduleRow(e, i)}
                        className="col-1 d-flex justify-content-center align-items-end cursorPointer mb-1"
                      />
                    </div>

                  </Box>
                  <button onClick={() => setShowModal(true)}>
                    <span className={titleSearch}>Búsqueda avanzada</span>
                  </button>
                  <div className={line} />
                </div>

                <Row ref={ref} key={"rowCard" + triggerRow} style={{ marginTop: '1.2rem' }}>
                  {scheduleInfoRow[i].optionsRow.calendarSearch === true ? (
                    <>
                      <Col xs={4}>

                        <DatePicker
                          onChange={(newDate) => onChangeDatePicker(i, newDate)}
                          selected={'' + scheduleInfoRow[i].date !== 'Invalid Date' ? scheduleInfoRow[i].date : new Date()}
                          key={`datePicker + ${triggerPicker[i]}`}
                          style={{ border: `1px solid ${datePickerBorder}` }}
                        />
                      </Col>
                      {scheduleInfoResults[i]?.results?.length > 0 ? (
                        <>
                          <Col xs={5} className={styles.app_container_calendar_component}>
                            {scheduleInfoResults[i].results.map((item, index) => {
                              return scheduleInfoRow[i].idDoctor !== "" ? (
                                <div key={index}>
                                  {(item?.schedule?.length > 0 && index === e?.index) &&
                                    item?.schedule.map((sch, index) => {
                                      let hourSch = sch?.time?.split(":")[0];
                                      let showExtraQuota = true
                                      sch.hours.forEach((e) => {
                                        if (e.statusAppointment === "block_agenda") {
                                          showExtraQuota = false
                                        }
                                      })
                                      return (
                                        <div key={index} className={styles.app_container_map}>
                                          <div className={styles.line}>
                                            <div className={styles.app_container_left_hours}>
                                              <span className={styles.app_title}>{hourSch}</span>
                                            </div>
                                          </div>
                                          <Carousel
                                            itemsToShow={3}
                                            itemsToScroll={3}
                                            showArrows={true}
                                            pagination={false}
                                            renderArrow={renderArrow}
                                          >
                                            {sch.hours.map((hour, index) => {
                                              let hourCard = `${hour?.hourStart.slice(0, hour?.hourStart?.length - 3)}`;
                                              return (
                                                <OverlayTrigger
                                                  placement="top"
                                                  delay={{ show: 250, hide: 400 }}
                                                  overlay={renderTooltipTypeIcon(hour.nameSite)}
                                                >
                                                  <div key={index} className={styles.app_container_map}>
                                                    <div
                                                      className={
                                                        hour.appointment
                                                          ? styles.app_container_hours_inactive
                                                          : dataCreateAppointment.multiSelected
                                                            ? hour.hourStart === scheduleInfoRow[i].hourStart
                                                              ? styles.app_container_hours_active_multi
                                                              : styles.app_container_hours_multi
                                                            : hour.hourStart === scheduleInfoRow[i].hourStart
                                                              ? styles.app_container_hours_active
                                                              : styles.app_container_hours
                                                      }
                                                      onClick={() => !hour.appointment && handleClickSchedule(hour, i, scheduleInfoResults[i]?.unique)}
                                                    >
                                                      <span
                                                        style={{
                                                          color: hour.hourStart === scheduleInfoRow[i].hourStart ? "#FFFFFF" : "#6e6f7c",
                                                        }}
                                                        className={styles.hours}
                                                      >
                                                        {hourCard}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </OverlayTrigger>
                                              );
                                            })}
                                            {showExtraQuota &&
                                              <div
                                                className={styles.app_container_sum}
                                                onClick={() => openRequestQuotaModal(scheduleInfoRow[i].service, sch, scheduleInfoRow[i].date,
                                                  scheduleInfoRow[i].idDoctor,)}
                                              >
                                                <span>+</span>
                                              </div>
                                            }
                                          </Carousel>
                                        </div>
                                      );
                                    })}
                                </div>
                              ) : index === 0 ? (
                                <div className="d-flex justify-content-center w-100 h-100 justify-align-center align-middle align-self-center">
                                  <div className="d-flex w-100 h-100 align-items-center justify-content-center justify-align-center align-middle align-self-center">
                                    <span className={styles.app_title_card} style={{ textAlign: "center" }}>
                                      Seleccione especialista
                                    </span>
                                  </div>
                                </div>
                              ) : ''
                            })}
                          </Col>
                          <Col xs={3} className={styles.app_container_card_component}>
                            {scheduleInfoResults[i].results?.length > 0 &&
                              scheduleInfoResults[i]?.results.map((item, index) => {
                                
                                return (
                                  <div
                                    key={index + item.idDoctor}
                                    className={
                                      dataCreateAppointment.multiSelected
                                        ? item.schId === scheduleInfoRow[i].schId
                                          ? styles.app_card_doctor_active_multi
                                          : styles.app_card_doctor_multi
                                        : item?.idSchedule === scheduleInfoRow[i]?.idSchedule
                                          ? styles.app_card_doctor_active
                                          : styles.app_card_doctor
                                    }
                                    style={{ flexDirection: 'column', color: '#6e6f7c' }}
                                    onClick={() => handleClickDoctor(item, i, index)}
                                  >
                                    <div className="d-flex gap-2">
                                      <img
                                        className={`${styles.app_container_img}`}
                                        src={item.image || userImg}
                                        alt="doctor-img"
                                        style={item?.image ? { filter: 'none' } : {}}
                                      />

                                      <div className={styles.container_name_doctor}>
                                        <span
                                          className={styles.app_title_card}
                                          style={{
                                            color: item.idSchedule === scheduleInfoRow[i].idSchedule ? "#FFFFFF" : "#6e6f7c",
                                          }}
                                        >
                                          {item.doctor}
                                        </span>
                                        <span
                                          className={styles.app_site_card}
                                          style={{
                                            color: item.schId === scheduleInfoRow[i].schId ? "#FFFFFF" : "#6e6f7c",
                                          }}
                                        >
                                        </span>
                                      </div>

                                    </div>

                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 250 }}
                                      overlay={renderTooltipSchedule(item?.scheduleName || '-')}
                                    >
                                      <div
                                        className="d-flex gap-2 align-items-center"
                                      // style={{ color: item.idSchedule === scheduleActive ? "#FFFFFF" : "#6e6f7c", }}
                                      >
                                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Agenda:</div>
                                        <div
                                          className={tableStyles.elipsis}
                                          style={{
                                            fontSize: '10px'
                                          }}
                                        >
                                          {item?.scheduleName || '-'}
                                        </div>
                                      </div>
                                    </OverlayTrigger>

                                  </div>
                                );
                              })}
                          </Col>
                        </>
                      ) : (
                        <Col xs={8} className="d-flex justify-content-center justify-align-center align-middle align-self-center">
                          <div className="d-flex w-100 h-100 justify-content-center justify-align-center align-middle align-self-center">
                            <span className={styles.app_title_card} style={{ textAlign: "center" }}>
                              No hay agendas disponibles
                            </span>
                          </div>
                        </Col>
                      )}
                    </>
                  ) : null}
                </Row>
              </div>
              {scheduleInfoRow[i].optionsRow.calendarSearch && (
                <div className="my-2">
                  <Form.Group className="mb-3" controlId="fName">
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <span className={` px-1`}>Anotaciones</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      className="text-secondary"
                      type="text"
                      placeholder="Escribe..."
                      // value={e.appObservation}
                      onChange={(e) => {
                        let arrCopy = scheduleInfoRow;
                        // scheduleInfoRow[i].appObservation = e.target.value;
                        arrCopy[i].appObservation = e.target.value;
                        setScheduleInfoRow(arrCopy);
                      }}
                    />
                  </Form.Group>
                </div>
              )}
            </>
          );
        })}
      <div className="my-2">
        {!dataCreateAppointment.multiSelected && (
          <button onClick={() => addNewServiceRow()} className={`${tableStyles.appButtonHover}`}>
            <img alt="addIcon" src={addIcon} className={`${tableStyles.AppIconHoverTurquoise}`} />
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14} ${tableStyles.appTextHoverTurquoise} mx-2`}>Añadir servicio </b>
          </button>
        )}
      </div>
      <div
        style={{
          width: "100%",
          padding: " 1rem 2rem",
          position: "relative",
          marginTop: "1rem",
        }}
        className="d-flex px-4  justify-content-end align-items-end align-self-end "
      >
        <div style={{}}>
          <div className="px-4  d-flex justify-content-end align-items-end align-self-end mb-2 ">
            <button
              onClick={() =>
                props?.goBackFunction()
              }
              // style={{backgroundColor: "#fff"  }}
              className={`mr-2 btn  ${tableStyles.ordBtnSecondary}`}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                // setData({ ...data, attGroup: careGroupRow.attGroup });
                createAppointment();
              }}
              className={`${tableStyles.ordBtnPrimary} btn`}
            >
              Registrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shedule;
