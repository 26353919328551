import React, { useEffect, useState } from "react";
// import chroma from 'chroma-js';
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import estetoscopic from "../../assets/img/icons/estetoscopic.svg";
import estetoscopicBlue from "../../assets/img/icons/estetoscopicBlue.svg";
import ordOrangeDownloadArrow from "../../assets/img/icons/ordOrangeDownloadArrow.svg";
import focaEye from "../../assets/img/icons/focaEye.svg";
import focaEyeBlue from "../../assets/img/icons/focaEyeBlue.svg";
import miniRoundWhiteCheck from "../../assets/img/icons/miniRoundWhiteCheck.svg";
import poundSign from "../../assets/img/icons/CentrodeCosto.svg";
import pillDarkBlue from "../../assets/img/icons/pillDarkBlue.svg";
import ordSugess from "../../assets/img/icons/ordSugess.svg";
import ordBackArrow from "../../assets/img/icons/ordBackArrow.svg";
import cleanCloseIcon from "../../assets/img/icons/cleanCloseIcon.svg";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { OrdGenericTemplate } from "../OrderingComponents/OrdGenericTemplate";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, TextField } from "@material-ui/core";
import Select from "react-select";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import OrdTable from "../OrderingComponents/OrdTable";
import { useSelector } from "react-redux";
import { useGetMethod, useMultiGetMethod, usePostMethod } from "../../Hooks/useFetch";
import { formatToRcSelect, loader, message, validateEmptyString, isEmptyOrUndefined, swalConfirm, getPermission } from "../../helpers/helpers";
import FullScreenDialog from "../OrderingComponents/FullScreenDialog";
import { RenderMedicalFormulasPdf } from "../../AsistanceModule/RenderMedicalFormulasPdf";
import OrdModal from "../OrderingComponents/OrdModal";
import { OrdCustomToast } from '../OrderingComponents/OrdCustomToast';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Box } from "@mui/material";


export const ServicesTab = (event) => {
  const isCloningServices = event?.clone?.isCloningServices;
  const cloneData = event?.clone?.cloneData;
  // //console.log("cloneData: ", cloneData);
  const store = useSelector((state) => state);
  const listPermission = store.loginReducer.currentAccount?.profile?.permission
  const myPermission = getPermission({ prefix: 'order', listPermission })
  const token = store.loginReducer.Authorization;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [consultTmpArr, setConsultTmpArr] = useState([]);
  // //console.log("consultTmpArr: ", consultTmpArr);
  const [changeView, setChangeView] = useState({
    generalView: true,
    diagnostics: false,
    procedure: false,
  });
  const [dataOut, setDataOut] = useState({
    consultReason: "",
    procedureReason: "",
    consultGeneralObservations: "",
    procedureGeneralObservations: "",
  });
  const [procedureTmpArr, setProcedureTmpArr] = useState([]);

  const [fullDialogScreen, setFullDialogScreen] = useState({
    show: false,
    id: "",
  });
  const [procedureData, setProcedureData] = useState({
    reason: "",
    orderType: "",
    service: "",
    anesthesia: "",
    eye: "",
    time: "",
    timeVal: "",
    generalObservation: "",
    duration: "",
    durationVal: "",
  });
  const [consultData, setConsultData] = useState({
    reason: "",
    orderType: "",
    service: "",
    anesthesia: "",
    eye: "",
    time: "",
    timeVal: "",
    generalObservation: "",
    durationVal: '',
    duration: '',
  });

  const [trigger, setTrigger] = useState(0);
  const [preQuiIds, setPreQuiIds] = useState([]);
  // //console.log("preQuiIds: ", preQuiIds);
  const [reqItems, setReqItems] = useState(0)

  let tempArray = [];
  const MySwal = withReactContent(Swal);
  /* ----------------------------- FETCHS SECTION ----------------------------- */
  /* ----------------------------- GET ----------------------------- */
  const { results: initialList, load: initialListLoader, trigger: getInitialList } = useMultiGetMethod();
  const { results: offeredServices, load: offeredServicesLoader, trigger: getOfferedServices } = useGetMethod();
  const { results: servicesToCloneList, load: servicesToCloneLoader, trigger: getServicesToClone } = useGetMethod();
  const { results: reasonsToClone, load: reasonsToCloneLoader, trigger: getReasonsToClone } = useGetMethod();
  const { results: prequisurgicalExams, load: prequisurgicalExamsLoader, trigger: getPrequisurgicalExams } = useGetMethod();

  // NEWWWWW PETITIONS -------------------------------------------


  const { results: times, load: timesLoader, trigger: getTimes } = useGetMethod();
  const { results: bodyOrgans, load: bodyOrgansLoader, trigger: getbodyOrgans } = useGetMethod();
  const { results: anesthesiaType, load: anesthesiaTypeLoader, trigger: getAnesthesiaType } = useGetMethod();
  const { results: orderingReasons, load: orderingReasonsLoader, trigger: getOrderingReasons } = useGetMethod();
  const { results: orderType, load: orderTypeLoader, trigger: getOrderType } = useGetMethod();

  /* ---------------------------------- POST /PUT ---------------------------------- */
  const { load: createServiceOrderLoader, trigger: createServiceOrder } = usePostMethod();
  /* ------------------------------------ - ----------------------------------- */
  // useEffect(() => {
  //   getInitialList({
  //     multipleGet: [
  //       {
  //         url: "/medical/time/",
  //         objFilters: { id_account: idEnterprise },
  //         requestName: "times",
  //         token: token,
  //       },
  //       {
  //         url: "/medical/bodyOrgans/",
  //         objFilters: { draId: 1 },
  //         requestName: "bodyOrgans",
  //       },
  //       {
  //         url: "/medical/anesthesiaTypes/",
  //         objFilters: { exams: 1 },
  //         requestName: "anesthesiaType",
  //       },
  //       {
  //         url: "/medical/orderingReasons/",
  //         objFilters: { id_account: idEnterprise },
  //         requestName: "orderingReasons",
  //         token: token,
  //       },
  //       {
  //         url: "/medical/orderType/",
  //         requestName: "orderType",
  //       },
  //     ],
  //     token: token,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [idEnterprise, token, trigger]);

  // useEffect(() => {
  //   getOfferedServices({
  //     url: "/medical/offered-services",
  //     objFilters: {
  //       slim: 1,
  //       idAccount: idEnterprise,
  //       ordServ: changeView.diagnostics ? 1 : 2,
  //       orderType: changeView.diagnostics ? consultData.orderType?.value : procedureData.orderType?.value,
  //       contract: event?.event?.contractCorpClient,
  //     },
  //     token: token,
  //   });
  //   // if (changeView.procedure) {
  //   getPrequisurgicalExams({
  //     url: "/medical/presurgicalExams/",
  //     token: token,
  //   });
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [consultData.orderType?.value, procedureData.orderType?.value]);

  useEffect(() => {

    getOrderingReasons({
      url: "/medical/orderingReasons/",
      objFilters: { id_account: idEnterprise },
      token: token,
    });

    getOrderType({
      url: "/medical/orderType/",
      token: token,
      doAfterSuccess: () => {

        getOfferedServices({
          url: "/medical/offered-services",
          objFilters: {
            slim: 1,
            idAccount: idEnterprise,
            ordServ: changeView.diagnostics ? 1 : 2,
            orderType: changeView.diagnostics ? consultData.orderType?.value : procedureData.orderType?.value,
            contract: event?.event?.contractCorpClient,
          },
          token: token,
        });

        getAnesthesiaType({
          url: "/medical/anesthesiaTypes/",
          objFilters: { exams: 1 },
          token: token,
        });

        getbodyOrgans({
          url: "/medical/bodyOrgans/",
          objFilters: { draId: 1 },
          token: token,
          doAfterSuccess: () => {

            getTimes({
              url: "/medical/time/",
              objFilters: { id_account: idEnterprise },
              token: token,
            });



          }

        })

        getPrequisurgicalExams({
          url: "/medical/presurgicalExams/",
          token: token,
        });

      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, token, idEnterprise, consultData.orderType?.value, procedureData.orderType?.value]);

  const formattedTimes = formatToRcSelect(times?.results, "id", "name", "value");
  const formattedBodyOrgans = formatToRcSelect(bodyOrgans?.results, "id", "name", "", "", "");
  const formattedReasons = formatToRcSelect(orderingReasons?.results, "id", "name", "", "", "");
  const formattedAnesthesia = formatToRcSelect(anesthesiaType?.results, "id", "name", "exams", "", "", false);
  const formattedOrderType = formatToRcSelect(orderType?.results, "name", "name", "id", "", "", false)

  // const formattedOrderType =
  //   orderType?.results?.length > 0 &&
  //   orderType?.results?.map((e, i) => {
  //     return {
  //       value: e?.name,
  //       extra: e?.id,
  //       label: (
  //         <div>
  //           <img
  //             alt="sign"
  //             src={e?.name === "Particular" ? poundSign : e?.name === "Aseguradora" ? pillDarkBlue : ordSugess}
  //             width="15px"
  //           />{" "}
  //           &nbsp;{e?.name}
  //         </div>
  //       ),
  //     };
  //   });
  // //console.log("formattedAnesthesia => ", formattedAnesthesia)
  // //console.log("formattedBodyOrgans => ", formattedBodyOrgans)
  // //console.log("formattedOrderType: ", formattedOrderType);

  // const formattedTimes = formatToRcSelect(initialList?.times?.results, "id", "name", "value");
  // const formattedBodyOrgans = formatToRcSelect(initialList.bodyOrgans?.results, "id", "name", "", "", "");
  // const formattedReasons = formatToRcSelect(initialList?.orderingReasons?.results, "id", "name", "", "", "");
  // const formattedAnesthesia = formatToRcSelect(initialList?.anesthesiaType?.results, "id", "name", "exams", "", "", false);
  // // const formattedOrderType = formatToRcSelect(initialList?.orderType?.results, "id", "name", "id", "", "", false)

  // const formattedOrderType =
  //   initialList?.orderType?.results?.length > 0 &&
  //   initialList?.orderType?.results?.map((e, i) => {
  //     return {
  //       value: e?.name,
  //       extra: e?.id,
  //       label: (
  //         <div>
  //           <img
  //             alt="sign"
  //             src={e?.name === "Particular" ? poundSign : e?.name === "Aseguradora" ? pillDarkBlue : ordSugess}
  //             width="15px"
  //           />{" "}
  //           &nbsp;{e?.name}
  //         </div>
  //       ),
  //     };
  //   });
  //   //console.log("formattedAnesthesia => ", formattedAnesthesia)
  //   //console.log("formattedBodyOrgans => ", formattedBodyOrgans)
  //   //console.log("formattedOrderType: ", formattedOrderType);



  // OFFERED SERVICES--------------------------

  const formattedOfferedServices = () => {
    let tmpList = [];
    (isCloningServices && cloneData.orderServiceKind === "consult")
      ? servicesToCloneList.results?.length > 0 &&
      servicesToCloneList.results?.forEach((item) => {
        tmpList.push({
          value: [item?.name, item?.id],
          label: <span style={{ color: item.color }}>
            {item?.name} - {item.id}
          </span>
          ,
          id: item.id,
          extra: item.cupCode,
          extra2: item.cupCode,
          extra3: item.id,
          extra4: item.reqAuth,
          extra5: item.name,
        });
      })
      : offeredServices?.results?.length > 0 &&
      offeredServices?.results?.forEach((item) => {
        tmpList.push({
          value: [item?.name, item?.id],
          label: (
            <span style={{ color: item.color }}>
              {item?.name} - {item.id}
            </span>
          ),
          id: item.id,
          extra: item.cupCode,
          extra2: item.cupCode,
          extra3: item.id,
          extra4: item.reqAuth,
          extra5: item.name,
        });
      });
    // //console.log("tmpListaaaa: ", tmpList);

    return tmpList;
  };

  const formattedOfferedServicesProcedure = () => {
    let tmpListP = [];
    isCloningServices
      ? servicesToCloneList.results?.length > 0 &&
      servicesToCloneList.results?.forEach((item) => {
        tmpListP.push({
          value: [item?.name, item?.id],
          label: (
            <span style={{ color: item.color }}>
              {item?.name} - {item.id}
            </span>
          ),
          id: item.id,
          extra: item.cupCode,
          extra2: item.cupCode,
          extra3: item.id,
          extra4: item.reqAuth,
          extra5: item.name,
          extra6: item?.optimalTimeId,
          extra7: item?.optimalTimeOrdId,
          extra8: item?.optimalTimeOrdQty,
          extra9: item?.optimalTimeQty,
        });
      })
      : offeredServices?.results?.length > 0 &&
      offeredServices?.results?.forEach((item) => {
        tmpListP.push({
          //   value: item?.name ,
          value: [item?.name, item?.id],
          label: (
            <span style={{ color: item.color }}>
              {item?.name} - {item.id}
            </span>
          ),
          //   label: `${item?.name} - ${item.id}`,
          color: item.color,
          id: item.id,
          extra: item.cupCode,
          extra2: item.cupCode,
          extra3: item.id,
          extra4: item.reqAuth,
          extra5: item.name,
          extra6: item?.optimalTimeId,
          extra7: item?.optimalTimeOrdId,
          extra8: item?.optimalTimeOrdQty,
          extra9: item?.optimalTimeQty,
        });
      });
    // //console.log("tmpList22222a: ", tmpListP);

    return tmpListP;
  };

  // const formattedOfferedServicesProcedure = () => {
  //   let tmpList = [];
  //   offeredServices?.results?.length > 0 &&
  //     offeredServices?.results?.forEach((item) => {
  //       tmpList.push({
  //       //   value: item?.name ,
  //         value: [item?.name, item?.id] ,
  //         label: (
  //           <span style={{ color: item.color }}>
  //             {item?.name} - {item.id}
  //           </span>
  //         ),
  //       //   label: `${item?.name} - ${item.id}`,
  //         color: item.color,
  //         id: item.id,
  //         extra: item.cupCode,
  //         extra2: item.cupCode,
  //         extra3: item.id,
  //         extra4: item.reqAuth,
  //         extra5: item.name,
  //       });
  //     });
  //   return tmpList;
  // };

  // //console.log('================================Procedrure=>', formattedOfferedServicesProcedure())


  // //console.log('================================Normal=>=>', formattedOfferedServices())

  const deleteItem = (item) => {
    let { id } = item;
    let newArr = consultTmpArr.length > 0 && consultTmpArr.filter((x) => x.id !== id);
    setConsultTmpArr(newArr);
    setReqItems(reqItems - 1)
  };
  const procedureDeleteItem = (item) => {
    let { id } = item;
    let newArr = procedureTmpArr.length > 0 && procedureTmpArr.filter((x) => x.id !== id);
    setProcedureTmpArr(newArr);
    setReqItems(reqItems - 1)
  };
  const renderOrderTypeTooltip = (text) => <Tooltip>{text}</Tooltip>;
  const consultFormatData = () => {
    // //console.log("consultTmpArr => ", consultTmpArr)
    let tempList = [];
    consultTmpArr.length > 0 &&
      consultTmpArr.forEach((item, index) => {
        let orderType = item.orderType?.value;
        let orangeText = item?.isRequ === true ? tableStyles.ordOrangeText : ""
        tempList.push(
          <>
            <tr key={index} className={`hover-table-row `}>
              <td className={`text-center ${orangeText}`}> &nbsp;{item.service?.id}</td>
              <td className={`text-start ${orangeText}`}>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderOrderTypeTooltip(orderType)}>
                  <span>
                    <img
                      alt="sign"
                      src={orderType === "Particular" ? poundSign : orderType === "Aseguradora" ? pillDarkBlue : ordSugess}
                      width="15px"
                    />
                  </span>
                </OverlayTrigger>
                &nbsp;
                <span>{`${item.service?.extra5} - ${item.service?.extra3}`} </span>
              </td>

              {/* <td className={`text-start`}> </td> */}

              {/* <td className={`text-start ${orangeText}`}> {item.anesthesia?.label}</td> */}
              <td className={`text-start ${orangeText}`}> {item?.eye?.label}</td>
              <td className={`text-start ${orangeText}`}> {`${item?.timeVal}  ${item.time?.label}`}</td>
              <td className={`text-start ${orangeText}`}> {`${item?.durationVal}  ${item.duration?.label}`}</td>
              <td className={`text-center `}>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltiDelete}>
                  <span>
                    <img className="cursorPointer" onClick={() => deleteItem(item)} src={cleanCloseIcon} width="10px" alt="Eliminar"></img>{" "}
                  </span>
                </OverlayTrigger>
              </td>
            </tr>
          </>
        );
      });
    return tempList;
  };
  const procedureFormatData = () => {
    // //console.log("procedureTmpArr1111 => ", procedureTmpArr)

    let tempList = [];
    procedureTmpArr.length > 0 &&
      procedureTmpArr.forEach((item, index) => {
        let orderType = item.orderType?.value;
        let orangeText = item?.isRequ === true ? tableStyles.ordOrangeText : ""
        tempList.push(
          <>
            <tr key={index} className={`hover-table-row`}>
              <td className={`text-center ${orangeText}`}> &nbsp;{item.service?.id}</td>
              <td className={`text-start ${orangeText}`}>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderOrderTypeTooltip(orderType)}>
                  <span>
                    <img
                      alt="sign"
                      src={orderType === "Particular" ? poundSign : orderType === "Aseguradora" ? pillDarkBlue : ordSugess}
                      width="15px"
                    />
                  </span>
                </OverlayTrigger>
                &nbsp;
                <span>{`${item.service?.extra5} - ${item.service?.extra}`}</span>
              </td>
              <td className={`text-start ${orangeText}`}> {item.anesthesia?.label}</td>
              <td className={`text-start ${orangeText}`}> {item.eye?.label}</td>
              <td className={`text-start ${orangeText}`}> {`${item?.timeVal}  ${item.time?.label}`}</td>
              <td className={`text-start ${orangeText}`}> {`${item?.durationVal}  ${item.duration?.label}`}</td>
              <td className={`text-start `}>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltiDelete}>
                  <span>
                    <img
                      className="cursorPointer"
                      onClick={() => procedureDeleteItem(item)}
                      src={cleanCloseIcon}
                      width="10px"
                      alt="Eliminar"
                    ></img>{" "}
                  </span>
                </OverlayTrigger>
              </td>
            </tr>
          </>
        );
      });
    return tempList;
  };
  const renderTooltiDelete = (props) => <Tooltip {...props}>Eliminar</Tooltip>;
  const headers = [
    {
      title: "Código",
      className: "px-2 text-start col-1",
    },
    {
      title: "Servicio",
      className: "text-start col-4",
    },
    // {
    //   title: "Tipo de anestesia",
    //   className: "text-start col-3",
    // },
    {
      title: "Ojo",
      className: "text-start col-2",
    },
    {
      title: "Programación",
      className: "text-start col-3",
    },
    {
      title: "Tiempo",
      className: "text-start",
    },
    {
      title: <>&nbsp;&nbsp;</>,
    },
  ];
  const headersProcedure = [
    {
      title: "Código",
      className: "px-2 text-start col-1",
    },
    {
      title: "Servicio",
      className: "text-start col-3",
    },
    {
      title: "Tipo de anestesia",
      className: "text-start col-3",
    },
    {
      title: "Ojo",
      className: "text-start col-1",
    },
    {
      title: "Programación",
      className: "text-start col-2",
    },
    {
      title: "Tiempo",
      className: "text-start",
    },
    {
      title: <>&nbsp;&nbsp;</>,
    },
  ];
  const [justification, setJustification] = useState({
    showModal: false,
    consultJustification: "",
    procedureJustification: "",
  });
  const handleConsultAddToTable = () => {
    let founded = consultTmpArr.find((x) => x.service?.value === consultData?.service?.value);
    if (!isEmptyOrUndefined(founded)) {
      return message("warning", "Advertencia", "El servicio ya se encuentra en la lista");
    }
    let validations = validateEmptyString([
      { field: dataOut.consultReason?.value, message: "Debe diligenciar el campo motivo que origina el medicamento" },
      { field: consultData.orderType?.value, message: "Debe diligenciar el campo tipo de orden" },
      { field: consultData.service.value, message: "Debe diligenciar el campo cantidad" },
      // { field: consultData?.anesthesia?.value, message: "Debe diligenciar el campo tipo de anestesia" },
      { field: consultData.eye?.value, message: "Debe diligenciar el campo Ojo" },
      { field: consultData.timeVal, message: "Debe diligenciar ambos campos de programación" },
      { field: consultData.time?.value, message: "Debe diligenciar ambos campos de programación" },
    ]);
    if (validations.valid) {
      if (consultData?.service?.extra4 === 1) {
        swalConfirm({
          title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
          text: `Este servicio no se encuentra dentro del plan de beneficios del paciente`,
          confirmButtonText: "Si, continuar",
          doAfterConfirm: () => setJustification({ ...justification, showModal: true }),
        });
      } else {
        let newData = { ...consultData, id: Date.now() };
        consultTmpArr.push(newData);
        consultFormatData(consultTmpArr);
        setConsultData({
          reason: "",
          orderType: "",
          service: "",
          anesthesia: "",
          eye: "",
          time: "",
          timeVal: "",
        });
      }
    }
  };
  const handleProcedureAddToTable = () => {
    let founded = procedureTmpArr.find((x) => x.service?.value === procedureData?.service?.value);
    if (!isEmptyOrUndefined(founded)) {
      return message("warning", "Advertencia", "El servicio ya se encuentra en la lista");
    }
    let validations = validateEmptyString([
      { field: dataOut.procedureReason?.value, message: "Debe diligenciar el campo motivo que origina el medicamento" },
      { field: procedureData.orderType?.value, message: "Debe diligenciar el campo tipo de orden" },
      { field: procedureData.service.value, message: "Debe diligenciar el campo cantidad" },
      { field: procedureData?.anesthesia?.value, message: "Debe diligenciar el campo tipo de anestesia" },
      { field: procedureData.eye?.value, message: "Debe diligenciar el campo Ojo" },
      { field: procedureData.durationVal, message: "Debe diligenciar ambos campos de duración" },
      { field: procedureData.duration?.value, message: "Debe diligenciar ambos campos de duración" },
      { field: procedureData.timeVal, message: "Debe diligenciar ambos campos de programación" },
      { field: procedureData.time?.value, message: "Debe diligenciar ambos campos de programación" },
    ]);
    if (validations.valid) {
      let newData = { ...procedureData, id: Date.now() };
      if (procedureData?.service?.extra4 === 1) {
        swalConfirm({
          title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
          text: `Este servicio no se encuentra dentro del plan de beneficios del paciente`,
          confirmButtonText: "Si, continuar",
          doAfterConfirm: () => setJustification({ ...justification, showModal: true }),
        });
      } else {
        procedureTmpArr.push(newData);
        procedureFormatData(procedureTmpArr);
        setProcedureData({
          reason: "",
          orderType: "",
          service: "",
          anesthesia: "",
          eye: "",
          time: "",
          timeVal: "",
          duration: "",
          durationVal: "",
        });
      }
    }
  };
  const handleSubmitConsult = () => {
    let newArr = [];
    let numItemsWithJustification = 0;
    if (consultTmpArr.length > 0) {
      consultTmpArr.forEach((item) => {
        if (!!item.justification) {
          if (item.justification !== "") {
            numItemsWithJustification = numItemsWithJustification + 1;
          }
        }
        // //console.log("item to submit=>: ", item);
        newArr.push({
          ordTypeId: item?.orderType?.extra, //TODO: Revisar que pasa con el tipo de orden , que no se envia
          ordServiceId: item?.service?.id,
          // anesthesiaTypeId: item?.anesthesia?.value,
          eye: item?.eye?.value,
          programingQty: item?.timeVal,
          programingTimeId: item?.time?.value,
          justification: item?.justification,
          durationQty: item?.durationVal,
          durationId: item?.duration?.value
        });
      });
    } else {
      return message("info", "Sin información", "No hay datos para guardar");
    }

    ////console.log("numItemsWithJustification", numItemsWithJustification)

    createServiceOrder({
      url: "/medical/serviceOrder/",
      token: token,
      method: "POST",
      body: {
        clinicalHistoryId: event?.event?.clhId,
        observations: dataOut?.consultGeneralObservations,
        reasonId: dataOut?.consultReason?.value,
        services: newArr,
        serviceType: "consulta",
      },
      //noAlert: false,
      succesAction: (results) => {

        if (results?.status === 200) {
          setDataOut({
            consultReason: "",
            procedureReason: "",
            consultGeneralObservations: "",
            procedureGeneralObservations: ""
          })
        }
        MySwal.fire({
          icon: "success",
          title: `<span style=${tableStyles.ordClearBlueText}>Orden de servicio creada exitosamente<span>`,
          text: numItemsWithJustification < consultTmpArr?.length ? "" : `Se ha(n) enviado (${numItemsWithJustification}) solicitud(es) de servicio(s) a dirección médica `,
          showConfirmButton: true,
          confirmButtonText: numItemsWithJustification < consultTmpArr?.length ? "Ver orden" : "Aceptar"
        })
          .then((result) => {
            if (result.isConfirmed) {



              if (numItemsWithJustification < consultTmpArr?.length) {
                setProcedureTmpArr([]);
                setConsultTmpArr([]);
                setFullDialogScreen({
                  show: true,
                  id: results.results,
                });
              }
            }
            setProcedureTmpArr([]);
            setConsultTmpArr([]);
          })
      },
    });
  };
  const handleSubmitProcedure = () => {
    let newArr = [];
    let numItemsWithJustification = 0;
    if (procedureTmpArr.length > 0) {
      // //console.log("procedureTmpArr222: ", procedureTmpArr);
      procedureTmpArr.forEach((item) => {
        if (!!item.justification) {
          if (item.justification !== "") {
            numItemsWithJustification = numItemsWithJustification + 1;
          }
        }
        newArr.push({
          ordTypeId: item?.orderType?.extra,
          ordServiceId: item?.service?.id,
          anesthesiaTypeId: item?.anesthesia?.value,
          eye: item?.eye?.value,
          programingQty: item?.timeVal,
          programingTimeId: item?.time?.value,
          presurgicalExams: item?.prequisurgicalExams,
          justification: item?.justification
          , durationQty: item?.durationVal
          , durationId: item?.duration?.value
        });
      }
      );
      // //console.log("newArrDENTRO DE SUBMIT PROCEDURE: ", newArr)

    } else {
      return message("info", "Sin información", "No hay datos para guardar");
    }

    createServiceOrder({
      url: "/medical/serviceOrder/",
      token: token,
      method: "POST",
      body: {
        clinicalHistoryId: event?.event?.clhId,
        observations: dataOut?.procedureGeneralObservations,
        reasonId: dataOut?.procedureReason?.value,
        services: newArr,
        serviceType: "cirugia",
      },
      //noAlert: true,
      succesAction: (results) => {
        if (results?.status === 200) {
          setDataOut({
            consultReason: "",
            procedureReason: "",
            consultGeneralObservations: "",
            procedureGeneralObservations: ""
          })
        }
        MySwal.fire({
          icon: "success",
          title: `<span style=${tableStyles.ordClearBlueText}>Orden de servicio creada exitosamente<span>`,
          text: numItemsWithJustification < procedureTmpArr?.length ? "" : `Se ha(n) enviado (${numItemsWithJustification}) solicitud(es) de servicio(s) a dirección médica `,
          showConfirmButton: true,
          confirmButtonText: numItemsWithJustification < procedureTmpArr?.length ? "Ver orden" : "Aceptar"
        })
          .then((result) => {
            if (result.isConfirmed) {

              if (numItemsWithJustification < procedureTmpArr?.length) {
                setProcedureTmpArr([]);
                setConsultTmpArr([]);
                setFullDialogScreen({
                  show: true,
                  id: results.results,
                });
              }
            }
            setProcedureTmpArr([]);
            setConsultTmpArr([]);
          })
      },
    });
  };

  //TOMAR UNA COPIA PREQUIIDS

  const handleChangePrequirugical = (elem, e) => {
    let bag = [...preQuiIds];
    let founded = bag.find((x) => x === elem.id); // REVISAMOS SI EL ID AL QUE SE ESTÁ HACIENDO CLICK EXISTE EN PREQUIIDS
    // let isChecked = e.target.checked
    if (!isEmptyOrUndefined(founded)) {
      // SI LA VARIABLE FOUNDED ES !== UNDEFINED (1)  next=>
      bag = bag.filter((x) => x !== elem.id); // ELIMINA EL ID ENCONTRADO POR EL FIND
      //  let array =[1,2,3,4,5]
      // array = array.filter((numero)=> numero !== 3) // OUTPUT : [1,2,4,5]
    } else {
      bag.push(elem.id); // AGREGAR UN ID QUE NO EXISTE AL ARRAY
    }
    setPreQuiIds(bag);
    setProcedureData({ ...procedureData, prequisurgicalExams: bag })
  };

  //   //console.log("preQuiIds=>", preQuiIds);
  //   //console.log("procedureData=>", procedureData);
  const addConsultJustify = () => {
    if (isEmptyOrUndefined(justification.consultJustification)) {
      return message("info", "Campo obligatorio", "Se requiere una justificación");
    }
    let newData = { ...consultData, id: Date.now(), justification: justification.consultJustification, isRequ: true };
    consultTmpArr.push(newData);
    setConsultData({
      reason: "",
      orderType: "",
      service: "",
      anesthesia: "",
      eye: "",
      time: "",
      timeVal: "",
    });
    setJustification({ showModal: false, consultJustification: "" });
  };
  const addProcedureJustify = () => {
    if (isEmptyOrUndefined(justification.procedureJustification)) {
      return message("info", "Campo obligatorio", "Se requiere una justificación");
    }
    let newData = { ...procedureData, id: Date.now(), justification: justification.procedureJustification, isRequ: true };
    procedureTmpArr.push(newData);
    setProcedureData({
      reason: "",
      orderType: "",
      service: "",
      anesthesia: "",
      eye: "",
      time: "",
      timeVal: "",
      duration: "",
      durationVal: "",
    });
    setReqItems(reqItems + 1)
    setJustification({ showModal: false, procedureJustification: "" });
  };

  const handleChangeAnesthesiaProcedure = (e) => {
    let arraysTemps = [];
    prequisurgicalExams.results?.length > 0 &&
      // eslint-disable-next-line array-callback-return
      prequisurgicalExams.results.map((item, i) => {
        let isChecked = e?.extra?.find((x) => x?.id === item?.id);
        if (!isEmptyOrUndefined(isChecked)) {
          arraysTemps.push(item.id);
        }
      })
    setPreQuiIds(arraysTemps);
    setProcedureData({ ...procedureData, anesthesia: e, prequisurgicalExams: arraysTemps });

  };

  async function getServiceCloning(id) {
    await getServicesToClone({
      url: "/medical/offered-services",
      objFilters: {
        slim: 1,
        idAccount: idEnterprise,
        ordServ: changeView.diagnostics ? 1 : 2,
        orderType: id,
        contract: event?.event?.contractCorpClient,
      },
      token: token,
    });
  }
  useEffect(() => {
    if (isCloningServices) {
      let formattedreasonsToClone = [];
      if (cloneData.orderServiceKind === "consult") {
        getReasonsToClone({
          url: "/medical/orderingReasons/",
          objFilters: { id_account: idEnterprise },
          requestName: "orderingReasons",
          token: token,
          doAfterSuccess: (response) => {
            formattedreasonsToClone = formatToRcSelect(response?.results, "id", "name", "", "", "");
            setDataOut({
              ...dataOut,
              consultReason: formattedreasonsToClone.find((x) => x.value === cloneData.reasonId),
              consultGeneralObservations: cloneData?.observations,
            });
          },
        });
        let newCloneArr = [];
        cloneData?.services?.forEach((item) => {
          //TODO: CALL AND FORMAT IT AGAIN INSIDE THE USEEFFECT
          getServiceCloning(item.ordTypeId);
          if (formattedOrderType.length >= 0 && formattedOfferedServices().length >= 0) {
            // const results = formattedOfferedServices().map(serv =>)
            // //console.log("formattedOrderType: ", formattedOrderType);
            newCloneArr.push({
              id: item.id,
              orderType: formattedOrderType?.find((x) => x.extra === item?.ordTypeId),
              service: formattedOfferedServices()?.find((x) => x.extra3 === item?.serviceTypeId),
              anesthesia: formattedAnesthesia?.find((x) => x.value === item?.anesthesiaId),
              eye: formattedBodyOrgans?.find((x) => x.label === item?.eye),
              timeVal: item?.programingQtySTR,
              time: formattedTimes?.find((x) => x.value === item?.programmingId),
              isRequ: item?.reqJust?.isReq === 1 ? true : false,
              justification: item?.reqJust?.justification || "",
              durationVal: item?.durationQty,
              duration: formattedTimes?.find((x) => x.value === item?.durationId),
            });
          }
        });
        // //console.log("newCloneArr: ", newCloneArr);
        setConsultTmpArr(newCloneArr);
        setChangeView({
          generalView: false,
          diagnostics: true,
          procedure: false,
        });
      }
      else if (cloneData.orderServiceKind === "procedure") {
        getReasonsToClone({
          url: "/medical/orderingReasons/",
          objFilters: { id_account: idEnterprise },
          requestName: "orderingReasons",
          token: token,
          doAfterSuccess: (response) => {
            formattedreasonsToClone = formatToRcSelect(response?.results, "id", "name", "", "", "");
            setDataOut({
              ...dataOut,
              procedureReason: formattedreasonsToClone.find((x) => x.value === cloneData.reasonId),
              procedureGeneralObservations: cloneData?.observations,
            });
          },
        });
        let newCloneArr = [];
        cloneData?.services?.forEach((item) => {
          //TODO: CALL AND FORMAT IT AGAIN INSIDE THE USEEFFECT
          getServiceCloning(item.ordTypeId);
          if (formattedOrderType.length >= 0 && formattedOfferedServicesProcedure().length >= 0) {
            // const results = formattedOfferedServices().map(serv =>)
            // //console.log("formattedOrderType: ", formattedOrderType);
            newCloneArr.push({
              id: item.id,
              orderType: formattedOrderType?.find((x) => x.extra === item?.ordTypeId),
              service: formattedOfferedServicesProcedure()?.find((x) => x.extra3 === item?.serviceTypeId),
              anesthesia: formattedAnesthesia?.find((x) => x.value === item?.anesthesiaId),
              eye: formattedBodyOrgans?.find((x) => x.label === item?.eye),
              timeVal: item?.programingQtySTR,
              time: formattedTimes?.find((x) => x.value === item?.programmingId),
              prequisurgicalExams: item?.presur, //item?.prequisurgicalExams,
              justification: item?.reqJust?.justification || "",
              isRequ: item?.reqJust?.isReq === 1 ? true : false,
              durationVal: item?.durationQty,
              duration: formattedTimes?.find((x) => x.value === item?.durationId),

            });
            // //console.log("newCloneArr: ", newCloneArr);
          }
        });
        setProcedureTmpArr(newCloneArr);
        setChangeView({
          generalView: false,
          diagnostics: false,
          procedure: true,
        });
      }
      else {
        setChangeView({
          generalView: true,
          diagnostics: false,
          procedure: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialList.length, formattedOrderType.length, formattedAnesthesia.length, formattedTimes.length, formattedOfferedServices().length]);
  useEffect(() => {
    let timeFind = formattedTimes.find(
      (item) => item.value === procedureData?.time?.value
    )
    let durationFind = formattedTimes.find(
      (item) => item.value === procedureData?.duration?.value
    )
    setProcedureData({ ...procedureData, time: timeFind, duration: durationFind })
  }, [procedureData.service])

  // //console.log("initialListOut", initialList)
  return (
    <>
      {initialListLoader && loader}
      {servicesToCloneLoader && loader}
      {createServiceOrderLoader && loader}
      {offeredServicesLoader && loader}
      {prequisurgicalExamsLoader && loader}
      {reasonsToCloneLoader && loader}
      {timesLoader && loader}
      {bodyOrgansLoader && loader}
      {anesthesiaTypeLoader && loader}
      {orderingReasonsLoader && loader}
      {orderTypeLoader && loader}
      {/* /* --------------------------- JUSTIFICATION MODAL -------------------------- */}
      <OrdModal
        title={"Justificación"}
        show={justification.showModal}
        btnYesName="Aceptar"
        btnNoName="Cancelar"
        size="700"
        btnYesEvent={changeView.diagnostics ? () => addConsultJustify() : () => addProcedureJustify()}
        onHide={() => {
          setJustification({ ...justification, showModal: false });
        }}
        btnNoEvent={() => {
          setJustification({ ...justification, showModal: false });
        }}
      >
        <Col xs={12}>
          <Form.Group className="mb-3" controlId="fName">
            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
              <b className="text-start">&nbsp;Explique la razón por la que solicita este servicio</b>
            </Form.Label>
            <textarea
              placeholder={"Escribir..."}
              value={justification.data}
              onChange={(e) => {
                changeView.diagnostics
                  ? setJustification({ ...justification, consultJustification: e.target.value })
                  : setJustification({ ...justification, procedureJustification: e.target.value });
              }}
              rows="25"
              cols="55"
              style={{ height: "7rem" }}
              className={`text-secondary ord-roundInput w-100`}
            ></textarea>
          </Form.Group>
        </Col>
      </OrdModal>
      {/* /* --------------------------------- DIALOG --------------------------------- */}
      <FullScreenDialog onHide={() => setFullDialogScreen(false)} isOpen={fullDialogScreen.show}>
        {reqItems > 0 &&
          <OrdCustomToast
            position='bottomLeft'
            width='42%'
            text={`Se ha(n) enviado (${reqItems}) solicitud(es) de servicio(s) a dirección médica `}
            onClose={() => setReqItems(0)}
          />}

        <RenderMedicalFormulasPdf
          withPagination
          pdfParams={{
            url: "/medical/pdfserviceOrder/",
            id: fullDialogScreen.id,
          }}
          title={"Ordenamiento de servicio"}
          finalize={{
            title: "Finalizar",
            finalizeAction: () => {
              setDataOut({
                consultReason: "",
                procedureReason: "",
                consultGeneralObservations: "",
                procedureGeneralObservations: "",
              });
              setProcedureTmpArr([]);
              setConsultTmpArr([]);
              message("success", "Orden(es) finalizada");
              setFullDialogScreen(false);
            },
          }}
          backAction={() => {
            setDataOut({
              consultReason: "",
              procedureReason: "",
              consultGeneralObservations: "",
              procedureGeneralObservations: "",
            });
            setProcedureTmpArr([]);
            setConsultTmpArr([]);
            setFullDialogScreen(false);
          }}
        />
      </FullScreenDialog>

      <div className={` ${tableStyles.ordContainer}`}>
        {changeView.generalView && (
          <>
            <Row>
              <Col xs={12} className={`d-flex justify-content-center mt-5 ${tableStyles.darkGrayText}`}>
                <h2>Orden a realizar:</h2>
              </Col>
            </Row>

            <Row style={{ marginBottom: "38%" }} className="ml-5">
              <Col xs={6} className={`cursorPointer`}>
                <Col className={`d-flex justify-content-center mb-2 pl-5`} xs={12}>
                  <img width="25px" alt="estetoscópico" src={estetoscopic}></img>
                </Col>
                <Col
                  onClick={() =>
                    setChangeView({
                      generalView: false,
                      diagnostics: true,
                      procedure: false,
                    })
                  }
                  className={`d-flex justify-content-center ${tableStyles.darkGrayText} ${tableStyles.ordBordersemiCard}`}
                  xs={11}
                >
                  <span className={`pl-5 py-2 px-2`}>Consulta y/o ayudas diagnósticas</span>
                </Col>
              </Col>
              {/* /* ----------------------------------- #2 ----------------------------------- */}
              <Col xs={6} className={`cursorPointer`}>
                <Col className={`d-flex justify-content-center mb-2 pl-5`} xs={12}>
                  <img alt="focaEye" src={focaEye}></img>
                </Col>
                <Col
                  onClick={() =>
                    setChangeView({
                      generalView: false,
                      diagnostics: false,
                      procedure: true,
                    })
                  }
                  className={`d-flex justify-content-center ${tableStyles.darkGrayText} ${tableStyles.ordBordersemiCard}`}
                  xs={11}
                >
                  <span className={`pr-5 py-2 px-2`}>Procedimientos y/o cirugía</span>
                </Col>
              </Col>
            </Row>
            <Row className={`${tableStyles.ordBorderTop}`}></Row>
          </>
        )}
        {/* /* --------------------------- Help or diagnostics -------------------------- */}
        {changeView.diagnostics && (
          <OrdGenericTemplate
            showBackArrow={true}
            backArrowWidth={"10px"}
            backArrowAction={() => {
              setChangeView({
                generalView: true,
                diagnostics: false,
                procedure: false,
              });
              setDataOut({
                consultReason: "",
                procedureReason: "",
                consultGeneralObservations: "",
                procedureGeneralObservations: "",
              });
              setConsultTmpArr([]);
            }}
            titleSize={12}
            backIcon={ordBackArrow}
            title={
              <p className={`pt-3 ${tableStyles.f18} ${tableStyles.ordClearBlueText}`}>
                <b>
                  <img src={estetoscopicBlue} width={"25px"} className="pb-2" alt="estetoscopicBlue"></img> &nbsp; Consulta y/o ayudas
                  diagnósticas
                </b>
              </p>
            }
          >
            {/* /* --------------------------- First select--------------------------- */}
            <Form.Label className={`ml-5 ${tableStyles.ordDarkBlueText}`}>
              <b>Motivo que origina el ordenamiento</b>
            </Form.Label>
            <Form.Group className={`${tableStyles.ordOrangeCard} p-3 mx-5 pt-2 mb-4 text-start`} controlId="cc">
              <Row className={`mb-2`}>
                <Autocomplete
                  noOptionsText={"No se encuentra"}
                  sx={{
                    input: {
                      color: "#6e6f7c",
                    },
                  }}
                  renderOption={(props, option) => {
                    const { label } = option;
                    return (
                      <span {...props} style={{ color: "#6e6f7c", borderBottom: "1px solid #cecece" }}>
                        {label}
                      </span>
                    );
                  }}
                  value={dataOut?.consultReason?.label}
                  key={dataOut?.consultReason?.label}
                  onChange={(event, newValue) => {
                    setDataOut({ ...dataOut, consultReason: newValue });
                  }}
                  id="controllabless"
                  options={formattedReasons}
                  placeholder="Motivo que origina el ordenamiento"
                  renderInput={(params) => <TextField placeholder="Motivo que origina el ordenamiento" {...params} label="" />}
                />
              </Row>
            </Form.Group>
            {!isEmptyOrUndefined(dataOut?.consultReason?.value) && (
              <>
                {/* /* ------------------------------- #2 BlueForm ------------------------------ */}
                <Form.Group className={`${tableStyles.ordSearchSections} mb-4 mx-5 pt-2 text-start`} controlId="cc">
                  <Row className={`px-2`}>
                    <Col xs={3}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                          <b>&nbsp;&nbsp;Tipo de orden</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          value={consultData.orderType}
                          // value={formattedOrderType.find(x => x?.extra === consultData?.orderType?.extra)}
                          options={formattedOrderType}
                          onChange={(e) => setConsultData({
                            ...consultData, orderType: e,
                            service: "",
                            anesthesia: "",
                            eye: "",
                            time: "",
                            timeVal: "",
                          })}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col xs={9} className="">
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                          <b className="text-start">&nbsp;&nbsp;Servicio</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          value={consultData.service}
                          options={formattedOfferedServices()}
                          onChange={(e) => setConsultData({ ...consultData, service: e })}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                        ></Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* #3 */}
                  <Row className={`px-2`}>
                    {/* <Col xs={3} className="">
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                          <b className="text-start">&nbsp;&nbsp;Tipo de anestesia</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          value={consultData.anesthesia}
                          options={formattedAnesthesia}
                          onChange={(e) => setConsultData({ ...consultData, anesthesia: e })}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                        ></Select>
                      </Form.Group>
                    </Col> */}

                    <Col xs={2} className="">
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                          <b className="text-start">&nbsp;&nbsp;Ojo</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          options={formattedBodyOrgans}
                          value={consultData.eye}
                          onChange={(e) => {
                            setConsultData({ ...consultData, eye: e });
                          }}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                        ></Select>
                      </Form.Group>
                    </Col>

                    <Col xs={3}>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                        <b className="text-start">&nbsp;Duración</b>
                      </Form.Label>
                      <Row className={`d-flex justify-content-between`}>
                        <Col xs={4} className="">
                          <Form.Control
                            className={`ord-roundInput`}
                            type="number"
                            pattern="[0-9]*"
                            style={{ width: "3rem" }}
                            value={consultData?.durationVal ? consultData?.durationVal : ""}
                            onChange={(e) => setConsultData({ ...consultData, durationVal: e.target.value })}
                            placeholder=""
                          />
                        </Col>

                        <Col xs={1} className="p-0"></Col>

                        <Col xs={7} className="ml-2 p-0">
                          <Select noOptionsMessage={() => 'No hay datos'}
                            value={formattedTimes.find(
                              (item) => item.value === procedureData?.duration?.value
                            )}
                            options={formattedTimes}
                            className="text-secondary"
                            placeholder={"Seleccione"}
                            styles={ordCustomSelect}
                            onChange={(e) => setConsultData({ ...consultData, duration: e })}
                          ></Select>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={3}>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                        <b className="text-start">&nbsp;Programación</b>
                      </Form.Label>
                      <Row className={`d-flex justify-content-between`}>
                        <Col xs={4} className="">
                          <Form.Control
                            className={`ord-roundInput`}
                            type="number"
                            style={{ width: "3rem" }}
                            pattern="[0-9]*"
                            value={consultData.timeVal}
                            onChange={(e) => setConsultData({ ...consultData, timeVal: e.target.value })}
                            placeholder=""
                          />
                        </Col>

                        <Col xs={1} className="p-0"></Col>

                        <Col xs={7} className="ml-2 p-0">
                          <Select noOptionsMessage={() => 'No hay datos'}
                            value={consultData.time}
                            options={formattedTimes}
                            className="text-secondary"
                            placeholder={"Seleccione"}
                            styles={ordCustomSelect}
                            onChange={(e) => setConsultData({ ...consultData, time: e })}
                          ></Select>
                        </Col>
                      </Row>
                    </Col>

                    <Col style={{ marginLeft: 'auto' }} xs={1} onClick={() => handleConsultAddToTable()} className="pt-4">
                      <img className="cursorPointer" width={"30px"} alt="estetoscopicBlue" src={ordOrangeDownloadArrow}></img>
                    </Col>

                  </Row>
                </Form.Group>
                {/* /* ---------------------------- CONSULT ORDTABLE ---------------------------- */}
                <div style={{ marginBottom: "5%" }} className={`mx-5 text-start`}>
                  <Col xs={12}>
                    <OrdTable headers={headers} hasChildren={true}>
                      {consultFormatData()}
                    </OrdTable>
                  </Col>
                </div>
              </>
            )}
            {/* /* ---------------------- CONSULT GENERAL OBSERVATIONS ---------------------- */}
            <div style={{ marginBottom: "5%" }} className={`mx-5 text-start`}>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Observación general</b>
                  </Form.Label>
                  <textarea
                    value={dataOut.consultGeneralObservations}
                    onChange={(e) => setDataOut({ ...dataOut, consultGeneralObservations: e.target.value })}
                    placeholder={"Escribir..."}
                    rows="25"
                    cols="55"
                    style={{ height: "4rem" }}
                    className={`text-secondary ord-roundInput w-100`}
                  ></textarea>
                </Form.Group>
              </Col>
            </div>

            <Row className={`${tableStyles.ordBorderTop} mt-2`}>
              <Col xs={12} className="d-flex justify-content-end pt-3 mb-3">
                <button className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                {
                  myPermission?.create && (
                    <button
                      // onClick={() => handleSubmitConsult()}
                      onClick={() =>
                        consultTmpArr.length > 0
                          ? swalConfirm({
                            title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                            text: `Se creará(n) orden(es) de servicio(s) para ${event?.event?.firstName} ${event?.event?.lastName}`,
                            confirmButtonText: "Si, continuar",
                            doAfterConfirm: () => handleSubmitConsult(),
                          })
                          : message("info", "Complete todos los campos para generar la orden")
                      }
                      className={`${tableStyles.ordBtnPrimary} btn`}
                    >
                      <img src={miniRoundWhiteCheck} className={`mr-2`} alt="check"></img>
                      Ordenar
                    </button>
                  )
                }

              </Col>
            </Row>
          </OrdGenericTemplate>
        )}

        {/* /* --------------------------- Procedure and surgery -------------------------- */}
        {changeView.procedure && (
          <OrdGenericTemplate
            backArrowWidth={"10px"}
            showBackArrow={true}
            backArrowAction={() => {
              setChangeView({
                generalView: true,
                diagnostics: false,
                procedure: false,
              });
              setDataOut({
                consultReason: "",
                procedureReason: "",
                consultGeneralObservations: "",
                procedureGeneralObservations: "",
              });
              setProcedureTmpArr([]);
            }}
            titleSize={12}
            backIcon={ordBackArrow}
            // title={<p className={`pt-3 ${tableStyles.f18} ${tableStyles.ordClearBlueText}`}>
            //     <b>
            //         <img
            //             width={'25px'}
            //             alt="eye"
            //             src={focaEyeBlue}
            //         >
            //         </img> &nbsp;
            //         Procedimientos y/o cirugía
            //     </b></p>}
            title={
              <p className={`pt-3 ${tableStyles.f18} ${tableStyles.ordClearBlueText}`}>
                <b>
                  <img width={"25px"} className="pb-2" alt="eye" src={focaEyeBlue}></img> &nbsp; Procedimientos y/o cirugía
                </b>
              </p>
            }
          >
            {/* /* --------------------------- First select--------------------------- */}
            <Form.Label className={`ml-5 ${tableStyles.ordDarkBlueText}`}>
              <b>Motivo que origina el ordenamiento</b>
            </Form.Label>
            {/* {!isEmptyOrUndefined(dataOut.procedureReason.value) && (<> */}
            <Form.Group className={`${tableStyles.ordOrangeCard} p-3 mx-5 pt-2 mb-4 text-start`} controlId="cc">
              <Row className={`mb-2`}>
                <Autocomplete
                  noOptionsText={"No se encuentra"}
                  sx={{
                    input: {
                      color: "#6e6f7c",
                    },
                  }}
                  renderOption={(props, option) => {
                    const { label } = option;
                    return (
                      <span {...props} style={{ color: "#6e6f7c", borderBottom: "1px solid #cecece" }}>
                        {label}
                      </span>
                    );
                  }}
                  value={dataOut?.procedureReason?.label}
                  key={dataOut?.procedureReason?.label}
                  onChange={(event, newValue) => {
                    setDataOut({ ...dataOut, procedureReason: newValue });
                  }}
                  id="controllablessprocedure"
                  options={formattedReasons}
                  placeholder="Motivo que origina el ordenamiento"
                  renderInput={(params) => <TextField placeholder="Motivo que origina el ordenamiento" {...params} label="" />}
                />
              </Row>
            </Form.Group>
            {!isEmptyOrUndefined(dataOut?.procedureReason?.value) && (
              <>
                {/* /* ------------------------------- #2 BlueForm ------------------------------ */}
                <Form.Group className={`${tableStyles.ordSearchSections} mb-4 mx-5 pt-2 text-start`} controlId="cc">
                  <Row className={`px-2`}>
                    <Col xs={3}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                          <b>&nbsp;&nbsp;Tipo de orden</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          value={procedureData.orderType}
                          // value={formattedOrderType?.find(x => x?.extra === procedureData?.orderType?.extra)}
                          options={formattedOrderType}
                          onChange={(e) =>
                            setProcedureData({
                              ...procedureData,
                              orderType: e,
                              service: "",
                              anesthesia: "",
                              eye: "",
                              time: "",
                              timeVal: "",
                              duration: "",
                              durationVal: "",
                            })
                          }
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col xs={9} className="">
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                          <b className="text-start">&nbsp;&nbsp;Servicio</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          value={procedureData.service}
                          isSearchable
                          color
                          // options={formattedOfferedServices()}
                          options={formattedOfferedServicesProcedure()}
                          key={procedureData.service?.id}
                          onChange={(e) => setProcedureData({
                            ...procedureData, service: e, timeVal: e.extra8, time: { value: e.extra7 },
                            duration: { value: e.extra6 }, durationVal: e.extra9
                          })}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}

                        ></Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* #3 */}
                  <Row className={`px-2`}>
                    <Col xs={3} className="">
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                          <b className="text-start">&nbsp;&nbsp;Anestesia</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          value={procedureData.anesthesia}
                          options={formattedAnesthesia}
                          onChange={(e) => handleChangeAnesthesiaProcedure(e)}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col xs={2} className="">
                      <Form.Group className="mb-3" controlId="fName">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                          <b className="text-start">&nbsp;&nbsp;Ojo</b>
                        </Form.Label>
                        <Select noOptionsMessage={() => 'No hay datos'}
                          options={formattedBodyOrgans}
                          value={procedureData.eye}
                          onChange={(e) => {
                            setProcedureData({ ...procedureData, eye: e });
                          }}
                          className="text-secondary"
                          placeholder={"Seleccione"}
                          styles={ordCustomSelect}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                        <b className="text-start">&nbsp;Duración</b>
                      </Form.Label>
                      <Row className={`d-flex justify-content-between`}>
                        <Col xs={4} className="">
                          <Form.Control
                            className={`ord-roundInput`}
                            type="number"
                            pattern="[0-9]*"
                            style={{ width: "3rem" }}
                            value={procedureData?.durationVal ? procedureData?.durationVal : ""}
                            onChange={(e) => setProcedureData({ ...procedureData, durationVal: e.target.value })}
                            placeholder=""
                          />
                        </Col>

                        <Col xs={1} className="p-0"></Col>

                        <Col xs={7} className="ml-2 p-0">
                          <Select noOptionsMessage={() => 'No hay datos'}
                            value={formattedTimes.find(
                              (item) => item.value === procedureData?.duration?.value
                            )}
                            options={formattedTimes}
                            className="text-secondary"
                            placeholder={"Seleccione"}
                            styles={ordCustomSelect}
                            onChange={(e) => setProcedureData({ ...procedureData, duration: e })}
                          ></Select>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={3}>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                        <b className="text-start">&nbsp;Programación</b>
                      </Form.Label>
                      <Row className={`d-flex justify-content-between`}>
                        <Col xs={4} className="">
                          <Form.Control
                            className={`ord-roundInput`}
                            type="number"
                            pattern="[0-9]*"
                            style={{ width: "3rem" }}
                            value={procedureData?.timeVal ? procedureData?.timeVal : ""}
                            onChange={(e) => setProcedureData({ ...procedureData, timeVal: e.target.value })}
                            placeholder=""
                          />
                        </Col>

                        <Col xs={1} className="p-0"></Col>

                        <Col xs={7} className="ml-2 p-0">
                          <Select noOptionsMessage={() => 'No hay datos'}
                            value={formattedTimes.find(
                              (item) => item.value === procedureData?.time?.value
                            )}
                            options={formattedTimes}
                            className="text-secondary"
                            placeholder={"Seleccione"}
                            styles={ordCustomSelect}
                            onChange={(e) => setProcedureData({ ...procedureData, time: e })}
                          ></Select>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={1} onClick={() => handleProcedureAddToTable()} className="pt-4">
                      <img className="cursorPointer" width={"30px"} alt="estetoscopicBlue" src={ordOrangeDownloadArrow}></img>
                    </Col>
                  </Row>
                </Form.Group>
                {/* /* --------------------------- PROCEDURE ORDTABLE --------------------------- */}
                <div style={{ marginBottom: "5%" }} className={`mx-5 text-start`}>
                  <Col xs={12}>
                    <OrdTable headers={headersProcedure} hasChildren={true}>
                      {procedureFormatData()}
                    </OrdTable>
                  </Col>
                </div>
              </>
            )}
            {/* /* --------------------- PROCEDURE GENERAL OBSERVATIONS --------------------- */}
            <div style={{ marginBottom: "5%" }} className={`mx-5 text-start`}>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Observación general</b>
                  </Form.Label>
                  <textarea
                    value={dataOut.procedureGeneralObservations}
                    onChange={(e) => setDataOut({ ...dataOut, procedureGeneralObservations: e.target.value })}
                    placeholder={"Escribir..."}
                    rows="25"
                    cols="55"
                    style={{ height: "4rem" }}
                    className={`text-secondary ord-roundInput w-100`}
                  ></textarea>
                </Form.Group>
              </Col>
            </div>
            {/* /* --------------------------------- Checks --------------------------------- */}
            {!isEmptyOrUndefined(dataOut?.procedureReason?.value) && (
              <>
                <Form.Group className={`${tableStyles.ordSearchSections} mb-4 mx-5 pt-2 text-start`} controlId="cc">
                  <Row className={`p-3 `}>
                    <Col xs={12}>
                      <p className={`pb-0 mb-0 ${tableStyles.ordClearBlueText} ${tableStyles.f18}`}>
                        <b>Exámenes prequirurgicos</b>
                      </p>
                    </Col>
                    {prequisurgicalExams.results?.length > 0 &&

                      <Box display='grid' gridTemplateColumns={'1fr 1fr 1fr'} gap='15px' className='my-3'>

                        {
                          prequisurgicalExams.results.map((item, i) => {
                            let isChecked = procedureData?.anesthesia?.extra?.find((x) => x?.id === item?.id);
                            if (!isEmptyOrUndefined(isChecked)) {
                              tempArray.push(item.id);
                              // setPreQuiIds(...preQuiIds,tempArray);
                            }
                            // //console.log("temp", tempArray);

                            return (
                              <Col className={`m-0 p-0 d-flex align-items-center gap-3 w-100 ${tableStyles.f14}`} xs={4}>
                                <Checkbox
                                  key={item?.id}
                                  id={`item${item.id}`}
                                  // disabled
                                  // isCheck={!isEmptyOrUndefined(isChecked) ? true : false}
                                  onChange={(e) => handleChangePrequirugical(item, e)}
                                  checked={preQuiIds.find((x) => x === item.id) === undefined ? false : true}
                                  style={{ borderRadius: "10px" }}
                                  className={"form-check-input"}
                                />
                                <label htmlFor={`item${item.id}`}>
                                  <b className={tableStyles.darkGrayText}>{item?.name}</b>
                                </label>
                              </Col>
                            );
                          })
                        }

                      </Box>


                    }
                  </Row>
                </Form.Group>
              </>
            )}
            <Row className={`${tableStyles.ordBorderTop} mt-2`}>
              <Col xs={12} className="d-flex justify-content-end pt-3 mb-3">
                <button className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                {
                  myPermission?.create && (
                    <button
                      // onClick={() => handleSubmitProcedure()}
                      onClick={() =>
                        procedureTmpArr.length > 0
                          ? swalConfirm({
                            title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                            text: `Se creará(n) orden(es) de servicios para ${event?.event?.firstName} ${event?.event?.lastName}`,
                            confirmButtonText: "Si, continuar",
                            doAfterConfirm: () => handleSubmitProcedure(),
                          })
                          : message("info", "Complete todos los campos para generar la orden")
                      }
                      className={`${tableStyles.ordBtnPrimary} btn`}
                    >
                      <img src={miniRoundWhiteCheck} className={`mr-2`} alt="check"></img>
                      Ordenar
                    </button>
                  )
                }
              </Col>
            </Row>
          </OrdGenericTemplate>
        )}
      </div>
    </>
  );
};
