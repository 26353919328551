import GenericTableNew from "../Layouts/GenericTableNew";
import Attach from "../../assets/img/icons/Adjuntar.svg";
import Watch from "../../assets/img/icons/relojAzulClaro.svg";
import Watch2 from "../../assets/img/icons/Reloj.svg";
import Excel from "../../assets/img/icons/excel.svg";
import Word from "../../assets/img/icons/word.svg";
import PDF from "../../assets/img/icons/pdf.svg";
import extraBtnRedX from "../../assets/img/icons/extraBtnRedX.svg";

import React, { useEffect, useState } from "react";

import {
    indauction_get_one,
    update_indauc,
    reject_indauc,
    indauction_articles_filter,
} from "../../actions/IndividualAuctionActions";

import {
    warehouse_supplier_active,
} from "../../actions/storageActions";
  

import {  useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import genericTableStyles from "../Layouts/genericTable.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import IndividualStyles from "./IndividualAuction_CCS.module.scss";
import pendingStyles from "./AuctionPending.module.scss";
import ModalNew from "../Layouts/ModalNew";
import ModalDocuments from "./IndividualDocuments";
import ModalProgramming from "./IndividualProgramming";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function docType(state, data){
    let iconDoc
    if(!!state?.document){   
        switch(state.document[data]?.type){
            case "application/pdf":
                iconDoc=PDF
                break;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/msword":
                iconDoc=Word
                break;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "csv" || "application/vnd.ms-excel":
                iconDoc=Excel
                break;
            default:
                iconDoc=PDF
                break;
        }
    }
    return iconDoc;
}

// function getReqCC (reqList) {
//     let selected_cc_id = null;
//     if (Array.isArray(reqList) && (reqList.length>=1) ){
//         selected_cc_id = reqList[0].id_consumption_center;
//     }
//     return selected_cc_id;
// }

function PendingAuctionModalArticles (props){
    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const sel_indauc= counter.individualAuctionReducer.selected_indauction;            

    const [filters, setFilters] = useState({
        page: 1,
        perpage:10,
    });
    
    const [selectedAuction, setselectedAuction] = useState({
        ...props.selectedAuction,
        id_user:  counter.loginReducer.user_data.id,
        account: counter.loginReducer.currentAccount.id,
        account_session: counter.loginReducer.currentAccount.name,
        id_consumption_center: props.selectedAuction?.cc_ref?.id,
        document:[],
    });
    
    const [modalDocumentsControl, setmodalDocumentsControl]= useState({
        show:false,
    });
    
    const [modalProgrammingControl, setmodalProgrammingControl]= useState({
        show:false,
    });

    const [rejection, setRejection]= useState({
        show:false,
    });

    const [trigger, setTrigger] = useState(1);

    useEffect(() => {
        dispatch( indauction_articles_filter({eaccount:counter.loginReducer.currentAccount.id}));
        dispatch(warehouse_supplier_active(counter.loginReducer.currentAccount.id));
    },[]);

    useEffect(() => {
        setRejection({ show:false });

        if(!!props.selectedAuction?.id){
            setselectedAuction({
                ...props.selectedAuction,
                id_user:  counter.loginReducer.user_data.id,
                account: counter.loginReducer.currentAccount.id,
                // id_consumption_center: getReqCC(props.selectedAuction?.req_list),
                document:[],
            });
            dispatch(  indauction_get_one(props.selectedAuction.id) );
        }
        

    },[props.show]);

    const optionStorage = /*useMemo(*/() =>{  
        let mylist=[]
        if (Array.isArray(counter.storageReducer.list_new)) {
            counter.storageReducer.list_new.map((elem) => {                
                mylist.push(
                    <option key={elem.id+"wh"} value={elem.id}>
                        {elem.address}
                    </option>
                )                
            });
        }
        return mylist;
    }//, counter.storageReducer.list_new)

    const renderHeaders = [
        <th key={`reqTH1`}>Código</th>,
        <th key={`reqTH2`}>Artículo</th>,
        <th key={`reqTH3`}>Cantidad</th>,
        <th key={`reqTH4`}></th>,
    ]

    const renderList = () => {
        let table =[];

        if (Array.isArray(sel_indauc?.article_list)) {
            table = sel_indauc?.article_list.map( (x, index) => {

                let watchicon = Watch;
                let watchclass = IndividualStyles.btnNew;
                if(selectedAuction.programacion?.find(y => y.article_id === x.article?.id)){
                    watchicon = Watch2;
                    watchclass = "";
                }
                
                return <tr key={"requisition"+index} className={`hover-table-row ${ x.newArticle? genericTableStyles.orange : "" }`}>
                    <td className={`col-md-2`}>
                        {x.article?.id}
                    </td>
                    <td className={`col-md-6 text-start`}>
                        {x.article?.description}
                    </td>
                    <td className={`col-md-2`}>
                        {x.qty_article} 
                    </td>
                    <td className={`col-md-1`}>
               
                        <img
                            src={watchicon}
                            alt="Reloj"
                            className={`icons-popUp ${watchclass} ${ x.newArticle? IndividualStyles.orangeIcon : "" }`}
                            onClick={() => !props.readonly? 
                                    setmodalProgrammingControl({
                                        ...modalProgrammingControl,
                                        id:x.article?.id,                                        
                                        articulo:{
                                            ...x.article,
                                            ammount: x.qty_article,
                                        },
                                        show: true
                                    })
                                :
                                    null
                            }
                        />
                    </td>
                </tr>
            });

          }
        return table;
    }

    const paginateList = () => {
        const fullList = renderList();
        return fullList.slice(filters.perpage*(filters.page-1),  filters.perpage*(filters.page));
    }



    const ButtonDocuments = () => {
        setmodalDocumentsControl({
            ...modalDocumentsControl,
            show: true
        })
    }

    const Disappears=(pos)=>{
        if(Array.isArray(selectedAuction.document)){
            let temp = selectedAuction.document;
            temp = temp.filter( (index) => index !== pos);
    
            setselectedAuction({
                ...selectedAuction,
                document: temp
            });
        }

    }

    const btnAccept = () => {
        let error="";

        let aboutToUpload = selectedAuction;

        //row 1
        if(!!aboutToUpload?.description === false){ error="El titulo es obligatorio";}
        if(!!aboutToUpload?.date_expiry === false){ error="La Fecha de vencimiento es obligatoria";}
        if(!!aboutToUpload?.hour_expiry === false){ error="LA hora de vencimiento es obligatoria";}

        //row 2
        if(!!aboutToUpload?.auction_type === false){ error="El tipo de subasta es obligatorio";}
        if(!!aboutToUpload?.coin === false){ error="El tipo de moneda es obligatorio";}
        
        //row 3
        if(!!aboutToUpload?.id_warehouse === false){ error="La dirección de entrega obligatoria";}

        //general

        if(!!aboutToUpload?.id_consumption_center === false){ error="El centro de consumo es obligatorio";}
        //if(!!aboutToUpload?.auction_number === false){ error="La subasta no tiene requisiciones, no se puede asignar centro de consumo";}

        if(!!error){
            MySwal.fire({
                icon:'error',
                title:'Error',
                text: error
            })
            return null;
        }

        MySwal.fire({
            icon:'warning',
            title: '¿Está seguro?',
            text: "Esta acción es irreversible.",
            showCancelButton: true,
            confirmButtonText: `Sí, enviar`,
            cancelButtonText: `Cancelar`,
            customClass: {
              confirmButton: 'order-2',
              cancelButton: 'order-3',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch( 
                    update_indauc(
                        {
                            ...selectedAuction,
                            status: "unanswered",
                            account_session: counter.loginReducer.currentAccount.name,
                        },  
                        ()=>{
                            props.updateparent();
                            props.onHide();
                        }
                    ) 
                );
            } 
        })
    }

    const btnCancel = () =>{
        //TODO
        props.onHide()
    }


    const sendRejection = () => {
        let error="";

        if(!!rejection?.justification === false){ error="Justifique la razon de rechazo";}

        if(!!error){
            MySwal.fire({
                icon:'error',
                title:'Error',
                text: error
            })
            return null;
        }

        
        dispatch( 
            reject_indauc(
                {
                    id: selectedAuction.id,
                    justification: rejection.justification,
                    status: "rejected",
                }, 
                ()=>{
                    props.updateparent();
                    props.onHide();
                }
            ) 
        )
    }

    return (
        <ModalNew
            title="Subasta de Artículos Pendientes"
            show={props.show}
            size="940"
            btnYesName="Enviar"
            btnNoName="Cancelar"

            btnYesEvent={()=>btnAccept()}
            onHide={()=>btnCancel()}

            extraButtons={
                (selectedAuction.status==="pending" && props.rejectionable)?
                [
                    {
                        type:1,
                        name:"Denegar Subasta",
                        icon: extraBtnRedX,
                        event: ()=>setRejection({
                            ...rejection,
                            show: true
                        }),
                    }
                ]
                :
                []
            }
        >
                            <div className="d-flex">
                            {counter.individualAuctionReducer.updateIndauction_loading && (
                            <div className="loading">
                                <Loader
                                type="Oval"
                                color="#003f80"
                                height={100}
                                width={100}
                                />
                            </div>
                            )}
                            
                                <div  className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f3}`}>
                                    <p className={IndividualStyles.crudModalLabel}>Título de Subasta</p>
                                    <input
                                        className={`${IndividualStyles.registerInputs}`}
                                        name="description"
                                        type="text"
                                        value={selectedAuction.description}
                                        onChange={(e)=>setselectedAuction({
                                            ...selectedAuction,
                                            description: e.target.value
                                        })}
                                        disabled={props.readonly}
                                        
                                    />
                                </div>

                                <div  className={`d-flex ${pendingStyles.f2}`}>
                                    
                                    <div  className={`${pendingStyles.inputMargin}  ${pendingStyles.Item} ${pendingStyles.f1}`}>
                                        <p className={IndividualStyles.crudModalLabel}>Fecha de Vencimiento</p>
                                        <input
                                            className={` ${IndividualStyles.crudModalInputDate} ${IndividualStyles.registerInputsBlue}`}
                                            name="date"
                                            type="date"
                                            value={selectedAuction.date_expiry}
                                            onChange={(e)=>setselectedAuction({
                                                ...selectedAuction,
                                                date_expiry: e.target.value
                                            })}
                                            disabled={props.readonly}
                                        />
                                            
                                    </div>

                                    <div  className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
                                        <p className={IndividualStyles.crudModalLabel}>Hora</p>
                                        <input
                                            className={`${IndividualStyles.styleSelect} ${IndividualStyles.selectRegister} `}
                                            name="hour"
                                            type="time"
                                            value={selectedAuction.hour_expiry}
                                            onChange={(e)=>setselectedAuction({
                                                ...selectedAuction,
                                                hour_expiry: e.target.value
                                            })}
                                            disabled={props.readonly}
                                        />
                                    </div>

                                </div>
                                
                            </div>

                                    {/* ============================= ROW 2 =================================== */}
                            <div className="d-flex">
                                <div  className={`d-flex ${pendingStyles.inputMargin} ${pendingStyles.f3}`}>

                                    {(!!selectedAuction.auction_number) &&
                                        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}>
                                            <p className={IndividualStyles.crudModalLabel}>Código de subasta</p>
                                            <input
                                                className={`${IndividualStyles.styleSelect} ${IndividualStyles.registerInputs}`}
                                                name="auction_number"
                                                type="text"
                                                value={selectedAuction.auction_number}
                                                disabled={true}
                                            />
                                        </div>
                                    }
                                    

                                    <div  className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
                                        <p className={IndividualStyles.crudModalLabel}>Tipo de Subasta</p>
                                        <select
                                            name="auction_type"
                                            className={`${IndividualStyles.selectRegister}`}
                                            onChange={(e)=>setselectedAuction({
                                                ...selectedAuction,
                                                priority: e.target.value
                                            })}
                                            value={selectedAuction.priority}
                                            disabled={props.readonly}
                                        >
                                            <option value="" ></option>
                                            <option value="normal" >Normal</option>
                                            <option value="priority">Prioritaria</option>  
                                        </select>
                                    </div>

                                    
                                    <div  className={`${pendingStyles.Item} ${pendingStyles.f2}`}>
                                            <p className={IndividualStyles.crudModalLabel}>Moneda</p>
                                            <select
                                            name="coin"
                                            className={`${IndividualStyles.selectRegister}`}
                                            onChange={(e)=>setselectedAuction({
                                                ...selectedAuction,
                                                coin: e.target.value
                                                })}
                                            value={selectedAuction.coin}
                                            disabled={props.readonly}
                                            >
                                                <option value="" ></option>
                                                <option value="peso" >Peso colombiano</option>
                                                <option value="Dolar">Dolar estadunidense</option>  
                                            </select>   
                                        
                                    </div>
                                
                                </div>

                                <div  className={`${pendingStyles.Item} ${pendingStyles.f2}`}>
                                    <p className={IndividualStyles.crudModalLabel}>Condiciones de Pago</p>
                                    <select
                                        name="pay_conditions"
                                        className={`${IndividualStyles.selectRegister}`}
                                        onChange={(e)=>setselectedAuction({
                                            ...selectedAuction,
                                            pay_conditions: e.target.value
                                            })}
                                        value={selectedAuction.pay_conditions}
                                        disabled={props.readonly}
                                        >
                                            <option value="" ></option>
                                            <option value="prepayment" >Pago anticipado</option>
                                            <option value="delivery">Pago contraentrega</option> 
                                            <option value="pay20">Pago a 20 días</option>
                                            <option value="pay30">Pago a 30 días</option>
                                            <option value="pay60">Pago a 60 días</option>
                                            <option value="pay90">Pago a 90 días</option> 
                                        </select>   
                                </div>

                                
                            </div>

                                    {/* ============================= ROW 3 =================================== */}
                            <div className="d-flex">
                                <div  className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f3}`}>
                                    <p className={IndividualStyles.crudModalLabel}>Términos y condiciones</p>
                                    <textarea
                                        className={`${pendingStyles.textarea}`}
                                        name="terms"
                                        type="text"
                                        value={selectedAuction.terms}
                                        onChange={(e)=>setselectedAuction({
                                            ...selectedAuction,
                                            terms: e.target.value
                                        })}
                                        disabled={props.readonly}
                                    />
                                </div>

                                <div  className={`${pendingStyles.Item} ${pendingStyles.f2}`}>
                                    <p className={IndividualStyles.crudModalLabel}>Dirección de Entrega</p>
                                    <select
                                        name="direction"
                                        className={`${IndividualStyles.selectRegister} ${pendingStyles.fnone}`}
                                            onChange={(e)=>setselectedAuction({
                                            ...selectedAuction,
                                            id_warehouse: e.target.value
                                            })}
                                            value={selectedAuction.id_warehouse}
                                            disabled={props.readonly}
                                        >
                                        <option value="" ></option> 
                                        {optionStorage()}
                                    </select>
                                </div>
                            </div>

                                    {/* ============================= ROW 4 =================================== */}
                            <div className={`${IndividualStyles.imagePadding} ${IndividualStyles.negativeMargin} ${IndividualStyles.justify} d-flex`}>
                                
                                <div className="">
                                    {
                                        (Array.isArray(selectedAuction.document)) && (
                                            selectedAuction.document.map( (elem, index) => {

                                                return (<div id="2" className={` ${IndividualStyles.buttonCreatCount} mx-3`}>
                                                    <div className={IndividualStyles.deleteicon}>
                                                        <span onClick={(e)=>Disappears(index)}>x</span>
                                                        <img              
                                                            src={docType(selectedAuction, index)}
                                                            alt="añadir"
                                                            className={`${IndividualStyles.imagePaddingType}`}
                                                            
                                                        />
                                                        <label className={`${IndividualStyles.labelNew} ${IndividualStyles.ellipsis}`}>{selectedAuction.document[index]?.file_name}</label>
                                                    </div>
                                                </div> );
                                            })
                                        )
                                    }
                                    
                                </div>
                            

                                            
                                    
                                {(!props.readonly) &&(
                                <div className= "">
                                    <img              
                                        src={Attach}
                                        onClick={()=>ButtonDocuments()}
                                        alt="añadir"
                                        className={`${IndividualStyles.btn} mx-3`}
                                    />
                                </div>
                                )}

                            </div>

                            <GenericTableNew dark={true} headers={renderHeaders}>
                                {paginateList()}
                            </GenericTableNew>

                            <div className={paginationStyles.wrapper}>
                                <p className={paginationStyles.paginationText}>
                                    Pag. { sel_indauc?.article_list?.length ? filters.page : ""} 
                                    {" de "}
                                    {Math.ceil( sel_indauc?.article_list?.length / filters.perpage) ? 
                                        Math.ceil( sel_indauc?.article_list?.length / filters.perpage)
                                    : 
                                        "" 
                                    }
                                    {" "}
                                    ({ sel_indauc?.article_list?.length} encontrados)
                                </p>
                                <Pagination
                                    activePage={filters.page}
                                    itemsCountPerPage={filters.perpage}
                                    totalItemsCount={ sel_indauc?.article_list?.length}
                                    pageRangeDisplayed={5}
                                    onChange={ (e) => setFilters({...filters, page: e}) }
                                    itemClassPrev={paginationStyles.itemClassPrev}
                                    itemClassNext={paginationStyles.itemClassNext }
                                    itemClassFirst={paginationStyles.itemClassFirst}
                                    itemClassLast={paginationStyles.itemClassLast}
                                    itemClass={paginationStyles.itemClass}
                                />
                            </div>

                            <div  className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
                                <p className={IndividualStyles.crudModalLabel}>Observaciones</p>
                                <textarea 
                                id="txtArea" 
                                rows="10" 
                                cols="10" 
                                className={IndividualStyles.textArea}
                                value={selectedAuction.observations}
                                onChange={(e)=>setselectedAuction({
                                    ...selectedAuction,
                                    observations: e.target.value
                                })}
                                disabled={props.readonly}
                                ></textarea>
                            </div>
                        
            <ModalDocuments
                title="Anexar Documento"
                key={"modalDocuments"+trigger}
                show={modalDocumentsControl.show}
                onHide={()=>setmodalDocumentsControl({
                    ...modalDocumentsControl,
                    show: false
                })}
                reqState={selectedAuction}
                setreqState={setselectedAuction}
            />

            <ModalNew
                title="Denegar Subasta"
                key={"modalRejection"+trigger}
                show={rejection.show}
                onHide={()=>setRejection({
                    ...rejection,
                    show: false
                })}
                btnYesName="Guardar"
                btnYesEvent={sendRejection}
            >
                 <div  className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
                    <p className={IndividualStyles.crudModalLabel}>Justificación</p>
                    <textarea 
                        id="txtArea" 
                        rows="10" 
                        cols="10" 
                        className={IndividualStyles.textAreaBig}
                        value={rejection.justification}
                        onChange={(e)=>setRejection({
                            ...rejection,
                            justification: e.target.value
                        })}
                    ></textarea>
                </div>
            </ModalNew>

            {modalProgrammingControl.show?
                <ModalProgramming
                    title="Programación de entregas"
                    key={"modalProgramming"+trigger}
                    show={modalProgrammingControl.show}
                    onHide={()=>setmodalProgrammingControl({
                        ...modalProgrammingControl,
                        show: false
                    })}
                    articulo={modalProgrammingControl.articulo}
                    id={modalProgrammingControl.id}
                    reqState={selectedAuction}
                    setreqState={setselectedAuction}
                />
            :
                ""
            }

        </ModalNew>
    )

}



export default PendingAuctionModalArticles;