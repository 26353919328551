//react
import React, { useState, useEffect } from "react";

//components
import { OrdGenericTemplate } from "../../../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import { appCustomSelectNew } from "../../../Layouts/react-select-custom";
import OrdTable from "../../../../OrderingModule/OrderingComponents/OrdTable";
import OrdModal from "../../../../OrderingModule/OrderingComponents/OrdModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Select from "react-select";

//hooks
import { useGetMethod } from "../../../../Hooks/useFetch";
import { useSelector } from "react-redux";

//helpers
import { convertMoneyFormatNew, loader } from "../../../../helpers/helpers";

//icons
import pdfClearBlueIcon from "../../../../../src/assets/img/icons/pdfClearBlueIcon.svg";
import ordBlueSearch from '../../../../../src/assets/img/icons/ordBlueSearch.svg'
import focaEyeBlue from '../../../../../src/assets/img/icons/focaEyeBlue.svg'
import arrowDarkGrey from '../../../../../src/assets/img/icons/arrowDarkGrey.svg'
import excelVeryClearBlue from "../../../../../src/assets/img/icons/excelVeryClearBlue.svg";

//css
import styles from "../../../../components/Layouts/tableStyle.module.scss";
import { Box } from "@mui/material";

export const QueriesOrder = () => {
    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const [trigger, setTrigger] = useState(1);
    const [filters, setFilters] = useState({
        page: 1,
        perpage: 10,
        filterPatient: "",
    });


    const [services, setServices] = useState({
        data: {},
        showDrawer: false,
        showImportance: false,
        header: [
            {
                title: "Tipo",
                className: "px-2 text-center",
            },
            {
                title: "Identificación",
                className: "px-2 text-center",
            },
            {
                title: "Proveedor",
                className: "px-2 text-start",
            },
            {
                title: "Ordenes",
                className: "px-2 text-center",
            },
            {
                title: "Total copago",
                className: "px-2 text-end",
            },
            {
                title: "",
                className: "px-3 text-end",
            },
        ],
    });

    const {
        results: listPatient,
        load: loadListPatient,
        trigger: getListPatient,
    } = useGetMethod();


    const renderToolImportance = (text) => (
        <Tooltip>
            <span className={styles.darkerGrayText}>{text}</span>
        </Tooltip>
    );

    const formatData = (array) => {
        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                tempList.push(
                    <tr key={index} className={`hover-table-row`}>
                        <td className="text-center px-3">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 250 }}
                                className={styles.app_container_tool_websc}
                                overlay={renderToolImportance('Lente de contacto')}
                            >

                                <img height={'25px'} src={focaEyeBlue} alt="icono" />

                            </OverlayTrigger>
                        </td>
                        <td className="text-center px-2">
                            {convertMoneyFormatNew({ textNumber: '1045746685', SymbolShow: 'true', SymbolDecimal: '.' })}
                        </td>
                        <td className="text-start px-2">Echo - Echo Corporation</td>
                        <td className="text-center px-2">16415</td>
                        <td className="text-end px-2">{convertMoneyFormatNew({ textNumber: '10500' })}</td>
                        <td className="text-center px-3">
                            <img
                                className={`${styles.pointer} ${styles.filterOrdAquaMarine}`}
                                src={arrowDarkGrey}
                                alt="icono"
                            />
                        </td>
                    </tr>
                );
            });
        }
        return tempList;
    };


    useEffect(() => {
        getListPatient({
            url: "/medical/patient/listPatient/",
            objFilters: filters,
            token: token,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);

    return (
        <>
            {(loadListPatient) &&
                loader}
            <OrdModal
                title="Tipo de importancia"
                show={services.showImportance}
                btnYesName={"Aceptar"}
                btnNoName={"Cancelar"}
                size={"200"}
                onHide={() => setServices({ ...services, showImportance: false })}
                // btnYesEvent={() => OnSubmitMarkPatient()}
                btnNoEvent={() => setServices({ ...services, showImportance: false })}
            >

                <div>
                    <Select noOptionsMessage={() => 'No hay datos'}
                        className="text-secondary"
                        value={() => { }}
                        placeholder={"Seleccionar..."}
                        onChange={(e) => () => { }}
                        styles={appCustomSelectNew}
                    />
                </div>

            </OrdModal>

            <div className="container-fluid pt-3">
                <OrdGenericTemplate
                    titleSize='12'
                    title="Consulta órdenes a proveedores"
                >
                    <div className={`${styles.app_mr_100} asistencialDateColorIcon`}>

                        <Box
                            gap={2}
                            marginBottom={2}
                            display={'grid'}
                            gridTemplateColumns={'170px 170px 4fr 1fr'}
                        >

                            <Box>
                                <span className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className="text-start">&nbsp;Fecha desde</b>
                                </span>
                                <input
                                    className={`w-100 ord-roundInput pointer ${styles.inputDate} ${styles.boxShadowNone}`}
                                    type="date"
                                    value={filters.startDate}
                                    onChange={(e) => {
                                        setFilters({ ...filters, startDate: e.target.value });
                                    }}
                                />
                            </Box>

                            <Box>
                                <span className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className="text-start">&nbsp;Hasta</b>
                                </span>
                                <input
                                    disabled={!filters?.startDate}
                                    className={`w-100 ord-roundInput pointer  ${styles.inputDate} ${styles.boxShadowNone}`}
                                    type="date"
                                    value={filters.endDate}
                                    onChange={(e) => {
                                        setFilters({ ...filters, endDate: e.target.value });
                                    }}
                                />
                            </Box>

                            <Box className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
                                <input
                                    className={`ord-roundInput ${styles.appInputDate} w-100`}
                                    type="text"
                                    placeholder="Escribe aquí para buscar"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setFilters({ ...filters, filterPatient: e.target.value });
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            setTrigger(trigger + 1);
                                        }
                                    }}
                                />
                                <img
                                    src={ordBlueSearch}
                                    className="pointer"
                                    alt="buscar"
                                    onClick={() => setTrigger(trigger + 1)}
                                />
                            </Box>

                            <div style={{ display: 'flex', columnGap: '20px', marginLeft: 'auto' }}>

                                <div style={{ display: 'flex' }}>
                                    <div style={{
                                        display: "flex",
                                        columnGap: '20px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginTop: '25px'
                                    }}>
                                        <img height={'24px'} src={pdfClearBlueIcon} alt="" />
                                        <img height={'24px'} src={excelVeryClearBlue} alt="" />
                                    </div>

                                </div>

                            </div>

                        </Box>


                        <OrdTable
                            shadow
                            className={'mt-0'}
                            hasChildren={true}
                            headers={services.header}
                            paginate={{
                                activePage: filters.page,
                                totalPages: listPatient.rowTotal,
                                perPage: filters.perpage,
                                pageRangeDisplayed: 3,
                                onChangePage: async (e) => {
                                    const result = await getListPatient({
                                        url: "/medical/patient/listPatient/",
                                        objFilters: { ...filters, page: e },
                                        token: token,
                                    });
                                    setFilters({ ...filters, page: e });
                                    formatData(result?.results);
                                },
                                showTextDetails: true,
                            }}
                        >
                            {formatData(listPatient.results)}
                        </OrdTable>
                    </div>
                </OrdGenericTemplate>
            </div>
        </>
    );
};

