import React, { useState, useEffect, } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Col, Row } from "react-bootstrap";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Select from "react-select";
import Pagination from "react-js-pagination";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import NumberFormat from "react-number-format";
import {
  customSelectNewDark,
} from "../../components/Layouts/react-select-custom";
import { useSelector, useDispatch } from "react-redux";
import ModalNew from "../../components/Layouts/ModalNew";
import {
  getTypesServices,
  getListStatus,
  getServicesInADM,
  creatServices,
  getIva,
  changeStatusService,
  getOneService,
  updateServices,
  getCups
} from "../../actions/billingActions";
import { getNiifAccounts } from "../../actions/pucActions";
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import { formatToRcSelect, handleFilterSelectDynamic } from "../../helpers/helpers";
import Loader from "react-loader-spinner";
import { useGetMethod } from "../../Hooks/useFetch"

function Services(props) {
  const storage = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);
  const [triggerModal, setTriggerModal] = useState(1);
  const dispatch = useDispatch();
  const myPermission = () => storage.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "ServicesAD");
  const token = storage.loginReducer.Authorization
  const { results: times, load: timesLoader, trigger: getTimes } = useGetMethod();
  const [modalAlertcontrol, setModalAlertcontrol] = useState({
    show: false,
  });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    type: "",
    status: "",
    search: "",
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const defaultMessageDynamicCups = 'Digite el nombre o código CUPS'
  const [dynamicCups, setDynamicCups] = useState([{ value: "", label: defaultMessageDynamicCups }])
  const [selectOptionsCups, setSelectOptionsCups] = useState([])

  const defaultMessageDynamicNiif = 'Digite el nombre o código de la cuenta de ingreso'
  const [dynamicNiif, setDynamicNiif] = useState([{ value: "", label: defaultMessageDynamicNiif }])
  const [selectOptionsNiif, setSelectOptionsNiif] = useState([])

  const defaultMessageDynamicReturnNiif = 'Digite el nombre o código de la cuenta de devolución'
  const [dynamicReturnNiif, setDynamicReturnNiif] = useState([{ value: "", label: defaultMessageDynamicReturnNiif }])
  const [selectOptionsReturnNiif, setSelectOptionsReturnNiif] = useState([])
  const [selectOptionsConsents, setSelectOptionsConsents] = useState([])

  const [creatService, setcreatService] = useState({
    name: "",
    cup_code: "",
    incomeNiif: "",
    returnNiif: "",
    description: "",
    price: "",
    vat_id: "",
    type_id: undefined,
    eaccount: storage.loginReducer.currentAccount.id,
    enabled_telemedicine: "",
    vat_id_telemedicine: "",
    price_telemedicine: "",
    optimalTimeQty: "",
    optimalTimeId: undefined,
    optimalTimeOrdQty: undefined,
    optimalTimeOrdId: undefined,
    recommendations: "",
    cntId: undefined
  });
  const [loading, setLoading] = useState(true);

  const {
    results: listConsents,
    load: loaderListConsents,
    trigger: getListConsents,
  } = useGetMethod();

  const getOptionsCupsSelect = () => {

    if (Array.isArray(storage.billingReducer.cups)) {
      let data = []
      storage.billingReducer.cups.forEach((item) => {
        data.push({
          value: item.id,
          label: item.value,
          key: item.id + "cup",
        });
      });

      setSelectOptionsCups(state => ([...state, ...data]))
    }
  }
  
  const getOptionsNiifSelect = () => {

    if (Array.isArray(storage.pucReducer.niifAccounts)) {
      let data = []
      storage.pucReducer.niifAccounts.forEach((item) => {
        data.push({
          value: item.id,
          label: item.complete_account + " - " + item.description,
          key: item.id + "niff",
        });
      });

      setSelectOptionsNiif(state => ([...state, ...data]))

      setSelectOptionsReturnNiif(state => ([...state, ...data]))

      

    }
  }



  useEffect(() => {
    dispatch(
      getTypesServices({ eaccount: storage.loginReducer.currentAccount.id })
    );
    dispatch(
      getListStatus({
        eaccount: storage.loginReducer.currentAccount.id,
        type: "service",
      })
    );
    dispatch(getIva({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(
      getNiifAccounts({ eaccount: storage.loginReducer.currentAccount.id })
    );

    getListConsents({
      url: "/medical/admissions/consents/",
      objFilters: { status: 1 },
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getTimes({
      url: "/medical/time/",
      objFilters: { id_account: storage.loginReducer.currentAccount.id },
      token: storage.loginReducer.Authorization,
    });
  }, []);
  const formattedTimes = formatToRcSelect(times?.results, "id", "name", "value");

  useEffect(() => {
    dispatch(getCups({ eaccount: storage.loginReducer.currentAccount.id, search: creatService.cup_code }));
  }, [creatService.cup_code]);

  useEffect(() => {
    dispatch(getServicesInADM(filters, () => setLoading(false)));
  }, [filters.page, trigger, filters.type, filters.status]);

  useEffect(() => {
    if (!!modalAlertcontrol.service) {
      dispatch(
        getOneService(
          {
            idAccount: storage?.loginReducer?.currentAccount.id,
            srvId: modalAlertcontrol?.service,
          },
          // setcreatService
          (res) => {
            setLoading(false) 
            
            handleFilterSelectDynamic({
              keyboardValue: res[0]?.cupCode ?? "",
              listFilter: selectOptionsCups,
              labelField: 'label',
              valueField: 'value',
              defaultMessage: defaultMessageDynamicCups
            }).then(data => {
              setDynamicCups(data)
            })
            
            setDynamicNiif(selectOptionsNiif.filter(el=> el?.value === res[0]?.incomeNiif))
            
            setDynamicReturnNiif(selectOptionsReturnNiif.filter(el=> el?.value === res[0]?.returnNiif)) 

            const consentsList = listConsents?.results?.filter(el=> el?.id === res[0]?.cntId)
                                  .map(item => ({ label: `${item?.code}-${item?.name}-${item?.version}`, value: item?.id }))

            const defaultValueConsents = {label:'Seleccionar...', value:''}

            const responseConsents = consentsList?.length ? [defaultValueConsents, ...consentsList] : [defaultValueConsents]

            setSelectOptionsConsents(responseConsents)
            
            setcreatService({
              name: res[0]?.name,
              cup_code: res[0]?.cupCode,
              income_niif: res[0]?.incomeNiif,
              return_niif: res[0]?.returnNiif,
              description: res[0]?.description,
              price: res[0]?.price,
              vat_id: res[0]?.vatId,
              type_id: res[0]?.typeId,
              eaccount: storage.loginReducer.currentAccount.id,
              enabled_telemedicine: res[0]?.couldBeTmed,
              price_telemedicine: res[0]?.priceTmed,
              vat_id_telemedicine: res[0]?.vatIdTmed,
              optimalTimeQty: res[0]?.optimalTimeQty,
              optimalTimeId: res[0]?.optimalTimeId,
              optimalTimeOrdQty: res[0]?.srvConductTimeQty,
              optimalTimeOrdId: res[0]?.srvConductTimeId,
              recommendations: res[0]?.recommendations,
              cntId: res[0]?.cntId,
              incomeNiif: res[0]?.incomeNiif,
              idAccount: storage.loginReducer.currentAccount.id,
              returnNiif: res[0]?.returnNiif,
              srv_id: res[0]?.srvId,
              
            });

            setLoading(false)
          }
        )
      );
      // setLoading(false)
    }
  }, [triggerModal]);

  useEffect(() => {
    if (!selectOptionsCups.length) {
      getOptionsCupsSelect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage.billingReducer.cups])

  useEffect(() => {
    if (!selectOptionsNiif.length) {
      getOptionsNiifSelect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage.pucReducer.niifAccounts])

  let optionsServices = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (Array.isArray(storage.billingReducer.get_types_services)) {
    storage.billingReducer.get_types_services.map((item) => {
      optionsServices.push({
        value: item.id,
        label: item.name,
        key: item.id + "services",
      });
    });
  }
  let optionsStatus = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (Array.isArray(storage.billingReducer.status)) {
    storage.billingReducer.status.map((item) => {
      optionsStatus.push({
        value: item.name,
        label: item.value,
        key: item.id + "status",
      });
    });
  }

  let optionsIva = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (Array.isArray(storage.billingReducer.iva)) {
    storage.billingReducer.iva.map((item) => {
      optionsIva.push({
        value: item.id,
        label: item.short_name,
        key: item.id + "iva",
      });
    });
  }

  let optionsNiif = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (Array.isArray(storage.pucReducer.niifAccounts)) {
    storage.pucReducer.niifAccounts.map((item) => {
      optionsNiif.push({
        value: item.id,
        label: item.complete_account + " - " + item.description,
        key: item.id + "niff",
      });
    });
  }

  let optionsCUPS = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (Array.isArray(storage.billingReducer.cups)) {
    storage.billingReducer.cups.forEach((item) => {
      optionsCUPS.push({
        value: item.id,
        label: item.value,
        key: item.id + "cup",
      });
    });
  }

  // const loadOptions = async (searchQuery, loadedOptions, { page }) => {

  //   const response = await fetch(
  //     `https://www.anapioficeandfire.com/api/houses?region=${regionName}&page=${page}&pageSize=10`
  //   );
  //   const responseJSON = await response.json();

  //   return {
  //     options: responseJSON,
  //     hasMore: responseJSON.length >= 1,
  //     additional: {
  //       page: searchQuery ? 2 : page + 1,
  //     },
  //   };
  // };

  const header = [
    <th className="text-start px-2">Descripción</th>,
    <th className="text-center px-2">Tipo</th>,
    <th className="text-center w100 px-2">Estado</th>,
    <th className="text-center px-2">ㅤ</th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.billingReducer.services_ADM)) {
      table = storage.billingReducer.services_ADM.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">

            <td className="text-start px-2 col-5">{x?.name || '-'}</td>
            <td className="text-center px-2 col-3">{x?.serviceType || '-'}</td>
            <td className="px-2 col-3">
              <div
                className={`
                        ${IndividualStyles.popupReqActive} ${x.status === "disabled"
                    ? tableStyles.redState
                    : tableStyles.greenState
                  }
                    `}
              >
                {x.status === "disabled" ? 'Inhabilitado' : 'Habilitado'}
              </div>
            </td>
            <td className="px-2 col-1">
              <CustomPopupExtend
                triggerSrc={threeDots}
                noHover
                showEdit={myPermission()?.edit}
                editText="Editar"
                editClickEvent={() => {
                  setModalAlertcontrol({
                    ...modalAlertcontrol,
                    show: true,
                    edit: true,
                    creat: false,
                    service: x.srvId,
                  });
                  setLoading(true)
                  setTriggerModal(triggerModal + 1) 
                }
                }
                showDetails={myPermission()?.read}
                showDetailsEvent={() => {
                  setModalAlertcontrol({
                    ...modalAlertcontrol,
                    show: true,
                    edit: false,
                    creat: false,
                    service: x.srvId,
                  });
                  setLoading(true)
                  setTriggerModal(triggerModal + 1)
                }
                }
                textDetails="Ver Detalle"
                showEnable={myPermission()?.edit}
                isEnabled={x.status === "enabled"}
                enableClickEvent={() => changeStatus(x.srvId, x)}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const creat = () => {
    setModalAlertcontrol({
      ...modalAlertcontrol,
      show: false,
    });

    if (modalAlertcontrol.creat == false) {
      setLoading(true);
      dispatch(
        updateServices({
          srvId: creatService.srv_id,
          name: creatService.name,
          cupCode: creatService.cup_code,
          incomeNiif: creatService.incomeNiif,
          returnNiif: creatService.returnNiif,
          description: creatService.description,
          price: creatService.price,
          eaccount: storage.loginReducer.currentAccount.id,
          vatId: creatService.vat_id,
          typeId: creatService.type_id,
          couldBeTmed: creatService.enabled_telemedicine,
          priceTelemedicine: creatService.price_telemedicine,
          priceTmed: creatService.price_telemedicine,
          optimalTimeQty: creatService.optimalTimeQty,
          optimalTimeId: creatService.optimalTimeId,
          optimalTimeOrdQty: creatService.optimalTimeOrdQty,
          vatIdTmed: creatService.vat_id_telemedicine,
          srvConductTimeQty: creatService.optimalTimeOrdQty,
          srvConductTimeId: creatService.optimalTimeId,
          optimalTimeOrdId: creatService.optimalTimeOrdId,
          recommendations: creatService.recommendations,
          cntId: creatService?.cntId
        },
          () => {
            dispatch(getServicesInADM(filters, () => setLoading(false)));
            setcreatService({
              name: "",
              cup_code: "",
              income_niif: "",
              return_niif: "",
              description: "",
              price: "",
              vat_id: "",
              type_id: undefined,
              eaccount: storage.loginReducer.currentAccount.id,
              enabled_telemedicine: "",
              price_telemedicine: "",
              vat_id_telemedicine: "",
              optimalTimeQty: "",
              optimalTimeId: undefined,
              optimalTimeOrdQty: undefined,
              optimalTimeOrdId: undefined,
              recommendations: "",
              cntId: undefined,
              vatIdTmed: '',
              srvConductTimeQty: '',
              srvConductTimeId: '',
            });
          }
        )
      );
    } else {
      setLoading(true);
      dispatch(

        creatServices({
          price: Number(creatService.price),
          status: 'Habilitado',
          name: creatService.name,
          cupCode: String(creatService.cup_code),
          incomeNiif: creatService.incomeNiif,
          returnNiif: creatService.returnNiif,
          description: creatService.description,
          idAccount: storage.loginReducer.currentAccount.id,
          vatId: creatService.vat_id,
          typeId: creatService.type_id,
          couldBeTmed: creatService.enabled_telemedicine || false,
          medicalAuthRequired: false,
          protocolReviewRequired: false,
          price_telemedicine: creatService.price_telemedicine,
          vat_id_telemedicine: creatService.vat_id_telemedicine,
          optimalTimeQty: Number(creatService.optimalTimeQty),
          optimalTimeId: Number(creatService.optimalTimeId),
          optimalTimeOrdQty: Number(creatService.optimalTimeOrdQty),
          optimalTimeOrdId: Number(creatService.optimalTimeOrdId),
          recommendations: creatService.recommendations,
          cntId: creatService?.cntId,
          acronym: '1',

        },
          () => {
            dispatch(getServicesInADM(filters, () => setLoading(false)));
            setcreatService({
              name: "",
              cup_code: "",
              income_niif: "",
              return_niif: "",
              description: "",
              price: "",
              vat_id: "",
              type_id: undefined,
              eaccount: storage.loginReducer.currentAccount.id,
              enabled_telemedicine: "",
              price_telemedicine: "",
              vat_id_telemedicine: "",
              optimalTimeQty: "",
              optimalTimeId: undefined,
              optimalTimeOrdQty: undefined,
              optimalTimeOrdId: undefined,
              recommendations: "",
              cntId: undefined
            });
          }
        )
      );
    }
  };

  const changeStatus = (id_service, elem) => {
    setLoading(true)
    dispatch(
      changeStatusService(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          id: id_service,
          status: elem.status === 'disabled' ? 'enabled' : 'disabled',
          elem
        },
        () => {
          dispatch(getServicesInADM(filters, () => setLoading(false)));
        }
      )
    );
  };
  const handleSumit = (e) => {
    setLoading(true)
    e.preventDefault();
    setTrigger(trigger + 1);
    setFilters({ ...filters, page: 1 });
  }

  return (
    <>
      {(loading || loaderListConsents) &&
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      }
      <ModalNew
        hideFooter={modalAlertcontrol.edit ? !myPermission()?.edit : !myPermission()?.create}
        size="620"
        title={modalAlertcontrol.creat ? "Crear Servicio" : "Servicio"}
        subtitle={
          modalAlertcontrol.creat
            ? ""
            : modalAlertcontrol.edit
              ? "Editar"
              : "Detalle"
        }
        show={modalAlertcontrol.show}
        btnYesName={modalAlertcontrol.creat ? "Guardar" : "Editar"}
        btnYesDisabled={
          !!creatService.name == "" ||
          !!creatService.cup_code == "" ||
          !!!creatService.type_id ||
          !!creatService.incomeNiif == "" ||
          !!creatService.returnNiif == "" ||
          !!creatService.description == "" ||
          !!creatService.recommendations == "" ||
          !!creatService.vat_id == "" ||
          !!creatService.price == "" ||
          (!!(creatService.enabled_telemedicine == true) ? (!!creatService.price_telemedicine == "" || !!creatService.vat_id_telemedicine == "") : false)
        }

        btnYesEvent={modalAlertcontrol.edit ? () => creat() : null}
        onHide={() => {
          setModalAlertcontrol({
            ...modalAlertcontrol,
            show: false,
          });
          setcreatService({
            name: "",
            cup_code: "",
            income_niif: "",
            return_niif: "",
            description: "",
            price: "",
            vat_id: "",
            type_id: undefined,
            eaccount: storage.loginReducer.currentAccount.id,
            enabled_telemedicine: "",
            price_telemedicine: "",
            vat_id_telemedicine: "",
            recommendations: "",
            cntId: undefined
          });
          setSelectOptionsConsents([])
          setDynamicCups([{ value: "", label: defaultMessageDynamicCups }])
          setDynamicNiif([{ value: "", label: defaultMessageDynamicNiif }])
          setDynamicReturnNiif([{ value: "", label: defaultMessageDynamicReturnNiif }]) 
        }}
        btnNoEvent={() => {
          setModalAlertcontrol({
            ...modalAlertcontrol,
            show: false,
          });
          setcreatService({
            name: "",
            cup_code: "",
            income_niif: "",
            return_niif: "",
            description: "",
            price: "",
            vat_id: "",
            type_id: undefined,
            eaccount: storage.loginReducer.currentAccount.id,
            enabled_telemedicine: "",
            price_telemedicine: "",
            vat_id_telemedicine: "",
            recommendations: "",
            cntId: undefined
          });
        }}
      >
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <Row className="d-flex ">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Nombre del servicio</b>
              <span className={"text-danger"}>*</span>
            </p>
            <input
              placeholder='Escribir...'
              className={IndividualStyles.registerInputsClearBlue}
              name="position"
              style={{ color: "#58595B" }}
              value={creatService.name || ''}
              onChange={(e) =>
                setcreatService({
                  ...creatService,
                  name: e.target.value,
                })
              }
              disabled={!modalAlertcontrol.edit}
            />
          </Col>
        </Row>
        <Row className="d-flex ">
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Código CUPS</b>
              <span className={"text-danger"}>*</span>
            </p>

            <Select
              isDisabled={!modalAlertcontrol.edit}
              noOptionsMessage={() => 'No hay datos'}
              options={dynamicCups}
              placeholder={defaultMessageDynamicCups}
              styles={customSelectNewDark}
              value={dynamicCups.find(
                (item) => item.value === Number(creatService.cup_code)
              )}
              onInputChange={(e) => {

                handleFilterSelectDynamic({
                  keyboardValue: e,
                  listFilter: selectOptionsCups,
                  labelField: 'label',
                  valueField: 'value',
                  defaultMessage: defaultMessageDynamicCups
                }).then(data => setDynamicCups(data))

              }}
              onChange={(e) => {
                setcreatService({
                  ...creatService,
                  cup_code: e.value,
                })
              }}
            />

          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Tipo</b>
              <span className={"text-danger"}>*</span>
            </p>
            <Select noOptionsMessage={() => 'No hay datos'}
              placeholder={"Seleccionar..."}
              styles={customSelectNewDark}
              value={optionsServices.find(
                (item) => item.value === creatService.type_id
              )}
              options={optionsServices}
              onChange={(e) =>
                setcreatService({
                  ...creatService,
                  type_id: e.value,
                })
              }
              isDisabled={!modalAlertcontrol.edit}
            ></Select>
          </Col>
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Cuenta de ingreso</b>
              <span className={"text-danger"}>*</span>
            </p>

            <Select
              isDisabled={!modalAlertcontrol.edit}
              noOptionsMessage={() => 'No hay datos'}
              options={dynamicNiif}
              placeholder={defaultMessageDynamicNiif}
              styles={customSelectNewDark}
              value={dynamicNiif.find(
                (item) => item.value === Number(creatService.incomeNiif)
              )}
              onInputChange={(e) => {

                handleFilterSelectDynamic({
                  keyboardValue: e,
                  listFilter: selectOptionsNiif,
                  labelField: 'label',
                  valueField: 'value',
                  defaultMessage: defaultMessageDynamicNiif
                }).then(data => setDynamicNiif(data))

              }}
              onChange={(e) =>
                setcreatService({
                  ...creatService,
                  incomeNiif: e.value,
                })
              }
            />

          </Col>
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Cuenta de devolución</b>
              <span className={"text-danger"}>*</span>
            </p>

            <Select
              isDisabled={!modalAlertcontrol.edit}
              noOptionsMessage={() => 'No hay datos'}
              options={dynamicReturnNiif}
              placeholder={defaultMessageDynamicReturnNiif}
              styles={customSelectNewDark}
              value={dynamicReturnNiif.find(
                (item) => item.value == creatService.returnNiif
              )}
              onInputChange={(e) => {

                handleFilterSelectDynamic({
                  keyboardValue: e,
                  listFilter: selectOptionsReturnNiif,
                  labelField: 'label',
                  valueField: 'value',
                  defaultMessage: defaultMessageDynamicReturnNiif
                }).then(data => setDynamicReturnNiif(data))

              }}
              onChange={(e) =>
                setcreatService({
                  ...creatService,
                  returnNiif: e.value,
                })
              }
            />

          </Col>

          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Consentimiento</b>
            </p>
              
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={
                listConsents?.results?.length 
                  ? [{label:'Seleccionar...', value:''}, ...listConsents?.results?.map(el => ({ label: `${el?.code}-${el?.name}-${el?.version}`, value: el?.id }))]
                  : [{label:'Seleccionar...', value:''}]
              }
              placeholder={'Seleccionar...'}
              styles={customSelectNewDark}
              value={selectOptionsConsents[1]}
              onChange={({label, value}) =>{
                setcreatService({
                  ...creatService,
                  cntId: value,
                })

                setSelectOptionsConsents([{label:'Seleccionar...', value:''}, {label, value}])
              }}
            />
          </Col>

        </Row>
        <Row>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Tiempo óptimo del servicio</b>
              <span className={"text-danger"}>*</span>
            </p>
            <div className="d-flex">
              <input
                type="number"
                className={IndividualStyles.registerInputsClearBlue}
                name="position"
                placeholder='Escribir...'
                style={{ color: "#58595B", flex: 1, marginRight: "1rem" }}
                value={creatService.optimalTimeQty || ''}
                onChange={(e) =>
                  setcreatService({
                    ...creatService,
                    optimalTimeQty: e.target.value,
                  })
                }
                disabled={!modalAlertcontrol.edit}
              />
              <div style={{ flex: 4 }}>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder={"Seleccionar..."}
                  styles={customSelectNewDark}
                  value={formattedTimes.find(
                    (item) => item.value == creatService.optimalTimeId
                  )}
                  options={formattedTimes}
                  onChange={(e) =>
                    setcreatService({
                      ...creatService,
                      optimalTimeId: e.value,
                    })
                  }
                  isDisabled={!modalAlertcontrol.edit}
                ></Select>
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Tiempo óptimo del ordenamiento</b>
              <span className={"text-danger"}>*</span>
            </p>
            <div className="d-flex">
              <input
                type="number"
                placeholder='Escribir...'
                className={IndividualStyles.registerInputsClearBlue}
                name="position"
                style={{ color: "#58595B", flex: 1, marginRight: "1rem" }}
                value={creatService.optimalTimeOrdQty || ''}
                onChange={(e) =>
                  setcreatService({
                    ...creatService,
                    optimalTimeOrdQty: e.target.value,
                  })
                }
                disabled={!modalAlertcontrol.edit}
              />
              <div style={{ flex: 4 }}>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder={"Seleccionar..."}
                  styles={customSelectNewDark}
                  value={formattedTimes.find(
                    (item) => item.value == creatService.optimalTimeOrdId
                  )}
                  options={formattedTimes}
                  onChange={(e) =>
                    setcreatService({
                      ...creatService,
                      optimalTimeOrdId: e.value,
                    })
                  }
                  isDisabled={!modalAlertcontrol.edit}
                ></Select>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Descripción</b>
              <span className={"text-danger"}>*</span>
            </p>

            <textarea
              className={IndividualStyles.registerInputsClearBlue}
              placeholder='Escribir...'
              style={{ height: "100%", color: "#58595B" }}
              row="40"
              col="40"
              name="justification"
              type="text"
              value={creatService.description || ''}
              onChange={(e) =>
                setcreatService({
                  ...creatService,
                  description: e.target.value,
                })
              }
              disabled={!modalAlertcontrol.edit}
            ></textarea>
          </Col>
        </Row>
        <Row className="d-flex mt-4">
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Recomendaciones</b>
              <span className={"text-danger"}>*</span>
            </p>

            <textarea
              className={IndividualStyles.registerInputsClearBlue}
              style={{ height: "100%", color: "#58595B" }}
              row="40"
              col="40"
              name="justification"
              type="text"
              value={creatService.recommendations}
              onChange={(e) =>
                setcreatService({
                  ...creatService,
                  recommendations: e.target.value,
                })
              }
              disabled={!modalAlertcontrol.edit}
            ></textarea>
          </Col>
        </Row>
        <Row
          className={`w-100 d-flex mt-5 rounded m-0 ${tableStyles.bgClearBlue}`}
        >
          <Col xs={8} className={`p-0`}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Costo del servicio</b>
              <span className={"text-danger"}>*</span>
            </p>
            <NumberFormat
              allowNegative={false}
              placeholder='Escribir...'
              style={{ padding: 0, color: "#58595B" }}
              className={`${IndividualStyles.registerInputsClearBlue} pl1`}
              prefix={"$"}
              name="position"
              thousandsGroupStyle="thousand"
              thousandSeparator={"."}
              decimalSeparator={","}
              value={creatService.price || ''}
              onValueChange={(values) => {
                const { value } = values;
                setcreatService({
                  ...creatService,
                  price: value,
                });
              }}
              disabled={!modalAlertcontrol.edit}
            ></NumberFormat>
          </Col>
          <Col xs={4} style={{ paddingRight: "0px" }}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>IVA</b>
              <span className={"text-danger"}>*</span>
            </p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder={"Seleccionar..."}
              styles={customSelectNewDark}
              value={optionsIva.find(
                (item) => item.value == creatService.vat_id
              )}
              options={optionsIva}
              onChange={(e) =>
                setcreatService({
                  ...creatService,
                  vat_id: e.value,
                })
              }
              isDisabled={!modalAlertcontrol.edit}
            ></Select>
          </Col>
        </Row>
        <div className="d-flex mt-3 align-items-center">
          <input
            type="checkbox"
            name="handleTelemedicine"
            className="border border-dark-blue form-check-input p1 check-dark-blue"
            onChange={(e) =>
              setcreatService({
                ...creatService,
                enabled_telemedicine: !!(Number(creatService.enabled_telemedicine) === 1) ? false : true,
              })
            }
            checked={!!(Number(creatService.enabled_telemedicine) === 0) ? false : true}
          />
          <label
            htmlFor="handleTelemedicine"
            className={`${tableStyles.crudModalLabel}`}
          >
            Habilitado Telemedicina
          </label>
        </div>
        {!!(Number(creatService.enabled_telemedicine) === 1) && (
          <Row
            className={`w-100 d-flex mt-3 rounded m-0 ${tableStyles.bgClearBlue}`}
          >
            <Col xs={8} className={`p-0`}>
              <p className={`${tableStyles.crudModalLabel} `}>
                <b>Valor predefinido del servicio</b>
                <span className={"text-danger"}>*</span>
              </p>
              <NumberFormat
                allowNegative={false}
                placeholder='Escribir...'
                style={{ padding: 0, color: "#58595B" }}
                className={`${IndividualStyles.registerInputsClearBlue} pl1`}
                prefix={"$"}
                name="position"
                thousandsGroupStyle="thousand"
                thousandSeparator={"."}
                decimalSeparator={","}
                value={creatService.price_telemedicine || ''}
                onValueChange={(values) => {
                  const { value } = values;
                  setcreatService({
                    ...creatService,
                    price_telemedicine: value,
                  });
                }}
                disabled={!modalAlertcontrol.edit}
              ></NumberFormat>
            </Col>
            <Col xs={4} style={{ paddingRight: "0px" }}>
              <p className={`${tableStyles.crudModalLabel} `}>
                <b>IVA</b>
                <span className={"text-danger"}>*</span>
              </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder={"Seleccionar..."}
                styles={customSelectNewDark}
                value={optionsIva.find(
                  (item) => item.value == creatService.vat_id_telemedicine
                )}
                options={optionsIva}
                onChange={(e) =>
                  setcreatService({
                    ...creatService,
                    vat_id_telemedicine: e.value,
                  })
                }
                isDisabled={!modalAlertcontrol.edit}
              ></Select>
            </Col>
          </Row>
        )

        }
      </ModalNew>

      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >

        <div className="d-flex">
          <h1 className={tableStyles.title}>Servicios</h1>
        </div>

        {
          myPermission()?.read && (
            <>


              <div className="row gap-2">
                <div className="col-2">
                  <p className={tableStyles.crudModalLabel}>Tipo</p>
                </div>

                <div className="col-2" >
                  <p className={tableStyles.crudModalLabel}>Estado</p>
                </div>

              </div>
            </>
          )
        }

        <Row className="d-flex align-items-center gap-2" style={{ marginTop: '-5px' }}>
          {
            myPermission()?.read && (
              <>
                <Col xs={2}>

                  <div className="d-flex">
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      Name="optionsTypes"
                      className={"w-100"}
                      placeholder="Seleccionar..."
                      options={optionsServices}
                      styles={customSelectNewDark}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          type: e.value,
                        })
                        setLoading(true)
                      }
                      }

                    />
                  </div>
                </Col>

                <Col xs={2} >

                  <Select noOptionsMessage={() => 'No hay datos'}
                    Name="optionsMovements"
                    className={"w-100"}
                    placeholder="Seleccionar..."
                    options={optionsStatus}
                    styles={customSelectNewDark}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        status: e.value,
                      })
                      setLoading(true)
                    }
                    }
                  />
                </Col>

                <Col xs={8} style={{ display: "contents", }}>
                  <div className="d-flex align-items-center">
                    <form onSubmit={handleSumit}>
                      <input
                        className={tableStyles.searchUsersAlt}
                        type="text"
                        placeholder="Buscar..."
                        value={filters.search}
                        style={{
                          marginTop: '3px',
                          height: '25px'
                        }}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            search: e.target.value,
                          })
                        }
                      />
                      <button>
                        <img
                          src={Lupa}
                          alt="User icon"
                          className={`${tableStyles.iconSvgMargin2}`}
                          style={{ width: '1.4rem' }}
                          onClick={() => {
                            setTrigger(trigger + 1);
                            setFilters({ ...filters, page: 1 });
                            setLoading(true)
                          }}
                        />
                      </button>
                    </form>
                  </div>
                </Col>
              </>
            )
          }

          {
            myPermission()?.create && (
              <Col className="d-flex justify-content-end align-items-center">
                {myPermission()?.create ?
                  <div
                    className="d-flex justify-content-end groupAddButton align-items-center"
                    style={{ width: '160px' }}
                    onClick={() =>
                      setModalAlertcontrol({
                        ...modalAlertcontrol,
                        show: true,
                        edit: true,
                        creat: true,
                      })
                    }                          >
                    <label htmlFor="newAccident" className="darkGray fw-bold">
                      Crear servicio
                    </label>
                    <button
                      className="addCheckButton mx-2"
                      style={{ marginBottom: "1.5px" }}
                    />
                  </div>
                  :
                  ""
                }
              </Col>
            )
          }
        </Row>
        {
          myPermission()?.read && (
            <GenericTable fontFamilyTable={'fontFamilyTable'} headers={header} dark={true}>
              {renderList()}
            </GenericTable>
          )
        }
        <div className={IndividualStyles.bottom}>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {storage.billingReducer.services_ADM?.length ? filters.page : ""}
              {" de "}
              {Math.ceil(
                storage.billingReducer?.services_ADM_total / filters.perpage
              )
                ? Math.ceil(
                  storage.billingReducer?.services_ADM_total / filters.perpage
                )
                : ""}{" "}
              ({storage.billingReducer?.services_ADM_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={storage.billingReducer?.services_ADM_total}
              pageRangeDisplayed={5}
              onChange={(e) => { setFilters({ ...filters, page: e }); if (filters.page !== e) { setLoading(true) } }}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Services;
