import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import React, { useEffect, useState } from "react";

import { getUsers, getTotalIndex, update_roles_users} from "../../actions/userActions";
import { accounts_get } from "../../actions/cuentasActions"
import { getProfiles, getProfilesFromAccount } from "../../actions/profileActions";

import { connect, useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import popupStyles from "../Layouts/popupEdEdit.module.css";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import CustomPopup from "../Popup/customPopup";
import {customSelectStyle} from "../Layouts/react-select-custom";
import Select from "react-select";
import { Checkbox } from '@material-ui/core';
import GestionCheckbox from "../../assets/img/icons/GestionCheck.svg";
import GestionCheckFalse from "../../assets/img/icons/GestionCheckFalse.svg";
import { Button } from 'react-bootstrap';
import GestionUsers from './GestionUsers';
import { useHistory } from "react-router-dom";


function GestionRoles (){
    const counter = useSelector(state => state);
    const history = useHistory();
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    
    const [trigger, setTrigger] = useState(1);
    const [profile, setProfile] = useState(1);
    const [userChanges, setUserChanges] = useState( [] );

    useEffect(() => {
        if (counter.loginReducer.currentAccount?.profile?.prefijo != "ADM") {
            history.push("/categoryselect")
        }
        
        dispatch( getTotalIndex(  ()=>setUserChanges([])  ) );
        dispatch(getProfiles());

    },[trigger]);

    const perfilCheckHandle = (user, profile, account) => {
        let userChangesTemp = userChanges;
        let exists = userChangesTemp.find(x => (x.id_profile == profile) && (x.id_user == user));
        if(exists){
            userChangesTemp = userChangesTemp.filter(x => x != exists);
        }else{
            userChangesTemp.push({
                id_profile: profile,
                id_account: account,
                id_user: user
          });
        }
        setUserChanges(userChangesTemp);
    }

    const perfilCheckStatus = (elem, compareProfile) => {
        let aaa = !!elem.profile_list.find(assoc => (assoc.id_profile === compareProfile) && (assoc.id_account ===  counter.loginReducer.currentAccount.id));
        let bbb = !!userChanges.find(assoc => ((assoc.id_profile === compareProfile) && (assoc.id_user === elem.id) && (assoc.id_account === counter.loginReducer.currentAccount.id)) );

        return aaa != bbb;
    }

    const renderUser = (elem) => {
        return <div key={elem.id} className="customTabs__Row">
            <div className="customTabs__CellFirst">
                <Checkbox 

                    checked={perfilCheckStatus(elem, profile)}
                    onChange={()=>perfilCheckHandle(elem.id, profile, counter.loginReducer.currentAccount.id )}

                    id={"check"+elem.id} 
                    name={"check"+elem.id} 
                    color="primary"
                    style={ {padding:0} }
                    disabled={counter.userReducer.loading}
                    
                />
            </div>
            <div className="customTabs__Cell customTabs__Cell--border">
                {elem.first_name +" "+ elem.second_name +" "+ elem.first_surname +" "+ elem.second_surname }
            </div>
            <div className="customTabs__Cell">{elem.doc_num}</div>
        </div>
    }

    const listUsers = (thelist) => {
        let elemMap;
        if (Array.isArray(counter.userReducer.users) ) {
            elemMap = counter.userReducer.users.map((elem) => {
              return renderUser(elem);
            });

          }
        return <div className="customTabs_scroll">{elemMap}</div>;
    }

    const listProfileTab = () => {
        let elemMap;
        if (Array.isArray(counter.profileReducer.profiles) ) {
            elemMap = counter.profileReducer.profiles.map((elem, id) => {
              return <Nav.Item key={elem.id} className={id===0?"customTabs__First":""}>
                <Nav.Link eventKey={elem.id} onClick={()=>setProfile(elem.id)}>{elem.nombre}</Nav.Link>
            </Nav.Item>
            });

          }
        return elemMap;
    }

    const listProfilePane = () => {
        let elemMap;
        if (Array.isArray(counter.profileReducer.profiles) ) {
            elemMap = counter.profileReducer.profiles.map((elem) => {
              return <TabPane key={elem.id} eventKey={elem.id} className="customTabs__Area">
                    {listUsers( elem.user_list )}
                </TabPane>
            });

          }
        return elemMap;
    }



    const validateMinimum = () => {

        let atLeastOne=false;
        if (Array.isArray(counter.userReducer.users) ) {
            atLeastOne = counter.userReducer.users.reduce((accumulator, elem, i) => {
                return (accumulator || perfilCheckStatus(elem, 1));
            }, atLeastOne);

          }
        return atLeastOne;
    }

    const buttonCancel = () => {
        setTrigger(trigger+1);
    }

    const buttonOk = () => {
        if(validateMinimum()){
            dispatch(  
                update_roles_users( 
                    userChanges, 
                   ()=>{
                       setUserChanges([]);
                       MySwal.fire({
                          icon:'success',
                          title:'¡Roles Actualizados!',
                          text:'Se actualizaron los roles de los usuarios'
                        })
                    }
                )  
            );
        }else{
            MySwal.fire({
                icon:'error',
                title:'Error',
                text:'Debe estar resgistrado por lo menos un usuario administrador en cada cuenta'
              });
        }
    }

    return (
        <div className={tableStyles.container}>
            <h1 className={tableStyles.title}>Gestión de Roles</h1>

            <GestionUsers></GestionUsers>

            <div className="customTabs__Border customTabs__Container">
                <TabContainer id="left-tabs-example" defaultActiveKey={profile}>
                    <Nav className="flex-row customTabs">
                        {listProfileTab()}
                    </Nav>
                    <TabContent className="customTabs__relative">
                        {listProfilePane()}
                    </TabContent>
                </TabContainer>
            </div>

            <div className={`customTabs__Footer`}>
                <Button onClick={()=>buttonOk()}>Guardar</Button>
                <Button onClick={()=>buttonCancel()}>Limpiar</Button>
            </div>
        </div>
    )

}

export default GestionRoles;