import React from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import backArrow from "../../../assets/img/icons/iconBack.svg";
import { useState } from "react";
import styles from "../../CategorySelect/CategorySelect.module.scss";
import { useGetMethod } from "../../../Hooks/useFetch";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loader, message, validateEmptyString } from "../../../helpers/helpers";
const NewRol = () => {
  const location = useLocation();
  const store = useSelector((state) => state);
  const history = useHistory();
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const modulesInfo = location?.state?.data ?? {};
  const dataEdit = location?.state?.dataEdit ?? {};
  const isEditingAgain = location?.state?.isEditingAgain ?? false;
  const isEditing = location?.state?.isEditing ?? isEditingAgain ?? false;
  const idProfile = dataEdit.id ?? location?.state?.idProfile;
  const goBackFunction = () => {
    history.push("/administracion/GestionDePermisos");
  };
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "gespermiss");

  const [data, setData] = useState({
    rolName: "",
    modules: [],
  });

  const [modulesEditData, setModulesEditData] = useState([])

  const { results: moduleListResults, load: moduleListLoader, trigger: getModuleListMethod } = useGetMethod();
  const { results: moduleListEditResults, load: moduleListEditLoader, trigger: getModuleEditListMethod } = useGetMethod();
  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/categoryselect");
    }
    let fullData = Array.isArray(modulesInfo.modules) ? modulesInfo : { rolName: "", modules: [] };
    setData(fullData);

    getModuleListMethod({
      url: "/security/modules/",
      objFilters: { eaccount: idEnterprise },
      token: token,
      doAfterSuccess: (moduleRes) => {
        if (isEditing || isEditingAgain) {
          getModuleEditListMethod({
            url: "/security/permissions_by_profile/",
            objFilters: { eaccount: idEnterprise, idProfile: idProfile },
            token: token,
            doAfterSuccess: (dataEditRes) => {
              let tempArr = modulesInfo?.modules?.length > 0 ? [...modulesInfo?.modules] : [...data.modules]
              if (!modulesInfo?.modules?.length > 0) {
                let tempArrModulesList = []
                moduleRes.results.forEach(category =>{
                  category?.categories?.forEach(mod=>{
                    tempArrModulesList.push(mod)
                  })
                })
                tempArrModulesList.forEach(mod => {
                  if (dataEditRes.results.find((x) => x.id === mod.id)) {
                    if (!data.modules.some((x) => x.id === mod.id)) {
                      tempArr.push(mod)
                    }
                  }
                });
              }
              setData({ rolName: dataEdit.rolName ?? modulesInfo?.rolName, modules: tempArr })
              setModulesEditData(dataEditRes?.results)
            },

          });
        }
      }
    });



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEnterprise]);
  const onChangeCheckBox = (e, value) => {
    if (e.target.checked) {
      let tempArr = [...data?.modules];
      tempArr.push(value);
      setData({ ...data, modules: tempArr });
    } else {
      let filterArr = data?.modules.filter((x) => x.id !== value.id);
      setData({ ...data, modules: filterArr });
    }
  };

  const goNextFunction = () => {
    let validations = validateEmptyString([{ field: data.rolName, message: "El nombre del rol es obligatorio para continuar" }]);

    let status;
    if (data.modules.length > 0) {
      status = true;
    } else {
      status = false;
      message("info", "Verificar módulos", "Debe seleccionar como mínimo un módulo para continuar");
    }

    if (validations.valid && status) {
      history.push({
        state: { data: data, dataEdit: modulesEditData, isEditing: isEditing, idProfile: dataEdit.id ?? idProfile, },
        pathname: "/administracion/GestionDePermisos/NuevoRol/Configuracion",
      });
    }
  };
  return (
    <>
      {(moduleListLoader || moduleListEditLoader) && loader}
      <div className="w-80 mx-4">
        <Row>
          <Col xs={10}>
            <h1 className={`${tableStyles.title} d-flex`}>
              <img
                onClick={() => goBackFunction()}
                src={backArrow}
                alt="icon"
                height={32}
                width={32}
                className={`mr-3 cursorPointer`}
                style={{ marginTop: "10px" }}
              />
              <div className="flex-column">
                <div>{isEditing || isEditingAgain ? "Editar rol" : "Nuevo rol"}</div>
                <div
                  className={`${tableStyles.f16} ${tableStyles.darkBlueText}  `}
                  style={{ fontWeight: "normal " }}
                >
                  Elige los módulos a los que tendrá acceso este rol
                </div>
              </div>
            </h1>
          </Col>
        </Row>
        <div className="mx-4">
          <Row className="">
            <div>
              <div className={`${tableStyles.cardAdminPortal} py-3 px-3 `}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Nombre del rol
                  <span className={"text-danger"}>*</span>
                </p>
                <input
                  defaultValue={data.rolName}
                  className={`register-inputs  text-secondary ${tableStyles.outlineNone}`}
                  name="position"
                  placeholder="Escribe..."
                  onChange={(e) => setData({ ...data, rolName: e.target.value })}
                />
              </div>
            </div>
          </Row>
          <Row className="my-4">
            {moduleListResults?.results?.length > 0 &&
              moduleListResults?.results?.map((category, ind) => {
               return(
                <>
                <div
                  className={`${tableStyles.f24} ${tableStyles.darkBlueText}  mb-2`}
                >
                  {category?.groupName}
                </div>
                <div>
                  <Row>
                  {category?.categories?.map((mod, i)=>{
                return (
                  <>
                    <Col
                      key={`Module${i} + ${mod?.id}`}
                      xs={6}
                    >
                      <Card className={`${styles.flex1} rounded-5 mb-2`}>
                        <Row>
                          <Col xs={3}>
                            <img
                              src={mod.image}
                              className={styles.classIcon}
                              alt="icon"
                              height={80}
                            />
                          </Col>
                          <Col xs={7}>
                            <p className={`my-auto ${styles.classTitle}`}>{mod.name ?? "-"}</p>
                            <p className={`my-auto ${styles.classSubTitle}`}>
                              {mod.subtitle?.length >= 55 ? mod.subtitle.slice(0, 55) ?? "-" : mod.subtitle ?? "-"}
                            </p>
                          </Col>
                          <Col
                            className="d-flex align-self-center "
                            xs={2}
                          >
                            <input
                              checked={data?.modules?.some((e) => e?.id === mod?.id)}
                              id={`checkBox-${mod.id}`}
                              key={`keyCheckbox-${mod.id}`}
                              onChange={(e) => onChangeCheckBox(e, mod)}
                              className={`border border-dark-blue form-check-input p1 check-dark-blue`}
                              type="checkbox"
                              style={{ width: "16px", height: "16px" }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </>
                );
                  })}
                  </Row>
                </div>
                </>
               )
              })}
          </Row>
          <Row>
            <div className={`customTabs__Footer mt-4 ml-1`}
              style={{ paddingRight: "13px" }}
            >
              <Button
                onClick={() => goNextFunction()}
                className={tableStyles.btnPrimary}
              >
                Continuar
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default NewRol;
