// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { Component, useState, useEffect } from "react";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import { Button, Col, Form, Row } from "react-bootstrap";
import ModalNew from "../../components/Layouts/ModalNew";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

// --------------- icons 💥  ----------------//
import Lupa from "../../assets/img/icons/lupa.svg";
import Excel from "../../assets/img/icons/excel.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import LupaClear from "../../assets/img/icons/lupaClear.svg";

//-------------- scss styles 😄   --------------
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Styles from "./Consultations.module.scss";


//-------------- actions 😄   --------------
import { useHistory } from "react-router-dom";
import { Title } from "../../shared";



function DeferralHistory(props) {

    const history = useHistory()
    const storage = useSelector((state) => state);
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(1);
    const [filters, setFilters] = useState({
        page: 1,
        perpage: 10,
        client: "",
        contract: "",
        status: "",
        sequence: "",
        date_from: "",
        date_until: "",
        resolution: "",
        range_from: "",
        range_until: ""
    });


    //-------------- first actions 😄   --------------

    // useEffect(() => {
    //     dispatch( getHistory(filters))
    // },[trigger, filters.page]);



    // --------------- Table 💥  ----------------//

    const header = [
        <div className={`col-1`}>No. de cuenta</div >,
        <div className={`col-1`}>No. de comprobante</div>,
        <div className={`col-2`}>Descripción</div>,
        <div className={`col-2`}>Centro de costo</div>,
        <div className={`col-2`}>Fecha</div>,
        <div className={`col-2`}>Debe</div>,
        <div className={`col-2`}>Haber</div>,
    ];

    const renderList = () => {
        let tempList = [];
        // if (Array.isArray(storage.accountingReducer.listUsers)) {
        //   storage.accountingReducer.listUsers.map((item, index) => {
        //     tempList.push(
        //       <section2 className={`d-flex`} key={index}>
        //         <div className="text-center col-1" ></div>
        //         <div className="text-center col-1" ></div>
        //         <div className="text-center col-3" ></div>
        //         <div className="text-center col-1" ></div>
        //         <div className="text-center col-1" ></div>
        //         <div className="text-center col-1" ></div>
        //         <div className="text-center col-1" ></div>
        //         <div className="text-center col-1" ></div>
        //         <div className="text-center col-2" ></div>
        //       </section2>
        //     );
        //   });
        // }
        return tempList;
    };

    // --------------- Functions 💥  ----------------//
    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }
    const handleSearch = (e) => {
        e.preventDefault();
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
    };

    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>

                <div className={tableStyles.title}>
                    <Title
                        title="Historial de diferidos"
                        onClickIcon={() => {
                            history.push('/contabilidad/inicio')
                        }}
                    />
                </div>

                <Row className="d-flex">

                    <Col xs={2}>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <label className={tableStyles.crudModalLabel}>Fecha desde</label>
                            <input className={IndividualStyles.registerInputsBlue}
                                type="date"
                            // value={filters.resolution}
                            // onChange={(e) =>
                            //     setFilters({
                            //     ...filters,
                            //     resolution: e.target.value,
                            //     })
                            // }
                            >

                            </input>
                        </form>
                    </Col>

                    <Col xs={2}>
                    <form  onSubmit={(e) => handleSearch(e)}>
                        <label className={tableStyles.crudModalLabel}>Hasta</label>
                        <input className={IndividualStyles.registerInputsBlue} 
                            type="date" 
                            // value={filters.resolution}
                            // onChange={(e) =>
                            //     setFilters({
                            //     ...filters,
                            //     resolution: e.target.value,
                            //     })
                            // }
                            >

                            </input>
                        </form>
                    </Col>

                    <Col xs={2}>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <label className={tableStyles.crudModalLabel}>Valor desde</label>
                            <NumberFormat
                                allowNegative={false}
                                style={{ padding: 0, color: "#58595B" }}
                                className={IndividualStyles.registerInputs}
                                name="valueNote"
                                thousandsGroupStyle="thousand"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"$"}
                            // value={creatEgress.amount}
                            // onValueChange={(values) => {
                            //     const { value } = values;
                            //     setcreatEgress({
                            //         ...creatEgress,
                            //         amount: value,
                            //     })
                            //     }}
                            ></NumberFormat>
                        </form>
                    </Col>

                    <Col xs={2}>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <label className={tableStyles.crudModalLabel}>Valor hasta</label>
                            <NumberFormat
                                allowNegative={false}
                                style={{ padding: 0, color: "#58595B" }}
                                className={IndividualStyles.registerInputs}
                                name="valueNote"
                                thousandsGroupStyle="thousand"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"$"}
                            // value={creatEgress.amount}
                            // onValueChange={(values) => {
                            //     const { value } = values;
                            //     setcreatEgress({
                            //         ...creatEgress,
                            //         amount: value,
                            //     })
                            //     }}
                            ></NumberFormat>
                        </form>
                    </Col>

                    <Col xs={3} style={{ alignSelf: "end" }}>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <label className={`${tableStyles.crudModalLabel}`}></label>
                            <input
                                className={Styles.inputSearch}
                                placeholder="Buscar..."
                                type="text"
                            // onChange={(e) => setInfo({
                            //     ...info,
                            //     search: e.target.value
                            // })}
                            ></input>
                        </form>
                    </Col>

                    <div className=" col display-grid  mr-2" style={{ alignContent: "end" }}>
                        <label className=""> <img className={`${tableStyles.cursorPointer}`} src={Lupa} /></label>

                    </div>

                </Row>



                <GenericTableScroll
                    headers={header}
                    dark={true}
                    body={renderList()}
                    typeHead={"2"}
                >
                </GenericTableScroll>




            </div>
        </>
    )

};
export default DeferralHistory;