import React, { Component } from "react";
import {Modal, Button} from "react-bootstrap";
import Cerrar from "../../assets/img/icons/xCuadroBlanco.svg";
import Close from "../../assets/img/icons/checkXblanco.svg";
import "../Layouts/modal.scss";
import { connect } from "react-redux";

function GenericModal (props) {
    return (
      <Modal
        // {...props}
        title = {props.title}
        show = {props.show}
        onHide ={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header >
          <div className="div-header-modal">
            <h2>{props.title}</h2>
            <img src={Close} alt="User icon" className="icons-svg close-icon-modal hoverPointer" onClick={props.onHide || props.toggleModal} />
          </div>
        </Modal.Header>
        <Modal.Body className= {props.class}>
      
          <div className="container">
           {props.children}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props.onHide && (<Button onClick={props.onHide}>Cancelar</Button>)}
          {props.confirmation && (<Button onClick={props.confirmation}>Guardar</Button>)}
        </Modal.Footer>
      </Modal>
    );

}


export default GenericModal;
