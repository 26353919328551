import React, { useState } from 'react'
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate'
import { Form } from 'react-bootstrap';
import styles from "../../components/Layouts/tableStyle.module.scss";
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import Select from "react-select";
import engraneGrisOscuro from "../../assets/img/icons/engraneGrisOscuro.svg";
import blueBallfrom from "../../assets/img/icons/blueBall.svg";
import questionBlueClear from "../../assets/img/icons/questionBlueClear.svg";
import modalsearch from "../../assets/img/icons/modalsearch.svg";
import moneySymbol from "../../assets/img/icons/moneySymbol.svg";
import addCheck from "../../assets/img/icons/add-check.svg";
import { useHistory } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';

const LensOrder = () => {

    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [activeModal, setActiveModal] = useState(false)

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (

        <>
            <OrdModal
                title="Solicitar descuento"
                show={activeModal}
                subtitle='Luz Marina Hernandez Paraíso'
                // btnYesName={"Aceptar"}
                btnNoName={false}
                size={"200"}
                onHide={() => setActiveModal(false)}
            // btnYesEvent={() => OnSubmitMarkPatient()}
            // btnNoEvent={() => setServices({ ...services, showImportance: false })}
            >
                <div>

                    <div className={`${styles.grid2} mb-2`}>
                        <Form.Group className=" text-start" controlId="">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`} data-lpignore="true">
                                <span>
                                    {" "}
                                    &nbsp;Valor inicial <span className={`${styles.ordOrangeText}`}></span>{" "}
                                </span>
                            </Form.Label>
                            <Form.Control
                                className={`ord-roundInput `}
                                // disabled
                                type="text"
                                placeholder="Escribe..."
                                // value={data.document}
                                onChange={(e) => {
                                    // setTriggerTable(triggerTable + 1);
                                }}

                            />
                        </Form.Group>

                        <Form.Group className=" text-start" controlId="">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`} data-lpignore="true">
                                <span>
                                    {" "}
                                    &nbsp;Valor final <span className={`${styles.ordOrangeText}`}></span>{" "}
                                </span>
                            </Form.Label>
                            <Form.Control
                                className={`ord-roundInput `}
                                // disabled
                                type="text"
                                placeholder="Escribe..."
                                // value={data.document}
                                onChange={(e) => {
                                    // setTriggerTable(triggerTable + 1);
                                }}

                            />
                        </Form.Group>
                    </div>

                    <div className={`${styles.grid2} mb-2`}>
                        <Form.Group className=" text-start" controlId="">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`} data-lpignore="true">
                                <span>
                                    {" "}
                                    &nbsp;Tipo<span className={`${styles.ordOrangeText}`}></span>{" "}
                                </span>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                // options={servicesAppOptions}
                                className="text-secondary "
                                placeholder={"Seleccione"}
                                styles={ordCustomSelect}
                                // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                onChange={(e) => {
                                    // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                }}
                            ></Select>
                        </Form.Group>

                        <Form.Group className=" text-start" controlId="">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`} data-lpignore="true">
                                <span>
                                    {" "}
                                    &nbsp;Cantidad <span className={`${styles.ordOrangeText}`}></span>{" "}
                                </span>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                // options={servicesAppOptions}
                                className="text-secondary "
                                placeholder={"Seleccione"}
                                styles={ordCustomSelect}
                                // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                onChange={(e) => {
                                    // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                }}
                            ></Select>
                        </Form.Group>
                    </div>

                    <div>
                        <Form.Group className=" text-start" controlId="">
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                <span>
                                    {" "}
                                    &nbsp;comentarios <span className={`${styles.ordOrangeText}`}></span>{" "}
                                </span>
                            </Form.Label>
                            <textarea
                                placeholder={"Escribir..."}
                                rows="25"
                                cols="55"
                                style={{ height: "4rem" }}
                                // value={data.mecSuggestions}
                                className={`text-secondary ord-roundInput w-100`}
                            // onChange={(e) =>
                            //   setData({ ...data, mecSuggestions: e.target.value })
                            // }
                            ></textarea>
                        </Form.Group>
                    </div>

                </div>
            </OrdModal>

            <OrdGenericTemplate
                title="Orden de lente de contacto"
                showBackArrow={true}
                backArrowAction={() => history.push("/admision/ordenesProveedores")}
            >


                <div className='w-70 mt-4' style={{ fontFamily: 'PT Sans Caption' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: '1', display: 'flex', columnGap: '10px', marginLeft: '5px', alignItems: 'center' }}>
                            <img className={`${styles.svgTurquoiseFilter}`} height={10} src={blueBallfrom} alt="engraneGrisOscuro " />
                            <span className={`${styles.infoPatient}`}>Información del paciente</span>
                        </div>
                        <div style={{ cursor: 'pointer' }}>
                            <img
                                onClick={() => setActiveModal(true)}
                                // onMouseOver={handleClick} 
                                height={30}
                                src={engraneGrisOscuro}
                                alt="engraneGrisOscuro"
                            />
                            {/* <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <div style={{ padding: '5px' }}>Solicitar descuento</div>
                            </Popover> */}

                        </div>
                    </div>

                    <div className={`${styles.bgBox} mt-3 mb-4`} >

                        <div className={`${styles.grid2} mb-2`}>
                            <Form.Group className=" text-start" controlId="">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                    <span>
                                        {" "}
                                        &nbsp;ID - Paciente <span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    // options={servicesAppOptions}
                                    className="text-secondary "
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                    // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                    onChange={(e) => {
                                        // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                    }}
                                ></Select>
                            </Form.Group>
                            <Form.Group className=" text-start" controlId="">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                    <span>
                                        {" "}
                                        &nbsp;Aseguradora <span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    // options={servicesAppOptions}
                                    className="text-secondary "
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                    // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                    onChange={(e) => {
                                        // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                    }}
                                ></Select>
                            </Form.Group>
                        </div>

                        <div className={`${styles.grid4} mb-2`}>
                            <Form.Group className=" text-start" controlId="">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                    <span>
                                        {" "}
                                        &nbsp;Contrato <span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    // options={servicesAppOptions}
                                    className="text-secondary "
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                    // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                    onChange={(e) => {
                                        // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                    }}
                                ></Select>
                            </Form.Group>
                            <Form.Group className=" text-start" controlId="">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                    <span>
                                        {" "}
                                        &nbsp;Grupo de ingreso <span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    // options={servicesAppOptions}
                                    className="text-secondary "
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                    // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                    onChange={(e) => {
                                        // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                    }}
                                ></Select>
                            </Form.Group>

                            <Form.Group className=" text-start input-wrapper" controlId="" >
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`} style={{ paddingRight: '20%' }}>
                                    <span>
                                        {" "}
                                        &nbsp;Autorización<span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <div>

                                    <Form.Control
                                        className={`ord-roundInput `}
                                        // disabled
                                        type="text"
                                        placeholder="Escribe..."
                                        // value={data.document}
                                        onChange={(e) => {
                                            // setTriggerTable(triggerTable + 1);
                                        }}

                                    />
                                    <img className='input-icon' src={questionBlueClear} alt="" />
                                </div>

                            </Form.Group>
                            <Form.Group className=" text-start" controlId="">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`} data-lpignore="true">
                                    <span>
                                        {" "}
                                        &nbsp;Valor autorizado <span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    className={`ord-roundInput `}
                                    // disabled
                                    type="text"
                                    placeholder="Escribe..."
                                    // value={data.document}
                                    onChange={(e) => {
                                        // setTriggerTable(triggerTable + 1);
                                    }}

                                />
                            </Form.Group>
                        </div>

                    </div>

                    <div>
                        <div className='mb-2' style={{ flex: '1', display: 'flex', columnGap: '10px', marginLeft: '5px', alignItems: 'center' }}>
                            <img className={`${styles.svgTurquoiseFilter}`} height={10} src={blueBallfrom} alt="engraneGrisOscuro " />
                            <span className={`${styles.infoPatient}`}>Lente de contacto</span>
                        </div>

                        <div className={`${styles.grid2} mb-2`} style={{ alignItems: 'flex-start' }}>
                            <Form.Group className=" text-start input-wrapper" controlId="" >
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`} style={{ paddingRight: '20%' }}>
                                    <span>
                                        {" "}
                                        &nbsp;Adaptación de lentes<span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <div>

                                    <Form.Control
                                        className={`ord-roundInput `}
                                        // disabled
                                        type="text"
                                        placeholder="Escribe..."
                                        // value={data.document}
                                        onChange={(e) => {
                                            // setTriggerTable(triggerTable + 1);
                                        }}

                                    />
                                    <img className='input-icon-large' src={modalsearch} alt="" />
                                </div>

                            </Form.Group>

                            <Form.Group className=" text-start" controlId="">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                    <span>
                                        {" "}
                                        &nbsp;Proveedor <span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <textarea
                                    placeholder={"Escribir..."}
                                    rows="25"
                                    cols="55"
                                    style={{ height: "4rem" }}
                                    // value={data.mecSuggestions}
                                    className={`text-secondary ord-roundInput w-100`}
                                // onChange={(e) =>
                                //   setData({ ...data, mecSuggestions: e.target.value })
                                // }
                                ></textarea>
                            </Form.Group>
                        </div>

                        <div className={`${styles.grid2} mb-2`}>
                            <Form.Group className=" text-start" controlId="">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                    <span>
                                        {" "}
                                        &nbsp;Laboratorio <span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    // options={servicesAppOptions}
                                    className="text-secondary "
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                    // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                    onChange={(e) => {
                                        // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                    }}
                                ></Select>
                            </Form.Group>
                            <Form.Group className=" text-start" controlId="">
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                    <span>
                                        {" "}
                                        &nbsp;Tipo de lente <span className={`${styles.ordOrangeText}`}></span>{" "}
                                    </span>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    // options={servicesAppOptions}
                                    className="text-secondary "
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                    // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                    onChange={(e) => {
                                        // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                    }}
                                ></Select>
                            </Form.Group>
                        </div>

                    </div>

                    <div className='mt-4' style={{ width: '48%' }}>
                        <div className={`m-0 ${styles.ordDarkBlueText} ${styles.f14} d-flex`}>
                            <div style={{ flex: '1' }} className={`${styles.fs17}`}>Valor del lente</div>
                            <div className={`${styles.fs17}`}>$ 0</div>
                        </div>

                        <div className={`m-0 ${styles.ordDarkBlueText} ${styles.f14} d-flex`}>
                            <div className={`${styles.fs17}`} style={{ flex: '1' }}>Abono</div>
                            <div className={`${styles.fs17}`}>$ 0</div>
                        </div>

                        <div className={`m-0 ${styles.ordDarkBlueText} ${styles.f14} d-flex`}>
                            <div className={`${styles.fs17}`} style={{ flex: '1' }}>descuento</div>
                            <div className={`${styles.fs17}`}>$ 0</div>
                        </div>

                        <div className={`m-0 ${styles.ordDarkBlueText} ${styles.f14} d-flex`}>
                            <div className={`${styles.fs17}`} style={{ flex: '1', fontWeight: 'bold' }}>Total a pagar</div>
                            <div className={`${styles.fs17}`} style={{ fontWeight: 'bold' }}>$ 0</div>
                        </div>
                    </div>


                    <div className='mt-4 mb-2' style={{ flex: '1', display: 'flex', columnGap: '10px', marginLeft: '5px', alignItems: 'center' }}>
                        <img className={`${styles.svgTurquoiseFilter}`} height={10} src={blueBallfrom} alt="engraneGrisOscuro " />
                        <span className={`${styles.infoPatient}`}>Recaudo</span>
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '150px 1fr', boxShadow: '0px 0px 15px #ccc', borderRadius: '10px', padding: '10px 10px 10px 0px' }}>
                        <div style={{ boxShadow: '0px 0px 5px #ccc', margin: '10px 20px 10px 20px', borderRadius: '10px' }}>
                            <img className='w-100' src={moneySymbol} alt="moneySymbol" />
                        </div>

                        <div>

                            <div className={`${styles.grid2} mb-2`}>
                                <Form.Group className=" text-start" controlId="">
                                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                        <span>
                                            {" "}
                                            &nbsp;Tipo de recaudo <span className={`${styles.ordOrangeText}`}></span>{" "}
                                        </span>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        // options={servicesAppOptions}
                                        className="text-secondary "
                                        placeholder={"Seleccione"}
                                        styles={ordCustomSelect}
                                        // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                        onChange={(e) => {
                                            // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                        }}
                                    ></Select>
                                </Form.Group>

                                <Form.Group className=" text-start" controlId="">
                                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`} data-lpignore="true">
                                        <span>

                                        </span>
                                    </Form.Label>
                                    <Form.Control
                                        className={`ord-roundInput `}
                                        // disabled
                                        type="number"
                                        placeholder="Escribe..."
                                        // value={data.document}
                                        onChange={(e) => {
                                            // setTriggerTable(triggerTable + 1);
                                        }}

                                    />
                                </Form.Group>
                            </div>
                            <div className={`${styles.grid4} mb-2`}>
                                <Form.Group className=" text-start" controlId="">
                                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`} data-lpignore="true">
                                        <span>
                                            {" "}
                                            &nbsp;Vuelto total <span className={`${styles.ordOrangeText}`}></span>{" "}
                                        </span>
                                    </Form.Label>
                                    <Form.Control
                                        className={`ord-roundInput `}
                                        // disabled
                                        type="text"
                                        placeholder="Escribe..."
                                        // value={data.document}
                                        onChange={(e) => {
                                            // setTriggerTable(triggerTable + 1);
                                        }}

                                    />
                                </Form.Group>

                                <Form.Group className=" text-start" controlId="">
                                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                        <span>
                                            {" "}
                                            &nbsp;¿Donar el vuelto? <span className={`${styles.ordOrangeText}`}></span>{" "}
                                        </span>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        // options={servicesAppOptions}
                                        className="text-secondary "
                                        placeholder={"Seleccione"}
                                        styles={ordCustomSelect}
                                        // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                        onChange={(e) => {
                                            // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                        }}
                                    ></Select>
                                </Form.Group>

                                <Form.Group className=" text-start" controlId="">
                                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                        <span>
                                            {" "}
                                            &nbsp;¿cuanto? <span className={`${styles.ordOrangeText}`}></span>{" "}
                                        </span>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        // options={servicesAppOptions}
                                        className="text-secondary "
                                        placeholder={"Seleccione"}
                                        styles={ordCustomSelect}
                                        // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                        onChange={(e) => {
                                            // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                        }}
                                    ></Select>
                                </Form.Group>

                                <Form.Group className=" text-start" controlId="">
                                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                                        <span>
                                            {" "}
                                            &nbsp;¿Vuelto entregado? <span className={`${styles.ordOrangeText}`}></span>{" "}
                                        </span>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        // options={servicesAppOptions}
                                        className="text-secondary "
                                        placeholder={"Seleccione"}
                                        styles={ordCustomSelect}
                                        // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                        onChange={(e) => {
                                            // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                        }}
                                    ></Select>
                                </Form.Group>
                            </div>


                        </div>
                    </div>

                    <div className={`${styles.grid2} mt-4`} style={{ display: 'grid', }}>
                        <div className='d-flex' style={{ columnGap: '10px' }}>
                            <span style={{ color: '#6E6F7C', fontWeight: 'bold' }}>Agregar otro recaudo</span>
                            <img src={addCheck} alt="addCheck" />
                        </div>

                        <div className='d-flex mb-4' style={{ columnGap: '100px', marginLeft: 'auto' }}>
                            <span style={{ fontWeight: 'bold' }} className={`${styles.fs17} ${styles.ordDarkBlueText}`}>Faltante</span>
                            <span style={{ fontWeight: 'bold' }} className={`${styles.fs17} ${styles.ordDarkBlueText}`}>$ 0.0</span>

                        </div>
                    </div>

                    <div className='mt-3 mb-5' style={{ display: 'flex', justifyContent: 'end', columnGap: '10px' }}>
                        <div className={`${styles.btnCancel}`}>Cancelar</div>
                        <div className={`${styles.btnSave}`}>Guardar</div>

                    </div>

                </div>


            </OrdGenericTemplate>
        </>


    )
}

export default LensOrder
