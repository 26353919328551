import React, { useEffect, useState } from "react";
import { convertMoneyFormatNew, formatToRcSelect, loader, message, swalConfirm, validateEmptyString } from "../../helpers/helpers";
import { useSelector } from "react-redux";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Col, Form, Row } from "react-bootstrap";
import ordComponentStyles from "../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss";
import CustomPopupStyles from "../../../src/components/Popup/customPopup.module.scss";
import threeDots from "../../assets/img/icons/threeDots.svg";
import check2 from "../../assets/img/icons/check2.svg";
import focaEyeBlue from "../../assets/img/icons/focaEyeBlue.svg";
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import { OrdGenericTemplate } from './../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from './../../OrderingModule/OrderingComponents/OrdModal';
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import OrdTable from './../../OrderingModule/OrderingComponents/OrdTable';
import { useHistory } from 'react-router';
import { OrdAccordion } from "../../OrderingModule/OrderingComponents/OrdAccordion";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from "react-select";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg'
import ADD from '../../assets/img/icons/ADD.svg'
import moment from "moment";


const OrdersPage = () => {

    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const idAccount = store.loginReducer.currentAccount.id;
    const history = useHistory();

    //  --------------------------- States 🪔 ------------------------
    const [showAssigmentModal, setShowAssigmentModal] = useState({
        show: false,
        isEditing: false,
    });

    const [data, setData] = useState({
        idAssigment: '',
        personal: {
            name: '',
            locations: []
        },
        position: '',
        enable: true
    });
    const [activeModal, setActiveModal] = useState(false)

    const [tabActice, setTabActice] = useState('OrdersPage')

    const [dataTable, setDataTable] = useState()

    const [trigger, setTrigger] = useState(0);

    const [filters, setFilters] = useState({
        // canDescription: "",
        // eaccount: idAccount,
        assigmentEnabled: false
    });

    //  --------------------------- Petitions  🤙 ------------------------------
    const [assignmentResults, setAssignmentResults] = React.useState({
        results: [
            {
                "canApp": 1,
                "canDescription": "sd",
                "canId": 8,
                "canLocked": 1,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "Solicitud de cupo adicional anulada",
                "canId": 9,
                "canLocked": 1,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "Paciente no se present\u00f3",
                "canId": 10,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "El paciente se tuvo que ir",
                "canId": 19,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "El m\u00e9dico se tuvo que ir",
                "canId": 20,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "hola mundo 2",
                "canId": 21,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            }
        ]
    })
    // const { results: anulationResults, load: AnulationsLoader, trigger: getAnulations } = useGetMethod();
    const { load: updateMotiveLoader, trigger: updateMotive } = usePostMethod();
    const { load: createMotiveLoader, trigger: createNewMotive } = usePostMethod();

    // useEffect(() => {
    //     getAnulations({
    //         url: "/medical/generals/cancellationReason/",
    //         objFilters: filters,
    //         token: token,
    //     });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [trigger]);

    //  --------------------------- Modal -------------------------
    const openNewAssigment = () => {
        setData({
            idAssigment: '',
            personal: {
                name: '',
                locations: []
            },
            position: '',
            enable: true
        })
        setShowAssigmentModal({ ...showAssigmentModal, show: true, isEditing: false });
    };

    const openEditAssigment = (item) => {
        setShowAssigmentModal({ ...showAssigmentModal, show: true, isEditing: true });
        setData({ ...item })
    };
    const closeAssigmentModal = () => {
        setShowAssigmentModal({ ...showAssigmentModal, show: false, isEditing: false });
        // setData({ ...data, canDescription: "", canApp: 0, canSurgery: 0, canId: "" });
    };
    //  --------------------------- Methods ------------------------------

    const editAssigment = (item) => {
        // updateMotive({
        //     url: "/medical/generals/cancellationReason/",
        //     token: token,
        //     method: "PUT",
        //     body: data,
        //     doAfterSuccess: () => {
        //         setTrigger(trigger + 1);
        //         closeAssigmentModal();
        //     },
        //     succesAction: () =>
        //         message("success", `<span class=${tableStyles.ordClearBlueText}>Cambios guardados</span>`, `${data.canDescription}`),
        // });

        const filter = dataExample.map(e => {
            if (e.idAssigment === data.idAssigment) {
                return { ...data }
            } else {
                return e
            }
        })
        setDataExample(filter)
        message("success", `<span class=${tableStyles.ordClearBlueText}>Asignacion Editada</span>`)
        closeAssigmentModal();
    };

    const createAssigment = () => {
        // createNewMotive({
        //     url: "/medical/generals/cancellationReason/",
        //     token: token,
        //     method: "POST",
        //     body: data,
        //     doAfterSuccess: () => {
        //         setTrigger(trigger + 1);
        //         closeAssigmentModal();
        //     },
        //     succesAction: () => message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo creado</span>`, `${data.canDescription}`),
        // });
        setDataExample(e => ([...e, data]))
        message("success", `<span class=${tableStyles.ordClearBlueText}>Asignacion creada</span>`)
        closeAssigmentModal();

    };

    const disabledAssigment = (item) => {
        // swalConfirm({
        //     title: `¿Está seguro?`,
        //     text: `Se inhabilitara el motivo, "paciente no atendido"`,
        //     confirmButtonText: `Si, continuar`,
        //     doAfterConfirm: () => {
        //         const filterReasonDisable = assignmentResults.results.map(state => state?.canId === item.canId ? { ...state, canEnabled: false } : state)
        //         setAssignmentResults

        //             ({ results: filterReasonDisable })
        //         message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo inhabilitado</span>`)

        //         // updateMotive({
        //         //     url: "/medical/generals/cancellationReason/",
        //         //     token: token,
        //         //     method: "PUT",
        //         //     body: {
        //         //         canEnabled: 0,
        //         //         canId: item.canId,
        //         //     },
        //         //     doAfterSuccess: () => {
        //         //         setTrigger(trigger + 1);
        //         //         closeMotiveModal();
        //         //     },
        //         //     succesAction: () =>
        //         //         message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo inhabilitado</span>`),
        //         // });
        //     },
        // });

        const filter = dataExample.map(e => {
            if (e.idAssigment === item.idAssigment) {
                return { ...item, enable: false }
            } else {
                return e
            }
        })
        setDataExample(filter)
        message("success", `<span class=${tableStyles.ordClearBlueText}>Asignacion inhabilitada</span>`)
        closeAssigmentModal();
    };

    // -------------------------------- Validations -----------------------
    const validationRequest = (item) => {

        if (!data.position) {
            return message("warning", `<span class=${tableStyles.ordClearBlueText}>El Cargo es obligatorio</span>`)
        }

        if (!data.personal.name) {
            return message("warning", `<span class=${tableStyles.ordClearBlueText}>El Usuario es obligatorio</span>`)
        }

        showAssigmentModal.isEditing ? editAssigment(item) : createAssigment();

    };


    // ----------------------------- Headers table --------------------------------

    const headers = [
        {
            title: "Tipo",
            className: "text-center px-2",
        },
        {
            title: "No. Orden",
            className: "text-center px-2",
        },
        {
            title: "Fecha",
            className: "text-center px-2",
        },
        {
            title: "Paciente",
            className: "text-start px-2",
        },

        {
            title: "Empresa",
            className: "text-start px-2",
        },
        {
            title: "Contrato",
            className: "text-start px-2",
        },
        {
            title: "Total",
            className: "text-end px-2",
        },
        {
            title: "Vuelto",
            className: "text-start px-2 text-center",
        },
        {
            title: "Estado",
            className: "text-center"
        },
        {
            title: "",
            className: "text-center "
        },
    ];

    //-------------- useEffect 🍕   --------------------------

    //  useEffect(() => {
    //     setFilters({ ...filters, page: 1 })
    //     getListApp({
    //       url: "/medical/generals/appointments/",
    //       objFilters: filters,
    //       token: token,
    //       doAfterSuccess: (result) => { formatData(result.results) }
    //     });
    //   }, [filters.doctor, filters.company, filters.status, trigger]);

    //   useEffect(() => {
    //     getListApp({
    //       url: "/medical/generals/appointments/",
    //       objFilters: filters,
    //       token: token,
    //       doAfterSuccess: (result) => { formatData(result.results) }
    //     });
    //   }, [filters.page]);


    const [dataExample, setDataExample] = useState([
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: moment(new Date()).format("MM/DD/YYYY"),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
    ])

    useEffect(() => {
        formatData(dataExample)
    }, [showAssigmentModal, dataExample])

    //-------------- format data to table 🍕   --------------------------
    const formatData = (array) => {
        let tempList = [];
        if (Array.isArray(array)) {
            array?.forEach((e, index) => {

                tempList.push([
                    {
                        text: <><img height={25} src={focaEyeBlue} alt="focaEyeBlue" /></>,
                        className: "text-center px-2"
                    },
                    {
                        text: <>{e?.nOrden}</>,
                        className: "text-center px-2"
                    },
                    {
                        text:
                            <>
                                <div>{e?.fecha}</div>
                                <div>{moment(new Date()).format('h:mm: a')}</div>
                            </>,
                        className: "text-center px-2"
                    },
                    {
                        text: <><div>{e?.paciente}</div> <div>C.C. {e?.identificacion}</div></>,
                        className: "text-start px-2"
                    },
                    {
                        text: <>{e?.empresa}</>,
                        className: "text-start px-2"
                    },
                    {
                        text: <>{e?.contrato}</>,
                        className: "text-start px-2"
                    },
                    {
                        text: <>{convertMoneyFormatNew({ textNumber: e?.total }) || '-'}</>,
                        className: "text-end px-2"
                    },
                    {
                        text: <div><img src={check2} height={20} alt="check2" /></div>,
                        className: "text-start px-2 text-center"
                    },
                    {
                        text: (
                            <div className="text-success" style={{ background: '#1987540a', padding: '5px', borderRadius: '10px' }}>
                                {e?.estado}
                            </div>
                        ),
                        className: "text-start px-2 text-center"
                    },
                    {
                        text: <CustomPopupExtend
                            triggerSrc={threeDots}
                            center
                            extraButtons={
                                [
                                    {
                                        text: "Ver orden",
                                        class: CustomPopupStyles.popUpGeneric,
                                        event: () => {
                                            openEditAssigment(e);
                                            setShowAssigmentModal(e => ({ ...e, isEditing: true, show: true }))
                                        },
                                    },
                                    {
                                        text: "Ver factura",
                                        class: CustomPopupStyles.popUpGeneric,
                                        event: () => {
                                            openEditAssigment(e);
                                            setShowAssigmentModal(e => ({ ...e, isEditing: true, show: true }))
                                        },
                                    },
                                    {
                                        text: "Dar vuelto",
                                        class: CustomPopupStyles.popUpGeneric,
                                        event: () => {
                                            setActiveModal(true)
                                        },
                                    },
                                    {
                                        text: "Anular",
                                        class: CustomPopupStyles.popUpGeneric,
                                        event: () => {
                                            disabledAssigment(e)
                                        },
                                    },
                                    {
                                        text: "Entregar",
                                        class: CustomPopupStyles.popUpGeneric,
                                        event: () => {
                                            disabledAssigment(e)
                                        },
                                    },
                                ]
                            }
                        />,
                        className: "text-center"
                    },

                ]);
            });
        }
        setDataTable(tempList);
        // return tempList;
    };


    const optionsPosition = [
        { value: 'coordinador', label: 'coordinador' },
        { value: 'auxiliar', label: 'auxiliar' },
        { value: 'ingeniero', label: 'ingeniero' },
        { value: 'tecnico', label: 'tecnico' },
    ]

    const optionsPersonal = [
        { value: 'jhan', label: 'jhan' },
        { value: 'walter', label: 'walter' },
        { value: 'emanuel', label: 'emanuel' },
        { value: 'laura', label: 'laura' },
        { value: 'valentina', label: 'valentina' },
        { value: 'leidy', label: 'leidy' }
    ]

    const optionsLocations = [
        { value: 'sur', label: 'sur' },
        { value: 'norte', label: 'norte' },
        { value: 'foca', label: 'foca' },
        { value: 'optica', label: 'optica' },
    ]

    const handleFilterPatient = (e) => {
        setFilters({ ...filters, page: 1 })
        setTrigger(trigger + 1)
        e.preventDefault()
    }

    return (
        <div>

            <OrdModal
                title="Entrega de vuelto"
                show={activeModal}
                btnYesName={"Aceptar"}
                size={"200"}
                onHide={() => setActiveModal(false)}
                btnYesEvent={() => { }}
            // btnNoEvent={() => setServices({ ...services, showImportance: false })}
            >
                <div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <div className={`${tableStyles.infoPatient2}`}> Vuelto pendiente</div>
                        <Form.Group className=" text-start" controlId="">

                            <Form.Control
                                className={`ord-roundInput `}
                                // disabled
                                type="text"
                                placeholder="Escribe..."
                                // value={data.document}
                                onChange={(e) => {
                                    // setTriggerTable(triggerTable + 1);
                                }}

                            />
                        </Form.Group>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <div className={`${tableStyles.infoPatient2}`}>Monto a entregar</div>
                        <Form.Group className=" text-start" controlId="">

                            <Form.Control
                                className={`ord-roundInput `}
                                // disabled
                                type="text"
                                placeholder="Escribe..."
                                // value={data.document}
                                onChange={(e) => {
                                    // setTriggerTable(triggerTable + 1);
                                }}

                            />
                        </Form.Group>
                    </div>

                    <div className={`${tableStyles.grid2} mb-2`}>
                        <Form.Group className=" text-start" controlId="">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`} data-lpignore="true">
                                <span>
                                    {" "}
                                    &nbsp;¿Donar vuelto? <span className={`${tableStyles.ordOrangeText}`}></span>{" "}
                                </span>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                // options={servicesAppOptions}
                                className="text-secondary "
                                placeholder={"Seleccione"}
                                styles={ordCustomSelect}
                                // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                onChange={(e) => {
                                    // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                }}
                            ></Select>
                        </Form.Group>


                        <Form.Group className=" text-start" controlId="">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`} data-lpignore="true">
                                <span>
                                    {" "}
                                    &nbsp;¿Cuanto?<span className={`${tableStyles.ordOrangeText}`}></span>{" "}
                                </span>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                // options={servicesAppOptions}
                                className="text-secondary "
                                placeholder={"Seleccione"}
                                styles={ordCustomSelect}
                                // value={servicesAppOptions.find((x) => x.value === filterAppointment.id)}
                                onChange={(e) => {
                                    // setFilterAppointment({ ...filterAppointment, srvId: e.value,  });
                                }}
                            ></Select>
                        </Form.Group>


                    </div>



                </div>
            </OrdModal>

            <OrdTable
                // accordion
                data={dataExample}
                isEnable={filters.assigmentEnabled}
                headers={headers}
                body={dataTable}
                className={`${tableStyles.ordTableShadow}`}
                paginate={{
                    activePage: filters.page,
                    totalPages: 3,
                    perPage: filters.perpage,
                    pageRangeDisplayed: 3,
                    onChangePage: async (e) => {
                        setFilters({ ...filters, page: e })
                    },
                    showTextDetails: true
                }}
            />

        </div>
    )
}

export default OrdersPage