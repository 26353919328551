import React, { Component, useEffect, useState } from "react";
import Trash from "../../assets/img/icons/canecaNueva.svg";
import modalsearch from "../../assets/img/icons/modalsearch.svg";
import Atras from "../../assets/img/icons/atras-icon.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import deepcopy from "deepcopy";
import ModalNew from "../../components/Layouts/ModalNew";
import {
  getLetters,
  PayLegalCollection,
  sendLetter,
} from "../../actions/receiptOfInvoicesActions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { message } from "../../helpers/helpers";

function PrelegalCollection(props) {
  const storage = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showletter, setShowletter] = useState(false);
  const [previewStatus, setPreviewStatus] = useState(false);
  const [invoices, setInvoices] = useState(deepcopy(props.invoices));
  const idEnterprise = storage.loginReducer.currentAccount.id;
  const collectionLetter = storage.invoiceReducer?.collectionLetter;
  const [isLoading, setIsLoading] = useState(false);

  const [changeLetter, setChangeLetter] = useState({
    value: "",
    name: "",
  });

  const [chooseLetter, setChooseLetter] = useState({});

  const [showTemplate, setShowTemplate] = useState(false);

  const [template, setTemplate] = useState("");
  const handleRadio = (elem) => {
    setChooseLetter(elem);
  };

  const deleteInvoice = (id) => {
    let tempInvoices = deepcopy(invoices);
    let found = tempInvoices.filter((x) => x.id != id);
    setInvoices(found);
  };

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };

  const header = [
    <th className="text-center">No. Factura </th>,
    <th className="text-center">Saldo</th>,
    <th className="text-center">Vencimiento</th>,
    <th className="text-center"></th>,
  ];

  const header2 = [
    <th className="col-1"></th>,
    <th className="text-center mr-3">Nombre </th>,
  ];
  useEffect(() => {
    dispatch(
      getLetters({
        eaccount: idEnterprise,
      })
    );
  }, []);

  const body =
    collectionLetter?.length > 0 &&
    collectionLetter.map((elem, i) => {
      return (
        <tr>
          <td className="text-center">
            <input
              type="radio"
              //   value="1"
              className="cursorPointer"
              name="c1"
              //   checked={elem?.id === chosenLetter?.id ? true : false}
              onChange={(e) => handleRadio(elem)}
            />
          </td>
          <td className="text-start" style={{ paddingLeft: "40px" }}>
            {elem.name ? elem.name : "-"}
          </td>
        </tr>
      );
    });

  const renderList = () => {
    let table = [];

    if (Array.isArray(invoices)) {
      table = invoices.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">
            <td className="text-uppercase">{x.sequence}</td>
            <td>{"$2" + numberWithCommas(x.pending)}</td>
            <td>{x.expiration_period}</td>
            <td className="text-center">
              <img
                style={{ width: "15px" }}
                src={Trash}
                onClick={() => deleteInvoice(x.id)}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const sendToPay = () => {
    dispatch(
      PayLegalCollection(
        {
          client: props.invoices[0].client,
          invoices: invoices,
          letter: chooseLetter.id,
          preview: previewStatus,
          eaccount: storage.loginReducer.currentAccount.id,
          user: storage.loginReducer.user_data.id
        },
        () => {
          props.setDetails({ show: false });
        }
      )
    );
  };

  //   const selectLetter = () => {
  //     setChooseLetter({
  //       value: changeLetter.value,
  //       name: changeLetter.name,
  //     });
  //     setShowletter(false);
  //   };

  const closeLetter = () => {
    setShowletter(false);
    setChangeLetter({
      value: "",
      name: "",
    });
  };

  const getTemplate = async () => {
    setIsLoading(true);
    const result = await sendLetter({
      client: props.invoices[0].client,
      invoices: invoices,
      letter: chooseLetter.id,
      preview: previewStatus,
      eaccount: storage.loginReducer.currentAccount.id,
      preview: true,
    });

    if (result.success) {
      setIsLoading(false);
      setShowTemplate(true);
      setTemplate(result.results);
    } else {
      setIsLoading(false);
      return message(
        "warning",
        "Error",
        " No se ha podido obtener la plantilla"
      );
    }
  };

  return (
    <>
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        {!!storage.invoiceReducer.loading_PayLegalCollection && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        )}

        <div className="d-flex">
          <img
            className={`${tableStyles.title} mr-3`}
            src={Atras}
            onClick={() => props.setshowReceipt({ show: false })}
          />
          <h1 className={tableStyles.title}>Cobro pre-jurídico</h1>
        </div>
        <div className="d-flex gap-3">
          <div>
            &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
            <input
              className={IndividualStyles.registerInputsGris}
              type="text"
              defaultValue={
                props.invoices[0]?.clientNit +
                "-" +
                props.invoices[0]?.clientName
              }
              readOnly
            ></input>
          </div>
        </div>
        <GenericTable headers={header} dark={true}>
          {renderList()}
        </GenericTable>
        <div className="col-2 display-grid mr-3">
          <label className={tableStyles.crudModalLabel}>Carta de cobro</label>
          <input
            className={`${IndividualStyles.registerInputs}  ${tableStyles.labelIntoButton} bg-img-prejuridico cursorPointer`}
            type="text"
            defaultValue={(chooseLetter.name = !"" ? chooseLetter.name : "")}
            readOnly
            onClick={() => setShowletter(true)}
          />
        </div>
        <div style={{ marginLeft: "auto", marginTop: "40px" }}>
          <Button
            className={tableStyles.buttonExtra3}
            onClick={() => sendToPay()}
            style={{ display: "block", alignItems: "center" }}
            disabled={!!chooseLetter.name == "" || !!(invoices?.length <= 0)}
          >
            Enviar cobro
          </Button>
        </div>
      </div>

      <ModalNew
        title="Cartas de cobro"
        show={showletter}
        size="400"
        btnYesName="Seleccionar"
        btnYesEvent={() => closeLetter()}
        hideCancelButton={true}
        onHide={() => closeLetter()}
        extraButtons={[
          {
            name: "Vista previa",
            icon: modalsearch,
            event: () => getTemplate(),
          },
        ]}
      >
        <GenericTable
          headers={header2}
          children={body}
          dark={false}
        ></GenericTable>
      </ModalNew>

      <ModalNew
        show={showTemplate}
        size="700"
        hideCancelButton={true}
        onHide={() => setShowTemplate(false)}
        title="ㅤ"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: template,
          }}
        ></div>
      </ModalNew>

      {isLoading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
    </>
  );
}
export default PrelegalCollection;
