import React, { useEffect, useState } from "react";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { useDispatch, useSelector } from "react-redux";
import Search from "../../assets/img/icons/searchFileIcon.svg"
import ModalDocuments from "../../components/TableIndividualAuction/IndividualDocuments";
import NumberFormat from "react-number-format";
import {
    getListCompanies,
    getListContracts,
    getSites,
    getOneListCompanies,
    generateCaption,
    getOneListContract
} from "../../actions/billingActions";
import Adjuntar from "../../assets/img/icons/adjuntarMini.svg";
import Dropzone from 'react-dropzone';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Attach from "../../assets/img/icons/insertar.svg";
import { MAX_SIZE_FILE } from "../../helpers/constants";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Title } from "../../shared";


const MULTIPLE_REGEX_VALID_FILE = /^.+\.(([pP][dD][fF]))|(xlsx|xls|csv)|(doc|docx)$/;
const type_file = "csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, \
                  application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, \
                  application/pdf"

function convertBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        const MySwal = withReactContent(Swal);
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

function CapitationInvoice(props) {
    const history = useHistory()
    const storage = useSelector((state) => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const [triggerSelect, setTriggerSelect] = useState(0);

  const [info, setInfo] = useState({
    client: '',
    contract: '',
    observations: '',
    eaccount: storage.loginReducer.currentAccount.id,
    site: '',
    user: storage.loginReducer.user_data.id,
    file64: '',
    fileName: '',
    copayment: 0,
    type_doc: '',
    contractType: 1,
    ungroup: true
  });

    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    };


    useEffect(() => {
        dispatch(getSites({ eaccount: storage.loginReducer.currentAccount.id }));
    }, []);

    useEffect(() => {
        dispatch(getListContracts(info));
        dispatch(getOneListCompanies(info));
    }, [info.client]);

    useEffect(() => {
        dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
        dispatch(getOneListContract(info));
    }, [info.contract]);

    ///TODAY
    var currDate = new Date().toISOString().substr(0, 10)


    let optionsCompanies = [{ key: "default", value: "", label: "Seleccionar..." }];
    if (Array.isArray(storage.billingReducer.companies)) {
        storage.billingReducer.companies.map((item) => {
            optionsCompanies.push({
                value: item.id,
                label: item.copyName,
                key: item.id + "clients",
            });
        });
    }

    let optionsContracts = [{ key: "default", value: "", label: "Seleccionar..." }];
    if (Array.isArray(storage.billingReducer.contracts)) {
        storage.billingReducer.contracts.map((item) => {
            optionsContracts.push({
                value: item.id,
                label: item.contract_name,
                key: item.id + "contracts",
            });
        });
    }

  let optionsSites = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.sites)) {
    storage.billingReducer.sites.forEach(item => {
      optionsSites.push({
        value: item.id,
        label: item.description,
        key: item.id + 'sites',
      });
    });
  }

  const buttonGenerate = () => {
    dispatch(
      generateCaption(info, () => {
        setInfo({
          client: '',
          contract: '',
          observations: '',
          eaccount: storage.loginReducer.currentAccount.id,
          site: '',
          user: storage.loginReducer.user_data.id,
          file64: '',
          fileName: '',
          copayment: '',
          type_doc: '',
          ungroup: true
        });
        setTriggerSelect(triggerSelect + 1);
      }),
    );
  };

    const validateFile = async (acceptedFile) => {
        let size = acceptedFile[0].size
        if (size > MAX_SIZE_FILE) {
            MySwal.fire({
                icon: 'error',
                title: "El archivo es demasiado pesado",
                footer: "El tamaño máximo permitido es de 5MB"
            })
        } if (new RegExp(MULTIPLE_REGEX_VALID_FILE).test(acceptedFile.type)) {
            MySwal.fire({
                icon: 'error',
                title: "El formato del archivo debe ser PDF, WORD, EXCEL",
                footer: "El tamaño máximo permitido es de 5MB"
            })
        }
    }

    const onChangeDoc = async (e) => {
        var f = e.target.files[0]
        var name_doc = f.name.split(".")[0];
        var type_doc = f.type;
        let encode = await convertBase64(e.target.files[0]);
        let file64 = encode.split(",")[1];
        setInfo({
            ...info,
            file64: file64,
            fileName: name_doc,
            type_doc: type_doc
        });
    }

    return (
        <>
            <div 
                className={` ml-5 ${tableStyles.container}`} 
                style={{ marginRight: "3rem", paddingTop:'30px' }}
            >
                {(storage.billingReducer.loading_generate_caption) &&
                    <div className="loading">
                        <Loader
                            type="Oval"
                            color="#003f80"
                            height={100}
                            width={100}
                        />
                    </div>
                }
                
                <Title
                    title="Factura de capitación"
                    className={'mb-2'}
                    onClickIcon={() => {
                        history.push('/facturacion/inicio')
                    }}
                />

                <div>
                    <div className="div gap-3 align-items-end">

                        <div className={tableStyles.containerSelect} >
                            &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                key={"client" + triggerSelect}
                                placeholder='Seleccionar...'
                                options={optionsCompanies}
                                onChange={(e) =>
                                    setInfo({
                                        ...info,
                                        client: e.value,
                                    })
                                }
                                styles={customSelectNewDark}
                            />
                        </div>

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Contrato</label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                key={"contract" + triggerSelect}
                                placeholder='Seleccionar...'
                                options={optionsContracts}
                                onChange={(e) =>
                                    setInfo({
                                        ...info,
                                        contract: e.value,
                                    })
                                }
                                styles={customSelectNewDark}
                            />
                        </div>

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Dirección</label>
                            <input className={IndividualStyles.registerInputs} type="text" style={{ background: "#F5F7FA", color: "#58595B" }}
                                placeholder="Seleccionar..."
                                defaultValue={storage.billingReducer.one_company?.address}
                                readOnly
                            />
                        </div>

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Sedes</label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                key={"site" + triggerSelect}
                                options={optionsSites}
                                placeholder='Seleccionar...'
                                onChange={(e) =>
                                    setInfo({
                                        ...info,
                                        site: e.value,
                                    })
                                }
                                styles={customSelectNewDark}
                            />
                        </div>

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Ciudad</label>
                            <input className={IndividualStyles.registerInputs} type="text" style={{ background: "#F5F7FA", color: "#58595B" }}
                                placeholder="Seleccionar..."
                                defaultValue={storage.billingReducer.one_company?.city}
                                readOnly
                            />
                        </div>



                    </div>

                    <div className="div gap-3 align-items-end">

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Fecha</label>
                            <input className={`${IndividualStyles.registerInputs} register-inputs`} style={{ background: "#F5F7FA", color: "#005DBF", fontWeight: "bold" }} type="date" value={currDate} readOnly />
                        </div>

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Anexar archivo</label>
                            <div className={tableStyles.deleteicon}>
                                {!!info.fileName ?
                                    <span onClick={e => setInfo({ ...info, fileName: "", file64: "", type_doc: "" })} >x</span>
                                    :
                                    ""
                                }
                                <Dropzone onDrop={acceptedFile => validateFile(acceptedFile)} accept={type_file}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section className={IndividualStyles.registerInputs}>
                                            <div {...getRootProps()} className="d-flex">
                                                <input {...getInputProps()} type="file" name="documents" onChange={onChangeDoc} accept={type_file} multiple={false} />
                                                {!!info.fileName ?
                                                    <p style={{ margin: '3px' }} className={`${IndividualStyles.stylesLabelGris} ${IndividualStyles.ellipsisNew} hoverPointer`}>{info.fileName}</p>
                                                    :
                                                    <p style={{ alignSelf: 'self-end', margin: 0, color: '#58595B' }} className={`${IndividualStyles.LabelIntoDropzone} hoverPointer `}>{"Cargar documento"}</p>
                                                }
                                                {<img src={Attach} alt="añadir" style={{ marginLeft: "auto", width: "18px", height: '15px', marginTop: '4px', cursor: 'pointer' }} />}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>

                        </div>

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Valor</label>
                            <input className={IndividualStyles.registerInputs} type="text" style={{ background: "#F5F7FA", color: "#58595B" }}
                                value={storage.billingReducer.one_contract?.totalPrice ? "$" + numberWithCommas(storage.billingReducer.one_contract?.totalPrice) : "$"}
                                readOnly
                            />
                        </div>

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Copago</label>
                            <NumberFormat
                                allowNegative={false}
                                style={{ padding: 0, color: "#58595B" }}
                                className={IndividualStyles.registerInputs}
                                name="valueNote"
                                thousandsGroupStyle="thousand"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={'$'}
                                value={info.copayment}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    setInfo({
                                        ...info,
                                        copayment: value,
                                    })
                                }}
                                min={1}
                            >
                            </NumberFormat>
                        </div>

                        <div className={tableStyles.containerSelect}>

                            &nbsp;<label className={tableStyles.stylesLabel}>Valor a pagar</label>
                            <input className={IndividualStyles.registerInputs} type="text" style={{ background: "#F5F7FA", color: "#58595B" }}
                                value={storage.billingReducer.one_contract?.totalPrice && !!(info.copayment >= 0) ? "$" + numberWithCommas(((Number(storage.billingReducer.one_contract.totalPrice)) - Number(info.copayment))) : "$"}
                                readOnly
                            />

                        </div>

                    </div>

                    <div style={{ maxWidth: '1065px' }}>
                        <div className="mt-4">
                            &nbsp;<label className={tableStyles.stylesLabel}>Descripción</label>
                            <textarea

                                className={IndividualStyles.textArea_new3}
                                rows="6" cols=""
                                value={info.observations}
                                placeholder="Escribir..."
                                onChange={(e) =>
                                    setInfo({
                                        ...info,
                                        observations: e.target.value,
                                    })
                                }
                            >

                            </textarea>
                        </div>
                        <div className={IndividualStyles.bottomRow} >
                            <Button
                                className={`${tableStyles.buttonExtra3} d-block`}
                                style={{ padding: '8px', border: '0px' }}
                                onClick={() => buttonGenerate()}
                                disabled={!!(info.value == "") || !!(info.site == "") || !!(info.file64 == "") || !!(info.description == "")}
                            >
                                Generar factura
                            </Button>
                            {/* <Button
                        className={tableStyles.buttonExtra1}
                    >
                        <img src={Search} alt="my image" /> <span></span> Vista previa
                    </Button> */}
                        </div>
                    </div>
                </div>
                <div>
                </div>


            </div>


        </>
    )

};
export default CapitationInvoice;