import React, { useEffect, useState } from "react";
import backArrow from "../../assets/img/icons/atras-icon.svg";
import { useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { Col, Row, Button } from "react-bootstrap";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";

import {
  expRegList,
  isEmptyOrUndefined,
  message,
  validExpReg,
} from "../../helpers/helpers";
import Loader from "react-loader-spinner";
import { createNewResolution, updateResolution } from "../../actions/bankNoteActions";
import moment from "moment";
import { customSwaltAlert } from "../../helpers";
import { Title } from "../../shared/Title";

export const NewResolution = () => {
  const location = useLocation();
  let isEditing = location?.state?.isEditing;
  let billInfo = location?.state?.billInfo;
  const store = useSelector((state) => state);
  const history = useHistory();
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [isLoading, setIsLoading] = useState(false);
  const token = store.loginReducer.Authorization;
  const [inputs, setInputs] = useState({
    eaccount: idEnterprise,
    nbr: "",
    sequence: "",
    authDate: "",
    dueDate: "",
    prefix: "",
    origin: "",
    end: "",
    user: idAccount,
    type: undefined,
  });
  const types = [
    { value: "", label: "Seleccionar..." },
    { value: "Habilita", label: "Habilita" },
    { value: "Autoriza", label: "Autoriza" },
  ];
  // //console.log(inputs);
  // //console.log("billInfo=>", billInfo);
  useEffect(() => {
    if (isEditing) {
      setInputs({
        id: billInfo.id,
        eaccount: idEnterprise,
        nbr: billInfo?.nbr,
        sequence: billInfo?.sequence,
        authDate: billInfo?.authDate,
        dueDate: billInfo?.dueDate,
        prefix: billInfo?.prefix,
        origin: billInfo?.origin,
        end: billInfo?.end,
        user: idAccount,
        type: billInfo?.type,
      });
    }
  }, [location]);

  const handleBack = () => {
    history.push("/administracion/Facturacion/ResolucionesFacturacion");
  };

  const handleSubmit = async () => {

    const validateInputDateEnd = moment(inputs.authDate).format('YYYYMMDD')
    const maxDate = moment(inputs.dueDate).format('YYYYMMDD')

    if (
      isEmptyOrUndefined(inputs.nbr) ||
      isEmptyOrUndefined(inputs.sequence) ||
      isEmptyOrUndefined(inputs.authDate) ||
      isEmptyOrUndefined(inputs.dueDate) ||
      isEmptyOrUndefined(inputs.origin) ||
      isEmptyOrUndefined(inputs.end)
    ) {

      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor llene los campos obligatorios',
        showCancelButton: false
      })

    }

    if (maxDate < validateInputDateEnd) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'La fecha de finalización no puede ser menor a la fecha de aprobación',
        showCancelButton: false
      })
    }

    try {
      setIsLoading(true);
      const result = isEditing ? await updateResolution(inputs, token) : await createNewResolution(inputs, token);
      if (result.success) {
        setIsLoading(false);
        setInputs({
          id: "",
          eaccount: idEnterprise,
          nbr: "",
          sequence: "",
          authDate: "",
          dueDate: "",
          prefix: "",
          origin: "",
          end: "",
          user: idAccount,
          type: undefined,
        });
        handleBack()
        const messageText = `"La resolución se ${isEditing ? 'actualizó' : 'creó'} exitosamente"`
        const tittle = `"Resolución ${isEditing ? 'actualizada' : 'creada'}"`
        return message(
          "success",
          tittle,
          messageText, undefined, true
        );
      } else {
        setIsLoading(false);
        return message("error", "Ha ocurrido un error", result.message, undefined, true);
      }
    } catch (error) {
      setIsLoading(false);
      //console.log(error.message)
      message("error", "Error", error.message, undefined, true);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      <div className={tableStyles.container} style={{paddingTop:'30px'}}>
        <div className={tableStyles.tableArea}>

          <Title
            title={isEditing ? "Editar resolución" : "Nueva resolución"}
            onClickIcon={handleBack}
          />

          <Row>
            <Col xs={12}>
              {" "}
              <p className={`${tableStyles.crudModalLabel} `}>Prefijo</p>
              <input
                onChange={(e) =>
                  setInputs({ ...inputs, prefix: e.target.value })
                }
                value={inputs.prefix}
                type="text"
                placeholder="Escribe..."
                className="register-inputs text-primary "
                name="position"
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Factura actual
                <span className={"text-danger"}>*</span>
              </p>
              <input
                onChange={(e) => {
                  if (
                    validExpReg(e.target.value, expRegList.number) ||
                    e.target.value === ""
                  ) {
                    setInputs({ ...inputs, sequence: e.target.value });
                  }
                }}
                value={inputs.sequence}
                type="number"
                placeholder="Escribe..."
                className="register-inputs text-primary "
                name="position"
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Primera factura
                <span className={"text-danger"}>*</span>
              </p>
              <input
                onChange={(e) =>
                  setInputs({ ...inputs, origin: e.target.value })
                }
                type="text"
                value={inputs.origin}
                placeholder="Escribe..."
                className="register-inputs text-primary "
                name="position"
              />
            </Col>
            <Col xs={12}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Última factura
                <span className={"text-danger"}>*</span>
              </p>
              <input
                onChange={(e) => setInputs({ ...inputs, end: e.target.value })}
                type="text"
                value={inputs.end}
                placeholder="Escribe..."
                className="register-inputs text-primary "
                name="position"
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Número de resolución
                <span className={"text-danger"}>*</span>
              </p>
              <input
                onChange={(e) => setInputs({ ...inputs, nbr: e.target.value })}
                type="text"
                value={inputs.nbr}
                placeholder="Escribe..."
                className="register-inputs text-primary "
                name="position"
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Fecha de aprobación
                <span className={"text-danger"}>*</span>
              </p>
              <input
                onChange={(e) =>
                  setInputs({ ...inputs, authDate: e.target.value })
                }
                value={inputs.authDate}
                type="date"
                className="register-inputs"
                style={{ fontWeight: 'lighter' }}
                name="position"
              />

            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Fecha de fin
                <span className={"text-danger"}>*</span>
              </p>
              <input
                onChange={(e) =>
                  setInputs({ ...inputs, dueDate: e.target.value })
                }
                value={inputs.dueDate}
                type="date"
                min={inputs.authDate}
                className="register-inputs"
                name="position"
                style={{ fontWeight: 'lighter' }}
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Habilita o Autoriza
                <span className={"text-danger"}>*</span>
              </p>

              <Select noOptionsMessage={() => 'No hay datos'}
                className="text-secondary"
                onChange={(e) => setInputs({ ...inputs, type: e.value })}
                options={types}
                value={types.find((x) => x.value === inputs.type)}
                placeholder={"Seleccionar..."}
                styles={customSelectNewDark}
              ></Select>
            </Col>
            <Col xs={12} className={`pt-3 customTabs__Footer mt-2`}>
              <Button
                onClick={() => handleSubmit()}
                className={tableStyles.btnPrimary}
              // disabled={!isEmptyOrUndefined(inputs.colaborator) ? false : true}
              >
                {isEditing ? "Actualizar" : "Guardar"}
              </Button>

              <Button
                variant="outline-primary"
                onClick={() => handleBack()}
                className={tableStyles.btnSecondary}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
