import React, { Component } from "react";
import GenericTableNew from "../Layouts/GenericTableNew";
import ModalNew from "../Layouts/ModalNew";
import Pagination from "react-js-pagination";
import { getConCenter } from "../../actions/consumptionCenterActions";
import { getAllUsersNew } from "../../actions/userActions";
import { createCCenter } from "../../actions/consumptionCenterActions";
import { getNiif } from "../../actions/inventoryFamilyActions";
import { updateCCenter } from "../../actions/consumptionCenterActions";
import { activationDeactivation } from "../../actions/consumptionCenterActions";
import { get_active_functional } from "../../actions/functionalActions";
import { get_active_inventory } from "../../actions/inventoryFamilyActions";
import { costcenter_get } from "../../actions/costcenterActions";
import Trash from "../../assets/img/icons/canecaGris.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import Adding from "../../assets/img/icons/agregarEspecialidad.svg";
import Search from "../../assets/img/icons/lupa.svg";
import * as deepcopy from "deepcopy";
import { connect, useSelector } from "react-redux";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import {
  customSelectNew,
  customSelectNewDark
} from "../Layouts/react-select-custom";
import CustomPopup from "../Popup/customPopUpExtends";
import Select from "react-select";
import { Link } from "react-router-dom";
import IndividualStyles from "./consumptionCenter.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NumberFormat from "react-number-format";
import ProgressBar from "react-bootstrap/ProgressBar";
import Loader from "react-loader-spinner";
import { formatToRcSelect } from "../../helpers/helpers";
import { useEffect } from "react";
import { useGetMethod } from "../../Hooks/useFetch";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);
class TableConsumptionCenter extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;

  }
  componentDidMount() {
    this.props.getAllUsersNew(this.state.filters2);
    this.props.getConCenter(this.state.filters);
    this.props.get_active_functional();
    this.props.costcenter_get(this.state.filters2);
    this.props.get_active_inventory();
    this.props.getNiif(this.state.filters.account);
    this.setState(this.initialState);
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });

    this.props.getConCenter(this.state.filters);
  }

  handlePageChangeModal(pageNumber) {
    let { filters_modal } = this.state;
    filters_modal.page = pageNumber;
    this.setState({ filters_modal });
  }

  handleChange(event) {
    let { filters } = this.state;
    filters.search = event.target.value;
    this.setState({ filters });
  }

  handleClick() {
    this.props.getConCenter(this.state.filters);
  }

  toggleModal = (e) => {
    this.setState(this.initialState);
    this.setState({
      modal: !this.state.modal,
    });
  };

  get initialState() {
    return {
      titleModal: "Crear centro de consumo",
      filters: {
        page: 1,
        search: "",
        perpage: 10,
        account: this.props.currentAccount.id,
        eaccount: this.props.currentAccount.id,
      },
      filters2: {
        eaccount: this.props.currentAccount.id,
        active: 1,
      },
      filters_modal: {
        page: 1,
        perpage: 5,
      },
      users_label: "",
      functional_label: "",
      inventory_label: "",
      cost_label: "",
      niif_label: "",
      account_niif: 0,
      inventoryF: 0,
      costId: 0,
      combination: "",
      infoTable: false,
      consumption_comb: [],
      consumption_center: {
        description: "",
        users: undefined,
        functional_unit_id: [],
        consumption_combination: [],
        active: true,
        account: this.props.currentAccount.id,
        eaccount: this.props.currentAccount.id,
        annual_budget: 0,
        effective_start_date: "",
        effective_end_date: "",
        current_balance: 0,
      },
      // headerState: [
      //   // "Código",
      //   "Descripción",
      //   "Usuario Encargado",
      //   "est.",
      // "ㅤ"
      // ],
      headerState: [
        {
          tittle: "Descripción",
          className: "text-start px-2",
        },
        {
          tittle: "Usuario Encargado",
          className: "text-start",
        },
        {
          tittle: "Estado",
          className: "text-center w100",
        },
        {
          tittle: "ㅤ",
          className: "text-start",
        },
      ],
      headerModal: [
        `#`,
        "Centro de Costo",
        "Familia de Inventario",
        "Cuenta Niif",
        "",
      ],
      editRecord: false,
      detailsModal: false,
      modal: false,
    };
  }
  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "CCon"
    );
  onChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { consumption_center } = this.state;
    consumption_center[name] = value;
    this.setState({ consumption_center });
  };

  addingAdditional = (e) => {
    let { consumption_center, consumption_comb } = this.state;
    let add = {
      combination: this.state.combination,
    };

    if (
      !!this.state.niif_label === false ||
      !!this.state.cost_label === false ||
      !!this.state.inventory_label === false
    ) {
      return null;
    }

    if (Array.isArray(consumption_center["consumption_combination"])) {
      let alreadyExists = consumption_center["consumption_combination"].find(
        (x) =>
          x.cost_centers_id == this.state.costId &&
          x.id_inventory_family == this.state.inventoryF
      );
      if (!!alreadyExists) {
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "Esta combinación ya existe",
          showCancelButton: false
        });
        return null;
      }
    } else {
      return null;
    }

    let ids = {};
    let descs = {};


    ids.id_niif = this.state.account_niif;
    ids.cost_centers_id = this.state.costId;
    ids.id_inventory_family = this.state.inventoryF;
    descs.costs_desc = this.state.cost_label;
    descs.iF_desc = this.state.inventory_label;
    descs.niif_desc = this.state.niif_label;
    consumption_comb.push(descs);
    consumption_center["consumption_combination"].push(ids);
    this.setState({ consumption_center, consumption_comb });

  };

  addingAdditionalEdit = (e) => {
    let { consumption_center } = this.state;
    let add = {
      combination: this.state.combination,
    };

    if (
      !!this.state.account_niif === false ||
      !!this.state.cost_label === false ||
      !!this.state.inventory_label === false
    ) {
      return null;
    }

    if (Array.isArray(consumption_center["consumption_combination"])) {
      let alreadyExists = consumption_center["consumption_combination"].find(
        (x) =>
          x.cost_centers_id == this.state.costId &&
          x.id_inventory_family == this.state.inventoryF
      );
      if (!!alreadyExists) {
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "Esta combinación ya existe",
          showCancelButton: false,
        });
        return null;

      }
    } else {
      return null;
    }

    let ids = {};
    let descs = {
      cost_center_ref: {
        description: this.state.cost_label,
      },
      cost_centers_id: this.state.costId,
      inventory_Family_ref: {
        description: this.state.inventory_label,
      },
      id_niif: this.state.account_niif,
      id_inventory_family: this.state.inventoryF,
    };


    consumption_center["consumption_combination"].push(descs);
    this.setState({ consumption_center });

  };

  handleEditRecord = (consumo) => {
    let { modal, consumption_center, titleModal } = this.state;

    modal = !this.state.modal;

    consumption_center = deepcopy(consumo);

    this.props.getNiif(consumption_center.functional_ref?.account);
    titleModal = "Editar Centro de Consumo";
    this.setState({
      modal,
      infoTable: true,
      consumption_center,
      editRecord: true,
      titleModal,
    });


    this.props.costcenter_get();
  };
  handleShowDetails = (consumo) => {
    let { modal, consumption_center, titleModal } = this.state;
    modal = !this.state.modal;
    consumption_center = deepcopy(consumo);
    this.props.getNiif(consumption_center.functional_ref?.account);
    titleModal = "Detalle centro de Consumo";
    this.setState({
      modal,
      consumption_center,
      infoTable: true,
      detailsModal: true,
      titleModal,
    });
  };

  handleSubmit() {
    if (
      this.state.consumption_center.effective_start_date === "" ||
      this.state.consumption_center.effective_end_date === "" ||
      isNaN(this.state.consumption_center.annual_budget) ||
      this.state.consumption_center.annual_budget === 0
    ) {
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Debe ingresar los datos obligatorios para crear el centro de consumo.",
        showCancelButton: false,
      });
    } else {
      if (this.state.editRecord) {
        this.setState({
          modal: !this.state.modal,
        });

        return this.props.updateCCenter(
          this.state.consumption_center,
          this.state.filters
        );
      }
      this.props.createCCenter(
        this.state.consumption_center,
        this.state.filters
      );
      this.setState({
        modal: !this.state.modal,
      });
    }
  }

  renderConsumption(consumptions) {
    return (
      <tr
        key={consumptions.id}
        className="hover-table-row text-center align-middle"
      >
        {/* <td className="col-md-1">{consumptions.id}</td> */}
        <td className="col-md-6 text-start px-2">{consumptions.description}</td>
        <td className="col-md-5 text-start px-2"> {consumptions.user} </td>
        <td className="col-md-1 text-start px-2">
          <div className={tableStyles.groupElems}>
            <div
              className={`${consumptions.active
                ? tableStyles.greenState
                : tableStyles.redState
                } mx-0`}
            >{consumptions.active ? 'Habilitado' : 'Inhabilitado'}</div>
          </div>
        </td>
        <td>
          <CustomPopup
            triggerSrc={threeDots}
            noHover
            showEdit={this.myPermission()?.edit}
            showDetails={this.myPermission()?.read}
            showDetailsEvent={() => this.handleShowDetails(consumptions)}
            editClickEvent={() => this.handleEditRecord(consumptions)}
            showEnable={this.myPermission()?.edit}
            isEnabled={consumptions.active}
            enableClickEvent={() =>
              this.props.activationDeactivation(
                consumptions.id,
                consumptions.active,
                this.state.filters,
                consumptions.description
              )
            }
          />
        </td>
      </tr>
    );
  }

  changeSelect = (e) => {
    let { consumption_center } = this.state;
    consumption_center["users"] = e.value;

    this.setState({ consumption_center, users_label: e.description });
  };

  changeSelectFunctional = (e) => {
    let { consumption_center, consumption_comb } = this.state;
    consumption_center["functional_unit_id"] = e.value;
    consumption_center["consumption_combination"] = [];
    consumption_comb = [];

    this.setState({
      consumption_center,
      consumption_comb,
      functional_label: e.description,
    });

    let account_look = 0;
    let { account_niif } = this.state;

    if (this.props.functionals) {
      for (let m in this.props.functionals) {
        if (
          this.state.consumption_center.functional_unit_id ===
          this.props.functionals[m].id
        ) {
          account_look = this.props.functionals[m].account;
          account_niif = account_look;
        }
      }
    }

    //relevant to TS-208
    if (!!account_look) {
      this.props.getNiif(account_niif);
      this.setState({
        inventoryF: "",
        inventory_label: "",
        costId: "",
        cost_label: "",
        account_niif,
        niif_label: "",
      });
    } else {
      this.setState({
        inventoryF: "",
        inventory_label: "",
        costId: "",
        cost_label: "",
        account_niif: "",
        niif_label: "",
      });
    }
  };

  changeSelectInventory = (e) => {
    let { inventoryF } = this.state;
    inventoryF = e.value;

    this.setState({ inventoryF, inventory_label: e.description });
  };

  changeSelectCostCenter = (e) => {
    let { costId } = this.state;
    costId = e.value;

    this.setState({ costId, cost_label: e.description });
  };

  changeSelectNiif = (e) => {
    let { account_niif } = this.state;
    account_niif = e.value;

    this.setState({ account_niif, niif_label: e.value });
  };

  removeRowEdit(e) {
    let { consumption_center, consumption_comb } = this.state;
    consumption_comb.splice(e, 1);
    consumption_center["consumption_combination"].splice(e, 1);
    this.setState({ consumption_center, consumption_comb });

  }

  removeRow(e) {
    let { consumption_center, consumption_comb } = this.state;
    consumption_comb.splice(e, 1);
    consumption_center["consumption_combination"].splice(e, 1);
    this.setState({ consumption_center, consumption_comb });
  }

  toggleModal = (e) => {
    this.setState(this.initialState);
    this.setState({
      modal: !this.state.modal,
    });
  };

  renderAddConsumption = (center, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{center.costs_desc.length > 0 ? center.costs_desc : "-"}</td>
        <td>{center.iF_desc.length > 0 ? center.iF_desc : "-"}</td>
        <td>{center.niif_desc.length > 0 ? center.niif_desc : "-"}</td>
        <td>
          <img
            src={Trash}
            alt="trash"
            className="icons-popUp"
            onClick={() => this.removeRow(index)}
          />
        </td>
      </tr>
    );
  };

  renderEditConsumption = (center, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{center.cost_center_ref?.description}</td>
        <td>{center.inventory_Family_ref?.description}</td>
        <td>{center.id_niif}</td>
        <td>
          <img
            src={Trash}
            alt="trash"
            className="icons-popUp"
            onClick={
              !this.state.detailsModal ? () => this.removeRowEdit(index) : null
            }
          />
        </td>
      </tr>
    );
  };

  render() {
    let index = 0;

    let pageInitEdit =
      this.state.filters_modal.page * this.state.filters_modal.perpage -
      this.state.filters_modal.perpage;
    let pageEndEdit =
      this.state.filters_modal.page * this.state.filters_modal.perpage;
    //relevant to TS-208
    let auxiEdit = Array.isArray(
      this.state.consumption_center.consumption_combination
    )
      ? this.state.consumption_center.consumption_combination.slice(
        pageInitEdit,
        pageEndEdit
      )
      : [];

    let listAddEdit = auxiEdit.map((item, index) => {
      return this.renderEditConsumption(item, index);
    });

    let pageInit =
      this.state.filters_modal.page * this.state.filters_modal.perpage -
      this.state.filters_modal.perpage;
    let pageEnd =
      this.state.filters_modal.page * this.state.filters_modal.perpage;
    //relevant to TS-208
    let auxi = Array.isArray(this.state.consumption_comb)
      ? this.state.consumption_comb.slice(pageInit, pageEnd)
      : [];

    let listAdd = auxi.map((item, index) => {
      return this.renderAddConsumption(item, index);
    });
    let listConsumptions;

    const optionUsers = () => {
      let Users = [{ value: "", label: "Seleccionar..." }];

      // if (Array.isArray(this?.props?.newAllUsers)) {
      //   for (let m in this?.props?.newAllUsers) {
      //     // console.log("m=>",m)
      //     // let check = this?.props?.newAllUsers[m].profile_list.find(
      //     //   (x) =>
      //     //     (x.profile.nombre === "OPERATIVO" ||
      //     //       x.profile.nombre === "SUPERVISOR") &&
      //     //     x.id_account === this.props.currentAccount.id
      //     // );
      //     // if (!!check) {
      //     //   tempUsers.push(this.props.newAllUsers[m]);
      //     // }
      //   }

      //   tempUsers.map((elem) => {
      //     let temp = {
      //       value: elem.id,
      //       label: elem.first_name + " " + elem.first_surname,
      //       description: elem.first_name + " " + elem.first_surname,
      //     };
      //     Users.push(temp);
      //   });
      // }

      // console.log("this?.props?.newAllUsers?: ", this?.props?.newAllUsers)
      if (this?.props?.newAllUsers?.length > 0) {
        this?.props?.newAllUsers?.forEach((e) => {
          Users.push({ value: e.id, label: e.firstName + " " + e.secondName + " " + e.firstSurname + " " + e.secondSurname })
        })
      }
      return Users;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps


    const optionFunctional = () => {
      let selectedOptions = [];
      if (Array.isArray(this.props.functionals)) {
        const elem2 = this.props.functionals;

        elem2.map((elem) => {
          if (this.props.currentAccount.id == elem.entity_account) {
            let temp = {
              value: elem.id,
              label: elem.description,
              description: elem.description,
            };
            selectedOptions.push(temp);
          }
        });
      }

      return selectedOptions;
    };

    const optionInventory = () => {
      let selectedOptions = [];
      if (Array.isArray(this.props.activeInventory)) {
        const elem2 = this.props.activeInventory;

        elem2.map((elem) => {
          let temp = {
            value: elem.id,
            label: elem.description,
            description: elem.description,
          };
          selectedOptions.push(temp);
        });
      }

      return selectedOptions;
    };

    const optionNiif = () => {
      let selectedOptions = [];

      if (this.props.auxNiif !== undefined) {
        const elem2 = this.props.auxNiif;
        selectedOptions = elem2.map((elem) => {
          return {
            value: elem.complete_account,
            label: elem.complete_account + " : " + elem.description,
            description: elem.description,
          };
        });
      }

      return selectedOptions;
    };

    const optionCost = () => {
      let selectedOptions = [];
      let cost_center = [];
      if (Array.isArray(this.props.cost_center)) {
        const elem2 = this.props.cost_center;

        this.props.cost_center.map((cc) => {

          if (Array.isArray(cc.functional_units)) {
            cc.functional_units.map((fu) => {
              if (
                fu.id == this.state.consumption_center?.functional_unit_id &&
                fu.entity_account === this.props.currentAccount.id
              ) {
                cost_center.push(cc);
              }
            });
          }
        });
        selectedOptions = this.props.cost_center.map((elem) => {
          return {
            value: elem.id,
            label: elem.description,
            description: elem.description,
          };
        });
      }

      return selectedOptions;
    };

    if (this.props.consumption_center != undefined) {
      const consumption = this.props.consumption_center;
      listConsumptions = consumption.map((consumos) => {
        return this.renderConsumption(consumos);
      });
    }
    let renderHeaders = this.state.headerState.map((header) => {
      return (
        <th>
          <div className={`${header === 'est.' || header === '' ? tableStyles.groupElems : ''} ${header.className}`}>
            {header.tittle}
          </div>
        </th>
      )

    });
    const handleSumit = (e) => {
      e.preventDefault();
      this.props.getConCenter(this.state.filters);
    }

    let renderHeaderModal = this.state.headerModal.map((header) => {
      return (
        <th style={header === '#' ? { paddingLeft: '10px' } : {}} className={header === "est." || header === "" ? "w-1-rem" : ""}>
          {header}
        </th>
      );
    });

    return (
      <div className={tableStyles.container}>
        {this.props.loading && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        )}
        {this.props.ccCreateLoading && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        )}
        {!!this.myPermission()?.read ? (
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}>Centro de Consumo</h1>
            <div className={tableStyles.mainRow}>
              <div>
                {
                  this.myPermission()?.read && (
                  <form onSubmit={handleSumit}>
                    <input
                      className={tableStyles.searchUsersAlt}
                      type="text"
                      placeholder="Buscar..."
                      onChange={(e) => this.handleChange(e)}
                    />

                    <img
                      src={Search}
                      alt="Search icon"
                      style={{ cursor: "pointer" }}
                      className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                      onClick={(e) => this.handleClick(e)}
                    />
                  </form>
                  )
                }
              </div>

              {this.myPermission()?.create && (
              <div className={`${IndividualStyles.principalScreenBtns}`}>
                <Link
                  className={`text-decoration-none ${IndividualStyles.buttonCreatCount} groupAddButton align-items-center
                    `}
                  onClick={() => this.toggleModal(true)}
                  style={{
                    width: "max-content",
                    // "align-items": "flex-end"
                  }}
                >
                  <label htmlFor="newAccident" className="darkGray fw-bold">
                    Crear centro
                  </label>

                  <img
                    src={Adding}
                    alt="User"
                    className={`${IndividualStyles.btnNewHover} 
                  ${IndividualStyles.iconSvg}
                  `}
                    style={{}}
                  />
                </Link>
              </div>
              )} 
            </div>

            <ModalNew
              hideFooter={this.state.editRecord ? !this.myPermission()?.edit : !this.myPermission()?.create }
              title={this.state.titleModal}
              size="lg"
              show={this.state.modal}
              btnYesEvent={!this.state.detailsModal ? () => this.handleSubmit() : null}
              onHide={() => this.toggleModal(false)}
              headers={"body-modal"}
            >
              <div className="">
                <div className="row">
                  <div className={`col-md-4  `}>
                    <p className={`${IndividualStyles.crudModalBottomLabel}`}>Descripción
                      <span className="text-warning">*</span>
                    </p>
                    <input
                      placeholder="Escribir..."
                      className="register-inputs"
                      name="description"
                      type="text"
                      onChange={this.onChangeForm}
                      value={this.state.consumption_center.description || ''}
                      disabled={this.state.detailsModal}
                    />
                  </div>
                  <div className={`col-md-4 `}>
                    <p className={`${IndividualStyles.crudModalBottomLabel}`}>Encargado
                      <span className="text-warning">*</span>
                    </p>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //defaultValue={putState.account}
                      defaultValue={optionUsers().find((option) => option?.value == this.state.consumption_center.users)}
                      name="users_label"
                      onChange={(e) => this.changeSelect(e)}
                      options={optionUsers()}
                      placeholder="Seleccionar..."
                      isDisabled={this.state.detailsModal}
                      styles={customSelectNewDark}
                    />
                  </div>
                  <div className={`col-md-4 `}>
                    <p className={`${IndividualStyles.crudModalBottomLabel}`}>Unidades Funcionales
                      <span className="text-warning">*</span>
                    </p>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //defaultValue={putState.account}
                      defaultValue={optionFunctional().find((option) => option?.value == this.state.consumption_center.functional_unit_id) || ''}
                      name="functional_label"
                      onChange={(e) => this.changeSelectFunctional(e)}
                      options={[{label: 'Seleccionar...', value:''},...optionFunctional()]}
                      placeholder="Seleccionar..."
                      isDisabled={this.state.detailsModal}
                      styles={customSelectNewDark}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={`col-md-4 `}>
                    <p className={`${IndividualStyles.crudModalBottomLabel}`}>Familia de Inventario
                      <span className="text-warning">*</span>
                    </p>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      key={"SelectInventory" + this.state.inventoryF}
                      defaultValue={optionInventory().find((option) => option?.value == this.state.inventoryF) || ''}
                      name="inventory_label"
                      onChange={(e) => this.changeSelectInventory(e)}
                      options={[{label: 'Seleccionar...', value:''},...optionInventory()]}
                      placeholder="Seleccionar..."
                      isDisabled={this.state.detailsModal}
                      styles={customSelectNew}
                    />
                  </div>

                  <div className={`col-md-4 `}>
                    <p className={`${IndividualStyles.crudModalBottomLabel}`}>Centro de Costos
                      <span className="text-warning">*</span>
                    </p>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      key={"SelectCostCenter" + this.state.costId}
                      defaultValue={optionCost().find((option) => option?.value === this.state.costId) || ''}
                      name="cost_label"
                      onChange={(e) => this.changeSelectCostCenter(e)}
                      options={[{label: 'Seleccionar...', value:''},...optionCost()]}
                      placeholder="Seleccionar..."
                      isDisabled={this.state.detailsModal}
                      styles={customSelectNew}
                    />
                  </div>
                  <div className={`col-md-4 `}>
                    <p className={`${IndividualStyles.crudModalBottomLabel}`}>Cuenta Niif
                      <span className="text-warning">*</span>
                    </p>
                    <div className="flex-between align-items-center">
                      <Select noOptionsMessage={() => 'No hay datos'}
                        key={"SelectNiif" + this.state.niif_label}
                        defaultValue={optionNiif().find((option) => option?.value == this.state.niif_label) || ''}
                        name="cost_label"
                        onChange={(e) => this.changeSelectNiif(e)}
                        options={[{label: 'Seleccionar...', value:''},...optionNiif()]}
                        placeholder="Seleccionar..."
                        isDisabled={this.state.detailsModal}
                        className="niifSelectMod"
                        styles={customSelectNew}
                      />
                      <img
                        style={{ marginTop: '0.2rem' }}
                        height={'20px'}
                        src={Adding}
                        alt="agregar"
                        className="icon-consumption"
                        onClick={this.state.infoTable ? () => this.addingAdditionalEdit() : () => this.addingAdditional()}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">

                    {
                      this.myPermission()?.read && (
                      <GenericTableNew fontFamilyTable={'fontFamilyTable'} headers={renderHeaderModal}>{this.state.infoTable ? listAddEdit : listAdd}</GenericTableNew>
                      )
                    }

                    {
                      this.myPermission()?.read && (
                      <Pagination
                        activePage={this.state.filters_modal.page}
                        itemsCountPerPage={this.state.filters_modal.perpage}
                        totalItemsCount={
                          this.state.infoTable
                            ? this.state.consumption_center.consumption_combination.length
                            : this.state.consumption_comb.length
                        }
                        pageRangeDisplayed={this.state.filters_modal.perpage}
                        onChange={this.handlePageChangeModal.bind(this)}
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext}
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                      />
                      )
                    }


                  </div>
                </div>
                <div className="row">
                  <label className={`${IndividualStyles.subtitle}`}>Presupuesto anual</label>
                  <div className={`col-md-4  `}>
                    <p className={`${IndividualStyles.crudModalBottomLabel}`}>
                      Fecha inicial de vigencia
                      <span className="text-warning">*</span>
                    </p>
                    <input
                      style={{ color: "#01A0F6", fontWeight: 'lighter' }}
                      name="effective_start_date"
                      type="date"
                      className="register-inputs"
                      max={this.state.consumption_center.effective_end_date}
                      onChange={this.onChangeForm}
                      value={this.state.consumption_center.effective_start_date}
                      disabled={this.state.detailsModal}
                    />
                  </div>
                  <div className={`col-md-4  `}>
                    <p className={`${IndividualStyles.crudModalBottomLabel}`}>
                      Fecha final de vigencia
                      <span className="text-warning">*</span>
                    </p>
                    <input
                      style={{ color: "#01A0F6", fontWeight: 'lighter' }}
                      name="effective_end_date"
                      type="date"
                      className="register-inputs"
                      onChange={this.onChangeForm}
                      min={this.state.consumption_center.effective_start_date}
                      value={this.state.consumption_center.effective_end_date}
                      disabled={this.state.detailsModal}
                    />
                  </div>
                  <div className={`col-md-4  `}>
                    <p className={`${IndividualStyles.crudModalBottomLabel} `}>
                      Presupuesto anual
                      <span className="text-warning">*</span>
                    </p>

                    <NumberFormat
                      disabled={this.state.detailsModal}
                      name="annual_budget"
                      allowNegative={false}
                      prefix="$"
                      placeholder="Escribir..."
                      thousandsGroupStyle="thousand"
                      thousandSeparator
                      className={`register-inputs w-100`}
                      value={this.state.consumption_center.annual_budget || ''}
                      onValueChange={(values) => {
                        this.setState({
                          ...this.state,
                          consumption_center: {
                            ...this.state.consumption_center,
                            annual_budget: parseFloat(values.value),
                          },
                        });
                      }}
                    />
                  </div>

                  {this.state.detailsModal ? (
                    <div className={`col-md-4  `}>
                      <p className={`${IndividualStyles.crudModalTopLabel} `}>Saldo actual</p>
                      <NumberFormat
                        readOnly
                        disabled={this.state.detailsModal}
                        name="current_balance"
                        allowNegative={false}
                        prefix="$"
                        thousandsGroupStyle="thousand"
                        thousandSeparator
                        className={`register-inputs w-100`}
                        value={this.state.consumption_center.current_balance}
                        onValueChange={(values) => {
                          this.setState({
                            ...this.state,
                            consumption_center: {
                              ...this.state.consumption_center,
                              current_balance: values.value,
                            },
                          });
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.detailsModal ? (
                    <div className={`col-md-4  `}>
                      <p className={`${IndividualStyles.crudModalBottomLabel} `}>‎</p>
                      <ProgressBar
                        className={IndividualStyles.progressBar}
                        // animated
                        now={
                          this.state.consumption_center.annual_budget !== 0
                            ? (this.state.consumption_center.current_balance / this.state.consumption_center.annual_budget) * 100
                            : 0
                        }
                        label={
                          this.state.consumption_center.annual_budget !== 0
                            ? `      ‏‏‎       ‏‏‎       ‏‏‎       ‏‏‎‏${(this.state.consumption_center.current_balance / this.state.consumption_center.annual_budget) * 100
                            }%`
                            : "0%"
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </ModalNew>
            <GenericTableNew fontFamilyTable={'fontFamilyTable'} dark={true} headers={renderHeaders}>
              {listConsumptions}
            </GenericTableNew>
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {this.props.totalConsumption ? this.state.filters.page : ""}
                {" de "}
                {Math.ceil(this.props.totalConsumption / this.state.filters.perpage)
                  ? Math.ceil(this.props.totalConsumption / this.state.filters.perpage)
                  : ""}{" "}
                ({this.props.totalConsumption} encontrados)
              </p>
              <Pagination
                activePage={this.state.filters.page}
                itemsCountPerPage={10}
                totalItemsCount={this.props.totalConsumption}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
  currentAccount: state.loginReducer.currentAccount,
  consumption_center: state.consumptionCenterReducer.consumption_center,
  totalConsumption: state.consumptionCenterReducer.totalConsumption,
  auxNiif: state.inventoryFamilyReducer.niifAux,
  allUsers: state.userReducer.allUsers,
  newAllUsers: state.userReducer.newAllUsers,
  functionals: state.functionalReducer.functionals,
  activeInventory: state.inventoryFamilyReducer.activeInventory,
  cost_center: state.costcenterReducer.cc,
  ccCreateLoading: state.costcenterReducer.loading,
  loading: state.consumptionCenterReducer.loading_cc,
  idAccount: state.loginReducer.currentAccount.id,
});

const mapDispatchToProps = (dispatch) => ({
  getConCenter: (filters) => {
    dispatch(getConCenter(filters));
  },
  getNiif: (data) => {
    dispatch(getNiif(data));
  },
  getAllUsersNew: (data) => {
    dispatch(getAllUsersNew(data));
  },
  activationDeactivation: (id, status, filters, description) => {
    dispatch(activationDeactivation(id, status, filters, description));
  },
  get_active_functional: () => {
    dispatch(get_active_functional());
  },
  get_active_inventory: () => {
    dispatch(get_active_inventory());
  },
  costcenter_get: (data) => {
    dispatch(costcenter_get(data));
  },
  createCCenter: (obj, filters) => {
    dispatch(createCCenter(obj, filters));
  },
  updateCCenter: (obj, filters) => {
    dispatch(updateCCenter(obj, filters));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableConsumptionCenter);
