import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GET_SERVICES, GET_SPECIALTIES, GET_SYMPTON, GET_DOCTORS,GET_COMPANY,GET_STATE_APPOINTMENTS,GET_APPOINTMENTS } from "./actionTypes";

const MySwal = withReactContent(Swal);

export const getSympton = (objFilters, loading) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/sympton/?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                loading()
                const { results } = res;
                dispatch({
                    type: GET_SYMPTON,
                    payload: results,
                });
            } else {
                loading();
                MySwal.fire({
                    icon: "error",
                    title: "Error ",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            loading();
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const updateSymptom = (data, loading) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/sympton/`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: token,
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                loading();
                MySwal.fire({
                    icon: "success",
                    title: `<span style=color:#01A0F6>${res.title}</span>`,
                    text: res.message,
                    confirmButtonText: "Aceptar",
                })
            } else {
                loading();
                MySwal.fire({
                    icon: "error",
                    title: "Error ",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            loading();
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const createSymptom = (data, loading) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/sympton/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: token,
            Authorization: token
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                loading();
                MySwal.fire({
                    icon: "success",
                    title: `<span style=color:#01A0F6>${res.title}</span>`,
                    text: res.message,
                    confirmButtonText: "Aceptar",
                })
            } else {
                loading();
                MySwal.fire({
                    icon: "error",
                    title: res.message,
                    confirmButtonText: "Aceptar",
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            loading();
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                confirmButtonText: "Aceptar",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const getSpecialties = (objFilters, loading) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/specialty/?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                loading()
                const { results } = res;
                dispatch({
                    type: GET_SPECIALTIES,
                    payload: results,
                });
            } else {
                loading();
                MySwal.fire({
                    icon: "error",
                    title: "Error ",
                    text: res.message,
                    confirmButtonText: "Aceptar",
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            loading();
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                confirmButtonText: "Aceptar",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const updateSpecialties = (data, loading) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/specialty/`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: token,
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                loading();
                MySwal.fire({
                    icon: "success",
                    title: `<span style=color:#01A0F6>${res.title}</span>`,
                    text: res.message,
                    confirmButtonText: "Aceptar",
                })
            } else {
                loading();
                MySwal.fire({
                    icon: "error",
                    title: "Error ",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            loading();
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const createSpecialties = (data, loading) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/specialty/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: token,
            Authorization: token
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                loading();
                MySwal.fire({
                    icon: "success",
                    title: `<span style=color:#01A0F6>${res.title}</span>`,
                    text: res.message,
                    confirmButtonText: "Aceptar",
                })
            } else {
                loading();
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: res.message,
                    confirmButtonText: "Aceptar",
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            loading();
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const getServices = () => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/appointment/services/`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                const { results } = res;
                dispatch({
                    type: GET_SERVICES,
                    payload: results,
                });
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error ",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const getAppointments = (objFilters, loading) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/appointments/?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                loading()
                const { results } = res;
                dispatch({
                    type: GET_APPOINTMENTS,
                    payload: results,
                });
            } else {
                loading();
                MySwal.fire({
                    icon: "error",
                    title: "Error ",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            loading();
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};

export const getStateAppointments = (loading) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/stateAppointments/`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {

                const { results } = res;
                dispatch({
                    type: GET_STATE_APPOINTMENTS,
                    payload: results,
                });
            } else {
            
                MySwal.fire({
                    icon: "error",
                    title: "Error ",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};

export const getCompany = (loading) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/company/`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                
                const { results } = res;
                dispatch({
                    type: GET_COMPANY,
                    payload: results,
                });
            } else {
                
                MySwal.fire({
                    icon: "error",
                    title: "Error ",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};

export const getDoctors = (loading) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/doctors/`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    }).then((response) => response.json())
        .then((res) => {
            if (res.success) {
                
                const { results } = res;
                dispatch({
                    type: GET_DOCTORS,
                    payload: results,
                });
            } else {
               
                MySwal.fire({
                    icon: "error",
                    title: "Error ",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};