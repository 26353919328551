// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import ModalNew from "../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import GenericTableNew from "../../components/Layouts/GenericTableNew";
// --------------- icons 💥  ----------------//

import Back from "../../assets/img/icons/atras-icon.svg";
import SearchFile from "../../assets/img/icons/searchFileGrey.svg";
//-------------- scss styles 😄   --------------
import Select from "react-select";
import {
  customSelectNew,
  customSelectNewDark,
} from "../../components/Layouts/react-select-custom";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Styles from "./CodeReplace.module.scss"

// ------------------ Functions ----------------------
import { getJournalType } from "../../actions/accountingConsultsActions";
import { getListCompanies } from "../../actions/billingActions";
import { getProviders } from "../../actions/providersActions";
import { listThirdParties, replaceVoucherCode } from "../../actions/movementsActions";
import ReplacementHistory from "./ReplacementHistory";
import { Title } from "../../shared";




function CodeReplace(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);
  const [show, setShow] = useState(false);


  const [info, setInfo] = useState({
    eaccount: storage.loginReducer.currentAccount.id,
    user: storage.loginReducer.user_data.id,
    // typeVoucher: "",
    dateVoucher: "",
    dateUntil: new Date().toISOString().slice(0, 10),
    dateFrom: "",

    typeThird1: "",
    third1: "",
    nit1: "",
    thirdName1: "",

    typeThird2: "",
    third2: "",
    nit2: "",
    thirdName2: "",
    justify: ""
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "vouchers"
    );

  //--------------first functions 🍕   ----------

  useEffect(() => {
    if (!myPermission()?.create || !myPermission()?.read) {
      history?.push("/contabilidad/inicio");
    }
  }, []);

  useEffect(() => {
    dispatch(getJournalType({ eaccount: storage.loginReducer.currentAccount.id }));
  }, []);

  //-------------- select options 🍕   --------------

  let optionsJournalType = [{ key: "", value: "", label: "Seleccionar..." }];
  if (Array.isArray(storage.accountingConsultsReducer.listJournalType)) {
    storage.accountingConsultsReducer.listJournalType.map((item, index) => {
      optionsJournalType.push({
        value: item.id,
        label: item.name,
        key: index + "Jtype",
      });
    });

  }

  let optionsTypeThirds = [
    { key: "default", value: "", label: "Seleccionar..." },
    { key: "1", value: 1, label: "Cliente empresa" },
    { key: "1", value: 2, label: "Proveedor" },
    { key: "1", value: 3, label: "Terceros" },
  ];

  let optionsThirds1 = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (info.typeThird1 === 1) {
    if (Array.isArray(storage.billingReducer.companies)) {
      storage.billingReducer.companies.map((item) => {
        optionsThirds1.push({
          value: item.id,
          label: item.Nit + " - " + item.copyName,
          key: item.Nit,
          name: item.copyName,
        });
      });
    };
  }
  if (info.typeThird1 === 2) {
    if (Array.isArray(storage.providerReducer.providers)) {
      storage.providerReducer.providers.map((item) => {
        optionsThirds1.push({
          value: item.id,
          label: item.nit + " - " + item.name,
          key: item.nit,
          name: item.name,
        });
      });
    };
  }
  if (info.typeThird1 === 3) {
    if (Array.isArray(storage.accountingConsultsReducer.thirdParties)) {
      storage.accountingConsultsReducer.thirdParties.map((item) => {
        optionsThirds1.push({
          value: item.id,
          label: item.nit + " - " + item.description,
          key: item.nit,
          name: item.description,
        });
      });
    };
  }
  let optionsTypeThirds2 = [
    { key: "default", value: "", label: "Seleccionar..." },
    { key: "1", value: 1, label: "Cliente empresa" },
    { key: "1", value: 2, label: "Proveedor" },
    { key: "1", value: 3, label: "Terceros" },
  ];

  let optionsThirds2 = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (info.typeThird2 === 1) {
    if (Array.isArray(storage.billingReducer.companies)) {
      storage.billingReducer.companies.map((item) => {
        optionsThirds2.push({
          value: item.id,
          label: item.Nit + "-" + item.copyName,
          key: item.Nit,
          name: item.copyName,
        });
      });
    };
  }
  if (info.typeThird2 === 2) {
    if (Array.isArray(storage.providerReducer.providers)) {
      storage.providerReducer.providers.map((item) => {
        optionsThirds2.push({
          value: item.id,
          label: item.nit + "-" + item.name,
          key: item.nit,
          name: item.name,
        });
      });
    };
  }
  if (info.typeThird2 === 3) {
    if (Array.isArray(storage.accountingConsultsReducer.thirdParties)) {
      storage.accountingConsultsReducer.thirdParties.map((item) => {
        optionsThirds2.push({
          value: item.id,
          label: item.nit + "-" + item.description,
          key: item.nit,
          name: item.description,
        });
      });
    };
  }




  //--------------  Actions  🍕   --------------
  const replaceCode = () => {
    dispatch(replaceVoucherCode(info, () => {
      setTrigger(trigger + 1)
      setInfo({
        eaccount: storage.loginReducer.currentAccount.id,
        user: storage.loginReducer.user_data.id,
        // typeVoucher: "",
        dateVoucher: "",
        dateUntil: new Date().toISOString().slice(0, 10),
        dateFrom: "",

        typeThird1: "",
        third1: "",
        nit1: "",
        thirdName1: "",

        typeThird2: "",
        third2: "",
        nit2: "",
        thirdName2: "",
        justify: "",
      })
    }))
  }


  const principalRender =
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
      <div className="d-flex">
        
        <div className="d-flex flex-column" style={{ width: "60%" }}>
          
          <div className={tableStyles.title}>
            <Title
              title="Reemplazo de terceros"
              onClickIcon={() => {
                props.setShow({ showReplace: false })
              }}
            />
          </div>

          <Row className="mx-1">
            {/* <Col xs={3} style={{paddingLeft:"0"}}>
                    <p className={tableStyles.crudModalLabel}>Tipo de comprobante <span className={"text-warning"}>*</span> </p>
                    <Select noOptionsMessage={() => 'No hay datos'}
                        key={"typeVoucher"+ trigger}
                        options={optionsJournalType}
                        placeholder=""
                        styles={customSelectNewDark}
                        onChange={(e) =>
                            setInfo({
                              ...info,
                              typeVoucher: e.value,
                            })
                        }
                    />
                </Col> */}
            <Col xs={4} style={{ paddingLeft: "0" }}>
              <p className={tableStyles.crudModalLabel}>Fecha de comprobante <span className={"text-warning"}>*</span> </p>
              <input
                key={"dateVoucher" + trigger}
                type="date"
                name="date"
                className={IndividualStyles.registerInputsBlue}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    dateVoucher: e.target.value,
                  })
                }
                max={new Date().toISOString().slice(0, 10)}
              />
            </Col>
            <Col xs={4} style={{ paddingRight: "0" }}>
              <p className={tableStyles.crudModalLabel}>Fecha de inicio <span className={"text-warning"}>*</span> </p>
              <input
                key={"dateF" + trigger}
                type="date"
                name="date"
                className={IndividualStyles.registerInputsBlue}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    dateFrom: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4} style={{ paddingRight: "0" }}>
              <p className={tableStyles.crudModalLabel}>Fecha de fin</p>
              <input
                defaultValue={new Date().toISOString().slice(0, 10)}
                type="date"
                name="date"
                className={IndividualStyles.registerInputsBlue}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    dateUntil: e.target.value,
                  })
                }
                disabled={true}
              />
            </Col>
          </Row>

          <Row>
            <p className="mr-1" style={{ marginBottom: "8px", marginTop: "16px", fontWeight: "700", fontSize: "15px", color: "#01A0F6" }}>
              • Tercero a reemplazar
            </p>
          </Row>

          <Row className="mx-1" style={{ background: "rgba(0, 93, 191, 0.1)", borderRadius: "10px", paddingRight: "16px", marginTop: "8px", paddingBottom: "16px", paddingLeft: "16px", paddingTop: "16px" }}>
            <Col xs={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <p className={tableStyles.crudModalLabel} style={{ marginTop: "0px" }}>Seleccionar tipo de tercero <span className={"text-warning"}>*</span> </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={"Tthird" + trigger}
                placeholder="Seleccionar..."
                styles={customSelectNewDark}
                options={optionsTypeThirds}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    typeThird1: e.value,
                  })
                  if (e.value === 1) {
                    dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }))
                  }
                  if (e.value === 2) {
                    dispatch(getProviders({ id_account: storage.loginReducer.currentAccount.id, slim: 1, status: "enabled", checked: 1 }))
                  }
                  if (e.value === 3) {
                    dispatch(listThirdParties({ eaccount: storage.loginReducer.currentAccount.id }))
                  }
                }}
              />
            </Col>
            <Col xs={12} style={{ paddingLeft: "0px", paddingRight: "0px", marginTop: "14px" }}>
              <p className={tableStyles.crudModalLabel} style={{ marginTop: "0px" }}>Seleccionar tercero <span className={"text-warning"}>*</span> </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={"third1" + trigger}
                placeholder="Seleccionar..."
                styles={customSelectNewDark}
                options={optionsThirds1}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    third1: e.value,
                    thirdName1: e.name,
                    nit1: e.key,

                  })
                }
              />

            </Col>

          </Row>
          <Row>
            <p className="mr-1" style={{ fontWeight: "700", fontSize: "15px", color: "#005DBF", marginBottom: "8px", marginTop: "16px" }}>
              • Tercero de reemplazo
            </p>
          </Row>

          <Row className="mx-1" style={{ background: "rgba(0, 93, 191, 0.1)", borderRadius: "10px", paddingRight: "16px", marginTop: "8px", paddingBottom: "16px", paddingLeft: "16px", paddingTop: "16px" }}>
            <Col xs={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <p className={tableStyles.crudModalLabel} style={{ marginTop: "0px" }}>Seleccionar tipo de tercero <span className={"text-warning"}>*</span> </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={"Tthird" + trigger}
                placeholder="Seleccionar..."
                styles={customSelectNewDark}
                options={optionsTypeThirds2}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    typeThird2: e.value,
                  })
                  if (e.value === 1) {
                    dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }))
                  }
                  if (e.value === 2) {
                    dispatch(getProviders({ id_account: storage.loginReducer.currentAccount.id, slim: 1 }))
                  }
                  if (e.value === 3) {
                    dispatch(listThirdParties({ eaccount: storage.loginReducer.currentAccount.id }))
                  }
                }}
              />
            </Col>
            <Col xs={12} style={{ paddingLeft: "0px", paddingRight: "0px", marginTop: "14px" }}>
              <p className={tableStyles.crudModalLabel} style={{ marginTop: "0px" }}>Seleccionar tercero <span className={"text-warning"}>*</span>  </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={"third2" + trigger}
                placeholder="Seleccionar..."
                styles={customSelectNewDark}
                options={optionsThirds2}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    third2: e.value,
                    thirdName2: e.name,
                    nit2: e.key
                  })
                }
              />

            </Col>

          </Row>

          <Row>
            <Col xs={12} style={{ margin: "8px 4px 0px", paddingRight: "18px" }}>
              <p className={tableStyles.crudModalLabel}>Justificación <span className={"text-warning"}>*</span> </p>
              <input
                key={"jjustify" + trigger}
                type="text"
                name="justify"
                className={IndividualStyles.registerInputsBlue}
                style={{ color: "#58595B" }}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    justify: e.target.value,
                  })
                }
                maxLength={30}
              />

            </Col>
          </Row>


          <div className={IndividualStyles.bottomRow} style={{ marginRight: "0px", paddingRight: "1px" }}>

            <Button
              className={IndividualStyles.btnSecondary2}
              style={{ padding: "0px 1px" }}
              onClick={() => replaceCode()}
              disabled={!!(info.justify == "") || !!(info.dateUntil == "") || !!(info.dateFrom == "") || !!(info.dateVoucher == "") || !!(info.typeThird1 == "") || !!(info.typeThird2 == "") || !!(info.third1 == "") || !!(info.third2 == "")}
            >
              Reemplazar
            </Button>
            <Button
              className={IndividualStyles.btnPrimary}
              onClick={() => {
                setTrigger(trigger + 1);
                setInfo({
                  eaccount: storage.loginReducer.currentAccount.id,
                  user: storage.loginReducer.user_data.id,
                  typeVoucher: "",
                  dateVoucher: new Date().toISOString().slice(0, 10),
                  dateUntil: new Date().toISOString().slice(0, 10),
                  dateFrom: "",

                  typeThird1: "",
                  third1: "",
                  nit1: "",
                  thirdName1: "",

                  typeThird2: "",
                  third2: "",
                  nit2: "",
                  thirdName2: ""
                })
              }}
            //disabled={!!(storage.accountingReducer.detailExpense.length <= 0)}
            >
              Cancelar
            </Button>
            <div style={{ padding: "5px", width: "60%" }}>
              <label
              // className={Styles.bgBtn}
              >
                {" "}
              </label>
            </div>
            <div style={{ alignContent: "flex-start" }} onClick={() => setShow(true)}>
              <label
                className={Styles.bgBtn}
                style={{
                  borderRadius: "6px", padding: "5px", width: "185px", fontSize: "12px", fontWeight: "700", fontStyle: "bold", cursor: "pointer",
                  marginLeft: "-7px"
                }}
              >
                {" "}
                Historial de reemplazos
                <img
                  style={{ width: "17px", marginLeft: "7px" }}
                  src={SearchFile}
                />
              </label>
            </div>

          </div>
        </div>
      </div>
    </div>
  return (show) ?
    <ReplacementHistory
      setShow={setShow}
    />
    :
    principalRender
}
export default CodeReplace;