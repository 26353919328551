import React, { useEffect, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import LupaClear from "../../assets/img/icons/lupaClear.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import iconBack from "../../assets/img/icons/iconBack.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { SlideDownButton } from "../../components/Layouts/SlideDownButton";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { getBankNotes, getPdfTest } from "../../actions/consultAction"
import { getBankingEntities } from "../../actions/providersActions";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import { getTypesNotes, } from "../../actions/billingActions";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import NumberFormat from "react-number-format";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import { message } from "../../helpers/helpers";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";
function BankNote(props) {
    let history = useHistory();
    function handleClick() {
        history.push("/tesoreria/TipoDeConsulta");
    } const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }
    const [trigger, setTrigger] = useState(1);
    const [trigger2, setTrigger2] = useState(1);
    const counter = useSelector(state => state);//entra aca para acceder a todos los reducers
    const dispatch = useDispatch(); //usa este metodo para ejecutar un action
    const [showModal, setShowModal] = useState(false)
    const myPermission = () => counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "BnkNoteReg");
    const myPermission2 = () => counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "TypCons");
    const [showPdf, setShowPdf] = useState(false)
    const [base64, setBase64] = useState("")
    const getPdf = async (id) => {
        const result = await getPdfTest(
            { id: id, doc: 2 },
            counter.loginReducer.Authorization,
        )
        if (result?.success) {
            return setBase64(result?.pdf[0]?.key?.split("'")[1])
        } else {
            message('error', 'Ha ocurrido un error', result?.message)
        }
    }
    const [BankNote, setBankNote] = useState({
        entity_account: counter.loginReducer.currentAccount.id,
        eaccount: counter.loginReducer.currentAccount.id,
        page: 1,
        perpage: 10,
        amount_from: "",
        amount_to: "",
        date_since: "",
        date_until: "",
        id_bank_nt_type: "",
        name_bank: "",
        note_type: "",
    })
    useEffect(
        () => {
            dispatch(getBankNotes(BankNote))
        },
        [BankNote.page,
        BankNote.note_type,
        BankNote.id_bank_nt_type,
        BankNote.name_bank,
        BankNote.date_since,
        BankNote.date_until,
            trigger2
        ] //cada vez que esto cambie, ejecutar la funcion de arriba. 
    );
    useEffect(() => {
        if (!myPermission()?.read || !myPermission2()?.read) {
            history?.push("/tesoreria/inicio");
        }
        dispatch(getBankingEntities({}));
        dispatch(getTypesNotes({ category: 3, eaccount: counter.loginReducer.currentAccount.id, status: "enabled" }));
    }, [trigger]);
    const handlePageChange = (val) => {
        setBankNote({
            ...BankNote,
            page: val
        });
        getBankNotes(BankNote)
    }
    var optionNoteTypes = []
    optionNoteTypes.push({
        value: "",
        label: "Seleccionar...",
        key: "qg",
    },
        {
            value: "D",
            label: "Débito",
            key: "qg1",
        }
        , {
            value: "C",
            label: "Crédito",
            key: "qg2",
        }

    )
    let optionsNotes = counter.billingReducer.note_types?.length
        ? [{ key: "default", value: "", label: "Seleccionar..." }] : [];
    if (Array.isArray(counter.billingReducer.note_types)) {
        counter.billingReducer.note_types.map((item) => {
            optionsNotes.push({
                value: item.id,
                label: item.name,
            });
        });
    }
    var optionBanks = []
    optionBanks.push({
        value: "",
        label: "Seleccionar...",
        label2: "",
        key: "qg",
    })
    if (Array.isArray(counter.providerReducer.bankingEntities)) {
        counter.providerReducer.bankingEntities.map((item) => {
            optionBanks.push({
                value: item.id,
                label: item.description,
                label2: item.description,
                key: item.id + "wweh",
            }
            )
        });
    }
    const header = [

        <th className="text-center">No. Nota</th>,
        <th className="text-center">Tipo</th>,
        <th className="text-center">Fecha</th>,
        <th className="text-center">Cuenta</th>,
        <th className="text-center">Banco</th>,
        <th className="text-center">Cuenta bancaria</th>,
        <th className="text-center">No. Cpte</th>,
        <th className="text-end px-2">Valor nota</th>,
        <th className="text-center">&nbsp;</th>
    ]
    const body = [

    ]
    if (Array.isArray(counter.consultReducer.bankNote)) {
        counter.consultReducer.bankNote.map((item) => {
            body.push(
                <tr>
                    <td className="text-center">{item.consecutive}</td>
                    <td className="text-center">{item?.nature === "C" ? "Crédito" : "Débito"}</td>
                    <td className="text-center">{convertDateToLatinFormat(item.date)}</td>
                    <td className="text-center">{item.niif_account}</td>
                    <td className="text-center">{item.name_bank}</td>
                    <td className="text-center">{item.bank_account_nbr}</td>
                    <td className="text-center">{item.jrnlvoucher}</td>
                    <td className="text-end px-2">$ {numberWithCommas(item.amount)}</td>
                    <td className="px-2 text-start">
                        <img src={Filtrar}
                            className={tableStyles.cursorPointer}
                            onClick={() => (getPdf(item.id), setShowPdf(true))}
                        />
                    </td>
                </tr>
            );
        })
    }

    const [showOpt, setShowOpt] = useState({
        show: false,
        arrow: false,
    });

    const ShowOptions = () => {
        if (showOpt?.show && showOpt?.arrow) {
            setShowOpt({
                ...showOpt,
                show: false,
                arrow: false,
            });
        } else {
            setShowOpt({
                ...showOpt,
                show: true,
                arrow: true,
            });
            setBankNote({
                entity_account: counter.loginReducer.currentAccount.id,
                eaccount: counter.loginReducer.currentAccount.id,
                page: 1,
                perpage: 10,
                amount_from: "",
                amount_to: "",
                date_since: "",
                date_until: "",
                id_bank_nt_type: "",
                name_bank: "",
                note_type: "",
            })
        }
    };

    const handleSumit = (e) => {
        e.preventDefault();
        setTrigger2(trigger2 + 1)
    }
    return (

        <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
            {(counter.consultReducer.bankNote_loading) &&
                <div className="loading">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            }

            <div 
                className="d-flex mb-4" 
                style={{ marginTop: '30px', alignItems: 'end' }}>
                <Title
                    title="Consulta de notas bancarias"
                    onClickIcon={handleClick}
                />
            </div>


            <div className={`${tableStyles.container}`} style={{ padding: "0" }}>
                <div>

                    <div
                        className="div align-items-end"
                        style={{ gap: '0.5rem 1rem', flexWrap: 'wrap' }}
                    >

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Tipo de nota</label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                styles={customSelectNewDark}
                                options={optionNoteTypes}
                                placeholder="Seleccionar..."
                                onChange={(e) => setBankNote({
                                    ...BankNote,
                                    note_type: e.value
                                })}
                            />
                        </div>
                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Notas de proveedores</label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                styles={customSelectNewDark}
                                options={optionsNotes}
                                placeholder="Seleccionar..."
                                onChange={(e) => setBankNote({
                                    ...BankNote,
                                    id_bank_nt_type: e.value
                                })}
                            />
                        </div>
                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Banco</label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                styles={customSelectNewDark}
                                options={optionBanks}
                                placeholder="Seleccionar..."
                                onChange={(e) => setBankNote({
                                    ...BankNote,
                                    name_bank: e.label2
                                })}
                            />
                        </div>

                        <div className={tableStyles.containerDate}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Fecha desde</label>
                            <input
                                className={`${IndividualStyles.registerInputsGris} register-inputs`}
                                style={{ color: "#005DBF", fontWeight: "bold" }}
                                name="date_since"
                                type="date"
                                onChange={(e) => setBankNote({
                                    ...BankNote,
                                    date_since: e.target.value,
                                })}
                            />
                        </div>

                        <div className={tableStyles.containerDate}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Hasta</label>
                            <input
                                className={`${IndividualStyles.registerInputsGris} register-inputs`}
                                style={{ color: "#005DBF", fontWeight: "bold" }}
                                name="date_until"
                                type="date"
                                onChange={(e) => setBankNote({
                                    ...BankNote,
                                    date_until: e.target.value,
                                })}
                            />
                        </div>

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Valor desde</label>
                            <form onSubmit={handleSumit}>
                                <NumberFormat
                                    placeholder="Escribir..."
                                    allowNegative={false}
                                    className={IndividualStyles.registerInputsGris}
                                    name="amount_from"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={'$'}
                                    onValueChange={(e) => setBankNote({
                                        ...BankNote,
                                        amount_from: e.value,
                                    })}
                                >
                                </NumberFormat>
                            </form>
                        </div>

                        <div className={tableStyles.containerSelect}>
                            &nbsp;<label className={tableStyles.stylesLabel}>Valor hasta</label>
                            <form onSubmit={handleSumit}>
                                <NumberFormat
                                    placeholder="Escribir..."
                                    allowNegative={false}
                                    className={IndividualStyles.registerInputsGris}
                                    name="amount_from"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={'$'}
                                    onValueChange={(e) => setBankNote({
                                        ...BankNote,
                                        amount_to: e.value,
                                    })}
                                >
                                </NumberFormat>
                            </form>
                        </div>

                        <div>
                            <label>
                                <img
                                    src={Lupa}
                                    alt="search"
                                    style={{ width: '25px' }}
                                    className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`}
                                    onClick={() => { setBankNote({ ...BankNote, page: 1 }); dispatch(getBankNotes({ ...BankNote, page: 1 })) }}
                                />
                            </label>

                        </div>

                    </div>

                </div>
                <GenericTable
                    headers={header}
                    children={body}
                    dark={true}
                >
                </GenericTable>
                <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                        Pag.{" "}
                        {counter.consultReducer.totalBankNotes
                            ? BankNote.page
                            : ""}
                        {" de "}
                        {Math.ceil(counter.consultReducer.totalBankNotes / BankNote.perpage)
                            ? Math.ceil(counter.consultReducer.totalBankNotes / BankNote.perpage) : ""}{" "}
                        ({counter.consultReducer.totalBankNotes} encontrados)
                    </p>
                    <Pagination
                        activePage={BankNote.page}
                        itemsCountPerPage={BankNote.perpage}
                        totalItemsCount={counter.consultReducer.totalBankNotes}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext}
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                    />
                </div>
            </div>
            <ModalNew
                title="Detalle de consulta de nota"
                show={showPdf}
                btnNoName={"Cancelar"}
                size="700"
                btnYesDisabled={false}
                onHide={() => (setBase64(""), setShowPdf(false))}
                btnNoEvent={() => (setBase64(""), setShowPdf(false))}
                btnNoDisabled={false}
            >
                <PdfViewer
                    downloadable
                    file={`data:application/pdf;base64,${base64}`}></PdfViewer>
            </ModalNew>
        </div>
    )

};
export default BankNote;