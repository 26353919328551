import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {cups_filter, cups_edit_active} from "../../actions/cupsActions";


import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


import Add from "../../assets/img/icons/agregar.svg";
import Export from "../../assets/img/icons/exportar.svg";
import lupa from "../../assets/img/icons/lupa.svg";

import Pagination from "react-js-pagination";
import GenericTable from "../Layouts/GenericTable";
import GenericModal from "../Layouts/GenericModal";

import "../TableUsers/tableUserStyle.scss";
import popupStyles from "../Layouts/popupEdEdit.module.css";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import CustomPopup from "../Popup/customPopup";

function TableCups (props){
    const store = useSelector(state => state); //entra aca para acceder a todos los reducers
    const dispatch = useDispatch(); //usa este metodo para ejecutar un action
    const MySwal = withReactContent(Swal); //alert

    const [filters, setFilter]= useState({
        search: "",
        page: 1,
        perpage: 10
    }); //valores de entrada de la funcion en el backend
    
    const [contador, setContador] = useState(0); //esto es para recargar la pagina
    // [variable, funcion para cambiar variable] = useState( valor inicial)

    //se ejecuta minimo una vez cuando cargue la pagina
    useEffect(
        () => {
            //hola soy la funcion
            dispatch(cups_filter(filters))
        },
        [contador, filters.page] //cada vez que esto cambie, ejecutar la funcion de arriba. 
    ); 

    const handlePageChange = (val) => {
        setFilter({
            ...filters, // División de objetos
            page: val
        });
    }

    const handleSearchInput = (val) => {
        setFilter({
            ...filters, //el resto no los quiero cambiar
            search: val //solo search
        });
    }
    

    


    const renderElement = (elem) => {
        
        return (
          <tr key={elem.codigo}>
            <td className="col-md-2">{elem.codigo}</td>
            <td className="col-md-8">{elem.descripcion}</td>
            <td className="col-md-1">

                <div className={tableStyles.groupElems}>
                    <div className={elem.enabled ? tableStyles.circleGreen : tableStyles.circleRed}></div>
                    <CustomPopup
                        showEdit = {false}
                        
                        showEnable = {true}
                        isEnabled = {elem.enabled}
                        enableClickEvent = {() => dispatch(
                            cups_edit_active(
                                elem.codigo,
                                {active: !elem.enabled},
                                elem.descripcion,
                                ()=>setContador(contador+1)
                            )
                        )}
                    />
                </div>

            </td>
          </tr>
        );
    }
    
    const listElem = () => {
        let elemMap;
        if (Array.isArray(store.cupsReducer.cups)) {
            const misCups = store.cupsReducer.cups;
            elemMap = misCups.map(
                (elem) => {
                    return renderElement(elem);
                }
            );

          }
        return elemMap;
    }

    const renderHeaders = [
        <th key={0}>Código</th>,
        <th key={1}>Descripción</th>,
        <th key={99}><div className={tableStyles.groupElems}>est.</div></th>,
    ]


    return(
        <div className={tableStyles.container}>
            <h1 className={tableStyles.title}>CUPS</h1>
            <div className={tableStyles.tableArea}>
                <div className={tableStyles.mainRow}>
                    <div>
                        <input
                            className={tableStyles.searchUsersAlt}
                            type="text"
                            placeholder="Buscar"
                            onChange={(e)=>handleSearchInput(e.target.value)}
                        />

                        <button 
                            onClick={()=>setContador(contador+1)} 
                        >
                            <img src={lupa} alt="User icon" className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`} />
                        </button>
                    </div>

                    <div className={tableStyles.groupElems}>
                        <img 
                            src={Export} 
                            alt="exportar" 
                            className={tableStyles.iconSvg}
                        /> 
                        <img
                            src={Add}
                            alt="User icon"
                            className={tableStyles.iconSvg}
                            //onClick={() => setPostState({...postState, modalShow:true})}
                        />
                    </div>
                </div>

                <GenericTable headers={renderHeaders}>{listElem()}</GenericTable>

                <div className={paginationStyles.wrapper}>
                        <p className={paginationStyles.paginationText}>
                            Pag. {store.cupsReducer.total ? filters.page : ""} 
                            {" de "}
                            {Math.ceil(store.cupsReducer.total / filters.perpage) ? 
                                Math.ceil(store.cupsReducer.total / filters.perpage)
                            : 
                                "" 
                            }
                            {" "}
                            ({store.cupsReducer.total} encontrados)
                        </p>
                        <Pagination
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={store.cupsReducer.total}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClassPrev={paginationStyles.itemClassPrev}
                            itemClassNext={paginationStyles.itemClassNext }
                            itemClassFirst={paginationStyles.itemClassFirst}
                            itemClassLast={paginationStyles.itemClassLast}
                            itemClass={paginationStyles.itemClass}
                        />
                    </div>
                    
            </div>
        </div>
    )

}

export default TableCups;
