import React, { Component, useState, useEffect } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import Recibir from "../../assets/img/icons/recibir.svg";
import Recibir2 from "../../assets/img/icons/recibirGris.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useHistory } from "react-router-dom";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import {
    getReceiptInvoices,
    getStatus,
    getClient
} from "../../actions/receiptOfInvoicesActions";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import DetReceiptInvoices from "./DetReceiptInvoices";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";

function InvoiceReceipt(props) {

    const history = useHistory()
    const storage = useSelector((state) => state);
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(1);
    const [details, setDetails] = useState({
        show: false,
    });

    const [filters, setFilters] = useState({
        page: 1,
        perpage: 10,
        eaccount: storage.loginReducer.currentAccount.id,
        minuteNumber: "",
        status: "",
        date: "",
        client: "",
    });

    useEffect(() => {
        dispatch(getReceiptInvoices(filters));
    }, [trigger, filters.page]);

    useEffect(() => {
        dispatch(getStatus({ type: "receivRec" }));
        dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
    }, []);

    let optionsStatus = [{ key: "default", value: "", label: "Seleccionar...", id: "" }];
    if (Array.isArray(storage.invoiceReducer.status)) {
        storage.invoiceReducer.status.map((item) => {
            optionsStatus.push({
                value: item.name,
                label: item.value,
                key: item.id + "status",
            })
        });
    }

    let optionsClient = [{ key: "default", value: "", label: "Seleccionar...", id: "" }];
    if (Array.isArray(storage.invoiceReducer.clients)) {
        storage.invoiceReducer.clients.map((item) => {
            optionsClient.push({
                value: item.id,
                label: item.copyName,
                key: item.id + "clients",
            })
        });
    }

    const header = [
        <th className="col-1 text-center">No. Acta </th>,
        <th className="text-center">NIT</th>,
        <th className="text-center">Empresa</th>,
        <th className="text-center">Responsable</th>,
        <th className="text-center">Fecha envío</th>,
        <th className="col-1 text-center">Estado</th>,
        <th className="col-1 text-center"></th>
    ]

    const renderList = () => {
        let table = [];

        if (Array.isArray(storage.invoiceReducer.invoices)) {
            table = storage.invoiceReducer.invoices.map((x, index) => {

                return (
                    <tr key={"renderList" + index} className="hover-table-row">
                        <td className="text-uppercase">{x.sequence}</td>
                        <td>{x.clientNit}</td>
                        <td>{x.clientName}</td>
                        <td>{x.generatorFullName}</td>
                        <td>{x.exp_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</td>
                        <td className="text-center"><div className="rounded-pill p-1" style={{ backgroundColor: `${x.status_background}` }}><b style={{ color: `${x.status_fontcolor}` }}>{x.status}</b></div></td>
                        {x.status == "Pendiente" ?
                            <td className="text-center"><img className={`text-start w-40 ${IndividualStyles.pointer}`} src={Recibir} onClick={() => setDetails({ show: true, selectedRecord: x.id })} /></td>
                            :
                            <td className="text-center"><img className={`text-start w-40`} src={Recibir2} /></td>
                        }
                    </tr>
                );
            });
        }
        return table;
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
    };


    // return (
    const principalRender = <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
        {(storage.invoiceReducer.loading_receipt_invoice) &&
            <div className="loading">
                <Loader
                    type="Oval"
                    color="#003f80"
                    height={100}
                    width={100}
                />
            </div>
        }
        
        <div className={tableStyles.title}>
            <Title
                title="Recepción de acta"
                onClickIcon={() => {
                    history.push('/cartera/inicio')
                }}
            />
        </div>

        <div className="d-flex gap-3 align-items-end">

            <div className={tableStyles.containerSelect}>
                <form onSubmit={(e) => handleSearch(e)}>
                    &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                        options={optionsClient}
                        placeholder="Seleccionar..."
                        onChange={(e) =>
                            setFilters({
                                ...filters,
                                client: e.value,
                            })
                        }
                        styles={customSelectNewDark} name="id_consumption_center" />
                </form>
            </div>

            <div className={tableStyles.containerSelect}>
                <form onSubmit={(e) => handleSearch(e)}>
                    &nbsp;<label className={tableStyles.stylesLabel}>No. Acta</label>
                    <input className={IndividualStyles.registerInputs} type="text"
                        value={filters.minuteNumber}
                        placeholder="Escribir..."
                        onChange={(e) =>
                            setFilters({
                                ...filters,
                                minuteNumber: e.target.value,
                            })
                        }
                    ></input>
                </form>
            </div>

            <div className={tableStyles.containerSelect}>
                <form onSubmit={(e) => handleSearch(e)}>
                    &nbsp;<label className={tableStyles.stylesLabel}>Estado</label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                        options={optionsStatus}
                        placeholder="Seleccionar..."
                        onChange={(e) =>
                            setFilters({
                                ...filters,
                                status: e.value,
                            })
                        }
                        styles={customSelectNewDark} name="id_consumption_center" />
                </form>
            </div>

            <div className={tableStyles.containerDate}>
                <form onSubmit={(e) => handleSearch(e)}>
                    &nbsp;<label className={tableStyles.stylesLabel}>Fecha de envío</label>
                    <input
                        className={`${IndividualStyles.registerInputs} register-inputs`}
                        type="date"
                        style={{ color: "#005DBF", fontWeight: "bold" }}
                        value={filters.date}
                        onChange={(e) => setFilters({ ...filters, date: e.target.value })}
                    ></input>
                </form>
            </div>

            <div>
                <form onSubmit={(e) => handleSearch(e)}>
                    <i>
                        <img
                            style={{ width: '25px' }}
                            className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`}
                            src={Lupa}
                            alt=""
                            srcset=""
                            onClick={() => { setTrigger(trigger + 1); setFilters({ ...filters, page: 1 }) }}
                        />
                    </i>
                </form>
            </div>

            {/* <Col xs={1}>
                        
                    </Col> */}
        </div>

        <GenericTable
            headers={header}
            dark={true}
        >
            {renderList()}
        </GenericTable>

        <div className={IndividualStyles.bottom}>
            <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                    Pag.{" "}
                    {storage.invoiceReducer.invoices
                        ? filters.page
                        : ""}
                    {" de "}
                    {Math.ceil(
                        storage.invoiceReducer?.invoices_total / filters.perpage
                    )
                        ? Math.ceil(
                            storage.invoiceReducer.invoices_total /
                            filters.perpage
                        )
                        : ""}{" "}
                    ({storage.invoiceReducer.invoices_total} encontrados)
                </p>
                <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={10}
                    totalItemsCount={parseInt(storage.invoiceReducer.invoices_total)}
                    pageRangeDisplayed={5}
                    onChange={(e) => setFilters({ ...filters, page: e })}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                />
            </div>
        </div>

    </div>

    return (!!details.show) ?
        <DetReceiptInvoices
            selectedRecord={details.selectedRecord}
            show={details.show}
            setDetails={setDetails}
        />
        :
        principalRender;

};
export default InvoiceReceipt;