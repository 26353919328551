import React, { useState } from 'react'
import '../../../OrderingModule/orderingStyles.css'
import { PseudoSideBarAccordion } from '../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion';
import { useHistory } from "react-router-dom";
import SelectSites from '../../../AdmissionModule/SelectSites';
import { useSelector } from 'react-redux';
import { onCapitalLetter } from '../../../helpers/helpers';
export const AdmissionHomeContent = () => {
    const [siteSelected, setSiteSelected] = useState({
        siteId: "",
        siteName: "",
    })
    const store = useSelector((state) => state);
    const siteId = store.userReducer.site
    const { all_categories } = useSelector((state) => state?.loginReducer);
    const accountingModules = all_categories?.find((cat) => cat?.name === 'Admisión')
    const { modules } = accountingModules
    const storage = useSelector((state) => state);
    // const category = store?.loginReducer?.category;

    let history = useHistory();
    // const titles = [
    //     {
    //         title: 'Admisiones',
    //         content: [
    //             { subtitle: "Admitir pacientes", action: () => history.push('/admision/admitirPacientes') },
    //         ]
    //     },
    //     {
    //         title: 'Órdenes',
    //         content: [
    //             { subtitle: "Órdenes de servicios", action: () => history.push('/admision/ordenesServicios') },
    //             {
    //                 subtitle: "Órdenes de Proveedores", action: () =>
    //                     history.push("/admision/ordenesProveedores"),
    //             },
    //             { subtitle: "Órdenes anuladas", action: () => history.push('/admision/ordenesAnuladas') },
    //         ]
    //     },
    //     {
    //         title: 'Cierre diario',
    //         content: [
    //             { subtitle: "Cierre diario", action: () => history.push("/admision/cierreDiario") },
    //             { subtitle: "Historial de cierres", action: () => history.push("/admision/historialCierres") },
    //         ]
    //     },
    //     {
    //         title: 'Autorizar solicitudes',
    //         content: [
    //             { subtitle: "Solicitudes", action: () => history.push("/admision/autorizarSolicitudes"), },
    //             { subtitle: "Anulaciones", action: () => history.push("/admision/autorizarAnulaciones") },
    //         ]
    //     },
    //     {
    //         title: 'Consultas',
    //         content: [
    //             {
    //                 subtitle: "Actividad diaria",
    //                 action: () =>
    //                     history.push("/admision/actividadDiaria"),
    //             },
    //             { subtitle: "Consolidado de cajas", action: () => history.push("/admision/consolidadoCajas") },
    //             { subtitle: "Consulta de oportunidad", action: () => history.push("/admision/consultaOportunidad") },
    //             { subtitle: "Consulta de ocupación", action: () => history.push("/admision/consultaOcupacion") },
    //             { subtitle: "Consulta de órdenes", action: () => history.push("/admision/consultaOrdenes") },
    //         ]
    //     },
    //     {
    //         title: 'Clientes pacientes',
    //         content: [
    //             {
    //                 subtitle: "Listado de pacientes",
    //                 action: () =>
    //                     history.push("/admision/listadoDePacientes"),
    //             },
    //             // { subtitle: "Listado de pacientes", action: () => history.push('/admision/listadoDePacientes') },
    //         ]
    //     },

    // ]
    const contentDynamic = modules?.map((module) => {
        const textJoin = onCapitalLetter({text:module?.name})
        return {
            title: textJoin,
            content: module?.functionalities?.map((subModule)=>{
                let subModuleName = onCapitalLetter({text: subModule?.name})
                let returnValue = <></>
                let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
                    (x) => x.functionality?.prefix === subModule?.prefix
                  )?.read
                if(permissionValidation){
                    returnValue = { subtitle: subModuleName, action: () => history.push(subModule?.url) };
                  }
                return returnValue
            })
        }
    })
    return (
        <>
            {(siteId === "") ?
                <div className='d-flex align-self-center'>
                    <SelectSites siteSelected={siteSelected} setSiteSelected={setSiteSelected} />
                </div>
                :
                <div>
                    {/* /* ----------------------------- PSEUDO-SIDEBAR--------------------------*/}
                    {<PseudoSideBarAccordion accordionItems={contentDynamic} />}
                </div>

            }
        </>

    )
}
