import GenericTableNew from "../Layouts/GenericTableNew";
import Trash from "../../assets/img/icons/canecaGris.svg";
import buscar from "../../assets/img/icons/buscar.svg";
import TraerReq from "../../assets/img/icons/traerReq.svg";
import React, { useEffect, useState } from "react";

import {
  requisition_filter,
  requisition_articles_filter,
  requisition_insert,
} from "../../actions/requisitionActions";

import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import reqStyles from "./Requisitions.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import genericTableStyles from "../Layouts/genericTable.module.scss";
import { Button } from "react-bootstrap";
import ModalArticle from "./ReqModalArticle";
import ModalClone from "./ReqModalClone";
import CustomNumUpDown from "../Layouts/CustomNumUpDown/CustomNumUpDown";
import ModalBudget from "./ReqModalBudget";
import Loader from "react-loader-spinner";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import moment from 'moment'

function RequisitionForm(props) {
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const [trigger, setTrigger] = useState(1);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
  });
  
  const [reqState, setreqState] = useState({
    concept: "",
    urgent: false,
    observations: "",
    requisition_date: moment(new Date()).format("DD/MM/YYYY"),

    id_user: counter.loginReducer.user_data.id,
    name_user:
      counter.loginReducer.user_data.first_name +
      " " +
      counter.loginReducer.user_data.first_surname,
    account: counter.loginReducer.currentAccount.id,
    id_consumption_center: 0,
    cc_name: "",
    annual_budget: 0,

    articulos: [],

    over_run_justification: "",
  });

  const myData = counter.loginReducer.user_data;
  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "Req"
    );

  const [modalArtControl, setmodalArtControl] = useState({
    show: false,
  });
  const [modalCloneControl, setmodalCloneControl] = useState({
    show: false,
  });
  const [modalBugdetControl, setmodalBugdetControl] = useState({
    show: false,
  });

  const [loading, setLoading] = useState(false);

  const findThatDamnCC = counter.requisitionReducer.centroconsumos.find(
    (cc) => cc.id_user == myData?.id
  );
  //console.log("findThatDamnCC", findThatDamnCC);

  useEffect(() => {
    const { history } = props;

    if (!myPermission()?.create) {
      history?.push("/compras/inicio");
    }
    setLoading(true);
    dispatch(
      requisition_articles_filter(
        { eaccount: counter.loginReducer.currentAccount.id },
        () => setLoading(false)
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      requisition_filter({ eaccount: counter.loginReducer.currentAccount.id })
    );

    // if(Array.isArray(counter.requisitionReducer.centroconsumos)){
    //     dispatch(requisition_filter({
    //         eaccount: counter.loginReducer.currentAccount.id,
    //         cc_id: findThatDamnCC?.id,
    //     }));
    // }
  }, [trigger]);

  const resetReq = () => {
    setreqState({
      ...reqState,
      concept: "",
      urgent: false,
      observations: "",
      id_consumption_center: 0,
      cc_name: "",
      articulos: [],
      over_run_justification: "",
      annual_budget: 0,
    });
    setTrigger(trigger + 1);
  };

  const buttonCancel = () => {
    if (reqState?.articulos.length > 0) {
      customSwaltAlert({
        title: "¿Está seguro?",
        text: "Se descartarán los cambios de esta requisición",
        showCancelButton: true,
        confirmButtonText: `Sí`,
      }).then((result) => {
        if (result.isConfirmed) {
          resetReq();
        }
      });
    }
  };

  const buttonOk = () => {
    let error = "";

    if (reqState?.articulos?.length < 1) {
      error = "No tiene Artículos";
    }

    if (!!error) {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: error,
        showCancelButton: false,
      });
      return null;
    }
    if (reqState.concept === "") {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo ",
        text: "Debe digitar el concepto de la requisición",
        showCancelButton: false,
      });
      return null;
    }
    setmodalBugdetControl({
      ...modalBugdetControl,
      show: true,
    });
  };

  const sendrequisition = () => {
    setLoading(true)
    dispatch(requisition_insert({ ...reqState }, () => resetReq(), () => setLoading(false)));
  };

  const ButtonArticle = () => {
    setmodalArtControl({
      ...modalArtControl,
      show: true,
    });
  };

  const ButtonReq = () => {
    setmodalCloneControl({
      ...modalCloneControl,
      show: true,
    });
  };

  const removeArticle = (id) => {
    let articleTemp = reqState.articulos;

    articleTemp = articleTemp.filter((x) => x.id != id);

    setreqState({
      ...reqState,
      articulos: articleTemp,
    });
  };

  const editArticleAmmount = (id, ammount) => {
    let tempReqArt = reqState.articulos;
    if (Array.isArray(tempReqArt)) {
      let target = tempReqArt.find((x) => x.id == id);
      if (!!target) {
        target.ammount = Math.max(1, ammount);
        setreqState({
          ...reqState,
          articulos: tempReqArt,
        });
      }
    }
  };

  const renderHeaders = [
    <th key={`reqTH1`} className="px-2">Código</th>,
    <th key={`reqTH2`}>Artículo</th>,
    <th key={`reqTH3`} className="px-2 text-center">Cantidad</th>,
    <th key={`reqTH99`}></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(reqState.articulos)) {
      table = reqState.articulos.map((x) => {
        return (
          <tr
            key={"requisition" + x.id}
            className={`hover-table-row  ${x.newArticle ? genericTableStyles.orange : ""
              }`}
          >
            <td className={`col-md-2 text-start px-2`}>{x.id}</td>
            <td className={`text-start col-md-6 `}>{x.description}</td>
            <td className={`col-md-2`}>
              <CustomNumUpDown
                id={x.id}
                value={x.ammount}
                onChange={editArticleAmmount}
              ></CustomNumUpDown>
            </td>
            <td className={`col-md-2`}>
              <img
                src={Trash}
                alt="Eliminar"
                className={`icons-popUp ${x.newArticle ? reqStyles.orangeIcon : ""
                  }`}
                onClick={() => removeArticle(x.id)}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const paginateList = () => {
    const fullList = renderList();
    return fullList.slice(
      filters.perpage * (filters.page - 1),
      filters.perpage * filters.page
    );
  };

  const [optionConstCenterState, setOptionConstCenterState] = useState([])
  const [selectedCC, setselectedCC] = useState({});
const [articleChanges, setArticleChanges] = useState( [] );
const [articleNew, setArticleNew] = useState( [] );
  //======================================================================= for ReqModalArticle
  // const optionCostCenter = () => {
  //   let tempModules = [];
  //   if (Array.isArray(counter.requisitionReducer.centroconsumos)) {
  //     tempModules = counter.requisitionReducer.centroconsumos
  //       .filter((cc) => cc.id_user == myData?.id)
  //       .map((elem) => {
  //         console.log("elem: ", elem);
  //         let label = elem.description;
  //         if (Array.isArray(elem.families)) {
  //           if (elem.families.length < 1) {
  //             label += "*";
  //           }
  //         } else {
  //           label += "*";
  //         }
  //         return {
  //           value: elem.id,
  //           label: label,
  //           families: elem.families,
  //           isDisabled: !elem.active,
  //           annual_budget: elem.annual_budget,
  //           id_user: elem.id_user,
  //         };
  //       });
  //   }
  //   setselectedCC(tempModules.find(cc => cc.id_user==myData?.id))
  //   setOptionConstCenterState(tempModules)
  //   // return tempModules;
  // };

  useEffect(() => {
    let tempModules = [];
    if (Array.isArray(counter.requisitionReducer.centroconsumos)) {
      tempModules = counter.requisitionReducer.centroconsumos
        .filter((cc) => cc.id_user === myData?.id)
        .map((elem) => {
          let label = elem.description;
          if (Array.isArray(elem.families)) {
            if (elem.families.length < 1) {
              label += "*";
            }
          } else {
            label += "*";
          }
          return {
            value: elem.id,
            label: label,
            families: elem.families,
            isDisabled: !elem.active,
            annual_budget: elem.annual_budget,
            id_user: elem.id_user,
          };
        });
    }
    setselectedCC(tempModules.find(cc => cc.id_user === myData?.id))
    setOptionConstCenterState(tempModules)
  }, [counter.requisitionReducer.centroconsumos, myData?.id])

  // ---------------------------------------------------
  const [filtersArticle, setFilterArticle] = useState({
    search: "",
    page: 1,
    perpage: 10,
    account:counter.loginReducer.currentAccount.id
});


const articlesForParentForm = (parentArticles, myArticles, parentCC, myCC) => {
  let articlesready = [];
  if(parentCC===myCC){
      //let myArticlesReady = myArticles.filter(item => !parentArticles.includes(item) )
      let myArticlesReady = myArticles.filter(item => !parentArticles.find(parentItem=> item.id==parentItem.id) )
      articlesready = [...parentArticles, ...myArticlesReady]
      return articlesready;
  }else{
      return [...myArticles];
  }
}
  const savearticles =()=>{
    setreqState({
        ...reqState,
        id_consumption_center: selectedCC.value,
        cc_name: selectedCC.label,
        annual_budget: selectedCC?.annual_budget,
        articulos:  articlesForParentForm(reqState.articulos, articleChanges.concat(articleNew), reqState.id_consumption_center, selectedCC.value),
    });
    //setArticleChanges([]);
    //setArticleNew([]);
    setFilterArticle({
        search:"",
        page:1,
        perpage:10,
    });
    setmodalArtControl({
      ...modalArtControl,
      show: false,
    })
} 

  //======================================================================= for ReqModalArticle

  return (
    <div className={tableStyles.container} style={{ padding: "0 6rem" }}>
      {loading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div className={reqStyles.container1}>
        <div className={reqStyles.ItemSmall}>
          <h1 className={tableStyles.title}>Crear Requisición</h1>
        </div>
        <div className={reqStyles.throwThisToTheBottom}>
          <div className={reqStyles.traerReq} onClick={() => ButtonReq()}>
            <p>Traer Requisición</p>
            <img
              src={TraerReq}
              alt="Traer Requisición"
              className={`icons-popUp`}
            />
          </div>
        </div>
      </div>

      <div className={reqStyles.container2}>
        {/* ---------------------------- ROW 1 ---------------------------------- */}
        <div className="d-flex">
          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Fecha de Requisición</p>
            <input
              className="register-inputs"
              name="requisition_date"
              type="text"
              placeholder="Escribir..."
              value={reqState.requisition_date}
              // value={reqState.requisition_date?.toLocaleDateString("es-CO")}
              disabled={true}
            />
          </div>

          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
            <input
              className="register-inputs"
              name="id_consumption_center"
              type="text"
              value={reqState.cc_name}
              disabled={true}

            //disabled={!(!!reqState.id_consumption_center)}
            />
          </div>

          <div className={reqStyles.ItemMedium}>
            <div className={`${reqStyles.ItemSmall}`}>
              <p className={tableStyles.crudModalLabel}>Concepto</p>
              <input
                className="register-inputs"
                name="concept"
                type="text"
                value={reqState.concept}
                onChange={(e) =>
                  setreqState({
                    ...reqState,
                    concept: e.target.value,
                  })
                }
                placeholder="Escribir..."
                disabled={!!!reqState.id_consumption_center}
              />
            </div>
          </div>
        </div>

        {/* ============================= ROW 2 =================================== */}
        <div className="d-flex">
          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Requerido por</p>
            <input
              className="register-inputs"
              name="id_user"
              type="text"
              value={reqState.name_user}
              disabled={true}
              placeholder="Escribir..."

            />
          </div>

          <div className={reqStyles.ItemMedium}>
            <div className={`${reqStyles.ItemSmall}`}>
              <p className={tableStyles.crudModalLabel}>Artículos</p>
              <div
                className={`register-inputs ${reqStyles.pseudoInput}`}
                onClick={() => ButtonArticle()}
              >
                <p className={`${tableStyles.f12}`}>{"Agregar artículos"}</p>
                <img src={buscar} />
              </div>
            </div>
          </div>

          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <div className={`${reqStyles.ItemCheckbox}`}>
              <div className={tableStyles.myCheckbox}>
                <input
                  key={"urgentbox" + Math.random()}
                  className={tableStyles.myCheckboxInput}
                  type="checkbox"
                  name="urgent"
                  value="urgent"
                  defaultChecked={reqState.urgent}
                  onChange={(e) =>
                    setreqState({ ...reqState, urgent: !reqState.urgent })
                  }
                  disabled={!!!reqState.id_consumption_center}
                />
                <label
                  className={`${tableStyles.myCheckboxLabel} ${reqStyles.urgente}`}
                  htmlFor="urgent"
                  style={{textTransform: "none"}}
                >
                  Marcar como urgente
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={reqStyles.container3}>
        <GenericTableNew dark={true} headers={renderHeaders}>
          {reqState.articulos.length < 1 ? (
            <tr key="theOnlyOne">
              <td>&nbsp;</td>
              <td>
                <b className={tableStyles.textCenter}>
                  <a
                    className={reqStyles.tableButton}
                    onClick={() => ButtonArticle()}
                  >
                    Agregar Artículo
                  </a>
                  <span className={reqStyles.tableSlash}>/</span>
                  <a
                    className={reqStyles.tableButton}
                    onClick={() => ButtonReq()}
                  >
                    Traer Requisición
                  </a>
                </b>
              </td>
              <td>-</td>
              <td>&nbsp;</td>
            </tr>
          ) : (
            paginateList()
          )}
        </GenericTableNew>
      </div>

      <ModalArticle
        title="Artículos"
        key={"modalArticle" + trigger}
        show={modalArtControl.show}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            show: false,
          })
        }
        reqState={reqState}
        optionCostCenter={optionConstCenterState}
        setreqState={setreqState}
        setFilterArticle={setFilterArticle}
        filtersArticle={filtersArticle}
        selectedCC={selectedCC}
        setselectedCC={setselectedCC}
        setArticleChanges={setArticleChanges}
        articleChanges={articleChanges}
        articleNew={articleNew}
        setArticleNew={setArticleNew}
        savearticles={savearticles}
      />

      {modalCloneControl.show ? (
        <ModalClone
          title="Traer Requisición"
          key={"modalClone" + trigger}
          show={true}
          onHide={() =>
            setmodalCloneControl({
              ...modalCloneControl,
              show: false,
            })
          }
          reqState={reqState}
          setreqState={setreqState}
          optionCostCenter={optionConstCenterState}
          cc_id={findThatDamnCC?.id ? findThatDamnCC?.id : 0}
          savearticles={savearticles}
          selectedCC={selectedCC}
        />
      ) : (
        ""
      )}

      {modalBugdetControl.show ? (
        <ModalBudget
          title="Presupuesto de Requisición"
          key={"modalBudget" + trigger}
          show={true}
          onHide={() =>
            setmodalBugdetControl({
              ...modalBugdetControl,
              show: false,
            })
          }
          reqState={reqState}
          setreqState={setreqState}
          optionCostCenter={optionConstCenterState}
          sendrequisition={sendrequisition}
        />
      ) : (
        ""
      )}

      <div className={reqStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {reqState?.articulos?.length ? filters.page : ""}
            {" de "}
            {Math.ceil(reqState?.articulos?.length / filters.perpage)
              ? Math.ceil(reqState?.articulos?.length / filters.perpage)
              : ""}{" "}
            ({reqState?.articulos?.length} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={reqState?.articulos?.length}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>

        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Observaciones</p>
          <textarea
            id="txtArea"
            rows="10"
            cols="10"
            style={{ border: "1px solid #7FADDE" }}
            placeholder="Escribir..."
            // className={reqStyles.textArea}
            className="textArea darkGray px-2 "
            value={reqState.observations}
            onChange={(e) =>
              setreqState({
                ...reqState,
                observations: e.target.value,
              })
            }
          ></textarea>
        </div>

        <div className={reqStyles.bottomRow}>
          <Button
            className={tableStyles.btnPrimary}
            onClick={() => buttonOk()}
            disabled={!!!reqState.id_consumption_center}
          >
            Enviar
          </Button>
          <Button
            className={tableStyles.btnSecondary}
            onClick={() => buttonCancel()}
            disabled={!!!reqState.id_consumption_center}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RequisitionForm;
