import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Select from "react-select";
import topArrow from "../../assets/img/icons/topArrow.svg";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Chart from "react-apexcharts";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import {
  month,
  year,
  formatToRcSelect,
  day,
  daysOfTheMonth,
} from "../../helpers/helpers";

import { customSelectNew } from "../Layouts/react-select-custom";
import {
  donutOptions,
  highChartVerticalBars,
  highChartHorizontalLine,
} from "../../helpers/constants";
import {
  clientBussinessWallet,
  getDebtTotalAcc,
  getProvisionedVsCollected,
  getWalletByAges,
  getRadications,
  getTotalAcc,
  unFiledInvoices,
  getProvisionedVsCollectedByClient,
} from "../../actions/managementActions";
import { getCorporateClients } from "../../actions/corporateClientsActions";

export const Wallet = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  let radicationList = store?.managementReducer?.radicationList;
  let totalDebtAcc = store?.managementReducer?.totalDebtAcc;
  let provisionedVsCollected = store?.managementReducer?.provisionedVsCollected;
  let loadingProvisionedVsCollected =
    store?.managementReducer?.provisionedVsCollected?.loading;
  let walletByAges = store?.managementReducer?.walletByAges;
  let loadingWalletByAges = store?.managementReducer?.walletByAges?.loading;
  let provVsCol = store?.managementReducer?.provisionedVsCollectedByClient;

  let totalAcc = store?.managementReducer?.totalAcc;
  let unfiledList = store?.managementReducer?.unfiledList;
  let bussinnessWalletList = store?.managementReducer?.bussinnessWalletList;
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "indWallet"
    );
  const token = store.loginReducer.Authorization;
  const seriesProvCol = provVsCol?.series;
  const labelsProvCol = provVsCol?.labels;

  let corporateClientList = store?.corporateclientsReducer?.corporateClients;
  let formattedClients = formatToRcSelect(
    corporateClientList,
    "id",
    "copyName"
  );
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowButton(false);
    }, 15000);
  }, [showButton]);

  const [filters, setFilters] = useState({
    month: month(),
    year: year(),
  });
  const [filtersBill, setFiltersBill] = useState({
    month: month(),
    year: year(),
  });
  const [filtersProv, setFiltersProv] = useState({
    month: month(),
    year: year(),
    day: day(),
    client: "all",
  });
  const [trigger, setTrigger] = useState(0);
  useEffect(() => {
    dispatch(
      getCorporateClients({
        id_account: store.loginReducer.currentAccount.id,
      })
    );
  }, []);

  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/gerencia/inicio");
    }

    dispatch(
      getRadications({
        entity_account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      })
    );
    dispatch(
      unFiledInvoices({
        entity_account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      })
    );
  }, [filters, trigger]);

  let formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
  });

  useEffect(() => {
    dispatch(
      getTotalAcc({
        entity_account: store.loginReducer.currentAccount.id,
        month: filtersBill.month,
        year: filtersBill.year,
      })
    );
    dispatch(
      clientBussinessWallet({
        entity_account: store.loginReducer.currentAccount.id,
        month: filtersBill.month,
        year: filtersBill.year,
      })
    );
    dispatch(
      getWalletByAges({
        entity_account: store.loginReducer.currentAccount.id,
        month: filtersBill.month,
        year: filtersBill.year,
        client: filtersBill.client,
      })
    );
  }, [filtersBill]);

  useEffect(() => {
    dispatch(
      getDebtTotalAcc({
        entity_account: store.loginReducer.currentAccount.id,
        month: filtersProv.month,
        year: filtersProv.year,
        day: filtersProv.day,
      })
    );
    dispatch(
      getProvisionedVsCollected({
        entity_account: store.loginReducer.currentAccount.id,
        month: filtersProv.month,
        year: filtersProv.year,
        day: filtersProv.day,
      })
    );

    dispatch(
      getProvisionedVsCollectedByClient({
        entity_account: store.loginReducer.currentAccount.id,
        month: filtersProv.month,
        year: filtersProv.year,
        client: filtersProv.client,
      })
    );
  }, [filtersProv]);

  const getFivePreviousYear = () => {
    let array = [];
    let current_year = year();
    for (let step = 0; step <= 5; step++) {
      array.push(current_year - step);
    }
    return array;
  };

  return (
    <>
      {showButton && (
        <a
          width="25px"
          className={`${tableStyles.noTextDecoration} ${tableStyles.backToTop} p-3 center`}
          href="#home"
        >
          <img className={`center`} src={topArrow}></img>
        </a>
      )}
      <div
        className={`${tableStyles.container}`}
        style={{ overflowX: "hidden" }}
        onScroll={() => setShowButton(true)}
      >
        <div className={tableStyles.tableArea}>
          <Row>
            <Col xs={8} className={``} id="home">
              <h1 className={tableStyles.title}>Indicadores de cartera</h1>
            </Col>
            <Col
              xs={4}
              className={`text-end d-flex justify-content-between`}
              style={{ paddingTop: "5rem" }}
            >
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a
                  className={`${tableStyles.noTextDecoration}`}
                  href="#Radication"
                >
                  <b>Radicación</b>
                </a>
              </h6>
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a
                  className={`${tableStyles.noTextDecoration}`}
                  href="#AccDebt"
                >
                  <b>Cuentas por cobrar</b>{" "}
                </a>
              </h6>
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a
                  className={`${tableStyles.noTextDecoration}`}
                  href="#Provision"
                >
                  <b>Provisión</b>{" "}
                </a>
              </h6>
            </Col>
          </Row>
          <hr></hr>
          <Row className="mb-4" id="Radication">
            <Row className="justify-content-between mb-2">
              <Col xs={3} className=" text-start">
                <h3 className={tableStyles.darkBlueText}>
                  <b>Radicación</b>
                </h3>
              </Col>
              <Col xs={3} className=" text-end mb-2">
                <select
                  value={filters.month}
                  onChange={(e) =>
                    setFilters({ ...filters, month: Number(e.target.value) })
                  }
                  placeHolder={"Mes"}
                  className={tableStyles.simpleDropDown}
                >
                  <option value={1}>Enero</option>
                  <option value={2}>Febrero</option>
                  <option value={3}>Marzo</option>
                  <option value={4}>Abril</option>
                  <option value={5}>Mayo</option>
                  <option value={6}>Junio</option>
                  <option value={7}>Julio</option>
                  <option value={8}>Agosto</option>
                  <option value={9}>Septiembre</option>
                  <option value={10}>Octubre</option>
                  <option value={11}>Noviembre</option>
                  <option value={12}>Diciembre</option>
                </select>
                <select
                  value={filters.year}
                  onChange={(e) =>
                    setFilters({ ...filters, year: Number(e.target.value) })
                  }
                  placeHolder={"Año"}
                  className={tableStyles.simpleDropDown}
                >
                  {getFivePreviousYear().map((e) => {
                    return <option value={e}>{e}</option>;
                  })}
                </select>
              </Col>
            </Row>

            <Col xs={6}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`text-start `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Facturas radicadas vs sin radicar</b>
                    </p>

                    {radicationList?.labels?.length > 0 &&
                    radicationList?.data?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels:
                            radicationList?.labels?.length > 0
                              ? radicationList?.labels
                              : [],
                        })}
                        series={
                          radicationList?.data?.length > 0
                            ? radicationList?.data
                            : []
                        }
                        type="donut"
                        // width={400}
                        height={450}
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ["Cargando..."],
                          colors: ["gray"],
                        })}
                        series={[50]}
                        type="donut"
                        // height={900}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`text-start `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Facturas sin radicar</b>
                    </p>

                    {unfiledList?.labels?.length > 0 &&
                    unfiledList?.data?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels:
                            unfiledList?.labels?.length > 0
                              ? unfiledList?.labels
                              : [],
                        })}
                        series={
                          unfiledList?.data?.length > 0 ? unfiledList?.data : []
                        }
                        type="donut"
                        // width={400}
                        height={450}
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ["Cargando..."],
                          colors: ["gray"],
                        })}
                        series={[50]}
                        type="donut"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* /* ---------------------------- accounts and debts --------------------------- */}
          <hr></hr>
          <h3 className={`${tableStyles.darkBlueText} mt-4`} id="AccDebt">
            <b>Cuentas por cobrar y mora</b>
          </h3>
          <Row className="mb-4">
            <Row className="justify-content-end mb-2">
              <Col xs={2} className=" text-end">
                <select
                  value={filtersBill.month}
                  onChange={(e) =>
                    setFiltersBill({
                      ...filtersBill,
                      month: Number(e.target.value),
                    })
                  }
                  placeHolder={"Mes"}
                  className={tableStyles.simpleDropDown}
                >
                  <option value={1}>Enero</option>
                  <option value={2}>Febrero</option>
                  <option value={3}>Marzo</option>
                  <option value={4}>Abril</option>
                  <option value={5}>Mayo</option>
                  <option value={6}>Junio</option>
                  <option value={7}>Julio</option>
                  <option value={8}>Agosto</option>
                  <option value={9}>Septiembre</option>
                  <option value={10}>Octubre</option>
                  <option value={11}>Noviembre</option>
                  <option value={12}>Diciembre</option>
                </select>
              </Col>
              <Col xs={1} className=" text-end">
                <select
                  value={filtersBill.year}
                  onChange={(e) =>
                    setFiltersBill({
                      ...filtersBill,
                      year: Number(e.target.value),
                    })
                  }
                  placeHolder={"Año"}
                  className={tableStyles.simpleDropDown}
                >
                  {getFivePreviousYear().map((e) => {
                    return <option value={e}>{e}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Col xs={5}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={` text-start`}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <span className={`text-secondary`}>
                        Total de cuentas por cobrar
                      </span>
                      <br></br>
                      <b>{formatter.format(totalAcc)}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={` text-start`}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Consumo de presupuesto</b>
                    </p>

                    {bussinnessWalletList?.labels?.length > 0 &&
                    bussinnessWalletList?.data?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels:
                            bussinnessWalletList?.labels?.length > 0
                              ? bussinnessWalletList?.labels
                              : [],
                        })}
                        series={
                          bussinnessWalletList?.data?.length > 0
                            ? bussinnessWalletList?.data
                            : []
                        }
                        type="donut"
                        // width={400}
                        height={395}
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ["Cargando..."],
                          colors: ["gray"],
                        })}
                        series={[50]}
                        type="donut"
                        // height={900}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>{" "}
            {/* /* ---------------------------- Wallet by age ---------------------------- */}
            <Col xs={7}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2 pt-0`}>
                <Row className={`pb-2 rounded ${tableStyles.bgColorClearBlue}`}>
                  <Col xs={5}>
                    <label className={tableStyles.crudModalLabel}>
                      Cliente-empresa
                    </label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      className="text-secondary"
                      placeholder={"Seleccione"}
                      options={formattedClients}
                      onChange={(e) =>
                        setFiltersBill({ ...filtersBill, client: e.value })
                      }
                      styles={customSelectNew}
                    ></Select>
                  </Col>
                </Row>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Cartera por edades</b>
                    </p>
                    {loadingWalletByAges && (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartVerticalBars({
                          series: [
                            {
                              name: "Cargando...",
                              data: [
                                ["Cargando...", 3],
                                ["Cargando...", 10],
                                ["Cargando...", 20],
                                ["Cargando...", 30],
                                ["Cargando...", 40],
                                ["Cargando...", 50],
                                ["Cargando...", 60],
                                ["Cargando...", 70],
                                ["Cargando...", 80],
                                ["Cargando...", 90],
                                ["Cargando...", 100],
                                ["Cargando...", 90],
                                ["Cargando...", 80],
                                ["Cargando...", 70],
                                ["Cargando...", 60],
                                ["Cargando...", 50],
                                ["Cargando...", 40],
                                ["Cargando...", 30],
                                ["Cargando...", 20],
                                ["Cargando...", 10],
                                ["Cargando...", 3],
                              ],
                            },
                          ],
                          colors: ["gray"],
                        })}
                      />
                    )}
                    <div
                      style={{
                        display: loadingWalletByAges ? "none" : "block",
                      }}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartVerticalBars({
                          series: walletByAges?.results?.series,
                          categories: walletByAges?.results?.categories,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>{" "}
          <hr></hr>
          {/* /* ----------------------------  Provisión --------------------------- */}
          <h3 className={tableStyles.darkBlueText} id="Provision">
            <b>Provisión</b>
          </h3>
          <Row className="mb-4">
            <Row className="justify-content-end mb-2">
              <Col xs={2} className=" text-end">
                <select
                  value={filtersProv.month}
                  onChange={(e) =>
                    setFiltersProv({
                      ...filtersProv,
                      month: Number(e.target.value),
                    })
                  }
                  placeHolder={"Mes"}
                  className={tableStyles.simpleDropDown}
                >
                  <option value={1}>Enero</option>
                  <option value={2}>Febrero</option>
                  <option value={3}>Marzo</option>
                  <option value={4}>Abril</option>
                  <option value={5}>Mayo</option>
                  <option value={6}>Junio</option>
                  <option value={7}>Julio</option>
                  <option value={8}>Agosto</option>
                  <option value={9}>Septiembre</option>
                  <option value={10}>Octubre</option>
                  <option value={11}>Noviembre</option>
                  <option value={12}>Diciembre</option>
                </select>
              </Col>
              <Col xs={1} className=" text-end">
                <select
                  value={filtersProv.year}
                  onChange={(e) =>
                    setFiltersProv({
                      ...filtersProv,
                      year: Number(e.target.value),
                    })
                  }
                  placeHolder={"Año"}
                  className={tableStyles.simpleDropDown}
                >
                  {getFivePreviousYear().map((e) => {
                    return <option value={e}>{e}</option>;
                  })}
                </select>
              </Col>

              <Col xs={1} className="">
                <select
                  value={filtersProv.day}
                  onChange={(e) =>
                    setFiltersProv({
                      ...filtersProv,
                      day: Number(e.target.value),
                    })
                  }
                  style={{ width: "70%" }}
                  placeHolder={"Día"}
                  className={tableStyles.simpleDropDown}
                >
                  {daysOfTheMonth.map((e) => {
                    return <option value={e.value}>{e.label}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Col xs={5}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={` text-start`}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Total actual provisionado</b>
                      <br></br>
                      <b>{formatter.format(totalDebtAcc)}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={` text-start`}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Provisionado vs recaudado</b>
                    </p>

                    {provisionedVsCollected?.labels?.length > 0 &&
                    provisionedVsCollected?.data?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels:
                            provisionedVsCollected?.labels?.length > 0
                              ? provisionedVsCollected?.labels
                              : [],
                        })}
                        series={
                          provisionedVsCollected?.data?.length > 0
                            ? provisionedVsCollected?.data
                            : []
                        }
                        type="donut"
                        // width={400}
                        height={395}
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ["Cargando..."],
                          colors: ["gray"],
                        })}
                        series={[50]}
                        type="donut"
                        // height={900}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>{" "}
            <Col xs={7}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2 pt-0`}>
                <Row className={`pb-2 rounded ${tableStyles.bgColorClearBlue}`}>
                  <Col xs={5}>
                    <label className={tableStyles.crudModalLabel}>
                      Cliente-empresa
                    </label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      className="text-secondary"
                      placeholder={"Seleccione"}
                      options={formattedClients}
                      onChange={(e) =>
                        setFiltersProv({ ...filtersProv, client: e.value })
                      }
                      styles={customSelectNew}
                    ></Select>
                  </Col>
                </Row>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Provisionado vs recaudado</b>
                    </p>
                    {loadingProvisionedVsCollected ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartHorizontalLine({
                          series: [
                            {
                              name: "Cargando...",
                              data: [
                                3, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90,
                                80, 70, 60, 50, 40, 30, 20, 10, 3,
                              ],
                            },
                            // { name: "Cargando...", data: [30, 50, 100] },
                          ],
                          categories: [
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                            "Cargando...",
                          ],
                          colors: ["gray"],
                        })}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartHorizontalLine({
                          series: seriesProvCol,
                          categories: labelsProvCol,
                          colors: ["#01A0F6", "#003F80"],
                        })}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>{" "}
          <hr></hr>
        </div>
      </div>
    </>
  );
};
