// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router";

// --------------- icons 💥  ----------------//
import Excel from "../../assets/img/icons/excel.svg";
import Imprimir from "../../assets/img/icons/imprimir.svg";

//-------------- scss styles 😄   --------------
import Select from "react-select";
import {
  customSelectNewDark,
} from "../../components/Layouts/react-select-custom";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import Styles from "./Reports.module.scss";
import StyleGloses from "../../billing/glosses/Glosses.module.scss";

// ------------------ Functions ----------------------

import { journalReport } from "../../actions/reportsActions";
import { reportsReducer } from "../../reducers/reportsReducer";
import { getJournalType } from "../../actions/accountingConsultsActions";
import { getPdfTest } from "../../actions/consultAction";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import { message } from "../../helpers/helpers";
import { convertFilterToString } from "../../helpers/convertToFilter";
import { API_VERSION, URL_GATEWAY } from "../../helpers/constants";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import { Title } from "../../shared";

function GeneralJournal(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const token = storage.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const [base64, setBase64] = useState("");


  useEffect(() => {
    if (showPdf) { getPdf() }
  }, [showPdf])

  const [info, setInfo] = useState({
    reportType: "generalDaily",
    jrnlType: "",
    dateFrom: "",
    dateUntil: "",
    orderType: "",
    eaccount: storage.loginReducer.currentAccount.id,
    page: 1,
    perpage: 10,
    excludeVoucher: false,
    dateReport: false,
    pagination: false,
    sendInfo: false,
  });

  const [showSection, setShowSection] = useState(false);
  const [loading, setLoading] = useState(true);

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "generalJou"
    );

  //--------------first functions 🍕   -----------

  const getPdf = async () => {
    setBase64("");
    // const result = await getPdfTest(
    //   "",
    //   storage.loginReducer.Authorization,
    //   "treasury/consultation_of_bank_notes"
    // )
    // if (result?.success) {
    //   return setBase64(result?.result?.base64)
    // } else {
    //   setShowPdf(false)
    //   message('error', 'Ha ocurrido un error', result?.message)
    // }
    const treatedInfo = { ...info };
    delete treatedInfo.page;
    delete treatedInfo.perpage;
    const filters = convertFilterToString(treatedInfo);
    const result = await fetch(`${URL_GATEWAY}${API_VERSION}/composite/documentPdf_generalDaily/?${filters}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "pdf_body": "dailyGeneral",
          "pdf_row": "dailyGeneralLine",
        }),
      }
    )
      .then((response) => response.json())
      .then(res => {
        if (res.success) {
          let treatPdf = res.key.replace(/^b'/gm, "").replace(/=*'$/gm, "");
          setBase64(treatPdf);
        } else {
          setShowPdf(false);
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `Ha ocurrido un error`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false
          });

        }
      }).catch(err => {
        console.error(err);
      })

  }

  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push("/contabilidad/inicio");
    }
  }, []);

  useEffect(() => {
    if (!!info.dateFrom != "" && !!info.dateUntil != "" && !!info.jrnlType != "" && !!info.orderType != "") {
      dispatch(journalReport(info));
    }
    dispatch(getJournalType({ eaccount: storage.loginReducer.currentAccount.id }, () => setLoading(false)));

  }, [info.page]);


  //-------------- select options 🍕   --------------

  let optionsJournalType = [{ key: "", value: "", label: "Seleccionar..." }];
  if (Array.isArray(storage.accountingConsultsReducer.listJournalType)) {
    storage.accountingConsultsReducer.listJournalType.map((item, index) => {
      optionsJournalType.push({
        value: item.id,
        label: item.name,
        key: index + "Jtype",
      });
    });

  }


  //--------------  Actions  🍕   --------------

  const handleRadio = (value) => {
    if (value === "1") { setInfo({ ...info, orderType: 1 }) }
    if (value === "2") { setInfo({ ...info, orderType: 2 }) }
    if (value === "3") { setInfo({ ...info, orderType: 3 }) }
    if (value === "4") { setInfo({ ...info, orderType: 4 }) }
  }

  const generateReport = () => {
    dispatch(journalReport(info));
    setShowSection(true)
  }

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  }



  return (
    <>

      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        {(loading) &&
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        }
        <div className={tableStyles.title}>
          <Title
            title="Informe diario general"
            onClickIcon={() => {
              history.push('/contabilidad/inicio')
            }}
          />
        </div>
        <div className="d-flex">

          {/*----------------------------FIRST SECTION---------------------------------- */}
          <div
            className={`${tableStyles.container}`}
            style={{
              width: "35%",
              paddingLeft: "0px",
              borderRight: "1px solid #CECECE",
              marginRight: "1px",
            }}
          >
            <Row className="d-flex">
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>Fecha desde<span className={"text-warning"}>*</span></p>
                <input
                  key={"DateFrom" + trigger}
                  className={IndividualStyles.registerInputsBlue}
                  type="date"
                  value={info.dateFrom}
                  onChange={(e) => setInfo({
                    ...info,
                    dateFrom: e.target.value
                  })}
                />
              </Col>
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>Hasta<span className={"text-warning"}>*</span></p>
                <input
                  key={"DateUntil" + trigger}
                  className={IndividualStyles.registerInputsBlue}
                  type="date"
                  value={info.dateUntil}
                  onChange={(e) => setInfo({
                    ...info,
                    dateUntil: e.target.value
                  })}
                />
              </Col>
            </Row>

            <Row
              className="d-flex"
              style={{
                background: "rgba(0, 93, 191, 0.05)",
                borderRadius: "10px",
                height: "82px",
                marginRight: "1px",
                marginLeft: "1px",
                marginTop: "16px",
                paddingRight: "8px",
                paddingLeft: "8px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Tipo de comprobante
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={"typeVoucher" + trigger}
                  options={optionsJournalType}
                  placeholder="Seleccionar..."
                  styles={customSelectNewDark}
                  onChange={(e) =>
                    setInfo({
                      ...info,
                      jrnlType: e.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row
              className="d-flex"
              style={{ paddingTop: "24px", paddingBottom: "16px" }}
            >
              <p
                className={tableStyles.crudModalLabel}
                style={{ fontWeight: "700", fontSize: "16px" }}
              >
                Ordenar por<span className={"text-warning"}>*</span>
              </p>
            </Row>

            <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "15px" }}>
                <input
                  className={"form-check-input "}
                  //style={{margin: "margin: 8px 0px 0px 0px;"}}
                  //style={{marginTop:"5px"}}
                  type="radio"
                  value="1"
                  disabled={false}
                  onChange={(e) => handleRadio(e.target.value)}
                  checked={info.orderType == 1 ? true : false}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: "#58595B",
                    marginTop: "4px",
                    fontSize: "13px",
                    marginLeft: "1px",
                  }}
                >
                  Número de comprobante, cuenta y NIT
                </p>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "15px" }}>
                <input
                  className={"form-check-input"}
                  type="radio"
                  value="2"
                  disabled={false}
                  onChange={(e) => handleRadio(e.target.value)}
                  checked={info.orderType == 2 ? true : false}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: "#58595B",
                    marginTop: "4px",
                    fontSize: "13px",
                    marginLeft: "1px",
                  }}
                >
                  Tipo de comprobante, No. de comprobante
                </p>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "15px" }}>
                <input
                  className={"form-check-input"}
                  type="radio"
                  value="3"
                  disabled={false}
                  onChange={(e) => handleRadio(e.target.value)}
                  checked={info.orderType == 3 ? true : false}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: "#58595B",
                    marginTop: "4px",
                    fontSize: "13px",
                    marginLeft: "1px",
                  }}
                >
                  Fecha, Número de comprobante y NIT
                </p>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "15px" }}>
                <input
                  className={"form-check-input"}
                  type="radio"
                  value="4"
                  disabled={false}
                  onChange={(e) => handleRadio(e.target.value)}
                  checked={info.orderType == 4 ? true : false}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: "#58595B",
                    marginTop: "4px",
                    fontSize: "13px",
                    marginLeft: "1px",
                  }}
                >
                  Tipo de movimiento, fecha, y número de <br /> comprobante
                </p>
              </Col>
            </Row>

            <Row
              className="d-flex"
              style={{ paddingTop: "24px", paddingBottom: "16px" }}
            >
              <p
                className={tableStyles.crudModalLabel}
                style={{ fontWeight: "700", fontSize: "16px" }}
              >
                Opciones
              </p>
            </Row>

            <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "18px" }}>
                <input
                  className="border border-dark-blue form-check-input p1 check-dark-blue"
                  type="checkbox"
                  name="edit"
                  value="selectedAll"
                  checked={info.dateReport}
                  onChange={e => { setInfo({ ...info, dateReport: e.target.checked }) }}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                >
                  No imprimir fecha en informe
                </p>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "18px" }}>
                <input
                  className="border border-dark-blue form-check-input p1 check-dark-blue"
                  type="checkbox"
                  name="edit"
                  value="selectedAll"
                  checked={info.excludeVoucher}
                  onChange={e => setInfo({ ...info, excludeVoucher: e.target.checked })}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                >
                  Excluir los comprobantes de cierre
                </p>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "18px" }}>
                <input
                  className="border border-dark-blue form-check-input p1 check-dark-blue"
                  type="checkbox"
                  name="edit"
                  value="selectedAll"
                  checked={info.pagination}
                  onChange={e => setInfo({ ...info, pagination: e.target.checked })}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                >
                  Imprimir en páginas numeradas
                </p>
              </Col>
            </Row>

            <Row
              className="d-flex"
              style={{ marginTop: "40px", marginButton: "5px" }}
            >
              <Col xs={3} style={{ marginRight: "5%" }}>
                <Button
                  className={`${IndividualStyles.btnPrimary}`}
                  onClick={() => {
                    setShowSection(false);
                    setInfo({
                      reportType: "generalDaily",
                      jrnlType: 1,
                      dateFrom: "",
                      dateUntil: "",
                      orderType: "",
                      eaccount: storage.loginReducer.currentAccount.id,
                      page: 1,
                      perpage: 10,
                      excludeVoucher: false,
                      dateReport: false,
                      pagination: false
                    });
                    setTrigger(trigger + 1)
                  }}
                  disabled={false}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={7}>
                <Button
                  className={IndividualStyles.btnPrimaryDark}
                  onClick={() => generateReport()}
                  disabled={!!info.dateFrom == "" || !!info.dateUntil == "" || !!info.orderType == ""}
                >
                  Generar informe
                </Button>
              </Col>
              {/* <Col xs={4}>

                        </Col> */}
            </Row>
          </div>

          {/*----------------------------END FIRST SECTION---------------------------------- */}

          {/*----------------------------SECOND SECTION---------------------------------- */}
          {showSection ?
            <div style={{ width: "65%", display: "flex", flexDirection: "column", paddingLeft: "40px" }}>

              {storage.reportsReducer.loadingJR &&
                <div className="loading">
                  <Loader type="Oval" color="#003f80" height={100} width={100} />
                </div>
              }

              <div className=" display-grid col-12" style={{ textAlignLast: "end" }}>
                <div className="col" style={{ textAlignLast: "end" }}>
                  <img
                    className={`${Styles.btn} `}
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    src={Imprimir}
                    onClick={() => setShowPdf(true)}
                  />
                  <img className={`${Styles.btn} `} src={Excel} />
                </div>
              </div>

              <div style={{ marginTop: "9px", backgroundColor: "#FFFF", padding: "21px", height: "100%", width: "100%", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)" }}>
                <Row>
                  <Col xs={3}>
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                    >
                      INFORME DIARIO GENERAL

                    </label>
                  </Col>
                  <Col xs={6}></Col>
                  <Col xs={3} style={{ textAlign: "end" }}>
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#CECECE" }}
                    >
                      Fundación Oftamológica del Caribe

                    </label>

                  </Col>

                </Row>
                {storage.reportsReducer.dateReport ?

                  <span></span>
                  :
                  <Row>
                    <Col xs={3}>
                      <label
                        style={{ fontWeight: "400", fontSize: "11px", color: "#58595B" }}
                      >
                        Del día {info.dateFrom?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')} al día {info.dateUntil?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
                      </label>
                    </Col>
                  </Row>

                }

                <Row>
                  <Col xs={3}>
                    <label
                      style={{ fontWeight: "400", fontSize: "11px", color: "#58595B" }}
                    >
                      LOCAL
                    </label>
                  </Col>
                </Row>

                <Row style={{ marginTop: "20px" }}>
                  <Col xs={1}>
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                    >
                      Fecha
                    </label>
                  </Col>
                  <Col xs={1}>
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                    >
                      No.Cpte
                    </label>
                  </Col>
                  <Col xs={1}>
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                    >
                      No.Dto
                    </label>
                  </Col>
                  <Col xs={1}>
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                    >
                      Cuenta
                    </label>
                  </Col>
                  <Col xs={2}>
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                    >
                      NIT
                    </label>
                  </Col>
                  <Col xs={4} style={{ textAlign: "start" }} >
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                    >
                      Nombres
                    </label>
                  </Col>
                  <Col xs={1} style={{ textAlign: "end" }}>
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                    >
                      Debe
                    </label>
                  </Col>
                  <Col xs={1} style={{ textAlign: "end" }}>
                    <label
                      style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                    >
                      Haber
                    </label>
                  </Col>

                </Row>

                {storage.reportsReducer.getJournalReport?.map((x, realindex) => (

                  <div className={`d-flex`} key={"table" + realindex} >
                    <div className="col-1">
                      <label
                        style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                      >
                        {!!x.date ? x.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') : "-"}
                      </label>
                    </div>
                    <div className="col-1">
                      <label
                        style={{ fontWeight: "400", fontSize: "9px", color: "#58595B", textAlign: "center" }}
                      >
                        {!!x.docNbr ? x.docNbr : "-"}
                      </label>
                    </div>
                    <div className="col-1">
                      <label
                        style={{ fontWeight: "400", fontSize: "9px", color: "#58595B", textAlign: "center" }}
                      >
                        {!!x.accountNbr ? x.accountNbr : "-"}
                      </label>
                    </div>
                    <div className="col-1">
                      <label
                        style={{ fontWeight: "400", fontSize: "9px", color: "#58595B", textAlign: "center" }}
                      >
                        {!!x.accountNbr ? x.accountNbr : "-"}
                      </label>
                    </div>
                    <div className="col-2">
                      <label
                        style={{ fontWeight: "400", fontSize: "9px", color: "#58595B", textAlign: "center" }}
                      >
                        {!!x.thirdNit ? x.thirdNit : "-"}
                      </label>
                    </div>
                    <div className="col-4">
                      <label
                        style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                      >
                        {!!x.thirdName ? x.thirdName : "-"}
                      </label>
                    </div>
                    <div className="col-1" style={{ textAlign: "end" }}>
                      <label
                        style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                      >
                        {!!x.debit ? "$" + numberWithCommas(x.debit) : "$"}
                      </label>
                    </div>
                    <div className="col-1" style={{ textAlign: "end" }}>
                      <label
                        style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                      >
                        {!!x.credit ? "$" + numberWithCommas(x.credit) : "$"}

                      </label>
                    </div>

                  </div>
                ))}

                {storage.reportsReducer.pagination ?

                  <Col xs={12} style={{ textAlign: "end" }}>

                    <label
                      style={{ fontWeight: "400", fontSize: "9px", color: "#58595B" }}
                    >
                      {Math.ceil(storage.reportsReducer?.getJournalReport_total / info.perpage) ? info.page + "/" + Math.ceil(storage.reportsReducer?.getJournalReport_total / info.perpage) : ""}
                    </label>


                  </Col>
                  :
                  <span></span>
                }


              </div>

              {/* ----- pagination #️⃣ ----- */}
              <div className={IndividualStyles.bottom} style={{ marginTop: "14px" }}>
                <div className={paginationStyles.wrapper}>
                  <p className={paginationStyles.paginationText}>
                    Pag.{" "}
                    {storage.reportsReducer.getJournalReport_total
                      ? info.page
                      : ""}
                    {" de "}
                    {Math.ceil(
                      storage.reportsReducer?.getJournalReport_total / info.perpage
                    )
                      ? Math.ceil(
                        storage.reportsReducer.getJournalReport_total /
                        info.perpage
                      )
                      : ""}
                  </p>
                  <Pagination
                    activePage={info.page}
                    itemsCountPerPage={10}
                    totalItemsCount={Number(storage.reportsReducer.getJournalReport_total)}
                    pageRangeDisplayed={5}
                    onChange={(e) => setInfo({ ...info, page: e })}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />

                </div>
              </div>

              {/*----------------------------END SECOND SECTION---------------------------------- */}

            </div>
            :
            <span></span>
          }

        </div>
      </div>
      <ModalNew
        title="Informe diario general"
        show={showPdf}
        btnNoName={"Cancelar"}
        size="700"
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer
          downloadable
          file={`data:application/pdf;base64,${base64}`}></PdfViewer>
      </ModalNew>
    </>
  );
}
export default GeneralJournal;