import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Search from "../../../../assets/img/icons/lupa.svg";
import alertCircle from "../../../../assets/img/icons/alert-circle-orange.svg";
import check from "../../../../assets/img/icons/check2.svg";
import backArrow from "../../../../assets/img/icons/atras-icon.svg";
import CheckMark from "../../../../assets/img/icons/CheckMark.svg";
import FalseCross from "../../../../assets/img/icons/FalseCross.svg";
import rejectedIcon from "../../../../assets/img/icons/extraBtnRedX.svg";
import excelIcon from "../../../../assets/img/icons/excelIcon.svg";
import { useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../../Layouts/pagination.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Grid, Button } from "@mui/material";
import tableStyles2 from "../../../../components/Layouts/tableStyle.module.scss";
import { convertMoneyFormat, downloadExcel, loader, message } from "../../../../helpers/helpers";
import { useGetMethod, usePostMethod } from "../../../../Hooks/useFetch";
import { customSwaltAlert } from "../../../../helpers/customSwaltAlert";
import TableScroll from "../../../Layouts/TableScroll/TableScroll";
import { Col, Row } from "react-bootstrap";
import ModalNew from "../../../Layouts/ModalNew";

export const RoyaltyPaymentAuthDetail = ({ backPage, setTrigger }) => {
    const store = useSelector((state) => state);
    const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "plandenom");
    const idAccount = store.loginReducer.currentAccount.id;
    const token = store.loginReducer.Authorization;
    const history = useHistory();
    const [data, setData] = useState({
        trigger: 0,
        filters: {
            search: "",
            page: 1,
            perpage: 10,
            entity_account: idAccount,
            perks_id: backPage?.detailPage?.id,
        },
    });

    const { filters, trigger } = data;
    const { results: PremiumScheduleResults, load: premiumScheduleLoader, trigger: getPremiumSchedule } = useGetMethod();
    const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();
    const { load: excelMethodLoader, trigger: getExcelMethod } = useGetMethod();

    useEffect(() => {
        if (!!!myPermission?.read) {
            history.push("/nomina/inicio");
        }

        getPremiumSchedule({
            url: "/payroll/perks_detail/",
            objFilters: filters,
            token: token,
            doAfterSuccess: (res) => { },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);

    const getExcelFunction = () => {
        getExcelMethod({
            url: "/payroll/perks_detail_excel/",
            objFilters: {
                perks_id: filters.perks_id,
                entity_account: idAccount
            },
            token: token,
            doAfterSuccess: (res) => {
                downloadExcel(res.results.base64, res.results.filename, true);

            },
        });
    };
    const bodyTable = () => {
        let tempArr = [];
        PremiumScheduleResults?.results?.length > 0 &&
            PremiumScheduleResults?.results?.forEach((elem, ind) => {
                tempArr.push(
                    <tr key={elem.id}>
                        <td className={`col-6 px-3 text-start fw-bold`}>
                            <Row className="d-flex" >
                                <Col xs={1} className="px-2 text-center" > {ind + 1}</Col>
                                <Col xs={5} className="px-2 text-start textHide" title={elem.full_name ?? "-"}> {elem.full_name.toUpperCase() ?? "-"}</Col>
                                <Col xs={3} className="px-2 text-center">{elem.doc_number ?? "-"} </Col>
                                <Col xs={3} className="px-2 text-center">{elem.start_date ?? "-"} </Col>
                            </Row>
                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.january ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.february ?? "-")}
                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.march ?? "-")}
                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.april ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.may ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.june ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.july ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.august ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.september ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.october ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.november ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.december ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.total ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.perk_value ?? "-")}

                        </td>
                    </tr>
                );
            });
        return tempArr;
    };
    const renderHeaders = [
        <th key={1} className="text-start col-6 px-3">
            <Row className="d-flex" style={{ width: 600 }}>
                <Col xs={1} className="px-2 text-center"> No.</Col>
                <Col xs={5} className="px-2 text-start">Empleado</Col>
                <Col xs={3} className="px-2 text-center">ID</Col>
                <Col xs={3} className="px-2 text-center">F.Ingreso</Col>
            </Row>
        </th>,

        <th key={2} className="text-end col-2">
            Enero
        </th>,
        <th key={2} className="text-end col-2">
            Febrero
        </th>,
        <th key={2} className="text-end col-2">
            Marzo
        </th>,
        <th key={2} className="text-end col-2">
            Abril
        </th>,
        <th key={2} className="text-end col-2">
            Mayo
        </th>,
        <th key={2} className="text-end col-2">
            Junio
        </th>,
        <th key={2} className="text-end col-2">
            Julio
        </th>,
        <th key={2} className="text-end col-2">
            Agosto
        </th>,
        <th key={2} className="text-end col-2">
            Septiembre
        </th>,
        <th key={2} className="text-end col-2">
            Octubre
        </th>,
        <th key={2} className="text-end col-2">
            Noviembre
        </th>,
        <th key={2} className="text-end col-2">
            Diciembre
        </th>,
        <th key={2} className="text-end col-2">
            Total
        </th>,
        <th key={2} className="text-end col-2">
            Regalía
        </th>,
    ];


    const approveOrDenyPayroll = (status) => {
        customSwaltAlert({
            icon: "warning",
            title: `<span style=color:#01A0F6>¿Está seguro?</span>`,
            text: `Se ${status === "approved" ? "autorizará" : "denegará"} la planilla de cesantías`,
            confirmButtonText: `Si, Continuar`,
            showCancelButton: true,
        }).then((response) => {
            if (response.isConfirmed) {
                updatePayroll({
                    url: "/payroll/perks/",
                    token: token,
                    method: "PUT",
                    body: {
                        persk_id: data?.filters?.perks_id,
                        entity_account: idAccount,
                        status: status,
                        description: status === "rejected" ? commentModal.comment : null,
                    },
                    succesAction: (info) => {
                        setTrigger((state) => ({ ...state, trigger: state.trigger + 1 }));
                        backPage?.setDetailPage((state) => null);
                        customSwaltAlert({
                            title: `Planilla de regalías pascuales ${status === "approved" ? "autorizada" : "denegada"}`,
                            icon: "success",
                            confirmButtonText: "Aceptar",
                            showCancelButton: false,
                        });
                    },
                });
            }
        });
    };

    const [commentModal, setCommentModal] = useState({
        show: false,
        comment: "",
    });

    const closeCommentModal = () => {
        setCommentModal({
            ...commentModal,
            show: false,
            comment: ""
        });
    };

    return (
        <>
            {(premiumScheduleLoader || excelMethodLoader || updatePayrollLoader) && loader}
            <ModalNew
                title="Comentarios"
                show={commentModal.show}
                btnNoName={"Cerrar"}
                size="400"
                btnYesEvent={() => commentModal.comment !== "" ? approveOrDenyPayroll("rejected") : message("warning", "Intenta de nuevo", "El comentario es obligatorio para denegar una planilla", undefined, true)}
                onHide={() =>
                    closeCommentModal()
                }
                btnNoEvent={() =>
                    closeCommentModal()
                }
                btnNoDisabled={false}
            >
                <p className={tableStyles.crudModalLabel}>
                    Comentario
                </p>
                <textarea
                    placeholder={"Escribir..."}
                    id="txtArea"
                    rows="10"
                    cols="10"
                    onChange={({ target }) => {
                        setCommentModal({ ...commentModal, comment: target.value });
                    }}
                    style={{
                        height: "4rem", borderRadius: "5px", border: "1.5px solid #01A0F6", padding: "0 0.05rem",
                        width: "100%"
                    }}
                    className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
                ></textarea>
            </ModalNew >
            <div className="w-80 mx-auto">
                <h2
                    style={{ color: "#005dbf", marginLeft: "-37px" }}
                    className={`${tableStyles.title} fw-bold d-flex gap-3`}
                >
                    <div>
                        <img
                            onClick={() => {
                                backPage.setDetailPage((state) => null);
                                setTrigger((state) => ({ ...state, trigger: state.trigger + 1 }));
                            }}
                            src={backArrow}
                            alt="backArrow"
                            className={`${tableStyles2.svgDarkBlueFilter} pointer`}
                        />
                    </div>

                    <div>
                        <div>{` ${backPage.detailPage.period} - ${backPage.detailPage.payment_year}`}</div>
                        {backPage?.detailPage?.status?.name === "review" ? (
                            <div>
                                <div
                                    className="d-flex gap-2 align-items-center"
                                    style={{ margin: "10px 0px 0px 5px", color: "#ff8b00" }}
                                >
                                    <span style={{ fontSize: "18px" }}>Revisión pendiente</span>
                                    <img
                                        height={20}
                                        src={alertCircle}
                                        alt="alertCircle"
                                    />
                                </div>
                            </div>
                        ) : backPage?.detailPage?.status?.name === "rejected" ? (
                            <div>
                                <div
                                    className="d-flex gap-2 align-items-center"
                                    style={{ margin: "10px 0px 0px 5px", color: "rgba(236, 28, 36, 1)" }}
                                >
                                    <span style={{ fontSize: "18px" }}>Planilla rechazada</span>
                                    <img
                                        height={15}
                                        src={rejectedIcon}
                                        alt="rejectedIcon"
                                    />
                                </div>
                            </div>
                        ) : (
                            backPage?.detailPage?.status?.name === "approved" && (
                                <div>
                                    <div
                                        className="d-flex gap-2 align-items-center"
                                        style={{ margin: "10px 0px 0px 5px", color: "#00a551" }}
                                    >
                                        <span style={{ fontSize: "18px" }}>Planilla autorizada</span>
                                        <img
                                            height={20}
                                            src={check}
                                            alt="check"
                                        />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </h2>

                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        xs={6}
                        className="d-flex align-items-end justify-content-end"
                    >
                        <input
                            className={`${tableStyles.SearchNew} mr-2`} //${tableStyles.clearBlueSearch}
                            style={{ flex: 1, color: `#73a6dc` }}
                            name="search"
                            type="text"
                            placeholder="Buscar por ID, Empleado, Días laborados..."
                            value={filters.search}
                            onChange={(e) => setData((state) => ({ ...state, filters: { ...state.filters, search: e.target.value } }))}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setData((state) => ({ ...state, trigger: state.trigger + 1 }));
                                }
                            }}
                        />

                        <img
                            src={Search}
                            style={{ cursor: "pointer" }}
                            alt="Search icon"
                            onClick={() => {
                                setData((state) => ({ ...state, trigger: state.trigger + 1 }));
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        className="d-flex align-items-end justify-content-end"
                    >
                        <img
                            src={excelIcon}
                            style={{ cursor: "pointer" }}
                            alt="Search icon"
                            onClick={() => {
                                getExcelFunction();
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className="py-4">
                    <Grid
                        item
                        xs={12}
                    >
                        <TableScroll
                            body={bodyTable()}
                            headers={renderHeaders}
                        ></TableScroll>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <div className={paginationStyles.wrapper}>
                            <p className={`${paginationStyles.paginationText} text-secondary`}>
                                Pag. {PremiumScheduleResults?.results ? filters.page : ""}
                                {" de "}
                                {Math.ceil(PremiumScheduleResults?.rowTotal / filters.perpage)
                                    ? Math.ceil(PremiumScheduleResults?.rowTotal / filters.perpage)
                                    : ""}{" "}
                                ({PremiumScheduleResults?.rowTotal} encontrados)
                            </p>
                            <Pagination
                                activePage={filters.page}
                                itemsCountPerPage={filters.perpage}
                                totalItemsCount={PremiumScheduleResults?.rowTotal}
                                pageRangeDisplayed={5}
                                onChange={(e) => setData((state) => ({ ...state, filters: { ...state.filters, page: e }, trigger: state.trigger + 1 }))}
                                itemClassPrev={paginationStyles.itemClassPrev}
                                itemClassNext={paginationStyles.itemClassNext}
                                itemClassFirst={paginationStyles.itemClassFirst}
                                itemClassLast={paginationStyles.itemClassLast}
                                itemClass={paginationStyles.itemClass}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid className="d-flex justify-content-end gap-3 my-3">
                    {backPage?.detailPage?.status?.name !== "review" ? (
                        <>
                            <Button
                                style={{ textTransform: "none" }}
                                className={tableStyles.btnCancelStyle}
                                onClick={() => {
                                    backPage.setDetailPage((state) => null);
                                }}
                            >
                                Cancelar
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                style={{ textTransform: "none" }}
                                className={tableStyles.btnCancelStyle}
                                onClick={() => {
                                    backPage.setDetailPage((state) => null);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                style={{ textTransform: "none" }}
                                className={tableStyles.btnCancelStyle}
                                onClick={() =>
                                    setCommentModal({ ...commentModal, show: true })}
                            >
                                <div className="d-flex gap-2">
                                    <img
                                        src={FalseCross}
                                        alt="FalseCross"
                                    />
                                    <span>Denegar</span>
                                </div>
                            </Button>
                            <Button
                                style={{ textTransform: "none" }}
                                className={tableStyles.btnCancelStyle}
                                onClick={() => approveOrDenyPayroll("approved")}
                            >
                                <div className="d-flex gap-2">
                                    <img
                                        src={CheckMark}
                                        alt="CheckMark"
                                    />
                                    <span>Autorizar</span>
                                </div>
                            </Button>
                        </>
                    )}
                </Grid>
            </div>
        </>




    );
};
