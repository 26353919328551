

import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GET_CHECK_DEPOSIT_BANK, GET_INVOICES, GET_ONE_DEPOSIT_BANK, LOADING } from "./actionTypes";
import { customSwaltAlert } from "../helpers";

const MySwal = withReactContent(Swal);

export const getInvoices = (objFilters, loading) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/invoices/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (loading) {
        loading()
      }
      const { results, amountTotal, row_total } = res;
      dispatch({
        type: GET_INVOICES,
        payload: results,
        total: amountTotal,
        rows: row_total
      });
    })
    .catch((err) => {
      if (loading) {
        loading()
      }
      console.error(err.message);
    });
};

export const update_Invoices = (data, doAfter, doafterLoading = null) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  // }).then((result) => {
  //   if (result.isConfirmed) {
      fetch(
        `${URL_GATEWAY}${API_VERSION}/treasury/invoices/`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
            token: token,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (doafterLoading){
            doafterLoading()
          }
          dispatch({
            type: GET_CHECK_DEPOSIT_BANK,
            payload: [],
          });
          dispatch({
            type: GET_ONE_DEPOSIT_BANK,
            payload: [],
          });

          if (res.success === true) {
            if(doAfter){
              doAfter();
            }
            customSwaltAlert({
              icon: "success",
              title: 'Actualizado exitosamente',
              text: res.RC,
              showCancelButton: false,
            });

          } else {
            customSwaltAlert({
              icon: "warning",
              title: 'Intenta de nuevo',
              text: res.message,
              showCancelButton: false,
            });
          }
        })
        .catch((res) => {
          if (doafterLoading){
            doafterLoading()
          }

          customSwaltAlert({
              icon: "warning",
              title: 'Intenta de nuevo',
              text: res.message,
              showCancelButton: false,
            })
        });
  //   }
  // });
};