import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  BodyContent,
  Container,
  Description,
  Section,
  Timeline,
  YearContent,
} from 'vertical-timeline-reactjs';

import Ellipse from '../../assets/img/icons/Ellipse.svg';
import anonymus from '../../assets/img/icons/anonymus.svg';
import circleGreenCheck from '../../assets/img/icons/circleGreenCheck.svg';
import EditIcon from '../../assets/img/icons/editar.svg';

import {
  getAnnotations,
  getCandidateInfo,
  getDisciplinaryProcess,
  getMedicalInfo,
} from '../../actions/partnersActions';
import { customSwaltAlert } from '../../helpers';
import {
  convertDateToLatinFormat,
  convertMoneyFormat,
  isEmptyOrUndefined,
  placeMyIcon,
} from '../../helpers/helpers';

import ModalNew from '../../components/Layouts/ModalNew';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { DialogModalFullScreen } from '../Layouts/DialogModalFullScreen';
import { PdfViewer } from '../Layouts/PdfViewer';
import { DiscountsAndPayout } from './DiscountsAndPayouts';

import 'reactjs-popup/dist/index.css';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';

export const MyCv = () => {
  const store = useSelector(state => state);
  const [open, setOpen] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [slider, setSlider] = useState({
    infoGen: true,
    attached: false,
    disciplinaryProcess: false,
    annotations: false,
    medicalExams: false,
    examns: false,
    accruedAndDeductions: false,
  });

  useEffect(() => {
    if (!myPermission?.read) {
      history?.push('/configuracion/miperfil');
    }
    if (Object.keys(store?.loginReducer?.user_data?.payrollInfo).length === 0) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Advertencia',
        text: `El usuario no posee un contrato vigente en la cuenta ${store.loginReducer.currentAccount.name.toLowerCase()}`,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          history.push('/accountselect');
        }
      });
    }

    if (slider.infoGen) {
      dispatch(getCandidateInfo({ id_candidate, id_contract, generalInfo: 1 }));
    } else if (slider.attached) {
      dispatch(getCandidateInfo({ id_candidate, id_contract }));
    } else if (slider.annotations) {
      dispatch(getAnnotations({ id_candidate, id_contract, annotations: 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider]);

  useEffect(() => {
    dispatch(getAnnotations({ annotations: 1, id_contract, id_candidate }));
    dispatch(
      getMedicalInfo({
        candidate: id_candidate,
        entity_account: idEnterprise,
      }),
    );
    dispatch(
      getDisciplinaryProcess({
        entity_account: idEnterprise,
        involved: id_candidate,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let id_candidate = store?.loginReducer?.user_data?.payrollInfo?.id_candidate;
  let id_contract = store?.loginReducer?.user_data?.payrollInfo?.id_contract;

  const medicalInfo = store?.partnersReducer?.employee_info;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'myCv',
  );
  const historial = store?.partnersReducer?.medicalInfo?.historial;
  const idEnterprise = store?.loginReducer?.currentAccount.id;
  const employeeInfo = store.partnersReducer?.employee_info;
  const annotations = store.partnersReducer?.employee_info?.annotations;
  const disciplinaryProcess = store.partnersReducer?.disciplinaryProcesses;
  let arrSections = employeeInfo?.sections;

  const downloadExam = url => {
    window.location.href = url;
  };

  const download = url => {
    window.location.href = url;
  };
  const renderSections = () => {
    let sections = [];
    if (Array.isArray(arrSections)) {
      arrSections.forEach((elem, index) => {
        let docs = [];
        if (Array.isArray(employeeInfo?.documents)) {
          const filteredDocs = employeeInfo?.documents.filter(doc => doc.section === elem.section);
          filteredDocs.forEach(elem2 => {
            let filename = elem2?.s3_location?.split('/').pop();
            let ext = filename?.split('.')[1];

            const generaterow = (
              <div key={elem2.id}>
                <div>
                  <div className='mt-1'>
                    <label
                      htmlFor={`checkBox-${elem2.id}`}
                      className={`${tableStyles.darkBlueText} ${tableStyles.f13}`}
                      style={{ marginLeft: '13%' }}
                    >
                      {elem2.doc_description}
                    </label>
                  </div>
                  {isEmptyOrUndefined(elem2.s3_location) ? (
                    <Row>
                      <Col xs={12} className='d-flex'>
                        <img className='mr-2' src={Ellipse} alt='' />

                        <div className={`w-100 ${tableStyles.clearBlueBorder}`}>
                          <input
                            readOnly={true}
                            className={` register-inputs ${tableStyles.clearBlueText}
                            ${IndividualStyles.ellipsis} ${tableStyles.noBorderInput} ${tableStyles.noBorderFocus} `}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs={12} className='d-flex'>
                        <img className='mr-2' src={circleGreenCheck} alt='' />

                        <div className={`w-100 ${tableStyles.clearBlueBorder}`}>
                          <img src={placeMyIcon(ext)} alt='' />
                          <input
                            readOnly={true}
                            style={{
                              backgroundColor: '#eff4fb',
                              textDecoration: 'underline',
                              color: '#4d84fe',
                            }}
                            className={`register-inputs ${tableStyles.clearBlueText} hoverPointer
                           ${IndividualStyles.ellipsis} ${tableStyles.noBorderInput} `}
                            onClick={
                              isEmptyOrUndefined(elem2.s3_location)
                                ? null
                                : () => (window.location.href = elem2.s3_location)
                            }
                            value={
                              isEmptyOrUndefined(elem2.s3_location)
                                ? null
                                : elem2.s3_location.split('/').pop()
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            );
            if (elem2.doc_description) {
              docs.push(generaterow);
            }
          });
        }
        const generateSec = (
          <div key={index}>
            <div className='' style={{ width: '21.5rem' }}>
              <h6>
                <b className={`${tableStyles.darkBlueText}`}>{elem.section}</b>
              </h6>
              {docs.length > 0 ? (
                docs
              ) : (
                <div className={`text-secondary ${tableStyles.f12} justify-content-center `}>
                  No hay documentos disponibles
                </div>
              )}
            </div>
          </div>
        );
        sections.push(generateSec);
      });
    }
    return (
      <div key={'sectionwrap'} className={'mb-4 d-flex justify-content-between'}>
        {sections}
      </div>
    );
  };

  const customTheme = {
    yearColor: '#005dbf',
    lineColor: '#005dbf',
    dotColor: '#005dbf',
    borderDotColor: '#d0cdc4',
    titleColor: '#58595b',
    subtitleColor: '#005dbf',
    textColor: 'gray',
  };
  /* ------------------------- HANDLE CHANGES SECTION ------------------------- */

  const renderDisciplinaryProcessInfo = () => {
    return (
      <>
        {disciplinaryProcess?.length > 0 ? (
          disciplinaryProcess.map((e, i) => {
            return (
              <Row key={i} className={`d-flex justify-content-start `}>
                <Col xs={3} className={`${tableStyles.darkGrayText} `}>
                  <b className={tableStyles.darkBlueText}>•</b>
                  <b className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}>
                    {e.report_date}
                  </b>
                </Col>

                <Col xs={9} className={`text-secondary`}>
                  <span className={tableStyles.fw700End}>
                    <u className={`${tableStyles.darkGrayText} `} title={e.title}>
                      <b
                        onClick={() => {
                          setOpen(true);
                          setSelectedProcess(e);
                        }}
                        className={`${tableStyles.darkGrayText} ${tableStyles.ellipsis} cursorPointer`}
                      >
                        {e.title.length >= 45 ? e.title.slice(0, 45) + '...' : e.title}
                      </b>
                    </u>
                    <br />
                    {!isEmptyOrUndefined(e.reporter) ? e.reporter : '-'}
                  </span>
                </Col>
              </Row>
            );
          })
        ) : (
          <h6 className={`${tableStyles.darkBlueText} pt-4`}>
            <b>No se encontraron procesos disciplinarios</b>
          </h6>
        )}
      </>
    );
  };
  return (
    <>
      {/* /* -------------------------------- PDF Viewer ------------------------------- */}
      <ModalNew
        title='Contratos'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer file={employeeInfo?.signed_doc}></PdfViewer>
      </ModalNew>
      {/* /* ------------------------------------ - ----------------------------------- */}

      {/* /* ------------------------ DIALOG MODAL FULL SCREEN  COMPONENT ------------------------ */}
      {open ? (
        <DialogModalFullScreen
          closeDialog={() => {
            setSlider({
              ...slider,
              infoGen: false,
              attached: false,
              disciplinaryProcess: true,
              annotations: false,
              medicalExams: false,
              examns: false,
            });
            setOpen(false);
          }}
          title={selectedProcess.title}
        >
          <Row className='ml-5'>
            <Col xs={6} className='mt-4 mb-4 ml-5'>
              <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                <b>• Información general</b>
              </h6>

              <div className='mt-2'>
                <Row className='d-flex justify-content-start'>
                  <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}>
                    <b>Fecha</b>
                  </Col>

                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess.date
                      ? moment(selectedProcess.date.slice(0, 10)).format('YYYY/MM/DD')
                      : '-'}
                  </Col>
                </Row>

                <Row className='d-flex justify-content-start'>
                  <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}>
                    <b>Reportante</b>
                  </Col>
                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess?.reporter ? selectedProcess.reporter : '-'}
                  </Col>
                </Row>

                <Row className='d-flex justify-content-start'>
                  <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}>
                    <b>Cargo</b>
                  </Col>
                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess?.job_title ? selectedProcess.job_title : '-'}
                  </Col>
                </Row>

                <Row className='d-flex justify-content-start'>
                  <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}>
                    <b>Área</b>
                  </Col>
                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess?.area_name ? selectedProcess.area_name : '-'}
                  </Col>
                </Row>

                <Row className='d-flex justify-content-start'>
                  <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}>
                    <b>Título</b>
                  </Col>
                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess?.title ? selectedProcess.title : '-'}
                  </Col>
                </Row>
              </div>
              <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                <b>• Descripción</b>
              </h6>
              <div className='mt-2'>
                <Row className='d-flex justify-content-start'>
                  <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}>
                    <b>Colaboradores implicados: </b>
                    <br></br>
                    {selectedProcess?.involved ? selectedProcess.involved : '-'}
                  </Col>
                </Row>

                <Row className='d-flex justify-content-start'>
                  <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f14}`}>
                    <b>Descripción de la situación: </b>
                    <br></br>

                    {selectedProcess?.description ? selectedProcess.description : '-'}
                  </Col>
                </Row>
              </div>
              <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                <b>• Evidencias</b>
              </h6>
              <div className='mt-4'>
                <Row className='d-flex justify-content-start'>
                  <Col xs={12} className={`${tableStyles.darkGrayText} ${tableStyles.fw700End}`}>
                    {selectedProcess?.support_files?.length > 0
                      ? selectedProcess.support_files?.map(e => {
                          return (
                            <>
                              <u
                                onClick={() => download(e.support_file)}
                                className={`${tableStyles.darkBlueText} cursorPointer`}
                              >
                                {e.support_file.split('/').pop()}
                              </u>
                              &nbsp; &nbsp;
                            </>
                          );
                        })
                      : ''}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </DialogModalFullScreen>
      ) : (
        <div className={`${tableStyles.container}`}>
          <div
            style={{
              display: 'flex',
              marginRight: '4rem',
              justifyContent: 'space-between',
              alignItems: 'end',
              paddingBottom: 10,
            }}
          >
            <div className='mt-3 d-flex  ' style={{ marginLeft: '4rem' }}>
              <img
                style={{
                  width: '120px',
                  height: '120px',
                  borderRadius: '10px',
                  border: '1px solid #005dbf',
                }}
                src={employeeInfo?.photo ? employeeInfo?.photo : anonymus}
                alt='imagen usuario'
              />
              &nbsp; &nbsp;
              <div>
                <h1 className={`${tableStyles.title} mb-0 pt-0`}>{employeeInfo?.full_name}</h1>
                <h6 className={`${tableStyles.darkBlueText}`}>
                  <b>{employeeInfo?.job_title}</b>
                </h6>
              </div>
            </div>
            <img
              className={`${tableStyles.filterOrdDarkGrayText} cursorPointer`}
              src={EditIcon}
              alt='Edit icon'
              name='Edit'
              width={22}
              height={22}
              onClick={() => {
                history.push({
                  pathname: '/nomina/colaborador/nuevo',
                  state: {
                    module:'miNomina',
                    isEditing: true,
                    employeeToEdit: employeeInfo,
                    hasPermission: true,
                    currentPercentage: employeeInfo?.percentage
                  },
                });
              }}
            />
          </div>

          <div className={tableStyles.tableArea}>
            <Row className='mb-4'>
              <Col xs={12} className={`${tableStyles.weirdBackGround3} m-0 p-0`}>
                <Nav variant='tabs'>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() =>
                        setSlider({
                          infoGen: true,
                          attached: false,
                          disciplinaryProcess: false,
                          annotations: false,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: false,
                        })
                      }
                      eventKey='link-1'
                      className={tableStyles.darkBlueText}
                      style={{
                        color: slider.infoGen ? '#495057' : '',
                        backgroundColor: slider.infoGen ? '#fff' : '',
                        borderColor: slider.infoGen ? '#dee2e6 #dee2e6 #fff' : '',
                      }}
                    >
                      Información general
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      onClick={() =>
                        setSlider({
                          infoGen: false,
                          attached: true,
                          disciplinaryProcess: false,
                          annotations: false,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: false,
                        })
                      }
                      eventKey='link-2'
                      style={{
                        color: '#005dbf',
                      }}
                    >
                      Contrato y anexos
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setSlider({
                          ...slider,
                          infoGen: false,
                          attached: false,
                          disciplinaryProcess: true,
                          annotations: false,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: false,
                        });
                      }}
                      style={{
                        color: '#005dbf',
                      }}
                      eventKey='link-3'
                    >
                      Procesos disciplinarios
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setSlider({
                          ...slider,
                          infoGen: false,
                          attached: false,
                          disciplinaryProcess: false,
                          annotations: true,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: false,
                        });
                      }}
                      eventKey='link-4'
                      style={{
                        color: '#005dbf',
                      }}
                    >
                      Anotaciones
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setSlider({
                          ...slider,
                          infoGen: false,
                          attached: false,
                          disciplinaryProcess: false,
                          annotations: false,
                          medicalExams: true,
                          examns: false,
                          accruedAndDeductions: false,
                        });
                      }}
                      eventKey='link-5'
                      style={{
                        color: '#005dbf',
                      }}
                    >
                      Exámenes medicos
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setSlider({
                          ...slider,
                          infoGen: false,
                          attached: false,
                          disciplinaryProcess: false,
                          annotations: false,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: true,
                        });
                      }}
                      eventKey='link-6'
                      style={{
                        color: '#005dbf',
                      }}
                    >
                      Descuentos y libranzas
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              {slider.infoGen && (
                <>
                  <Col xs={4} className='mt-4 mb-4'>
                    <h4 className={tableStyles.darkBlueText}>
                      <b>Datos básicos</b>
                    </h4>
                    <div className='mt-4'>
                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText}  ${tableStyles.f13}`}>
                          <b>Nacionalidad</b>
                        </Col>

                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.country_name}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Tipo de documento</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.document_type}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Vigencia hasta</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.end_date ? employeeInfo?.end_date : '-'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Expedición</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.doc_issued}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Fecha de nacimiento</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {moment(employeeInfo?.birthdate).format('YYYY/MM/DD')}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Edad</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.age ? employeeInfo?.age : '-'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Género</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.gender_label}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Est. civil</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.marital_status === 'single' && 'Soltero(a)'}
                          {employeeInfo?.marital_status === 'married' && 'Casado(a)'}
                          {employeeInfo?.marital_status === 'divorced' && 'Divorciado(a)'}
                          {employeeInfo?.marital_status === 'widowed' && 'Viudo(a)'}
                          {employeeInfo?.marital_status === 'freeunion' && 'Unión(a)'}
                          {employeeInfo?.marital_status === 'others' && 'Soltero(a)'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>No. de hijos</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.children_qty}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Fecha de ingreso</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {moment(employeeInfo?.start_date).format('DD/MM/YYYY')}
                        </Col>
                      </Row>
                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Método de pago</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.payment_method ?? '-'}
                        </Col>
                      </Row>
                      {employeeInfo?.payment_method === 'Transferencia' && (
                        <>
                          <Row className='d-flex justify-content-start'>
                            <Col
                              xs={6}
                              className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                            >
                              <b>No. cuenta</b>
                            </Col>
                            <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                              {employeeInfo?.bank_account_nbr && employeeInfo?.bank_account_nbr}
                            </Col>
                          </Row>
                          <Row className='d-flex justify-content-start'>
                            <Col
                              xs={6}
                              className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                            >
                              <b>Tipo de cuenta</b>
                            </Col>
                            <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                              {employeeInfo?.bank_account_type && employeeInfo?.bank_account_type}
                            </Col>
                          </Row>
                          <Row className='d-flex justify-content-start'>
                            <Col
                              xs={6}
                              className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                            >
                              <b>Banco</b>
                            </Col>
                            <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                              {employeeInfo?.bankingName && employeeInfo?.bankingName}
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                    <h4 className={`${tableStyles.darkBlueText} mt-3`}>
                      <b>Contacto</b>
                    </h4>
                    <div className='mt-4'>
                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Télefono</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.mobile}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Email</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                          style={{ wordBreak: 'break-all' }}
                        >
                          {employeeInfo?.email}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Domicilio</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.home_address}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Cont. de emergencia</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.emergency_contact}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Parentesco</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.emercont_relationship === 'partner' && 'Pareja'}
                          {employeeInfo?.emercont_relationship === 'parent' && 'Padre/madre'}
                          {employeeInfo?.emercont_relationship === 'son' && 'Hijo(a)'}
                          {employeeInfo?.emercont_relationship === 'sibling' && 'Hermano(a)'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Teléfono</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {employeeInfo?.emercont_mobile ? employeeInfo?.emercont_mobile : '-'}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col
                    xs={4}
                    className={`pt-4  ${tableStyles.bgColorClearBlue} ${tableStyles.scrollThis}`}
                    style={{ height: '83vh' }}
                  >
                    <div className='mt-2 ml-5'>
                      <p className={tableStyles.darkBlueText}>
                        <b>Educación</b>
                      </p>

                      {employeeInfo?.education?.length > 0 &&
                        employeeInfo?.education?.map((e, i) => {
                          return (
                            <Timeline key={i} lang='es' theme={customTheme}>
                              {e.graduation_date ? (
                                <Container>
                                  <YearContent
                                    startDate={moment(e.graduation_date).format('YYYY/MM/DD')}
                                    endDate={moment(e.graduation_date).format('YYYY/MM/DD')}
                                  />
                                  <BodyContent>
                                    <Section
                                      title={
                                        <b className={`${tableStyles.darkGrayText}`}>
                                          {e.education_type}
                                        </b>
                                      }
                                    >
                                      <Description text={e.institution_name} />

                                      <Description variant='subtitle' text={e.description} />
                                    </Section>
                                    <Section></Section>
                                  </BodyContent>
                                </Container>
                              ) : (
                                <></>
                              )}
                            </Timeline>
                          );
                        })}
                    </div>
                  </Col>
                  <Col
                    xs={4}
                    className={`pt-4  ${tableStyles.bgColorClearBlue} ${tableStyles.scrollThis} `}
                    style={{ height: '83vh' }}
                  >
                    <div className='mt-2 '>
                      <p className={`mt-1 ${tableStyles.darkBlueText}`}>
                        <b>Experiencia</b>
                      </p>
                      {employeeInfo?.workExperience?.length > 0 &&
                        employeeInfo?.workExperience?.map((e, i) => {
                          return (
                            <Timeline key={i} lang='es' theme={customTheme}>
                              {e.start_date ? (
                                <Container>
                                  <YearContent
                                    startDate={moment(e.start_date).format('YYYY/MM/DD')}
                                    endDate={moment(e.end_date).format('YYYY/MM/DD')}
                                  />
                                  <BodyContent>
                                    <Section
                                      title={
                                        <b className={`${tableStyles.darkGrayText}`}>
                                          {e.company_name}
                                        </b>
                                      }
                                    >
                                      <Description text={e.description} />
                                    </Section>
                                    <Section></Section>
                                  </BodyContent>
                                </Container>
                              ) : (
                                <></>
                              )}
                            </Timeline>
                          );
                        })}
                    </div>
                  </Col>
                </>
              )}
            </Row>
            {slider.attached && (
              <>
                <div>
                  <Row className='mb-3'>
                    <Col xs={4} className='mt-4'>
                      <div className=''>
                        <h4 className={tableStyles.darkBlueText}>
                          <b>Contrato</b>
                        </h4>
                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>Tipo de contrato</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {employeeInfo?.description ? employeeInfo?.description : '-'}
                          </Col>
                        </Row>

                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>Fecha de inicio</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {employeeInfo?.start_date
                              ? moment(employeeInfo?.start_date).format('DD/MM/YYYY')
                              : '-'}
                          </Col>
                        </Row>

                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>Vigencia hasta</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {employeeInfo?.doc_number ? employeeInfo?.doc_number : '-'}
                          </Col>
                        </Row>

                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>Salario base</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {convertMoneyFormat(
                              employeeInfo?.base_salary ? employeeInfo?.base_salary : '0',
                            )}
                          </Col>
                        </Row>
                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>Salario no constitutivo</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {convertMoneyFormat(employeeInfo?.non_const_income ?? '-')}
                          </Col>
                        </Row>
                      </div>{' '}
                    </Col>
                    <Col xs={5} className='mt-4'>
                      <div className='mt-3'>
                        <h4 className={tableStyles.darkBlueText}>
                          <b>Seguridad y salud</b>
                        </h4>
                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>EPS</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {employeeInfo?.hce_description ? employeeInfo?.hce_description : '-'}
                          </Col>
                        </Row>

                        {!!employeeInfo.pensioner === false && (
                          <Row className='d-flex justify-content-start'>
                            <Col
                              xs={6}
                              className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                            >
                              <b>Fondo de pensión</b>
                            </Col>
                            <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                              {employeeInfo?.pension_description
                                ? employeeInfo?.pension_description
                                : '-'}
                            </Col>
                          </Row>
                        )}

                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>ARL</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {employeeInfo?.occupational_risk_description
                              ? employeeInfo?.occupational_risk_description
                              : '-'}
                          </Col>
                        </Row>

                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>Caja de compensación</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {employeeInfo?.compensation_center
                              ? employeeInfo?.compensation_center
                              : '-'}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className='mt-2'>
                        <Row className='d-flex justify-content-start'>
                          <Col
                            xs={12}
                            className={`d-flex justify-content-center`}
                            style={{ width: '11rem' }}
                          ></Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  {renderSections()}
                </div>{' '}
              </>
            )}
            {slider.disciplinaryProcess && <>{renderDisciplinaryProcessInfo()}</>}
            {slider.annotations && (
              <>
                <div className={`${tableStyles.scrollThis} ${tableStyles.grayBorderBottom}`}>
                  {annotations?.length > 0 ? (
                    annotations.map((e, i) => {
                      return (
                        <div
                          key={i}
                          className={`${tableStyles.cardBoxContainer} p-2 mr-2 m-2 mb-4`}
                          style={{ width: '50%' }}
                        >
                          <p className={tableStyles.cardBoxTitle}>{e.title}</p>
                          <hr className='m-0'></hr>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: isEmptyOrUndefined(e.description)
                                ? "<h1 class='text-secondary text-center'>No hay descripción para esta anotación</h1>"
                                : e.description,
                            }}
                          ></div>

                          <div className={`d-flex text-start justify-content-between pt-4`}>
                            <p className={tableStyles.cardBoxBottomText}>{e.created_by_name}</p>
                            <p
                              className={`${tableStyles.cardBoxBottomText} ${tableStyles.fw700End}`}
                            >
                              {convertDateToLatinFormat(e.created_at)}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <h4 className={tableStyles.darkBlueText}>
                        <b>Este colaborador no tiene anotaciones</b>
                      </h4>
                    </>
                  )}
                </div>
              </>
            )}
            {slider.medicalExams && (
              <>
                <Row className={`${tableStyles.grayBorderBottom} pb-5  ${tableStyles.scrollThis}`}>
                  <Col xs={4} className='mt-3'>
                    <h3 className={tableStyles.darkBlueText}>
                      <b>Información médica</b>
                    </h3>
                    <div className='mt-2'>
                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Tipo de sangre</b>
                        </Col>

                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {medicalInfo?.blood_type ? medicalInfo?.blood_type : '-'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>RH</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {medicalInfo?.rh_factor ? medicalInfo?.rh_factor : '-'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Peso</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {medicalInfo?.weight_kg ? medicalInfo?.weight_kg + ' kg' : '-'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Estatura</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {medicalInfo?.heigth_cm ? medicalInfo?.heigth_cm + ' cm' : '-'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Alergias</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {medicalInfo?.allergies ? medicalInfo?.allergies : '-'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Discapacidades</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {medicalInfo?.disabilities ? medicalInfo?.disabilities : '-'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Medicamentos</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {medicalInfo?.medicines ? medicalInfo?.medicines : '-'}
                        </Col>
                      </Row>

                      <Row className='d-flex justify-content-start'>
                        <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                          <b>Enfermedades</b>
                        </Col>
                        <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                          {medicalInfo?.diseases ? medicalInfo?.diseases : '-'}
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col className={`mt-3 rounded m-3`} xs={4}>
                    <h4 className={tableStyles.darkBlueText}>
                      <b>Información de exámenes </b>
                    </h4>
                    <p className={`${tableStyles.crudModalLabel} `}>Fecha de próximos exámenes</p>
                    <input
                      disabled={true}
                      value={store.partnersReducer?.medicalInfo?.next_exam}
                      className='register-inputs text-secondary'
                    />
                    <h4 className={`${tableStyles.darkBlueText} pt-4`}>
                      <b>Historial</b>
                    </h4>
                    {historial?.length > 0 ? (
                      historial.map((e, i) => {
                        return (
                          <Row key={i} className={`d-flex justify-content-start `}>
                            <Col xs={3} className={`${tableStyles.darkGrayText} `}>
                              <b className={tableStyles.darkBlueText}>•</b>
                              <b className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}>
                                {e.done_date}
                              </b>
                            </Col>

                            <Col xs={9} className={`text-secondary`}>
                              <span className={tableStyles.fw700End}>
                                <u className={`${tableStyles.darkGrayText} `} title={e.title}>
                                  <b
                                    onClick={() => downloadExam(e.result_location)}
                                    className={`${tableStyles.darkGrayText} ${tableStyles.ellipsis} cursorPointer`}
                                  >
                                    {e.title.length >= 45 ? e.title.slice(0, 45) + '...' : e.title}
                                  </b>
                                </u>
                                <br />
                                {!isEmptyOrUndefined(e.result_loaded_by_name)
                                  ? e.result_loaded_by_name
                                  : '-'}
                              </span>
                            </Col>
                          </Row>
                        );
                      })
                    ) : (
                      <h6 className={`${tableStyles.darkBlueText} pt-4`}>
                        <b>No se encontraron fechas</b>
                      </h6>
                    )}
                  </Col>
                </Row>
              </>
            )}
            {slider.accruedAndDeductions && (
              <DiscountsAndPayout
                idContract={employeeInfo?.contract_id}
                idCandidate={employeeInfo?.id_candidate}
              />
            )}
            {/* /* ------------------------------------ - ----------------------------------- */}
          </div>
        </div>
      )}
    </>
  );
};
