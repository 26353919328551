/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import lupa from "../../assets/img/icons/lupa.svg";
import { useState } from "react";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import OrdTableAccordion from "../../OrderingModule/OrderingComponents/OrdTableAccordion";
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import CustomPopupStyles from "../../../src/components/Popup/customPopup.module.scss";
import threeDots from "../../assets/img/icons/threeDots.svg";
import EditIcon from "../../assets/img/icons/editar.svg";
import EnableIcon from "../../assets/img/icons/puntoVerde.svg"
import DisableIcon from "../../assets/img/icons/puntoRojo.svg"
import { useSelector } from "react-redux";
import filtroLupa from "../../assets/img/filtroLupa.svg";
import SearchFileIcon from "../../assets/img/icons/SearchLocal.svg";
import ModalNew from "../../components/Layouts/ModalNew";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import Swal from "sweetalert2";
import { getPermission, loader, optionsSelect } from "../../helpers/helpers";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import Select from "react-select";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import { valueSelect } from './../../helpers/helpers';
import FranchiseStyles from "./Franchises_CSS.module.scss";
import ReactCodeInput from 'react-code-input';

const Franchises = () => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const createdBy = store.loginReducer.user_data.id;
  const listPermission = store.loginReducer.currentAccount?.profile?.permission
  const myPermission = getPermission({ prefix: 'franquicia', listPermission })

  const { trigger: getListNifAccount,
    results: listNifAccount,
    load: loadListNifAccount,
  } = useGetMethod();


  const {
    results: listFranchises,
    load: loadingFranchises,
    trigger: getListFranchises,
  } = useGetMethod();

  const {
    load: loadListDetailFranchise,
    trigger: getListDetailFranchise,
  } = useGetMethod();

  const { trigger: createFranchises, load: createLoader } = usePostMethod();

  const [data, setData] = useState({
    trigger: 0,
    selects: {
      listAccount: null,
      listStatus: [
        { id: "enabled", name: "Habilitado" },
        { id: "disabled", name: "Inhabilitado" },
      ],
    },
    table: {
      listDataTableFilter: null,
      listDataTable: listFranchises?.results,
      dataArrayOrder: null,
      filters: {
        page: 1,
        perPage: 10,
        pageRangeDisplayed: 3,
        search: "",
      },
      formatDataTable: null,
      headersTable: [
        {
          title: "Franquicia",
          className: "text-start px-5 fw-bold",
        },
        {
          title: "Cuenta",
          className: "text-start fw-bold",
        },
        {
          title: "Estado",
          className: "text-center fw-bold  w-100",
        },
      ],

    },
    modal: {
      show: null,
      isEditing: null,
    },
    dataSchema: {
      eaccount: eaccount,
      createdBy: createdBy,
    },
  });

  const [dataModalDetail, setDataModalDetail] = useState({
    niffAccountCompleteAccount: '',
    fraId: '',
    fraName: '',
    niifAccount: '',
    niifAccountName: '',
    fraStatus: '',
    showModal: false
  })

  const { selects, table, modal, dataSchema } = data;

  const [filters, setFilters] = useState({
    search: "",
    fraStatus: "",
    eaccount: eaccount,
  });

  useEffect(() => {
    getListFranchises({
      url: "/accounting/franchise/",
      token: token,
      objFilters: filters,
    });
  }, []);

  useEffect(() => {
    if (listFranchises?.results !== undefined) {
      setData({
        ...data,
        table: {
          ...table,
          listDataTable: listFranchises?.results,
        },
      });
    }
  }, [listFranchises]);

  useEffect(() => {
    formatData(table?.listDataTable || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.listDataTable]);

  useEffect(() => {

    getListNifAccount({
      url: "/admin/niif",
      token: token,
      objFilters: {
        active: 1,
        eaccount: eaccount,
      }
    });

  }, []);


  useEffect(() => {

    if (listNifAccount?.results?.length) {
      const filter = listNifAccount?.results?.map((item) => ({
        value: item.id,
        label: `${item?.complete_account} - ${item?.description}`,
      }));
      
      setData((state) => ({
        ...state,
        selects: {
          ...state.selects,
          listAccount: [{label:'Seleccionar...', value:''}, ...filter],
        },
      }));
    }
  }, [listNifAccount])

  useEffect(() => {
    getListFranchises({
      url: "/accounting/franchise/",
      token: token,
      objFilters: filters,
    });
  }, [filters.fraStatus, data.trigger]);

  const formatData = (array) => {
    let tempList = [];
    const actives = array?.filter((e) => e?.fraStatus === "enabled");
    const inactive = array?.filter((e) => e?.fraStatus === "disabled");
    const dataArrayOrder = [...actives, ...inactive];

    if (Array.isArray(array)) {
      array?.forEach((e, index) => {
        tempList?.push([
          {
            text: (
              <div
                key={index}
                style={
                  !e.fraStatus
                    ? {
                      display: "flex",
                      columnGap: "10px",
                      alignItems: "center",
                      opacity: "0.3",
                      paddingLeft: '3px',
                    }
                    : {
                      display: "flex",
                      columnGap: "10px",
                      alignItems: "center",
                      paddingLeft: '3px',
                    }
                }
              >
                <div>{e?.fraName}</div>
              </div>
            ),
            className: "text-start px-2",
          },
          {
            text: (
              <div style={!e?.fraStatus ? { opacity: "0.3" } : {}}>
                {e?.niffAccountCompleteAccount}
              </div>
            ),
            className: "text-start px-2",
          },
          {
            text: (
              <div
                style={
                  !e.fraStatus
                    ? {
                      display: "flex",
                      justifyContent: "center",
                      columnGap: "20px",
                      marginLeft: "6rem",
                      alignItems: "center",
                      opacity: "0.3",
                    }
                    : {
                      display: "flex",
                      justifyContent: "center",
                      columnGap: "40px",
                      marginLeft: "6rem",
                      alignItems: "center",
                    }
                }
              >
                <div
                  className={`${e.fraStatus === "enabled"
                    ? tableStyles.greenState2
                    : tableStyles.redState2
                    } mx-2`}
                >
                  {e.fraStatus === "enabled" ? 'Habilitado' : 'Inhabilitado'}
                </div>
                {!e.fraStatus ? (
                  ""
                ) : (
                  <>
                    {
                      myPermission?.edit && (
                        <CustomPopupExtend
                          noHover
                          triggerSrc={threeDots}
                          position="right center"
                          extraButtons={[
                            {
                              text: "Editar",
                              icon: EditIcon,
                              class: CustomPopupStyles.popUpGeneric,
                              event: () => {
                                
                                setData((state) => ({
                                  ...state,
                                  modal: { isEditing: true, show: true },
                                  dataSchema: { 
                                    ...state.dataSchema, 
                                    ...e,
                                    niifAccount: selects?.listAccount?.find(el=>Number(el?.value) === Number(e?.niifAccount))?.value,
                                    niifAccountLabel: selects?.listAccount?.find(el=>Number(el?.value) === Number(e?.niifAccount))?.label
                                  },
                                }));
                              },
                            },
                            {
                              text: `${e.fraStatus === "enabled"
                                ? "Inhabilitar"
                                : "Habilitar"
                                }`,
                              icon: e.fraStatus === "enabled"
                                ? DisableIcon : EnableIcon,
                              class: CustomPopupStyles.popUpGeneric,
                              event: () => {
                                customSwaltAlert({
                                  title: "¿Está seguro?",
                                  text: `Se ${e.fraStatus === "enabled" ? 'inhabilitará' : 'habilitará'} la franquicia: ${e?.fraName}`,
                                  confirmButtonText: "Si, continuar",
                                })
                                  .then((result) => {
                                    if (result.value) {
                                      createFranchises({
                                        url: "/accounting/franchise/",
                                        method: "PUT",
                                        token: token,
                                        body: {
                                          fraId: e.fraId,
                                          fraName: e.fraName,
                                          fraStatus:
                                            e.fraStatus === "enabled"
                                              ? "disabled"
                                              : "enabled",
                                          eaccount: eaccount,
                                          createdBy: createdBy,
                                          niffAccount: e.niffAccount,
                                        },
                                        noAlert: true,
                                        doAfterSuccess: (results) => {
                                          if (results?.statusCode === 201) {

                                            customSwaltAlert({
                                              icon: "success",
                                              title: `Franquicia ${e.fraStatus === "enabled" ? 'inhabilitada' : 'habilitada'} exitosamente`,
                                              confirmButtonText: "Aceptar",
                                              showCancelButton: false,
                                              text: `Se ha ${e.fraStatus === "enabled" ? 'inhabilitado' : 'habilitado'} la franquicia:  ${results?.results?.fraName}`,
                                            })

                                            closeModal();
                                            getListFranchises({
                                              url: "/accounting/franchise/",
                                              token: token,
                                              objFilters: filters,
                                            });
                                          }
                                        },
                                      });
                                    }
                                  });
                              },
                            },
                          ]}
                        />
                      )
                    }
                  </>
                )}
              </div>
            ),
            className: "text-center",
          },
        ]);
      });
    }

    setData((state) => ({
      ...state,
      table: { ...state.table, dataArrayOrder, formatDataTable: tempList },
    }));
  };

  const accordionBody = (id, item) => (
    <div
      style={
        table?.dataArrayOrder[id]?.status === 0
          ? { textAlign: "start", opacity: "0.3" }
          : { textAlign: "start" }
      }
    >
      <div>
        <span
          style={{
            color: "#005DBF",
            fontWeight: "bold",
          }}
        >
          Cuenta
        </span>
        <div
          style={{
            gap: "50px",
            display: "flex",
            borderBottom: "1px solid #CECECE",
          }}
        >
          <span
            style={{
              color: "#58595B",
              fontWeight: "bold",
            }}
          >
            No. de cuenta
          </span>
          <span
            style={{
              color: "#58595B",
              fontWeight: "bold",
            }}
          >
            Descripción
          </span>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #CECECE",
            }}
          >
            <div
              style={{
                gap: "110px",
                display: "flex",
              }}
            >
              <span
                style={{
                  color: "#58595B",
                }}
              >
                {table?.dataArrayOrder[id]?.niffAccountCompleteAccount}
              </span>
              <span
                style={{
                  color: "#58595B",
                }}
              >
                {table?.dataArrayOrder[id]?.niifAccountName}
              </span>
            </div>
            <div
              style={{
                paddingRight: "1rem",
              }}
              onClick={() => {
              }}
              className={`${tableStyles.hoverLupa}`}
            >
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                src={filtroLupa}
                alt="filtroLupa"
                onClick={() => { onDetailAccount(id) }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const closeModal = () => {
    setData((state) => ({
      ...state,
      modal: { isEditing: false, show: false },
      dataSchema: {
        ...state.dataSchema,
        fraName: "",
        niifAccount: "",
        niifAccountLabel: "",
      },
    }));
  };

  const handleSubmit = () => {
    if (dataSchema?.fraId) {
      if (dataSchema?.fraName !== "" || dataSchema?.niifAccount !== "") {
        createFranchises({
          url: "/accounting/franchise/",
          method: "PUT",
          token: token,
          body: dataSchema,
          noAlert: true,
          doAfterSuccess: (results) => {
            if (results?.statusCode === 201) {
              customSwaltAlert({
                icon: "success",
                title: "Franquicia actualizada",
                confirmButtonText: "Aceptar",
                text: `${results?.results?.fraName}`,
                showCancelButton: false,
              })

              closeModal();
              getListFranchises({
                url: "/accounting/franchise/",
                token: token,
                objFilters: filters,
              });
            }
          },
        });
      }
    } else {
      if (dataSchema.fraName !== "" || dataSchema.niifAccount !== "") {
        customSwaltAlert({
          title: '¿Está seguro?',
          text: 'Verifique la información de la franquicia'
        })
          .then((result) => {
            if (result.value) {
              createFranchises({
                url: "/accounting/franchise/",
                method: "POST",
                token: token,
                body: dataSchema,
                noAlert: true,
                doAfterSuccess: (results) => {

                  if (results?.statusCode === 201) {

                    customSwaltAlert({
                      icon: "success",
                      title: "Creada exitosamente",
                      confirmButtonText: "Aceptar",
                      text: `Se ha creado la franquicia: ${results?.results?.fraName}`,
                      showCancelButton: false,
                    })
                    closeModal();
                    getListFranchises({
                      url: "/accounting/franchise/",
                      token: token,
                      objFilters: filters,
                    });
                  }
                },
              });
            }
          });
      }
    }
  };

  const onDetailAccount = (id) => {

    const item = data?.table?.listDataTable[id]

    getListDetailFranchise({
      url: `/admin/niif/${item?.niffAccountCompleteAccount}`,
      token: token,
      objFilters: { eaccount },
    }).then((res) => {
      setDataModalDetail(state => ({ ...state, ...item, ...res, showModal: true }))
    }).catch(error => {
      customSwaltAlert({
        icon: "warning",
        title: `Intenta de nuevo`,
        confirmButtonText: "Aceptar",
        showCancelButton: false,
        text: error?.message,
      })
    })

  }

  return (
    <>
      {(loadingFranchises || loadListNifAccount || createLoader || loadListDetailFranchise) && loader}
      <div className={`${tableStyles.container}`}>
        <h1 className={tableStyles.title} style={{ marginLeft: "76px" }}>
          Franquicias
        </h1>

        <div>
          {
            myPermission?.read && (
              <span className={`${tableStyles.textColorAdministracion} ${tableStyles.tableArea}`}>
                Estado
              </span>
            )
          }
          <div style={{ marginTop: '-10px' }} className={tableStyles.tableArea}>
            <div className={tableStyles.mainRow}>
              {
                myPermission?.read && (
                  <form
                    className={tableStyles.containerSearch}
                    onSubmit={(e) => {
                      e.preventDefault();
                      setData(state => ({ ...state, trigger: state.trigger + 1 }))
                      getListFranchises({
                        url: "/accounting/franchise/",
                        token: token,
                        objFilters: filters,
                      });
                    }}
                  >
                    <div
                      className={`d-flex flex-column ${tableStyles.containerSelect}`}
                    >

                      <Select
                        noOptionsMessage={() => 'No hay datos'}
                        className={"text-secondary w-100"}
                        onChange={(e) => {
                          setFilters((state) => ({
                            ...state,
                            fraStatus: e.value,
                          }));
                        }}
                        options={
                          [
                            { value: "", label: "Seleccionar..." },
                            ...selects.listStatus?.map((item, index) => ({ label: item?.name, value: item?.id }))
                          ]
                        }
                        placeholder="Seleccionar..."
                        styles={customSelectNewDark}
                        maxHeight={26}
                      />

                    </div >

                    <div>
                      <input
                        style={{ marginTop: '2px', height: '26px' }}
                        className={tableStyles.searchUsersAlt}
                        type="text"
                        placeholder="Buscar..."
                        onChange={(e) =>
                          setFilters({ ...filters, search: e.target.value })
                        }
                      />
                      <button>
                        <img
                          src={lupa}
                          alt="User icon"
                          className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                          style={{ width: '1.5rem' }}
                        />
                      </button>
                    </div>

                  </form>
                )
              }

              {
                myPermission?.create && (
                  <div
                    className={tableStyles.containerFranquicia}
                    style={{
                      width: "30%",
                      marginLeft: 'auto'
                    }}
                  >
                    <div
                      className="d-flex justify-content-end groupAddButton align-items-center"
                      onClick={() => {
                        setData((state) => ({
                          ...state,
                          modal: { isEditing: false, show: true },
                        }));
                      }}
                    >
                      <label htmlFor="newAccident" className="darkGray fw-bold">
                        Nueva Franquicia
                      </label>
                      <button
                        className="addCheckButton mx-2"
                        style={{ marginBottom: "1.5px" }}
                      />
                    </div>

                  </div >
                )
              }

            </div >

          </div >

          <ModalNew
            hideFooter={modal.isEditing ? !myPermission?.edit : !myPermission?.create}
            title={`${modal.show && modal.isEditing
              ? "Editar Franquicia"
              : "Crear Franquicia"
              }`}
            size={750}
            show={modal.show}
            onHide={() => closeModal()}
            btnYesEvent={() => handleSubmit()}
            btnNoEvent={() => {
              closeModal();
              setData((state) => ({
                ...state,
                dataSchema: {
                  ...state.dataSchema,
                  fraName: "",
                  niifAccount: "",
                },
              }));
            }}
          >
            <div
              style={{
                padding: "1rem",
                display: "flex",
                marginBottom: ".5rem",
                borderRadius: "10px",
                flexDirection: "column",
                backgroundColor: "rgba(0, 93, 191, 0.1)",
              }}
            >
              <span
                style={{
                  color: "#005DBF",
                  fontWeight: "bold",
                }}
              >
                Información General
              </span>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginBottom: ".5rem",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "#005DBF",
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                >
                  Descripción
                </span>
                <input
                  style={{
                    height: "30px",
                    outline: "none",
                    color: "#58595b",
                    padding: "0 .3rem",
                    borderRadius: "6px",
                    border: "0.7px solid rgba(1, 160, 246, 0.5)",
                  }}
                  type="text"
                  onChange={(e) => {
                    setData((state) => ({
                      ...state,
                      dataSchema: {
                        ...state.dataSchema,
                        fraName: e.target.value,
                      },
                    }));
                  }}
                  value={dataSchema?.fraName}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "#005DBF",
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                >
                  Cuenta contable
                </span>

                <SelectComponent
                  options={selects?.listAccount}
                  
                  value={
                    dataSchema?.niifAccount 
                      ? { label: dataSchema?.niifAccountLabel, value: dataSchema?.niifAccount}
                      : ''
                  }
                  onChange={({ value, label }) => {
                    setData((state) => ({
                      ...state,
                      dataSchema: {
                        ...state.dataSchema,
                        niifAccount: value,
                        niifAccountLabel: label
                      },
                    }));
                  }}
                />

              </div>
            </div>
          </ModalNew>

          <ModalNew
            title={'Detalle de Cuenta'}
            show={dataModalDetail?.showModal}
            btnYesName="Aceptar"
            onHide={() => setDataModalDetail(state => ({ ...state, showModal: false }))}

          >
            <div className="row justify-content-center mb-4">
              <div className=" justify-content-center mx-auto">
                <p className={`float-left labelNumberAccount ${FranchiseStyles.label_inputs_new}`}>
                  No. de cuenta<span className={"text-warning"}>*</span>
                </p>
                <ReactCodeInput
                  type="number"
                  fields={8}
                  value={dataModalDetail?.niffAccountCompleteAccount}
                  name="code"
                  disabled={true}
                  // onChange={this.onChangeAccountNumber}
                  inputStyle={{
                    backgroundColor: "rgba(0, 93, 191, 0.1)",
                    border: "1px solid rgba(0,0,0,0.05)",
                    borderRadius: "6px",
                    padding: "1rem 0.5rem",
                    margin: "0.1rem",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    //flexDirection: "column",
                  }}
                />

                {/* <AccountInput /> */}
                <div className={`d-flex justify-content-araund ${FranchiseStyles.divAjuste}`}>

                  <p className={`${FranchiseStyles.divAjuste2} ${FranchiseStyles.label_inputs_new2}`}>
                    Clase
                  </p>
                  <p className={`${FranchiseStyles.divAjuste2} ${FranchiseStyles.label_inputs_new2}`}>
                    Grupo
                  </p>
                  <p className={`${FranchiseStyles.divAjuste3} ${FranchiseStyles.label_inputs_new2}`}>
                    Cuenta
                  </p>
                  <p className={`${FranchiseStyles.divAjuste3} ${FranchiseStyles.label_inputs_new2}`}>
                    Subcuenta
                  </p>
                  <p className={`${FranchiseStyles.divAjuste3} ${FranchiseStyles.label_inputs_new2}`}>
                    Auxiliar
                  </p>


                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="  mx-auto">
                <p className={`${FranchiseStyles.label_inputs_new}`}>Nombre<span className={"text-warning"}>*</span></p>
                <textarea
                  rows="10"
                  cols="10"
                  className={`${FranchiseStyles.register_inputs_new} p-1 ${tableStyles.outlineNone}`}
                  style={{ color: '#58595b' }}
                  name="description"
                  type="text"
                  value={dataModalDetail?.description}
                  disabled={true}

                />
              </div>
              <div
                className={` mt-3 mx-5 p-3`}
              >
                <p className={`${FranchiseStyles.titulo}`}>Tipo de cuenta</p>
                <div className="d-flex justify-content-flex-start">
                  <div className="form-check mx-1">
                    <input
                      className="form-check-input styleCheckInputRatio"
                      type="radio"
                      name="type"
                      id="radioNiifSelect"
                      value="niif"
                      checked={true}
                      disabled={true}

                    />
                    <label className={`form-check-label ${FranchiseStyles.label_inputs_new3}`} for="radioNiifSelect">
                      NIIF
                    </label>
                  </div>
                  <div className="form-check mx-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="type"
                      id="radioLocalSelect"
                      value="local"
                      disabled={true}
                      checked={false}
                    />
                    <label className={`form-check-label ${FranchiseStyles.label_inputs_new3}`} for="radioLocalSelect">
                      Local
                    </label>
                  </div>
                </div>

              </div>
              <div className="d-flex  mt-3">
                <div className="form-check mx-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="uses_third"
                    id="uses_third"
                    disabled={true}
                    checked={dataModalDetail?.uses_third}
                  />
                  <label className={`form-check-label ${FranchiseStyles.label_inputs_new3}`} for="uses_third">
                    Utiliza terceros
                  </label>
                </div>
                <div className="form-check mx-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="uses_cost_center"
                    id="usesCenterCost"
                    disabled={true}
                    checked={dataModalDetail?.uses_cost_center}
                  />
                  <label className={`form-check-label ${FranchiseStyles.label_inputs_new3}`} for="usesCenterCost">
                    Exige centro de costo
                  </label>
                </div>

              </div>
              <div className="d-flex  mt-3">
                <div className="form-check mx-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="check_info_bank"
                    id="usesInfoBank"
                    checked={dataModalDetail?.check_info_bank}
                    disabled={true}
                  />
                  <label className={`form-check-label ${FranchiseStyles.label_inputs_new3}`} for="usesInfoBank">
                    Contiene Información bancaria
                  </label>
                </div>
              </div>

              {'' ?
                <div className={" mt-3 mx-5 p-3"}>
                  <p className={` ${FranchiseStyles.titulo}`}> Información Bancaria</p>
                  <div className="mx-auto">
                    <p className={` ${FranchiseStyles.label_inputs_new}`}>Nombre del banco<span className={"text-warning"}>*</span></p>
                    <input
                      className={` ${FranchiseStyles.register_inputs_new}`}
                      name="name_bank"
                      type="text"
                    />
                  </div>
                  <div className={`d-flex ${FranchiseStyles.justify}`}>

                    <div className={` ${FranchiseStyles.flexDiv}  ${FranchiseStyles.flexDiv_margin}`}>
                      <p className={` ${FranchiseStyles.label_inputs_new}`}>Número de Cta.</p>
                      <input
                        className={` flexDiv__flexSelectInput ${FranchiseStyles.register_inputs_new}`}
                        name="num_count"
                        type="number"
                        maxLength={11}
                      />
                    </div>

                    <div className="flexDiv">
                      <p className={`${FranchiseStyles.label_inputs_new}`}>Tipo de Cta.</p>

                      <select
                        name="account_type"
                        className={` flexDiv__flexSelectInput ${FranchiseStyles.register_inputs_new}`}
                      >
                        <option value="" ></option>
                        <option value="Corriente" >Corriente</option>
                        <option value="Fondos_de_inversión">Fondos de inversión</option>
                        <option value="Ahorros">Ahorros</option>
                        <option value="Fiduciarias">Fiduciarias</option>

                      </select>
                    </div>
                  </div>
                </div>
                :
                <span></span>
              }


            </div>
          </ModalNew>

          {
            myPermission?.read && (
              <div
                className={`${tableStyles.container}`}
                style={{
                  padding: "0 4.8rem",
                  paddingBottom: "2rem",
                }}
              >
                <OrdTableAccordion
                  dark={true}
                  shadow={true}
                  isAnAccTable={true}
                  noHoverAccordion={true}
                  headers={table?.headersTable}
                  body={table?.formatDataTable}
                  accordionBody={accordionBody}
                  headersGridColumn={"2fr 1fr 300px"}
                  className={`${tableStyles.ordTableShadow}`}
                />
              </div>
            )
          }
        </div >
      </div>
    </>

  )
}

export default Franchises;
