import {
  GET_PURCHASE_ORDERS,
  LOADING,
  GET_PAY_CONDITIONS,
  PURCHASEORDER_MODAL3,
  PURCHASE_ORDER_SUMMARY_GET_ONE,
  PURCHASE_ORDER_SUMMARY_GET_ONE_LOADING,PURCHASEORDER_MODAL2, PURCHASEORDER_PRINCIPAL, AUTH_LOADING_CHANGE
} from "../actions/actionTypes";

const initialState = {
  purchaseOrders: [],
  loading: false,
  payConditions: [],
  modalPart3: false,
  modalPart2: false,
  summary_PO: [],
  summary_PO_loading: false,
  codes: [],
  principalLayout: false,
  loadingAuth: false
};
export const purchaseOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_PAY_CONDITIONS:
      return {
        ...state,
        payConditions: action.payload,
      };

    case PURCHASEORDER_MODAL3:
      return {
        ...state,
        modalPart3: action.show,
        codes: action.codes,
      };
    case PURCHASEORDER_MODAL2:
      return {
        ...state,
        modalPart2: action.show,
      };
    case PURCHASEORDER_PRINCIPAL:
      return {
        ...state,
        principalLayout: action.show,
      };
    case PURCHASE_ORDER_SUMMARY_GET_ONE:
      return {
        ...state,
        summary_PO: action.res,
        summary_PO_loading: action.loadingP,
      };
    case PURCHASE_ORDER_SUMMARY_GET_ONE_LOADING:
      return {
        ...state,
        summary_PO_loading: action.loadingP,
      };

    case GET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: action.payload,
      };

    case AUTH_LOADING_CHANGE:
      return {
        ...state,
        loadingAuth: action.loading,
      };
    default:
      return state;
  }
};
