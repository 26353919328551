import React, { useEffect, useState } from "react";
import { customSelectNewDark } from "../../Layouts/react-select-custom";
import Pagination from "react-js-pagination";
import Search from "../../../assets/img/icons/lupa.svg";
import backArrow from "../../../assets/img/icons/atras-icon.svg";
import Excel from "../../../assets/img/icons/excel.svg";
import avion from "../../../assets/img/icons/avion.svg";
import burbuje from "../../../assets/img/icons/burbuje.svg";
import GenericTableNew from "../../Layouts/GenericTableNew";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import Select from "react-select";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Styles from "../Hiring/Contract.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Grid,Button } from "@mui/material";
import moment  from 'moment';
import tableStyles2 from "../../../components/Layouts/tableStyle.module.scss";
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import {generateId} from '../../../helpers/helpers'

export const SocialSecurityScheduleDetail = ({backPage}) => {

  const store = useSelector((state) => state);

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "pplTm");

  const idAccount = store.loginReducer.currentAccount.id;

  const history = useHistory();

  const [data, setData] = useState({
    trigger:0,
    filters:{
      date: moment(new Date()).format('YYYY-MM-DD'),
      search: "",
      accrued:'',
      deductions:'',
      area_id: 0,
      page: 1,
      perpage: 10,
      entity_account: idAccount,
    },
    modal:{
      show:false,
      isEditing:false,
      denial:''
    }
  })

  const {filters,trigger,modal} = data


  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);


  const renderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className={`col-2 px-2 text-start`}>
          {!!elem?.id ? elem.id : "-"}
        </td>

        <td className="col-3 text-start">
          {!!elem.employed ? elem.employed : "-"}
        </td>

        <td className="col-2 text-end">
          {!!elem?.salary ? (elem.salary !== ' ' ? '$ ' : '') + elem.salary : "-"}
        </td>

        <td className="col-1 text-center">
          {!!elem?.workingDays ?  elem.workingDays : "-"}
        </td>
        
        <td className="col-2 text-end">
          {!!elem?.disabilities ?  elem.disabilities : '-'}
        </td>
        
        <td className="col-2 text-end px-2">
          {!!elem?.Balance ?  elem.Balance : '-'} 
        </td>
      </tr>
    );
  };

  const listPremiumSchedule = [
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
    {
      id:generateId() , 
      employed:'Jhan carlos ortegon',
      salary:'2.500.000',
      workingDays:'180', 
      disabilities:'$ 1.650.000', 
      Balance:'$ 4.200.000', 
    },
      {
      id:' ', 
      employed:' ',
      salary:' ',
      workingDays:<span className="fw-bold">Totales</span>, 
      disabilities:<span className="fw-bold">$25.000.000</span>, 
      Balance:<span className="fw-bold">$25.000.000</span>, 
    },
  
  ]

  const renderHeaders = [
    <th key={1} className="text-start col-2 px-2">
      ID
    </th>,

    <th key={2} className="text-start col-3">
      Empleado
    </th>,

    <th key={3} className="text-end col-2">
      Salario base
    </th>,

    <th key={4} className="text-center col-1">
      Días laborados
    </th>,

    <th key={5} className="text-end col-2">
      Incapacidades
    </th>,

    <th key={6} className="text-end col-2 px-2">
      Saldo
    </th>,

  ];

  const closeModal = ()=>{
    setData(state=>({...state, modal:{
      show:false,
      isEditing:false,
      denial:''
    }}))
  }

  const validationRequest = ()=>{
    
  }
   

  return (
    <div className="d-flex flex-column w-100">
    
    <OrdModal
      title={modal.isEditing ? "" : "Denegación"}
      show={modal.show}
      btnYesName={modal.isEditing ? "Guardar" : "Guardar"}
      btnNoName={"Cancelar"}
      size={550}
      onHide={() => closeModal()}
      btnYesEvent={() => validationRequest()}
      btnNoEvent={() => closeModal()}
      sizeBody={`${tableStyles2.modalHeight}`}
      titleClassName={tableStyles2.darkBlueText}
      btnYesStyle={tableStyles2.btnYesStyle}
      btnCancelStyle={tableStyles2.btnCancelStyle}
    >

      <Grid container spacing={3}>

        <Grid item xs={12}>
        <textarea
            placeholder={"Escribir..."}
            rows="25"
            cols="55"
            style={{ height: "4rem" }}
            value={modal.denial}
            className={`text-secondary ord-roundInput w-100 focusBlueVisible`}
            onChange={(e) =>
              setData(state=>({...state,modal:{...state.modal,denial:e.target.value}}))
            }
        ></textarea>
        </Grid>
    
      </Grid>

    </OrdModal>

    <div className="w-80 mx-auto">

      <h1 className={`${tableStyles.title} mb-0 d-flex gap-2 align-items-center`}
      style={{marginLeft: "-32px"}}
      >
        <img 
          onClick={()=>{
            backPage.setData(state=>({...state,backPage:false}))
          }}
          height='30px'
          src={backArrow} 
          alt="backArrow" 
          className={` pointer mr-2`}
        />
        <pre className="m-0">
          Sanitas
        </pre>
      </h1>
      <pre style={{color:'#01a0f6', fontSize:'24px'}}>EPS</pre>


     <Grid container padding={0} spacing={3}>
        <Grid padding={0} item xs={3}>
          <label className={tableStyles.crudModalLabel}>
            Fecha
          </label>
          
          <input
            className={`register-inputs`}
            type="date"
            value={filters.date}
            onChange={(e) =>
              setData(state=>({...state, filters:{...state.filters, date: e.target.value}}))
            }
          />
        </Grid>

        <Grid padding={0} item xs={3} className='d-flex align-items-end '>
          <input
            className={`${tableStyles.blueSearch} mr-2`} //${tableStyles.clearBlueSearch} 
            style={{ flex: 1, color: `#73a6dc` }}
            name="search"
            type="text"
            placeholder="Buscar..."
            value={filters.search}
            onChange={(e)=>
              setData(state=>({...state, filters:{...state.filters, search: e.target.value}}))
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setData(state=>({...state, trigger: state.trigger+1}))
              }
            }}
          />

          <img
            src={Search}
            style={{ cursor: "pointer" }}
            alt="Search icon"
            onClick={() => {
              setData(state=>({...state, trigger: state.trigger+1}))
            }}
          />
        </Grid>
        
        <Grid padding={0} item xs={6} className="d-flex align-items-end justify-content-end gap-1">
          <img src={Excel} alt="Excel" />
        </Grid>
        
     </Grid>

     <Grid container>

        <Grid item xs={12}>
          <GenericTableNew headers={renderHeaders} dark={true}>
            {
              listPremiumSchedule.map((el)=>{
                return renderElement(el);
              })
            }
          </GenericTableNew>
        </Grid>
        
        <Grid item xs={12}>
          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {store.partnersReducer.personal ? filters.page : ""}
              {" de "}
              {Math.ceil(store.partnersReducer.totalPersonal / filters.perpage)
                ? Math.ceil(
                  store.partnersReducer.totalPersonal / filters.perpage
                )
                : ""}{" "}
              ({store.partnersReducer.totalPersonal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              // itemsCountPerPage={2}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.partnersReducer.totalPersonal}
              // totalItemsCount={2}
              pageRangeDisplayed={5}
              onChange={(e) => setData(state=>({ ...state,filters:{...filters, page: e} }))}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </Grid>
     </Grid>
    </div>

    <hr />                  
    <Grid className="d-flex justify-content-end gap-3 mt-3 w-80 m-auto">
        <Button 
          style={{textTransform: 'none'}} 
          className={tableStyles.btnCancelStyle}
          onClick={()=>{
            backPage.setData(state=>({...state,backPage:false}))
          }}
        >
          Cancelar
        </Button>
     </Grid>
    </div>
  );
}
