import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import GenericTableNew from "../Layouts/GenericTableNew";
import {
  customSelectNewDark,
  customSelectNew,
  customSelectStyle,
} from "../Layouts/react-select-custom";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import Pagination from "react-js-pagination";
import miniShearchIcon from "../../assets/img/icons/miniShearchIcon.svg";
import iconPrint from "../../assets/img/icons/iconPrint.svg";
import Search from "../../assets/img/icons/lupa.svg";
import LupaClear from "../../assets/img/icons/lupaClear.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import ModalNew from "../Layouts/ModalNew";
import Swal from "sweetalert2";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import {
  convertMoneyFormat,
  formatToRcSelect,
  isEmptyOrUndefined,
  message,
} from "../../helpers/helpers";
import CustomPopup from "../Popup/customPopup";
import { SlideDownButton } from "../Layouts/SlideDownButton";
import {
  getNoteTypeList,
  getProvidersList,
  getProvidersNotes,
  listBIllsAndVal,
  noteRecords,
} from "../../actions/notesAction";
import { useDispatch, useSelector } from "react-redux";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import { Tooltip } from "@material-ui/core";
import { PdfViewer } from "../Layouts/PdfViewer";
import { getPdfTest } from "../../actions/IndividualAuctionActions";
import Loader from "react-loader-spinner";
export const NoteQuery = () => {
  const store = useSelector((state) => state);
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [selectedNote, setSelectedNote] = useState("");
  const arrProvidersList = store.notesReducer?.providersList;
  const arrRecords = store.notesReducer?.noteRecords;
  const [selectedProvider, setSelectedProvider] = useState("");
  const [consecutive, setConsecutive] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const arrNotesTypes = store.notesReducer.noteTypeList;
  const [detailNote, setDetailNote] = useState({});
  const providersFormatted = formatToRcSelect(arrProvidersList, "id", "name");
  const [showPdf, setShowPdf] = useState(false);
  const token = store.loginReducer.Authorization;
  const [base64, setBase64] = useState("");
  const [showOpt, setShowOpt] = useState({
    show: false,
    arrow: false,
  });
  const getPdf = async (id) => {
    const result = await getPdfTest(
      {
        id: id,
        doc: 16,
      },
      token
    );
    if (result?.success) {
      return setBase64(result?.pdf[0]?.key?.split("'")[1]);
    } else {
      message(
        "error",
        "Ha ocurrido un error",
        "No ha sido posible cargar el documento", undefined, true
      );
      return setShowPdf(false);
    }
  };

  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  const noteTypeFilters = {
    account: idEnterprise,
    page: 1,
    perpage: 10,
  };
  const providersNotesFilters = {
    eaccount: idEnterprise,
  };
  const provFilt = {
    id_account: idEnterprise,
  };
  const cOrD = [
    { value: "", label: "Seleccionar..." },
    { value: "c", label: "Crédito" },
    { value: "d", label: "Débito" },
  ];
  const ShowOptions = () => {
    if (showOpt?.show && showOpt?.arrow) {
      setShowOpt({
        ...showOpt,
        show: false,
        arrow: false,
      });
    } else {
      setShowOpt({
        ...showOpt,
        show: true,
        arrow: true,
      });
    }
  };
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    date_from: "",
    date_until: "",
    search: "",
    wm_movement_type: "donationEntry",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getProvidersNotes(idEnterprise));
    dispatch(getProvidersList(provFilt));
    dispatch(getNoteTypeList(providersNotesFilters));
    dispatch(noteRecords({ page: 1, perpage: 10, account: idEnterprise, eaccount: store.loginReducer.currentAccount.id }));
  }, []);

  useEffect(()=>{
    handleSubmit()
  }, [selectedNote, selectedProvider, consecutive, dateFrom, dateTo])

  let i = 0;

  const [modalAlertControl, setModalAlertControl] = useState({
    showFirst: false,
    showSecond: false,
    showThird: false,
    showFourt: false,
  });
  const listElem = () => {
    let elemMap;
    if (arrRecords !== undefined) {
      const elem2 = arrRecords;
      if (elem2?.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-start px-2">{elem.id}</td>
        <td className="col-md-2 text-center">
          {elem.rm_note_type === "c" ? "Credito" : "Débito"}
        </td>
        <td className="col-md-4 text-start">{elem.provider_ref.name}</td>
        <td className="col-md-2 text-center">
          {convertDateToLatinFormat(elem.rn_date)}{" "}
        </td>
        <td className="col-md-2 text-center">{elem.bill_items?.length}</td>
        <td className="col-md-2 text-center cursorPointer">
          <div
            className={`d-flex`}
            onClick={() => handleClickFirstModal(elem)}
            style={{ justifyContent: "flex-end", marginRight: "5px" }}
          >
            <img
              className="cursorPointer"
              src={miniShearchIcon}
              alt="Search icon"
            />
          </div>
        </td>
      </tr>
    );
  };

  const localStyles = {
    endPagination: {
      display: "flex",
      justifyContent: "flex-end",
    },
    blueInputText: {
      color: "#005dbf",
    },
    footerStyles: {
      display: "flex",
      justifyContent: "flex-end",
      width: "330%",
    },
    noBorder: { border: "none", padding: "8px" },
  };

  const renderHeaders = [
    <th
      className="col-md-1 text-start px-2"
      style={{ borderTopLeftRadius: "10px" }}
      key={i}
    >
      No. Nota
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Tipo
    </th>,
    <th className="col-md-2 text-start" key={i + 1}>
      Proveedor
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Fecha
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Facturas afectadas
    </th>,
    <th
      className="col-md-1 text-center"
      style={{ borderTopRightRadius: "10px" }}
      key={i + 1}
    >
      &nbsp;
    </th>,
  ];

  /* -------------------------------------------------------------------------- */
  /*                           Handle changes section                           */
  /* -------------------------------------------------------------------------- */
  const handleDetails = () => {
    // message("success", "any text", "any text");
    setModalAlertControl({
      ...modalAlertControl,
      showSecond: true,
    });
  };
  const handleProviders = (e) => {
    // if (!isEmptyOrUndefined(selectedNote)) {
    setSelectedProvider(e.value);
    if (!isEmptyOrUndefined(selectedProvider)) {
      const filts = { account: idAccount, provider: selectedProvider };
      dispatch(listBIllsAndVal(filts));
    }
    // }
  };
  const handleSubmit = () => {
    const objFilter = {
      note_type: selectedNote,
      provider: selectedProvider,
      note_date_from: dateFrom,
      date_date_until: dateTo,
      page: 1,
      perpage: 10,
      account: idEnterprise,
      consecutive: consecutive,
      eaccount: store.loginReducer.currentAccount.id,
    };

    dispatch(noteRecords(objFilter));
  };

  const handleClickFirstModal = (elem) => {
    getPdf(elem.id);
    setDetailNote(elem);
    setModalAlertControl({
      ...modalAlertControl,
      showFirst: true,
    });
  };

  const handlePageChange = (val) => {
    setFilters({
      ...filters,
      page: val,
    });
  };

  const handleDateTo = (e) => {
    if (e.target.value < dateFrom) {
      setDateTo("");
      return message(
        "warning",
        "Advertencia",
        " La fecha desde no puede ser mayor a la fecha hasta", undefined, true
      );
    } else {
      setDateTo(e.target.value);
    }
  };

  return (
    <>
      <ModalNew
        hideCancelButton
        btnYesEvent={() =>
          setModalAlertControl({
            ...modalAlertControl,
            showFirst: false,
          })
        }
        btnYesName="Aceptar"
        size="700"
        title="Detalle de nota"
        subtitle={`Nota crédito No. ${detailNote.id}`}
        show={modalAlertControl.showFirst}
        onHide={() =>
          setModalAlertControl({
            ...modalAlertControl,
            showFirst: false,
          })
        }
      >
        {" "}
        <Row className="d-flex">
          {/* /* -------------------------------- PDF Viwer ------------------------------- */}

          <ModalNew
            title="Vista previa"
            show={showPdf}
            btnNoName={"Cancelar"}
            size="700"
            btnYesDisabled={false}
            onHide={() => setShowPdf(false)}
            btnNoEvent={() => setShowPdf(false)}
            btnNoDisabled={false}
          >
            <PdfViewer
              downloadable
              file={`data:application/pdf;base64,${base64}`}
            ></PdfViewer>
          </ModalNew>
          <Col
            xs={12}
            className="d-flex justify-content-end mt-2 cursorPointer"
          >
            <Tooltip title="Imprimir">
              <img
                // className={`${detailStyles.titleIcons}`}
                src={iconPrint}
                alt="imprimir"
                onClick={() => setShowPdf(true)}
              />
            </Tooltip>
          </Col>
        </Row>
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <Row className="d-flex ">
          <Col xs={4}>
            <p className={`${tableStyles.crudModalLabel} `}>Proveedor</p>
            <input
              style={{ color: "gray" }}
              className="register-inputs"
              name="name"
              value={detailNote.provider_ref?.name}
              readOnly={true}
            />
          </Col>
          <Col xs={4}>
            <p className={`${tableStyles.crudModalLabel} `}>No. Consecutivo</p>
            <input
              style={{ color: "gray" }}
              className="register-inputs"
              name="name"
              value={detailNote.id}
              readOnly={true}
            />
          </Col>
          <Col xs={4}>
            <p className={`${tableStyles.crudModalLabel} `}>No. Fecha</p>
            <input
              style={{ color: "gray" }}
              className="register-inputs"
              value={detailNote.rn_date?.replace(
                /^(\d{4})-(\d{2})-(\d{2})$/g,
                "$3/$2/$1"
              )}
              name="name"
              readOnly={true}
            />
          </Col>
        </Row>
        <h3 className={`${tableStyles.subtitle} mt-4`}>Detalle de facturas</h3>
        {detailNote.bill_items?.length > 0 &&
          detailNote.bill_items.map((e) => {
            return (
              <Row className="d-flex ">
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} `}>
                    No. factura
                  </p>
                  <input
                    style={{ color: "gray" }}
                    value={e.rnd_bill_number}
                    className="register-inputs"
                    name="name"
                    readOnly={true}
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} `}>
                    Valor inicial facturas
                  </p>
                  <input
                    style={{ color: "gray" }}
                    value={convertMoneyFormat(e.rnd_total_value)}
                    className="register-inputs"
                    name="name"
                    readOnly={true}
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} `}>Valor nota</p>
                  <input
                    style={{ color: "gray" }}
                    value={convertMoneyFormat(e.rnd_value_note)}
                    className="register-inputs"
                    name="name"
                    readOnly={true}
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} `}>Saldo</p>
                  <input
                    style={{ color: "gray" }}
                    value={convertMoneyFormat(e.rnd_balance)}
                    className="register-inputs"
                    name="name"
                    readOnly={true}
                  />
                </Col>
              </Row>
            );
          })}
      </ModalNew>
      {store.notesReducer?.loading && (
        <div className="loading">
          <Loader type="Oval" color="#003F80" height={100} width={100} />
        </div>
      )}
      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Consulta de notas</h1>
          <div className={IndividualStyles.container2}>
            <div
              className={`${IndividualStyles.Aling} ${IndividualStyles.inputMargin} `}
            >
              <div
                className={`${IndividualStyles.Boton} `}
                onClick={() => ShowOptions()}
              >
                <img
                  src={LupaClear}
                  alt="añadir"
                  className={`mx-3  ${IndividualStyles.pointerNew} ${IndividualStyles.sizeNew2}`}
                />
                <span className={`${IndividualStyles.proArrowWrapper}`}>
                  {!!showOpt.arrow ? (
                    <span className={`${IndividualStyles.proArrow2}`}> </span>
                  ) : (
                    <span className={`${IndividualStyles.proArrow}`}> </span>
                  )}
                </span>
              </div>
            </div>
          </div>

          {!!showOpt.show ? (
            <>
              <Row className="d-flex">
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Tipo de nota</p>
                  <div className="d-flex">
                    <Select noOptionsMessage={() => 'No hay datos'}
                      onChange={(e) => setSelectedNote(e.value)}
                      options={cOrD}
                      className={"w-100"}
                      placeholder="Seleccionar..."
                      styles={customSelectNew}
                    />
                  </div>
                </Col>

                <Col xs={5}>
                  <p className={tableStyles.crudModalLabel}>Proveedor</p>
                  <div className="d-flex">
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={providersFormatted}
                      onChange={(e) => handleProviders(e)}
                      className={"w-100"}
                      placeholder="Seleccionar..."
                      styles={customSelectNew}
                      // isDisabled={selectedNote === "" ? true : false}
                    />
                  </div>
                </Col>

                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>No. Consecutivo</p>
                  <div className="d-flex">
                    <input
                      className={`register-inputs`}
                      value={consecutive}
                      name="requisition_date"
                      type="number"
                      placeholder="Escribir..."
                      onChange={(e) => setConsecutive(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Fecha desde</p>
                  <div className="d-flex">
                    <input
                      className={`register-inputs`}
                      value={dateFrom}
                      max={today}
                      name="requisition_date"
                      type="date"
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                  </div>
                </Col>

                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Fecha hasta</p>
                  <div className="d-flex">
                    <input
                      className={`register-inputs`}
                      value={dateTo}
                      max={today}
                      name="requisition_date"
                      type="date"
                      onChange={(e) => handleDateTo(e)}
                    />
                  </div>
                </Col>
                {/* 
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Valor desde</p>
                  <div className="d-flex">
                    <input
                      className={`register-inputs`}
                      name="requisition_date"
                      type="number"
                      onChange={(e) => setValueFrom(e.target.value)}
                    />
                  </div>
                </Col> */}

                <Col xs={3}>
                  {/* <p className={tableStyles.crudModalLabel}>Valor hasta</p> */}
                  <div className="d-flex">
                    {/* <input
                      className={`register-inputs`}
                      name="j"
                      type="number"
                      onChange={(e) => setValueTo(e.target.value)}
                    /> */}
                    <button onClick={() => handleSubmit()} className="mt-4">
                      <img
                        src={Search}
                        alt="User icon"
                        className={`${tableStyles.iconSvgMargin2}`}
                      />
                    </button>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          <GenericTableNew dark={true} headers={renderHeaders}>
            {listElem()}
          </GenericTableNew>

          <div
            className={`${paginationStyles.wrapper}`}
            style={localStyles.endPagination}
          >
            <p className={paginationStyles.paginationText}>
              Pag. {store.notesReducer.noteRecords ? filters.page : ""}
              {" de "}
              {Math.ceil(store.notesReducer.row_total / filters.perpage)
                ? Math.ceil(store.notesReducer.row_total / filters.perpage)
                : ""}{" "}
              ({store.notesReducer.row_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={store.notesReducer.row_total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
