import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "reactjs-popup/dist/index.css";
import { useGetMethod } from "../../../../Hooks/useFetch";
import alertIcon from "../../../../assets/img/icons/alert-circle-auxiliary-red.svg";
import Search from "../../../../assets/img/icons/lupa.svg";
import threeDots from "../../../../assets/img/icons/threeDots.svg";
import CustomPopupStyles from "../../../../components/Popup/customPopup.module.scss";
import { loader, today } from "../../../../helpers/helpers";
import GenericTableNew from "../../../Layouts/GenericTableNew";
import paginationStyles from "../../../Layouts/pagination.module.scss";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import CustomPopupExtend from "../../../Popup/customPopUpExtends";
import "../../../TableUsers/tableUserStyle.scss";

const PerformanceSurvey = () => {
  const store = useSelector((state) => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "minoreg");
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    startDate: "",
    endDate: "",
    status: "in_progress",
    page: 1,
    perpage: 10,
    eaccount: idAccount,
    contract_id: store?.loginReducer?.user_data?.payrollInfo?.id_contract
  });
  const { results: surveyListResults, load: surveyListLoader, trigger: getSurveyListMethod } = useGetMethod();

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/miNomina/miNomina/hojaDeVida');
    }

    getSurveyListMethod({
      url: "/payroll/performance_evaluation",
      objFilters: filters,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  // --------------------- SECTION TABLE  -------------------------

  const toolTipAlert = (message) => <Tooltip>{message}</Tooltip>;

  const renderElement = (elem) => {
    const options = [
      {
        text: "Evaluar",
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          handleClickSurvey("detail", elem);
        },
      },
    ];
    // const dateAlert = moment(elem.endDate).subtract(2, "days").format("YYYY-MM-DD");
    // let showAlert = false;
    // if (dateAlert <= today() && elem.status === "in_progress") {
    //  showAlert = true;
    // }


    const dateAlert = moment(today()).add(2, "days").format("YYYY-MM-DD");
    let showAlert = false;
    let message;
    if (elem.endDate >= today() && elem.endDate <= dateAlert && elem.status === "in_progress") {
      showAlert = true;
      message = "Próximo a vencer";
    } else if (elem.endDate < today() && elem.status === "in_progress") {
      showAlert = true;
      message = "Vencida";
    }



    return (
      <tr key={elem.id}>
        <td
          className="col-md-3 text-start "
          style={{ paddingLeft: "2rem" }}
        >
          {elem.title ? elem.title : "-"}
        </td>
        <td className="col-md-2 text-center">{elem.startDate ?? "-"}</td>
        <td
          className="col-md-2 text-center"
        // style={{ paddingLeft: "2.3rem" }}
        >
          <div className="d-flex justify-content-center "
            style={{ marginLeft: `${showAlert ? "1.7rem" : ""}` }}
          >
            <span className=" text-center">{elem.endDate ?? "-"}</span>
            {showAlert && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 200 }}
                overlay={toolTipAlert(message)}
              >
                <img
                  alt="alertIcon"
                  src={alertIcon}
                  height="15px"
                  className="px-2"
                />
              </OverlayTrigger>
            )}
          </div>
        </td>
        <td className="col-md-2 text-center">{elem.numberRespondents ?? "-"}</td>
        <td className="col-md-2 text-center">
          <div
            className="rounded-pill p-1"
            style={{
              backgroundColor: elem?.background,
            }}
          >
            <b style={{ color: elem?.fontColor }}>{elem.statusName ? elem.statusName : "-"}</b>
          </div>
        </td>
        <td className="col-md-1 text-center cursorPointer">
          <div>
            <CustomPopupExtend
              noHover
              className={`align-self-end`}
              // triggerClass={`${tableStyles.hoverPopup} `}
              triggerSrc={threeDots}
              extraButtons={options}
            />
          </div>
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    // surveyListResults
    if (surveyListResults?.results?.length > 0) {
      const elem2 = surveyListResults?.results;
      if (elem2?.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th
      key={1}
      className=" pl-5 text-start"
      style={{ paddingLeft: "2rem" }}
    >
      Título de la encuesta
    </th>,
    <th
      key={2}
      className="text-center"
    >
      Fecha inicio
    </th>,
    <th
      key={3}
      className="text-center"
    >
      Fecha fin
    </th>,
    <th
      key={4}
      className="text-center"
    >
      No. de encuestados
    </th>,
    <th
      key={5}
      className="text-center"
    >
      Estado
    </th>,
    <th
      key={6}
      className="text-center"
    >
      &nbsp;
    </th>,
  ];
  // ----------------!SECTION--------------------------------

  const handleClickSurvey = (action, elem) => {
    history.push({
      pathname: "/miNomina/miNomina/registros/detalleDeEvaluacion",
      state: { data: elem },
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const statusOptions = [
    { value: "", label: "Seleccione..." },
    { value: "in_progress", label: "En curso" },
    { value: "to_start", label: "Por iniciar" },
    { value: "completed", label: "Finalizada" },
    { value: "draft", label: "Borrador" },
  ];

  return (
    <>
      {(surveyListLoader) && loader}
      <div className={""}>
        <div className={""}>
          <Row className="align-items-end">
            <Col
              className="d-block"
              xs={2}
            >
              <p className={`${tableStyles.crudModalLabel}`}>Fecha inicio</p>
              <input
                onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                type="date"
                placeholder="Escribe..."
                className={`register-inputs`}
                name="position"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e);
                  }
                }}
              />
            </Col>
            <Col
              className="d-block"
              xs={2}
            >
              <p className={`${tableStyles.crudModalLabel}`}>Fecha fin</p>
              <input
                onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                type="date"
                placeholder="Escribe..."
                className={`register-inputs`}
                name="position"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e);
                  }
                }}
              />
            </Col>
            {/* <Col
       className="d-block"
       xs={2}
      >
       <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
       <Select noOptionsMessage={() => 'No hay datos'}
        styles={customSelectNewDark}
        placeholder={""}
        value={statusOptions.find((x) => x.value === filters.status)}
        onChange={(e) => {
         setFilters({
          ...filters,
          status: e.value,
          page: 1,
         });
         setTrigger(trigger + 1);
        }}
        options={statusOptions}
       />
      </Col> */}

            <Col
              className="d-block"
              xs={6}
            >
              <div className="pt-4 d-flex">
                <input
                  className={`${tableStyles.SearchNew} mr-2 text-secondary ${tableStyles.outlineNone}`}
                  onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                  name="search"
                  type="text"
                  placeholder="Buscar por título..."
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(e);
                    }
                  }}
                />
                <img
                  src={Search}
                  className="cursorPointer"
                  width="24px"
                  alt="Search icon"
                  onClick={(e) => handleSearch(e)}
                />
              </div>
            </Col>
          </Row>

          <GenericTableNew
            headers={renderHeaders}
            dark={true}
          >
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {surveyListResults?.results ? filters.page : ""}
              {" de "}
              {Math.ceil(surveyListResults?.rowTotal / filters.perpage) ? Math.ceil(surveyListResults?.rowTotal / filters.perpage) : ""} (
              {surveyListResults?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={surveyListResults?.rowTotal}
              pageRangeDisplayed={5}
              onChange={(e) => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceSurvey;
