import React, { useState } from "react";
import "reactjs-popup/dist/index.css";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TypesCollections } from "./Tabs/TypesCollections";
import { Coins } from './Tabs/Coins';
import { Col, Row } from "react-bootstrap";

const TableCollections = (props) => {

    const [tabActive, setTabActive] = useState('TypesCollections')
    const [newOrdenActive, setNewOrdenActive] = useState(false)


    return (
        <div className={tableStyles.container}>

            <h1 className={tableStyles.title} style={{ marginLeft: "76px" }}>Recaudos</h1>

            <div style={{ marginLeft: "76px", width: '95%' }} onClick={() => {
                newOrdenActive && setNewOrdenActive(false)
            }}>

                <Row style={{ margin: '0px', color: '#005dbf', marginTop: '15px', marginBottom: '15px', textAlign: 'center', }}>
                    <Col
                        onClick={() => setTabActive('TypesCollections')}
                        xs={2} style={tabActive === 'TypesCollections' ?
                            { border: '1px solid #CECECE', padding: '5px 0px', borderRadius: '10px 10px 0px 0px', borderBottom: '0px' }
                            : { cursor: 'pointer', background: '#F5F7FA', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px 10px 0px 0px', borderBottom: '1px solid #CECECE' }
                        }

                    >
                        <span>Tipos de recaudos</span>
                    </Col>
                    <Col
                        onClick={() => setTabActive('Coins')}
                        xs={2} style={tabActive === 'Coins' ?
                            { border: '1px solid #CECECE', padding: '5px 0px', borderRadius: '10px 10px 0px 0px', borderBottom: '0px' }
                            : { cursor: 'pointer', background: '#F5F7FA', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px 10px 0px 0px', borderBottom: '1px solid #CECECE' }
                        }

                    >
                        <span>Monedas</span>
                    </Col>
                    <Col xs={8} style={{ borderBottom: '1px solid #CECECE' }}></Col>
                </Row>

                {
                    tabActive === 'TypesCollections' &&
                    <TypesCollections />
                }

                {
                    tabActive === 'Coins' &&
                    <Coins />
                }

            </div>


        </div>
    )
}


export default TableCollections;

