import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { verifyAccessToken } from '../../actions/loginActions';
import { getTotalIndex } from '../../actions/userActions';
import { useHistory, useLocation } from "react-router-dom"
import { OrderingHomeContent } from "./AlternativeHome/OrderingHomeContent";
import { AssistanceHomeContent } from "./AlternativeHome/AssistanceHomeContent";
import { AppointmentsHomeContent } from './AlternativeHome/AppointmentsHomeContent';
import { TelemedicineHomeContent } from './AlternativeHome/TelemedicineHomeContent';
import { MedicalAccountsHomeContent } from './AlternativeHome/MedicalAccountsHomeContent';
import { AdmissionHomeContent } from './AlternativeHome/AdmissionHomeContent';
import { ManagementHomeContent } from './AlternativeHome/ManagementHomeContent';
import { PayrollHomeContent } from './AlternativeHome/PayrollHomeContent';
import { AccountingHomeContent } from './AlternativeHome/AccountingHomeContent';
import { PortfolioHomeContent } from './AlternativeHome/PortfolioHomeContent';
import { ShoppingHomeContent } from './AlternativeHome/ShoppingHomeContent';
import { InvoicingHomeContent } from './AlternativeHome/InvoicingHomeContent';
import { TreasuryHomeContent } from './AlternativeHome/TreasuryHomeContent';

const Home = (props) => {
  const location = useLocation();
  const counter = useSelector((state) => state);
  const categories = counter.loginReducer?.newCategories
  const path = location.pathname.split("/")[1]
  let portal;
  if (path === "nomina" || path === "compras"
    || path === "contabilidad" || path === "tesoreria" || path === "facturacion" || path === "cartera"
    || path === "administracion" || path === "gerencia") {
    portal = "Portal Administrativo"
  } else {
    portal = "Portal Asistencial"
  }
  let categoryMo = categories.find((e) => e.description === portal)
  let moduleH = categoryMo?.data.find((e) => e.url == path)
  let state = {
    group: "Portal Administrativo",
    catDetail: {
      home_img: moduleH.home_img,
      id: moduleH.id,
      img: moduleH.img,
      subtitle: moduleH.subtitle,
      title: "Módulo de " + moduleH.title,
      url: moduleH.url,
    },
  };
  let { catDetail, group } = location?.state ?? state
  
  const store = useSelector((state) => state);
  const history = useHistory();
  const isAuth = store?.loginReducer.Authenticated;
  const Authentication = store.loginReducer?.Authorization;
  const category = store?.loginReducer?.category;
  
  
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth) {
      history.push("/login")
    }
    dispatch(getTotalIndex())
    dispatch(verifyAccessToken(Authentication));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const siteId = store.userReducer.site
  
  const centerClass = (siteId === "" && category === "Admisión") ? "align-self-center" : ""
  
  return (
    <div className="d-flex w-100 mx-auto">
      <div className={`container-fluid ${centerClass} ms-4`}>
        {
          (category === "Admisión" && siteId) ? (
            <h1 className={`${group === "Portal Asistencial" ? "ord-title-blue" : "title-blue-admin"} mt-5 fw-bold`}>
              {(siteId !== "" || category !== "Admisión") && catDetail?.title}
            </h1>
          ) : ''
        }
        
        {
          (category !== "Admisión") ? (
            <h1 className={`${group === "Portal Asistencial" ? "ord-title-blue" : "title-blue-admin"} mt-5 fw-bold`}>
              {catDetail?.title}
            </h1>
          ) : ''
        }
        
        {
          catDetail?.title === "Módulo de Ordenamientos" && <OrderingHomeContent />
        }
        {
          catDetail?.title === "Módulo de Asistencial" && <AssistanceHomeContent />
        }
        {
          catDetail?.title === "Módulo de Citas" && <AppointmentsHomeContent />
        }
        {
          catDetail?.title === "Módulo de Administración" && <ManagementHomeContent />
        }
        {
          catDetail?.title === "Módulo de Nómina" && <PayrollHomeContent />
        }
        {
          catDetail?.title === "Módulo de Admisión" && <AdmissionHomeContent />
        }
        {
          catDetail?.title === "Módulo de Telemedicina" && <TelemedicineHomeContent />
        }
        {
          catDetail?.title === "Módulo de Cuentas médicas" && <MedicalAccountsHomeContent />
        }
        {
          catDetail?.title === "Módulo de Contabilidad" && <AccountingHomeContent />
        }
        {
          catDetail?.title === "Módulo de Compras" && <ShoppingHomeContent />
        }
        {
          catDetail?.title === "Módulo de Cartera" && <PortfolioHomeContent />
        }
        {
          catDetail?.title === "Módulo de Facturación" && <InvoicingHomeContent />
        }
        {
          catDetail?.title === "Módulo de Tesorería" && <TreasuryHomeContent />
        }

        {
          (siteId !== "" || category !== "Admisión") &&
          <div>
            <img
              className={`homeBackground `}
              alt="HomeBackGround"
              src={`${catDetail.home_img}`}>
            </img>

          </div>
        }

      </div>
    </div>
  );
}

export default Home