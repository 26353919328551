import tableStyles from "./genericTable.module.scss";
import { Box } from '@mui/material';

export const GenericTableScrollDynamic = (props) => (

  <Box
    className={`${tableStyles.edgeOutside}`}
    display={'grid'}
    gridTemplateColumns={props.columns || '50% 50%'}
  >
    <table
      className={
        `table
          ${props?.lowerCase ? tableStyles.genericTableLower : tableStyles.genericTable}
          ${props?.oneHeadDarkBlue ? tableStyles.oneHeadDarkBlue : ""}
          ${props?.treeHeadDarkBlue ? tableStyles.treeHeadDarkBlue : ""}
          ${tableStyles.edgeOutside}
          `
      }
      style={props?.style}
    >
      <thead className={`${tableStyles.thead} ${props?.clearHeader ? "" : props?.dark ? tableStyles.theadDark : tableStyles.theadLight}`}>
        <tr className={`${props?.fontFamilyTable ? props?.fontFamilyTable : ""}`}>{props?.headerStatic}</tr>
      </thead>
      <tbody>
        {props?.bodyStatic()}
      </tbody>
    </table>

    <div
      className={`d-flex ${tableStyles.scrollSize}`}
      style={{ overflow: 'overlay' }}
    >
      <table
        className={
          `table
          ${props?.lowerCase ? tableStyles.genericTableLower : tableStyles.genericTable}
          ${props?.oneHeadDarkBlue ? tableStyles.oneHeadDarkBlue : ""}
          ${props?.treeHeadDarkBlue ? tableStyles.treeHeadDarkBlue : ""}
          ${tableStyles.edgeOutside2}
          `
        }
        style={props?.style}
      >
        <thead className={`${tableStyles.thead} ${props?.clearHeader ? "" : props?.dark ? tableStyles.theadDark : tableStyles.theadLight}`}>
          <tr className={`${props?.fontFamilyTable ? props?.fontFamilyTable : ""}`}>{props?.headerDynamic}</tr>
        </thead>
        <tbody>
          {props?.bodyDynamic()}
        </tbody>
      </table>
    </div>
  </Box>
)

