import React, { useEffect, useState } from "react";
import { OrdGenericTemplate } from "../OrderingModule/OrderingComponents/OrdGenericTemplate";
import editPencil from "../assets/img/icons/editPencil.svg";
import del from "../assets/img/icons/Basura.svg";
import { useGetMethod, usePostMethod } from "../Hooks/useFetch";
import { loader, message, swalConfirm, validateEmptyString } from "../helpers/helpers";
import { useSelector } from "react-redux";
import tableStyles from "../components/Layouts/tableStyle.module.scss";
import OrdModal from "../OrderingModule/OrderingComponents/OrdModal";
import { Col, Form, Row } from "react-bootstrap";
import ordComponentStyles from "../OrderingModule/OrderingComponents/ordComponentStyles.module.scss";

const AnulationReason = () => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;

  //  --------------------------- States 🪔 ------------------------
  const [showMotiveModal, setShowMotiveModal] = useState({
    show: false,
    isEditing: false,
  });
  const [data, setData] = useState({
    eaccount: idAccount,
    canApp: 0,
    canSurgery: 0,
    canDescription: "",
  });
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    canDescription: "",
    eaccount: idAccount,
  });

  //  --------------------------- Petitions  🤙 ------------------------------
  const { results: anulationResults, load: AnulationsLoader, trigger: getAnulations } = useGetMethod();
  const { load: updateMotiveLoader, trigger: updateMotive } = usePostMethod();
  const { load: createMotiveLoader, trigger: createNewMotive } = usePostMethod();

  useEffect(() => {
    getAnulations({
      url: "/medical/generals/cancellationReason/",
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  //  --------------------------- Modal -------------------------
  const openNewMotive = () => {
    setShowMotiveModal({ ...showMotiveModal, show: true, isEditing: false });
  };

  const openEditMotive = (item) => {
    setShowMotiveModal({ ...showMotiveModal, show: true, isEditing: true });
    setData({ ...data, canApp: item.canApp, canSurgery: item.canSurgery, canDescription: item.canDescription, canId: item.canId });
  };

  const closeMotiveModal = () => {
    setShowMotiveModal({ ...showMotiveModal, show: false, isEditing: false });
    setData({ ...data, canDescription: "",  canApp: 0, canSurgery: 0, canId: "" });
  };

  //  --------------------------- Methods ------------------------------

  const editMotive = (item) => {
    updateMotive({
      url: "/medical/generals/cancellationReason/",
      token: token,
      method: "PUT",
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
        closeMotiveModal();
      },
      succesAction: () =>
        message("success", `<span class=${tableStyles.ordClearBlueText}>Cambios guardados</span>`, `${data.canDescription}`),
    });
  };

  const createMotive = () => {
    createNewMotive({
      url: "/medical/generals/cancellationReason/",
      token: token,
      method: "POST",
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
        closeMotiveModal();
      },
      succesAction: () => message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo creado</span>`, `${data.canDescription}`),
    });
  };

  const disabledMotive = (item) => {
    swalConfirm({
      title: `¿Está seguro?`,
      text: `Se eliminará el motivo`,
      confirmButtonText: `Si, continuar`,
      doAfterConfirm: () => {
        updateMotive({
          url: "/medical/generals/cancellationReason/",
          token: token,
          method: "PUT",
          body: {
            canEnabled: 0,
            canId: item.canId,
          },
          doAfterSuccess: () => {
            setTrigger(trigger + 1);
            closeMotiveModal();
          },
          succesAction: () =>
            message("success", `<span class=${tableStyles.ordClearBlueText}>Se ha eliminado el motivo</span>`, `${item.canDescription}`),
        });
      },
    });
  };

  // -------------------------------- Validations -----------------------
  const validationRequest = (item) => {
    let validations = validateEmptyString([{ field: data.canDescription, message: "La descripción del motivo es obligatorio" }]);
    let checksValidations =
      data.canApp === 1 || data.canSurgery === 1
        ? true
        : message(
            "info",
            "",
            "Debe seleccionar una de las dos opciones. "
          );

    if (checksValidations &&  validations.valid ) {
      showMotiveModal.isEditing ? editMotive(item) : createMotive();
    }
  };

  // ----------------------------- checks ✔️--------------------------------
  const checkAppointment = (e) => {
    if (data.canApp === 0) {
      setData({ ...data, canApp: 1 });
    } else {
      setData({ ...data, canApp: 0 });
    }
  };
  const checkSurgery = () => {
    if (data.canSurgery === 0) {
      setData({ ...data, canSurgery: 1 });
    } else {
      setData({ ...data, canSurgery: 0 });
    }
  };

  return (
    <>
      {(AnulationsLoader || updateMotiveLoader || createMotiveLoader) && loader}

      <OrdModal
        title={showMotiveModal.isEditing ? "Editar motivo de anulación" : "Nuevo motivo de anulación"}
        show={showMotiveModal.show}
        btnYesName={showMotiveModal.isEditing ? "Editar" : "Crear"}
        btnNoName={"Cancelar"}
        size={550}
        onHide={() => closeMotiveModal()}
        btnYesEvent={() => validationRequest()}
        btnNoEvent={() => closeMotiveModal()}
        sizeBody={`${tableStyles.modalHeight}`}
      >
        <div>
          <Row>
            <Form.Group className="mb-3" controlId="fName">
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <b className="text-start">&nbsp;Descripción</b>
              </Form.Label>
              <textarea
                value={data?.canDescription}
                placeholder={"Escribir..."}
                rows="70"
                cols="60"
                style={{ height: "112px" }}
                className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
                key={trigger + "observations"}
                onChange={(e) =>
                  setData({
                    ...data,
                    canDescription: e.target.value,
                  })
                }
              ></textarea>
            </Form.Group>
          </Row>
          <Row className="">
            <Col xs={6} className={`d-flex justify-content-center  `}>
              <div
                className={`d-flex w-100 justify-content-center text-end p-3  ${tableStyles.cardCheckAppointment}`}
                style={{ marginRight: "2px", marginBottom: "0px", marginTop: "-2px" }}
              >
                <input
                  className={`border border-dark-blue form-check-input p1 check-dark-blue ${tableStyles.cursorPointer} `}
                  type="checkbox"
                  name="edit"
                  value="selectedAll"
                  checked={data.canApp === 1 ? true : false}
                  onClick={() => checkAppointment()}
                />
                <b className={`ml-2 pl-3 ${tableStyles.tlnTextGray}`}>Citas</b>
              </div>
            </Col>
            <Col xs={6} className={`d-flex justify-content-center  `}>
              <div
                className={`d-flex w-100 justify-content-center text-end p-3  ${tableStyles.cardCheckAppointment}`}
                style={{ marginRight: "2px", marginBottom: "0px", marginTop: "-2px" }}
              >
                <input
                  className={`border border-dark-blue form-check-input p1 check-dark-blue ${tableStyles.cursorPointer}`}
                  type="checkbox"
                  name="edit"
                  value="selectedAll"
                  checked={data.canSurgery === 1 ? true : false}
                  onClick={() => checkSurgery()}
                />
                <b className={`ml-2 pl-3 ${tableStyles.tlnTextGray}`}>Cirugía</b>
              </div>
            </Col>
          </Row>
        </div>
      </OrdModal>

      <OrdGenericTemplate
        className="w-90"
        showBottomBorder={false}
        title={"Motivos de anulación"}
        titleSize={12}
        buttonNewLabel={"Nuevo motivo"}
        buttonNewAction={() => {
          // openNewCombo();
        }}
        searchWithNew={{
          colSize: "12",
          WSize: "90",
          buttonNewLabel: "Nuevo motivo",
          onChange: (e) => {
            setFilters({ ...filters, canDescription: e.target.value });
          },
          buttonNewAction: () => openNewMotive(),
          onSubmit: (e) => {
            e.preventDefault();
            setTrigger(trigger + 1);
          },
        }}
      >
        <div className="w-90 mx-1">
          {anulationResults?.results?.length > 0 &&
            anulationResults.results.map((item, index) => {
              return (
                <>
                  {/* <OrdCard key={item} className="my-3" title={titleCard(index)}></OrdCard> */}
                  <div className={`${ordComponentStyles.ordCard} my-3 `}>
                    <div className="my-2">
                      <div className="d-flex justify-content-between ">
                        <div>{item.canDescription}</div>
                        {item.canLocked === 0 && (
                          <div className="d-flex">
                            <button
                              onClick={() => {
                                openEditMotive(item);
                              }}
                            >
                              <img
                                alt="edit"
                                className="cursorPointer"
                                style={{
                                  // transform: !rotate && "rotate(180deg)",
                                  width: "20px",
                                }}
                                width={10}
                                src={editPencil}
                              />
                            </button>
                            <button onClick={() => disabledMotive(item)}>
                              <img
                                alt="disabled"
                                className="cursorPointer "
                                style={{
                                  // transform: !rotate && "rotate(180deg)",
                                  width: "20px",
                                  marginLeft: "20px",
                                }}
                                width={10}
                                src={del}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="d-flex ">
                        <span>
                          <b>Tipo:</b>
                        </span>
                      </div>
                      {item.canApp === 1 && <div className={`${tableStyles.ordRedButton} ml-5 text-center`}> Citas </div>}
                      {item.canSurgery === 1 && <div className={`${tableStyles.ordOrangeButton} ml-5 text-center`}> Cirugía </div>}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default AnulationReason;
