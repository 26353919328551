import { Col, Form, Row } from "react-bootstrap"
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";
import { convertMoneyFormatNew } from "../../../helpers";

export const CardTotal = ({valuesPay}) => {
    return (
        <Row className="">
            <div className="">
                <div className={`d-flex justify-content-end mb-2 ${tableStyles.addBorderLineGray} py-2`}>
                    <Col xs={2}>
                        <div className="d-flex flex-column">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span className="text-start">&nbsp;Total</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span className="text-start">&nbsp;Descuento</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span className="text-start">&nbsp;Anticipos</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <b className="text-start">&nbsp;Total a pagar</b>
                            </Form.Label>
                        </div>
                    </Col>
                    <Col xs={1}>
                        <div className="d-flex flex-column">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span>$&nbsp;{`${convertMoneyFormatNew({textNumber:Number(valuesPay.amount),SymbolShow:true})}`}</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span>{`$ ${convertMoneyFormatNew({textNumber:Number(valuesPay.discount), SymbolShow:true})}`}</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span>{`$ ${convertMoneyFormatNew({textNumber:Number(valuesPay.advance), SymbolShow:true})}`}</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <b>$&nbsp;{`${convertMoneyFormatNew({textNumber:Number(valuesPay.amountTotal),SymbolShow:true})}`}</b>
                            </Form.Label>
                        </div>
                    </Col>
                </div>
            </div>
        </Row>
    )
}