import React, { useEffect, useState } from "react";
import { formatToRcSelect, loader, message, swalConfirm, validateEmptyString } from "../../helpers/helpers";
import { useSelector } from "react-redux";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Col, Form, Row } from "react-bootstrap";
import ordComponentStyles from "../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss";
import CustomPopupStyles from "../../../src/components/Popup/customPopup.module.scss";
import threeDots from "../../assets/img/icons/threeDots.svg";
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import { OrdGenericTemplate } from './../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from './../../OrderingModule/OrderingComponents/OrdModal';
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import OrdTable from './../../OrderingModule/OrderingComponents/OrdTable';
import { useHistory } from 'react-router';
import { OrdAccordion } from "../../OrderingModule/OrderingComponents/OrdAccordion";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from "react-select";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg'
import ADD from '../../assets/img/icons/add-check.svg'
import OrdersPage from "../TabsProviders/OrdersPage";
import SubscriptionsPage from "../TabsProviders/SubscriptionsPage";

const SupplierOrders = () => {

    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const idAccount = store.loginReducer.currentAccount.id;
    const history = useHistory();

    const [newOrdenActive, setNewOrdenActive] = useState(false)

    //  --------------------------- States 🪔 ------------------------
    const [showAssigmentModal, setShowAssigmentModal] = useState({
        show: false,
        isEditing: false,
    });

    const [tabActive, setTabActive] = useState('OrdersPage')

    const [data, setData] = useState({
        idAssigment: '',
        personal: {
            name: '',
            locations: []
        },
        position: '',
        enable: true
    });

    const [dataTable, setDataTable] = useState()

    const [trigger, setTrigger] = useState(0);

    const [filters, setFilters] = useState({
        // canDescription: "",
        // eaccount: idAccount,
        assigmentEnabled: false
    });

    //  --------------------------- Petitions  🤙 ------------------------------
    const [assignmentResults, setAssignmentResults] = React.useState({
        results: [
            {
                "canApp": 1,
                "canDescription": "sd",
                "canId": 8,
                "canLocked": 1,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "Solicitud de cupo adicional anulada",
                "canId": 9,
                "canLocked": 1,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "Paciente no se present\u00f3",
                "canId": 10,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "El paciente se tuvo que ir",
                "canId": 19,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "El m\u00e9dico se tuvo que ir",
                "canId": 20,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            },
            {
                "canApp": 1,
                "canDescription": "hola mundo 2",
                "canId": 21,
                "canLocked": 0,
                "canSurgery": 0,
                canEnabled: true
            }
        ]
    })
    // const { results: anulationResults, load: AnulationsLoader, trigger: getAnulations } = useGetMethod();
    const { load: updateMotiveLoader, trigger: updateMotive } = usePostMethod();
    const { load: createMotiveLoader, trigger: createNewMotive } = usePostMethod();

    // useEffect(() => {
    //     getAnulations({
    //         url: "/medical/generals/cancellationReason/",
    //         objFilters: filters,
    //         token: token,
    //     });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [trigger]);

    //  --------------------------- Modal -------------------------
    const openNewAssigment = () => {
        setData({
            idAssigment: '',
            personal: {
                name: '',
                locations: []
            },
            position: '',
            enable: true
        })
        setShowAssigmentModal({ ...showAssigmentModal, show: true, isEditing: false });
    };

    const openEditAssigment = (item) => {
        setShowAssigmentModal({ ...showAssigmentModal, show: true, isEditing: true });
        setData({ ...item })
    };
    const closeAssigmentModal = () => {
        setShowAssigmentModal({ ...showAssigmentModal, show: false, isEditing: false });
        // setData({ ...data, canDescription: "", canApp: 0, canSurgery: 0, canId: "" });
    };
    //  --------------------------- Methods ------------------------------

    const editAssigment = (item) => {
        // updateMotive({
        //     url: "/medical/generals/cancellationReason/",
        //     token: token,
        //     method: "PUT",
        //     body: data,
        //     doAfterSuccess: () => {
        //         setTrigger(trigger + 1);
        //         closeAssigmentModal();
        //     },
        //     succesAction: () =>
        //         message("success", `<span class=${tableStyles.ordClearBlueText}>Cambios guardados</span>`, `${data.canDescription}`),
        // });

        const filter = dataExample.map(e => {
            if (e.idAssigment === data.idAssigment) {
                return { ...data }
            } else {
                return e
            }
        })
        setDataExample(filter)
        message("success", `<span class=${tableStyles.ordClearBlueText}>Asignacion Editada</span>`)
        closeAssigmentModal();
    };

    const createAssigment = () => {
        // createNewMotive({
        //     url: "/medical/generals/cancellationReason/",
        //     token: token,
        //     method: "POST",
        //     body: data,
        //     doAfterSuccess: () => {
        //         setTrigger(trigger + 1);
        //         closeAssigmentModal();
        //     },
        //     succesAction: () => message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo creado</span>`, `${data.canDescription}`),
        // });
        setDataExample(e => ([...e, data]))
        message("success", `<span class=${tableStyles.ordClearBlueText}>Asignacion creada</span>`)
        closeAssigmentModal();

    };

    const disabledAssigment = (item) => {
        // swalConfirm({
        //     title: `¿Está seguro?`,
        //     text: `Se inhabilitara el motivo, "paciente no atendido"`,
        //     confirmButtonText: `Si, continuar`,
        //     doAfterConfirm: () => {
        //         const filterReasonDisable = assignmentResults.results.map(state => state?.canId === item.canId ? { ...state, canEnabled: false } : state)
        //         setAssignmentResults

        //             ({ results: filterReasonDisable })
        //         message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo inhabilitado</span>`)

        //         // updateMotive({
        //         //     url: "/medical/generals/cancellationReason/",
        //         //     token: token,
        //         //     method: "PUT",
        //         //     body: {
        //         //         canEnabled: 0,
        //         //         canId: item.canId,
        //         //     },
        //         //     doAfterSuccess: () => {
        //         //         setTrigger(trigger + 1);
        //         //         closeMotiveModal();
        //         //     },
        //         //     succesAction: () =>
        //         //         message("success", `<span class=${tableStyles.ordClearBlueText}>Motivo inhabilitado</span>`),
        //         // });
        //     },
        // });

        const filter = dataExample.map(e => {
            if (e.idAssigment === item.idAssigment) {
                return { ...item, enable: false }
            } else {
                return e
            }
        })
        setDataExample(filter)
        message("success", `<span class=${tableStyles.ordClearBlueText}>Asignacion inhabilitada</span>`)
        closeAssigmentModal();
    };

    // -------------------------------- Validations -----------------------
    const validationRequest = (item) => {

        if (!data.position) {
            return message("warning", `<span class=${tableStyles.ordClearBlueText}>El Cargo es obligatorio</span>`)
        }

        if (!data.personal.name) {
            return message("warning", `<span class=${tableStyles.ordClearBlueText}>El Usuario es obligatorio</span>`)
        }

        showAssigmentModal.isEditing ? editAssigment(item) : createAssigment();

    };


    // ----------------------------- Headers table --------------------------------

    const headers = [
        {
            title: "Tipo",
            className: "text-start px-2",
        },
        {
            title: "No. Orden",
            className: "text-start px-2",
        },
        {
            title: "Fecha",
            className: "text-start px-2",
        },
        {
            title: "Paciente",
            className: "text-start px-2",
        },

        {
            title: "Empresa",
            className: "text-start px-2",
        },
        {
            title: "Contrato",
            className: "text-start px-2",
        },
        {
            title: "Total",
            className: "text-start px-2",
        },
        {
            title: "Vuelto",
            className: "text-start px-2",
        },
        {
            title: "Estado",
            className: "text-center"
        },
    ];

    //-------------- useEffect 🍕   --------------------------

    //  useEffect(() => {
    //     setFilters({ ...filters, page: 1 })
    //     getListApp({
    //       url: "/medical/generals/appointments/",
    //       objFilters: filters,
    //       token: token,
    //       doAfterSuccess: (result) => { formatData(result.results) }
    //     });
    //   }, [filters.doctor, filters.company, filters.status, trigger]);

    //   useEffect(() => {
    //     getListApp({
    //       url: "/medical/generals/appointments/",
    //       objFilters: filters,
    //       token: token,
    //       doAfterSuccess: (result) => { formatData(result.results) }
    //     });
    //   }, [filters.page]);


    const [dataExample, setDataExample] = useState([
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: new Date().toDateString(),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: new Date().toDateString(),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: new Date().toDateString(),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: new Date().toDateString(),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: new Date().toDateString(),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: new Date().toDateString(),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: new Date().toDateString(),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
        {
            tipo: 'icono',
            nOrden: '2365',
            fecha: new Date().toDateString(),
            paciente: 'jhan carlos ortegon',
            identificacion: '1045745554',
            empresa: 'sura',
            contrato: 'convenio especial',
            total: 10000,
            vuelto: 'icono',
            estado: 'entregado',
            enable: true,

        },
    ])

    useEffect(() => {
        formatData(dataExample)
    }, [showAssigmentModal, dataExample])

    //-------------- format data to table 🍕   --------------------------
    const formatData = (array) => {
        let tempList = [];
        if (Array.isArray(array)) {
            array?.forEach((e, index) => {

                tempList.push([
                    {
                        text: <>{e?.tipo}</>,
                        className: "text-start px-2"
                    },
                    {
                        text: <>{e?.nOrden}</>,
                        className: "text-start px-2"
                    },
                    {
                        text: <>{e?.fecha}</>,
                        className: "text-start px-2"
                    },
                    {
                        text: <><div>{e?.paciente}</div> <div>{e?.identificacion}</div></>,
                        className: "text-start px-2"
                    },
                    {
                        text: <>{e?.empresa}</>,
                        className: "text-start px-2"
                    },
                    {
                        text: <>{e?.contrato}</>,
                        className: "text-start px-2"
                    },
                    {
                        text: <>{e?.total}</>,
                        className: "text-start px-2"
                    },
                    {
                        text: <>{e?.vuelto}</>,
                        className: "text-start px-2"
                    },
                    {
                        text:
                            <div style={{ marginLeft: '25px' }} >
                                {e?.estado}
                                <CustomPopupExtend
                                    triggerSrc={threeDots}
                                    center
                                    extraButtons={
                                        [
                                            {
                                                text: "Editar",
                                                class: CustomPopupStyles.popUpGeneric,
                                                event: () => {
                                                    openEditAssigment(e);
                                                    setShowAssigmentModal(e => ({ ...e, isEditing: true, show: true }))
                                                },
                                            },
                                            {
                                                text: "Inhabilitar",
                                                class: CustomPopupStyles.popUpGeneric,
                                                event: () => {
                                                    disabledAssigment(e)
                                                },
                                            },
                                        ]
                                    }
                                ></CustomPopupExtend>
                            </div>,
                        className: "text-center"
                    },

                ]);
            });
        }
        setDataTable(tempList);
        // return tempList;
    };


    const optionsPosition = [
        { value: 'coordinador', label: 'coordinador' },
        { value: 'auxiliar', label: 'auxiliar' },
        { value: 'ingeniero', label: 'ingeniero' },
        { value: 'tecnico', label: 'tecnico' },
    ]

    const optionsPersonal = [
        { value: 'jhan', label: 'jhan' },
        { value: 'walter', label: 'walter' },
        { value: 'emanuel', label: 'emanuel' },
        { value: 'laura', label: 'laura' },
        { value: 'valentina', label: 'valentina' },
        { value: 'leidy', label: 'leidy' }
    ]

    const optionsLocations = [
        { value: 'sur', label: 'sur' },
        { value: 'norte', label: 'norte' },
        { value: 'foca', label: 'foca' },
        { value: 'optica', label: 'optica' },
    ]

    const handleFilterPatient = (e) => {
        setFilters({ ...filters, page: 1 })
        setTrigger(trigger + 1)
        e.preventDefault()
    }


    return (
        <>
            {/* {(AnulationsLoader || updateMotiveLoader || createMotiveLoader) && loader} */}

            <OrdModal
                title={showAssigmentModal.isEditing ? "Editar asignación" : "Nueva asignación"}
                show={showAssigmentModal.show}
                btnYesName={showAssigmentModal.isEditing ? "Guardar" : "Crear"}
                btnNoName={"Cancelar"}
                size={550}
                onHide={() => closeAssigmentModal()}
                btnYesEvent={() => validationRequest()}
                btnNoEvent={() => closeAssigmentModal()}
                sizeBody={`${tableStyles.modalHeight}`}
            >
                <div

                >
                    <Row>
                        <Form.Group className="mb-3" controlId="fName">

                            <div className="mb-2">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">Cargo</b>
                                </Form.Label>

                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={optionsPosition}
                                    defaultValue={data?.position ? { value: data?.position, label: data?.position } : []}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    onChange={(e) => {
                                        setData(state => ({ ...state, position: e.value }))
                                    }}
                                />
                            </div>

                            <div className="mb-2">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">Usuario</b>
                                </Form.Label>

                                <Select noOptionsMessage={() => 'No hay datos'}
                                    options={optionsPersonal}
                                    defaultValue={data?.personal?.name ? { value: data?.personal?.name, label: data?.personal?.name } : []}
                                    className="text-secondary"
                                    placeholder={"Seleccionar..."}
                                    styles={ordCustomSelect}
                                    onChange={(e) => {
                                        setData(state => ({
                                            ...state,
                                            personal: {
                                                ...state.personal,
                                                name: e.value,
                                            }
                                        }))
                                    }}
                                />
                            </div>


                            <div className="mb-2">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">Sede</b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    closeMenuOnSelect={false}
                                    defaultValue={data?.personal?.locations?.length
                                        ? data?.personal?.locations.map(e => ({ label: e, value: e }))
                                        : []
                                    }
                                    isMulti
                                    options={optionsLocations}
                                    onChange={(e) => {
                                        setData(state => ({
                                            ...state,
                                            personal: {
                                                name: state.personal.name,
                                                locations: e?.map((loc) => loc.value) || []
                                            }
                                        }))
                                    }}
                                />

                            </div>


                        </Form.Group>
                    </Row>

                </div>
            </OrdModal>

            <OrdGenericTemplate
                className=" mt-4 mx-2"
                // showBottomBorder={false}
                title={"Órdenes de proveedores"}
                titleSize={12}
            // buttonNewLabel={"Nueva asignación"}
            // buttonNewAction={() => {
            //     // openNewCombo();
            // }}

            // searchWithNew={{
            //     colSize: "12",
            //     WSize: "80",
            //     buttonNewLabel: "Nueva asignación",
            //     onChange: (e) => {
            //         setFilters({ ...filters, canDescription: e.target.value });
            //     },
            //     buttonNewAction: () => openNewAssigment(),
            //     onSubmit: (e) => {
            //         e.preventDefault();
            //         setTrigger(trigger + 1);
            //     },
            // }}
            >


                <div className="asistencialDateColorIcon"  style={{ width: '95%' }} onClick={() => {
                    newOrdenActive && setNewOrdenActive(false)
                }}>

                    <Row style={{ margin: '0px', color: '#00B4CC', marginTop: '15px', marginBottom: '15px', textAlign: 'center', }}>
                        <Col
                            onClick={() => setTabActive('OrdersPage')}
                            xs={2} style={tabActive === 'OrdersPage' ?
                                { border: '1px solid #00B4CC', padding: '5px 0px', borderRadius: '10px 10px 0px 0px', borderBottom: '0px' }
                                : { cursor: 'pointer', background: 'rgb(0 180 204 / 9%)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px 10px 0px 0px', borderBottom: '1px solid #00B4CC' }
                            }

                        >
                            <span>Órdenes</span>
                        </Col>
                        <Col
                            onClick={() => setTabActive('SubscriptionsPage')}
                            xs={2} style={tabActive === 'SubscriptionsPage' ?
                                { border: '1px solid #00B4CC', padding: '5px 0px', borderRadius: '10px 10px 0px 0px', borderBottom: '0px' }
                                : { cursor: 'pointer', background: 'rgb(0 180 204 / 9%)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px 10px 0px 0px', borderBottom: '1px solid #00B4CC' }
                            }

                        >
                            <span>Abonos</span>
                        </Col>
                        <Col xs={8} style={{ borderBottom: '1px solid #00B4CC' }}></Col>
                    </Row>

                    <Row>
                        <Col xs={4} >
                            {/* <Form.Group className="mb-3" controlId="fName">
                                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} `}>
                                        <b className="text-start"> &nbsp;Tipo</b>
                                    </Form.Label>
                                    <Select noOptionsMessage={() => 'No hay datos'}
                                        options={formattedServicesType}
                                        className="text-secondary"
                                        placeholder={"Seleccionar..."}
                                        styles={ordCustomSelect}
                                        onChange={(e) => setFilters({ ...filters, typeId: e.value })}
                                    ></Select>
                                </Form.Group> */}

                            <Row>
                                <Col xs={6}>
                                    <Form.Group className="" controlId="fName">
                                        <Form.Label
                                            className={`m-0 ${tableStyles.ordDarkBlueText} `}
                                        >
                                            <b className="text-start">&nbsp;Fecha desde</b>
                                        </Form.Label>
                                        <input
                                            className={`ord-roundInput2`}
                                            style={filters.startDate ? {color: '#1a538d'} : {}}
                                            type="date"
                                            placeholder=""
                                            value={filters.startDate}
                                            onChange={(e) => {
                                                setFilters({ ...filters, startDate: e.target.value });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="" controlId="fName">
                                        <Form.Label
                                            className={`m-0 ${tableStyles.ordDarkBlueText} `}
                                        >
                                            <b className="text-start">&nbsp;Hasta</b>
                                        </Form.Label>
                                        <input
                                            className={`ord-roundInput2`}
                                            style={filters.endDate ? {color: '#1a538d'} : {}}
                                            type="date"
                                            value={filters.endDate}
                                            onChange={(e) => {
                                                setFilters({ ...filters, endDate: e.target.value });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col>
                                </Col>
                            </Row>

                        </Col>

                        <Col xs={5}>
                            <form onSubmit={(e) => handleFilterPatient(e)}>
                                <Form.Group className="mb-3 text-start" controlId="search"
                                >
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                    </Form.Label>
                                    <div
                                        className={`d-flex ${tableStyles.ordGrayInputSearch}`}
                                    >
                                        <Form.Control
                                            className={`ord-roundInput-search`}
                                            type="text"
                                            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                            placeholder="Escribe aquí para buscar" />
                                        <img
                                            alt="buscar"
                                            src={ordBlueSearch}
                                        >
                                        </img>
                                    </div>
                                </Form.Group>
                            </form>
                        </Col>

                        <Col xs={3} className={`${tableStyles.colorNewOrder} ${tableStyles.colorNewOrderHover}`}

                        >
                            <div onClick={() => setNewOrdenActive(true)}

                                style={{ marginTop: '20px' }} className={`${tableStyles.colorNewOrderHover}`}>
                                <span style={{ fontWeight: 'bold', marginRight: '10px' }} >Nueva orden</span>
                                <img src={ADD} style={{ marginRight: '5px' }} alt="" />
                            </div>
                            {newOrdenActive &&
                                <div className={`${tableStyles.popap}`}>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        className={`${tableStyles.poapItem}`}
                                        onClick={() => history.push("/admision/ordenLenteContacto")}
                                    >
                                        <img src={ADD} alt="icono" />
                                        <span>Lente de contacto</span>
                                    </div>
                                </div>
                            }
                        </Col>


                    </Row>


                    {
                        tabActive === 'OrdersPage' &&
                        <OrdersPage />
                    }

                    {
                        tabActive === 'SubscriptionsPage' &&
                        <SubscriptionsPage />
                    }

                    {/* <OrdTable
                        // accordion
                        data={dataExample}
                        isEnable={filters.assigmentEnabled}
                        headers={headers}
                        body={dataTable}
                        className={`${tableStyles.ordTableShadow}`}
                        paginate={{
                            activePage: filters.page,
                            totalPages: 3,
                            perPage: filters.perpage,
                            pageRangeDisplayed: 3,
                            onChangePage: async (e) => {
                                setFilters({ ...filters, page: e })
                            },
                            showTextDetails: true
                        }}
                    ></OrdTable> */}

                </div>
            </OrdGenericTemplate>
        </>
    )
}

export default SupplierOrders