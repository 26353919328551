import React, { Component, useEffect, useState } from "react";
import master from "../../assets/img/icons/masterCard.svg";
import america from "../../assets/img/icons/american-express.svg";
import { validateEmail } from "../../helpers/helpers";
import { responseSwal } from "../../helpers/responseSwal";
import visa from "../../assets/img/icons/visa.svg";
import discover from "../../assets/img/icons/discover.svg";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Col, Row } from "react-bootstrap";
import CardStyle from "./movementsStyles.module.scss";
import ModalNew from "../../components/Layouts/ModalNew";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { createCreditCard, getCardCredit } from "../../actions/accountingMovementsActions";
import { useDispatch, useSelector } from "react-redux";
import { getBankingEntities } from "../../actions/providersActions";
import NumberFormat from "react-number-format";
import CreatePaymentsTC from "./createPaymentsTC";
import { useHistory } from "react-router";
import Loader from "react-loader-spinner";
import { Title } from "../../shared";

function PaymentsTC(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const counter = useSelector(state => state);
    const [showModal, setShowModal] = useState(false);
    const [createCard, setCreateCard] = useState({});
    const [typeCard, setTypeCard] = useState();
    const [trigger, setTrigger] = useState(1);
    const [details, setDetails] = useState({ show: false, });
    const myPermission = () => counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "payTC");
    useEffect(() => {
        if (!!!myPermission()?.read) {
            history.push("/contabilidad/inicio");
        }
    })
    useEffect(() => {
        dispatch(getCardCredit({ eaccount: counter.loginReducer.currentAccount.id }));
        dispatch(getBankingEntities());
    }, [trigger]);
    useEffect(() => {
        dispatch(getCardCredit({ eaccount: counter.loginReducer.currentAccount.id }));
    }, [details]);

    let optionBank = [{ label: "Seleccionar...", value: "" }]
    if (Array.isArray(counter.providerReducer.bankingEntities)) {
        counter.providerReducer.bankingEntities.map((item) => {
            optionBank.push({
                value: item.id,
                label: item.description,
            }
            );
        })
    }

    const renderList = () => {
        let tempList = [];
        if (Array.isArray(counter.accountingMovementsReducer.getCardCredit)) {
            counter.accountingMovementsReducer.getCardCredit.map((item, index) => {
                tempList.push(
                    <Col className=""
                        onClick={() => setDetails({
                            ...details,
                            show: true,
                            details: true,
                            edit: false,
                            info: item.id
                        })}>
                        <div className={CardStyle.card} style={{
                            backgroundColor: item.franchise === "Visa" ? "white" :
                                item.franchise === "Discover" ? "#01A0F6" :
                                    item.franchise === "Master" ? "#005DBF" :
                                        item.franchise === "Amex" ? "white" : ""
                        }}
                        >
                            <p className={item.franchise === "Discover" || item.franchise === "Master" ? CardStyle.nameBank : CardStyle.nameBankGray}>{item.bankName}</p>
                            <p className={item.franchise === "Discover" || item.franchise === "Master" ? CardStyle.codeBank : CardStyle.codeBankGray}>{item.creditCardNbr}</p>
                            <p className={item.franchise === "Discover" || item.franchise === "Master" ? CardStyle.nameUserCard : CardStyle.nameUserCardGray}>{item.cardName}</p>
                            <p className={CardStyle.logoCard}>
                                {item.franchise === "Visa" ? <img src={visa} style={{ width: "60px" }} /> :
                                    item.franchise === "Discover" ? <img src={discover} style={{ width: "60px" }} /> :
                                        item.franchise === "Master" ? <img src={master} style={{ width: "60px" }} /> :
                                            item.franchise === "Amex" ? <img src={america} style={{ width: "60px" }} /> : ""
                                }

                            </p>
                        </div>
                    </Col >
                );
            })
        }
        return tempList;
    }
    function limit(val, max) {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    function cardExpiry(val) {
        let month = limit(val.substring(0, 2), '12');
        let year = val.substring(2, 4);

        return month + (year.length ? '/' + year : '');
    }

    function cc_brand_id(cur_val) {
        setCreateCard({
            ...createCard,
            cardNumber: cur_val,
            franchise: typeCard,
            eaccount: counter.loginReducer.currentAccount.id,
        })
        // cur_val = cur_val.toString()
        // the regular expressions check for possible matches as you type, hence the OR operators based on the number of chars
        // regexp string length {0} provided for soonest detection of beginning of the card numbers this way it could be used for BIN CODE detection also

        //JCB
        let jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$'); //2131, 1800, 35 (3528-3589)
        // American Express
        let amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
        // Diners Club
        let diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); //300-305, 309, 36, 38-39
        // Visa
        let visa_regex = new RegExp('^4[0-9]{0,}$'); //4
        // MasterCard
        let mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); //2221-2720, 51-55
        let maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$'); //always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
        //Discover
        let discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
        ////6011, 622126-622925, 644-649, 65


        // get rid of anything but numbers
        cur_val = cur_val.replace(/\D/g, '');

        // checks per each, as their could be multiple hits
        //fix: ordering matter in detection, otherwise can give false results in rare cases
        var sel_brand = "unknown";
        if (cur_val.match(jcb_regex)) {
            sel_brand = "jcb";
        } else if (cur_val.match(amex_regex)) {
            sel_brand = "Amex";
        } else if (cur_val.match(diners_regex)) {
            sel_brand = "diners_club";
        } else if (cur_val.match(visa_regex)) {
            sel_brand = "Visa";
        } else if (cur_val.match(mastercard_regex)) {
            sel_brand = "Master";
        } else if (cur_val.match(discover_regex)) {
            sel_brand = "Discover";
        } else if (cur_val.match(maestro_regex)) {
            if (cur_val[0] == '5') { //started 5 must be mastercard
                sel_brand = "Master";
            } else {
                sel_brand = "maestro"; //maestro is all 60-69 which is not something else, thats why this condition in the end
            }
        }
        setTypeCard(sel_brand)
        return sel_brand;

    }
    const renderListCard =

        <>
            {(counter.accountingMovementsReducer.card_loading) &&
                <div className="loading">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            }
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                <div className={tableStyles.title}>
                    <Title
                        title="Registro de pagos con TC"
                        onClickIcon={() => {
                            history.push('/contabilidad/inicio')
                        }}
                    />
                </div>
                <h4 style={{ color: "#58595B" }}>Selecciona la tarjeta de crédito usada</h4>

                <Row className="p-5 d-flex">
                    {renderList()}
                    <Col xs={6} style={{ marginTop: "9%" }}>
                        {!!myPermission()?.create ?
                            <div style={{ marginLeft: "35%" }} className="cursorPointer">
                                <div className={CardStyle.btnAddCard} onClick={() => setShowModal(true)}>
                                    <span >+</span>
                                </div>
                            </div> : ""}
                    </Col>
                </Row>
            </div>

            <ModalNew
                show={showModal}
                onHide={() => setShowModal(false)}
                title={"Tarjeta de Crédito"}
                btnYesDisabled={
                    !!createCard.bankId === false ||
                        !!createCard.cardName === false ||
                        !!createCard.email === false ||
                        !!createCard.validateEmail === false ||
                        !!createCard.cardNumber === false ? true : false}
                btnYesEvent={() => {
                    setTrigger(trigger + 1)
                    dispatch(createCreditCard(createCard, () => setTypeCard({}), setTrigger(trigger + 1), setCreateCard({}), setShowModal(false)))
                    dispatch(getCardCredit({ eaccount: counter.loginReducer.currentAccount.id }));
                }}
                btnNoEvent={() => (setTypeCard({}), setCreateCard({}), setShowModal(false))}
                btnYesName={"Aceptar"}
                size={"500"}

            >
                <Row className="d-flex">
                    <Col className="text-center" >
                        <div className={typeCard === "Visa" ? CardStyle.cardTypeSelec : ""}>
                            <img src={visa} style={{ width: "50px" }} />
                        </div>
                    </Col>
                    <Col className="text-center" >
                        <div className={typeCard === "Discover" ? CardStyle.cardTypeSelec : ""}>
                            <img src={discover} style={{ width: "70px" }} />
                        </div>
                    </Col>
                    <Col className="text-center">
                        <div className={typeCard === "Master" ? CardStyle.cardTypeSelec : ""}>
                            <img src={master} style={{ width: "40px" }} />
                        </div>
                    </Col>
                    <Col className="text-center">
                        <div className={typeCard === "Amex" ? CardStyle.cardTypeSelec : ""}>
                            <img src={america} style={{ width: "40px" }} />
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex">
                    <Col>
                        <label className={tableStyles.crudModalLabel}>Banco
                            <span className={"text-danger"}>*</span>
                        </label>
                        <Select
                            // key={"Consignante" + triggerSelect}
                            noOptionsMessage={() => 'No hay datos'}
                            placeholder="Seleccionar..."
                            styles={customSelectNewDark}
                            options={optionBank}
                            onChange={(e) => setCreateCard({
                                ...createCard,
                                bankId: e.value,

                            })}
                        />
                    </Col>
                </Row>
                <Row className="d-flex">
                    <Col >
                        <label className={tableStyles.crudModalLabel}>Número<span className={"text-danger"}>*</span>  </label>
                        <NumberFormat
                            // allowNegative={false}
                            id="cost_price"
                            format="#### **** **** ####"
                            // style={{ backgroundColor: "transparent", border: "none" }}
                            className={`${IndividualStyles.registerInputsBlue} `}
                            // prefix="$"
                            // thousandsGroupStyle="thousand"
                            // thousandSeparator
                            onValueChange={(e) => cc_brand_id(e.formattedValue)}
                        />
                    </Col>
                </Row>
                <Row className="d-flex">
                    <Col >
                        <label className={tableStyles.crudModalLabel}>Nombre en la tarjeta<span className={"text-danger"}>*</span>  </label>
                        <input
                            className={`${IndividualStyles.registerInputs} text-secondary`}
                            type="text"
                            onChange={(e) => setCreateCard({
                                ...createCard,
                                cardName: e.target.value,

                            })}
                        ></input>
                    </Col>
                </Row>
                <Row className="d-flex">
                    <Col>
                        <label className={tableStyles.crudModalLabel}>Correo de notifiaciones<span className={"text-danger"}>*</span>  </label>
                        <input className={`${IndividualStyles.registerInputs} text-secondary`} type="email"
                            onChange={(e) => setCreateCard({
                                ...createCard,
                                email: e.target.value,

                            })}
                            onBlur={() => {
                                if (!validateEmail(createCard.email)
                                ) {
                                    setCreateCard({
                                        ...createCard,
                                        validateEmail: false,

                                    })
                                    return responseSwal({
                                        icon: "error",
                                        title: "Error",
                                        text: "Este correo no es válido",
                                    });
                                } else {
                                    setCreateCard({
                                        ...createCard,
                                        validateEmail: true,

                                    })
                                }
                            }}
                        ></input>
                    </Col>
                </Row>
            </ModalNew>

        </>;
    return (!!details.show) ?
        <CreatePaymentsTC
            setDetails={setDetails}
            info={details.info}
            edit={details.edit}
            details={details.details}
            show={details.show}
        /> : renderListCard


};
export default PaymentsTC;