import GenericTableNew from "../Layouts/GenericTableNew";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import paginationStyles from "../Layouts/pagination.module.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import registerWarehouseStyles from "./registerWarehouse.module.scss";
import buscar from "../../assets/img/icons/buscar.svg";
import adjuntar from "../../assets/img/icons/AdjuntarOscuro.svg";
import ModalNew from "../Layouts/ModalNew";
import lupaClear from "../../assets/img/icons/lupaClear.svg";
import Flecha from "../../assets/img/icons/rigthArrow.svg";
import Agregar from "../../assets/img/icons/ADD.svg";
import canecaGris from "../../assets/img/icons/trash.svg";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { customSelectNew, customSelectNewDark} from "../Layouts/react-select-custom";
import IndividualStyles from "../TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Add from "../../assets/img/icons/crearCuenta.svg";
// import ModalArticle from "./RegisterEntryArticles";
import genericTableStyles from "../Layouts/genericTable.module.scss";
// import Trash from "../../assets/img/icons/canecaGris.svg";
// import Watch from "../../assets/img/icons/watch.svg";
import Dropzone from 'react-dropzone';
import Excel from "../../assets/img/icons/excel.svg";
import AlertTriangle from "../../assets/img/icons/AlertTriangle.svg";
import Word from "../../assets/img/icons/word.svg";
import PDF from "../../assets/img/icons/pdf.svg";
import Attach from "../../assets/img/icons/insertar.svg";
import {
    get_purchaseOrders_inWarehouse,
    creat_entryWarehouse,
    providersWithPO,getWEProblems, saveArticleInWarehouse
} from "../../actions/warehouseActions";
import {
    indauction_articles_filter,
} from "../../actions/IndividualAuctionActions";
import {
    warehouse_supplier_active,
} from "../../actions/storageActions";
import DetailPurchaseOrder from "./DetailPurchaseOrder";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomNumUpDown from "../Layouts/CustomNumUpDown/CustomNumUpDown";
// import { set } from "lodash";
import deepcopy from "deepcopy";
import cloneDeep from 'lodash/cloneDeep';
import { MAX_SIZE_FILE} from "../../helpers/constants";
import Loader from "react-loader-spinner";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
const MULTIPLE_REGEX_VALID_FILE = /^.+\.(([pP][dD][fF]))|(xlsx|xls|csv)|(doc|docx)$/;
const type_file= "csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, \
                application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, \
                application/pdf"


function RegisterEntryByWarehouse(props) {
    const MySwal = withReactContent(Swal);
    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(1);
    const [trigger2, setTrigger2] = useState(1);
    const [modalAddArticle, setModalAddArticle] = useState(false);
    const [addArticle, setAddArticle] = useState({});
    const [filters, setFilters] = useState({
        page: 1,
        perpage:10,
        eaccount:counter.loginReducer.currentAccount.id,
        created_at:"",
        code:"",
        provider:"",
        havePendings : true
    });
    const [articlesFixed, setarticlesFixed]= useState(props.articlesFixed);    

    const [details, setDetails] = useState({
        show: false,
    });

    const [modalArtControl, setmodalArtControl]= useState({
        show:false,
    });

 

    useEffect(() => {
        dispatch( get_purchaseOrders_inWarehouse(filters));
        dispatch( indauction_articles_filter({eaccount:counter.loginReducer.currentAccount.id}));
        dispatch(warehouse_supplier_active(counter.loginReducer.currentAccount.id));
        dispatch( providersWithPO());
        dispatch(getWEProblems());
    },[trigger,filters.page]);

    var optionProviders =[{value: "", label:"Seleccionar..."}]       
        if (Array.isArray(counter.entryWarehouseReducer.providers.results)) {
            counter.entryWarehouseReducer.providers.results.map((item) => {     
                optionProviders.push({
                    value: item.name,
                    label: item.name,
                    key:item.id+"wh",
                }
                )                
            });
        }
    var optionStorage =[{label: "Seleccionar...", value: ""}]       
        if (Array.isArray(counter.storageReducer.list_new)) {
            counter.storageReducer.list_new.map((item) => {     
                optionStorage.push({
                    value: item.id,
                    label: item.description,
                    key:item.id+"storage",
                }
                )                
            });
        }

    const [modalPurchaseOrderControl, setmodalPurchaseOrderControl]= useState({
        show:false,
    });

    const [modalDocsControl, setmodalDocsControl]= useState({
        show:false,
    });

    const [modalLotsControl, setmodalLotsControl]= useState({
        show:false,
    });

    //======================================================================= for individualModalArticle
    const optionInventoryFamily = () => {
        let tempModules = [{label: "Seleccionar...", value: ""}];
        if (Array.isArray(counter.individualAuctionReducer.familiainventario)) {
            counter.individualAuctionReducer.familiainventario.map((elem) => {
                let label = elem.description
                let id_account= elem.id_account
                tempModules.push({
                    value: elem.id,
                    label: label,
                    id_account: id_account,
                    articles: elem.articles,
                })
            });

          }
        return tempModules;
    }

   
    //======================================================================= for individualModalArticle

    useEffect(()=>{

        if (checkHandle.checked){
            if(!!modalLotsControl?.programming?.id){
                let target= articlesFixed.findIndex(x=>x.id === modalLotsControl.programming.id);
                if(!!articlesFixed[target].lots){
                    setlots(articlesFixed[target].lots);
                }else{
                    setlots([]);
                }
            }
        }
        if(!!modalLotsControl?.programming?.id_article){
            let target= articlesFixed.findIndex(x=>x.id_article === modalLotsControl.programming.id_article);
            if(!!articlesFixed[target].lots){
                setlots(articlesFixed[target].lots);
            }else{
                setlots([]);
            }
        }
    }, [modalLotsControl.show, modalLotsControl.programming?.id_article, articlesFixed]);

    const ButtonPurchaseOrder = () => {
        setmodalPurchaseOrderControl({
            ...modalPurchaseOrderControl,
            show: true
        })
    };

    const ButtonDocs = () => {
        setmodalDocsControl({
            ...modalDocsControl,
            show: true
        })
    };

    const renderHeadersPO = [
        <th key={`PO1`} className="px-2 text-start">No.OC</th>,
        <th key={`PO2`} className="px-2 text-center">Fecha</th>,
        <th key={`PO3`} className="px-2 text-start">Proveedor</th>,
        <th key={`PO4`} className="px-2 text-start"></th>,
    ]
    
    const renderPurchaseOrders= () => {
        let table =[];
        if (Array.isArray(counter.entryWarehouseReducer.purchaseOrders)) {
            table = counter.entryWarehouseReducer.purchaseOrders.map( (x) => {
                return (
                    <tr key={"purchaseOrder"+x.id_PurchaseOrder} className="hover-table-row">
                        <td className="px-2 text-start">
                            {x.purchaseOrder_number?.toUpperCase()}
                        </td>
                        <td >
                            {x.created_at?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}
                        </td>
                        <td className="text-start px-2">
                            {x?.name_provider}
                        </td>
                        <td>
                            <img
                                src={Flecha}
                                alt="Buscar"
                                onClick={()=> setDetails({show: true, selectedPurchaseOrder: x})}
                            />
                        </td>
                    </tr>
                )
            });

        }
        return table;
    };

    const lastHead = articlesFixed?.some(x => x.inWarehouse === 0) && (
        <th key={`RW5`}></th>
    )
    const renderHeaders = [
        <th key={`RW0`}>&nbsp;</th>,
        <th key={`RW1`}>Código</th>,
        <th key={`RW2`}>Descripción de Artículo</th>,
        <th key={`RW3`}>Lote</th>,
        <th key={`RW4`}>Cantidad Ordenada</th>,
        <th key={`RW5`}>Cantidad recibida</th>,
        lastHead   
    ]

    const handleAddArticle = (article, realindex) => {
        setModalAddArticle(true)
        setAddArticle({...article, index:realindex, available:0})
    }

    const updatedArticle = (article) => {
        let tempArray = articlesFixed
        tempArray[article.index].inWarehouse = 1
        setarticlesFixed(tempArray)
        setModalAddArticle(false);
    }

    // ANCHOR render articles on table
    const renderArticlesEntrys = () => {
        let table = [];
        if (Array.isArray(articlesFixed)) {
            table = articlesFixed.map((x, realindex) => {
                return (
                  <tr
                    key={"renderEntrysByWarehouse" + realindex}
                    className={`hover-table-row ${ x.inWarehouse === 0 ? genericTableStyles.orange : null }`}
                  >
                    <td>
                        {checkHandle.checked?
                        <input
                            className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
                            type="checkbox"
                            name={`Art${x.article_id}`}
                            onChange={()=>articleCheckDeleteHandle(x)}
                            checked={!!articleChangesDelete.find(change=> change.id === x.id)}
                        />
                        :
                        <span></span>
                        }
                    </td>
                    <td>
                      {!!x.id_article? x.id_article :x.id}
                    </td>
                    <td className="text-start">
                      {x.description}
                    </td>
                    <td >
                        {/* {!articlesFixed[realindex].lots? */}
                            <img 
                                src={Agregar} 
                                alt="btnAgregar" 
                                onClick={()=>setmodalLotsControl({show:true, programming: x})}
                                style={{cursor:"pointer"}}
                            />
                        {/* :
                            <label 
                                onClick={()=>setmodalLotsControl({show:true, programming: x})}
                                style={{cursor:"pointer"}}
                            > 
                                {articlesFixed[realindex].lots?.length}
                            </label> 
                        */}
                    </td>
                    <td>
                      {x.qty_article? x.qty_article:"-"}
                    </td>
                    <td>
                        {x?.qty_received? x.qty_received: "-"}
                    </td>
                    <td>
                        {x.inWarehouse === 0 
                            ? 
                            <img src={AlertTriangle} width={16} onClick={()=>handleAddArticle(x, realindex)}/>
                            : null }
                    </td>
                  </tr>
                );
            });
        }else{

        }
        return table;
    };
    const [lotsInfo, setlotsInfo] = useState({
        lot: "",
        date_expiry: "",
        qty:1,
        id_article:"",
    });
    const [lots, setlots] = useState([])
    // const [saveLots, setsaveLots]=useState()
  
    const Addlots = (id, idPO) => {
        if(lotsInfo.lot==="" || lotsInfo.date_expiry==="" ){
            customSwaltAlert({
                icon:"warning",
                title: "Intenta de nuevo",
                text: 'Por favor ingrese una fecha de vencimiento y un número de lote',
                confirmButtonText: `Aceptar`,
                showCancelButton: false
            });
        }else{
            let temlots= lots
            temlots.push({ 
                    date_expiry: lotsInfo.date_expiry,
                    lot:lotsInfo.lot ,
                    qty:lotsInfo.qty ,
                    id_article:id ,
                    id_PO_scheduling: idPO
                });
            setlots(temlots);
            setlotsInfo({lot: "", date_expiry: "", qty:1, id_article:""})
        }
  
    };
  
    const btnSaveLots =(id)=>{
        if (lots.length>0){
        let temparray = deepcopy(articlesFixed);
          let target= temparray.findIndex(x=>x.id_article === id);
          if(!isNaN(target)){
            temparray[target].qty_received = Math.max(1,amount_received);
            temparray[target].lots=lots;
            setarticlesFixed(temparray);
            let state=false
            let iguales=0
            if(Array.isArray(articlesFixed)){
                
                temparray?.forEach(element => {
                   
                    if (element.qty_article === element?.qty_received) {
                        iguales=iguales +1  
                    }
                });
            }
            if (articlesFixed.length === iguales){state=false} 
            else{state= true}
            setregister({...register, req_auth: state})

         }

        }else{
              customSwaltAlert({
                  icon:"warning",
                  title: "Intenta de nuevo",
                  text: "Por favor ingrese los datos necesarios para registrar el lote",
                  confirmButtonText: `Aceptar`,
                  showCancelButton: false
                });
  
        }
        
        setlots([]);
        setlotsInfo({lot: "", date_expiry: "", qty:1, id_article:""})
        setmodalLotsControl({show:false})
    }

    const btnSaveLotsDonation=(id)=>{
        if (lots.length>0){
        //   let temsavelots= saveLots
        //   temsavelots.push(lots)
        //   setsaveLots(temsavelots)
        let temparray = deepcopy(articlesFixed);
          let target= temparray.findIndex(x=>x.id === id);
          if(!isNaN(target)){
            temparray[target].qty_received = Math.max(1,amount_received);
            temparray[target].lots=lots;
            setarticlesFixed(temparray);
            }
          }

          else{
              customSwaltAlert({
                  icon:"warning",
                  title: "Intenta de nuevo",
                  text: "Por favor ingrese los datos necesarios para registrar el lote",
                  confirmButtonText: `Aceptar`,
                  showCancelButton: false
                });
  
        }
        
        setlots([]);
        setlotsInfo({lot: "", date_expiry: "", qty:1, id_article:""})
        setmodalLotsControl({show:false})
    }

    const renderHeadersLots = [
        <th key={`Lots1`} className="px-2 text-start">Vencimiento</th>,
        <th key={`Lots2`}>Lote</th>,
        <th key={`Lots3`}>Cantidad</th>,
    ]

    const editArticleAmmount = (lot, ammount) => {
        let temp = [...lots];
        if(Array.isArray(temp)){
            let target = temp.findIndex(x=>x.lot === lot);
            if(!isNaN(target)){
                temp[target].qty = Math.max(1, ammount);
                setlots(temp)
            }
        }
    }

    
    const [renderTableLots, amount_received] = useMemo(() => {
        let table = [];
        let amount_received=0;
        let num_lots=0;
            table = lots.map((x,index) => {
                amount_received=amount_received+x.qty
                num_lots=num_lots+1
                return (
                  <tr key={"lots" + index}>
                    <td>{x.date_expiry}</td>
                    <td style={{textTransform:"uppercase"}}>{x.lot}</td>
                    <td><CustomNumUpDown id={x.lot} value={x.qty} onChange={editArticleAmmount}></CustomNumUpDown></td>
                  </tr>
                );
            })
        return [table,amount_received];
    },[ editArticleAmmount,lots]);
        

    const cancelLots =()=>{
        setmodalLotsControl({show:false})
        setlots([])
    }

    const clean = () => {
        setregister({
            id_warehouse: null,
            id_provider: null,
            id_po:null,
            status:"pending",
            addInfo:"",
            problem:null,
            id_user:counter.loginReducer.user_data.id,
            storer:counter.loginReducer.user_data.first_name+" "+counter.loginReducer.user_data.first_surname,
            account:counter.loginReducer.currentAccount.id,
            entry_date:"",
            billNumber:null,
            donation:false,
            document:[],
            file:"",
            observations:null,
            req_auth:false,
        }) 
        setarticlesFixed([])
        setmodalPurchaseOrderControl({show:false})
        if(props?.setSelected){
            props.setSelected()
        }
        dispatch( get_purchaseOrders_inWarehouse(filters));
    }
    const handlePageChange = (val) => {
        setFilters({
            ...filters,
            page: val
        });
    }
    const [checkHandle, setcheckHandle] = useState({
        checked:false
    });


    const articleUpdated = (article) => {        

    }

    const [register, setregister] = useState({
        id_warehouse: props.poSelected?.id_warehouse,
        id_provider: checkHandle.checked? null :props.poSelected?.id_provider,
        id_po: checkHandle.checked? null : props.poSelected?.id,
        status:"pending",
        addInfo:"",
        problem:null,
        id_user:counter.loginReducer.user_data.id,
        storer:counter.loginReducer.user_data.first_name+" "+counter.loginReducer.user_data.first_surname,
        account:counter.loginReducer.currentAccount.id,
        entry_date:"",
        donation:false,
        billNumber:null,
        document:[],
        file:"",
        observations:null,
        req_auth:false,
    });

    

    const buttonRegisterEntry = () => {
        dispatch(creat_entryWarehouse({register, programmings:articlesFixed}, () =>clean() ))
        
    }

    

    const checkHandleEntry= ()=>{
        if (checkHandle.checked){
          setcheckHandle({checked:false})
          setregister({
            id_warehouse: "",
            id_provider: "",
            id_po:"",
            status:"pending",
            addInfo:"",
            problem:null,
            id_user:counter.loginReducer.user_data.id,
            storer:counter.loginReducer.user_data.first_name+" "+counter.loginReducer.user_data.first_surname,
            account:counter.loginReducer.currentAccount.id,
            entry_date:"",
            billNumber:null,
            document:[],
            file:"",
            observations:null,
            req_auth:false,
            donation: false,
            })
          setarticlesFixed([])
        }else{
          setcheckHandle({checked:true})
          setregister({
            id_warehouse: "",
            id_provider: "",
            id_po:"",
            status:"pending",
            addInfo:"",
            problem:null,
            id_user:counter.loginReducer.user_data.id,
            storer:counter.loginReducer.user_data.first_name+" "+counter.loginReducer.user_data.first_surname,
            account:counter.loginReducer.currentAccount.id,
            entry_date:"",
            billNumber:null,
            document:[],
            file:"",
            observations:null,
            req_auth:false,
            donation: true,
            })
            setarticlesFixed([])
        }
    }

    const [filters2, setFilter2] = useState({
        search: "",
        page: 1,
        perpage: 10,
        eaccount:counter.loginReducer.currentAccount.id,
    });

    const [selectedCC, setselectedCC] = useState({label: "Seleccionar...", value: ""}); //props.optionInventoryFamily.find(cc => cc.id_account==counter.loginReducer.currentAccount.id)
    const [articleChanges, setArticleChanges] = useState( [] );
    const [articleChangesDelete, setArticleChangesDelete] = useState( [] );
    const [articleNew, setArticleNew] = useState( [] );
    

    const articleCheckHandle = (article) => {
        let articleChangesTemp = articleChanges;
        let exists = articleChangesTemp.find(x => (x.id == article.id));
        if(exists){
            articleChangesTemp = articleChangesTemp.filter(x => x != exists);
        }else{
            articleChangesTemp.push(article);
        }
        setArticleChanges(articleChangesTemp);
    }

    const articleCheckDeleteHandle = (article) => {

        let articleChangesTemp = articleChangesDelete;
        let exists = articleChangesTemp.find(x => (x.id == article.id));
        if(exists){
            articleChangesTemp = articleChangesTemp.filter(x => x != exists);
        }else{
            articleChangesTemp.push(article);
        }
        setArticleChangesDelete(articleChangesTemp);

    }

    const articleCheckAllStatus = ()=>{
        const articles = filteredArticles();

        let allEqual = articleChanges?.length>0;

        articles.map( (elem)=>{
            let exists = articleChanges.find(x => (x.id === elem.id));
            if(!(!!exists)){
                allEqual=false;
            }
        });
        return allEqual;
    };


    const articleNewAdd = (elem)=>{
        let articleNewTemp = articleNew;
        articleNewTemp.push(elem);
        setArticleNew(articleNewTemp);
        setFilter2({
            ...filters2,
            search:"",
            page:1,
            perpage:10,
        });
    } 

    const articleCheckAllHandle = () => {
        let articleChangesTemp = articleChanges;

        if( !articleCheckAllStatus() ){
            const articles = filteredArticles();
            articles.map( (elem)=>{
                let exists = articleChangesTemp.find(x => (x.id === elem.id));
                if(!!exists===false){
                    articleChangesTemp.push(elem);
                }
            });
        }else{
            articleChangesTemp = [];
        }
        
        setArticleChanges(articleChangesTemp);
    }


    const filteredArticles = () => {
        //el metodo del dispatch trae las familias de inventario, vamos a filtrar solo los Artículos dentro de estos
        let onlyArticles = [];
        let articles = selectedCC.articles
        if(Array.isArray(articles)){
            articles.map(z=>{
                if(
                    z?.description?.toUpperCase().includes( filters2?.search?.trim().toUpperCase() ) 
                    || 
                    z?.id?.toString().toUpperCase().includes(filters2?.search?.trim().toUpperCase() ) 
                    ){
                    onlyArticles.push({...z, ammount: 1});
                }
            })
        }


        onlyArticles=onlyArticles.sort( (a,b) =>(a.id - b.id) )

        return onlyArticles;
    }

    const allCheckButton = (
        <input
            className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
            type="checkbox"
            name={`selectedArtAll`}
            onChange={()=>articleCheckAllHandle()}
            checked={ articleCheckAllStatus() }
        />
    )

    const renderHeadersArtciles = [
        <th key={`ReqModalArticle1`} className="px-2 text-start">Código</th>,
        <th key={`ReqModalArticle2`}>Descripción</th>,
        <th key={`ReqModalArticle99`}>{allCheckButton}</th>,
    ]

    const renderList = () => {
        let table =[], butwaittheresmore=[];
        const articles = filteredArticles();
      
        if (Array.isArray(articles)) {
            table = articles.map( x => {
                return <tr key={"ReqModalArticleList"+x.id} className="hover-table-row">
                    <td className={`col-md-2 px-2`}>
                        {x.id}
                    </td>
                    <td className={`col-md-8 text-start`}>
                        {x.description}
                    </td>
                    <td className={`col-md-2`}>
                        <input
                            className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
                            type="checkbox"
                            name={`selectedArt${x.article_id}`}
                            onChange={()=>articleCheckHandle(x)}
                            checked={!!articleChanges.find(change=> change.id == x.id)}
                        />
                    </td>
                </tr>
            });

        }
        
        if (Array.isArray(articleNew)) {
            butwaittheresmore = articleNew.map( x => {
                if(
                    x?.description?.toUpperCase().includes( filters2?.search?.trim().toUpperCase() ) || 
                    x?.id?.toString().toUpperCase().includes( filters2?.search?.trim().toUpperCase() ) 
                    ){
                    return <tr key={"newItems"+x.id+" "+Math.random()} className={`hover-table-row ${genericTableStyles.orange}`}>
                        <td className={`col-md-2`}>
                            {x.id}
                        </td>
                        <td className={`col-md-8`}>
                            {x.description}
                        </td>
                    </tr>
                }
            });

            if(butwaittheresmore.length>0){
                table = table.concat(butwaittheresmore);
            }
        }

        return table;
    }

    const paginateList = () => {
        let fullList = renderList();

        fullList =  fullList.slice(filters2.perpage*(filters2.page-1),  filters2.perpage*(filters2.page));

        if(filteredArticles().length<1 && filters2?.search?.trim()!==""){
                let newRow = <tr key="infinityyyyyyyyy" className="hover-table-row">
                <td className={`col-md-2`}>
                    <div className={`${IndividualStyles.newArticle}`}>NUEVO</div>
                </td>
                <td className={`col-md-8`}>
                    <div className={`${IndividualStyles.newArticle}`}>{filters?.search?.trim()}</div>
                </td>
                <td className={`col-md-2`}>
                    <img 
                        src={Agregar} 
                        alt="Agregar" 
                        className={`${IndividualStyles.adjustIcon}  ${IndividualStyles.orangeIcon}`} 
                        onClick={()=>articleNewAdd({
                            id: articleNew.length>0? "NUEVO "+articleNew.length:"NUEVO",
                            description:filters?.search?.trim().toUpperCase(),
                            newArticle: true,
                            ammount: 1,
                        })}
                    />
                </td>
            </tr>;
            
            fullList.push(newRow);
        }        
        return fullList;
    }

    const deleteArtciles=()=>{
        let articlesDelete= [...articleChangesDelete];
        let arrayRetained=[...articlesFixed]
        arrayRetained = arrayRetained.filter(x=>{
            return articlesDelete.indexOf(x) === -1
        })
        setarticlesFixed(arrayRetained)
        setArticleChanges(arrayRetained)
        setArticleChangesDelete([])
        
    }


    const savearticles =()=>{
        setarticlesFixed(articleChanges.concat(articleNew));
        setFilter2({
            ...filters2,
            search:"",
            page:1,
            perpage:10,
        });
        setmodalArtControl({show:false})
    } 
    const [filenameState, setfilenameState] = useState({  
        name_doc: "",
        type_doc:"",
      });

    const validateFile = async (acceptedFile) => {
        let fileValid
        let size=acceptedFile[0].size
        if (size > MAX_SIZE_FILE) {
          fileValid = false
         customSwaltAlert({
            icon:'warning',
            title:"El archivo es demasiado pesado",
            footer:"El tamaño máximo permitido es de 5MB",
            showCancelButton: false
          })
        } if (new RegExp(MULTIPLE_REGEX_VALID_FILE).test(acceptedFile.type)) {
          fileValid = false
          customSwaltAlert({
            icon:'warning',
            title:"El formato del archivo debe ser PDF, WORD, EXCEL",
            footer:"El tamaño máximo permitido es de 5MB",
            showCancelButton: false
          })
        } else{
          fileValid = true;
        }    
        if (fileValid) {
          
          let tempReq = [...register];
          tempReq.file=acceptedFile;
          setregister(tempReq);
  
          
        }
      }

    const docType=(data) => {
        let iconDoc
        if(!!data){   
            switch(data){
                case "application/pdf":
                    iconDoc=PDF
                    break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/msword":
                    iconDoc=Word
                    break;
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "csv" || "application/vnd.ms-excel":
                    iconDoc=Excel
                    break;
                default:
                    iconDoc=PDF
                    break;
            }
        }
        return iconDoc;
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };

    const saveDoc = async (file) => {
        let encode = await convertBase64(file);
        let file_nanme= file.name.split(".")[0]
        let type= file.type    
        let valueSave = encode.split(',')[1]; 
        let tempReq = deepcopy(register);
        tempReq.document.push({base64:valueSave,file_name:file_nanme,type:type});
        setregister(tempReq);
        setfilenameState({name_doc:""})
        setmodalDocsControl({show:false})
    }

    const onChangeDoc=  (e) => {
        var f =e.target.files[0]
        var name_doc= f.name.split(".")[0];
        var type_doc=f.type;
        setfilenameState({name_doc:name_doc,type_doc:type_doc});
        let tempReq = deepcopy(register);
        tempReq.file=(f);
        setregister(tempReq);
    }

    const DisabledSave=  () => {
        let bool=false
        if (register.req_auth){
            if (register.entry_date !=="" && articlesFixed?.length >0 && register.problem !== null && register.addInfo !==""){
                articlesFixed.forEach(element => {
                    if (element.lots?.length>0) {
                        bool=false
                    }
                    else{
                        bool=true
                    }
                });
                if (articlesFixed?.some(element => element.inWarehouse === 0)) bool = true
                return bool
                
            }
            else {
                bool = true
                if (articlesFixed?.some(element => element.inWarehouse === 0)) bool = true
                return bool 
            }
            

        }else{
            if (register.entry_date !=="" && articlesFixed?.length >0){
                articlesFixed.forEach(element => {
                    if (element.lots?.length>0) {
                        bool=false
                    }
                    else{
                        bool=true
                    }
                });
                if (articlesFixed?.some(element => element.inWarehouse === 0)) bool = true
                return bool
                
            }
            else {
                bool = true
                if (articlesFixed?.some(element => element.inWarehouse === 0)) bool = true
                return bool 
            }            

        }
        
       

    }   

    const handleSearch = (e) => {
        e.preventDefault();
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
    };

    


    //render ___________________________________________________________________________________________
    
    const principalPage = <div className={tableStyles.container} style={{padding:"0 6rem"}}>
            {counter.entryWarehouseReducer.loading ? (
            <div className="loading">
                <Loader type="Oval" color="#003f80" height={100} width={100} />
            </div>
            ) : null}
            <div className={registerWarehouseStyles.container1}>
                <h1 className={tableStyles.title}>Registrar entrada a almacén</h1>
            </div>
            <div className={registerWarehouseStyles.container2}>
                {/* ---------------------------- ROW 1 ---------------------------------- */}
                <div className="d-flex">
                    {checkHandle.checked?

                    <div className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.Item1}`}
                    >
                        <p className={` ${tableStyles.f12} ${registerWarehouseStyles.crudModalLabelGris}`}>Orden de compra</p>
                        <div
                            className={` ${registerWarehouseStyles.registerInputsGris}`}
                            
                        >
                        </div>
                        
                    </div>
                    
                    :
                    <div
                        className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.Item1}`}
                    >
                        <p className={registerWarehouseStyles.crudModalLabel}>Orden de compra</p>

                        {props.poSelected?
                        <div
                            className={` Register-inputs  ${registerWarehouseStyles.pseudoInput1}`}
                            onClick={()=>ButtonPurchaseOrder()}
                        >
                            <p>{props.poSelected?.purchaseOrder_number}</p>

                                <img
                                    alt="icon"
                                    src={buscar}
                                />
                        </div>
                        :
                        <div
                            className={` register-inputs ${registerWarehouseStyles.pseudoInput}`}
                            onClick={()=>ButtonPurchaseOrder()}
                        >
                            <p className={`${tableStyles.f12}`}>{"Buscar..."}</p>

                            <img 
                                alt="icon"
                                src={buscar} 
                            />
                        </div>
                        }
                    </div>

                    }

                    <div
                        className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.Item1}`}
                    >
                        <p className={registerWarehouseStyles.crudModalLabel}>Almacén*</p>
                        {checkHandle.checked?
                            <Select noOptionsMessage={() => 'No hay datos'}
                            name="storage"
                            placeholder="Seleccionar..."
                            options={optionStorage}
                            styles={customSelectNewDark}
                            key={"id_warehouse" + trigger2}
                            onChange={(e) =>
                                setregister({
                                ...register,
                                id_warehouse: e.value,
                                })
                            }
                            />
                        :
                            <input
                            className={`register-inputs`}
                            value={props.poSelected?.name_warehouse}
                            // onChange={(e) =>
                            //     setregister({
                            //     ...register,
                            //     id_warehouse: e.target.value,
                            //     })
                            // }
                            name="ID"
                            key={"name_warehouse" + trigger2}
                            type="text"
                            readOnly
                        />
                        }
                    </div>

                    <div
                        className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.Item1}`}
                    >
                        <p className={registerWarehouseStyles.crudModalLabel}>No. de Factura o remisión*</p>
                        <input
                        className={`register-inputs`}
                        value={register?.billNumber}
                        key={"billNumber" + trigger2}
                        onChange={(e) =>
                            setregister({
                            ...register,
                            billNumber: e.target.value,
                            })
                        }
                        name="ID"
                        type="text"
                        disabled={false}
                        placeholder="Escribir..."
                        />
                    </div>

                    
                    <div
                        className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.Item1}`}
                        >
                        <p className={registerWarehouseStyles.crudModalLabel}>Fecha*</p>
                        <input
                            className={`register-inputs`}
                            name="fromDate"
                            type="date"
                            value={register.entry_date}
                            onChange={(e) =>
                                setregister({
                                ...register,
                                entry_date: e.target.value,
                                })
                            }
                            max={new Date().toISOString().split("T")[0]}
                            placeholder="Escribir..."
                        />
                    </div>

                </div>
                {/* ---------------------------- ROW 2 ---------------------------------- */}
                <div className="d-flex">
                    <div
                        className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.Item0}`}
                    >
                        <p className={registerWarehouseStyles.crudModalLabel}>Cargar factura o remisión</p>
                        <div
                            className={` register-inputs ${registerWarehouseStyles.pseudoInput}`}
                            onClick={()=>ButtonDocs()}
                        >
                            {!!register.document[0]?
                            <div className={` ${registerWarehouseStyles.buttonCreatCount}`}>
                                    <img              
                                        src={docType(register.document[0]?.type)}
                                        alt="añadir"
                                        className={`${IndividualStyles.imagePaddingType}`}
                                        
                                    />
                                    <label className={`${IndividualStyles.labelNew} ${registerWarehouseStyles.ellipsis}`}>{register.document[0]?.file_name}</label>
                            </div>
                            :
                            <p className={`${tableStyles.f12}`}>{"Cargar documento..."}</p>
                            }
                            
                            <img 
                                src={adjuntar} 
                            />
                        </div>
                    </div>
                    
                    
                    <div className={`${registerWarehouseStyles.ItemMedium}`}>
                        <div  className={`${registerWarehouseStyles.ItemCheckbox}`}>
                            <div className={tableStyles.myCheckbox}>
                                <input 
                                    key={"urgentbox"+Math.random()}
                                    className={tableStyles.myCheckboxInput} 
                                    type="checkbox" 
                                    name={'inputDonate'} 
                                    checked={checkHandle.checked}
                                    onChange={checkHandleEntry}
                                    //defaultChecked={reqState.urgent}
                                    //onChange={e=>setreqState({...reqState, urgent: !reqState.urgent})}
                                    //disabled={!(!!reqState.id_consumption_center)}
                                />
                                <label className={`${tableStyles.myCheckboxLabel} ${registerWarehouseStyles.urgente}`} htmlFor="urgent">Entrada por donación</label>
                            </div>
                        </div>
                    </div>

                    {checkHandle.checked?

                    <div className={IndividualStyles.marginTop}> 
                        <img src={canecaGris} alt="trash"  onClick={()=> deleteArtciles() } className={`mx-2  ${IndividualStyles.btn} ${IndividualStyles.sizeTrash}  `}/>
                        <img src={Add} alt="addArticle" onClick={()=> setmodalArtControl({ ...modalArtControl,show: true})} className={`${IndividualStyles.sizeAdd} ${IndividualStyles.btn}`}/>
                    </div>
                    :
                    null
                    }


                </div>
                <GenericTableNew dark= {true} headers={renderHeaders}>
                    {renderArticlesEntrys()}
                </GenericTableNew>

                <div  className={`${IndividualStyles.ItemSmall}`}>
                    <p className={IndividualStyles.crudModalLabel}>Observaciones</p>
                    <textarea 
                    id="txtArea"
                    style={{border: "1px solid #7FADDE"}}
                    className={IndividualStyles.textArea}
                    value={register.observations}
                    onChange={(e)=>setregister({
                        ...register,
                        observations: e.target.value
                    })}
                    ></textarea>
                </div>

                  {/* ------------ REQ AUTH ----------- */}
                <div className="d-flex">            
                    <div className={registerWarehouseStyles.containerObservations}>
                        <div className="col">
                        <div className="d-flex my-2">
                        <input 
                            id={'req.auth'}
                            className={tableStyles.myCheckboxInput} 
                            type="checkbox" 
                            name={'radioAuthorization'} 
                            value={'req.auth'}
                            //checked={checkRadio()}
                            checked={register.req_auth}
                            // defaultChecked={e=>checkRadio()}
                            readOnly
                            // onChange={e=>checkRadio()}
                            // onChange={checkRadio}
                            
                            
                       />

                        <h6 className="text-light-blue fw-bold mb-0 mx-1">
                            Solicitar autorización
                        </h6>
                        </div>
                        {register.req_auth?
                            <div className="d-flex justify-content-between">
                            <div className="col-5">
                                <label htmlFor="we_problem" className={registerWarehouseStyles.labelFont}>
                                ¿Cuál era el problema?
                                </label>
                                <select name="we_problem" className={`w-100 ${registerWarehouseStyles.selectInput}`} value={register.problem}
                                    onChange={(e) =>setregister({ ...register, problem: e.target.value,})} readOnly>
                                    <option value=""></option>
                                    {counter.entryWarehouseReducer.problems?.map(pro => {
                                        return <option key={`problem-${pro.id}`} value={pro.id}>{pro.value}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col ml-3">
                                <label
                                htmlFor="we_additional_info"
                                className={registerWarehouseStyles.labelFont}
                                >
                                Información adicional
                                </label>
                            <textarea
                                name="we_additional_info"
                                type="text"
                                value={register.addInfo}
                                onChange={(e) =>
                                    setregister({
                                    ...register,
                                    addInfo: e.target.value,
                                    })
                                }
                                className={`w-100  ${IndividualStyles.textArea_new} darkGray`}
                            />
                            </div>
                            </div>
                        :
                        <span></span>
                        }
                        
                    </div>
                    </div>
                </div>



                
                


                <div className={registerWarehouseStyles.bottomRow}>
                    <Button
                            className={tableStyles.btnPrimary}
                            onClick={()=>buttonRegisterEntry()}
                            disabled={DisabledSave()}
                    >
                        Guardar
                    </Button>
                    <Button
                            className={`${tableStyles.btnSecondary} ${registerWarehouseStyles.inputMargin}`}
                            onClick={() =>clean()}
                    >
                        Cancelar
                    </Button>
                </div>

                
            </div>

            {/* MODAL RESUMEN DE ORDENES DE COMPRA*/}
            <ModalNew

            title={"Orden de compra"}
            show={modalPurchaseOrderControl.show}
            btnNoName= {"Cancelar"}
            btnNoEvent={()=>setmodalPurchaseOrderControl({show:false})}
            size="lg"
            onHide={()=>setmodalPurchaseOrderControl({show:false})}
            >
                <div className={registerWarehouseStyles.container2}>
                    <div className="d-flex align-items-end">
                        <div className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.Item1}`}>
                        <form  onSubmit={(e) => handleSearch(e)}>
                            <p className={registerWarehouseStyles.crudModalLabel}>No. de OC</p>
                            <input
                                className={`register-inputs`}
                                name="ID"
                                type="text"
                                value={filters.code}
                                onChange={(e) =>
                                    setFilters({
                                    ...filters,
                                    code: e.target.value,
                                    })
                                }
                                disabled={false}
                                placeholder="Escribir..."
                            /> 
                        </form>
                        </div>
                        <div className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.Item1}`}>
                            <p className={registerWarehouseStyles.crudModalLabel}>Fecha</p>
                            <input
                                className={`register-inputs`}
                                name="fromDate"
                                type="date"
                                value={filters.created_at}
                                onChange={(e) =>
                                    setFilters({
                                    ...filters,
                                    created_at: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className={`${registerWarehouseStyles.inputMarginR} ${registerWarehouseStyles.Item1}`}>
                        <form  onSubmit={(e) => handleSearch(e)}>
                            <p className={registerWarehouseStyles.crudModalLabel}>Proveedor</p>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                name="provider"
                                placeholder="Seleccionar..."
                                options={optionProviders}
                                styles={customSelectNewDark}
                                onChange={(e) =>
                                    setFilters({
                                    ...filters,
                                    provider: e.value,
                                    })
                                }
                            />
                        </form>
                        </div>
                        <img 
                            src={lupaClear} 
                            alt="filtrar" 
                            className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin} ${registerWarehouseStyles.marginTopIcon}`} 
                            onClick={() => setTrigger(trigger + 1)}
                        />
                    </div>

                    <GenericTableNew headers={renderHeadersPO}>
                    {renderPurchaseOrders()}
                    </GenericTableNew>
                    <div className={registerWarehouseStyles.wrapperReverse}>
                    <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.perpage}
                        totalItemsCount={counter.entryWarehouseReducer.purchaseOrders_total}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
                        itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
                        itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
                        itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
                        itemClass={paginationStyles.itemClass}
                    />
                </div>
                </div>
                

            </ModalNew>


            <ModalNew
                title={"Artículos"}
                show={modalArtControl.show}
                size="lg"
                btnYesName="Agregar"
                btnYesEvent={() => savearticles()}
                onHide={()=>setmodalArtControl({show:false})}
            >
            <p className={`${IndividualStyles.crudModalLabel} ${IndividualStyles.letterPadding}`}>Familia de inventario</p>
            <div className={IndividualStyles.mainRow}>
                <div  className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemSmall}`}>
                    <Select noOptionsMessage={() => 'No hay datos'}
                        key={"selectCC"+selectedCC.value}
                        value={selectedCC}
                        onChange={e => {
                                setselectedCC(cloneDeep(e));
                                setArticleChanges([]);
                                setArticleNew([]);
                                setFilter2({...filters, search:"", page:1})
                            }
                        }
                        options={optionInventoryFamily()}
                        styles={customSelectNew}
                        maxHeight={26}
                        placeholder="Seleccionar..."
                    />
                </div>
                <div  className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemSmall}`}>
                    <input
                        className={`register-inputs`}
                        type="text"
                        placeholder="Buscar..."
                        value={filters.search}
                        onChange={(e) => setFilter2({
                            ...filters2,
                            search: e.target.value
                        })}
                    />
                </div>
                <img
                    src={lupaClear} 
                    alt="filtrar"
                    height={24}
                    className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`} 
                    //onClick={this.clickFilter}
                />
                {/* <div  className={`${IndividualStyles.ItemTiny} ${IndividualStyles.orangeText}`}>
                    {(filteredArticles().length<1) && (filters.search.trim()!="")? "Artículo no encontrado" : ""}
                </div> */}

            </div>

            <GenericTableNew headers={renderHeadersArtciles}>{paginateList()}</GenericTableNew>
        
            <div className={paginationStyles.wrapperReverse}>
                <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={filteredArticles().length + articleNew.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
                itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
                itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
                itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
                itemClass={paginationStyles.itemClass}
                />
            </div>

            </ModalNew>

            {/* MODAL DE DOCUMENTOS*/}
            <ModalNew

            title={"Cargar Factura"}
            show={modalDocsControl.show}
            btnYesName= {"Guardar"}
            btnNoName= {"Cancelar"}
            btnYesEvent={()=>saveDoc(register?.file)}
            btnNoEvent={()=>setmodalDocsControl({show:false})}
            size="sz"
            onHide={()=>setmodalDocsControl({show:false})}
            btnYesDisabled={!(filenameState.name_doc !== "")}
            
            >
                <Dropzone onDrop={acceptedFile => validateFile(acceptedFile)} accept= {type_file}>
                    {({getRootProps, getInputProps}) => (
                        <section className="border w-100 h-100 p-1 text-center mx-auto">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} type="file" name="documents" onChange={onChangeDoc} accept= {type_file} multiple={false}/>
                            {!!filenameState.name_doc?
                            <div id="2" className={`text-muted text-center "px-3 pt-3 pb-5": "p-5" ${IndividualStyles.filesDir}`}>
                            <img   src= {docType(filenameState.type_doc)} alt="icon" className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.inputMargin}`} />
                            <p className={``}>{filenameState.name_doc}</p>
                            </div>  
    
                            : 
                            
                            <p className={`text-muted text-center "px-3 pt-3 pb-5": "p-5"} hoverPointer`}>{"Soltar archivo aquí"}</p>

                            } 
                            
                            { <img   src= {Attach} alt="añadir" className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.Aling}`} />}
                            
                        </div>
                        </section>
                    )}
                </Dropzone>

            </ModalNew>

            {/* MODAL DE LOTES*/}
            <ModalNew

            title={"Lotes"}
            show={modalLotsControl.show}
            btnYesName= {"Guardar"}
            btnNoName= {"Cancelar"}
            btnYesEvent={()=> checkHandle.checked? btnSaveLotsDonation(modalLotsControl.programming.id) : btnSaveLots(modalLotsControl.programming.id_article)}
            btnNoEvent={()=>cancelLots()}
            size="sz"
            onHide={()=>cancelLots()}
            >
                <div
                    id="arriba"
                    className={`${registerWarehouseStyles.backgroundModal} ${registerWarehouseStyles.paddingButton}`}
                >
                {/* ---------------------------- ROW 1 ---------------------------------- */}
                <label className={`${IndividualStyles.subtitle} `}>
                 Información del producto  
                </label>
                <div className="d-flex">
                <div className={` ${IndividualStyles.ItemSmall}`}>
                    <p className={IndividualStyles.crudModalLabelGris}>Producto</p>
                    <input
                    className={` ${IndividualStyles.registerInputs}` }
                    name="auction_title"
                    type="text"
                    value={modalLotsControl.programming?.description}
                    readOnly
                    />
                </div>
                </div>
                </div>
            {/* ============================= ROW 2 =================================== */}
            <div
                id="bajo"
                className={`${registerWarehouseStyles.backgroundModal_new} ${IndividualStyles.paddingButton}`}
            >
                <div className="d-flex">
                    <div className={`${IndividualStyles.ItemSmall1}`}>
                        <p className={tableStyles.crudModalLabel}>Vencimiento</p>
                        <input
                        className={`register-inputs`}
                        name="delivery_date"
                        type="date"
                        value={lotsInfo.date_expiry}
                        onChange={(e) =>
                            setlotsInfo({
                            ...lotsInfo,
                            date_expiry: e.target.value,
                            })
                        }
                        />
                    </div>
                    <div
                        className={` ${registerWarehouseStyles.ItemTiny_cantidad} ${IndividualStyles.inputMarginleft}`}
                    >
                        <p className={` ${IndividualStyles.crudModalLabel}`}>No. Lote</p>
                        <input
                        style={{textTransform:"uppercase"}}
                        className={`${IndividualStyles.registerInputsGris}`}
                        name="qty"
                        type="text"
                        value={lotsInfo.lot}
                        onChange={(e) =>
                            setlotsInfo({
                            ...lotsInfo,
                            lot: (e.target.value).toUpperCase(),
                            })
                        }
                        min={0}
                        />
                    </div>
                    <div
                        className={`${IndividualStyles.Aling} ${IndividualStyles.ItemTiny2}`}
                    >
                        <span> ‏‏‎</span>
                        <img
                        src={Add}
                        onClick={() => checkHandle.checked? Addlots(modalLotsControl.programming.id, modalLotsControl.programming.id_po_sch):Addlots(modalLotsControl.programming.id_article,modalLotsControl.programming.id_po_sch)}
                        alt="añadir"
                        className={`${IndividualStyles.size} ${IndividualStyles.btn} ${IndividualStyles.imagePadding2}`}
                        />
                    </div>
                </div>
                
            </div>
            <div
                id="arriba"
                className={`${IndividualStyles.paddingButton}`}
            >
                <GenericTableNew dark={true} headers={renderHeadersLots}>
                {renderTableLots}
                </GenericTableNew>
            </div>

            </ModalNew>
            
            <ModalNew
                title={"Agregar artículo"}
                subtitle={props.poSelected?.name_warehouse}
                btnYesName="Guardar"
                btnYesEvent={()=>dispatch(saveArticleInWarehouse(
                    {...addArticle, id_warehouse: props.poSelected.id_warehouse},
                    updatedArticle(addArticle)
                    ))}
                show={modalAddArticle && !!counter.loginReducer.currentAccount.profile.permission.find(x => x.functionality.prefix === "EntryWareh").create}
                size="400"
                onHide={() => setModalAddArticle(false)}
            >
                <div className="col-12">
                    <label htmlFor="description" className={registerWarehouseStyles.labelFont}>
                        Nombre del artículo
                    </label>
                    <input
                        type="text"
                        name="description"
                        className={`${registerWarehouseStyles.inputTextClear} w-100`}
                        disabled
                        value={addArticle?.description}
                    />
                </div>
                <div className="row">
                    <div className="col-6">
                    <label className={registerWarehouseStyles.labelFont} htmlFor="min_stock">
                        Stock min
                    </label>
                    <input
                        type="number"
                        min={1}
                        name="min_stock"                
                        className={`${registerWarehouseStyles.inputTextClear} w-100`}
                        value={addArticle?.min_stock}
                        onChange={(event)=>{
                            const {value} = event.target;
                            setAddArticle({...addArticle, min_stock : value})
                        }}
                        onBlur={()=>{
                        let value = addArticle.min_stock;
                        if (addArticle.max_stock && parseInt(addArticle.max_stock) < parseInt(addArticle.min_stock)) {
                            customSwaltAlert({icon:"warning", title:"Intenta de nuevo", text:"Max stock inválido", showCancelButton:false});
                            value = ""
                        }else if (parseInt(addArticle.min_stock) < 1 ) {
                            customSwaltAlert({icon:"warning", title:"Intenta de nuevo", text:"Max stock inválido", showCancelButton:false});
                            value = ""
                        }
                        setAddArticle({...addArticle, min_stock: value})
                        }}
                    />
                                    
                    </div>
                    <div className="col-6">
                    <label className={registerWarehouseStyles.labelFont} htmlFor="max_stock">
                        Stock max
                    </label>
                    <input
                        type="number"
                        name="max_stock"
                        className={`${registerWarehouseStyles.inputTextClear} w-100`}
                        value={addArticle?.max_stock}
                        onChange={(event)=>{setAddArticle({...addArticle, max_stock: event.target.value})}}
                        onBlur={()=>{
                        let value = addArticle.max_stock;
                        if (addArticle.min_stock && parseInt(addArticle.max_stock) < parseInt(addArticle.min_stock)) {
                            customSwaltAlert({icon:"warning", title:"Intenta de nuevo", text:"Max stock inválido", showCancelButton: false});
                            value = ""
                        }else if (parseInt(addArticle.max_stock) < 1 ) {
                            customSwaltAlert({icon:"warning", title:"Intenta de nuevo", text:"Max stock inválido", showCancelButton: false});
                            value = ""
                        }
                        setAddArticle({...addArticle, max_stock:value})
                        }}
                    />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                    <label className={registerWarehouseStyles.labelFont} htmlFor="purchaseOrder_number">
                        No. de reorden
                    </label>
                    <input
                        name="reorder"
                        type="text"
                        className={`${registerWarehouseStyles.inputTextClear} w-100`}
                        onChange={(e)=>setAddArticle({...addArticle, reorder: e.target.value})}
                        value={addArticle?.reorder}
                    />
                    </div>
                    <div className="col-6">
                    <label className={registerWarehouseStyles.labelFont} htmlFor="available">
                        Cant. disponible
                    </label>
                    <input
                        name="available"                
                        className={`${registerWarehouseStyles.inputTextClear} w-100`}
                        value={addArticle.available}
                        disabled                        
                    />
                    </div>
                </div>
            </ModalNew>            
        </div>

    return (!!details?.show)? 
        <DetailPurchaseOrder
        selectedPurchaseOrder={details.selectedPurchaseOrder}
        show={details?.show}
        setDetails={setDetails}
        setShowPurcharseOrder={setmodalPurchaseOrderControl}
        /> 
    : <>
    {counter.entryWarehouseReducer?.loading && (
        <div className="loading">
          <Loader type="Oval" color="#003F80" height={100} width={100} />
        </div>
      )}


    {principalPage}
    </>
       

}

export default RegisterEntryByWarehouse;