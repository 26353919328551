import { useSelector } from "react-redux";

export const useGetStore = () => {
    
    const store = useSelector((state) => state);

    return { 
        ...store,
        token: store.loginReducer.Authorization,
        userId: store.loginReducer.user_data.id,
        idAccount: store.loginReducer.currentAccount.id
    }
}