//-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ COMPONENTS
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Chart from "react-apexcharts";
import { Button, Card, Col, Nav, Row, Table } from "react-bootstrap";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import { donutOptions } from "../../helpers/constants";
//-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ STYLES
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
//-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ FUNCTIONS
import { getPeriodExpenses } from "../../actions/managementActions";
import { getListPettyCash } from "../../actions/accountingActions";

export const Accounting = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  //-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ PERMISSION
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "glbInd"
    );
  //-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ VARIABLES
  var currDate = new Date().toISOString().substr(0, 10);
  let formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
  });
  let dataDonut = !!(
    store?.managementReducer?.periodExpensesList?.data?.length > 0
  )
    ? store?.managementReducer?.periodExpensesList?.data
    : [];
  let labelsDonut = !!(
    store?.managementReducer?.periodExpensesList?.labels?.length > 0
  )
    ? store?.managementReducer?.periodExpensesList?.labels
    : [];
  let table = !!(
    store?.managementReducer?.periodExpensesList?.Fontainebleau?.length > 0
  )
    ? store?.managementReducer?.periodExpensesList?.Fontainebleau
    : [];
  let total = !!store?.managementReducer?.periodExpensesList?.total_ammount
    ? store?.managementReducer?.periodExpensesList?.total_ammount
    : 0;
  //-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ FILTERS
  const [filtersAccounting, setFiltersAccounting] = useState({
    date_start: currDate,
    date_end: currDate,
    petty_cash: "",
  });

  //-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ REQUEST
  useEffect(() => {
    dispatch(
      getPeriodExpenses({
        account: store.loginReducer.currentAccount.id,
        date_start: filtersAccounting.date_start,
        date_end: filtersAccounting.date_end,
        petty_cash: filtersAccounting.petty_cash,
      })
    );
  }, [
    filtersAccounting.date_end,
    filtersAccounting.date_start,
    filtersAccounting.petty_cash,
  ]);

  useEffect(() => {
    dispatch(
      getListPettyCash({
        status: "enabled",
        eaccount: store.loginReducer.currentAccount.id,
      })
    );
  }, []);

  //-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ SELECT OPTIONS

  let optionsPettyCash = [
    { key: "", value: "", label: "Seleccione caja menor" },
  ];
  if (Array.isArray(store.accountingReducer.listPettyCash)) {
    store.accountingReducer.listPettyCash.map((item, index) => {
      optionsPettyCash.push({
        value: item.id,
        label: item.name,
        key: index + "pettyC",
      });
    });
  }

  //-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ RETURN

  return (
    <>
      <div className={`${tableStyles.container}`}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Indicadores de contabilidad</h1>
          <hr></hr>
          <Row className="mb-4">
            {/* INDICADORES ELIMINADOS TEMPORALMENTE */}
            {/* <Col xs={3}> */}
            {/* <Row className={`${tableStyles.cardBoxContainer}`} style={{height:"20vh"}}>
                
                <Col xs={12} >
                  <h5 className={`${tableStyles.darkBlueText} d-flex`}>
                    <img src={ReembolsoBlue} className={``} style={{marginRight: "8px"}}/>
                    <b >Endeudamiento</b>
                  </h5>
                </Col>
              </Row> */}
            {/* <Row className={`${tableStyles.cardBoxContainer}`} style={{height:"20vh"}}>
                <Col xs={12}>
                  <h5 className={`${tableStyles.darkBlueText} d-flex`}>
                    <img src={ReembolsoBlue} className={``}  style={{marginRight: "8px"}}/>
                    <b>Capital de trabajo</b>
                  </h5>
                </Col>

              </Row> */}
            {/* <Row className={`${tableStyles.cardBoxContainer}`} style={{height:"20vh"}}>
              <Col xs={12} >
                  <h5 className={`${tableStyles.darkBlueText} d-flex`}>
                    <img src={ReembolsoBlue} className={``}  style={{marginRight: "8px"}}/>
                    <b>Prueba acida</b>
                  </h5>
              </Col>

              </Row> */}
            {/* </Col> */}

            <Col xs={8}>
              <div
                className={`${tableStyles.cardBoxContainer}`}
                style={{ minHeight: "60vh", maxHeight: "65vh", height: "62vh" }}
              >
                <Row className="d-flex">
                  <Col xs={12}>
                    <p className={tableStyles.crudModalLabel}>Caja menor </p>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={optionsPettyCash}
                      placeholder={""}
                      className="text-secondary"
                      styles={customSelectNewDark}
                      onChange={(e) =>
                        setFiltersAccounting({
                          ...filtersAccounting,
                          petty_cash: e.value,
                        })
                      }
                    />
                  </Col>
                  <Col xs={6}>
                    <p className={tableStyles.crudModalLabel}>Fecha desde</p>
                    <input
                      key={"date1"}
                      className={IndividualStyles.registerInputsBlue}
                      type="date"
                      defaultValue={currDate}
                      onChange={(e) =>
                        setFiltersAccounting({
                          ...filtersAccounting,
                          date_start: e.target.value,
                        })
                      }
                    />
                  </Col>

                  <Col xs={6}>
                    <p className={tableStyles.crudModalLabel}>Fecha hasta</p>
                    <input
                      key={"date2"}
                      className={IndividualStyles.registerInputsBlue}
                      type="date"
                      defaultValue={currDate}
                      onChange={(e) =>
                        setFiltersAccounting({
                          ...filtersAccounting,
                          date_end: e.target.value,
                        })
                      }
                      min={filtersAccounting.date_start}
                    />
                  </Col>

                  <Col xs={12} style={{ marginTop: "8px" }}>
                    <div
                      className={`d-flex`}
                      style={{
                        borderBottom: "1px solid  #CECECE",
                        paddingBottom: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <div className="col-3">
                        <p
                          className={`m-0`}
                          style={{
                            textAlign: "start",
                            color: "58595B",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          Cuenta
                        </p>
                      </div>
                      <div className="col-6">
                        <p
                          className={`m-0`}
                          style={{
                            textAlign: "center",
                            color: "58595B",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Nombre de cuenta
                        </p>
                      </div>
                      <div className="col-3">
                        <p
                          className={`m-0`}
                          style={{
                            textAlign: "center",
                            color: "#58595B",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Valor
                        </p>
                      </div>
                    </div>
                    <div
                      className={tableStyles.scrollThis}
                      style={{ height: "25vh" }}
                    >
                      {table?.map((x, realindex) => (
                        <div
                          className={`d-flex`}
                          key={"table" + realindex}
                          style={{
                            borderBottom: "1px solid  #CECECE",
                            paddingBottom: "8px",
                            paddingTop: "8px",
                          }}
                        >
                          <div className="col-3">
                            <p
                              className={`m-0`}
                              style={{
                                textAlign: "start",
                                color: "#58595B",
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              {x.Account}{" "}
                            </p>
                          </div>
                          <div className="col-6">
                            <p
                              className={`m-0`}
                              style={{
                                textAlign: "start",
                                color: "#58595B",
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              {x.Name}{" "}
                            </p>
                          </div>
                          <div className="col-3">
                            <p
                              className={`m-0`}
                              style={{
                                textAlign: "end",
                                color: "#58595B",
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              {formatter.format(x.Ammount)}{" "}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div
                      className={`d-flex`}
                      style={{
                        paddingBottom: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <div className="col-3">
                        <p
                          className={`m-0`}
                          style={{
                            textAlign: "start",
                            color: "#005DBF",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        ></p>
                      </div>
                      <div className="col-6">
                        <p
                          className={`m-0`}
                          style={{
                            textAlign: "center",
                            color: "#005DBF",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Total periodo
                        </p>
                      </div>
                      <div className="col-3">
                        <p
                          className={`m-0`}
                          style={{
                            textAlign: "end",
                            color: "#005DBF",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          {formatter.format(total)}{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={4}>
              <div
                className={`${tableStyles.cardBoxContainer} `}
                style={{ minHeight: "60vh" }}
              >
                <div className={`text-start `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Porcentaje total gastos del periodo</b>
                    </p>

                    {labelsDonut?.length > 0 > 0 && dataDonut?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels: labelsDonut?.length > 0 ? labelsDonut : [],
                        })}
                        series={dataDonut.length > 0 ? dataDonut : []}
                        type="donut"
                        height={450}
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ["Cargando..."],
                          colors: ["gray"],
                        })}
                        series={[50]}
                        type="donut"
                        height={900}
                      />
                    )}

                    {/* <Chart
                      options={donutOptions({
                        labels:
                          labelsDonut?.length > 0
                            ? labelsDonut
                            : [],
                      })}
                      series={dataDonut.length>0? dataDonut : []}
                      type="donut"
                      height={450}
                    /> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
