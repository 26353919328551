import React, { Component, useEffect, useState } from "react";
import bankMoveStyle from "./bankMoveStyles.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import searchFileIcon from "../../assets/img/icons/searchFileIcon.svg";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import { getNiifAccounts } from "../../actions/pucActions";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import NumberFormat from "react-number-format";
import { ConsignementToDate } from "../../actions/insert_consignment_to_dateAction";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from "react-loader-spinner";
import { customSwaltAlert } from "../../helpers";
import { Title } from "../../shared";

function BankMoveForm(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const counter = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const MySwal = withReactContent(Swal);

  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix == "ConsigToDa");

  useEffect(() => {
    if (!myPermission?.create || !myPermission?.read) {
      history.push("/inicio");
    }
  }, [])

  useEffect(() => {
    dispatch(
      getNiifAccounts({ eaccount: counter.loginReducer.currentAccount.id })
    );
  }, [trigger]);

  const [insert, setInsert] = useState({
    transaction_date: "",
    niff_account: "",
    deposit_slip: "",
    details: "",
    check_amount: false,
    voucher_amount: "",
    created_by: counter.loginReducer.user_data.id,
    doc_type: 1,
    cash_amount: 0,
    entity_account: counter.loginReducer.currentAccount.id,
  });
  let optionsNiif = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];

  if (Array.isArray(counter.pucReducer.niifAccounts)) {
    counter.pucReducer.niifAccounts.map((item) => {
      if (item.check_info_bank === true) {
        optionsNiif.push({
          value: item.id,
          label: `${item.complete_account}: ${item.description} ${item.account_type ? "- " + item.account_type : ""}`,
          key: item.id + "niff",
        });
      }
    });
  }

  const resetStatus = () => {
    setInsert({
      transaction_date: "",
      niff_account: "",
      deposit_slip: "",
      details: "",
      check_amount: false,
      voucher_amount: "",
      created_by: counter.loginReducer.user_data.id,
      doc_type: 1,
      cash_amount: 0,
      entity_account: counter.loginReducer.currentAccount.id,
    })
    setTrigger(trigger + 1)
  }

  const submitParamsPost = () => {

    if (!insert.niff_account ||
      !insert.details ||
      !insert.deposit_slip ||
      !insert.transaction_date ||
      (!insert.voucher_amount && !insert.cash_amount)
    ) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: "Formulario incompleto"
      })
      return null;
    } else {
      dispatch(
        ConsignementToDate(
          {
            ...insert,
            amount: insert.cash_amount,
            eaccount: counter.loginReducer.currentAccount.id,
          },
          resetStatus
        )
      );
    }
  }

  return (
    <>
      {(!!counter.bankNoteReducer.transfer_register_loading) &&
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      }
      <div className={bankMoveStyle.centredContainer}>
        <div className={bankMoveStyle.container2}>
          <div className={tableStyles.container1}>
            <div 
              className={tableStyles.ItemSmall}
              style={{marginTop:'30px'}}
            >
              <Title
                title="Consignaciones a la fecha"
                className={'mb-4'}
                onClickIcon={() => {
                  history.push('/tesoreria/inicio')
                }}
              />
            </div>
          </div>
          <div
            className={`pt-3 pb-3`}
            style={{
              backgroundColor: "#F2F7FC",
              borderRadius: "10px",
              paddingLeft: "24px",
            }}
          >
            {/* ---------------------------- ROW 1 ---------------------------------- */}
            <Row className="d-flex align-items-end" style={{ width: "100%" }}>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Fecha</p>
                <input
                  className={IndividualStyles.registerInputsBlue}
                  name="requisition_date"
                  type="date"
                  value={insert.transaction_date}
                  onChange={(e) => setInsert({
                    ...insert,
                    transaction_date: e.target.value,
                  })}
                />
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Cuenta bancaria</p>
                <Select noOptionsMessage={() => 'No hay datos'}
                  styles={customSelectNewDark}
                  name="id_consumption_center"
                  options={optionsNiif}
                  value={optionsNiif.find(x => x.value === insert.niff_account)}
                  onChange={(e) => setInsert({
                    ...insert,
                    niff_account: e.value,
                  })}
                  placeholder="Seleccionar..."
                >
                </Select>
              </Col>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  No. Volante de consignación
                </p>
                <input
                  placeholder="Escribir..."
                  className={IndividualStyles.registerInputsGris}
                  name="requisition_date"
                  type="text"
                  value={insert.deposit_slip}
                  onChange={(e) => setInsert({
                    ...insert,
                    deposit_slip: e.target.value,
                  })}
                />
              </Col>
            </Row>
            {/* ---------------------------- ROW 2 ---------------------------------- */}
            <Row className="d-flex" style={{ width: "100%" }}>
              <Col>
                <p className={tableStyles.crudModalLabel}>Detalle</p>
                <textarea
                  style={{ height: "3rem", padding: '5px' }}
                  placeholder="Escribir..."
                  rows="5"
                  cols="40"
                  className={IndividualStyles.registerInputsGris}
                  name="requisition_date"
                  value={insert.details}
                  onChange={(e) => setInsert({
                    ...insert,
                    details: e.target.value,
                  })}
                />
              </Col>
            </Row>

            {/*--------------------------------- ROW 3 --------------------------------- */}
            <Row className="d-flex " style={{ width: "100%" }}>
              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Efectivo</p>
                <NumberFormat
                  placeholder="Escribir..."
                  allowNegative={false}
                  id="cost_price"
                  className={`${IndividualStyles.registerInputsGris}`}
                  prefix="$"
                  thousandsGroupStyle="thousand"
                  thousandSeparator
                  value={insert.cash_amount}
                  onValueChange={(e) => setInsert({
                    ...insert,
                    cash_amount: e.value,
                  })}
                />
              </Col>

              {/* <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Cheques del día</p>
                <input
                  className={IndividualStyles.registerInputsGris}
                  name="requisition_date"
                  type="text"
                />
              </Col> */}

              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Voucher tarjeta</p>
                <NumberFormat
                  placeholder="Escribir..."
                  allowNegative={false}
                  id="cost_price"
                  className={`${IndividualStyles.registerInputsGris}`}
                  prefix="$"
                  thousandsGroupStyle="thousand"
                  thousandSeparator
                  value={insert.voucher_amount}
                  onValueChange={(e) => setInsert({
                    ...insert,
                    voucher_amount: e.value,
                  })}
                />
              </Col>
              {/* <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>
                  Cheques postfechados
                </p>
                <input
                  className={IndividualStyles.registerInputsGris}
                  name="requisition_date"
                  type="text"
                />
              </Col> */}
            </Row>
          </div>
          {/*--------------------------------- ROW 4 --------------------------------- */}
          <Row className="d-flex">
            <Col xs={6}></Col>
            <Col
              xs={6}
              className="d-flex"
              style={{ justifyContent: "flex-end", paddingRight: "2.5rem" }}
            >
              <button
                className={`${tableStyles.btnSecondary} mt-4`}
                onClick={() => resetStatus()}
              >
                Cancelar
              </button>
              &nbsp;
              <button
                className={`${tableStyles.btnPrimary} mt-4`}
                onClick={() => submitParamsPost()}
                disabled={
                  !insert.niff_account ||
                  !insert.details ||
                  !insert.deposit_slip ||
                  !insert.transaction_date ||
                  (!insert.voucher_amount && !insert.cash_amount)
                }
              >
                Guardar
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
} export default BankMoveForm;
