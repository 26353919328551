import GenericTableNew from "../Layouts/GenericTableNew";
import lupaClear from "../../assets/img/icons/lupaClear.svg";
import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg";


import React, { useState } from "react";


import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../TableUsers/tableUserStyle.scss";
import "../Layouts/customTabs.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import reqStyles from "./Requisitions.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import ModalNew from "../Layouts/ModalNew";
import ReqModalReq from "./ReqModalReq";
import moment from "moment";




function ModalClone (props){

    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    
    let listRequisitions = [];

    const [filters, setFilter] = useState({
        search: "",
        date: "",
        page: 1,
        perpage: 10
    });
    
    const [modalCloneControl, setmodalCloneControl]= useState({
        show:false,
        req: {}
    });

    const handlePageChange = (val) => {
        setFilter({
            ...filters,
            page: val
        });
    }

    const filteredArticles = () => {

        let onlyArticles = [];
        let realTotal=0;
        if(Array.isArray(counter.requisitionReducer.requisitions)){
            counter.requisitionReducer.requisitions.filter(rr=>rr.id_consumption_center == props.cc_id).map(x=>{
                let dateX = new Date(x.requisition_date);
                //no se por que esto no explota pero bueno ahi va
                let dateInput = filters.date.split("-");
                dateInput = new Date(dateInput[0], dateInput[1]-1, dateInput[2]);

                //comparing dates from mysql and js is hard
                //mostly cuz the difference between Date and Datetime
                //then you gotta consider locale
                let filterDate = !!filters.date? 
                    (dateX?.getDate() == dateInput?.getDate() ) &&
                    (dateX?.getMonth() == dateInput?.getMonth() ) &&
                    (dateX?.getFullYear() == dateInput?.getFullYear() ) 
                : 
                    true
                if(
                    (
                        x?.concept?.toUpperCase().includes( filters.search.trim().toUpperCase() ) 
                        || 
                        x?.num_req?.toString().toUpperCase().includes(filters.search.trim().toUpperCase() ) 
                    ) &&
                        filterDate
                    ){
                        onlyArticles.push(x);
                        realTotal++;
                    }
            });
        }

        onlyArticles=onlyArticles.sort( (a,b) =>{
            let aa = new Date(a.created_at);
            let bb = new Date(b.created_at);
            return (bb - aa)
        } )

        return onlyArticles;
    }

    const renderList = () => {
        let table =[];
        const articles = filteredArticles();
        if (Array.isArray(articles)) {
            table = articles.map( x => {
                let date = new Date(x.requisition_date);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear().toString();
                const formatDate = `${day}/${month}/${year}`;
                return <tr key={"renderList"+x.num_req} className="hover-table-row">
                    <td className={`col-md-2 px-2 text-start`}>
                        {x.num_req}
                    </td>
                    <td className={`col-md-3 text-center`}>
                        {formatDate} {/* , { timeZone: 'America/Bogota' }*/}
                    </td>
                    <td className={`col-md-5 text-start`}>
                        {x.concept}
                    </td>
                    <td className={`col-md-2`}>
                        <img 
                            src={lupaTransparent}
                            alt="detalles"
                            className={`icons-popUp ${reqStyles.adjustIcon}`}
                            onClick={()=>verReqDetalles(x)}
                        />
                    </td>
                </tr>
            });

        }

        return table;
    }

    const verReqDetalles = (req) =>{
        setmodalCloneControl({
            ...modalCloneControl,
            show:true,
            req: req
        })
    }

    const paginateList = () => {
        let fullList = renderList();
        fullList =  fullList.slice(filters.perpage*(filters.page-1),  filters.perpage*(filters.page));
        return fullList;
    }

    const renderHeaders = [
        <th key={`ReqModalClone1`} className="px-2">N° Req</th>,
        <th key={`ReqModalClone2`} className="text-center">Fecha</th>,
        <th key={`ReqModalClone3`}>Concepto</th>,
        <th key={`ReqModalClone99`}> </th>,
    ]

    const justArticles = (requisitions_article) => {
        let articles =[];
        //articles = requisitions_article.map(x=> {
        if(Array.isArray( counter.requisitionReducer?.req_chosen?.article_list)){
            articles = counter.requisitionReducer.req_chosen.article_list.map(x=> {
                return {
                    ...x.article,
                    ammount: x.qty_article
                };
            })
        }
        return articles;
    }

    const btnClonar = () => {
        props.savearticles()
        props.setreqState({
            ...props.reqState,
            concept: modalCloneControl.req.concept,
            urgent: modalCloneControl.req.urgent,
            observations: modalCloneControl.req.observations,
            requisition_date: moment(new Date()).format("DD/MM/YYYY"),
    
            id_consumption_center: modalCloneControl.req.id_consumption_center,
            cc_name: modalCloneControl.req.cc_ref?.description,
            annual_budget: props.selectedCC.annual_budget,
            articulos: justArticles(modalCloneControl.req.article_list),
        });
        setmodalCloneControl({ show:false});
        props.onHide();
    }

    
    {/* //////////////////////modal //////////////////////////////// */}
    return modalCloneControl.show?
        <ReqModalReq
            reqState={modalCloneControl.req}
            reqId = {modalCloneControl.req?.id}
            show={true}
            btnClonar={btnClonar}
            onHide={()=>setmodalCloneControl({
                ...modalCloneControl,
                show: false
            })}
        />
    :
        <ModalNew
            title={props.title}
            show={props.show}
            size="lg"
            onHide={props.onHide}
        >
            
            <div className={tableStyles.mainRow}>
                <div  className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
                    <p className={tableStyles.crudModalLabel}>Fecha</p>
                
                    <div className={tableStyles.deleteicon}>
                        {!!filters.date?
                            <span  onClick={(e)=>setFilter({...filters, date:""})} >x</span>
                        :
                            ""
                        }

                        <input 
                            type="date"
                            className={`register-inputs ${tableStyles.crudModalInputDate}`}
                            value={filters.date}
                            onChange={(e)=>setFilter({...filters, date:e.target.value})}
                            placeholder="Escribir..."
                        />
                    </div>
                </div>

                <div  className={`${reqStyles.ItemSmall}`}>
                    <p className={tableStyles.crudModalLabel}>Concepto</p>
                    <div className="d-flex align-items-end">
                        <input
                            className="register-inputs"
                            type="text"
                            placeholder="Escribir..."
                            value={filters.search}
                            onChange={(e) => setFilter({
                                ...filters,
                                search: e.target.value
                            })}
                        />
                        <img 
                            src={lupaClear} 
                            alt="filtrar" 
                            className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`} 
                        />
                    </div>
                    
                </div>
                
                

            </div>

            <GenericTableNew headers={renderHeaders}>{paginateList()}</GenericTableNew>
            
            <div className={paginationStyles.wrapperReverse}>
                <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={filteredArticles().length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
                itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
                itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
                itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
                itemClass={paginationStyles.itemClass}
                />
            </div>

        </ModalNew>
    
}

export default ModalClone;