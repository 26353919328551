import React, { Component } from "react";
import GenericTableNew from "../Layouts/GenericTableNew";
import Pagination from "react-js-pagination";
import backArrow from "../../assets/img/icons/atras-icon.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import Search from "../../assets/img/icons/lupa.svg";
import * as deepcopy from "deepcopy";
import { connect } from "react-redux";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import CustomPopup from "../Popup/customPopup";
import Select from "react-select";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalNew from "../Layouts/ModalNew";
import valeStyles from "../TableAccAccounts/AccAccounts_CSS.module.scss";
import {
  getCorporateClients,
  getCIIU,
  createCorportateClient,
  updateCorporateClient,
  activationDesactivation,
} from "../../actions/corporateClientsActions";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import IndividualStyles from "./tableCorporate.module.scss";
import { nitCalculate } from "../../helpers/nitCalculate.js";
import {
  city_get,
  province_get,
  getCountries,
  getCompanyType,
} from "../../actions/locationActions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { optionsSelect, expRegList, validExpReg } from "../../helpers/helpers";
import { responseSwal } from "../../helpers/responseSwal";
import { Col, Row } from "react-bootstrap";
import { getDateFormat } from "../../helpers/getDateFormat";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import { Title } from "../../shared";


const MySwal = withReactContent(Swal);

class TableCorporateClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: "Crear Cliente - Empresa",
      modalbtnYes: true,
      filters: {
        page: 1,
        search: "",
        perpage: 10,
        id_account: this.props.currentAccount.id,
        enabled: "",
        companyType: "",
      },
      corporateClients: {
        companyType: "",
        companyTypeId: "",
        copyName: "",
        Nit: "",
        id_ciiu: "",
        created_at: getDateFormat(),
        id_country: "",
        id_province: "",
        id_city: "",
        address: "",
        phone: "",
        // whatsapp:"",
        email: "",
        comments: "",
        enabled: true,
        id_account: this.props.currentAccount.id,
        verificationCode: "",
      },
      // headerState: ["Razón Social", "NIT", "CIIU", "Tipo", "est."],
      headerState: [
        {
          tittle: "Razón Social2",
          className: "text-start px-2",
        },
        {
          tittle: "Nit",
          className: "text-start",
        },
        {
          tittle: "Ciiu",
          className: "text-center",
        },
        {
          tittle: "Estado",
          className: "w100",
        }
      ],
      editRecord: false,
      detailModal: false,
      modal: false,
    };
  }
  getInitialState = () => {
    this.setState({
      titleModal: "Crear Cliente - Empresa",
      modalbtnYes: true,
      filters: {
        page: 1,
        search: "",
        perpage: 10,
        id_account: this.props.currentAccount.id,
        enabled: "",
        companyType: "",
      },
      corporateClients: {
        companyTypeId: '',
        companyType: "",
        copyName: "",
        Nit: "",
        id_ciiu: "",
        created_at: getDateFormat(),
        id_country: "",
        id_province: "",
        id_city: "",
        address: "",
        phone: "",
        // whatsapp:"",
        email: "",
        comments: "",
        enabled: true,
        id_account: this.props.currentAccount.id,
        verificationCode: "",
      },
      // headerState: ["Razón Social", "NIT", "CIIU", "Tipo", "est."],
      headerState: [
        {
          tittle: "Razón Social",
          className: "text-start px-2",
        },
        {
          tittle: "Nit",
          className: "text-start",
        },
        {
          tittle: "Ciiu",
          className: "text-center",
        },
        {
          tittle: "Estado",
          className: "w100",
        }
      ],
      editRecord: false,
      detailModal: false,
      modal: false,
    });
  };

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "CliEmp"
    );

  componentDidMount() {
    this.props.getCorporateClients(this.state.filters);
    this.getInitialState();
    this.props.getCountries();
    this.props.city_get({});
    this.props.province_get({});
    this.props.getCIIU();
    this.props.getCompanyType();



  }
  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getCorporateClients(this.state.filters);
  }
  handleClick(e) {
    e.preventDefault();
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters });
    this.props.getCorporateClients(this.state.filters);
  }
  toggleModal = (e) => {
    this.getInitialState();
    this.setState({
      modal: !this.state.modal,
    });
  };
  onChangeForm = (e) => {

    const name = e.target.name;
    const value = e.target.value;

    if (name === "Nit") {
      if (value.length <= 15) {
        let { corporateClients } = this.state;
        corporateClients[name] = value;
        this.setState({ corporateClients });
      }
    } else if (name === "verificationCode") {
      if (value.length <= 1) {
        let { corporateClients } = this.state;
        corporateClients[name] = value;
        this.setState({ corporateClients });
      }
    } else {
      let { corporateClients } = this.state;
      corporateClients[name] = value;
      this.setState({ corporateClients });
    }
    this.changeBtnYes();
  };

  validateEmail = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (validExpReg(value, expRegList.email)) {
      let { corporateClients } = this.state;
      corporateClients[name] = value;
      this.setState({ corporateClients });
    } else {
      let { corporateClients } = this.state;
      corporateClients[name] = "";
      this.setState({ corporateClients });
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: `Este correo no es válido`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false
      });
    }
  };

  onChangeSelect = (e) => {
    const name = e.name;
    const value = e.value;

    let { corporateClients } = this.state;
    corporateClients[name] = value;
    this.setState({ corporateClients });
    this.changeBtnYes();
  };

  onChangeSelectCompany = ({ value, name }) => {

    let { corporateClients } = this.state;
    corporateClients[name] = value;
    this.setState({ corporateClients });
    this.changeBtnYes();
  };
  changeBtnYes() {

    let { modalbtnYes } = this.state;
    if (
      this.state.corporateClients.copyName !== "" &&
      // this.state.corporateClients.companyType !== "" &&
      this.state.corporateClients.Nit !== "" &&
      this.state.corporateClients.id_ciiu !== "" &&
      this.state.corporateClients.id_country !== "" &&
      this.state.corporateClients.id_province !== "" &&
      this.state.corporateClients.id_city !== "" &&
      this.state.corporateClients.address !== "" &&
      this.state.corporateClients.phone !== "" &&
      // this.state.corporateClients.whatsapp !== "" &&
      this.state.corporateClients.email !== "" &&
      this.state.corporateClients.verificationCode !== ""
    ) {
      modalbtnYes = false;
      this.setState({ modalbtnYes });
    } else {
      modalbtnYes = true;
      this.setState({ modalbtnYes });
    }
  }
  onChangeFiltersForm = (e) => {
    if (e.label) {
      const value = e.value;
      let { filters } = this.state;
      filters.page = 1;
      filters.enabled = value;
      this.setState({ filters });
      this.props.getCorporateClients(this.state.filters);
    } else {
      const name = e.target.name;
      const value = e.target.value;
      let { filters } = this.state;
      filters.page = 1;
      filters[name] = value;
      this.setState({ filters });
      this.props.getCorporateClients(this.state.filters);
    }
  };

  onChangeSearch = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };
  handleSubmit() {

    if (!this.state.detailModal) {
      // this.setState({
      //   modal: !this.state.modal,
      // });
      if (this.state.editRecord) {
        this.setState({
          modal: !this.state.modal,
        });
        if (!this.filtersToCreate()) {
          this.props.updateCorporateClient(
            this.state.corporateClients,
            this.state.filters,
            () => this.setState({
              modal: false
            }),
          );
        }
      } else {
        if (!this.filtersToCreate()) {
          this.props.createCorportateClient(
            this.state.corporateClients,
            this.state.filters,
            () => this.setState({
              modal: !this.state.modal,
            }),
          );
          // this.setState({
          //   modal: !this.state.modal,
          // });
        }
      }
    }
    else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  }

  filtersToCreate = () => {
    let sw = false;
    if (
      this?.state?.corporateClients?.phone?.length < 7 ||
      this?.state?.corporateClients?.phone?.length > 10
    ) {

      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "El número teléfono o celular digitado es erróneo.",
        confirmButtonText: 'Aceptar',
        showCancelButton: false
      });
      sw = true;
      return sw;
    }
    // if (
    //   this?.state?.corporateClients?.whatsapp?.length < 7 ||
    //   this?.state?.corporateClients?.whatsapp?.length > 10
    // ) {
    //   MySwal.fire({
    //     icon: "error",
    //     title: "whatsApp erróneo",
    //     text: "El número teléfono o celular digitado es erróneo.",
    //   });
    //   sw = true;
    //   return sw;
    // }

    if (this?.state?.corporateClients?.Nit?.length <= 5) {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "El número NIT digitado es demasiado corto.",
        confirmButtonText: 'Aceptar',
        showCancelButton: false
      });
      sw = true;
      return sw;
    }
    if (
      this?.state?.corporateClients?.copyName === "" ||
      // this?.state?.corporateClients?.companyType === "" ||
      this?.state?.corporateClients?.id_ciiu === "" ||
      this?.state?.corporateClients?.Nit === "" ||
      this?.state?.corporateClients?.id_country === "" ||
      this?.state?.corporateClients?.id_province === "" ||
      this?.state?.corporateClients?.id_city === "" ||
      this?.state?.corporateClients?.address === "" ||
      this?.state?.corporateClients?.phone === "" ||
      // this?.state?.corporateClients?.whatsapp === "" ||
      this?.state?.corporateClients?.email === ""
    ) {

      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Favor llenar todos los campos necesarios para la creación del cliente-empresa.",
        confirmButtonText: 'Aceptar',
        showCancelButton: false
      });

      sw = true;
      return sw;
    }
    // if (
    //   nitCalculate(this.state.corporateClients.Nit).toString() !==
    //   this.state.corporateClients.verificationCode.toString()
    // ) {
    //   MySwal.fire({
    //     icon: "error",
    //     title: "Código de verificación erróneo",
    //     text: "El número de verifación del NIT digitado es erróneo.",
    //   });
    //   sw = true;
    //   return sw;
    // }
    let { corporateClients } = this.state;
    corporateClients.Nit =
      corporateClients.Nit + this.state.corporateClients.verificationCode;
    this.setState({ corporateClients });
  };

  handleEditRecord = (client) => {
    let { modal, corporateClients, titleModal, detailModal, modalbtnYes } =
      this.state;
    modal = !this.state.modal;
    detailModal = false;
    modalbtnYes = true;
    corporateClients = deepcopy(client);
    corporateClients.verificationCode = corporateClients.Nit.slice(
      corporateClients.Nit.length - 1
    );
    corporateClients.Nit = corporateClients.Nit.slice(
      0,
      corporateClients.Nit.length - 1
    );

    corporateClients.companyTypeId = client.companyTypeId;

    corporateClients.created_at = getDateFormat();
    titleModal = "Editar Cliente - Empresa";

    this.setState({
      modal,
      corporateClients,
      editRecord: true,
      titleModal,
      detailModal,
      modalbtnYes,
    });
  };
  handleDetailRecord = (client) => {
    let { modal, corporateClients, titleModal, detailModal, modalbtnYes } =
      this.state;
    detailModal = true;
    modalbtnYes = false;
    modal = !this.state.modal;
    corporateClients = deepcopy(client);
    corporateClients.verificationCode = corporateClients.Nit.slice(
      corporateClients.Nit.length - 1
    );

    corporateClients.Nit = corporateClients.Nit.slice(
      0,
      corporateClients.Nit.length - 1
    );
    corporateClients.created_at = getDateFormat();
    titleModal = "Detalle Cliente - Empresa";
    this.setState({
      modal,
      corporateClients,
      titleModal,
      detailModal,
      modalbtnYes,
      editRecord: false,
    });
  };
  renderCorporateClients(CorporateClients, ciiu) {
    let ciiuClass = ciiu.filter((x) => x.value === CorporateClients.id_ciiu);
    return (
      <tr
        key={CorporateClients.id}
        className="hover-table-row text-center align-middle"
      >
        <td className="col-md-5 text-start px-2">
          {CorporateClients.copyName}
        </td>
        <td className="col-md-2 text-start">
          {(
            CorporateClients.Nit.substring(0, CorporateClients.Nit.length - 1) +
            "-" +
            CorporateClients.Nit.substring(CorporateClients.Nit.length - 1)
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
        </td>
        <td className="col-md-2">
          {ciiuClass.length > 0 ? ciiuClass[0].klass : "-"}
        </td>
        {/* <td className="col-md-2 text-start text-uppercase">
          {CorporateClients.companyType === "Convenios_especiales" ? "Covenios especiales" : CorporateClients.companyType}
        </td> */}
        <td className="col-md-1">
          <div className={tableStyles.groupElems}>
            <div
              className={`${CorporateClients.enabled
                ? tableStyles.greenState
                : tableStyles.redState
                } mx-1`}
            >
              {CorporateClients.enabled ? 'Habilitado' : 'Inhabilitado'}
            </div>
            {!this.props.location?.state?.accounting && (
              <>
                {
                  this.myPermission()?.edit && (
                    <CustomPopup
                      triggerSrc={threeDots}
                      showEdit={this.myPermission()?.edit}
                      editClickEvent={() => this.handleEditRecord(CorporateClients)}
                      showEnable={this.myPermission()?.edit}
                      showDetails={this.myPermission()?.read}
                      textDetails="Ver Detalle"
                      showDetailsEvent={() => this.handleDetailRecord(CorporateClients)}
                      isEnabled={CorporateClients.enabled}
                      enableClickEvent={() =>
                        this.props.activationDesactivation(
                          CorporateClients.id,
                          CorporateClients.enabled,
                          this.state.filters,
                          CorporateClients.copyName
                        )
                      }
                    />
                  )
                }
              </>
            )}
          </div>
        </td>
      </tr>
    );
  }
  maxlengthVal(value, length) {
    if (value.length > length) {
      value = value.slice(0, 14);
    }
    return value;
  }

  render() {
    let optionsCiius = [];
    if (this.props.ciiu) {
      this.props.ciiu.map((item) => {
        optionsCiius.push({
          value: item.id,
          label: `${item.klass} - ${item.description}`,
          name: "id_ciiu",
          klass: item.klass,
        });
      });
    }
    let listCorporateClients;
    console.log("aa", window.location.pathname);
    if (this.props.corporateClients !== undefined) {
      const corporateClients = this.props.corporateClients;
      listCorporateClients = corporateClients.map((x) => {
        return this.renderCorporateClients(x, optionsCiius);
      });
    }
    let renderHeaders = this.state.headerState.map((header) => {
      return (
        <th>
          <div className={`${header === 'est.' || header === '' ? tableStyles.groupElems : ''} ${header.className}`}>
            {header.tittle}
          </div>
        </th>);
    });

    let countriesOptions = [];
    if (this.props.countries) {
      this.props.countries.map((item) => {
        countriesOptions.push({
          value: item.id,
          label: item.name,
          name: "id_country",
        });
      });
    }
    let provincesOptions = [];
    if (this.props.provinces) {
      this.props.provinces.map((item) => {
        provincesOptions.push({
          value: item.id,
          label: item.name,
          name: "id_province",
        });
      });
    }
    let citiesOptions = [];
    if (this.props.cities) {
      this.props.cities.map((item) => {
        citiesOptions.push({
          value: item.id,
          label: item.name,
          name: "id_city",
        });
      });
    }
    return (
      <div className={tableStyles.container}>
        {this.props.loading && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        )}
        {!!this.myPermission()?.read ? (
          <div className={tableStyles.tableArea}>
            <Row className="d-flex">
              <Col xs={10}
                style={{ marginLeft: window.location.pathname !== "/administracion/corporateClients" && "-40px" }}
              >
                <h1 className={tableStyles.title}>
                  {window.location.pathname !== "/administracion/corporateClients" &&
                    <img
                      onClick={() => this.props.history.push("/contabilidad/terceros")}
                      src={backArrow}
                      alt="icon"
                      height={40}
                      className={`mr-2 mb-2 cursorPointer`}
                    ></img>
                  }
                  Clientes - Empresas
                </h1>
              </Col>
            </Row>

            <div>
              <div
                className={`flex-direction-row align-middle ${valeStyles.widtDiv}`}
              >
                <div className="d-flex gap-4">
                  {
                    this.myPermission()?.read && (
                      <form
                        className="w-100 d-grid gap-2 align-items-end mb-1" onSubmit={(e) => this.handleClick(e)}
                        style={{ gridTemplateColumns: '200px 300px' }}
                      >

                        <div
                          className={`${IndividualStyles.ItemInicio}`}
                        >
                          <p className={`${tableStyles.crudModalLabel}`}>Estado</p>


                          <Select
                            noOptionsMessage={() => 'No hay datos'}
                            name="enabled"
                            className={`${tableStyles.widthSelect} text-secondary w-100`}
                            onChange={this.onChangeFiltersForm}
                            options={[
                              { value: "", label: "Seleccionar..." },
                              { value: 0, label: "Inhabilitado" },
                              { value: 1, label: "Habilitado" },
                            ]}
                            placeholder="Seleccionar..."
                            styles={customSelectNewDark}
                            maxHeight={26}
                          />

                        </div>
                        <div
                          className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemLarge}`}
                          style={{ "align-self": "flex-end" }}
                        >
                          <div className="d-flex w-100">
                            <input
                              className={`${tableStyles.SearchNew}`}
                              name="search"
                              type="text"
                              placeholder="Buscar..."
                              onChange={this.onChangeSearch}
                              value={this.state.filters.search}
                              onKeyDown={e => {
                                if (e.key === 'Enter') {
                                  this.handleClick(e);
                                }
                              }}
                            />
                            <img
                              src={Search}
                              alt="Search icon"
                              height="24px"
                              className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                              onClick={(e) => this.handleClick(e)}
                            />
                          </div>
                        </div>
                      </form>
                    )
                  }


                  {this.props.location?.state?.accounting ? false : !!this.myPermission()?.create ? (

                    <div className="w-100 d-flex justify-content-end">
                      {
                        this.myPermission()?.create && (
                          <div
                            className="d-flex justify-content-end groupAddButton align-items-center"
                            style={{ marginTop: "15px", width: '200px' }}
                            onClick={() => this.toggleModal(true)}
                          >
                            <label htmlFor="newAccident" className="darkGray fw-bold">
                              Nuevo cliente
                            </label>
                            <button
                              className="addCheckButton mx-2 "
                            // style={{ marginBottom: "1.5px" }}
                            />
                          </div>
                        )
                      }

                    </div>


                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <ModalNew
              hideFooter={this.state.editRecord ? !this.myPermission()?.edit : !this.myPermission()?.create}
              title={this.state.titleModal}
              show={this.state.modal}
              size="lg"
              onHide={() => this.toggleModal(false)}
              btnYesName={"Aceptar"}
              hideCancelButton={
                this.state.detailModal ? () => this.handleSubmit() : null
              }
              btnYesEvent={
                // !this.state.detailModal ?
                () => this.handleSubmit()
                // : null
              }
              btnYesDisabled={this.state.modalbtnYes}
            >
              <div
                id="arriba"
                className={`${IndividualStyles.backgroundModal}`}
              >
                <label className={`${IndividualStyles.subtitle}`}>
                  Información general
                </label>
                <div className="d-flex flex-direction-row align-middle ">
                  <div
                    className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemRz}`}
                  >
                    <p className={IndividualStyles.crudModalTopLabel}>
                      Razón social<span className="text-danger" >*</span>
                    </p>
                    <input
                      className="register-inputs"
                      name="copyName"
                      type="text"
                      onChange={this.onChangeForm}
                      value={this.state.corporateClients.copyName}
                      disabled={this.state.detailModal}
                    />
                  </div>

                  <div className={` ${IndividualStyles.ItemNormal}`}>
                    <p className={IndividualStyles.crudModalTopLabel}>NIT<span className="text-danger" >*</span></p>
                    <div className="d-flex">
                      <NumberFormat
                        className={`register-inputs ${IndividualStyles.nitBig}`}
                        name="Nit"
                        type="tel"
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        placeholder="000.000.000.000"
                        disabled={this.state.detailModal}
                        value={this.state.corporateClients.Nit}
                        isAllowed={(values) => values.value.length <= 10} //max 10 digitos
                        onValueChange={(values) => {
                          const { value } = values;
                          let { corporateClients } = this.state;
                          corporateClients.Nit = value;
                          corporateClients.verificationCode = nitCalculate(
                            this.state.corporateClients.Nit
                          );
                          this.setState({ corporateClients });
                          this.changeBtnYes();
                        }}
                      />
                      -
                      <input
                        className={`register-inputs ${IndividualStyles.nitMini}`}
                        name="verificationCode"
                        value={this.state.corporateClients.verificationCode}
                        //onChange={this.onChangeForm}
                        disabled={this.state.detailModal}
                      // disabled={true}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-direction-row align-middle">
                  <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemNormal} `}>
                    <p className={IndividualStyles.crudModalTopLabel}>
                      Código de empresa de ProgramasTOP
                    </p>
                    <input
                      className="register-inputs"
                      name="code_top"
                      value={this.state.corporateClients.code_top}
                      onChange={this.onChangeForm}
                      disabled={this.state.detailModal}
                    ></input>
                  </div>
                  <div
                    className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemLarge} `}
                  >
                    <p className={IndividualStyles.crudModalTopLabel}>
                      Código CIIU y actividad económica<span className="text-danger" >*</span>
                    </p>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      name="id_ciiu"
                      placeholder="Seleccionar..."
                      defaultValue={optionsCiius.find(
                        (elem) =>
                          elem.value === this.state.corporateClients.id_ciiu
                      )}
                      options={[
                        { label: 'Seleccionar...', value: '' },
                        ...optionsCiius
                      ]}
                      styles={customSelectNewDark}
                      isDisabled={this.state.detailModal}
                      onChange={(e) => this.onChangeSelect(e)}
                    />
                  </div>
                  <div
                    className={`${IndividualStyles.ItemNormal} 
                  
                  `}
                  >
                    <p className={IndividualStyles.crudModalTopLabel}>
                      Fecha de creación
                    </p>
                    <input
                      className="register-inputs"
                      name="copyName"
                      type="text"
                      style={{ color: "#005DBF" }}
                      value={this.state.corporateClients.created_at}
                      disabled={this.state.detailModal}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div id="abajo" style={{ paddingLeft: "20px" }}>
                <label className={`${IndividualStyles.subtitle}`}>
                  Oficina principal
                </label>
                <div className="d-flex flex-direction-row align-middle">
                  <div
                    className={`${IndividualStyles.inputMargin} 
                ${IndividualStyles.ItemNormal} `}
                  >
                    <p className={IndividualStyles.crudModalBottomLabel}>
                      País<span className="text-danger" >*</span>
                    </p>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      name="id_country"
                      options={[
                        { label: 'Seleccionar...', value: '' },
                        ...countriesOptions
                      ]}
                      placeholder="Seleccionar..."
                      styles={customSelectNewDark}
                      isDisabled={this.state.detailModal}
                      defaultValue={countriesOptions.find(
                        (elem) =>
                          elem.value == this.state.corporateClients.id_country
                      )}
                      onChange={(e) => {
                        this.onChangeSelect(e);
                        this.props.province_get({ country: e.value });
                      }}
                    />
                  </div>
                  <div
                    className={`${IndividualStyles.inputMargin} 
                ${IndividualStyles.ItemNormal}
                `}
                  >
                    <p className={IndividualStyles.crudModalBottomLabel}>
                      Departamento<span className="text-danger" >*</span>
                    </p>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      name="id_province"
                      placeholder="Seleccionar..."
                      options={[{ label: 'Seleccionar...', value: '' }, ...provincesOptions]}
                      styles={customSelectNewDark}
                      isDisabled={this.state.detailModal}
                      defaultValue={provincesOptions.find(
                        (elem) =>
                          elem.value == this.state.corporateClients.id_province
                      )}
                      onChange={(e) => {
                        this.onChangeSelect(e);
                        this.props.city_get({ province: e.value });
                      }}
                    />
                  </div>
                  <div
                    className={`
                ${IndividualStyles.ItemNormal}
                `}
                  >
                    <p className={IndividualStyles.crudModalBottomLabel}>
                      Ciudad<span className="text-danger" >*</span>
                    </p>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      name="id_city"
                      styles={customSelectNewDark}
                      placeholder="Seleccionar..."
                      options={[{ label: 'Seleccionar...', value: '' }, ...citiesOptions]}
                      isDisabled={this.state.detailModal}
                      defaultValue={citiesOptions.find(
                        (elem) =>
                          elem.value == this.state.corporateClients.id_city
                      )}
                      onChange={(e) => {
                        this.onChangeSelect(e);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-direction-row align-middle">
                  <div
                    className={`${IndividualStyles.inputMargin}
                ${IndividualStyles.ItemNormal} `}
                  >
                    <p className={IndividualStyles.crudModalBottomLabel}>
                      Dirección<span className="text-danger" >*</span>
                    </p>
                    <input
                      className="register-inputs"
                      name="address"
                      type="text"
                      onChange={this.onChangeForm}
                      disabled={this.state.detailModal}
                      value={this.state.corporateClients.address}
                    />
                  </div>
                  <div
                    className={`${IndividualStyles.inputMargin}
                ${IndividualStyles.ItemNormal}
                `}
                  >
                    <p className={IndividualStyles.crudModalBottomLabel}>
                      Celular<span className="text-danger" >*</span>
                    </p>
                    <input
                      className="register-inputs"
                      name="phone"
                      type="number"
                      onChange={this.onChangeForm}
                      disabled={this.state.detailModal}
                      value={this.state.corporateClients.phone}
                    />
                  </div>
                  {/* <div
                    className={`${IndividualStyles.inputMargin}
                ${IndividualStyles.ItemNormal}
                `}
                  >
                    <p className={IndividualStyles.crudModalBottomLabel}>
                      WhatsApp<span className="text-danger" >*</span>
                    </p>
                    <input
                      className="register-inputs"
                      name="whatsapp"
                      type="number"
                      onChange={this.onChangeForm}
                      disabled={this.state.detailModal}
                      value={this.state.corporateClients.whatsapp}
                    />
                  </div> */}
                  <div
                    className={`
                ${IndividualStyles.ItemNormal}
                `}
                  >
                    <p className={IndividualStyles.crudModalBottomLabel}>
                      Correo electrónico<span className="text-danger" >*</span>
                    </p>
                    <input
                      className="register-inputs"
                      name="email"
                      type="email"
                      onChange={this.onChangeForm}
                      // onBlur={this.validateEmail}
                      onBlur={this.validateEmail}
                      disabled={this.state.detailModal}
                      value={this.state.corporateClients.email}
                    />
                  </div>
                </div>
                <div
                  className={`
              ${IndividualStyles.ItemXL}
              `}
                >
                  <p className={IndividualStyles.crudModalBottomLabel}>
                    Comentarios
                  </p>
                  <textarea
                    className={`register-inputsTextArea ${tableStyles.outlineNone}`}
                    style={{ color: '#58595b' }}
                    name="comments"
                    type="text"
                    onChange={this.onChangeForm}
                    disabled={this.state.detailModal}
                    value={this.state.corporateClients.comments}
                  ></textarea>
                </div>
              </div>
            </ModalNew>
            {
              this.myPermission()?.read && (
                <GenericTableNew fontFamilyTable={'fontFamilyTable'} dark={true} headers={renderHeaders}>
                  {listCorporateClients}
                </GenericTableNew>
              )
            }

            {
              this.myPermission()?.read && (
                <div className={paginationStyles.wrapper}>
                  <p className={paginationStyles.paginationText}>
                    Pag.{" "}
                    {this.props.totalcorporateClients
                      ? this.state.filters.page
                      : ""}
                    {" de "}
                    {Math.ceil(
                      this.props.totalcorporateClients / this.state.filters.perpage
                    )
                      ? Math.ceil(
                        this.props.totalcorporateClients /
                        this.state.filters.perpage
                      )
                      : ""}{" "}
                    ({this.props.totalcorporateClients} encontrados)
                  </p>
                  <Pagination
                    activePage={this.state.filters.page}
                    itemsCountPerPage={10}
                    totalItemsCount={this.props.totalcorporateClients}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              )
            }

          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentAccount: state.loginReducer.currentAccount,
  corporateClients: state.corporateclientsReducer.corporateClients,
  totalcorporateClients: state.corporateclientsReducer.totalcorporateClients,
  ciiu: state.corporateclientsReducer.ciiu,
  countries: state.locationReducer.countries,
  cities: state.locationReducer.cities,
  provinces: state.locationReducer.provinces,
  companyType: state.companyTypeReducer.data,
  loading: state.corporateclientsReducer.corporate_loading,
});

const mapDispatchToProps = (dispatch) => ({
  getCorporateClients: (filters) => {
    dispatch(getCorporateClients(filters));
  },
  createCorportateClient: (data, filters, doAfter) => {
    dispatch(createCorportateClient(data, filters, doAfter));
  },
  updateCorporateClient: (data, filters, doAfter) => {
    dispatch(updateCorporateClient(data, filters, doAfter));
  },
  getCIIU: () => {
    dispatch(getCIIU());
  },

  getCountries: () => {
    dispatch(getCountries());
  },

  getCompanyType: () => {
    dispatch(getCompanyType());
  },

  city_get: async (filters) => {
    dispatch(city_get(filters));
  },
  province_get: async (filters) => {
    dispatch(province_get(filters));
  },
  activationDesactivation: (obj, status, filters, description) => {
    dispatch(activationDesactivation(obj, status, filters, description));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableCorporateClient);
