// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Pagination from "react-js-pagination";

// --------------- icons 💥  ----------------//
import Excel from "../../assets/img/icons/excel.svg";
import Imprimir from "../../assets/img/icons/imprimir.svg";

//-------------- scss styles 😄   --------------
// import CustomPopupExtend from "../Popup/customPopUpExtends";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { getNiifAccounts } from "../../actions/pucActions";
import { listThirdParties } from "../../actions/accountingActions";
import { getAuxiliaryBookInfo } from "../../actions/reportsActions";
import { functional_get } from "../../actions/functionalActions";
import { Redirect } from "react-router";
import { costcenter_get } from "../../actions/costcenterActions";
import { getSites } from "../../actions/sitesActions";
import {
  convertDateToLatinFormat,
  convertMoneyFormat,
  formatToRcSelect,
} from "../../helpers/helpers";
import Loader from "react-loader-spinner";
// import GenericTableScroll from "../Layouts/GenericTableScroll";
// import tableStylesScroll from "../Layouts/genericTableScroll.module.scss";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import { message } from "../../helpers/helpers";
import { getListCompanies } from "../../actions/billingActions";
import { getProviders } from "../../actions/providersActions";
import { convertFilterToString } from "../../helpers/convertToFilter";
import { API_VERSION, URL_GATEWAY } from "../../helpers/constants";
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { Title } from "../../shared";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// ------------------ Functions ----------------------

function SubsidiaryLedger(props) {
  const history = useHistory()
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const token = storage.loginReducer.Authorization;
  const [dataReport, setDataReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPdf, setShowPdf] = useState(false)
  const [base64, setBase64] = useState("")
  const [filters, setFilters] = useState({
    search: "",
    eaccount: storage.loginReducer.currentAccount.id,
    account: "",
    thirdId: "",
    functionalUnit: "",
    site: "",
    costCenter: "",
    dateFrom: "",
    dateUntil: "",
    thirdType: 3,
    reportType: "auxiliaryBook",
    page: 1,
    perpage: 10,
    row_total: 0,
    onlyAccountWithMovs: 1,
  });
  const [showSection, setShowSection] = useState(false);

  const getPdf = async () => {
    setBase64("");
    // const result = await getPdfTest(
    //   "",
    //   storage.loginReducer.Authorization,
    //   "treasury/consultation_of_bank_notes"
    // )
    // if (result?.success) {
    //   return setBase64(result?.result?.base64)
    // } else {
    //   setShowPdf(false)
    //   message('error', 'Ha ocurrido un error', result?.message)
    // }
    const treatedInfo = { ...filters };
    delete treatedInfo.page;
    delete treatedInfo.perpage;
    const my_filters = convertFilterToString(treatedInfo);
    await fetch(`${URL_GATEWAY}${API_VERSION}/composite/documentPdf_generalDaily/?${my_filters}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "pdf_body": "auxBookBase",
          "pdf_row": "auxBookTable",
        }),
      }
    )
      .then((response) => response.json())
      .then(res => {
        if (res.success) {
          let treatPdf = res.key.replace(/^b'/gm, "").replace(/=*'$/gm, "");
          setBase64(treatPdf);
        } else {
          setShowPdf(false);

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `Ha ocurrido un error, no ha sido posible cargar el documento`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false
          });
        }
      }).catch(err => {
        console.error(err)
      })

  }


  useEffect(() => {
    if (showPdf) { getPdf() }
  }, [showPdf])


  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "subsidiary"
    );

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };
  //--------------first functions 🍕   -----------
  useEffect(() => {
    dispatch(
      getNiifAccounts({
        eaccount: storage.loginReducer.currentAccount.id,
        active: 1,
      })
    );
    dispatch(listThirdParties());
    dispatch(functional_get({ entity_account: filters.eaccount, active: 1 }));
    dispatch(costcenter_get({ entity_account: filters.eaccount, active: 1 }));
    dispatch(getSites({ id_account: filters.eaccount, active: 1 }));
  }, []);

  const getReportInfo = async (params) => {
    setLoading(true);
    const res = await getAuxiliaryBookInfo(
      params,
      storage.loginReducer.Authorization
    );
    if (!!res) {
      setLoading(false);
      if (res.success) {
        setDataReport(res.results);
        setFilters({ ...filters, page: params.page, row_total: res.row_count });
        setShowSection(true);
      } else {
        customSwaltAlert({ icon: "warning", title: "Intenta de nuevo", text: res.message, showCancelButton: false });
      }
    } else {
      setLoading(false);
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Ocurrió un error al traer la información del informe",
        showCancelButton: false
      });
    }
  };

  const btnReport = async () => {
    if (!filters.dateFrom || !filters.dateUntil)
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Debe ingresar fecha desde y hasta",
        showCancelButton: false
      });
    getReportInfo({ ...filters, page: 1 });
  };

  const handlePageChange = (page) => {
    setFilters({ ...filters, page: page });
    getReportInfo({ ...filters, page: page });
  };

  const optionsCostCenter = () => {
    let options = [{ label: "Seleccionar...", value: "" }];
    storage.costcenterReducer.cc?.forEach((item) => {
      options.push({ label: item.description, value: item.id });
    });
    return options;
  };

  // const formattedNiifAcc = formatToRcSelect(storage?.pucReducer?.niifAccounts,
  //   "id",
  //   "complete_account",
  //   "",
  //   "",
  //   "description")

  const [foundedNiif, setFoundedNiif] = useState([{ value: "", label: "Seleccionar..." }])

  //console.log(storage?.pucReducer?.niifAccounts)

  const optionsSite = () => {
    let options = [{ label: "Seleccionar...", value: "" }];
    storage.siteReducer.sites?.forEach((item) => {
      options.push({ label: item.description, value: item.id });
    });
    return options;
  };
  const optionsFunctionalUnit = () => {
    let options = [{ label: "Seleccionar...", value: "" }];
    storage.functionalReducer.units?.forEach((item) => {
      options.push({ label: item.description, value: item.id });
    });
    return options;
  };

  let optionsTypeThirds2 = [
    { key: "default", value: "", label: "Seleccionar..." },
    { key: "1", value: 1, label: "Cliente empresa" },
    { key: "1", value: 2, label: "Proveedor" },
    { key: "1", value: 3, label: "Terceros" },
  ];
  let optionsThirds2 = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
  ];
  if (filters.typeThird2 === 1) {
    if (Array.isArray(storage.billingReducer.companies)) {
      storage.billingReducer.companies.map((item) => {
        optionsThirds2.push({
          value: item.id,
          label: item.Nit + " - " + item.copyName,
          key: item.Nit,
          name: item.copyName,
        });
      });
    };
  }
  if (filters.typeThird2 === 2) {
    if (Array.isArray(storage.providerReducer.providers)) {
      storage.providerReducer.providers.map((item) => {
        optionsThirds2.push({
          value: item.id,
          label: item.nit + " - " + item.name,
          key: item.nit,
          name: item.name,
        });
      });
    };
  }
  if (filters.typeThird2 === 3) {
    if (Array.isArray(storage.accountingConsultsReducer.thirdParties)) {
      storage.accountingConsultsReducer.thirdParties.map((item) => {
        optionsThirds2.push({
          value: item.id,
          label: item.nit + " - " + item.description,
          key: item.nit,
          name: item.description,
        });
      });
    };
  }
  const [trigger, setTrigger] = useState(1);

  const handleFilter = (e) => {
    // //console.log(e)
    let nombreEncontrados
    let expresion = new RegExp(`${e.toUpperCase()}.*`, "i");

    if (e.length > 0) {
      nombreEncontrados = storage?.pucReducer?.niifAccounts?.filter(dat => expresion.test(dat.description) || expresion.test(dat.complete_account));
      // //console.log("🚀 ~ nombreEncontrados", nombreEncontrados)
      setFoundedNiif(
        formatToRcSelect(nombreEncontrados,
          "id",
          "complete_account",
          "",
          "",
          "description")
      )
    }
    else {
      setFoundedNiif([{ value: "", label: "Digite el nombre o número de la cuenta" }])
    }

  }


  return (
    <>
      {loading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      {!myPermission().read && <Redirect to="/contabilidad/inicio" />}
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        <div className={tableStyles.title}>
          <Title
            title="Informe de libro auxiliar"
            onClickIcon={() => {
              history.push('/contabilidad/inicio')
            }}
          />
        </div>
        <div className="d-flex">
          {/*----------------------------FIRST SECTION---------------------------------- */}
          <div
            className={`${tableStyles.container}`}
            style={{
              width: "35%",
              paddingLeft: "0px",
              borderRight: "1px solid #CECECE",
              marginRight: "1px",
            }}
          >
            <Row className="d-flex">
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>
                  Fecha desde<span className={"text-warning"}>*</span>
                </p>
                <input
                  className={IndividualStyles.registerInputsBlue}
                  type="date"
                  value={filters.dateFrom}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      dateFrom: e.target.value,
                    })
                  }
                  max={filters.dateUntil}
                />
              </Col>
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>
                  Hasta<span className={"text-warning"}>*</span>
                </p>
                <input
                  className={IndividualStyles.registerInputsBlue}
                  type="date"
                  value={filters.dateUntil}
                  min={filters.dateFrom}
                  onChange={(e) =>
                    setFilters({ ...filters, dateUntil: e.target.value })
                  }
                />
              </Col>
            </Row>

            <Row
              className="d-flex"
              style={{
                background: "rgba(0, 93, 191, 0.05)",
                borderRadius: "10px",
                height: "300px",
                marginRight: "1px",
                marginLeft: "1px",
                marginTop: "16px",
                paddingRight: "8px",
                paddingLeft: "8px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Cuenta<span className={"text-warning"}>*</span>
                </p>

                <Select
                  // options={optionsNiifAccounts()}
                  noOptionsMessage={() => 'No hay datos'}
                  options={foundedNiif}
                  placeholder="Seleccionar..."
                  onInputChange={(e) => {
                    handleFilter(e)
                  }}
                  styles={customSelectNewDark}
                  onChange={(e) => {
                    setFilters({ ...filters, account: e })
                  }
                  }
                />

              </Col>

              <Col xs={12}>
                <p className={tableStyles.crudModalLabel} style={{ marginTop: "0px" }}>Seleccionar tipo de tercero <span className={"text-warning"}>*</span> </p>

                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder="Seleccionar..."
                  styles={customSelectNewDark}
                  options={optionsTypeThirds2}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      typeThird2: e.value,
                      thirdId: ""
                    })
                    if (e.value === 1) {
                      dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }))
                    }
                    if (e.value === 2) {
                      dispatch(getProviders({ id_account: storage.loginReducer.currentAccount.id, slim: 1, status: "enabled" }))
                    }
                    if (e.value === 3) {
                      dispatch(listThirdParties({ eaccount: storage.loginReducer.currentAccount.id }))
                    }
                    setTrigger(trigger + 1)
                  }}
                />
              </Col>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Tercero<span className={"text-warning"}>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={optionsThirds2}
                  placeholder="Seleccionar..."
                  key={"Tthird" + trigger}
                  styles={customSelectNewDark}
                  onChange={(e) => setFilters({ ...filters, thirdId: e.value })}
                />
              </Col>

              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>
                  Unidad funcional<span className={"text-warning"}>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={optionsFunctionalUnit()}
                  placeholder="Seleccionar..."
                  styles={customSelectNewDark}
                  onChange={(e) =>
                    setFilters({ ...filters, functionalUnit: e.value })
                  }
                />
              </Col>
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>
                  Sede<span className={"text-warning"}>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={optionsSite()}
                  placeholder="Seleccionar..."
                  styles={customSelectNewDark}
                  onChange={(e) => setFilters({ ...filters, site: e.value })}
                />
              </Col>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Centro de costo<span className={"text-warning"}>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={optionsCostCenter()}
                  placeholder="Seleccionar..."
                  styles={customSelectNewDark}
                  onChange={(e) =>
                    setFilters({ ...filters, costCenter: e.value })
                  }
                />
              </Col>
              <Col xs={12} style={{ paddingTop: "8px", paddingLeft: "14px" }}
              >
                <div className="d-flex">
                  <input
                    className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
                    type="checkbox"
                    placeholder="Escribir..."
                    name={`onlyAccountWithMovs`}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        onlyAccountWithMovs: e.target.checked === true ? 1 : 0,
                      })
                    }
                    }
                    checked={filters.onlyAccountWithMovs}
                  />
                  <p className={tableStyles.crudModalLabel}>
                    Solo cuentas con movimiento
                  </p>
                </div>
              </Col>
            </Row>

            {/* <Row
              className="d-flex"
              style={{ paddingTop: "24px", paddingBottom: "16px" }}
            >
              <p
                className={tableStyles.crudModalLabel}
                style={{ fontWeight: "700", fontSize: "16px" }}
              >
                Opciones
              </p>
            </Row> */}

            {/* <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "18px" }}>
                <input
                  className="border border-dark-blue form-check-input p1 check-dark-blue"
                  type="checkbox"
                  name="edit"
                  value="selectedAll"
                  // checked={isBoxAllSelected()}
                  // onChange={e => changeBoxAllSelected(!isBoxAllSelected())}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: "#58595B",
                    marginTop: "4px",
                    fontSize: "13px",
                  }}
                >
                  Sólo los NITs que tienen movimiento en el periodo seleccionado
                </p>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "18px" }}>
                <input
                  className="border border-dark-blue form-check-input p1 check-dark-blue"
                  type="checkbox"
                  name="edit"
                  value="selectedAll"
                  // checked={isBoxAllSelected()}
                  // onChange={e => changeBoxAllSelected(!isBoxAllSelected())}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: "#58595B",
                    marginTop: "4px",
                    fontSize: "13px",
                  }}
                >
                  No imprimir fecha en informe
                </p>
              </Col>
            </Row> */}

            {/* <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "18px" }}>
                <input
                  className="border border-dark-blue form-check-input p1 check-dark-blue"
                  type="checkbox"
                  name="edit"
                  value="selectedAll"
                  // checked={isBoxAllSelected()}
                  // onChange={e => changeBoxAllSelected(!isBoxAllSelected())}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: "#58595B",
                    marginTop: "4px",
                    fontSize: "13px",
                  }}
                >
                  Excluir los comprobantes de cierre
                </p>
              </Col>
            </Row>
            <Row className="d-flex">
              <Col xs={1} style={{ paddingLeft: "18px" }}>
                <input
                  className="border border-dark-blue form-check-input p1 check-dark-blue"
                  type="checkbox"
                  name="edit"
                  value="selectedAll"
                  // checked={isBoxAllSelected()}
                  // onChange={e => changeBoxAllSelected(!isBoxAllSelected())}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: "1px" }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: "#58595B",
                    marginTop: "4px",
                    fontSize: "13px",
                  }}
                >
                  Imprimir en páginas numeras
                </p>
              </Col>
            </Row> */}

            <Row className="d-flex my-3">
              <Col xs={3} style={{ marginRight: "5%" }}>
                <Button
                  className={`${IndividualStyles.btnPrimary}`}
                  onClick={() => setShowSection(false)}
                  disabled={false}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={7}>
                <Button
                  className={IndividualStyles.btnPrimaryDark}
                  onClick={() => btnReport()}
                  disabled={!filters.dateFrom || !filters.dateUntil}
                >
                  Generar informe
                </Button>
              </Col>
              {/* <Col xs={4}>

                                </Col> */}
            </Row>
          </div>

          {/*----------------------------END FIRST SECTION---------------------------------- */}

          {/*----------------------------SECOND SECTION---------------------------------- */}
          {showSection && (
            <div
              style={{
                width: "65%",
                display: "flex",
                flexDirection: "column",
                paddingLeft: "40px",
              }}
            >
              <div
                className=" display-grid col-12"
                style={{ textAlignLast: "end" }}
              >
                <div className="col" style={{ textAlignLast: "end" }}>
                  <img
                    alt=""
                    className={`${tableStyles.cursorPointer} `}
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    src={Imprimir}
                    onClick={() => setShowPdf(true)}
                  />
                  <img
                    alt=""
                    className={`${tableStyles.cursorPointer} `}
                    src={Excel}
                  />
                </div>
              </div>

              <div
                style={{
                  marginTop: "9px",
                  backgroundColor: "#FFFF",
                  padding: "21px",
                  height: "100%",
                  width: "100%",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                }}
              >
                <div className="d-flex justify-content-center">
                  <h5 className="text-primary fw-bold text-center">
                    {storage.loginReducer.currentAccount.bussines_name}
                    <br />
                    <span style={{ fontSize: "1rem" }}>
                      {numberWithCommas(
                        storage.loginReducer.currentAccount.nit
                      )}
                    </span>
                  </h5>
                </div>
                <Row>
                  <Col xs={3}>
                    <label
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#005DBF",
                      }}
                    >
                      LIBRO AUXILIAR
                    </label>
                  </Col>
                  <Col xs={4}></Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <label
                      style={{
                        fontWeight: "400",
                        fontSize: "11px",
                        color: "#58595B",
                      }}
                    >
                      Del día {convertDateToLatinFormat(filters.dateFrom)} al
                      día {convertDateToLatinFormat(filters.dateUntil)}
                    </label>
                  </Col>
                </Row>
                <div
                  className={tableStyles.rwoReport}
                  style={{ marginTop: "20px" }}
                >
                  <Col xs={1}>
                    <label
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#005DBF",
                      }}
                    >
                      Fecha
                    </label>
                  </Col>
                  <Col xs={1}>
                    <label
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#005DBF",
                      }}
                    >
                      Cuenta
                    </label>
                  </Col>
                  <Col xs={4}>
                    <label
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#005DBF",
                      }}
                    >
                      Detalle
                    </label>
                  </Col>
                  <Col xs={2} style={{ textAlign: "end" }}>
                    <label
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#005DBF",
                      }}
                    >
                      Debe
                    </label>
                  </Col>
                  <Col xs={2} style={{ textAlign: "end" }}>
                    <label
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#005DBF",
                      }}
                    >
                      Haber
                    </label>
                  </Col>
                  <Col xs={2} style={{ textAlign: "end" }}>
                    <label
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#005DBF",
                      }}
                    >
                      Saldo
                    </label>
                  </Col>
                </div>

                {/*-------MAPEO 1---- */}
                {dataReport?.map((x) => (
                  <div
                    className={tableStyles.rwoReport}
                    key={String(parseInt(x.accountNbr) * Math.random())}
                  >
                    <div className="col-1">
                      <label
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#58595B",
                        }}
                      >
                        {convertDateToLatinFormat(x.cutDate)}
                      </label>
                    </div>
                    <div className="col-1">
                      <label
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#58595B",
                        }}
                      >
                        {x.complete_account}
                      </label>
                    </div>

                    <div className="col-4">
                      <label
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#58595B",
                        }}
                      >
                        {x.description}
                      </label>
                    </div>
                    <div className="col-2 text-end">
                      <label
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#58595B",
                        }}
                      >
                        {convertMoneyFormat(
                          Number(x.startBalanceDebit).toFixed(2)
                        )}
                      </label>
                    </div>
                    <div className="col-2 text-end">
                      <label
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#58595B",
                        }}
                      >
                        {convertMoneyFormat(
                          Number(x.startBalanceCredit).toFixed(2)
                        )}
                      </label>
                    </div>
                    <div className="col-2 text-end">
                      <label
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#58595B",
                        }}
                      >
                        {convertMoneyFormat(Number(x.startBalance).toFixed(2))}
                      </label>
                    </div>
                    {x?.thirds?.map((y) => (
                      <div className={tableStyles.rwoReport}>
                        {y.nit && (
                          <>
                            <div className="col-2">
                              <label
                                className="whiteGray fw-bold"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                NIT: {y.nit}
                              </label>
                            </div>
                            <div className="col-4">
                              <label
                                className="whiteGray fw-bold"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {y.name}
                              </label>
                            </div>
                            <div className="col-2 text-end">
                              <label
                                className="whiteGray fw-bold"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {convertMoneyFormat(
                                  Number(y.startBalanceDebit).toFixed(2)
                                )}
                              </label>
                            </div>
                            <div className="col-2 text-end">
                              <label
                                className="whiteGray fw-bold"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {convertMoneyFormat(
                                  Number(y.startBalanceCredit).toFixed(2)
                                )}
                              </label>
                            </div>
                            <div className="col-2 text-end">
                              <label
                                className="whiteGray fw-bold"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {convertMoneyFormat(
                                  Number(y.startBalance).toFixed(2)
                                )}
                              </label>
                            </div>
                          </>
                        )}
                        {y?.movs?.map((z) => {
                          return (
                            <div className={tableStyles.rwoReport}>
                              <div
                                className="col-1"
                                style={{ textAlign: "start" }}
                              >
                                <label
                                  className="whiteGray"
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                  }}
                                >
                                  {convertDateToLatinFormat(z.date)}
                                </label>
                              </div>
                              <div
                                className="col-1"
                                style={{ textAlign: "start" }}
                              >
                                <label
                                  className="whiteGray"
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                  }}
                                >
                                  {z.jrnlNbr}
                                </label>
                              </div>
                              <div
                                className="col-4"
                                style={{ textAlign: "start" }}
                              >
                                <label
                                  className="whiteGray"
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                  }}
                                >
                                  {z.description}
                                </label>
                              </div>
                              <div
                                className="col-2"
                                style={{ textAlign: "end" }}
                              >
                                <label
                                  className="whiteGray"
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                  }}
                                >
                                  {convertMoneyFormat(
                                    Number(z.debitAmount).toFixed(2)
                                  )}
                                </label>
                              </div>
                              <div
                                className="col-2"
                                style={{ textAlign: "end" }}
                              >
                                <label
                                  className="whiteGray"
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                  }}
                                >
                                  {convertMoneyFormat(
                                    Number(z.creditAmount).toFixed(2)
                                  )}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                        <div className={tableStyles.rwoReport}>
                          <div className="col-6">
                            <span
                              className="darkGray fw-bold"
                              style={{ fontSize: "12px" }}
                            >
                              SUMAS NIT AL{" "}
                              {convertDateToLatinFormat(filters.dateUntil)}
                            </span>
                          </div>
                          <div
                            className="col-2 text-end"
                            style={{
                              borderBottom: "1px solid gray",
                              borderTop: "1px solid gray",
                            }}
                          >
                            <span
                              className="fw-bold darkGray"
                              style={{ fontSize: "12px" }}
                            >
                              {convertMoneyFormat(
                                Number(parseFloat(y.debitAmount + y.startBalanceDebit)).toFixed(2)
                              )}
                            </span>
                          </div>
                          <div
                            className="col-2 text-end"
                            style={{
                              borderBottom: "1px solid gray",
                              borderTop: "1px solid gray",
                            }}
                          >
                            <span
                              className="fw-bold darkGray"
                              style={{ fontSize: "12px" }}
                            >
                              {convertMoneyFormat(
                                Number(parseFloat(
                                  y.creditAmount + y.startBalanceCredit
                                )).toFixed(2)
                              )}
                            </span>
                          </div>
                          <div
                            className="col-2 text-end"
                            style={{
                              borderBottom: "1px solid gray",
                              borderTop: "1px solid gray",
                            }}
                          >
                            <span
                              className="fw-bold darkGray"
                              style={{ fontSize: "12px" }}
                            >
                              {convertMoneyFormat(
                                Number(parseFloat(
                                  y.debitAmount + y.startBalanceDebit
                                ) -
                                  parseFloat(
                                    y.creditAmount + y.startBalanceCredit
                                  )
                                ).toFixed(2))}
                            </span>
                          </div>
                        </div>
                        <div className={tableStyles.rwoReport}>
                          <div className="col-6">
                            <span
                              className="darkGray fw-bold"
                              style={{ fontSize: "12px" }}
                            >
                              MOVIMIENTO NIT
                            </span>
                          </div>
                          <div className="col-2 text-end">
                            <span
                              className="fw-bold darkGray"
                              style={{ fontSize: "12px" }}
                            >
                              {convertMoneyFormat(
                                Number(y.debitAmount).toFixed(2)
                              )}
                            </span>
                          </div>
                          <div className="col-2 text-end">
                            <span
                              className="fw-bold darkGray"
                              style={{ fontSize: "12px" }}
                            >
                              {convertMoneyFormat(
                                Number(y.creditAmount).toFixed(2)
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className={tableStyles.rwoReport}>
                      <div className="col-6 mb-2">
                        <span
                          className="darkGray fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          SUMAS CUENTA AL{" "}
                          {convertDateToLatinFormat(filters.dateUntil)}
                        </span>
                      </div>
                      <div
                        className="col-2 text-end"
                        style={{
                          borderBottom: "1px solid gray",
                          borderTop: "1px solid gray",
                        }}
                      >
                        <span
                          className="fw-bold darkGray"
                          style={{ fontSize: "12px" }}
                        >
                          {convertMoneyFormat(
                            Number(
                              parseFloat(x.debitAmount + x.startBalanceDebit)
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                      <div
                        className="col-2 text-end"
                        style={{
                          borderBottom: "1px solid gray",
                          borderTop: "1px solid gray",
                        }}
                      >
                        <span
                          className="fw-bold darkGray"
                          style={{ fontSize: "12px" }}
                        >
                          {convertMoneyFormat(
                            Number(
                              parseFloat(x.creditAmount + x.startBalanceCredit)
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                      <div
                        className="col-2 text-end"
                        style={{
                          borderBottom: "1px solid gray",
                          borderTop: "1px solid gray",
                        }}
                      >
                        <span
                          className="fw-bold darkGray"
                          style={{ fontSize: "12px" }}
                        >
                          {convertMoneyFormat(
                            Number(
                              parseFloat(x.debitAmount + x.startBalanceDebit) -
                              parseFloat(
                                x.creditAmount + x.startBalanceCredit
                              )
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={tableStyles.rwoReport}>
                      <div className="col-6">
                        <span
                          className="darkGray fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          MOVIMIENTO CUENTA
                        </span>
                      </div>
                      <div className="col-2 text-end">
                        <span
                          className="fw-bold darkGray"
                          style={{ fontSize: "12px" }}
                        >
                          {convertMoneyFormat(x.creditAmount)}
                        </span>
                      </div>
                      <div className="col-2 text-end">
                        <span
                          className="fw-bold darkGray"
                          style={{ fontSize: "12px" }}
                        >
                          {convertMoneyFormat(x.debitAmount)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
                <p className="text-center darkGray">Vigilado Super Salud</p>
              </div>

              {/*----------------------------END SECOND SECTION---------------------------------- */}
              <div
                className={`${paginationStyles.wrapper} justify-content-between mt-2`}
              >
                <p style={{ display: "inline" }}>
                  Pag. {filters.page} de{" "}
                  {Math.ceil(filters.row_total / filters.perpage)
                    ? Math.ceil(filters.row_total / filters.perpage)
                    : ""}{" "}
                  ({filters.row_total} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={filters.row_total}
                  pageRangeDisplayed={5}
                  activeClass={paginationStyles.activeClass}
                  onChange={(e) => handlePageChange(e)}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalNew
        title="Preview"
        show={showPdf}
        btnNoName={"Cancelar"}
        size="700"
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer
          downloadable
          file={`data:application/pdf;base64,${base64}`}></PdfViewer>
      </ModalNew>
    </>
  );
}
export default SubsidiaryLedger;
