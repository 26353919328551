import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { customSwaltAlert } from "../helpers/customSwaltAlert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  GET_AGREEMENTS,
  GET_AGREEMENTS_PROVIDERS,
  LOADING,
  GET_AGREEMENT_DETAILS,
} from "./actionTypes";
const MySwal = withReactContent(Swal);

export const getAgreements = (params) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(params);
  dispatch({ type: LOADING, payload: true });
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/agreement/?${filters}`, {
    method: "GET",
    headers: {
      Authorization: token,
      application: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        if (res.results.length <= 0) {
          dispatch({ type: LOADING, payload: false });
          return customSwaltAlert({
            icon: "info",
            title: "Sin información",
            text: `No se encontraron convenios.`,
            showCancelButton: false,
          });
        }

        dispatch({
          type: GET_AGREEMENTS,
          payload: res.results,
          row_total: res.row_total,
        });
        dispatch({ type: LOADING, payload: false });
      } else {
        dispatch({ type: LOADING, payload: false });
        return customSwaltAlert({
          icon: "error",
          title: "Intenta de nuevo",
          text: `Ocurrió un error al traer la información de los convenios`,
          showCancelButton: false,
        });
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: LOADING, payload: false });
      return customSwaltAlert({
        icon: "error",
        title: "Intenta de nuevo",
        text: `Ocurrió un error al traer la información de los convenios`,
        showCancelButton: false,
      });
    });
};

export const getAgreementsProviders = (params) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(params);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/inventory/agreement/providers?${filters}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
        accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_AGREEMENTS_PROVIDERS,
          payload: res.results,
        });
      }
    })
    .catch(() => {
      dispatch({ type: LOADING, payload: false });
    });
};

export const changeStatusAgreement =
  (data, doAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    dispatch({
      type: LOADING,
      payload: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/agreement/status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          customSwaltAlert({
            icon: "success",
            title: `${res.message}`,
            text: `${res.text}`,
          });
          dispatch({
            type: LOADING,
            payload: false,
          });
          dispatch(
            getAgreements({
              page: 1,
              perpage: 10,
              eaccount: getState().loginReducer.currentAccount.id,
              multistatus: !!data.filters?.multistatus
                ? data.filters?.multistatus
                : "authorized,expired",
            })
          );
          doAfter();
        } else {
          dispatch({
            type: LOADING,
            payload: false,
          });
          customSwaltAlert({
            icon: "error",
            title: `Intenta de nuevo`,
            text: `${res.message}`,
            showCancelButton: false,
          });
        }
      })
      .catch(() => dispatch({ type: LOADING, payload: false }));
  };

export const getOneAgreement =
  (id_agreement, doAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/agreement/${id_agreement}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch({ type: LOADING, payload: false });
        if (!!res.success) {
          dispatch({ type: GET_AGREEMENT_DETAILS, payload: res.results });
          if (!!doAfter) {
            doAfter();
          }
        } else {
          customSwaltAlert({
            icon: "error",
            title: "Intenta de nuevo",
            text: `${res.message}`,
            showCancelButton: false,
          });
        }
      })
      .catch(() => {
        customSwaltAlert({
          icon: "error",
          title: "Intenta de nuevo",
          text: "Ocurrió un error al traer la información del convenio",
          showCancelButton: false,
        });
      });
  };

function responseError(message) {
  return customSwaltAlert({ icon: "error", title: "Intenta de nuevo", text: message, showCancelButton: false, });
}

export const updateAgreement = (data, doAfter) => (dispatch, getState) => {
  if (!data.name) return responseError("El nombre del convenio no es válido");
  if (!data.valid_until)
    return responseError("La fecha de vigencia no es válida");
  if (!data.quotation_ref.pay_conditions)
    return responseError("Debe escoger condiciones de pago validas");
  if (!data.valid_until)
    return responseError("Debe escoger una nueva fecha válida");
  if (!data.justification_renew)
    return responseError("Debe ingresar una justificación");
  dispatch({ type: LOADING, payload: true });
  const dataBody = {
    id: data.id,
    name: data.name,
    pay_conditions: data.quotation_ref.pay_conditions,
    valid_until: data.valid_until,
    justification_renew: data.justification_renew,
  };
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/agreement/`, {
    method: "PUT",
    body: JSON.stringify(dataBody),
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({ type: LOADING, payload: false });
      if (res.success) {
        customSwaltAlert({
          icon: "success",
          title: "Convenio actualizado",
          text: `${res.message}`,
          showCancelButton: false,
        });
      }
      if (!!doAfter) doAfter();
    })
    .catch(() => {
      dispatch({ type: LOADING, payload: false });
      customSwaltAlert({
        icon: "error",
        title: "Intenta de nuevo",
        text: "Ocurrió un error al actualizar el convenio",
        showCancelButton: false,
      });
    });
};
export const agreement_create =
  (data, executeThisAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/agreement/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          MySwal.fire({
            icon: "success",
            title: "Convenio Solicitado",
            confirmButtonText: "Aceptar",
            text: `Convenio ${data.name}`,
          }).then((result) => {
            if (!!executeThisAfter) {
              executeThisAfter();
            }
          });
        } else {
          MySwal.fire({
            icon: "error",
            title: "Intenta de nuevo",
            text: `${res.message}`,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        MySwal.fire({
          icon: "error",
          title: "Intenta de nuevo",
          text: `error al crear convenio`,
        });
      });
  };
