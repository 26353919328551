import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { saveAccount, selectAccount } from "../../actions/loginActions";
import Arrow from "../../assets/img/icons/Arrow-gray.svg";
import { loader } from "../../helpers/helpers";
import { LOGOUT_SUCCESS, USER_LOGIN_SUCCESS } from './../../actions/actionTypes';
import myStyles from "./AccountSelect.module.css";

function AccountSelectRender(props) {
  const history = useHistory();
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(0);
  const [rolArtControl, setrolArtControl] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const onRedirectLogin = () => {
    dispatch({
      type: LOGOUT_SUCCESS
    });

    history.push("/login");
  };
  useEffect(() => {
    setLoading(true);
    dispatch(selectAccount(counter.loginReducer.user_data.id, onRedirectLogin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const handleClick = () => {
    if (!!props.afterClick) {
      history.push("/categoryselect");
      props.afterClick();
    } else {
      history.push("/categoryselect");
    }
  };


  const toggleInfo = (acc, index) => {
    if (show && index === rolArtControl) {
      setShow(false);
      setrolArtControl(null);
    } else {
      changeInfoAccountInPayroll(acc.id);
      setrolArtControl(index);
      setShow(true);
    }
  };

  const renderRol = (account) => {

    if (Array.isArray(account.profile)) {
      return account?.profile.map((rol) => {
        return (
          <div
            key={rol.id}
            className={`${myStyles.flex2} ${myStyles.containerChildren}`}
            onClick={() => {
              dispatch(
                saveAccount(
                  {
                    ...account,
                    profile: rol,
                  },
                  handleClick
                ));
            }}
          >
            <span
            >{rol?.nombre?.toUpperCase()}</span>
          </div>
        );
      });
    } else {
      return <div
        key={account.profile.id}
        className={`${myStyles.flex2} ${myStyles.containerChildren}`}
        onClick={() => {
          dispatch(
            saveAccount({ ...account }, handleClick)
          );
        }}
      >
        <span>{account.profile.nombre}</span>
      </div>;
    }
  };
  const changeInfoAccountInPayroll = (id) => {
    if (!!counter?.loginReducer?.user_data?.payrollInfo) {
      let tempObj = counter?.loginReducer?.user_data;
      if (Array.isArray(counter?.loginReducer?.user_data?.payrollInfo)) {
        tempObj.accountsRelated = counter?.loginReducer?.user_data?.payrollInfo;
        let payrollInfo = counter?.loginReducer?.user_data?.payrollInfo?.find((e) => e.entity_account === id) ?? {};
        tempObj.payrollInfo = payrollInfo;
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: tempObj,
          Authorization: token
        });
      } else {
        let payrollInfo = counter?.loginReducer?.user_data?.accountsRelated?.find((e) => e.entity_account === id) ?? {};
        tempObj.payrollInfo = payrollInfo;
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: tempObj,
          Authorization: token
        });
      }
    }
  };
  const renderAccounts = () => {
    const accounts = counter?.loginReducer?.accounts?.length === 0 && counter?.loginReducer?.loading ? undefined : counter?.loginReducer?.accounts;
    if (!!accounts === false || !!counter.loginReducer.error) {
      return <div key={0} className={myStyles.flex1} onClick={() => setTrigger(trigger + 1)}>
        <p>Error de Conexión</p>
      </div>;
    }


    if (accounts.length === 0) {
      return <div key={0} className={myStyles.flex1} >
        <p>Este usuario no tiene cuentas accesibles</p>
      </div>;
    }


    return accounts.map((acc, index) => {
      if (accounts.length - 1 === index) {
        if (loading) {
          setLoading(false);
        }
      }
      return (
        <div className="row" style={{ height: "max-content" }}>
          <div
            key={acc.id}
            className={`${myStyles.flex1} col-5`}
            onClick={() => toggleInfo(acc, index)}
          >
            <p className={`my-auto ${myStyles.classTitle}`}>{acc.name}</p>
            <img alt={acc.name} src={Arrow} style={{ width: "15px" }} />
          </div>
          <div className={`col-5 ${accounts[rolArtControl || 0]?.profile?.length === 1 ? 'align-items-center d-flex' : ''}`}>
            {(index === rolArtControl) && show ?
              <div className={`col-3 ${myStyles.containerInter} my-auto mx-2 animate__animated animate__fadeIn`}>
                {(index === rolArtControl) && renderRol(acc)}
              </div>
              : ""}
          </div>

        </div>
      );
    });

  };



  return (
    <div className={myStyles.accountBoxWrap}>
      {(loading) && loader}
      {renderAccounts()}
    </div>
  );
}

AccountSelectRender.propTypes = {
  afterClick: PropTypes.func
};


export default AccountSelectRender;