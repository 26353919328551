/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Shedule from "./schedule";
import { Card, Tooltip } from "react-bootstrap";
import { isEmptyOrUndefined, loader } from "../../../helpers/helpers";
import { useGetMethod } from "../../../Hooks/useFetch";
import styles from "../../../components/Layouts/tableStyle.module.scss";
import alert from "../../../assets/img/icons/alert-circle-auxiliary-red.svg";
import { OrdGenericTemplate } from "../../../OrderingModule/OrderingComponents/OrdGenericTemplate";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLocation } from 'react-router';

const Programming = ({
  patient,
  token,
  order,
  options,
  setOptions,
  procedure,
  detailPatient,
  detailLoaderPatients,
  ListPacientLoader,
  postTicketFunction,
  setTriggerTicket,
  triggerTicket,
  setTicketValue,
}) => {
  const store = useSelector((state) => state);
  const idEaccount = store.loginReducer.currentAccount.id;
  const MySwal = withReactContent(Swal);
  const [selectProgramming, setSelectProgramming] = useState({
    serviceId: order.serviceId,
    insurer: {},
  });
  const location = useLocation()
  const [infoPatient, setInfoPatient] = useState(location?.state?.infoPatient && location?.state.infoPatient)
  const [continueInsurerRequired, setContinueInsurerRequired] = useState(false)
  
  const dispatch = useDispatch();
  // const history = useHistory();

  // const {
  //   results: detailPatient,
  //   load: detailLoaderPatient,
  //   trigger: getDetailPatient,
  // } = useGetMethod();

  const {
    results: webScrapper,
    load: loaderWebScrapper,
    trigger: getWebScrapper,
  } = useGetMethod();

  useEffect(() => {
    // getDetailPatient({
    //   url: "/medical/patient/",
    //   objFilters: { id: detailPatient.results.id, eaccount: idEaccount },
    //   token: token,
    // });
    procedure?.contracts && getInsurer(procedure?.contracts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setContinueInsurerRequired(false)
  }, []);

  const getInsurer = (insurer) => {
    // postTicketFunction("insurance") //para cuando toque esta vaina
    setSelectProgramming({
      ...selectProgramming,
      insurer: insurer,
    });

    if (
      insurer?.companyTypeTag === "eps" ||
      insurer?.companyTypeTag === "special_agreement"
    ) {
      getWebScrapper({
        url: "/medical/appointment/validateInfoWebscrapper/",
        objFilters: {
          doc: detailPatient?.results?.document,
          entityType: insurer?.companyTypeTag,
          docType: detailPatient?.results?.docType,
          docTypeId: detailPatient?.results?.docTypeId,
          entityId: insurer?.corporateClientId,
          id: patient.id || store?.patientActiveReducer?.id,
          eaccount: idEaccount,
        },
        token: token,
        doAfterSuccess: (result) => {
          
          if (result?.success === true && result?.errorWS === false) {
            setOptions({
              ...options,
              showProgramming: true,
              showShedule: true,
            });
          } else {
            MySwal.fire({
              icon: "info",
              title: result.message,
              text: !!result?.title ? result.title : "",
              confirmButtonText: "Aceptar",
              
            })
          }
        },
        doAfterException: (error) => {

          MySwal.fire({
            icon: "info",
            title: error.message,
            html: !!error?.title
              ? `Ingrese aquí <a href="${error?.title}" target="_blank">URL</a> para validar los derechos de afiliación del usuario`
              : "",
            confirmButtonText: "Continuar",
            cancelButtonText:"Cancelar",
            showCancelButton: true
          }).then((response)=>{
            if(response.isConfirmed){
              
              setContinueInsurerRequired(true)
            }
          })
        },
      });
    } else {
      setOptions({
        ...options,
        showProgramming: true,
        showShedule: true,
      });
    }
  };

  const goBackFunction = () => {
    if (options.showShedule) {
      setOptions({ ...options, showShedule: false });
    } else if (!options.showSchedule) {
      setOptions({ ...options, showCards: true, showProgramming: false });
    }

    dispatch({
      type:'CHECKED_FILTER_CHECK_TELE',
      payload: false
    })
  };


  useEffect(()=>{
    if(!options.showShedule){

      dispatch({
        type:'ACTIVE_FILTER_CHECK_TELE',
        payload: false
      })
    }
  },[options])
      


  const renderTooltipAlert = () => (
    <Tooltip className={`${styles.tooltipAlert} tooltipAlert`}>
      Creada manualmente, falla del Webcraping.{" "}
    </Tooltip>
  );
  
  return (
    <>
      {(
        detailLoaderPatients ||
        ListPacientLoader ||
        loaderWebScrapper) &&
        loader}

      <div
        style={{
          width: "100%",
          backgroundColor: "#F6FEFF",
          overflow: "auto",
          height: "inherit",
        }}
      >
        
        <div className={styles.app_container_programming}>
          <OrdGenericTemplate
            showBackArrow={true}
            backArrowAction={() => goBackFunction()}
            titleStyle={{}}
            titleSize="8"
            filterSwitch={{
              active: store.filterCheckTeleScheduleReducer.active,
              size:4,
              checked: store.filterCheckTeleScheduleReducer.checked,
              label:'Telemedicina',
              onChange:(e)=>{
                
                dispatch({
                  type:'CHECKED_FILTER_CHECK_TELE',
                  payload: (!store.filterCheckTeleScheduleReducer.checked)
                })
              }
            }}
            title={"Programación"}
          >
            {/* <span className={styles.app_title_programming}>Programación</span> */}
            {(options.showShedule || continueInsurerRequired ) ? (
              <>
                <Card className={`${styles.app_card_custom_shedule} mt-2`}>
                  <div>
                    <span class={styles.app_title_order}>
                      {selectProgramming?.insurer.isMain === 0 &&
                        selectProgramming?.insurer.isEnabled === 1
                        ? "Aseguradora Voluntaria"
                        : "Aseguradora Obligatoria"}
                    </span>
                  </div>
                  <div className={styles.app_container_card_ord}>
                    <div className={styles.app_container_info_card}>
                      {!isEmptyOrUndefined(
                        selectProgramming.insurer?.companyTypeName
                      ) && (
                          <div className="d-flex" style={{ gap: "1rem" }}>
                            <span
                              className={`col-6 ${styles.app_title_card_programming}`}
                            >
                              Tipo de entidad
                            </span>
                            <span
                              className={`col-6 ${styles.app_subtitle_card_order}`}
                            >
                              {selectProgramming.insurer.companyTypeName
                                ? selectProgramming.insurer.companyTypeName
                                : "-"}
                            </span>
                          </div>
                        )}

                      {!isEmptyOrUndefined(
                        selectProgramming.insurer?.corporateClient
                      ) && (
                          <div className="d-flex" style={{ gap: "1rem" }}>
                            <span
                              className={`col-6 ${styles.app_title_card_programming}`}
                            >
                              Entidad
                            </span>
                            <span
                              className={`col-6 ${styles.app_subtitle_card_order}`}
                            >
                              {selectProgramming.insurer.corporateClient}
                            </span>
                          </div>
                        )}
                      {!isEmptyOrUndefined(
                        selectProgramming.insurer?.contractName
                      ) && (
                          <div className="d-flex" style={{ gap: "1rem" }}>
                            <span
                              className={`col-6 ${styles.app_title_card_programming}`}
                            >
                              Contrato
                            </span>
                            <span
                              className={`col-12 ${styles.app_subtitle_card_order}`}
                            >
                              {selectProgramming.insurer?.contractName}
                            </span>
                          </div>
                        )}
                      {!isEmptyOrUndefined(
                        selectProgramming.insurer?.population
                      ) && (
                          <div className="d-flex" style={{ gap: "1rem" }}>
                            <span
                              className={`col-6 ${styles.app_title_card_programming}`}
                            >
                              Población
                            </span>
                            <span
                              className={`col-9 ${styles.app_subtitle_card_order}`}
                            >
                              {selectProgramming.insurer?.population}
                            </span>
                          </div>
                        )}
                      {!isEmptyOrUndefined(
                        selectProgramming.insurer?.crtName
                      ) && (
                          <div className="d-flex" style={{ gap: "1rem" }}>
                            <span
                              className={`col-6 ${styles.app_title_card_programming}`}
                            >
                              Grupo de ingreso
                            </span>
                            <span
                              className={`col-9 ${styles.app_subtitle_card_order}`}
                            >
                              {selectProgramming.insurer?.crtName}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </Card>
                {/* ANCHOR schedule */}
                <Shedule
                  token={token}
                  contractId={selectProgramming?.insurer?.contractId}
                  corporateClientId={
                    selectProgramming?.insurer?.corporateClientId
                  }
                  populationId={selectProgramming?.insurer?.populationId}
                  procedure={procedure}
                  detailPatient={detailPatient}
                  companyType={selectProgramming?.insurer?.companyTypeId}
                  popId={selectProgramming?.insurer?.populationId}
                  wsValidated={selectProgramming?.insurer?.wsValidated}
                  crtName={selectProgramming?.insurer?.crtName}
                  attGroupId={selectProgramming?.insurer?.attGroupId}
                  postTicketFunction={postTicketFunction}
                  setTriggerTicket={setTriggerTicket}
                  triggerTicket={triggerTicket}
                  setTicketValue={setTicketValue}
                  insureSelected={selectProgramming?.insurer}
                  goBackFunction={goBackFunction}
                />
              </>
            ) : (
              <>
                {detailPatient?.results?.contracts.map((item, index) => {
                  if (item.isMain === 0 && item.isEnabled === 1) {
                    return (
                      <Card
                        className={styles.app_card_custom_programming}
                        key={index}
                        onClick={() => {
                          postTicketFunction("insurance");
                          getInsurer(item);
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <span class={styles.app_title_order}>
                            Aseguradora Voluntaria
                          </span>
                          {item.wsValidated === 1 && (
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltipAlert()}
                              style={{ backgroundColor: "#F39682" }}
                            >
                              <img alt="alert" src={alert} />
                            </OverlayTrigger>
                          )}
                        </div>
                        {item.companyTypeTag === "special_agreement" ? (
                          <>
                            <div
                              key={index}
                              className={styles.app_container_card_ord}
                            >
                              <div className={`${styles.app_container_info_card} w-100`}>
                                <div className="d-flex" style={{ gap: "1rem" }}>
                                  <span
                                    className={`col-6 ${styles.app_title_card_programming}`}
                                  >
                                    Tipo de entidad
                                  </span>
                                  <span
                                    className={`col-6 ${styles.app_subtitle_card_order}`}
                                  >
                                    {item.companyTypeName
                                      ? item.companyTypeName
                                      : "-"}
                                  </span>
                                </div>
                                <div className="d-flex" style={{ gap: "1rem" }}>
                                  <span
                                    className={`col-6 ${styles.app_title_card_programming}`}
                                  >
                                    Entidad
                                  </span>
                                  <span
                                    className={`col-6 ${styles.app_subtitle_card_order}`}
                                  >
                                    {item.corporateClient
                                      ? item.corporateClient
                                      : "-"}
                                  </span>
                                </div>
                                <div className="d-flex" style={{ gap: "1rem" }}>
                                  <span
                                    className={`col-6 ${styles.app_title_card_programming}`}
                                  >
                                    Contrato
                                  </span>
                                  <span
                                    className={`col-12 ${styles.app_subtitle_card_order}`}
                                  >
                                    {item.contractName
                                      ? item.contractName
                                      : "-"}
                                  </span>
                                </div>
                                <div className="d-flex" style={{ gap: "1rem" }}>
                                  <span
                                    className={`col-6 ${styles.app_title_card_programming}`}
                                  >
                                    Población
                                  </span>
                                  <span
                                    className={`col-9 ${styles.app_subtitle_card_order}`}
                                  >
                                    {item.population ? item.population : "-"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              key={index}
                              className={styles.app_container_card_ord}
                            >
                              <div className={`${styles.app_container_info_card} w-100`}>
                                <div className="d-flex" style={{ gap: "1rem" }}>
                                  <span
                                    className={`col-6 ${styles.app_title_card_programming}`}
                                  >
                                    Tipo de entidad
                                  </span>
                                  <span
                                    className={`col-6 ${styles.app_subtitle_card_order}`}
                                  >
                                    {item.companyTypeName
                                      ? item.companyTypeName
                                      : "-"}
                                  </span>
                                </div>
                                <div className="d-flex" style={{ gap: "1rem" }}>
                                  <span
                                    className={`col-6 ${styles.app_title_card_programming}`}
                                  >
                                    Entidad
                                  </span>
                                  <span
                                    className={`col-6 ${styles.app_subtitle_card_order}`}
                                  >
                                    {item.corporateClient
                                      ? item.corporateClient
                                      : "-"}
                                  </span>
                                </div>
                                <div className="d-flex" style={{ gap: "1rem" }}>
                                  <span
                                    className={`col-6 ${styles.app_title_card_programming}`}
                                  >
                                    Contrato
                                  </span>
                                  <span
                                    className={`col-12 ${styles.app_subtitle_card_order}`}
                                  >
                                    {item.contractName
                                      ? item.contractName
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Card>
                    );
                  }
                })}
                {detailPatient?.results?.contracts.map((item, index) => {
                  if (item.isMain === 1 && item.isEnabled === 1) {
                    return (
                      <Card
                        className={styles.app_card_custom_programming}
                        key={index}
                        onClick={() => {
                          postTicketFunction("insurance");
                          getInsurer(item);
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <span class={styles.app_title_order}>
                            Aseguradora Obligatoria
                          </span>
                          {item.wsValidated === 1 && (
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltipAlert()}
                            >
                              <img alt="alert" src={alert} />
                            </OverlayTrigger>
                          )}
                        </div>
                        <div
                          key={index}
                          className={styles.app_container_card_ord}
                        >
                          <div className={styles.app_container_info_card}>
                            <div className="d-flex" style={{ gap: "1rem" }}>
                              <span
                                className={`col-9 ${styles.app_title_card_programming}`}
                              >
                                Entidad
                              </span>
                              <span
                                className={`col-6 ${styles.app_subtitle_card_order}`}
                              >
                                {item.corporateClient
                                  ? item.corporateClient
                                  : "-"}
                              </span>
                            </div>
                            <div className="d-flex" style={{ gap: "1rem" }}>
                              <span
                                className={`col-9 ${styles.app_title_card_programming}`}
                              >
                                Contrato
                              </span>
                              <span
                                className={`col-9 ${styles.app_subtitle_card_order}`}
                              >
                                {item.contractName
                                  ? item.contractName
                                  : "-"}
                              </span>
                            </div>
                            <div className="d-flex" style={{ gap: "1rem" }}>
                              <span
                                className={`col-9 ${styles.app_title_card_programming}`}
                              >
                                Población
                              </span>
                              <span
                                className={`col-9 ${styles.app_subtitle_card_order}`}
                              >
                                {item.population ? item.population : "-"}
                              </span>
                            </div>
                            <div className="d-flex" style={{ gap: "1rem" }}>
                              <span
                                className={`col-9 ${styles.app_title_card_programming}`}
                              >
                                Grupo de ingreso
                              </span>
                              <span
                                className={`col-6 ${styles.app_subtitle_card_order}`}
                              >
                                {item.crtName ? item.crtName : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  }
                })}
              </>
            )}
          </OrdGenericTemplate>
        </div>
      </div>
    </>
  );
};

export default Programming;
