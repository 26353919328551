import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
const HelpDesk = () => {
  const history = useHistory();
  useEffect(
    () => {
      const script1 = document.createElement("script");
      const script2 = document.createElement("script");
      const scriptBsop = location => {
        script1.src = "https://ayuda.ttncompany.com/assest/js/bsop.js";
        document.body.appendChild(script1);
        script2.src = "https://code.jquery.com/jquery-3.6.0.min.js";
        document.body.appendChild(script2);
      };
      if(!(window.location.pathname === "/login" || window.location.pathname === "/")){
        scriptBsop()
      }
      const handleLocationChange = location => {
        const event = new Event("hashchange");
        window.dispatchEvent(event);
      };
      const unlisten = history.listen(handleLocationChange);
      const unlistenScript = history.listen(scriptBsop);

      return () => {
        unlisten();
        unlistenScript();
        document.body.removeChild(script1);
        document.body.removeChild(script2);
      };
    },
    [history]
  );
  return <div id="bsop" class="bsop-main-biowel" />;
};

export default HelpDesk;
