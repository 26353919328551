import React, { useState, useEffect } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import iconBack from "../../assets/img/icons/iconBack.svg";
import Excel from "../../assets/img/icons/excel.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button } from "react-bootstrap";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import { getOneBalance } from "../../actions/receiptOfInvoicesActions";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import PreLegal from "./PrelegalCollection";
import Style from "./balanceStyle.module.scss";
import { isEmptyOrUndefined } from "../../helpers/helpers";
import { GET_ONE_BALANCE } from "../../actions/actionTypes";
import Loader from "react-loader-spinner";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";

function CompanyBalance(props) {
  const storage = useSelector((state) => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [selectedList, setselectedList] = useState([]);

  const [showReceipt, setshowReceipt] = useState({
    show: false,
  });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    id: props.client_id,
    orderByField: "",
    orderByType: "",
    contractName: "",
    balanceOrder: "",
    invoiceSeq: "",
    eaccount: storage.loginReducer.currentAccount.id,
  });

  ///////// Logic of selects ////////////////////////////7

  const isBoxSelected = (invoice_id) => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find((x) => x.id === invoice_id);
      return !!target;
    }
  };

  const changeBoxSelected = (invoice) => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find((x) => x.id == invoice.id);
      if (!!target) {
        tempSelected = tempSelected.filter((x) => x.id != invoice.id);
      } else {
        let newChange = {
          ...invoice,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };
  const isBoxAllSelected = () => {
    let res = true;
    let counting = 0;
    if (
      Array.isArray(storage.invoiceReducer.one_balance.detail) &&
      Array.isArray(selectedList)
    ) {
      storage.invoiceReducer.one_balance.detail.map((item) => {
        if (item.filing_date !== null) {
          if (selectedList.find((itemb) => (item.id == itemb.id))) {
            counting++;
          } else {
            res = false;
          }
        }
      });
    }
    return res;
  };

  const changeBoxAllSelected = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [...selectedList];
      if (
        Array.isArray(storage.invoiceReducer.one_balance.detail) &&
        Array.isArray(tempSelected)
      ) {
        storage.invoiceReducer.one_balance.detail.map((x) => {

          if (x.filing_date !== null) {
            if (selectedList.find((itemb) => (x.id == itemb.id))) {

            } else {
              let newChange = {
                ...x,
              };
              tempSelected.push(newChange);

            }
          }

        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };

  useEffect(() => {
    // if (storage.invoiceReducer?.one_balance?.client !== "") {
    //   setIsLoading(false);
    // } else {
    //   setIsLoading(true);
    // }

    dispatch({
      type: GET_ONE_BALANCE,
      results: "",
      total: 0,
    });

    dispatch(getOneBalance(filters));
  }, [trigger, filters.page, dispatch]);

  const header = [
    <th className="text-center" style={{ paddingLeft: "0.5rem" }}>
      <input
        type="checkbox"
        className="border border-dark-blue form-check-input p1 check-dark-blue"
        style={{ width: "14px", height: "14px" }}
        name={"selectedAll"}
        value="selectedAll"
        checked={isBoxAllSelected()}
        onChange={(e) => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th className="text-center">No. Factura </th>,
    <th className="text-center">Estado</th>,
    <th className="text-center">Contrato</th>,
    <th className="text-center">Fecha Factura</th>,
    <th className="text-center">Valor Factura</th>,
    <th className="text-center">Saldo factura</th>,
    <th className="text-center">F. Radicado</th>,
    <th className="text-center">F. Vencimiento</th>,
    <th className="text-center">Vencimiento</th>,
    <th className="text-center">Abonos</th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.one_balance.detail)) {
      table = storage.invoiceReducer.one_balance.detail.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">
            <td className="text-center" style={{ paddingLeft: "0.5rem" }}>
              {/* {x.filing_date!== null? */}
              <input
                type="checkbox"
                className={!!x.filing_date ? "border border-dark-blue form-check-input p1 check-dark-blue" : "border border-clear-grey form-check-input p1"}
                style={{ width: "14px", height: "14px" }}
                name=""
                id=""
                checked={isBoxSelected(x.id)}
                onChange={(e) => changeBoxSelected(x)}
                disabled={!!x.filing_date ? false : true}

              />
              {/* :
              <span></span>
              } */}
            </td>
            <td className="text-uppercase">{x.sequence}</td>
            <td
              className="text-center"
              style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
            >
              <div
                className="rounded-pill p-1"
                style={{ backgroundColor: `${x.background}` }}
              >
                <b style={{ color: `${x.fontcolor}` }}>{x.status}</b>
              </div>
            </td>
            <td className="text-start">{x.contract_name}</td>
            <td>
              {!!x?.date
                ? x.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")
                : ""}
            </td>
            <td className="text-end">{"$" + numberWithCommas(x.amount)}</td>
            <td className="text-end">
              {"$" + numberWithCommas(x.pending_amount)}
            </td>
            <td>
              {!!x?.filing_date
                ? x.filing_date.replace(
                  /^(\d{4})-(\d{2})-(\d{2})$/g,
                  "$3/$2/$1"
                )
                : ""}
            </td>
            <td>
              {!!x?.due_date
                ? x.due_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")
                : ""}
            </td>
            <td>{x.expiration_period}</td>
            <td>
              {"$" +
                numberWithCommas(Number(x.amount) - Number(x.pending_amount))}
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  let optionsBalance = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
    { key: "default1", value: "DESC", label: "Mayor saldo", id: "1" },
    { key: "default2", value: "ASC", label: "Menor saldo", id: "2" },
  ];

  let optionsRel = [
    { key: "default", value: "", label: "Seleccionar...", id: "" },
    { key: "default1", value: "DESC", label: "Mas antigua", id: "1" },
    { key: "default2", value: "ASC", label: "Mas reciente", id: "2" },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };


  const principalRender = (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >
      <div className="d-flex" style={{ marginTop: '40px', alignItems: 'end' }}>
        
        <img 
          style={{ height: '43px', paddingBottom: '10px' }} 
          className={`mr-3 cursorPointer`} 
          src={iconBack} 
          onClick={() => props.setDetails({ show: false })}
        />

        {!!storage.invoiceReducer.loading_get_one_balance && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        )}
        <h1 className={tableStyles.title} style={{ margin: '0px' }}>Saldo empresa</h1>
      </div>

      <div className="bg-inputs">
        <div className="div">
          <div className="col-6 display-grid" style={{ paddingRight: "1rem" }}>
            <label className={tableStyles.crudModalLabel}>Empresa</label>
            <input
              className={`${IndividualStyles.registerInputsGris} ${tableStyles.f12}`}
              style={{ background: "#F5F7FA", fontSize: "13px" }}
              type="text"
              value={
                storage.invoiceReducer.one_balance
                  ? storage.invoiceReducer.one_balance.client
                  : ""
              }
            ></input>
          </div>
          <div className="col-2 display-grid" style={{ paddingRight: "1rem" }}>
            <label className={tableStyles.crudModalLabel}>
              Deudas por cobrar
            </label>
            <input
              className={`${IndividualStyles.registerInputsGris} ${tableStyles.f12}`}
              style={{ background: "#F5F7FA", fontSize: "13px" }}
              type="text"
              value={
                storage.invoiceReducer.one_balance?.pending
                  ? "$" +
                  numberWithCommas(storage.invoiceReducer.one_balance.pending)
                  : ""
              }
            ></input>
          </div>
          <div className="col-2 display-grid">
            <label className={tableStyles.crudModalLabel}>
              Valor anticipado
            </label>
            <input
              className={`${IndividualStyles.registerInputsGris} ${tableStyles.f12}`}
              style={{ background: "#F5F7FA", fontSize: "13px" }}
              type="text"
              value={
                storage.invoiceReducer.one_balance?.anticipated
                  ? "$" +
                  numberWithCommas(
                    storage.invoiceReducer.one_balance.anticipated
                  )
                  : ""
              }
            ></input>
          </div>
        </div>
        <div className="div">
          <div className="col-3 display-grid">
            <label className={tableStyles.crudModalLabel}>
              Saldo de cartera
            </label>
            <input
              className={`${IndividualStyles.registerInputsGris} ${tableStyles.f12}`}
              style={{ background: "#F5F7FA", fontSize: "13px" }}
              type="text"
              value={
                storage.invoiceReducer.one_balance?.sum_pending
                  ? "$" +
                  numberWithCommas(
                    storage.invoiceReducer.one_balance.sum_pending
                  )
                  : ""
              }
            ></input>
          </div>
          <div
            className="col-3 display-grid"
            style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
          >
            <label className={tableStyles.crudModalLabel}>Valor vencido</label>
            <input
              className={`${IndividualStyles.registerInputsGris} ${tableStyles.f12}`}
              style={{ background: "#F5F7FA", fontSize: "13px" }}
              type="text"
              value={
                storage.invoiceReducer.one_balance?.expired
                  ? "$" +
                  numberWithCommas(storage.invoiceReducer.one_balance.expired)
                  : ""
              }
            ></input>
          </div>
          <div className="col-2 display-grid" style={{ paddingRight: "1rem" }}>
            <label className={tableStyles.crudModalLabel}>
              Valor por vencer
            </label>
            <input
              className={`${IndividualStyles.registerInputsGris} ${tableStyles.f12}`}
              style={{ background: "#F5F7FA", fontSize: "13px" }}
              type="text"
              value={
                storage.invoiceReducer.one_balance?.not_due
                  ? "$" +
                  numberWithCommas(storage.invoiceReducer.one_balance.not_due)
                  : ""
              }
            ></input>
          </div>
        </div>
      </div>
      <div className="div gap-3 align-items-end">
        <div className={tableStyles.containerSelect}>
          <form onSubmit={(e) => handleSearch(e)}>
            <label className={tableStyles.stylesLabel}>No. Factura</label>
            <input
              className={IndividualStyles.registerInputs}
              type="text"
              value={filters.invoiceSeq}
              style={{ color: "#58595B" }}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  invoiceSeq: e.target.value,
                })
              }
              placeholder={"Escribe..."}
            ></input>
          </form>
        </div>

        <div className={tableStyles.containerSelect}>
          <form onSubmit={(e) => handleSearch(e)}>
            <label className={tableStyles.stylesLabel}>Contrato</label>
            <input
              className={IndividualStyles.registerInputs}
              type="text"
              value={filters.contractName}
              style={{ color: "#58595B" }}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  contractName: e.target.value,
                })
              }
              placeholder={"Escribe..."}
            ></input>
          </form>
        </div>

        <div className={tableStyles.containerSelect}>
          <form onSubmit={(e) => handleSearch(e)}>
            <label className={tableStyles.stylesLabel}>Saldo</label>
            <Select noOptionsMessage={() => 'No hay datos'}
              options={optionsBalance}
              placeholder="Seleccionar..."
              onChange={(e) =>
                setFilters({
                  ...filters,
                  balanceOrder: e.value,
                })
              }
              styles={customSelectNewDark}
              name="id_client"
            />
            {/* <select
            className={IndividualStyles.selectRegister}
            onChange={(e) =>
              setFilters({
                ...filters,
                balanceOrder: e.target.value,
              })
            }
            placeholder={"Seleccionar..."}
          >
            <option value={""}></option>
            <option value={"DESC"}>Mayor saldo</option>
            <option value={"ASC"}>Menor saldo</option>
          </select> */}
          </form>
        </div>

        <div className={tableStyles.containerSelect}>
          <form onSubmit={(e) => handleSearch(e)}>
            <label className={tableStyles.stylesLabel}>Relevancia</label>
            <Select noOptionsMessage={() => 'No hay datos'}
              options={optionsRel}
              placeholder="Seleccionar..."
              onChange={(e) =>
                setFilters({
                  ...filters,
                  orderByType: e.value,
                })
              }
              styles={customSelectNewDark}
              name="id_rel"
            />
            {/* <select
            className={IndividualStyles.selectRegister}
            onChange={(e) =>
              setFilters({
                ...filters,
                orderByType: e.target.value,
              })
            }
            placeholder={"Seleccionar..."}
          >
            <option value={""}>-Seleccione-</option>
            <option value={"ASC"}>Mas antigua</option>
            <option value={"DESC"}>Mas reciente</option>
          </select> */}
          </form>
        </div>
        {!isEmptyOrUndefined(filters.orderByType) && (
          <div className={tableStyles.containerSelect} >

            <label className={tableStyles.crudModalLabel}>Por fecha de </label>
            <form onSubmit={(e) => handleSearch(e)}>
              <select
                className={IndividualStyles.selectRegister}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    orderByField: e.target.value,
                  })
                }
                placeholder={"Seleccionar..."}
              >
                <option value={""}>Seleccionar...</option>
                <option value={"invoice_date"}>Fecha de factura</option>
                <option value={"filing_date"}>Fecha de radicado</option>
              </select>
            </form>
          </div>
        )}

        <div>
          <i>
            <img
              style={{ marginTop: "30px" }}
              src={Lupa}
              alt=""
              srcset=""
              onClick={() => {
                setTrigger(trigger + 1);
                setFilters({ ...filters, page: 1 });
              }}
            />
          </i>
        </div>

        <div className="text-end" style={{ marginLeft: 'auto' }}>
          <i>
            <img style={{ marginTop: "30px" }} src={Excel} alt="" srcset="" />
          </i>
        </div>
      </div>
      <GenericTable headers={header} dark={true}>
        {renderList()}
      </GenericTable>

      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {storage.invoiceReducer.one_balance ? filters.page : ""}
            {" de "}
            {Math.ceil(
              storage.invoiceReducer?.one_balance_total / filters.perpage
            )
              ? Math.ceil(
                storage.invoiceReducer.one_balance_total / filters.perpage
              )
              : ""}{" "}
            ({storage.invoiceReducer.one_balance_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={storage.invoiceReducer.one_balance_total}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>

      <div
        className={IndividualStyles.bottomRow}
        style={{ marginBottom: "40px" }}
      >
        <Button
          className={Style.btnPrimaryDark}
          onClick={() => setshowReceipt({ show: true })}
          disabled={!!selectedList.length > 0 ? false : true}
        >
          Cobrar
        </Button>
        <Button
          className={Style.btnSecondary}
          onClick={() => setselectedList([])}
          disabled={!!selectedList.length > 0 ? false : true}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );

  return !!showReceipt.show ? (
    <PreLegal
      invoices={selectedList}
      show={showReceipt.show}
      setshowReceipt={setshowReceipt}
      setDetails={props.setDetails}
    />
  ) : (
    principalRender
  );
}
export default CompanyBalance;
