// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useState, useEffect, useMemo } from "react";
import GenericTableNew from "../../components/Layouts/GenericTableNew";
import CustomPopupExtend from "../../components/Popup/customPopUpExtends"
import Pagination from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  customSelectNewDark, customSelectNewDark2,
} from "../../components/Layouts/react-select-custom";
import { Tooltip } from "@material-ui/core";
import NewContract from "./NewContract";
import ModalNew from "../../components/Layouts/ModalNew";
import { MAX_SIZE_FILE } from "../../helpers/constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Dropzone from 'react-dropzone';


// --------------- icons 💥  ----------------//
import lupa from "../../assets/img/icons/lupa.svg";
import CheckMark from "../../assets/img/icons/CheckMark.svg";
import Circle from "../../assets/img/icons/circleGreen.svg";
import agregarEspecialidad from "../../assets/img/icons/agregarEspecialidad.svg";
import Attach from "../../assets/img/icons/insertar.svg";
import Excel from "../../assets/img/icons/excel.svg";
import Word from "../../assets/img/icons/word.svg";
import PDF from "../../assets/img/icons/pdf.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";

//-------------- scss styles 😄   --------------
import styles from "./contractClientsStyles.module.scss";

import paginationStyles from "../../components/Layouts/pagination.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";



//-------------- functions 🍕   --------------
import {
  getStatusForHistory,
  getTypesContracts,
  getListContracts,
  sendSign,
  changeStatusContract

} from "../../actions/billingActions";
import Loader from "react-loader-spinner";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import { getPermission } from "../../helpers/helpers";

//-------------- consts 🍕   --------------
const type_file = "csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, " +
  "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, " +
  "application/pdf"
const MULTIPLE_REGEX_VALID_FILE = /^.+\.(([pP][dD][fF]))|(xlsx|xls|csv)|(doc|docx)$/;



function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const MySwal = withReactContent(Swal);
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export function ClientContract(props) {
  const storage = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);
  const dispatch = useDispatch();
  const listPermission = storage.loginReducer.currentAccount?.profile?.permission
  const myPermission = getPermission({ prefix: 'contractCl', listPermission })

  const [details, setDetails] = useState({
    show: false,
  });
  const MySwal = withReactContent(Swal);
  const [filenameState, setfilenameState] = useState({
    file64: "",
    fileName: "",
    type_doc: ""
  });
  const [modalDoc, setModalDoc] = useState({
    show: false,
    id: ""
  });
  const [loading, setLoading] = useState(true);

  const initialStateFilters = useMemo(
    () => ({
      page: 1,
      perpage: 10,
      contractType: undefined,
      status: '',
      search: '',
      eaccount: storage.loginReducer.currentAccount.id,
      ungroup: true
    }),
    [storage.loginReducer.currentAccount.id],
  );

  const [filters, setFilters] = useState(initialStateFilters);

  //--------------initial functions 🍕   --------------
  useEffect(() => {
    dispatch(getStatusForHistory({ eaccount: storage.loginReducer.currentAccount.id, type: "contract" }));
    dispatch(getTypesContracts({ eaccount: storage.loginReducer.currentAccount.id }));
  }, []);

  useEffect(() => {
    dispatch(getListContracts(filters, () => setLoading(false)));
  }, [trigger, filters.page, filters.contractType, filters.status]);

  //-------------- documents 🍕   --------------

  const validateFile = async (acceptedFile) => {
    console.log("acceptedFile: ", acceptedFile);
    let size = acceptedFile[0]?.size
    if (size > MAX_SIZE_FILE) {
      MySwal.fire({
        icon: 'error',
        title: "El archivo es demasiado pesado",
        footer: "El tamaño máximo permitido es de 5MB"
      })
    } if (new RegExp(MULTIPLE_REGEX_VALID_FILE).test(acceptedFile.type)) {
      MySwal.fire({
        icon: 'error',
        title: "El formato del archivo debe ser PDF, WORD, EXCEL",
        footer: "El tamaño máximo permitido es de 5MB"
      })
    }
  }

  const docType = (data) => {
    let iconDoc
    if (!!data) {
      switch (data) {
        case "application/pdf":
          iconDoc = PDF
          break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/msword":
          iconDoc = Word
          break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "csv" || "application/vnd.ms-excel":
          iconDoc = Excel
          break;
        default:
          iconDoc = PDF
          break;
      }
    }
    return iconDoc;
  }

  const onChangeDoc = async (e) => {
    var f = e.target.files[0]
    var name_doc = f.name.split(".")[0];
    var type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(",")[1];
    setfilenameState({
      file64: file64,
      fileName: name_doc,
      type_doc: type_doc
    });
  }

  const sendDocument = () => {
    setLoading(true)
    dispatch(sendSign({ eaccount: storage.loginReducer.currentAccount.id, id: modalDoc.id, file64: filenameState.file64, typeDoc: filenameState.type_doc }, () => setLoading(false), () => setTrigger(trigger + 1)))
    setModalDoc({ show: false, id: "" });
    setfilenameState({ file64: "", fileName: "", type_doc: "" })
  }


  //-------------- select options 🍕   --------------
  let optionsStatus = [{ key: "default", value: "", label: "Seleccionar..." }];
  if (Array.isArray(storage.billingReducer.status_in_history)) {
    storage.billingReducer.status_in_history.map((item) => {
      optionsStatus.push({
        value: item.name,
        label: item.value,
        key: item.id + "status",
      });
    });
  }

  let optionsContracts = [{ key: "default", value: "", label: "Seleccionar..." }];
  if (Array.isArray(storage.billingReducer.types_contracts)) {
    storage.billingReducer.types_contracts.map((item) => {
      optionsContracts.push({
        value: item.id,
        label: item.value,
        key: item.id + "contracts",
      });
    });
  }


  const changeStatus = (id_service, elem) => {
    setLoading(true);
    dispatch(changeStatusContract({ eaccount: storage.loginReducer.currentAccount.id, id: id_service, elem }, () => setLoading(false), () => setTrigger(trigger + 1)))
  }


  const renderList = () => {
    let table = [];
    if (Array.isArray(storage.billingReducer.contracts)) {
      table = storage.billingReducer.contracts.map((x, index) => {
        return (
          <tr key={"renderList" + index} className="hover-table-row">
            <td className="text-uppercase  px-2 ">{x.sequence}</td>
            <td className="text-start px-2">{x.contract_name}</td>
            <td className="text-start px-2">{x.clientName}</td>
            <td className=" px-2">{!!x?.start_date ? x.start_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1") : ""}</td>
            <td className=" px-2">{!!x?.end_date ? x.end_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1") : ""}</td>
            <td className="px-2">
              {x.hasSignature == 1 ?
                <img src={CheckMark} alt="checkeado" title="Firmada" width={18} />
                :
                <img src={Circle} alt="circle" title="SinFirnar" width={18} />
              }

            </td>
            <td className="text-center px-2">

              <div
                className={`
                                    ${IndividualStyles.popupReqActive} ${x.status === "Inhabilitado"
                    ? tableStyles.redState
                    : tableStyles.greenState
                  }
                                `}
              >
                {x.status === "Inhabilitado" ? 'Inhabilitado' : 'Habilitado'}
              </div>

            </td>
            <td>
              <CustomPopupExtend
                triggerSrc={threeDots}
                noHover
                showDetails={myPermission?.read}
                textDetails="Ver detalle"
                showDetailsEvent={() => setDetails({ ...details, show: true, edit: false, sign: true, clone: false, selectedContract: x.id })}
                editText="Editar"
                showEnable={myPermission?.edit}
                isEnabled={(x.status === "Habilitado")}
                showClone={myPermission?.create}
                cloneText={"Clonar"}
                cloneEvent={() => setDetails({ ...details, show: true, edit: true, sign: false, clone: true, selectedContract: x.id })}
                showSing={!!(x.hasSignature == 1) ? false : true}
                signEvent={() => setModalDoc({ show: true, id: x.id })}
                enableClickEvent={() => changeStatus(x.id, x)}

              />
            </td>

          </tr>
        );
      });
    }
    return table;
  }
  const handleSumit = (e) => {
    setLoading(true);
    e.preventDefault();
    setTrigger(trigger + 1)
    setFilters({ ...filters, page: 1 })
  }

  const principalRender =
    <div className={`d-flex flex-column mx-5 px-3 mx-auto ${styles.container}`}>
      {(loading) &&
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      }
      <h1>Contratos de clientes</h1>
      {/* filter section */}

      {
        myPermission?.read && (
          <div className="row ">
            <div className="col-2">
              <label htmlFor="contractType" className={styles.labelFont}>
                Tipo de contrato
              </label>
            </div>

            <div className="col-2 mx-3">
              <label htmlFor="status" className={styles.labelFont}>
                Estado
              </label>
            </div>
          </div>
        )
      }

      <div style={{ marginTop: '-10px' }} className="d-flex justify-content-between align-items-center">

        {
          myPermission?.read && (
            <>
              <div className="col-2">

                <div className="d-flex flex-column ">
                  <SelectComponent
                    noOptionsMessage={() => 'No hay datos'}
                    placeholder='Seleccionar....'
                    value={filters?.contractType && { label: optionsContracts.find(e => e.value === filters?.contractType)?.label, value: filters?.contractType }}
                    Name="optionsTypeContract"
                    className={"w-100"}
                    options={[...optionsContracts]}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        page: 1,
                        contractType: e.value,
                      })
                      setLoading(true)
                      setTrigger(trigger + 1);
                    }
                    }
                  />
                </div>
              </div>

              <div className="col-2 mx-3">

                <div
                  className="d-flex flex-column "
                >
                  <SelectComponent
                    noOptionsMessage={() => 'No hay datos'}
                    placeholder='Seleccionar...'
                    value={{ label: optionsStatus.find(e => e.value === filters?.status)?.label, value: filters.status }}
                    Name="optionsStatus"
                    className={"w-100"}
                    options={[...optionsStatus]}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        status: e.value,
                      })
                      setLoading(true)
                      setTrigger(trigger + 1);
                    }
                    }
                  />
                </div>
              </div>
              {/* LLegué hasta acá , feature/BIOW-37 */}
              <div className="d-flex flex-column col">
                <form onSubmit={handleSumit}>
                  <div className="d-flex">
                    <input
                      type="text"
                      className={tableStyles.searchUsersAlt}
                      placeholder="Buscar..."
                      value={filters.search}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          search: e.target.value,
                        })

                      }
                      }
                    // onKeyDown={(e)=>{
                    //   setLoading(true)
                    //   setTrigger(trigger + 1);
                    // }}

                    />
                    <img
                      src={lupa}
                      alt="buscar"
                      width={24}
                      className="mx-2 hoverPointer"
                      onClick={() => { setTrigger(trigger + 1); setFilters({ ...filters, page: 1 }); setLoading(true) }}
                    />
                  </div>
                </form>
              </div>

            </>
          )
        }

        {
          myPermission?.create && (
            <div
              style={{ marginLeft: 'auto' }}
              className={`${styles.groupBounding2} ${styles.buttonCreatCount} groupAddButton align-items-center`}
              onClick={() => setDetails({ show: true, edit: true, sign: false, clone: true })}
            >
              <label htmlFor="newAccident" className="darkGray fw-bold px-2">
                Nuevo contrato
              </label>
              <button
                className="addCheckButton mx-2"
                style={{ marginBottom: "1.5px" }}
              />
            </div>
          )
        }

      </div>

      {
        myPermission?.read && (
          <>
            <GenericTableNew
              fontFamilyTable={'fontFamilyTable'}
              dark={true}
              headers={[
                <th className="text-center">No.</th>,
                <th className="text-start">Nombre del contrato</th>,
                <th>Cliente Empresa</th>,
                <th className="text-center">Fecha</th>,
                <th className="text-center">Vence</th>,
                <th className="text-center">Firma</th>,
                <th className="text-center w100">Estado</th>,
                <th></th>,
              ]}
            >
              {renderList()}
            </GenericTableNew>

            {/* ----- pagination #️⃣ ----- */}
            <div className={IndividualStyles.bottom}>
              <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                  Pag.{" "}
                  {storage.billingReducer.contracts
                    ? filters.page
                    : ""}
                  {" de "}
                  {Math.ceil(
                    storage.billingReducer?.contracts_total / filters.perpage
                  )
                    ? Math.ceil(
                      storage.billingReducer.contracts_total /
                      filters.perpage
                    )
                    : ""}{" "}
                  ({storage.billingReducer.contracts_total} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={10}
                  totalItemsCount={Number(storage.billingReducer.contracts_total)}
                  pageRangeDisplayed={5}
                  onChange={(e) => { setFilters({ ...filters, page: e }); if (filters.page !== e) { setLoading(true) } }}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </div>
          </>
        )
      }

      <ModalNew
        hideFooter={!myPermission?.create}
        title={"Contrato firmado"}
        show={modalDoc.show}
        btnYesName="Subir"
        btnNoName="Cancelar"
        btnYesEvent={() => sendDocument()}
        onHide={() => {
          setModalDoc({ show: false, id: "" });
          setfilenameState({ file64: "", fileName: "", type_doc: "" })
        }
        }
        btnNoEvent={() => {
          setModalDoc({ show: false, id: "" });
          setfilenameState({ file64: "", fileName: "", type_doc: "" })
        }}
      >
        <Dropzone onDrop={acceptedFile => validateFile(acceptedFile)} accept={type_file}>
          {({ getRootProps, getInputProps }) => (
            <section className="border w-100 h-100 p-1 text-center mx-auto">
              <div {...getRootProps()}>
                <input {...getInputProps()} type="file" name="documents" onChange={onChangeDoc} accept={type_file} multiple={false} />
                {!!filenameState.fileName ?
                  <div id="2" className={`text-muted text-center "px-3 pt-3 pb-5": "p-5" ${IndividualStyles.filesDir}`}>
                    <img src={docType(filenameState.type_doc)} alt="icon" className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.inputMargin}`} />
                    <p className={``}>{filenameState.fileName}</p>
                  </div>

                  :

                  <p className={`text-muted text-center "px-3 pt-3 pb-5": "p-5"} hoverPointer`}>{"Soltar archivo aquí"}</p>

                }

                {<img src={Attach} alt="añadir" className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.Aling}`} />}


              </div>
            </section>
          )}
        </Dropzone>
      </ModalNew>
    </div>

  return (!!details.show) ?
    <NewContract
      selectedContract={details.selectedContract}
      show={details.show}
      setDetails={setDetails}
      edit={details.edit}
      sign={details.sign}
      clone={details.clone}
    />
    :
    principalRender;
}

export default ClientContract;

