import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { message } from "../helpers/helpers";
import { GET_LIST_WORK_ENV_POLL } from "./actionTypes";

export const getWorkEnvPollList = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_LIST_WORK_ENV_POLL,
    loading: true
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/work_environment/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_LIST_WORK_ENV_POLL,
        payload: res.results,
        rowTotal: res.row_total,
        loading: false
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_LIST_WORK_ENV_POLL,
        payload: [],
        rowTotal: 0,
        loading: false
      });
      console.error(error.message);
      message(
        "error",
        "Error",
        "Ha ocurrido un error al cargar la información"
      );
    });
};

export const sendSurvey = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/work_environment/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    //console.log(result)
    return result
  } catch (error) {
    console.error(error.message);
    return message(
      "error",
      "Error",
      "Ha ocurrido un error al guardar la información"
    );
  }
};
