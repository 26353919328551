import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GET_TEMP_WORK_CERT } from "../../actions/actionTypes";
import { create_workCertificate } from "../../actions/contractAction";
import { getCandidateList } from "../../actions/partnersActions";
import { getAllJobPositions } from "../../actions/positionsActions";
import { isEmptyOrUndefined, today } from "../../helpers/helpers";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import tableStyles from "../Layouts/tableStyle.module.scss";
import Styles from "../PayRollModule/Hiring/Contract.module.scss";
import SelectComponent from "../SelectComponent/SelectComponent";
function WorkCertificateRequest(props) {
  function goBack() {
    history.push("/configuracion/miperfil");
  }
  const counter = useSelector((state) => state);
  const myPermission =
    counter.loginReducer?.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "WkCertifiR"
    );
  const [trigger, setTrigger] = useState(1);
  const dispatch = useDispatch();
  let history = useHistory();
  const [data, setData] = useState({
    employee: counter?.loginReducer?.user_data?.payrollInfo?.id_candidate,
    job_position: counter?.loginReducer?.user_data?.payrollInfo?.id_jobPosition,
    exp_date: today(),
    addressed_to: "",
    title_to: "",
    contract: counter?.loginReducer?.user_data?.payrollInfo?.id_contract,
    include_salary: false,
    include_notconst: false,
    include_average: false,
    entity_account: counter?.loginReducer?.currentAccount.id,
    created_by: counter?.loginReducer?.user_data?.id,
  });
  const titleOptions = [
    { label: "Seleccionar...", value: "" },
    { label: "Sin título", value: "Sin título" },
    { label: "Señor", value: "Señor" },
    { label: "Señora", value: "Señora" },
    { label: "Señores", value: "Señores" },
  ];
  // let base64 ="https://file-examples-com.github.io/uploads/2017/10/file-example_PDF_1MB.pdf";
  let fileUrl = counter.contractReducer?.tempWorkCertificatePDF;
  const [btnyes, setBtnyes] = useState(true);
  useEffect(() => {
    if (!!!myPermission?.create) {
      goBack();
    } else {
      dispatch(
        getCandidateList({
          status: "current",
          filtered: 1,
          eaccount: counter.loginReducer?.currentAccount?.id,
        })
      );
    }
  }, []);

  useEffect(() => {
    setTrigger(trigger + 1);
    if (data.employee !== 0) {
      dispatch(
        getAllJobPositions({ slim: 1, active: 1, onlyCurrent: "current", id_candidate: data.employee })
      );
    }
  }, [data.employee]);
  useEffect(() => {
    if (!!!data.employee || !!!data.exp_date || !!!data.job_position) {
      setBtnyes(true);
    } else {
      setBtnyes(false);
    }
  }, [data]);
  const optionCandidates = [];
  if (Array.isArray(counter.partnersReducer.employee_list)) {
    counter.partnersReducer.employee_list.map((item) => {
      optionCandidates.push({
        label: item.full_name,
        value: item.id_candidate,
        name: "candidates",
      });
    });
  }
  const optionPositions = [];
  if (Array.isArray(counter?.positionReducer?.jobsPositionsList)) {
    counter?.positionReducer?.jobsPositionsList.map((item) => {
      optionPositions.push({
        label: item.job_title,
        value: item.id,
        contract: item.contract_id,
        name: "job_position",
      });
    });
  }
  const generateWorkCertificate = async (e) => {
    await dispatch(create_workCertificate(data));
    setData({
      employee: counter.loginReducer.user_data.payrollInfo.id_candidate,
      job_position: counter.loginReducer.user_data.payrollInfo.id_jobPosition,
      exp_date: today(),
      addressed_to: "",
      title_to: "",
      contract: counter.loginReducer.user_data.payrollInfo.id_contract,
      include_salary: false,
      include_notconst: false,
      include_average: false,
      entity_account: counter.loginReducer.currentAccount.id,
      created_by: counter.loginReducer.user_data.id,
    });
    setTrigger(trigger + 1);
  };

  return (
    <>
      {counter.contractReducer.loading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <Row>
            <Col xs={5} className="mr-2">
              <h1 className={tableStyles.title}>Carta laboral</h1>
              <div
                style={{
                  backgroundColor: "rgba(0, 93, 191, 0.05)",
                  borderRadius: "10px",
                  marginTop: "10px",
                  padding: "10px",
                }}
              >
                <Row className="d-flex">
                  <Col xs={7}>
                    <div className="display-grid mt-1">
                      <label className={tableStyles.crudModalLabel}>
                        Colaborador
                      </label>
                      <input
                        className={Styles.registerInputs}
                        value={
                          counter?.loginReducer?.user_data?.first_name +
                          " " +
                          counter?.loginReducer?.user_data?.first_surname
                        }
                        disabled={true}
                      ></input>
                    </div>
                  </Col>
                  <Col xs={5}>
                    <div className="display-grid mt-1">
                      <label className={tableStyles.crudModalLabel}>
                        Fecha
                      </label>
                      <input
                        className={`register-inputs`}
                        type="date"
                        value={data.exp_date}
                        onChange={(e) =>
                          setData({
                            ...data,
                            exp_date: e.target.value,
                          })
                        }
                      ></input>
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex">
                  <Col xs={12}>
                    <div className="display-grid mt-1">
                      <label className={tableStyles.crudModalLabel}>
                        Nombre del cargo
                      </label>
                      <SelectComponent
                        styles={customSelectNewDark}
                        placeholder={"Seleccionar..."}
                        key={"cargo" + trigger}
                        onChange={(e) =>
                          setData({
                            ...data,
                            job_position: e.value,
                            contract: e.contract,
                          })
                        }
                        options={optionPositions}
                        value={optionPositions.find((x) => x.value === data.job_position)}
                      ></SelectComponent>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <div className="display-grid mt-1">
                      <label className={tableStyles.crudModalLabel}>
                        Título
                      </label>
                      <SelectComponent
                        styles={customSelectNewDark}
                        placeholder={"Seleccionar..."}
                        key={"cargo" + trigger}
                        onChange={(e) => {
                          const valueAddress = e.value === "Sin título" ? "A quien interese" : "";
                          setData({
                            ...data,
                            title_to: e.value,
                            addressed_to: valueAddress
                          });
                        }}
                        options={titleOptions}
                        value={titleOptions.find((x) => x.value === data.title_to)}
                      ></SelectComponent>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="display-grid mt-1">
                      <label className={tableStyles.crudModalLabel}>
                        Nombre
                      </label>
                      <input
                        className={`register-inputs`}
                        value={data.addressed_to}
                        onChange={(e) =>
                          setData({
                            ...data,
                            addressed_to: e.target.value,
                          })
                        }
                        placeholder="Escribir..."
                      ></input>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} className="pt-1">
                    <label
                      className={tableStyles.crudModalLabel}
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      Añadir información
                    </label>
                    <Col
                      className={"d-flex"}
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <input
                          className=" border border-dark-blue form-check-input p1 check-dark-blue"
                          type="checkbox"
                          onChange={(e) =>
                            setData({
                              ...data,
                              include_salary: e.target.checked,
                            })
                          }
                          checked={data.include_salary}
                        ></input>
                      </div>
                      <div>
                        <p
                          style={{ color: "#58595b", marginLeft: "5px" }}
                          className={`${tableStyles.crudModalLabel}`}
                        >
                          Salario
                        </p>
                      </div>
                    </Col>
                    <Col
                      className={"d-flex"}
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <input
                          className=" border border-dark-blue form-check-input p1 check-dark-blue"
                          type="checkbox"
                          checked={data.include_average}
                          onChange={(e) =>
                            setData({
                              ...data,
                              include_average: e.target.checked,
                            })
                          }
                        ></input>
                      </div>
                      <div>
                        <p
                          style={{ color: "#58595b", marginLeft: "5px" }}
                          className={`${tableStyles.crudModalLabel}`}
                        >
                          Promedio salarial
                        </p>
                      </div>
                    </Col>
                    <Col
                      className={"d-flex"}
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <input
                          className=" border border-dark-blue form-check-input p1 check-dark-blue"
                          type="checkbox"
                          checked={data.include_notconst}
                          onChange={(e) =>
                            setData({
                              ...data,
                              include_notconst: e.target.checked,
                            })
                          }
                        ></input>
                      </div>
                      <div>
                        <p
                          style={{ color: "#58595b", marginLeft: "5px" }}
                          className={`${tableStyles.crudModalLabel}`}
                        >
                          Ingreso no constitutivo
                        </p>
                      </div>
                    </Col>
                  </Col>
                </Row>
                <Row
                  className={"pt-2 col-12"}
                  style={{
                    justifyContent: "end",
                  }}
                >
                  <button
                    style={{ marginLeft: "10px" }}
                    className={`col-1 ${tableStyles.btnPrimary}`}
                    disabled={btnyes}
                    onClick={() => generateWorkCertificate()}
                  >
                    Generar
                  </button>
                </Row>
              </div>
            </Col>

            <Col xs={6} className={`mb-4`} style={{ marginLeft: "2rem" }}>
              {!isEmptyOrUndefined(fileUrl) && (
                <>
                  <Row className={"pt-2 d-flex justify-content-end"}>
                    <Col xs={4}>
                      <Button
                        variant="outline-primary"
                        onClick={() =>
                          dispatch({
                            type: GET_TEMP_WORK_CERT,
                            payload: "",
                          })
                        }
                        className={tableStyles.btnSecondary}
                      >
                        Limpiar
                      </Button>
                    </Col>
                  </Row>
                  <iframe
                    src={fileUrl}
                    key={trigger}
                    title="document"
                    style={{ width: "100%", height: "500px" }}
                    frameBorder="0"
                    className="pb-4"
                  />
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default WorkCertificateRequest;
