import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import ModalNew from "../../components/Layouts/ModalNew";
import Style from "../../components/Payroll/payrollStyles.module.scss";
import { convertFilterToString } from "../../helpers/convertToFilter";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Down from "../../assets/img/icons/detailsClosed.svg";
import Lupa from "../../assets/img/icons/lupa.svg";
import Excel from "../../assets/img/icons/excel.svg";
import Word from "../../assets/img/icons/word.svg";
import PDF from "../../assets/img/icons/pdf.svg";
import Atras from "../../assets/img/icons/atras-icon.svg";
import Document from "../../assets/img/icons/traerReq.svg";
import { Col, Row } from "react-bootstrap";
import Loader from "react-loader-spinner";
import GenericTableScroll from "../../components/Layouts/GenericTableScroll";
import { SlideDownButton } from "../../components/Layouts/SlideDownButton";
import {
  getDetailCalendar,
  getOneBalance,
  getTypeActivity,
  newObservation,
  resetDetailCalendar,
} from "../../actions/receiptOfInvoicesActions";
import ModalDocuments from "../../components/TableIndividualAuction/IndividualDocuments";
import Cerrar from "../../assets/img/icons/littleClose.svg";
import Adjuntar from "../../assets/img/icons/insertar.svg";

function SituationPurseDetail(props) {
  const MySwal = withReactContent(Swal);
  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [selectedList, setselectedList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalDocumentsControl, setmodalDocumentsControl] = useState({
    show: false,
  });
  const ButtonDocuments = () => {
    setmodalDocumentsControl({
      ...modalDocumentsControl,
      show: true,
    });
  };
  useEffect(() => {
    dispatch(getTypeActivity());
    dispatch(
      getOneBalance({
        eaccount: counter.loginReducer.currentAccount.id,
        id: props.filter.client,
        invoiceSeq: !!filters.invoiceSeq ? filters.invoiceSeq : "",
      })
    );
    dispatch(
      getDetailCalendar({
        year: props.filter.year,
        month: props.filter.month,
        client: props.filter.client,
      })
    );
  }, [trigger]);
  const [reqState, setreqState] = useState({
    auction_title: "",
    date_expiry: "",
    hour_expiry: "",
    terms: "",
    priority: "",
    id_warehouse: "",
    id_user: counter.loginReducer.user_data.id,
    account: counter.loginReducer.currentAccount.id,
    account_session: counter.loginReducer.currentAccount.name,
    id_consumption_center: 0,
    articulos: [],
    pay_conditions: "",
    coin: "",
    document: [],
    file: false,
    observations: " ",
    qty_total: 0,
  });
  const resetStatusFile = (event) => {
    if (!!event) {
      event.stopPropagation();
    }
    setreqState({
      auction_title: "",
      date_expiry: "",
      hour_expiry: "",
      terms: "",
      priority: "",
      id_warehouse: "",
      id_user: counter.loginReducer.user_data.id,
      account: counter.loginReducer.currentAccount.id,
      account_session: counter.loginReducer.currentAccount.name,
      id_consumption_center: 0,
      articulos: [],
      pay_conditions: "",
      coin: "",
      document: [],
      file: false,
      observations: " ",
      qty_total: 0,
    });
  };
  const [filters, setFilters] = useState({});
  const [filterModal, setFilterModal] = useState({
    client: props.filter.client,
    user: counter.loginReducer.user_data.id,
  });
  let optionsActivity = [
    { key: "", value: "", label: "Seleccione tipo de gestión" },
  ];
  if (Array.isArray(counter.invoiceReducer.typeActivity)) {
    counter.invoiceReducer.typeActivity.map((item, index) => {
      optionsActivity.push({
        value: item.id,
        label: item.name,
        key: index + "eventType",
      });
    });
  }
  function handleClick() {
    props.setDetails({ show: false });
    props.filter.year = "";
    props.filter.month = "";
    props.filter.client = "";
    dispatch(resetDetailCalendar());
  }
  const docType = (data) => {
    let iconDoc;
    if (!!data.file) {
      switch (data.file.type) {
        case "application/pdf":
          iconDoc = PDF;
          break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          "application/msword":
          iconDoc = Word;
          break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          "csv" ||
          "application/vnd.ms-excel":
          iconDoc = Excel;
          break;
        default:
          iconDoc = PDF;
          break;
      }
    }
    return iconDoc;
  };
  const isBoxSelected = (invoice_id) => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find((x) => x === invoice_id);
      return !!target;
    }
  };

  const changeBoxSelected = (invoice) => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find((x) => x == invoice);
      if (!!target) {
        tempSelected = tempSelected.filter((x) => x != invoice);
      } else {
        let newChange = invoice;
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };
  const isBoxAllSelected = () => {
    let res = true;
    let counting = 0;
    if (
      Array.isArray(counter.invoiceReducer.one_balance.detail) &&
      Array.isArray(selectedList)
    ) {
      counter.invoiceReducer.one_balance.detail.map((item) => {
        if (item.filing_date !== null) {
          if (selectedList.find((itemb) => item.id == itemb)) {
            counting++;
          } else {
            res = false;
          }
        }
      });
    }
    return res;
  };

  const changeBoxAllSelected = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [...selectedList];
      if (
        Array.isArray(counter.invoiceReducer.one_balance.detail) &&
        Array.isArray(tempSelected)
      ) {
        counter.invoiceReducer.one_balance.detail.map((x) => {
          if (x.filing_date !== null) {
            if (selectedList.find((itemb) => x.id == itemb)) {
            } else {
              let newChange = x.id;
              tempSelected.push(newChange);
            }
          }
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };
  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  };
  let optionsStatus = [
    { key: "default", value: "", label: "Seleccione estado", id: "" },
  ];
  if (Array.isArray(counter.invoiceReducer.status)) {
    counter.invoiceReducer.status.map((item) => {
      optionsStatus.push({
        value: item.name,
        label: item.value,
        key: item.id + "status",
      });
    });
  }

  let optionsClient = [
    { key: "default", value: "", label: "Seleccione cliente", id: "" },
  ];
  if (Array.isArray(counter.invoiceReducer.clients)) {
    counter.invoiceReducer.clients.map((item) => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + "clients",
      });
    });
  }

  const header = [
    <div className="col-1">
      <input
        type="checkbox"
        className="border border-dark-blue form-check-input p1 check-dark-blue"
        style={{ width: "14px", height: "14px" }}
        name={"selectedAll"}
        value="selectedAll"
        checked={isBoxAllSelected()}
        onChange={(e) => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </div>,
    <div className="col-2 text-start">No. Factura </div>,
    <div className="col-1 text-center">Fecha Factura</div>,
    <div className="col-2 text-center">Valor</div>,
    <div className="col-2 text-center">F. Radicado</div>,
    <div className="col-2 text-start">Vencimiento</div>,
    <div className="col-2 text-center">Estado</div>,
  ];
  const headerCard = [
    <div className="col text-center">No. Factura </div>,
    <div className="col text-center">Fecha Factura</div>,
    <div className="col text-center">Valor</div>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(counter.invoiceReducer.one_balance.detail)) {
      table = counter.invoiceReducer.one_balance.detail.map((x, index) => {
        return (
          <section2 key={"renderList" + index} className="hover-table-row">
            <div className="col-1">
              <input
                type="checkbox"
                // className="border border-dark-blue form-check-input p1 check-dark-blue"
                className={
                  !!x.filing_date
                    ? "border border-dark-blue form-check-input p1 check-dark-blue"
                    : "border border-clear-grey form-check-input p1"
                }
                style={{ width: "14px", height: "14px" }}
                name=""
                id=""
                checked={isBoxSelected(x.id)}
                onChange={(e) => changeBoxSelected(x.id)}
                disabled={!!x.filing_date ? false : true}
              />
            </div>
            <div className="col-2 text-start text-uppercase">{x.sequence}</div>
            <div className="col-1">
              {!!x?.date
                ? x.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")
                : ""}
            </div>
            <div className="col-2 text-end">
              {"$" + numberWithCommas(x.amount)}
            </div>
            <div className="col-2 text-center">
              {!!x?.filing_date
                ? x.filing_date.replace(
                  /^(\d{4})-(\d{2})-(\d{2})$/g,
                  "$3/$2/$1"
                )
                : "-"}
            </div>
            <div className="col-2 text-start">{x.expiration_period}</div>
            <div
              className="col-2 text-center"
              style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
            >
              <div
                className="rounded-pill p-1"
                style={{ backgroundColor: `${x.background}` }}
              >
                <b style={{ color: `${x.fontcolor}` }}>{x.status}</b>
              </div>
            </div>
          </section2>
        );
      });
    }
    return table;
  };
  const renderListCard = (invoice) => {
    let tableCard = [];

    if (Array.isArray(invoice)) {
      tableCard = invoice.map((x, index) => {
        return (
          <section2 key={"renderList" + index} className="hover-table-row">
            <div
              className="col text-start text-uppercase"
              style={{ marginLeft: "1rem" }}
            >
              {x.sequence}
            </div>
            <div className="col">
              {!!x?.invoice_date
                ? x.invoice_date.replace(
                  /^(\d{4})-(\d{2})-(\d{2})$/g,
                  "$3/$2/$1"
                )
                : ""}
            </div>
            <div className="col text-end" style={{ marginRight: "1rem" }}>
              {"$" + numberWithCommas(x.amount)}
            </div>
          </section2>
        );
      });
    }
    return tableCard;
  };

  const resetForm = () => {
    setselectedList([]);
    setShowModal(false);
    setTrigger(trigger + 1);
    resetStatusFile();
  };
  const newObj = () => {
    filterModal.invoices = selectedList;
    filterModal.file64 = reqState?.document[0]?.base64;
    dispatch(newObservation(filterModal, () => resetForm()));
  };

  return (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >
      {!!counter.invoiceReducer.loading_PayLegalCollection && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}

      {counter.invoiceReducer.loading_calendar_detail && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}

      <div className="d-flex">
        <img
          className={`${tableStyles.title} mr-3`}
          src={Atras}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />
        <h1 className={tableStyles.title}>Detalle cartera</h1>
      </div>
      <div className="d-flex">
        <div style={{ width: "70%" }}>
          <Row className="d-flex">
            <Col xs={3}>
              <label className={tableStyles.crudModalLabel}>Empresa</label>
              <input
                className={IndividualStyles.registerInputsGris}
                type="text"
                value={props.filter.clientName}
                disabled
              ></input>
            </Col>
            <Col xs={3}>
              <label className={tableStyles.crudModalLabel}>
                Fecha de cobro
              </label>
              <input
                className={IndividualStyles.registerInputsBlue}
                type="text"
                value={props.info.data.date}
                disabled
              ></input>
            </Col>
            <Col xs={3}>
              <label className={tableStyles.crudModalLabel}>No. Factura</label>
              <input
                className={IndividualStyles.registerInputsGris}
                type="text"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    invoiceSeq: e.target.value,
                  })
                }
              ></input>
            </Col>
            <Col xs={1}>
              <i>
                <img
                  className={`${tableStyles.cursorPointer}`}
                  style={{ marginTop: "30px" }}
                  src={Lupa}
                  onClick={() => {
                    setTrigger(trigger + 1);
                  }}
                />
              </i>
            </Col>
          </Row>
          <GenericTableScroll
            headers={header}
            body={renderList()}
            typeHead={"2"}
          />
        </div>
        <div style={{ marginLeft: "20px", width: "30%" }}>
          <div className={`d-flex flex-row-reverse`}>
            <div
              onClick={() =>
                selectedList.length < 1
                  ? MySwal.fire({
                    icon: "info",
                    title: "Info",
                    text: "No hay facturas relacionadas",
                    confirmButtonText: "Aceptar",
                  })
                  : setShowModal(true)
              }
              className={`${tableStyles.iconWrap} hoverPointer`}
              style={{ marginLeft: 10 }}
            >
              <span className={`${tableStyles.iconLabel}`}>
                Nueva observación
              </span>
              <img width="15px" height="20px" src={Document} alt="Form" />
            </div>
            <div style={{ flex: 1 }}></div>

            <div className={``}>
              <b className={tableStyles.crudModalLabel}>Observaciones</b>
            </div>
          </div>
          <div
            style={{
              marginTop: "50px",
              overflow: "scroll",
              maxHeight: "53vh",
              padding: "10px",
            }}
          >
            {counter?.invoiceReducer?.DetailCalendar?.map((item) => {
              //console.log(item, "item");
              return (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "20px",
                    padding: "10px",
                    // border: "solid black 1px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <Row className="d-flex">
                    <Col xs={6}>
                      <label className={IndividualStyles.crudModalLabelGris}>
                        <b style={{ fontSize: "14px" }}>{item.type}</b>
                      </label>
                    </Col>
                    <Col xs={6} className="text-end">
                      <label className={IndividualStyles.crudModalLabelGris}>
                        <b style={{ fontSize: "14px" }}>{item.date}</b>
                      </label>
                    </Col>
                  </Row>
                  <label
                    className={IndividualStyles.crudModalLabelGris}
                    style={{ whiteSpace: "inherit" }}
                  >
                    <p>{item.summary}</p>
                  </label>

                  {item.invoices.length > 0 ? (
                    <SlideDownButton
                      iconAfter={Down}
                      widthText={true}
                      bgBody="white"
                      width={"100%"}
                      colorBg={"white"}
                      textAlign="start"
                      title="Ver facturas relacionadas"
                      bodyWidth={"100%"}
                      body={
                        <>
                          <GenericTableScroll
                            headers={headerCard}
                            body={renderListCard(item.invoices)}
                            typeHead={"2"}
                          ></GenericTableScroll>
                        </>
                      }
                    ></SlideDownButton>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ModalNew
        show={showModal}
        onHide={() => setShowModal(false)}
        title={"Observación"}
        btnYesEvent={() => newObj()}
        btnNoEvent={() => (
          setShowModal(false),
          setFilterModal({
            client: props.filter.client,
            user: counter.loginReducer.user_data.id,
          }),
          resetStatusFile()
        )}
        btnYesName={"Guardar"}
        size={"500"}
      >
        <div className="row">
          <div className="col-6">
            <label className={tableStyles.crudModalLabel}>Responsable</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              type="text"
              value={
                counter.loginReducer.user_data.first_name +
                " " +
                counter.loginReducer.user_data.first_surname
              }
              disabled
            ></input>
          </div>
          <div className="col-6">
            <label className={tableStyles.crudModalLabel}>Gestión</label>
            <Select noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar"
              options={optionsActivity}
              styles={customSelectNewDark}
              onChange={(e) =>
                setFilterModal({
                  ...filterModal,
                  type: e.value,
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label className={tableStyles.crudModalLabel}>Fecha</label>
            <input
              className={IndividualStyles.registerInputsBlue}
              type="date"
              onChange={(e) =>
                setFilterModal({
                  ...filterModal,
                  date: e.target.value,
                })
              }
            ></input>
          </div>
          <div className="col-6">
            <label className={tableStyles.crudModalLabel}>Cargar archivo</label>
            <div
              className={`${IndividualStyles.registerInputsBlue} d-flex`}
              style={{ backgroundColor: "#fff" }}
              type="text"
              onClick={() => ButtonDocuments()}
            >
              <img src={docType(reqState)} alt="" srcset="" />
              <p
                className={tableStyles.crudModalLabel}
                style={{ marginTop: "0.2rem" }}
              >
                {reqState.file.name}
                {reqState.file.name ? (
                  <img
                    src={Cerrar}
                    style={{ marginLeft: "5px" }}
                    onClick={(e) => resetStatusFile(e)}
                  />
                ) : (
                  ""
                )}
                {/* // <img src={Cerrar} onClick={()=> resetStatusFile()} /> */}
              </p>
              <img
                style={{ marginLeft: "auto", width: "16px" }}
                src={Adjuntar}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label className={tableStyles.crudModalLabel}>ㅤ</label>
            <textarea
              className={`${IndividualStyles.textAreaClear} w-100`}
              onChange={(e) =>
                setFilterModal({
                  ...filterModal,
                  summary: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>
      </ModalNew>
      <ModalDocuments
        title="Anexar Documento"
        key={"modalDocuments" + trigger}
        show={modalDocumentsControl.show}
        onHide={() =>
          setmodalDocumentsControl({
            ...modalDocumentsControl,
            show: false,
          })
        }
        reqState={reqState}
        setreqState={setreqState}
      />
    </div>
  );
}
export default SituationPurseDetail;
