import React, { useEffect, useState } from "react";

import {useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import { TabContainer, Nav, TabContent, TabPane } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { OrdGenericTemplate } from "../../../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import SurveyPerf from "./SurveyPerf";
import SurveyAnswers from "./SurveyAnswers";
import backIcon from "../../../../assets/img/icons/atras-icon.svg";
import { loader } from "../../../../helpers/helpers";


const DetailSurveyPerf = () => {
    const store = useSelector((state) => state);
    const myPermission =
        store.loginReducer.currentAccount?.profile?.permission?.find(
            (x) => x.functionality?.prefix === "evalPerf"
        );
    const history = useHistory();
    useEffect(() => {
        if (!!!myPermission?.read) {
            history.push("/nomina/inicio");
        }
        // dispatch(getWorkEnvPollList(filters)); NOTE PETITION
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const location = useLocation();
    const isEditing = location?.state?.action === "edit" ? true : false;
    const detail = location?.state?.action === "detail" ? true : false;
    const data = location?.state?.data;

    const goBack = () => {
        history.push("/nomina/EvaluacionDeDesempeño");
    }

    const [profile, setProfile] = useState(1);
    const [loading, setLoading]=useState(false);
    return (
        <>
        {/* {loading && loader} */}
            <OrdGenericTemplate
                showBackArrow={true}
                backArrowAction={() => {
                    goBack();
                }}
                classIcon={`${tableStyles.svgFilterColorBlue}`}
                backIcon={backIcon}
                title={"Evaluación"}
                className="px-4 my-2 w-80"
                titleSize={10}
                // style={{ overflow: "auto", height: "min-content" }}
                titleStyle={{ fontSize: "32px", }}
            >
                <div style={{ marginLeft: "2rem" }}>

                    <div className="simpleTabs__Container">
                        <TabContainer defaultActiveKey={profile}>
                            <Nav className="flex-row simpleTabs">
                                <Nav.Item key={1}>
                                    <Nav.Link eventKey={1} onClick={() => setProfile(1)}
                                        style={{ width: "200px" }}
                                    >
                                        Preguntas
                                    </Nav.Link>
                                </Nav.Item>
                                { (detail && (data.status === "in_progress" || data.status === "completed")) &&
                                <Nav.Item key={2}>
                                    <Nav.Link eventKey={2} onClick={() => setProfile(2)}
                                        style={{ width: "200px" }}
                                    >
                                        Respuestas
                                    </Nav.Link>
                                </Nav.Item>
                                }
                                <div style={{ flex: 1 }} className="simpleTabs__filler"></div>
                            </Nav>
                            <TabContent
                                className="simpleTabs__relative"
                                style={{ overflowY: "unset" }}
                            >
                                <TabPane
                                    key={"panelTab_1"}
                                    eventKey={1}
                                    className="simpleTabs__Area"
                                >
                                    <SurveyPerf profile={profile} edit={isEditing} detailD={detail}  setLoading={setLoading}/>
                                </TabPane>
                                <TabPane
                                    key={"panelTab_2"}
                                    eventKey={2}
                                    className="simpleTabs__Area"
                                >
                                    <SurveyAnswers profile={profile} edit={isEditing}  detailD={detail} data={data}/>
                                </TabPane>
                            </TabContent>
                        </TabContainer>
                    </div>
                </div>
            </OrdGenericTemplate>
        </>
    )
}

export default DetailSurveyPerf