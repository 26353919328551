import React, { Component, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import ModalNew from "../../components/Layouts/ModalNew";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { SlideDownButton } from "../../components/Layouts/SlideDownButton";



function CheckInventory(props) {

    let i = 0
    const options = [
        { value: i += 1, label: 'Pepe' },
        { value: i += 1, label: 'Juancho' },
        { value: i += 1, label: 'Valentina' },
        { value: i += 1, label: 'Pepe' },
        { value: i += 1, label: 'Juancho' },
        { value: i += 1, label: 'Valentina1' },
        { value: i += 1, label: 'Pepe' },
        { value: i += 1, label: 'Juancho' },
        { value: i += 1, label: 'Valentina' },
        { value: i += 1, label: 'Pepe' },
        { value: i += 1, label: 'Juancho' },
        { value: i += 1, label: 'Valentina' },
        { value: i += 1, label: 'Pepe' },
        { value: i += 1, label: 'Juancho' },
        { value: i += 1, label: 'Valentina' },
        { value: i += 1, label: 'Pepe' },
        { value: i += 1, label: 'Juancho' },
        { value: i += 1, label: 'Valentina' },
        { value: i += 1, label: 'Pepe' },
        { value: i += 1, label: 'Juancho' },
        { value: i += 1, label: 'Valentina' },
    ]
    const [showModal, setShowModal] = useState(false)
    const [changeRadius, setChangeRadius] = useState("20px");

    const header = [

        <th className="text-center">No. Factura </th>,
        <th className="text-center">Fecha</th>,
        <th className="text-center">Saldo</th>,
        <th className="text-center">Cuenta por pagar</th>,
        <th className="text-center">Valor pagado</th>
    ]
    const body = [
        <tr>
            <td className="text-center">6548</td>
            <td className="text-center">12/05/2021</td>
            <td className="text-center">$8.100.000</td>
            <td className="text-center">326589</td>
            <td className="text-center">$8.000.000</td>
        </tr>

    ]
    const header2 = [
        <th className="text-center"></th>,
        <th className="text-center">No. Orden</th>,
        <th className="text-center">Fecha</th>,
        <th className="text-center">Valor</th>

    ]
    const body2 = [
        <tr>
            <td className="text-center"><input type="checkbox" name="" id="" /></td>
            <td className="text-center">309.092.23982</td>
            <td className="text-center">12/05/2021</td>
            <td className="text-center">$1.990.000</td>
        </tr>
    ]


    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                <h1 className={tableStyles.title}>Inventario de cheques</h1>
                <div>

                    <SlideDownButton direction="row-reverse" bgBody="white" dFlex={true} mLeft="96%" width={"max-content"} bodyWidth={'100%'} body={
                        <>
                            <div className="div">
                                <div className="col-2 display-grid mr-3">
                                    <label className={tableStyles.crudModalLabel}>Tipo de cheque</label>
                                    <select className={IndividualStyles.selectRegister}></select>
                                </div>
                                <div className="col-2 display-grid mr-3">
                                    <label className={tableStyles.crudModalLabel}>Saldo</label>
                                    <select className={IndividualStyles.selectRegister}></select>
                                </div>
                                <div className="col-3 display-grid mr-3">
                                    <label className={tableStyles.crudModalLabel}>NIT</label>
                                    <select className={IndividualStyles.selectRegister}></select>
                                </div>
                                <div className="col-2 display-grid mr-3">
                                    <label className={tableStyles.crudModalLabel}>Banco</label>
                                    <select className={IndividualStyles.selectRegister}></select>
                                </div>
                                <div className="col-2 display-grid mr-3">
                                    <label className={tableStyles.crudModalLabel}>No. de cheque</label>
                                    <input className={IndividualStyles.registerInputs} type="text" name="" id="" />
                                </div>

                            </div>

                            <div className="div">
                                <div className="col-2 display-grid mr-3">
                                    <label className={tableStyles.crudModalLabel}>Desde</label>
                                    <input className={IndividualStyles.registerInputs} type="date" name="" id="" />
                                </div>
                                <div className="col-2 display-grid mr-3">
                                    <label className={tableStyles.crudModalLabel}>Hasta</label>
                                    <input className={IndividualStyles.registerInputs} type="date" name="" id="" />
                                </div>
                                <div className="col-2 display-grid mr-3">
                                    <label className={tableStyles.crudModalLabel}>Valor de</label>
                                    <input className={IndividualStyles.registerInputs} type="text" name="" id="" />
                                </div>
                                <div className="col-2 display-grid mr-3">
                                    <label className={tableStyles.crudModalLabel}>Valor hasta</label>
                                    <input className={IndividualStyles.registerInputs} type="text" name="" id="" />
                                </div>
                                <div className="col-2 d-block mr-3 mt-4" style={{ alignContent: "end" }}>
                                    <input type="checkbox" name="" id="" />
                                    <label >Cualquier valor</label>
                                </div>
                                <div className="col-1 display-grid  mr-3" style={{ alignContent: "end" }}>
                                    <label className=""> <img src={Lupa} /></label>

                                </div>
                            </div>

                        </>
                    }
                        iconTitle={Lupa}
                    />
                </div>
                <div style={{ width: "10px" }}>
                    <ModalNew
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        title={"Ordenes de pago pendientes"}
                        subtitle={"802.203.265 - Nueva EPS"}
                        btnYesEvent={true}
                        btnYesName={"Seleccionar"}
                        size={"500"}

                    >
                        <Row className="d-flex">
                            <Col xs={5}>
                                <label className={tableStyles.crudModalLabel}>No. Orden de pago</label>
                                <input className={IndividualStyles.registerInputs} type="number"></input>
                            </Col>
                            <Col xs={6}>
                                <label className={tableStyles.crudModalLabel}>Fecha</label>
                                <input className={IndividualStyles.registerInputs} type="date"></input>
                            </Col>
                            <Col xs={1}>
                                <i><img className="mt-4" style={{ width: "25px" }} src={Lupa} alt="" srcset="" /></i>
                            </Col>
                        </Row>
                        <GenericTable
                            headers={header2}
                            children={body2}
                        >
                        </GenericTable>

                    </ModalNew>

                </div>
                <GenericTable
                    headers={header}
                    children={body}
                    dark={true}
                >
                </GenericTable>

                <div className="d-block text-end p-5">
                    <Button className="mr-3">Cancelar</Button>
                    <Button>Guardar</Button>
                </div>
            </div>

        </>
    )

};
export default CheckInventory;