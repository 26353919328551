import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "../../components/Layouts/tableStyle.module.scss";
import select from "../../assets/img/icons/ordGrayBack.svg";
import filterIcon from "../../assets/img/icons/filterIcon.svg";
import addIcon from "../../assets/img/icons/add-check.svg";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import Select from "react-select";
import ordBlueSearch from "../../assets/img/icons/ordBlueSearch.svg";
import OrdTable from "../../OrderingModule/OrderingComponents/OrdTable";
import arrow from "../../assets/img/icons/arrowDarkGrey.svg";
import disabledIcon from "../../assets/img/icons/Ellipse.svg";
import assignedIcon from "../../assets/img/icons/checkOnTable.svg";
import Ellipse from "../../assets/img/icons/Ellipse.svg";
import expandIcon from "../../assets/img/icons/expandIcon.svg";
import personIcon from "../../assets/img/icons/personAddIcon.svg";
import blockIcon from "../../assets/img/icons/blockIcon.svg";
import OrdModal from "../../OrderingModule/OrderingComponents/OrdModal";
import { AntSwitchBlue } from "../../helpers/constants";
import { Box, Drawer } from "@mui/material";
import close from "../../assets/img/icons/appClosePatient.svg";
import CalendarPage from "../../components/Calendar/CalendarPage";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import { useDispatch, useSelector } from 'react-redux';
import { loader, message, swalConfirm } from "../../helpers/helpers";
import { getAllConCenter } from "../../actions/consumptionCenterActions";
const Offices = (tab, setTab) => {
  const dispatch = useDispatch();
  
  const store = useSelector((state) => state);
  const createdBy = store.loginReducer.user_data.id
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const consumptionCenter = store.consumptionCenterReducer.consumption_center || {};

  const [filters, setFilters] = useState({
    siteName:null,
    siteId:null,
    search: null,
    status:null,
    statusName:null,
    show: false,
    eaccount,
    createdBy,
  })

  const [modal, setModal] = useState({
    isEditing:false,
    coceId: null,
    coceDescription: null,
    siteId:null,
    siteName:null,
    crtyId: null,
    crtyName:null,
    show: false,
    name:null,
    status:0,
    eaccount,
    createdBy,
  })

  const [trigger, setTrigger] = useState(0)

  const {
        results: ListSites,
        trigger: getListSites,
        load: loadListSites
    } = useGetMethod()

  const {
        results: ListConsultingRooms,
        trigger: getListConsultingRooms,
        load: loadListConsultingRooms
    } = useGetMethod()

    const {
        results: ListTypesSchedules,
        trigger: getListTypesSchedules,
        load: loadListTypesSchedules
    } = useGetMethod()

    const {
      load: loaderCreateConsulting,
      trigger: createConsulting
  } = usePostMethod();
  
  const [filtersOffices, setFiltersOffices] = useState({
    siteId: "",
    statusId: "",
    search: "",
    showFilters: false,
  });

  const [showDetail, setShowDetail] = useState({
    show: false,
    state: false,
    idRow: "",
  });

  useEffect(() => {
    if (tab !== "Consultorios"){
      setShowDetail({
      show: false,
      state: false,
      idRow: "",
    })
    }
  }, [tab])

  useEffect(() => {
    if (modal.siteId) {
      dispatch(getAllConCenter({
        eaccount,
        lite: 1,
        active: 1,
        handleResponse: 1,
        siteId: modal.siteId
      }));
    }
  }, [dispatch, modal.crtyId, modal.siteId, eaccount]);

  useEffect(()=>{
    getListSites({
      url: `/medical/generals/site/`,
      objFilters: { 
        eaccount,
        status:'enabled'
      },
      token: token
  })
    
  getListTypesSchedules({
      url: `/medical/schedules/consultingRoomType/`,
      token: token
  })

    getListConsultingRooms({
      url: `/medical/resources/consultingRooms/`,
      objFilters: { 
        eaccount,
        // search:''
      },
      token: token
    })
  },[])

  useEffect(()=>{
    if(trigger){
      getListConsultingRooms({
        url: `/medical/resources/consultingRooms/`,
        objFilters: { 
          ...filters
        },
        token: token
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[trigger])


  
  const headers = [
    {
      //   title: "Pacinte",
      //   className: "px-2 col-2 text-start",
    },
    {
      title: "Consultorios",
      className: "px-2 text-start",
    },
    {
      title: "Sede",
      className: "px-2  text-start",
    },
    {
      className: "px-2 text-start",
    },
  ];


  const formatData = (array) => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let status = !item.status ? Ellipse : assignedIcon ;
        let orange = showDetail.state === true && showDetail.idRow === item.id ? styles.ordBgFileTableYellow : "";
        tempList.push(
          <tr
            style={!item.status ? {opacity:'0.5'} :{}}
            key={item?.srvId}
            className={`hover-table-row ${orange} ${styles.cursorPointer}`}
            onClick={()=> 
              setModal(e=>({
                ...e,
                id:item.id,
                isEditing:true, 
                show:true,
                coceId: item.coceId,
                coceDescription: item.coceDescription,
                siteId:item.siteId,
                siteName:item.siteName,
                crtyName:item.crtyName,
                crtyId:item.crtyId,
                name: item.name,
                status:item.status
            }))} 
          >
            <td style={!item.status ? {background:'#F5F7FA'} :{}} className={`text-center px-2 ${orange} `}>
              {
                item.status 
                ? <img alt="statusIcon" src={status} style={{ height: "14px" }} />
                : <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                  <div className={styles.iconCircle}></div>
                </div>  
              }
            </td>
            <td style={!item.status ? {background:'#F5F7FA'} :{}} className={`text-start px-2 ${orange} `}>{item?.name}</td>
            <td style={!item.status ? {background:'#F5F7FA'} :{}} className={`text-start px-2 ${orange} `}>{item?.siteName}</td>
            <td
              style={!item.status ? {background:'#F5F7FA'} :{}} 
              className={`text-start px-2 ${orange} `}
            >
              <img alt="arrowIcon" src={arrow} />
            </td>
          </tr>
        );
      });
    }
    return tempList;
  };

  const closeNewOfficeModal = () => {
    setFilters(state=>({
      ...state,
      siteId: '',
      eaccount,
      createdBy,
      name: '',
      crtyId: '',
      show: false,
      
    }))

    setModal({
      isEditing:false,
      siteId:null,
      siteName:null,
      crtyId: null,
      crtyName:null,
      show: false,
      name:null,
      eaccount,
      createdBy,
      status:0
    })
    // setTriggerTable(triggerTable + 1);
  };

  const renderPersonIcon = () => <Tooltip> Asignar</Tooltip>;
  const renderBlockIcon = () => <Tooltip> Bloquear</Tooltip>;
  const renderExpandTooltip = () => <Tooltip> Vista completa</Tooltip>;

  const [openAssignmentDrawer, setOpenAssignmentDrawer] = useState({
    show: false,
    showAssignment: false,
    menuIsOpen: true,
    showBlock: false,
  });
  const closeAssignmentsDrawer = () => {
    setOpenAssignmentDrawer({ ...openAssignmentDrawer, show: false, menuIsOpen: true, showAssignment: false, showBlock: false });
  };

  const validateFields = ()=>{
    
    if((modal.crtyId !== '' && modal.crtyId !== null) 
      && (modal.name !== '' && modal.name !== null) 
      && (modal.siteId !== '' && modal.siteId !== null)){
      return false
    }
    
    return true
  }


  const submitAddConsulting = ()=>{
    createConsulting({
      url: "/medical/resources/consultingRooms/",
      token: token,
      method: modal.isEditing ? 'PUT' : 'POST',
      body: modal,
      succesAction: (res) => {
        closeNewOfficeModal()
        setTrigger(state=> (state+1))
        message("success", `<span class=${styles.ordClearBlueText}>${res.message}</span>`)
      },
      doAfterException: (res) => {
          swalConfirm({
            icon:'warning',
            title: `<span style=color:#58595b>Advertencia</span>`,
            text: `${res.message}`,
            confirmButtonText: `Aceptar`,
            doAfterConfirm: () => {
              //action post 
            },
          });
      }
  })
  }



  return (
    <>
      {/* Assignment drawer */}

      {/* {(loadListSites || loadListTypesSchedules || loaderCreateConsulting || loadListConsultingRooms) && loader} */}

      <Drawer anchor={"right"} open={openAssignmentDrawer.show} style={{ width: "50%" }}>
        <div style={{ width: "350px", padding: " 1rem 1rem", height: "100%" }} className="">
          <div>
            <Row>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <button onClick={() => closeAssignmentsDrawer()}>
                    <img alt="close" src={close} />
                  </button>
                </div>
              </div>
            </Row>
            {openAssignmentDrawer.menuIsOpen === true ? (
              <div className="px-2">
                <div className="d-flex text center align-self-center justify-content-center">
                  <b className={`${styles.f24} ${styles.tlnTextPrimary} text-center align-self-center d-flex `}> &nbsp;¿Qué desea hacer?</b>{" "}
                  <br />
                </div>
                <button
                  onClick={() => {
                    setOpenAssignmentDrawer({ ...openAssignmentDrawer, showAssignment: true, menuIsOpen: false });
                  }}
                  style={{ width: "259px", height: "150px" }}
                  className={`${styles.appBackBorderBlue} my-4 ${styles.appButtonHover}  p-4 mx-4 d-flex flex-column align-self-center justify-content-center text-center`}
                >
                  <img alt="personIcon" src={personIcon} height="50px" className={`${styles.AppIconHoverTurquoise} align-self-center`} />
                  <span className={`${styles.tlnTextGray} ${styles.appTextHoverTurquoise} ${styles.f16} align-self-center `}>Asignar</span>
                </button>
                <button
                  onClick={() => {
                    setOpenAssignmentDrawer({ ...openAssignmentDrawer, showAssignment: false, showBlock: true , menuIsOpen: false });
                  }}
                  style={{ width: "259px", height: "150px" }}
                  className={`${styles.appBackBorderBlue} my-4 ${styles.appButtonHover}  p-4 mx-4 d-flex flex-column align-self-center justify-content-center text-center`}
                >
                  <img alt="blockIcon" src={blockIcon} height="50px" className={`${styles.AppIconHoverTurquoise} align-self-center`} />
                  <span className={`${styles.tlnTextGray} ${styles.appTextHoverTurquoise} ${styles.f16} align-self-center `}>
                    Bloquear fecha
                  </span>
                </button>
              </div>
            ) : openAssignmentDrawer.showAssignment ? (
              <div className="px-2">
                <div className="">
                  <b className={`${styles.f32} ${styles.tlnTextPrimary} text-center `}> &nbsp;Asignación</b> <br />
                </div>
                <div className="my-2">
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Auxiliar:</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filtersOffices.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFiltersOffices({ ...filtersOffices, siteId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Instrumentador:</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filtersOffices.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFiltersOffices({ ...filtersOffices, siteId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                  <Row>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Desde:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput `}
                          type="time"
                          placeholder="Escriba..."
                          // value={dataConfig.reprog}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, reprog: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hasta:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput ${styles.tlnTextGray}
                    `}
                          type="time"
                          placeholder="Escriba..."
                          // value={dataConfig.labor}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, labor: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Fecha de inicio:</b>
                    </Form.Label>
                    <Form.Control
                      // disabled={!dataConfig.isEditing}
                      className={`ord-roundInput `}
                      type="date"
                      placeholder="Escriba..."
                      // value={dataConfig.reprog}
                      onChange={(e) => {
                        // setDataConfig({ ...dataConfig, reprog: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Repetición:</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filtersOffices.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFiltersOffices({ ...filtersOffices, siteId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                </div>
              </div>
            ) : openAssignmentDrawer.showBlock ? (
              <div className="px-2">
                <div className="">
                  <b className={`${styles.f32} ${styles.tlnTextPrimary} text-center `}> &nbsp;Bloquear fecha</b> <br />
                </div>
                <div className="my-2">
                  <Row>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Desde:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput `}
                          type="date"
                          placeholder="Escriba..."
                          // value={dataConfig.reprog}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, reprog: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hora:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput ${styles.tlnTextGray}
                    `}
                          type="time"
                          placeholder="Escriba..."
                          // value={dataConfig.labor}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, labor: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hasta:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput `}
                          type="date"
                          placeholder="Escriba..."
                          // value={dataConfig.reprog}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, reprog: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-3 text-start" controlId="fName">
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hora:</b>
                        </Form.Label>
                        <Form.Control
                          // disabled={!dataConfig.isEditing}
                          className={`ord-roundInput ${styles.tlnTextGray}
                    `}
                          type="time"
                          placeholder="Escriba..."
                          // value={dataConfig.labor}
                          onChange={(e) => {
                            // setDataConfig({ ...dataConfig, labor: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Repetición:</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      //   options={insurancesOptions}
                      isDisabled={filtersOffices.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      //   value={insurancesOptions.find((x) => x.value === data.insurance)}
                      onChange={(e) => {
                        setFiltersOffices({ ...filtersOffices, siteId: e.value });
                        // getContract(e.value);
                      }}
                    ></Select>
                  </Form.Group>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!openAssignmentDrawer.menuIsOpen && (
          <div
            style={{ width: "350px", padding: " 1rem 2rem", height: "100%" }}
            className="d-flex justify-content-end align-items-end align-self-end mb-4 "
          >
            <div className="px-4">
              <button onClick={() => closeAssignmentsDrawer()} className={`mr-2 btn ${styles.ordBtnSecondary}`}>
                Cancelar
              </button>
              <button
                // onClick={() => handleSubmit()}
                className={`${styles.ordBtnPrimary} btn`}
              >
                Guardar
              </button>
            </div>
          </div>
        )}
      </Drawer>

      <OrdModal
        title={modal.isEditing ? 'Actualizar consultorio' : 'Nuevo consultorio'}
        show={modal.show}
        btnYesName={modal.isEditing ? 'Actualizar': "Guardar"}
        btnYesDisabled={validateFields()} 
        btnNoName={"Cancelar"}
        size={400}
        onHide={() => closeNewOfficeModal()}
        btnYesEvent={() => submitAddConsulting()}
        btnNoEvent={() => closeNewOfficeModal()}
      >
        <div>
          <Row>
            <div>
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <span>
                    {" "}
                    &nbsp;Sede <span className={`${styles.ordOrangeText}`}>*</span>
                  </span>
                </Form.Label>
                <Select noOptionsMessage={() => 'No hay datos'}
                  options={ListSites?.results?.map(e=>({label:e.site,value:e.siteId}))}
                  value={modal.siteId ? {label:modal.siteName, value:modal.siteId}:''}
                  isDisabled={modal.isEditing}
                  className="text-secondary "
                  placeholder={"Seleccione"}
                  styles={ordCustomSelect}
                  onChange={(e) => {
                    setModal(state=>({...state, siteId:e.value, siteName:e.label}));
                  }}
                ></Select>
              </Form.Group>

              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <span>
                    {" "}
                    &nbsp;Tipo <span className={`${styles.ordOrangeText}`}>*</span>
                  </span>
                </Form.Label>
                <Select noOptionsMessage={() => 'No hay datos'}
                  options={ListTypesSchedules?.results?.map(e=>({label:e.name,value:e.id}))}
                  isDisabled={modal.isEditing}
                  className="text-secondary"
                  placeholder={"Seleccione"}
                  styles={ordCustomSelect}
                  value={ modal.crtyId ? {label:modal.crtyName, value:modal.crtyId}:''}
                  onChange={(e) => {
                    setModal(state=>({...state, crtyId:e.value, crtyName:e.label}));
                  }}
                ></Select>
              </Form.Group>

              {modal.crtyName !== "Virtual" && (
                <Form.Group className="mb-3 text-start" controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span>
                      {" "}
                      &nbsp;Centro de consumo
                    </span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={consumptionCenter?.results?.map(e => ({ label: e.description, value: e.id }))}
                    className="text-secondary"
                    placeholder={"Seleccione"}
                    styles={ordCustomSelect}
                    value={modal.coceId ? { label: modal.coceDescription, value: modal.coceId } : ''}
                    onChange={(e) => {
                      setModal(state => ({ ...state, coceId: e.value, coceDescription: e.label }));
                    }}
                  />
                </Form.Group>
              )}

            </div>
            <div>
              <Form.Group className="mb-3 text-start" controlId="fName">
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <span>
                    {" "}
                    &nbsp;Nombre <span className={`${styles.ordOrangeText}`}>*</span>
                  </span>
                </Form.Label>
                <Form.Control
                  className={`ord-roundInput text-secondary textGrayPlaceholder`}
                  type="text"
                  placeholder="Escribir ..."
                  value={modal.name}
                  onChange={(e) => {
                    setModal(state=>({...state,name:e.target.value}))
                  }}
                />
              </Form.Group>
            </div>
            
            {
              modal.isEditing 
              ? <div>

              <label htmlFor="material-switch" className='d-flex align-items-center justify-content-start my-2 gap-2'>
                <AntSwitchBlue 
                    checked={modal.status}
                    onChange={((e)=>{
                      setModal(state=>({...state,status:e.target.checked}))
                    })}
                    inputProps={{ "aria-label": "ant design" }}
                />
                <span className={styles.labelFilterSwitch}>{modal.status ? 'Habilitado' : 'Deshabilitado'}</span>
                </label>
            </div> :''
            }
          </Row>
        </div>
      </OrdModal>



      <Box display={'grid'} gridTemplateColumns='1fr 1fr'>
        <Box>
          <Row className="pt-3">
            <Col xs={6}>
              <button
                onClick={() => {
                  setFiltersOffices({ ...filtersOffices, showFilters: !filtersOffices.showFilters });
                  setFilters({
                    siteName:null,
                    siteId:null,
                    search: null,
                    status:null,
                    statusName:null,
                    show: false,
                    eaccount,
                    createdBy,
                  });
                  setTrigger(state=>state+1)
                }}
                className={`${styles.appButtonHover}`}
              >
                <img alt="filterIcon" src={filterIcon} className={`${styles.AppIconHoverTurquoise}`} />
                <b className={`${styles.tlnTextGray} ${styles.appTextHoverTurquoise} ${ styles.f14} mx-2`}>Filtrar</b>
              </button>
            </Col>
            <Col xs={6}>
              <button onClick={() => setModal(state=>({ ...state, show: true, isEditing:false }))} className={`${styles.appButtonHover}`}>
                <img alt="addIcon" src={addIcon} className={`${styles.AppIconHoverTurquoise}`} />
                <b className={`${styles.tlnTextGray} ${styles.f14} ${styles.appTextHoverTurquoise} mx-2`}>Agregar consultorio </b>
              </button>
            </Col>
            {filtersOffices.showFilters && (
              <>
                <Col xs={6} className="mt-2">
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <span> &nbsp;Sede</span>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={ListSites.results.map(e=>({value:e.siteId, label:e.site}))}
                      // isDisabled={filtersOffices.siteId}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      value={{value:filters.siteId, label:filters.siteName}}
                      onChange={(e) => {
                        setFilters(state=>({...state, siteId:e.value, siteName:e.label}));
                        setTrigger(state=>state+1)
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={6} className="mt-2">
                  <Form.Group className="mb-3 text-start" controlId="fName">
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <b> &nbsp;Estado</b>
                    </Form.Label>
                    <Select noOptionsMessage={() => 'No hay datos'}
                      options={[{value:1,label:'Habilitados'},{value:0, label:'Deshabilitados'}]}
                      className="text-secondary "
                      placeholder={"Seleccione"}
                      styles={ordCustomSelect}
                      value={{value:filters.status,label:filters.statusName}}
                      onChange={(e) => {
                        setFilters(state=>({...state, status:e.value, statusName:e.label}));
                        setTrigger(state=>state+1)
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <div>
                  <div className={`d-flex ${styles.ordGrayInputSearch} mt-2`}>
                    <Form.Control
                      style={{ backGroundColor: "red" }}
                      className={`ord-roundInput`}
                      type="text"
                      placeholder="Escribe aquí para buscar"
                      autoComplete="off"
                      onChange={(e) => {
                        setFilters(state=>({...state, search:e.target.value}));
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setTrigger(state=>state+1)
                        }
                      }}
                    />
                    <img src={ordBlueSearch} className="p-2" alt="buscar"></img>
                  </div>
                </div>
              </>
            )}
          </Row>
          <Row>
            <div style={{ height: "70vh", overflow: "auto" }} className="mt-2">
              <OrdTable headers={headers} hasChildren={true} oneHeadDarkBlue={false} lowerCase={false}>
                {formatData(ListConsultingRooms?.results)}
              </OrdTable>
            </div>
          </Row>
        </Box>

        <Box className="d-flex flex-column">
          <div className="d-flex justify-content-end mx-2 pt-3">
            {showDetail.show && (
              <>
                <div className="d-flex justify-content-end align-self-end mb-1 ">
                  <AntSwitchBlue
                    className="mr-2 "
                    defaultChecked
                    // key={trigger + "Ant"}
                    // checked={data.modality === "on_site" ? false : true}
                    // onChange={(e) => handleChangeStatus(e.target.checked)}
                    // onClick={() => modalitySwitch()}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>
                <div>
                  <span className={`${styles.tlnTextGray} ${styles.f12} mr-2`}> Habilitar </span>
                </div>
                <div className="d-flex justify-content-end mr-2 ">
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderPersonIcon()}>
                    <button onClick={() => setOpenAssignmentDrawer({ ...openAssignmentDrawer, show: true })}>
                      <img alt="personIcon" src={personIcon} className={`${styles.filterHoverBlue}`} />
                    </button>
                  </OverlayTrigger>
                </div>
                <div className="d-flex justify-content-end mr-2">
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderBlockIcon()}>
                    <button>
                      <img alt="blockIcon" src={blockIcon} className={`${styles.filterHoverBlue}`} />
                    </button>
                  </OverlayTrigger>
                </div>
                <div className="d-flex justify-content-end ">
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderExpandTooltip()}>
                <button>
                  <img alt="expandIcon" src={expandIcon} className={`${styles.filterHoverBlue}`} />
                </button>
              </OverlayTrigger>
            </div>
              </>
            )}

          
          </div>

          <div className="d-flex justify-content-center w-100 h-100">
            {showDetail.show ? (
              <div className="pt-3 text-end w-100 ml-5 mb-2">
                <CalendarPage
                optionalDoctor={true}
                views={["week"]}
                container
                className="w-100 ml-5"
                />
              </div>
            ) : (
              <div className="d-flex align-items-center flex-column align-self-center text-center justify-content-center h-100">
                <span className={`${styles.tlnTextGray} ${styles.f20}`}>Selecciona un consultorio</span>
                <img alt="select" src={select} width="25px" className="align-self-center text-center" />
              </div>
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Offices;
