import React from "react";
import Pagination from "react-js-pagination";
import Search from "../../../../assets/img/icons/lupa.svg";
import check from "../../../../assets/img/icons/check2.svg";
import GenericTableNew from "../../../Layouts/GenericTableNew";
import "reactjs-popup/dist/index.css";
import "../../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../../../components/Layouts//tableStyle.module.scss";
import paginationStyles from "../../../../components/Layouts//pagination.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Grid } from "@mui/material";
import tableStyles2 from "../../../../components/Layouts/tableStyle.module.scss";
import OrdModal from '../../../../OrderingModule/OrderingComponents/OrdModal';
import {convertMoneyFormat, swalConfirm} from '../../../../helpers/helpers'

export const Interests = ({dataPage, data, setData, severancePayrollResults,  setEmployeeDetail, EmployeeDetail}) => {
  const {filters,modal} = data

  const renderElement = (elem) => {
    return (
      <tr key={elem?.severanceId}>
        <td className={`col-1 px-3 text-start`}>
          {elem?.docNumber ?? "-"}
        </td>
        <td className="col-2 text-start">
        <u className="cursorPointer"
          onClick={()=>{
            setEmployeeDetail({...EmployeeDetail, show: true, dataEmployee: elem, dataPayroll: dataPage, page:"severance" })
          }}
          >
        {elem?.candidateFullName ?? "-"}
          </u>
        </td>
        <td className="col-1 text-end">
        {convertMoneyFormat(elem?.averageSalary ?? "-")}
        </td>
        <td className="col-2 text-center">
        {elem?.totalDaysWork ?? "-"}
        </td>
        <td className="col-2 text-end">
        {convertMoneyFormat(elem?.severanceInterest ?? "-")}
        </td>
        <td className="col-2 text-end">
        {convertMoneyFormat(elem?.severanceInterestInsc ?? "-")}
        </td>
        <td className="col-2 text-end px-3">
        {convertMoneyFormat((Number(elem?.severanceInterest) + Number(elem?.severanceInterestInsc)).toFixed(2))  ?? "-"}
        </td>
      </tr>
    );
  };
  const renderHeaders = [
    <th key={1} className="text-start col-1 px-3">
      ID
    </th>,

    <th key={2} className="text-start col-2">
      Empleado
    </th>,

    <th key={3} className="text-end col-1">
      Salario
    </th>,

    <th key={4} className="text-center col-2">
      Días laborados
    </th>,

    <th key={5} className="text-end col-2">
      Intereses
    </th>,

    <th key={6} className="text-end col-2">
      Intereses INCS
    </th>,

    <th key={7} className="text-end col-2 px-3">
      Total a pagar
    </th>,

  ];
  const closeModal = ()=>{
    setData(state=>({...state, modal:{
      show:false,
      isEditing:false,
      denial:''
    }}))
  }
  const validationRequest = ()=>{
    if(modal.denial){
      setData((e)=>({...e, modal:{...modal, show:false}}))
      swalConfirm({
        icon:'success',
        title: `Planilla denegada exitosamente`,
        text: `Se ha denegado la planilla ${`${dataPage.detailPage.month} ${dataPage.detailPage.range} / ${dataPage.detailPage.year}`}`,
        confirmButtonText: `Aceptar`,
        
        doAfterConfirm: () => {
            dataPage.setDetailPage(null)
            // updateSite({
            //     url: "/medical/admissions/sites/",
            //     token: token,
            //     method: "PUT",
            //     body: { ...item, status: 0, eaccount, createdBy, sites: item.sites?.map(e => (e.siteId)) },
            //     succesAction: (res) => {
            //         closeModal()
            //         setData(state => ({ ...state, table: { ...state.table, trigger: table.trigger + 1 } }));
            //         message("success", `<span class=${tableStyles.ordClearBlueText}>Registro deshabilitado</span>`)
            //     },
            //     doAfterException: (res) => {
            //         message("success", `<span class=${tableStyles.ordClearBlueText}>${res.message}</span>`)
            //     }
            // })

        },
    });
    }
  }
  return (
    <>
    <OrdModal
      title={modal.isEditing ? "" : "Denegar planilla"}
      show={modal.show}
      btnYesName={modal.isEditing ? "" : "Enviar"}
      btnNoName={"Cancelar"}
      size={550}
      onHide={() => closeModal()}
      btnYesEvent={() => validationRequest()}
      btnNoEvent={() => closeModal()}
      btnYesDisabled={!modal.denial}
      sizeBody={`${tableStyles2.modalHeight}`}
      titleClassName={tableStyles2.darkBlueText}
      btnYesStyle={modal.denial ? tableStyles2.btnYesStyle :tableStyles2.ordBtnPrimaryDisabled}
      btnCancelStyle={tableStyles2.btnCancelStyle}
      iconBtnYes
      iconBtnYesColor={tableStyles2.svgWhiteFilter }
      iconSrc={check}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <label className={tableStyles.labelTex}>
            Devengados
          </label>
          <textarea
              placeholder={"Escribir..."}
              rows="25"
              cols="55"
              style={{ height: "8rem" }}
              value={modal.denial}
              className={`text-secondary ord-roundInput w-100 focusBlueVisible`}
              onChange={(e) =>
                setData(state=>({...state,modal:{...state.modal,denial:e.target.value}}))
              }
          ></textarea>
          <label className={tableStyles.labelText2}>
            *El mensaje será enviado al autor de la planilla
          </label>
        </Grid>
      </Grid>
    </OrdModal>
    <div>
     <Grid container spacing={3}>
     <Grid item xs={2}>
        <p className={tableStyles.crudModalLabel}>Días menores a</p>
        <input
         onChange={(e) => 
          setData(state=>({...state, filters:{...state.filters, daysToPay: e.target.value}}))
        }
         type="number"
         value={filters.daysToPay}
         placeholder="Escribir..."
         className={`register-inputs`}
         name="position"
         onKeyDown={e => {
          if (e.key === 'Enter') {
            setData(state=>({...state, trigger: state.trigger+1}))
          }
        }}
         />
     </Grid>
        <Grid item xs={6} className='d-flex align-items-end justify-content-end'>
          <input
            className={`${tableStyles.SearchNew} mr-2`}
            style={{ flex: 1, color: `#73a6dc` }}
            name="search"
            type="text"
            placeholder="Buscar por ID, Empleado, Días laborados..."
            value={filters.search}
            onChange={(e)=>
              setData(state=>({...state, filters:{...state.filters, search: e.target.value}}))
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setData(state=>({...state, trigger: state.trigger+1}))
              }
            }}
          />
          <img
            src={Search}
            style={{ cursor: "pointer" }}
            alt="Search icon"
            height="24px"
            onClick={() => {
              setData(state=>({...state, trigger: state.trigger+1}))
            }}
          />
        </Grid>
     </Grid>
     <Grid container>
        <Grid item xs={12}>
          <GenericTableNew headers={renderHeaders} dark={true}>
            {
              severancePayrollResults?.results?.length > 0 &&
              severancePayrollResults?.results?.map((el)=>{
                return renderElement(el);
              })
            }
          </GenericTableNew>
        </Grid>
        <Grid item xs={12}>
        <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {severancePayrollResults?.results ? filters.page : ""}
              {" de "}
              {Math.ceil(severancePayrollResults?.rowTotal / filters.perpage)
                ? Math.ceil(severancePayrollResults?.rowTotal / filters.perpage)
                : ""}{" "}
              ({severancePayrollResults?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={severancePayrollResults?.rowTotal}
              pageRangeDisplayed={5}
              onChange={(e) => 
                setData(state=>({...state, filters:{...state.filters, page: e}, trigger:state.trigger + 1}))

              }
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </Grid>
     </Grid>
    </div>
    </>
  );
}
