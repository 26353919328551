import React, { Component } from "react";
import tableStyles from "../Layouts/tableStyle.module.scss";
import GenericTable from "../Layouts/GenericTable";
import Pagination from "react-js-pagination";
import paginationStyles from "../Layouts/pagination.module.scss";
import { connect } from "react-redux";
import Trash from "../../assets/img/icons/canecaGris.svg";
import "./specialties_CSS.scss"

class TableSubspecialty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: "Crear subespecialidades",
      subspecialities: {
        description: "",
        id: "",
        enabled: true,
        id_speciality: "",
      },
      filters: {
        page: 1,
        search: "",
        perpage: 5,
      },
      headerState: ["Subespecialidades", ""],
      editRecord: false,
      modal: false,
    };
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });    
  }


  toggleModal = (e) => {
    this.getInitialState();
    this.setState({
      modal: !this.state.modal,
    });
  };

  getInitialState = () => {
    this.setState({
      subspecialities: {
        description: "",
        id: "",
        enabled: true,
        id_speciality: "",
      },
      filters: {
        page: 1,
        search: "",
        perpage: 5,
      },
      headerState: ["Subespecialidades", ""],
      editRecord: false,
      modal: false,
    });
  };


  renderSubSpecialities(subspecialities, i) {
    return (
      <tr
        key={i}
        className="hover-table-row text-center align-middle"
      >
        <td className = "col-md-10">{subspecialities.description}</td>
        <td className = "col-md-2">
          <img
              src={Trash}
              alt="trash icon"
              className={tableStyles.iconSvg, "trashImage"}
              onClick={() => {
                this.props.removeSubspecialty(i);
                }
              }
            />
        </td>
      </tr>
    );
  }


  render() {
    let listSubSpecialities;
    if(Array.isArray(this.props.specialty?.subspecialty)){
      let subsp = this.props.specialty.subspecialty
      
      subsp =  subsp.slice(this.state.filters.perpage*(this.state.filters.page-1),  this.state.filters.perpage*(this.state.filters.page));
      listSubSpecialities = subsp.map((medidas, i) => {
        return this.renderSubSpecialities(medidas, i);
      });
    }
    
    let renderHeaders = this.state.headerState.map((header) => {
      return (
        <th>
          <div
            className={
              header === "est." || header === "" ? tableStyles.groupElems : ""
            }
          >
            {header}
          </div>
        </th>
      );
    });
    return (
      <div className={tableStyles.container}>
        <GenericTable headers={renderHeaders} >
          {listSubSpecialities}
        </GenericTable>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag.{" "}
            {this.props.specialty.subspecialty.length ? this.state?.filters?.page : ""}
            {" de "}
            {Math.ceil(
              this.props.specialty.subspecialty.length / this.state?.filters?.perpage
            )
              ? Math.ceil(
                  this.props.specialty.subspecialty.length / this.state?.filters?.perpage
                )
              : ""}{" "}
            ({this.props.specialty.subspecialty.length} encontrados)
          </p>
          <Pagination
            activePage={this.state?.filters?.page}
            itemsCountPerPage={5}
            totalItemsCount={this.props.specialty.subspecialty.length}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange.bind(this)}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
  subspecialities: state.subspecialitiesReducer.subspecialities,
  totalsubspecialities: state.subspecialitiesReducer.totalsubspecialities,
});

const mapDispatchToProps = (dispatch) => ({
  
});
export default connect(mapStateToProps, mapDispatchToProps)(TableSubspecialty);
