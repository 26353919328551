import React, { useEffect, useState } from "react";

import Agregar from "../../assets/img/icons/agregarEspecialidad.svg";
import Pagination from "react-js-pagination";
import Search from "../../assets/img/icons/lupa.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import GenericTableNew from "../Layouts/GenericTableNew";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import valeStyles from "../TableAccAccounts/AccAccounts_CSS.module.scss";
import Styles from "../../components/PayRollModule/Hiring/Contract.module.scss";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../Layouts/pagination.module.scss";
import { Col, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import { message } from "../../helpers/helpers";
import Loader from "react-loader-spinner";
import {
  changeResolutionBillStatus,
  getBillingResolutionList,
} from "../../actions/bankNoteActions";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
export const BillingResolution = (props) => {
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "reFac"
    );
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const billResolutionList = store.bankNoteReducer?.billingResolutionList;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: idEnterprise,
    // eaccount: 31,
    search: "",
    status: "",
  });

  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/administracion/inicio");
    }
    dispatch(getBillingResolutionList(filters));
    setIsLoading(false)
  }, [filters.page, trigger]);

  const renderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className="col-md-2 text-center" style={{ paddingLeft: "0.5rem" }}>
          {elem.type}
        </td>
        <td className="col-md-1 text-start" style={{ paddingLeft: "0.5rem" }}>
          {elem.prefix}
        </td>
        <td className="col-md-2 text-start">{elem.nbr}</td>
        <td className="col-md-2 text-center">{elem.origin}</td>
        <td className="col-md-2 text-center">{elem.end}</td>
        <td className="col-md-2 text-center">{elem.sequence}</td>

        <td className="col-md-1 text-center cursorPointer">
          <div
            className={
              elem.status === "Habilitado"
                ? tableStyles.greenState
                : tableStyles.redState
            }
          >
            {elem.status === "Habilitado" ? "Habilitado" : "Inhabilitado"}
          </div>
        </td>
        <td className="col-md-1 text-center cursorPointer">
          <div>
            {
              myPermission?.edit && (
                <CustomPopupExtend
                  noHover
                  triggerSrc={threeDots}
                  showEdit={myPermission?.edit}
                  editClickEvent={() =>
                    history.push({
                      pathname:
                        "/administracion/Facturacion/ResolucionesFacturacion/nueva",
                      state: {
                        billInfo: elem,
                        isEditing: true,
                      },
                    })
                  }
                  isEnabled={elem.status === "Habilitado"}
                  showEnable={myPermission?.edit}
                  enableClickEvent={() =>

                    customSwaltAlert({
                      icon: "warning",
                      title:'¿Está seguro?',
                        // elem.status === "Habilitado"
                        //   ? "Deshabilitar resolución"
                        //   : "Habilitar resolución",
                      text:
                        elem.status === "Habilitado"
                          ? `Se va deshabilitar la resolución: ${elem.nbr}`
                          : `Se va habilitar la resolución: ${elem.nbr}`,
                      showCancelButton: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        const send = async () => {
                          setIsLoading(true);
                          try {
                            let result = await changeResolutionBillStatus(
                              {
                                eaccount: idEnterprise,
                                // eaccount: 31,
                                id: elem.id,
                              },
                              token
                            );
                            if (result.success) {
                              setIsLoading(false);
                              setTrigger(trigger + 1);

                              customSwaltAlert({
                                icon: 'success',
                                title:'Actualizado exitosamente',
                                text: `Se ha ${elem.status === "Habilitado" ? 'deshabilitado' : 'habilitado'} la resolución: ${elem.nbr}`,
                                showCancelButton: false,
                              })
                              
                            } else {
                              setIsLoading(false);
                              return customSwaltAlert({
                                icon: 'warning',
                                title:'Intenta de nuevo',
                                text: result.message,
                                showCancelButton: false,
                              })
                              
                            }
                          } catch (error) {
                            setIsLoading(false);
                            customSwaltAlert({
                              icon: 'warning',
                              title:'Intenta de nuevo',
                              text: error.message,
                              showCancelButton: false,
                            })
                          }
                        };
                        send();
                      }
                    })
                  }
                />
              )
            }
          </div>
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (billResolutionList !== undefined) {
      const elem2 = billResolutionList;
      if (elem2.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className="text-center">
      &nbsp; Tipo
    </th>,
    <th key={2} className="text-start">
      &nbsp; Prefijo
    </th>,
    <th key={3} className="text-start">
      # Resolución
    </th>,
    <th key={4} className="text-center">
      Primera factura
    </th>,
    <th key={5} className="text-center">
      Última factura
    </th>,
    <th key={6} className="text-center">
      Num. Actual
    </th>,
    <th key={7} className="text-center w100">
      Estado
    </th>,
    <th key={8}>&nbsp;</th>,
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      {isLoading ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      {store?.bankNoteReducer?.billResList_loading ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Resoluciones de facturación</h1>
          {
            myPermission?.read && (
              <div className="row">
                <div className="col-2">
                  <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
                </div>

                <div className="col-2">
                  <p className={`${tableStyles.crudModalLabel}`}>Tipo</p>
                </div>

              </div>
            )
          }

          <Row className="align-items-center">
            {
              myPermission?.read && (
                <>
                  <Col className="d-block" xs={2}>
                    <form onSubmit={(e) => handleSearch(e)}>

                      <Select 
                        noOptionsMessage={() => 'No hay datos'}
                        onChange={(e) => {
                          setFilters({ ...filters, status: e.value })
                          setIsLoading(true)
                          setTrigger(trigger + 1);
                        }}
                        options={[
                          { value: "", label: "Seleccionar..." },
                          { value: "Habilitado", label: "Habilitado" },
                          { value: "Deshabilitado", label: "Deshabilitado" },
                        ]}
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNewDark}
                      ></Select>
                    </form>
                  </Col>

                  <Col className="d-block" xs={2}>
                    <form onSubmit={(e) => handleSearch(e)}>

                      <Select 
                        noOptionsMessage={() => 'No hay datos'}
                        onChange={(e) => {
                          setFilters({ ...filters, type: e.value })
                          setIsLoading(true)
                          setTrigger(trigger + 1);
                        }}
                        options={[
                          { value: "", label: "Seleccionar..." },
                          { value: "Habilita", label: "Habilita" },
                          { value: "Autoriza", label: "Autoriza" },
                        ]}
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNewDark}
                      ></Select>{" "}
                    </form>
                  </Col>

                  <Col className="d-block" xs={5}>
                    <div className="">
                      <form onSubmit={(e) => handleSearch(e)}>
                        <input
                          className={tableStyles.searchUsersAlt}
                          onChange={(e) =>
                            setFilters({ ...filters, search: e.target.value })
                          }
                          name="search"
                          type="text"
                          placeholder="Buscar..."
                          style={{
                            marginTop: '2px',
                            height: '25px'
                          }}
                        />
                        <img
                          src={Search}
                          className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                          width={"30px"}
                          style={{ marginBottom: "2px", width: '1.4rem' }}
                          alt="Search icon"
                          onClick={(e) => handleSearch(e)}
                        />
                      </form>
                    </div>
                  </Col>
                </>
              )
            }

            {
              myPermission?.create && (
                <Col style={{marginLeft:'auto'}} xs={3}
                  onClick={(e) =>
                    history.push(
                      "/administracion/Facturacion/ResolucionesFacturacion/nueva"
                    )
                  }
                >
                  <div style={{ display: 'flex', marginLeft: 'auto' }}
                    className={`w210  groupBounding2 groupAddButton align-items-center`}>
                    <label htmlFor="newAccident" className="darkGray fw-bold px-2">
                      Nueva resolución
                    </label>

                    <button
                      className="addCheckButton mx-2"
                      style={{ marginBottom: "1.5px" }}
                    />
                  </div>



                </Col>
              )
            }

          </Row>

          {
            myPermission?.read && (
              <>
                <GenericTableNew fontFamilyTable={'fontFamilyTable'} headers={renderHeaders} dark={true}>
                  {listElem()}
                </GenericTableNew>

                <div className={paginationStyles.wrapper}>
                  <p className={`${paginationStyles.paginationText} text-secondary`}>
                    Pag.{" "}
                    {store.bankNoteReducer?.billingResolutionList ? filters.page : ""}
                    {" de "}
                    {Math.ceil(store.bankNoteReducer.rowTotal / filters.perpage)
                      ? Math.ceil(store.bankNoteReducer.rowTotal / filters.perpage)
                      : ""}{" "}
                    ({store.bankNoteReducer.rowTotal} encontrados)
                  </p>
                  <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.perpage}
                    totalItemsCount={store.bankNoteReducer.rowTotal}
                    pageRangeDisplayed={5}
                    onChange={(e) => setFilters({ ...filters, page: e })}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              </>
            )
          }



        </div>
      </div>
    </>
  );
};
