import React from "react";
import { Table } from "react-bootstrap";
// import Inventory from "./Inventory.module.scss";
import Inventory from "../Inventory/Inventory.module.scss";
import Paper from "@material-ui/core/Paper";
import TableM from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { Col, Row } from "react-bootstrap";
import TableRow from "@material-ui/core/TableRow";
export const SkeletonTemplate = (props) => {
  const localStyles = {
    blueInputText: {
      color: "#005dbf",
    },
    footerStyles: {
      display: "flex",
      justifyContent: "flex-end",
      width: "310%",
    },
    noBorder: { border: "none", padding: "8px" },
  };
  const classes = {
    root: {
      width: "100%",
    },
    container: {
      maxHeight: 300,
    },
    text: {
      color: "#58595B",
    },
  };
  return (
    <>
      <Table striped size="sm" className={`mt-4`} s>
        <tbody>
          <tr style={{ height: "3rem", backgroundColor: "#58595b54" }}>
            <td style={{ borderTopLeftRadius: "15px" }}></td>
            <td></td>
            <td></td>
            <td style={{ borderTopRightRadius: "10px" }}></td>
          </tr>
          {/* /* ---------------------------------- White --------------------------------- */}
          <tr style={{ height: "2rem" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {/* /* ---------------------------------- Gray ---------------------------------- */}
          <tr style={{ height: "2rem" }}>
            <td></td>
            <td colSpan="2"></td>
            <td></td>
          </tr>
          {/* /* ---------------------------------- White --------------------------------- */}
          <tr style={{ height: "2rem" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {/* /* ---------------------------------- Gray ---------------------------------- */}
          <tr style={{ height: "2rem" }}>
            <td></td>
            <td colSpan="2"></td>
            <td></td>
          </tr>
          {/* /* ---------------------------------- White --------------------------------- */}
          <tr style={{ height: "2rem" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {/* /* ---------------------------------- Gray ---------------------------------- */}
          <tr style={{ height: "2rem" }}>
            <td></td>
            <td colSpan="2"></td>
            <td></td>
          </tr>
          {/* /* ---------------------------------- White --------------------------------- */}
          <tr style={{ height: "2rem" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {/* /* ---------------------------------- Gray ---------------------------------- */}
          <tr style={{ height: "2rem" }}>
            <td></td>
            <td colSpan="2"></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
      {props.footerDisabled ? (
        <></>
      ) : (
        <Row className={`w-100 m-0`}>
          <Col
            xs={9}
            className={`bg-light px-3`}
            style={{ paddingTop: "30px" }}
          >
            <textarea
              style={{ height: "3rem", border: "1px solid gray" }}
              rows="10"
              cols="10"
              disabled={true}
              className={`register-inputs`}
            ></textarea>
          </Col>

          <Col xs={3} className={``} style={{ paddingRight: 0 }}>
            <Paper className={classes.root}>
              <TableContainer style={classes.container}>
                <TableM stickyHeader aria-label="sticky table">
                  <TableRow className={Inventory.stickyValues1}>
                    <TableCell
                      align="left"
                      colSpan={0}
                      style={localStyles.noBorder}
                      className={`${Inventory.stickyValues4}`}
                    >
                      &nbsp;
                    </TableCell>
                    <TableCell
                      style={localStyles.noBorder}
                      align="center"
                      className={`${Inventory.stickyValues4} text-muted`}
                    >
                      &nbsp;
                    </TableCell>
                  </TableRow>
                  <TableRow className={Inventory.stickyValues3}>
                    <TableCell
                      className={"text-muted"}
                      style={localStyles.noBorder}
                      align="left"
                    >
                      &nbsp;
                    </TableCell>
                    <TableCell style={localStyles.noBorder} align="center">
                      &nbsp;
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={localStyles.noBorder}
                    className={Inventory.stickyValues4}
                  >
                    <TableCell
                      style={localStyles.noBorder}
                      align="left"
                      colSpan={0}
                    >
                      &nbsp;
                    </TableCell>
                    <TableCell style={localStyles.noBorder} align="center">
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableM>
              </TableContainer>
            </Paper>
          </Col>
        </Row>
      )}
    </>
  );
};
