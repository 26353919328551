import React from 'react'
import styled from "styled-components";
import { Text } from '../../atoms';
import { useTheme } from "styled-components";
import { ordCustomSelect } from '../../../Layouts/react-select-custom';
import Select from 'react-select';



const StyledBoxSelectText = styled.div`
    padding: ${({ padding }) => padding ? padding : '0px'};
    margin: ${({ margin }) => margin ? margin : '0px'};
    width:  ${({ width }) => width ? width : '296px'};
    display: flex;
    flex-direction: column;
`

export const BoxSelectText = (
    {
        width,
        padding,
        margin,
        arrayOptions,
        backgroundColorSelect,
        titleText,
        handleChangeSelect,
        nameSelect,
        onblurSelect,
        colorSelect,
        fontSizeSelect,
        paddingSelect,
        marginSelect,
        fontWeightSelect,
        valueSelect,
        widthSelect,
        colorText,
        fontWeightText,
        defaultValue
    }) => {

    return (
        <StyledBoxSelectText
            padding={padding}
            margin={margin}
            width={width}
        >

            <Text color={colorText} fontWeight={fontWeightText} title={titleText} margin='0px 0px 0px 2px' />

            <Select noOptionsMessage={() => 'No hay datos'}
                options={arrayOptions}
                className="text-secondary w-100 "
                placeholder={"Seleccionar..."}
                value={valueSelect}
                styles={ordCustomSelect}
                onChange={handleChangeSelect}
                width={widthSelect}
                defaultValue={defaultValue}
            ></Select>



        </StyledBoxSelectText>
    )
}
