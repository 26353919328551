import { API_VERSION, defaultFilters, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import {
  GET_SITES,
  CREATE_SITE_SUCCESS,
  ERROR_CREATE_SITE,
  SET_INDEX_SITE,
  GET_RESOLUTIONS,
  SITE_RED_LOADING,
  GET_SITES_SELECT,
  GET_DISTANCES_SELECT
} from "./actionTypes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { responseSwal } from "../helpers/responseSwal";
import { customSwaltAlert } from './../helpers/customSwaltAlert';

//Fields for sites and messages errors
const requiredFields = {
  description: "descripción",
  address: "dirección",
  active: "estado",
  phone: "teléfono",
  prefix: "prefijo",
  id_account: "cuenta",
  id_province: "departamento",
  id_city: "ciudad",
  id_resolution: "Resolución de facturación",
};

const MySwal = withReactContent(Swal);

export const getSites = (objFilters) => (dispatch, getState) => {
  
  dispatch({
    type: GET_SITES,
    loading: true
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/site/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (!!res) {
        const { data } = res; //Obtenemos la propiedad data de la petición que contiene un array de objetos
        dispatch({
          type: GET_SITES,
          payload: data,
          loading: false
        });
        
        dispatch({
          type: SET_INDEX_SITE,
          payload: res.count,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: GET_SITES,
        payload: [],
        loading: false
      });
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: `Ocurrió un error al traer la información de las sedes`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      });

    })
};

export const getSitesSelect = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_SITES_SELECT,
    loading: true
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/site/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.status === 200) {
        const { results } = res; //Obtenemos la propiedad data de la petición que contiene un array de objetos
        dispatch({
          type: GET_SITES_SELECT,
          payload: results,
          loading: false
        });

        // dispatch({
        //   type: SET_INDEX_SITE,
        //   // payload: res.count,
        // });
      }
    })
    .catch(() => {
      dispatch({
        type: GET_SITES_SELECT,
        payload: [],
        loading: false
      });
      return responseSwal({ icon: "error", title: "Error", text: "Ocurrió un error al traer la información de las sedes" })
    })
};

export const getListDistances = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_SITES_SELECT,
    loading: true
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/distance/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.status === 200) {
        const { results } = res; //Obtenemos la propiedad data de la petición que contiene un array de objetos
        dispatch({
          type: GET_DISTANCES_SELECT,
          payload: results,
          loading: false
        });

        // dispatch({
        //   type: SET_INDEX_SITE,
        //   // payload: res.count,
        // });
      }
    })
    .catch(() => {
      dispatch({
        type: GET_DISTANCES_SELECT,
        payload: [],
        loading: false
      });
      return responseSwal({ icon: "error", title: "Error", text: "Ocurrió un error al traer la información de las sedes" })
    })
};

const findDuplicates = (arrayObject, llave) => {
  let repetidos = []

  if (!arrayObject.length) {
    return []
  }

  for (let i = 0; i < arrayObject.length; i++) {
    for (let j = 0; j < arrayObject.length; j++) {
      if (i !== j && arrayObject[i][llave] === arrayObject[j][llave]) {
        repetidos.push(arrayObject[i])
      }
    }
  }

  return repetidos
}

const validateOnSite = (objOnSite) => {
  let error = 0
  if (objOnSite.length) {

    objOnSite.forEach(el => {

      if (Number(el.distance) <= 0) {
        error = 1
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          // text: "Debe llenar todos los campos para agregar una sede",
          text: `El campo distancia no puede ser menor o igual a cero`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

      }

      if (el.site === '') {
        error = 1
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          // text: "Debe llenar todos los campos para agregar una sede",
          text: `El campo sede no puede estar vació`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

      }

      if (el.unit === '') {
        error = 1
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          // text: "Debe llenar todos los campos para agregar una sede",
          text: `El campo unidad no puede estar vació`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      }



    })

    if (findDuplicates(objOnSite, 'site').length) {
      error = 1
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        // text: "Debe llenar todos los campos para agregar una sede",
        text: `Las sedes no se pueden repetir`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    }


  }
  return error ? 1 : 0
}

export const createSite = (objSite, objFilters, {objOnSite}, doAfter = null) => (dispatch, getState) => {

  const validOnSite = validateOnSite(objOnSite)

  if (validOnSite) {
    return
  }


  let keyError = "";
  for (const prop in objSite) {
    if (Object.hasOwnProperty.call(objSite, prop)) {
      const element = objSite[prop];
      if (requiredFields[prop])
        if (element === "" || element === 0 || element === "0") keyError = requiredFields[prop];
    }
  }
  const created_at = Date.now();
  const updated_at = Date.now();
  objSite["created_at"] = created_at;
  objSite["updated_at"] = updated_at;
  const token = getState().loginReducer.Authorization;
  if (keyError !== "") {
    dispatch({
      type: CREATE_SITE_SUCCESS,
      payload: false
    })

    customSwaltAlert({
      icon: "warning",
      title: "Intenta de nuevo",
      text: "Debe llenar todos los campos para agregar una sede",
      footer: `El campo ${keyError} es obligatorio`,
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })

  } else {
    dispatch({
      type: SITE_RED_LOADING,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/site/`, {
      method: "POST",
      body: objOnSite.length
        ? JSON.stringify({ ...objSite, siteCloses: objOnSite.map(el => ({ id: el.site, distance: el.distance, distanceTypeId: el.unit })) })
        : JSON.stringify({ ...objSite, siteCloses: [] }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: SITE_RED_LOADING,
          loading: false,
        });
        if (res.status || !res.message) {

          customSwaltAlert({
            icon: "success",
            title: "Creada exitosamente",
            text: `Se ha creado la sede ${objSite?.description}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

          dispatch({
            type: CREATE_SITE_SUCCESS,
            payload: true,
          });
          dispatch(getSites(objFilters));
          doAfter()
          if (!!doAfter) doAfter();
        } else {
          console.error(res.message);

          customSwaltAlert({
            icon: "warning",
            title: "Intente de nuevo",
            text: res.message,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: SITE_RED_LOADING,
          loading: false,
        });
        console.error(err);
        dispatch({
          type: ERROR_CREATE_SITE,
          payload: err,
        });
      });
  }
};

export const updateSite = (objSite, objFilters, objOnSite, doAfter ) => (dispatch, getState) => {

  const validOnSite = validateOnSite(objOnSite)

  if (validOnSite) {
    return
  }

  let keyError = "";
  for (const prop in objSite) {
    if (Object.hasOwnProperty.call(objSite, prop)) {
      const element = objSite[prop];
      if (requiredFields[prop])
        if (element === "") keyError = requiredFields[prop];
    }
  }
  const updated_at = Date.now();
  objSite["updated_at"] = updated_at;
  const token = getState().loginReducer.Authorization;
  if (keyError !== "") {
    dispatch({
      type: CREATE_SITE_SUCCESS,
      payload: false
    })
    return customSwaltAlert({
      icon: "warning",
      title: "Intenta de nuevo",
      text: "Debe llenar todos los campos para agregar una sede",
      footer: `El campo ${keyError} es obligatorio`,
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
    
  } else {
    const token = getState().loginReducer.Authorization;
    dispatch({
      type: SITE_RED_LOADING,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/site/${objSite.id}`, {
      method: "PUT",
      body: objOnSite.length
        ? JSON.stringify({ ...objSite, siteCloses: objOnSite.map(el => ({ id: el.site, distance: el.distance, distanceTypeId: el.unit })) })
        : JSON.stringify({ ...objSite, siteCloses: [] }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: SITE_RED_LOADING,
          loading: false,
        });
        if (res.status === 200 ) {
          
          doAfter();

          dispatch({
            type: CREATE_SITE_SUCCESS,
            payload: true,
          });

          dispatch(getSites(objFilters));

          return customSwaltAlert({
            icon: "success",
            title: "Sede actualizada",
            text: "La sede se actualizó correctamente",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

        } else {
          
          dispatch({
            type: SITE_RED_LOADING,
            loading: false,
          });

          console.error(res.message);

          customSwaltAlert({
            icon: "error",
            title: "Error al actualizar",
            text: res.message,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

        }
      })
      .catch((err) => {
        console.error(err);

        customSwaltAlert({
          icon: "error",
          title: "Error al guardar",
          text: "No se ocurrió un error al guardar los cambios",
          footer: "Si el problema persiste comuníquese con un asesor.",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      });
  }
};

export const changeStatus = (objSite, objFilters) => (dispatch, getState) => {
  const updated_at = Date.now();
  objSite["updated_at"] = updated_at;
  const token = getState().loginReducer.Authorization;
  let status = objSite.active ? "deshabilitará" : "habilitará";
  objSite.active = !objSite.active;
  objSite.active = objSite.active !== false ? 1 : 0;

  customSwaltAlert({
    title: `¿Está seguro?`,
    text:`Se ${status} la sede: ${objSite?.description} `,
    showCancelButton: true
  })
  .then((result) => {
    if (result.isConfirmed) {
      dispatch({
        type: SITE_RED_LOADING,
        loading: true,
      });
      fetch(
        `${URL_GATEWAY}${API_VERSION}/site/change-status/${objSite.id}/status/${objSite.active}/`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          dispatch({
            type: SITE_RED_LOADING,
            loading: false,
          });
          if (res.status || !res.message) {
            let status = res.data.active ? "habilitado" : "deshabilitado";

            customSwaltAlert({
              icon: "success",
              title: "Actualizado exitosamente",
              text: `Se ha ${status} la sede: ${res.data.description}`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })

            dispatch(getSites(objFilters));
          } else {
            dispatch({
              type: SITE_RED_LOADING,
              loading: false,
            });
            console.error(res.message);
          }
        })
        .catch((err) => {
          dispatch({
            type: SITE_RED_LOADING,
            loading: false,
          });
          console.error(err);

          customSwaltAlert({
            icon: "error",
            title: "Error al guardar",
            text: "No se pudo cambiar el estado",
            footer: "Si el problema persiste comuníquese con un asesor.",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

        });
    }
  });
};

export const getResolution = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/invoicingResolution/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (!!res) {
        const { results } = res; //Obtenemos la propiedad data de la petición que contiene un array de objetos
        dispatch({
          type: GET_RESOLUTIONS,
          payload: results,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: GET_RESOLUTIONS,
        payload: [],
      });
      return responseSwal({ icon: "error", title: "Error", text: "Ocurrió un error al traer las resoluciones de facturación" })
    })
};