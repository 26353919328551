import React, { useState } from "react";
import { OrdCard } from "../../OrderingModule/OrderingComponents/OrdCard";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { OrdGenericTemplate } from "../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ordBlueSearch from "../../assets/img/icons/ordBlueSearch.svg";
import { ColorButton } from "../../OrderingModule/OrderingComponents/ColorButton";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { customSwaltAlertAsistencial } from "../../helpers/customSwaltAlertAsistencial";


const AuthorizeRequests = (props) => {

    const store = useSelector((state) => state);
    // const token = store.loginReducer.Authorization;
    const user = store.loginReducer.user_data.id;
    const [filters, setFilters] = useState({
        status: "",
        startDate: "",
        endDate: "",
        search: "",
    });

    const methodUpdate = (idApp, idUser, namePatient, dateQuota, typeProcess) => {
        //console.log(dateQuota, "dateQuota");
        //console.log(dateQuota.split(" ")[2], "dateQuota2");

        customSwaltAlertAsistencial({
            icon: "warning",
            title: "¿Está seguro?",
            text: `Se aprobará la admisión del paciente "Luz Marina Hernandez Palacio" con un saldo pendiente de $300.000`,
            confirmButtonText: "Si, continuar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {

                customSwaltAlertAsistencial({
                    icon: "success",
                    title: "Admisión aprobada",
                    text: `<span>Cita de oftalmología</span> <br/> <span>Paciente: Luz Marina Hernandez Palacio</span>`,
                    confirmButtonText: "Aceptar",
                    showCancelButton: false,
                })
            }
        });
    };


    const renderTooltipAccept = (props) => <Tooltip {...props}>Autorizar</Tooltip>;
    const renderTooltipDeny = (props) => <Tooltip {...props}>Denegar</Tooltip>;

    const titleCard = (e, i) => {
        return (
            <Box
                className={`pb-3 ${tableStyles.ordGrayBorderBottom}`}
                key={i}
                display={'grid'}
                gridTemplateColumns={'4fr 3fr 290px 1fr 1fr'}
                alignItems={'center'}
                gap={1}
            >

                <Box className={`d-flex ${tableStyles.ellipsisAuto}`}>
                    <div>
                        <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                            Solicitud: &nbsp;
                        </span>
                    </div>

                    <div className={tableStyles.ellipsisAuto}>
                        <span className={`text-secondary fw-normal`}>Admitir paciente con saldo pendiente</span>
                    </div>

                </Box>

                <Box className={`d-flex ${tableStyles.ellipsisAuto}`}>
                    <div>
                        <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                            Solicitante: &nbsp;
                        </span>
                    </div>

                    <div className={tableStyles.ellipsisAuto}>
                        <span className={`text-secondary fw-normal`}>Camila Hernandez</span>
                    </div>

                </Box>

                <Box className={`d-flex ${tableStyles.ellipsisAuto}`}>
                    <div>
                        <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                            F.de solicitud: &nbsp;
                        </span>
                    </div>

                    <div>
                        <span className={`text-secondary fw-normal`}>12/12/2021 12:30 am</span>
                    </div>

                </Box>

                <Box className="w-auto">
                    <ColorButton
                        style={{ width: "100%", fontSize: "15px" }}
                        text={'Pendiente'
                            // e?.status === "pending"
                            //     ? "Pendiente"
                            //     : e?.status === "approved"
                            //         ? "Aprobado"
                            //         : "Rechazado"
                        }
                        variant={'danger'
                            // e?.status === "pending"
                            //     ? "danger"
                            //     : e?.status === "approved"
                            //         ? "success"
                            //         : "rejected"
                        }
                    />
                </Box>

                {'pending' === "pending" && (
                    <Box className={`w-auto d-flex gap-2`} key={i}>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipDeny}
                        >
                            <div
                                className={tableStyles.denyCardRed}
                            // onClick={() =>
                            //     rejectedMethod(
                            //         e?.idApp,
                            //         user,
                            //         e?.fullNamePatient,
                            //         e?.dateQuota,
                            //         "rejected"
                            //     )
                            // }
                            >
                            </div>
                        </OverlayTrigger>
                        
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipAccept}
                        >
                            <div
                                className={tableStyles.checkCardGreen}
                                onClick={() =>
                                    methodUpdate(
                                        e?.idApp,
                                        user,
                                        e?.fullNamePatient,
                                        e?.dateQuota,
                                        "approved"
                                    )
                                }
                            >
                                {" "}
                                &nbsp; &nbsp;
                            </div>

                        </OverlayTrigger>
                    </Box>
                )}

            </Box>
        );
    };

    const bodyCard = (e, i) => {
        <Box
            className={`pb-3 ${tableStyles.ordGrayBorderBottom}`}
            key={i}
            display={'grid'}
            gridTemplateColumns={'4fr 3fr 290px 1fr 1fr'}
            alignItems={'center'}
            gap={1}
        >

        </Box>
        return (
            <>
                <Box
                    key={i}
                    display={'grid'}
                    gridTemplateColumns={'4fr 3fr 290px 2fr'}
                    gap={1}
                >
                    <Box>
                        <Box>
                            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
                                Paciente
                            </b>

                            <span className={`${tableStyles.f14} text-secondary fw-normal d-block`}>
                                {" "}Luz marina Hernandez Paraíso
                            </span>

                        </Box>

                        <Box>
                            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
                                Servicio / producto
                            </b>

                            <span className={`${tableStyles.f14} text-secondary fw-normal d-block`}>
                                {" "}Cita postquirurjica
                            </span>

                        </Box>

                        <Box>
                            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
                                Comentarios
                            </b>

                            <span className={`${tableStyles.f14} text-secondary fw-normal d-block`}>
                                {" "}Hermano del sobrino del primo del director de sede
                            </span>
                        </Box>
                    </Box>

                    <Box>
                        <Box>
                            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
                                Identificación
                            </b>

                            <span className={`${tableStyles.f14} text-secondary fw-normal d-block`}>
                                {" "}C.C 1140903610
                            </span>

                        </Box>

                        <Box>
                            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
                                Saldo pendiente
                            </b>

                            <span className={`${tableStyles.f14} text-secondary fw-normal d-block`}>
                                {" "}$300.000
                            </span>

                        </Box>

                    </Box>

                    <Box>
                        <Box>
                            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
                                Aseguradora
                            </b>

                            <span className={`${tableStyles.f14} text-secondary fw-normal d-block`}>
                                {" "}E.P.S y Méd. Prep. Suramericana
                            </span>

                        </Box>

                        <Box>
                            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
                                Saldo a pagar
                            </b>

                            <span className={`${tableStyles.f14} text-secondary fw-normal d-block`}>
                                {" "}$400.000
                            </span>

                        </Box>

                    </Box>

                    <Box display={'flex'} alignItems='center'>
                        <Box>
                            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
                                Desde
                            </b>

                            <span className={`${tableStyles.f14} text-secondary fw-normal d-block`}>
                                {" "}04/10/2010
                            </span>

                        </Box>

                    </Box>

                </Box>


            </>
        );
    };

    return (
        <>
            <div style={{ marginRight: '100px' }}>

                <OrdGenericTemplate
                    className="w-100 mx-2"
                    titleSize={6}
                    colBtnSize={6}
                    colbtnClass={"p-0 m-0"}
                    title={"Autorizar Solicitudes"}
                >
                    <Box className={'mb-3 asistencialDateColorIcon'}>

                        <Box
                            display={'grid'}
                            gridTemplateColumns={'170px 170px 1fr'}
                            gap={2}
                        >

                            <Box>
                                <span className={`m-0 d-block ${tableStyles.ordDarkBlueText} fw-bold`}>&nbsp;Fecha desde</span>
                                <input
                                    className={`ord-roundInput2`}
                                    type="date"
                                    style={filters.startDate ? { color: '#1a538d' } : {}}
                                    onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                                />
                            </Box>

                            <Box>
                                <span className={`m-0 d-block ${tableStyles.ordDarkBlueText} fw-bold`}>&nbsp;Hasta</span>
                                <input
                                    className={`ord-roundInput2`}
                                    type="date"
                                    style={filters.endDate ? { color: '#1a538d' } : {}}
                                    onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                                />
                            </Box>

                            <Box className={`d-flex ${tableStyles.appGrayInputSearch} ${tableStyles.f12} align-self-end`}>
                                <input
                                    className={`ord-roundInput ${tableStyles.appInputDate} w-100`}
                                    type="text"
                                    placeholder="Escribe aquí para buscar"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        // setFilters({ ...filters, filterPatient: e.target.value });
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            // setTrigger(trigger + 1);
                                        }
                                    }}
                                />
                                <img src={ordBlueSearch} className="pointer" alt="buscar"></img>
                            </Box>

                        </Box>

                        {
                            [1, 2, 3].map((e, i) => {
                                return <OrdCard className="my-3" title={titleCard(e, i)} body={bodyCard(e, i)}></OrdCard>;
                            })}
                    </Box>
                </OrdGenericTemplate>
            </div>
        </>
    );
};
export default AuthorizeRequests;
