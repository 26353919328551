import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import moment from "moment";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import atrasIcon from "../../assets/img/icons/atras-icon.svg";
import GenericTableNew from "../Layouts/GenericTableNew";
import Search from "../../assets/img/icons/lupa.svg";
import Authorizate from "../../assets/img/icons/Authorizate.svg";
import { useHistory } from "react-router-dom";
import ModalNew from "../Layouts/ModalNew";
import minModal from "./minModal.module.scss";
import Agregar from "../../assets/img/icons/circleAdd.svg";
import Select from "react-select";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import Pagination from "react-js-pagination";
import canecaGris from "../../assets/img/icons/canecaGris.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import {
  formatToRcSelect,
  message,
  convertDateToLatinFormat,
  isEmptyOrUndefined,
} from "../../helpers/helpers";

import { SkeletonTemplate } from "../Layouts/SkeletonTemplate";
import Swal from "sweetalert2";
import grayOutPut from "../../assets/img/icons/leftGrayDonation.svg";
import {
  getWareHouseList,
  getArticlesInWarehouse,
  getOutputArticles,
  creatMovement,
} from "../../actions/inventoryActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { filter } from "lodash";
import deepcopy from "deepcopy";
import CustomNumUpDown from "../Layouts/CustomNumUpDown/CustomNumUpDown";
import Loader from "react-loader-spinner";
export const Donation = () => {
  const [disableInput, setDisableInput] = useState(true);
  const store = useSelector((state) => state);
  const arrWarehouse = store.inventoryReducer.wareHouseList;
  const arrArticles = store.inventoryReducer.articles;
  const [dataUserInput, setDataUserInput] = useState([]);
  const arrOutputArticles = store.inventoryReducer.outPutLotsArticles;
  const [allow, setAllow] = useState(true);
  const [saveList, setSaveList] = useState([]);
  const [selectedList, setselectedList] = useState([]);
  const [details, setDetails] = useState("");
  const [selectedList2, setSelectedList2] = useState([]);
  const [itemCheck, setItemCheck] = useState([]);
  const [copy, setCopy] = useState();
  const [search, setSearch] = useState({ search: "" });
  const [justification, setJustification] = useState("");
  // const [lots, setlots] = useState([]);
  // const [lotsInfo, setlotsInfo] = useState({
  //   lot: "",
  //   date_expiry: "",
  //   qty: 1,
  //   id_article: "",
  // });
  const [modalLotsControl, setmodalLotsControl] = useState({
    show: false,
  });
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilters] = useState({
    id_warehouse: "",
    page: 1,
    perpage: 10,
  });

  const [lotsFilters, setLotsFilters] = useState({
    id_warehouse_articles: 0,
    page: 1,
    perpage: 10,
    trigger: false,
  });

  let history = useHistory();
  const [fillpTable, setFillpTable] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWareHouseList(store.loginReducer.currentAccount.id));
  }, []);
  useEffect(() => {
    if (filters.id_warehouse !== "") {
      dispatch(getArticlesInWarehouse(filters));
    }
  }, [filters.page, trigger]);
  useEffect(() => {
    if (selectedList.length > 0) {
      dispatch(getOutputArticles(lotsFilters));
    }
  }, [lotsFilters.trigger, lotsFilters.page]);

  let arrWh = formatToRcSelect(
    arrWarehouse,
    "id_warehouse",
    "description_warehouse"
  );
  const [modalArtControl, setmodalArtControl] = useState({
    show: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
    showJustification: false,
  });

  const secondDataFalse = [
    {
      code: "2356",
      desc: "Bimatroprost 0.1 mg/ml solución oftalmica",
      lote: "10",
      cant: "200",
      canRecv: "200",
    },
    {
      code: "2356",
      desc: "Bimatroprost 0.1 mg/ml solución oftalmica",
      lote: "10",
      cant: "200",
      canRecv: "200",
    },
    {
      code: "2356",
      desc: "Bimatroprost 0.1 mg/ml solución oftalmica",
      lote: "10",
      cant: "200",
      canRecv: "200",
    },
  ];
  const thirdDataFalse = [
    {
      validity: "19/02/2019",

      lote: "104549789789845",
      cant: "3",
    },
    {
      validity: "19/02/2019",

      lote: "104549789789845",
      cant: "3",
    },
    {
      validity: "19/02/2019",

      lote: "104549789789845",
      cant: "3",
    },
  ];
  const localStyles = {
    blueInputText: {
      color: "#005dbf",
    },
    footerStyles: {
      display: "flex",
      justifyContent: "flex-end",
      width: "310%",
    },
    noBorder: { border: "none", padding: "8px" },
  };
  let curr = new Date();
  curr.setDate(curr.getDate());
  let date = curr.toISOString().substr(0, 10);

  const isBoxSelected2 = (item) => {
    if (Array.isArray(selectedList2)) {
      let target = selectedList2.find((x) => x.id == item.id);
      return !!target;
    }
  };
  const changeBoxSelected2 = (item) => {
    let tempSelected = selectedList2;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find((x) => x.id == item.id);
      if (!!target) {
        tempSelected = tempSelected.filter((x) => x.id != item.id);
      } else {
        let newChange = {
          ...item,
          lot: item.name,
          date_expiry: item.date_expiration,
        };
        tempSelected.push(newChange);
      }
      setSelectedList2(tempSelected);
    }
  };
  const isBoxAllSelected2 = () => {
    let res = true;
    let counting = 0;
    if (Array.isArray(arrOutputArticles) && Array.isArray(selectedList2)) {
      arrOutputArticles.map((item) => {
        if (selectedList2.find((itemb) => item.id == itemb.id)) {
          counting++;
        } else {
          res = false;
        }
      });
    }
    return res;
  };
  const deleteItems = () => {
    let templist = [...selectedList];
    templist = templist.filter(
      (fullelement) => itemCheck.indexOf(fullelement.id_article) === -1
    );
    setselectedList(templist);
    setSaveList(templist);
  };
  const changeBoxAllSelected2 = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(arrOutputArticles) && Array.isArray(tempSelected)) {
        arrOutputArticles.map((x) => {
          let newChange = {
            ...x,
          };
          tempSelected.push(newChange);
        });
        setSelectedList2(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setSelectedList2([]);
    }
  };
  const handleLots = (elem) => {
    setLotsFilters({
      ...lotsFilters,
      trigger: !lotsFilters.trigger,
      id_warehouse_articles: elem.id_warehouse_articles,
    });
    setmodalLotsControl({
      show: true,
      product: elem.description_article,
      id: elem.id_article,
    });
    setmodalArtControl({
      ...modalArtControl,
      showLots: true,
    });
  };
  const handleChangeInput = (elem, e, i) => {
    let { value, name } = e.target;
    setDataUserInput({
      ...dataUserInput,
      [name]: value,
      id_article: elem.id_article,
      id_warehouse: elem.id_warehouse_articles,
    });

    // let { value, name } = e.target;

    // let copy = deepcopy(dataUserInput);
    // copy[i][name] = value;
    // setDataUserInput(copy);
  };
  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-1 text-center">{elem.id_article}</td>
        <td className="col-md-6 text-start">{elem.description_article}</td>
        <td className="col-md-1 text-center">
          <input
            type="checkbox"
            name=""
            id=""
            checked={isBoxSelected(elem)}
            onChange={(e) => changeBoxSelected(elem)}
          />
        </td>
      </tr>
    );
  };

  const pTableListElem = () => {
    let elemMap;
    if (saveList !== undefined) {
      const elem2 = saveList;
      elemMap = elem2.map((elem, i) => {
        let amount = "-";
        if (!isEmptyOrUndefined(copy)) {
          amount = copy.length > 0 ? copy[i]?.amount : "-";
        }
        return pTableRenderElem(elem, i, amount);
      });
    }
    return elemMap;
  };
  const listElem = () => {
    let elemMap;
    if (arrArticles !== undefined) {
      const elem2 = arrArticles;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const secondListElem = () => {
    let elemMap;
    if (secondDataFalse !== undefined) {
      const elem2 = secondDataFalse;
      elemMap = elem2.map((elem) => {
        return secondRenderElement(elem);
      });
    }
    return elemMap;
  };
  const thirdListElem = () => {
    let elemMap;
    if (thirdDataFalse !== undefined) {
      const elem2 = thirdDataFalse;
      elemMap = elem2.map((elem) => {
        return thirdRenderElement(elem);
      });
    }
    return elemMap;
  };
  const isBoxSelected = (elem) => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find((x) => x.id_article === elem.id_article);
      return !!target;
    }
  };
  const changeBoxSelected = (item) => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find((x) => x.id_article == item.id_article);
      if (!!target) {
        tempSelected = tempSelected.filter(
          (x) => x.id_article != item.id_article
        );
      } else {
        let newChange = {
          ...item,
          // amount_received: item.amount,
          amount_received: 0,
          lot: item.name,
          date_expiry: item.date_expiration,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };
  const btnCreatMovement = () => {
    setmodalArtControl({ ...modalArtControl, showJustification: false });
    dispatch(
      creatMovement({
        wm_account: store.loginReducer.currentAccount.id,
        wm_justification: justification,
        wm_observations: details,
        created_by: store.loginReducer.user_data.id,
        wm_movement_type: "Donation",
        wm_origin: filters.id_warehouse,
        wm_status: "legalized",
        articles: selectedList,
      })
    );
  };
  const isBoxAllSelected = () => {
    let res = true;
    let counting = 0;
    if (Array.isArray(arrArticles) && Array.isArray(selectedList)) {
      arrArticles.map((item) => {
        if (selectedList.find((itemb) => item.id_article == itemb.id_article)) {
          counting++;
        } else {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = selectedList;
      if (Array.isArray(arrArticles) && Array.isArray(tempSelected)) {
        arrArticles.map((x) => {
          let found = selectedList.find(
            (obj) => obj.id_article === x.id_article
          );
          if (found?.id_article !== x.id_article) {
            let newChange = {
              ...x,
              amount_received: 0,
            };
            tempSelected.push(newChange);
          }
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };
  const handleItemsToAdd = (id_article) => {
    let temparray = deepcopy(selectedList);
    let target = temparray.findIndex((x) => x.id_article === id_article);
    if (target >= 0) {
      temparray[target].lots = selectedList2;
      let newammount =
        selectedList2.length > 0 &&
        selectedList2.reduce((prev, current) => {
          return { qty_received: prev.qty_received + current.qty_received };
        });
      temparray[target].amount_received = newammount.qty_received;
      setselectedList(temparray);
      setCopy(temparray);
      let state = false;
      let iguales = 0;
    }

    setSelectedList2([]);
    setmodalArtControl({
      ...modalArtControl,
      showLots: false,
    });
  };
  const editArticleAmmount = (id, ammount, og_val, item) => {
    if (ammount > og_val) {
      ammount = og_val;
    }
    let tempSelected = selectedList2;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.findIndex((x) => x.id === item.id);
      if (target >= 0) {
        tempSelected[target].qty_received = Math.max(1, ammount);
        //tempSelected = tempSelected.filter((x) => x.id != item.id);
      } else {
        let newChange = {
          ...item,
          qty_received: Math.max(1, ammount),
          lot: item.name,
          date_expiry: item.date_expiration,
        };
        tempSelected.push(newChange);
      }
      setSelectedList2(tempSelected);
    }
  };

  const renderTableLots = () => {
    let table = [];
    let amount_received = 0;
    let num_lots = 0;
    if (!isEmptyOrUndefined(arrOutputArticles)) {
      if (arrOutputArticles.length > 0) {
        table = arrOutputArticles.map((x, index) => {
          amount_received = amount_received + x.qty;
          num_lots = num_lots + 1;
          let foundlotvalue = selectedList2.find((item) => item.id == x.id);
          return (
            <tr key={"lots" + index}>
              {/* <td>
                <td className="col-md-1 text-center">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={isBoxSelected2(x)}
                    onChange={(e) => changeBoxSelected2(x)}
                  />
                </td>
              </td> */}
              <td>{moment(x.date_expiration).format("DD/MM/YYYY")}</td>
              <td style={{ textTransform: "uppercase" }}>{x.name}</td>
              <td>
                {foundlotvalue ? foundlotvalue.qty_received : x.qty_received}
                {/* <CustomNumUpDown
                  id={x.id}
                  value={
                    foundlotvalue ? foundlotvalue.qty_received : x.qty_received
                  }
                  onChange={(r_id, r_val) =>
                    editArticleAmmount(x.id, r_val, x.qty_received, x)
                  }
                ></CustomNumUpDown> */}
              </td>
            </tr>
          );
        });
      }
    }
    return table;
  };
  const pTableRenderElem = (elem, i, amount) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-1 text-center">
          <input
            key={"checkbox" + i + "-" + itemCheck.length}
            type="checkbox"
            checked={!!itemCheck.find((myitem) => myitem === elem.id_article)}
            name=""
            id=""
            onChange={(e) => checkToDelete(e, elem, i)}
            // checked={isBoxSelected(elem)}
            // onChange={(e) => changeBoxSelected(elem)}
          />
        </td>
        <td className="col-md-1 text-center">{i + 1}</td>
        <td className="col-md-2 text-center">{elem.id_article}</td>
        <td className="col-md-2 text-start">{elem.description_article}</td>
        <td className="cursorPointer">
          <img
            src={Agregar}
            alt="btnAgregar"
            onClick={() => handleLots(elem)}
          ></img>
        </td>
        <td className="col-md-2 text-info text-center">{elem.available}</td>
        <td className="col-md-2 text-center">
          {elem.available <= 0 ? (
            <input
              value="0"
              className="text-center text-secondary"
              disabled={true}
              style={{
                backgroundColor: "none",
                borderWidth: "0px",
                border: "none",
                background: "none",
                border: "1px solid #01a0f6",
                borderRadius: "5px",
                width: "80%",
              }}
              name={"amount_received"}
            ></input>
          ) : (
            <input
              // type="number"
              min="1"
              className="text-center text-secondary"
              style={{
                backgroundColor: "none",
                borderWidth: "0px",
                border: "none",
                background: "none",
                border: "1px solid #01a0f6",
                borderRadius: "5px",
                width: "80%",
              }}
              onChange={(e) => handleChangeAmount(elem, e, i)}
              name={"amount_received"}
            ></input>
          )}
        </td>
      </tr>
    );
  };
  const checkToDelete = (e, elem, i) => {
    let checked = e.target.checked;

    let nueva = [...itemCheck];
    if (checked) {
      nueva.push(elem.id_article);
    } else {
      nueva = nueva.filter((x) => x !== elem.id_article);
    }
    setItemCheck(nueva);
  };

  const handleChangeAmount = (elem, e, i) => {
    if (e.target.value > elem.available) {
      e.target.value = elem.available;
    }
    if (e.target.value == 0) {
      e.target.value = 1;
    }

    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {

    } else {
      e.target.value = 1;
    }

    //Making a copy of articles in the table
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      //Finding item by id
      let target = tempSelected.findIndex(
        (x) => x.id_article === elem.id_article
      );

      if (target >= 0) {
        //Setting elem.key = round number
        tempSelected[target].amount_received = Math.max(1, e.target.value);
      } else {
        let newChange = {
          ...elem,
          amount_received: Math.max(1, e.target.value),
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };
  const secondRenderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">{elem.code}</td>
        <td className="col-md-4 text-start">{elem.desc}</td>

        <td className="col-md-1 text-center">
          <u
            onClick={() =>
              setmodalArtControl({
                ...modalArtControl,
                showThird: true,
              })
            }
          >
            <b className={tableStyles.darkBlueText}>{elem.lote}</b>
          </u>
        </td>
        <td className="col-md-2 text-center">{elem.cant}</td>
        <td className="col-md-2 text-center">{elem.canRecv}</td>
      </tr>
    );
  };
  const thirdRenderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">
          {moment(elem.validity).format("DD/MM/YYYY")}
        </td>
        <td className="col-md-4 text-center">{elem.lote}</td>
        <td className="col-md-2 text-center">{elem.cant}</td>
      </tr>
    );
  };
  const confirmation = (id) => {
    if (selectedList.length > 0) {
      Swal.fire({
        icon: "warning",
        title: "¿Está seguro?",
        text: "Revise la información",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Confirmar",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (selectedList.length > 0) {
            selectedList.map((e, i) => {
              if (
                isEmptyOrUndefined(e.amount_received) ||
                e.amount_received === 0
              ) {
                setmodalArtControl({
                  ...modalArtControl,
                  showJustification: false,
                });
                return message(
                  "warning",
                  "Advertencia",
                  "Digite la cantidad a donar para cada artículo y/o elimine los artículos no modificados"
                );
              }
              setmodalArtControl({
                ...modalArtControl,
                showJustification: true,
              });
            });
          }
        }
      });
    } else {
      return message(
        "warning",
        "Advertencia!",
        "Debe seleccionar al menos un artículo"
      );
    }
  };
  const pTableIsBoxAllSelected = () => {
    let res = true;
    let counting = 0;
    if (Array.isArray(selectedList) && Array.isArray(itemCheck)) {
      selectedList.map((item) => {
        if (itemCheck.find((itemb) => item.id_article == itemb)) {
          counting++;
        } else {
          res = false;
        }
      });
    }
    return res;
  };

  const pTableCheckThemAll = (checkEmAll) => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(selectedList) && Array.isArray(tempSelected)) {
        selectedList.map((x) => {
          let id = x.id_article;
          let newChange = id;
          tempSelected.push(newChange);
        });
        setItemCheck(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setItemCheck([]);
    }
  };
  const renderHeadersPTable = [
    <th className="col-md-1" style={{ borderTopLeftRadius: "10px" }} key={1}>
      <input
        type="checkbox"
        name=""
        id=""
        checked={pTableIsBoxAllSelected()}
        onChange={(e) => pTableCheckThemAll(!pTableIsBoxAllSelected())}
      />
    </th>,
    <th className="col-md-1" key={2}>
      Item
    </th>,
    <th className="col-md-1" key={3}>
      Código
    </th>,
    <th className="col-md-4" key={4}>
      Descripción de articulo
    </th>,
    <th className="col-md-1" key={5}>
      Lote
    </th>,
    <th className="col-md-1" key={6}>
      Disponible
    </th>,
    <th className="col-md-2" style={{ borderTopRightRadius: "10px" }} key={7}>
      A donar
    </th>,
  ];
  const renderHeadersFirst = [
    <th style={{ borderTopLeftRadius: "10px" }} className="px-2" key={1}>
      Código
    </th>,
    <th className="text-start" key={2}>
      Nombre
    </th>,

    <th style={{ borderTopRightRadius: "10px" }} key={3}>
      <input
        type="checkbox"
        name=""
        id=""
        checked={isBoxAllSelected()}
        onChange={(e) => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
  ];

  const renderHeadersLots = [
    // <th key={`Lots0`}>
    //   <input
    //     type="checkbox"
    //     name=""
    //     id=""
    //     checked={isBoxAllSelected2()}
    //     onChange={(e) => changeBoxAllSelected2(!isBoxAllSelected2())}
    //   />
    // </th>,
    <th key={`Lots1`}>Vencimiento</th>,
    <th key={`Lots2`}>Lote</th>,
    <th key={`Lots3`}>Cantidad</th>,
  ];
  const renderHeadersSecond = [
    <th style={{ borderTopLeftRadius: "10px" }} key={1}>
      Código
    </th>,
    <th key={2}>Descripción de Artículo</th>,
    <th key={3}>Lote</th>,
    <th key={4}>Cantidad ordenada</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={5}>
      Cantidad Recibida
    </th>,
  ];
  const renderHeadersThird = [
    <th style={{ borderTopLeftRadius: "10px" }} key={1}>
      Vencimiento
    </th>,
    <th key={2}>Lote</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={3}>
      Cantidad
    </th>,
  ];
  /* -------------------------- HANDLE CHANGE SECTION ------------------------- */
  useEffect(() => {
    if (selectedList.length > 0) {
      selectedList.map((e) => {
        if (e.lots?.length > 0) {
          e.lots?.map(() => {
            setAllow(false);
          });
        } else {
          setAllow(true);
        }
      });
    }
  }, [selectedList]);
  const handleBack = () => {
    history.push("/compras/registrarMovimiento");
  };

  const handleClickEntrybyStorage = () => {
    setLoading(true)
    setDataUserInput([]);
    setmodalArtControl({
      ...modalArtControl,
      show: true,
    });
    dispatch(getArticlesInWarehouse(filters, () => setLoading(false)));
  };
  const handleBring = () => {
    let selListCopy = deepcopy(selectedList);
    setSaveList(selListCopy);
    setFillpTable(true);
    setDisableInput(false);
    setmodalArtControl({
      ...modalArtControl,
      showSecond: false,
      show: false,
    });
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setTrigger(trigger + 1);
    setFilters({
      ...filters,
      page: 1,
      search: search.search,
    });
  };
  return (
    <>
      {/* MODAL JUSTIFICACIÓN DE DENEGACIÓN*/}
      <ModalNew
        title="Justificación"
        show={modalArtControl.showJustification}
        btnYesName={justification !== "" && "Guardar"}
        btnNoName={"Cancelar"}
        size="400"
        btnYesEvent={justification !== "" ? () => btnCreatMovement() : null}
        btnYesDisabled={false}
        onHide={() =>
          setmodalArtControl({ ...modalArtControl, showJustification: false })
        }
        btnNoEvent={() =>
          setmodalArtControl({ ...modalArtControl, showJustification: false })
        }
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>
          ¿Porqué es necesario hacer este ajuste?
        </p>
        <textarea
          placeholder={"Escribir..."}
          id="txtArea"
          rows="10"
          cols="10"
          onChange={(e) => setJustification(e.target.value)}
          value={justification}
          style={{ height: "4rem" }}
          className={`register-inputs text-secondary`}
        ></textarea>
      </ModalNew>
      {/* /* ------------------------------- First modal ------------------------------ */}
      <ModalNew
        size="700"
        btnYesEvent={selectedList.length > 0 ? () => handleBring() : null}
        title="Artículos"
        btnYesName={"Agregar"}
        show={modalArtControl.show}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            show: false,
          })
        }
      >
        <Row className="d-flex">
          <Col xs={5}>
            <form onSubmit={(e) => handleSearch(e)}>
              <p className={tableStyles.crudModalLabel}>&nbsp;</p>
              <input
                placeHolder="Escribir..."
                className="register-inputs text-secondary"
                name="id_consumption_center"
                type="text"
                value={search.search}
                onChange={(e) =>
                  setSearch({ ...search, search: e.target.value })
                }
              />
            </form>
          </Col>

          <Col xs={1} className={`pt-3`} style={{ "align-self": "end" }}>
            <div>
              <img
                src={Search}
                alt="Search icon"
                className="cursorPointer"
                height={24}
                onClick={(e) => handleSearch(e)}
              />
            </div>
          </Col>
        </Row>

        <GenericTableNew dark={true} headers={renderHeadersFirst}>
          {listElem()}
        </GenericTableNew>

        <div
          className={`${paginationStyles.wrapper}`}
          style={localStyles.endPagination}
        >
          <p className={paginationStyles.paginationText}>
            Pag. {store.inventoryReducer.articles ? filters.page : ""}
            {" de "}
            {Math.ceil(store.inventoryReducer.articles_total / filters.perpage)
              ? Math.ceil(
                  store.inventoryReducer.articles_total / filters.perpage
                )
              : ""}{" "}
            ({store.inventoryReducer.articles_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={store.inventoryReducer.articles_total}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </ModalNew>
      {/* /* ------------------------------ Second modal ------------------------------ */}
      <ModalNew
        size="940"
        title="Entrada No.2365"
        show={modalArtControl.showSecond}
        btnYesEvent={() => handleBring()}
        btnYesName={"Traer"}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            showSecond: false,
          })
        }
      >
        <Row className="d-flex">
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Orden de compra</p>
            <input
              style={localStyles.blueInputText}
              className={` ${tableStyles.articleSearch}  register-inputs`}
              name="requisition_date"
              type="text"
              value={"003"}
              onClick={() =>
                setmodalArtControl({
                  ...modalArtControl,
                  showFourth: true,
                })
              }
            />
          </Col>

          <Col xs={3}>
            <div>
              <p className={tableStyles.crudModalLabel}>Almacén</p>
              <Select noOptionsMessage={() => 'No hay datos'} placeholder="" styles={customSelectNewDark} />
            </div>
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>
              No. de Factura o remisión
            </p>
            <input
              style={localStyles.blueInputText}
              className="register-inputs"
              name="requisition_date"
              type="number"
            />
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Fecha</p>
            <input
              defaultValue={date}
              value={date}
              style={localStyles.blueInputText}
              className="register-inputs text-secondary"
              type="date"
            />
          </Col>
        </Row>

        <GenericTableNew dark={true} headers={renderHeadersSecond}>
          {secondListElem()}
        </GenericTableNew>

        <Col xs={12}>
          <p className={tableStyles.crudModalLabel}>Descripción</p>
          <textarea
            placeholder={"Escribir..."}
            id="txtArea"
            rows="10"
            cols="10"
            style={{ height: "3rem" }}
            className={`register-inputs`}
          ></textarea>
        </Col>
        <Row className={`${tableStyles.clearBlueBg}`}>
          <Row>
            <p className={`${tableStyles.fluorBlue} ml-2 pt-2`}>
              <img className={"ml-3"} src={Authorizate}></img>
              &nbsp; Entrada autorizada
            </p>
          </Row>
          <Col xs={4}>
            <p className={tableStyles.crudModalLabel}>¿Cúal era el problema?</p>
            <Select noOptionsMessage={() => 'No hay datos'} placeholder="" styles={customSelectNewDark} />
          </Col>
          <Col xs={8}>
            <p className={tableStyles.crudModalLabel}>Información adicional</p>
            <input className="register-inputs" type="text" />
          </Col>
        </Row>
      </ModalNew>
      {/* /* ------------------------------ Third modal ------------------------------ */}
      {/* MODAL DE LOTES*/}
      <ModalNew
        title={"Lotes"}
        show={modalArtControl.showLots}
        btnYesName={"Aceptar"}
        btnNoName={"Cancelar"}
        // btnYesEvent={
        //   lots.length > 0 ? () => handleItemsToAdd(modalLotsControl?.id) : null
        // }
        btnYesEvent={() => handleItemsToAdd(modalLotsControl.id)}
        size="sz"
        onHide={() => {
          setmodalArtControl({
            ...modalArtControl,
            showLots: false,
          });
          setSelectedList2([]);
        }}
      >
        <div
          id="arriba"
          className={`${minModal.backgroundModal} ${minModal.paddingButton}`}
        >
          {/* ---------------------------- ROW 1 ---------------------------------- */}
          <label className={`${tableStyles.subtitle} `}>
             Información del producto  
          </label>
          <div className="d-flex">
            <div className={` ${tableStyles.ItemSmall}`}>
              <p className={"text-secondary"}>Producto</p>
              <input
                className="register-inputs text-secondary"
                // className={` ${tableStyles.registerInputs}`}
                name="auction_title"
                type="text"
                value={modalLotsControl.product}
                readOnly
              />
            </div>
          </div>
        </div>
        {/* ============================= ROW 2 =================================== */}
        <div id="arriba" className={`${tableStyles.paddingButton}`}>
          <GenericTableNew headers={renderHeadersLots}>
            {renderTableLots()}
          </GenericTableNew>
          <div
            className={`${paginationStyles.wrapper}`}
            style={localStyles.endPagination}
          >
            <p className={paginationStyles.paginationText}>
              Pag.{" "}
              {store.inventoryReducer.outPutLotsArticles
                ? lotsFilters.page
                : ""}
              {" de "}
              {Math.ceil(store.inventoryReducer.lotsTotal / lotsFilters.perpage)
                ? Math.ceil(
                    store.inventoryReducer.lotsTotal / lotsFilters.perpage
                  )
                : ""}{" "}
              ({store.inventoryReducer.lotsTotal} encontrados)
            </p>
            <Pagination
              activePage={lotsFilters.page}
              itemsCountPerPage={lotsFilters.perpage}
              totalItemsCount={store.inventoryReducer.lotsTotal}
              pageRangeDisplayed={2}
              onChange={(e) => setLotsFilters({ ...lotsFilters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </ModalNew>
      <ModalNew
        size="200"
        title="Lotes"
        show={modalArtControl.showThird}
        hideCancelButton={true}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            showThird: false,
          })
        }
      >
        <GenericTableNew dark={true} headers={renderHeadersThird}>
          {thirdListElem()}
        </GenericTableNew>
      </ModalNew>      
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        {!!(store.inventoryReducer.loading || loading) &&
            <div className="mx-auto loading">
                <Loader
                  type="Oval"
                  color="#003f80"
                  height={100}
                  width={100}
                />
            </div>
        }
        <Row>
          <Col xs={10}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={atrasIcon}
                className={`mr-2 mb-2 cursorPointer`}
                alt="icon"
              ></img>
              Donación
              <img width="3.5%" src={grayOutPut} className={`ml-3`} alt="icon"></img>
            </h1>
          </Col>
        </Row>
        <Row className=" d-flex">
          <Col xs={3} className="align-self-end">
            <p className={tableStyles.crudModalLabel}>Almacén</p>
            <Select noOptionsMessage={() => 'No hay datos'}
              options={arrWh}
              defaultValue={filters.id_warehouse}
              onChange={(e) => {
                setFilters({ ...filters, id_warehouse: e.value });
                setselectedList([]);
                setSaveList([]);
              }}
              placeholder="Seleccionar..."
              styles={customSelectNewDark}
            />
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Traer artículos</p>
            <input
              placeholder={"Buscar..."}
              onClick={() => handleClickEntrybyStorage()}
              className={` ${tableStyles.articleSearch} register-inputs cursorPointer`}
              name="requisition_date"
              type="text"
              disabled={filters.id_warehouse === "" ? true : false}
              // value={disableInput ? "" : "325"}
            />
          </Col>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel}`}>Fecha</p>
            <input
              defaultValue={date}
              style={localStyles.blueInputText}
              className="register-inputs text-secondary"
              type="date"
            />
          </Col>
          {fillpTable && (
            <Col
              xs={12}
              className="d-flex"
              style={{ justifyContent: "flex-end" }}
            >
              <img
                width="1.5%"
                src={canecaGris}
                className="cursorPointer"
                alt="btnAgregar"
                onClick={(e) => deleteItems(e)}
              ></img>
            </Col>
          )}
          <Col xs={12}>
            {fillpTable ? (
              <>
                <GenericTableNew dark={true} headers={renderHeadersPTable}>
                  {pTableListElem()}
                </GenericTableNew>
                <Row className={`w-100 m-0`}>
                  <Col xs={9} className={`${tableStyles.clearBlueBg} px-3`}>
                    <p className={`${tableStyles.crudModalLabel} pt-2`}>
                      Observaciones
                    </p>
                    <textarea
                      onChange={(e) => setDetails(e.target.value)}
                      style={{ height: "3rem" }}
                      rows="10"
                      cols="10"
                      className={`register-inputs`}
                    ></textarea>
                  </Col>
                </Row>

                <div className={`customTabs__Footer mt-5`}>
                  <Button
                    onClick={() => confirmation()}
                    className={tableStyles.btnPrimary}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="outline-primary"
                    className={tableStyles.btnSecondary}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            ) : (
              <>
                <SkeletonTemplate></SkeletonTemplate>
                <div className={`customTabs__Footer mt-5`}>
                  <Button className={tableStyles.btnPrimary} disabled={true}>
                    Guardar
                  </Button>

                  <Button
                    variant="outline-primary"
                    onClick={() => handleBack()}
                    className={tableStyles.btnSecondary}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
