import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import Agregar from '../../../assets/img/icons/add-check.svg';
import detailIcon from '../../../assets/img/icons/auctionDetailLupa2.svg';
import excelClearGrey from '../../../assets/img/icons/excelClearGray.svg';
import CheckMark from '../../../assets/img/icons/extraBtnCheck.svg';
import Search from '../../../assets/img/icons/lupa.svg';
import threeDots from '../../../assets/img/icons/threeDots.svg';

import { useGetMethod } from '../../../Hooks/useFetch';
import { getAllConCenter } from '../../../actions/consumptionCenterActions';
import { get_personal } from '../../../actions/partnersActions';
import { genericDownloaFile } from '../../../helpers/helpers';

import GenericTableNew from '../../Layouts/GenericTableNew';
import CustomPopupExtend from '../../Popup/customPopUpExtends';
import SelectComponent from '../../SelectComponent/SelectComponent';
import { AccordionForm } from './AccordionForm';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'reactjs-popup/dist/index.css';
import paginationStyles from '../../Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../Layouts/react-select-custom';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import '../../TableUsers/tableUserStyle.scss';
import { useHasPermissionByTagModule } from '../../../Hooks';

export const PeopleMasterTable = () => {
  const location = useLocation();
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'pplTm',
  );

  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;

  const { trigger: getDocExcel } = useGetMethod();

  const { load: loaderListDoctors, trigger: getListDoctors } = useGetMethod();

  const [listDoctor, setListDoctor] = useState([]);

  const [filters, setFilters] = useState({
    area_id: undefined,
    search: '',
    page: 1,
    perpage: 10,
    entity_account: idAccount,
  });
  const [hvDetail, setHvDetail] = useState({
    show: false,
    data: '',
    trigger: 0,
  });
  useEffect(() => {
    dispatch(get_personal(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, hvDetail.trigger]);

  useEffect(() => {

    dispatch(getAllConCenter({ lite: 1, eaccount: idAccount }));

    getListDoctors({
      url: '/medical/generals/medicalUsers/',
      token: token,
      objFilters: { eaccount: idAccount, status: 'enabled' },
    }).then(res => {
      if (res.success) {
        const format = res?.results?.map(doctor => ({
          ...doctor,
          label: doctor?.user,
          value: doctor?.id,
        }));

        const defaultValue = { label: 'Seleccionar...', value: '' };

        if (format.length) {
          setListDoctor([defaultValue, ...format]);
        } else {
          setListDoctor([defaultValue]);
        }
      }
    });
  }, [dispatch, getListDoctors, idAccount, token]);

  useHasPermissionByTagModule({ module: 'nomina', hasRead: myPermission?.read });

  const optionConCenter = [
    {
      value: '',
      label: 'Seleccionar...',
    },
  ];
  if (Array.isArray(store.consumptionCenterReducer.consumption_center)) {
    store.consumptionCenterReducer.consumption_center.forEach(elem => {
      optionConCenter.push({
        value: elem.id,
        label: elem.description,
      });
    });
  }

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-3 px-3 text-start'>{elem?.full_name ? elem.full_name : ''}</td>

        <td className='col-2 text-start'>{!elem.job_title ? '-' : elem.job_title}</td>

        <td className='col-2 text-start'>{elem?.area_name ? elem.area_name : '-'}</td>

        <td className='col-2 text-start'>{elem?.full_name_leader ? elem.full_name_leader : '-'}</td>

        <td
          className='col-1 text-center'
          style={{
            color: elem?.percentage === 100 ? '#00a551' : '#58595b',
            fontWeight: elem?.percentage === 100 ? 'bold' : '',
          }}
        >
          {elem?.percentage ? elem.percentage + ' %' : '0 %'}
        </td>

        <td className='col-1 text-center'>
          {elem?.doc_num ? <img src={CheckMark} alt='icon'></img> : null}
        </td>

        <td className='text-center'>
          <div
            className='rounded-pill p-1'
            style={{
              backgroundColor: elem?.status?.color,
            }}
          >
            <b style={{ color: elem?.status?.fontcolor }}>{elem?.status?.name ?? ''}</b>
          </div>
        </td>

        <td className='col-1 '>
          {location?.state?.employees ? (
            !!myPermission?.read && (
              <img
                src={detailIcon}
                style={{ cursor: 'pointer' }}
                alt=''
                title='Ver HV'
                onClick={() =>
                  location?.state?.employees
                    ? history.push({
                      pathname: '/contabilidad/terceros/empleados/detalleCurriculo',
                      state: elem,
                    })
                    : history.push({
                      pathname: '/nomina/colaborador/detalleCurriculo',
                      state: elem,
                    })
                }
              />
            )
          ) : (
            <CustomPopupExtend
              noHover
              triggerSrc={threeDots}
              editClickEvent={() => {
                setHvDetail({ ...hvDetail, show: true, data: elem });
              }}
              showEdit={location?.state?.employees ? false : myPermission?.edit ? true : false}
              editText={elem?.percentage !== 100 ? 'Continuar HV' : 'Editar HV'}
              showDetails={!!myPermission?.read}
              textDetails='Ver HV'
              showDetailsEvent={() =>
                location?.state?.employees
                  ? history.push({
                    pathname: '/contabilidad/terceros/empleados/detalleCurriculo',
                    state: elem,
                  })
                  : history.push({
                    pathname: '/nomina/colaborador/detalleCurriculo',
                    state: elem,
                  })
              }
            />
          )}
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (store.partnersReducer.personal !== undefined) {
      const elem2 = store.partnersReducer.personal;
      elemMap = elem2.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-start col-3 px-3'>
      Nombre completo
    </th>,

    <th key={2} className='text-start col-2'>
      Cargo
    </th>,

    <th key={3} className='text-start col-2'>
      Área
    </th>,

    <th key={3} className='text-start col-2'>
      Líder
    </th>,

    <th key={4} className='text-center col-1'>
      Hoja de vida
    </th>,

    <th key={5} className='text-center col-1'>
      Usuario
    </th>,

    <th key={5} className='text-center col-1'>
      Estado
    </th>,

    <th key={6} className='text-start col-1'>
      &nbsp;
    </th>,
  ];

  const handleClickNew = () => {
    history.push({
      pathname: '/nomina/colaborador/nuevo',
      state: { detail: false },
    });
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    dispatch(get_personal(filters));
  };

  const downloadExcelDoc = () => {
    let name = 'report';
    getDocExcel({
      url: '/payroll/candidate-export-excel/',
      objFilters: {
        entity_account: idAccount,
      },
      token: token,
      doAfterSuccess: results => {
        if (results.success) {
          genericDownloaFile(results?.results?.base64, name);
        }
      },
    });
  };

  return (
    <>
      <div className={tableStyles.container}>
        {(store.partnersReducer.personal_loading || loaderListDoctors) && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        {hvDetail.show ? (
          <AccordionForm hvDetail={hvDetail} setHvDetail={setHvDetail} />
        ) : (
          <div className={tableStyles.tableArea}>
            <Row>
              <Col xs={10}>
                <div className={tableStyles.title}>
                  <h1>{location?.state?.employees ? 'Empleados' : 'Tabla maestra de personal'}</h1>
                </div>
              </Col>
            </Row>
            {!!myPermission?.read && (
              <>
                <Row>
                  <Col xs={2}>
                    <p className={tableStyles.crudModalLabel}>Área</p>
                    <SelectComponent
                      styles={customSelectNewDark}
                      placeholder={'Seleccionar...'}
                      onChange={e => {
                        setFilters({ ...filters, area_id: e.value, page: 1 });
                        setHvDetail(state => ({ ...state, trigger: state.trigger + 1 }));
                      }}
                      options={optionConCenter}
                      value={optionConCenter.find(x => x.value === filters.area_id)}
                    ></SelectComponent>
                  </Col>
                  <Col xs={2}>
                    <p className={tableStyles.crudModalLabel}>Superior directo</p>
                    <SelectComponent
                      styles={customSelectNewDark}
                      placeholder={'Seleccionar...'}
                      options={listDoctor}
                      value={listDoctor.find(x => x.value === filters.id_leader)}
                      onChange={e => {
                        setFilters({ ...filters, id_leader: e.value, page: 1 });
                        setHvDetail(state => ({
                          ...state,
                          id_leader: e.value,
                          trigger: state.trigger + 1,
                        }));
                      }}
                    ></SelectComponent>
                  </Col>
                  <Col xs={4} className='d-flex align-self-end gap-1'>
                    <input
                      className={tableStyles.SearchNew}
                      value={filters.search}
                      placeholder='Buscar...'
                      type='text'
                      onChange={e => setFilters({ ...filters, search: e.target.value, page: 1 })}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          handleSearch(e);
                        }
                      }}
                    ></input>
                    <img
                      className='hoverPointer'
                      alt='iconSearch'
                      style={{}}
                      src={Search}
                      height='24px'
                      onClick={e => handleSearch(e)}
                    />
                  </Col>
                  {location?.pathname === '/nomina/tmPersonal' ? (
                    <Col xs={4} className='align-self-end'>
                      <div className='d-flex align-self-end justify-content-end'>
                        <div>
                          <img
                            alt={'excel'}
                            className='cursorPointer mx-2'
                            src={excelClearGrey}
                            onClick={() => downloadExcelDoc()}
                          />
                        </div>
                        <div className='d-flex justify-content-end'>
                          {!!myPermission?.create && (
                            <div
                              onClick={() => handleClickNew()}
                              className={tableStyles.createNomModule}
                            >
                              <b className={`mr-2`}>Crear colaborador</b>
                              <img src={Agregar} alt='User' />
                              <div></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  ) : null}
                </Row>
                <GenericTableNew headers={renderHeaders} dark={true}>
                  {listElem()}
                </GenericTableNew>
                <div className={paginationStyles.wrapper}>
                  <p className={`${paginationStyles.paginationText} text-secondary`}>
                    Pag. {filters.page}
                    {' de '}
                    {Math.ceil(store.partnersReducer.totalPersonal / filters.perpage)
                      ? Math.ceil(store.partnersReducer.totalPersonal / filters.perpage)
                      : '1'}{' '}
                    ({store.partnersReducer.totalPersonal} encontrados)
                  </p>
                  <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.perpage}
                    totalItemsCount={store.partnersReducer.totalPersonal}
                    pageRangeDisplayed={5}
                    onChange={e => setFilters({ ...filters, page: e })}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
