import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Lupa from "../../assets/img/icons/lupa.svg";
import lupaTransparent from "../../assets/img/icons/lupaTransparent.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import Pagination from "react-js-pagination";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Tooltip } from '@material-ui/core';

import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import Select from "react-select";
import { customSelectNew, customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import { useHistory } from "react-router-dom";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import { message } from "../../helpers/helpers";
import {
    getListCompanies,

    getHistoryStatus,
    getHistory,
    getInvoiceTypes,
} from "../../actions/billingActions";
import { getPdfTest } from "../../actions/consultAction";
import { Title } from "../../shared";


var formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
});

function BillingHistory(props) {
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();

    const history = useHistory();
    const token = counter.loginReducer.Authorization;
    const [trigger, setTrigger] = useState(0);
    const [showPdf, setShowPdf] = useState(false)
    const [base64, setBase64] = useState("");

    const getPdf = async (id, type) => {
        const result = await getPdfTest(
            {
                id: id,
                doc: type === "Capita" ? 19 : 18,
            },
            token
        );
        if (result?.success) {
            return setBase64(result?.pdf[0]?.key?.split("'")[1]);
        } else {
            message(
                "error",
                "Ha ocurrido un error",
                "No ha sido posible cargar el documento"
            );
            return setShowPdf(false)
        }
    };


    const [filters, setFilters] = useState({
        eaccount: counter.loginReducer.currentAccount.id,
        //created_by: counter.loginReducer.user_data.id,
        page: 1,
        perpage: 10,
    });

    const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "historyInv");


    useEffect(() => {
        if (!myPermission?.read) {
            history.push("/categoryselect");
        }
        dispatch(
            getListCompanies({
                eaccount: counter.loginReducer.currentAccount.id,
            })
        );
        dispatch(
            getInvoiceTypes({
                eaccount: counter.loginReducer.currentAccount.id,
            })
        );

        dispatch(
            getHistoryStatus({
                type: "invoiceHis",
                eaccount: counter.loginReducer.currentAccount.id,
            })
        );
    }, []);

    useEffect(() => {
        dispatch(
            getHistory(filters)
        );
    }, [trigger]);

    let optionCompanies = [{ key: "default", value: "", label: "Seleccionar...", id: "" }];
    if (Array.isArray(counter.billingReducer.companies)) {

        counter.billingReducer.companies.map((item) => {
            optionCompanies.push({
                key: item.id,
                value: item.id,
                label: item.copyName,

                id: item.id,
                companyType: item.companyType,
                copyName: item.copyName,
                notification_email: item.notification_email,
                Nit: item.Nit,
                address: item.address,
                phone: item.phone,
                email: item.email,
                cityId: item.cityId,
                city: item.city,
            })
        });
    }

    let optionStatus = [{ key: "default", value: "", label: "Seleccionar...", name: "" }];
    if (Array.isArray(counter.billingReducer.billing_history_status)) {
        counter.billingReducer.billing_history_status.map((item) => {
            optionStatus.push({
                key: item.id,
                value: item.id,
                label: item.value,

                id: item.id,
                name: item.name,
                value: item.value,
                prefix: item.prefix,
                color_id: item.color_id,
                background: item.background,
                fontcolor: item.fontcolor,
            })
        });
    }

    let optionTypes = [{ key: "default", value: "", label: "Seleccionar..." }];
    if (Array.isArray(counter.billingReducer.invoice_types)) {
        counter.billingReducer.invoice_types.map((item) => {
            optionTypes.push({
                key: item.id,
                value: item.id,
                label: item.type,
            })
        });
    }

    const renderList = () => {
        let tempList = [];
        if (Array.isArray(counter.billingReducer.billing_history)) {
            counter.billingReducer.billing_history.map((item, index) => {
                tempList.push(
                    <tr key={index}>
                        <td className="text-center">{item.sequence}</td>
                        <td className="text-center">{convertDateToLatinFormat(item.exp_date)}</td>
                        <td className="text-center">{item.clientNit}</td>
                        <td className="text-center">{item.clientName}</td>
                        <td className="text-center">{item.type}</td>
                        <td className="text-center">
                            <Tooltip title={item.value}>
                                <p
                                    style={{
                                        margin: "0 auto",
                                        padding: "2px 4px",
                                        borderRadius: "10px",
                                        backgroundColor: `${item.status_fontcolor}1A`,
                                        color: `${item.status_fontcolor}`,
                                        fontWeight: "bold"
                                    }}
                                >
                                    {item.value ? item.value : " - "}
                                </p>

                            </Tooltip>
                        </td>
                        <td className="text-end" style={{ paddingRight: "8px" }}>{formatter.format(item.total_amount)}</td>
                        <td className="text-center cursorPointer px-2">
                            <img src={lupaTransparent}
                                onClick={() => {
                                    setShowPdf(true);
                                    getPdf(item.id, item.type)
                                }}
                            />
                        </td>
                    </tr>
                );
            })
        }
        return tempList;
    }


    let i = 0

    const header3 = [

        <th className="text-center">No. Factura </th>,
        <th className="text-center">Fecha factura</th>,
        <th className="text-center">NIT</th>,
        <th className="text-center">Empresa</th>,
        <th className="text-center">Tipo</th>,
        <th className="text-center">Estado</th>,
        <th className="text-end px-2">Valor</th>,
        <th className="text-center"></th>

    ]

    const handleSearch = (e) => {
        e.preventDefault();
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
    };

    return (
        <>
            <div 
                className={` ml-5 ${tableStyles.container}`}
                style={{ marginRight: "3rem", marginTop:'30px' }}
            >

                <Title
                    title="Historial de facturas"
                    className={'mb-2'}
                    onClickIcon={() => {
                        history.push('/facturacion/inicio')
                    }}
                />

                <div>
                    <div className="div gap-3 align-items-end">
                        <div className={`display-grid ${tableStyles.containerSelect}`}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.crudModalLabel}>Empresa</label>
                                <Select
                                    placeholder='Seleccionar...'
                                    noOptionsMessage={() => 'No hay datos'}
                                    value={optionCompanies.find(option => option.value == filters.client)}
                                    onChange={(e) => setFilters({
                                        ...filters,
                                        client: e.id,
                                    })}
                                    options={optionCompanies}
                                    styles={customSelectNew}
                                />
                            </form>
                        </div>

                        <div className={`display-grid ${tableStyles.containerSelect}`}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.crudModalLabel}>No. Factura</label>
                                <input
                                    placeholder='Escribir...'
                                    className={IndividualStyles.registerInputs}
                                    type="text"
                                    style={{ borderColor: "#01A0F6" }}
                                    value={filters.sequence}
                                    onChange={e => setFilters({ ...filters, sequence: e.target.value })}
                                />
                            </form>
                        </div>

                        <div className={`display-grid ${tableStyles.containerSelect}`}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.crudModalLabel}>Tipo</label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    value={optionTypes.find(option => option.value == filters.type)}
                                    onChange={(e) => setFilters({
                                        ...filters,
                                        type: e.value,
                                    })}
                                    options={optionTypes}
                                    placeholder="Seleccionar..."
                                    styles={customSelectNew}
                                />
                            </form>
                        </div>

                        <div className={`display-grid ${tableStyles.containerSelect}`}>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <label className={tableStyles.crudModalLabel}>Estado</label>
                                <Select
                                    placeholder='Seleccionar...'
                                    noOptionsMessage={() => 'No hay datos'}
                                    value={optionStatus.find(option => option.name === filters.status)}
                                    onChange={(e) => setFilters({
                                        ...filters,
                                        status: e.name,
                                    })}
                                    options={optionStatus}
                                    styles={customSelectNew}
                                />
                            </form>
                        </div>

                        <div className={`display-grid `}>
                            <label className={tableStyles.crudModalLabel}>Fecha desde</label>

                            <div className={tableStyles.deleteicon}>
                                {!!filters.date_from ?
                                    <span onClick={e => setFilters({ ...filters, date_from: "" })} >x</span>
                                    :
                                    ""
                                }
                                <form onSubmit={(e) => handleSearch(e)}>
                                    <input
                                        className={`${IndividualStyles.registerInputs} register-inputs`}
                                        style={{ color: "#005DBF", fontWeight: "bold" }}
                                        type="date"
                                        value={filters.date_from}
                                        onChange={e => setFilters({ ...filters, date_from: e.target.value })}
                                    />
                                </form>
                            </div>
                        </div>

                        <div className={`display-grid`}>
                            <label className={tableStyles.crudModalLabel}>Fecha hasta</label>
                            <div className={tableStyles.deleteicon}>
                                {!!filters.date_until ?
                                    <span onClick={e => setFilters({ ...filters, date_until: "" })} >x</span>
                                    :
                                    ""
                                }
                                <form onSubmit={(e) => handleSearch(e)}>
                                    <input
                                        className={`${IndividualStyles.registerInputs} register-inputs`}
                                        style={{ color: "#005DBF", fontWeight: "bold" }}
                                        type="date"
                                        value={filters.date_until}
                                        onChange={e => setFilters({ ...filters, date_until: e.target.value })}
                                    />
                                </form>
                            </div>
                        </div>

                        <div className={`display-grid`}>
                            <label className={tableStyles.crudModalLabel}>&nbsp;</label>
                            <form onSubmit={(e) => handleSearch(e)}>
                                <i><img
                                    style={{ width: "25px" }}
                                    src={Lupa}
                                    alt=""
                                    className="cursorPointer"
                                    srcset=""
                                    onClick={e => { setTrigger(trigger + 1); setFilters({ ...filters, page: 1 }); }}
                                /></i>
                            </form>
                        </div>

                        <div className={`display-grid`}>
                            <label className={tableStyles.crudModalLabel}>&nbsp;</label>
                        </div>
                    </div>


                </div>
                <div>
                    <GenericTable
                        headers={header3}
                        children={renderList()}
                        dark={true}
                    >
                    </GenericTable>
                </div>
                {(counter.billingReducer.billing_history_loading) &&
                    <div className="mx-auto">
                        <Loader
                            type="Oval"
                            color="#003f80"
                            height={100}
                            width={100}
                        />
                    </div>
                }

                <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                        Pag. {counter.billingReducer.billing_history_total ? filters.page : ""}
                        {" de "}
                        {Math.ceil(counter.billingReducer.billing_history_total / filters.perpage)
                            ? Math.ceil(counter.billingReducer.billing_history_total / filters.perpage)
                            : ""}{" "}
                        ({counter.billingReducer.billing_history_total} encontrados)
                    </p>
                    <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.perpage}
                        totalItemsCount={counter.billingReducer.billing_history_total}
                        pageRangeDisplayed={5}
                        onChange={val => { setTrigger(trigger + 1); setFilters({ ...filters, page: val }) }}
                        itemClassPrev={`${paginationStyles.itemClassPrev} `}
                        itemClassNext={`${paginationStyles.itemClassNext} `}
                        itemClassFirst={`${paginationStyles.itemClassFirst} `}
                        itemClassLast={`${paginationStyles.itemClassLast} `}
                        itemClass={paginationStyles.itemClass}
                    />
                </div>
            </div>
            <ModalNew
                title="Detalle de factura"
                show={showPdf}
                btnNoName={"Cancelar"}
                size="700"
                btnYesDisabled={false}
                onHide={() => setShowPdf(false)}
                btnNoEvent={() => setShowPdf(false)}
                btnNoDisabled={false}
            >
                <PdfViewer
                    downloadable
                    file={`data:application/pdf;base64,${base64}`}></PdfViewer>
            </ModalNew>
        </>
    )

};
export default BillingHistory;

