import React, { useEffect, useState } from "react";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import OrdTable from "../OrderingComponents/OrdTable";
import { Col, Form, Row } from "react-bootstrap";
import { OrdGenericTemplate } from "../OrderingComponents/OrdGenericTemplate";
import Select from "react-select";
import excelVeryClearBlue from "../../assets/img/icons/excelVeryClearBlue.svg";
import pdfClearBlueIcon from "../../assets/img/icons/pdfClearBlueIcon.svg";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import { useGetMethod, useMultiGetMethod } from "../../Hooks/useFetch";
import { useSelector } from "react-redux";
import { loader, formatToRcSelect, isEmptyOrUndefined, downloaFile, genericDownloaFile } from "../../helpers/helpers";
import advertenciaOrd from "../../assets/img/icons/alertIconOrd.svg";
import Typography from '@mui/material/Typography';



const FormulatedMedicationReports = () => {

  const {results: unities,load: unitiesLoader, trigger: getUnities,} = useMultiGetMethod();
  const {results: medicationsReports, load: medicationsLoader, trigger: getMedicationsReports,} = useGetMethod();
  const { load: pdfLoader, trigger: getPdfOrder } = useGetMethod()
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idCurrentAccount = store.loginReducer.currentAccount.id;
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    idAccount: idCurrentAccount,
    report: "1"
  });
  const [showTable, setShowTable] = useState(false);

  // const mipresOptions = [
  //   {value: "", label: "-seleccione-" },
  //   {value: "noReq", label: "No requiere" },
  //   {value: "reqMipres", label: "Requiere" },
  // ]
  const siteOptions = formatToRcSelect(unities?.site?.data, "id","description","","","",)
  const companyOptions = formatToRcSelect(unities?.company?.results, "idCorporateClient","nameCorporateClient","","","")
  const searchButton = () => {
    let valueStartDate;
    let valueEndDate;
    let inputStartDate = document.getElementById('date-start');
    let inputEndDate = document.getElementById('date-end');
    let siteInput = isEmptyOrUndefined(filters?.site)
    let companyInput = isEmptyOrUndefined(filters?.company)
    // let mipresInput = isEmptyOrUndefined(filters?.mipres)
    if(inputStartDate.value){
      valueStartDate = true;
    } else{
      valueStartDate = false;
    }
    if(inputEndDate.value){
      valueEndDate = true;
    } else{
      valueEndDate = false;
    }
    // if (!siteInput || !companyInput || !mipresInput || valueStartDate|| valueEndDate === true) {
    if (!siteInput || !companyInput || valueStartDate|| valueEndDate === true) {
      setFilters({...filters, page: 1})
      setTrigger(trigger + 1)
      setShowTable(true)
    } else{
      setShowTable(false)
    }
  };
  const downloadPdf = () => {
    getPdfOrder({
        url: "/medical/pdfMedicationReport/",
        objFilters: { startDate : filters.startDate, endDate : filters.endDate, site : filters.site, company: filters.company, report: 1, idAccount: idCurrentAccount },
        token: token,
        doAfterSuccess: (res) => {
            if(!!res.success){
                downloaFile(res?.results[0].base64)
            }
        }
    });
};
  const downloadExcel = () => {
    getPdfOrder({
        url: "/medical/pdfMedicationReport/",
        objFilters: { startDate : filters.startDate, endDate : filters.endDate, site : filters.site, company: filters.company, report: 1, idAccount: idCurrentAccount, excel: 1 },
        token: token,
        doAfterSuccess: (res) => {
            if(!!res.success){
              genericDownloaFile(res?.results?.base64, res?.results?.filename )
            }
        }
    });
};

   // --- GET REFERENCES HISTORY-----
  useEffect(() => {
      getMedicationsReports({
          url: "/medical/medicationOrder/",
          objFilters: filters,
          token: token,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);
  // ------------MULTIGET FILTERS------------
  useEffect(() => {
      getUnities({
        multipleGet: [
          {
            // ---------COMPANY-----------
            url: "/medical/generals/company/",
            requestName: "company",
          },
          {
            // --------- SITE -----------
            url: "/site/",
            objFilters: { id_account: idCurrentAccount},
            requestName: "site",
          },
        ],
        token: token,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headers = [
      {
        title: "Identificación",
        className: "px-2 text-start",
      },
      {
        title: "Paciente",
        className: "px-2 text-start",
      },
      {
        title: "Empresa",
        className: "px-2 text-start",
      },
      {
        title: "Médico",
        className: "px-2 text-start",
      },
      {
        title: "Fecha",
        className: "px-2 text-center",
      },
      {
        title: "Medicamento",
        className: "px-2 text-start",
      },
      {
        title: "Cantidad",
        className: "px-2 text-center",
      },
      // {
      //   title: "MIPRES",
      //   className: "px-2 text-center",
      // },
    ];
    const formatData = (array) => {
      let tempList = [];
      if (Array.isArray(array)) {
        array.forEach((item, index) => {
          let createdDate = item?.createdAtMedicalOrders.split("|");
          let date = createdDate[0];
          tempList.push(
            <tr key={index} className={`hover-table-row`}>
              <td className="text-start px-2">{item?.patient?.document}</td>
              <td className="text-start px-2  ">{item?.patient?.name}</td>
              <td className="text-start px-2 ">{item?.corporateClient?.name}</td>
              <td className="text-start px-2 "> {item?.doctor?.name}</td>
              <td className="text-center px-2 "> {date}</td>
              <td className="text-start px-2 ">{item?.medicine[0]?.tradeName}</td>
              <td className="text-center px-2 ">{item?.medicine[0]?.presentation?.meoQtyPresentation}</td>
              {/* <td className="text-center px-2 ">{item?.mipres}</td> */}
            </tr>
          );
        });
      }
      return tempList;
    };
  return (
    <>
      {unitiesLoader && loader}
      {medicationsLoader && loader}
      {pdfLoader && loader}
      <div className="col-11">
        <OrdGenericTemplate
          title={" Informe de medicamentos formulados"}
          titleSize={12}
          className="mx-2"
          titleStyle={{ paddingLeft: "1.8rem" }}
        >
          <div>
            <Row className={`d-flex justify-content-start aling-items-end`} style={{ marginLeft: "0.8rem" }}>
              <Col xs={2} className={`px-0 align-self-end`}>
                <Form.Group className="px-2" controlId="ordType" >
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b>&nbsp;Fecha de inicio</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordDarkBlueText} ${tableStyles.inputDateServiceReports}`}
                    type="date"
                    id="date-start"
                    onChange={(e) => {
                      setFilters({ ...filters, startDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2} className={`px-0 align-self-end`}>
                <Form.Group className=" px-2" controlId="ordType" >
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b>&nbsp;Fecha fin</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordDarkBlueText} ${tableStyles.inputDateServiceReports}`}
                    type="date"
                    id="date-end"
                    onChange={(e) => {
                      setFilters({ ...filters, endDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className={`px-0 align-self-end`} xs={2}>
                <Form.Group className=" px-2" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Sede</b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={siteOptions}
                    className="text-secondary "
                    placeholder={"Seleccione"}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setFilters({ ...filters, site: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col className={`px-0 align-self-end`} xs={2}>
                <Form.Group className=" px-2" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Empresa</b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={companyOptions}
                    className="text-secondary"
                    placeholder={"Seleccione"}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setFilters({ ...filters, company: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              {/* <Col className={`px-0 align-self-end`} xs={2}>
                <Form.Group className=" px-2" controlId="fName">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className="text-start">&nbsp;Mipres</b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={mipresOptions}
                    className="text-secondary"
                    placeholder={"Seleccione"}
                    styles={ordCustomSelect}
                    onChange={(e) => {
                      setFilters({ ...filters, mipres: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col> */}
              <Col className={`px-4 d-flex flex-column justify-content-end`} xs={4} >
              {showTable && (
                <div className=" d-flex justify-content-end">
                  <button onClick={() => {
                  downloadPdf()
                  }}>
                  <img src={pdfClearBlueIcon} alt="pdfClearBlueIcon" className={`${tableStyles.iconSvgSemi}`} style={{ width: "1.7rem" }} />
                  </button>
                  <button onClick={() => {
                  downloadExcel()
                  }}>
                  <img
                    src={excelVeryClearBlue}
                    alt="excelVeryClearBlue p-2 mr-2"
                    className={`${tableStyles.iconSvg}`}
                    style={{ width: "1.5rem" }}
                  />
                  </button>
                </div>)}
                <div className="d-flex  justify-content-end pt-2">
                  <button
                    type="button"
                    className={`${tableStyles.btnGenerateRep}`}
                    onClick={() => {
                      searchButton();
                    }}
                  >
                    Generar
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          {showTable && (
            <div className="pl-3 m-2 ">
              <OrdTable
                headers={headers}
                hasChildren={true}
                shadow={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                paginate={{
                  activePage: filters?.page,
                  totalPages: medicationsReports?.rowTotal,
                  perPage: filters?.perpage,
                  pageRangeDisplayed: 3,
                  onChangePage: async (e) => {
                    const result = await getMedicationsReports({
                      url: "/medical/medicationOrder/",
                      objFilters: { ...filters, page: e },
                      token: token,
                    });
                    setFilters({ ...filters, page: e });
                    formatData(result?.results);
                  },
                  showTextDetails: true,
                }}
              >
                {formatData(medicationsReports.results)}
              </OrdTable>
            </div>
          )}
          {!showTable &&
          <section className={`d-flex justify-content-center`}
          style={{ padding: '10%' }} >
          <div >
              <Typography className={`text-secondary`} >
                  <h5 className={`  ${tableStyles.ordSpanText} text-center pb-4`}>
                  Para generar el informe indica rango de fechas, <br></br> sede y/o empresa
                  </h5>
              </Typography>
              <img
                  className={'center'}
                  // width={'30x'}
                  src={advertenciaOrd}
                  alt="Atrás"
              >
              </img>
          </div>
        </section>}
        </OrdGenericTemplate>
      </div>
    </>
  );
};

export default FormulatedMedicationReports;
