import React from "react";
import tableStylesScroll from "./TableScrollStyles.module.scss";

function TableScroll(props) {

  return (
    <main className={tableStylesScroll.mainTable}>
      <div style={{
        overflow: "auto", borderRadius: 10,
        scrollbarWidth: "none",
        scrollbarColor: "#ccc transparent",
      }}>
        <table className={tableStylesScroll.tableScrollK}>
          <thead>
            <tr>
              {
                props?.headers?.length > 0 &&
                props?.headers?.map((header, ind) => {
                  return (
                    header
                  );
                })
              }
            </tr>
          </thead>

          <tbody>
            {
              props?.body?.length > 0 &&
              props?.body?.map((body, ind) => {
                return (
                  body
                );
              })
            }
            {/* <tr>
            <td className={`${tableStylesScroll.fixed} ${tableStylesScroll.fixedLeft}`}><div className="d-flex">
                <Col xs={6}> prueba</Col>
                <Col xs={6}>prueba</Col>

                </div>
            </td>
            <td >Sable</td>
            <td>15 yo</td>
            <td>Japan</td>
            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
            <td>Purple</td>
          </tr>
          <tr>
            <td className={`${tableStylesScroll.fixed} ${tableStylesScroll.fixedLeft}`}><div className="d-flex">
                <Col xs={6}> prueba</Col>
                <Col xs={6}>prueba</Col>

                </div></td>
            <td >Toco toucan</td>
            <td>35 yo</td>
            <td>Brazil</td>
            <td>
              Sed tortor erat, imperdiet a enim quis, placerat rhoncus nisl.
            </td>
            <td>Orange</td>
          </tr>
          <tr>
            <td className={`${tableStylesScroll.fixed} ${tableStylesScroll.fixedLeft}`}><div className="d-flex">
                <Col xs={6}> prueba</Col>
                <Col xs={6}>prueba</Col>

                </div></td>
            <td >Bull</td>
            <td>42 yo</td>
            <td>Spain</td>
            <td>Donec vitae risus urna.</td>
            <td>Red</td>
          </tr>
          <tr>
            <td className={`${tableStylesScroll.fixed} ${tableStylesScroll.fixedLeft}`}>Python</td>
            <td className={`${tableStylesScroll.fixed} ${tableStylesScroll.fixedLeft}`}>Brown bear</td>
            <td>17 yo</td>
            <td>Russia</td>
            <td>Proin gravida et velit ut congue.</td>
            <td>Green</td>
          </tr> */}
          </tbody>
        </table>

      </div>
    </main>
  );
}

export default TableScroll;
