// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ModalNew from "../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { Button } from "react-bootstrap";
import RepaymentHistoryDetail from "./RepaymentHistoryDetail"
import { Tooltip } from "@material-ui/core";
import { GET_REPAYMENT } from "../../actions/actionTypes";
import { useHistory } from "react-router";
import Loader from "react-loader-spinner";
// --------------- icons 💥  ----------------//
import Lupa from "../../assets/img/icons/lupaTransparent.svg";
import Imprimir from "../../assets/img/icons/iconPrint.svg";
import Check from "../../assets/img/icons/extraBtnRedX.svg";
import Reject from "../../assets/img/icons/extraBtnCheck.svg";
import LupaBlue from "../../assets/img/icons/lupa.svg";

//-------------- scss styles 😄   --------------
import CustomPopupExtend from "../Popup/customPopUpExtends";
import Select from "react-select";
import {
  customSelectNew,
  customSelectNewDark,
} from "../../components/Layouts/react-select-custom";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import { SkeletonTemplate } from "../Layouts/SkeletonTemplate";
import {
  getListPettyCash,
  ListRepayment,
  DetailRepayment

} from "../../actions/accountingActions";
import { Title } from "../../shared";

function RepaymentHistory(props) {
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);
  const [Detail, setDetail] = useState({
    show: false,
  });
  const history = useHistory();


  const [info, setInfo] = useState({
    search: "",
    eaccount: storage.loginReducer.currentAccount.id,
    pettyCash: "",
    dateFrom: "",
    dateUntil: "",
  });

  //-------------- myPermission  😄   --------------
  const myPermission = () => storage.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "RepaymentH");

  //--------------first functions 🍕   --------------

  useEffect(() => {
    if (!myPermission()?.create || !myPermission()?.read) {
      history?.push("/contabilidad/inicio");
    }
  }, []);

  useEffect(() => {
    dispatch(
      getListPettyCash({ eaccount: storage.loginReducer.currentAccount.id })
    );
  }, []);

  useEffect(() => {
    dispatch({
      type: GET_REPAYMENT,
      payload: [],
      total: 0,
    })
  }, []);

  useEffect(() => {
    if (info.pettyCash != "") {
      dispatch(
        ListRepayment(info)
      );
    }
  }, [trigger]);

  const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  }

  const bodyTemplate = () => {
    let body = []
    for (let i = 0; i < 10; i++) {
      let bodyTemp = <section2 className={`d-flex`}>
        <div className={`col-5`}>-</div>
        <div className={`col-5`}>-</div>
        <div className={`col-1`}>-</div>
        <div className={`col-1`}>-</div>
      </section2>
      body.push(bodyTemp)

    }
    return body
  }


  //-------------- Table 🍕   --------------

  const header = [
    <div className={`col-5`}>Fecha</div>,
    <div className={`col-5 text-end`}>Valor</div>,
    <div className={`col-1`}>Estado</div>,
    <div className={`col-1 px-2`}>&nbsp;</div>,
  ]

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingReducer.listRepayment)) {
      storage.accountingReducer.listRepayment.map((item, index) => {
        tempList.push(
          <section2 className={`d-flex`}>
            <div className={`col-5`}>{item.date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")}</div>
            <div className={`col-5 text-end`}>{!!item.totalAmount ? "$" + numberWithCommas(item.totalAmount) : ""}</div>
            <div className={`col-1 px-2`}>
              <Tooltip title={item.statusName} arrow>
                {/* <div
                                      className={`
                                          ${IndividualStyles.popupReqActive} ${
                                              item.statusName === "Inhabilitado"
                                              ? tableStyles.circleRed
                                              : tableStyles.circleGreen
                                          }
                                      `}
                                  ></div> */}
                <div
                  className="rounded-pill p-1"
                  style={{
                    backgroundColor: item.statusName !== "Inhabilitado" ? "#FAFDF6" : "#FEF7F5",
                  }}
                >
                  <b style={{ color: item.statusName !== "Inhabilitado" ? "#83C036" : "#F39682" }}>{item.statusName !== "Inhabilitado"
                    ? "Habilitado"
                    : "Inhabilitado"}</b>
                </div>
              </Tooltip>
            </div>
            <div className={`col-1`}>
              <img
                className={`w-40 ${IndividualStyles.pointer}`}
                style={{ width: "16px", height: "16px" }}
                onClick={() => setDetail({ show: true, selectedRepayment: item.id })}
                src={Lupa}
                alt="icon"
              />
            </div>
          </section2>

        );
      })
    }
    return tempList;
  }

  //-------------- select options 🍕   --------------

  let optionsPettyCash = [{ key: "", value: "", label: "Seleccionar..." }];
  if (Array.isArray(storage.accountingReducer.listPettyCash)) {
    storage.accountingReducer.listPettyCash.map((item, index) => {
      optionsPettyCash.push({
        value: item.id,
        label: item.name,
        key: index + "pettyC",
      });
    });
  }


  const Loading =
    <div className="loading">
      <Loader type="Oval" color="#003f80" height={100} width={100} />
    </div>

  const myRender =
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >

      <div className={tableStyles.title}>
        <Title
          title="Historial de reembolso"
          onClickIcon={() => {
            history.push('/contabilidad/inicio')
          }}
        />
      </div>

      <Row>
        <Col xs={4}>
          <div className="display-grid mt-1">
            <label className={tableStyles.crudModalLabel}>Caja menor</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder="Seleccionar..."
              value={optionsPettyCash.find((item) => item.value === info.pettyCash)}
              options={optionsPettyCash}
              styles={customSelectNewDark}
              onChange={(e) => {
                setInfo({
                  ...info,
                  pettyCash: e.value,
                })
                setTrigger(trigger + 1)
              }
              }
            />
          </div>
        </Col>
        <Col xs={3}>
          <div className="display-grid mt-1">
            <label className={tableStyles.crudModalLabel}>Fecha desde</label>
            <input
              type="date"
              name="fromDate"
              value={info.dateFrom}
              onChange={(e) => {
                setInfo({
                  ...info,
                  dateFrom: e.target.value
                })
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1)
                }
              }}
              className={IndividualStyles.registerInputsBlue}
            />
          </div>
        </Col>
        <Col xs={3}>
          <div className="display-grid mt-1">
            <label className={tableStyles.crudModalLabel}>Hasta</label>
            <input
              type="date"
              name="untilDate"
              value={info.dateUntil}
              onChange={(e) => {
                setInfo({
                  ...info,
                  dateUntil: e.target.value
                })
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1)
                }
              }}
              className={IndividualStyles.registerInputsBlue}
            />
          </div>
        </Col>

        <Col xs={1}>
          <div className="display-grid mt-1">
            <label className={tableStyles.crudModalLabel}>ㅤ</label>
            <i><img
              src={LupaBlue}
              onClick={() => setTrigger(trigger + 1)}
              alt="icon"
              height="24px"
              className="cursorPointer"
            /></i>
          </div>
        </Col>

        <Col xs={1} className="text-end">
          {/* <img
                style={{ marginTop: "30px", width: "26px", height:"23px"  }}
                src={Imprimir}
            ></img> */}
        </Col>
      </Row>

      <GenericTableScroll
        headers={header}
        dark={true}
        body={(storage.accountingReducer.listRepayment?.length > 0) ? renderList() : bodyTemplate()}
        typeHead={"2"}
      ></GenericTableScroll>

    </div>
  return (!!Detail.show) ?
    <RepaymentHistoryDetail
      selectedRepayment={Detail.selectedRepayment}
      show={Detail.show}
      setDetails={setDetail}
    />
    :
    storage.accountingReducer.listRepayment_loading ?
      Loading
      :
      myRender;
}
export default RepaymentHistory;