//import Reacts natives && React Router
import React from 'react'

//import Hooks
import { useFormatData } from '../../../Hooks';

//import SASS && Mui material
import { Box } from '@mui/material'
import tableStyle from "../../../components/Layouts/tableStyle.module.scss";
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';
import { convertMoneyFormat } from './../../../helpers/helpers';

export const ServiceOrderCart = ({ data = {} }) => {

  const tableHeader = [
    {
      title: "Fecha",
      className: `text-start px-2 ${tableStyle.minWidth100px}`
    },
    {
      title: "Hora",
      className: "col-1 text-start px-2",
    },
    {
      title: "Servicio",
      className: " text-start px-2",
    },
    {
      title: "Profesional",
      className: "text-start px-2",
    },
    {
      title: "Sede",
      className: "col-2 text-start px-2",
    },
    {
      title: "Valor Servicio",
      className: "text-end px-2",
    },
    {
      title: "Copago o Cuota",
      className: "text-end px-2",
    },
    {
      title: "Valor empresa",
      className: "text-end px-2",
    }
  ]

  const tableBodyStructure = (el) => ([
    {
      text: <>{el?.date || '-'}</>,
      className: "text-start px-2"
    },
    {
      text: <>{el?.hour || '-'}</>,
      className: "text-start px-2"
    },
    {
      text: <>{el?.name || '-'}</>,
      className: "text-start px-2"
    },
    {
      text: <> {el?.doctor || '-'} </>,
      className: "text-start px-2"
    },
    {
      text: <> {el?.siteName || '-'} </>,
      className: "text-start px-2"
    },
    {
      text: (
        <span>
          {el?.price >= 0 ? convertMoneyFormat(Number(el?.price).toFixed(2)) : '-'}
        </span>
      ),
      className: "text-end px-2"
    },
    {
      text: (
        <span>
          {el?.copay >= 0 ? convertMoneyFormat(Number(el?.copay).toFixed(2)) : '-'}
        </span>
      ),
      className: "text-end px-2"
    },
    {
      text: (
        <span>
          {el?.corpPrice >= 0 ? convertMoneyFormat(Number(el?.corpPrice).toFixed(2)) : '-'}
        </span>
      ),
      className: "text-end px-2"
    }
  ])

  const listTableBody = Object.keys(data?.service || {})?.length
    ? [{ ...data?.service, hour: data?.hour, date: data?.date }]
    : []

  const { formatData } = useFormatData({ tableBodyStructure, listTableBody })


  return (
    <Box mb={3}>
      <Box
        display={'grid'}
        gridTemplateColumns={'65% 35%'}
        mb={3}
      >
        <Box>
          <Box
            className={`${tableStyle.ordDarkBlueText} fw-bold`}
          >
            {data?.account?.bussinesName || '-'}
          </Box>

          <Box
            className={`${tableStyle.ordDarkBlueText} ${tableStyle.fs13px}`}
          >
            <span className={`${tableStyle.ordDarkGrayText}`}>
              <span className={`fw-bold`}>Nit:&nbsp;</span>
              <span>{data?.account?.nit || '-'}</span>
              <span className={`fw-bold`}>&nbsp;|&nbsp;</span>
            </span>

            <span className={`${tableStyle.ordDarkGrayText}`}>
              <span className={`fw-bold`}>Teléfono:&nbsp;</span>
              <span>{data?.account?.phone || '-'}</span>
              <span className={`fw-bold`}>&nbsp;|&nbsp;</span>
            </span>

            <span className={`${tableStyle.ordDarkGrayText}`}>
              <span className={`fw-bold`}>Email:&nbsp;</span>
              <span>{data?.account?.email || '-'}</span>
              <span className={`fw-bold`}>&nbsp;|&nbsp;</span>
            </span>

            <span className={`${tableStyle.ordDarkGrayText}`}>
              <span className={`fw-bold`}>Dirección:&nbsp;</span>
              <span>{data?.account?.address || '-'}</span>
            </span>
          </Box>

        </Box>

        <Box textAlign={'end'}>
          <Box className={`${tableStyle.ordAquaMarineText} fw-bold text`}>Orden de Servicio - {data?.id} </Box>
          <Box className={`${tableStyle.fs13px}`}>&nbsp;</Box>
          <Box className={`${tableStyle.ordDarkGrayText} ${tableStyle.fs13px}`}>
            <span className='fw-bold'>Expedición:&nbsp;</span>
            <span>{data?.createdDate || '-'}</span>
          </Box>

        </Box>

      </Box>

      <Box
        className={`${tableStyle.ordDarkGrayText}`}
        display={'grid'}
        gridTemplateColumns={'1fr 1fr'}
        gap={2}
        mb={3}
      >
        <Box>
          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Paciente:</span>
            <span>{data?.patient?.name || '-'}</span>
          </Box>

          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Empresa:</span>
            <span>{data?.company?.name || '-'}</span>
          </Box>

          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Autorización:</span>
            <span>{data?.service?.authorizationNumber || '-'}</span>
          </Box>
        </Box>

        <Box>
          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Identificación:</span>
            <span>{`${data?.patient?.docType} ${data?.patient?.document}`}</span>
          </Box>

          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Contrato:</span>
            <span>{data?.contract?.name || '-'}</span>
          </Box>

        </Box>

      </Box>

      <Box>
        <Box
          className={`${tableStyle.ordDarkBlueText} fw-bold`}
        >
          Servicios prestados
        </Box>

        <OrdTable
          hoverYellow
          lastBorderNone
          body={formatData}
          headers={tableHeader}
          theadClassName={`${tableStyle.bgTransparent}`}
        />

      </Box>
    </Box>
  )
}

