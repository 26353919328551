import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import orangeNoBGArrow from "../../assets/img/icons/orangeNoBGArrow.svg";
import cleanCloseIcon from "../../assets/img/icons/cleanCloseIcon.svg";
import miniRoundWhiteCheck from "../../assets/img/icons/miniRoundWhiteCheck.svg";
import ordDownArrow from "../../assets/img/icons/ordDownArrow.svg";
import tableStyles from '../../components/Layouts/tableStyle.module.scss'
import Select from "react-select";
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import OrdTable from "../OrderingComponents/OrdTable";
import { useSelector } from "react-redux";
import { useMultiGetMethod, usePostMethod } from "../../Hooks/useFetch";
import { formatToRcSelect, getPermission, loader, message, swalConfirm, validateEmptyString } from "../../helpers/helpers";
import FullScreenDialog from "../OrderingComponents/FullScreenDialog";
import { RenderMedicalFormulasPdf } from "../../AsistanceModule/RenderMedicalFormulasPdf";
export const ReferenceTab = (event) => {
    const store = useSelector((state) => state);
    const listPermission = store.loginReducer.currentAccount?.profile?.permission
    const myPermission = getPermission({ prefix: 'order', listPermission })
    const idEnterprise = store.loginReducer.currentAccount.id;
    const token = store.loginReducer.Authorization;
    const [data, setData] = useState({
        type: "",
        reason: "",
        referencePlan: "",
        expectation: "",
        personWho: "",
        institution: "",
        position: "",
        phone: "",
        hour: "",
    })
    const [fullDialogScreen, setFullDialogScreen] = useState({
        show: false,
        id: ""
    })
    const [tmpArr, setTmpArr] = useState([])
    /* ----------------------------- FETCHS SECTION ----------------------------- */
    /* ----------------------------- GET ----------------------------- */
    const { results: initialList, load: initialListLoader, trigger: getInitialList } = useMultiGetMethod()
    /* ---------------------------------- POST /PUT ---------------------------------- */
    const { load: orderReferenceLoader, trigger: orderReference } = usePostMethod()
    /* ------------------------------------ - ----------------------------------- */
    useEffect(() => {
        getInitialList({
            multipleGet: [
                {
                    url: "/medical/referecenType/",
                    objFilters: { idAccount: idEnterprise },
                    requestName: "reftype",
                }
            ],
            token: token,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const formattedRefTypes = formatToRcSelect(initialList.reftype?.results, "id", "name", "contrareference", "tag", "", false)
    const headers = [
        {
            title: <>&nbsp;</>,
            className: "col-1"
        },
        {
            title: "Tipo",
            className: "px-2 text-start col-2"
        },
        {
            title: "Expectativa",
            className: "text-start col-4"
        },
        {
            title: "Motivo",
            className: "text-start col-4"
        },
        {
            title: <>&nbsp;</>,
            className: "col-1"
        },

    ]
    const handleAddToTable = () => {
        if (data.reason.length <= 49) {
            return message("info", "Campos erróneos", "El motivo debe tener mínimo 50 caracteres")
        }
        let validations = validateEmptyString(
            data.type?.extra === 1 ? [
                { field: data.type?.value, message: "Debe diligenciar el campo tipo" },
                { field: data.reason, message: "Debe diligenciar el campo Motivo" },
                { field: data?.referencePlan, message: "Debe diligenciar el campo plan de referencia" },
                { field: data.expectation, message: "Debe diligenciar el campo expectativa" },
                { field: data.personWho, message: "Debe diligenciar el campo persona que acepta la remisión" },
                { field: data.institution, message: "Debe diligenciar el campo institución" },
                { field: data.position, message: "Debe diligenciar el campo cargo" },
                { field: data.phone, message: "Debe diligenciar el campo celular" },
                { field: data.hour, message: "Debe diligenciar el campo hora" },
            ] : [
                { field: data.type?.value, message: "Debe diligenciar el campo tipo" },
                { field: data.reason, message: "Debe diligenciar el campo Motivo" },
                { field: data?.referencePlan, message: "Debe diligenciar el campo plan de referencia" },
                { field: data.expectation, message: "Debe diligenciar el campo expectativa" },
            ]
        )
        if (validations.valid) {
            let newData = { ...data, id: Date.now(), isRequ: false }
            tmpArr.push(newData)
            formatData(tmpArr)
            setData({
                type: "",
                reason: "",
                referencePlan: "",
                expectation: "",
                personWho: "",
                institution: "",
                position: "",
                phone: "",
                hour: "",
            })
        }
    }
    const [tempTable, setTempTable] = useState({
        currentItem: {}
    })
    const handleToggle = (item, isOpen) => {
        let accordion = isOpen === "open" ? "fold" : "open"
        let newItem = { ...item, isOpen: accordion }
        setTempTable({ ...tempTable, currentItem: newItem })
    }
    const deleteItem = (item) => {
        let { id } = item
        let newArr = tmpArr.length > 0 && tmpArr.filter(x => x.id !== id)
        setTmpArr(newArr)
    }
    const renderTooltiDelete = (props) => (
        <Tooltip  {...props}>
            Eliminar
        </Tooltip>
    );
    const formatData = () => {
        let tempList = [];
        if (Array.isArray(tmpArr)) {
            tmpArr.forEach((item, index) => {
                // let isOpen = tempTable.currentItem?.id === item.id ? "open" : "fold"
                // let rotate = tempTable.currentItem?.id === item.id ? tableStyles.rotate180Degrees : ""
                let isOpen = tempTable.currentItem?.id === item.id ? tempTable.currentItem.isOpen : "fold"
                let rotate = tempTable.currentItem?.id === item.id ? tempTable.currentItem.isOpen === "open" ? tableStyles.rotate180Degrees : tableStyles.rotate0Degrees : tableStyles.rotate0Degrees
                tempList.push(
                    <>
                        {/* /* ---------------------- FIRST ROW IS THE NORMAL VIEW ---------------------- */}
                        <tr
                            key={index}
                            // eslint-disable-next-line eqeqeq
                            // onClick={() => item.type?.extra == 1 ? handleToggle(item) : null}
                            onClick={(e) => {
                                handleToggle(item, isOpen)
                            }}
                            className="view">
                            <td
                                className={`${rotate}  col-1 `}
                            > <img
                                src={ordDownArrow}
                                alt="flecha abajo"
                            ></img></td>
                            <td className={`text-start`} >
                                {item.type?.label}
                            </td>
                            <td className={`text-start`} >{item.expectation}</td>
                            <td className={`text-start`} >{item.reason}</td>
                            <td className={`text-center`} >
                                &nbsp;
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltiDelete}
                                >
                                    <span>
                                        <img
                                            className="cursorPointer"
                                            onClick={() => deleteItem(item)}
                                            src={cleanCloseIcon}
                                            width="10px"
                                            alt="Eliminar"
                                        >
                                        </img> </span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        {/* /* ---------------------- SECOND ROW IS THE FOLDED CONTENT ---------------------- */}
                        < tr
                            key={index}
                            className={`${isOpen} w-100`} >
                            <td colspan="12">
                                <div className="fold-content">
                                    <Row>
                                        <Col xs={5} className={`mx-2 ${tableStyles.rightLineGray}`}>
                                            <span className={`${tableStyles.ordClearBlueText}`}>
                                                <b>
                                                    Persona que acepta la remisión
                                                </b>
                                            </span>
                                            <br></br>
                                            <span>
                                                {item.personWho || "-"}
                                            </span>
                                        </Col>
                                        <Col xs={2} className={`text-start`} >
                                            <span className={`${tableStyles.ordClearBlueText}`}>
                                                <b>
                                                    Cargo
                                                </b>
                                            </span>
                                            <br></br>
                                            <span>
                                                {item.position || "-"}
                                            </span>
                                        </Col>
                                        <Col xs={2} className={`text-start`} >
                                            <span className={`${tableStyles.ordClearBlueText}`}>
                                                <b>
                                                    Celular
                                                </b>
                                            </span>
                                            <br></br>
                                            <span>
                                                {item.phone || "-"}
                                            </span>
                                        </Col>
                                        <Col xs={2} className={`text-start`} >
                                            <span className={`${tableStyles.ordClearBlueText}`}>
                                                <b>
                                                    Hora
                                                </b>
                                            </span>
                                            <br></br>
                                            <span>
                                                {item.hour || "-"}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </td>
                        </tr >
                    </>,
                );
            });
        }
        return tempList;
    };
    const handleSubmit = () => {
        let toSend = []
        tmpArr.length > 0 && tmpArr.forEach((e, i) => {
            toSend.push({
                refId: e.type?.value,
                reoCause: e.reason,
                reoReferencePlan: e.referencePlan,
                reoExpectation: e.expectation,
                reoReferenceToFulllname: e.personWho,
                reoEntity: e.institution,
                reoPosition: e.position,
                reoCellphone: e.phone,
                reoTime: e.hour,
            })
        })
        orderReference({
            url: "/medical/reference/",
            method: "POST",
            body: {
                clhId: event?.event?.clhId,
                references: toSend
            },
            noAlert: true,
            token: token,
            succesAction: (results) => {
                setFullDialogScreen({
                    show: true,
                    id: results.results
                })
            }
        })
    }
    return (
        <>
            {orderReferenceLoader && loader}
            {/* /* --------------------------------- DIALOG --------------------------------- */}
            <FullScreenDialog
                onHide={() => setFullDialogScreen(false)}
                isOpen={fullDialogScreen.show}
            >
                <RenderMedicalFormulasPdf
                    withPagination
                    pdfParams={{
                        url: '/medical/pdfReference/',
                        id: fullDialogScreen.id,
                    }}
                    title={'Referencia y contrarreferencia'}
                    finalize={{
                        title: "Finalizar",
                        finalizeAction: () => {
                            setData({
                                type: "",
                                reason: "",
                                referencePlan: "",
                                expectation: "",
                                personWho: "",
                                institution: "",
                                position: "",
                                phone: "",
                                hour: "",
                            });
                            setTmpArr([]);
                            message('success', 'Orden(es) finalizada')
                            setFullDialogScreen(false)
                        }
                    }}
                    backAction={() => {
                        setData({
                            type: "",
                            reason: "",
                            referencePlan: "",
                            expectation: "",
                            personWho: "",
                            institution: "",
                            position: "",
                            phone: "",
                            hour: "",
                        });
                        setTmpArr([]);
                        setFullDialogScreen(false)
                    }}
                />
            </FullScreenDialog>
            {/* /* ----------------------------- LOADERS SECTION ---------------------------- */}
            {initialListLoader && loader}
            <div className={` ${tableStyles.ordContainer}  mt-3 p-3`}>

                {/* /* ------------------------------- #1 BlueForm ------------------------------ */}
                < Form.Group
                    className={`${tableStyles.ordSearchSections}  mx-5 pt-2 p-2 text-start`} controlId="cc">
                    <Row className={`px-2`}>
                        <Col xs={12}>
                            <h6 className={`${tableStyles.ordClearBlueText}`}>
                                <b>
                                    Referencia
                                </b>
                            </h6>
                        </Col>
                        <Col xs={3}>
                            <Form.Group className="mb-3 text-start" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                    <b> &nbsp;Tipo </b>
                                </Form.Label>
                                <Select noOptionsMessage={() => 'No hay datos'}
                                    value={data.type}
                                    options={formattedRefTypes}
                                    onChange={(e) => setData({ ...data, type: e })}
                                    className="text-secondary"
                                    placeholder={"Seleccione"}
                                    styles={ordCustomSelect}
                                ></Select>
                            </Form.Group></Col>
                        <Col xs={9} className="">
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start"> &nbsp;Motivo</b>
                                </Form.Label>
                                <Form.Control
                                    value={data.reason}
                                    onChange={(e) => setData({ ...data, reason: e.target.value })}
                                    className={`ord-roundInput`} type="text" placeholder="Escribir..." />
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start">&nbsp;Plan de referencia</b>
                                </Form.Label>
                                <textarea
                                    value={data.referencePlan}
                                    onChange={(e) => setData({ ...data, referencePlan: e.target.value })}
                                    placeholder={"Escribir..."}
                                    rows="25"
                                    cols="55"
                                    style={{ height: "4rem" }}
                                    className={`text-secondary ord-roundInput w-100`}
                                ></textarea>

                            </Form.Group></Col>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="fName">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                    <b className="text-start"> &nbsp;Expectativa</b>
                                </Form.Label>
                                <textarea
                                    value={data.expectation}
                                    onChange={(e) => setData({ ...data, expectation: e.target.value })}
                                    placeholder={"Escribir..."}
                                    rows="25"
                                    cols="55"
                                    style={{ height: "4rem" }}
                                    className={`text-secondary ord-roundInput w-100`}
                                ></textarea>

                            </Form.Group></Col>
                    </Row>
                    {/* #2 contra */}
                    {data.type?.extra !== 1 && <Col xs={12}
                        className="justify-content-end d-flex cursorPointer">
                        <p className={`pt-2 px-3 ${tableStyles.ordOrangeText}`}>
                            <b>Agregar</b>
                        </p>
                        <img
                            onClick={() => handleAddToTable()}
                            className="cursorPointer"
                            width={"30px"}
                            alt="add"
                            src={orangeNoBGArrow}>
                        </img>
                    </Col>}
                    {data.type?.extra === 1 &&
                        <Row className={`px-2`}>
                            <Col xs={12}>
                                <h6 className={`${tableStyles.ordClearBlueText}`}>
                                    <b>
                                        Contrarreferencia
                                    </b>
                                </h6>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group className="mb-3" controlId="fName">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                        <b className="text-start"> &nbsp;Persona que acepta la remisión</b>
                                    </Form.Label>
                                    <Form.Control
                                        value={data.personWho}
                                        onChange={(e) => setData({ ...data, personWho: e.target.value })}
                                        className={`ord-roundInput`} type="text" placeholder="" />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group className="mb-3" controlId="fName">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                        <b className="text-start"> &nbsp;Institución</b>
                                    </Form.Label>
                                    <Form.Control
                                        value={data.institution}
                                        onChange={(e) => setData({ ...data, institution: e.target.value })}
                                        className={`ord-roundInput`} type="text" placeholder="" />
                                </Form.Group>
                            </Col>
                            <Col xs={3} className="">
                                <Form.Group className="mb-3" controlId="fName">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                        <b className="text-start"> &nbsp;Cargo</b>
                                    </Form.Label>
                                    <Form.Control
                                        value={data.position}
                                        onChange={(e) => setData({ ...data, position: e.target.value })}
                                        className={`ord-roundInput`} type="text" placeholder="" />
                                </Form.Group>
                            </Col>
                            <Col xs={3} className="">
                                <Form.Group className="mb-3" controlId="fName">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                        <b className="text-start"> &nbsp;Celular</b>
                                    </Form.Label>
                                    <Form.Control
                                        value={data.phone}
                                        onChange={(e) => setData({ ...data, phone: e.target.value })}
                                        className={`ord-roundInput`} type="number" placeholder="" />
                                </Form.Group>
                            </Col>
                            <Col xs={3} className="">
                                <Form.Group className="mb-3" controlId="fName">
                                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                        <b className="text-start"> &nbsp;Hora</b>
                                    </Form.Label>
                                    <Form.Control
                                        value={data.hour}
                                        onChange={(e) => setData({ ...data, hour: e.target.value })}
                                        className={`ord-roundInput`} type="time" placeholder="" />
                                </Form.Group>
                            </Col>

                            <Col xs={3}
                                onClick={() => handleAddToTable()}
                                className="pt-4 justify-content-end d-flex cursorPointer">
                                <p className={`pt-2 px-3 ${tableStyles.ordOrangeText}`}>
                                    <b>Agregar</b>
                                </p>
                                <img
                                    className="cursorPointer"
                                    width={"30px"}
                                    alt="add"
                                    src={orangeNoBGArrow}>
                                </img>
                            </Col>
                        </Row>}
                </Form.Group>

                <div
                    style={{ marginBottom: '15%' }}
                    className={` mx-5 pt-2 text-start`}>
                    <Col className={`pl-0 m-0 p-0`} xs={12}>
                        <OrdTable
                            className={`w-100 ${tableStyles.shade}`}
                            isAnAccTable
                            headers={headers}
                            hasChildren={true}
                        >
                            {formatData()}
                        </OrdTable></Col>
                </div>
                <Row className={`${tableStyles.ordBorderTop} mt-2`}>
                    <Col xs={12} className="d-flex justify-content-end pt-3 mb-3">
                        <button className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                        {
                            myPermission?.create && (
                                <button
                                    onClick={() =>
                                        tmpArr.length > 0 ? swalConfirm({
                                            title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                                            text: `Se creará(n) referencia(s) y contrareferencia(s) para ${event?.event?.firstName} ${event?.event?.lastName}`,
                                            confirmButtonText: "Si, continuar",
                                            doAfterConfirm: () => handleSubmit(),
                                        }) : message('info', 'Sin referencias')
                                    }
                                    className={`${tableStyles.ordBtnPrimary} btn`}>
                                    Generar
                                </button>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </>
    )
}
