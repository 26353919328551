import React, { useState, useEffect } from "react";
import { OrdGenericTemplate } from "../OrderingModule/OrderingComponents/OrdGenericTemplate";
import { BoxSelect, Title } from "./styled";
import Select from "react-select";
import { ordCustomSelect } from "../components/Layouts/react-select-custom";
import { useSelector, useDispatch } from "react-redux";
import tableStyles from "../components/Layouts/tableStyle.module.scss";
import TableStatic from "../components/TableStatic/TableStatic";
import { useGetMethod, usePostMethod } from "../Hooks/useFetch";
import { getSites } from "./../actions/sitesActions";
import { loader, message } from "../helpers/helpers";
import { useLocation } from "react-router";
import { parse } from "query-string";
import { useHistory } from "react-router-dom";
import { Alert } from "../components/TableStatic/styled";
import useForm from "../Hooks/useForm";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from 'react-bootstrap';
import alertCircle from '../assets/img/icons/alert-circle-orange.svg'

const ParameterizationOfTimes = () => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const dispatch = useDispatch();
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const eaccount = store.loginReducer.currentAccount.id;
  const userId = store.loginReducer.user_data.id;

  const {
    results: ListCompanyTypes,
    trigger: getListCompanyTypes,
    load: loadListCompanys,
  } = useGetMethod();

  let initialState = {};
  const {
    handleChange: changeForm,
    valuesForm,
    setValuesForm,
    reset,
  } = useForm(initialState);

  const [data, setData] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [filtersCloneTime, setFiltersCloneTime] = useState({});

  const location = useLocation();
  const parseLocation = parse(location.search);

  const {
    results: ListServices,
    trigger: getListServices,
    load: loadListServices,
  } = useGetMethod();

  const {
    results: listSites,
    trigger: getListSites,
    load: loadListSites,
  } = useGetMethod();

  const options = ListCompanyTypes?.results?.map(({ id, name, tag }) => ({
    value: tag,
    label: name,
    id,
  }));

  const { load: createServiceLoader, trigger: createNewService } =
    usePostMethod();

  const createService = (getData) => {
    createNewService({
      url: "/medical/doctor/serviceTimesPerDoctor/",
      token: token,
      method: "POST",
      body: getData,
      succesAction: (data) => {
        message(
          "success",
          `<span class=${tableStyles.ordClearBlueText}> ${data.message}</span>`
        );

        history.push(`/cuentasMedicas/Contratos`);

      },
    });
  };

  // const locations =
  //   store?.siteReducer?.sites?.map(({ id, description, active }) => ({
  //     id,
  //     active,
  //     name: description,
  //   })) || [];

  const [locations, setLocations] = useState([])


  useEffect(() => {
    getListCompanyTypes({
      url: "/medical/generals/companyTypes/",
      token,
    });

    getListSites({
      url: "/medical/generals/site/",
      objFilters: { eaccount: store.loginReducer.currentAccount.id, status: "enabled" },
      token,
    });

    // dispatch(
    //   getSites({ id_account: store.loginReducer.currentAccount.id, active: 1 })
    // );

  }, []);

  useEffect(() => {

    if (listSites?.success) {

      const filter = listSites?.results?.map(({ siteId, site }) =>
        ({ id: siteId, name: site, }))

      setLocations(filter)
    }
  }, [listSites])


  const [changeSelect, setChangeSelect] = useState(false);
  const [idSelect, setIdSelect] = useState(0)
  const handleChangeSelect = (e) => {
    setIdSelect(e.id)
    getListServices({
      url: "/medical/doctor/serviceTimesPerDoctor/",
      objFilters: {
        userMd: Number(parseLocation.userMd),
        companyType: e.id,
        idContractMd: Number(parseLocation.idContractMd),
      },
      token,
    });

    setData((state) => ({
      companyType: e.id,
      userMd: Number(parseLocation.userMd),
      ...state,
    }));

    setChangeSelect((e) => !e);
  };
  const services = ListServices.results;
  const [r, setR] = useState(true)
  const handleSubmit = (e) => {
    e.preventDefault();
    if (r) {
      let a = [];
      let obj = {};
      let obj1 = {};
      let rr = [];
      for (let i = 0; i < services.length; i++) {
        obj = {};
        obj1 = {};
        rr = [];
        let x = 0;

        for (let j = 0; j < data.servicesTimes?.length; j++) {

          if (services[i]?.idService === data?.servicesTimes[j].idService) {
            if (typeof data.servicesTimes[j].sites[0]?.maxTime !== "undefined") {
              obj.idSite = data.servicesTimes[j].sites[0].idSite;
              obj.maxTime = data.servicesTimes[j].sites[0].maxTime;
            }
            if (typeof data.servicesTimes[j].sites[0]?.minTime !== "undefined") {
              obj.minTime = data.servicesTimes[j].sites[0].minTime;
              obj.idSite = data.servicesTimes[j].sites[0].idSite;
            }
            x++;
            if (x === 2) {
              x = 0;
              rr.push(obj);
              obj = {};
            }
          }
        }
        obj.sites = [...rr];
        obj1.idService = services[i].idService;
        obj1.name = services[i].name;
        obj1.sites = [...rr];
        a.push(obj1);
      }
      data.servicesTimes = a;
    }

    let hola = []
    let ok = []
    let cc = []
    let objx = {}


    for (let i = 0; i < services.length; i++) {


      if (cc.length) {
        hola.push(cc)
        objx.sites = cc.filter(e => e !== undefined)
        const validate = objx.sites.filter(e => e.maxTime === 0 || e.minTime === 0)
        if (validate.length) {
          setR(false)
          return MySwal.fire({
            icon: "warning",
            title: "Error",
            text: "el tiempo mínimo y el tiempo máximo son obligatorios",
          });
        }

        ok.push(objx)
        cc = []
      }

      objx = {}
      for (let j = 0; j < data.servicesTimes[i]?.sites?.length; j++) {


        if (data.servicesTimes[i].idService === services[i].idService) {

          if (data.servicesTimes[i].idService === 540) {

          }
          if (data?.servicesTimes[i]?.sites[j]?.maxTime !== 0 || data?.servicesTimes[i]?.sites[j]?.minTime !== 0) {
            objx.idService = data.servicesTimes[i].idService
            objx.name = data.servicesTimes[i].name
            cc.push(data.servicesTimes[i].sites[j])
          }

        }
      }

      if (cc.length && (i + 1 === services.length)) {
        hola.push(cc)
        objx.sites = cc.filter(e => e !== undefined)

        ok.push(objx)
        cc = []
      }

    }

    data.servicesTimes = ok;
    data.companyType = idSelect


    if (!data.servicesTimes.length) {
      return MySwal.fire({
        icon: "warning",
        title: "Error",
        text: "Por favor , digite al menos un grupo de servicio",
      });
    }

    createService(data);
  };

  const renderOrderTypeTooltip = (text) => (
    <Tooltip>
      {text}
    </Tooltip>
  );

  return (
    <>
      {(loadListCompanys || loadListServices || createServiceLoader || loadListSites) && loader}
      <OrdGenericTemplate
        showBottomBorder={false}
        title={"Parametrización tiempos de atención"}
        titleSize={12}
        className="mx-2 py-2 w-90"
        titleStyle={{ marginLeft: "1rem" }}
      >
        <form onSubmit={handleSubmit}>
          <BoxSelect 
            className="mx-4 py-2 d-flex justify-content-between align-items-center"
            style={locations.length === 1 
                ? {width:`calc(700px * ${locations.length} + 400px )`} 
                :locations.length <= 3 ? {width:`calc(230px * ${locations.length} + 400px )`} : {width:'100%'}}
          >
            <div>
            <Title className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>
              Tipo de Compañía
            </Title>
            <Select noOptionsMessage={() => 'No hay datos'}
              options={options}
              className="text-secondary"
              placeholder={"Seleccione"}
              styles={ordCustomSelect}
              onChange={handleChangeSelect}
            ></Select>
            </div>
            
            {
              idSelect ?
              <div style={locations.length <= 3 ? {} : {marginRight:'1.8rem'}}>
                          
                          <OverlayTrigger
                              placement="top"
                              delay={{ show: 100, hide: 100 }}
                              overlay={renderOrderTypeTooltip('El tiempo parametrizado será medido en minutos')}
                            >
                              <span>
                                <img src={alertCircle} alt="alert" />
                              </span>
                            </OverlayTrigger>
                          
                        </div>:''
            }
            
          </BoxSelect>

          {/* {loadListServices && loader} */}

          {!services?.length ? (
            // <Alert>no hay servicios asociados</Alert>
            <Alert>Seleccione un Grupo de Atención</Alert>

          ) : (
            <TableStatic
              r={{ setR }}
              handleChange={changeForm}
              valuesForm={valuesForm}
              setValuesForm={setValuesForm}
              changeSelect={changeSelect}
              initialState={initialState}
              locations={locations}
              services={services || []}
              data={{ setData, data }}
              filterClone={{ setFiltersCloneTime, filtersCloneTime }}
            />
          )}
        </form>
      </OrdGenericTemplate>
    </>
  );
};

export default ParameterizationOfTimes;
