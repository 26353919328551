import React, { Component } from "react";
import style from "./layout-styles/accountInput.module.scss";
class AccountInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.data1 = React.createRef();
    this.data2 = React.createRef();
    this.data3 = React.createRef();
    this.data4 = React.createRef();
  }
  onFocus = (e) => {
    var { value, name, maxLength } = e.target;
    switch (name) {
      case "data1":
        if (value >= maxLength) {
          this.data2.current.focus();
        }
        break;
      case "data2":
        if (value >= maxLength) {
          this.data3.current.focus();
        }
        break;
      case "data3":
        if (value >= maxLength) {
          this.data4.current.focus();
        }
        break;
      case "data4":
        if (value >= maxLength) {
          this.data4.current.blur();
        }
        break;
      default:
        break;
    }
  };
  onChange = (e) => {
    var { value, name, maxLength } = e.target;
    switch (name) {
      case "data1":
        if (value >= maxLength) {
          this.data2.current.focus();
        }
        break;
      case "data2":
        if (value >= maxLength) {
          this.data3.current.focus();
        }
        break;
      case "data3":
        if (value >= maxLength) {
          this.data4.current.focus();
        }
        break;
      case "data4":
        if (value >= maxLength) {
          this.data4.current.blur();
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div className="d-flex justify-content-center">
        <input
          name="data1"
          onFocus={(e) => this.onFocus(e)}
          onChange={(e) => this.onChange(e)}
          className={`border p-4 text-center h2 ${style.inputAccountNumber}`}
          type="number"
          pattern="[0-9]"
          placeholder="1"
          maxLength={1}
          ref={this.data1}
        />
        <input
          name="data2"
          onFocus={(e) => this.onFocus(e)}
          onChange={(e) => this.onChange(e)}
          className={`border p-4 text-center h2 ${style.inputAccountNumber}`}
          type="number"
          pattern="[0-9]"
          placeholder="2"
          maxLength={1}
          ref={this.data2}
        />
        <input
          name="data3"
          onFocus={(e) => this.onFocus(e)}
          onChange={(e) => this.onChange(e)}
          className={`border p-4 text-center h2 ${style.inputAccountNumber}`}
          type="number"
          pattern="[0-9]"
          placeholder="03"
          maxLength={3}
          ref={this.data3}
        />
        <input
          name="data4"
          onFocus={(e) => this.onFocus(e)}
          onChange={(e) => this.onChange(e)}
          className={`border p-4 text-center h2 ${style.inputAccountNumber}`}
          type="number"
          pattern="[0-9]"
          placeholder="04"
          maxLength={3}
          ref={this.data4}
        />
      </div>
    );
  }
}

export default AccountInput;
