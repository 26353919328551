import React, { Component } from "react";
import { connect } from "react-redux";

class UsersCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
       <div className="container">
           <h1>Registro de Usuario</h1>
           
       </div>
        );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authenticated,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UsersCreation);
