//react
import React, { useState, useEffect } from "react";
import es from "date-fns/locale/es"; 
import moment from "moment";

//helpers
import { convertMoneyFormatNew, loader } from "../../../../helpers";

//icons
import ordBlueSearch from "../../../../../src/assets/img/icons/ordBlueSearch.svg";

// hooks
import { useGetMethod } from "../../../../Hooks/useFetch";
import { useSelector } from "react-redux";

//css
import styles from "../../../../components/Layouts/tableStyle.module.scss";

//components
import { OrdGenericTemplate } from "../../../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import { Box } from "@mui/material";
import OrdTable from "../../../../OrderingModule/OrderingComponents/OrdTable";
import DatePicker from "react-datepicker";

export const QueriesOccupation = () => {

    //constants-------------------------

    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const userId = store.loginReducer.user_data.id;
    const eaccount = store.loginReducer.currentAccount.id;

    //states
    const [data, setData] = useState({
        filters: {
            userId,
            eaccount,
            page: 1,
            perpage: 10,
            search: undefined,
            dateFilter: new Date(),
        },
        trigger: 0
    })

    // headers
    const headers = [
        {
            title: `Año`,
            className: `px-3 text-center `,
        },
        {
            title: `Mes`,
            className: `px-2 text-center ${styles.minWidth100px}`,
        },
        {
            title: "Médico",
            className: `px-2 text-start col-4`,
        },
        {
            title: "Agenda",
            className: "px-2 text-start col-3",
        },
        {
            title: "Habilitados",
            className: "px-2 text-center",
        },
        {
            title: "Atendidos",
            className: `px-2 text-center`,
        },
        {
            title: "Inasistencias",
            className: `px-2 text-center`,
        },
        {
            title: "Agendados",
            className: "px-2 text-center",
        },
        {
            title: "Disponibles",
            className: "px-3 text-center",
        },
    ];

    // functions

    const incrementTrigger = () => {
        setData(state => ({
            ...state,
            trigger: state.trigger + 1
        }))
    }

    const formatData = (array) => {

        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                tempList.push(
                    <tr key={index} className={`hover-table-row`}>
                        <td className="text-center px-3 m-auto">
                            <div className={`${styles.minWidth50px} m-auto`}>
                                {item?.schYear || '-'}
                            </div>
                        </td>

                        <td className="text-center px-2 m-auto">
                            <div className={`${styles.minWidth50px} m-auto`}>
                                {item?.schMonth || '-'}
                            </div>
                        </td>
                        
                        <td className="text-start px-2">
                            <div>{item?.doctorName || '-'}</div>
                            <div className="d-flex">
                                <span>{item?.documentType || '-'}&nbsp;</span>
                                <span>{convertMoneyFormatNew({ textNumber: item?.document, SymbolShow: true, SymbolDecimal: '.' }) || '-'}</span>
                            </div>
                        </td>

                        <td className="text-start px-2">
                            <span>{item?.schName || '-'}</span>
                        </td>

                        <td className="text-center px-2">
                            <span>{item?.enabledApps || '-'}</span>
                        </td>

                        <td className="text-center px-2">
                            <span>{item?.attendedApps || '-'}</span>
                        </td>

                        <td className="text-center px-2"
                        >
                            <span>{item?.cancelledApps || '-'}</span>
                        </td>

                        <td className="text-center px-2">
                            <span>{item?.scheduledApps || '-'}</span>
                        </td>

                        <td className="text-center px-3">
                            <span>{item?.availableApps|| '-'}</span>
                        </td>
                    </tr >
                );
            });
        }
        return tempList;
    };

    // hooks

    const {
        load: loaderListCapability,
        results: listCapability,
        trigger: getListCapability,
    } = useGetMethod();

    useEffect(() => {

        getListCapability({
            token,
            objFilters: {
                ...data?.filters, 
                dateFilter: data?.filters?.dateFilter ? moment(data?.filters?.dateFilter).format('MM-YYYY') : undefined
            },
            url: "/medical/admissions/capability/",
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.trigger])
    
    return (
        <>
            {(loaderListCapability) && loader}

            <OrdGenericTemplate
                titleSize={12}
                className={'mx-2'}
                title={"Consulta de ocupación"}
            >

                <Box className={`mb-3 asistencialDateColorIcon ${styles.app_mr_100}`}>

                    <Box
                        display={'grid'}
                        gridTemplateColumns={'230px 1fr'}
                        gridAutoRows={'100%'}
                    >
                        <Box>
                            <span className={`m-0 d-block ${styles.ordDarkBlueText} fw-bold`}>
                                &nbsp;Mes - Año
                            </span>
                            <Box id="opportunityInputDate">
                                <DatePicker
                                    selected={data?.filters?.dateFilter}
                                    onChange={(date) => {
                                        setData(state => (
                                            {
                                                ...state,
                                                filters: {
                                                    ...state.filters,
                                                    dateFilter:date
                                                }
                                            }
                                        ))
                                    }}
                                    dateFormat="MMMM - yyyy"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    showFourColumnMonthYearPicker
                                    className={styles.inputMonthYear}
                                    locale={es}
                                />
                            </Box>

                        </Box>

                        <Box className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
                            <input
                                className={`ord-roundInput ${styles.ordDarkGrayText} w-100 pe-2`}
                                type="text"
                                placeholder="Escribe aquí para buscar"
                                autoComplete="off"
                                onChange={(e) => {
                                    setData(state => ({
                                        ...state,
                                        filters: { ...state?.filters, search: e.target.value }
                                    }))
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") incrementTrigger()
                                }}
                            />
                            <img
                                src={ordBlueSearch}
                                className="pointer"
                                alt="buscar"
                                onClick={incrementTrigger}
                            />
                        </Box>

                    </Box>

                    <OrdTable
                        shadow
                        hasChildren
                        headers={headers}
                        paginate={{
                            activePage: data?.filters.page,
                            totalPages: listCapability?.rowTotal,
                            perPage: data?.filters?.perpage,
                            pageRangeDisplayed: 3,
                            showTextDetails: true,
                            onChangePage: async (e) => {
                                setData(state => ({
                                    ...state,
                                    trigger: state.trigger + 1,
                                    filters: {
                                        ...state.filters,
                                        page: e
                                    }
                                }))
                            },
                        }}
                    >
                        {formatData(listCapability?.results || [])}
                    </OrdTable>
                </Box>

            </OrdGenericTemplate>
        </>
    );
};