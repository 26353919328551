import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { responseSwal } from "../helpers/responseSwal";

import {
  GET_RETEICA,
  GET_RETEICA_RATE,
  GET_ONE_RETEICA,
  GET_TYPE_EXPENSE,
  GET_ONE_TYPE_EXPENSE,
  GET_PETTY_CASH,
  GET_EMPLOYEES,
  GET_JOURNAL_TYPE,
  GET_ONE_PETTY_CASH,
  GET_REPAYMENT,
  GET_DETAIL_EXPENSE,
  GET_DETAIL_REPAYMENT,
  GET_LIST_PETTY_CASH_EXPENSES,
  GET_EXPENSE_FOR_REPAYMENT,
  GET_THIRD_PARTIES,
  GET_ONE_DETAIL_EXPENSE,
  GET_PETTYCASH_USER_PERMISSION,
  GET_ONE_PETTYCASH_USER_PERMISSION,
  GET_LIST_USERS,
  GET_CONFIGURATION_PARAMS,
  REPAYMENT_LOADING,
  GET_PAYROLL_PARAMS,
} from "./actionTypes";
import { message } from "../helpers/helpers";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

export const getListReteICA = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_RETEICA,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/reteica/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_RETEICA,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_RETEICA,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getListReteICARate = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/reteicaRate/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_RETEICA_RATE,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const creatReteica = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/reteica/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      token: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        customSwaltAlert({
          showCancelButton: false,
          icon: "success",
          title: "Creado exitosamente",
          text: `Se ha creado la actividad: ${res.results.name}`,
          confirmButtonText: "Aceptar",
        }).then((response) => {
          if (response.isConfirmed) {
            doAfter();
          } else {
            doAfter();
          }
        });
      } else {
        customSwaltAlert({
          showCancelButton: false,
          icon: "warning",
          title: "Intenta de nuevo",
          text: res.message,
          footer: "Si el problema persiste comuníquese con un asesor.",
        });
      }
    })
    .catch((res) => {
      customSwaltAlert({
        showCancelButton: false,
        icon: "warning",
        title: "Intenta de nuevo",
        text: res.message,
        footer: "Si el problema persiste comuníquese con un asesor.",
      });
    });
};

export const updateReteica = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/reteica/`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      token: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        customSwaltAlert({
          showCancelButton: false,
          icon: "success",
          title: "Actividad actualizada exitosamente",
          text: `Se ha actualizado la actividad: ${res.results.name}`,
          confirmButtonText: "Aceptar",
        }).then((response) => {
          if (response.isConfirmed) {
            doAfter();
          } else {
            doAfter();
          }
        });
      } else {
        customSwaltAlert({
          showCancelButton: false,
          icon: "warning",
          title: "Intenta de nuevo",
          text: res.message,
          footer: "Si el problema persiste comuníquese con un asesor.",
        });
      }
    })
    .catch((res) => {
      customSwaltAlert({
        showCancelButton: false,
        icon: "warning",
        title: "Intenta de nuevo",
        text: res.message,
        footer: "Si el problema persiste comuníquese con un asesor.",
      });
    });
};

export const getOneReteICA =
  (objFilters, doAfter = null) =>
  (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/reteica/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_ONE_RETEICA,
          payload: results,
          total: total,
        });
        if (!!results) {
          if (!!doAfter) {
            doAfter(results);
          }
        }
      })
      .catch((err) => {
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "Ocurrió un error al traer la información de la actividad económica",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        });
      });
  };

export const changeStatusActivity = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  customSwaltAlert({
    icon: "warning",
    title: "¿Está seguro?",
    text: `Se ${data?.elem?.statusName === "Deshabilitado" ? 'habilitará' : 'deshabilitará'} la actividad: ${data?.elem?.name}`,
    showCancelButton: true,
  }).then(({isConfirmed})=>{

    if(isConfirmed){

      dispatch({
        type: GET_RETEICA,
        loading: true,
      });

      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/reteica/changeStatus/`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            customSwaltAlert({
              showCancelButton: false,
              icon: "success",
              title: "Actualizado exitosamente",
              text: `Se ${data?.elem?.statusName === "Deshabilitado" ? 'habilitó' : 'deshabilitó'} la actividad: ${data?.elem?.name}`,
              confirmButtonText: "Aceptar",
            }).then((response) => {
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {
            customSwaltAlert({
              showCancelButton: false,
              icon: "error",
              title: "Error al actualizar actividad",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
            });
          }
        })
        .catch((res) => {
          customSwaltAlert({
            showCancelButton: false,
            icon: "error",
            title: "Error al actualizar actividad",
            text: res.message,
            footer: "Si el problema persiste comuníquese con un asesor.",
          });
        }).finally(()=>{
          dispatch({
            type: GET_RETEICA,
            loading: false,
          });
        })
    }
    
  })
};
export const getListTypeExpense = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_TYPE_EXPENSE,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expenseType/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_TYPE_EXPENSE,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_TYPE_EXPENSE,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const changeStatusTypeExpense =
  (data, doAfter) => (dispatch, getState) => {

    customSwaltAlert({
      title: "¿Está seguro?",
      text: `Se ${data?.status ? 'deshabilitará' : 'habilitará'} el tipo de gasto: ${data?.description}`,
      icon: "warning",
      showCancelButton:true
    }).then((result) => {
      if (result.isConfirmed) {
        const token = getState().loginReducer.Authorization;
        fetch(
          `${URL_GATEWAY}${API_VERSION}/accounting/expenseType/changeStatus/`,
          {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",

              token: token,
            },
          }
        )
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {

              customSwaltAlert({
                icon: "success",
                title: "Actualizado exitosamente",
                text: `Se ha actualizado un tipo de gasto: ${res.results.name}`,
                confirmButtonText: "Aceptar",
                showCancelButton: false,
              }).then((response) => {
                if (response.isConfirmed) {
                  doAfter();
                } else {
                  doAfter();
                }
              });
            } else {
              customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
                confirmButtonText: "Aceptar",
                showCancelButton: false,
              })

            }
          })
          .catch((res) => {
            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            })

          });
      }
    });
  };

export const getOneTypeExpense =
  (objFilters, doAfter = null) =>
  (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expenseType/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_ONE_TYPE_EXPENSE,
          payload: results,
          total: total,
        });
        if (!!results) {
          if (!!doAfter) {
            doAfter(results);
          }
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
export const updateTypeExpense = (data, doAfter) => (dispatch, getState) => {

  customSwaltAlert({
    title: `¿Está seguro?`,
    text: `Se actualizará el tipo de gasto: ${data?.description}`,
    icon: "warning",
    showCancelButton:true
  }).then((result) => {
    if (result.isConfirmed) {
      const token = getState().loginReducer.Authorization;
      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expenseType/`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          token: token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            customSwaltAlert({
              icon: "success",
              title: "Tipo de gasto actualizado",
              text: `Se ha actualizado un tipo de gasto: ${res.results.name}`,
              confirmButtonText: "Aceptar",
              showCancelButton: true,
            }).then((response) => {
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {
            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              showCancelButton: false,
            })

          }
        })
        .catch((res) => {

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: res.message,
            footer: "Si el problema persiste comuníquese con un asesor.",
            showCancelButton: false,
          })

        });
    }
  });
};

export const creatTypeExpense = (data, doAfter) => (dispatch, getState) => {

  customSwaltAlert({
    title: `¿Está seguro?`,
    text: `Se creará el tipo de gasto: ${data?.description}`,
    icon: "warning",
    showCancelButton:true
  }).then((result) => {
    if (result.isConfirmed) {
      const token = getState().loginReducer.Authorization;
      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expenseType/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          token: token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {

            customSwaltAlert({
              icon: "success",
              title: "Creado exitosamente",
              text: `Se ha creado un tipo de gasto: ${res.results.description}`,
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            }).then((response) => {
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {
            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            })

          }
        })
        .catch((res) => {

          customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            })

        });
    }
  });
};

export const getListPettyCash = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_PETTY_CASH,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/pettyCash/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      if (results?.length === 0) {
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "No hay registros para mostrar",
          confirmButtonText: "Aceptar",
          showCancelButton: false,
        });
      }
      dispatch({
        type: GET_PETTY_CASH,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PETTY_CASH,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getOnePettyCash =
  (objFilters, doAfter = null) =>
  (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/pettyCash/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_ONE_PETTY_CASH,
          payload: results,
          total: total,
        });
        if (!!results) {
          if (!!doAfter) {
            doAfter(results);
          }
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

export const createPettyCash = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  customSwaltAlert({
    title: "¿Está seguro?",
    text: "Verifique la información",
    icon: "warning",
    showCancelButton: true
  }).then((result) => {
    if (result.isConfirmed) {
      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/pettyCash/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          token: token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {

            customSwaltAlert({
              icon: "success",
              title: "Creada exitosamente",
              text: `se ha creado la caja menor: ${res.results.name}`,
              showCancelButton: false,
              confirmButtonText: "Aceptar",
            }).then((response) => {
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              showCancelButton: false,
              confirmButtonText: "Aceptar",
              footer: "Si el problema persiste comuníquese con un asesor.",
            })
            
          }
        })
        .catch((res) => {

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: res.message,
            showCancelButton: false,
            confirmButtonText: "Aceptar",
            footer: "Si el problema persiste comuníquese con un asesor.",
          })
        });
    }
  });
};

export const getEmployees = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/candidate-list?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_EMPLOYEES,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const changeStatusPettyCash =
  (data, doAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    let statusName = "";
    if (data.status === "Deshabilitado") {
      statusName = "habilitará";
    } else {
      statusName = "deshabilitará";
    }

    customSwaltAlert({
      title: `¿Está seguro?`,
      text: "Se " + statusName + " la caja menor: " + data.name,
      icon: "warning",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `${URL_GATEWAY}${API_VERSION}/accounting/pettyCash/changeStatus/`,
          {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",

              token: token,
            },
          }
        )
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {

              customSwaltAlert({
                title: `${statusName === 'habilitará' ? 'Habilitado' : 'Deshabilitado'} exitosamente`,
                text: `caja menor: ${data.name} ${statusName === 'habilitará' ? 'habilitada' : 'deshabilitada'}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Aceptar",
              }).then((response) => {
                if (response.isConfirmed) {
                  doAfter();
                } else {
                  doAfter();
                }
              });
            } else {

              customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
                showCancelButton: false,
                confirmButtonText: "Aceptar",
              })

            }
          })
          .catch((res) => {

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              showCancelButton: false,
              confirmButtonText: "Aceptar",
            })

          });
      }
    });
  };

export const updatePettyCash = (data, doAfter) => (dispatch, getState) => {

  customSwaltAlert({
    icon: "warning",
    title: `¿Está seguro?`,
    text: "Verifique la información",
  }).then((result) => {
    if (result.isConfirmed) {
      const token = getState().loginReducer.Authorization;
      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/pettyCash/`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          token: token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            
            customSwaltAlert({
              icon: "success",
              title: "Caja menor actualizada",
              text: res.results.name,
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            }).then((response) => {
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            })
          }
        })
        .catch((res) => {

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: res.message,
            footer: "Si el problema persiste comuníquese con un asesor.",
            confirmButtonText: "Aceptar",
            showCancelButton: false,
          })
        });
    }
  });
};

export const getListJournalType = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/journalType/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_JOURNAL_TYPE,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const getRepayment = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_EXPENSE_FOR_REPAYMENT,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/accounting/expense/forRepayment/?${filters}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      if (results?.expenses?.length === 0) {
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "No hay registros para mostrar",
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        });
      }
      dispatch({
        type: GET_EXPENSE_FOR_REPAYMENT,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_EXPENSE_FOR_REPAYMENT,
        loading: false,
      });
      console.error(err.message);
    });
};

export const createRepayment = (data, doAfter) => (dispatch, getState) => {
  dispatch({
    type: REPAYMENT_LOADING,
    loading: true,
  });
  let statusName = "";
  if (data.status == "rejected") {
    statusName = "denegar";
  } else {
    statusName = "autorizar";
  }
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/repayment/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      token: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: REPAYMENT_LOADING,
          loading: false,
        });
        customSwaltAlert({
          showCancelButton: false,
          icon: "success",
          title: res.message,
          text: `${res.results.niifAccountNbr + " - " + res.results.name}`,
          confirmButtonText: "Aceptar",
        }).then((response) => {
          if (response.isConfirmed) {
            dispatch({
              type: GET_EXPENSE_FOR_REPAYMENT,
              payload: [],
              total: 0,
            });
            doAfter();
          } else {
            dispatch({
              type: GET_EXPENSE_FOR_REPAYMENT,
              payload: [],
              total: 0,
            });
            doAfter();
          }
        });
      } else {
        dispatch({
          type: REPAYMENT_LOADING,
          loading: false,
        });
        customSwaltAlert({
          showCancelButton: false,
          icon: "warning",
          title: "Error al " + statusName + " el reembolso",
          text: res.message,
          footer: "Si el problema persiste comuníquese con un asesor.",
        });
      }
    })
    .catch((res) => {
      dispatch({
        type: REPAYMENT_LOADING,
        loading: false,
      });
      customSwaltAlert({
        showCancelButton: false,
        icon: "error",
        title: "Error al " + statusName + " el reembolso",
        text: res.message,
        footer: "Si el problema persiste comuníquese con un asesor.",
      });
    });
};

export const ListRepayment = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_REPAYMENT,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/repayment/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_REPAYMENT,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err.message);
      dispatch({
        type: GET_REPAYMENT,
        loading: false,
      });
    });
};

export const DetailRepayment =
  (objFilters, doAfter = null) =>
  (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/repayment/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_DETAIL_REPAYMENT,
          payload: results,
          total: total,
        });
        if (!!results) {
          if (!!doAfter) {
            doAfter(results);
          }
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

export const getDetailExpense =
  (objFilters, doAfter = null) =>
  (dispatch, getCreate) => {
    dispatch({
      type: GET_DETAIL_EXPENSE,
      loading: true,
    });
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expense/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        if (results?.length === 0) {
          customSwaltAlert({
            showCancelButton: false,
            icon: "warning",
            title: "Intenta de nuevo",
            text: "No hay registros para mostrar",
            confirmButtonText: "Aceptar",
          });
        }
        dispatch({
          type: GET_DETAIL_EXPENSE,
          payload: results,
          total: total,
          loading: false,
        });
        if (!!results) {
          if (!!doAfter) {
            doAfter(results);
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_DETAIL_EXPENSE,
          loading: false,
          payload: [],
          total: 0,
        });
        console.error(err.message);
      });
  };

export const getOneDetailExpense =
  (objFilters, doAfter = null) =>
  (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expense/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_ONE_DETAIL_EXPENSE,
          payload: results,
          total: total,
        });
        if (!!results) {
          if (!!doAfter) {
            doAfter(results);
          }
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

//-------------- Egress 😄   --------------

export const listPettyCashExpenses = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expense/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_LIST_PETTY_CASH_EXPENSES,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const createExpense = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expense/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      token: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {

        customSwaltAlert({
          icon: "success",
          title: "Egreso creado exitosamente",
          text: `Se ha creado egreso: ${res.results.description}`,
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        }).then((response) => {
          if (response.isConfirmed) {
            doAfter();
          } else {
            doAfter();
          }
        });
      } else {

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: res.message,
          footer: "Si el problema persiste comuníquese con un asesor.",
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        })

      }
    })
    .catch((res) => {

      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: res.message,
        footer: "Si el problema persiste comuníquese con un asesor.",
        showCancelButton: false,
        confirmButtonText: "Aceptar",
      })


    });
};

export const updateExpense = (data, doAfter) => (dispatch, getState) => {

  customSwaltAlert({
    title: `¿Está seguro?`,
    text: "Verifique la información",
    icon: "warning",
    footer: "Si el problema persiste comuníquese con un asesor.",
  }).then((result) => {
    if (result.isConfirmed) {
      const token = getState().loginReducer.Authorization;
      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expense/`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          token: token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {

            customSwaltAlert({
              icon: "success",
              title: "Actualizado exitosamente",
              text: `se ha actualizado el egreso: ${res.results.name}`,
              showCancelButton: false,
              confirmButtonText: "Aceptar",
            }).then((response) => {
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              showCancelButton: false,
              confirmButtonText: "Aceptar",
            })

          }
        })
        .catch((res) => {

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: res.message,
            footer: "Si el problema persiste comuníquese con un asesor.",
            showCancelButton: false,
            confirmButtonText: "Aceptar",
          })
        });
    }
  });
};

export const updateActivity = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/reteica/changeStatus/`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      token: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        customSwaltAlert({
          showCancelButton: false,
          icon: "success",
          title: "Actividad actualizada",
          text: res.results.name,
          confirmButtonText: "Aceptar",
        }).then((response) => {
          if (response.isConfirmed) {
            doAfter();
          } else {
            doAfter();
          }
        });
      } else {
        customSwaltAlert({
          showCancelButton: false,
          icon: "error",
          title: "Error al actualizar actividad",
          text: res.message,
          footer: "Si el problema persiste comuníquese con un asesor.",
        });
      }
    })
    .catch((res) => {
      customSwaltAlert({
        showCancelButton: false,
        icon: "error",
        title: "Error al actualizar actividad",
        text: res.message,
        footer: "Si el problema persiste comuníquese con un asesor.",
      });
    });
};

export const sendForReimbursement = (data, doAfter) => (dispatch, getState) => {

  customSwaltAlert({
    title: `¿Está seguro?`,
    text: "Verifique la información",
    icon: "warning",
  }).then((result) => {
    if (result.isConfirmed) {
      const token = getState().loginReducer.Authorization;
      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expense/forRepayment/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          token: token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {

            customSwaltAlert({
              icon: "success",
              title: "Solicitud enviada exitosamente",
              text: "Reembolso pendiente de aprobación",
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            }).then((response) => {
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {
            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            })
            
          }
        })
        .catch((res) => {
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: res.message,
            footer: "Si el problema persiste comuníquese con un asesor.",
            confirmButtonText: "Aceptar",
            showCancelButton: false,
          })

        });
    }
  });
};

export const listThirdParties = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/third/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_THIRD_PARTIES,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const deleteExpense =
  (data, doAfter = null) =>
  (dispatch, getState) => {

    customSwaltAlert({
      title: `¿Está seguro?`,
      text: "Verifique la información",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/accounting/expense/`, {
          method: "DELETE",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

            token: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              customSwaltAlert({
                icon: "success",
                title: "Egreso eliminado exitosamente",
                text: `Se ha eliminado egreso: ${res.results.description}`,
                confirmButtonText: "Aceptar",
                showCancelButton: false,
              }).then((response) => {
                if (response.isConfirmed) {
                  doAfter();
                } else {
                  doAfter();
                }
              });
            } else {
              customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
                confirmButtonText: "Aceptar",
                showCancelButton: false,
              });
            }
          })
          .catch((res) => {

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            });

          });
      }
    });
  };

export const userPermission = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_PETTYCASH_USER_PERMISSION,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/accounting/pettyCashPermission/?${filters}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_PETTYCASH_USER_PERMISSION,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PETTYCASH_USER_PERMISSION,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const oneUserPermission =
  (objFilters, doAfter = null) =>
  (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(
      `${URL_GATEWAY}${API_VERSION}/accounting/pettyCashPermission/?${filters}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        const { results, total } = res;
        dispatch({
          type: GET_ONE_PETTYCASH_USER_PERMISSION,
          payload: results,
          total: total,
        });
        if (!!results) {
          if (!!doAfter) {
            doAfter(results);
          }
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
export const listUsers = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_LIST_USERS,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/users/byAccount/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_LIST_USERS,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_LIST_USERS,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const deleteUserPermission =
  (data, doAfter = null) =>
  (dispatch, getState) => {
   customSwaltAlert({
      title: `¿Está seguro?`,
      text: "Verifique la información",
      icon: "warning",
      showCancelButton:true
    }).then((result) => {
      if (result.isConfirmed) {
        const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/accounting/pettyCashPermission/`, {
          method: "DELETE",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

            token: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              customSwaltAlert({
                showCancelButton: false,
                icon: "success",
                title: 'Actualizado exitosamente',
                text: `Permisos de ${data?.userFullName} eliminados`,
                confirmButtonText: "Aceptar",
              }).then((response) => {
                if (response.isConfirmed) {
                  doAfter();
                } else {
                  doAfter();
                }
              });
            } else {
              customSwaltAlert({
                showCancelButton: false,
                icon: "warning",
                title: "Intenta de nuevo",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
              });
            }
          })
          .catch((res) => {
            customSwaltAlert({
              showCancelButton: false,
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
            });
          });
      }
    });
  };

export const updateUserPermissions =
  (data, doAfter) => (dispatch, getState) => {
   customSwaltAlert({
      title: `¿Está seguro?`,
      text: "Verifique la información",
      icon: "warning",
      showCancelButton:true
    }).then((result) => {
      if (result.isConfirmed) {
        const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/accounting/pettyCashPermission/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

            token: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              customSwaltAlert({
                showCancelButton: false,
                icon: "success",
                title: res.message,
                text: res.results.name,
                confirmButtonText: "Aceptar",
              }).then((response) => {
                if (response.isConfirmed) {
                  doAfter();
                } else {
                  doAfter();
                }
              });
            } else {
              customSwaltAlert({
                showCancelButton: false,
                icon: "warning",
                title: "Intenta de nuevo",
                text: `Hay un error de conexión, no se puede editar`,
                confirmButtonText: "Aceptar",
              });
            }
          })
          .catch((res) => {
            customSwaltAlert({
              showCancelButton: false,
              icon: "warning",
              title: "Intenta de nuevo",
              text: `Hay un error de conexión, no se puede editar`,
              confirmButtonText: "Aceptar",
            });
          });
      }
    });
  };

export const getAllconfigParams =
  (objFilters, withOutLoading = true) =>
  (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    withOutLoading &&
      dispatch({
        type: GET_CONFIGURATION_PARAMS,
        loading: true,
      });
    fetch(
      `${URL_GATEWAY}${API_VERSION}/accounting/accountParameter/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: GET_CONFIGURATION_PARAMS,
          payload: res,
          loading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CONFIGURATION_PARAMS,
          payload: [],
          loading: false,
        });
        return console.error(error.message);
      });
  };

export const getPayrollParams = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_PAYROLL_PARAMS,
    loading: true,
    rowTotal: 0,
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/params?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_PAYROLL_PARAMS,
        payload: res.results,
        loading: false,
        rowTotal: res.rowTotal,
      });
      if (!res.success) {
        dispatch({
          type: GET_PAYROLL_PARAMS,
          loading: false,
          rowTotal: 0,
        });
        return customSwaltAlert({
          icon:'warning',
          title:'Intenta de nuevo',
          text:res.message,
          showCancelButton:false,
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_PAYROLL_PARAMS,
        loading: false,
        rowTotal: 0,
      });
      console.error(error.message);
      return customSwaltAlert({
        icon:'warning',
        title:'Intenta de nuevo',
        text:error.message,
        showCancelButton:false,
      })
    });
};

export const updatePayrrolParam = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/params`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
      icon:'warning',
      title:'Intenta de nuevo',
      text:error.message,
      showCancelButton:false,
    })
  }
};
export const createNewPayrollParam = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/params`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
      icon:'warning',
      title:'Intenta de nuevo',
      text:error.message,
      showCancelButton:false,
    })
  }
};
