// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { Component, useState,useEffect } from "react";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import { Button, Col, Form, Row } from "react-bootstrap";
import ModalNew from "../../components/Layouts/ModalNew";
import Select from "react-select";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

// --------------- icons 💥  ----------------//
import Lupa from "../../assets/img/icons/lupa.svg";
import Excel from "../../assets/img/icons/excel.svg";
import Filtrar from "../../assets/img/icons/Filtrar.svg";
import LupaClear from "../../assets/img/icons/lupaClear.svg";
import Imprimir from "../../assets/img/icons/imprimir.svg";

//-------------- scss styles 😄   --------------
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import paginationStyles from "../../components/Layouts/pagination.module.scss";


//-------------- actions 😄   --------------
import { useHistory } from "react-router-dom";

import { 
    getAccountsPayable,
} from "../../actions/accountingConsultsActions";


import {
    getProviders
} from "../../actions/providersActions";

import {
    getSelectPayConditions
}from "../../actions/InvoiceReceiptActions";
import { Title } from "../../shared";





function AccountsPayable(props) {

    const history = useHistory()
    const storage = useSelector((state) => state);
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(1);
    const [filters, setFilters] = useState({
        eaccount: storage.loginReducer.currentAccount.id,
        provider:"",
        invoiceNbr:"",
        dateFrom:"",
        dateUntil:"",
        dueDateFrom:"",
        dueDateUntil:"",
        amountFrom:"",
        amountUntil:"",
        payCondition:""
    });

    const [info, setInfo] = useState([]);


    //-------------- first actions 😄   --------------

    useEffect(() => {
        // if (!!modalAlertcontrol.service) {
          dispatch(
            getAccountsPayable(filters,
              setInfo
            )
          );
        // }
      }, [trigger]);

    useEffect(() => {
        dispatch(getProviders({id_account: storage.loginReducer.currentAccount.id, slim: 1 }))
        dispatch(getSelectPayConditions())
    }, []);
    

    let optionsClients = storage.providerReducer.providers?.length ? [{ key: "", value: "", label: "Seleccionar..." }] : []
      if (Array.isArray(storage.providerReducer.providers)) {
        storage.providerReducer.providers.map((item) => {
          optionsClients.push({
            value: item.id,
            label: item.name,
            key: "company" + item.id,
          });
        });
    }

    let optionsExpired = storage.InvoiceReceiptReducer.resultsSelectPayConditions?.length ? [{key:"default1", value:"", label:"Seleccionar...", id:""}] : [];
    if (Array.isArray(storage.InvoiceReceiptReducer.resultsSelectPayConditions)) {
        storage.InvoiceReceiptReducer.resultsSelectPayConditions.map((item) => {     
            optionsExpired.push({
                value: item.id,
                label: item.pay_value,
                key:item.id+"expired",
            })                
        });
    }
    
    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
      };


    // --------------- Table 💥  ----------------//

    const header = [
        <div  className={`col-2 text-start px-2`}>No. Factura</div >,
        <div className={`col-1`}>Nit</div>,
        <div className={`col-2 text-start`}>Empresa</div>,
        <div className={`col-1`}>Fecha factura</div>,
        <div className={`col-1`}>Fecha vencimiento</div>,
        <div className={`col-1 text-start`}>Vencimiento</div>,
        <div className={`col-1`}>Días vencidos</div>,
        <div className={`col-1`}>Por vencer</div>,
        <div className={`col-2 text-end px-2`}>Valor</div>
    ];

    const renderList = () => {
        let tempList = [];
        let total=0;
        if (Array.isArray(info)) {
          info.map((item, index) => {
              total=total+ Number(item.totalAmount)
            tempList.push(
              <section2 className={`d-flex`} key={index}>
                <div className="text-start px-2 col-2" >{item.invoiceNbr}</div>
                <div className="text-center col-1" >{item.providerNit}</div>
                <div className="text-start col-2" >{item.providerName}</div>
                <div className="text-center col-1" >{!!item.date? item.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1"): ""}</div>
                <div className="text-center col-1" >{!!item.dueDate? item.dueDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1"): ""}</div>
                <div className="text-start col-1" >{item.paymentCondition}</div>
                <div className="text-center col-1" >{item.dueDays}</div>
                <div className="text-center col-1" >{item.forDueDays}</div>
                <div className="text-end col-2 px-2 " >{!!item.totalAmount? "$"+ numberWithCommas(item.totalAmount): ""}</div>
              </section2>
            );
          });
          tempList.push(
            <section2 className={`d-flex`} key={"849302"}>
                <div className="text-center col-2" >{}</div>
                <div className="text-center col-1" >{}</div>
                <div className="text-center col-2" >{}</div>
                <div className="text-center col-1" >{}</div>
                <div className="text-center col-1" >{}</div>
                <div className="text-center col-1" >{}</div>
                <div className="text-center col-1" >{}</div>
                <div className="text-center col-1" style={{fontWeight: "700", fontSize:"15px", color:"#58595B"}}>{"Total"}</div>
                <div className="text-end col-2 px-2"  style={{fontWeight: "700", fontSize:"15px", color:"#58595B"}}>{"$" + numberWithCommas(total)}</div>
            </section2>
          )

        }
        return tempList;
      };
    
    const handleSearch = (e) => {
        e.preventDefault();
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
    };

    
    // --------------- Functions 💥  ----------------//

    const [showOpt, setShowOpt] = useState({  
        show: false,
        arrow: false,
        });

    const ShowOptions = ()=>{
        if (showOpt?.show && showOpt?.arrow){
          setShowOpt({
            ...showOpt,
            show: false,
            arrow: false,
            });}else{
              setShowOpt({
                ...showOpt,
                show: true,
                arrow: true,
                });
                setFilters({
                    eaccount: storage.loginReducer.currentAccount.id,
                    provider:"",
                    invoiceNbr:"",
                    dateFrom:"",
                    dateUntil:"",
                    dueDateFrom:"",
                    dueDateUntil:"",
                    amountFrom:"",
                    amountUntil:"",
                    payCondition:""
                })
                setTrigger(trigger+1)
            }
      };
    const applyFilters =() =>{
        setTrigger(trigger+1)
    }

    return (
        <>
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
            {(storage.accountingConsultsReducer.accountsP_loading) &&
                <div className="loading">
                    <Loader
                    type="Oval"
                    color="#003f80"
                    height={100}
                    width={100}
                    />
                </div>
            }

                <div className={tableStyles.title}>
                    <Title
                        title="Cuentas por pagar"
                        onClickIcon={() => {
                            history.push('/contabilidad/inicio')
                        }}
                    />
                </div>

                <div className={IndividualStyles.container2}>
                    <div
                        className={`${IndividualStyles.Aling} `}
                    >
                    <div
                        className ={ `${IndividualStyles.Boton} `} onClick={()=>ShowOptions()}>
                        <img
                            src={LupaClear}
                            alt="añadir"
                            className={`mx-3  ${IndividualStyles.pointerNew} ${IndividualStyles.sizeNew2} ${tableStyles.cursorPointer}`}
                        />
                        <span class= {`${IndividualStyles.proArrowWrapper}`}>
                        {!!showOpt.arrow?
                            <span class={`${IndividualStyles.proArrow2}`}> </span>
                            :
                            <span class={`${IndividualStyles.proArrow}`}> </span>
                        } 
                        </span>
                    </div>
                </div>
            </div>
            {!!showOpt.show? 
            
                <div>

                <Row className="d-flex">
                    <Col xs={6} className="align-self-end">
                    <form  onSubmit={(e) => handleSearch(e)}>
                        <p className={tableStyles.crudModalLabel}>Empresa</p>
                        <Select 
                            noOptionsMessage={() => 'No hay datos'}
                            options={optionsClients}
                            onChange={(e) =>
                                {setFilters({
                                ...filters,
                                provider: e.value,
                                })
                                applyFilters()}
                            }
                            placeholder="Seleccionar..."
                            styles={customSelectNewDark} />
                    </form>
                    </Col>
                    <Col xs={2}>
                    <form  onSubmit={(e) => handleSearch(e)}>
                        <p className={tableStyles.crudModalLabel}>No. Factura</p>
                        <input className={IndividualStyles.registerInputsGris} 
                            type="text" 
                            value={filters.invoiceNbr}
                            onChange={(e) =>
                                setFilters({
                                ...filters,
                                invoiceNbr: e.target.value,
                                })
                            }
                            placeholder="Escribir..."
                            onKeyDown={e=>{
                                if (e.key === 'Enter') {
                                    applyFilters()
                                }
                              }}
                        ></input>
                    </form>   
                    </Col>
                    <Col xs={2}>
                    <form  onSubmit={(e) => handleSearch(e)}>
                        <p className={tableStyles.crudModalLabel}>Fecha desde</p>
                        <input className={IndividualStyles.registerInputsBlue} 
                            type="date" 
                            value={filters.dateFrom}
                            onChange={(e) =>
                                setFilters({
                                ...filters,
                                dateFrom: e.target.value,
                                })
                            }
                            onKeyDown={e=>{
                                if (e.key === 'Enter') {
                                    applyFilters()
                                }
                              }}
                        >
                            
                        </input>
                    </form> 
                    </Col>
                    <Col xs={2}>
                        <p className={tableStyles.crudModalLabel}>Hasta</p>
                        <input className={IndividualStyles.registerInputsBlue} 
                            type="date" 
                            value={filters.dateUntil}
                            onChange={(e) =>
                                setFilters({
                                ...filters,
                                dateUntil: e.target.value,
                                })
                            }
                            onKeyDown={e=>{
                                if (e.key === 'Enter') {
                                    applyFilters()
                                }
                              }}
                        >
                            
                        </input>
                    </Col>
                </Row>

                <Row className="d-flex">
                    <Col xs={2}>
                        <p className={tableStyles.crudModalLabel}>Vencimiento desde</p>
                        <input className={IndividualStyles.registerInputsBlue} 
                            type="date" 
                            value={filters.dueDateFrom}
                            onChange={(e) =>
                                setFilters({
                                ...filters,
                                dueDateFrom: e.target.value,
                                })
                            }
                            onKeyDown={e=>{
                                if (e.key === 'Enter') {
                                    applyFilters()
                                }
                              }}
                        >
                            
                        </input>
                    </Col>

                    <Col xs={2}>
                        <p className={tableStyles.crudModalLabel}>Vencimiento hasta</p>
                        <input className={IndividualStyles.registerInputsBlue} 
                            type="date" 
                            value={filters.dueDateUntil}
                            onChange={(e) =>
                                setFilters({
                                ...filters,
                                dueDateUntil: e.target.value,
                                })
                            }
                            onKeyDown={e=>{
                                if (e.key === 'Enter') {
                                    applyFilters()
                                }
                              }}
                        >
                            
                        </input>
                    </Col>

                    <Col xs={2}>
                    <form  onSubmit={(e) => handleSearch(e)}>
                        <p className={tableStyles.crudModalLabel}>Valor desde</p>
                        <NumberFormat
                            allowNegative={false}
                            style={{ padding: 0, color: "#58595B" }}
                            className={IndividualStyles.registerInputs}
                            name="valueNote"
                            thousandsGroupStyle="thousand"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                            value={filters.amountFrom}
                            onValueChange={(values) => {
                                const { value } = values;
                                setFilters({
                                    ...filters,
                                    amountFrom: value,
                                })
                                }}
                            placeholder="Escribir..."
                            onKeyDown={e=>{
                                if (e.key === 'Enter') {
                                    applyFilters()
                                }
                                }}
                        ></NumberFormat>
                     </form> 
                    </Col>

                    <Col xs={2}>
                    <form  onSubmit={(e) => handleSearch(e)}>
                        <p className={tableStyles.crudModalLabel}>Valor hasta</p>
                        <NumberFormat
                            allowNegative={false}
                            style={{ padding: 0, color: "#58595B" }}
                            className={IndividualStyles.registerInputs}
                            name="valueNote"
                            thousandsGroupStyle="thousand"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                            value={filters.amountUntil}
                            onValueChange={(values) => {
                                const { value } = values;
                                setFilters({
                                    ...filters,
                                    amountUntil: value,
                                })
                            }}
                            placeholder="Escribir..."
                            onKeyDown={e=>{
                                if (e.key === 'Enter') {
                                    applyFilters()
                                }
                              }}

                        ></NumberFormat>
                    </form>   
                    </Col>

                    <Col xs={3}>
                    <form  onSubmit={(e) => handleSearch(e)}>
                        <p className={tableStyles.crudModalLabel}>Vencimiento</p>
                        <Select 
                            noOptionsMessage={() => 'No hay datos'}
                            options={optionsExpired}
                            onChange={(e) =>{
                                setFilters({
                                ...filters,
                                payCondition: e.value,
                                })
                                applyFilters()}
                            }
                            placeholder="Seleccionar..."
                            styles={customSelectNewDark} 
                        />
                    </form> 
                    </Col>
                    
                    <div className=" display-grid col-1 p-0" style={{ alignContent: "end" }}>
                    <p className="">ㅤ </p>
                    <div className="d-flex">
                         <img className={`${tableStyles.cursorPointer}`} src={Lupa}  onClick={() => applyFilters()} alt="icon" />
                         <img className={`${tableStyles.cursorPointer} `} style={{ paddingLeft:"5px", paddingRight:"5px"}} src={Imprimir} alt="icon"  onClick={() => applyFilters()} />
                         <img src={Excel} alt="icon"/>
                    </div>

                    </div>
                    {/* <div className=" col display-grid" style={{ alignContent: "end" }}>
                        
                    </div>
                    <div className="col display-grid" style={{ alignContent: "end" }}>
                        <p className=""> </p>
                    </div> */}
                    
                </Row>

                </div>
                :
                <span ></span>
              } 
            

                <GenericTableScroll
                    headers={header}
                    dark={true}
                    body={renderList()}
                    typeHead={"2"}
                >   
                </GenericTableScroll>
            </div>

        </>
    )

};
export default AccountsPayable;