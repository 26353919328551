import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import GenericTableNew from "../../Layouts/GenericTableNew";
import { customSelectNewDark } from "../../Layouts/react-select-custom";
import Pagination from "react-js-pagination";
import miniShearchIcon from "../../../assets/img/icons/miniShearchIcon.svg";
import iconPrint from "../../../assets/img/icons/iconPrint.svg";
import Search from "../../../assets/img/icons/lupa.svg";
import paginationStyles from "../../Layouts/pagination.module.scss";
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";
import ModalNew from "../../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import {
  getMovementInventory,
  getMovementType,
  getReportDetails,
} from "../../../actions/inventoryActions";
import { GET_REPORT_DETAILS } from "../../../actions/actionTypes";
import {
  convertDateToLatinFormat,
  convertMoneyFormat,
  isEmptyOrUndefined,
  message,
} from "../../../helpers/helpers";
import { PdfViewer } from "../../Layouts/PdfViewer";
import { Tooltip } from "@material-ui/core";
import { getPdfTest } from "../../../actions/IndividualAuctionActions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function typeMovement(key) {
  switch (key) {
    case "purchaseEntry":
      return "Entrada por compras";
    case "consignmentEntry":
      return "Entrada por consignación";
    case "donationEntry":
      return "Entrada por donación";
    case "inputSetting":
      return "Ajuste de entrada";
    case "outputSetting":
      return "Ajuste de salida";
    case "warehouseTransfer":
      return "Traslado de almácen";
    case "Donation":
      return "Donación";
    default:
      return "-";
  }
}

export const ReportInventoryMovement = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const arrReportInventoryMovement = store.inventoryReducer.movementsList;
  const [currentLot, setCurrentLot] = useState([]);
  const [trigger, setTrigger] = useState(1);

  const arrReportList = store.inventoryReducer.reportDetail;
  const arrArticles = store.inventoryReducer.reportDetail?.articles;

  const [filters, setFilters] = useState({
    eaccount: store.loginReducer.currentAccount.id,
    num_mov: "",
    mov_type: "",
    page: 1,
    perpage: 10,
    date_until: "",
    date_from: "",
    name_warehouse: "",
  });

  const [modalArtControl, setmodalArtControl] = useState({
    showModalLot: false,
    showType: "",
    showTypeID: 0,
  });

  const handlePageChange = (val) => {
    setFilters({
      ...filters,
      page: val,
    });
  };
  useEffect(() => {
    dispatch(getMovementInventory(filters));
    dispatch(getMovementType());
  }, [trigger, filters, filters.mov_type]);

  useEffect(() => {
    if (modalArtControl.showTypeID) {
      dispatch(getReportDetails(modalArtControl.showTypeID));
    }
  }, [modalArtControl.showType, modalArtControl.showTypeID]);

  let optionsMovements = [{label: "Seleccionar...", value: ""}];
  if (Array.isArray(store.inventoryReducer.movementsTypes)) {
    store.inventoryReducer.movementsTypes.map((item) => {
      optionsMovements.push({
        value: item.name,
        label: item.value,
        key: item.id + "mov",
      });
    });
  }

  const listElem = () => {
    let elemMap;
    if (arrReportInventoryMovement !== undefined) {
      const elem2 = arrReportInventoryMovement;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const modalListElem = (arrayToMap) => {
    if (Array.isArray(arrayToMap)) {
      return arrayToMap.map((elem, index) => {
        return modalListElement(elem, index);
      });
    } else {
      return [];
    }
  };

  const modalListOther = () => {
    if (Array.isArray(currentLot)) {
      return currentLot.map((elem) => {
        return modalListOtherElement(elem);
      });
    } else {
      return [];
    }
  };

  const renderElement = (elem, index) => {
    return (
      <tr key={index}>
        <td className="col-md-2 text-center text-uppercase">{elem.num_mov}</td>
        <td className="col-md-4 text-center">
          {elem.created_at?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")}
        </td>
        <td className="col-md-2 text-start">
          {typeMovement(elem.movement_type)}
        </td>
        <td className="col-md-2 text-start">{elem.warehouse}</td>
        <td className="col-md-2 text-center cursorPointer">
          <div
            className={`d-flex`}
            onClick={() => {
              getPdf(elem.id);
              setmodalArtControl({
                ...modalArtControl,
                showType: elem.movement_type,
                showTypeID: elem.id,
              });
            }}
            style={{ justifyContent: "flex-end", marginRight: "5px" }}
          >
            <img src={miniShearchIcon} alt="Search icon" />
          </div>
        </td>
      </tr>
    );
  };
  const modalListElement = (elem, index) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-1 text-center">{index + 1}</td>
        <td className="col-md-1 text-start">{elem.article_id}</td>
        <td className="col-md-3 text-start">{elem.description}</td>
        <td className="col-md-1 text-center cursorPointer">
          <u onClick={() => handleClickSecondModal(elem)}>
            <b className={tableStyles.darkBlueText}>{elem.lots.length}</b>
          </u>
        </td>
        <td className="col-md-2 text-center">{elem.qty_received}</td>
        <td className="col-md-2 text-center cursorPointer">
          {convertMoneyFormat(elem.unit_price)}
        </td>
        <td className="col-md-2 text-center">
          {elem.discount}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {convertMoneyFormat(elem.discount_value)}
        </td>
        <td
          className={`${
            elem.total === 0 ? "text-center" : "text-end"
          } col-md-2 text-center`}
        >
          {elem.total === 0 ? "-" : convertMoneyFormat(elem.total)}
        </td>
      </tr>
    );
  };

  const handleClickSecondModal = (elem) => {
    setCurrentLot(elem.lots);
    setmodalArtControl({
      ...modalArtControl,
      showModalLot: true,
    });
  };

  const modalListOtherElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">
          {convertDateToLatinFormat(elem.validity)}
        </td>
        <td className="col-md-4 text-center">{elem.lote}</td>
        <td className="col-md-2 text-center">{elem.cant}</td>
      </tr>
    );
  };
  const token = store.loginReducer.Authorization;
  const [showPdf, setShowPdf] = useState(false);
  const [base64, setBase64] = useState("");

  const getPdf = async (id) => {
    const result = await getPdfTest(
      {
        id: id,
        doc: 15,
      },
      token
    );
    if (result?.success) {
      return setBase64(result?.pdf[0]?.key?.split("'")[1]);
    } else {
      message(
        "warning",
        "Intente de nuevo",
        "No ha sido posible cargar el documento", undefined, true
      );
      return setShowPdf(false);
    }
  };

  const localStyles = {
    endPagination: {
      display: "flex",
      justifyContent: "flex-end",
    },
    blueInputText: {
      color: "#005dbf",
    },
    footerStyles: {
      display: "flex",
      justifyContent: "flex-end",
      width: "330%",
    },
    noBorder: { border: "none", padding: "8px" },
  };

  const renderHeadersModal = [
    <th
      className="col-md-1 text-center pb-3"
      style={{ borderTopLeftRadius: "10px" }}
      key={0}
    >
      Item
    </th>,
    <th className="col-md-1 text-start pb-3" key={1}>
      Código
    </th>,
    <th className="col-md-3 text-start pb-3" key={2}>
      Descripción de Artículo
    </th>,
    <th className="col-md-1 text-center pb-3" key={3}>
      Lote
    </th>,
    <th className="col-md-1 text-center pb-3" key={4}>
      Cant.
    </th>,
    <th className="col-md-2 text-center pb-3" key={5}>
      Valor unidad
    </th>,
    <th className="col-md-2 text-center " key={6}>
      Descuento <br></br> % &nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $
    </th>,
    <th
      className="col-md-2 text-center pb-3 px-2"
      style={{ borderTopRightRadius: "10px" }}
      key={7}
    >
      Total
    </th>,
  ];

  const renderHeadersModalDonation = [
    <th
      className="col-md-1 text-center pb-3"
      style={{ borderTopLeftRadius: "10px" }}
      key={0}
    >
      Item
    </th>,
    <th className="col-md-1 text-start pb-3" key={1}>
      Código
    </th>,
    <th className="col-md-3 text-center pb-3" key={2}>
      Descripción de Artículo
    </th>,
    <th className="col-md-1 text-center pb-3" key={3}>
      Lote
    </th>,
    <th
      className="col-md-2 text-center pb-3"
      style={{ borderTopRightRadius: "10px" }}
      key={4}
    >
      Cant.
    </th>,
  ];

  const headerSwitch = (type) => {
    switch (type) {
      case "purchaseEntry":
      case "consignmentEntry":
        return renderHeadersModal;
      case "Donation":
      case "donationEntry":
        return renderHeadersModalDonation;
      // case "inputSetting":
      //   return "Ajuste de entrada";
      // case "outputSetting":
      //   return "Ajuste de salida";
      // case "warehouseTransfer":
      //   return "Traslado de almácen";
      default:
        return renderHeadersModal;
    }
  };

  const renderHeaders = [
    <th
      className="col-md-3 text-center"
      style={{ borderTopLeftRadius: "10px" }}
      key={0}
    >
      No. de movimiento
    </th>,
    <th className="col-md-2 text-center" key={1}>
      Fecha
    </th>,
    <th className="col-md-3 text-start" key={2}>
      Tipo de movimiento
    </th>,
    <th className="col-md-3 text-start" key={3}>
      Almacenes
    </th>,
    <th
      className="col-md-3 text-center"
      style={{ borderTopRightRadius: "10px" }}
      key={4}
    >
      &nbsp;
    </th>,
  ];
  const renderHeadersLot = [
    <th style={{ borderTopLeftRadius: "10px" }} key={0}>
      Vencimiento
    </th>,
    <th key={1}>Lote</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={2}>
      Cantidad
    </th>,
  ];

  return (
    <>
      <ModalNew
        size="940"
        title="Detalle movimiento de inventario"
        // show={modalArtControl.showType==="consignmentEntry" || modalArtControl.showType==="purchaseEntry"}
        show={modalArtControl.showType != ""}
        onHide={() => {
          setmodalArtControl({
            ...modalArtControl,
            showType: "",
            showTypeID: 0,
          });
          dispatch({
            type: GET_REPORT_DETAILS,
            payload: {},
          });
          setBase64("");
        }}
      >
        <Row className="d-flex">
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>No. Entrada</p>
            <input
              disabled
              className="register-inputs text-secondary"
              value={arrReportList?.we_number}
            />
          </Col>

          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Entrada por almacén</p>
            <input
              disabled
              className="register-inputs text-secondary"
              name="id_consumption_center"
              value={arrReportList?.wm_number}
            />
          </Col>

          {(modalArtControl.showType === "consignmentEntry" ||
            modalArtControl.showType === "purchaseEntry") && (
            <Col xs={3}>
              <p className={tableStyles.crudModalLabel}>Orden de compra</p>
              <input
                disabled
                className="register-inputs text-secondary"
                name="id_consumption_center"
                value={arrReportList?.purchaseOrder_number}
              />
            </Col>
          )}

          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Fecha</p>
            <input
              className="register-inputs text-secondary"
              disabled
              name="requisition_date"
              type="date"
              value={arrReportList?.created_at}
            />
          </Col>

          {(modalArtControl.showType === "consignmentEntry" ||
            modalArtControl.showType === "purchaseEntry") && (
            <Col xs={3}>
              <p className={tableStyles.crudModalLabel}>Proveedor</p>
              <input
                className="register-inputs text-secondary"
                disabled
                name="id_consumption_center"
                value={arrReportList?.name}
              />
            </Col>
          )}

          {(modalArtControl.showType === "consignmentEntry" ||
            modalArtControl.showType === "purchaseEntry") && (
            <Col xs={3}>
              <p className={tableStyles.crudModalLabel}>NIT</p>
              <input
                className="register-inputs text-secondary"
                disabled
                name="id_consumption_center"
                value={arrReportList?.nit}
              />
            </Col>
          )}

          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Almacén</p>
            <input
              className="register-inputs text-secondary"
              disabled
              name="id_consumption_center"
              value={arrReportList?.description}
            />
          </Col>

          {(modalArtControl.showType === "consignmentEntry" ||
            modalArtControl.showType === "purchaseEntry") && (
            <Col xs={3}>
              <p className={tableStyles.crudModalLabel}>No. Factura</p>
              <input
                className="register-inputs text-secondary"
                disabled
                name="id_consumption_center"
                value={arrReportList?.we_billNumber}
              />
            </Col>
          )}
        </Row>
        <Row className="d-flex">
          {/* /* -------------------------------- PDF Viwer ------------------------------- */}

          <ModalNew
            title="Detalle de movimiento de inventario"
            show={showPdf}
            btnNoName={"Cancelar"}
            size="700"
            btnYesDisabled={false}
            onHide={() => setShowPdf(false)}
            btnNoEvent={() => setShowPdf(false)}
            btnNoDisabled={false}
          >
            <PdfViewer
              downloadable
              file={`data:application/pdf;base64,${base64}`}
            ></PdfViewer>
          </ModalNew>
          <Col
            xs={12}
            className="d-flex justify-content-end mt-2 cursorPointer"
          >
            <Tooltip title="Imprimir">
              <img
                // className={`${detailStyles.titleIcons}`}
                src={iconPrint}
                alt="imprimir"
                onClick={() => setShowPdf(true)}
              />
            </Tooltip>
          </Col>
        </Row>

        <GenericTableNew headers={renderHeadersModal}>
          {modalListElem(arrArticles)}
        </GenericTableNew>

        <div style={localStyles.endPagination}>
          <p className={tableStyles.crudModalLabel}>
            <b>
              Total: &nbsp;&nbsp;
              {arrReportList?.sumTotal
                ? convertMoneyFormat(arrReportList?.sumTotal)
                : ""}
            </b>
          </p>
        </div>
      </ModalNew>

      {/* /* ------------------------------ LOT modal ------------------------------ */}
      <ModalNew
        size="200"
        title="Lotes"
        show={modalArtControl.showModalLot}
        hideCancelButton={true}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            showModalLot: false,
          })
        }
      >
        <GenericTableNew dark={true} headers={renderHeadersLot}>
          {modalListOther()}
        </GenericTableNew>
      </ModalNew>

      <div className={tableStyles.container}>
        {(store.inventoryReducer.movements_loading) &&
            <div className="loading">
                <Loader
                type="Oval"
                color="#003f80"
                height={100}
                width={100}
                />
            </div>
          }
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>
            Informe de movimientos de inventario
          </h1>

          <Row className="d-flex">
            <Col xs={2} className="align-self-end">
              <p className={tableStyles.crudModalLabel}>No. de mov</p>
              <div className="d-flex">
                <input
                  className={`register-inputs`}
                  name="requisition_date"
                  type="text"
                  placeholder="Escribir..."
                  value={filters.num_mov}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      num_mov: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
            <Col xs={2} className="align-self-end">
              <p className={tableStyles.crudModalLabel}>Tipo de movimiento</p>
              <div className="d-flex w-100">
                <Select noOptionsMessage={() => 'No hay datos'}
                  Name="optionsMovements"
                  className={"w-100"}
                  placeholder="Seleccionar..."
                  options={optionsMovements}
                  styles={customSelectNewDark}
                  onChange={(e) =>{
                    setFilters({
                      ...filters,
                      mov_type: e.value,
                    })
                    setTrigger(trigger + 1)
                  }}
                />
              </div>
            </Col>
            <Col xs={2} className="align-self-end">
              <p className={tableStyles.crudModalLabel}>Desde</p>
              <div className="d-flex">
                <input
                  className={`register-inputs`}
                  name="requisition_date"
                  type="date"
                  value={filters.date_from}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      date_from: e.target.value,
                    })
                  }
                  onKeyDown={e=>{
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1)
                  }
                  }}
                />
                {/* <div style={{ color: "gray" }}>━</div> */}
              </div>
            </Col>
            <Col xs={2} className="align-self-end">
              <p className={tableStyles.crudModalLabel}>Hasta</p>
              <input
                className="register-inputs"
                name="requisition_date"
                type="date"
                value={filters.date_until}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    date_until: e.target.value,
                  })
                }
                onKeyDown={e=>{
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1)
                }  
                }}
              />
            </Col>
            <Col xs={3} className="align-self-end">
              <div className="mt-4 d-flex">
                <input
                  className={` ${tableStyles.SearchNew} align-self-end`}
                  type="text"
                  placeholder="Buscar"
                  value={filters.name_warehouse}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      name_warehouse: e.target.value,
                    })
                  }
                  onKeyDown={e=>{
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1)
                  }  
                  }}
                />
                <button>
                  <img
                    src={Search}
                    alt="User icon"
                    height={24}
                    className={`${tableStyles.iconSvgMargin2}`}
                    onClick={() => setTrigger(trigger + 1)}
                  />
                </button>
              </div>
            </Col>
          </Row>

          <GenericTableNew dark={true} headers={renderHeaders}>
            {listElem()}
          </GenericTableNew>

          <div
            className={`${paginationStyles.wrapper}`}
            style={localStyles.endPagination}
          >
            <p className={paginationStyles.paginationText}>
              Pag. {store.inventoryReducer.movementsList ? filters.page : ""}
              {" de "}
              {Math.ceil(
                store.inventoryReducer.movementsList_total / filters.perpage
              )
                ? Math.ceil(
                    store.inventoryReducer.movementsList_total / filters.perpage
                  )
                : ""}{" "}
              ({store.inventoryReducer.movementsList_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={store.inventoryReducer.movementsList_total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
