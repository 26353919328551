
const minDateAndMaxDate = (dates) => {

    const converDates = dates.map(e => e.getTime())

    const minDate = Math.min(...converDates)
    const maxDate = Math.max(...converDates)

    return { minDate: new Date(minDate), maxDate: new Date(maxDate) }
}

export default minDateAndMaxDate