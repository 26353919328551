// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

// --------------- icons 💥  ----------------//
import Excel from "../../assets/img/icons/excel.svg";
import Imprimir from "../../assets/img/icons/imprimir.svg";

//-------------- scss styles 😄   --------------
import Select from "react-select";
import { customSelectNew, customSelectNewDark } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { functional_get } from "../../actions/functionalActions";
import { getSites } from "../../actions/sitesActions";
import { costcenter_get } from "../../actions/costcenterActions";
import NumberFormat from "react-number-format";
import { generateIncomeStatementReport } from "../../actions/reportsActions";
import { responseSwal } from "../../helpers/responseSwal";
import { getPdfrestultStatus } from "../../actions/consultAction";
import ModalNew from "../../components/Layouts/ModalNew";
import { PdfViewer } from "../../components/Layouts/PdfViewer";
import Loader from "react-loader-spinner";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import { Title } from "../../shared";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// ------------------ Functions ----------------------

function IncomeStatement(props) {
    const history = useHistory()
    const dispatch = useDispatch();
    const storage = useSelector(state => state);
    const [trigger, setTrigger] = useState(1);
    const [arrayBalance, setArrayBalance] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPdf, setShowPdf] = useState(false)
    const [base64, setBase64] = useState("")

    const getPdf = async () => {
        const result = await getPdfrestultStatus(info, storage.loginReducer.Authorization);

        if (result?.success) {
            return setBase64(result?.base64?.split("'")[1]);
        } else {
            customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: `Ha ocurrido un error, no ha sido posible cargar el documento`,
                confirmButtonText: 'Aceptar',
                showCancelButton: false
            });
            return setShowPdf(false)
        }
    };

    useEffect(() => {
        if (showPdf) { getPdf() }

    }, [showPdf])
    const [info, setInfo] = useState({
        search: "",
        excludeVoucher: false,
        printDate: false,
        printPages: false,
        groupAccounts: false,
        level: "",
        vatPercentage: "",
        reportType: "resultsStatus",
        search: "",
        eaccount: storage.loginReducer.currentAccount.id,
    });
    const [showSection, setShowSection] = useState(false);
    const myPermission = () => storage.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "incomeStat");
    const numberWithCommas = (x) => {
        if (!!x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            return parts.join(".");
        } else {
            return ("0")
        }
    };
    //--------------first functions 🍕   -----------
    useEffect(() => {
        dispatch(functional_get({ eaccount: info.eaccount, active: 1 }));
        dispatch(getSites({ id_account: info.eaccount, active: 1 }));
        dispatch(costcenter_get({ entity_account: info.eaccount, active: 1 }));

    }, [trigger]);

    //-------------- select options 🍕   --------------

    // let optionsAccount = [{ key: "", value: "", label: "Seleccione cuenta" }];
    //   if (Array.isArray(storage.pucReducer.niifAccounts)) {
    //     storage.pucReducer.niifAccounts.map((item, index) => {
    //         optionsAccount.push({
    //         value: item.id,
    //         label: item.complete_account +" - " + item.description ,
    //         key: index +"account",
    //       });
    //     });
    // }

    //--------------  Actions  🍕   --------------
    const btnReport = async () => {
        const res = await generateIncomeStatementReport(info, storage.loginReducer.Authorization);
        // if (res.success) {
        setShowSection(true)
        setArrayBalance(res)
        setLoading(false)
        // }else{
        // responseSwal({icon:"error", title:"Error", text:res.message})
        // }
    }

    const optionsCostCenter = () => {
        let options = [{ label: "Seleccionar...", value: "" }];
        storage.costcenterReducer.cc?.forEach((item) => {
            options.push({ label: item.description, value: item.id });
        });
        return options;
    };
    const optionsLevel = () => {
        let options = [
            { label: "Seleccionar...", value: "" },
            { label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
        ];
        return options;
    };

    const optionsSite = () => {
        let options = [{ label: "Seleccionar...", value: "" }];
        storage.siteReducer.sites?.forEach((item) => {
            options.push({ label: item.description, value: item.id });
        });
        return options;
    };
    const optionsFunctionalUnit = () => {
        let options = [{ label: "Seleccionar...", value: "" }];
        storage.functionalReducer.units?.forEach((item) => {
            options.push({ label: item.description, value: item.id });
        });
        return options;
    };



    return (
        <>
            {loading && (
                <div className="loading">
                    <Loader type="Oval" color="#003f80" height={100} width={100} />
                </div>
            )}
            <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: "3rem" }}>
                <div className={tableStyles.title}>
                    <Title
                        title="Informe de estado de resultados"
                        onClickIcon={() => {
                            history.push('/contabilidad/inicio')
                        }}
                    />
                </div>
                <div className="d-flex">
                    {/*----------------------------FIRST SECTION---------------------------------- */}
                    <div
                        className={`${tableStyles.container}`}
                        style={{
                            width: "35%",
                            paddingLeft: "0px",
                            borderRight: "1px solid #CECECE",
                            marginRight: "1px",
                        }}
                    >
                        <Row className="d-flex">
                            <Col xs={6}>
                                <p className={tableStyles.crudModalLabel}>Fecha desde<span className={"text-warning"}>*</span></p>
                                <input
                                    className={IndividualStyles.registerInputsBlue}
                                    type="date"
                                    value={info.dateFrom}
                                    onChange={(e) => setInfo({
                                        ...info,
                                        dateFrom: e.target.value
                                    })}
                                />
                            </Col>
                            <Col xs={6}>
                                <p className={tableStyles.crudModalLabel}>Hasta<span className={"text-warning"}>*</span></p>
                                <input
                                    className={IndividualStyles.registerInputsBlue}
                                    type="date"
                                    value={info.dateUntil}
                                    onChange={(e) => setInfo({
                                        ...info,
                                        dateUntil: e.target.value
                                    })}
                                />
                            </Col>
                        </Row>

                        <Row
                            className="d-flex"
                            style={{
                                background: "rgba(0, 93, 191, 0.05)",
                                borderRadius: "10px",
                                height: "200px",
                                marginRight: "1px",
                                marginLeft: "1px",
                                marginTop: "16px",
                                paddingRight: "8px",
                                paddingLeft: "8px",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                            }}
                        >
                            <Col xs={6}>
                                <p className={tableStyles.crudModalLabel}>
                                    Niveles<span className={"text-warning"}>*</span>
                                </p>
                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    options={optionsLevel()}
                                    placeholder="seleccionar..."
                                    styles={customSelectNewDark}
                                    onChange={(e) =>
                                        setInfo({
                                            ...info,
                                            level: e.value,
                                        })
                                    }
                                />
                            </Col>
                            <Col xs={6}>
                                <p className={tableStyles.crudModalLabel}>
                                    Unidad funcional<span className={"text-warning"}>*</span>
                                </p>
                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    options={optionsFunctionalUnit()}
                                    placeholder="seleccionar..."
                                    styles={customSelectNewDark}
                                    onChange={(e) =>
                                        setInfo({
                                            ...info,
                                            functionalUnit: e.value,
                                        })
                                    }
                                />
                            </Col>
                            <Col xs={12}>
                                <p className={tableStyles.crudModalLabel}>
                                    Centro de costo<span className={"text-warning"}>*</span>
                                </p>
                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    options={optionsCostCenter()}
                                    placeholder="seleccionar..."
                                    styles={customSelectNewDark}
                                    onChange={(e) =>
                                        setInfo({
                                            ...info,
                                            costcenter: e.value,
                                        })
                                    }
                                />
                            </Col>
                            {/* <Col xs={1} style={{ paddingLeft: "12px" }}>
                        <input
                            className="border border-dark-blue form-check-input p1 check-dark-blue"
                            type="checkbox"
                            name="edit"
                            value="selectedAll"
                            // checked={isBoxAllSelected()}
                            // onChange={e => changeBoxAllSelected(!isBoxAllSelected())}
                        />
                    </Col>
                    <Col xs={11} style={{ paddingLeft: "1px" }}>
                        <p
                            className={tableStyles.crudModalLabel}
                            style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                        >
                            Todas las centros de costo
                        </p>
                    </Col> */}

                            <Col xs={6}>
                                <p className={tableStyles.crudModalLabel}>
                                    Porcentaje de provisión de impuesto de Renta
                                </p>
                                <NumberFormat
                                    allowNegative={false}
                                    isAllowed={({ floatValue }) => floatValue <= 100}
                                    className={`${IndividualStyles.registerInputs} text-secondary `}
                                    suffix="%"
                                    onValueChange={(values) => {
                                        setInfo({ ...info, vatPercentage: values.value })
                                    }}
                                    placeholder="Escribir..."


                                >
                                </NumberFormat>
                            </Col>
                            <Col xs={6}>
                                <p className={tableStyles.crudModalLabel}>
                                    Sede<span className={"text-warning"}>*</span>
                                </p>
                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    options={optionsSite()}
                                    placeholder="seleccionar..."
                                    styles={customSelectNewDark}
                                    onChange={(e) =>
                                        setInfo({
                                            ...info,
                                            site: e.value,
                                        })
                                    }
                                />
                            </Col>
                        </Row>

                        <Row
                            className="d-flex"
                            style={{ paddingTop: "24px", paddingBottom: "16px" }}
                        >
                            <p
                                className={tableStyles.crudModalLabel}
                                style={{ fontWeight: "700", fontSize: "16px" }}
                            >
                                Opciones
                            </p>
                        </Row>

                        <Row className="d-flex">
                            <Col xs={1} style={{ paddingLeft: "18px" }}>
                                <input
                                    className="border border-dark-blue form-check-input p1 check-dark-blue"
                                    type="checkbox"
                                    onChange={e => setInfo({ ...info, printDate: e.target.checked })}
                                />
                            </Col>
                            <Col xs={11} style={{ paddingLeft: "1px" }}>
                                <p
                                    className={tableStyles.crudModalLabel}
                                    style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                                >
                                    No imprimir fecha en informe
                                </p>
                            </Col>
                        </Row>

                        <Row className="d-flex">
                            <Col xs={1} style={{ paddingLeft: "18px" }}>
                                <input
                                    className="border border-dark-blue form-check-input p1 check-dark-blue"
                                    type="checkbox"
                                    checked={info.excludeVoucher}
                                    onChange={e => setInfo({ ...info, excludeVoucher: e.target.checked })}
                                />
                            </Col>
                            <Col xs={11} style={{ paddingLeft: "1px" }}>
                                <p
                                    className={tableStyles.crudModalLabel}
                                    style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                                >
                                    Excluir los comprobantes de cierre
                                </p>
                            </Col>
                        </Row>

                        {/* <Row className="d-flex">
                            <Col xs={1} style={{ paddingLeft: "18px" }}>
                                <input
                                    className="border border-dark-blue form-check-input p1 check-dark-blue"
                                    type="checkbox"
                                    checked={info.printPages}
                                    onChange={e => setInfo({ ...info, printPages: e.target.checked })}
                                />
                            </Col>
                            <Col xs={11} style={{ paddingLeft: "1px" }}>
                                <p
                                    className={tableStyles.crudModalLabel}
                                    style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                                >
                                    Imprimir en páginas numeradas
                                </p>
                            </Col>
                        </Row>
                        <Row className="d-flex">
                            <Col xs={1} style={{ paddingLeft: "18px" }}>
                                <input
                                    className="border border-dark-blue form-check-input p1 check-dark-blue"
                                    type="checkbox"
                                    checked={info.groupAccounts}
                                    onChange={e => setInfo({ ...info, groupAccounts: e.target.checked })}
                                />
                            </Col>
                            <Col xs={11} style={{ paddingLeft: "1px" }}>
                                <p
                                    className={tableStyles.crudModalLabel}
                                    style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                                >
                                    Agrupar cuentas
                                </p>
                            </Col>
                        </Row> */}

                        {/* <Row className="d-flex">
                    <Col xs={1} style={{ paddingLeft: "18px" }}>
                    <input
                        className="border border-dark-blue form-check-input p1 check-dark-blue"
                        type="checkbox"
                        name="edit"
                        value="selectedAll"
                        // checked={isBoxAllSelected()}
                        // onChange={e => changeBoxAllSelected(!isBoxAllSelected())}
                    />
                    </Col>
                    <Col xs={11} style={{ paddingLeft: "1px" }}>
                    <p
                        className={tableStyles.crudModalLabel}
                        style={{ color: "#58595B", marginTop: "4px", fontSize: "13px" }}
                    >
                        Comparar con otro periodo
                    </p>
                    </Col>
                </Row> */}

                        <Row
                            className="d-flex"
                            style={{ marginTop: "40px", marginButton: "5px" }}
                        >
                            <Col xs={3} style={{ marginRight: "5%" }}>
                                <Button
                                    className={`${IndividualStyles.btnPrimary}`}
                                    onClick={() => setShowSection(false)}
                                    disabled={false}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col xs={7}>
                                <Button
                                    className={IndividualStyles.btnPrimaryDark}
                                    onClick={() => (btnReport(), setLoading(true))}
                                    disabled={!info.dateFrom || !info.dateUntil || !info.level ? true : false}
                                >
                                    Generar informe
                                </Button>
                            </Col>
                            {/* <Col xs={4}>

                                </Col> */}
                        </Row>
                    </div>

                    {/*----------------------------END FIRST SECTION---------------------------------- */}

                    {/*----------------------------SECOND SECTION---------------------------------- */}
                    {showSection ?
                        <div style={{ width: "65%", display: "flex", flexDirection: "column", paddingLeft: "40px" }}>

                            <div className=" display-grid col-12" style={{ textAlignLast: "end" }}>
                                <div className="col" style={{ textAlignLast: "end" }}>
                                    <img
                                        className={`${tableStyles.cursorPointer} `}
                                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                                        src={Imprimir}
                                        onClick={() => setShowPdf(true)}
                                    />
                                    {/* <img className={`${tableStyles.cursorPointer} `} src={Excel} /> */}
                                </div>
                            </div>

                            <div style={{ marginTop: "9px", backgroundColor: "#FFFF", padding: "21px", height: "100%", width: "100%", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)" }}>
                                <div className="d-flex justify-content-center">
                                    <h5 className="text-primary fw-bold text-center">
                                        {storage.loginReducer.currentAccount.bussines_name}
                                        <br />
                                        <span style={{ fontSize: "1rem" }}>
                                            {numberWithCommas(
                                                storage?.loginReducer?.currentAccount?.nit
                                            )}
                                        </span>
                                    </h5>
                                </div>
                                <Row>
                                    <Col xs={12} className="text-center">
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                                        >
                                            ESTADO DE RESULTADOS
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="text-center">
                                        <label
                                            style={{ fontWeight: "700", fontSize: "12px", color: "#005DBF" }}
                                        >
                                            ESTADO DE RESULTADOS
                                        </label>
                                    </Col>
                                </Row>

                                {/*-------MAPEO 1---- */}
                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Ingresos operaciones
                                        </label>
                                    </Col>
                                </div>

                                {arrayBalance?.results?.ordinaryInc?.records.map((x, realindex) => (

                                    <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                        <div className="col-7">
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                {x.description}
                                            </label>
                                        </div>
                                        <div className="col-4 text-end" >
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                ㅤ$ {x?.balance == 0 ? 0 : numberWithCommas(x?.balance)}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                                <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                    <div className="col-7">
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Total
                                        </label>
                                    </div>
                                    <div className="col-4 text-end" >
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            ㅤ$ {arrayBalance?.results?.ordinaryInc?.total == 0 ? 0 : numberWithCommas(arrayBalance?.results?.ordinaryInc?.total)}
                                        </label>
                                    </div>
                                </div>
                                {/*-------MAPEO 2---- */}

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Costos de venta y prestacion de servicio
                                        </label>
                                    </Col>
                                </div>

                                {arrayBalance?.results?.cost?.records.map((x, realindex) => (

                                    <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                        <div className="col-7">
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                {x.description}
                                            </label>
                                        </div>
                                        <div className="col-4 text-end" >
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                ㅤ$ {x?.balance == 0 ? 0 : numberWithCommas(x?.balance)}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                                <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                    <div className="col-7">
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Total
                                        </label>
                                    </div>
                                    <div className="col-4 text-end" >
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            ㅤ$ {arrayBalance?.results?.cost?.total == 0 ? 0 : numberWithCommas(arrayBalance?.results?.cost?.total)}
                                        </label>
                                    </div>
                                </div>

                                {/*-------MAPEO 3---- */}

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Ingreso netos
                                        </label>
                                    </Col>
                                    <Col xs={4} style={{ textAlign: "end" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            $ {arrayBalance?.results?.total?.incNeto == 0 ? 0 : numberWithCommas(arrayBalance?.results?.total?.incNeto)}
                                        </label>
                                    </Col>
                                </div>

                                {/*-------MAPEO 4---- */}

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Gastos operacionales de administracion
                                        </label>
                                    </Col>
                                </div>

                                {arrayBalance?.results?.AdmExpenses?.records?.map((x, realindex) => (

                                    <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                        <div className="col-7">
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                {x.description}
                                            </label>
                                        </div>
                                        <div className="col-4 text-end" >
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                $ {x?.balance == 0 ? 0 : numberWithCommas(x?.balance)}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                                <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                    <div className="col-7">
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Total
                                        </label>
                                    </div>
                                    <div className="col-4 text-end" >
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            $ {arrayBalance?.results?.AdmExpenses?.total == 0 ? 0 : numberWithCommas(arrayBalance?.results?.AdmExpenses?.total)}
                                        </label>
                                    </div>
                                </div>

                                {/*-------MAPEO 5---- */}

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Gasto de venta
                                        </label>
                                    </Col>
                                </div>

                                {arrayBalance?.results?.SaleExpenses?.records?.map((x, realindex) => (

                                    <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                        <div className="col-7">
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                {x.description}
                                            </label>
                                        </div>
                                        <div className="col-4 text-end" >
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                $ {x?.balance == 0 ? "0" : numberWithCommas(x?.balance)}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                                <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                    <div className="col-7">
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Total
                                        </label>
                                    </div>
                                    <div className="col-4 text-end" >
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            $ {arrayBalance?.results?.SaleExpenses?.total == "0" ? "0" : numberWithCommas(arrayBalance?.results?.SaleExpenses?.total)}
                                        </label>
                                    </div>
                                </div>
                                {/*-------MAPEO 6---- */}

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Utilidad/Perdida operacional
                                        </label>
                                    </Col>
                                    <Col xs={4} style={{ textAlign: "end" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            $ {numberWithCommas(arrayBalance?.results?.total?.opsRevenue)}
                                        </label>
                                    </Col>
                                </div>
                                {/*-------MAPEO 7---- */}

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Gasto no operacionales
                                        </label>
                                    </Col>
                                </div>

                                {arrayBalance?.results?.SaleExpenses?.records?.map((x, realindex) => (

                                    <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                        <div className="col-7">
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                {x.description}
                                            </label>
                                        </div>
                                        <div className="col-4 text-end" >
                                            <label
                                                style={{ fontWeight: "400", fontSize: "12px", color: "#58595B" }}
                                            >
                                                ㅤ$ {numberWithCommas(x?.balance)}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                                <div className={tableStyles.rwoReport} key={"table"} style={{ width: "60%" }}>
                                    <div className="col-7">
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Total
                                        </label>
                                    </div>
                                    <div className="col-4 text-end" >
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            ㅤ$ {numberWithCommas(arrayBalance?.results?.SaleExpenses?.total)}
                                        </label>
                                    </div>
                                </div>
                                {/*-------MAPEO 8---- */}

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Utilidad o perdida antes de impuestos
                                        </label>
                                    </Col>
                                    <Col xs={4} style={{ textAlign: "end" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            $ {numberWithCommas(arrayBalance?.results?.total?.revenueBeforeVAT)}
                                        </label>
                                    </Col>
                                </div>
                                {/*-------MAPEO 9---- */}

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Impuesto de renta
                                        </label>
                                    </Col>
                                    <Col xs={4} style={{ textAlign: "end" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            $ {numberWithCommas(arrayBalance?.results?.total?.rentaTax)}
                                        </label>
                                    </Col>
                                </div>
                                {/*-------MAPEO 10---- */}

                                <div className={tableStyles.rwoReport} style={{ marginTop: "20px", width: "60%" }}>
                                    <Col xs={7}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            Utilidad o perdida del ejercicio
                                        </label>
                                    </Col>
                                    <Col xs={4} style={{ textAlign: "end" }}>
                                        <label
                                            style={{ fontWeight: "700", fontSize: "14px", color: "#58595B" }}
                                        >
                                            $ {numberWithCommas(arrayBalance?.results?.total?.totalRevenue)}
                                        </label>
                                    </Col>
                                </div>

                            </div>
                            {/*----------------------------END SECOND SECTION---------------------------------- */}

                        </div>
                        :
                        <span></span>
                    }
                </div>
            </div>
            <ModalNew
                title="Preview"
                show={showPdf}
                btnNoName={"Cancelar"}
                size="700"
                btnYesDisabled={false}
                onHide={() => setShowPdf(false)}
                btnNoEvent={() => setShowPdf(false)}
                btnNoDisabled={false}
            >
                <PdfViewer
                    downloadable
                    file={`data:application/pdf;base64,${base64}`}></PdfViewer>
            </ModalNew>
        </>
    )

};
export default IncomeStatement;