// ----------------- COMPONENTS- ---------------------------//
import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Link, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// --------------------------------------------------------//
// ----------------- CONSTANTS 🚡 - ---------------------------//

// -------------------------------------------------------//

// ⛳ functions ---------------------------------------//
import { useHistory } from "react-router-dom";
import backIcon from "../../assets/img/icons/backIcon.svg";
import { createCollectionLetter } from "../../actions/collectionActions";
import { Title } from "../../shared/Title";

// ---------------------- ##### -----------------------------------//

function CollectionCreate(props) {
  const dispatch = useDispatch();
  const reducers = useSelector((state) => state);
  const history = useHistory();

  const initialState = {
    registerResults: {
      eaccount: reducers.loginReducer.currentAccount.id,
      id: "",
      name: "",
      signature: "",
      template: "",
      role: "",
      status: "",
    },
  };

  const [state, setState] = useState(initialState);
  useEffect(() => {}, []);

  const myPermission = () =>
    reducers.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "collectLet"
    );

  //   handle submit to update results
  const handleSubmit = (data) => {
    dispatch(
      createCollectionLetter(data, () => {
        setState(initialState);
        history.push("/administracion/TMDeCartera/CartasCobro");
      })
    );
  };

  return (
    <div className="container" style={{paddingTop:'30px'}}>
      {!myPermission().create && <Redirect to="/administracion/inicio" />}
      
      <Title 
        title="Crear carta de cobro"
        className={'mb-4'}
        onClickIcon={()=>{
          history.push('/administracion/TMDeCartera/CartasCobro')
        }}
      />

      {/* inputs ------- 🔥  */}

      {/* name */}
      <div className="d-flex mb-3 justify-content-between">
        <div className="d-flex flex-column w-100">
          <label htmlFor="name" className="labelFont">
            Nombre de la carta:
          </label>
          <input
            type="text"
            className="input-darker-blue"
            value={state.registerResults?.name}
            onChange={(e) =>
              setState({
                ...state,
                registerResults: {
                  ...state.registerResults,
                  name: e.target.value,
                },
              })
            }
          />
        </div>
        <div className="d-flex flex-column w-100  mx-2">
          <label htmlFor="signature" className="labelFont">
            Firma:
          </label>
          <input
            type="signature"
            className="input-darker-blue"
            value={state.registerResults?.signature}
            onChange={(e) =>
              setState({
                ...state,
                registerResults: {
                  ...state.registerResults,
                  signature: e.target.value,
                },
              })
            }
          />
        </div>
        <div className="d-flex flex-column w-100">
          <label htmlFor="role" className="labelFont">
            Rol:
          </label>
          <input
            type="text"
            className="input-darker-blue"
            value={state.registerResults?.role}
            onChange={(e) =>
              setState({
                ...state,
                registerResults: {
                  ...state.registerResults,
                  role: e.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="d-flex mt-2">
        <div className="d-flex flex-column">
          <p className="text-primary fw-bold mb-0">
            Variables para la plantilla:
          </p>
          <ul className="darkGray">
            <li>[DD] : Día en que se está redactando la carta</li>
            <li>[MM] : Mes en que se está redactando la carta</li>
            <li>[AAAA] : Año en que se está redactando la carta</li>
            <li>[CLIENTE] : Cliente al cual se le va a redactar la carta</li>
            <li>
              [TABLA_FACTURAS] : Tabla de las facturas que se le está cobrando
              al usuario
            </li>
            <li>
              [NOMBRE_FIRMA] : Nombre de la firma parametrizada en la plantilla
            </li>
            <li>
              [CARGO_FIRMA] : Cargo de la firma parametrizada en la plantilla
            </li>
            <li>[EMPRESA_FIRMA] : Cuenta en sesión</li>
          </ul>
        </div>
      </div>
      <CKEditor
        editor={ClassicEditor}
        data={state.registerResults.template}
        // onReady={(editor) => {
        //   // You can store the "editor" and use when it is needed.
        // }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setState({
            ...state,
            registerResults: {
              ...state.registerResults,
              template: data,
            },
          });
        }}
        // onBlur={(event, editor) => {
        // }}
        // onFocus={(event, editor) => {
        // }}
      />

      <div className="d-flex justify-content-end">
        <button
          className="btn-primary rounded-2 my-3"
          onClick={() => handleSubmit(state.registerResults)}
        >
          Guardar
        </button>
      </div>
    </div>
  );
}

export default CollectionCreate;
