/* eslint-disable jsx-a11y/alt-text */
import { Calendar } from 'react-big-calendar'
import React from 'react'
import styles from "../../components/Layouts/tableStyle.module.scss";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { localizer, getMessagesES } from './../../helpers/';
import CalendarEvent from './CalendarEvent';
import useCalendarStore from '../../Hooks/useCalendarStore';
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { loader, message } from '../../helpers/helpers';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import user from "../../assets/img/icons/user.svg"
import locations from "../../assets/img/icons/location.svg"
import usuario from "../../assets/img/icons/usuario.svg"
import formatHours from '../../helpers/formatHours';
import calendar from "../../assets/img/icons/calendar.svg"
import Reloj from "../../assets/img/icons/Reloj.svg"
import estetoscopic from "../../assets/img/icons/estetoscopic.svg"
import closeIcon from "../../assets/img/icons/close.svg"
import person from "../../assets/img/icons/person.svg"
import block from "../../assets/img/icons/block.svg"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Drawer } from '@mui/material';
import { Children } from 'react';
import userLogo from "../../assets/img/header/user-img.png";
import BlockAgenda from './BlockAgenda';
import useCalendarBlockTextarea from '../../Hooks/useCalendarBlockTextarea';

const CalendarPage = ({ objDoctor = '', objSite = '', optionalDoctor, views, container, calendarOption, schedule, filterScheduleChange, setFilterScheduleChange, isEditingSchedule, setIsEditingSchedule, setTab, tab }) => {

    const MySwal = withReactContent(Swal);

    const store = useSelector((state) => state);

    const idAccount = store.loginReducer.currentAccount.id;
    const idUser = store.loginReducer.user_data.id;

    const [activeDay, setDayActive] = React.useState('')

    const [currentDate, setCurrentDate] = React.useState(moment(new Date()).format("YYYY-MM-DD"))


    const [optionBlock, setOptionBlock] = React.useState(false)



    const [drawerListblockMonthData, setDrawerListblockMonthData] = React.useState(false)

    const [selectedOption, setSelectedOption] = React.useState('month')

    const [selectEventActive, setSelectEventActive] = React.useState(null)

    const [data, setData] = React.useState({
        modalActive: false,
        event: []
    })

    const [modalBlockActive, setModalBlockActive] = React.useState(false)

    const {
        activeEvent,
        events,
        setActiveEvent,
        setEvents,
    } = useCalendarStore()

    const { comment, setBlockTextarea } = useCalendarBlockTextarea()

    const [filterBlock, setFilterBlock] = React.useState({
        method: "",
        idAgenda: "",
        date: "",
        idUser: "",
        comment: '',
        thisDay: false,
        thisDayAndAll: false
    })

    const {
        results: ListEventsDay,
        trigger: getListEventsDay,
        load: loadListEventsDay

    } = useGetMethod()

    const {
        results: ListEventBlockReprogrammingMonth,
        trigger: getListEventBlockReprogrammingMonth,
        load: loadListEventBlockReprogrammingMonth
    } = useGetMethod()

    const {
        results: ListEventsSite,
        trigger: getListEventsSite,
        load: loadListEventsSite

    } = useGetMethod()

    const {
        results: ListEventsWeek,
        trigger: getListEventsWeek,
        load: loadListEventsWeek
    } = useGetMethod()

    const {
        results: ListEventsMonth,
        trigger: getListEventsMonth,
        load: loadListEventsMonth
    } = useGetMethod()

    const {
        trigger: sendBlockMonth,
        load: loadListBlockMonth
    } = usePostMethod()

    const {
        trigger: sendUnlockMonth,
        load: loadListUnlockMonth
    } = usePostMethod()


    const token = store.loginReducer.Authorization;
    const [dateGrid, setDateGrid] = React.useState('second')

    const eventStyledGetter = () => {

        const style = {
            borderRadius: '0px',
            opacity: 0.8,
            // backgroundColor: `${event.color}`
        }

        return {
            style
        }
    }

    const [optionalActiveEvent, setOptionalActiveEvent] = React.useState({})

    const onSelect = (event) => {

        if (event !== null) {
            setSelectEventActive({ ...event })
            setActiveEvent(event)
            setOptionalActiveEvent(event)
            setDateGrid(event)
        }

        if (selectedOption === 'month') {

            setData({ event, modalActive: true })
        }

        if (selectedOption === 'day' || selectedOption === 'week') {
            setData({ event, modalActive: true })
        }
    }


    const onViewChange = (event) => {

        calendarOption?.setCalendarOptionDate(event)

        setSelectedOption(event)

        setFilterScheduleChange('')

    }
    // eslint-disable-next-line no-unused-vars
    const DateCellWrapper = ({ children, value }) => {

        if (objDoctor.mdId) {

            let cellStyle = React.cloneElement(Children.only(children), {

                style: {
                    ...children.style,
                    backgroundColor: 'white',
                },
            });
            return cellStyle;
        }


        if (objSite.idSite) {
            const filterColor = ListEventsSite?.results?.find((e) => moment(new Date(e.beginsAt)).format("YYYY-MM-DD") === moment(new Date(value)).format("YYYY-MM-DD"))

            let cellStyle = React.cloneElement(Children.only(children), {
                //className:valueDay === selDay ? "yourclassname rbc-day-bg": "rbc-day-bg",
                style: {
                    ...children.style,
                    // backgroundColor: filterColor?.enabled === 0 ? '#d6e0eb' : '',
                },
            });
            return cellStyle;
        }

    }

    const Onnavigate = (e) => {

        setDayActive(moment(e).format('dddd - DD').toLocaleUpperCase())

        setCurrentDate(moment(e).format('YYYY-MM-DD'))

    }

    const EventWrapperComponent = ({ event, children }) => {

        const newChildren = { ...children };
        const newChildrenProps = { ...newChildren.props };
        newChildrenProps.className = `${newChildrenProps.className} 
        outline-none border-none  `;


        if (selectedOption === 'week') {
            newChildrenProps.style = {
                ...newChildrenProps.style,
                borderRight: `3px solid ${event.color}`,
                borderLeft: `3px solid ${event.color}`,
                borderTop: 'transparent',
                borderBottom: 'transparent',
                color: `${event.color}`,
                borderRadius: '0px',
                padding: '10px',
                background: `${event.color}80`,
                fontSize: "10px",
                display: "flex",
                alignItems: "center",
                marginBottom: "2px",
                justifyContent: 'center',
                fontWeight: 'bold'
            }
        }

        if (selectedOption === 'month') {

            if (objSite.idSite) {
                newChildrenProps.style = {
                    ...newChildrenProps.style,
                    borderRight: `transparent`,
                    borderLeft: `transparent`,
                    borderTop: 'transparent',
                    borderBottom: 'transparent',
                    color: `${event.color}`,
                    padding: '0px',
                    background: `${event.enabled === 0 ? '#d6e0eb' : event.color + 80}`,
                    cursor: 'pointer',
                    // background: 'black',
                    borderRadius: "11px",
                    fontSize: "10px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "2px",
                    justifyContent: 'center',
                    fontWeight: 'bold'
                }
            }

            if (objDoctor.mdId) {
                newChildrenProps.style = {
                    ...newChildrenProps.style,
                    borderRight: `transparent`,
                    borderLeft: `transparent`,
                    borderTop: 'transparent',
                    borderBottom: 'transparent',
                    padding: '0px',
                    color: `${event.enabled === 0 ? '#77787a' : `${event.color}`}`,
                    cursor: 'pointer',
                    background: `${event.enabled === 0 ? '#d6e0eb' : `${event.color + 80}`}`,
                    borderRadius: "11px",
                    fontSize: "10px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "2px",
                    justifyContent: 'center',
                    fontWeight: 'bold'
                }
            }
        }

        if (selectedOption === 'day') {

            newChildrenProps.style = {
                ...newChildrenProps.style,
                borderRight: '0px',
                borderLeft: '0px',
                borderTop: 'transparent',
                padding: '0px',
                background: `${event.color}80`,
                // background: 'black',
                borderRadius: "0px",
                minHeight: '10px !important',




            }
        }

        newChildren.props = { ...newChildrenProps };

        return <div>{newChildren}</div>;
    };

    React.useEffect(() => {

        const btnGroup = document.querySelector('.rbc-btn-group');
        const rbcToolbarLabel = document.querySelector('.rbc-toolbar-label');
        btnGroup?.children[0].remove()
        btnGroup?.prepend(rbcToolbarLabel)


        if (selectedOption === 'week' && objDoctor.mdId) {
            calendarOption?.setCalendarOptionDate('week')
            getListEventsWeek({
                url: `/medical/schedules`,
                objFilters: { view: 'month', doctorId: objDoctor?.mdId, date: currentDate, eaccount: idAccount },
                token: token
            })

            // return  setEvents(DataWeeks)
        }

        if (selectedOption === 'month' && objDoctor.mdId) {
            calendarOption?.setCalendarOptionDate('month')

            getListEventsMonth({
                url: `/medical/schedules`,
                objFilters: { view: 'month', doctorId: objDoctor?.mdId, date: currentDate, eaccount: idAccount },
                token: token
            })

            // return  setEvents(DataWeeks)
        }

        if (selectedOption === 'day' && objDoctor.mdId) {

            getListEventsDay({
                url: `/medical/schedules`,
                objFilters: { view: 'day', doctorId: objDoctor?.mdId, date: currentDate, eaccount: idAccount },
                token: token
            })
            // return  setEvents(DataWeeks)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objDoctor.mdId])

    React.useEffect(() => {

        const btnGroup = document.querySelector('.rbc-btn-group');
        const rbcToolbarLabel = document.querySelector('.rbc-toolbar-label');
        btnGroup?.children[0].remove()
        btnGroup?.prepend(rbcToolbarLabel)

        if (selectedOption === 'month' && objDoctor.mdName) {
            getListEventsMonth({
                url: `/medical/schedules`,
                // objFilters: { view: 'day', doctorId: objDoctor.mdId, date: currentDate },
                objFilters: { view: 'month', doctorId: objDoctor.mdId, date: currentDate, eaccount: idAccount },
                token: token
            })

            const data = ListEventsMonth?.results?.map(e => ({
                ...e,
                beginsAt: new Date(e.beginsAt),
                endsAt: new Date(e.endsAt)
            })) || []

            if (data?.length) {
                let filters = []
                data.forEach((e, i) => {
                    if (i === 0) {
                        filters.push(e)
                    } else {
                        if (filters.filter(x => x.name === e.name).length === 0) {
                            filters.push(e)
                        }
                    }
                })
                schedule.setFilterSchedule(filters)

                return setEvents(data)
            } else {
                schedule.setFilterSchedule([])

                return setEvents([])
            }
        }

        if (selectedOption === 'week' && objDoctor.mdId) {

            getListEventsWeek({
                url: `/medical/schedules`,
                // objFilters: { view: 'day', doctorId: objDoctor.mdId, date: currentDate },
                objFilters: { view: 'month', doctorId: objDoctor.mdId, date: currentDate, eaccount: idAccount },
                token: token
            })


            const data = ListEventsWeek?.results?.map(e => ({
                ...e,
                beginsAt: new Date(e.beginsAt),
                endsAt: new Date(e.endsAt)
            })) || []

            if (data?.length) {

                let filters = []
                data.forEach((e, i) => {
                    if (i === 0) {
                        filters.push(e)
                    } else {
                        if (filters.filter(x => x.name === e.name).length === 0) {
                            filters.push(e)
                        }
                    }
                })

                schedule.setFilterSchedule(filters)

                return setEvents(data)
            } else {
                schedule.setFilterSchedule([])

                return setEvents([])
            }


        }

        if (selectedOption === 'day' && objDoctor.mdId) {

            getListEventsDay({
                url: `/medical/schedules`,
                // objFilters: { view: 'day', doctorId: objDoctor.mdId, date: currentDate },
                objFilters: { view: 'day', doctorId: objDoctor.mdId, date: currentDate, eaccount: idAccount },
                token: token
            })


            const data = ListEventsDay?.results?.map(e => ({
                ...e,
                beginsAt: new Date(e.beginsAt),
                endsAt: new Date(e.endsAt)
            })) || []

            if (data?.length) {

                return setEvents(data)
            } else {

                return setEvents([])
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption])

    React.useEffect(() => {

        const btnGroup = document.querySelector('.rbc-btn-group');
        const rbcToolbarLabel = document.querySelector('.rbc-toolbar-label');
        btnGroup?.children[0].remove()
        btnGroup?.prepend(rbcToolbarLabel)


        if (objDoctor?.mdId === '' && objSite.idSite === '') {
            setSelectedOption('month')
            calendarOption?.setCalendarOptionDate('month')
            // return  setEvents(DataMonth)
        }

        // if (selectedOption !== '' && objSite.idSite !== '') {
        if (selectedOption !== '' && objSite.idSite) {

            calendarOption?.setCalendarOptionDate('month')
            setSelectedOption('month')
            getListEventsSite({
                url: `/medical/schedules`,
                // objFilters: { view: 'site', site: objSite?.idSite, date: currentDate },
                objFilters: { view: 'site', site: objSite.idSite, date: currentDate, eaccount: idAccount },
                token: token
            })


        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objSite.idSite])

    React.useEffect(() => {

        if (selectedOption === 'day' && objDoctor.mdId) {
            getListEventsDay({
                url: `/medical/schedules`,
                // objFilters: { view: 'day', doctorId: objDoctor.mdId, date: currentDate },
                objFilters: { view: 'day', doctorId: objDoctor.mdId, date: currentDate, eaccount: idAccount },
                token: token
            })
        }

        if (selectedOption === 'week' && objDoctor.mdId) {
            getListEventsWeek({
                url: `/medical/schedules`,
                objFilters: { view: 'month', doctorId: objDoctor.mdId, date: currentDate, eaccount: idAccount },
                token: token
            })
        }

        if (selectedOption === 'month' && objSite?.idSite === '') {
            getListEventsMonth({
                url: `/medical/schedules`,
                objFilters: { view: 'month', doctorId: objDoctor.mdId, date: currentDate, eaccount: idAccount },
                token: token
            })
        }

        // if (selectedOption === 'month' && objSite?.idSite !== '') {
        if (selectedOption === 'month' && objSite?.idSite) {



            getListEventsSite({
                url: `/medical/schedules`,
                objFilters: { view: 'site', site: objSite.idSite, date: currentDate, eaccount: idAccount },
                token: token
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDate])

    React.useEffect(() => {
        if (selectedOption === 'day' && objDoctor.mdId) {

            if (ListEventsDay.results?.length) {

                const data = []

                ListEventsDay.results.forEach(e => {
                    data.push(new Date(e.beginsAt), new Date(e.endsAt))
                })

            }


            const data = ListEventsDay?.results?.map(e => ({
                ...e,
                beginsAt: new Date(e.beginsAt),
                endsAt: new Date(e.endsAt)
            })) || []

            if (data?.length) {

                return setEvents(data)
            } else {

                return setEvents([])
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ListEventsDay])

    React.useEffect(() => {
        if (selectedOption !== '' && !objDoctor.mdId) {

            if (ListEventsSite.results?.length) {

                const data = []

                ListEventsSite.results.forEach(e => {
                    data.push(new Date(e.beginsAt), new Date(e.endsAt))
                })

            }

            const data = ListEventsSite?.results?.map(e => ({
                ...e,
                beginsAt: new Date(e.beginsAt),
                endsAt: new Date(e.endsAt)
            })) || []

            if (data?.length) {
                let filters = []
                data.forEach((e, i) => {
                    if (i === 0) {
                        filters.push(e)
                    } else {
                        if (filters.filter(x => x.name === e.name).length === 0) {
                            filters.push(e)
                        }
                    }
                })

                schedule.setFilterSchedule(filters)

                return setEvents(data)
            } else {
                schedule.setFilterSchedule([])

                return setEvents([])
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ListEventsSite])

    React.useEffect(() => {

        if (selectedOption === 'week' && objDoctor.mdId) {

            if (ListEventsWeek?.results?.length) {

                const data = []

                ListEventsWeek?.results.forEach(e => {
                    data.push(new Date(e.beginsAt), new Date(e.endsAt))
                })


            }

            const data = ListEventsWeek?.results?.map(e => ({
                ...e,
                beginsAt: new Date(e.beginsAt),
                endsAt: new Date(e.endsAt)
            })) || []


            if (data?.length) {

                let filters = []
                data.forEach((e, i) => {
                    if (i === 0) {
                        filters.push(e)
                    } else {
                        if (filters.filter(x => x.name === e.name).length === 0) {
                            filters.push(e)
                        }
                    }
                })
                schedule.setFilterSchedule(filters)

                return setEvents(data)
            } else {
                schedule.setFilterSchedule([])

                return setEvents([])
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ListEventsWeek])

    React.useEffect(() => {

        if (selectedOption === 'month' && objDoctor.mdId) {

            if (ListEventsMonth?.results?.length) {

                const data = []

                ListEventsMonth?.results.forEach(e => {
                    data.push(new Date(e.beginsAt), new Date(e.endsAt))
                })

            }
            const data = ListEventsMonth?.results?.map(e => ({
                ...e,
                beginsAt: new Date(e.beginsAt),
                endsAt: new Date(e.endsAt)
            })) || []



            if (data?.length) {
                let filters = []
                data.forEach((e, i) => {
                    if (i === 0) {
                        filters.push(e)
                    } else {
                        if (filters.filter(x => x.name === e.name).length === 0) {
                            filters.push(e)
                        }
                    }
                })
                schedule.setFilterSchedule(filters)

                return setEvents(data)
            } else {
                schedule.setFilterSchedule([])

                return setEvents([])
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ListEventsMonth])



    React.useEffect(() => {

        if (selectedOption === 'month' && objDoctor.mdId) {

            if (ListEventsMonth?.results?.length) {

                const data = []

                ListEventsMonth?.results.forEach(e => {
                    data.push(new Date(e.beginsAt), new Date(e.endsAt))
                })
            }

            // eslint-disable-next-line array-callback-return
            const dataPush = []
            ListEventsMonth?.results?.forEach(e => {
                if (filterScheduleChange === '') {
                    dataPush.push({
                        ...e,
                        beginsAt: new Date(e.beginsAt),
                        endsAt: new Date(e.endsAt)
                    })
                } else {
                    if (e.name === filterScheduleChange) {
                        dataPush.push({
                            ...e,
                            beginsAt: new Date(e.beginsAt),
                            endsAt: new Date(e.endsAt)
                        })
                    }
                }
            })

            if (dataPush?.length) {

                return setEvents(dataPush)
            } else {

                return setEvents([])
            }
        }

        if (selectedOption === 'month' && objSite.idSite) {
            if (ListEventsSite?.results?.length) {

                const data = []

                ListEventsSite?.results.forEach(e => {
                    data.push(new Date(e.beginsAt), new Date(e.endsAt))
                })
            }

            // eslint-disable-next-line array-callback-return
            const dataPush = []
            ListEventsSite?.results?.forEach(e => {
                if (filterScheduleChange === '') {
                    dataPush.push({
                        ...e,
                        beginsAt: new Date(e.beginsAt),
                        endsAt: new Date(e.endsAt)
                    })
                } else {
                    if (e.name === filterScheduleChange) {
                        dataPush.push({
                            ...e,
                            beginsAt: new Date(e.beginsAt),
                            endsAt: new Date(e.endsAt)
                        })
                    }
                }
            })
            if (dataPush?.length) {

                return setEvents(dataPush)
            } else {

                return setEvents([])
            }
        }

        if (selectedOption === 'week' && objDoctor.mdId) {

            if (ListEventsWeek?.results?.length) {

                const data = []

                ListEventsWeek?.results.forEach(e => {
                    // if (e.name === filterScheduleChange) {
                    data.push(new Date(e.beginsAt), new Date(e.endsAt))
                    // }
                })
            }
            // eslint-disable-next-line array-callback-return
            const dataPush = []
            ListEventsWeek?.results?.forEach(e => {
                if (filterScheduleChange === '') {
                    dataPush.push({
                        ...e,
                        beginsAt: new Date(e.beginsAt),
                        endsAt: new Date(e.endsAt)
                    })
                } else {
                    if (e.name === filterScheduleChange) {
                        dataPush.push({
                            ...e,
                            beginsAt: new Date(e.beginsAt),
                            endsAt: new Date(e.endsAt)
                        })
                    }
                }
            })

            if (dataPush?.length) {

                return setEvents(dataPush)
            } else {

                return setEvents([])
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterScheduleChange])


    const handleSubmitBlock = async () => {

        if (activeEvent?.enabled) {
            if (comment.trim() === '' || filterBlock.method.trim() === '') {
                setModalBlockActive(true)
                return MySwal.fire({
                    icon: "warning",
                    // title: "¿Está seguro?",
                    text: `Por favor digite todos los campos`,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Si, Continuar",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                })
            }
        } else {
            if (filterBlock.method.trim() === '') {
                setModalBlockActive(true)
                return MySwal.fire({
                    icon: "warning",
                    // title: "¿Está seguro?",
                    text: `Por favor seleccione una opción`,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Si, Continuar",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                })
            }
        }

        if (!activeEvent?.enabled) { }

        try {
            MySwal.fire({
                icon: "warning",
                title: "¿Está seguro?",
                text: activeEvent?.enabled ? 'La agenda será bloqueada para detener las programaciones, sin embargo, estas que ya existían en la agenda seguirán activas, recuerde reagendarlas.' : 'La agenda será desbloqueada!',
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si, Continuar",
                cancelButtonColor: "#d33",
                cancelButtonText: "Cancelar",
                showCancelButton: true,
            })
                .then((result) => {

                    if (result.isConfirmed) {
                        setFilterBlock({ comment: "", method: "", thisDay: false, thisDayAndAll: false })
                        setBlockTextarea('')

                        if (activeEvent?.enabled) {
                            sendBlockMonth({
                                url: `/medical/schedules/block/`,
                                token: token,
                                noAlert: true,
                                method: "POST",

                                body: objSite?.idSite ?
                                    {
                                        method: filterBlock.method,
                                        idSite: objSite.idSite,
                                        date: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format("YYYY-MM-DD"),
                                        idUser: idUser,
                                        comment: comment
                                    }
                                    : objDoctor?.mdName ?
                                        {
                                            method: filterBlock.method,
                                            idAgenda: data.event.id || optionalActiveEvent.id,
                                            date: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format("YYYY-MM-DD"),
                                            idUser: idUser,
                                            comment: comment
                                        } : {},

                                doAfterSuccess: (response) => {
                                    if (response.status !== 200) {
                                        // return message('warning', ' Advertencia', response.message)

                                        if (objSite?.idSite) {
                                            getListEventsSite({
                                                url: `/medical/schedules`,
                                                objFilters: { view: 'site', site: objSite.idSite, date: currentDate, eaccount: idAccount },
                                                token: token
                                            })
                                        }

                                        if (objDoctor?.mdName) {
                                            getListEventsMonth({
                                                url: `/medical/schedules`,
                                                objFilters: { view: 'month', doctorId: objDoctor?.mdId, date: currentDate, eaccount: idAccount },
                                                token: token
                                            })
                                        }


                                        MySwal.fire({
                                            icon: "success",
                                            title: objSite.idSite && activeEvent?.enabled
                                                ? 'Agenda(s) bloqueada(s)'
                                                : objSite.idSite && !activeEvent?.enabled ? 'Agenda(s) desbloqueada(s)'
                                                    : !objSite.idSite && !activeEvent?.enabled ? 'Día desbloqueado' : 'Día bloqueado',
                                            text: objDoctor.mdName && response.message,
                                            confirmButtonColor: "#00B4CC",
                                            confirmButtonText: "Si, Continuar",
                                            showCancelButton: false,
                                        }).then((rs) => {
                                            if (rs.isConfirmed) {


                                                if (objSite?.idSite) {

                                                    const data1 = !optionBlock
                                                        ? {
                                                            url: `/medical/generals/appointments/`,
                                                            objFilters: {
                                                                site: objSite.idSite,
                                                            },
                                                            token: token
                                                        }

                                                        : {
                                                            url: `/medical/generals/appointments/`,
                                                            objFilters: {
                                                                site: objSite.idSite,
                                                                blockedDay: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format("YYYY-MM-DD")
                                                            },
                                                            token: token
                                                        }

                                                    getListEventBlockReprogrammingMonth(data1)
                                                }

                                                if (objDoctor?.mdName) {

                                                    const data1 = !optionBlock
                                                        ? {
                                                            url: `/medical/generals/appointments/`,
                                                            objFilters: {
                                                                idSchedule: data.event.id || optionalActiveEvent.id,
                                                            }, token: token
                                                        }

                                                        : {
                                                            url: `/medical/generals/appointments/`,
                                                            objFilters: {
                                                                idSchedule: data.event.id || optionalActiveEvent.id,
                                                                blockedDay: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format("YYYY-MM-DD")
                                                            }, token: token
                                                        }

                                                    getListEventBlockReprogrammingMonth(data1)
                                                }
                                                setDrawerListblockMonthData(true)



                                            } else {
                                                setDrawerListblockMonthData(false)
                                            }
                                        })

                                    }

                                },
                            });
                        } else {
                            sendUnlockMonth({
                                url: `/medical/schedules/unblock/`,
                                token: token,
                                noAlert: true,
                                method: "POST",

                                body: objSite?.idSite ?
                                    {

                                        method: filterBlock.method,
                                        idSite: objSite.idSite,
                                        date: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format("YYYY-MM-DD"),
                                        idUser: idUser,

                                    }
                                    : objDoctor?.mdName ?
                                        {

                                            method: filterBlock.method,
                                            idAgenda: data.event.id || optionalActiveEvent.id,
                                            date: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format("YYYY-MM-DD"),

                                        } : {},

                                doAfterSuccess: (response) => {
                                    if (response.status !== 200) {
                                        // return message('warning', ' Advertencia', response.message)

                                        if (objSite?.idSite) {
                                            getListEventsSite({
                                                url: `/medical/schedules`,
                                                objFilters: { view: 'site', site: objSite.idSite, date: currentDate, eaccount: idAccount },
                                                token: token
                                            })
                                        }

                                        if (objDoctor?.mdName) {
                                            getListEventsMonth({
                                                url: `/medical/schedules`,
                                                objFilters: { view: 'month', doctorId: objDoctor?.mdId, date: currentDate, eaccount: idAccount },
                                                token: token
                                            })
                                        }



                                        MySwal.fire({
                                            icon: "success",
                                            title: objSite.idSite && activeEvent?.enabled
                                                ? 'Agenda(s) bloqueada(s)'
                                                : objSite.idSite && !activeEvent?.enabled ? 'Agenda(s) desbloqueada(s)'
                                                    : !objSite.idSite && !activeEvent?.enabled ? 'Día desbloqueado' : 'Día bloqueado',
                                            text: objDoctor.mdName && response.message,
                                            confirmButtonColor: "#00B4CC",
                                            confirmButtonText: "Si, Continuar",
                                            showCancelButton: false,
                                        }).then((rs) => {
                                            if (rs.isConfirmed) {


                                                if (objSite?.idSite) {

                                                    const data1 = !optionBlock
                                                        ? {
                                                            url: `/medical/generals/appointments/`,
                                                            objFilters: {
                                                                site: objSite.idSite,
                                                            }, token: token
                                                        }

                                                        : {
                                                            url: `/medical/generals/appointments/`,
                                                            objFilters: {
                                                                site: objSite.idSite,
                                                                blockedDay: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format("YYYY-MM-DD")
                                                            }, token: token
                                                        }

                                                    getListEventBlockReprogrammingMonth(data1)
                                                }

                                                if (objDoctor?.mdName) {

                                                    const data1 = !optionBlock
                                                        ? {
                                                            url: `/medical/generals/appointments/`,
                                                            objFilters: {
                                                                idSchedule: data.event.id || optionalActiveEvent.id,
                                                            }, token: token
                                                        }

                                                        : {
                                                            url: `/medical/generals/appointments/`,
                                                            objFilters: {
                                                                sidSchedule: data.event.id || optionalActiveEvent.id,
                                                                blockedDay: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format("YYYY-MM-DD")
                                                            }, token: token
                                                        }

                                                    getListEventBlockReprogrammingMonth(data1)
                                                }
                                                setDrawerListblockMonthData(true)



                                            } else {
                                                setDrawerListblockMonthData(false)
                                            }
                                        })

                                    }

                                },
                            });
                        }

                    } else {
                        setFilterBlock({ comment: "", method: "", thisDay: false, thisDayAndAll: false })
                        setBlockTextarea('')
                    }
                });
        } catch (error) {
            return message("error", "Error", error.message);
        }
    };


    React.useEffect(() => {
        if (isEditingSchedule?.isEditing) {
            setFilterScheduleChange('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditingSchedule?.isEditing])

    async function getScheduleIdToEdit() {
        await setIsEditingSchedule({
            isEditing: true,
            schId: activeEvent?.id,
        })
        await setData(state => ({ ...state, modalActive: false }))
        await setTab({ ...tab, page: "newSchedule" })

    }


    // console.log("events: ", events);

    React.useEffect(() => {

        setEvents([])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objDoctor?.mdId, objSite?.idSite])


    return (

        <>

            {/* ANCHOR CONFIG MODAL  */}
            <OrdModal
                titleClassName={'app_titleModalCalendar'}
                headerModalClassName={'headerModalClassName'}
                footerModalClassName={'footerModalClassName'}
                headerBorderNone={'headerBorderNone'}
                styleBody={'app_body'}
                iconBlock={{ active: selectedOption === 'month' ? true : false, isBlock: data.event.enabled === 0 ? true : false }}

                title=
                {
                    selectedOption === 'day'
                        ? activeEvent?.serviceName : selectedOption === 'month' && objSite.idSite === ''
                            ? activeEvent?.name || optionalActiveEvent?.name : selectedOption === 'week' && objSite.idSite === ''
                                ? activeEvent?.consultingRoomName : selectedOption === 'month' && objSite.idSite !== ''
                                    ? objSite?.site : ''
                }
                show={data.modalActive}
                size={"200"}
                onHide={() => {
                    setData(state => ({ ...state, modalActive: false }))
                    setFilterBlock({ comment: "", method: "", thisDay: false, thisDayAndAll: false })
                    setBlockTextarea('')
                }

                }
                onBlock={() => {
                    setData(state => ({ ...state, modalActive: false }))
                    setModalBlockActive(true)
                    setFilterBlock({ comment: "", method: "", thisDay: false, thisDayAndAll: false })
                    setBlockTextarea('')
                }}

                onEdit={() => getScheduleIdToEdit()}
                hideCancelButton


            >

                {
                    selectEventActive !== null && selectedOption === 'month' && objSite.idSite === '' &&
                    <>

                        <p className='boxModal2'>{data.event?.description || optionalActiveEvent?.description}</p>

                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', rowGap: '10px' }} >

                            <div className='boxModal'>
                                <img className={`${styles.svgBlueFilter}`} src={calendar} style={{ height: '15px' }} />
                                <span>Cada semana el {data.event?.dowValue || optionalActiveEvent?.dowValue}</span>
                            </div>

                            <div className='boxModal'>
                                <img className={`${styles.svgBlueFilter}`} src={locations} style={{ height: '15px' }} />
                                <span>{activeEvent?.siteDescription || optionalActiveEvent?.siteDescription} - {activeEvent?.consultingRoomName || optionalActiveEvent?.consultingRoomName} {activeEvent?.site || optionalActiveEvent?.site}</span>
                            </div>

                            <div className='boxModal'>
                                <img src={Reloj} style={{ height: '15px' }} />
                                <span>{formatHours(activeEvent?.beginsAt || optionalActiveEvent?.beginsAt)} {formatHours(activeEvent?.endsAt || optionalActiveEvent?.endsAt)} ({Number(formatHours(activeEvent?.endsAt || optionalActiveEvent?.endsAt).split(':')[0]) - Number(formatHours(activeEvent?.beginsAt || optionalActiveEvent?.beginsAt).split(':')[0])} hora(s))</span>
                            </div>

                            {
                                data.event.enabled === 0 &&

                                <div className='boxModal' style={{ marginLeft: '-2px' }}>
                                    <img className={`${styles.svgBlueFilter}`} src={block} style={{ height: '18px' }} />
                                    <span>{data.event?.blockReason || optionalActiveEvent?.blockReason || 'motivo de bloqueo, no disponible'}</span>
                                </div>
                            }

                            <div className='boxModal'>
                                <img src={person} style={{ height: '15px' }} />
                                <span>Asistente - {activeEvent?.auxName || optionalActiveEvent?.auxName}</span>
                            </div>

                            <div>
                                <div class="container d-flex flex-column text-start align-self-center w-100">
                                    <label for="inputTypeColor" className={`${styles.appInputColorExa} ${styles.ordDarkBlueText} ${styles.f14} mb-1`}>
                                        Color
                                    </label>
                                    <input
                                        type="color"
                                        id="inputTypeColor"
                                        value={`${data.event?.color || optionalActiveEvent?.color}`}
                                        disabled
                                    />
                                </div>
                            </div>

                        </div>

                    </>
                }

                {
                    selectEventActive !== null && selectedOption === 'month' && objSite.idSite !== '' &&
                    <>

                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', rowGap: '10px' }} >

                            <div className='boxModal'>
                                <img className={`${styles.svgBlueFilter}`} src={calendar} style={{ height: '15px' }} />
                                <span>{moment(activeEvent?.beginsAt).format('LL')}</span>
                            </div>

                            <div className='boxModal'>
                                <img src={Reloj} style={{ height: '15px' }} />
                                <span>{formatHours(activeEvent?.beginsAt)} {formatHours(activeEvent?.endsAt)}</span>
                            </div>

                            <div className='boxModal'>
                                <img className={`${styles.svgBlueFilter}`} src={estetoscopic} style={{ height: '15px' }} />
                                <span>{activeEvent?.appointmentsCount} Citas agendadas</span>
                            </div>

                            <div className='boxModal'>
                                <img className={`${styles.svgBlueFilter}`} src={usuario} style={{ height: '15px' }} />
                                <span>{activeEvent?.doctorsCount} {activeEvent?.doctorsCount > 1 ? 'médicos' : 'médico'}</span>
                            </div>

                            {
                                activeEvent?.enabled === 0 &&

                                <div className='boxModal' style={{ marginLeft: '-2px' }}>
                                    <img className={`${styles.svgBlueFilter}`} src={block} style={{ height: '18px' }} />
                                    <span>{activeEvent?.blockReason || optionalActiveEvent?.blockReason || 'motivo de bloqueo, no disponible'}</span>
                                </div>
                            }

                        </div>

                    </>
                }


                {
                    selectEventActive !== null && selectedOption === 'week' &&
                    <>

                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
                            <div className='boxModal'>

                                <img className={`${styles.svgBlueFilter}`} src={locations} style={{ height: '15px' }} />
                                <span>{`${activeEvent?.siteDescription}`}</span>
                            </div>

                            <div className='boxModal'>
                                <img className={`${styles.svgBlueFilter}`} src={locations} style={{ height: '15px' }} />
                                <span>{activeEvent?.site}, {activeEvent?.consultingRoomName}</span>
                            </div>

                            <div className='boxModal'>
                                <img src={Reloj} style={{ height: '15px' }} />
                                <span>{moment(activeEvent?.beginsAt).format('h:mm a')} - {moment(activeEvent?.endsAt).format('h:mm a')}</span>
                            </div>
                        </div>


                    </>
                }
                {
                    selectEventActive !== null && selectedOption === 'day' &&
                    <>

                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
                            <div className='boxModal'>

                                <img src={user} style={{ height: '15px' }} />
                                <span>{`${activeEvent?.patientName} ${activeEvent?.patientSecondName} ${activeEvent?.patientSecondSurname} ${activeEvent?.patientLastName}`}</span>
                            </div>

                            <div className='boxModal'>
                                <img className={`${styles.svgBlueFilter}`} src={locations} style={{ height: '15px' }} />
                                <span>{activeEvent?.site}, {activeEvent?.consultingRoomName}</span>
                            </div>

                            <div className='boxModal'>
                                <img src={Reloj} style={{ height: '15px' }} />
                                <span>{activeEvent?.serviceTime} minutos</span>
                            </div>
                        </div>


                    </>
                }


            </OrdModal>

            <OrdModal
                headerBorderNone={'headerBorderNone'}
                title={data.event?.enabled === 0 ? 'Desbloquear' : 'Bloquear'}
                show={modalBlockActive}
                size={400}
                onHide={() => setModalBlockActive(false)}
                btnYesName="Guardar"
                btnYesEvent={() => {
                    setModalBlockActive(false)
                    handleSubmitBlock()
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }} >

                    <div className='boxModal'>
                        <img className={`${styles.svgBlueFilter}`} src={calendar} style={{ height: '15px' }} />
                        <span>Cada semana el {data.event?.dowValue}</span>
                    </div>


                    <div className='boxModal'>
                        <img src={Reloj} style={{ height: '15px' }} />
                        <span>{formatHours(data.event?.beginsAt)} {formatHours(data.event?.endsAt)}</span>
                    </div>


                    {
                        data.event?.enabled ?
                            <BlockAgenda />
                            : ''
                    }

                    <div className="mb-2" >

                        {
                            data.event && !data.event.enabled &&
                            <>
                                {!data.event.enabled | !data.event.enabled && data.event.blockBySchedule | optionalActiveEvent.blockBySchedule ?

                                    <div style={{ color: '#6E6F7C' }}>
                                        <input
                                            className="form-check-input styleCheckInputRatio mr-2 cursorPointer"
                                            style={{ border: '2px solid #1a538d', color: '#6E6F7C' }}
                                            onChange={(e) => {
                                                setFilterBlock(state => ({
                                                    ...state,
                                                    method: 'followingDays',
                                                    thisDayAndAll: true,
                                                    thisDay: false
                                                    // comment: e.target.value
                                                }))

                                                setOptionBlock(false)

                                            }}

                                            checked={filterBlock.thisDayAndAll}
                                            type='radio'
                                            id="checkReqAuth"
                                        >
                                        </input>
                                        <span>Este dia y los siguientes*</span>
                                    </div> : ''

                                }

                                {!data.event.enabled | !optionalActiveEvent.enabled && !data.event.blockBySchedule | !optionalActiveEvent.blockBySchedule ?

                                    <div style={{ color: '#6E6F7C' }}>
                                        <input
                                            className="form-check-input styleCheckInputRatio mr-2 cursorPointer"
                                            style={{ border: '2px solid #1a538d', color: '#6E6F7C' }}
                                            onChange={
                                                (e) => {
                                                    setFilterBlock(state => ({
                                                        ...state,
                                                        method: 'thisDay',
                                                        thisDayAndAll: false,
                                                        thisDay: true
                                                        // comment: e.target.value
                                                    }))

                                                    setOptionBlock(true)
                                                }
                                            }

                                            checked={filterBlock.thisDay}
                                            type='radio'
                                            id="checkReqAuth"
                                        >
                                        </input>
                                        <span>Este dia*</span>
                                    </div> : ''

                                }
                            </>
                        }

                        {
                            data.event && data.event.enabled ?
                                <>
                                    {/* {!data.event.enabled | !optionalActiveEvent.enabled && data.event.blockBySchedule | optionalActiveEvent.blockBySchedule ? */}

                                    <div style={{ color: '#6E6F7C' }}>
                                        <input
                                            className="form-check-input styleCheckInputRatio mr-2 cursorPointer"
                                            style={{ border: '2px solid #1a538d', color: '#6E6F7C' }}
                                            onChange={(e) => {
                                                setFilterBlock(state => ({
                                                    ...state,
                                                    method: 'followingDays',
                                                    thisDayAndAll: true,
                                                    thisDay: false
                                                    // comment: e.target.value
                                                }))

                                                setOptionBlock(false)

                                            }}

                                            checked={filterBlock.thisDayAndAll}
                                            type='radio'
                                            id="checkReqAuth"
                                        >
                                        </input>
                                        <span>Este dia y los siguientes*</span>
                                    </div>

                                    {/* } */}

                                    {/* {!data.event.enabled | !optionalActiveEvent.enabled && !data.event.blockBySchedule | !optionalActiveEvent.blockBySchedule ? */}

                                    <div style={{ color: '#6E6F7C' }}>
                                        <input
                                            className="form-check-input styleCheckInputRatio mr-2 cursorPointer"
                                            style={{ border: '2px solid #1a538d', color: '#6E6F7C' }}
                                            onChange={
                                                (e) => {
                                                    setFilterBlock(state => ({
                                                        ...state,
                                                        method: 'thisDay',
                                                        thisDayAndAll: false,
                                                        thisDay: true
                                                        // comment: e.target.value
                                                    }))

                                                    setOptionBlock(true)
                                                }
                                            }

                                            checked={filterBlock.thisDay}
                                            type='radio'
                                            id="checkReqAuth"
                                        >
                                        </input>
                                        <span>Este dia*</span>
                                    </div>

                                    {/* } */}
                                </> : ''
                        }

                    </div>

                </div>

            </OrdModal>

            <Drawer
                anchor={"bottom"}
                open={drawerListblockMonthData}
                style={{ zIndex: 900 }}
            // onClose={() => setServices({ ...services, showDrawer: false })}
            >
                <div className={styles.app_drawer3}>
                    <div style={{ borderBottom: '1px solid #ccc' }}>
                        <div className={styles.app_container_close} style={{ display: 'flex' }}>
                            <div className={styles.xx}>
                                <div style={{ color: '#00b4cc', fontWeight: 'bold', fontSize: '30px' }}><span>Reprogramación de citas</span></div>
                                {
                                    optionBlock &&
                                    <div style={{ color: '#1a538d', fontWeight: 'bold' }}><span>{moment(dateGrid?.beginsAt).format("LL")}</span></div>
                                }
                            </div>
                            <div>
                                <img
                                    src={closeIcon}
                                    width={25}
                                    className={"cursorPointer"}
                                    alt="closeIcon"
                                    onClick={() => {
                                        setDrawerListblockMonthData(false)
                                    }}
                                />
                            </div>

                        </div>



                    </div>

                    <div style={{ marginTop: '50px' }}>

                        {typeof ListEventBlockReprogrammingMonth?.results !== 'undefined' &&

                            ListEventBlockReprogrammingMonth.results.length ?

                            <table style={{ width: '80%', margin: 'auto auto 100px auto', boxShadow: '0px 0px 0px 1px #00b4cc3d', borderRadius: '10px', }}>
                                <div style={{ height: 'auto', fontSize: '13px', fontFamily: 'PT Sans Caption', padding: '3px', overflowY: 'scrol', overflowX: 'hidden' }}>
                                    <tr style={{ color: '#00b4cc', fontWeight: 'bold', background: '#f5fcfd', display: 'inline-table', width: '100%' }}>
                                        <th style={{ width: '15%' }}>Fecha Atención</th>
                                        <th style={{ width: '20%' }}>Paciente</th>
                                        <th style={{ width: '20%' }}>Correo electrónico</th>
                                        <th style={{ width: '15%' }}>WhatsApp</th>
                                        <th style={{ width: '10%' }}>Atención</th>
                                        <th style={{ width: '10%' }}>Aseguradora</th>
                                        <th style={{ width: '10%' }}>Estado</th>
                                    </tr>
                                    {
                                        ListEventBlockReprogrammingMonth?.results.map((e, i) => (
                                            // dataXrr.map((e, i) => (

                                            i === ListEventBlockReprogrammingMonth?.results.length - 1
                                                ?
                                                <tr key={i} className={styles.styleT2}>
                                                    <td style={{ width: '200px', color: '#696969' }}>
                                                        <div>
                                                            {e.dateAttentionApp.split(' ')[0]}
                                                        </div>
                                                        <div>
                                                            {e.dateAttentionApp.split(' ')[1]}
                                                            <span> {e.dateAttentionApp.split(' ')[2]}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ color: '#696969' }}>
                                                        <div>
                                                            {e.fullNamePatient}
                                                        </div>
                                                        <div>
                                                            {e.documentDoctor}
                                                        </div>
                                                    </td>
                                                    <td style={{ color: '#696969' }}>{e.emailPatient}</td>
                                                    <td style={{ color: '#696969' }}><span>{`+57 ${e.whatsappPatient}`}</span></td>
                                                    <td style={{ color: '#696969' }}>{e.modalityLabel}</td>
                                                    <td style={{ color: '#696969' }}>{e.company}</td>
                                                    <td style={{ color: '#696969' }}>{e.statusApp}</td>

                                                </tr>
                                                :
                                                <tr key={i} className={styles.styleTr}>
                                                    <td style={{ width: '200px', color: '#696969' }}>
                                                        <div>
                                                            {e.dateAttentionApp.split(' ')[0]}

                                                        </div>
                                                        <div>
                                                            {e.dateAttentionApp.split(' ')[1]}
                                                            <span> {e.dateAttentionApp.split(' ')[2]}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ color: '#696969' }}>
                                                        <div>
                                                            {e.fullNamePatient}
                                                        </div>
                                                        <div>
                                                            {e.documentDoctor}
                                                        </div>
                                                    </td>
                                                    <td style={{ color: '#696969' }}>{e.emailPatient}</td>
                                                    <td style={{ color: '#696969' }}><span>{`+57 ${e.whatsappPatient}`}</span></td>
                                                    <td style={{ color: '#696969' }}>{e.modalityLabel}</td>
                                                    <td style={{ color: '#696969' }}>{e.company}</td>
                                                    <td style={{ color: '#696969' }}>{e.statusApp}</td>

                                                </tr>


                                        ))
                                    }
                                </div>

                            </table>

                            :
                            <>
                                <div class="alert alert-primary w-70 text-center m-auto" role="alert">
                                    No hay citas disponibles
                                </div>
                            </>

                        }



                    </div>
                </div>
            </Drawer>

            <div className={container ? "" : styles.calendarContainer}>
                {(loadListEventsDay || loadListUnlockMonth || loadListEventsWeek || loadListEventsSite || loadListEventsMonth || loadListEventBlockReprogrammingMonth || loadListBlockMonth) &&
                    loader}
                {
                    objDoctor?.mdName !== '' && objDoctor !== '' &&
                    <>
                        <div className={styles.headerCalendarDoctor}>

                            <div style={{ boxShadow: '0px 0px 3', backgroundImage: `url(${objDoctor.photo !== '' ? objDoctor.photo : userLogo} )`, backgroundSize: 'contain', height: '50px', width: '50px', borderRadius: '100%', backgroundRepeat: 'no-repeat' }}></div>

                            <div className={styles.headerCalendarDoctor_nameDoctor} >
                                {`Dr. ${objDoctor?.mdName}`}
                            </div>
                        </div>

                        <div>
                            <Calendar
                                showAllEvents={true}
                                culture='es'
                                localizer={localizer}
                                defaultView={'month'}
                                events={events}
                                startAccessor="beginsAt"
                                endAccessor="endsAt"
                                style={{ height: '70vh' }}
                                messages={getMessagesES()}
                                eventPropGetter={eventStyledGetter}
                                components={{
                                    eventWrapper: EventWrapperComponent,
                                    event: (e) => CalendarEvent(e, selectedOption, objSite, objDoctor),
                                    dateCellWrapper: DateCellWrapper

                                }}
                                onSelectEvent={onSelect}
                                onView={onViewChange}
                                views={
                                    {
                                        'month': true,
                                        'week': true,
                                        'day': true,
                                    }
                                }
                                onNavigate={Onnavigate}
                            // min={objMinMaxDay?.minDate}
                            // max={objMinMaxDay?.maxDate}
                            />


                            {/* <CalendarModal selectedOption={selectedOption} /> */}
                        </div>
                    </>
                }

                {
                    objSite?.site !== '' && objSite !== '' &&
                    <>
                        <div className={styles.headerCalendarSite}>
                            {/* <div className={styles.headerCalendarDoctor_logo}></div> */}
                            <div className={styles.headerCalendarDoctor_nameDoctor}>
                                {`Agenda ${objSite?.site}`}
                            </div>
                        </div>

                        <div>
                            <Calendar
                                showAllEvents={true}
                                culture='es'
                                localizer={localizer}
                                defaultView={'month'}
                                events={events}
                                startAccessor="beginsAt"
                                endAccessor="endsAt"
                                style={{ height: '70vh' }}
                                messages={getMessagesES()}
                                eventPropGetter={eventStyledGetter}
                                components={{
                                    eventWrapper: EventWrapperComponent,
                                    event: (e) => CalendarEvent(e, selectedOption, objSite, objDoctor),
                                    dateCellWrapper: DateCellWrapper
                                }}
                                onSelectEvent={onSelect}
                                onView={onViewChange}
                                views={['month']}
                                onNavigate={Onnavigate}
                            />

                        </div>
                    </>
                }


                {
                    objDoctor === '' && objSite === '' && optionalDoctor &&

                    <div>
                        <Calendar
                            showAllEvents={true}
                            culture='es'
                            localizer={localizer}
                            defaultView={'week'}
                            events={events}
                            startAccessor="beginsAt"
                            endAccessor="endsAt"
                            style={{ height: '70vh' }}
                            messages={getMessagesES()}
                            eventPropGetter={eventStyledGetter}
                            components={{
                                eventWrapper: EventWrapperComponent,
                                event: (e) => CalendarEvent(e, selectedOption, objSite, objDoctor),
                                dateCellWrapper: DateCellWrapper
                            }}
                            onSelectEvent={onSelect}
                            onView={onViewChange}
                            views={views}
                            onNavigate={Onnavigate}
                        />


                    </div>
                }



            </div>

        </>
    )


}

export default CalendarPage


