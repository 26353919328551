import React, {useState } from "react";
import messageIcon from "../../assets/img/icons/messageBlue.svg";
import Search from "../../assets/img/icons/lupa.svg";
import "reactjs-popup/dist/index.css";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { Col, Row } from "react-bootstrap";
import ModalNew from "../Layouts/ModalNew";
import { convertMoneyFormat } from "../../helpers/helpers";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import { useSelector } from "react-redux";


const InterestGenerate = ({ profile, idTemplate, setLoading, filters, setFilters, trigger, setTrigger, interestPayrollResults, setDetailPayroll, status }) => {
  const store = useSelector((state) => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "planCes");
      const renderElement = (elem) => {
        return (
         <section2 key={elem?.severanceId}
         style={{ backgroundColor: elem.comment && "#FCF1E1" }}
         >
          <div
           className="col-1 text-start px-3"
          //  style={{ paddingLeft: "14px" }}
          >
           <b className={`${tableStyles.crudModalLabel}`}>{elem?.docNumber ?? "-"}</b>
          </div>
          <div className="col-2 text-start px-3">{elem?.candidateFullName ?? "-"}</div>
          <div className="col-1 text-end px-2">{convertMoneyFormat(elem?.averageSalary ?? "-")}</div>
          <div className="col-1 text-center">{elem?.totalDaysWork ?? "-"}</div>
          <div className="col-2 text-end px-2">{convertMoneyFormat(elem?.severanceInterest ?? "-")}</div>
          <div className={`text-end col-2 ${status === "loaded" && " px-4 d-flex justify-content-center"}`}>
          
          {convertMoneyFormat(elem?.severanceInterestInsc ?? "-")}

              </div>
          <div className="col-2 text-end px-2">
          {convertMoneyFormat(elem?.totalInterest ?? "-")}

           {/* {convertMoneyFormat(Number(elem?.severanceInterest + elem?.severanceInterestInsc).toFixed(2) ?? "-")} */}
          </div>
          <div className="text-center col-1">
           <div>
            {(elem.comment !== "" && !!myPermission?.edit) && (
             <button onClick={() => openCommentModal(elem.comment)}>
              <img
               alt="iconSend"
               src={messageIcon}
              />
             </button>
            )}
           </div>
          </div>
         </section2>
        );
       };

 const listElem = () => {
    let elemMap;
    if (interestPayrollResults?.length > 0) {
     const elem2 = interestPayrollResults;
     if (elem2?.length > 0) {
      elemMap = elem2.map((elem) => {
       return renderElement(elem);
      });
     }
    }
    return elemMap;
   };

   const renderHeaders = [
    <div
     key={1}
     className="text-start col-1 px-3"
  //    style={{ paddingLeft: "1.5rem" }}
    >
     &nbsp; ID
    </div>,
    <div
     key={2}
     className="text-start col-2 px-3"
    >
     Empleado
    </div>,
    <div
     key={3}
     className="text-end px-2 col-1"
    >
     Salario
    </div>,
    <div
     key={4}
     className="text-center col-1"
    >
     Días laborados
    </div>,
    <div
     key={5}
     className="text-end px-2 col-2"
    >
     Intereses
    </div>,
    <div
     key={6}
     className={` col-2 ${status === "loaded" ? "text-center" : "text-end"}`}
     >
     Intereses INCS
    </div>,
    <div
     key={7}
     className="text-end px-2 col-2"
    >
     Total a pagar
    </div>,
    <div
     key={8}
     className="text-center col-1"
    >
     &nbsp;
    </div>,
   ];


 const handleSearch = (e) => {
  e.preventDefault();
  setFilters({ ...filters, page: 1 });
  setTrigger(trigger + 1);
 };

 const [commentModal, setCommentModal] = useState({
  show: false,
  cancelledReason: "",
  title: "",
  surveyId: "",
 });

 const openCommentModal = (comment) => {
  setCommentModal({ ...commentModal, show: true, comment:comment });
 };
 const closeCommentModal = () => {
  setCommentModal({ ...commentModal, show: false, cancelledReason: "" });
 };

 return (
  <>
   <ModalNew
    title="Comentarios"
    show={commentModal.show}
    btnNoName={"Cerrar"}
    size="400"
    btnYesEvent={null}
    btnYesDisabled={true}
    onHide={() => closeCommentModal()}
    btnNoEvent={() => closeCommentModal()}
    btnNoDisabled={false}
   >
    <p className={tableStyles.crudModalLabel}>Comentarios de empleado</p>
    <textarea
     placeholder={"Escribir..."}
     id="txtArea"
     rows="10"
     cols="10"
     disabled={true}
     defaultValue={commentModal.comment}
     style={{
      height: "4rem",
      borderRadius: "5px",
      border: "1.5px solid #01A0F6",
      padding: "0 0.05rem",
      width: "100%",
     }}
     className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
    ></textarea>
   </ModalNew>

   <div className="">
    <div className="">
     <Row className="align-items-end">
     <Col xs={2}>
        <p className={tableStyles.crudModalLabel}>Días menores a</p>
        <input
         onChange={(e) => setFilters({ ...filters, daysToPay: e.target.value })}
         type="number"
         value={filters.daysToPay}
         placeholder="Escribir..."
         className={`register-inputs`}
         name="position"
         onKeyDown={(e) => {
         if (e.key === "Enter") {
          handleSearch(e);
         }
         }}
         />
     </Col>
      <Col
       xs={6}
       className="d-flex align-self-end gap-1"
      >
       <input
        className={tableStyles.SearchNew}
        value={filters.search}
        placeholder="Buscar por ID, Empleado, Días laborados..."
        type="text"
        onChange={(e) => setFilters({ ...filters, search: e.target.value })}
        onKeyDown={(e) => {
         if (e.key === "Enter") {
          handleSearch(e);
         }
        }}
       ></input>
       <img
        className="hoverPointer"
        alt="iconSearch"
        style={{}}
        src={Search}
        height="24px"
        onClick={(e) => handleSearch(e)}
       />
      </Col>
     </Row>
     <GenericTableScroll
              headers={renderHeaders}
              dark={true}
              body={listElem()}
              typeHead={"2"}
            ></GenericTableScroll>
    </div>
   </div>
  </>
 );
};

export default InterestGenerate;
