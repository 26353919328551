import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import GenericTableNew from "../Layouts/GenericTableNew";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import Pagination from "react-js-pagination";
import miniShearchIcon from "../../assets/img/icons/miniShearchIcon.svg";
import backIcon from "../../assets/img/icons/backIcon.svg";
import Search from "../../assets/img/icons/lupa.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import ModalNew from "../Layouts/ModalNew";
import { useDispatch, useSelector } from "react-redux";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import {
  getArticlesForEachWarehouse,
  getMovementForArticle,
  getMovementType,
} from "../../actions/inventoryActions";
import {
  convertDateToLatinFormat,
  formatToRcSelect,
  isEmptyOrUndefined,
} from "../../helpers/helpers";

function colorTypeMovement(key) {
  switch (key) {
    case "purchaseEntry":
      return "#20b067";
    case "consignmentEntry":
      return "#20b067";
    case "donationEntry":
      return "#20b067";
    case "inputSetting":
      return "#20b067";
    case "outputSetting":
      return "red";
    case "consumOutput":
      return "red";
    case "warehouseTransfer":
      return "red";
    case "Donation":
      return "red";
    default:
      return;
  }
}
function typeMovement(key) {
  switch (key) {
    case "purchaseEntry":
      return "Entrada por compra";
    case "consignmentEntry":
      return "Entrada por consignación";
    case "donationEntry":
      return "Entrada por Donación";
    case "inputSetting":
      return "Ajuste de entrada";
    case "outputSetting":
      return "Ajuste de salida";
    case "consumOutput":
      return "Salida para consumo";
    case "warehouseTransfer":
      return "Transferencia de almacen";
    case "Donation":
      return "Donación";
    default:
      return;
  }
}
function symbol(key) {
  switch (key) {
    case "purchaseEntry":
      return "+";
    case "consignmentEntry":
      return "+";
    case "donationEntry":
      return "+";
    case "inputSetting":
      return "+";
    case "consumOutput":
      return "-";
    case "outputSetting":
      return "-";
    case "warehouseTransfer":
      return "-";
    case "Donation":
      return "-";
    default:
      return;
  }
}

export const ArticlesInStorage = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const arrReportEntryByPurchase = store.inventoryReducer?.articles_for_each_wh;
  const movements = store.inventoryReducer?.movements;
  const [avaliable, setAvaliable] = useState(0);
  const [trigger, setTrigger] = useState(1);
  const [trigger2, setTrigger2] = useState(1);
  const [currentMov, setCurrentMov] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    date_from: "",
    date_until: "",
    search: "",
    warehouse: "",
  });
  const [search, setSearch] = useState({
    search: "",
    date_from: "",
    date_until: "",
  });
  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      page: 1,
      search: search.search,
      date_from: search.date_from,
      date_until: search.date_until,
    });
  };
  let optionsMovements = [
    {
      label: "Seleccionar...",
      value: "",
    },
  ];
  if (Array.isArray(store.inventoryReducer.movementsTypes)) {
    store.inventoryReducer.movementsTypes.map((item) => {
      if (item.name === "donation") {
        item.name = "Donation";
      }
      optionsMovements.push({
        value: item.name,
        label: item.value,
        key: item.id + "mov",
      });
    });
  }
  //Something
  const [filters2, setFilters2] = useState({
    page: 1,
    perpage: 10,
    id_article: "",
    warehouse: "",
    code: "",
    type: "",
    date: "",
  });

  const handlePageChange2 = (val) => {
    setFilters({
      ...filters,
      page: val,
    });
  };

  const btnDetail = (id_article, id_warehouse, elem) => {
    setCurrentMov(elem);
    let qty = 0;
    setFilters2({
      ...filters2,
      id_article: id_article,
      warehouse: id_warehouse,
    });
    setTrigger2(trigger2 + 1);
    if (movements !== undefined) {
      movements.length > 0 &&
        movements.forEach((e, i) => {
          if (
            e.movement_type === "donationEntry" ||
            e.movement_type === "purchaseEntry" ||
            e.movement_type === "consignmentEntry"
          ) {
            // sum = Number(avaliable) + Number(e.qty_article);
            qty = Number(e.qty_article) + avaliable;
          }
        });
      setAvaliable(qty);
    }

    setmodalArtControl({
      ...modalArtControl,
      showFirst: true,
    });
  };
  const btnFilter = () => {
    dispatch(getMovementForArticle(filters2));
  };

  useEffect(() => {
    if (!!props.selectedWarehouse) {
      dispatch(
        getArticlesForEachWarehouse({
          page: filters.page,
          perpage: filters.perpage,
          date_from: filters.date_from,
          date_until: filters.date_until,
          search: filters.search,
          warehouse: props.selectedWarehouse,
        })
      );
    }
    dispatch(getMovementType());
  }, [trigger, filters]);

  useEffect(() => {
    dispatch(getMovementForArticle(filters2));
  }, [filters2.page, dispatch, trigger2]);

  const thirdDataFalse = [
    {
      validity: "19/02/2019",

      lote: "104549789789845",
      cant: "3",
    },
    {
      validity: "19/02/2019",

      lote: "104549789789845",
      cant: "3",
    },
    {
      validity: "19/02/2019",

      lote: "104549789789845",
      cant: "3",
    },
  ];
  let i = 0;
  const [modalArtControl, setmodalArtControl] = useState({
    showFirst: false,
    showSecond: false,
    showThird: false,
  });
  const listElem = () => {
    let elemMap;
    if (arrReportEntryByPurchase !== undefined) {
      const elem2 = arrReportEntryByPurchase;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const listElemFirst = () => {
    let elemMap;
    if (movements !== undefined) {
      const elem2 = movements;
      elemMap = elem2.map((elem, index) => {
        return renderElementFirst(elem, index);
      });
    }
    return elemMap;
  };
  const thirdListElem = () => {
    let elemMap;
    if (thirdDataFalse !== undefined) {
      const elem2 = thirdDataFalse;
      elemMap = elem2.map((elem) => {
        return thirdRenderElement(elem);
      });
    }
    return elemMap;
  };

  const renderElement = (elem, index) => {
    return (
      <tr key={index + elem.code}>
        <td className="col-md-1 text-center">{elem.id_article}</td>
        <td className="col-md-1 text-start">{elem.description_article}</td>
        <td className="col-md-2 text-center text-primary">{elem.available}</td>

        <td
          className={`col-md-1 text-center ${
            elem.min_stock > elem.available && "text-warning"
          }`}
        >
          {elem.min_stock}
        </td>
        <td
          className={`col-md-1 text-center ${
            elem.max_stock > elem.available && "text-warning"
          }`}
        >
          {elem.max_stock}
        </td>
        <td className="col-md-1 text-center">{elem.reorder}</td>
        <td className="col-md-1 text-center">
          {!isEmptyOrUndefined(elem.max_date) ? elem.max_date : "-"}
        </td>
        <td className="col-md-2 text-center">{elem.lots}</td>
        <td className="col-md-1 text-center cursorPointer">
          <div
            className={`d-flex`}
            onClick={() => btnDetail(elem.id_article, elem.id_warehouse, elem)}
            style={{ justifyContent: "flex-center", marginRight: "5px", paddingRight: "20px"}}
          >
            <img src={miniShearchIcon} alt="Search icon" />
          </div>
        </td>
      </tr>
    );
  };

  const renderElementFirst = (elem, index) => {
    return (
      <tr key={index}>
        <td className="col-md-1 text-center">{index + 1}</td>
        <td className="col-md-2 text-center">
          {elem.created_at.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1")}
        </td>
        <td className="col-md-3 text-start">
          {typeMovement(elem.movement_type)}
        </td>
        <td className="col-md-2 text-center text-uppercase">
          {elem.movement_code}
        </td>
        <td
          className="col-md-2 text-center"
          style={{ color: colorTypeMovement(elem.movement_type) }}
        >
          {symbol(elem.movement_type)}
          {elem.qty_article}
        </td>
      </tr>
    );
  };

  const thirdRenderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">
          {convertDateToLatinFormat(elem.validity)}
        </td>
        <td className="col-md-4 text-center">{elem.lote}</td>
        <td className="col-md-2 text-center">{elem.cant}</td>
      </tr>
    );
  };
  const localStyles = {
    endPagination: {
      display: "flex",
      justifyContent: "flex-end",
    },
    w20: { width: "20%" },
    blueInputText: {
      color: "#005dbf",
    },
    footerStyles: {
      display: "flex",
      justifyContent: "flex-end",
      width: "330%",
    },
    noBorder: { border: "none", padding: "8px" },
  };
  const renderHeadersFirst = [
    <th
      className="col-md-1 text-center"
      style={{ borderTopLeftRadius: "10px" }}
      key={i}
    >
      Item
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Fecha
    </th>,
    <th className="col-md-3 text-center" key={i + 1}>
      Tipo de movimiento
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Código
    </th>,
    <th
      className="col-md-1 text-center"
      style={{ borderTopRightRadius: "10px" }}
      key={i + 1}
    >
      Cant.
    </th>,
  ];
  const renderHeaders = [
    <th
      className="col-md-1 text-center "
      style={{ borderTopLeftRadius: "10px" }}
      key={i}
    >
      Código
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Artículo
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Disponible
    </th>,
    <th className="col-md-2 text-center" key={i + 1}>
      Stock min
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Stock max
    </th>,
    <th className="col-md-1 text-center" key={i + 1}>
      Reorden
    </th>,
    <th className="col-md-2 text-center" key={i + 1}>
      Último ingreso
    </th>,
    <th className="col-md-2 text-center" key={i + 1}>
      Lote
    </th>,
    <th
      className="col-md-1 text-center"
      style={{ borderTopRightRadius: "10px" }}
      key={i + 1}
    >
      &nbsp;
    </th>,
  ];

  const closeModal = () => {
    setAvaliable(0);
    setmodalArtControl({
      ...modalArtControl,
      showFirst: false,
    });
    setFilters2({
      page: 1,
      perpage: 10,
      id_article: "",
      warehouse: "",
      code: "",
      type: "",
      date: "",
    });
  };
  const handleSearch2 = (e) => {
    setTrigger2(trigger2 + 1);
    e.preventDefault();
    setFilters2({ ...filters2, page: 1 });
  };
  return (
    <>
      <ModalNew
        size="700"
        title="Detalle de artículo en inventario"
        show={modalArtControl.showFirst}
        subtitle={`${
          currentMov.description_article
            ? currentMov.description_article
            : "Desconocido"
        } / No. ${
          currentMov.id_article ? currentMov.id_article : "Desconocido"
        }`}
        onHide={() => closeModal()}
      >
        <Row className="d-flex">
          <Col xs={4}>
            <p className={tableStyles.crudModalLabel}>Tipo de movimiento</p>
            <Select noOptionsMessage={() => 'No hay datos'}
              styles={customSelectNewDark}
              Name="optionsMovements"
              className={"w-100 text-secondary"}
              placeholder="Seleccionar..."
              options={optionsMovements}
              onChange={(e) =>
                setFilters2({
                  ...filters2,
                  type: e.value,
                })
              }
            ></Select>
          </Col>

          <Col xs={4}>
            <p className={tableStyles.crudModalLabel}>Fecha</p>
            <input
              className="register-inputs text-secondary"
              type="date"
              value={filters2.date}
              onChange={(e) =>
                setFilters2({
                  ...filters2,
                  date: e.target.value,
                })
              }
            />
          </Col>

          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Código</p>
            <form onSubmit={(e) => handleSearch(e)}>
              <input
                className="register-inputs text-secondary"
                name="id_consumption_center"
                type="text"
                placeholder="Escribir..."
                value={filters2.code}
                onChange={(e) =>
                  setFilters2({
                    ...filters2,
                    code: e.target.value,
                  })
                }
              />
            </form>
          </Col>
          <Col className={"mt-4"} xs={1}>
            <div>
              <img
                src={Search}
                alt="Search icon"
                className="cursorPointer"
                onClick={(e) => handleSearch2(e)}
              />
            </div>
          </Col>
        </Row>

        <GenericTableNew headers={renderHeadersFirst}>
          {listElemFirst()}
        </GenericTableNew>

        <div
          className={`${paginationStyles.wrapper}`}
          style={localStyles.endPagination}
        >
          <p className={paginationStyles.paginationText}>
            Pag. {store.inventoryReducer.movements ? filters2.page : ""}
            {" de "}
            {Math.ceil(
              store.inventoryReducer.movements_total / filters2.perpage
            )
              ? Math.ceil(
                  store.inventoryReducer.movements_total / filters2.perpage
                )
              : ""}{" "}
            ({store.inventoryReducer.movements_total} encontrados)
          </p>
          <Pagination
            activePage={filters2.page}
            itemsCountPerPage={filters2.perpage}
            totalItemsCount={store.inventoryReducer.movements_total}
            pageRangeDisplayed={5}
            onChange={(e) => setFilters2({ ...filters2, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
        <p className={tableStyles.crudModalLabel}>Disponible</p>
        <div
          style={(localStyles.endPagination, localStyles.w20)}
          className={"d-flex justify-content-flex-start"}
        >
          <input
            readOnly={true}
            className={`register-inputs text-secondary`}
            name="requisition_date"
            type="text"
            value={currentMov.available}
            // value={
            //   movements?.length > 0
            //     ? movements
            //         .map((item) => Number(item.qty_article))
            //         .reduce((prev, current) => prev + current)
            //     : "-"
            // }
          />
        </div>
      </ModalNew>

      <div className={tableStyles.container}>
        {(store.inventoryReducer.movementsA_loading) &&
            <div className="loading">
                <Loader
                type="Oval"
                color="#003f80"
                height={100}
                width={100}
                />
            </div>
          }
        <div className={tableStyles.tableArea}>
          <div className={`d-flex ${tableStyles.title}`}>
            {(props.goback) && <img 
              src={backIcon}
              style={{alignSelf:"center", marginRight: 15, cursor: "pointer"}}
              onClick={() => props.goback() }
            />}
            <div style={{flex:1}}>
              <h1 style={{margin:0}}>
                Inventario en almacén
              </h1>
              {
                (props.selectedWarehouseName) && 
                <h2 className={tableStyles.subtitle}>{props.selectedWarehouseName}</h2>
              }
            </div>
          </div>
          <Row className="d-flex">
            <Col xs={2} className="align-self-end">
              <p className={tableStyles.crudModalLabel}>Desde</p>
              <div className="d-flex">
                <input
                  className={`register-inputs text-secondary font-weight-bold`}
                  name="requisition_date"
                  type="date"
                  value={search.date_from}
                  onChange={(e) =>
                    setSearch({ ...search, date_from: e.target.value })
                  }
                  onKeyDown={e=>{
                    if (e.key === 'Enter') {
                      handleSearch(e)
                  }  
                  }}
                />
                {/* <div style={{ color: "gray" }}>━</div> */}
              </div>
            </Col>
            <Col xs={2} className="align-self-end">
              <p className={tableStyles.crudModalLabel}>Hasta</p>
              <input
                className="register-inputs text-secondary"
                name="requisition_date"
                type="date"
                value={search.date_until}
                onChange={(e) =>
                  setSearch({ ...search, date_until: e.target.value })
                }
                onKeyDown={e=>{
                  if (e.key === 'Enter') {
                    handleSearch(e)
                }  
                }}
              />
            </Col>
            <Col xs={5} className="align-self-end">
              <div className="mt-4 d-flex align-self-end">
                <form onSubmit={(e) => handleSearch(e)}>
                  <input
                  className={` ${tableStyles.SearchNew} align-self-end`}
                  type="text"
                    placeholder="Buscar..."
                    value={search.search}
                    onChange={(e) =>
                      setSearch({ ...search, search: e.target.value })
                    }
                    onKeyDown={e=>{
                      if (e.key === 'Enter') {
                        handleSearch(e)
                    }  
                    }}
                  />
                </form>
                <button>
                  <img
                    src={Search}
                    alt="User icon"
                    className={`${tableStyles.iconSvgMargin2}`}
                    onClick={(e) => handleSearch(e)}
                    // onClick={() => setTrigger(trigger + 1)}
                  />
                </button>
              </div>
            </Col>
          </Row>

          <GenericTableNew dark={true} headers={renderHeaders}>
            {listElem()}
          </GenericTableNew>

          <div
            className={`${paginationStyles.wrapper}`}
            style={localStyles.endPagination}
          >
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag.{" "}
              {store.inventoryReducer.articles_for_each_wh ? filters.page : ""}
              {" de "}
              {Math.ceil(
                store.inventoryReducer.articles_for_each_wh_total /
                  filters.perpage
              )
                ? Math.ceil(
                    store.inventoryReducer.articles_for_each_wh_total /
                      filters.perpage
                  )
                : ""}{" "}
              ({store.inventoryReducer.articles_for_each_wh_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={
                store.inventoryReducer.articles_for_each_wh_total
              }
              pageRangeDisplayed={5}
              onChange={(e) => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
