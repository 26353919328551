import React, { useEffect, useState } from "react";
import backArrow from "../../assets/img/icons/atras-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { Col, Row, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  loader,
  message,
  today,
  validateEmptyString,
} from "../../helpers/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ModalNew from "../Layouts/ModalNew";
import { PdfViewer } from "../Layouts/PdfViewer";
import { customSelectNew } from "../Layouts/react-select-custom";
import { Radio } from "@material-ui/core";
import ADD from "../../assets/img/icons/addbutton.svg";
import cloneIcon from "../../assets/img/icons/cloneIcon.svg";
import deleteIcon from "../../assets/img/icons/cleanCloseIcon.svg";
import { usePostMethod } from "../../Hooks/useFetch";
import moment from "moment";
import Swal from "sweetalert2";
import { responseSwal } from "../../helpers/responseSwal";
import SelectComponent from "../SelectComponent/SelectComponent";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

export const CreateSurvey = () => {
  const location = useLocation();
  const store = useSelector((state) => state);
  const history = useHistory();
  const [showPdf, setShowPdf] = useState(false);
  const detail = location.state.detail;
  const surveyData = location.state.data;
  const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "jobWea"
    );
  //   ----------------------------- STATES--------------------------------------
  const { load: createSurveyLoader, trigger: createSurveyMethod } = usePostMethod();

  const [data, setData] = useState({
    title: "",
    description: "",
    startDate: today(),
    endDate: "",
    questions: [],
    eaccount: idAccount,
    createdBy: idUser,
  });

  const [questions, setQuestions] = useState([
    {
      required: true,
      title: "",
      type: "multiple_choice",
      answers: [
        {
          label: "",
        },
      ],
    },
  ]);

  const validationsCreateMethod = () => {
    let status = true;
    // let message = ""
    let requiredQuantity = 0;

    if (moment(data.startDate).isBefore(today())) {

      customSwaltAlert({
        icon: "warning",
        title: "Valide campos",
        text: `Para la creación de la encuesta es necesario que la fecha de inicio sea superior o igual al día de hoy`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      status = false;
    }

    if (moment(data.endDate).isBefore(today())) {
      customSwaltAlert({
        icon: "warning",
        title: "Valide campos",
        text: `Para la creación de la encuesta es necesario que la fecha de inicio sea superior o igual al día de hoy`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      status = false;
    }

    if (moment(data.endDate).isBefore(data.startDate)) {
      customSwaltAlert({
        icon: "warning",
        title: "Valide campos",
        text: `Para la creación de la encuesta es necesario que la fecha fin sea superior o igual a la fecha de inicio`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      
      status = false;
    }

    questions.forEach((elem) => {
      if (elem.title === "") {
        customSwaltAlert({
          icon: "warning",
          title: "Valide campos",
          text: `La pregunta es obligatoria`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        
        status = false;
      }

      if (elem.type === "") {
        customSwaltAlert({
          icon: "warning",
          title: "Valide campos",
          text: `El tipo de pregunta es obligatoria para crear una encuesta`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        
        status = false;
      }

      // status = elem.title == "" ? false
      if (elem.type === "multiple_choice") {
        elem.answers.forEach((ans) => {
          if (ans?.label === "") {
            customSwaltAlert({
              icon: "warning",
              title: "Valide campos",
              text: `No es posible crear una encuesta con una opción de una pregunta vacía`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
            
            status = false;
          }
        });

        if (elem.answers.length < 2) {
          customSwaltAlert({
            icon: "warning",
            title: "Valide campos",
            text: `Para que una pregunta sea válida es necesario tener más de (${elem.answers.length}) opción`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
          
          status = false;
        }
      }

      if (elem.required === true) {
        requiredQuantity++;
      }
    });

    if (requiredQuantity < 1) {
      customSwaltAlert({
        icon: "warning",
        title: "Valide campos",
        text: `Para la creación de la encuesta es necesario tener como mínimo una pregunta como obligatoria`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      
      status = false;
    }
    if (questions.length < 0) {
      customSwaltAlert({
        icon: "warning",
        title: "Valide campos",
        text: `Para la creación de la encuesta es necesario tener como mínimo (1) pregunta`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      
      status = false;
    }

    return status;
  };

  const createSurveyFunction = () => {
    let status = validationsCreateMethod();
    let validations = validateEmptyString([
      { field: data?.endDate, message: "La fecha fin de la encuesta es obligatorio" },
      { field: data?.startDate, message: "La fecha de inicio de la encuesta es obligatorio" },
      { field: data?.description, message: "La descripción de la encuesta es obligatoria" },
      { field: data?.title, message: "El título de la encuesta es obligatorio" },
    ]);
    if (validations.valid && status) {
      customSwaltAlert({
        title: `¿Está seguro?`,
        text: `Se creará la encuesta ${data.title}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Continuar",
        cancelButtonText: "Cancelar",

      }).then((result) => {
        if (result.isConfirmed) {
          createSurveyMethod({
            url: "/payroll/workenviroment",
            method: "POST",
            body: {
              title: data?.title,
              eaccount: data?.eaccount,
              createdBy: data?.createdBy,
              description: data?.description,
              startDate: data?.startDate,
              endDate: data?.endDate,
              questions: questions,
            },
            token: token,
            succesAction: (info) => {
              handleBack();
              customSwaltAlert({
                title: `Creado exitosamente`,
                icon: "success",
                text:`Se ha creado la encuesta: ${data.title}`,
                confirmButtonText: 'Aceptar',
                showCancelButton: false

              })
              // message("info", "Información", `<span style=color:#005dbf>${info.message}</span>`);
            },
            doAfterException: (error) => {
              customSwaltAlert({
                title: 'Intenta de nuevo',
                text: `${error.message}`,
                icon: "warning",
                confirmButtonText: 'Aceptar',
                showCancelButton: false

              })
              // message("info", "Información", `<span style=color:#005dbf>${error.message}</span>` );
            },
          });

        }
      })

    }
  };


  const answersType = [
    { value: "multiple_choice", label: "Opción múltiple" },
    { value: "short_answer", label: "Respuesta corta" },
  ];

  const setStatusFunction = (name, value, index) => {
    let tempArr = questions;
    tempArr[index][name] = value;
    if (name === "type") {
      if (value === "short_answer") {
        delete tempArr[index].answers;
      } else {
        tempArr[index].answers = [
          {
            label: "",
          },
        ];
      }
    }
    setQuestions(tempArr);
  };

  const setStatusMultipleChoicesAnswers = (name, value, indQuestion, index) => {
    let tempArr = questions;
    let tempArrAns = tempArr[indQuestion].answers;
    tempArrAns[index][name] = value;
    tempArr[indQuestion].answers = tempArrAns;
    setQuestions(tempArr);
  };

  const addMultipleChoice = (index) => {
    let tempArr = questions;
    let tempArrAns = tempArr[index].answers;
    tempArrAns.push({
      label: "",
    });
    tempArr[index].answers = tempArrAns;
    setQuestions(tempArr);
  };
  const addQuestion = (index) => {
    let tempArr = questions;
    tempArr.push({
      required: false,
      title: "",
      type: "multiple_choice",
      answers: [
        {
          label: "",
        },
      ],
    });
    setQuestions(tempArr);
  };
  const deleteQuestion = (index) => {
    let tempArr = questions;
    tempArr.splice(index, 1);
    setQuestions(tempArr);
  };

  const deleteMultipleChoice = (indQues, i) => {
    let tempArr = questions;
    let tempArrAns = tempArr[indQues].answers;
    tempArrAns.splice(i, 1);
    tempArr[indQues].answers = tempArrAns;
    setQuestions(tempArr);
  };
  /* ----------------------------- HANDLE CHANGES ----------------------------- */

  const handleBack = () => {
    history.push("/nomina/seguridadYsalud/climaLaboral");
  };

  const cloneQuestion = (index) => {
    if (questions[index]?.answers.length > 0) {
      let tempArr = questions;
    let clone = questions[index];
    let cloneAnswers = questions[index]?.answers;
    let newAnswers = [];
    // tempArr.push(clone)
    cloneAnswers.forEach((elem) => {
      newAnswers.push({ label: `${elem?.label} (${questions?.length + 1})` });
    });
    tempArr.push({
      required: clone?.required,
      title: `${clone?.title}(${questions.length + 1})`,
      type: clone?.type,
      answers: newAnswers,
    });
    setQuestions(tempArr);
    } else{
      customSwaltAlert({
        icon: "warning",
        title: "No es posible clonar la pregunta",
        text: `No se puede clonar la pregunta puesto a que la card se encuentra vacía`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })

    }

  };
  const renderToolAdd = () => (
    <Tooltip>
      <span style={{ color: "#c99489" }}>Añadir respuesta</span>
    </Tooltip>
  );
  const renderToolCopy = () => (
    <Tooltip>
      <span style={{ color: "#c99489" }}>Copiar pregunta</span>
    </Tooltip>
  );
  return (
    <>
      {createSurveyLoader && loader}
      {/* /* ------------------------------- PDF VIEWER ------------------------------- */}
      <ModalNew
        title="Contratos"
        show={showPdf}
        btnNoName={"Cancelar"}
        size="700"
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer file={surveyData?.results_file}></PdfViewer>
      </ModalNew>
      <div className="w-100 container">
        <Row>
          <Col xs={10}>
            <h1 className={`${tableStyles.title} ${tableStyles.f40}`}>
              <img onClick={() => handleBack()} src={backArrow} alt="icon" height="30px" className={`mr-2 mb-2 cursorPointer`} />
              Crear encuesta
            </h1>
          </Col>
        </Row>
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <div className="d-flex justify-content-between">
          <div className="mt-3 " style={{ width: "75%", paddingLeft: "2rem" }}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Título principal
              <span className={"text-danger"}>*</span>
            </p>
            <input
              value={data.title}
              disabled={detail ? true : false}
              className={`register-inputs  text-secondary ${tableStyles.outlineNone}`}
              name="position"
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
            <Row>
              <div className="mb-4">
                <Form.Group className=" text-start" controlId="description">
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Descripción de la encuesta
                      <span className={"text-danger"}>*</span>
                    </p>
                  </Form.Label>
                  <textarea
                    placeholder={"Escribir..."}
                    rows="30"
                    cols="55"
                    style={{ height: "5rem", border: "1px solid #7FADDE", fontSize: "12px" }}
                    value={data.description}
                    className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
                    onChange={(e) => setData({ ...data, description: e.target.value })}
                  ></textarea>
                </Form.Group>
              </div>
            </Row>
            <Row className="mt-2 mb-4">
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Fecha inicio
                  <span className={"text-danger"}>*</span>
                </p>
                <input
                  value={data.startDate}
                  type={"date"}
                  disabled={detail ? true : false}
                  min={today()}
                  onChange={(e) => setData({ ...data, startDate: e.target.value })}
                  className={`register-inputs ${tableStyles.outlineNone}`}
                  name="position"
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Fecha fin
                  <span className={"text-danger"}>*</span>
                </p>
                <input
                  value={data.endDate}
                  type={detail ? "text" : "date"}
                  //   value={inputs.date}
                  disabled={detail ? true : false}
                  min={today()}
                  onChange={(e) => setData({ ...data, endDate: e.target.value })}
                  className={`register-inputs  ${tableStyles.outlineNone}`}
                  name="position"
                />
              </Col>
            </Row>
            {/* ANCHOR QUESTIONS */}
            {questions.map((elem, i) => {
              let isRequired = questions[i].required ? tableStyles.nmCardQuestionsRequired : tableStyles.nmCardQuestions;
              return (
                <div className={`${isRequired} mt-5 p-5`} key={`card+${i}`}>
                  <Row className="d-flex justify-content-end">
                    <div className="d-flex justify-content-end">
                      {i !== 0 && (
                        <img
                          src={deleteIcon}
                          onClick={() => {
                            deleteQuestion(i);
                          }}
                          // width={'25px'}
                          className={`cursorPointer`}
                          alt="iconA"
                          style={{
                            height: "1rem",
                            alignSelf: "center",
                            position: "relative",
                            marginRight: "-29px",
                            marginTop: "-44px",
                          }}
                        />
                      )}
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <b className={`${tableStyles.f20} ${tableStyles.tlnTextGray}   `}> {`Pregunta #${i + 1}`}</b>
                  </Row>
                  <Row className="">
                    <Col xs={6}>
                      <input
                        key={`questionInput+${i}`}
                        className={`${tableStyles.registerInputsNom} text-secondary ${tableStyles.outlineNone}`}
                        name="position"
                        placeholder="Escribe una pregunta"
                        //   value={(questions?.filter((x, ind) => ind === i))?.title }
                        defaultValue={elem?.title}
                        onChange={(e) => setStatusFunction("title", e.target.value, i)}
                      />
                    </Col>
                    <Col xs={6}>
                      <SelectComponent
                        key={`select+${i}`}
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNew}
                        options={answersType}
                        value={answersType.find((x) => x.value === questions[i].type)}
                        onChange={(e) => {
                          setStatusFunction("type", e.value, i);
                        }}
                      />
                    </Col>
                  </Row>
                  {/* ANCHOR MULTIPLE CHOICES */}
                  <Row className="mt-4">
                    {questions[i].type === "multiple_choice" ? (
                      <>
                        {questions[i].answers.map((el, ind) => {
                          return (
                            <div
                              className="d-flex align-items-center
                              "
                              key={`${ind}+answers`}
                            >
                              <Radio
                                key={`${ind}+answer`}
                                checked={false}
                                onChange={(e) => { }}
                                // onClick={() => {
                                //     setRegisterPaymentModal({ ...registerPaymentModal, cutDownType: "paymentNumber" });
                                // }}
                                //   value="alphabetic"
                                name="alphabetic"
                                style={{ color: "#005dbf" }}
                                // color="primary"
                                // className={"text-info"}
                                id="alphabetic"
                              />
                              <input
                                key={`${ind}+answer2`}
                                className={`${tableStyles.registerInputsNom} text-secondary ${tableStyles.outlineNone}`}
                                name="position"
                                defaultValue={el?.label}
                                placeholder="Escribe una respuesta"
                                onChange={(e) => setStatusMultipleChoicesAnswers("label", e.target.value, i, ind)}
                              />
                              <div className="d-flex justify-content-end">
                                {ind !== 0 ? (
                                  <img
                                    src={deleteIcon}
                                    onClick={() => {
                                      deleteMultipleChoice(i, ind);
                                    }}
                                    // width={'25px'}
                                    className={`cursorPointer`}
                                    alt="iconA"
                                    style={{
                                      height: "0.8rem",
                                      alignSelf: "center",
                                      marginLeft: "1rem",
                                      marginRight: "0.5rem",
                                    }}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      height: "0.8rem",
                                      alignSelf: "center",
                                      marginLeft: "1rem",
                                      marginRight: "0.5rem",
                                    }}
                                  >
                                    &nbsp;&nbsp;&nbsp;
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="mt-2">
                        <textarea
                          placeholder={"Escribir..."}
                          rows="30"
                          cols="55"
                          disabled={true}
                          style={{ height: "5rem" }}
                          // value={data.description}
                          className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
                          onChange={(e) => setData({ ...data, description: e.target.value })}
                        />
                      </div>
                    )}
                  </Row>
                  <div className="d-flex justify-content-end mt-3">
                    <div
                      className="d-flex align-self-end  items-center mr-2 align-items-center"
                    // styles={{marginBotton}}
                    >
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        // className={styles.app_container_tool_websc}
                        overlay={renderToolCopy()}
                      >
                        <img onClick={() => cloneQuestion(i)} src={cloneIcon} alt="icon" className={`mr-2  cursorPointer`} />
                      </OverlayTrigger>

                      <input
                        className="form-check-input styleCheckInputRatio mr-2 cursorPointer mb-1"
                        style={{ border: "2px solid #1a538d" }}
                        onChange={(e) => {
                          if (questions[i]?.required === true) {
                            setStatusFunction("required", false, i);
                          } else {
                            setStatusFunction("required", true, i);
                          }
                        }}
                        checked={questions[i]?.required}
                        type="checkbox"
                        id="checkReqAuth"
                      />

                      <b className={`${tableStyles.f14} ${tableStyles.tlnTextGray}   `}>Obligatorio</b>
                    </div>
                    {questions[i].type === "multiple_choice" && (
                      <>
                        <div className="d-flex" onClick={() => addMultipleChoice(i)}>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            // className={styles.app_container_tool_websc}
                            overlay={renderToolAdd()}
                          >
                            <img
                              src={ADD}
                              // width={'25px'}
                              className={`cursorPointer`}
                              alt="iconA"
                              style={{
                                height: "1.5rem",
                                alignSelf: "center",
                              }}
                            />
                          </OverlayTrigger>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );
            })}

            {
              myPermission?.create && (
                <>
                  <div className="d-flex justify-content-end mt-4 align-items-center">
                    <div className="d-flex align-items-center" onClick={() => addQuestion()}>
                      <label
                        style={{
                          alignSelf: "center",
                          paddingRight: "8px",
                          fontSize: "15px",
                        }}
                        className={`${tableStyles.labelNew} hoverPointer`}
                      >
                        <b className={`${tableStyles.f14} ${tableStyles.tlnTextGray}   `}>Añadir pregunta</b>
                      </label>
                      <img
                        src={ADD}
                        // width={'25px'}
                        className={`cursorPointer`}
                        alt="iconA"
                        style={{
                          height: "1.8rem",
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </div>

                  <div className={`customTabs__Footer mt-4 ml-1`}>
                    <Button
                      onClick={() => createSurveyFunction()}
                      className={tableStyles.btnPrimary}
                    //   disabled={allowSend ? false : true}
                    >
                      Crear
                    </Button>

                    <Button variant="outline-primary" onClick={() => handleBack()} className={tableStyles.btnSecondary}>
                      Cancelar
                    </Button>
                  </div>
                </>
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};
